import { stringifyQuery } from "vue-router";
import { $BTSTracker, BTSTracker } from "./BTSTracker";
const gsmBands = [
  { band: 'GSM 450', earfcn: [259, 293] }, { band: 'GSM 480', earfcn: [306, 340] }, { band: 'GSM 750', earfcn: [438, 511] }, { band: 'GSM 850', earfcn: [128, 251] },
  { band: 'P-GSM', earfcn: [1, 124] }, { band: 'E-GSM', earfcn: [975, 1023] }, { band: 'GSM-R', earfcn: [955, 1023] }, { band: 'DCS 1800', earfcn: [512, 885] },
  { band: 'PCS 1900', earfcn: [512, 810] }
];
const umtsBands = [
  { band: 'B1', earfcn: [10562, 10838] }, { band: 'B2', earfcn: [9662, 9938] }, { band: 'B3', earfcn: [1162, 1513] },
  { band: 'B4', earfcn: [1537, 1738] }, { band: 'B5', earfcn: [4357, 4458] }, { band: 'B6', earfcn: [4387, 4413] }, { band: 'B7', earfcn: [2237, 2563] },
  { band: 'B8', earfcn: [2937, 3088] },
  { band: 'B9', earfcn: [9237, 9387] },
  { band: 'B10', earfcn: [3112, 3388] },
  { band: 'B11', earfcn: [3712, 3787] },
  { band: 'B12', earfcn: [3842, 3903] },
  { band: 'B13', earfcn: [4017, 4043] },
  { band: 'B14', earfcn: [4117, 4143] },
  { band: 'B19', earfcn: [712, 763] },
  { band: 'B20', earfcn: [4512, 4638] },
  { band: 'B21', earfcn: [862, 912] },
  { band: 'B22', earfcn: [4662, 5038] },
  { band: 'B25', earfcn: [5112, 5413] },
  { band: 'B26', earfcn: [5762, 5913] },
  { band: 'B32', earfcn: [6617, 6813] }
];
const lteBands = [
  { band: '1', up_fr: [1920, 1980], up_earfcn: [18000, 18599], dl_fr: [2110, 2170], dl_earfcn: [0, 599], duplex: 'FDD' },
  { band: '2', up_fr: [1850, 1910], up_earfcn: [18600, 19199], dl_fr: [1930, 1990], dl_earfcn: [600, 1199], duplex: 'FDD' },
  { band: '3', up_fr: [1710, 1785], up_earfcn: [19200, 19949], dl_fr: [1805, 1880], dl_earfcn: [1200, 1949], duplex: 'FDD' },
  { band: '4', up_fr: [1710, 1755], up_earfcn: [19950, 20399], dl_fr: [2110, 2155], dl_earfcn: [1950, 2399], duplex: 'FDD' },
  { band: '5', up_fr: [824, 849], up_earfcn: [20400, 20649], dl_fr: [869, 894], dl_earfcn: [2400, 2649], duplex: 'FDD' },
  { band: '7', up_fr: [2500, 2570], up_earfcn: [20750, 21449], dl_fr: [2620, 2690], dl_earfcn: [2750, 3449], duplex: 'FDD' },
  { band: '8', up_fr: [880, 915], up_earfcn: [21450, 21799], dl_fr: [925, 960], dl_earfcn: [3450, 3799], duplex: 'FDD' },
  { band: '9', up_fr: [1749.9, 1784.9], up_earfcn: [21800, 22149], dl_fr: [1844.9, 1879.9], dl_earfcn: [3800, 4149], duplex: 'FDD' },
  { band: '10', up_fr: [1710, 1770], up_earfcn: [22150, 22749], dl_fr: [2110, 2170], dl_earfcn: [4150, 4749], duplex: 'FDD' },
  { band: '11', up_fr: [1427.9, 1447.9], up_earfcn: [22750, 22949], dl_fr: [1475.9, 1495.9], dl_earfcn: [4750, 4949], duplex: 'FDD' },
  { band: '12', up_fr: [699, 716], up_earfcn: [23010, 23179], dl_fr: [729, 746], dl_earfcn: [5010, 5179], duplex: 'FDD' },
  { band: '13', up_fr: [777, 787], up_earfcn: [23180, 23279], dl_fr: [746, 756], dl_earfcn: [5180, 5279], duplex: 'FDD' },
  { band: '14', up_fr: [788, 798], up_earfcn: [23280, 23379], dl_fr: [758, 768], dl_earfcn: [5280, 5379], duplex: 'FDD' },
  { band: '17', up_fr: [704, 716], up_earfcn: [23730, 23849], dl_fr: [734, 746], dl_earfcn: [5730, 5849], duplex: 'FDD' },
  { band: '18', up_fr: [815, 830], up_earfcn: [23850, 23999], dl_fr: [860, 875], dl_earfcn: [5850, 5999], duplex: 'FDD' },
  { band: '19', up_fr: [830, 845], up_earfcn: [24000, 24149], dl_fr: [875, 890], dl_earfcn: [6000, 6149], duplex: 'FDD' },
  { band: '20', up_fr: [832, 862], up_earfcn: [24150, 24449], dl_fr: [791, 821], dl_earfcn: [6150, 6449], duplex: 'FDD' },
  { band: '21', up_fr: [1447.9, 1462.9], up_earfcn: [24450, 24599], dl_fr: [1495.9, 1510.9], dl_earfcn: [6450, 6599], duplex: 'FDD' },
  { band: '22', up_fr: [3410, 3490], up_earfcn: [24600, 25399], dl_fr: [3510, 3590], dl_earfcn: [6600, 7399], duplex: 'FDD' },
  { band: '24', up_fr: [1626.5, 1660.5], up_earfcn: [25700, 26039], dl_fr: [1525, 1559], dl_earfcn: [7700, 8039], duplex: 'FDD' },
  { band: '25', up_fr: [1850, 1915], up_earfcn: [26040, 26689], dl_fr: [1930, 1995], dl_earfcn: [8040, 8689], duplex: 'FDD' },
  { band: '26', up_fr: [814, 849], up_earfcn: [26690, 27039], dl_fr: [859, 894], dl_earfcn: [8690, 9039], duplex: 'FDD' },
  { band: '27', up_fr: [807, 824], up_earfcn: [27040, 27209], dl_fr: [852, 869], dl_earfcn: [9040, 9209], duplex: 'FDD' },
  { band: '28', up_fr: [703, 748], up_earfcn: [27210, 27659], dl_fr: [758, 803], dl_earfcn: [9210, 9659], duplex: 'FDD' },
  { band: '29', up_fr: [null, null], up_earfcn: [null, null], dl_fr: [717, 728], dl_earfcn: [9660, 9769], duplex: 'SDL' },
  { band: '30', up_fr: [2305, 2315], up_earfcn: [27660, 27759], dl_fr: [2350, 2360], dl_earfcn: [9770, 9869], duplex: 'FDD' },
  { band: '31', up_fr: [452.5, 457.5], up_earfcn: [27760, 27809], dl_fr: [462.5, 467.5], dl_earfcn: [9870, 9919], duplex: 'FDD' },
  { band: '32', up_fr: [null, null], up_earfcn: [null, null], dl_fr: [1452, 1496], dl_earfcn: [9920, 10359], duplex: 'SDL' },
  { band: '33', up_fr: [null, null], up_earfcn: [null, null], dl_fr: [1900, 1920], dl_earfcn: [36000, 36199], duplex: 'TDD' },
  { band: '34', up_fr: [null, null], up_earfcn: [null, null], dl_fr: [2010, 2025], dl_earfcn: [36200, 36349], duplex: 'TDD' },
  { band: '35', up_fr: [null, null], up_earfcn: [null, null], dl_fr: [1850, 1910], dl_earfcn: [36350, 36949], duplex: 'TDD' },
  { band: '36', up_fr: [null, null], up_earfcn: [null, null], dl_fr: [1930, 1990], dl_earfcn: [36950, 37549], duplex: 'TDD' },
  { band: '37', up_fr: [null, null], up_earfcn: [null, null], dl_fr: [1910, 1930], dl_earfcn: [37550, 37749], duplex: 'TDD' },
  { band: '38', up_fr: [null, null], up_earfcn: [null, null], dl_fr: [2570, 2620], dl_earfcn: [37750, 38249], duplex: 'TDD' },
  { band: '39', up_fr: [null, null], up_earfcn: [null, null], dl_fr: [1880, 1920], dl_earfcn: [38250, 38649], duplex: 'TDD' },
  { band: '40', up_fr: [null, null], up_earfcn: [null, null], dl_fr: [2300, 2400], dl_earfcn: [38650, 39649], duplex: 'TDD' },
  { band: '41', up_fr: [null, null], up_earfcn: [null, null], dl_fr: [2496, 2690], dl_earfcn: [39650, 41589], duplex: 'TDD' },
  { band: '42', up_fr: [null, null], up_earfcn: [null, null], dl_fr: [3400, 3600], dl_earfcn: [41590, 43589], duplex: 'TDD' },
  { band: '43', up_fr: [null, null], up_earfcn: [null, null], dl_fr: [3600, 3800], dl_earfcn: [43590, 45589], duplex: 'TDD' },
  { band: '44', up_fr: [null, null], up_earfcn: [null, null], dl_fr: [703, 803], dl_earfcn: [45590, 46589], duplex: 'TDD' },
  { band: '45', up_fr: [null, null], up_earfcn: [null, null], dl_fr: [1447, 1467], dl_earfcn: [46590, 46789], duplex: 'TDD' },
  { band: '46', up_fr: [null, null], up_earfcn: [null, null], dl_fr: [5150, 5925], dl_earfcn: [46790, 54539], duplex: 'TDD' },
  { band: '47', up_fr: [null, null], up_earfcn: [null, null], dl_fr: [5855, 5925], dl_earfcn: [54540, 55239], duplex: 'TDD' },
  { band: '48', up_fr: [null, null], up_earfcn: [null, null], dl_fr: [3550, 3700], dl_earfcn: [55240, 56739], duplex: 'TDD' },
  { band: '49', up_fr: [null, null], up_earfcn: [null, null], dl_fr: [3550, 3700], dl_earfcn: [56740, 58239], duplex: 'TDD' },
  { band: '50', up_fr: [null, null], up_earfcn: [null, null], dl_fr: [1432, 1517], dl_earfcn: [58240, 59089], duplex: 'TDD' },
  { band: '51', up_fr: [null, null], up_earfcn: [null, null], dl_fr: [1427, 1432], dl_earfcn: [59090, 59139], duplex: 'TDD' },
  { band: '52', up_fr: [null, null], up_earfcn: [null, null], dl_fr: [3300, 3400], dl_earfcn: [59140, 60139], duplex: 'TDD' },
  { band: '53', up_fr: [null, null], up_earfcn: [null, null], dl_fr: [2483.5, 2495], dl_earfcn: [60140, 60254], duplex: 'TDD' },
  { band: '65', up_fr: [1920, 2010], up_earfcn: [131072, 131971], dl_fr: [2110, 2200], dl_earfcn: [65536, 66435], duplex: 'FDD' },
  { band: '66', up_fr: [1710, 1780], up_earfcn: [131972, 132671], dl_fr: [2110, 2200], dl_earfcn: [66436, 67335], duplex: 'FDD' },
  { band: '67', up_fr: [null, null], up_earfcn: [null, null], dl_fr: [738, 758], dl_earfcn: [67336, 67535], duplex: 'SDL' },
  { band: '68', up_fr: [698, 728], up_earfcn: [132672, 132971], dl_fr: [753, 783], dl_earfcn: [67536, 67835], duplex: 'FDD' },
  { band: '69', up_fr: [null, null], up_earfcn: [null, null], dl_fr: [2570, 2620], dl_earfcn: [67836, 68335], duplex: 'SDL' },
  { band: '70', up_fr: [1695, 1710], up_earfcn: [132972, 133121], dl_fr: [1995, 2020], dl_earfcn: [68336, 68585], duplex: 'FDD' },
  { band: '71', up_fr: [663, 698], up_earfcn: [133122, 133471], dl_fr: [617, 652], dl_earfcn: [68586, 68935], duplex: 'FDD' },
  { band: '72', up_fr: [451, 456], up_earfcn: [133472, 133521], dl_fr: [461, 466], dl_earfcn: [68936, 68985], duplex: 'FDD' },
  { band: '73', up_fr: [450, 455], up_earfcn: [133522, 133571], dl_fr: [460, 465], dl_earfcn: [68986, 69035], duplex: 'FDD' },
  { band: '74', up_fr: [1427, 1470], up_earfcn: [133572, 134001], dl_fr: [1475, 1518], dl_earfcn: [69036, 69465], duplex: 'FDD' },
  { band: '75', up_fr: [null, null], up_earfcn: [null, null], dl_fr: [1432, 1517], dl_earfcn: [69466, 70315], duplex: 'SDL' },
  { band: '76', up_fr: [null, null], up_earfcn: [null, null], dl_fr: [1427, 1432], dl_earfcn: [70316, 70365], duplex: 'SDL' },
  { band: '85', up_fr: [698, 716], up_earfcn: [134002, 134181], dl_fr: [728, 746], dl_earfcn: [70366, 70545], duplex: 'FDD' },
  { band: '87', up_fr: [410, 415], up_earfcn: [134182, 134231], dl_fr: [420, 425], dl_earfcn: [70546, 70595], duplex: 'FDD' },
  { band: '88', up_fr: [412, 417], up_earfcn: [134232, 134281], dl_fr: [422, 427], dl_earfcn: [70596, 70645], duplex: 'FDD' }
];

const apn = [
  // 208 France
  { mcc_mnc: ["208-01", "208-02", "208-32", "208-91", "208-95"], operatore: "Orange S.A.", img: "https://www.orange.com/themes/theme_boosted/Master_Logo_RGB.png", },
  { mcc_mnc: ["208-03"], operatore: "MobiquiThings", img: "https://image.pitchbook.com/jyQfWPB5Paokc9gp0BTtkHm7pAK1567076979199_200x200", },
  { mcc_mnc: ["208-04"], operatore: "Societe d'ingenierie systeme telecom et reseaux", img: "", },
  { mcc_mnc: ["208-05", "208-06", "208-07"], operatore: "Globalstar Europe", img: "https://ww1.freelogovectors.net/wp-content/uploads/2022/09/globalstar-logo-freelogovectors.net_.png?lossy=1&w=2560&ssl=1", },
  { mcc_mnc: ["208-08", "208-09", "208-10", "208-11", "208-13"], operatore: "Altice", img: "https://alticefrance.com/sites/all/themes/zen_altice/logo.png", },
  { mcc_mnc: ["208-12"], operatore: "Truphone France", img: "https://mms.businesswire.com/media/20230222005599/it/1719919/2/logo.jpg", },
  { mcc_mnc: ["208-14"], operatore: "SNCF Réseau", img: "https://www.sncf-reseau.com/themes/custom/rzo/logo.svg", },
  { mcc_mnc: ["208-15", "208-16"], operatore: "Iliad", img: "https://upload.wikimedia.org/wikipedia/commons/thumb/6/6c/Iliad_logo.svg/2560px-Iliad_logo.svg.png", },
  { mcc_mnc: ["208-17"], operatore: "Local Exchange Global Operation Services", img: "https://media.licdn.com/dms/image/C5616AQHLXyutEqSPJw/profile-displaybackgroundimage-shrink_200_800/0/1548321333681?e=2147483647&v=beta&t=X0Tm-tb8rNx_FXGIAVIy1-MDx9aLqtBCaW08r6CyC7g", },
  { mcc_mnc: ["208-18"], operatore: "Voxbone mobile", img: "https://upload.wikimedia.org/wikipedia/commons/thumb/e/e7/Voxbone_2020_logo.svg/440px-Voxbone_2020_logo.svg.png", },
  { mcc_mnc: ["208-19"], operatore: "Haute-Garonne numérique", img: "https://hautegaronnenumerique.fr/wp-content/uploads/2019/10/logo-header.svg", },
  { mcc_mnc: ["208-20", "208-21"], operatore: "Bouygues Telecom", img: "https://www.bouyguestelecom.fr/static/wbm/assets/hub/img/logo-bouygues-telecom.svg", },
  { mcc_mnc: ["208-22"], operatore: "Transatel", img: "https://upload.wikimedia.org/wikipedia/commons/5/5e/Transatel_corporate_logo.png", },
  { mcc_mnc: ["208-23"], operatore: "Syndicat mixte ouvert Charente Numérique ", img: "https://www.charente-numerique.fr/wp-content/uploads/2018/12/charente_numerique_logo.png", },
  { mcc_mnc: ["208-24"], operatore: "MobiquiThings", img: "https://image.pitchbook.com/jyQfWPB5Paokc9gp0BTtkHm7pAK1567076979199_200x200", },
  { mcc_mnc: ["208-25"], operatore: "LycaMobile", img: "https://lycamobile-italy-website.s3.eu-central-1.amazonaws.com/lycamobile-it-cms/wp-content/uploads/2018/06/06101549/LM_CT_logo_it.svg", },
  { mcc_mnc: ["208-26"], operatore: "Euro-Information Telecom SAS", img: "https://cdnsi.e-i.com/INGR/sd/euro_information_2020/1.107.16/fr/images/css/env/logo.svg", },
  { mcc_mnc: ["208-27"], operatore: "Coriolis Telecom", img: "https://www.coriolis.com/skin/frontend/boilerplate/default/images/logo-coriolis-telecom.png", },
  { mcc_mnc: ["208-28"], operatore: "Airbus Defence and Space SAS", img: "https://www.airbus.com/themes/custom/airbus_web_experience_ui/logo.svg", },
  { mcc_mnc: ["208-29"], operatore: "Cubic télécom France", img: "https://www.cubictelecom.com/wordpress/wp-content/uploads/2021/01/cubic-logo-1.svg", },
  { mcc_mnc: ["208-30"], operatore: "Syma Mobile", img: "https://www.symamobile.com/version-202105/images/logo_syma.svg?v=20230902_01", },
  { mcc_mnc: ["208-31"], operatore: "Mundio Mobile", img: "https://media.licdn.com/dms/image/C560BAQEM1SmbwSTniw/company-logo_200_200/0/1519908762955?e=2147483647&v=beta&t=iVF1tS2U2w6qPknbaPYot9iJdnaJg7eyZtN64tpPDeE", },
  { mcc_mnc: ["208-33"], operatore: "Département des Pyrénées-Atlantiques", img: "https://upload.wikimedia.org/wikipedia/fr/thumb/e/e1/Logo_Pyr%C3%A9n%C3%A9es_Atlantiques_2015.svg/langfr-1024px-Logo_Pyr%C3%A9n%C3%A9es_Atlantiques_2015.svg.png", },
  { mcc_mnc: ["208-34"], operatore: "Cellhire France", img: "https://www.cellhire.fr/img/logos/cellhire-dark.svg", },
  { mcc_mnc: ["208-50"], operatore: "EDF", img: "https://www.edf.fr/themes/custom/nova/assets/images/00-tokens/logos/images/logo-edf.svg", },
  { mcc_mnc: ["208-70"], operatore: "Weaccess group", img: "https://www.weaccess.fr/img/logo.png", },
  { mcc_mnc: ["208-86"], operatore: "SEM@FOR77", img: "", },
  { mcc_mnc: ["208-87"], operatore: "Régie autonome des transports parisiens", img: "https://www.ratp.fr/sites/default/files/logos/logo_ratp_2.svg", },
  { mcc_mnc: ["208-88"], operatore: "Bouygues Telecom", img: "https://www.bouyguestelecom.fr/static/wbm/assets/hub/img/logo-bouygues-telecom.svg", },
  { mcc_mnc: ["208-89"], operatore: "Fondation b-com", img: "https://b-com.com/assets/images/logos/b-com.svg", },
  { mcc_mnc: ["208-90"], operatore: "Images & Réseaux", img: "https://www.images-et-reseaux.com/voy_content/uploads/2021/11/logo_ir_100.png.png", },
  { mcc_mnc: ["208-92"], operatore: "Association Plate-forme Télécom", img: "", },
  { mcc_mnc: ["208-93", "208-97"], operatore: "Thales Communications & Security SAS", img: "http], //coopol.eurecom.fr/sites/coopol.eurecom.fr/files/Logo_COOPOL.jpg", },
  { mcc_mnc: ["208-94"], operatore: "Halys", img: "https://static.wixstatic.com/media/75b555_20ded2b2cf3b4543baadc03ff7a0b3ec~mv2.jpg/v1/fill/w_412,h_206,al_c,lg_1,q_80,enc_auto/75b555_20ded2b2cf3b4543baadc03ff7a0b3ec~mv2.jpg", },
  { mcc_mnc: ["208-96"], operatore: "Région Bourgogne-Franche-Comté", img: "https://www.bourgognefranchecomte.fr/themes/bourg/logo.svg", },
  { mcc_mnc: ["208-98"], operatore: "Société Air France", img: "https://les-eauxvives.org/wp-content/uploads/2016/09/air-france.png", },
  // 525 Singapore
  { mcc_mnc: ["525-07", "525-01", "525-02"], operatore: "Singapore Telecom", img: "https://cdn1.singteldigital.com/content/dam/singtel/online-draft/singtel_logo_coloured.png", },
  { mcc_mnc: ["525-11", "525-011", "525-03"], operatore: "M1 limited", img: "https://www.m1.com.sg/-/media/M1/M1CP/Feature/Identity/Logo/logo.png?h=95&w=95&la=en&hash=91F81D0822B2265764A0E2FDDB2CA2915B9B0422", },
  { mcc_mnc: ["525-05", "525-06", "525-08"], operatore: "StarHub Mobile", img: "https://www.starhub.com/etc/designs/starhub/clientlibs/img/agile/star-icon.png", },
  { mcc_mnc: ["525-09"], operatore: "Liberty Wireless Pte Ltd", img: "https://is1-ssl.mzstatic.com/image/thumb/Purple116/v4/73/4f/f3/734ff3c7-3b8b-5c88-8307-c29a23308cf0/AppIcon-1x_U007emarketing-0-5-0-85-220.png/1024x1024.jpg", },
  { mcc_mnc: ["525-10"], operatore: "TPG Telecom Pte Ltd", img: "https://www.tpgtelecom.com.au/themes/custom/tpg/TPG_Telecom_Logo_RGB.png", },
  { mcc_mnc: ["525-12"], operatore: "GRID Communications Pte Ltd.", img: "https://www.grid.net.sg/wp-content/uploads/2019/01/logo-final.png", },
  // 502 Malesia
  { mcc_mnc: ["502-01", "502-11"], operatore: "Telekom Malaysia Bhd", img: "https://tm.com.my/themes/custom/tmtheme/img/TM_YNIN.webp", },
  { mcc_mnc: ["502-10"], operatore: "Maxis, DiGi, Celcom, XOX", img: "https://www.maxis.com.my/content/dam/mxs/images/rebrand/about-maxis/newsroom/2021/march/tron-social.png", },
  { mcc_mnc: ["502-12", "502-17"], operatore: "Maxis Communications Berhad", img: "https://www.maxis.com.my/content/dam/mxs/rebrand/maxis-logo.png", },
  { mcc_mnc: ["502-13"], operatore: "Celcom Axiata Berhad", img: "https://www.celcom.com.my/sites/default/files/menu_icons/personal-logo_3_0.svg", },
  { mcc_mnc: ["502-14"], operatore: "Telekom Malaysia Berhad for PSTN SMS", img: "https://tm.com.my/themes/custom/tmtheme/img/TM_YNIN.webp", },
  { mcc_mnc: ["502-150"], operatore: "Tune Talk Sdn Bhd", img: "https://cdn.tunetalk.com/wp-content/uploads/2022/02/27132634/tunetalk-icon.png", },
  { mcc_mnc: ["502-151"], operatore: "Baraka Telecom Sdn Bhd", img: "", },
  { mcc_mnc: ["502-152"], operatore: "YTL Communications Sdn Bhd", img: "https://www.ytl.com/images/ytl-logo_black.png", },
  { mcc_mnc: ["502-153"], operatore: "Webe Digital Sdn Bhd", img: "https://upload.wikimedia.org/wikipedia/commons/d/df/Webe-with-TM-Group_01.png?20170731020907", },
  { mcc_mnc: ["502-154"], operatore: "Talk Focus Sdn Bhd", img: "", },
  { mcc_mnc: ["502-155"], operatore: "Clixster Mobile Sdn Bhd", img: "https://1.bp.blogspot.com/-uo738emOxD4/Un5nH37oZVI/AAAAAAAAABY/WWuvS-ZtZOc/s200/logo.png", },
  { mcc_mnc: ["502-156"], operatore: "Altel Communications Sdn Bhd", img: "https://www.altel.my/images/logo.png", },
  { mcc_mnc: ["502-157"], operatore: "Telekomunikasi Indonesia International (M) Sdn Bhd", img: "https://telin.net/images/logo.svg", },
  { mcc_mnc: ["502-16"], operatore: "DiGi Telecommunications", img: "https://assets-global.website-files.com/6152b2d34ca06b6d275dd66e/641a9eefcec88a2cf7a55602_Digi_CelcomDigi-Logo.png", },
  { mcc_mnc: ["502-18"], operatore: "U Mobile Sdn Bhd", img: "https://www.u.com.my/content/dam/u-mobile/personal/general/logo/umobile-personal.svg", },
  { mcc_mnc: ["502-19"], operatore: "Celcom Axiata Berhad", img: "https://www.celcom.com.my/sites/default/files/menu_icons/personal-logo_3_0.svg", },
  { mcc_mnc: ["502-20"], operatore: "Electcoms Berhad", img: "https://electcoms.net.my/wp-content/uploads/2023/05/logo-electcoms.svg", },
  // 230 Repubblica Ceca
  { mcc_mnc: ["230-01"], operatore: "T-Mobile", img: "https://www.t-mobile.com/content/dam/t-mobile/ntm/branding/logos/corporate/tmo-logo-v4.svg", },
  { mcc_mnc: ["230-02"], operatore: "O2", img: "https://static2.o9.de/resource/blob/59652/2943a5dae8568adfa7f8acf11b14e09d/png/telefonica-logo-image-picture-data.webp", },
  { mcc_mnc: ["230-04"], operatore: "Nordic Telecom s.r.o.", img: "https://aeqqktywno.cloudimg.io/v7/_atmimg_prod_/images/profiles/company-2937018/f40defa0-d172-40f0-ba7a-24eab9f83c6b.svg", },
  { mcc_mnc: ["230-05"], operatore: "PODA a.s.", img: "https://media.licdn.com/dms/image/D4E0BAQHVHspSIXDchw/company-logo_200_200/0/1681742275515?e=2147483647&v=beta&t=vpwTVJHf9S-r31kAyvnB9gP4HdfUdiYuHVVXaonJNxY", },
  { mcc_mnc: ["230-06"], operatore: "Nordic Telecom 5G a.s.", img: "https://i.iinfo.cz/images/235/NT_2.jpg", },
  { mcc_mnc: ["230-07"], operatore: "ASTELNET, s.r.o.", img: "http], //www.astelnet.com/files/bluemarine_logo.png", },
  { mcc_mnc: ["230-08"], operatore: "Compatel s.r.o.", img: "http], //www.compatel.com/public/images/logo.png", },
  { mcc_mnc: ["230-09"], operatore: "Vectone Mobile", img: "https://www.vectonemobile.co.uk/sites/default/files/logo_2.png", },
  { mcc_mnc: ["230-98"], operatore: "Správa železniční dopravní cesty, s.o.", img: "https://www.spravazeleznic.cz/szdc-theme-web/images/szdc/szdc-logo-barevne.svg", },
  // 222 Italia
  { mcc_mnc: ["222-37", "222-88"], operatore: "Wind Tre", img: "https://d1qsjop7tycsfb.cloudfront.net/typo3conf/ext/wind_onebrand/Resources/Public/img/mm_header/windtre-logo-mob.png", },
  { mcc_mnc: ["222-99"], operatore: "3 Italia", img: "https://d1qsjop7tycsfb.cloudfront.net/typo3conf/ext/wind_onebrand/Resources/Public/img/mm_header/windtre-logo-mob.png", },
  { mcc_mnc: ["222-34"], operatore: "BT Italia", img: "https://upload.wikimedia.org/wikipedia/commons/thumb/c/cd/BT_logo_2019.svg/800px-BT_logo_2019.svg.png", },
  { mcc_mnc: ["222-53"], operatore: "COOP Voce", img: "https://www.coopvoce.it/etc.clientlibs/coopvoce/clientlibs/clientlib-site/resources/images/logo-coopvoce.svg", },
  { mcc_mnc: ["222-08"], operatore: "Fastweb S.p.A.", img: "https://upload.wikimedia.org/wikipedia/it/archive/4/42/20230104192916%21Fastweb_logo_flat.png", },
  { mcc_mnc: ["222-50"], operatore: "Iliad", img: "https://www.iliad.it/assets/images/logo.png", },
  { mcc_mnc: ["222-33"], operatore: "Poste Mobile S.p.A.", img: "http], //www.postemobile.it/ClientLibrary/img/share/logo-postemobile.png", },
  { mcc_mnc: ["222-39"], operatore: "SMS Italia S.r.l.", img: "https://www.sms.it/user/themes/smsh/assets/logo_smsit_o_from.png", },
  { mcc_mnc: ["222-47"], operatore: "Tiscali S.p.A.", img: "https://casa.tiscali.it/img/logo.png", },
  { mcc_mnc: ["222-01", "222-43", "222-48"], operatore: "Tim", img: "https://risorse.tim.it/content/dam/flytoco-areapubblica-aemfe/loghi/logo.svg", },
  { mcc_mnc: ["222-10", "222-06", "214-01", "214-06", "214-18", "214-24", "214-37", "268-01", "268-91", "230-03", "230-99"], operatore: "Vodafone", img: "https://privati.vodafone.it/favicon-32x32.png", },
  { mcc_mnc: ["222-38"], operatore: "Linkem S.p.A.", img: "https://www.linkem.com/-/media/linkem/images/logo/logo-linkem-new.png?h=140&w=320&hash=7F4116801FB8D7C2C4C5E58256B123CBA8153816", },
  // 214 Spagna
  { mcc_mnc: ["214-02"], operatore: "Alta Tecnologia en Comunicacions SL", img: "https://www.altecom.net/images/logo-altecom.jpg", },
  { mcc_mnc: ["214-03", "214-09", "214-19", "214-21"], operatore: "Orange Espagne", img: "https://upload.wikimedia.org/wikipedia/commons/thumb/c/c8/Orange_logo.svg/150px-Orange_logo.svg.png", },
  { mcc_mnc: ["214-04", "214-23", "214-29", "214-33"], operatore: "Xfera Moviles", img: "https://upload.wikimedia.org/wikipedia/commons/6/66/Yoigo_morado.svg", },
  { mcc_mnc: ["214-05", "214-07", "214-32", "214-38"], operatore: "Telefónica Móviles España", img: "https://upload.wikimedia.org/wikipedia/commons/thumb/5/55/Movistar_isotype_2020.svg/1280px-Movistar_isotype_2020.svg.png", },
  { mcc_mnc: ["214-08"], operatore: "Euskaltel", img: "https://upload.wikimedia.org/wikipedia/commons/2/2d/Euskaltel_2018_logo.svg", },
  { mcc_mnc: ["214-11"], operatore: "Telecom Castilla-La Mancha", img: "https://telecomclm.net/wp-content/uploads/2019/04/cropped-operador-2.png", },
  { mcc_mnc: ["214-13"], operatore: "Syma Mobile España", img: "https://www.symamobile.com/version-202001/images/logo_syma.svg", },
  { mcc_mnc: ["214-16", "214-17"], operatore: "R Cable y Telecomunicaciones Galicia S.A.", img: "https://en.wikipedia.org/wiki/File], R_Cable_logo.svg", },
  { mcc_mnc: ["214-20"], operatore: "Fonyou Telecom", img: "https://fonyou.com/wp-content/themes/beep-fonyou/assets/images/logos/footer_logo.svg", },
  { mcc_mnc: ["214-25", "268-04", "311-960"], operatore: "LycaMobile", img: "https://upload.wikimedia.org/wikipedia/commons/thumb/2/24/Lycamobile.svg/1920px-Lycamobile.svg.png", },
  { mcc_mnc: ["214-26"], operatore: "Lleida Networks Serveis Telemátics, SL", img: "https://www.lleida.net/img/logos/logo-lleidanet.png", },
  { mcc_mnc: ["214-27"], operatore: "SCN Truphone, S.L.", img: "https://encrypted-tbn2.gstatic.com/images?q=tbn], ANd9GcRV4n6Y6UlLkNeXgq5Rczv0TTT3rBDC0mNuQvowIg9oTPfaz-jJ", },
  { mcc_mnc: ["214-28"], operatore: "Consorcio de Telecomunicaciones Avanzadas, S.A.", img: "http], //www.cotatelecom.com/wp-content/uploads/2016/11/Logo-transparente.png", },
  { mcc_mnc: ["214-30", "268-11"], operatore: "Compatel Limited", img: "", },
  { mcc_mnc: ["214-31"], operatore: "Red Digital De Telecomunicacioned de las lslas Baleares", img: "", },
  { mcc_mnc: ["214-34"], operatore: "Aire Networks del Mediterráneo", img: "", },
  { mcc_mnc: ["214-35"], operatore: "INGENIUM OUTSOURCING SERVICES", img: "", },
  { mcc_mnc: ["214-36"], operatore: "ALAI OPERADOR DE TELECOMUNICACIONES", img: "https://www.hostelvending.com/image/sys_companies/700x0/alai-operador-de-telecomunicaciones-s.l.png", },
  { mcc_mnc: ["214-51"], operatore: "Administrador de Infraestructuras Ferroviarias", img: "https://www.adif.es/o/adif-theme/images/color_schemes/adif/logo-adif.svg", },
  // 424 Emirati Arabi
  { mcc_mnc: ["424-02"], operatore: "Emirates Telecom Corp", img: "https://www.etisalat.ae/content/dam/etisalat/consumer/nwt/mega-menu/etisalat-logo/etisalat-logo.svg", },
  { mcc_mnc: ["424-03"], operatore: "Emirates Integrated Telecommunications Company", img: "https://www.du.ae/servlet/duaediscovery/common/discovery/common/images/about-us-footer.svg", },
  // 268 Portogallo
  { mcc_mnc: ["268-02", "268-06", "268-80"], operatore: "Telecomunicações Móveis Nacionais", img: "https://conteudos.meo.pt/Style%20Library/consumo/images/logo_meo_preto.png", },
  { mcc_mnc: ["268-03"], operatore: "NOS Comunicações", img: "https://galeria.nos.pt/Style%20Library/5G/images/logo-nos.svg", },
  { mcc_mnc: ["268-05"], operatore: "Oniway - Inforcomunicaçôes, S.A.", img: "https://en.wikipedia.org/wiki/File], Oni_logo2.png", },
  { mcc_mnc: ["268-07"], operatore: "Mundio Mobile (Portugal) Limited", img: "https://www.vectonemobile.co.uk/sites/default/files/logo_2.png", },
  { mcc_mnc: ["268-12"], operatore: "Infraestruturas de Portugal, S.A.", img: "https://www.infraestruturasdeportugal.pt/themes/ip_corporativo_theme/images/logo-cor.png", },
  { mcc_mnc: ["268-13"], operatore: "G9Telecom, S.A.", img: "https://www.g9telecom.pt/img/logo.png", },
  { mcc_mnc: ["268-21"], operatore: "Zapp Portugal", img: "", },
  // 334 Messico
  { mcc_mnc: ["334-01", "334-04", "334-05", "334-09", "334-10", "334-40", "334-50", "334-90"], operatore: "AT&T Mexico", img: "	https://www.att.com.mx/content/dam/ATT/personal/lading/home-assets/marquee/homer/menu/logo-att.svg", },
  { mcc_mnc: ["334-02", "334-20"], operatore: "Telcel", img: "https://www.telcel.com/content/dam/htmls/img/icons/logo-telcel.svg", },
  { mcc_mnc: ["334-03", "334-30", "334-45"], operatore: "Movistar", img: "https://upload.wikimedia.org/wikipedia/commons/thumb/5/55/Movistar_isotype_2020.svg/1280px-Movistar_isotype_2020.svg.png", },
  { mcc_mnc: ["334-60"], operatore: "Servicios de Acceso Inalambrico, S.A. de C.V.", img: "", },
  { mcc_mnc: ["334-66"], operatore: "Telmex", img: "https://telmex.com/documents/20194/7582410/telmex.svg/fd1183b3-c08d-7c40-86f0-cec5dd75c6d7", },
  { mcc_mnc: ["334-70", "334-80"], operatore: "Unefon", img: "https://www.unefon.com.mx/img/unefon-logo-black.png", },
  { mcc_mnc: ["334-140"], operatore: "Altán Redes S.A.P.I. de C.V.", img: "https://cdn-www.bnamericas.com/img/logo_bna.png", },
  { mcc_mnc: ["334-299"], operatore: "Carriers de otros paises", img: "", },
  { mcc_mnc: ["334-999"], operatore: "FIX LINE", img: "", },
  // 313 United States of America
  { mcc_mnc: ["313-100", "313-110", "313-190"], operatore: "700 MHz Public Safety Broadband", img: "https://www.firstnet.gov/sites/default/files/FirstNetAuthorityLogo.jpg", },
  // 312 Stati Uniti d'America
  { mcc_mnc: ["312-500"], operatore: "AB Spectrum LLC", img: "", },
  { mcc_mnc: ["312-70"], operatore: "Adams Networks Inc", img: "", },
  { mcc_mnc: ["310-880"], operatore: "Advantage Cellular Systems, Inc.", img: "https://www.dtcwireless.com/wp-content/uploads/2017/01/logo-1.jpg", },
  { mcc_mnc: ["310-850"], operatore: "Aeris Communications, Inc.", img: "https://www.aeris.com/wp-content/uploads/2022/01/aeris-logo.svg", },
  { mcc_mnc: ["310-630"], operatore: "Agri-Valley Communications", img: "https://ipid72.p3cdn1.secureserver.net/wp-content/uploads/2022/01/agri-valley-services-logo.png", },
  { mcc_mnc: ["310-34"], operatore: "Airpeak", img: "", },
  { mcc_mnc: ["310-50", "310-190"], operatore: "Alaska Communications", img: "https://www.alaskacommunications.com/-/media/Images/AKCS_Global/akcs-logo-expect-more.ashx?h=100&w=190&la=en&hash=9C834A2C8A68163098BC405BD9D0C99B", },
  { mcc_mnc: ["312-880"], operatore: "Albemarle County Public Schools", img: "", },
  { mcc_mnc: ["310-54"], operatore: "Alltel US", img: "", },
  { mcc_mnc: ["313-390"], operatore: "Altice USA Wireless, Inc.", img: "https://alticeusadev2.prod.acquia-sites.com/sites/default/files/2022-05/ALTICE_LOGO_HORIZONTAL_REV_TRANS_RGB.png", },
  // 311 Stati uniti d'America
  { mcc_mnc: ["311-770"], operatore: "Altiostar Networks, Inc.", img: "", },
  { mcc_mnc: ["311-780"], operatore: "American Samoa Telecommunications", img: "https://www.astca.net/wp-content/uploads/2018/08/Logo.png", },
  { mcc_mnc: ["311-30"], operatore: "Americell PA 3 Partnership", img: "https://www.indigowireless.com/uploads/7/0/1/7/70179849/indigo-logo.jpg", },
  { mcc_mnc: ["311-200"], operatore: "ARINC", img: "https://it.wikipedia.org/wiki/File], ARINC_logo.svg", },
  { mcc_mnc: ["312-750"], operatore: "Artemis Networks LLC", img: "https://assets.website-files.com/52f04b50d6dbc2930f000490/622598a5639e30842ea2bdc2_artemis_title_home%402x.png", },
  { mcc_mnc: ["313-440"], operatore: "Arvig Enterprises, Inc.", img: "https://www.arvig.com/wp-content/uploads/2018/11/arvig_logo.png", },
  { mcc_mnc: ["310-16", "310-30", "310-70", "310-80", "310-90", "310-150", "310-170", "310-280", "310-380", "310-410", "310-560", "310-670", "310-680", "310-950", "311-70", "311-90", "311-180", "311-190", "312-90", "312-670", "312-680", "313-210"], operatore: "AT&T Mobility", img: "http], //t0.gstatic.com/images?q=tbn], ANd9GcRoyThCKtMvxaPQrZ0aNYDoBbTs6m-aQprv47XhaSuynSmsSZon", },
  { mcc_mnc: ["311-570"], operatore: "Bend Cable Communications LLC", img: "https://hellotds.com/content/dam/tdstelecom/images/logos/tds-logo.svg", },
  { mcc_mnc: ["311-970"], operatore: "Big River Broadband, LLC", img: "https://residential.bigrivercom.com/wp-content/uploads/br-logo.png", },
  { mcc_mnc: ["313-270"], operatore: "Blackstar Management", img: "", },
  { mcc_mnc: ["311-440", "311-800", "311-810"], operatore: "Bluegrass Cellular LLC", img: "https://www.verizon.com/about/sites/default/files/2021-03/bluegrass-cellular-2-700x393.jpg", },
  { mcc_mnc: ["544-11"], operatore: "Bluesky", img: "https://blueskyweb.org/images/logo-32x32.jpg", },
  { mcc_mnc: ["312-810"], operatore: "Bristol Bay Telephone Cooperative", img: "https://www.bristolbay.com/uploads/8/2/1/8/82184268/published/bbtc-logo-for-website.jpg?1609437968", },
  { mcc_mnc: ["312-540"], operatore: "Broadband In Hand LLC", img: "", },
  { mcc_mnc: ["312-570"], operatore: "Blue Wireless", img: "", },
  { mcc_mnc: ["311-330"], operatore: "Bug Tussel Wireless LLC", img: "https://btussel.com/wp-content/themes/webfitters/assets/images/logo.png", },
  { mcc_mnc: ["310-900", "312-450"], operatore: "Cable & Communications Corporation", img: "https://cable-com.com/wp-content/uploads/2017/10/cci_script_logo_sm.jpg", },
  { mcc_mnc: ["313-310"], operatore: "CAL.NET, Inc.", img: "https://www.calnet.com/public/img/calnet_logo.png", },
  { mcc_mnc: ["313-300"], operatore: "Cambio WiFi of Delmarva, LLC", img: "", },
  { mcc_mnc: ["313-20"], operatore: "Cambridge Telephone Company Inc.", img: "https://ctcweb.net/Portals/0/Logo_Vector.png?ver=2017-10-04-120534-490", },
  { mcc_mnc: ["310-130", "312-470"], operatore: "Carolina West Wireless", img: "", },
  { mcc_mnc: ["310-360", "312-270", "312-280"], operatore: "Cellular Network Partnership", img: "https://gopioneer.com/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Flogo-dark.02d7e1b2.png&w=128&q=75", },
  { mcc_mnc: ["311-230", "311-630"], operatore: "Cellular South Inc.", img: "https://en.wikipedia.org/wiki/File], Cspire-logo.png", },
  { mcc_mnc: ["312-260"], operatore: "Central LTE Holdings", img: "", },
  { mcc_mnc: ["313-330"], operatore: "CenturyTel Broadband Services LLC", img: "", },
  { mcc_mnc: ["311-10", "312-220"], operatore: "Chariton Valley Communications", img: "https://www.cvalley.net/wp-content/uploads/2016/08/logo.svg", },
  { mcc_mnc: ["310-420"], operatore: "Cincinnati Bell Wireless", img: "https://login.altafiber.com/cinbell-logo-color.png", },
  { mcc_mnc: ["312-890"], operatore: "Circle Gx", img: "https://a-cloud.b-cdn.net/media/iW=210&iH=210&oX=2&oY=0&cW=206&cH=210/9d4c4988b9c846c1b5c03ad43957daf8.png", },
  { mcc_mnc: ["312-800"], operatore: "Cirrus Core Networks", img: "", },
  { mcc_mnc: ["315-10"], operatore: "Citizens Broadband Radio Service", img: "", },
  { mcc_mnc: ["312-310"], operatore: "Clear Stream Communications, LLC", img: "https://clearstreamcomms.com/wp-content/uploads/2016/11/Clearstream-logo.png", },
  { mcc_mnc: ["312-100"], operatore: "ClearSky Technologies, Inc.", img: "http], //www.csky.com/wp-content/uploads/2018/08/ClearSkyTechnologies_twotone_v2-250x100.jpg", },
  { mcc_mnc: ["312-140"], operatore: "Cleveland Unlimited, Inc.", img: "https://en.wikipedia.org/wiki/File], Revol_logo_new.png", },
  { mcc_mnc: ["311-790"], operatore: "Coleman County Telephone Cooperative, Inc.", img: "https://www.cctelco.org/wp-content/uploads/2017/04/logo-white-small.png", },
  { mcc_mnc: ["311-40", "311-320", "312-370", "311-550"], operatore: "Commnet Wireless", img: "https://www.commnetbroadband.com/uploads/1/3/9/6/139656345/published/commnetlogo-horizontalwbg.png?1643041259", },
  { mcc_mnc: ["310-60", "312-440"], operatore: "Consolidated Telcom", img: "https://consolidatednd.com/wp-content/uploads/2022/06/ConsLogoVector-01-NO-TAGLINE-768x198.png", },
  { mcc_mnc: ["310-930", "312-380"], operatore: "Copper Valley Wireless", img: "https://ruralwireless.org/wp-content/uploads/2010/09/Copper-Valley-Wireless-logo.jpeg", },
  { mcc_mnc: ["311-240"], operatore: "Cordova Wireless", img: "https://www.ctcak.net/wp-content/uploads/2021/01/CWC-solid-background-980x457.jpg", },
  { mcc_mnc: ["313-60"], operatore: "Country Wireless", img: "https://countrywireless.com/wp-content/uploads/2016/11/cropped-admin-ajax-1-2.png", },
  { mcc_mnc: ["312-60"], operatore: "CovarageCo", img: "", },
  { mcc_mnc: ["311-140"], operatore: "Cross Telephone Company", img: "https://www.crosstel.net/images/logos/2022-OCT-logo-01.png", },
  { mcc_mnc: ["310-700"], operatore: "Cross Valiant Cellular Partnership", img: "", },
  { mcc_mnc: ["312-30", "312-10"], operatore: "Cross Wireless", img: "https://bravadowireless.com/wp-content/themes/theme-bravado/assets/images/Bravado_Logo_White.png", },
  { mcc_mnc: ["312-40", "312-950", "313-220"], operatore: "Custer Telephone Co-op (CTCI)", img: "https://custertel.net/wp-content/uploads/2021/08/cropped-FullColor.png", },
  { mcc_mnc: ["310-780"], operatore: "Dispatch Direct", img: "https://dct.aero/wp-content/uploads/2020/02/dd-logo-mail.svg", },
  { mcc_mnc: ["313-340", "313-350", "313-360"], operatore: "Dish Network", img: "https://it.wikipedia.org/wiki/File], Dish_Network_2019.svg", },
  { mcc_mnc: ["313-30"], operatore: "Eagle Telephone System Inc.", img: "", },
  { mcc_mnc: ["310-750", "312-120", "312-130", "312-910"], operatore: "East Kentucky Network, LLC", img: "https://www.appalachianwireless.com/images/AppalachianWirelessLogo.png", },
  { mcc_mnc: ["311-760"], operatore: "Edigen Inc.", img: "", },
  { mcc_mnc: ["311-460"], operatore: "Electric Imp Inc.", img: "", },
  { mcc_mnc: ["310-610"], operatore: "Elkhart Telephone Co.", img: "", },
  { mcc_mnc: ["312-410"], operatore: "Eltopia Communications, LLC", img: "http], //wp.eltopia.com/wp-content/uploads/2014/09/eltopia-sm.jpg", },
  { mcc_mnc: ["311-950"], operatore: "Enhanced Telecommmunications Corp.", img: "https://www.etd.com/wp-content/uploads/2018/03/ETD-Logo-1.jpg", },
  { mcc_mnc: ["310-35"], operatore: "ETEX Communications, LP", img: "https://www.etex.net/wp-content/uploads/2015/07/etex-3d-logo-1.svg", },
  { mcc_mnc: ["310-990"], operatore: "Evolve Cellular Inc.", img: "https://uploads-ssl.webflow.com/6234b7acf63b34064d6110fb/6234c1c7f5197a4f5d35d9d9_Evolve%20Cellular%20Logo%20White%20on%20Transparent%20Background%20(small).png", },
  { mcc_mnc: ["313-260"], operatore: "Expeto Wireless Inc.", img: "", },
  { mcc_mnc: ["310-311"], operatore: "Farmers Wireless", img: "", },
  { mcc_mnc: ["313-520"], operatore: "Florida Broadband, Inc.", img: "https://lightyear.ai/images/home/lightyear-logo-white.svg", },
  { mcc_mnc: ["312-390"], operatore: "FTC Communications LLC", img: "https://www.ftc.ch/wp-content/uploads/ressources/logo_ftc.svg", },
  { mcc_mnc: ["312-50"], operatore: "Fuego Wireless", img: "", },
  { mcc_mnc: ["313-240"], operatore: "Fundamental Holdings, Corp.", img: "", },
  { mcc_mnc: ["310-430"], operatore: "GCI Communications Corp.", img: "https://www.gci.com/-/media/images/gci/global/header/gci_logo_same_dimension_bottom_aligned.png?h=60&iar=0&mh=60&w=80&hash=29DD92F2DCE81D0F33229F43F758E2A9", },
  { mcc_mnc: ["311-370"], operatore: "General Communication Inc.", img: "http], //www.generalcommunicationsrl.it/wp-content/uploads/2016/05/logo-trasparente-general-communication.gif", },
  { mcc_mnc: ["311-900", "312-870"], operatore: "GigSky", img: "https://static.wixstatic.com/media/58c0c3_4295422bbcf948a083c3f67019d053a9~mv2.png/v1/crop/x_0,y_0,w_553,h_123/fill/w_296,h_70,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/gs_banner_white_logo.png", },
  { mcc_mnc: ["312-790"], operatore: "Gila Electronics", img: "http], //gilaelectronics.com/wp-content/uploads/2017/10/GE-white-clear.png", },
  { mcc_mnc: ["310-970"], operatore: "Globalstar", img: "https://www.globalstar.com/Globalstar/media/Globalstar/Images/Logos/GlobalstarEmblemLogo.png", },
  { mcc_mnc: ["311-890"], operatore: "Globecomm Network Services Corporation", img: "", },
  { mcc_mnc: ["312-620"], operatore: "GlobeTouch Inc.", img: "https://www.airlinq.com/wp-content/uploads/2020/03/PNG-FINAL-EXPORT-1-1536x1070-1.png", },
  { mcc_mnc: ["312-710"], operatore: "Great North Woods Wireless LLC", img: "", },
  { mcc_mnc: ["312-550"], operatore: "Great Plains Communications, Inc.", img: "	https://gpcom.com/wp-content/uploads/2019/06/gpc-logo-1000.svg", },
  { mcc_mnc: ["310-33"], operatore: "Guam Telephone Authority", img: "Guam Telephone Authority", },
  { mcc_mnc: ["311-680"], operatore: "GreenFly LLC", img: "https://www.greenfly.com/wp-content/themes/greenfly/assets/images/greenfly-logo.svg#logo", },
  { mcc_mnc: ["313-290"], operatore: "Gulf Coast Broadband LLC", img: "	https://vportal.visp.net/gulfcoastwifi/wp-content/…/sites/137/2017/09/Web_Size_Transparent_Bkgrd.png", },
  { mcc_mnc: ["312-930"], operatore: "Hewlett-Packard Communication Services  LLC", img: "", },
  { mcc_mnc: ["310-540"], operatore: "Hilliary Communications", img: "	https://hilliary.com/wp-content/uploads/2022/08/hill_logo_square.png", },
  { mcc_mnc: ["313-420"], operatore: "Hudson Valley Wireless", img: "https://www.hvwisp.com/wp-content/uploads/2021/02/cropped-HVW-Logo-400x100-Trans-1.png", },
  { mcc_mnc: ["311-340"], operatore: "Illinois Valley Cellular", img: "https://i0.wp.com/www.ivcel.com/wp-content/uploads/2019/06/ivc_newweblogo.png?fit=800%2C168&ssl=1", },
  { mcc_mnc: ["313-250"], operatore: "Imperial County Office of Education", img: "https://www.icoe.org/themes/icoe/icoe-logo.png", },
  { mcc_mnc: ["312-20"], operatore: "Infrastructure Networks  LLC", img: "https://inetlte.com/wp-content/themes/inet/img/inet.svg", },
  { mcc_mnc: ["310-580"], operatore: "Inland Cellular Telephone Company", img: "https://inlandcellular.com/wp-content/uploads/2020/07/IC-Logo-Flat-large.png", },
  { mcc_mnc: ["312-970"], operatore: "IOSAZ Intellectual Property LLC", img: "", },
  { mcc_mnc: ["311-410", "312-170"], operatore: "Iowa RSA No. 2 LP", img: "	https://rsaorg.com/wp-content/uploads/2017/10/RSALogo_SmallDark.png", },
  { mcc_mnc: ["310-770", "310-530"], operatore: "Iowa Wireless Services LLC", img: "	https://upload.wikimedia.org/wikipedia/en/thumb/b/bf/IWireless_logo.png/220px-IWireless_logo.png", },
  { mcc_mnc: ["310-300"], operatore: "iSmart Mobile  LLC", img: "", },
  { mcc_mnc: ["310-32"], operatore: "IT&E Overseas  Inc", img: "https://store.ite.net/wp-content/uploads/2020/09/ITE-LOGO.png", },
  { mcc_mnc: ["310-920"], operatore: "James Valley Wireless  LLC", img: "https://jamesvalley.com/wp-content/uploads/2021/03/jvcameraready_black_web.png", },
  { mcc_mnc: ["310-650"], operatore: "Jasper Technologies", img: "", },
  { mcc_mnc: ["310-870"], operatore: "Kaplan Telephone Company", img: "https://cdn.shopify.com/s/files/1/2078/7729/files/kaptellogowithwebsite3_550x.png?v=1613733865", },
  { mcc_mnc: ["312-740"], operatore: "KDDI America  Inc.", img: "https://us.kddi.com/files/user/common/img/header/KDDI_America.png", },
  { mcc_mnc: ["312-460"], operatore: "Ketchikan Public Utilities (KPU)", img: "", },
  { mcc_mnc: ["313-280"], operatore: "King Street Wireless  LP", img: "https://broadbandnow.com/images/King-Street-Wireless-5727.png", },
  { mcc_mnc: ["312-830"], operatore: "Kings County Office of Education", img: "https://www.kingscoe.org/cms/lib/CA50000415/Centri…late/GlobalAssets/images///logos/KCOE_280x280.png", },
  { mcc_mnc: ["311-310"], operatore: "Leaco Rural Telephone Company Inc.", img: "https://img1.wsimg.com/isteam/ip/44d07865-8641-4d93-8ef6-53f716be49c9/Capture-35d86a1.PNG/], /rs=w], 481,h], 200,cg], true,m/cr=w], 481,h], 200/qt=q], 95", },
  { mcc_mnc: ["311-510", "311-130", "311-160", "311-520"], operatore: "Lightsquared L.P.", img: "https://en.wikipedia.org/wiki/File], Ligado_Networks_logo.png", },
  { mcc_mnc: ["311-980"], operatore: "LigTel Communications", img: "https://www.ligtel.com/images/LigTel-Logo.png", },
  { mcc_mnc: ["312-180", "310-340", "310-690", "311-600"], operatore: "Limitless Mobile  LLC", img: "https://limitlessmobile.com/content/limitless-logo.png", },
  { mcc_mnc: ["310-760"], operatore: "Lynch 3G Communications Corporation", img: "", },
  { mcc_mnc: ["312-960"], operatore: "M&A Technology  Inc.", img: "https://www.macomp.com/wp-content/uploads/thegem-logos/logo_a5e80175885aa73a037789ed1de97f1c_1x.png", },
  { mcc_mnc: ["311-720"], operatore: "MainePCS LLC", img: "", },
  { mcc_mnc: ["313-50"], operatore: "Manti Tele Communications Company  Inc.", img: "https://www.manti.com/wp-content/uploads/MTCC-Logo-2017-W-out_Connecting-White-e1497277684551.png", },
  { mcc_mnc: ["312-980"], operatore: "Mark Twain Communications Company", img: "https://portal.marktwain.net/wp-content/uploads/2017/11/mt-logo-234x74.png", },
  { mcc_mnc: ["310-40", "312-340"], operatore: "Matanuska Telephone Association  Inc.", img: "", },
  { mcc_mnc: ["313-200"], operatore: "Mercury Network Corporation", img: "https://www.mercury.net/img/logo.png", },
  { mcc_mnc: ["311-660"], operatore: "Metro by T-Mobile", img: "https://www.metrobyt-mobile.com/content/dam/digx/m…n/branding/logos/corporate/metro-d-light-2022.svg", },
  { mcc_mnc: ["312-400"], operatore: "Mid-Rivers Telephone Cooperative", img: "https://midrivers.com/wp-content/uploads/2020/04/MR-FullColor.png", },
  { mcc_mnc: ["311-00"], operatore: "Mid-Tex Cellular Ltd.", img: "", },
  { mcc_mnc: ["311-700", "313-70"], operatore: "Midwest Network Solutions Hub LLC", img: "", },
  { mcc_mnc: ["313-550"], operatore: "Mile High Networks LLC", img: "https://static.wixstatic.com/media/8a2422_7cf6dd19…66_1.00_0.01,enc_auto/MileHighNetworks_edited.png", },
  { mcc_mnc: ["311-20", "311-920"], operatore: "Missouri RSA 5 Partnership", img: "", },
  { mcc_mnc: ["313-460"], operatore: "Mobi  Inc.", img: "", },
  { mcc_mnc: ["312-580"], operatore: "Morgan  Lewis & Bockius LLP", img: "https://www.morganlewis.com/-/media/starterkit/ml-logo_white_281x45.png?rev=f51b3f514b9c4a00a26938252dcfd715", },
  { mcc_mnc: ["311-500"], operatore: "Mosaic Telecom", img: "https://experiencemosaic.com/wp-content/uploads/2022/09/Mosaic-Logo-Horizontal-210.png", },
  { mcc_mnc: ["312-330"], operatore: "Nemont Communications  Inc.", img: "", },
  { mcc_mnc: ["310-290"], operatore: "NEP Cellcorp Inc.", img: "", },
  { mcc_mnc: ["312-630"], operatore: "NetGenuity  Inc.", img: "http], //netgenuity.com/wp-content/uploads/2016/08/cropped-NetGenuity-Logo-Small.jpg", },
  { mcc_mnc: ["311-380"], operatore: "New Dimension Wireless Ltd.", img: "", },
  { mcc_mnc: ["310-100"], operatore: "New Mexico RSA 4 East LP", img: "", },
  { mcc_mnc: ["310-600"], operatore: "NewCell Inc.", img: "", },
  { mcc_mnc: ["310-460", "311-530"], operatore: "NewCore Wireless LLC", img: "https://www.newcore.ca/images/cmp_logo_fin.jpg", },
  { mcc_mnc: ["311-100", "312-420"], operatore: "Nex-Tech Wireless", img: "https://www.nex-techwireless.com/assets/images/layout/logo.svg", },
  { mcc_mnc: ["316-10"], operatore: "Nextel Communications", img: "https://en.wikipedia.org/wiki/File], Nextel_logo.svg", },
  { mcc_mnc: ["311-300"], operatore: "Nexus Communications  Inc.", img: "https://nexuscommunications.com/wp-content/uploads/2020/12/Nexus-logo-reverse.svg", },
  { mcc_mnc: ["312-560"], operatore: "NHLT Inc.", img: "", },
  { mcc_mnc: ["313-540"], operatore: "Nokia Innovations US LLC", img: "", },
  { mcc_mnc: ["311-610", "312-230"], operatore: "North Dakota Network Co.", img: "https://www.ndit.nd.gov/sites/www/files/styles/logo/public/documents/theme/Information-Technology-SWP-white-reverse.png?itok=Ymn_Jc-m", },
  { mcc_mnc: ["310-17"], operatore: "North Sight Communications Inc.", img: "https://northsightpr.com/wp-content/uploads/2020/05/NS-left-Color.png", },
  { mcc_mnc: ["311-710", "312-920"], operatore: "Northeast Wireless Networks LLC", img: "https://image.pitchbook.com/7ZL4PB5SBTHkQe2We16bIZlXCHO1621240848777_200x200", },
  { mcc_mnc: ["312-590"], operatore: "Northern Michigan University", img: "", },
  { mcc_mnc: ["311-420", "312-150"], operatore: "Northwest Missouri Cellular LP", img: "https://www.nwmcell.com/themes/custom/rye/images/logo-nwmcell.png", },
  { mcc_mnc: ["310-510", "311-840", "311-850"], operatore: "Nsight", img: "https://www.nsight.com/images/nsight-logo.png", },
  { mcc_mnc: ["310-620"], operatore: "Nsighttel Wireless LLC", img: "https://www.nsighttel.com/wp-content/uploads/2020/04/Header-Nsight-Logo-1.svg", },
  { mcc_mnc: ["312-610", "312-660"], operatore: "nTelos Wireless", img: "https://en.wikipedia.org/wiki/File], NTelos_Logo.svg", },
  { mcc_mnc: ["310-370", "310-470"], operatore: "NTT DoCoMo Pacific", img: "", },
  { mcc_mnc: ["313-40"], operatore: "Nucla-Naturita Telephone Company", img: "https://www.nntc.bz/wp-content/uploads/2020/04/NNTC-logo.jpg", },
  { mcc_mnc: ["310-440", "310-640"], operatore: "Numerex", img: "", },
  { mcc_mnc: ["312-850"], operatore: "Onvoy Spectrum  LLC", img: "", },
  { mcc_mnc: ["313-380"], operatore: "OptimERA Inc.", img: "", },
  { mcc_mnc: ["311-560"], operatore: "OTZ Communications  Inc.", img: "https://www.nwarctic.org/cms/lib/AK01001584/Centri…//logos/Atautchikun%20district%20logo%20small.png", },
  { mcc_mnc: ["310-810"], operatore: "Pacific Lightwave Inc.", img: "https://www.paclw.com/wp-content/uploads/2017/11/logo.png", },
  { mcc_mnc: ["313-320"], operatore: "Paladin Wireless", img: "https://www.paladinwireless.com/files/6614/3628/9559/logo.png", },
  { mcc_mnc: ["311-450"], operatore: "Panhandle Telecommunication Systems Inc.", img: "https://www.ptci.net/wp-content/uploads/2021/03/logo-ptci@2x.png", },
  { mcc_mnc: ["313-410"], operatore: "pdvWireless", img: "https://bearcom.com/static/frontend/Bearcom/ninth/en_US/images/imgpsh_fullsizeanim.png", },
  { mcc_mnc: ["311-670"], operatore: "Pine Belt Cellular Inc.", img: "https://static.wixstatic.com/media/d3cecc_6e03defe…o/d3cecc_6e03defee4ae4476b84ac8b489167bfc~mv2.png", },
  { mcc_mnc: ["311-80"], operatore: "Pine Telephone Company", img: "", },
  { mcc_mnc: ["310-790", "311-290"], operatore: "PinPoint Communications Inc.", img: "https://static.wixstatic.com/media/c42204_dcb00a1f…c,q_85,usm_0.66_1.00_0.01,enc_auto/pnpt_black.png", },
  { mcc_mnc: ["312-990"], operatore: "Premier Holdings LLC", img: "", },
  { mcc_mnc: ["311-540", "311-730"], operatore: "Proximiti Mobility Inc.", img: "http], //www.proximiti.com/images/proximiti_logo_white.jpg", },
  { mcc_mnc: ["310-110"], operatore: "PTI Pacifica Inc.", img: "", },
  { mcc_mnc: ["310-500"], operatore: "Public Service Cellular Inc.", img: "", },
  { mcc_mnc: ["313-510"], operatore: "Puerto Rico Telecom Company", img: "", },
  { mcc_mnc: ["313-490"], operatore: "Puloli  Inc.", img: "https://puloli.com/wp-content/uploads/2020/04/Puloli_logo_2020.svg", },
  { mcc_mnc: ["313-480"], operatore: "Ready Wireless  LLC", img: "https://readywireless.com/wp-content/uploads/2023/01/cropped-ReadyIoT_LOGO_white-100x39.png", },
  { mcc_mnc: ["313-370"], operatore: "Red Truck Wireless  LLC", img: "", },
  { mcc_mnc: ["312-780"], operatore: "Redzone Wireless", img: "https://www.redzonewireless.com/img/redzone_broadband-for-me.svg", },
  { mcc_mnc: ["314-100", "314-190"], operatore: "Reserved for Public Safety", img: "", },
  { mcc_mnc: ["311-430"], operatore: "RSA 1 LP", img: "", },
  { mcc_mnc: ["312-160"], operatore: "RSA1 Limited Partnership", img: "", },
  { mcc_mnc: ["312-320"], operatore: "RTC Communications LLC", img: "https://www.rtccom.com/wp-content/uploads/2022/01/RTC-Logo-V01-Variations-07-copy.png", },
  { mcc_mnc: ["311-350", "312-480", "312-600", "312-640"], operatore: "Sagebrush Cellular  Inc.", img: "", },
  { mcc_mnc: ["313-470"], operatore: "San Diego Gas & Electric Company", img: "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d9/Sdgenewlogo.png/220px-Sdgenewlogo.png", },
  { mcc_mnc: ["312-820"], operatore: "Santel Communications Cooperative  Inc.", img: "", },
  { mcc_mnc: ["313-500"], operatore: "Shelcomm  Inc.", img: "", },
  { mcc_mnc: ["311-910"], operatore: "SI Wireless LLC", img: "https://www.systemsintegrated.com/assets/images/si-logo-new-big-transparent-tight-552x128.png", },
  { mcc_mnc: ["312-430"], operatore: "Silver Star Communications", img: "https://www.silverstar.com/wp-content/uploads/2020/09/SSLogo-no-tagline.svg", },
  { mcc_mnc: ["310-320"], operatore: "Smith Bagley  Inc.", img: "", },
  { mcc_mnc: ["311-820"], operatore: "Sonus Networks", img: "https://ribboncommunications.com/themes/custom/ribbon2020/images/ribbon-logo-color-1.svg", },
  { mcc_mnc: ["312-80", "312-840"], operatore: "South Georgia Regional Information Technology Authority", img: "", },
  { mcc_mnc: ["310-15", "312-720", "316-11"], operatore: "Southern Communications", img: "https://www.southern-comms.co.uk/wp-content/themes/southerncomms-scl/img/logo-nov-22.svg", },
  { mcc_mnc: ["311-60"], operatore: "Space Data Corporation", img: "https://spacedata.net/wp-content/uploads/2020/05/logo.png", },
  { mcc_mnc: ["313-450"], operatore: "Spectrum Wireless Holdings  LLC", img: "", },
  { mcc_mnc: ["313-80"], operatore: "Speedwavz LLP", img: "https://speedwavz.net/wp-content/uploads/2019/05/logo.png", },
  { mcc_mnc: ["310-53", "310-120", "310-830", "311-260", "311-490", "311-870", "311-880", "311-882", "311-940", "312-190", "312-240", "312-250", "312-530"], operatore: "Sprint", img: "https://www.sistemaproprietaintellettuale.it/public/img/Sprint.png", },
  { mcc_mnc: ["311-640"], operatore: "Standing Rock Telecommunications", img: "https://lirp.cdn-website.com/bced3c6c/dms3rep/multi/opt/Standing-Rock-Telecom-222w.png", },
  { mcc_mnc: ["311-360"], operatore: "Stelera Wireless", img: "https://www.americanlegal.com/theme/americanlegalclaims/images/american_legal_button.png", },
  { mcc_mnc: ["310-550", "310-720"], operatore: "Syniverse Technologies", img: "", },
  { mcc_mnc: ["311-930"], operatore: "Syringa Wireless", img: "https://uploads-ssl.webflow.com/61a50738b192e1672c7d9e24/61b4e29e1e0ca88604a97042_Syringa_Networks_logo_white.png", },
  { mcc_mnc: ["310-200", "310-210", "310-220", "310-230", "310-240", "310-250", "310-270", "310-310", "310-490", "310-800", "310-160", "310-260"], operatore: "T-Mobile USA", img: "https://www.t-mobile.com/content/dam/t-mobile/ntm/branding/logos/corporate/tmo-logo-v4.svg", },
  { mcc_mnc: ["311-170"], operatore: "Tampnet", img: "https://www.tampnet.com/hubfs/Profile/logo-tampnet-white.svg", },
  { mcc_mnc: ["311-740"], operatore: "Telalaska Cellular", img: "https://www.telalaska.com/wp-content/uploads/2022/08/thumbnail_image005.jpg", },
  { mcc_mnc: ["311-690"], operatore: "TeleBEEPER of New Mexico", img: "", },
  { mcc_mnc: ["310-840", "312-300"], operatore: "Telecom North America Mobile  Inc.", img: "https://en.wikipedia.org/wiki/File], 2021.04.30-Telna-Logo-360x360.svg", },
  { mcc_mnc: ["310-140"], operatore: "Teleguam Holdings  LLC", img: "https://en.wikipedia.org/wiki/File], GTA_Teleguam_logo.svg", },
  { mcc_mnc: ["311-210"], operatore: "Telnyx LLC", img: "", },
  { mcc_mnc: ["313-00"], operatore: "Tennessee Wireless", img: "http], //tennesseewireless.com/wp-content/uploads/2013/11/250-x-68-Banner.jpg", },
  { mcc_mnc: ["311-620"], operatore: "TerreStar Networks  Inc.", img: "", },
  { mcc_mnc: ["312-110"], operatore: "Texas Energy Network LLC", img: "", },
  { mcc_mnc: ["310-980"], operatore: "Texas RSA 7B3", img: "https://rsaorg.com/wp-content/uploads/2017/10/RSALogo_SmallDark.png", },
  { mcc_mnc: ["313-400"], operatore: "Texoma Communications  LLC", img: "", },
  { mcc_mnc: ["312-690"], operatore: "TGS  LLC", img: "", },
  { mcc_mnc: ["311-50", "311-830"], operatore: "Thumb Cellular LP", img: "", },
  { mcc_mnc: ["310-520"], operatore: "Transaction Network Services", img: "https://tnsi.com/wp-content/uploads/2022/09/brand.svg", },
  { mcc_mnc: ["313-560"], operatore: "Transit Wireless LLC", img: "https://transitwireless.com/wp-content/uploads/cropped-TW-logo-2@4x.png", },
  { mcc_mnc: ["312-350", "312-730"], operatore: "Triangle Communication System Inc.", img: "https://www.itstriangle.com/images/trianglelogosvg.svg", },
  { mcc_mnc: ["312-490"], operatore: "TrustComm  Inc.", img: "https://files.spazioweb.it/3e/49/3e49cbf5-3f07-44a5-a51e-e03bc9cd4781.png", },
  { mcc_mnc: ["310-860"], operatore: "TX RSA 15B2  LP", img: "", },
  { mcc_mnc: ["310-570"], operatore: "TX-10  LLC and Central Louisiana Cellular  LLC (MTPCS)", img: "", },
  { mcc_mnc: ["310-390"], operatore: "TX-11 Acquisition  LLC", img: "", },
  { mcc_mnc: ["310-940"], operatore: "Tyntec Inc.", img: "https://www.tyntec.com/themes/custom/tyntec/image/tyntec_logo_large.svg", },
  { mcc_mnc: ["310-66", "310-730", "311-220", "311-580"], operatore: "U.S. Cellular", img: "https://it.wikipedia.org/wiki/File], Uscellular_logo20.png", },
  { mcc_mnc: ["310-960"], operatore: "UBET Wireless", img: "", },
  { mcc_mnc: ["311-860", "312-290"], operatore: "Uintah Basin Electronic Telecommunications", img: "", },
  { mcc_mnc: ["310-20"], operatore: "Union Telephone Company", img: "https://uniontel.net/wp-content/uploads/2020/07/Union-Tel-logo.png", },
  { mcc_mnc: ["311-650"], operatore: "United Wireless", img: "https://getunited.com/wp-content/uploads/2022/01/United-Logo.png", },
  { mcc_mnc: ["313-230"], operatore: "Velocity Communications Inc.", img: "https://www.velotech.net/images/logo.png", },
  { mcc_mnc: ["310-4", "310-5", "310-6", "310-10", "310-12", "310-13", "310-350", "310-590", "310-820", "310-890", "310-910", "311-12", "311-110", "311-270", "311-271", "311-272", "311-273", "311-274", "311-275", "311-276", "311-277", "311-278", "311-279", "311-280", "311-281", "311-282", "311-283", "311-284", "311-285", "311-286", "311-287", "311-288", "311-289", "311-390", "311-480", "311-481", "311-482", "311-483", "311-484", "311-485", "311-486", "311-487", "311-488", "311-489", "311-590", "312-770", "310-450", "310-740"], operatore: "Verizon Communications", img: "https://upload.wikimedia.org/wikipedia/commons/8/81/Verizon_2015_logo_-vector.svg", },
  { mcc_mnc: ["311-470"], operatore: "Vitelcom Cellular Inc.", img: "", },
  { mcc_mnc: ["313-90"], operatore: "Vivint Wireless  Inc.", img: "", },
  { mcc_mnc: ["312-200"], operatore: "Voyager Mobility LLC", img: "https://images.squarespace-cdn.com/content/v1/617031493dc8bb165d9600aa/339c2053-c859-4cfc-bfbf-795e0d88878e/VGM%2BNew%2Blogo%2Bgreen%2Bno%2Btext.png?format=1500w", },
  { mcc_mnc: ["311-990"], operatore: "VTel Wireless", img: "https://www.vtelwireless.com/wp-content/uploads/2018/09/vtel-logo.png", },
  { mcc_mnc: ["310-400", "310-480", "311-120", "311-250"], operatore: "Wave Runner LLC", img: "", },
  { mcc_mnc: ["312-940"], operatore: "Webformix", img: "https://www.webformix.com/wp-content/uploads/2021/05/clean-horizonal-logo-small.png", },
  { mcc_mnc: ["312-360"], operatore: "Wes-Tex Telecommunications  Ltd.", img: "https://westex.coop/wp-content/uploads/2020/09/logo-160x62.png", },
  { mcc_mnc: ["310-180"], operatore: "West Central Wireless", img: "https://www.westcentral.com/images/West-Central.png", },
  { mcc_mnc: ["311-150"], operatore: "Wilkes Cellular", img: "", },
  { mcc_mnc: ["310-330", "312-700"], operatore: "Wireless Partners  LLC", img: "https://www.wireless-partnersllc.com/wp-content/themes/wireless_partners_theme/assets/images/wireless-partners-logo.png", },
  { mcc_mnc: ["312-510"], operatore: "WUE Inc.", img: "", },
  { mcc_mnc: ["310-660"], operatore: "", img: "", },
  // australia
  { mcc_mnc: ["50501", "50511", "50539", "50571", "50572"], operatore: "Telstra", img: "https://upload.wikimedia.org/wikipedia/commons/0/0b/Telstra_logo_2023.svg", },
  { mcc_mnc: ["50502", "50590"], operatore: "Optus", img: "https://www.ilcittadinomb.it/wp-content/uploads/2022/09/image-227.png", },
  { mcc_mnc: ["50503", "50506", "50507", "50512"], operatore: "Vodafone", img: "https://www.vodafone.com.au/images/icons/VF-logo-desktop.svg", },
  { mcc_mnc: ["50519"], operatore: "Lycamobile", img: "https://cms-assets.ldsvcplatform.com/IRE/s3fs-public/2023-09/home_logo.png" },
  // colombia
  { mcc_mnc: ["732-101"], operatore: "Claro", img: "https://www.claro.com.co/portal/co/recursos_contenido/1704216919651-1661457416868-claro-musica.png" },
  { mcc_mnc: ["732-123"], operatore: "Movistar", img: "https://www.movistar.com.co/assets/img/brand-movistar.svg" },
  { mcc_mnc: ["732-103", "732-165"], operatore: "Tigo", img: "https://upload.wikimedia.org/wikipedia/commons/thumb/a/a2/Tigolatam.png/800px-Tigolatam.png" },
  { mcc_mnc: ["732-360"], operatore: "Wom", img: "https://upload.wikimedia.org/wikipedia/commons/thumb/f/f4/WOM_logo.png/640px-WOM_logo.png" },
  { mcc_mnc: ["732-154"], operatore: "Virgi Mobile", img: "https://cdn1.virginmobile.co/wp-content/uploads/wpfiles/20220215212620/logo-vmco-1.svg" },
  { mcc_mnc: ["732-187"], operatore: "ETB", img: "https://upload.wikimedia.org/wikipedia/commons/thumb/3/30/ETB_Bogot%C3%A1_logo.svg/440px-ETB_Bogot%C3%A1_logo.svg.png" },
  // croazia
  { mcc_mnc: ["219-01"], operatore: "T-Mobile", img: "https://www.t-mobile.com/content/dam/t-mobile/ntm/branding/logos/corporate/tmo-logo-v4.svg" },
  { mcc_mnc: ["219-02"], operatore: "Telemach/Tele2", img: "https://telemach.hr/img/icons/logo.svg" },
  { mcc_mnc: ["219-10"], operatore: "A1 / VIP", img: "https://cdn.a1.rs/a1website/img/a1/header/logo.png" },
  { mcc_mnc: ["219-12"], operatore: "Tele Focus", img: "https://www.croatia.biz/images/logo.png" },
  { mcc_mnc: ["219-299"], operatore: "Failed Calls", img: "https://static.thenounproject.com/png/2203911-200.png" },
  { mcc_mnc: ["219-999"], operatore: "Fix Line", img: "" },
  // Lettonia
  { mcc_mnc: ["247-01", "247-10"], operatore: "LMT", img: "https://upload.wikimedia.org/wikipedia/en/2/26/Latvijas_Mobilais_Telefons_logo_2013.png?20170721064336" },
  { mcc_mnc: ["247-02"], operatore: "Tele2", img: "https://www.tele2.lv/img/cybot/logo.gif" },
  { mcc_mnc: ["247-05"], operatore: "Bite", img: "https://upload.wikimedia.org/wikipedia/lt/3/32/Bit%C4%97_logo_2019.svg" }
];

const vendors = [
  { max_prefix: "28-6F-B9", vendor: "Nokia Shanghai Bell Co., Ltd." },
  {max_prefix: "08-EA-44",vendor: "Extreme Networks Headquarters"},
  {max_prefix: "F4-EA-B5",vendor: "Extreme Networks Headquarters"},
  {max_prefix: "B8-7C-F2",vendor: "Extreme Networks Headquarters"},
  {max_prefix: "E0-A1-29",vendor: "Extreme Networks Headquarters"},
  {max_prefix: "A8-C6-47",vendor: "Extreme Networks Headquarters"},
  {max_prefix: "A4-73-AB",vendor: "Extreme Networks Headquarters"},
  {max_prefix: "0C-9B-78",vendor: "Extreme Networks Headquarters"},
  {max_prefix: "A4-C7-F6",vendor: "Extreme Networks Headquarters"},
  {max_prefix: "B4-2D-56",vendor: "Extreme Networks Headquarters"},
  {max_prefix: "88-7E-25",vendor: "Extreme Networks Headquarters"},
  {max_prefix: "00-19-77",vendor: "Extreme Networks Headquarters"},
  {max_prefix: "38-E2-CA",vendor: "Katun Corporation"},
  {max_prefix: "B0-5B-99",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "E8-0A-B9",vendor: "Cisco Systems, Inc"},
  {max_prefix: "78-46-5F",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "90-75-DE",vendor: "Zebra Technologies Inc."},
  {max_prefix: "80-BA-16",vendor: "Micas Networks Inc."},
  {max_prefix: "10-E9-92",vendor: "INGRAM MICRO SERVICES"},
  {max_prefix: "78-F2-76",vendor: "Cyklop Fastjet Technologies (Shanghai) Inc."},
  {max_prefix: "20-F8-3B",vendor: "Nabu Casa, Inc."},
  {max_prefix: "F0-1B-24",vendor: "zte corporation"},
  {max_prefix: "B8-4C-87",vendor: "IEEE Registration Authority"},
  {max_prefix: "E4-F1-4C",vendor: "Private"},
  {max_prefix: "10-06-1C",vendor: "Espressif Inc."},
  {max_prefix: "E0-06-30",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "04-D1-68",vendor: "Sunplus Technology Co., Ltd."},
  {max_prefix: "64-4C-69",vendor: "CONPROVE"},
  {max_prefix: "DC-D1-60",vendor: "Tianjin Changdatong Technology Co.,LTD"},
  {max_prefix: "64-4E-D7",vendor: "HP Inc."},
  {max_prefix: "9C-E3-30",vendor: "Cisco Meraki"},
  {max_prefix: "04-A9-59",vendor: "New H3C Technologies Co., Ltd"},
  {max_prefix: "0C-B9-83",vendor: "Honor Device Co., Ltd."},
  {max_prefix: "2C-B3-01",vendor: "Honor Device Co., Ltd."},
  {max_prefix: "00-0E-72",vendor: "Arca Technologies S.r.l."},
  {max_prefix: "E0-C2-B7",vendor: "Masimo Corporation"},
  {max_prefix: "C8-8B-E8",vendor: "Masimo Corporation"},
  {max_prefix: "E4-C7-67",vendor: "Intel Corporate"},
  {max_prefix: "90-2D-77",vendor: "Edgecore Americas Networking Corporation"},
  {max_prefix: "6C-FB-ED",vendor: "GN Audio A/S"},
  {max_prefix: "E4-F2-7C",vendor: "Juniper Networks"},
  {max_prefix: "DC-7C-F7",vendor: "China Mobile Group Device Co.,Ltd."},
  {max_prefix: "90-6C-4B",vendor: "Advance Security Electronics"},
  {max_prefix: "D4-8A-FC",vendor: "Espressif Inc."},
  {max_prefix: "E4-65-B8",vendor: "Espressif Inc."},
  {max_prefix: "E0-01-C7",vendor: "Hui Zhou Gaoshengda Technology Co.,LTD"},
  {max_prefix: "B4-DF-91",vendor: "Cisco Meraki"},
  {max_prefix: "98-EE-8C",vendor: "zte corporation"},
  {max_prefix: "FC-50-0C",vendor: "Sitehop Ltd"},
  {max_prefix: "50-E4-78",vendor: "Sichuan AI-Link Technology Co., Ltd."},
  {max_prefix: "90-C7-10",vendor: "zte corporation"},
  {max_prefix: "48-D4-75",vendor: "Lampuga GmbH"},
  {max_prefix: "60-1A-C7",vendor: "Nintendo Co.,Ltd"},
  {max_prefix: "48-DA-35",vendor: "IEEE Registration Authority"},
  {max_prefix: "A8-81-FE",vendor: "Luxul Tech Co., Ltd"},
  {max_prefix: "64-9A-08",vendor: "Shenzhen SuperElectron Technology Co.,Ltd."},
  {max_prefix: "50-F2-22",vendor: "EM Microelectronic"},
  {max_prefix: "30-7A-57",vendor: "Accuenergy (CANADA) Inc"},
  {max_prefix: "CC-EB-5E",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "60-C7-8D",vendor: "Juniper Networks"},
  {max_prefix: "DC-51-93",vendor: "zte corporation"},
  {max_prefix: "74-78-A6",vendor: "Fortinet, Inc."},
  {max_prefix: "00-19-82",vendor: "SmarDTV Corporation"},
  {max_prefix: "50-FE-0C",vendor: "AzureWave Technology Inc."},
  {max_prefix: "BC-FD-0C",vendor: "Shenzhen Phaten Tech. LTD"},
  {max_prefix: "18-65-C7",vendor: "Dongguan YIMO Technology Co.LTD"},
  {max_prefix: "0C-01-C8",vendor: "DENSO Co.,Ltd"},
  {max_prefix: "F4-2E-48",vendor: "zte corporation"},
  {max_prefix: "AC-D7-5B",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "B4-C2-E0",vendor: "Bouffalo Lab (Nanjing) Co., Ltd."},
  {max_prefix: "04-09-86",vendor: "Arcadyan Corporation"},
  {max_prefix: "48-1B-A4",vendor: "Cisco Systems, Inc"},
  {max_prefix: "F0-EE-7A",vendor: "Apple, Inc."},
  {max_prefix: "20-3A-EB",vendor: "zte corporation"},
  {max_prefix: "58-76-B3",vendor: "Ubee Interactive Co., Limited"},
  {max_prefix: "E8-70-72",vendor: "Hangzhou BroadLink Technology Co.,Ltd"},
  {max_prefix: "D8-DA-F1",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "54-44-3B",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "5C-70-75",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "CC-03-FA",vendor: "Vantiva USA LLC"},
  {max_prefix: "48-F7-C0",vendor: "Vantiva USA LLC"},
  {max_prefix: "48-00-33",vendor: "Vantiva USA LLC"},
  {max_prefix: "14-98-7D",vendor: "Vantiva USA LLC"},
  {max_prefix: "58-23-8C",vendor: "Vantiva USA LLC"},
  {max_prefix: "80-B2-34",vendor: "Vantiva USA LLC"},
  {max_prefix: "84-17-EF",vendor: "Vantiva USA LLC"},
  {max_prefix: "A4-56-CC",vendor: "Vantiva USA LLC"},
  {max_prefix: "70-03-7E",vendor: "Vantiva USA LLC"},
  {max_prefix: "44-1C-12",vendor: "Vantiva USA LLC"},
  {max_prefix: "5C-76-95",vendor: "Vantiva USA LLC"},
  {max_prefix: "AC-4C-A5",vendor: "Vantiva USA LLC"},
  {max_prefix: "00-CB-7A",vendor: "Vantiva USA LLC"},
  {max_prefix: "F8-D2-AC",vendor: "Vantiva USA LLC"},
  {max_prefix: "CC-F3-C8",vendor: "Vantiva USA LLC"},
  {max_prefix: "40-F3-B0",vendor: "Texas Instruments"},
  {max_prefix: "14-9C-EF",vendor: "Texas Instruments"},
  {max_prefix: "80-C4-1B",vendor: "Texas Instruments"},
  {max_prefix: "34-68-B5",vendor: "Texas Instruments"},
  {max_prefix: "58-AD-12",vendor: "Apple, Inc."},
  {max_prefix: "60-FD-A6",vendor: "Apple, Inc."},
  {max_prefix: "80-A9-97",vendor: "Apple, Inc."},
  {max_prefix: "34-8C-5E",vendor: "Apple, Inc."},
  {max_prefix: "D0-B2-C4",vendor: "Vantiva USA LLC"},
  {max_prefix: "80-C6-AB",vendor: "Vantiva USA LLC"},
  {max_prefix: "38-3F-B3",vendor: "Vantiva USA LLC"},
  {max_prefix: "64-12-36",vendor: "Vantiva USA LLC"},
  {max_prefix: "0C-75-D2",vendor: "Hangzhou Hikvision Digital Technology Co.,Ltd."},
  {max_prefix: "D8-77-66",vendor: "Nurivoice Co., Ltd"},
  {max_prefix: "B8-A7-5E",vendor: "Wuxi Xinjie Electric Co.,Ltd"},
  {max_prefix: "8C-53-E6",vendor: "Wistron Neweb Corporation"},
  {max_prefix: "70-81-85",vendor: "New H3C Technologies Co., Ltd"},
  {max_prefix: "50-D3-3B",vendor: "cloudnineinfo"},
  {max_prefix: "84-C8-A0",vendor: "Hui Zhou Gaoshengda Technology Co.,LTD"},
  {max_prefix: "8C-3B-4A",vendor: "Universal Global Scientific Industrial Co., Ltd."},
  {max_prefix: "A8-8B-28",vendor: "SHENZHEN DIYANG SMART TECHNOLOGY CO.,LTD."},
  {max_prefix: "08-F0-1E",vendor: "eero inc."},
  {max_prefix: "18-A5-FF",vendor: "Arcadyan Corporation"},
  {max_prefix: "8C-1A-F3",vendor: "Shenzhen Gooxi Information Security CO.,Ltd."},
  {max_prefix: "F4-B6-C6",vendor: "Indra Heera Technology LLP"},
  {max_prefix: "6C-03-B5",vendor: "Cisco Systems, Inc"},
  {max_prefix: "58-C4-1E",vendor: "IEEE Registration Authority"},
  {max_prefix: "A8-91-62",vendor: "Sophos Ltd"},
  {max_prefix: "70-98-83",vendor: "SHENZHEN KAYAN ELECTRONICS., LTD."},
  {max_prefix: "F4-3A-7B",vendor: "zte corporation"},
  {max_prefix: "68-9E-29",vendor: "zte corporation"},
  {max_prefix: "E4-40-97",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "DC-B4-CA",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "8C-D5-4A",vendor: "TAIYO YUDEN CO.,LTD"},
  {max_prefix: "A0-02-A5",vendor: "Intel Corporate"},
  {max_prefix: "AC-C0-48",vendor: "OnePlus Technology (Shenzhen) Co., Ltd"},
  {max_prefix: "40-84-32",vendor: "Microchip Technology Inc."},
  {max_prefix: "44-D5-C1",vendor: "EM Microelectronic"},
  {max_prefix: "94-3B-B1",vendor: "Kaon Group Co., Ltd."},
  {max_prefix: "44-F0-34",vendor: "Kaon Group Co., Ltd."},
  {max_prefix: "88-8F-10",vendor: "Shenzhen Max Infinite Technology Co.,Ltd."},
  {max_prefix: "C4-79-9F",vendor: "Haiguang Smart Device Co.,Ltd."},
  {max_prefix: "84-28-59",vendor: "Amazon Technologies Inc."},
  {max_prefix: "50-16-F4",vendor: "Motorola Mobility LLC, a Lenovo Company"},
  {max_prefix: "3C-08-CD",vendor: "Juniper Networks"},
  {max_prefix: "68-50-5D",vendor: "Halo Technologies"},
  {max_prefix: "7C-6A-8A",vendor: "SINOBONDER Technology Co., Ltd."},
  {max_prefix: "90-EC-E3",vendor: "Nokia"},
  {max_prefix: "88-7B-2C",vendor: "zte corporation"},
  {max_prefix: "60-DB-EF",vendor: "Unify Software and Solutions GmbH & Co. KG"},
  {max_prefix: "00-16-F6",vendor: "Nevion"},
  {max_prefix: "40-B7-FC",vendor: "Phyplus Microelectronics Limited"},
  {max_prefix: "68-2C-4F",vendor: "leerang corporation"},
  {max_prefix: "68-DD-B7",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "14-D8-64",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "B8-51-A9",vendor: "Nokia"},
  {max_prefix: "D0-77-CE",vendor: "Edgecore Networks Corporation"},
  {max_prefix: "EC-AF-F9",vendor: "Hailo Technologies Ltd."},
  {max_prefix: "FC-59-C0",vendor: "Arista Networks"},
  {max_prefix: "64-1B-2F",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "9C-73-B1",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "38-8A-06",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "B0-D8-88",vendor: "Panasonic Automotive Systems Co.,Ltd"},
  {max_prefix: "28-EC-22",vendor: "eero inc."},
  {max_prefix: "40-D4-F6",vendor: "Honor Device Co., Ltd."},
  {max_prefix: "7C-31-80",vendor: "SMK corporation"},
  {max_prefix: "90-56-07",vendor: "Sichuan\u00a0AI-Link\u00a0Technology\u00a0Co.,\u00a0Ltd."},
  {max_prefix: "48-31-33",vendor: "Robert Bosch Elektronika Kft."},
  {max_prefix: "B8-AB-61",vendor: "Cisco Meraki"},
  {max_prefix: "40-ED-00",vendor: "TP-Link Corporation Limited"},
  {max_prefix: "78-2D-AD",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "D0-61-58",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "24-4B-F1",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "60-15-2B",vendor: "Palo Alto Networks"},
  {max_prefix: "44-D7-7E",vendor: "Robert Bosch GmbH"},
  {max_prefix: "90-88-55",vendor: "Cisco Systems, Inc"},
  {max_prefix: "68-71-61",vendor: "Cisco Systems, Inc"},
  {max_prefix: "88-3F-37",vendor: "UHTEK CO., LTD."},
  {max_prefix: "00-80-B8",vendor: "DMG MORI Digital Co., LTD"},
  {max_prefix: "28-73-F6",vendor: "Amazon Technologies Inc."},
  {max_prefix: "C4-EB-FF",vendor: "zte corporation"},
  {max_prefix: "48-93-DC",vendor: "UNIWAY INFOCOM PVT LTD"},
  {max_prefix: "5C-E6-88",vendor: "VECOS Europe B.V."},
  {max_prefix: "5C-28-86",vendor: "Inventec(Chongqing) Corporation"},
  {max_prefix: "D4-BA-FA",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "4C-EC-0F",vendor: "Cisco Systems, Inc"},
  {max_prefix: "84-3E-1D",vendor: "Hui Zhou Gaoshengda Technology Co.,LTD"},
  {max_prefix: "E0-CB-1D",vendor: "Amazon Technologies Inc."},
  {max_prefix: "FC-84-A7",vendor: "Murata Manufacturing Co., Ltd."},
  {max_prefix: "3C-CB-4D",vendor: "Avikus Co., Ltd"},
  {max_prefix: "60-1B-52",vendor: "Vodafone Italia S.p.A."},
  {max_prefix: "24-A4-2C",vendor: "NETIO products a.s."},
  {max_prefix: "70-2F-86",vendor: "Marquardt GmbH"},
  {max_prefix: "C0-8F-20",vendor: "Shenzhen Skyworth Digital  Technology  CO., Ltd"},
  {max_prefix: "08-FF-24",vendor: "Shenzhen Skyworth Digital  Technology  CO., Ltd"},
  {max_prefix: "90-B6-7A",vendor: "Shenzhen Skyworth Digital  Technology  CO., Ltd"},
  {max_prefix: "24-9A-C8",vendor: "Shenzhen Skyworth Digital  Technology  CO., Ltd"},
  {max_prefix: "A8-88-1F",vendor: "SERVERCOM (INDIA) PRIVATE LIMITED"},
  {max_prefix: "00-50-79",vendor: "Private"},
  {max_prefix: "28-C0-1B",vendor: "Shenzhen Skyworth Digital  Technology  CO., Ltd"},
  {max_prefix: "E0-28-B1",vendor: "Shenzhen Skyworth Digital  Technology  CO., Ltd"},
  {max_prefix: "30-31-80",vendor: "Shenzhen Skyworth Digital  Technology  CO., Ltd"},
  {max_prefix: "20-15-82",vendor: "Apple, Inc."},
  {max_prefix: "40-92-1A",vendor: "Apple, Inc."},
  {max_prefix: "10-E2-C9",vendor: "Apple, Inc."},
  {max_prefix: "04-36-B8",vendor: "I&C Technology"},
  {max_prefix: "D0-43-1E",vendor: "Dell Inc."},
  {max_prefix: "F0-A0-B1",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "40-4F-42",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "30-86-F1",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "00-C0-4F",vendor: "Dell Inc."},
  {max_prefix: "00-B0-D0",vendor: "Dell Inc."},
  {max_prefix: "00-19-B9",vendor: "Dell Inc."},
  {max_prefix: "00-1A-A0",vendor: "Dell Inc."},
  {max_prefix: "00-25-64",vendor: "Dell Inc."},
  {max_prefix: "A4-BA-DB",vendor: "Dell Inc."},
  {max_prefix: "38-A8-51",vendor: "Quickset Defense Technologies, LLC"},
  {max_prefix: "00-CB-00",vendor: "Private"},
  {max_prefix: "11-00-AA",vendor: "Private"},
  {max_prefix: "9C-93-E4",vendor: "Private"},
  {max_prefix: "78-2B-CB",vendor: "Dell Inc."},
  {max_prefix: "14-FE-B5",vendor: "Dell Inc."},
  {max_prefix: "18-03-73",vendor: "Dell Inc."},
  {max_prefix: "74-86-7A",vendor: "Dell Inc."},
  {max_prefix: "20-47-47",vendor: "Dell Inc."},
  {max_prefix: "00-0B-DB",vendor: "Dell Inc."},
  {max_prefix: "00-12-3F",vendor: "Dell Inc."},
  {max_prefix: "A4-1F-72",vendor: "Dell Inc."},
  {max_prefix: "00-1C-23",vendor: "Dell Inc."},
  {max_prefix: "84-7B-EB",vendor: "Dell Inc."},
  {max_prefix: "98-90-96",vendor: "Dell Inc."},
  {max_prefix: "80-18-44",vendor: "Dell Inc."},
  {max_prefix: "98-40-BB",vendor: "Dell Inc."},
  {max_prefix: "D4-81-D7",vendor: "Dell Inc."},
  {max_prefix: "F8-0D-A9",vendor: "Zyxel Communications Corporation"},
  {max_prefix: "54-BF-64",vendor: "Dell Inc."},
  {max_prefix: "CC-C5-E5",vendor: "Dell Inc."},
  {max_prefix: "4C-D9-8F",vendor: "Dell Inc."},
  {max_prefix: "DC-F4-01",vendor: "Dell Inc."},
  {max_prefix: "6C-2B-59",vendor: "Dell Inc."},
  {max_prefix: "C8-F7-50",vendor: "Dell Inc."},
  {max_prefix: "98-E7-43",vendor: "Dell Inc."},
  {max_prefix: "18-5A-58",vendor: "Dell Inc."},
  {max_prefix: "D0-8E-79",vendor: "Dell Inc."},
  {max_prefix: "B4-45-06",vendor: "Dell Inc."},
  {max_prefix: "48-5A-0D",vendor: "Juniper Networks"},
  {max_prefix: "E0-D8-48",vendor: "Dell Inc."},
  {max_prefix: "04-BF-1B",vendor: "Dell Inc."},
  {max_prefix: "58-10-31",vendor: "Hon Hai Precision IND.CO.,LTD"},
  {max_prefix: "AC-91-9B",vendor: "Wistron Neweb Corporation"},
  {max_prefix: "E0-3C-1C",vendor: "IEEE Registration Authority"},
  {max_prefix: "00-09-93",vendor: "Visteon Corporation"},
  {max_prefix: "EC-41-CA",vendor: "Shenzhen TecAnswer Technology co.,ltd"},
  {max_prefix: "14-84-77",vendor: "New H3C Technologies Co., Ltd"},
  {max_prefix: "14-96-2D",vendor: "New H3C Technologies Co., Ltd"},
  {max_prefix: "C4-A6-4E",vendor: "Quectel Wireless Solutions Co.,Ltd."},
  {max_prefix: "70-66-B9",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "C4-A1-AE",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "48-6F-33",vendor: "KYUNGWOO.SYSTEM, INC."},
  {max_prefix: "44-20-63",vendor: "Continental Automotive Technologies GmbH"},
  {max_prefix: "08-61-95",vendor: "Rockwell Automation"},
  {max_prefix: "24-BB-C9",vendor: "Shenzhen SuperElectron Technology Co.,Ltd."},
  {max_prefix: "00-00-BC",vendor: "Rockwell Automation"},
  {max_prefix: "FC-D7-49",vendor: "Amazon Technologies Inc."},
  {max_prefix: "A8-71-16",vendor: "Earda Technologies co Ltd"},
  {max_prefix: "E8-EB-DD",vendor: "Guangzhou Qingying Acoustics Technology Co., Ltd"},
  {max_prefix: "54-8C-81",vendor: "Hangzhou Hikvision Digital Technology Co.,Ltd."},
  {max_prefix: "24-48-45",vendor: "Hangzhou Hikvision Digital Technology Co.,Ltd."},
  {max_prefix: "00-50-CA",vendor: "DZS Inc."},
  {max_prefix: "74-EE-8D",vendor: "Apollo Intelligent Connectivity (Beijing) Technology Co., Ltd."},
  {max_prefix: "D8-58-C6",vendor: "Katch Asset Tracking Pty Limited"},
  {max_prefix: "2C-C6-A0",vendor: "Lumacron Technology Ltd."},
  {max_prefix: "EC-C8-9C",vendor: "Hangzhou Hikvision Digital Technology Co.,Ltd."},
  {max_prefix: "8C-E7-48",vendor: "Hangzhou Hikvision Digital Technology Co.,Ltd."},
  {max_prefix: "24-28-FD",vendor: "Hangzhou Hikvision Digital Technology Co.,Ltd."},
  {max_prefix: "AC-B9-2F",vendor: "Hangzhou Hikvision Digital Technology Co.,Ltd."},
  {max_prefix: "D4-E8-53",vendor: "Hangzhou Hikvision Digital Technology Co.,Ltd."},
  {max_prefix: "24-0F-9B",vendor: "Hangzhou Hikvision Digital Technology Co.,Ltd."},
  {max_prefix: "C0-6D-ED",vendor: "Hangzhou Hikvision Digital Technology Co.,Ltd."},
  {max_prefix: "24-32-AE",vendor: "Hangzhou Hikvision Digital Technology Co.,Ltd."},
  {max_prefix: "E0-BA-AD",vendor: "Hangzhou Hikvision Digital Technology Co.,Ltd."},
  {max_prefix: "E0-CA-3C",vendor: "Hangzhou Hikvision Digital Technology Co.,Ltd."},
  {max_prefix: "DC-07-F8",vendor: "Hangzhou Hikvision Digital Technology Co.,Ltd."},
  {max_prefix: "64-DB-8B",vendor: "Hangzhou Hikvision Digital Technology Co.,Ltd."},
  {max_prefix: "94-E1-AC",vendor: "Hangzhou Hikvision Digital Technology Co.,Ltd."},
  {max_prefix: "58-03-FB",vendor: "Hangzhou Hikvision Digital Technology Co.,Ltd."},
  {max_prefix: "44-47-CC",vendor: "Hangzhou Hikvision Digital Technology Co.,Ltd."},
  {max_prefix: "98-DF-82",vendor: "Hangzhou Hikvision Digital Technology Co.,Ltd."},
  {max_prefix: "C0-56-E3",vendor: "Hangzhou Hikvision Digital Technology Co.,Ltd."},
  {max_prefix: "BC-AD-28",vendor: "Hangzhou Hikvision Digital Technology Co.,Ltd."},
  {max_prefix: "88-B6-BD",vendor: "Flaircomm Microelectronics, Inc."},
  {max_prefix: "2C-96-82",vendor: "MitraStar Technology Corp."},
  {max_prefix: "BC-49-B2",vendor: "SHENZHEN ALONG COMMUNICATION TECH CO., LTD"},
  {max_prefix: "94-97-AE",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "0C-BD-75",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "AC-90-73",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "00-03-51",vendor: "Diebold Nixdorf"},
  {max_prefix: "F0-9F-C2",vendor: "Ubiquiti Inc"},
  {max_prefix: "80-2A-A8",vendor: "Ubiquiti Inc"},
  {max_prefix: "FC-1D-3A",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "E4-BE-FB",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "58-F8-D7",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "78-8A-20",vendor: "Ubiquiti Inc"},
  {max_prefix: "74-83-C2",vendor: "Ubiquiti Inc"},
  {max_prefix: "E0-63-DA",vendor: "Ubiquiti Inc"},
  {max_prefix: "24-5A-4C",vendor: "Ubiquiti Inc"},
  {max_prefix: "60-22-32",vendor: "Ubiquiti Inc"},
  {max_prefix: "E4-38-83",vendor: "Ubiquiti Inc"},
  {max_prefix: "78-8C-B5",vendor: "TP-Link Corporation Limited"},
  {max_prefix: "84-B5-9C",vendor: "Juniper Networks"},
  {max_prefix: "5C-45-27",vendor: "Juniper Networks"},
  {max_prefix: "EC-3E-F7",vendor: "Juniper Networks"},
  {max_prefix: "00-21-59",vendor: "Juniper Networks"},
  {max_prefix: "00-23-9C",vendor: "Juniper Networks"},
  {max_prefix: "50-C5-8D",vendor: "Juniper Networks"},
  {max_prefix: "28-C0-DA",vendor: "Juniper Networks"},
  {max_prefix: "28-8A-1C",vendor: "Juniper Networks"},
  {max_prefix: "B8-EA-98",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "10-C4-CA",vendor: "HUMAX Co., Ltd."},
  {max_prefix: "AC-80-0A",vendor: "Sony Corporation"},
  {max_prefix: "40-A6-77",vendor: "Juniper Networks"},
  {max_prefix: "D8-18-D3",vendor: "Juniper Networks"},
  {max_prefix: "F0-4B-3A",vendor: "Juniper Networks"},
  {max_prefix: "C0-42-D0",vendor: "Juniper Networks"},
  {max_prefix: "00-1B-C0",vendor: "Juniper Networks"},
  {max_prefix: "44-EC-CE",vendor: "Juniper Networks"},
  {max_prefix: "CC-E1-94",vendor: "Juniper Networks"},
  {max_prefix: "E4-5D-37",vendor: "Juniper Networks"},
  {max_prefix: "94-F7-AD",vendor: "Juniper Networks"},
  {max_prefix: "78-4F-9B",vendor: "Juniper Networks"},
  {max_prefix: "88-D9-8F",vendor: "Juniper Networks"},
  {max_prefix: "78-50-7C",vendor: "Juniper Networks"},
  {max_prefix: "F0-7C-C7",vendor: "Juniper Networks"},
  {max_prefix: "00-05-85",vendor: "Juniper Networks"},
  {max_prefix: "88-90-09",vendor: "Juniper Networks"},
  {max_prefix: "00-CC-34",vendor: "Juniper Networks"},
  {max_prefix: "E0-30-F9",vendor: "Juniper Networks"},
  {max_prefix: "20-4E-71",vendor: "Juniper Networks"},
  {max_prefix: "D4-04-FF",vendor: "Juniper Networks"},
  {max_prefix: "84-C1-C1",vendor: "Juniper Networks"},
  {max_prefix: "4C-73-4F",vendor: "Juniper Networks"},
  {max_prefix: "D4-5A-3F",vendor: "Juniper Networks"},
  {max_prefix: "04-69-8F",vendor: "Juniper Networks"},
  {max_prefix: "58-C9-35",vendor: "Chiun Mai Communication System, Inc"},
  {max_prefix: "7C-E2-CA",vendor: "Juniper Networks"},
  {max_prefix: "A4-A5-28",vendor: "Sichuan Tianyi Comheart Telecom Co.,LTD"},
  {max_prefix: "E8-78-EE",vendor: "New H3C Technologies Co., Ltd"},
  {max_prefix: "EC-A7-AD",vendor: "Barrot Technology Co.,Ltd."},
  {max_prefix: "00-30-2B",vendor: "Inalp Solutions AG"},
  {max_prefix: "F0-74-8D",vendor: "Ruijie Networks Co.,LTD"},
  {max_prefix: "48-81-4E",vendor: "E&M SOLUTION CO,.Ltd"},
  {max_prefix: "7C-4D-8F",vendor: "HP Inc."},
  {max_prefix: "9C-CB-F7",vendor: "CLOUD STAR TECHNOLOGY CO., LTD."},
  {max_prefix: "74-D5-58",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "F4-64-12",vendor: "Sony Interactive Entertainment Inc."},
  {max_prefix: "3C-F9-F0",vendor: "zte corporation"},
  {max_prefix: "68-77-DA",vendor: "zte corporation"},
  {max_prefix: "88-58-BE",vendor: "kuosheng.com"},
  {max_prefix: "44-DB-D2",vendor: "YEALINK(XIAMEN) NETWORK TECHNOLOGY CO.,LTD."},
  {max_prefix: "E0-DC-A0",vendor: "Siemens Industrial Automation Products Ltd., Chengdu"},
  {max_prefix: "1C-3B-62",vendor: "HMD Global Oy"},
  {max_prefix: "5C-64-F1",vendor: "Cisco Systems, Inc"},
  {max_prefix: "9C-DB-AF",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "0C-2E-57",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "E8-D7-75",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "64-EC-65",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "F4-24-62",vendor: "Selcom Electronics (Shanghai) Co., Ltd"},
  {max_prefix: "F4-A1-7F",vendor: "Marquardt Electronics Technology (Shanghai) Co.Ltd"},
  {max_prefix: "48-BC-E1",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "B8-38-EF",vendor: "ADVA Optical Networking Ltd."},
  {max_prefix: "08-42-18",vendor: "Asyril SA"},
  {max_prefix: "D8-5B-22",vendor: "Shenzhen Hohunet Technology Co., Ltd"},
  {max_prefix: "D0-D0-03",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "5C-76-D5",vendor: "Nokia"},
  {max_prefix: "8C-7A-00",vendor: "Nokia"},
  {max_prefix: "A4-FC-14",vendor: "Apple, Inc."},
  {max_prefix: "A8-1A-F1",vendor: "Apple, Inc."},
  {max_prefix: "70-58-46",vendor: "Trig Avionics Limited"},
  {max_prefix: "08-91-A3",vendor: "Amazon Technologies Inc."},
  {max_prefix: "0C-D9-23",vendor: "GOCLOUD Networks(GAOKE Networks)"},
  {max_prefix: "84-39-8F",vendor: "Fortinet, Inc."},
  {max_prefix: "B4-ED-D5",vendor: "Quectel Wireless Solutions Co.,Ltd."},
  {max_prefix: "2C-D1-C6",vendor: "Murata Manufacturing Co., Ltd."},
  {max_prefix: "B8-5C-EE",vendor: "Baidu Online Network Technology (Beijing) Co., Ltd"},
  {max_prefix: "CC-08-FA",vendor: "Apple, Inc."},
  {max_prefix: "90-9B-6F",vendor: "Apple, Inc."},
  {max_prefix: "74-73-B4",vendor: "Apple, Inc."},
  {max_prefix: "F8-3C-80",vendor: "MITSUMI ELECTRIC CO.,LTD."},
  {max_prefix: "D8-3A-DD",vendor: "Raspberry Pi Trading Ltd"},
  {max_prefix: "70-F8-AE",vendor: "Microsoft Corporation"},
  {max_prefix: "00-0F-2A",vendor: "Cableware Electronics"},
  {max_prefix: "BC-64-D9",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "30-1F-48",vendor: "zte corporation"},
  {max_prefix: "7C-C7-4A",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "38-FC-34",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "3C-F6-92",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "94-0E-E7",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "A8-B2-71",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "64-05-E4",vendor: "ALPSALPINE CO,.LTD"},
  {max_prefix: "DC-AC-6F",vendor: "Everytale Inc"},
  {max_prefix: "F4-15-32",vendor: "PETAiO (NanJing), Inc."},
  {max_prefix: "2C-AB-33",vendor: "Texas Instruments"},
  {max_prefix: "28-B5-E8",vendor: "Texas Instruments"},
  {max_prefix: "08-C3-B3",vendor: "TCL King Electrical Appliances(Huizhou)Co.,Ltd"},
  {max_prefix: "20-45-69",vendor: "ITEL MOBILE LIMITED"},
  {max_prefix: "D4-63-52",vendor: "Vutility Inc."},
  {max_prefix: "D8-21-DA",vendor: "SERNET (SUZHOU) TECHNOLOGIES CORPORATION"},
  {max_prefix: "08-F1-B3",vendor: "Cisco Meraki"},
  {max_prefix: "BC-E8-FA",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "50-5A-65",vendor: "AzureWave Technology Inc."},
  {max_prefix: "C4-A5-59",vendor: "IEEE Registration Authority"},
  {max_prefix: "6C-0C-9A",vendor: "Amazon Technologies Inc."},
  {max_prefix: "CC-00-F1",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "30-82-16",vendor: "Apple, Inc."},
  {max_prefix: "8C-D0-B2",vendor: "Beijing Xiaomi Mobile Software Co., Ltd"},
  {max_prefix: "C4-A0-52",vendor: "Motorola Mobility LLC, a Lenovo Company"},
  {max_prefix: "84-90-0A",vendor: "Arcadyan Corporation"},
  {max_prefix: "78-76-89",vendor: "eero inc."},
  {max_prefix: "80-76-C2",vendor: "GD Midea Air-Conditioning Equipment Co.,Ltd."},
  {max_prefix: "F0-4A-3D",vendor: "Bosch Thermotechnik GmbH"},
  {max_prefix: "94-1F-A2",vendor: "Wuhan YuXin Semiconductor Co., Ltd."},
  {max_prefix: "B4-9D-FD",vendor: "Shenzhen SDMC Technology CO.,Ltd."},
  {max_prefix: "F4-1A-9C",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "00-1A-EB",vendor: "Allied Telesis K.K."},
  {max_prefix: "DC-06-82",vendor: "Accessia Technology Ltd."},
  {max_prefix: "44-D2-67",vendor: "Snorble"},
  {max_prefix: "B0-BC-7A",vendor: "Harman/Becker Automotive Systems GmbH"},
  {max_prefix: "48-77-06",vendor: "NXP Semiconductor (Tianjin) LTD."},
  {max_prefix: "0C-CC-47",vendor: "IEEE Registration Authority"},
  {max_prefix: "5C-3E-06",vendor: "Cisco Systems, Inc"},
  {max_prefix: "C8-28-E5",vendor: "Cisco Systems, Inc"},
  {max_prefix: "B0-FC-88",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "34-87-3D",vendor: "Quectel Wireless Solutions Co.,Ltd."},
  {max_prefix: "C4-41-37",vendor: "Quectel Wireless Solutions Co.,Ltd."},
  {max_prefix: "6C-D1-99",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "D0-40-BE",vendor: "NPO RPS LLC"},
  {max_prefix: "10-D6-80",vendor: "Tendyron Corporation"},
  {max_prefix: "00-3E-73",vendor: "Mist Systems, Inc."},
  {max_prefix: "0C-FC-18",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "F4-4D-AD",vendor: "Cable Matters Inc."},
  {max_prefix: "80-AB-4D",vendor: "Nokia Solutions and Networks GmbH & Co. KG"},
  {max_prefix: "4C-BA-7D",vendor: "Gemtek Technology Co., Ltd."},
  {max_prefix: "50-02-38",vendor: "Nokia Shanghai Bell Co., Ltd."},
  {max_prefix: "AC-AD-4B",vendor: "zte corporation"},
  {max_prefix: "A8-16-9D",vendor: "Hui Zhou Gaoshengda Technology Co.,LTD"},
  {max_prefix: "F0-22-1D",vendor: "IEEE Registration Authority"},
  {max_prefix: "9C-54-40",vendor: "ChengDu TD Tech"},
  {max_prefix: "50-26-EF",vendor: "Murata Manufacturing Co., Ltd."},
  {max_prefix: "44-49-88",vendor: "Intel Corporate"},
  {max_prefix: "FC-91-89",vendor: "Sichuan Tianyi Comheart Telecom Co.,LTD"},
  {max_prefix: "1C-1A-1B",vendor: "Shanghai Sunmi Technology Co.,Ltd."},
  {max_prefix: "80-38-D4",vendor: "Fibercentury Network Technology Co.,Ltd."},
  {max_prefix: "7C-29-6F",vendor: "Apple, Inc."},
  {max_prefix: "40-ED-CF",vendor: "Apple, Inc."},
  {max_prefix: "74-05-7C",vendor: "Qorvo International Pte. Ltd."},
  {max_prefix: "8C-98-6B",vendor: "Apple, Inc."},
  {max_prefix: "1C-86-82",vendor: "Apple, Inc."},
  {max_prefix: "80-54-E3",vendor: "Apple, Inc."},
  {max_prefix: "10-2E-00",vendor: "Intel Corporate"},
  {max_prefix: "20-3A-43",vendor: "Intel Corporate"},
  {max_prefix: "3C-3B-99",vendor: "ITEL MOBILE LIMITED"},
  {max_prefix: "FC-33-57",vendor: "KAGA FEI Co., Ltd."},
  {max_prefix: "9C-DE-4D",vendor: "ML vision Co.,LTD"},
  {max_prefix: "F8-F0-C5",vendor: "Suzhou Kuhan Information Technologies Co.,Ltd."},
  {max_prefix: "F8-DE-73",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "EC-1A-02",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "90-27-78",vendor: "Open Infrastructure"},
  {max_prefix: "68-41-8F",vendor: "Telechips, Inc."},
  {max_prefix: "BC-9E-BB",vendor: "Nintendo Co.,Ltd"},
  {max_prefix: "D0-09-C8",vendor: "Cisco Systems, Inc"},
  {max_prefix: "D0-39-57",vendor: "Liteon Technology Corporation"},
  {max_prefix: "48-E7-29",vendor: "Espressif Inc."},
  {max_prefix: "E8-BA-E2",vendor: "Xplora Technologies AS"},
  {max_prefix: "5C-A0-6C",vendor: "Realme Chongqing Mobile Telecommunications Corp.,Ltd."},
  {max_prefix: "A8-3B-76",vendor: "CLOUD NETWORK TECHNOLOGY SINGAPORE PTE. LTD."},
  {max_prefix: "40-E1-1C",vendor: "shenzhen Cloud High Communication Technology Co.,Ltd"},
  {max_prefix: "44-64-3C",vendor: "Cisco Systems, Inc"},
  {max_prefix: "24-16-1B",vendor: "Cisco Systems, Inc"},
  {max_prefix: "A8-40-0B",vendor: "Visteon Corporation"},
  {max_prefix: "20-31-8D",vendor: "Giax GmbH"},
  {max_prefix: "3C-B0-7E",vendor: "Arounds Intelligent Equipment Co., Ltd."},
  {max_prefix: "C0-4E-8A",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "08-56-9B",vendor: "WiZ"},
  {max_prefix: "08-1A-FD",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "C0-BF-AC",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "44-27-2E",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "10-66-50",vendor: "Robert Bosch JuP1"},
  {max_prefix: "A8-0C-03",vendor: "Florawise"},
  {max_prefix: "90-DA-F9",vendor: "Siemens Rail Automation SAU"},
  {max_prefix: "F8-00-9D",vendor: "INTRACOM DEFENSE S.A."},
  {max_prefix: "20-16-42",vendor: "Microsoft Corporation"},
  {max_prefix: "8C-94-6A",vendor: "New H3C Technologies Co., Ltd"},
  {max_prefix: "FC-11-65",vendor: "Cambium Networks Limited"},
  {max_prefix: "CC-8D-B5",vendor: "Shenzhen SuperElectron Technology Co.,Ltd."},
  {max_prefix: "AC-EE-64",vendor: "Shenzhen SuperElectron Technology Co.,Ltd."},
  {max_prefix: "00-12-F3",vendor: "u-blox AG"},
  {max_prefix: "54-70-68",vendor: "VTech Communications Limited"},
  {max_prefix: "C8-40-52",vendor: "PAX Computer Technology(Shenzhen) Ltd."},
  {max_prefix: "68-EE-4B",vendor: "Sharetronic Data Technology Co.,Ltd"},
  {max_prefix: "48-C3-5A",vendor: "LENOVO(BEIJING)CO., LTD."},
  {max_prefix: "C4-61-C7",vendor: "Microsoft Corporation"},
  {max_prefix: "AC-CB-36",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "60-70-6C",vendor: "Google, Inc."},
  {max_prefix: "C8-2A-DD",vendor: "Google, Inc."},
  {max_prefix: "24-09-35",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "B4-DB-91",vendor: "CELESTICA INC."},
  {max_prefix: "C4-83-72",vendor: "IEEE Registration Authority"},
  {max_prefix: "C0-A5-E8",vendor: "Intel Corporate"},
  {max_prefix: "90-65-84",vendor: "Intel Corporate"},
  {max_prefix: "28-C5-D2",vendor: "Intel Corporate"},
  {max_prefix: "E4-8C-73",vendor: "Realme Chongqing Mobile Telecommunications Corp.,Ltd."},
  {max_prefix: "2C-B6-C8",vendor: "Raisecom Technology CO., LTD"},
  {max_prefix: "D8-34-D1",vendor: "Shenzhen Orange Digital Technology Co.,Ltd"},
  {max_prefix: "84-22-89",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "84-AA-A4",vendor: "SONoC Corp."},
  {max_prefix: "88-3C-93",vendor: "Alcatel-Lucent Enterprise"},
  {max_prefix: "FC-DF-00",vendor: "GD Midea Air-Conditioning Equipment Co.,Ltd."},
  {max_prefix: "E8-BF-DB",vendor: "Inodesign Group"},
  {max_prefix: "28-7E-80",vendor: "Hui Zhou Gaoshengda Technology Co.,LTD"},
  {max_prefix: "0C-9F-71",vendor: "Dolphin Electronics (DongGuan) Co., Ltd."},
  {max_prefix: "1C-8B-76",vendor: "Calix Inc."},
  {max_prefix: "74-58-89",vendor: "Multilaser Industrial S.A."},
  {max_prefix: "E8-FF-98",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "24-15-51",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "58-95-7E",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "2C-94-52",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "60-01-B1",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "B8-14-4D",vendor: "Apple, Inc."},
  {max_prefix: "EC-28-D3",vendor: "Apple, Inc."},
  {max_prefix: "5C-BB-EE",vendor: "zte corporation"},
  {max_prefix: "08-65-18",vendor: "Apple, Inc."},
  {max_prefix: "2C-57-CE",vendor: "Apple, Inc."},
  {max_prefix: "80-39-8C",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "98-0D-6F",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "1C-90-FF",vendor: "Tuya Smart Inc."},
  {max_prefix: "10-82-3D",vendor: "Ruijie Networks Co.,LTD"},
  {max_prefix: "98-20-44",vendor: "New H3C Technologies Co., Ltd"},
  {max_prefix: "D8-A0-E8",vendor: "zte corporation"},
  {max_prefix: "80-56-3C",vendor: "ZF"},
  {max_prefix: "A0-44-F3",vendor: "RafaelMicro"},
  {max_prefix: "F4-2B-7D",vendor: "Chipsguide technology CO.,LTD."},
  {max_prefix: "8C-52-19",vendor: "SHARP Corporation"},
  {max_prefix: "2C-9E-00",vendor: "Sony Interactive Entertainment Inc."},
  {max_prefix: "DC-6A-E7",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "7C-A4-49",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "D0-06-6A",vendor: "Cornelis Networks, Inc."},
  {max_prefix: "88-3C-C5",vendor: "IEEE Registration Authority"},
  {max_prefix: "C4-04-D8",vendor: "Aviva Links Inc."},
  {max_prefix: "AC-96-5B",vendor: "Lucid Motors"},
  {max_prefix: "08-91-15",vendor: "Amazon Technologies Inc."},
  {max_prefix: "74-D4-23",vendor: "Amazon Technologies Inc."},
  {max_prefix: "E0-47-35",vendor: "Ericsson AB"},
  {max_prefix: "90-98-77",vendor: "Vestel Elektronik San ve Tic. A.S."},
  {max_prefix: "EC-A1-38",vendor: "Amazon Technologies Inc."},
  {max_prefix: "E0-EF-02",vendor: "Chengdu Quanjing Intelligent Technology Co.,Ltd"},
  {max_prefix: "4C-A3-A7",vendor: "TECNO MOBILE LIMITED"},
  {max_prefix: "B0-67-B5",vendor: "Apple, Inc."},
  {max_prefix: "5C-52-84",vendor: "Apple, Inc."},
  {max_prefix: "C0-95-6D",vendor: "Apple, Inc."},
  {max_prefix: "3C-39-C8",vendor: "Apple, Inc."},
  {max_prefix: "A8-AB-B5",vendor: "Apple, Inc."},
  {max_prefix: "58-64-C4",vendor: "Apple, Inc."},
  {max_prefix: "40-A5-3B",vendor: "Nokia"},
  {max_prefix: "74-80-3F",vendor: "Renesas Electronics (Penang) Sdn. Bhd."},
  {max_prefix: "90-0A-62",vendor: "Inventus Power Eletronica do Brasil LTDA"},
  {max_prefix: "50-5F-B5",vendor: "ASKEY COMPUTER CORP"},
  {max_prefix: "88-DE-7C",vendor: "ASKEY COMPUTER CORP"},
  {max_prefix: "04-57-47",vendor: "GoPro"},
  {max_prefix: "5C-60-BA",vendor: "HP Inc."},
  {max_prefix: "BC-F8-8B",vendor: "zte corporation"},
  {max_prefix: "68-53-9D",vendor: "EM Microelectronic"},
  {max_prefix: "04-43-FD",vendor: "Sichuan Tianyi Comheart Telecom Co.,LTD"},
  {max_prefix: "A4-89-7E",vendor: "Guangzhou Yuhong Technology Co.,Ltd."},
  {max_prefix: "BC-D7-67",vendor: "BAE Systems Apllied Intelligence"},
  {max_prefix: "EC-1D-9E",vendor: "Quectel Wireless Solutions Co.,Ltd."},
  {max_prefix: "80-64-6F",vendor: "Espressif Inc."},
  {max_prefix: "3C-A7-AE",vendor: "zte corporation"},
  {max_prefix: "AC-31-84",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "50-3F-50",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "2C-69-1D",vendor: "IEEE Registration Authority"},
  {max_prefix: "CC-29-BD",vendor: "zte corporation"},
  {max_prefix: "38-5C-FB",vendor: "Silicon Laboratories"},
  {max_prefix: "C4-3D-1A",vendor: "Intel Corporate"},
  {max_prefix: "04-E8-B9",vendor: "Intel Corporate"},
  {max_prefix: "E0-2E-0B",vendor: "Intel Corporate"},
  {max_prefix: "E8-CC-8C",vendor: "Chengdu Jiarui Hualian Communication Technology Co"},
  {max_prefix: "E4-65-64",vendor: "SHENZHEN KTC TECHNOLOGY CO.,LTD"},
  {max_prefix: "3C-E0-64",vendor: "Texas Instruments"},
  {max_prefix: "E0-92-8F",vendor: "Texas Instruments"},
  {max_prefix: "CC-03-7B",vendor: "Texas Instruments"},
  {max_prefix: "58-1C-F8",vendor: "Intel Corporate"},
  {max_prefix: "AC-19-8E",vendor: "Intel Corporate"},
  {max_prefix: "C8-5E-A9",vendor: "Intel Corporate"},
  {max_prefix: "C8-BF-4C",vendor: "Beijing Xiaomi Mobile Software Co., Ltd"},
  {max_prefix: "7C-DE-78",vendor: "New H3C Technologies Co., Ltd"},
  {max_prefix: "B8-FB-AF",vendor: "Xiamen IPRT Technology CO.,LTD"},
  {max_prefix: "34-85-18",vendor: "Espressif Inc."},
  {max_prefix: "E0-51-D8",vendor: "China Dragon Technology Limited"},
  {max_prefix: "54-9A-11",vendor: "IEEE Registration Authority"},
  {max_prefix: "EC-E6-A2",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "AC-84-C6",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "1C-0E-D3",vendor: "Sichuan Tianyi Comheart Telecom Co.,LTD"},
  {max_prefix: "38-0A-4F",vendor: "PRACHI ENTERPRISES"},
  {max_prefix: "A0-CD-F3",vendor: "Murata Manufacturing Co., Ltd."},
  {max_prefix: "AC-5A-F0",vendor: "LG Electronics"},
  {max_prefix: "EC-A6-2F",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "0C-BE-F1",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "AC-93-6A",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "38-A4-4B",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "80-19-70",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "5C-53-C3",vendor: "Ubee Interactive Co., Limited"},
  {max_prefix: "B4-8A-0A",vendor: "Espressif Inc."},
  {max_prefix: "C8-3A-1B",vendor: "Toshiba TEC Corporation Inc"},
  {max_prefix: "E0-F7-28",vendor: "Amazon Technologies Inc."},
  {max_prefix: "24-29-34",vendor: "Google, Inc."},
  {max_prefix: "38-22-F4",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "80-02-F4",vendor: "IEEE Registration Authority"},
  {max_prefix: "14-9B-F3",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "10-07-1D",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "C8-C1-3C",vendor: "RuggedTek Hangzhou Co., Ltd"},
  {max_prefix: "B8-50-D8",vendor: "Beijing Xiaomi Mobile Software Co., Ltd"},
  {max_prefix: "F4-B3-B1",vendor: "Silicon Laboratories"},
  {max_prefix: "C0-9F-51",vendor: "SERNET (SUZHOU) TECHNOLOGIES CORPORATION"},
  {max_prefix: "10-B2-32",vendor: "Qingdao Intelligent&Precise Electronics Co.,Ltd."},
  {max_prefix: "84-70-D7",vendor: "eero inc."},
  {max_prefix: "58-1D-D8",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "30-D5-87",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "14-F5-92",vendor: "Shenzhen SDG DONZHI Technology Co., Ltd"},
  {max_prefix: "4C-09-FA",vendor: "FRONTIER SMART TECHNOLOGIES LTD"},
  {max_prefix: "24-2C-FE",vendor: "Zhejiang Tmall Technology Co., Ltd."},
  {max_prefix: "A0-42-D1",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "58-87-9F",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "9C-1F-CA",vendor: "Hangzhou AlmightyDigit Technology Co., Ltd"},
  {max_prefix: "2C-DC-78",vendor: "Descartes Systems (USA) LLC"},
  {max_prefix: "68-A7-B4",vendor: "Honor Device Co., Ltd."},
  {max_prefix: "80-3C-20",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "A4-DD-58",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "E8-D8-7E",vendor: "Amazon Technologies Inc."},
  {max_prefix: "78-03-4F",vendor: "Nokia"},
  {max_prefix: "9C-57-BC",vendor: "eero inc."},
  {max_prefix: "00-15-A6",vendor: "Digital Electronics Products Ltd."},
  {max_prefix: "A8-A2-37",vendor: "Arcadyan Corporation"},
  {max_prefix: "F8-AB-82",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "EC-30-B3",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "08-E6-3B",vendor: "zte corporation"},
  {max_prefix: "88-C1-74",vendor: "zte corporation"},
  {max_prefix: "C8-9E-61",vendor: "Lyngsoe Systems LTd"},
  {max_prefix: "90-2C-FB",vendor: "CanTops Co,.Ltd."},
  {max_prefix: "1C-AF-4A",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "C8-12-0B",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "6C-15-24",vendor: "IEEE Registration Authority"},
  {max_prefix: "C0-8D-51",vendor: "Amazon Technologies Inc."},
  {max_prefix: "44-B4-B2",vendor: "Amazon Technologies Inc."},
  {max_prefix: "AC-CC-FC",vendor: "Amazon Technologies Inc."},
  {max_prefix: "E8-DC-6C",vendor: "Cisco Systems, Inc"},
  {max_prefix: "B0-3F-64",vendor: "Apple, Inc."},
  {max_prefix: "2C-82-17",vendor: "Apple, Inc."},
  {max_prefix: "14-2D-4D",vendor: "Apple, Inc."},
  {max_prefix: "EC-42-CC",vendor: "Apple, Inc."},
  {max_prefix: "B8-21-1C",vendor: "Apple, Inc."},
  {max_prefix: "28-BE-43",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "2C-FC-8B",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "00-26-04",vendor: "WorldCast Systems"},
  {max_prefix: "F8-AD-24",vendor: "Realme Chongqing Mobile Telecommunications Corp.,Ltd."},
  {max_prefix: "A8-C9-8A",vendor: "New H3C Technologies Co., Ltd"},
  {max_prefix: "E0-27-6C",vendor: "Guangzhou Shiyuan Electronic Technology Company Limited"},
  {max_prefix: "DC-8E-95",vendor: "Silicon Laboratories"},
  {max_prefix: "AC-8F-A9",vendor: "Nokia Solutions and Networks GmbH & Co. KG"},
  {max_prefix: "00-0A-D0",vendor: "Niigata Develoment Center,  F.I.T. Co., Ltd."},
  {max_prefix: "7C-EF-40",vendor: "Nextorage Corporation"},
  {max_prefix: "C0-E0-1C",vendor: "IoT Security Group, SL"},
  {max_prefix: "AC-51-AB",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "48-CD-D3",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "CC-66-18",vendor: "Adtran Inc"},
  {max_prefix: "44-29-1E",vendor: "AltoBeam (China) Inc."},
  {max_prefix: "24-EB-ED",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "28-74-F5",vendor: "Nokia Solutions and Networks GmbH & Co. KG"},
  {max_prefix: "A0-44-66",vendor: "Intellics"},
  {max_prefix: "44-6D-7F",vendor: "Amazon Technologies Inc."},
  {max_prefix: "24-06-F2",vendor: "Sichuan Tianyi Comheart Telecom Co.,LTD"},
  {max_prefix: "68-18-D9",vendor: "Hill AFB - CAPRE Group"},
  {max_prefix: "1C-61-B4",vendor: "TP-Link Corporation Limited"},
  {max_prefix: "9C-A2-F4",vendor: "TP-Link Corporation Limited"},
  {max_prefix: "18-BC-57",vendor: "ADVA Optical Networking Ltd."},
  {max_prefix: "D8-E2-DF",vendor: "Microsoft Corporation"},
  {max_prefix: "38-8F-30",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "6C-0F-61",vendor: "Hypervolt Ltd"},
  {max_prefix: "C0-C1-70",vendor: "Shenzhen SuperElectron Technology Co.,Ltd."},
  {max_prefix: "50-42-89",vendor: "zte corporation"},
  {max_prefix: "84-C6-92",vendor: "Texas Instruments"},
  {max_prefix: "6C-B2-FD",vendor: "Texas Instruments"},
  {max_prefix: "30-2B-DC",vendor: "Top-Unum Electronics Co., LTD"},
  {max_prefix: "90-F7-B2",vendor: "New H3C Technologies Co., Ltd"},
  {max_prefix: "1C-47-F6",vendor: "Zhidao Network Technology(Shenzhen) Co.,Ltd"},
  {max_prefix: "00-0C-D6",vendor: "PARTNER TECH"},
  {max_prefix: "6C-93-08",vendor: "IEEE Registration Authority"},
  {max_prefix: "E8-EB-D3",vendor: "Mellanox Technologies, Inc."},
  {max_prefix: "E8-81-AB",vendor: "Beijing Sankuai Online Technology Co.,Ltd"},
  {max_prefix: "D4-BD-4F",vendor: "Ruckus Wireless"},
  {max_prefix: "5C-C9-C0",vendor: "Renesas Electronics (Penang) Sdn. Bhd."},
  {max_prefix: "8C-15-53",vendor: "Beijing Memblaze Technology Co Ltd"},
  {max_prefix: "6C-B1-58",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "B0-A4-F0",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "04-E3-1A",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "7C-6A-60",vendor: "China Mobile Group Device Co.,Ltd."},
  {max_prefix: "B4-69-5F",vendor: "TCT mobile ltd"},
  {max_prefix: "24-75-3A",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "C8-58-95",vendor: "Motorola Mobility LLC, a Lenovo Company"},
  {max_prefix: "6C-99-9D",vendor: "Amazon Technologies Inc."},
  {max_prefix: "C0-06-0C",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "2C-33-41",vendor: "China Mobile IOT Company Limited"},
  {max_prefix: "AC-56-7B",vendor: "Sunnovo International Limited"},
  {max_prefix: "34-D7-37",vendor: "IBG Industriebeteiligungsgesellschaft mbH &b Co. KG"},
  {max_prefix: "A8-53-7D",vendor: "Mist Systems, Inc."},
  {max_prefix: "30-34-22",vendor: "eero inc."},
  {max_prefix: "E4-DA-DF",vendor: "Taicang T&W Electronics"},
  {max_prefix: "7C-35-F8",vendor: "Zhejiang Tmall Technology Co., Ltd."},
  {max_prefix: "B0-5C-16",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "08-E0-21",vendor: "Honor Device Co., Ltd."},
  {max_prefix: "9C-C1-2D",vendor: "GD Midea Air-Conditioning Equipment Co.,Ltd."},
  {max_prefix: "74-26-FF",vendor: "zte corporation"},
  {max_prefix: "C4-27-28",vendor: "zte corporation"},
  {max_prefix: "8C-C7-C3",vendor: "NETLINK ICT"},
  {max_prefix: "E8-D3-22",vendor: "Cisco Systems, Inc"},
  {max_prefix: "A0-B4-BF",vendor: "InfiNet LLC"},
  {max_prefix: "7C-C9-5E",vendor: "Dongguan Liesheng Electronic Co., Ltd."},
  {max_prefix: "D0-ED-FF",vendor: "ZF CVCS"},
  {max_prefix: "98-8F-E0",vendor: "IEEE Registration Authority"},
  {max_prefix: "F8-A9-1F",vendor: "ZVISION Technologies Co., Ltd"},
  {max_prefix: "C0-79-82",vendor: "TCL King Electrical Appliances(Huizhou)Co.,Ltd"},
  {max_prefix: "08-00-76",vendor: "PC LAN TECHNOLOGIES"},
  {max_prefix: "7C-CC-FC",vendor: "Quectel Wireless Solutions Co.,Ltd."},
  {max_prefix: "DC-80-84",vendor: "Apple, Inc."},
  {max_prefix: "4C-BA-D7",vendor: "LG Innotek"},
  {max_prefix: "34-B8-83",vendor: "Cisco Systems, Inc"},
  {max_prefix: "80-65-7C",vendor: "Apple, Inc."},
  {max_prefix: "2C-FD-B4",vendor: "Shenzhen Jingxun Software Telecommunication Technology Co.,Ltd"},
  {max_prefix: "38-C8-04",vendor: "Hui Zhou Gaoshengda Technology Co.,LTD"},
  {max_prefix: "E0-07-C2",vendor: "FUJIAN STAR-NET COMMUNICATION CO.,LTD"},
  {max_prefix: "18-D7-93",vendor: "IEEE Registration Authority"},
  {max_prefix: "10-2C-8D",vendor: "GD Midea Air-Conditioning Equipment Co.,Ltd."},
  {max_prefix: "D8-B6-73",vendor: "Texas Instruments"},
  {max_prefix: "B4-F2-67",vendor: "Compal Broadband Networks, Inc."},
  {max_prefix: "B0-DD-74",vendor: "Heimgard Technologies AS"},
  {max_prefix: "C0-FB-C1",vendor: "ITEL MOBILE LIMITED"},
  {max_prefix: "98-50-A3",vendor: "SIGNALTEK JSC"},
  {max_prefix: "B4-8C-9D",vendor: "AzureWave Technology Inc."},
  {max_prefix: "50-B3-B4",vendor: "Shenzhen Furuilian Electronic Co.,Ltd."},
  {max_prefix: "94-E6-86",vendor: "Espressif Inc."},
  {max_prefix: "F8-28-C9",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "FC-11-93",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "DC-36-43",vendor: "IEEE Registration Authority"},
  {max_prefix: "50-87-4D",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "A4-17-52",vendor: "Hifocus Electronics India Private Limited"},
  {max_prefix: "00-62-0B",vendor: "Broadcom Limited"},
  {max_prefix: "40-FE-95",vendor: "New H3C Technologies Co., Ltd"},
  {max_prefix: "A0-53-94",vendor: "Shenzhen zediel co., Ltd."},
  {max_prefix: "04-EE-E8",vendor: "IEEE Registration Authority"},
  {max_prefix: "9C-B8-B4",vendor: "AMPAK Technology,Inc."},
  {max_prefix: "84-B4-DB",vendor: "Silicon Laboratories"},
  {max_prefix: "1C-70-C9",vendor: "Jiangsu Aisida Electronic Co., Ltd"},
  {max_prefix: "3C-F7-D1",vendor: "OMRON Corporation"},
  {max_prefix: "DC-CC-E6",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "F0-65-AE",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "50-89-D1",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "BC-D0-74",vendor: "Apple, Inc."},
  {max_prefix: "0C-1E-F7",vendor: "Omni-ID"},
  {max_prefix: "E8-51-77",vendor: "Qingdao Intelligent&Precise Electronics Co.,Ltd."},
  {max_prefix: "38-8A-21",vendor: "UAB Teltonika Telematics"},
  {max_prefix: "A4-5F-B9",vendor: "DreamBig Semiconductor, Inc."},
  {max_prefix: "34-5D-9E",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "00-0F-32",vendor: "Lootom Telcovideo Network (Wuxi) Co Ltd"},
  {max_prefix: "18-C2-3C",vendor: "Lumi United Technology Co., Ltd"},
  {max_prefix: "04-75-F9",vendor: "Taicang T&W Electronics"},
  {max_prefix: "E0-6D-18",vendor: "PIONEERCORPORATION"},
  {max_prefix: "8C-F8-C5",vendor: "Intel Corporate"},
  {max_prefix: "A0-59-50",vendor: "Intel Corporate"},
  {max_prefix: "D4-35-4A",vendor: "ALAXALA Networks Corporation"},
  {max_prefix: "40-E9-9B",vendor: "SAMSUNG ELECTRO-MECHANICS(THAILAND)"},
  {max_prefix: "90-FF-D6",vendor: "Honor Device Co., Ltd."},
  {max_prefix: "CC-A3-BD",vendor: "ITEL MOBILE LIMITED"},
  {max_prefix: "BC-AF-87",vendor: "smartAC.com, Inc."},
  {max_prefix: "C0-2B-31",vendor: "Phytium Technology Co.,Ltd."},
  {max_prefix: "58-CF-79",vendor: "Espressif Inc."},
  {max_prefix: "78-62-99",vendor: "BITSTREAM sp. z o.o."},
  {max_prefix: "CC-5B-31",vendor: "Nintendo Co.,Ltd"},
  {max_prefix: "C0-28-0B",vendor: "Honor Device Co., Ltd."},
  {max_prefix: "9C-EA-97",vendor: "Honor Device Co., Ltd."},
  {max_prefix: "C8-9B-AD",vendor: "Honor Device Co., Ltd."},
  {max_prefix: "B4-D2-86",vendor: "Telechips, Inc."},
  {max_prefix: "08-F8-0D",vendor: "IEEE Registration Authority"},
  {max_prefix: "BC-45-5B",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "D4-2C-46",vendor: "BUFFALO.INC"},
  {max_prefix: "10-91-A8",vendor: "Espressif Inc."},
  {max_prefix: "78-04-7A",vendor: "Edge Networks LLC"},
  {max_prefix: "B0-3C-DC",vendor: "Intel Corporate"},
  {max_prefix: "20-80-97",vendor: "Shenzhen OXO Technology limited"},
  {max_prefix: "7C-16-89",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "5C-83-CD",vendor: "New platforms"},
  {max_prefix: "10-E8-A7",vendor: "Wistron Neweb Corporation"},
  {max_prefix: "84-15-D3",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "D4-94-00",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "38-4B-24",vendor: "SIEMENS AG"},
  {max_prefix: "F0-AE-66",vendor: "Cosonic Intelligent Technologies Co., Ltd."},
  {max_prefix: "3C-A9-16",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "80-6A-00",vendor: "Cisco Systems, Inc"},
  {max_prefix: "A0-6C-65",vendor: "Texas Instruments"},
  {max_prefix: "44-85-02",vendor: "Shenzhen SuperElectron Technology Co.,Ltd."},
  {max_prefix: "F4-A4-54",vendor: "IEEE Registration Authority"},
  {max_prefix: "58-FC-C6",vendor: "TOZO INC"},
  {max_prefix: "C4-1C-07",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "40-11-C3",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "C4-7D-9F",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "A4-EF-15",vendor: "AltoBeam (China) Inc."},
  {max_prefix: "1C-45-86",vendor: "Nintendo Co.,Ltd"},
  {max_prefix: "18-5B-00",vendor: "Nokia"},
  {max_prefix: "A8-61-DF",vendor: "China Mobile Group Device Co.,Ltd."},
  {max_prefix: "30-5A-99",vendor: "Sichuan Tianyi Comheart Telecom Co.,LTD"},
  {max_prefix: "60-4D-E1",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "70-4E-6B",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "60-3D-29",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "10-A5-1D",vendor: "Intel Corporate"},
  {max_prefix: "58-9B-4A",vendor: "DWnet Technologies(Suzhou) Corporation"},
  {max_prefix: "50-7C-6F",vendor: "Intel Corporate"},
  {max_prefix: "98-DD-60",vendor: "Apple, Inc."},
  {max_prefix: "C0-44-42",vendor: "Apple, Inc."},
  {max_prefix: "D4-68-AA",vendor: "Apple, Inc."},
  {max_prefix: "F8-C3-CC",vendor: "Apple, Inc."},
  {max_prefix: "54-C4-80",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "D8-66-EE",vendor: "BOXIN COMMUNICATION CO.,LTD."},
  {max_prefix: "F4-57-3E",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "70-C6-DD",vendor: "New H3C Technologies Co., Ltd"},
  {max_prefix: "3C-9E-C7",vendor: "SKY UK LIMITED"},
  {max_prefix: "08-8E-90",vendor: "Intel Corporate"},
  {max_prefix: "28-2A-87",vendor: "ITEL MOBILE LIMITED"},
  {max_prefix: "24-26-BA",vendor: "Shenzhen Toptel Technology Co., Ltd."},
  {max_prefix: "F0-6C-73",vendor: "Nokia"},
  {max_prefix: "9C-75-6E",vendor: "Ajax Systems DMCC"},
  {max_prefix: "E8-F9-D4",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "B0-C7-87",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "0C-4F-9B",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "48-2F-D7",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "7C-21-4A",vendor: "Intel Corporate"},
  {max_prefix: "50-84-92",vendor: "Intel Corporate"},
  {max_prefix: "98-80-BB",vendor: "IEEE Registration Authority"},
  {max_prefix: "B8-12-DA",vendor: " LVSWITCHES INC."},
  {max_prefix: "A0-D7-F3",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "78-90-A2",vendor: "zte corporation"},
  {max_prefix: "AC-BC-D9",vendor: "Cisco Systems, Inc"},
  {max_prefix: "D8-B0-53",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "6C-F7-84",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "54-6C-EB",vendor: "Intel Corporate"},
  {max_prefix: "00-93-37",vendor: "Intel Corporate"},
  {max_prefix: "58-CE-2A",vendor: "Intel Corporate"},
  {max_prefix: "04-2B-58",vendor: "Shenzhen Hanzsung Technology Co.,Ltd"},
  {max_prefix: "B4-3D-08",vendor: "GX International BV"},
  {max_prefix: "70-89-F5",vendor: "Dongguan Lingjie IOT Co., LTD"},
  {max_prefix: "94-31-CB",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "B8-20-8E",vendor: "Panasonic Connect Co., Ltd."},
  {max_prefix: "84-7B-57",vendor: "Intel Corporate"},
  {max_prefix: "40-06-D5",vendor: "Cisco Systems, Inc"},
  {max_prefix: "C4-CA-2B",vendor: "Arista Networks"},
  {max_prefix: "60-7D-DD",vendor: "Shenzhen Shichuangyi Electronics Co.,Ltd"},
  {max_prefix: "AC-5E-14",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "20-DF-73",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "48-12-8F",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "64-CB-E9",vendor: "LG Innotek"},
  {max_prefix: "24-D8-1E",vendor: "MirWifi,Joint-Stock Company"},
  {max_prefix: "90-38-0C",vendor: "Espressif Inc."},
  {max_prefix: "BC-22-28",vendor: "D-Link International"},
  {max_prefix: "40-CA-63",vendor: "Seongji Industry Company"},
  {max_prefix: "40-B0-A1",vendor: "VALCOM CO.,LTD."},
  {max_prefix: "10-3C-59",vendor: "zte corporation"},
  {max_prefix: "D4-8A-3B",vendor: "HUNAN FN-LINK TECHNOLOGY LIMITED"},
  {max_prefix: "6C-DD-EF",vendor: "EPCOMM Inc."},
  {max_prefix: "10-B7-A8",vendor: "CableFree Networks Limited"},
  {max_prefix: "08-9B-F1",vendor: "eero inc."},
  {max_prefix: "78-44-4A",vendor: "Shenzhen Aiwinn information Technology Co., Ltd."},
  {max_prefix: "D0-F1-21",vendor: "Xi'an LINKSCI Technology Co., Ltd"},
  {max_prefix: "9C-56-36",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "94-7F-1D",vendor: "Shenzhen Fastrain Technology Co., Ltd."},
  {max_prefix: "D0-F5-20",vendor: "KYOCERA Corporation"},
  {max_prefix: "70-B6-4F",vendor: "Guangzhou V-SOLUTION Electronic Technology Co., Ltd."},
  {max_prefix: "B8-94-70",vendor: "Calix Inc."},
  {max_prefix: "E4-29-3D",vendor: "Shenzhen Sy-Fiber Optical Communication Technology.Co.,Ltd"},
  {max_prefix: "70-B9-BB",vendor: "Shenzhen Hankvision Technology CO.,LTD"},
  {max_prefix: "B4-20-5B",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "A4-C6-9A",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "8C-B8-7E",vendor: "Intel Corporate"},
  {max_prefix: "70-1A-B8",vendor: "Intel Corporate"},
  {max_prefix: "5C-DF-89",vendor: "Ruckus Wireless"},
  {max_prefix: "B8-8C-29",vendor: "GD Midea Air-Conditioning Equipment Co.,Ltd."},
  {max_prefix: "54-44-A3",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "D0-3F-27",vendor: "Wyze Labs Inc"},
  {max_prefix: "50-29-7B",vendor: "China Mobile Group Device Co.,Ltd."},
  {max_prefix: "30-03-C8",vendor: "CLOUD NETWORK TECHNOLOGY SINGAPORE PTE. LTD."},
  {max_prefix: "08-9E-84",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "10-82-D7",vendor: "Realme Chongqing Mobile Telecommunications Corp.,Ltd."},
  {max_prefix: "78-5E-E8",vendor: "IEEE Registration Authority"},
  {max_prefix: "9C-40-CD",vendor: "Synclayer Inc."},
  {max_prefix: "D8-BE-1F",vendor: "Apple, Inc."},
  {max_prefix: "98-50-2E",vendor: "Apple, Inc."},
  {max_prefix: "58-0A-D4",vendor: "Apple, Inc."},
  {max_prefix: "A4-77-F3",vendor: "Apple, Inc."},
  {max_prefix: "CC-BC-E3",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "08-8E-DC",vendor: "Apple, Inc."},
  {max_prefix: "A8-4A-28",vendor: "Apple, Inc."},
  {max_prefix: "BC-6A-D1",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "08-45-D1",vendor: "Cisco Systems, Inc"},
  {max_prefix: "18-E2-15",vendor: "Nokia"},
  {max_prefix: "EC-1C-5D",vendor: "Siemens AG"},
  {max_prefix: "84-27-B6",vendor: "China Mobile IOT Company Limited"},
  {max_prefix: "80-7E-B4",vendor: "Shenzhen SuperElectron Technology Co.,Ltd."},
  {max_prefix: "2C-B8-ED",vendor: "SonicWall"},
  {max_prefix: "50-0A-52",vendor: "Huiwan Technologies Co. Ltd"},
  {max_prefix: "54-A9-D4",vendor: "Minibar Systems"},
  {max_prefix: "00-00-62",vendor: "BULL HN INFORMATION SYSTEMS"},
  {max_prefix: "C8-C9-A3",vendor: "Espressif Inc."},
  {max_prefix: "E4-3B-C9",vendor: "HISENSE VISUAL TECHNOLOGY CO.,LTD"},
  {max_prefix: "E8-95-26",vendor: "Luxshare Precision Industry CO., LTD."},
  {max_prefix: "10-41-21",vendor: "TELLESCOM INDUSTRIA E COMERCIO EM TELECOMUNICACAO"},
  {max_prefix: "D4-93-90",vendor: "CLEVO CO."},
  {max_prefix: "BC-06-2D",vendor: "Wacom Co.,Ltd."},
  {max_prefix: "3C-93-F4",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "64-33-B5",vendor: "IEEE Registration Authority"},
  {max_prefix: "50-4B-9E",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "04-7A-AE",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "40-9C-A6",vendor: "Curvalux"},
  {max_prefix: "8C-4B-14",vendor: "Espressif Inc."},
  {max_prefix: "D8-80-83",vendor: "CLOUD NETWORK TECHNOLOGY SINGAPORE PTE. LTD."},
  {max_prefix: "FC-42-65",vendor: "Zhejiang Tmall Technology Co., Ltd."},
  {max_prefix: "18-77-58",vendor: "Audoo Limited (UK)"},
  {max_prefix: "44-D4-54",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "68-87-C6",vendor: "Cisco Systems, Inc"},
  {max_prefix: "80-24-8F",vendor: "Cisco Systems, Inc"},
  {max_prefix: "4C-50-F1",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "B4-E4-54",vendor: "Amazon Technologies Inc."},
  {max_prefix: "0C-43-F9",vendor: "Amazon Technologies Inc."},
  {max_prefix: "D4-94-FB",vendor: "Continental Automotive Systems Inc."},
  {max_prefix: "44-A9-2C",vendor: "IEEE Registration Authority"},
  {max_prefix: "84-AC-16",vendor: "Apple, Inc."},
  {max_prefix: "2C-BC-87",vendor: "Apple, Inc."},
  {max_prefix: "50-78-B0",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "E4-07-2B",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "24-46-E4",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "50-E7-A0",vendor: "Renesas Electronics (Penang) Sdn. Bhd."},
  {max_prefix: "90-E8-68",vendor: "AzureWave Technology Inc."},
  {max_prefix: "68-F0-D0",vendor: "SkyBell Technologies Inc."},
  {max_prefix: "FC-A8-9B",vendor: "Texas Instruments"},
  {max_prefix: "98-F0-7B",vendor: "Texas Instruments"},
  {max_prefix: "CC-89-6C",vendor: "GN Hearing A/S"},
  {max_prefix: "A4-FF-95",vendor: "Nokia"},
  {max_prefix: "D4-73-50",vendor: "DBG Commnunications Technology  Co., Ltd."},
  {max_prefix: "70-DA-17",vendor: "Austrian Audio GmbH"},
  {max_prefix: "38-61-A5",vendor: "Grabango Co"},
  {max_prefix: "78-D3-ED",vendor: "NORMA"},
  {max_prefix: "8C-1E-D9",vendor: "Beijing Unigroup Tsingteng Microsystem Co., LTD."},
  {max_prefix: "34-B4-72",vendor: "Espressif Inc."},
  {max_prefix: "F8-0C-58",vendor: "Taicang T&W Electronics"},
  {max_prefix: "50-41-1C",vendor: "AMPAK Technology,Inc."},
  {max_prefix: "F4-26-79",vendor: "Intel Corporate"},
  {max_prefix: "B0-37-95",vendor: "LG Electronics"},
  {max_prefix: "3C-7A-F0",vendor: "ITEL MOBILE LIMITED"},
  {max_prefix: "48-F3-F3",vendor: "Baidu Online Network Technology (Beijing) Co., Ltd"},
  {max_prefix: "08-1C-6E",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "50-81-40",vendor: "HP Inc."},
  {max_prefix: "FC-58-DF",vendor: "Interphone Service"},
  {max_prefix: "98-10-82",vendor: "Nsolution Co., Ltd."},
  {max_prefix: "38-A6-59",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "78-13-05",vendor: "IEEE Registration Authority"},
  {max_prefix: "34-24-3E",vendor: "zte corporation"},
  {max_prefix: "98-ED-7E",vendor: "eero inc."},
  {max_prefix: "34-FE-9E",vendor: "Fujitsu Limited"},
  {max_prefix: "38-45-3B",vendor: "Ruckus Wireless"},
  {max_prefix: "64-50-D6",vendor: "Liquidtool Systems"},
  {max_prefix: "F4-46-37",vendor: "Intel Corporate"},
  {max_prefix: "0C-71-8C",vendor: "TCT mobile ltd"},
  {max_prefix: "24-08-5D",vendor: "Continental Aftermarket & Services GmbH"},
  {max_prefix: "A8-48-FA",vendor: "Espressif Inc."},
  {max_prefix: "50-5D-7A",vendor: "zte corporation"},
  {max_prefix: "C4-12-34",vendor: "Apple, Inc."},
  {max_prefix: "3C-A6-F6",vendor: "Apple, Inc."},
  {max_prefix: "68-1B-EF",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "E4-DC-5F",vendor: "Cofractal, Inc."},
  {max_prefix: "4C-AB-4F",vendor: "Apple, Inc."},
  {max_prefix: "9C-58-3C",vendor: "Apple, Inc."},
  {max_prefix: "00-09-01",vendor: "Shenzhen Shixuntong Information & Technoligy Co"},
  {max_prefix: "F4-63-E7",vendor: "Nanjing Maxon O.E. Tech. Co., LTD"},
  {max_prefix: "88-AE-DD",vendor: "EliteGroup Computer Systems Co., LTD"},
  {max_prefix: "78-06-C9",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "E8-A6-CA",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "CC-FA-66",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "68-96-6A",vendor: "OHSUNG"},
  {max_prefix: "A0-E7-0B",vendor: "Intel Corporate"},
  {max_prefix: "04-EE-EE",vendor: "Laplace System Co., Ltd."},
  {max_prefix: "1C-3C-D4",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "F4-E4-51",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "48-22-18",vendor: "Shenzhen Yipingfang Network Technology Co., Ltd."},
  {max_prefix: "F8-8E-A1",vendor: "Edgecore Networks Corporation"},
  {max_prefix: "2C-48-81",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "E4-0C-FD",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "58-D6-97",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "30-A1-76",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "48-E7-DA",vendor: "AzureWave Technology Inc."},
  {max_prefix: "04-71-53",vendor: "SERNET (SUZHOU) TECHNOLOGIES CORPORATION"},
  {max_prefix: "40-C4-8C",vendor: "N-iTUS CO.,LTD."},
  {max_prefix: "54-37-BB",vendor: "Taicang T&W Electronics"},
  {max_prefix: "60-F8-F2",vendor: "Synaptec"},
  {max_prefix: "AC-74-B1",vendor: "Intel Corporate"},
  {max_prefix: "F4-60-77",vendor: "Texas Instruments"},
  {max_prefix: "C0-3C-04",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "A4-D7-3C",vendor: "Seiko Epson Corporation"},
  {max_prefix: "00-08-08",vendor: "PPT Vision, Inc."},
  {max_prefix: "6C-10-8B",vendor: "WeLink Communications"},
  {max_prefix: "4C-71-67",vendor: "PoLabs d.o.o."},
  {max_prefix: "7C-85-30",vendor: "Nokia"},
  {max_prefix: "24-A7-99",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "7C-3E-74",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "14-89-19",vendor: "2bps"},
  {max_prefix: "58-FD-5D",vendor: "Hangzhou Xinyun technology Co., Ltd."},
  {max_prefix: "18-52-07",vendor: "Sichuan Tianyi Comheart Telecom Co.,LTD"},
  {max_prefix: "E0-C6-3C",vendor: "Sichuan Tianyi Comheart Telecom Co.,LTD"},
  {max_prefix: "F8-BA-E6",vendor: "Nokia"},
  {max_prefix: "BC-EC-A0",vendor: "COMPAL INFORMATION (KUNSHAN) CO., LTD."},
  {max_prefix: "A4-55-90",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "08-01-0F",vendor: "Sichuan Tianyi Comheart Telecom Co.,LTD"},
  {max_prefix: "C0-CC-42",vendor: "Sichuan Tianyi Comheart Telecom Co.,LTD"},
  {max_prefix: "80-65-59",vendor: "EM Microelectronic"},
  {max_prefix: "D0-47-C1",vendor: "Elma Electronic AG"},
  {max_prefix: "B8-A3-77",vendor: "Cisco Systems, Inc"},
  {max_prefix: "E4-4E-2D",vendor: "Cisco Systems, Inc"},
  {max_prefix: "98-42-65",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "DC-21-5C",vendor: "Intel Corporate"},
  {max_prefix: "C4-23-60",vendor: "Intel Corporate"},
  {max_prefix: "78-D9-E9",vendor: "MOMENTUM IOT"},
  {max_prefix: "10-3D-1C",vendor: "Intel Corporate"},
  {max_prefix: "38-87-D5",vendor: "Intel Corporate"},
  {max_prefix: "1C-D1-07",vendor: "Realme Chongqing Mobile Telecommunications Corp.,Ltd."},
  {max_prefix: "9C-1C-37",vendor: "AltoBeam (China) Inc."},
  {max_prefix: "2C-08-23",vendor: "Sercomm France Sarl"},
  {max_prefix: "A8-93-4A",vendor: "CHONGQING FUGUI ELECTRONICS CO.,LTD."},
  {max_prefix: "C8-7B-23",vendor: "Bose Corporation"},
  {max_prefix: "00-04-7D",vendor: "Motorola Solutions Inc."},
  {max_prefix: "8C-19-B5",vendor: "Arcadyan Corporation"},
  {max_prefix: "34-AB-95",vendor: "Espressif Inc."},
  {max_prefix: "C4-91-CF",vendor: "Luxul"},
  {max_prefix: "D8-9A-C1",vendor: "Nokia"},
  {max_prefix: "F0-B1-1D",vendor: "Nokia"},
  {max_prefix: "7C-D9-F4",vendor: "UAB Teltonika Telematics"},
  {max_prefix: "A4-D7-95",vendor: "Wingtech Mobile Communications Co.,Ltd"},
  {max_prefix: "84-AB-26",vendor: "Tiinlab Corporation"},
  {max_prefix: "58-35-6B",vendor: "TECNO MOBILE LIMITED"},
  {max_prefix: "F8-97-53",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "58-94-AE",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "B0-3A-CE",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "F8-4C-DA",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "BC-FF-4D",vendor: "Espressif Inc."},
  {max_prefix: "A4-05-6E",vendor: "Tiinlab Corporation"},
  {max_prefix: "FC-4E-A4",vendor: "Apple, Inc."},
  {max_prefix: "F4-BE-EC",vendor: "Apple, Inc."},
  {max_prefix: "54-E6-1B",vendor: "Apple, Inc."},
  {max_prefix: "CC-68-B6",vendor: "TP-Link Corporation Limited"},
  {max_prefix: "14-DD-9C",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "64-64-4A",vendor: "Beijing Xiaomi Mobile Software Co., Ltd"},
  {max_prefix: "E8-9E-0C",vendor: "MAX8USA DISTRIBUTORS INC."},
  {max_prefix: "64-80-8B",vendor: "VG Controls, Inc."},
  {max_prefix: "FC-13-F0",vendor: "Bouffalo Lab (Nanjing) Co., Ltd."},
  {max_prefix: "20-89-6F",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "F4-FB-B8",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "A0-70-B7",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "78-B5-54",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "68-9E-6A",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "28-2B-96",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "A4-39-B6",vendor: "SHENZHEN PEIZHE MICROELECTRONICS CO .LTD"},
  {max_prefix: "00-04-AD",vendor: "Malibu Networks"},
  {max_prefix: "C4-5B-BE",vendor: "Espressif Inc."},
  {max_prefix: "00-26-18",vendor: "ASUSTek COMPUTER INC."},
  {max_prefix: "CC-33-31",vendor: "Texas Instruments"},
  {max_prefix: "FC-9C-98",vendor: "Arlo Technology"},
  {max_prefix: "1C-6E-E6",vendor: "NHNETWORKS"},
  {max_prefix: "08-F6-06",vendor: "zte corporation"},
  {max_prefix: "B8-14-DB",vendor: "OHSUNG"},
  {max_prefix: "1C-D1-E0",vendor: "Cisco Systems, Inc"},
  {max_prefix: "E8-FD-35",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "EC-C5-D2",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "40-44-FD",vendor: "Realme Chongqing Mobile Telecommunications Corp.,Ltd."},
  {max_prefix: "64-6E-E0",vendor: "Intel Corporate"},
  {max_prefix: "04-56-E5",vendor: "Intel Corporate"},
  {max_prefix: "88-46-04",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "84-1E-A3",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "8C-2A-8E",vendor: "DongGuan Ramaxel Memory Technology"},
  {max_prefix: "80-07-1B",vendor: "VSOLUTION TELECOMMUNICATION TECHNOLOGY CO.,LTD."},
  {max_prefix: "24-45-6B",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "48-38-71",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "44-BD-DE",vendor: "BHTC GmbH"},
  {max_prefix: "B4-60-8C",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "F4-02-23",vendor: "PAX Computer Technology(Shenzhen) Ltd."},
  {max_prefix: "64-79-F0",vendor: "Intel Corporate"},
  {max_prefix: "08-10-86",vendor: "NEC Platforms, Ltd."},
  {max_prefix: "78-65-3B",vendor: "Shaoxing Ourten Electronics Co., Ltd."},
  {max_prefix: "E0-E6-56",vendor: "Nethesis  srl"},
  {max_prefix: "4C-F2-02",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "A8-77-E5",vendor: "SHENZHEN CHUANGWEI-RGB ELECTRONICS CO.,LTD"},
  {max_prefix: "10-A4-DA",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "FC-58-4A",vendor: "xiamenshi c-chip technology co., ltd"},
  {max_prefix: "84-D6-08",vendor: "Wingtech Mobile Communications Co., Ltd."},
  {max_prefix: "34-68-93",vendor: "Tecnovideo Srl"},
  {max_prefix: "90-23-B4",vendor: "New H3C Technologies Co., Ltd"},
  {max_prefix: "88-2A-5E",vendor: "New H3C Technologies Co., Ltd"},
  {max_prefix: "24-69-68",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "FC-A9-DC",vendor: "Renesas Electronics (Penang) Sdn. Bhd."},
  {max_prefix: "3C-7A-AA",vendor: "China Dragon Technology Limited"},
  {max_prefix: "84-FD-27",vendor: "Silicon Laboratories"},
  {max_prefix: "28-D3-EA",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "A8-F2-66",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "CC-9C-3E",vendor: "Cisco Meraki"},
  {max_prefix: "84-26-7A",vendor: "GUANGDONG TAIDE ZHILIAN TECHNOLOGY CO.,LTD"},
  {max_prefix: "6C-47-60",vendor: "Sunitec Enterprise Co.,Ltd"},
  {max_prefix: "18-32-19",vendor: "EM Microelectronic"},
  {max_prefix: "00-20-C1",vendor: "SAXA, Inc."},
  {max_prefix: "48-29-E4",vendor: "AO"},
  {max_prefix: "3C-9B-C6",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "00-36-BE",vendor: "Northwest Towers"},
  {max_prefix: "A4-5E-5A",vendor: "ACTIVIO Inc."},
  {max_prefix: "7C-50-79",vendor: "Intel Corporate"},
  {max_prefix: "80-38-FB",vendor: "Intel Corporate"},
  {max_prefix: "50-1F-C6",vendor: "Apple, Inc."},
  {max_prefix: "CC-69-FA",vendor: "Apple, Inc."},
  {max_prefix: "10-CE-E9",vendor: "Apple, Inc."},
  {max_prefix: "5C-56-A4",vendor: "Wanan Hongsheng Electronic Co.Ltd"},
  {max_prefix: "64-26-56",vendor: "Shenzhen Fanweitai Technology Service Co.,Ltd"},
  {max_prefix: "AC-82-47",vendor: "Intel Corporate"},
  {max_prefix: "F0-25-8E",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "9C-74-6F",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "AC-74-C4",vendor: "Maytronics Ltd."},
  {max_prefix: "54-09-10",vendor: "Apple, Inc."},
  {max_prefix: "9C-FC-28",vendor: "Apple, Inc."},
  {max_prefix: "B4-85-E1",vendor: "Apple, Inc."},
  {max_prefix: "0C-19-F8",vendor: "Apple, Inc."},
  {max_prefix: "08-B4-B1",vendor: "Google, Inc."},
  {max_prefix: "38-20-28",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "E4-77-27",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "10-51-07",vendor: "Intel Corporate"},
  {max_prefix: "74-9A-C0",vendor: "Cachengo, Inc."},
  {max_prefix: "34-58-7C",vendor: "MIRAE INFORMATION TECHNOLOGY CO., LTD."},
  {max_prefix: "A0-3B-01",vendor: "Kyung In Electronics"},
  {max_prefix: "14-5E-69",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "C4-E2-87",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "54-F6-07",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "A0-A3-F0",vendor: "D-Link International"},
  {max_prefix: "E8-A0-CD",vendor: "Nintendo Co.,Ltd"},
  {max_prefix: "9C-82-3F",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "E0-6C-4E",vendor: "Shenzhen TINNO Mobile Technology Corp."},
  {max_prefix: "50-55-8D",vendor: "China Mobile IOT Company Limited"},
  {max_prefix: "64-E0-03",vendor: "Hui Zhou Gaoshengda Technology Co.,LTD"},
  {max_prefix: "34-F7-16",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "84-65-69",vendor: "New H3C Technologies Co., Ltd"},
  {max_prefix: "28-1B-04",vendor: "Zalliant LLC"},
  {max_prefix: "7C-52-59",vendor: "Sichuan Jiuzhou Electronic Technology Co., Ltd."},
  {max_prefix: "F4-B3-01",vendor: "Intel Corporate"},
  {max_prefix: "E8-D2-FF",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "0C-96-CD",vendor: "MERCURY CORPORATION"},
  {max_prefix: "18-CE-94",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "78-37-16",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "5C-6F-69",vendor: "Broadcom Limited"},
  {max_prefix: "D0-12-CB",vendor: "AVM Audiovisuelles Marketing und Computersysteme GmbH"},
  {max_prefix: "78-CF-2F",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "A8-3B-5C",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "04-5F-B9",vendor: "Cisco Systems, Inc"},
  {max_prefix: "10-2F-A3",vendor: "Shenzhen Uvision-tech Technology Co.Ltd"},
  {max_prefix: "04-49-5D",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "F0-FE-E7",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "E8-F4-08",vendor: "Intel Corporate"},
  {max_prefix: "58-B0-FE",vendor: "Team EPS GmbH"},
  {max_prefix: "DC-CD-74",vendor: "Japan E.M.Solutions Co., Ltd."},
  {max_prefix: "74-8B-29",vendor: "Micobiomed"},
  {max_prefix: "48-4C-29",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "C4-D4-38",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "54-21-1D",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "14-3F-A6",vendor: "Sony Home Entertainment&Sound Products Inc"},
  {max_prefix: "44-D4-53",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "DC-A1-20",vendor: "Nokia"},
  {max_prefix: "50-52-3B",vendor: "Nokia"},
  {max_prefix: "24-06-AA",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "8C-AE-49",vendor: "IEEE Registration Authority"},
  {max_prefix: "20-FF-36",vendor: "IFLYTEK CO.,LTD."},
  {max_prefix: "24-64-9F",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "0C-17-73",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "E8-1E-92",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "88-8E-68",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "80-74-84",vendor: "ALL Winner (Hong Kong) Limited"},
  {max_prefix: "88-23-8C",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "A8-CC-6F",vendor: "HMD Global Oy"},
  {max_prefix: "A4-CC-B9",vendor: "Realme Chongqing Mobile Telecommunications Corp.,Ltd."},
  {max_prefix: "6C-B8-81",vendor: "zte corporation"},
  {max_prefix: "98-9A-B9",vendor: "zte corporation"},
  {max_prefix: "00-8A-55",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "64-A2-8A",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "AC-47-1B",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "00-31-92",vendor: "TP-Link Corporation Limited"},
  {max_prefix: "08-65-F0",vendor: "JM Zengge Co., Ltd"},
  {max_prefix: "14-AB-02",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "B0-45-30",vendor: "SKY UK LIMITED"},
  {max_prefix: "6C-A0-B4",vendor: "SKY UK LIMITED"},
  {max_prefix: "EC-0D-E4",vendor: "Amazon Technologies Inc."},
  {max_prefix: "B4-56-E3",vendor: "Apple, Inc."},
  {max_prefix: "84-30-95",vendor: "Hon Hai Precision IND.CO.,LTD"},
  {max_prefix: "3C-61-05",vendor: "Espressif Inc."},
  {max_prefix: "4C-20-B8",vendor: "Apple, Inc."},
  {max_prefix: "14-88-E6",vendor: "Apple, Inc."},
  {max_prefix: "78-95-EB",vendor: "ITEL MOBILE LIMITED"},
  {max_prefix: "B0-BB-E5",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "1C-9F-4E",vendor: "COOSEA GROUP (HK) COMPANY LIMITED"},
  {max_prefix: "A4-68-BC",vendor: "Oakley Inc."},
  {max_prefix: "8C-FD-DE",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "48-D8-90",vendor: "FN-LINK TECHNOLOGY LIMITED"},
  {max_prefix: "B0-C5-3C",vendor: "Cisco Systems, Inc"},
  {max_prefix: "EC-CE-13",vendor: "Cisco Systems, Inc"},
  {max_prefix: "C4-18-E9",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "1C-E5-7F",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "08-55-31",vendor: "Routerboard.com"},
  {max_prefix: "24-11-45",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "00-04-2B",vendor: "IT Access Co., Ltd."},
  {max_prefix: "D4-4F-67",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "F8-B9-5A",vendor: "LG Innotek"},
  {max_prefix: "78-1F-11",vendor: "RAB Lighting"},
  {max_prefix: "10-71-00",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "60-7E-A4",vendor: "Shanghai Imilab Technology Co.Ltd"},
  {max_prefix: "B4-FF-98",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "08-38-E6",vendor: "Motorola (Wuhan) Mobility Technologies Communication Co., Ltd."},
  {max_prefix: "E8-C2-DD",vendor: "Infinix mobility limited"},
  {max_prefix: "C4-1C-9C",vendor: "JiQiDao"},
  {max_prefix: "98-F1-81",vendor: "New H3C Technologies Co., Ltd"},
  {max_prefix: "8C-55-BB",vendor: "Songwoo Information & Technology Co., Ltd"},
  {max_prefix: "7C-8F-DE",vendor: "DWnet Technologies(Suzhou) Corporation"},
  {max_prefix: "90-80-8F",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "18-45-16",vendor: "Texas Instruments"},
  {max_prefix: "D0-2E-AB",vendor: "Texas Instruments"},
  {max_prefix: "84-71-27",vendor: "Silicon Laboratories"},
  {max_prefix: "F0-F7-E7",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "84-9D-C2",vendor: "Shanghai MXCHIP Information Technology Co., Ltd."},
  {max_prefix: "40-A9-CF",vendor: "Amazon Technologies Inc."},
  {max_prefix: "58-95-D8",vendor: "IEEE Registration Authority"},
  {max_prefix: "F8-0D-AC",vendor: "HP Inc."},
  {max_prefix: "88-50-F6",vendor: "Shenzhen Jingxun Software Telecommunication Technology Co.,Ltd"},
  {max_prefix: "04-05-DD",vendor: "Shenzhen Cultraview Digital Technology Co., Ltd"},
  {max_prefix: "38-97-A4",vendor: "ELECOM CO.,LTD."},
  {max_prefix: "E4-33-AE",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "38-7A-3C",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "8C-CE-4E",vendor: "Espressif Inc."},
  {max_prefix: "40-B5-C1",vendor: "Cisco Systems, Inc"},
  {max_prefix: "E4-47-91",vendor: "Iris ID Systems, Inc."},
  {max_prefix: "F0-13-C1",vendor: "Hannto Technology Co., Ltd"},
  {max_prefix: "84-54-DF",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "E8-84-A5",vendor: "Intel Corporate"},
  {max_prefix: "00-C0-35",vendor: "QUINTAR COMPANY"},
  {max_prefix: "1C-50-1E",vendor: "Sunplus Technology Co., Ltd."},
  {max_prefix: "FC-66-CF",vendor: "Apple, Inc."},
  {max_prefix: "AC-1D-06",vendor: "Apple, Inc."},
  {max_prefix: "44-A8-FC",vendor: "Apple, Inc."},
  {max_prefix: "F8-10-93",vendor: "Apple, Inc."},
  {max_prefix: "DC-E9-94",vendor: "CLOUD NETWORK TECHNOLOGY SINGAPORE PTE. LTD."},
  {max_prefix: "40-95-05",vendor: "ACOINFO TECHNOLOGY CO.,LTD"},
  {max_prefix: "5C-D8-9E",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "B8-2D-28",vendor: "AMPAK Technology,Inc."},
  {max_prefix: "A4-13-4E",vendor: "Luxul"},
  {max_prefix: "B8-5F-98",vendor: "Amazon Technologies Inc."},
  {max_prefix: "D8-F8-83",vendor: "Intel Corporate"},
  {max_prefix: "B4-36-D1",vendor: "Renesas Electronics (Penang) Sdn. Bhd."},
  {max_prefix: "10-19-65",vendor: "New H3C Technologies Co., Ltd"},
  {max_prefix: "94-FF-61",vendor: "China Mobile Group Device Co.,Ltd."},
  {max_prefix: "DC-77-4C",vendor: "Cisco Systems, Inc"},
  {max_prefix: "20-44-41",vendor: "Remote Solution"},
  {max_prefix: "FC-44-82",vendor: "Intel Corporate"},
  {max_prefix: "A0-85-FC",vendor: "Microsoft Corporation"},
  {max_prefix: "14-A9-D0",vendor: "F5 Networks, Inc."},
  {max_prefix: "00-0A-49",vendor: "F5 Networks, Inc."},
  {max_prefix: "00-94-A1",vendor: "F5 Networks, Inc."},
  {max_prefix: "80-F1-F1",vendor: "Tech4home, Lda"},
  {max_prefix: "00-61-51",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "BC-76-C5",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "5C-02-72",vendor: "Silicon Laboratories"},
  {max_prefix: "00-04-30",vendor: "Netgem"},
  {max_prefix: "04-46-CF",vendor: "Beijing Venustech Cybervision Co.,Ltd."},
  {max_prefix: "B8-80-35",vendor: "Shenzhen Qihu Intelligent Technology Company Limited"},
  {max_prefix: "90-12-A1",vendor: "We Corporation Inc."},
  {max_prefix: "64-B6-23",vendor: "Schrack Seconet Care Communication GmbH"},
  {max_prefix: "00-0E-FF",vendor: "Megasolution,Inc."},
  {max_prefix: "70-2C-09",vendor: "Nintendo Co.,Ltd"},
  {max_prefix: "E4-D3-73",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "C0-BC-9A",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "E8-1B-69",vendor: "Sercomm Corporation."},
  {max_prefix: "68-52-D6",vendor: "UGame Technology Co.,Ltd"},
  {max_prefix: "FC-19-99",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "CC-87-4A",vendor: "Nokia"},
  {max_prefix: "A4-51-29",vendor: "XAG"},
  {max_prefix: "B4-C2-6A",vendor: "Garmin International"},
  {max_prefix: "78-F8-B8",vendor: "Rako Controls Ltd"},
  {max_prefix: "6C-E5-F7",vendor: "New H3C Technologies Co., Ltd"},
  {max_prefix: "B8-DD-71",vendor: "zte corporation"},
  {max_prefix: "F4-E5-78",vendor: "LLC Proizvodstvennaya Kompania TransService"},
  {max_prefix: "08-54-BB",vendor: "SHENZHEN CHUANGWEI-RGB ELECTRONICS CO.,LTD"},
  {max_prefix: "3C-9C-0F",vendor: "Intel Corporate"},
  {max_prefix: "1C-5D-80",vendor: "Mitubishi Hitachi Power Systems Industries Co., Ltd."},
  {max_prefix: "3C-B1-5B",vendor: "Avaya Inc"},
  {max_prefix: "58-65-E6",vendor: "infomark"},
  {max_prefix: "08-FB-EA",vendor: "AMPAK Technology,Inc."},
  {max_prefix: "60-EB-5A",vendor: "Asterfusion Data Technologies Co.,Ltd"},
  {max_prefix: "5C-44-3E",vendor: "Skullcandy"},
  {max_prefix: "F8-82-00",vendor: "CaptionCall"},
  {max_prefix: "00-50-F1",vendor: "Maxlinear, Inc"},
  {max_prefix: "80-16-05",vendor: "Vodafone Italia S.p.A."},
  {max_prefix: "A8-02-DB",vendor: "zte corporation"},
  {max_prefix: "74-F7-F6",vendor: "Shanghai Sunmi Technology Co.,Ltd."},
  {max_prefix: "24-1A-E6",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "C4-54-44",vendor: "Quanta Computer Inc."},
  {max_prefix: "00-1B-24",vendor: "Quanta Computer Inc."},
  {max_prefix: "00-C0-9F",vendor: "Quanta Computer Inc."},
  {max_prefix: "C8-0A-A9",vendor: "Quanta Computer Inc."},
  {max_prefix: "60-EB-69",vendor: "Quanta Computer Inc."},
  {max_prefix: "B4-23-30",vendor: "Itron Inc"},
  {max_prefix: "00-08-95",vendor: "DIRC Technologie GmbH & Co.KG"},
  {max_prefix: "00-26-9E",vendor: "Quanta Computer Inc."},
  {max_prefix: "A0-22-DE",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "FC-73-FB",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "60-B7-6E",vendor: "Google, Inc."},
  {max_prefix: "00-92-7D",vendor: "Ficosa Internationa(Taicang) C0.,Ltd."},
  {max_prefix: "44-16-22",vendor: "Microsoft Corporation"},
  {max_prefix: "04-45-62",vendor: "ANDRA Sp. z o. o."},
  {max_prefix: "94-AE-F0",vendor: "Cisco Systems, Inc"},
  {max_prefix: "DC-D9-AE",vendor: "Nokia Shanghai Bell Co., Ltd."},
  {max_prefix: "C4-36-6C",vendor: "LG Innotek"},
  {max_prefix: "1C-08-C1",vendor: "LG Innotek"},
  {max_prefix: "BC-A9-93",vendor: "Cambium Networks Limited"},
  {max_prefix: "A4-97-B1",vendor: "CHONGQING FUGUI ELECTRONICS CO.,LTD."},
  {max_prefix: "44-E6-B0",vendor: "China Mobile IOT Company Limited"},
  {max_prefix: "74-7A-90",vendor: "Murata Manufacturing Co., Ltd."},
  {max_prefix: "58-B6-23",vendor: "Beijing Xiaomi Mobile Software Co., Ltd"},
  {max_prefix: "14-00-7D",vendor: "zte corporation"},
  {max_prefix: "24-E9-CA",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "D4-40-D0",vendor: "OCOSMOS Co., LTD"},
  {max_prefix: "98-B8-BC",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "70-3A-A6",vendor: "New H3C Technologies Co., Ltd"},
  {max_prefix: "8C-CE-FD",vendor: "Shenzhen zhouhai technology co.,LTD"},
  {max_prefix: "50-FB-19",vendor: "CHIPSEA TECHNOLOGIES (SHENZHEN) CORP."},
  {max_prefix: "94-08-C7",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "C8-CA-63",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "94-37-F7",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "18-4E-16",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "C0-3D-03",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "10-74-6F",vendor: "MOTOROLA SOLUTIONS MALAYSIA SDN. BHD."},
  {max_prefix: "D0-C6-37",vendor: "Intel Corporate"},
  {max_prefix: "D4-92-34",vendor: "NEC Corporation"},
  {max_prefix: "38-68-93",vendor: "Intel Corporate"},
  {max_prefix: "90-3F-EA",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "20-AB-48",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "CC-D7-3C",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "94-E7-0B",vendor: "Intel Corporate"},
  {max_prefix: "04-6C-59",vendor: "Intel Corporate"},
  {max_prefix: "D4-AB-CD",vendor: "Hui Zhou Gaoshengda Technology Co.,LTD"},
  {max_prefix: "A0-CA-A5",vendor: "INTELLIGENCE TECHNOLOGY OF CEC CO., LTD"},
  {max_prefix: "E8-85-4B",vendor: "Apple, Inc."},
  {max_prefix: "E0-0E-E4",vendor: "DWnet Technologies(Suzhou) Corporation"},
  {max_prefix: "58-FD-B1",vendor: "LG Electronics"},
  {max_prefix: "34-EA-E7",vendor: "Shanghai High-Flying Electronics  Technology Co., Ltd"},
  {max_prefix: "CC-9E-CA",vendor: "HMD Global Oy"},
  {max_prefix: "28-EC-95",vendor: "Apple, Inc."},
  {max_prefix: "E0-2B-96",vendor: "Apple, Inc."},
  {max_prefix: "18-45-93",vendor: "Taicang T&W Electronics"},
  {max_prefix: "F8-6C-03",vendor: "Shenzhen Teleone Technology Co., Ltd"},
  {max_prefix: "10-5D-DC",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "DC-73-85",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "54-55-D5",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "F0-41-C6",vendor: "Heat Tech Company, Ltd."},
  {max_prefix: "C4-98-86",vendor: "Qorvo International Pte. Ltd."},
  {max_prefix: "E4-3A-65",vendor: "MofiNetwork Inc"},
  {max_prefix: "54-21-9D",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "3C-30-6F",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "80-E1-BF",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "48-2C-D0",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "D8-71-4D",vendor: "Texas Instruments"},
  {max_prefix: "0C-EC-80",vendor: "Texas Instruments"},
  {max_prefix: "70-03-9F",vendor: "Espressif Inc."},
  {max_prefix: "70-9F-2D",vendor: "zte corporation"},
  {max_prefix: "5C-0F-FB",vendor: "Amino Communications Ltd"},
  {max_prefix: "E8-2A-44",vendor: "Liteon Technology Corporation"},
  {max_prefix: "48-E1-E9",vendor: "Chengdu Meross Technology Co., Ltd."},
  {max_prefix: "08-B0-55",vendor: "ASKEY COMPUTER CORP"},
  {max_prefix: "A0-DE-0F",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "F4-87-C5",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "88-2B-94",vendor: "MADOKA SYSTEM Co.,Ltd."},
  {max_prefix: "7C-EF-61",vendor: "STR Elektronik Josef Schlechtinger GmbH"},
  {max_prefix: "40-05-89",vendor: "T-Mobile, USA"},
  {max_prefix: "64-A9-65",vendor: "Linkflow Co., Ltd."},
  {max_prefix: "3C-F6-52",vendor: "zte corporation"},
  {max_prefix: "A8-05-77",vendor: "Netlist, Inc."},
  {max_prefix: "40-40-28",vendor: "ZIV"},
  {max_prefix: "74-58-F3",vendor: "Amazon Technologies Inc."},
  {max_prefix: "08-AA-55",vendor: "Motorola Mobility LLC, a Lenovo Company"},
  {max_prefix: "00-13-54",vendor: "Zcomax Technologies, Inc."},
  {max_prefix: "C0-9B-F4",vendor: "IEEE Registration Authority"},
  {max_prefix: "30-B2-37",vendor: "GD Midea Air-Conditioning Equipment Co.,Ltd."},
  {max_prefix: "68-E2-09",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "F4-30-8B",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "DC-6B-12",vendor: "worldcns inc."},
  {max_prefix: "AC-4A-56",vendor: "Cisco Systems, Inc"},
  {max_prefix: "B0-B5-C3",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "28-23-73",vendor: "Digita"},
  {max_prefix: "D0-AB-D5",vendor: "Intel Corporate"},
  {max_prefix: "D4-C1-C8",vendor: "zte corporation"},
  {max_prefix: "88-D2-74",vendor: "zte corporation"},
  {max_prefix: "BC-5A-56",vendor: "Cisco Systems, Inc"},
  {max_prefix: "70-4A-0E",vendor: "AMPAK Technology,Inc."},
  {max_prefix: "BC-0F-9A",vendor: "D-Link International"},
  {max_prefix: "4C-CE-2D",vendor: "Danlaw Inc"},
  {max_prefix: "0C-35-FE",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "8C-83-DF",vendor: "Nokia"},
  {max_prefix: "30-B9-B0",vendor: "Intracom Asia Co., Ltd"},
  {max_prefix: "D4-DA-CD",vendor: "SKY UK LIMITED"},
  {max_prefix: "F4-B7-8D",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "A4-16-E7",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "B4-09-31",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "94-E7-EA",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "94-E4-BA",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "34-71-46",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "2C-C5-46",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "0C-83-9A",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "E0-E0-FC",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "AC-4B-1E",vendor: "Integri-Sys.Com LLC"},
  {max_prefix: "68-69-CA",vendor: "Hitachi, Ltd."},
  {max_prefix: "B0-E4-D5",vendor: "Google, Inc."},
  {max_prefix: "88-C3-97",vendor: "Beijing Xiaomi Mobile Software Co., Ltd"},
  {max_prefix: "E0-D4-E8",vendor: "Intel Corporate"},
  {max_prefix: "30-50-75",vendor: "GN Audio A/S"},
  {max_prefix: "90-EC-77",vendor: "silicom"},
  {max_prefix: "B4-40-A4",vendor: "Apple, Inc."},
  {max_prefix: "48-B8-A3",vendor: "Apple, Inc."},
  {max_prefix: "F4-DB-E3",vendor: "Apple, Inc."},
  {max_prefix: "BC-42-8C",vendor: "ALPSALPINE CO,.LTD"},
  {max_prefix: "04-BD-BF",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "BC-7A-BF",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "60-68-4E",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "80-20-FD",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "B4-CE-40",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "D4-5E-EC",vendor: "Beijing Xiaomi Electronics Co., Ltd."},
  {max_prefix: "4C-45-76",vendor: "China Mobile(Hangzhou) Information Technology Co.,Ltd."},
  {max_prefix: "74-C9-29",vendor: "Zhejiang Dahua Technology Co., Ltd."},
  {max_prefix: "94-CC-04",vendor: "IEEE Registration Authority"},
  {max_prefix: "9C-C9-EB",vendor: "NETGEAR"},
  {max_prefix: "5C-B2-9E",vendor: "ASCO Power Technologies"},
  {max_prefix: "34-78-39",vendor: "zte corporation"},
  {max_prefix: "D8-4D-B9",vendor: "Wu Qi Technologies,Inc."},
  {max_prefix: "A0-4F-85",vendor: "LG Electronics (Mobile Communications)"},
  {max_prefix: "2C-1A-01",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "24-16-9D",vendor: "Cisco Systems, Inc"},
  {max_prefix: "F4-19-E2",vendor: "Volterra"},
  {max_prefix: "64-F2-FB",vendor: "Hangzhou Ezviz Software Co.,Ltd."},
  {max_prefix: "D8-07-B6",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "64-6E-97",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "6C-0D-34",vendor: "Nokia"},
  {max_prefix: "00-23-3D",vendor: "Laird Technologies"},
  {max_prefix: "6C-16-32",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "30-80-9B",vendor: "New H3C Technologies Co., Ltd"},
  {max_prefix: "74-22-BB",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "4C-63-71",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "5C-BA-EF",vendor: "CHONGQING FUGUI ELECTRONICS CO.,LTD."},
  {max_prefix: "00-0A-DB",vendor: "Trilliant"},
  {max_prefix: "30-AB-6A",vendor: "SAMSUNG ELECTRO-MECHANICS(THAILAND)"},
  {max_prefix: "8C-60-78",vendor: "Swissbit AG"},
  {max_prefix: "F8-0D-F0",vendor: "zte corporation"},
  {max_prefix: "9C-E9-1C",vendor: "zte corporation"},
  {max_prefix: "74-9B-E8",vendor: "Hitron Technologies. Inc"},
  {max_prefix: "00-DD-25",vendor: "Shenzhen hechengdong Technology Co., Ltd"},
  {max_prefix: "04-45-A1",vendor: "NIRIT- Xinwei  Telecom Technology Co., Ltd."},
  {max_prefix: "80-20-E1",vendor: "BVBA DPTechnics"},
  {max_prefix: "8C-97-EA",vendor: "FREEBOX SAS"},
  {max_prefix: "FC-8E-6E",vendor: "StreamCCTV, LLC"},
  {max_prefix: "78-8B-2A",vendor: "Zhen Shi Information Technology (Shanghai) Co., Ltd."},
  {max_prefix: "AC-64-CF",vendor: "FN-LINK TECHNOLOGY LIMITED"},
  {max_prefix: "3C-FA-D3",vendor: "IEEE Registration Authority"},
  {max_prefix: "5C-17-CF",vendor: "OnePlus Technology (Shenzhen) Co., Ltd"},
  {max_prefix: "10-29-59",vendor: "Apple, Inc."},
  {max_prefix: "F0-67-28",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "E0-2A-E6",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "14-F6-D8",vendor: "Intel Corporate"},
  {max_prefix: "3C-DA-6D",vendor: "Tiandy Technologies CO.,LTD"},
  {max_prefix: "A4-FA-76",vendor: "New H3C Technologies Co., Ltd"},
  {max_prefix: "9C-E1-76",vendor: "Cisco Systems, Inc"},
  {max_prefix: "B4-1A-1D",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "E4-76-84",vendor: "Apple, Inc."},
  {max_prefix: "F0-5C-D5",vendor: "Apple, Inc."},
  {max_prefix: "70-EA-5A",vendor: "Apple, Inc."},
  {max_prefix: "4C-E1-76",vendor: "Cisco Systems, Inc"},
  {max_prefix: "14-34-F6",vendor: "LV SOLUTION SDN. BHD."},
  {max_prefix: "18-AF-A1",vendor: "Shenzhen Yifang Network Technology Co., Ltd."},
  {max_prefix: "54-CE-69",vendor: "Hikari Trading Co.,Ltd."},
  {max_prefix: "6C-AE-F6",vendor: "eero inc."},
  {max_prefix: "C0-14-B8",vendor: "Nokia"},
  {max_prefix: "D0-28-BA",vendor: "Realme Chongqing MobileTelecommunications Corp Ltd"},
  {max_prefix: "A4-28-B7",vendor: "Yangtze Memory Technologies Co., Ltd."},
  {max_prefix: "94-92-D2",vendor: "KCF Technologies, Inc."},
  {max_prefix: "E4-A8-DF",vendor: "COMPAL INFORMATION (KUNSHAN) CO., LTD."},
  {max_prefix: "70-2F-35",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "8C-53-C3",vendor: "Beijing Xiaomi Mobile Software Co., Ltd"},
  {max_prefix: "D8-3B-BF",vendor: "Intel Corporate"},
  {max_prefix: "00-0D-BB",vendor: "Nippon Dentsu Co.,Ltd."},
  {max_prefix: "0C-EE-99",vendor: "Amazon Technologies Inc."},
  {max_prefix: "FC-F5-C4",vendor: "Espressif Inc."},
  {max_prefix: "E8-26-B6",vendor: "Companies House to GlucoRx Technologies Ltd."},
  {max_prefix: "00-76-3D",vendor: "Veea"},
  {max_prefix: "48-B0-2D",vendor: "NVIDIA Corporation"},
  {max_prefix: "58-8E-81",vendor: "Silicon Laboratories"},
  {max_prefix: "90-2B-34",vendor: "GIGA-BYTE TECHNOLOGY CO.,LTD."},
  {max_prefix: "94-DE-80",vendor: "GIGA-BYTE TECHNOLOGY CO.,LTD."},
  {max_prefix: "74-D4-35",vendor: "GIGA-BYTE TECHNOLOGY CO.,LTD."},
  {max_prefix: "40-8D-5C",vendor: "GIGA-BYTE TECHNOLOGY CO.,LTD."},
  {max_prefix: "6C-C6-3B",vendor: "Taicang T&W Electronics"},
  {max_prefix: "30-93-BC",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "F4-FE-FB",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "3C-41-0E",vendor: "Cisco Systems, Inc"},
  {max_prefix: "20-74-54",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "B8-C9-B5",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "98-49-14",vendor: "Wistron Neweb Corporation"},
  {max_prefix: "F8-6F-DE",vendor: "Shenzhen Goodix Technology Co.,Ltd."},
  {max_prefix: "00-45-E2",vendor: "CyberTAN Technology Inc."},
  {max_prefix: "04-3F-72",vendor: "Mellanox Technologies, Inc."},
  {max_prefix: "1C-4D-66",vendor: "Amazon Technologies Inc."},
  {max_prefix: "64-66-24",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "B0-F5-30",vendor: "Hitron Technologies. Inc"},
  {max_prefix: "00-1F-F6",vendor: "PS Audio International"},
  {max_prefix: "00-69-67",vendor: "IEEE Registration Authority"},
  {max_prefix: "00-1E-B8",vendor: "Aloys, Inc"},
  {max_prefix: "0C-E4-A0",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "BC-1A-E4",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "74-0A-E1",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "B4-A8-98",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "50-2C-C6",vendor: "GREE ELECTRIC APPLIANCES, INC. OF ZHUHAI"},
  {max_prefix: "00-27-E3",vendor: "Cisco Systems, Inc"},
  {max_prefix: "08-9C-86",vendor: "Nokia Shanghai Bell Co., Ltd."},
  {max_prefix: "F0-51-36",vendor: "TCT mobile ltd"},
  {max_prefix: "78-17-BE",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "90-AF-D1",vendor: "netKTI Co., Ltd"},
  {max_prefix: "E0-40-07",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "90-FD-73",vendor: "zte corporation"},
  {max_prefix: "88-AC-C0",vendor: "Zyxel Communications Corporation"},
  {max_prefix: "1C-1E-38",vendor: "PCCW Global, Inc."},
  {max_prefix: "90-81-2A",vendor: "Apple, Inc."},
  {max_prefix: "B8-7B-C5",vendor: "Apple, Inc."},
  {max_prefix: "40-70-F5",vendor: "Apple, Inc."},
  {max_prefix: "B0-35-B5",vendor: "Apple, Inc."},
  {max_prefix: "80-0C-67",vendor: "Apple, Inc."},
  {max_prefix: "F0-72-EA",vendor: "Google, Inc."},
  {max_prefix: "EC-9C-32",vendor: "Sichuan\u00a0AI-Link\u00a0Technology\u00a0Co.,\u00a0Ltd."},
  {max_prefix: "4C-AD-A8",vendor: "PANOPTICS CORP."},
  {max_prefix: "98-1B-B5",vendor: "ASSA ABLOY Korea Co., Ltd iRevo"},
  {max_prefix: "B8-87-C6",vendor: "Prudential Technology co.,LTD"},
  {max_prefix: "FC-1C-A1",vendor: "Nokia"},
  {max_prefix: "6C-D2-BA",vendor: "zte corporation"},
  {max_prefix: "1C-1A-DF",vendor: "Microsoft Corporation"},
  {max_prefix: "D4-F5-47",vendor: "Google, Inc."},
  {max_prefix: "34-CB-1A",vendor: "Procter & Gamble Company"},
  {max_prefix: "F0-B1-07",vendor: "Ericsson AB"},
  {max_prefix: "E8-D0-3C",vendor: "Shenzhen Jingxun Software Telecommunication Technology Co.,Ltd"},
  {max_prefix: "70-CE-8C",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "78-3A-6C",vendor: "TECNO MOBILE LIMITED"},
  {max_prefix: "C4-E0-DE",vendor: "Zhengzhou XindaJiean Information Technology Co.,Ltd."},
  {max_prefix: "90-1A-4F",vendor: "EM Microelectronic"},
  {max_prefix: "00-04-C9",vendor: "Micro Electron Co., Ltd."},
  {max_prefix: "C4-3A-35",vendor: "FN-LINK TECHNOLOGY LIMITED"},
  {max_prefix: "04-D1-6E",vendor: "IEEE Registration Authority"},
  {max_prefix: "D8-8A-DC",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "CC-6A-10",vendor: "The Chamberlain Group, Inc"},
  {max_prefix: "C8-4F-0E",vendor: "Integrated Device Technology (Malaysia) Sdn. Bhd."},
  {max_prefix: "10-E9-53",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "30-3A-BA",vendor: "Guangzhou BaoLun Electronics Co., Ltd"},
  {max_prefix: "7C-48-B2",vendor: "Vida Resources Lte Ltd"},
  {max_prefix: "04-0E-3C",vendor: "HP Inc."},
  {max_prefix: "6C-1C-71",vendor: "Zhejiang Dahua Technology Co., Ltd."},
  {max_prefix: "0C-2F-B0",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "F0-3F-95",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "18-56-44",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "9C-69-D1",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "78-C8-81",vendor: "Sony Interactive Entertainment Inc."},
  {max_prefix: "70-48-F7",vendor: "Nintendo Co.,Ltd"},
  {max_prefix: "18-E1-CA",vendor: "wanze"},
  {max_prefix: "CC-EF-03",vendor: "Hunan Keyshare Communication Technology Co., Ltd."},
  {max_prefix: "EC-BE-DD",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "88-57-1D",vendor: "Seongji Industry Company"},
  {max_prefix: "B0-4F-C3",vendor: "Shenzhen NVC Cloud Technology Co., Ltd."},
  {max_prefix: "30-91-76",vendor: "Skyworth Digital Technology(Shenzhen) Co.,Ltd"},
  {max_prefix: "B4-02-16",vendor: "Cisco Systems, Inc"},
  {max_prefix: "54-A4-93",vendor: "IEEE Registration Authority"},
  {max_prefix: "8C-C8-4B",vendor: "CHONGQING FUGUI ELECTRONICS CO.,LTD."},
  {max_prefix: "74-9E-A5",vendor: "OHSUNG"},
  {max_prefix: "D4-4F-68",vendor: "Eidetic Communications Inc"},
  {max_prefix: "34-0F-66",vendor: "Web Sensing LLC"},
  {max_prefix: "00-22-72",vendor: "American Micro-Fuel Device Corp."},
  {max_prefix: "00-D0-EF",vendor: "IGT"},
  {max_prefix: "D8-97-90",vendor: "Commonwealth Scientific and Industrial Research Organisation"},
  {max_prefix: "40-55-82",vendor: "Nokia"},
  {max_prefix: "A4-E3-1B",vendor: "Nokia"},
  {max_prefix: "B8-A5-8D",vendor: "Axe Group Holdings Limited"},
  {max_prefix: "50-CE-E3",vendor: "Gigafirm.co.LTD"},
  {max_prefix: "F4-97-C2",vendor: "Nebulon Inc"},
  {max_prefix: "30-FB-B8",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "F4-BD-9E",vendor: "Cisco Systems, Inc"},
  {max_prefix: "58-85-E9",vendor: "Realme Chongqing MobileTelecommunications Corp Ltd"},
  {max_prefix: "A4-45-19",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "84-46-FE",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "D8-29-18",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "4C-1D-96",vendor: "Intel Corporate"},
  {max_prefix: "BC-23-92",vendor: "BYD Precision Manufacture Company Ltd."},
  {max_prefix: "94-E6-F7",vendor: "Intel Corporate"},
  {max_prefix: "08-60-83",vendor: "zte corporation"},
  {max_prefix: "E0-19-54",vendor: "zte corporation"},
  {max_prefix: "7C-F3-1B",vendor: "LG Electronics (Mobile Communications)"},
  {max_prefix: "10-32-7E",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "C4-19-D1",vendor: "Telink Semiconductor (Shanghai) Co., Ltd."},
  {max_prefix: "64-B2-1D",vendor: "Chengdu Phycom Tech Co., Ltd."},
  {max_prefix: "C4-29-96",vendor: "Signify B.V."},
  {max_prefix: "B0-65-F1",vendor: "WIO Manufacturing HK Limited"},
  {max_prefix: "90-12-34",vendor: "Shenzhen YOUHUA Technology Co., Ltd"},
  {max_prefix: "38-C4-E8",vendor: "NSS Sp. z o.o."},
  {max_prefix: "34-DD-7E",vendor: "Umeox Innovations Co.,Ltd"},
  {max_prefix: "CC-CD-64",vendor: "SM-Electronic GmbH"},
  {max_prefix: "24-DF-A7",vendor: "Hangzhou BroadLink Technology Co.,Ltd"},
  {max_prefix: "5C-92-5E",vendor: "Zioncom Electronics (Shenzhen) Ltd."},
  {max_prefix: "08-4F-A9",vendor: "Cisco Systems, Inc"},
  {max_prefix: "08-4F-F9",vendor: "Cisco Systems, Inc"},
  {max_prefix: "98-06-37",vendor: "IEEE Registration Authority"},
  {max_prefix: "8C-B8-4A",vendor: "SAMSUNG ELECTRO-MECHANICS(THAILAND)"},
  {max_prefix: "98-E8-FA",vendor: "Nintendo Co.,Ltd"},
  {max_prefix: "F8-08-4F",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "18-02-AE",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "0C-20-D3",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "68-DB-F5",vendor: "Amazon Technologies Inc."},
  {max_prefix: "24-46-C8",vendor: "Motorola Mobility LLC, a Lenovo Company"},
  {max_prefix: "50-98-B8",vendor: "New H3C Technologies Co., Ltd"},
  {max_prefix: "44-D7-91",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "B8-4D-EE",vendor: "Hisense broadband multimedia technology Co.,Ltd"},
  {max_prefix: "D4-6B-A6",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "CC-05-77",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "98-BA-39",vendor: "Doro AB"},
  {max_prefix: "E4-CC-9D",vendor: "Integrated Device Technology (Malaysia) Sdn. Bhd."},
  {max_prefix: "A8-D0-E3",vendor: "Systech Electronics Ltd"},
  {max_prefix: "30-8B-B2",vendor: "Cisco Systems, Inc"},
  {max_prefix: "A8-93-52",vendor: "SHANGHAI ZHONGMI COMMUNICATION TECHNOLOGY CO.,LTD"},
  {max_prefix: "E0-EB-62",vendor: "Shanghai Hulu Devices Co., Ltd"},
  {max_prefix: "6C-E8-C6",vendor: "Earda Technologies co Ltd"},
  {max_prefix: "1C-41-76",vendor: "China Mobile Group Device Co.,Ltd."},
  {max_prefix: "48-21-6C",vendor: "China Mobile IOT Company Limited"},
  {max_prefix: "8C-BE-24",vendor: "Tashang Semiconductor(Shanghai) Co., Ltd."},
  {max_prefix: "94-DC-4E",vendor: "AEV, spol. s r. o."},
  {max_prefix: "54-DE-D0",vendor: "Sevio Srl"},
  {max_prefix: "08-B3-AF",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "30-86-2D",vendor: "Arista Network, Inc."},
  {max_prefix: "E8-6F-38",vendor: "CHONGQING FUGUI ELECTRONICS CO.,LTD."},
  {max_prefix: "08-68-8D",vendor: "New H3C Technologies Co., Ltd"},
  {max_prefix: "40-19-20",vendor: "Movon Corporation"},
  {max_prefix: "60-8B-0E",vendor: "Apple, Inc."},
  {max_prefix: "14-42-FC",vendor: "Texas Instruments"},
  {max_prefix: "AC-5D-5C",vendor: "FN-LINK TECHNOLOGY LIMITED"},
  {max_prefix: "A4-AE-11",vendor: "Hon Hai Precision Industry Co., Ltd."},
  {max_prefix: "2C-1E-4F",vendor: "Chengdu Qianli Network Technology Co., Ltd."},
  {max_prefix: "00-90-52",vendor: "SELCOM ELETTRONICA S.R.L."},
  {max_prefix: "18-71-D5",vendor: "Hazens Automotive Electronics(SZ)Co.,Ltd."},
  {max_prefix: "AC-B1-EE",vendor: "SHENZHEN FENDA TECHNOLOGY CO., LTD"},
  {max_prefix: "F8-AD-CB",vendor: "HMD Global Oy"},
  {max_prefix: "D0-37-45",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "60-3A-7C",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "00-01-78",vendor: "MARGI Systems, Inc."},
  {max_prefix: "00-1A-83",vendor: "Pegasus Technologies Inc."},
  {max_prefix: "50-E0-85",vendor: "Intel Corporate"},
  {max_prefix: "6C-5E-3B",vendor: "Cisco Systems, Inc"},
  {max_prefix: "58-C8-76",vendor: "China Mobile (Hangzhou) Information Technology Co., Ltd."},
  {max_prefix: "D4-62-EA",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "54-BA-D6",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "24-16-6D",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "94-0B-19",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "70-C7-F2",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "DC-A6-32",vendor: "Raspberry Pi Trading Ltd"},
  {max_prefix: "88-F5-6E",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "C8-C2-FA",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "88-B3-62",vendor: "Nokia Shanghai Bell Co., Ltd."},
  {max_prefix: "08-47-D0",vendor: "Nokia Shanghai Bell Co., Ltd."},
  {max_prefix: "CC-90-93",vendor: "Hansong Tehnologies"},
  {max_prefix: "BC-97-E1",vendor: "Broadcom Limited"},
  {max_prefix: "28-D1-B7",vendor: "Shenzhen YOUHUA Technology Co., Ltd"},
  {max_prefix: "38-94-ED",vendor: "NETGEAR"},
  {max_prefix: "70-04-33",vendor: "California Things Inc."},
  {max_prefix: "CC-64-A6",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "14-84-30",vendor: "MITAC COMPUTING TECHNOLOGY CORPORATION"},
  {max_prefix: "B8-A4-4F",vendor: "Axis Communications AB"},
  {max_prefix: "00-24-EB",vendor: "ClearPath Networks, Inc."},
  {max_prefix: "D8-13-99",vendor: "Hui Zhou Gaoshengda Technology Co.,LTD"},
  {max_prefix: "54-E0-19",vendor: "Ring LLC"},
  {max_prefix: "F0-A9-68",vendor: "Antailiye Technology Co.,Ltd"},
  {max_prefix: "50-AF-4D",vendor: "zte corporation"},
  {max_prefix: "C8-EA-F8",vendor: "zte corporation"},
  {max_prefix: "84-8B-CD",vendor: "IEEE Registration Authority"},
  {max_prefix: "08-ED-ED",vendor: "Zhejiang Dahua Technology Co., Ltd."},
  {max_prefix: "38-3B-26",vendor: "Jiangsu Qinheng Co., Ltd."},
  {max_prefix: "30-31-7D",vendor: "Hosiden Corporation"},
  {max_prefix: "5C-FA-FB",vendor: "Acubit"},
  {max_prefix: "9C-7B-EF",vendor: "Hewlett Packard"},
  {max_prefix: "88-29-9C",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "7C-89-56",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "74-2E-DB",vendor: "Perinet GmbH"},
  {max_prefix: "20-17-42",vendor: "LG Electronics"},
  {max_prefix: "CC-88-26",vendor: "LG Innotek"},
  {max_prefix: "EC-5B-73",vendor: "Advanced & Wise Technology Corp."},
  {max_prefix: "D0-9C-7A",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "1C-69-7A",vendor: "EliteGroup Computer Systems Co., LTD"},
  {max_prefix: "4C-17-44",vendor: "Amazon Technologies Inc."},
  {max_prefix: "B0-30-55",vendor: "China Mobile IOT Company Limited"},
  {max_prefix: "D4-4D-A4",vendor: "Murata Manufacturing Co., Ltd."},
  {max_prefix: "DC-71-96",vendor: "Intel Corporate"},
  {max_prefix: "90-5C-34",vendor: "Sirius Electronic Systems Srl"},
  {max_prefix: "D4-6A-35",vendor: "Cisco Systems, Inc"},
  {max_prefix: "F8-E5-CF",vendor: "CGI IT UK LIMITED"},
  {max_prefix: "C8-2C-2B",vendor: "IEEE Registration Authority"},
  {max_prefix: "80-20-DA",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "D4-20-B0",vendor: "Mist Systems, Inc."},
  {max_prefix: "00-22-AF",vendor: "Safety Vision, LLC"},
  {max_prefix: "A0-91-A2",vendor: "OnePlus Electronics (Shenzhen) Co., Ltd."},
  {max_prefix: "00-80-B5",vendor: "UNITED NETWORKS INC."},
  {max_prefix: "00-A0-B0",vendor: "I-O DATA DEVICE,INC."},
  {max_prefix: "9C-49-7F",vendor: "Integrated Device Technology (Malaysia) Sdn. Bhd."},
  {max_prefix: "C4-E3-9F",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "24-79-F3",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "80-A2-35",vendor: "Edgecore Networks Corporation"},
  {max_prefix: "40-23-43",vendor: "CHONGQING FUGUI ELECTRONICS CO.,LTD."},
  {max_prefix: "00-21-B7",vendor: "LEXMARK INTERNATIONAL, INC."},
  {max_prefix: "C8-C6-4A",vendor: "Flextronics Tech.(Ind) Pvt Ltd"},
  {max_prefix: "30-EA-26",vendor: "Sycada BV"},
  {max_prefix: "18-F1-8E",vendor: "ChipER Technology co. ltd"},
  {max_prefix: "F8-9A-78",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "88-F8-72",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "EC-56-23",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "54-86-BC",vendor: "Cisco Systems, Inc"},
  {max_prefix: "B8-08-CF",vendor: "Intel Corporate"},
  {max_prefix: "68-84-7E",vendor: "FUJITSU LIMITED"},
  {max_prefix: "00-30-85",vendor: "Cisco Systems, Inc"},
  {max_prefix: "60-5F-8D",vendor: "eero inc."},
  {max_prefix: "C4-B3-6A",vendor: "Cisco Systems, Inc"},
  {max_prefix: "70-F7-54",vendor: "AMPAK Technology,Inc."},
  {max_prefix: "6C-8B-D3",vendor: "Cisco Systems, Inc"},
  {max_prefix: "68-97-4B",vendor: "Shenzhen Costar Electronics Co. Ltd."},
  {max_prefix: "34-E1-D1",vendor: "IEEE Registration Authority"},
  {max_prefix: "14-B4-57",vendor: "Silicon Laboratories"},
  {max_prefix: "DC-96-2C",vendor: "NST Audio Ltd"},
  {max_prefix: "50-EC-50",vendor: "Beijing Xiaomi Mobile Software Co., Ltd"},
  {max_prefix: "60-63-F9",vendor: "Ciholas, Inc."},
  {max_prefix: "AC-8F-F8",vendor: "Nokia"},
  {max_prefix: "60-03-A6",vendor: "Inteno Broadband Technology AB"},
  {max_prefix: "44-B2-95",vendor: "Sichuan\u00a0AI-Link\u00a0Technology\u00a0Co.,\u00a0Ltd."},
  {max_prefix: "94-24-E1",vendor: "Alcatel-Lucent Enterprise"},
  {max_prefix: "10-82-86",vendor: "Luxshare Precision Industry Co.,Ltd"},
  {max_prefix: "60-61-DF",vendor: "Z-meta Research LLC"},
  {max_prefix: "70-57-BF",vendor: "New H3C Technologies Co., Ltd"},
  {max_prefix: "08-97-98",vendor: "COMPAL INFORMATION (KUNSHAN) CO., LTD."},
  {max_prefix: "18-02-2D",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "D8-BC-59",vendor: "Shenzhen DAPU Microelectronics Co., Ltd"},
  {max_prefix: "8C-79-F5",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "24-6F-28",vendor: "Espressif Inc."},
  {max_prefix: "00-12-2A",vendor: "VTech Telecommunications Ltd."},
  {max_prefix: "00-04-22",vendor: "Studio Technologies, Inc"},
  {max_prefix: "80-DA-13",vendor: "eero inc."},
  {max_prefix: "48-F8-DB",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "C0-8A-CD",vendor: "Guangzhou Shiyuan Electronic Technology Company Limited"},
  {max_prefix: "D8-1E-DD",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "B0-51-8E",vendor: "Holl technology CO.Ltd."},
  {max_prefix: "68-17-29",vendor: "Intel Corporate"},
  {max_prefix: "28-52-E0",vendor: "Layon international Electronic & Telecom Co.,Ltd"},
  {max_prefix: "7C-61-66",vendor: "Amazon Technologies Inc."},
  {max_prefix: "98-9B-CB",vendor: "AVM Audiovisuelles Marketing und Computersysteme GmbH"},
  {max_prefix: "AC-F6-F7",vendor: "LG Electronics (Mobile Communications)"},
  {max_prefix: "58-CB-52",vendor: "Google, Inc."},
  {max_prefix: "F8-CA-59",vendor: "NetComm Wireless"},
  {max_prefix: "88-B2-91",vendor: "Apple, Inc."},
  {max_prefix: "C4-2A-D0",vendor: "Apple, Inc."},
  {max_prefix: "CC-D2-81",vendor: "Apple, Inc."},
  {max_prefix: "F8-4D-33",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "10-77-17",vendor: "SHENZHEN CHUANGWEI-RGB ELECTRONICS CO.,LTD"},
  {max_prefix: "A8-6D-5F",vendor: "Raisecom Technology CO., LTD"},
  {max_prefix: "48-E6-C0",vendor: "SIMCom Wireless Solutions Co.,Ltd."},
  {max_prefix: "38-3C-9C",vendor: "Fujian Newland Payment Technology Co.,Ltd."},
  {max_prefix: "C0-2E-25",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "20-0D-B0",vendor: "Shenzhen Four Seas Global Link Network Technology Co., Ltd."},
  {max_prefix: "10-0C-6B",vendor: "NETGEAR"},
  {max_prefix: "58-EC-ED",vendor: "Integrated Device Technology (Malaysia) Sdn. Bhd."},
  {max_prefix: "C4-F0-EC",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "D8-8D-C8",vendor: "Atil Technology Co., LTD"},
  {max_prefix: "E8-0F-C8",vendor: "Universal Electronics, Inc."},
  {max_prefix: "C8-AA-CC",vendor: "Private"},
  {max_prefix: "00-21-67",vendor: "HWA JIN T&I Corp."},
  {max_prefix: "04-EE-03",vendor: "Texas Instruments"},
  {max_prefix: "4C-24-98",vendor: "Texas Instruments"},
  {max_prefix: "7C-D9-5C",vendor: "Google, Inc."},
  {max_prefix: "1C-2E-1B",vendor: "Suzhou Tremenet Communication Technology Co., Ltd."},
  {max_prefix: "90-7A-58",vendor: "Zegna-Daidong Limited"},
  {max_prefix: "E0-09-BF",vendor: "SHENZHEN\u2002TONG BO WEI\u2002TECHNOLOGY Co.,LTD"},
  {max_prefix: "B0-00-73",vendor: "Wistron Neweb Corporation"},
  {max_prefix: "00-1B-F7",vendor: "Lund IP Products AB"},
  {max_prefix: "84-69-91",vendor: "Nokia"},
  {max_prefix: "00-13-1E",vendor: "peiker acustic GmbH"},
  {max_prefix: "78-36-07",vendor: "Cermate Technologies Inc."},
  {max_prefix: "1C-24-EB",vendor: "Burlywood"},
  {max_prefix: "00-10-13",vendor: "Kontron America, Inc."},
  {max_prefix: "A8-E2-C1",vendor: "Texas Instruments"},
  {max_prefix: "90-9A-77",vendor: "Texas Instruments"},
  {max_prefix: "00-EE-AB",vendor: "Cisco Systems, Inc"},
  {max_prefix: "54-A7-03",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "58-C6-F0",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "84-A0-6E",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "88-4A-18",vendor: "Opulinks"},
  {max_prefix: "A4-3E-A0",vendor: "iComm HK LIMITED"},
  {max_prefix: "64-C2-DE",vendor: "LG Electronics (Mobile Communications)"},
  {max_prefix: "8C-44-4F",vendor: "HUMAX Co., Ltd."},
  {max_prefix: "00-67-62",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "2C-C4-07",vendor: "machineQ"},
  {max_prefix: "00-07-CB",vendor: "FREEBOX SAS"},
  {max_prefix: "14-9F-B6",vendor: "GUANGDONG GENIUS TECHNOLOGY CO., LTD."},
  {max_prefix: "00-11-5A",vendor: "Ivoclar Vivadent AG"},
  {max_prefix: "40-DF-02",vendor: "LINE BIZ Plus"},
  {max_prefix: "DC-31-D1",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "D4-3B-04",vendor: "Intel Corporate"},
  {max_prefix: "B4-ED-19",vendor: "Pie Digital, Inc."},
  {max_prefix: "84-13-9F",vendor: "zte corporation"},
  {max_prefix: "2C-2B-F9",vendor: "LG Innotek"},
  {max_prefix: "90-02-18",vendor: "SKY UK LIMITED"},
  {max_prefix: "14-4E-2A",vendor: "Ciena Corporation"},
  {max_prefix: "74-95-EC",vendor: "ALPSALPINE CO,.LTD"},
  {max_prefix: "AC-5A-EE",vendor: "China Mobile Group Device Co.,Ltd."},
  {max_prefix: "F0-51-EA",vendor: "Fitbit, Inc."},
  {max_prefix: "50-33-F0",vendor: "YICHEN (SHENZHEN) TECHNOLOGY CO.LTD"},
  {max_prefix: "4C-E1-9E",vendor: "TECNO MOBILE LIMITED"},
  {max_prefix: "44-1A-FA",vendor: "New H3C Technologies Co., Ltd"},
  {max_prefix: "04-07-2E",vendor: "VTech Electronics Ltd."},
  {max_prefix: "FC-2B-B2",vendor: "Actiontec Electronics, Inc"},
  {max_prefix: "E0-9F-2A",vendor: "Iton Technology Corp."},
  {max_prefix: "70-BC-10",vendor: "Microsoft Corporation"},
  {max_prefix: "9C-69-B4",vendor: "IEEE Registration Authority"},
  {max_prefix: "00-06-F7",vendor: "ALPSALPINE CO,.LTD"},
  {max_prefix: "00-07-04",vendor: "ALPSALPINE CO,.LTD"},
  {max_prefix: "00-06-F5",vendor: "ALPSALPINE CO,.LTD"},
  {max_prefix: "34-C7-31",vendor: "ALPSALPINE CO,.LTD"},
  {max_prefix: "64-D4-BD",vendor: "ALPSALPINE CO,.LTD"},
  {max_prefix: "04-98-F3",vendor: "ALPSALPINE CO,.LTD"},
  {max_prefix: "50-00-84",vendor: "Siemens Canada"},
  {max_prefix: "44-B4-33",vendor: "tide.co.,ltd"},
  {max_prefix: "00-21-4F",vendor: "ALPSALPINE CO,.LTD"},
  {max_prefix: "24-7D-4D",vendor: "Texas Instruments"},
  {max_prefix: "DC-21-B9",vendor: "Sentec Co.Ltd"},
  {max_prefix: "6C-DF-FB",vendor: "IEEE Registration Authority"},
  {max_prefix: "20-32-33",vendor: "SHENZHEN BILIAN ELECTRONIC CO.\uff0cLTD"},
  {max_prefix: "E4-98-BB",vendor: "Phyplus Microelectronics Limited"},
  {max_prefix: "70-5E-55",vendor: "Realme Chongqing MobileTelecommunications Corp Ltd"},
  {max_prefix: "B0-D5-68",vendor: "Shenzhen Cultraview Digital Technology Co., Ltd"},
  {max_prefix: "F0-0E-BF",vendor: "ZettaHash Inc."},
  {max_prefix: "04-EA-56",vendor: "Intel Corporate"},
  {max_prefix: "60-A1-1E",vendor: "Wuhan Maxsine Electric Co.,Ltd."},
  {max_prefix: "C4-5B-F7",vendor: "ants"},
  {max_prefix: "8C-DF-9D",vendor: "NEC Corporation"},
  {max_prefix: "70-35-09",vendor: "Cisco Systems, Inc"},
  {max_prefix: "5C-41-5A",vendor: "Amazon.com, LLC"},
  {max_prefix: "A8-B4-56",vendor: "Cisco Systems, Inc"},
  {max_prefix: "A4-94-26",vendor: "Elgama-Elektronika Ltd."},
  {max_prefix: "2C-A9-F0",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "54-9B-72",vendor: "Ericsson AB"},
  {max_prefix: "94-54-DF",vendor: "YST CORP."},
  {max_prefix: "78-0E-D1",vendor: "TRUMPF Werkzeugmaschinen GmbH+Co.KG"},
  {max_prefix: "F8-2F-08",vendor: "Molex CMS"},
  {max_prefix: "A0-47-D7",vendor: "Best IT World (India) Pvt Ltd"},
  {max_prefix: "68-99-CD",vendor: "Cisco Systems, Inc"},
  {max_prefix: "10-40-F3",vendor: "Apple, Inc."},
  {max_prefix: "44-28-A3",vendor: "Jiangsu fulian  Communication Technology Co., Ltd."},
  {max_prefix: "10-C5-95",vendor: "Lenovo"},
  {max_prefix: "68-29-DC",vendor: "Ficosa Electronics S.L.U."},
  {max_prefix: "F8-0D-F1",vendor: "Sontex SA"},
  {max_prefix: "7C-BC-84",vendor: "IEEE Registration Authority"},
  {max_prefix: "44-E6-6E",vendor: "Apple, Inc."},
  {max_prefix: "C0-E8-62",vendor: "Apple, Inc."},
  {max_prefix: "F4-06-16",vendor: "Apple, Inc."},
  {max_prefix: "58-6B-14",vendor: "Apple, Inc."},
  {max_prefix: "BC-B8-63",vendor: "Apple, Inc."},
  {max_prefix: "0C-FE-5D",vendor: "IEEE Registration Authority"},
  {max_prefix: "3C-8D-20",vendor: "Google, Inc."},
  {max_prefix: "60-1D-91",vendor: "Motorola Mobility LLC, a Lenovo Company"},
  {max_prefix: "D4-C9-4B",vendor: "Motorola Mobility LLC, a Lenovo Company"},
  {max_prefix: "AC-15-85",vendor: "silergy corp"},
  {max_prefix: "AC-50-93",vendor: "Magna Electronics Europe GmbH & Co. OHG"},
  {max_prefix: "70-BB-E9",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "00-D0-2D",vendor: "Resideo"},
  {max_prefix: "2C-1C-F6",vendor: "Alien Green LLC"},
  {max_prefix: "B0-7E-11",vendor: "Texas Instruments"},
  {max_prefix: "08-35-1B",vendor: "Shenzhen Jialihua Electronic Technology Co., Ltd"},
  {max_prefix: "80-69-40",vendor: "LEXAR CO.,LIMITED"},
  {max_prefix: "64-F8-1C",vendor: "Huawei Technologies Co., Ltd."},
  {max_prefix: "10-C7-53",vendor: "Qingdao Intelligent&Precise Electronics Co.,Ltd."},
  {max_prefix: "F4-95-1B",vendor: "Hefei Radio Communication Technology Co., Ltd"},
  {max_prefix: "6C-38-45",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "00-B1-E3",vendor: "Cisco Systems, Inc"},
  {max_prefix: "A4-11-94",vendor: "Lenovo"},
  {max_prefix: "2C-61-04",vendor: "SHENZHEN FENGLIAN TECHNOLOGY CO., LTD."},
  {max_prefix: "BC-93-25",vendor: "Ningbo Joyson Preh Car Connect Co.,Ltd."},
  {max_prefix: "0C-1C-19",vendor: "LONGCONN ELECTRONICS(SHENZHEN) CO.,LTD"},
  {max_prefix: "E0-13-B5",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "E0-79-5E",vendor: "Wuxi Xiaohu Technology Co.,Ltd."},
  {max_prefix: "8C-85-E6",vendor: "Cleondris GmbH"},
  {max_prefix: "90-E7-10",vendor: "New H3C Technologies Co., Ltd"},
  {max_prefix: "30-29-52",vendor: "Hillstone Networks Inc"},
  {max_prefix: "04-92-26",vendor: "ASUSTek COMPUTER INC."},
  {max_prefix: "E8-AD-A6",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "D0-B6-0A",vendor: "Xingluo Technology Company  Limited"},
  {max_prefix: "A4-FC-77",vendor: "Mega Well Limited"},
  {max_prefix: "50-A0-A4",vendor: "Nokia"},
  {max_prefix: "10-98-C3",vendor: "Murata Manufacturing Co., Ltd."},
  {max_prefix: "84-32-6F",vendor: "GUANGZHOU AVA ELECTRONICS TECHNOLOGY CO.,LTD"},
  {max_prefix: "C8-9C-13",vendor: "Inspiremobile"},
  {max_prefix: "E4-B2-FB",vendor: "Apple, Inc."},
  {max_prefix: "2C-CA-0C",vendor: "WITHUS PLANET"},
  {max_prefix: "44-00-49",vendor: "Amazon Technologies Inc."},
  {max_prefix: "18-38-AE",vendor: "CONSPIN SOLUTION"},
  {max_prefix: "04-CF-8C",vendor: "XIAOMI Electronics,CO.,LTD"},
  {max_prefix: "0C-75-12",vendor: "Shenzhen Kunlun TongTai Technology  Co.,Ltd."},
  {max_prefix: "0C-41-01",vendor: "Ruichi Auto Technology (Guangzhou) Co., Ltd."},
  {max_prefix: "00-B7-71",vendor: "Cisco Systems, Inc"},
  {max_prefix: "80-7D-14",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "20-28-3E",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "F8-38-80",vendor: "Apple, Inc."},
  {max_prefix: "4C-56-9D",vendor: "Apple, Inc."},
  {max_prefix: "38-53-9C",vendor: "Apple, Inc."},
  {max_prefix: "40-26-19",vendor: "Apple, Inc."},
  {max_prefix: "6C-E8-5C",vendor: "Apple, Inc."},
  {max_prefix: "B0-AE-25",vendor: "Varikorea"},
  {max_prefix: "04-91-62",vendor: "Microchip Technology Inc."},
  {max_prefix: "98-3B-8F",vendor: "Intel Corporate"},
  {max_prefix: "54-27-8D",vendor: "NXP (China) Management Ltd."},
  {max_prefix: "B0-BE-76",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "D0-03-DF",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "6C-C3-74",vendor: "Texas Instruments"},
  {max_prefix: "68-47-49",vendor: "Texas Instruments"},
  {max_prefix: "F8-D9-B8",vendor: "Open Mesh, Inc."},
  {max_prefix: "7C-69-6B",vendor: "Atmosic Technologies"},
  {max_prefix: "5C-D2-0B",vendor: "Yytek Co., Ltd."},
  {max_prefix: "00-50-0C",vendor: "e-Tek Labs, Inc."},
  {max_prefix: "48-5F-99",vendor: "Cloud Network Technology (Samoa) Limited"},
  {max_prefix: "88-F7-BF",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "D8-7D-7F",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "EC-C4-0D",vendor: "Nintendo Co.,Ltd"},
  {max_prefix: "50-57-9C",vendor: "Seiko Epson Corporation"},
  {max_prefix: "88-34-FE",vendor: "Bosch Automotive Products (Suzhou) Co. Ltd"},
  {max_prefix: "2C-79-D7",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "00-B4-F5",vendor: "DongGuan Siyoto Electronics Co., Ltd"},
  {max_prefix: "4C-1B-86",vendor: "Arcadyan Corporation"},
  {max_prefix: "58-B5-68",vendor: "SECURITAS DIRECT ESPA\u00d1A, SAU"},
  {max_prefix: "C4-23-A2",vendor: "PT. Emsonic Indonesia"},
  {max_prefix: "B4-CB-57",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "FC-03-9F",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "00-07-3A",vendor: "INVENTEL"},
  {max_prefix: "00-26-6C",vendor: "INVENTEC CORPORATION"},
  {max_prefix: "00-8C-FA",vendor: "INVENTEC CORPORATION"},
  {max_prefix: "84-6A-66",vendor: "Sumitomo Kizai  Co.,Ltd."},
  {max_prefix: "5C-FB-7C",vendor: "Shenzhen Jingxun Software Telecommunication Technology Co.,Ltd"},
  {max_prefix: "10-A2-4E",vendor: "GOLD3LINK ELECTRONICS CO., LTD"},
  {max_prefix: "88-10-8F",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "F4-63-1F",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "A4-9B-4F",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "00-05-1A",vendor: "3COM EUROPE LTD"},
  {max_prefix: "08-00-4E",vendor: "3COM EUROPE LTD"},
  {max_prefix: "00-30-1E",vendor: "3COM EUROPE LTD"},
  {max_prefix: "00-50-04",vendor: "3COM"},
  {max_prefix: "00-01-03",vendor: "3COM"},
  {max_prefix: "02-C0-8C",vendor: "3COM"},
  {max_prefix: "18-D7-17",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "80-B6-24",vendor: "IVS"},
  {max_prefix: "DC-F5-05",vendor: "AzureWave Technology Inc."},
  {max_prefix: "CC-F0-FD",vendor: "China Mobile (Hangzhou) Information Technology Co., Ltd."},
  {max_prefix: "84-89-EC",vendor: "IEEE Registration Authority"},
  {max_prefix: "1C-1B-B5",vendor: "Intel Corporate"},
  {max_prefix: "00-57-C1",vendor: "LG Electronics (Mobile Communications)"},
  {max_prefix: "7C-24-0C",vendor: "Telechips, Inc."},
  {max_prefix: "00-20-3D",vendor: "Honeywell Environmental & Combustion Controls"},
  {max_prefix: "00-40-84",vendor: "Honeywell"},
  {max_prefix: "D4-74-1B",vendor: "Beijing HuaDa ZhiBao Electronic System Co.,Ltd."},
  {max_prefix: "A0-95-0C",vendor: "China Mobile IOT Company Limited"},
  {max_prefix: "2C-15-E1",vendor: "Phicomm (Shanghai) Co., Ltd."},
  {max_prefix: "A4-D9-90",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "DC-F7-19",vendor: "Cisco Systems, Inc"},
  {max_prefix: "30-D1-6B",vendor: "Liteon Technology Corporation"},
  {max_prefix: "00-60-87",vendor: "KANSAI ELECTRIC CO., LTD."},
  {max_prefix: "98-AE-71",vendor: "VVDN Technologies Pvt Ltd"},
  {max_prefix: "AC-6E-1A",vendor: "SHENZHEN GONGJIN ELECTRONICS CO.,LT"},
  {max_prefix: "30-D3-2D",vendor: "devolo AG"},
  {max_prefix: "00-80-EB",vendor: "COMPCONTROL B.V."},
  {max_prefix: "00-02-EB",vendor: "Pico Communications"},
  {max_prefix: "34-2E-B6",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "AC-92-32",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "DC-8B-28",vendor: "Intel Corporate"},
  {max_prefix: "B8-69-F4",vendor: "Routerboard.com"},
  {max_prefix: "00-0F-B0",vendor: "Compal Electronics INC."},
  {max_prefix: "00-02-3F",vendor: "Compal Electronics INC."},
  {max_prefix: "00-15-55",vendor: "DFM GmbH"},
  {max_prefix: "BC-F2-AF",vendor: "devolo AG"},
  {max_prefix: "E0-AF-4F",vendor: "Deutsche Telekom AG"},
  {max_prefix: "68-A8-E1",vendor: "Wacom Co.,Ltd."},
  {max_prefix: "28-3A-4D",vendor: "Cloud Network Technology (Samoa) Limited"},
  {max_prefix: "B8-7C-6F",vendor: "NXP (China) Management Ltd."},
  {max_prefix: "30-5D-A6",vendor: "ADVALY SYSTEM Inc."},
  {max_prefix: "BC-30-D9",vendor: "Arcadyan Corporation"},
  {max_prefix: "04-79-B7",vendor: "Texas Instruments"},
  {max_prefix: "C0-D0-FF",vendor: "China Mobile IOT Company Limited"},
  {max_prefix: "88-DF-9E",vendor: "New H3C Technologies Co., Ltd"},
  {max_prefix: "2C-7C-E4",vendor: "Wuhan Tianyu Information Industry Co., Ltd."},
  {max_prefix: "24-D7-6B",vendor: "Syntronic AB"},
  {max_prefix: "C4-FE-E2",vendor: "AMICCOM Electronics Corporation"},
  {max_prefix: "14-48-02",vendor: "THE YEOLRIM Co.,Ltd."},
  {max_prefix: "FC-4A-E9",vendor: "Castlenet Technology Inc."},
  {max_prefix: "40-31-3C",vendor: "XIAOMI Electronics,CO.,LTD"},
  {max_prefix: "FC-45-96",vendor: "COMPAL INFORMATION (KUNSHAN) CO., LTD."},
  {max_prefix: "A0-E5-34",vendor: "Stratec Biomedical AG"},
  {max_prefix: "1C-75-08",vendor: "COMPAL INFORMATION (KUNSHAN) CO., LTD."},
  {max_prefix: "00-1B-38",vendor: "COMPAL INFORMATION (KUNSHAN) CO., LTD."},
  {max_prefix: "00-23-5A",vendor: "COMPAL INFORMATION (KUNSHAN) CO., LTD."},
  {max_prefix: "44-4B-5D",vendor: "GE Healthcare"},
  {max_prefix: "8C-A0-48",vendor: "Beijing NeTopChip Technology Co.,LTD"},
  {max_prefix: "24-D3-F2",vendor: "zte corporation"},
  {max_prefix: "D4-69-A5",vendor: "Miura Systems Ltd."},
  {max_prefix: "E0-12-83",vendor: " Shenzhen Fanzhuo Communication Technology Co., Lt"},
  {max_prefix: "E8-C5-7A",vendor: "Ufispace Co., LTD."},
  {max_prefix: "A0-CF-5B",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-24-21",vendor: "MICRO-STAR INT'L CO., LTD."},
  {max_prefix: "00-60-D1",vendor: "CASCADE COMMUNICATIONS"},
  {max_prefix: "0C-8C-24",vendor: "SHENZHEN BILIAN ELECTRONIC CO.\uff0cLTD"},
  {max_prefix: "80-5E-4F",vendor: "FN-LINK TECHNOLOGY LIMITED"},
  {max_prefix: "8C-6D-77",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "8C-81-26",vendor: "ARCOM"},
  {max_prefix: "D4-7C-44",vendor: "IEEE Registration Authority"},
  {max_prefix: "30-24-32",vendor: "Intel Corporate"},
  {max_prefix: "24-F5-7E",vendor: "HWH CO., LTD."},
  {max_prefix: "84-C9-C6",vendor: "SHENZHEN GONGJIN ELECTRONICS CO.,LT"},
  {max_prefix: "78-0C-F0",vendor: "Cisco Systems, Inc"},
  {max_prefix: "70-54-B4",vendor: "Vestel Elektronik San ve Tic. A.S."},
  {max_prefix: "20-A6-0C",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "50-59-67",vendor: "Intent Solutions Inc"},
  {max_prefix: "00-06-80",vendor: "Card Access, Inc."},
  {max_prefix: "88-B6-6B",vendor: "easynetworks"},
  {max_prefix: "48-8A-D2",vendor: "MERCURY COMMUNICATION TECHNOLOGIES CO.,LTD."},
  {max_prefix: "DC-E8-38",vendor: "CK Telecom (Shenzhen) Limited"},
  {max_prefix: "A8-D4-98",vendor: "Avira Operations GmbH & Co. KG"},
  {max_prefix: "60-D0-2C",vendor: "Ruckus Wireless"},
  {max_prefix: "D0-58-FC",vendor: "SKY UK LIMITED"},
  {max_prefix: "38-56-B5",vendor: "Peerbridge Health Inc"},
  {max_prefix: "14-57-9F",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "B4-43-26",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "3C-57-6C",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "84-17-66",vendor: "WEIFANG GOERTEK ELECTRONICS CO.,LTD"},
  {max_prefix: "2C-4D-79",vendor: "WEIFANG GOERTEK ELECTRONICS CO.,LTD"},
  {max_prefix: "04-AB-18",vendor: "ELECOM CO.,LTD."},
  {max_prefix: "D0-C5-D8",vendor: "LATECOERE"},
  {max_prefix: "00-0C-42",vendor: "Routerboard.com"},
  {max_prefix: "00-26-BD",vendor: "JTEC Card &amp; Communication Co., Ltd"},
  {max_prefix: "7C-96-D2",vendor: "Fihonest communication co.,Ltd"},
  {max_prefix: "78-D2-94",vendor: "NETGEAR"},
  {max_prefix: "70-9F-A9",vendor: "TECNO MOBILE LIMITED"},
  {max_prefix: "0C-01-DB",vendor: "Infinix mobility limited"},
  {max_prefix: "08-C5-E1",vendor: "SAMSUNG ELECTRO-MECHANICS(THAILAND)"},
  {max_prefix: "18-66-C7",vendor: "Shenzhen Libre Technology Co., Ltd"},
  {max_prefix: "5C-B3-F6",vendor: "Human, Incorporated"},
  {max_prefix: "2C-48-35",vendor: "IEEE Registration Authority"},
  {max_prefix: "48-2A-E3",vendor: "Wistron InfoComm(Kunshan)Co.,Ltd."},
  {max_prefix: "B0-EB-57",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "18-69-DA",vendor: "China Mobile Group Device Co.,Ltd."},
  {max_prefix: "FC-A6-CD",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "44-C8-74",vendor: "China Mobile Group Device Co.,Ltd."},
  {max_prefix: "74-C1-4F",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "A8-5B-6C",vendor: "Robert Bosch Gmbh, CM-CI2"},
  {max_prefix: "C8-B1-EE",vendor: "Qorvo"},
  {max_prefix: "00-CB-B4",vendor: "SHENZHEN ATEKO PHOTOELECTRICITY CO.,LTD"},
  {max_prefix: "4C-C0-0A",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "9C-E8-2B",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "70-79-B3",vendor: "Cisco Systems, Inc"},
  {max_prefix: "14-9B-2F",vendor: "JiangSu ZhongXie Intelligent Technology co., LTD"},
  {max_prefix: "48-DD-9D",vendor: "ITEL MOBILE LIMITED"},
  {max_prefix: "A0-75-EA",vendor: "BoxLock, Inc."},
  {max_prefix: "F0-4C-D5",vendor: "Maxlinear, Inc"},
  {max_prefix: "00-FC-BA",vendor: "Cisco Systems, Inc"},
  {max_prefix: "F8-5C-4D",vendor: "Nokia"},
  {max_prefix: "38-35-FB",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "00-01-AE",vendor: "Trex Enterprises"},
  {max_prefix: "00-E0-09",vendor: "Stratus Technologies"},
  {max_prefix: "E4-EA-83",vendor: "SHENZHEN GONGJIN ELECTRONICS CO.,LT"},
  {max_prefix: "74-EC-42",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "D4-FC-13",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "A0-B0-45",vendor: "Halong Mining"},
  {max_prefix: "EC-AF-97",vendor: "GIT"},
  {max_prefix: "E0-BA-B4",vendor: "Arrcus, Inc"},
  {max_prefix: "80-7D-3A",vendor: "Espressif Inc."},
  {max_prefix: "9C-7F-57",vendor: "UNIC Memory Technology Co Ltd"},
  {max_prefix: "B4-E0-1D",vendor: "CONCEPTION ELECTRONIQUE"},
  {max_prefix: "1C-00-42",vendor: "NARI Technology Co., Ltd."},
  {max_prefix: "68-CA-E4",vendor: "Cisco Systems, Inc"},
  {max_prefix: "78-1D-4A",vendor: "zte corporation"},
  {max_prefix: "00-10-27",vendor: "L-3 COMMUNICATIONS EAST"},
  {max_prefix: "58-9B-0B",vendor: "Shineway Technologies, Inc."},
  {max_prefix: "D8-16-0A",vendor: "Nippon Electro-Sensory Devices"},
  {max_prefix: "10-C0-7C",vendor: "Blu-ray Disc Association"},
  {max_prefix: "E4-B0-21",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "3C-E1-A1",vendor: "Universal Global Scientific Industrial Co., Ltd."},
  {max_prefix: "F8-98-EF",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "58-F9-87",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "A8-F5-AC",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "58-BA-D4",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "BC-26-43",vendor: "Elprotronic Inc."},
  {max_prefix: "04-E2-29",vendor: "Qingdao Haier Technology Co.,Ltd"},
  {max_prefix: "70-1D-08",vendor: "99IOT Shenzhen co.,ltd"},
  {max_prefix: "74-12-BB",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "00-BE-3B",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "7C-A1-77",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "24-2E-02",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "78-B6-EC",vendor: "Scuf Gaming International LLC"},
  {max_prefix: "80-35-C1",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "9C-E8-95",vendor: "New H3C Technologies Co., Ltd"},
  {max_prefix: "00-58-3F",vendor: "PC Aquarius"},
  {max_prefix: "90-3D-68",vendor: "G-Printec, Inc."},
  {max_prefix: "10-94-BB",vendor: "Apple, Inc."},
  {max_prefix: "08-DF-CB",vendor: "Systrome Networks"},
  {max_prefix: "A4-93-3F",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "34-E8-94",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "28-AC-9E",vendor: "Cisco Systems, Inc"},
  {max_prefix: "F8-6F-C1",vendor: "Apple, Inc."},
  {max_prefix: "28-FF-3C",vendor: "Apple, Inc."},
  {max_prefix: "F0-99-B6",vendor: "Apple, Inc."},
  {max_prefix: "88-E9-FE",vendor: "Apple, Inc."},
  {max_prefix: "38-89-2C",vendor: "Apple, Inc."},
  {max_prefix: "34-8B-75",vendor: "LAVA INTERNATIONAL(H.K) LIMITED"},
  {max_prefix: "74-9E-AF",vendor: "Apple, Inc."},
  {max_prefix: "94-BF-2D",vendor: "Apple, Inc."},
  {max_prefix: "20-47-DA",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "58-B3-FC",vendor: "SHENZHEN RF-LINK TECHNOLOGY CO.,LTD."},
  {max_prefix: "24-29-FE",vendor: "KYOCERA Corporation"},
  {max_prefix: "7C-49-EB",vendor: "XIAOMI Electronics,CO.,LTD"},
  {max_prefix: "C4-33-06",vendor: "China Mobile Group Device Co.,Ltd."},
  {max_prefix: "98-45-62",vendor: "Shanghai Baud Data Communication Co.,Ltd."},
  {max_prefix: "68-D4-82",vendor: "SHENZHEN GONGJIN ELECTRONICS CO.,LT"},
  {max_prefix: "10-E7-C6",vendor: "Hewlett Packard"},
  {max_prefix: "20-2D-23",vendor: "Collinear Networks Inc."},
  {max_prefix: "90-83-4B",vendor: "BEIJING YUNYI TIMES TECHNOLOGY CO,.LTD"},
  {max_prefix: "18-50-2A",vendor: "SOARNEX"},
  {max_prefix: "A4-33-D7",vendor: "MitraStar Technology Corp."},
  {max_prefix: "A8-36-7A",vendor: "frogblue TECHNOLOGY GmbH"},
  {max_prefix: "B0-AC-D2",vendor: "zte corporation"},
  {max_prefix: "20-0F-70",vendor: "FOXTECH"},
  {max_prefix: "00-71-47",vendor: "Amazon Technologies Inc."},
  {max_prefix: "00-BE-75",vendor: "Cisco Systems, Inc"},
  {max_prefix: "6C-E4-DA",vendor: "NEC Platforms, Ltd."},
  {max_prefix: "E4-C4-83",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "00-1F-BA",vendor: "Boyoung Tech"},
  {max_prefix: "DC-A3-33",vendor: "Shenzhen YOUHUA Technology Co., Ltd"},
  {max_prefix: "18-31-BF",vendor: "ASUSTek COMPUTER INC."},
  {max_prefix: "C0-48-E6",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "88-2E-5A",vendor: "storONE"},
  {max_prefix: "78-8C-54",vendor: "Ping Communication"},
  {max_prefix: "F0-0F-EC",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "AC-07-5F",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "B8-AF-67",vendor: "Hewlett Packard"},
  {max_prefix: "C0-98-DA",vendor: "China Mobile IOT Company Limited"},
  {max_prefix: "C8-FA-E1",vendor: "ARQ Digital LLC"},
  {max_prefix: "10-A4-B9",vendor: "Baidu Online Network Technology (Beijing) Co., Ltd"},
  {max_prefix: "80-CE-62",vendor: "Hewlett Packard"},
  {max_prefix: "80-1F-12",vendor: "Microchip Technology Inc."},
  {max_prefix: "50-6C-BE",vendor: "InnosiliconTechnology Ltd"},
  {max_prefix: "24-7E-12",vendor: "Cisco Systems, Inc"},
  {max_prefix: "50-1C-B0",vendor: "Cisco Systems, Inc"},
  {max_prefix: "04-C2-41",vendor: "Nokia"},
  {max_prefix: "78-25-7A",vendor: "LEO Innovation Lab"},
  {max_prefix: "8C-F7-10",vendor: "AMPAK Technology, Inc."},
  {max_prefix: "30-7B-AC",vendor: "New H3C Technologies Co., Ltd"},
  {max_prefix: "3C-47-9B",vendor: "Theissen Training Systems, Inc."},
  {max_prefix: "60-6B-FF",vendor: "Nintendo Co.,Ltd"},
  {max_prefix: "78-5D-C8",vendor: "LG Electronics"},
  {max_prefix: "0C-54-15",vendor: "Intel Corporate"},
  {max_prefix: "E8-C1-B8",vendor: " Nanjing Bangzhong Electronic Commerce Limited"},
  {max_prefix: "D8-D7-75",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "B4-A8-B9",vendor: "Cisco Systems, Inc"},
  {max_prefix: "50-DC-E7",vendor: "Amazon Technologies Inc."},
  {max_prefix: "64-98-29",vendor: "Integrated Device Technology (Malaysia) Sdn. Bhd."},
  {max_prefix: "08-1D-C4",vendor: "Thermo Fisher Scientific Messtechnik GmbH"},
  {max_prefix: "78-53-64",vendor: "SHIFT GmbH"},
  {max_prefix: "88-3D-24",vendor: "Google, Inc."},
  {max_prefix: "04-AC-44",vendor: "Holtek Semiconductor Inc."},
  {max_prefix: "F4-DC-A5",vendor: "DAWON DNS"},
  {max_prefix: "D4-60-E3",vendor: "Sercomm Corporation."},
  {max_prefix: "40-CB-C0",vendor: "Apple, Inc."},
  {max_prefix: "E8-33-0D",vendor: "Xaptec GmbH"},
  {max_prefix: "38-E6-0A",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "08-AE-D6",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "CC-4D-38",vendor: "Carnegie Technologies"},
  {max_prefix: "00-5D-73",vendor: "Cisco Systems, Inc"},
  {max_prefix: "60-6D-3C",vendor: "Luxshare Precision Industry Company Limited"},
  {max_prefix: "00-27-90",vendor: "Cisco Systems, Inc"},
  {max_prefix: "5C-5F-67",vendor: "Intel Corporate"},
  {max_prefix: "80-3A-59",vendor: "AT&T"},
  {max_prefix: "58-8D-64",vendor: "Xi'an Clevbee Technology Co.,Ltd"},
  {max_prefix: "34-BA-38",vendor: "PAL MOHAN ELECTRONICS PVT LTD"},
  {max_prefix: "98-29-A6",vendor: "COMPAL INFORMATION (KUNSHAN) CO., LTD."},
  {max_prefix: "DC-BF-E9",vendor: "Motorola Mobility LLC, a Lenovo Company"},
  {max_prefix: "CC-6E-A4",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "A8-16-D0",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "A4-6C-F1",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "C4-61-8B",vendor: "Apple, Inc."},
  {max_prefix: "08-E6-89",vendor: "Apple, Inc."},
  {max_prefix: "DC-56-E7",vendor: "Apple, Inc."},
  {max_prefix: "40-64-A4",vendor: "THE FURUKAWA ELECTRIC CO., LTD"},
  {max_prefix: "D4-5D-DF",vendor: "PEGATRON CORPORATION"},
  {max_prefix: "F0-65-C2",vendor: "Yanfeng Visteon Electronics Technology (Shanghai) Co.,Ltd."},
  {max_prefix: "70-B7-E2",vendor: "Jiangsu Miter Technology Co.,Ltd."},
  {max_prefix: "A0-9D-86",vendor: "Alcatel-Lucent Shanghai Bell Co., Ltd"},
  {max_prefix: "00-C0-FF",vendor: "Seagate Cloud Systems Inc"},
  {max_prefix: "88-D1-71",vendor: "BEGHELLI S.P.A"},
  {max_prefix: "A8-25-EB",vendor: "Cambridge Industries(Group) Co.,Ltd."},
  {max_prefix: "34-E3-80",vendor: "Genexis B.V."},
  {max_prefix: "5C-58-19",vendor: "Jingsheng Technology Co., Ltd."},
  {max_prefix: "B8-CA-04",vendor: "Holtek Semiconductor Inc."},
  {max_prefix: "C4-C5-63",vendor: "TECNO MOBILE LIMITED"},
  {max_prefix: "F8-B5-68",vendor: "IEEE Registration Authority"},
  {max_prefix: "2C-6B-7D",vendor: "Texas Instruments"},
  {max_prefix: "6C-B2-AE",vendor: "Cisco Systems, Inc"},
  {max_prefix: "80-B7-08",vendor: "Blue Danube Systems, Inc"},
  {max_prefix: "B0-98-2B",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "34-FA-9F",vendor: "Ruckus Wireless"},
  {max_prefix: "A0-9D-C1",vendor: "China Dragon Technology Limited"},
  {max_prefix: "2C-42-05",vendor: "Lytx"},
  {max_prefix: "6C-76-60",vendor: "KYOCERA CORPORATION"},
  {max_prefix: "00-21-02",vendor: "UpdateLogic Inc."},
  {max_prefix: "F4-1E-5E",vendor: "RtBrick Inc."},
  {max_prefix: "50-58-00",vendor: "WyTec International, Inc."},
  {max_prefix: "C8-D1-2A",vendor: "Comtrend Corporation"},
  {max_prefix: "BC-F2-92",vendor: "PLANTRONICS, INC."},
  {max_prefix: "04-50-DA",vendor: "Qiku Internet Network Scientific (Shenzhen) Co., Ltd"},
  {max_prefix: "E8-20-E2",vendor: "HUMAX Co., Ltd."},
  {max_prefix: "18-90-D8",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "88-83-5D",vendor: "FN-LINK TECHNOLOGY LIMITED"},
  {max_prefix: "F8-1D-0F",vendor: "Hitron Technologies. Inc"},
  {max_prefix: "00-05-A7",vendor: "HYPERCHIP Inc."},
  {max_prefix: "00-26-A8",vendor: "DAEHAP HYPER-TECH"},
  {max_prefix: "20-31-EB",vendor: "HDSN"},
  {max_prefix: "F8-C9-6C",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "84-48-23",vendor: "WOXTER TECHNOLOGY Co. Ltd"},
  {max_prefix: "08-BC-20",vendor: "Hangzhou Royal Cloud Technology Co., Ltd"},
  {max_prefix: "94-2A-3F",vendor: "Diversey Inc"},
  {max_prefix: "78-5C-28",vendor: "Prime Motion Inc."},
  {max_prefix: "EC-9F-0D",vendor: "IEEE Registration Authority"},
  {max_prefix: "E0-78-A3",vendor: "Shanghai Winner Information Technology Co.,Inc"},
  {max_prefix: "10-A4-BE",vendor: "SHENZHEN BILIAN ELECTRONIC CO.\uff0cLTD"},
  {max_prefix: "94-7B-BE",vendor: "Ubicquia LLC"},
  {max_prefix: "EC-C0-6A",vendor: "PowerChord Group Limited"},
  {max_prefix: "94-49-96",vendor: "WiSilica Inc"},
  {max_prefix: "68-A6-82",vendor: "Shenzhen YOUHUA Technology Co., Ltd"},
  {max_prefix: "0C-B2-B7",vendor: "Texas Instruments"},
  {max_prefix: "58-7A-62",vendor: "Texas Instruments"},
  {max_prefix: "54-7A-52",vendor: "CTE International srl"},
  {max_prefix: "34-08-BC",vendor: "Apple, Inc."},
  {max_prefix: "1C-36-BB",vendor: "Apple, Inc."},
  {max_prefix: "3C-2E-FF",vendor: "Apple, Inc."},
  {max_prefix: "08-84-66",vendor: "Novartis Pharma AG"},
  {max_prefix: "30-9F-FB",vendor: "Ardomus Networks Corporation"},
  {max_prefix: "F0-6E-0B",vendor: "Microsoft Corporation"},
  {max_prefix: "34-6F-ED",vendor: "Enovation Controls"},
  {max_prefix: "24-F6-77",vendor: "Apple, Inc."},
  {max_prefix: "B0-CA-68",vendor: "Apple, Inc."},
  {max_prefix: "C8-3C-85",vendor: "Apple, Inc."},
  {max_prefix: "54-33-CB",vendor: "Apple, Inc."},
  {max_prefix: "00-E0-25",vendor: "dit Co., Ltd."},
  {max_prefix: "00-15-30",vendor: "Dell EMC"},
  {max_prefix: "78-87-0D",vendor: "Unifiedgateways India Private Limited"},
  {max_prefix: "A8-82-00",vendor: "Hisense Electric Co.,Ltd"},
  {max_prefix: "38-20-A8",vendor: "ColorTokens, Inc."},
  {max_prefix: "70-58-96",vendor: "InShow Technology"},
  {max_prefix: "00-B6-9F",vendor: "Latch"},
  {max_prefix: "9C-E0-63",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "D0-31-69",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "84-2C-80",vendor: "Sichuan Changhong Electric Ltd."},
  {max_prefix: "3C-C0-79",vendor: "Shenzhen One-Nine Intelligent Electronic Science and Technology Co., Ltd"},
  {max_prefix: "00-05-89",vendor: "National Datacomputer"},
  {max_prefix: "3C-A6-16",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "98-C5-DB",vendor: "Ericsson AB"},
  {max_prefix: "BC-05-43",vendor: "AVM GmbH"},
  {max_prefix: "14-9F-3C",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "FC-EE-E6",vendor: "FORMIKE ELECTRONIC CO., LTD"},
  {max_prefix: "84-E3-27",vendor: "TAILYN TECHNOLOGIES INC"},
  {max_prefix: "00-21-B8",vendor: "Inphi Corporation"},
  {max_prefix: "0C-91-60",vendor: "Hui Zhou Gaoshengda Technology Co.,LTD"},
  {max_prefix: "D8-ED-1C",vendor: "Magna Technology SL"},
  {max_prefix: "A0-64-8F",vendor: "ASKEY COMPUTER CORP"},
  {max_prefix: "C8-50-E9",vendor: "Raisecom Technology CO., LTD"},
  {max_prefix: "F4-6E-24",vendor: "NEC Personal Computers, Ltd."},
  {max_prefix: "88-82-79",vendor: "Shenzhen RB-LINK Intelligent Technology Co.Ltd"},
  {max_prefix: "10-F1-63",vendor: "TNK CO.,LTD"},
  {max_prefix: "88-DA-1A",vendor: "Redpine Signals, Inc."},
  {max_prefix: "98-EF-9B",vendor: "OHSUNG"},
  {max_prefix: "14-CF-8D",vendor: "OHSUNG"},
  {max_prefix: "10-44-00",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "B0-E1-7E",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "E4-A7-C5",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "A0-FE-61",vendor: "Vivint Wireless Inc."},
  {max_prefix: "5C-2B-F5",vendor: "Vivint Wireless Inc."},
  {max_prefix: "2C-27-9E",vendor: "IEEE Registration Authority"},
  {max_prefix: "B4-30-C0",vendor: "York Instruments Ltd"},
  {max_prefix: "E8-1D-A8",vendor: "Ruckus Wireless"},
  {max_prefix: "5C-8D-2D",vendor: "Shanghai Wellpay Information Technology Co., Ltd"},
  {max_prefix: "68-C6-3A",vendor: "Espressif Inc."},
  {max_prefix: "40-8B-F6",vendor: "Shenzhen TCL New Technology Co., Ltd"},
  {max_prefix: "00-00-6B",vendor: "Silicon Graphics"},
  {max_prefix: "74-37-3B",vendor: "UNINET Co.,Ltd."},
  {max_prefix: "7C-64-56",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "90-FD-9F",vendor: "Silicon Laboratories"},
  {max_prefix: "F0-3D-03",vendor: "TECNO MOBILE LIMITED"},
  {max_prefix: "DC-F0-90",vendor: "Nubia Technology Co.,Ltd."},
  {max_prefix: "CC-5A-53",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-C0-EE",vendor: "KYOCERA Display Corporation"},
  {max_prefix: "24-5F-DF",vendor: "KYOCERA CORPORATION"},
  {max_prefix: "64-6E-69",vendor: "Liteon Technology Corporation"},
  {max_prefix: "70-6B-B9",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-60-88",vendor: "Analog Devices, Inc."},
  {max_prefix: "08-4A-CF",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "9C-63-ED",vendor: "zte corporation"},
  {max_prefix: "74-F6-61",vendor: "Schneider Electric Fire & Security Oy"},
  {max_prefix: "8C-5F-48",vendor: "Continental Intelligent Transportation Systems LLC"},
  {max_prefix: "94-7E-B9",vendor: "National Narrowband Network Communications Pty Ltd"},
  {max_prefix: "D4-38-9C",vendor: "Sony Corporation"},
  {max_prefix: "00-1B-D3",vendor: "Panasonic Corporation AVC Networks Company"},
  {max_prefix: "CC-7E-E7",vendor: "Panasonic Corporation AVC Networks Company"},
  {max_prefix: "20-C6-EB",vendor: "Panasonic Corporation AVC Networks Company"},
  {max_prefix: "64-B5-C6",vendor: "Nintendo Co.,Ltd"},
  {max_prefix: "00-19-87",vendor: "Panasonic Mobile Communications Co.,Ltd."},
  {max_prefix: "BC-C3-42",vendor: "Panasonic Communications Co., Ltd."},
  {max_prefix: "2C-B2-1A",vendor: "Phicomm (Shanghai) Co., Ltd."},
  {max_prefix: "00-28-9F",vendor: "Semptian Co., Ltd."},
  {max_prefix: "AC-1F-74",vendor: "Apple, Inc."},
  {max_prefix: "A8-5C-2C",vendor: "Apple, Inc."},
  {max_prefix: "00-DB-70",vendor: "Apple, Inc."},
  {max_prefix: "B8-63-4D",vendor: "Apple, Inc."},
  {max_prefix: "A4-E9-75",vendor: "Apple, Inc."},
  {max_prefix: "30-35-AD",vendor: "Apple, Inc."},
  {max_prefix: "84-41-67",vendor: "Apple, Inc."},
  {max_prefix: "98-00-C6",vendor: "Apple, Inc."},
  {max_prefix: "FC-E5-57",vendor: "Nokia Corporation"},
  {max_prefix: "48-C5-8D",vendor: "Lear Corporation GmbH"},
  {max_prefix: "24-C4-2F",vendor: "Philips Lifeline"},
  {max_prefix: "E4-70-B8",vendor: "Intel Corporate"},
  {max_prefix: "9C-30-5B",vendor: "Hon Hai Precision Ind. Co.,Ltd."},
  {max_prefix: "D4-D2-E5",vendor: "BKAV Corporation"},
  {max_prefix: "00-50-B5",vendor: "FICHET SECURITE ELECTRONIQUE"},
  {max_prefix: "00-14-39",vendor: "Blonder Tongue Laboratories, Inc"},
  {max_prefix: "00-86-9C",vendor: "Palo Alto Networks"},
  {max_prefix: "10-4E-89",vendor: "Garmin International"},
  {max_prefix: "D8-C4-97",vendor: "Quanta Computer Inc."},
  {max_prefix: "C8-40-29",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "F8-6E-EE",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "84-80-2D",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-18-8D",vendor: "Nokia Danmark A/S"},
  {max_prefix: "00-15-AB",vendor: "PRO CO SOUND INC"},
  {max_prefix: "58-76-C5",vendor: "DIGI I'S LTD"},
  {max_prefix: "94-D9-B3",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "00-A0-AC",vendor: "GILAT SATELLITE NETWORKS, LTD."},
  {max_prefix: "74-1C-27",vendor: "ITEL MOBILE LIMITED"},
  {max_prefix: "28-30-AC",vendor: "Frontiir Co. Ltd."},
  {max_prefix: "78-D8-00",vendor: "IEEE Registration Authority"},
  {max_prefix: "08-35-B2",vendor: "CoreEdge Networks Co., Ltd"},
  {max_prefix: "4C-49-E3",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "00-26-09",vendor: "Phyllis Co., Ltd."},
  {max_prefix: "28-F5-37",vendor: "IEEE Registration Authority"},
  {max_prefix: "78-02-B1",vendor: "Cisco Systems, Inc"},
  {max_prefix: "F8-34-41",vendor: "Intel Corporate"},
  {max_prefix: "44-EA-4B",vendor: "Actlas Inc."},
  {max_prefix: "C4-CB-6B",vendor: "Airista Flow, Inc."},
  {max_prefix: "54-66-6C",vendor: "Shenzhen YOUHUA Technology Co., Ltd"},
  {max_prefix: "A8-96-75",vendor: "Motorola Mobility LLC, a Lenovo Company"},
  {max_prefix: "B4-C1-70",vendor: "Yi chip Microelectronics (Hangzhou) Co., Ltd"},
  {max_prefix: "28-D4-36",vendor: "Jiangsu dewosi electric co., LTD"},
  {max_prefix: "38-9A-F6",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "E0-AA-96",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "50-77-05",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "24-58-80",vendor: "VIZEO"},
  {max_prefix: "18-80-90",vendor: "Cisco Systems, Inc"},
  {max_prefix: "B4-0F-3B",vendor: "Tenda Technology Co.,Ltd.Dongguan branch"},
  {max_prefix: "A8-B2-DA",vendor: "FUJITSU LIMITED"},
  {max_prefix: "78-62-56",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "B0-55-08",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "70-DF-2F",vendor: "Cisco Systems, Inc"},
  {max_prefix: "38-94-E0",vendor: "Syrotech Networks. Ltd."},
  {max_prefix: "B8-75-C0",vendor: "PayPal, Inc."},
  {max_prefix: "00-1C-71",vendor: "Emergent Electronics"},
  {max_prefix: "B0-90-D4",vendor: "Shenzhen Hoin Internet Technology Co., Ltd"},
  {max_prefix: "8C-9F-3B",vendor: "Qingdao Hisense Communications Co.,Ltd."},
  {max_prefix: "00-1F-41",vendor: "Ruckus Wireless"},
  {max_prefix: "84-20-96",vendor: "SHENZHEN RF-LINK TECHNOLOGY CO.,LTD."},
  {max_prefix: "C4-10-8A",vendor: "Ruckus Wireless"},
  {max_prefix: "F0-B0-52",vendor: "Ruckus Wireless"},
  {max_prefix: "AC-67-06",vendor: "Ruckus Wireless"},
  {max_prefix: "04-4F-AA",vendor: "Ruckus Wireless"},
  {max_prefix: "58-93-96",vendor: "Ruckus Wireless"},
  {max_prefix: "00-14-B8",vendor: "Hill-Rom"},
  {max_prefix: "AC-ED-5C",vendor: "Intel Corporate"},
  {max_prefix: "00-1A-93",vendor: "ERCO Leuchten GmbH"},
  {max_prefix: "94-F6-65",vendor: "Ruckus Wireless"},
  {max_prefix: "10-CD-B6",vendor: "Essential Products, Inc."},
  {max_prefix: "90-61-AE",vendor: "Intel Corporate"},
  {max_prefix: "50-18-4C",vendor: "Platina Systems Inc."},
  {max_prefix: "00-EC-0A",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "C8-1F-EA",vendor: "Avaya Inc"},
  {max_prefix: "34-F6-4B",vendor: "Intel Corporate"},
  {max_prefix: "A4-F3-E7",vendor: "Integrated Device Technology (Malaysia) Sdn. Bhd."},
  {max_prefix: "E4-3A-6E",vendor: "Shenzhen Zeroone Technology CO.,LTD"},
  {max_prefix: "6C-F9-D2",vendor: "CHENGDU POVODO ELECTRONIC TECHNOLOGY CO., LTD"},
  {max_prefix: "F4-6B-EF",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "08-30-6B",vendor: "Palo Alto Networks"},
  {max_prefix: "60-27-1C",vendor: "VIDEOR E. Hartig GmbH"},
  {max_prefix: "00-18-AE",vendor: "TVT CO.,LTD"},
  {max_prefix: "88-91-DD",vendor: "Racktivity"},
  {max_prefix: "F4-B7-B3",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "30-B6-2D",vendor: " Mojo Networks, Inc."},
  {max_prefix: "6C-60-EB",vendor: "ZHI YUAN ELECTRONICS CO., LIMITED"},
  {max_prefix: "74-DA-DA",vendor: "D-Link International"},
  {max_prefix: "D8-F1-F0",vendor: "Pepxim International Limited"},
  {max_prefix: "90-97-F3",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "7C-1C-68",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "C0-87-EB",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "C8-D7-B0",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "FC-A6-67",vendor: "Amazon Technologies Inc."},
  {max_prefix: "44-7B-BB",vendor: "Shenzhen YOUHUA Technology Co., Ltd"},
  {max_prefix: "1C-45-93",vendor: "Texas Instruments"},
  {max_prefix: "90-EC-50",vendor: "C.O.B.O. SPA"},
  {max_prefix: "2C-41-A1",vendor: "Bose Corporation"},
  {max_prefix: "9C-7B-D2",vendor: "NEOLAB Convergence"},
  {max_prefix: "D0-F8-8C",vendor: "Motorola (Wuhan) Mobility Technologies Communication Co., Ltd."},
  {max_prefix: "2C-B1-15",vendor: "Integrated Device Technology (Malaysia) Sdn. Bhd."},
  {max_prefix: "DC-C8-F5",vendor: "Shanghai UMEinfo CO.,LTD."},
  {max_prefix: "88-D7-F6",vendor: "ASUSTek COMPUTER INC."},
  {max_prefix: "10-D0-7A",vendor: "AMPAK Technology, Inc."},
  {max_prefix: "C4-AB-B2",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "44-B4-12",vendor: "SIUS AG"},
  {max_prefix: "0C-B9-12",vendor: "JM-DATA GmbH"},
  {max_prefix: "3C-A3-08",vendor: "Texas Instruments"},
  {max_prefix: "00-E1-8C",vendor: "Intel Corporate"},
  {max_prefix: "88-15-44",vendor: "Cisco Meraki"},
  {max_prefix: "00-24-49",vendor: "Shen Zhen Lite Star Electronics Technology Co., Ltd"},
  {max_prefix: "04-71-4B",vendor: "IEEE Registration Authority"},
  {max_prefix: "84-79-33",vendor: "profichip GmbH"},
  {max_prefix: "60-45-CB",vendor: "ASUSTek COMPUTER INC."},
  {max_prefix: "F4-3E-61",vendor: "SHENZHEN GONGJIN ELECTRONICS CO.,LT"},
  {max_prefix: "B4-41-7A",vendor: "SHENZHEN GONGJIN ELECTRONICS CO.,LT"},
  {max_prefix: "D4-50-3F",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "38-8C-50",vendor: "LG Electronics"},
  {max_prefix: "DC-08-56",vendor: "Alcatel-Lucent Enterprise"},
  {max_prefix: "E4-42-A6",vendor: "Intel Corporate"},
  {max_prefix: "3C-67-8C",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "28-A6-DB",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "14-A0-F8",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "98-AA-FC",vendor: "IEEE Registration Authority"},
  {max_prefix: "1C-DA-27",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "90-F3-05",vendor: "HUMAX Co., Ltd."},
  {max_prefix: "4C-81-20",vendor: "Taicang T&W Electronics"},
  {max_prefix: "00-11-8B",vendor: "Alcatel-Lucent Enterprise"},
  {max_prefix: "00-E0-B1",vendor: "Alcatel-Lucent Enterprise"},
  {max_prefix: "00-E0-DA",vendor: "Alcatel-Lucent Enterprise"},
  {max_prefix: "40-95-BD",vendor: "NTmore.Co.,Ltd"},
  {max_prefix: "E8-C1-D7",vendor: "Philips"},
  {max_prefix: "C8-F8-6D",vendor: "Alcatel-Lucent Shanghai Bell Co., Ltd"},
  {max_prefix: "F8-BE-0D",vendor: "A2UICT Co.,Ltd."},
  {max_prefix: "00-14-3F",vendor: "Hotway Technology Corporation"},
  {max_prefix: "F8-FF-0B",vendor: "Electronic Technology Inc."},
  {max_prefix: "14-4F-D7",vendor: "IEEE Registration Authority"},
  {max_prefix: "B8-55-10",vendor: "Zioncom Electronics (Shenzhen) Ltd."},
  {max_prefix: "70-F0-87",vendor: "Apple, Inc."},
  {max_prefix: "00-D3-18",vendor: "SPG Controls"},
  {max_prefix: "D0-55-B2",vendor: "Integrated Device Technology (Malaysia) Sdn. Bhd."},
  {max_prefix: "7C-6B-F7",vendor: "NTI co., ltd."},
  {max_prefix: "24-5B-A7",vendor: "Apple, Inc."},
  {max_prefix: "60-91-F3",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "28-39-5E",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "38-29-5A",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "88-E6-28",vendor: "Shenzhen Kezhonglong Optoelectronic Technology Co.,Ltd"},
  {max_prefix: "00-0C-46",vendor: "Allied Telesyn Inc."},
  {max_prefix: "00-1F-72",vendor: "QingDao Hiphone Technology Co,.Ltd"},
  {max_prefix: "00-23-65",vendor: "Insta Elektro GmbH"},
  {max_prefix: "40-FA-7F",vendor: "Preh Car Connect GmbH"},
  {max_prefix: "CC-82-EB",vendor: "KYOCERA CORPORATION"},
  {max_prefix: "D4-CF-37",vendor: "Symbolic IO"},
  {max_prefix: "40-9F-38",vendor: "AzureWave Technology Inc."},
  {max_prefix: "04-95-73",vendor: "zte corporation"},
  {max_prefix: "F0-D7-AA",vendor: "Motorola Mobility LLC, a Lenovo Company"},
  {max_prefix: "30-96-FB",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "48-27-EA",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "00-06-31",vendor: "Calix Inc."},
  {max_prefix: "BC-2F-3D",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "7C-78-7E",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "D4-7D-FC",vendor: "TECNO MOBILE LIMITED"},
  {max_prefix: "B8-16-DB",vendor: "CHANT SINCERE CO.,LTD"},
  {max_prefix: "E0-51-63",vendor: "Arcadyan Corporation"},
  {max_prefix: "54-E3-F6",vendor: "Alcatel-Lucent"},
  {max_prefix: "40-B0-34",vendor: "Hewlett Packard"},
  {max_prefix: "30-34-D2",vendor: "Availink, Inc."},
  {max_prefix: "50-40-61",vendor: "Nokia"},
  {max_prefix: "00-10-8E",vendor: "HUGH SYMONS CONCEPT Technologies Ltd."},
  {max_prefix: "64-1A-22",vendor: "Heliospectra AB"},
  {max_prefix: "8C-F5-A3",vendor: "SAMSUNG ELECTRO-MECHANICS(THAILAND)"},
  {max_prefix: "F4-2B-48",vendor: "Ubiqam"},
  {max_prefix: "D4-7A-E2",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "3C-BD-3E",vendor: "Beijing Xiaomi Electronics Co., Ltd."},
  {max_prefix: "40-B4-CD",vendor: "Amazon Technologies Inc."},
  {max_prefix: "40-7D-0F",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "68-CC-6E",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "50-F1-4A",vendor: "Texas Instruments"},
  {max_prefix: "04-DE-F2",vendor: "Shenzhen ECOM Technology Co. Ltd"},
  {max_prefix: "A0-84-CB",vendor: "SonicSensory,Inc."},
  {max_prefix: "78-C1-A7",vendor: "zte corporation"},
  {max_prefix: "4C-78-72",vendor: "Cav. Uff. Giacomo Cimberio S.p.A."},
  {max_prefix: "D8-E0-E1",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "00-A0-C8",vendor: "Adtran Inc"},
  {max_prefix: "D8-C7-71",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "D4-B1-69",vendor: "Le Shi Zhi Xin Electronic Technology (Tianjin) Limited"},
  {max_prefix: "E4-47-90",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "8C-C8-F4",vendor: "IEEE Registration Authority"},
  {max_prefix: "F4-83-E1",vendor: "Shanghai Clouder Semiconductor Co.,Ltd"},
  {max_prefix: "08-3E-5D",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "54-03-84",vendor: "Hongkong Nano IC Technologies Co., Ltd"},
  {max_prefix: "68-54-FD",vendor: "Amazon Technologies Inc."},
  {max_prefix: "70-91-8F",vendor: "Weber-Stephen Products LLC"},
  {max_prefix: "00-03-BC",vendor: "COT GmbH"},
  {max_prefix: "7C-10-15",vendor: "Brilliant Home Technology, Inc."},
  {max_prefix: "CC-B8-A8",vendor: "AMPAK Technology, Inc."},
  {max_prefix: "34-04-9E",vendor: "IEEE Registration Authority"},
  {max_prefix: "94-FB-29",vendor: "Zebra Technologies Inc."},
  {max_prefix: "74-61-4B",vendor: "Chongqing Huijiatong Information Technology Co., Ltd."},
  {max_prefix: "98-D2-93",vendor: "Google, Inc."},
  {max_prefix: "6C-19-C0",vendor: "Apple, Inc."},
  {max_prefix: "E0-2A-82",vendor: "Universal Global Scientific Industrial Co., Ltd."},
  {max_prefix: "B0-F9-63",vendor: "Hangzhou H3C Technologies Co., Limited"},
  {max_prefix: "D8-25-B0",vendor: "Rockeetech Systems Co.,Ltd."},
  {max_prefix: "F4-96-51",vendor: "NAKAYO Inc"},
  {max_prefix: "44-62-46",vendor: "Comat AG"},
  {max_prefix: "38-45-4C",vendor: "Light Labs, Inc."},
  {max_prefix: "B0-70-2D",vendor: "Apple, Inc."},
  {max_prefix: "00-20-4F",vendor: "DEUTSCHE AEROSPACE AG"},
  {max_prefix: "8C-E1-17",vendor: "zte corporation"},
  {max_prefix: "68-8A-F0",vendor: "zte corporation"},
  {max_prefix: "C0-21-0D",vendor: "SHENZHEN RF-LINK TECHNOLOGY CO.,LTD."},
  {max_prefix: "4C-E2-F1",vendor: "Udino srl"},
  {max_prefix: "00-DB-DF",vendor: "Intel Corporate"},
  {max_prefix: "A8-40-41",vendor: "Dragino Technology Co., Limited"},
  {max_prefix: "10-77-B0",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "80-D4-A5",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "04-B0-E7",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "44-6A-2E",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "A0-6F-AA",vendor: "LG Innotek"},
  {max_prefix: "94-A0-4E",vendor: "Bostex Technology Co., LTD"},
  {max_prefix: "FC-10-C6",vendor: "Taicang T&W Electronics"},
  {max_prefix: "F0-1D-BC",vendor: "Microsoft Corporation"},
  {max_prefix: "AC-58-7B",vendor: "JCT Healthcare"},
  {max_prefix: "B4-74-47",vendor: "CoreOS"},
  {max_prefix: "00-26-AB",vendor: "Seiko Epson Corporation"},
  {max_prefix: "10-62-EB",vendor: "D-Link International"},
  {max_prefix: "00-08-94",vendor: "InnoVISION Multimedia Ltd."},
  {max_prefix: "50-4B-5B",vendor: "CONTROLtronic GmbH"},
  {max_prefix: "C8-66-2C",vendor: "Beijing Haitai Fangyuan High Technology Co,.Ltd."},
  {max_prefix: "80-96-CA",vendor: "Hon Hai Precision Ind. Co.,Ltd."},
  {max_prefix: "18-65-71",vendor: "Top Victory Electronics (Taiwan) Co., Ltd."},
  {max_prefix: "2C-6F-C9",vendor: "Hon Hai Precision Ind. Co.,Ltd."},
  {max_prefix: "D0-58-A8",vendor: "zte corporation"},
  {max_prefix: "D0-71-C4",vendor: "zte corporation"},
  {max_prefix: "B4-B3-84",vendor: "ShenZhen Figigantic Electronic Co.,Ltd"},
  {max_prefix: "F8-3F-51",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "50-D7-53",vendor: "CONELCOM GmbH"},
  {max_prefix: "34-D2-70",vendor: "Amazon Technologies Inc."},
  {max_prefix: "0C-C4-7A",vendor: "Super Micro Computer, Inc."},
  {max_prefix: "A0-CC-2B",vendor: "Murata Manufacturing Co., Ltd."},
  {max_prefix: "00-C0-03",vendor: "GLOBALNET COMMUNICATIONS"},
  {max_prefix: "00-23-4A",vendor: "Private"},
  {max_prefix: "6C-A7-FA",vendor: "YOUNGBO ENGINEERING INC."},
  {max_prefix: "8C-7E-B3",vendor: "Lytro, Inc."},
  {max_prefix: "E0-D9-E3",vendor: "Eltex Enterprise Ltd."},
  {max_prefix: "80-5E-C0",vendor: "YEALINK(XIAMEN) NETWORK TECHNOLOGY CO.,LTD."},
  {max_prefix: "00-23-D2",vendor: "Inhand Electronics, Inc."},
  {max_prefix: "00-27-1C",vendor: "MERCURY CORPORATION"},
  {max_prefix: "1C-40-E8",vendor: "SHENZHEN PROGRESS&WIN TECHNOLOGY CO.,LTD"},
  {max_prefix: "2C-40-2B",vendor: "Smart iBlue Technology Limited"},
  {max_prefix: "5C-6B-4F",vendor: "Hello Inc."},
  {max_prefix: "40-4E-36",vendor: "HTC Corporation"},
  {max_prefix: "50-79-5B",vendor: "Interexport Telecomunicaciones S.A."},
  {max_prefix: "00-16-D9",vendor: "NINGBO BIRD CO.,LTD."},
  {max_prefix: "50-01-D9",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "A8-E7-05",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "60-A4-D0",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "88-6B-0F",vendor: "Bluegiga Technologies OY"},
  {max_prefix: "AC-84-C9",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "9C-62-AB",vendor: "Sumavision Technologies Co.,Ltd"},
  {max_prefix: "18-8B-15",vendor: "ShenZhen ZhongRuiJing Technology co.,LTD"},
  {max_prefix: "4C-E1-73",vendor: "IEEE Registration Authority"},
  {max_prefix: "0C-D8-6C",vendor: "SHENZHEN FAST TECHNOLOGIES CO.,LTD"},
  {max_prefix: "DC-0B-34",vendor: "LG Electronics (Mobile Communications)"},
  {max_prefix: "04-97-90",vendor: "Lartech telecom LLC"},
  {max_prefix: "28-EE-D3",vendor: "Shenzhen Super D Technology Co., Ltd"},
  {max_prefix: "24-C4-4A",vendor: "zte corporation"},
  {max_prefix: "98-54-1B",vendor: "Intel Corporate"},
  {max_prefix: "00-7B-18",vendor: "SENTRY Co., LTD."},
  {max_prefix: "14-4D-67",vendor: "Zioncom Electronics (Shenzhen) Ltd."},
  {max_prefix: "00-60-D6",vendor: "NovAtel Inc."},
  {max_prefix: "00-87-01",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "5C-99-60",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "24-5C-BF",vendor: "NCSE"},
  {max_prefix: "B0-95-8E",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "C0-25-E9",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "2C-33-61",vendor: "Apple, Inc."},
  {max_prefix: "C8-F9-46",vendor: "LOCOSYS Technology Inc."},
  {max_prefix: "00-13-7E",vendor: "CorEdge Networks, Inc."},
  {max_prefix: "D8-14-D6",vendor: "SURE SYSTEM Co Ltd"},
  {max_prefix: "6C-EF-C6",vendor: "SHENZHEN TWOWING TECHNOLOGIES CO.,LTD."},
  {max_prefix: "10-1D-C0",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "48-7B-6B",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "88-3F-D3",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "5C-A9-33",vendor: "Luma Home"},
  {max_prefix: "C8-02-8F",vendor: "Nova Electronics (Shanghai) Co., Ltd."},
  {max_prefix: "00-0B-14",vendor: "ViewSonic Corporation"},
  {max_prefix: "24-0D-65",vendor: "Shenzhen Vsun Communication Technology Co., Ltd."},
  {max_prefix: "78-47-1D",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "A0-75-91",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "0C-DF-A4",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "68-EB-AE",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "44-4E-1A",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "00-23-41",vendor: "Vanderbilt International (SWE) AB"},
  {max_prefix: "40-7C-7D",vendor: "Nokia"},
  {max_prefix: "24-59-0B",vendor: "White Sky Inc. Limited"},
  {max_prefix: "14-33-65",vendor: "TEM Mobile Limited"},
  {max_prefix: "6C-DE-A9",vendor: "Cisco Meraki"},
  {max_prefix: "A8-46-9D",vendor: "Cisco Meraki"},
  {max_prefix: "D8-EC-5E",vendor: "Belkin International Inc."},
  {max_prefix: "E8-9F-80",vendor: "Belkin International Inc."},
  {max_prefix: "00-50-CB",vendor: "Bucher Automation AG"},
  {max_prefix: "B0-72-BF",vendor: "Murata Manufacturing Co., Ltd."},
  {max_prefix: "70-1D-C4",vendor: "NorthStar Battery Company, LLC"},
  {max_prefix: "58-EF-68",vendor: "Belkin International Inc."},
  {max_prefix: "CC-03-D9",vendor: "Cisco Meraki"},
  {max_prefix: "0C-8D-DB",vendor: "Cisco Meraki"},
  {max_prefix: "5C-86-13",vendor: "Beijing Zhoenet Technology Co., Ltd"},
  {max_prefix: "CC-73-14",vendor: "HONG KONG WHEATEK TECHNOLOGY LIMITED"},
  {max_prefix: "B8-EE-65",vendor: "Liteon Technology Corporation"},
  {max_prefix: "98-5B-B0",vendor: "KMDATA INC."},
  {max_prefix: "00-11-FF",vendor: "Digitro Tecnologia Ltda"},
  {max_prefix: "00-1B-94",vendor: "T.E.M.A. S.p.A."},
  {max_prefix: "BC-85-56",vendor: "Hon Hai Precision Ind. Co.,Ltd."},
  {max_prefix: "34-23-87",vendor: "Hon Hai Precision Ind. Co.,Ltd."},
  {max_prefix: "64-DA-A0",vendor: "Robert Bosch Smart Home GmbH"},
  {max_prefix: "14-B8-37",vendor: "Shenzhen YOUHUA Technology Co., Ltd"},
  {max_prefix: "E4-7C-F9",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "48-44-F7",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "00-13-77",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "00-24-54",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "E8-11-32",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "50-B7-C3",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "1C-5A-3E",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "A0-21-95",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "B0-78-70",vendor: "Wi-NEXT, Inc."},
  {max_prefix: "5C-A3-9D",vendor: "SAMSUNG ELECTRO MECHANICS CO., LTD."},
  {max_prefix: "90-18-7C",vendor: "SAMSUNG ELECTRO MECHANICS CO., LTD."},
  {max_prefix: "50-CC-F8",vendor: "SAMSUNG ELECTRO MECHANICS CO., LTD."},
  {max_prefix: "00-26-37",vendor: "SAMSUNG ELECTRO MECHANICS CO., LTD."},
  {max_prefix: "00-21-19",vendor: "SAMSUNG ELECTRO MECHANICS CO., LTD."},
  {max_prefix: "F4-D9-FB",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "3C-62-00",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "94-39-E5",vendor: "Hon Hai Precision Ind. Co.,Ltd."},
  {max_prefix: "64-27-37",vendor: "Hon Hai Precision Ind. Co.,Ltd."},
  {max_prefix: "A4-17-31",vendor: "Hon Hai Precision Ind. Co.,Ltd."},
  {max_prefix: "E0-06-E6",vendor: "Hon Hai Precision Ind. Co.,Ltd."},
  {max_prefix: "F0-F0-02",vendor: "Hon Hai Precision Ind. Co.,Ltd."},
  {max_prefix: "C0-CB-38",vendor: "Hon Hai Precision Ind. Co.,Ltd."},
  {max_prefix: "F0-7B-CB",vendor: "Hon Hai Precision Ind. Co.,Ltd."},
  {max_prefix: "C4-17-FE",vendor: "Hon Hai Precision Ind. Co.,Ltd."},
  {max_prefix: "00-22-3B",vendor: "Communication Networks, LLC"},
  {max_prefix: "C0-F8-DA",vendor: "Hon Hai Precision Ind. Co.,Ltd."},
  {max_prefix: "00-17-D5",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "00-1E-7D",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "00-1D-F6",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "00-1D-25",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "E4-C1-F1",vendor: "SHENZHEN SPOTMAU INFORMATION TECHNOLIGY CO., Ltd"},
  {max_prefix: "24-0A-C4",vendor: "Espressif Inc."},
  {max_prefix: "F0-6B-CA",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "AC-36-13",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "BC-8C-CD",vendor: "SAMSUNG ELECTRO-MECHANICS(THAILAND)"},
  {max_prefix: "D0-22-BE",vendor: "SAMSUNG ELECTRO-MECHANICS(THAILAND)"},
  {max_prefix: "EC-9B-F3",vendor: "SAMSUNG ELECTRO-MECHANICS(THAILAND)"},
  {max_prefix: "34-31-11",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "08-FD-0E",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "04-1B-BA",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "88-9B-39",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "E4-32-CB",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "10-D5-42",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "A0-82-1F",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "F4-09-D8",vendor: "SAMSUNG ELECTRO-MECHANICS(THAILAND)"},
  {max_prefix: "18-D2-76",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "C0-65-99",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "BC-79-AD",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "4C-3C-16",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "00-73-E0",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "F0-E7-7E",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "F0-08-F1",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "58-C3-8B",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "00-26-11",vendor: "Licera AB"},
  {max_prefix: "00-52-18",vendor: "Wuxi Keboda Electron Co.Ltd"},
  {max_prefix: "00-E3-B2",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "30-19-66",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "94-35-0A",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "F0-03-8C",vendor: "AzureWave Technology Inc."},
  {max_prefix: "A8-1B-6A",vendor: "Texas Instruments"},
  {max_prefix: "98-5D-AD",vendor: "Texas Instruments"},
  {max_prefix: "D4-36-39",vendor: "Texas Instruments"},
  {max_prefix: "BC-28-2C",vendor: "e-Smart Systems Pvt. Ltd"},
  {max_prefix: "A4-0D-BC",vendor: "Xiamen Intretech Inc."},
  {max_prefix: "B0-F8-93",vendor: "Shanghai MXCHIP Information Technology Co., Ltd."},
  {max_prefix: "00-1E-81",vendor: "CNB Technology Inc."},
  {max_prefix: "00-02-C9",vendor: "Mellanox Technologies, Inc."},
  {max_prefix: "7C-A9-7D",vendor: "Objenious"},
  {max_prefix: "A8-A6-48",vendor: "Qingdao Hisense Communications Co.,Ltd."},
  {max_prefix: "84-EF-18",vendor: "Intel Corporate"},
  {max_prefix: "C4-11-E0",vendor: "Bull Group Co., Ltd"},
  {max_prefix: "34-3D-C4",vendor: "BUFFALO.INC"},
  {max_prefix: "7C-06-23",vendor: "Ultra Electronics Sonar System Division"},
  {max_prefix: "00-12-6C",vendor: "Visonic Technologies 1993 Ltd."},
  {max_prefix: "00-80-C7",vendor: "XIRCOM"},
  {max_prefix: "00-01-38",vendor: "XAVi Technologies Corp."},
  {max_prefix: "00-16-6D",vendor: "Yulong Computer Telecommunication Scientific (Shenzhen) Co.,Ltd"},
  {max_prefix: "3C-91-57",vendor: "Yulong Computer Telecommunication Scientific (Shenzhen) Co.,Ltd"},
  {max_prefix: "00-00-D8",vendor: "Novell, Inc."},
  {max_prefix: "14-82-5B",vendor: "Hefei Radio Communication Technology Co., Ltd"},
  {max_prefix: "AC-61-75",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "24-44-27",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "48-FD-8E",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "28-AC-67",vendor: "Mach Power, Rappresentanze Internazionali s.r.l."},
  {max_prefix: "08-00-51",vendor: "ExperData"},
  {max_prefix: "00-1F-46",vendor: "Nortel Networks"},
  {max_prefix: "00-30-93",vendor: "Sonnet Technologies, Inc"},
  {max_prefix: "00-03-4B",vendor: "Nortel Networks"},
  {max_prefix: "00-0E-40",vendor: "Nortel Networks"},
  {max_prefix: "00-1C-9C",vendor: "Nortel Networks"},
  {max_prefix: "00-1B-25",vendor: "Nortel Networks"},
  {max_prefix: "00-19-E1",vendor: "Nortel Networks"},
  {max_prefix: "28-BE-03",vendor: "TCT mobile ltd"},
  {max_prefix: "90-3A-E6",vendor: "PARROT SA"},
  {max_prefix: "A0-98-ED",vendor: "Shandong Intelligent Optical Communication Development Co., Ltd."},
  {max_prefix: "00-0E-F4",vendor: "Kasda Networks Inc"},
  {max_prefix: "00-16-7A",vendor: "Skyworth Overseas Development Ltd."},
  {max_prefix: "00-25-61",vendor: "ProCurve Networking by HP"},
  {max_prefix: "00-80-58",vendor: "PRINTER SYSTEMS CORP."},
  {max_prefix: "00-15-7D",vendor: "POSDATA"},
  {max_prefix: "48-49-C7",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "10-E8-78",vendor: "Nokia"},
  {max_prefix: "FC-B0-C4",vendor: "Shanghai DareGlobal Technologies Co.,Ltd"},
  {max_prefix: "A8-9D-D2",vendor: "Shanghai DareGlobal Technologies Co.,Ltd"},
  {max_prefix: "00-E0-0F",vendor: "Shanghai Baud Data Communication Co.,Ltd."},
  {max_prefix: "00-14-0D",vendor: "Nortel Networks"},
  {max_prefix: "84-98-66",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "18-80-F5",vendor: "Alcatel-Lucent Shanghai Bell Co., Ltd"},
  {max_prefix: "00-1D-42",vendor: "Nortel Networks"},
  {max_prefix: "A4-29-40",vendor: "Shenzhen YOUHUA Technology Co., Ltd"},
  {max_prefix: "E4-A3-87",vendor: "Control Solutions LLC"},
  {max_prefix: "10-F9-6F",vendor: "LG Electronics (Mobile Communications)"},
  {max_prefix: "C4-43-8F",vendor: "LG Electronics (Mobile Communications)"},
  {max_prefix: "A0-91-69",vendor: "LG Electronics (Mobile Communications)"},
  {max_prefix: "28-6C-07",vendor: "XIAOMI Electronics,CO.,LTD"},
  {max_prefix: "00-22-80",vendor: "A2B Electronics AB"},
  {max_prefix: "40-4A-D4",vendor: "Widex A/S"},
  {max_prefix: "98-93-CC",vendor: "LG ELECTRONICS INC"},
  {max_prefix: "3C-CD-93",vendor: "LG ELECTRONICS INC"},
  {max_prefix: "20-21-A5",vendor: "LG Electronics (Mobile Communications)"},
  {max_prefix: "6C-D6-8A",vendor: "LG Electronics (Mobile Communications)"},
  {max_prefix: "00-24-83",vendor: "LG Electronics (Mobile Communications)"},
  {max_prefix: "00-1F-E3",vendor: "LG Electronics (Mobile Communications)"},
  {max_prefix: "2C-54-CF",vendor: "LG Electronics (Mobile Communications)"},
  {max_prefix: "48-59-29",vendor: "LG Electronics (Mobile Communications)"},
  {max_prefix: "58-A2-B5",vendor: "LG Electronics (Mobile Communications)"},
  {max_prefix: "00-0F-62",vendor: "Alcatel Bell Space N.V."},
  {max_prefix: "F4-85-C6",vendor: "FDT Technologies"},
  {max_prefix: "00-19-AB",vendor: "Raycom CO ., LTD"},
  {max_prefix: "4C-33-4E",vendor: "HIGHTECH"},
  {max_prefix: "CC-79-CF",vendor: "SHENZHEN RF-LINK TECHNOLOGY CO.,LTD."},
  {max_prefix: "00-19-25",vendor: "Intelicis Corporation"},
  {max_prefix: "94-76-B7",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "B8-D8-12",vendor: "IEEE Registration Authority"},
  {max_prefix: "1C-21-D1",vendor: "IEEE Registration Authority"},
  {max_prefix: "7C-70-BC",vendor: "IEEE Registration Authority"},
  {max_prefix: "E8-18-63",vendor: "IEEE Registration Authority"},
  {max_prefix: "2C-D1-41",vendor: "IEEE Registration Authority"},
  {max_prefix: "3C-39-E7",vendor: "IEEE Registration Authority"},
  {max_prefix: "BC-66-41",vendor: "IEEE Registration Authority"},
  {max_prefix: "80-E4-DA",vendor: "IEEE Registration Authority"},
  {max_prefix: "88-5D-90",vendor: "IEEE Registration Authority"},
  {max_prefix: "C8-8E-D1",vendor: "IEEE Registration Authority"},
  {max_prefix: "B0-1F-81",vendor: "IEEE Registration Authority"},
  {max_prefix: "88-12-4E",vendor: "Qualcomm Inc."},
  {max_prefix: "00-1B-32",vendor: "QLogic Corporation"},
  {max_prefix: "00-17-CA",vendor: "Qisda Corporation"},
  {max_prefix: "F0-42-1C",vendor: "Intel Corporate"},
  {max_prefix: "00-1C-D8",vendor: "BlueAnt Wireless"},
  {max_prefix: "28-36-38",vendor: "IEEE Registration Authority"},
  {max_prefix: "C8-75-5B",vendor: "Quantify Technology Pty. Ltd."},
  {max_prefix: "4C-66-41",vendor: "SAMSUNG ELECTRO-MECHANICS(THAILAND)"},
  {max_prefix: "A8-54-B2",vendor: "Wistron Neweb Corporation"},
  {max_prefix: "E0-95-79",vendor: "ORTHOsoft inc, d/b/a Zimmer CAS"},
  {max_prefix: "A0-AD-A1",vendor: "JMR Electronics, Inc"},
  {max_prefix: "E0-AC-F1",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-01-5B",vendor: "ITALTEL S.p.A/RF-UP-I"},
  {max_prefix: "BC-C0-0F",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "9C-A5-C0",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "30-44-A1",vendor: "Shanghai Nanchao Information Technology"},
  {max_prefix: "00-A0-A8",vendor: "RENEX CORPORATION"},
  {max_prefix: "70-F3-95",vendor: "Universal Global Scientific Industrial Co., Ltd."},
  {max_prefix: "90-A4-DE",vendor: "Wistron Neweb Corporation"},
  {max_prefix: "70-E2-84",vendor: "Wistron Infocomm (Zhongshan) Corporation"},
  {max_prefix: "00-15-B7",vendor: "Toshiba"},
  {max_prefix: "E8-9D-87",vendor: "Toshiba"},
  {max_prefix: "00-14-28",vendor: "Vocollect Inc"},
  {max_prefix: "00-6B-9E",vendor: "Vizio, Inc"},
  {max_prefix: "5C-A8-6A",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "00-C0-AB",vendor: "Telco Systems, Inc."},
  {max_prefix: "00-1D-7E",vendor: "Cisco-Linksys, LLC"},
  {max_prefix: "E4-FB-8F",vendor: "MOBIWIRE MOBILES (NINGBO) CO.,LTD"},
  {max_prefix: "38-76-CA",vendor: "Shenzhen Smart Intelligent Technology Co.Ltd"},
  {max_prefix: "F4-0A-4A",vendor: "INDUSNET Communication Technology Co.,LTD"},
  {max_prefix: "00-23-F8",vendor: "Zyxel Communications Corporation"},
  {max_prefix: "00-19-CB",vendor: "Zyxel Communications Corporation"},
  {max_prefix: "10-BD-55",vendor: "Q-Lab Corporation"},
  {max_prefix: "B4-04-18",vendor: "Smartchip Integrated Inc."},
  {max_prefix: "90-CF-7D",vendor: "Qingdao Hisense Communications Co.,Ltd."},
  {max_prefix: "C4-49-BB",vendor: "MITSUMI ELECTRIC CO.,LTD."},
  {max_prefix: "FC-2D-5E",vendor: "zte corporation"},
  {max_prefix: "2C-09-4D",vendor: "Raptor Engineering, LLC"},
  {max_prefix: "AC-37-43",vendor: "HTC Corporation"},
  {max_prefix: "F8-5A-00",vendor: "Sanford LP"},
  {max_prefix: "B8-BB-AF",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "60-C5-AD",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "44-2C-05",vendor: "AMPAK Technology, Inc."},
  {max_prefix: "60-38-E0",vendor: "Belkin International Inc."},
  {max_prefix: "8C-89-7A",vendor: "AUGTEK"},
  {max_prefix: "00-25-50",vendor: "Riverbed Technology, Inc."},
  {max_prefix: "FC-55-DC",vendor: "Baltic Latvian Universal Electronics LLC"},
  {max_prefix: "04-27-58",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "9C-E3-74",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "8C-D2-E9",vendor: "YOKOTE SEIKO CO., LTD."},
  {max_prefix: "50-AB-3E",vendor: "Qibixx AG"},
  {max_prefix: "24-F0-94",vendor: "Apple, Inc."},
  {max_prefix: "08-6D-41",vendor: "Apple, Inc."},
  {max_prefix: "EC-AD-B8",vendor: "Apple, Inc."},
  {max_prefix: "98-01-A7",vendor: "Apple, Inc."},
  {max_prefix: "00-25-52",vendor: "VXi Corporation"},
  {max_prefix: "F4-91-1E",vendor: "ZHUHAI EWPE INFORMATION TECHNOLOGY INC"},
  {max_prefix: "7C-73-8B",vendor: "Cocoon Alarm Ltd"},
  {max_prefix: "B4-D5-BD",vendor: "Intel Corporate"},
  {max_prefix: "98-AA-3C",vendor: "Will i-tech Co., Ltd."},
  {max_prefix: "DC-37-52",vendor: "GE"},
  {max_prefix: "68-79-ED",vendor: "SHARP Corporation"},
  {max_prefix: "60-8D-17",vendor: "Sentrus Government Systems Division, Inc"},
  {max_prefix: "00-23-82",vendor: "Lih Rong electronic Enterprise Co., Ltd."},
  {max_prefix: "F8-45-AD",vendor: "Konka Group Co., Ltd."},
  {max_prefix: "00-0F-E2",vendor: "Hangzhou H3C Technologies Co., Limited"},
  {max_prefix: "6C-E9-83",vendor: "Gastron Co., LTD."},
  {max_prefix: "28-E3-1F",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "DC-D9-16",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "00-02-2E",vendor: "TEAC Corp. R& D"},
  {max_prefix: "00-60-B0",vendor: "Hewlett Packard"},
  {max_prefix: "F8-0F-84",vendor: "Natural Security SAS"},
  {max_prefix: "44-A4-2D",vendor: "TCT mobile ltd"},
  {max_prefix: "80-F6-2E",vendor: "Hangzhou H3C Technologies Co., Limited"},
  {max_prefix: "70-F9-6D",vendor: "Hangzhou H3C Technologies Co., Limited"},
  {max_prefix: "08-3F-BC",vendor: "zte corporation"},
  {max_prefix: "00-C0-F0",vendor: "Kingston Technology Company, Inc."},
  {max_prefix: "00-18-D7",vendor: "JAVAD GNSS, Inc."},
  {max_prefix: "00-1F-09",vendor: "Jastec"},
  {max_prefix: "AC-62-0D",vendor: "Jabil Circuit(Wuxi) Co.,Ltd"},
  {max_prefix: "1C-73-70",vendor: "Neotech"},
  {max_prefix: "30-E3-7A",vendor: "Intel Corporate"},
  {max_prefix: "BC-6A-44",vendor: "Commend International GmbH"},
  {max_prefix: "F0-EE-58",vendor: "PACE Telematics GmbH"},
  {max_prefix: "4C-A0-03",vendor: "VITEC"},
  {max_prefix: "08-00-0D",vendor: "International Computers, Ltd"},
  {max_prefix: "B8-AE-ED",vendor: "Elitegroup Computer Systems Co.,Ltd."},
  {max_prefix: "00-00-C9",vendor: "Emulex Corporation"},
  {max_prefix: "00-40-AA",vendor: "Valmet Automation"},
  {max_prefix: "70-50-AF",vendor: "SKY UK LIMITED"},
  {max_prefix: "F4-EF-9E",vendor: "SGSG SCIENCE & TECHNOLOGY CO. LTD"},
  {max_prefix: "1C-74-0D",vendor: "Zyxel Communications Corporation"},
  {max_prefix: "60-3E-CA",vendor: "Cambridge Medical Robotics Ltd"},
  {max_prefix: "9C-74-1A",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "E4-A8-B6",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "24-4C-07",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "74-6F-F7",vendor: "Wistron Neweb Corporation"},
  {max_prefix: "00-0D-B0",vendor: "Olym-tech Co.,Ltd."},
  {max_prefix: "30-F6-B9",vendor: "Ecocentric Energy"},
  {max_prefix: "D0-B0-CD",vendor: "Moen"},
  {max_prefix: "00-02-0E",vendor: "ECI Telecom Ltd."},
  {max_prefix: "20-0A-5E",vendor: "Xiangshan Giant Eagle Technology Developing Co., Ltd."},
  {max_prefix: "00-1F-1F",vendor: "Edimax Technology Co. Ltd."},
  {max_prefix: "B0-E2-E5",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "00-C1-64",vendor: "Cisco Systems, Inc"},
  {max_prefix: "CC-B1-1A",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "70-3C-03",vendor: "RadiAnt Co.,Ltd"},
  {max_prefix: "40-47-6A",vendor: "Astro Gaming"},
  {max_prefix: "00-1F-A7",vendor: "Sony Interactive Entertainment Inc."},
  {max_prefix: "90-46-A2",vendor: "Tedipay UK Ltd"},
  {max_prefix: "64-79-A7",vendor: "Phison Electronics Corp."},
  {max_prefix: "1C-5F-2B",vendor: "D-Link International"},
  {max_prefix: "CC-D3-E2",vendor: "Jiangsu Yinhe  Electronics Co.,Ltd."},
  {max_prefix: "E4-FA-ED",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "28-83-35",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "DC-CF-96",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "AC-44-F2",vendor: "YAMAHA CORPORATION"},
  {max_prefix: "1C-EA-1B",vendor: "Nokia"},
  {max_prefix: "D4-61-2E",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "54-51-1B",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "68-53-6C",vendor: "SPnS Co.,Ltd"},
  {max_prefix: "DC-2D-CB",vendor: "Beijing Unis HengYue Technology Co., Ltd."},
  {max_prefix: "2C-96-62",vendor: "Invenit BV"},
  {max_prefix: "84-68-3E",vendor: "Intel Corporate"},
  {max_prefix: "74-3E-2B",vendor: "Ruckus Wireless"},
  {max_prefix: "E0-C7-67",vendor: "Apple, Inc."},
  {max_prefix: "80-ED-2C",vendor: "Apple, Inc."},
  {max_prefix: "CC-B3-AB",vendor: "shenzhen Biocare Bio-Medical Equipment Co.,Ltd."},
  {max_prefix: "E4-B3-18",vendor: "Intel Corporate"},
  {max_prefix: "E0-CD-FD",vendor: "Beijing E3Control Technology Co, LTD"},
  {max_prefix: "F0-34-04",vendor: "TCT mobile ltd"},
  {max_prefix: "80-D1-60",vendor: "Integrated Device Technology (Malaysia) Sdn. Bhd."},
  {max_prefix: "04-7E-4A",vendor: "moobox CO., Ltd."},
  {max_prefix: "00-80-E5",vendor: "NetApp"},
  {max_prefix: "9C-5C-8E",vendor: "ASUSTek COMPUTER INC."},
  {max_prefix: "30-78-5C",vendor: "Partow Tamas Novin (Parman)"},
  {max_prefix: "60-AC-C8",vendor: "KunTeng Inc."},
  {max_prefix: "C8-87-22",vendor: "Lumenpulse"},
  {max_prefix: "70-66-1B",vendor: "Sonova AG"},
  {max_prefix: "B0-7F-B9",vendor: "NETGEAR"},
  {max_prefix: "3C-59-1E",vendor: "TCL King Electrical Appliances (Huizhou) Co., Ltd"},
  {max_prefix: "00-26-82",vendor: "Gemtek Technology Co., Ltd."},
  {max_prefix: "00-09-E1",vendor: "Gemtek Technology Co., Ltd."},
  {max_prefix: "14-C1-26",vendor: "Nokia Corporation"},
  {max_prefix: "60-01-94",vendor: "Espressif Inc."},
  {max_prefix: "D8-F7-10",vendor: "Libre Wireless Technologies Inc."},
  {max_prefix: "84-BA-3B",vendor: "CANON INC."},
  {max_prefix: "A8-7E-33",vendor: "Nokia Danmark A/S"},
  {max_prefix: "00-24-03",vendor: "Nokia Danmark A/S"},
  {max_prefix: "00-24-04",vendor: "Nokia Danmark A/S"},
  {max_prefix: "8C-A2-FD",vendor: "Starry, Inc."},
  {max_prefix: "00-1B-33",vendor: "Nokia Danmark A/S"},
  {max_prefix: "00-19-4F",vendor: "Nokia Danmark A/S"},
  {max_prefix: "00-BD-3A",vendor: "Nokia Corporation"},
  {max_prefix: "80-50-1B",vendor: "Nokia Corporation"},
  {max_prefix: "A0-4E-04",vendor: "Nokia Corporation"},
  {max_prefix: "AC-61-EA",vendor: "Apple, Inc."},
  {max_prefix: "38-B5-4D",vendor: "Apple, Inc."},
  {max_prefix: "1C-5C-F2",vendor: "Apple, Inc."},
  {max_prefix: "00-12-62",vendor: "Nokia Danmark A/S"},
  {max_prefix: "00-14-A7",vendor: "Nokia Danmark A/S"},
  {max_prefix: "00-15-A0",vendor: "Nokia Danmark A/S"},
  {max_prefix: "00-16-BC",vendor: "Nokia Danmark A/S"},
  {max_prefix: "00-17-4B",vendor: "Nokia Danmark A/S"},
  {max_prefix: "00-19-B7",vendor: "Nokia Danmark A/S"},
  {max_prefix: "00-17-B0",vendor: "Nokia Danmark A/S"},
  {max_prefix: "00-15-DE",vendor: "Nokia Danmark A/S"},
  {max_prefix: "00-02-EE",vendor: "Nokia Danmark A/S"},
  {max_prefix: "00-26-69",vendor: "Nokia Danmark A/S"},
  {max_prefix: "00-21-09",vendor: "Nokia Danmark A/S"},
  {max_prefix: "00-21-08",vendor: "Nokia Danmark A/S"},
  {max_prefix: "A4-C7-DE",vendor: "Cambridge Industries(Group) Co.,Ltd."},
  {max_prefix: "00-19-C7",vendor: "Cambridge Industries(Group) Co.,Ltd."},
  {max_prefix: "70-D9-31",vendor: "Cambridge Industries(Group) Co.,Ltd."},
  {max_prefix: "C4-36-55",vendor: "Shenzhen Fenglian Technology Co., Ltd."},
  {max_prefix: "E0-B9-E5",vendor: "Technicolor Delivery Technologies Belgium NV"},
  {max_prefix: "02-70-B3",vendor: "DATA RECALL LTD."},
  {max_prefix: "00-01-36",vendor: "CyberTAN Technology Inc."},
  {max_prefix: "00-30-DA",vendor: "Comtrend Corporation"},
  {max_prefix: "64-68-0C",vendor: "Comtrend Corporation"},
  {max_prefix: "38-72-C0",vendor: "Comtrend Corporation"},
  {max_prefix: "A8-06-00",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "00-60-BB",vendor: "Cabletron Systems, Inc."},
  {max_prefix: "D8-B8-F6",vendor: "Nantworks"},
  {max_prefix: "00-80-77",vendor: "Brother industries, LTD."},
  {max_prefix: "C4-57-6E",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "50-85-69",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "02-9D-8E",vendor: "CARDIAC RECORDERS, INC."},
  {max_prefix: "00-40-2A",vendor: "Canoga Perkins Corporation"},
  {max_prefix: "A0-B4-A5",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "E4-F8-EF",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "24-F5-AA",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "98-83-89",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "84-A4-66",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "F0-5A-09",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "50-32-75",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "08-FC-88",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "F8-D0-BD",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "78-59-5E",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "0C-14-20",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "94-B1-0A",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "3C-BB-FD",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "A4-84-31",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "14-0C-76",vendor: "FREEBOX SAS"},
  {max_prefix: "D0-4D-2C",vendor: "Roku, Inc."},
  {max_prefix: "B0-A7-37",vendor: "Roku, Inc."},
  {max_prefix: "00-1B-E9",vendor: "Broadcom"},
  {max_prefix: "DC-64-B8",vendor: "Shenzhen JingHanDa Electronics Co.Ltd"},
  {max_prefix: "44-EE-02",vendor: "MTI Ltd."},
  {max_prefix: "00-13-33",vendor: "BaudTec Corporation"},
  {max_prefix: "58-67-1A",vendor: "Barnes&Noble"},
  {max_prefix: "00-26-75",vendor: "Aztech Electronics Pte Ltd"},
  {max_prefix: "00-24-FE",vendor: "AVM GmbH"},
  {max_prefix: "C0-25-06",vendor: "AVM GmbH"},
  {max_prefix: "40-5D-82",vendor: "NETGEAR"},
  {max_prefix: "DC-EF-09",vendor: "NETGEAR"},
  {max_prefix: "DC-44-6D",vendor: "Allwinner Technology Co., Ltd"},
  {max_prefix: "74-5A-AA",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "04-FE-8D",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "A8-D3-F7",vendor: "Arcadyan Technology Corporation"},
  {max_prefix: "00-0D-92",vendor: "ARIMA Communications Corp."},
  {max_prefix: "00-21-63",vendor: "ASKEY COMPUTER CORP"},
  {max_prefix: "4C-60-DE",vendor: "NETGEAR"},
  {max_prefix: "C4-3D-C7",vendor: "NETGEAR"},
  {max_prefix: "48-9D-24",vendor: "BlackBerry RTS"},
  {max_prefix: "08-BD-43",vendor: "NETGEAR"},
  {max_prefix: "00-1F-E4",vendor: "Sony Corporation"},
  {max_prefix: "00-23-45",vendor: "Sony Corporation"},
  {max_prefix: "6C-0E-0D",vendor: "Sony Corporation"},
  {max_prefix: "6C-23-B9",vendor: "Sony Corporation"},
  {max_prefix: "00-1A-80",vendor: "Sony Corporation"},
  {max_prefix: "00-12-EE",vendor: "Sony Corporation"},
  {max_prefix: "00-16-20",vendor: "Sony Corporation"},
  {max_prefix: "00-19-63",vendor: "Sony Corporation"},
  {max_prefix: "30-17-C8",vendor: "Sony Corporation"},
  {max_prefix: "20-54-76",vendor: "Sony Corporation"},
  {max_prefix: "70-3C-39",vendor: "SEAWING Kft"},
  {max_prefix: "90-97-D5",vendor: "Espressif Inc."},
  {max_prefix: "AC-D0-74",vendor: "Espressif Inc."},
  {max_prefix: "38-E3-C5",vendor: "Taicang T&W Electronics"},
  {max_prefix: "88-41-FC",vendor: "AirTies Wireless Networks"},
  {max_prefix: "98-74-3D",vendor: "Shenzhen Jun Kai Hengye Technology Co. Ltd"},
  {max_prefix: "A0-F4-59",vendor: "FN-LINK TECHNOLOGY LIMITED"},
  {max_prefix: "58-63-56",vendor: "FN-LINK TECHNOLOGY LIMITED"},
  {max_prefix: "00-25-D3",vendor: "AzureWave Technology Inc."},
  {max_prefix: "A0-4F-D4",vendor: "ADB Broadband Italia"},
  {max_prefix: "8C-B8-64",vendor: "AcSiP Technology Corp."},
  {max_prefix: "5C-E2-F4",vendor: "AcSiP Technology Corp."},
  {max_prefix: "B8-61-6F",vendor: "Accton Technology Corp"},
  {max_prefix: "00-12-CF",vendor: "Accton Technology Corp"},
  {max_prefix: "00-30-F1",vendor: "Accton Technology Corp"},
  {max_prefix: "70-5A-0F",vendor: "Hewlett Packard"},
  {max_prefix: "44-95-FA",vendor: "Qingdao Santong Digital Technology Co.Ltd"},
  {max_prefix: "74-C2-46",vendor: "Amazon Technologies Inc."},
  {max_prefix: "00-0F-A3",vendor: "Alpha Networks Inc."},
  {max_prefix: "00-1D-6A",vendor: "Alpha Networks Inc."},
  {max_prefix: "00-00-F4",vendor: "Allied Telesis, Inc."},
  {max_prefix: "00-15-77",vendor: "Allied Telesis, Inc."},
  {max_prefix: "1C-4B-D6",vendor: "AzureWave Technology Inc."},
  {max_prefix: "08-A9-5A",vendor: "AzureWave Technology Inc."},
  {max_prefix: "94-DB-C9",vendor: "AzureWave Technology Inc."},
  {max_prefix: "24-0A-64",vendor: "AzureWave Technology Inc."},
  {max_prefix: "40-E2-30",vendor: "AzureWave Technology Inc."},
  {max_prefix: "80-D2-1D",vendor: "AzureWave Technology Inc."},
  {max_prefix: "70-9E-29",vendor: "Sony Interactive Entertainment Inc."},
  {max_prefix: "A4-DB-30",vendor: "Liteon Technology Corporation"},
  {max_prefix: "40-F0-2F",vendor: "Liteon Technology Corporation"},
  {max_prefix: "00-30-D3",vendor: "Agilent Technologies, Inc."},
  {max_prefix: "00-A0-2F",vendor: "ADB Broadband Italia"},
  {max_prefix: "D4-D1-84",vendor: "ADB Broadband Italia"},
  {max_prefix: "D0-0E-D9",vendor: "Taicang T&W Electronics"},
  {max_prefix: "54-14-73",vendor: " Wingtech Group (HongKong\uff09Limited"},
  {max_prefix: "0C-CC-26",vendor: "Airenetworks"},
  {max_prefix: "00-1F-3B",vendor: "Intel Corporate"},
  {max_prefix: "00-21-5D",vendor: "Intel Corporate"},
  {max_prefix: "00-21-6A",vendor: "Intel Corporate"},
  {max_prefix: "00-16-76",vendor: "Intel Corporate"},
  {max_prefix: "00-16-EA",vendor: "Intel Corporate"},
  {max_prefix: "90-E2-BA",vendor: "Intel Corporate"},
  {max_prefix: "00-26-C7",vendor: "Intel Corporate"},
  {max_prefix: "00-26-C6",vendor: "Intel Corporate"},
  {max_prefix: "88-53-2E",vendor: "Intel Corporate"},
  {max_prefix: "E0-94-67",vendor: "Intel Corporate"},
  {max_prefix: "08-D4-0C",vendor: "Intel Corporate"},
  {max_prefix: "6C-88-14",vendor: "Intel Corporate"},
  {max_prefix: "7C-CC-B8",vendor: "Intel Corporate"},
  {max_prefix: "F4-06-69",vendor: "Intel Corporate"},
  {max_prefix: "00-1D-E1",vendor: "Intel Corporate"},
  {max_prefix: "E0-9D-31",vendor: "Intel Corporate"},
  {max_prefix: "80-86-F2",vendor: "Intel Corporate"},
  {max_prefix: "30-3A-64",vendor: "Intel Corporate"},
  {max_prefix: "AC-FD-CE",vendor: "Intel Corporate"},
  {max_prefix: "74-C9-9A",vendor: "Ericsson AB"},
  {max_prefix: "5C-C2-13",vendor: "Fr. Sauter AG"},
  {max_prefix: "28-10-1B",vendor: "MagnaCom"},
  {max_prefix: "00-14-95",vendor: "2Wire Inc"},
  {max_prefix: "C4-66-99",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "C8-F2-30",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "8C-0E-E3",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "98-2C-BE",vendor: "2Wire Inc"},
  {max_prefix: "64-0F-28",vendor: "2Wire Inc"},
  {max_prefix: "88-4A-EA",vendor: "Texas Instruments"},
  {max_prefix: "00-1B-77",vendor: "Intel Corporate"},
  {max_prefix: "00-1C-C0",vendor: "Intel Corporate"},
  {max_prefix: "10-4A-7D",vendor: "Intel Corporate"},
  {max_prefix: "00-15-56",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "C0-D0-44",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "A0-1B-29",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "74-E1-4A",vendor: "IEEE Registration Authority"},
  {max_prefix: "0C-EF-AF",vendor: "IEEE Registration Authority"},
  {max_prefix: "F8-02-78",vendor: "IEEE Registration Authority"},
  {max_prefix: "A0-BB-3E",vendor: "IEEE Registration Authority"},
  {max_prefix: "B0-79-94",vendor: "Motorola Mobility LLC, a Lenovo Company"},
  {max_prefix: "A4-70-D6",vendor: "Motorola Mobility LLC, a Lenovo Company"},
  {max_prefix: "C8-CD-72",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "00-22-A4",vendor: "2Wire Inc"},
  {max_prefix: "B4-99-BA",vendor: "Hewlett Packard"},
  {max_prefix: "0C-47-C9",vendor: "Amazon Technologies Inc."},
  {max_prefix: "00-50-BA",vendor: "D-Link Corporation"},
  {max_prefix: "00-17-9A",vendor: "D-Link Corporation"},
  {max_prefix: "00-1C-F0",vendor: "D-Link Corporation"},
  {max_prefix: "00-1E-58",vendor: "D-Link Corporation"},
  {max_prefix: "00-22-B0",vendor: "D-Link Corporation"},
  {max_prefix: "00-24-01",vendor: "D-Link Corporation"},
  {max_prefix: "1C-AF-F7",vendor: "D-Link International"},
  {max_prefix: "14-D6-4D",vendor: "D-Link International"},
  {max_prefix: "90-94-E4",vendor: "D-Link International"},
  {max_prefix: "00-30-C5",vendor: "CADENCE DESIGN SYSTEMS, INC."},
  {max_prefix: "34-8A-AE",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "F8-2C-18",vendor: "2Wire Inc"},
  {max_prefix: "18-01-7D",vendor: "Harbin Arteor technology co., LTD"},
  {max_prefix: "94-44-52",vendor: "Belkin International Inc."},
  {max_prefix: "00-14-BF",vendor: "Cisco-Linksys, LLC"},
  {max_prefix: "BC-0D-A5",vendor: "Texas Instruments"},
  {max_prefix: "CC-8C-E3",vendor: "Texas Instruments"},
  {max_prefix: "E0-D7-BA",vendor: "Texas Instruments"},
  {max_prefix: "7C-03-D8",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "C0-AC-54",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "2C-39-96",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "F0-82-61",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "D0-8C-B5",vendor: "Texas Instruments"},
  {max_prefix: "00-18-2F",vendor: "Texas Instruments"},
  {max_prefix: "00-17-EA",vendor: "Texas Instruments"},
  {max_prefix: "00-21-BA",vendor: "Texas Instruments"},
  {max_prefix: "1C-E2-CC",vendor: "Texas Instruments"},
  {max_prefix: "98-59-45",vendor: "Texas Instruments"},
  {max_prefix: "D4-94-A1",vendor: "Texas Instruments"},
  {max_prefix: "B0-B4-48",vendor: "Texas Instruments"},
  {max_prefix: "28-7C-DB",vendor: "Hefei  Toycloud Technology Co.,ltd"},
  {max_prefix: "CC-B2-55",vendor: "D-Link International"},
  {max_prefix: "28-10-7B",vendor: "D-Link International"},
  {max_prefix: "FC-75-16",vendor: "D-Link International"},
  {max_prefix: "84-C9-B2",vendor: "D-Link International"},
  {max_prefix: "C8-D3-A3",vendor: "D-Link International"},
  {max_prefix: "3C-BB-73",vendor: "Shenzhen Xinguodu Technology Co., Ltd."},
  {max_prefix: "04-78-63",vendor: "Shanghai MXCHIP Information Technology Co., Ltd."},
  {max_prefix: "80-6A-B0",vendor: "Shenzhen TINNO Mobile Technology Corp."},
  {max_prefix: "48-AD-08",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "3C-5A-B4",vendor: "Google, Inc."},
  {max_prefix: "F4-F5-E8",vendor: "Google, Inc."},
  {max_prefix: "94-EB-2C",vendor: "Google, Inc."},
  {max_prefix: "4C-FB-45",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "00-9A-CD",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "70-B3-D5",vendor: "IEEE Registration Authority"},
  {max_prefix: "28-ED-6A",vendor: "Apple, Inc."},
  {max_prefix: "40-9F-87",vendor: "Jide Technology (Hong Kong) Limited"},
  {max_prefix: "0C-F9-C0",vendor: "SKY UK LIMITED"},
  {max_prefix: "4C-FF-12",vendor: "Fuze Entertainment Co., ltd"},
  {max_prefix: "0C-C7-31",vendor: "Currant, Inc."},
  {max_prefix: "AC-9A-22",vendor: "NXP Semiconductors"},
  {max_prefix: "38-F2-3E",vendor: "Microsoft Mobile Oy"},
  {max_prefix: "00-07-40",vendor: "BUFFALO.INC"},
  {max_prefix: "00-24-A5",vendor: "BUFFALO.INC"},
  {max_prefix: "CC-E1-D5",vendor: "BUFFALO.INC"},
  {max_prefix: "E4-F8-9C",vendor: "Intel Corporate"},
  {max_prefix: "2C-41-38",vendor: "Hewlett Packard"},
  {max_prefix: "44-1E-A1",vendor: "Hewlett Packard"},
  {max_prefix: "78-E7-D1",vendor: "Hewlett Packard"},
  {max_prefix: "00-1C-C4",vendor: "Hewlett Packard"},
  {max_prefix: "00-1E-0B",vendor: "Hewlett Packard"},
  {max_prefix: "6C-A1-00",vendor: "Intel Corporate"},
  {max_prefix: "A4-02-B9",vendor: "Intel Corporate"},
  {max_prefix: "DC-53-60",vendor: "Intel Corporate"},
  {max_prefix: "00-0C-F1",vendor: "Intel Corporation"},
  {max_prefix: "00-12-F0",vendor: "Intel Corporate"},
  {max_prefix: "5C-B9-01",vendor: "Hewlett Packard"},
  {max_prefix: "DC-4A-3E",vendor: "Hewlett Packard"},
  {max_prefix: "9C-B6-54",vendor: "Hewlett Packard"},
  {max_prefix: "6C-3B-E5",vendor: "Hewlett Packard"},
  {max_prefix: "A0-B3-CC",vendor: "Hewlett Packard"},
  {max_prefix: "EC-B1-D7",vendor: "Hewlett Packard"},
  {max_prefix: "78-48-59",vendor: "Hewlett Packard"},
  {max_prefix: "34-64-A9",vendor: "Hewlett Packard"},
  {max_prefix: "38-63-BB",vendor: "Hewlett Packard"},
  {max_prefix: "00-22-64",vendor: "Hewlett Packard"},
  {max_prefix: "00-25-B3",vendor: "Hewlett Packard"},
  {max_prefix: "64-31-50",vendor: "Hewlett Packard"},
  {max_prefix: "58-DC-6D",vendor: "Exceptional Innovation, Inc."},
  {max_prefix: "90-21-55",vendor: "HTC Corporation"},
  {max_prefix: "D8-B3-77",vendor: "HTC Corporation"},
  {max_prefix: "B0-F1-A3",vendor: "Fengfan (BeiJing) Technology Co., Ltd."},
  {max_prefix: "7C-B1-5D",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "88-CF-98",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "14-04-67",vendor: "SNK Technologies Co.,Ltd."},
  {max_prefix: "80-30-DC",vendor: "Texas Instruments"},
  {max_prefix: "B0-5A-DA",vendor: "Hewlett Packard"},
  {max_prefix: "00-10-83",vendor: "Hewlett Packard"},
  {max_prefix: "A4-D1-8C",vendor: "Apple, Inc."},
  {max_prefix: "24-1E-EB",vendor: "Apple, Inc."},
  {max_prefix: "CC-25-EF",vendor: "Apple, Inc."},
  {max_prefix: "94-2C-B3",vendor: "HUMAX Co., Ltd."},
  {max_prefix: "00-27-19",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "40-16-9F",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "F4-EC-38",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "A4-D5-78",vendor: "Texas Instruments"},
  {max_prefix: "24-60-81",vendor: "razberi technologies"},
  {max_prefix: "00-26-5E",vendor: "Hon Hai Precision Ind. Co.,Ltd."},
  {max_prefix: "00-24-2C",vendor: "Hon Hai Precision Ind. Co.,Ltd."},
  {max_prefix: "80-89-17",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "A0-93-47",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "E8-BB-A8",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "B0-AA-36",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "78-4B-87",vendor: "Murata Manufacturing Co., Ltd."},
  {max_prefix: "E4-CE-02",vendor: "WyreStorm Technologies Ltd"},
  {max_prefix: "9C-D2-4B",vendor: "zte corporation"},
  {max_prefix: "C8-7B-5B",vendor: "zte corporation"},
  {max_prefix: "00-19-C6",vendor: "zte corporation"},
  {max_prefix: "00-1D-D9",vendor: "Hon Hai Precision Ind. Co.,Ltd."},
  {max_prefix: "00-19-7D",vendor: "Hon Hai Precision Ind. Co.,Ltd."},
  {max_prefix: "90-F6-52",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "14-CF-92",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "20-DC-E6",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "14-CC-20",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "C0-3E-0F",vendor: "SKY UK LIMITED"},
  {max_prefix: "7C-4C-A5",vendor: "SKY UK LIMITED"},
  {max_prefix: "CC-4E-24",vendor: "Brocade Communications Systems LLC"},
  {max_prefix: "00-E0-52",vendor: "Brocade Communications Systems LLC"},
  {max_prefix: "00-14-A4",vendor: "Hon Hai Precision Ind. Co.,Ltd."},
  {max_prefix: "78-DD-08",vendor: "Hon Hai Precision Ind. Co.,Ltd."},
  {max_prefix: "9C-D2-1E",vendor: "Hon Hai Precision Ind. Co.,Ltd."},
  {max_prefix: "F8-0D-43",vendor: "Hon Hai Precision Ind. Co.,Ltd."},
  {max_prefix: "00-01-0F",vendor: "Brocade Communications Systems LLC"},
  {max_prefix: "08-00-88",vendor: "Brocade Communications Systems LLC"},
  {max_prefix: "84-74-2A",vendor: "zte corporation"},
  {max_prefix: "38-F8-89",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "D0-7A-B5",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "90-4E-2B",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "20-08-ED",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "40-F3-08",vendor: "Murata Manufacturing Co., Ltd."},
  {max_prefix: "00-07-D8",vendor: "Hitron Technologies. Inc"},
  {max_prefix: "B4-30-52",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "80-D0-9B",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "1C-8E-5C",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "00-25-68",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "78-1D-BA",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "00-25-9E",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "34-75-C7",vendor: "Avaya Inc"},
  {max_prefix: "B0-AD-AA",vendor: "Avaya Inc"},
  {max_prefix: "FC-48-EF",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "70-7B-E8",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "4C-1F-CC",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "D4-B1-10",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "E4-68-A3",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "88-53-D4",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "04-C0-6F",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "20-2B-C1",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "54-A5-1B",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "00-6B-8E",vendor: "Shanghai Feixun Communication Co.,Ltd."},
  {max_prefix: "D4-6A-A8",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "B8-BC-1B",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "58-2A-F7",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "00-34-FE",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "C8-51-95",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "40-CB-A8",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "D4-6E-5C",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "4C-8B-EF",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "B4-47-5E",vendor: "Avaya Inc"},
  {max_prefix: "BC-AD-AB",vendor: "Avaya Inc"},
  {max_prefix: "FC-A8-41",vendor: "Avaya Inc"},
  {max_prefix: "24-D9-21",vendor: "Avaya Inc"},
  {max_prefix: "84-83-71",vendor: "Avaya Inc"},
  {max_prefix: "00-1B-4F",vendor: "Avaya Inc"},
  {max_prefix: "34-00-A3",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "00-E0-8F",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-18-68",vendor: "Cisco SPVTG"},
  {max_prefix: "88-75-56",vendor: "Cisco Systems, Inc"},
  {max_prefix: "FC-99-47",vendor: "Cisco Systems, Inc"},
  {max_prefix: "6C-20-56",vendor: "Cisco Systems, Inc"},
  {max_prefix: "AC-F2-C5",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-03-DD",vendor: "Comark Interactive Solutions"},
  {max_prefix: "00-50-53",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-50-50",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-90-6D",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-90-AB",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-50-54",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-50-0B",vendor: "Cisco Systems, Inc"},
  {max_prefix: "24-01-C7",vendor: "Cisco Systems, Inc"},
  {max_prefix: "68-86-A7",vendor: "Cisco Systems, Inc"},
  {max_prefix: "08-CC-68",vendor: "Cisco Systems, Inc"},
  {max_prefix: "24-71-89",vendor: "Texas Instruments"},
  {max_prefix: "98-7B-F3",vendor: "Texas Instruments"},
  {max_prefix: "A0-F6-FD",vendor: "Texas Instruments"},
  {max_prefix: "D0-B5-C2",vendor: "Texas Instruments"},
  {max_prefix: "78-A5-04",vendor: "Texas Instruments"},
  {max_prefix: "6C-EC-EB",vendor: "Texas Instruments"},
  {max_prefix: "50-06-AB",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-E0-A3",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-90-2B",vendor: "Cisco Systems, Inc"},
  {max_prefix: "3C-0E-23",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-E0-18",vendor: "ASUSTek COMPUTER INC."},
  {max_prefix: "00-0C-6E",vendor: "ASUSTek COMPUTER INC."},
  {max_prefix: "00-0E-A6",vendor: "ASUSTek COMPUTER INC."},
  {max_prefix: "00-1D-60",vendor: "ASUSTek COMPUTER INC."},
  {max_prefix: "00-15-F2",vendor: "ASUSTek COMPUTER INC."},
  {max_prefix: "90-E6-BA",vendor: "ASUSTek COMPUTER INC."},
  {max_prefix: "0C-27-24",vendor: "Cisco Systems, Inc"},
  {max_prefix: "6C-41-6A",vendor: "Cisco Systems, Inc"},
  {max_prefix: "EC-E1-A9",vendor: "Cisco Systems, Inc"},
  {max_prefix: "1C-E8-5D",vendor: "Cisco Systems, Inc"},
  {max_prefix: "A8-9D-21",vendor: "Cisco Systems, Inc"},
  {max_prefix: "68-9C-E2",vendor: "Cisco Systems, Inc"},
  {max_prefix: "C0-67-AF",vendor: "Cisco Systems, Inc"},
  {max_prefix: "C0-25-5C",vendor: "Cisco Systems, Inc"},
  {max_prefix: "F4-4E-05",vendor: "Cisco Systems, Inc"},
  {max_prefix: "88-1D-FC",vendor: "Cisco Systems, Inc"},
  {max_prefix: "A0-F8-49",vendor: "Cisco Systems, Inc"},
  {max_prefix: "6C-FA-89",vendor: "Cisco Systems, Inc"},
  {max_prefix: "F4-6D-04",vendor: "ASUSTek COMPUTER INC."},
  {max_prefix: "74-5E-1C",vendor: "PIONEER CORPORATION"},
  {max_prefix: "00-10-11",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-00-0C",vendor: "Cisco Systems, Inc"},
  {max_prefix: "04-62-73",vendor: "Cisco Systems, Inc"},
  {max_prefix: "28-CF-E9",vendor: "Apple, Inc."},
  {max_prefix: "00-26-08",vendor: "Apple, Inc."},
  {max_prefix: "00-26-B0",vendor: "Apple, Inc."},
  {max_prefix: "00-26-BB",vendor: "Apple, Inc."},
  {max_prefix: "D4-9A-20",vendor: "Apple, Inc."},
  {max_prefix: "F8-1E-DF",vendor: "Apple, Inc."},
  {max_prefix: "D8-B1-90",vendor: "Cisco Systems, Inc"},
  {max_prefix: "80-E8-6F",vendor: "Cisco Systems, Inc"},
  {max_prefix: "AC-7E-8A",vendor: "Cisco Systems, Inc"},
  {max_prefix: "18-59-33",vendor: "Cisco SPVTG"},
  {max_prefix: "44-58-29",vendor: "Cisco SPVTG"},
  {max_prefix: "60-2A-D0",vendor: "Cisco SPVTG"},
  {max_prefix: "00-14-51",vendor: "Apple, Inc."},
  {max_prefix: "00-1E-52",vendor: "Apple, Inc."},
  {max_prefix: "00-21-E9",vendor: "Apple, Inc."},
  {max_prefix: "CC-08-E0",vendor: "Apple, Inc."},
  {max_prefix: "F0-B4-79",vendor: "Apple, Inc."},
  {max_prefix: "00-A0-40",vendor: "Apple, Inc."},
  {max_prefix: "00-30-65",vendor: "Apple, Inc."},
  {max_prefix: "00-23-BE",vendor: "Cisco SPVTG"},
  {max_prefix: "3C-D0-F8",vendor: "Apple, Inc."},
  {max_prefix: "68-09-27",vendor: "Apple, Inc."},
  {max_prefix: "6C-C2-6B",vendor: "Apple, Inc."},
  {max_prefix: "44-D8-84",vendor: "Apple, Inc."},
  {max_prefix: "64-20-0C",vendor: "Apple, Inc."},
  {max_prefix: "40-B3-95",vendor: "Apple, Inc."},
  {max_prefix: "44-FB-42",vendor: "Apple, Inc."},
  {max_prefix: "E8-8D-28",vendor: "Apple, Inc."},
  {max_prefix: "94-94-26",vendor: "Apple, Inc."},
  {max_prefix: "20-7D-74",vendor: "Apple, Inc."},
  {max_prefix: "F4-F1-5A",vendor: "Apple, Inc."},
  {max_prefix: "C8-6F-1D",vendor: "Apple, Inc."},
  {max_prefix: "B0-65-BD",vendor: "Apple, Inc."},
  {max_prefix: "8C-2D-AA",vendor: "Apple, Inc."},
  {max_prefix: "84-85-06",vendor: "Apple, Inc."},
  {max_prefix: "98-FE-94",vendor: "Apple, Inc."},
  {max_prefix: "D8-00-4D",vendor: "Apple, Inc."},
  {max_prefix: "10-93-E9",vendor: "Apple, Inc."},
  {max_prefix: "44-2A-60",vendor: "Apple, Inc."},
  {max_prefix: "C8-2A-14",vendor: "Apple, Inc."},
  {max_prefix: "3C-07-54",vendor: "Apple, Inc."},
  {max_prefix: "C8-33-4B",vendor: "Apple, Inc."},
  {max_prefix: "64-E6-82",vendor: "Apple, Inc."},
  {max_prefix: "9C-20-7B",vendor: "Apple, Inc."},
  {max_prefix: "A4-B1-97",vendor: "Apple, Inc."},
  {max_prefix: "A4-D1-D2",vendor: "Apple, Inc."},
  {max_prefix: "28-CF-DA",vendor: "Apple, Inc."},
  {max_prefix: "54-26-96",vendor: "Apple, Inc."},
  {max_prefix: "64-A3-CB",vendor: "Apple, Inc."},
  {max_prefix: "30-F7-C5",vendor: "Apple, Inc."},
  {max_prefix: "30-90-AB",vendor: "Apple, Inc."},
  {max_prefix: "04-54-53",vendor: "Apple, Inc."},
  {max_prefix: "F4-F9-51",vendor: "Apple, Inc."},
  {max_prefix: "C0-63-94",vendor: "Apple, Inc."},
  {max_prefix: "18-AF-8F",vendor: "Apple, Inc."},
  {max_prefix: "C8-B5-B7",vendor: "Apple, Inc."},
  {max_prefix: "90-B2-1F",vendor: "Apple, Inc."},
  {max_prefix: "B8-E8-56",vendor: "Apple, Inc."},
  {max_prefix: "20-78-F0",vendor: "Apple, Inc."},
  {max_prefix: "E0-AC-CB",vendor: "Apple, Inc."},
  {max_prefix: "A0-99-9B",vendor: "Apple, Inc."},
  {max_prefix: "24-24-0E",vendor: "Apple, Inc."},
  {max_prefix: "90-3C-92",vendor: "Apple, Inc."},
  {max_prefix: "AC-87-A3",vendor: "Apple, Inc."},
  {max_prefix: "D8-BB-2C",vendor: "Apple, Inc."},
  {max_prefix: "D0-4F-7E",vendor: "Apple, Inc."},
  {max_prefix: "9C-F3-87",vendor: "Apple, Inc."},
  {max_prefix: "B4-18-D1",vendor: "Apple, Inc."},
  {max_prefix: "F0-DB-F8",vendor: "Apple, Inc."},
  {max_prefix: "48-74-6E",vendor: "Apple, Inc."},
  {max_prefix: "34-36-3B",vendor: "Apple, Inc."},
  {max_prefix: "D0-A6-37",vendor: "Apple, Inc."},
  {max_prefix: "78-9F-70",vendor: "Apple, Inc."},
  {max_prefix: "A8-5B-78",vendor: "Apple, Inc."},
  {max_prefix: "FC-E9-98",vendor: "Apple, Inc."},
  {max_prefix: "0C-BC-9F",vendor: "Apple, Inc."},
  {max_prefix: "D8-96-95",vendor: "Apple, Inc."},
  {max_prefix: "14-99-E2",vendor: "Apple, Inc."},
  {max_prefix: "A8-8E-24",vendor: "Apple, Inc."},
  {max_prefix: "68-AE-20",vendor: "Apple, Inc."},
  {max_prefix: "54-AE-27",vendor: "Apple, Inc."},
  {max_prefix: "C8-F6-50",vendor: "Apple, Inc."},
  {max_prefix: "50-D5-9C",vendor: "Thai Habel Industrial Co., Ltd."},
  {max_prefix: "6C-4A-39",vendor: "BITA"},
  {max_prefix: "84-7D-50",vendor: "Holley Metering Limited"},
  {max_prefix: "D8-1D-72",vendor: "Apple, Inc."},
  {max_prefix: "34-12-98",vendor: "Apple, Inc."},
  {max_prefix: "70-E7-2C",vendor: "Apple, Inc."},
  {max_prefix: "70-EC-E4",vendor: "Apple, Inc."},
  {max_prefix: "E0-34-E4",vendor: "Feit Electric Company, Inc."},
  {max_prefix: "34-C9-F0",vendor: "LM Technologies Ltd"},
  {max_prefix: "68-14-01",vendor: "Hon Hai Precision Ind. Co.,Ltd."},
  {max_prefix: "50-A9-DE",vendor: "Smartcom - Bulgaria AD"},
  {max_prefix: "30-52-CB",vendor: "Liteon Technology Corporation"},
  {max_prefix: "04-96-45",vendor: "WUXI SKY CHIP INTERCONNECTION TECHNOLOGY CO.,LTD."},
  {max_prefix: "1C-CD-E5",vendor: "Shanghai Wind Technologies Co.,Ltd"},
  {max_prefix: "AC-1F-D7",vendor: "Real Vision Technology Co.,Ltd."},
  {max_prefix: "88-C2-42",vendor: "Poynt Co."},
  {max_prefix: "DC-82-F6",vendor: "iPort"},
  {max_prefix: "68-A8-28",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "CC-20-E8",vendor: "Apple, Inc."},
  {max_prefix: "6C-93-54",vendor: "Yaojin Technology (Shenzhen) Co., LTD."},
  {max_prefix: "DC-DC-07",vendor: "TRP Systems BV"},
  {max_prefix: "38-91-D5",vendor: "Hangzhou H3C Technologies Co., Limited"},
  {max_prefix: "BC-9C-31",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "70-48-0F",vendor: "Apple, Inc."},
  {max_prefix: "3C-B7-2B",vendor: "PLUMgrid Inc"},
  {max_prefix: "38-21-87",vendor: "Midea Group Co., Ltd."},
  {max_prefix: "78-D6-B2",vendor: "Toshiba"},
  {max_prefix: "8C-E2-DA",vendor: "Circle Media Inc"},
  {max_prefix: "48-9A-42",vendor: "Technomate Ltd"},
  {max_prefix: "20-D1-60",vendor: "Private"},
  {max_prefix: "E8-DE-D6",vendor: "Intrising Networks, Inc."},
  {max_prefix: "38-1C-23",vendor: "Hilan Technology CO.,LTD"},
  {max_prefix: "D8-8B-4C",vendor: "KingTing Tech."},
  {max_prefix: "48-BF-74",vendor: "Baicells Technologies Co.,LTD"},
  {max_prefix: "F0-B0-E7",vendor: "Apple, Inc."},
  {max_prefix: "64-9A-12",vendor: "P2 Mobile Technologies Limited"},
  {max_prefix: "E4-C2-D1",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "04-69-F8",vendor: "Apple, Inc."},
  {max_prefix: "AC-89-95",vendor: "AzureWave Technology Inc."},
  {max_prefix: "30-29-BE",vendor: "Shanghai MRDcom Co.,Ltd"},
  {max_prefix: "48-B6-20",vendor: "ROLI Ltd."},
  {max_prefix: "20-C3-A4",vendor: "RetailNext"},
  {max_prefix: "D4-F4-BE",vendor: "Palo Alto Networks"},
  {max_prefix: "D8-88-CE",vendor: "RF Technology Pty Ltd"},
  {max_prefix: "84-5B-12",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "20-55-31",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "30-7C-B2",vendor: "ANOV FRANCE"},
  {max_prefix: "84-79-73",vendor: "Shanghai Baud Data Communication Co.,Ltd."},
  {max_prefix: "E8-B4-C8",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "D0-87-E2",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "F0-5B-7B",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "B0-47-BF",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "7C-0B-C6",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "90-6F-A9",vendor: "NANJING PUTIAN TELECOMMUNICATIONS TECHNOLOGY CO.,LTD."},
  {max_prefix: "60-FD-56",vendor: "WOORISYSTEMS CO., Ltd"},
  {max_prefix: "7C-FE-90",vendor: "Mellanox Technologies, Inc."},
  {max_prefix: "0C-75-6C",vendor: "Anaren Microwave, Inc."},
  {max_prefix: "44-FD-A3",vendor: "Everysight LTD."},
  {max_prefix: "D4-7B-B0",vendor: "ASKEY COMPUTER CORP"},
  {max_prefix: "F0-22-4E",vendor: "Esan electronic co."},
  {max_prefix: "C8-F9-C8",vendor: "NewSharp Technology(SuZhou)Co,Ltd"},
  {max_prefix: "A8-A7-95",vendor: "Hon Hai Precision Ind. Co.,Ltd."},
  {max_prefix: "98-70-E8",vendor: "INNATECH SDN BHD"},
  {max_prefix: "44-65-6A",vendor: "Mega Video Electronic(HK) Industry Co., Ltd"},
  {max_prefix: "C4-12-F5",vendor: "D-Link International"},
  {max_prefix: "68-ED-A4",vendor: "Shenzhen Seavo Technology Co.,Ltd"},
  {max_prefix: "2C-C5-D3",vendor: "Ruckus Wireless"},
  {max_prefix: "80-C5-E6",vendor: "Microsoft Corporation"},
  {max_prefix: "10-DF-8B",vendor: "Shenzhen CareDear Communication Technology Co.,Ltd"},
  {max_prefix: "60-6D-C7",vendor: "Hon Hai Precision Ind. Co.,Ltd."},
  {max_prefix: "CC-5F-BF",vendor: "Topwise 3G Communication Co., Ltd."},
  {max_prefix: "E0-2C-B2",vendor: "Lenovo Mobile Communication (Wuhan) Company Limited"},
  {max_prefix: "78-EB-39",vendor: "Instituto Nacional de Tecnolog\u00eda Industrial"},
  {max_prefix: "54-40-AD",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "FC-E1-FB",vendor: "Array Networks"},
  {max_prefix: "E8-91-20",vendor: "Motorola Mobility LLC, a Lenovo Company"},
  {max_prefix: "D4-F9-A1",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "14-5A-83",vendor: "Logi-D inc"},
  {max_prefix: "FC-8F-90",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "54-CD-10",vendor: "Panasonic Mobile Communications Co.,Ltd."},
  {max_prefix: "A4-24-DD",vendor: "Cambrionix Ltd"},
  {max_prefix: "80-4E-81",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "48-06-6A",vendor: "Tempered Networks, Inc."},
  {max_prefix: "74-04-2B",vendor: "Lenovo Mobile Communication (Wuhan) Company Limited"},
  {max_prefix: "D0-5C-7A",vendor: "Sartura d.o.o."},
  {max_prefix: "64-16-7F",vendor: "Polycom"},
  {max_prefix: "C0-9A-71",vendor: "XIAMEN MEITU MOBILE TECHNOLOGY CO.LTD"},
  {max_prefix: "34-0B-40",vendor: "MIOS ELETTRONICA SRL"},
  {max_prefix: "14-41-46",vendor: "Honeywell (China) Co., LTD"},
  {max_prefix: "BC-5C-4C",vendor: "ELECOM CO.,LTD."},
  {max_prefix: "88-70-33",vendor: "Hangzhou Silan Microelectronic Inc"},
  {max_prefix: "DC-56-E6",vendor: "Shenzhen Bococom Technology Co.,LTD"},
  {max_prefix: "90-0A-39",vendor: "Wiio, Inc."},
  {max_prefix: "BC-F8-11",vendor: "Xiamen DNAKE Technology Co.,Ltd"},
  {max_prefix: "E8-61-BE",vendor: "Melec Inc."},
  {max_prefix: "58-70-C6",vendor: "Shanghai Xiaoyi Technology Co., Ltd."},
  {max_prefix: "40-28-14",vendor: "RFI Engineering"},
  {max_prefix: "F8-7A-EF",vendor: "Rosonix Technology, Inc."},
  {max_prefix: "20-28-BC",vendor: "Visionscape Co,. Ltd."},
  {max_prefix: "40-A5-EF",vendor: "Shenzhen Four Seas Global Link Network Technology Co., Ltd."},
  {max_prefix: "B8-56-BD",vendor: "ITT LLC"},
  {max_prefix: "C4-92-4C",vendor: "KEISOKUKI CENTER CO.,LTD."},
  {max_prefix: "74-9C-E3",vendor: "KodaCloud Canada, Inc"},
  {max_prefix: "CC-96-35",vendor: "LVS Co.,Ltd."},
  {max_prefix: "24-1C-04",vendor: "SHENZHEN JEHE TECHNOLOGY DEVELOPMENT CO., LTD."},
  {max_prefix: "44-80-EB",vendor: "Motorola Mobility LLC, a Lenovo Company"},
  {max_prefix: "50-50-2A",vendor: "Egardia"},
  {max_prefix: "94-F1-9E",vendor: "HUIZHOU MAORONG INTELLIGENT TECHNOLOGY CO.,LTD"},
  {max_prefix: "D4-55-56",vendor: "Fiber Mountain Inc."},
  {max_prefix: "DC-60-A1",vendor: "Teledyne DALSA Professional Imaging"},
  {max_prefix: "4C-A5-15",vendor: "Baikal Electronics JSC"},
  {max_prefix: "7C-3C-B6",vendor: "Shenzhen Homecare Technology Co.,Ltd."},
  {max_prefix: "1C-7D-22",vendor: "FUJIFILM Business Innovation Corp."},
  {max_prefix: "74-96-37",vendor: "Todaair Electronic Co., Ltd"},
  {max_prefix: "88-E6-03",vendor: "Avotek corporation"},
  {max_prefix: "84-C3-E8",vendor: "Vaillant GmbH"},
  {max_prefix: "2C-AD-13",vendor: "SHENZHEN ZHILU TECHNOLOGY CO.,LTD"},
  {max_prefix: "C4-8E-8F",vendor: "Hon Hai Precision Ind. Co.,Ltd."},
  {max_prefix: "54-36-9B",vendor: "1Verge Internet Technology (Beijing) Co., Ltd."},
  {max_prefix: "40-62-B6",vendor: "Tele system communication"},
  {max_prefix: "60-12-8B",vendor: "CANON INC."},
  {max_prefix: "78-AC-BF",vendor: "Igneous Systems"},
  {max_prefix: "40-D2-8A",vendor: "Nintendo Co., Ltd."},
  {max_prefix: "9C-D3-5B",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "A8-9F-BA",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "B8-8E-C6",vendor: "Stateless Networks"},
  {max_prefix: "BC-D1-65",vendor: "Cisco SPVTG"},
  {max_prefix: "28-E4-76",vendor: "Pi-Coral"},
  {max_prefix: "74-A3-4A",vendor: "ZIMI CORPORATION"},
  {max_prefix: "20-C0-6D",vendor: "SHENZHEN SPACETEK TECHNOLOGY CO.,LTD"},
  {max_prefix: "08-A5-C8",vendor: "Sunnovo International Limited"},
  {max_prefix: "D0-5B-A8",vendor: "zte corporation"},
  {max_prefix: "A8-64-05",vendor: "nimbus 9, Inc"},
  {max_prefix: "E4-BA-D9",vendor: "360 Fly Inc."},
  {max_prefix: "10-C6-7E",vendor: "SHENZHEN JUCHIN TECHNOLOGY CO., LTD"},
  {max_prefix: "68-28-F6",vendor: "Vubiq Networks, Inc."},
  {max_prefix: "10-A6-59",vendor: "Mobile Create Co.,Ltd."},
  {max_prefix: "58-85-6E",vendor: "QSC AG"},
  {max_prefix: "48-86-E8",vendor: "Microsoft Corporation"},
  {max_prefix: "A0-A3-E2",vendor: "Actiontec Electronics, Inc"},
  {max_prefix: "74-54-7D",vendor: "Cisco SPVTG"},
  {max_prefix: "8C-91-09",vendor: "Toyoshima Electric Technoeogy(Suzhou) Co.,Ltd."},
  {max_prefix: "54-09-8D",vendor: "deister electronic GmbH"},
  {max_prefix: "50-F4-3C",vendor: "Leeo Inc"},
  {max_prefix: "E8-87-A3",vendor: "Loxley Public Company Limited"},
  {max_prefix: "D8-CB-8A",vendor: "Micro-Star INTL CO., LTD."},
  {max_prefix: "F8-84-79",vendor: "Yaojin Technology(Shenzhen)Co.,Ltd"},
  {max_prefix: "2C-29-97",vendor: "Microsoft Corporation"},
  {max_prefix: "00-E6-E8",vendor: "Netzin Technology Corporation,.Ltd."},
  {max_prefix: "70-FC-8C",vendor: "OneAccess SA"},
  {max_prefix: "2C-37-96",vendor: "CYBO CO.,LTD."},
  {max_prefix: "58-7F-B7",vendor: "SONAR INDUSTRIAL CO., LTD."},
  {max_prefix: "EC-59-E7",vendor: "Microsoft Corporation"},
  {max_prefix: "C0-35-C5",vendor: "Prosoft Systems LTD"},
  {max_prefix: "90-8C-09",vendor: "Total Phase"},
  {max_prefix: "DC-2F-03",vendor: "Step forward Group Co., Ltd."},
  {max_prefix: "38-0E-7B",vendor: "V.P.S. Thai Co., Ltd"},
  {max_prefix: "08-EB-29",vendor: "Jiangsu Huitong Group Co.,Ltd."},
  {max_prefix: "40-9B-0D",vendor: "Shenzhen Yourf Kwan Industrial Co., Ltd"},
  {max_prefix: "4C-2C-83",vendor: "Zhejiang KaNong Network Technology Co.,Ltd."},
  {max_prefix: "D4-61-32",vendor: "Pro Concept Manufacturer Co.,Ltd."},
  {max_prefix: "54-A0-50",vendor: "ASUSTek COMPUTER INC."},
  {max_prefix: "84-96-81",vendor: "Cathay Communication Co.,Ltd"},
  {max_prefix: "2C-A3-0E",vendor: "POWER DRAGON DEVELOPMENT LIMITED"},
  {max_prefix: "84-86-F3",vendor: "Greenvity Communications"},
  {max_prefix: "60-50-C1",vendor: "Kinetek Sports"},
  {max_prefix: "2C-1A-31",vendor: "Electronics Company Limited"},
  {max_prefix: "20-12-D5",vendor: "Scientech Materials Corporation"},
  {max_prefix: "8C-5D-60",vendor: "UCI Corporation Co.,Ltd."},
  {max_prefix: "60-99-D1",vendor: "Vuzix / Lenovo"},
  {max_prefix: "B0-45-15",vendor: "mira fitness,LLC."},
  {max_prefix: "BC-6B-4D",vendor: "Nokia"},
  {max_prefix: "C8-D0-19",vendor: "Shanghai Tigercel Communication Technology Co.,Ltd"},
  {max_prefix: "90-21-81",vendor: "Shanghai Huaqin Telecom Technology Co.,Ltd"},
  {max_prefix: "58-10-8C",vendor: "Intelbras"},
  {max_prefix: "4C-74-03",vendor: "BQ"},
  {max_prefix: "1C-52-16",vendor: "DONGGUAN HELE ELECTRONICS CO., LTD"},
  {max_prefix: "14-48-8B",vendor: "Shenzhen Doov Technology Co.,Ltd"},
  {max_prefix: "80-F8-EB",vendor: "RayTight"},
  {max_prefix: "D4-37-D7",vendor: "zte corporation"},
  {max_prefix: "2C-01-0B",vendor: "NASCENT Technology, LLC - RemKon"},
  {max_prefix: "78-45-61",vendor: "CyberTAN Technology Inc."},
  {max_prefix: "94-CE-31",vendor: "CTS Limited"},
  {max_prefix: "6C-6E-FE",vendor: "Core Logic Inc."},
  {max_prefix: "38-08-FD",vendor: "Silca Spa"},
  {max_prefix: "20-ED-74",vendor: "Ability enterprise co.,Ltd."},
  {max_prefix: "6C-02-73",vendor: "Shenzhen Jin Yun Video Equipment Co., Ltd."},
  {max_prefix: "A0-56-B2",vendor: "Harman/Becker Automotive Systems GmbH"},
  {max_prefix: "44-66-6E",vendor: "IP-LINE"},
  {max_prefix: "5C-B6-CC",vendor: "NovaComm Technologies Inc."},
  {max_prefix: "5C-15-15",vendor: "ADVAN"},
  {max_prefix: "24-4F-1D",vendor: "iRule LLC"},
  {max_prefix: "94-AE-E3",vendor: "Belden Hirschmann Industries (Suzhou) Ltd."},
  {max_prefix: "04-DE-DB",vendor: "Rockport Networks Inc"},
  {max_prefix: "1C-48-40",vendor: "IMS Messsysteme GmbH"},
  {max_prefix: "54-DF-00",vendor: "Ulterius Technologies, LLC"},
  {max_prefix: "34-C5-D0",vendor: "Hagleitner Hygiene International GmbH"},
  {max_prefix: "10-C3-7B",vendor: "ASUSTek COMPUTER INC."},
  {max_prefix: "B4-0B-44",vendor: "Smartisan Technology Co., Ltd."},
  {max_prefix: "90-B6-86",vendor: "Murata Manufacturing Co., Ltd."},
  {max_prefix: "EC-D9-D1",vendor: "Shenzhen TG-NET Botone Technology Co.,Ltd."},
  {max_prefix: "28-29-CC",vendor: "Corsa Technology Incorporated"},
  {max_prefix: "34-31-C4",vendor: "AVM GmbH"},
  {max_prefix: "18-4A-6F",vendor: "Alcatel-Lucent Shanghai Bell Co., Ltd"},
  {max_prefix: "E8-5D-6B",vendor: "Luminate Wireless"},
  {max_prefix: "50-FE-F2",vendor: "Sify Technologies Ltd"},
  {max_prefix: "70-5B-2E",vendor: "M2Communication Inc."},
  {max_prefix: "B4-0A-C6",vendor: "DEXON Systems Ltd."},
  {max_prefix: "D0-59-E4",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "14-A3-64",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "48-1A-84",vendor: "Pointer Telocation Ltd"},
  {max_prefix: "DC-66-3A",vendor: "Apacer Technology Inc."},
  {max_prefix: "08-DF-1F",vendor: "Bose Corporation"},
  {max_prefix: "48-26-E8",vendor: "Tek-Air Systems, Inc."},
  {max_prefix: "58-1F-67",vendor: "Open-m technology limited"},
  {max_prefix: "A0-12-DB",vendor: "TABUCHI ELECTRIC CO.,LTD"},
  {max_prefix: "48-82-44",vendor: "Life Fitness / Div. of Brunswick"},
  {max_prefix: "C8-31-68",vendor: "eZEX corporation"},
  {max_prefix: "D0-5A-F1",vendor: "Shenzhen Pulier Tech CO.,Ltd"},
  {max_prefix: "40-B3-CD",vendor: "Chiyoda Electronics Co.,Ltd."},
  {max_prefix: "30-91-8F",vendor: "Technicolor Delivery Technologies Belgium NV"},
  {max_prefix: "5C-F4-AB",vendor: "Zyxel Communications Corporation"},
  {max_prefix: "08-B2-A3",vendor: "Cynny Italia S.r.L."},
  {max_prefix: "FC-E1-86",vendor: "A3M Co., LTD"},
  {max_prefix: "54-EF-92",vendor: "Shenzhen Elink Technology Co., LTD"},
  {max_prefix: "0C-20-26",vendor: "noax Technologies AG"},
  {max_prefix: "20-25-64",vendor: "PEGATRON CORPORATION"},
  {max_prefix: "C8-D5-90",vendor: "FLIGHT DATA SYSTEMS"},
  {max_prefix: "A0-DA-92",vendor: "Nanjing Glarun Atten Technology Co. Ltd."},
  {max_prefix: "54-C8-0F",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "E4-D3-32",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "70-30-5D",vendor: "Ubiquoss Inc"},
  {max_prefix: "D4-CF-F9",vendor: "Shenzhen SEI Robotics Co.,Ltd"},
  {max_prefix: "24-33-6C",vendor: "Private"},
  {max_prefix: "EC-E5-12",vendor: "tado GmbH"},
  {max_prefix: "7C-FF-62",vendor: "Huizhou Super Electron Technology Co.,Ltd."},
  {max_prefix: "28-3B-96",vendor: "Cool Control LTD"},
  {max_prefix: "64-8D-9E",vendor: "IVT Electronic Co.,Ltd"},
  {max_prefix: "94-9F-3F",vendor: "Optek Digital Technology company limited"},
  {max_prefix: "78-FE-E2",vendor: "Shanghai Diveo Technology Co., Ltd"},
  {max_prefix: "CC-95-D7",vendor: "Vizio, Inc"},
  {max_prefix: "FC-09-F6",vendor: "GUANGDONG TONZE ELECTRIC CO.,LTD"},
  {max_prefix: "BC-F6-1C",vendor: "Geomodeling Wuxi Technology Co. Ltd."},
  {max_prefix: "08-6D-F2",vendor: "Shenzhen MIMOWAVE Technology Co.,Ltd"},
  {max_prefix: "68-78-48",vendor: "WESTUNITIS CO., LTD."},
  {max_prefix: "48-D0-CF",vendor: "Universal Electronics, Inc."},
  {max_prefix: "DC-C7-93",vendor: "Nokia Corporation"},
  {max_prefix: "44-48-91",vendor: "HDMI Licensing, LLC"},
  {max_prefix: "FC-92-3B",vendor: "Nokia Corporation"},
  {max_prefix: "E0-3F-49",vendor: "ASUSTek COMPUTER INC."},
  {max_prefix: "28-65-6B",vendor: "Keystone Microtech Corporation"},
  {max_prefix: "CC-9F-35",vendor: "Transbit Sp. z o.o."},
  {max_prefix: "F0-3F-F8",vendor: "R L Drake"},
  {max_prefix: "5C-25-4C",vendor: "Avire Global Pte Ltd"},
  {max_prefix: "D4-2F-23",vendor: "Akenori PTE Ltd"},
  {max_prefix: "98-C0-EB",vendor: "Global Regency Ltd"},
  {max_prefix: "D0-9C-30",vendor: "Foster Electric Company, Limited"},
  {max_prefix: "EC-F7-2B",vendor: "HD DIGITAL TECH CO., LTD."},
  {max_prefix: "4C-F4-5B",vendor: "Blue Clover Devices"},
  {max_prefix: "B0-69-71",vendor: "DEI Sales, Inc."},
  {max_prefix: "C0-9D-26",vendor: "Topicon HK Lmd."},
  {max_prefix: "44-29-38",vendor: "NietZsche enterprise Co.Ltd."},
  {max_prefix: "9C-3E-AA",vendor: "EnvyLogic Co.,Ltd."},
  {max_prefix: "F8-F0-05",vendor: "Newport Media Inc."},
  {max_prefix: "1C-FC-BB",vendor: "Realfiction ApS"},
  {max_prefix: "B0-C5-54",vendor: "D-Link International"},
  {max_prefix: "C4-E9-84",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "48-EE-86",vendor: "UTStarcom (China) Co.,Ltd"},
  {max_prefix: "88-89-14",vendor: "All Components Incorporated"},
  {max_prefix: "F0-32-1A",vendor: "Mita-Teknik A/S"},
  {max_prefix: "24-05-0F",vendor: "MTN Electronic Co. Ltd"},
  {max_prefix: "AC-2D-A3",vendor: "TXTR GmbH"},
  {max_prefix: "14-C0-89",vendor: "DUNE HD LTD"},
  {max_prefix: "F4-B6-E5",vendor: "TerraSem Co.,Ltd"},
  {max_prefix: "A8-81-F1",vendor: "BMEYE B.V."},
  {max_prefix: "D0-69-D0",vendor: "Verto Medical Solutions, LLC"},
  {max_prefix: "88-9C-A6",vendor: "BTB Korea INC"},
  {max_prefix: "90-83-7A",vendor: "General Electric Water & Process Technologies"},
  {max_prefix: "A4-3A-69",vendor: "Vers Inc"},
  {max_prefix: "B0-D7-C5",vendor: "Logipix Ltd"},
  {max_prefix: "9C-44-A6",vendor: "SwiftTest, Inc."},
  {max_prefix: "FC-07-A0",vendor: "LRE Medical GmbH"},
  {max_prefix: "1C-EE-E8",vendor: "Ilshin Elecom"},
  {max_prefix: "FC-13-49",vendor: "Global Apps Corp."},
  {max_prefix: "84-84-33",vendor: "Paradox Engineering SA"},
  {max_prefix: "44-C3-06",vendor: "SIFROM Inc."},
  {max_prefix: "60-21-03",vendor: "I4VINE, INC"},
  {max_prefix: "60-FF-DD",vendor: "C.E. ELECTRONICS, INC"},
  {max_prefix: "88-A7-3C",vendor: "Ragentek Technology Group"},
  {max_prefix: "88-D9-62",vendor: "Canopus Systems US LLC"},
  {max_prefix: "90-31-CD",vendor: "Onyx Healthcare Inc."},
  {max_prefix: "E4-81-84",vendor: "Nokia"},
  {max_prefix: "E0-56-F4",vendor: "AxesNetwork Solutions inc."},
  {max_prefix: "84-56-9C",vendor: "Coho Data, Inc.,"},
  {max_prefix: "78-AE-0C",vendor: "Far South Networks"},
  {max_prefix: "84-A7-83",vendor: "Alcatel Lucent"},
  {max_prefix: "80-BA-E6",vendor: "Neets"},
  {max_prefix: "F8-A2-B4",vendor: "RHEWA-WAAGENFABRIK August Freudewald GmbH &amp;Co. KG"},
  {max_prefix: "B0-24-F3",vendor: "Progeny Systems"},
  {max_prefix: "0C-54-A5",vendor: "PEGATRON CORPORATION"},
  {max_prefix: "8C-4D-B9",vendor: "Unmonday Ltd"},
  {max_prefix: "4C-D9-C4",vendor: "Magneti Marelli Automotive Electronics (Guangzhou) Co. Ltd"},
  {max_prefix: "2C-5A-05",vendor: "Nokia Corporation"},
  {max_prefix: "D4-81-CA",vendor: "iDevices, LLC"},
  {max_prefix: "D8-58-D7",vendor: "CZ.NIC, z.s.p.o."},
  {max_prefix: "7C-6A-B3",vendor: "IBC TECHNOLOGIES INC."},
  {max_prefix: "18-1B-EB",vendor: "Actiontec Electronics, Inc"},
  {max_prefix: "78-CA-5E",vendor: "ELNO"},
  {max_prefix: "B4-82-7B",vendor: "AKG Acoustics GmbH"},
  {max_prefix: "DC-5E-36",vendor: "Paterson Technology"},
  {max_prefix: "50-E0-C7",vendor: "TurControlSystme AG"},
  {max_prefix: "DC-AD-9E",vendor: "GreenPriz"},
  {max_prefix: "B8-DF-6B",vendor: "SpotCam Co., Ltd."},
  {max_prefix: "9C-88-88",vendor: "Simac Techniek NV"},
  {max_prefix: "04-1A-04",vendor: "WaveIP"},
  {max_prefix: "18-C8-E7",vendor: "Shenzhen Hualistone Technology Co.,Ltd"},
  {max_prefix: "A0-3B-1B",vendor: "Inspire Tech"},
  {max_prefix: "7C-CD-3C",vendor: "Guangzhou Juzing Technology Co., Ltd"},
  {max_prefix: "44-8A-5B",vendor: "Micro-Star INT'L CO., LTD."},
  {max_prefix: "0C-CB-8D",vendor: "ASCO Numatics GmbH"},
  {max_prefix: "6C-4B-7F",vendor: "Vossloh-Schwabe Deutschland GmbH"},
  {max_prefix: "68-8A-B5",vendor: "EDP Servicos"},
  {max_prefix: "24-93-CA",vendor: "Voxtronic Austria"},
  {max_prefix: "10-B2-6B",vendor: "base Co.,Ltd."},
  {max_prefix: "4C-F0-2E",vendor: "Vifa Denmark A/S"},
  {max_prefix: "DC-CE-BC",vendor: "Shenzhen JSR Technology Co.,Ltd."},
  {max_prefix: "68-76-4F",vendor: "Sony Corporation"},
  {max_prefix: "38-DB-BB",vendor: "Sunbow Telecom Co., Ltd."},
  {max_prefix: "98-43-DA",vendor: "INTERTECH"},
  {max_prefix: "08-CA-45",vendor: "Toyou Feiji Electronics Co., Ltd."},
  {max_prefix: "3C-CA-87",vendor: "Iders Incorporated"},
  {max_prefix: "90-8C-44",vendor: "H.K ZONGMU TECHNOLOGY CO., LTD."},
  {max_prefix: "34-A5-E1",vendor: "Sensorist ApS"},
  {max_prefix: "6C-83-66",vendor: "Nanjing SAC Power Grid Automation Co., Ltd."},
  {max_prefix: "78-D9-9F",vendor: "NuCom HK Ltd."},
  {max_prefix: "14-2B-D2",vendor: "Armtel Ltd."},
  {max_prefix: "9C-A1-0A",vendor: "SCLE SFE"},
  {max_prefix: "88-78-9C",vendor: "Game Technologies SA"},
  {max_prefix: "80-79-AE",vendor: "ShanDong Tecsunrise  Co.,Ltd"},
  {max_prefix: "7C-BD-06",vendor: "AE REFUsol"},
  {max_prefix: "94-BA-56",vendor: "Shenzhen Coship Electronics Co., Ltd."},
  {max_prefix: "74-0E-DB",vendor: "Optowiz Co., Ltd"},
  {max_prefix: "A4-89-5B",vendor: "ARK INFOSOLUTIONS PVT LTD"},
  {max_prefix: "D0-9D-0A",vendor: "LINKCOM"},
  {max_prefix: "EC-21-9F",vendor: "VidaBox LLC"},
  {max_prefix: "A8-CC-C5",vendor: "Saab AB (publ)"},
  {max_prefix: "98-8E-4A",vendor: "NOXUS(BEIJING) TECHNOLOGY CO.,LTD"},
  {max_prefix: "50-B8-88",vendor: "wi2be Tecnologia S/A"},
  {max_prefix: "54-BE-F7",vendor: "PEGATRON CORPORATION"},
  {max_prefix: "90-79-90",vendor: "Benchmark Electronics Romania SRL"},
  {max_prefix: "EC-2A-F0",vendor: "Ypsomed AG"},
  {max_prefix: "F8-54-AF",vendor: "ECI Telecom Ltd."},
  {max_prefix: "F0-F5-AE",vendor: "Adaptrum Inc."},
  {max_prefix: "F8-3D-4E",vendor: "Softlink Automation System Co., Ltd"},
  {max_prefix: "7C-E5-6B",vendor: "ESEN Optoelectronics Technology Co.,Ltd."},
  {max_prefix: "D4-4C-9C",vendor: "Shenzhen YOOBAO Technology Co.Ltd"},
  {max_prefix: "20-CE-C4",vendor: "Peraso Technologies"},
  {max_prefix: "CC-47-03",vendor: "Intercon Systems Co., Ltd."},
  {max_prefix: "DC-17-92",vendor: "Captivate Network"},
  {max_prefix: "44-2A-FF",vendor: "E3 Technology, Inc."},
  {max_prefix: "7C-83-06",vendor: "Glen Dimplex Nordic as"},
  {max_prefix: "14-0D-4F",vendor: "Flextronics International"},
  {max_prefix: "FC-3F-AB",vendor: "Henan Lanxin Technology Co., Ltd"},
  {max_prefix: "58-46-8F",vendor: "Koncar Electronics and Informatics"},
  {max_prefix: "98-5D-46",vendor: "PeopleNet Communication"},
  {max_prefix: "F8-9F-B8",vendor: "YAZAKI Energy System Corporation"},
  {max_prefix: "54-05-36",vendor: "Vivago Oy"},
  {max_prefix: "6C-90-B1",vendor: "SanLogic Inc"},
  {max_prefix: "E0-FA-EC",vendor: "Platan sp. z o.o. sp. k."},
  {max_prefix: "44-67-55",vendor: "Orbit Irrigation"},
  {max_prefix: "54-22-F8",vendor: "zte corporation"},
  {max_prefix: "48-6E-73",vendor: "Pica8, Inc."},
  {max_prefix: "CC-7B-35",vendor: "zte corporation"},
  {max_prefix: "04-D4-37",vendor: "ZNV"},
  {max_prefix: "CC-F4-07",vendor: "EUKREA ELECTROMATIQUE SARL"},
  {max_prefix: "08-8E-4F",vendor: "SF Software Solutions"},
  {max_prefix: "AC-CA-8E",vendor: "ODA Technologies"},
  {max_prefix: "64-05-BE",vendor: "NEW LIGHT LED"},
  {max_prefix: "E8-BB-3D",vendor: "Sino Prime-Tech Limited"},
  {max_prefix: "28-A2-41",vendor: "exlar corp"},
  {max_prefix: "30-78-6B",vendor: "TIANJIN Golden Pentagon Electronics Co., Ltd."},
  {max_prefix: "20-DF-3F",vendor: "Nanjing SAC Power Grid Automation Co., Ltd."},
  {max_prefix: "4C-D6-37",vendor: "Qsono Electronics Co., Ltd"},
  {max_prefix: "94-36-E0",vendor: "Sichuan Bihong Broadcast &amp; Television New Technologies Co.,Ltd"},
  {max_prefix: "34-A6-8C",vendor: "Shine Profit Development Limited"},
  {max_prefix: "94-9F-B4",vendor: "ChengDu JiaFaAnTai Technology Co.,Ltd"},
  {max_prefix: "50-98-71",vendor: "Inventum Technologies Private Limited"},
  {max_prefix: "04-8C-03",vendor: "ThinPAD Technology (Shenzhen)CO.,LTD"},
  {max_prefix: "88-46-2A",vendor: "Telechips Inc."},
  {max_prefix: "C8-02-58",vendor: "ITW GSE ApS"},
  {max_prefix: "F4-20-12",vendor: "Cuciniale GmbH"},
  {max_prefix: "98-B0-39",vendor: "Nokia"},
  {max_prefix: "B8-30-A8",vendor: "Road-Track Telematics Development"},
  {max_prefix: "40-68-26",vendor: "Thales UK Limited"},
  {max_prefix: "F8-2B-C8",vendor: "Jiangsu Switter Co., Ltd"},
  {max_prefix: "60-C3-97",vendor: "2Wire Inc"},
  {max_prefix: "FC-60-18",vendor: "Zhejiang Kangtai Electric Co., Ltd."},
  {max_prefix: "0C-F0-19",vendor: "Malgn Technology Co., Ltd."},
  {max_prefix: "28-28-5D",vendor: "Zyxel Communications Corporation"},
  {max_prefix: "64-6E-EA",vendor: "Iskratel d.o.o."},
  {max_prefix: "D0-73-7F",vendor: "Mini-Circuits"},
  {max_prefix: "58-E0-2C",vendor: "Micro Technic A/S"},
  {max_prefix: "78-B3-CE",vendor: "Elo touch solutions"},
  {max_prefix: "F8-51-6D",vendor: "Denwa Technology Corp."},
  {max_prefix: "44-4A-65",vendor: "Silverflare Ltd."},
  {max_prefix: "74-4B-E9",vendor: "EXPLORER HYPERTECH CO.,LTD"},
  {max_prefix: "E8-CE-06",vendor: "SkyHawke Technologies, LLC."},
  {max_prefix: "C8-F3-86",vendor: "Shenzhen Xiaoniao Technology Co.,Ltd"},
  {max_prefix: "2C-72-C3",vendor: "Soundmatters"},
  {max_prefix: "2C-69-BA",vendor: "RF Controls, LLC"},
  {max_prefix: "D4-BF-7F",vendor: "UPVEL"},
  {max_prefix: "C8-DD-C9",vendor: "Lenovo Mobile Communication Technology Ltd."},
  {max_prefix: "6C-86-86",vendor: "Technonia"},
  {max_prefix: "D4-AC-4E",vendor: "BODi rS, LLC"},
  {max_prefix: "A4-FC-CE",vendor: "Security Expert Ltd."},
  {max_prefix: "E0-7F-88",vendor: "EVIDENCE Network SIA"},
  {max_prefix: "1C-7C-C7",vendor: "Coriant GmbH"},
  {max_prefix: "34-1B-22",vendor: "Grandbeing Technology Co., Ltd"},
  {max_prefix: "84-2F-75",vendor: "Innokas Group"},
  {max_prefix: "CC-3C-3F",vendor: "SA.S.S. Datentechnik AG"},
  {max_prefix: "34-1A-4C",vendor: "SHENZHEN WEIBU ELECTRONICS CO.,LTD."},
  {max_prefix: "88-14-2B",vendor: "Protonic Holland"},
  {max_prefix: "70-C6-AC",vendor: "Bosch Automotive Aftermarket"},
  {max_prefix: "04-88-E2",vendor: "Beats Electronics LLC"},
  {max_prefix: "40-56-0C",vendor: "In Home Displays Ltd"},
  {max_prefix: "A0-86-1D",vendor: "Chengdu Fuhuaxin Technology co.,Ltd"},
  {max_prefix: "50-8D-6F",vendor: "CHAHOO Limited"},
  {max_prefix: "30-89-99",vendor: "Guangdong East Power Co.,"},
  {max_prefix: "A4-7A-CF",vendor: "VIBICOM COMMUNICATIONS INC."},
  {max_prefix: "BC-26-1D",vendor: "HONG KONG TECON TECHNOLOGY"},
  {max_prefix: "20-4C-6D",vendor: "Hugo Brennenstuhl Gmbh & Co. KG."},
  {max_prefix: "9C-97-26",vendor: "Technicolor Delivery Technologies Belgium NV"},
  {max_prefix: "7C-05-07",vendor: "PEGATRON CORPORATION"},
  {max_prefix: "88-09-05",vendor: "MTMCommunications"},
  {max_prefix: "F4-B3-81",vendor: "WindowMaster A/S"},
  {max_prefix: "74-F1-02",vendor: "Beijing HCHCOM Technology Co., Ltd"},
  {max_prefix: "F0-F2-60",vendor: "Mobitec AB"},
  {max_prefix: "28-2C-B2",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "64-E5-99",vendor: "EFM Networks"},
  {max_prefix: "40-C4-D6",vendor: "ChongQing Camyu Technology Development Co.,Ltd."},
  {max_prefix: "A8-29-4C",vendor: "Precision Optical Transceivers, Inc."},
  {max_prefix: "30-D4-6A",vendor: "Autosales Incorporated"},
  {max_prefix: "E8-DE-27",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "C8-93-46",vendor: "MXCHIP Company Limited"},
  {max_prefix: "94-AC-CA",vendor: "trivum technologies GmbH"},
  {max_prefix: "90-82-60",vendor: "IEEE 1904.1 Working Group"},
  {max_prefix: "D4-EE-07",vendor: "HIWIFI Co., Ltd."},
  {max_prefix: "FC-AD-0F",vendor: "QTS NETWORKS"},
  {max_prefix: "98-4C-04",vendor: "Zhangzhou Keneng Electrical Equipment Co Ltd"},
  {max_prefix: "AC-DB-DA",vendor: "Shenzhen Geniatech Inc, Ltd"},
  {max_prefix: "A4-E9-91",vendor: "SISTEMAS AUDIOVISUALES ITELSIS S.L."},
  {max_prefix: "3C-86-A8",vendor: "Sangshin elecom .co,, LTD"},
  {max_prefix: "84-F4-93",vendor: "OMS spol. s.r.o."},
  {max_prefix: "BC-D1-77",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "7C-D7-62",vendor: "Freestyle Technology Pty Ltd"},
  {max_prefix: "90-1D-27",vendor: "zte corporation"},
  {max_prefix: "F0-5D-C8",vendor: "Duracell Powermat"},
  {max_prefix: "CC-5D-57",vendor: "Information  System Research Institute,Inc."},
  {max_prefix: "08-1D-FB",vendor: "Shanghai Mexon Communication Technology Co.,Ltd"},
  {max_prefix: "C4-4E-AC",vendor: "Shenzhen Shiningworth Technology Co., Ltd."},
  {max_prefix: "04-4C-EF",vendor: "Fujian Sanao Technology Co.,Ltd"},
  {max_prefix: "4C-80-4F",vendor: "Armstrong Monitoring Corp"},
  {max_prefix: "64-C6-67",vendor: "Barnes&Noble"},
  {max_prefix: "10-3D-EA",vendor: "HFC Technology (Beijing) Ltd. Co."},
  {max_prefix: "D4-27-51",vendor: "Infopia Co., Ltd"},
  {max_prefix: "68-A4-0E",vendor: "BSH Hausger\u00e4te GmbH"},
  {max_prefix: "F4-C6-D7",vendor: "blackned GmbH"},
  {max_prefix: "4C-CA-53",vendor: "Skyera, Inc."},
  {max_prefix: "90-FF-79",vendor: "Metro Ethernet Forum"},
  {max_prefix: "D4-CA-6E",vendor: "u-blox AG"},
  {max_prefix: "B0-14-08",vendor: "LIGHTSPEED INTERNATIONAL CO."},
  {max_prefix: "9C-31-78",vendor: "Foshan Huadian Intelligent Communications Teachnologies Co.,Ltd"},
  {max_prefix: "98-3F-9F",vendor: "China SSJ (Suzhou) Network Technology Inc."},
  {max_prefix: "B8-38-CA",vendor: "Kyokko Tsushin System CO.,LTD"},
  {max_prefix: "B0-C9-5B",vendor: "Beijing Symtech CO.,LTD"},
  {max_prefix: "38-B5-BD",vendor: "E.G.O. Elektro-Ger"},
  {max_prefix: "5C-43-D2",vendor: "HAZEMEYER"},
  {max_prefix: "D8-09-C3",vendor: "Cercacor Labs"},
  {max_prefix: "2C-E8-71",vendor: "Alert Metalguard ApS"},
  {max_prefix: "F8-7B-62",vendor: "FASTWEL INTERNATIONAL CO., LTD. Taiwan Branch"},
  {max_prefix: "40-27-0B",vendor: "Mobileeco Co., Ltd"},
  {max_prefix: "E4-EE-FD",vendor: "MR&D Manufacturing"},
  {max_prefix: "10-5C-BF",vendor: "DuroByte Inc"},
  {max_prefix: "20-91-8A",vendor: "PROFALUX"},
  {max_prefix: "A8-01-80",vendor: "IMAGO Technologies GmbH"},
  {max_prefix: "0C-55-21",vendor: "Axiros GmbH"},
  {max_prefix: "78-98-FD",vendor: "Q9 Networks Inc."},
  {max_prefix: "D0-63-B4",vendor: "SolidRun Ltd."},
  {max_prefix: "2C-44-1B",vendor: "Spectrum Medical Limited"},
  {max_prefix: "E4-E4-09",vendor: "LEIFHEIT AG"},
  {max_prefix: "68-B8-D9",vendor: "Act KDE, Inc."},
  {max_prefix: "90-CC-24",vendor: "Synaptics, Inc"},
  {max_prefix: "C8-8A-83",vendor: "Dongguan HuaHong Electronics Co.,Ltd"},
  {max_prefix: "8C-C5-E1",vendor: "ShenZhen Konka Telecommunication Technology Co.,Ltd"},
  {max_prefix: "64-A3-41",vendor: "Wonderlan (Beijing) Technology Co., Ltd."},
  {max_prefix: "74-FE-48",vendor: "ADVANTECH CO., LTD."},
  {max_prefix: "80-B9-5C",vendor: "ELFTECH Co., Ltd."},
  {max_prefix: "A0-19-17",vendor: "Bertel S.p.a."},
  {max_prefix: "24-F2-DD",vendor: "Radiant Zemax LLC"},
  {max_prefix: "A4-6E-79",vendor: "DFT System Co.Ltd"},
  {max_prefix: "B8-77-C3",vendor: "METER Group"},
  {max_prefix: "00-4D-32",vendor: "Andon Health Co.,Ltd."},
  {max_prefix: "FC-A9-B0",vendor: "MIARTECH (SHANGHAI),INC."},
  {max_prefix: "9C-8D-1A",vendor: "INTEG process group inc"},
  {max_prefix: "48-03-62",vendor: "DESAY ELECTRONICS(HUIZHOU)CO.,LTD"},
  {max_prefix: "18-67-3F",vendor: "Hanover Displays Limited"},
  {max_prefix: "7C-0A-50",vendor: "J-MEX Inc."},
  {max_prefix: "40-F2-E9",vendor: "IBM"},
  {max_prefix: "74-4D-79",vendor: "Arrive Systems Inc."},
  {max_prefix: "50-11-EB",vendor: "SilverNet Ltd"},
  {max_prefix: "54-DF-63",vendor: "Intrakey technologies GmbH"},
  {max_prefix: "D4-93-A0",vendor: "Fidelix Oy"},
  {max_prefix: "08-EB-ED",vendor: "World Elite Technology Co.,LTD"},
  {max_prefix: "EC-49-93",vendor: "Qihan Technology Co., Ltd"},
  {max_prefix: "B0-AC-FA",vendor: "FUJITSU LIMITED"},
  {max_prefix: "8C-E0-81",vendor: "zte corporation"},
  {max_prefix: "3C-83-B5",vendor: "Advance Vision Electronics Co. Ltd."},
  {max_prefix: "28-A1-92",vendor: "GERP Solution"},
  {max_prefix: "9C-54-1C",vendor: "Shenzhen My-power Technology Co.,Ltd"},
  {max_prefix: "8C-33-30",vendor: "EmFirst Co., Ltd."},
  {max_prefix: "08-7B-AA",vendor: "SVYAZKOMPLEKTSERVICE, LLC"},
  {max_prefix: "C4-45-67",vendor: "SAMBON PRECISON and ELECTRONICS"},
  {max_prefix: "E4-56-14",vendor: "Suttle Apparatus"},
  {max_prefix: "A4-4E-2D",vendor: "Adaptive Wireless Solutions, LLC"},
  {max_prefix: "0C-CD-FB",vendor: "EDIC Systems Inc."},
  {max_prefix: "9C-04-73",vendor: "Tecmobile (International) Ltd."},
  {max_prefix: "B0-12-03",vendor: "Dynamics Hong Kong Limited"},
  {max_prefix: "98-86-B1",vendor: "Flyaudio corporation (China)"},
  {max_prefix: "70-93-F8",vendor: "Space Monkey, Inc."},
  {max_prefix: "28-B3-AB",vendor: "Genmark Automation"},
  {max_prefix: "C4-E7-BE",vendor: "SCSpro Co.,Ltd"},
  {max_prefix: "58-87-4C",vendor: "LITE-ON CLEAN ENERGY TECHNOLOGY CORP."},
  {max_prefix: "28-91-D0",vendor: "Stage Tec Entwicklungsgesellschaft f\u00fcr professionelle Audiotechnik mbH"},
  {max_prefix: "C0-BD-42",vendor: "ZPA Smart Energy a.s."},
  {max_prefix: "20-74-CF",vendor: "Shenzhen Voxtech Co.,Ltd"},
  {max_prefix: "FC-52-CE",vendor: "Control iD"},
  {max_prefix: "5C-4A-26",vendor: "Enguity Technology Corp"},
  {max_prefix: "D8-D2-7C",vendor: "JEMA ENERGY, SA"},
  {max_prefix: "E0-F5-CA",vendor: "CHENG UEI PRECISION INDUSTRY CO.,LTD."},
  {max_prefix: "A8-EF-26",vendor: "Tritonwave"},
  {max_prefix: "60-F2-EF",vendor: "VisionVera International Co., Ltd."},
  {max_prefix: "C0-3F-2A",vendor: "Biscotti, Inc."},
  {max_prefix: "38-1C-4A",vendor: "SIMCom Wireless Solutions Co.,Ltd."},
  {max_prefix: "48-F8-B3",vendor: "Cisco-Linksys, LLC"},
  {max_prefix: "B4-DF-FA",vendor: "Litemax Electronics Inc."},
  {max_prefix: "68-1C-A2",vendor: "Rosewill Inc."},
  {max_prefix: "60-46-16",vendor: "XIAMEN VANN INTELLIGENT CO., LTD"},
  {max_prefix: "74-99-75",vendor: "IBM Corporation"},
  {max_prefix: "D8-C6-91",vendor: "Hichan Technology Corp."},
  {max_prefix: "E4-3F-A2",vendor: "Wuxi DSP Technologies Inc."},
  {max_prefix: "F4-B7-2A",vendor: "TIME INTERCONNECT LTD"},
  {max_prefix: "DC-9F-A4",vendor: "Nokia Corporation"},
  {max_prefix: "44-C3-9B",vendor: "OOO RUBEZH NPO"},
  {max_prefix: "6C-40-C6",vendor: "Nimbus Data, Inc."},
  {max_prefix: "10-48-B1",vendor: "Beijing Duokan Technology Limited"},
  {max_prefix: "10-6F-EF",vendor: "Ad-Sol Nissin Corp"},
  {max_prefix: "2C-62-5A",vendor: "Finest Security Systems Co., Ltd"},
  {max_prefix: "20-DC-93",vendor: "Cheetah Hi-Tech, Inc."},
  {max_prefix: "44-23-AA",vendor: "Farmage Co., Ltd."},
  {max_prefix: "7C-FE-28",vendor: "Salutron Inc."},
  {max_prefix: "E8-10-2E",vendor: "Really Simple Software, Inc"},
  {max_prefix: "0C-56-5C",vendor: "HyBroad Vision (Hong Kong) Technology Co Ltd"},
  {max_prefix: "8C-6A-E4",vendor: "Viogem Limited"},
  {max_prefix: "64-51-7E",vendor: "LONG BEN (DONGGUAN) ELECTRONIC TECHNOLOGY CO.,LTD."},
  {max_prefix: "D4-3D-7E",vendor: "Micro-Star Int'l Co, Ltd"},
  {max_prefix: "AC-D9-D6",vendor: "tci GmbH"},
  {max_prefix: "48-28-2F",vendor: "zte corporation"},
  {max_prefix: "88-DC-96",vendor: "EnGenius Technologies, Inc."},
  {max_prefix: "20-44-3A",vendor: "Schneider Electric Asia Pacific Ltd"},
  {max_prefix: "EC-A2-9B",vendor: "Kemppi Oy"},
  {max_prefix: "C4-BA-99",vendor: "I+ME Actia Informatik und Mikro-Elektronik GmbH"},
  {max_prefix: "04-CE-14",vendor: "Wilocity LTD."},
  {max_prefix: "54-39-68",vendor: "Edgewater Networks Inc"},
  {max_prefix: "44-0C-FD",vendor: "NetMan Co., Ltd."},
  {max_prefix: "8C-D3-A2",vendor: "VisSim AS"},
  {max_prefix: "14-35-8B",vendor: "Mediabridge Products, LLC."},
  {max_prefix: "00-F4-03",vendor: "Orbis Systems Oy"},
  {max_prefix: "54-73-98",vendor: "Toyo Electronics Corporation"},
  {max_prefix: "C4-39-3A",vendor: "SMC Networks Inc"},
  {max_prefix: "E8-D4-83",vendor: "ULTIMATE Europe Transportation Equipment GmbH"},
  {max_prefix: "1C-84-64",vendor: "FORMOSA WIRELESS COMMUNICATION CORP."},
  {max_prefix: "EC-93-27",vendor: "MEMMERT GmbH + Co. KG"},
  {max_prefix: "A0-EF-84",vendor: "Seine Image Int'l Co., Ltd"},
  {max_prefix: "E0-A3-0F",vendor: "Pevco"},
  {max_prefix: "5C-24-79",vendor: "Baltech AG"},
  {max_prefix: "D8-2D-E1",vendor: "Tricascade Inc."},
  {max_prefix: "D0-D2-12",vendor: "K2NET Co.,Ltd."},
  {max_prefix: "80-D1-8B",vendor: "Hangzhou I'converge Technology Co.,Ltd"},
  {max_prefix: "40-88-E0",vendor: "Beijing Ereneben Information Technology Limited Shenzhen Branch"},
  {max_prefix: "E8-54-84",vendor: "NEO Information Systems Co., Ltd."},
  {max_prefix: "74-AE-76",vendor: "iNovo Broadband, Inc."},
  {max_prefix: "70-9A-0B",vendor: "Italian Institute of Technology"},
  {max_prefix: "34-6E-8A",vendor: "Ecosense"},
  {max_prefix: "64-F2-42",vendor: "Gerdes Aktiengesellschaft"},
  {max_prefix: "60-F2-81",vendor: "TRANWO TECHNOLOGY CO., LTD."},
  {max_prefix: "0C-C0-C0",vendor: "MAGNETI MARELLI SISTEMAS ELECTRONICOS MEXICO"},
  {max_prefix: "08-37-9C",vendor: "Topaz Co. LTD."},
  {max_prefix: "D8-0D-E3",vendor: "FXI TECHNOLOGIES AS"},
  {max_prefix: "60-CB-FB",vendor: "AirScape Inc."},
  {max_prefix: "7C-16-0D",vendor: "Saia-Burgess Controls AG"},
  {max_prefix: "A4-97-BB",vendor: "Hitachi Industrial Equipment Systems Co.,Ltd"},
  {max_prefix: "4C-54-27",vendor: "Linepro Sp. z o.o."},
  {max_prefix: "EC-1A-59",vendor: "Belkin International Inc."},
  {max_prefix: "88-10-36",vendor: "Panodic(ShenZhen) Electronics Limted"},
  {max_prefix: "68-B6-FC",vendor: "Hitron Technologies. Inc"},
  {max_prefix: "94-21-97",vendor: "Stalmart Technology Limited"},
  {max_prefix: "A0-C3-DE",vendor: "Triton Electronic Systems Ltd."},
  {max_prefix: "D0-69-9E",vendor: "LUMINEX Lighting Control Equipment"},
  {max_prefix: "B0-D2-F5",vendor: "Vello Systems, Inc."},
  {max_prefix: "B0-43-5D",vendor: "NuLEDs, Inc."},
  {max_prefix: "08-08-EA",vendor: "AMSC"},
  {max_prefix: "A4-93-4C",vendor: "Cisco Systems, Inc"},
  {max_prefix: "F0-FD-A0",vendor: "Acurix Networks Pty Ltd"},
  {max_prefix: "B4-55-70",vendor: "Borea"},
  {max_prefix: "50-57-A8",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-DE-FB",vendor: "Cisco Systems, Inc"},
  {max_prefix: "3C-A3-15",vendor: "Bless Information & Communications Co., Ltd"},
  {max_prefix: "10-0D-2F",vendor: "Online Security Pty. Ltd."},
  {max_prefix: "3C-98-BF",vendor: "Quest Controls, Inc."},
  {max_prefix: "D0-AE-EC",vendor: "Alpha Networks Inc."},
  {max_prefix: "E4-77-D4",vendor: "Minrray Industry Co.,Ltd"},
  {max_prefix: "38-E0-8E",vendor: "Mitsubishi Electric Corporation"},
  {max_prefix: "10-A9-32",vendor: "Beijing Cyber Cloud Technology Co. ,Ltd."},
  {max_prefix: "34-FC-6F",vendor: "ALCEA"},
  {max_prefix: "C0-B3-57",vendor: "Yoshiki Electronics Industry Ltd."},
  {max_prefix: "A8-17-58",vendor: "Elektronik System i Ume\u00e5 AB"},
  {max_prefix: "E8-13-24",vendor: "GuangZhou Bonsoninfo System CO.,LTD"},
  {max_prefix: "14-2D-F5",vendor: "Amphitech"},
  {max_prefix: "F8-30-94",vendor: "Alcatel-Lucent Telecom Limited"},
  {max_prefix: "C4-95-A2",vendor: "SHENZHEN WEIJIU INDUSTRY AND TRADE DEVELOPMENT CO., LTD"},
  {max_prefix: "8C-68-78",vendor: "Nortek-AS"},
  {max_prefix: "20-25-98",vendor: "Teleview"},
  {max_prefix: "38-F8-B7",vendor: "V2COM PARTICIPACOES S.A."},
  {max_prefix: "A0-4C-C1",vendor: "Helixtech Corp."},
  {max_prefix: "34-A7-BA",vendor: "Fischer International Systems Corporation"},
  {max_prefix: "F8-D4-62",vendor: "Pumatronix Equipamentos Eletronicos Ltda."},
  {max_prefix: "A0-DC-04",vendor: "Becker-Antriebe GmbH"},
  {max_prefix: "40-60-5A",vendor: "Hawkeye Tech Co. Ltd"},
  {max_prefix: "54-53-ED",vendor: "Sony Corporation"},
  {max_prefix: "F4-73-CA",vendor: "Conversion Sound Inc."},
  {max_prefix: "F8-F7-FF",vendor: "SYN-TECH SYSTEMS INC"},
  {max_prefix: "E4-C8-06",vendor: "Ceiec Electric Technology Inc."},
  {max_prefix: "E0-F9-BE",vendor: "Cloudena Corp."},
  {max_prefix: "08-B4-CF",vendor: "Abicom International"},
  {max_prefix: "7C-94-B2",vendor: "Philips Healthcare PCCI"},
  {max_prefix: "44-2B-03",vendor: "Cisco Systems, Inc"},
  {max_prefix: "B8-8F-14",vendor: "Analytica GmbH"},
  {max_prefix: "60-E9-56",vendor: "Ayla Networks, Inc"},
  {max_prefix: "EC-11-20",vendor: "FloDesign Wind Turbine Corporation"},
  {max_prefix: "E8-6D-6E",vendor: "voestalpine Signaling UK Ltd."},
  {max_prefix: "68-16-05",vendor: "Systems And Electronic Development FZCO"},
  {max_prefix: "88-20-12",vendor: "LMI Technologies"},
  {max_prefix: "3C-4E-47",vendor: "Etronic A/S"},
  {max_prefix: "F4-87-71",vendor: "Infoblox"},
  {max_prefix: "F8-97-CF",vendor: "DAESHIN-INFORMATION TECHNOLOGY CO., LTD."},
  {max_prefix: "B4-9E-E6",vendor: "SHENZHEN TECHNOLOGY CO LTD"},
  {max_prefix: "BC-4B-79",vendor: "SensingTek"},
  {max_prefix: "A4-90-05",vendor: "CHINA GREATWALL COMPUTER SHENZHEN CO.,LTD"},
  {max_prefix: "C4-0A-CB",vendor: "Cisco Systems, Inc"},
  {max_prefix: "D4-A0-2A",vendor: "Cisco Systems, Inc"},
  {max_prefix: "88-C3-6E",vendor: "Beijing Ereneben lnformation Technology Limited"},
  {max_prefix: "64-54-22",vendor: "Equinox Payments"},
  {max_prefix: "00-37-6D",vendor: "Murata Manufacturing Co., Ltd."},
  {max_prefix: "50-00-8C",vendor: "Hong Kong Telecommunications (HKT) Limited"},
  {max_prefix: "34-AA-99",vendor: "Nokia"},
  {max_prefix: "24-B8-8C",vendor: "Crenus Co.,Ltd."},
  {max_prefix: "4C-9E-80",vendor: "KYOKKO ELECTRIC Co., Ltd."},
  {max_prefix: "5C-EB-4E",vendor: "R. STAHL HMI Systems GmbH"},
  {max_prefix: "94-3A-F0",vendor: "Nokia Corporation"},
  {max_prefix: "04-63-E0",vendor: "Nome Oy"},
  {max_prefix: "94-0B-2D",vendor: "NetView Technologies(Shenzhen) Co., Ltd"},
  {max_prefix: "30-6E-5C",vendor: "Validus Technologies"},
  {max_prefix: "C8-94-D2",vendor: "Jiangsu Datang  Electronic Products Co., Ltd"},
  {max_prefix: "BC-FE-8C",vendor: "Altronic, LLC"},
  {max_prefix: "64-9E-F3",vendor: "Cisco Systems, Inc"},
  {max_prefix: "24-BB-C1",vendor: "Absolute Analysis"},
  {max_prefix: "9C-CA-D9",vendor: "Nokia Corporation"},
  {max_prefix: "04-6D-42",vendor: "Bryston Ltd."},
  {max_prefix: "D8-E7-43",vendor: "Wush, Inc"},
  {max_prefix: "80-94-6C",vendor: "TOKYO RADAR CORPORATION"},
  {max_prefix: "3C-E6-24",vendor: "LG Display"},
  {max_prefix: "D8-F0-F2",vendor: "Zeebo Inc"},
  {max_prefix: "64-4D-70",vendor: "dSPACE GmbH"},
  {max_prefix: "D0-CF-5E",vendor: "Energy Micro AS"},
  {max_prefix: "DC-C1-01",vendor: "SOLiD Technologies, Inc."},
  {max_prefix: "08-0D-84",vendor: "GECO, Inc."},
  {max_prefix: "88-E7-12",vendor: "Whirlpool Corporation"},
  {max_prefix: "D4-12-BB",vendor: "Quadrant Components Inc. Ltd"},
  {max_prefix: "80-60-07",vendor: "RIM"},
  {max_prefix: "94-E0-D0",vendor: "HealthStream Taiwan Inc."},
  {max_prefix: "DC-F8-58",vendor: "Lorent Networks, Inc."},
  {max_prefix: "18-03-FA",vendor: "IBT Interfaces"},
  {max_prefix: "D8-05-2E",vendor: "Skyviia Corporation"},
  {max_prefix: "18-38-25",vendor: "Wuhan Lingjiu High-tech Co.,Ltd."},
  {max_prefix: "7C-4B-78",vendor: "Red Sun Synthesis Pte Ltd"},
  {max_prefix: "64-A0-E7",vendor: "Cisco Systems, Inc"},
  {max_prefix: "EC-62-64",vendor: "Global411 Internet Services, LLC"},
  {max_prefix: "00-F0-51",vendor: "KWB Gmbh"},
  {max_prefix: "CC-EF-48",vendor: "Cisco Systems, Inc"},
  {max_prefix: "24-37-EF",vendor: "EMC Electronic Media Communication SA"},
  {max_prefix: "28-B0-CC",vendor: "Xenya d.o.o."},
  {max_prefix: "20-5B-5E",vendor: "Shenzhen Wonhe Technology Co., Ltd"},
  {max_prefix: "B0-E5-0E",vendor: "NRG SYSTEMS INC"},
  {max_prefix: "48-C1-AC",vendor: "PLANTRONICS, INC."},
  {max_prefix: "98-58-8A",vendor: "SYSGRATION Ltd."},
  {max_prefix: "94-31-9B",vendor: "Alphatronics BV"},
  {max_prefix: "E8-78-A1",vendor: "BEOVIEW INTERCOM DOO"},
  {max_prefix: "80-3F-5D",vendor: "Winstars Technology Ltd"},
  {max_prefix: "40-BF-17",vendor: "Digistar Telecom. SA"},
  {max_prefix: "78-07-38",vendor: "Z.U.K. Elzab S.A."},
  {max_prefix: "20-37-BC",vendor: "Kuipers Electronic Engineering BV"},
  {max_prefix: "C8-A6-20",vendor: "Nebula, Inc"},
  {max_prefix: "FC-94-6C",vendor: "UBIVELOX"},
  {max_prefix: "40-7B-1B",vendor: "Mettle Networks Inc."},
  {max_prefix: "48-9B-E2",vendor: "SCI Innovations Ltd"},
  {max_prefix: "80-FF-A8",vendor: "UNIDIS"},
  {max_prefix: "40-D5-59",vendor: "MICRO S.E.R.I."},
  {max_prefix: "30-6C-BE",vendor: "Skymotion Technology (HK) Limited"},
  {max_prefix: "88-6B-76",vendor: "CHINA HOPEFUL GROUP HOPEFUL ELECTRIC CO.,LTD"},
  {max_prefix: "78-F7-D0",vendor: "Silverbrook Research"},
  {max_prefix: "20-76-00",vendor: "Actiontec Electronics, Inc"},
  {max_prefix: "F0-4B-6A",vendor: "Scientific Production Association Siberian Arsenal, Ltd."},
  {max_prefix: "64-AE-0C",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-E1-75",vendor: "AK-Systems Ltd"},
  {max_prefix: "CC-50-1C",vendor: "KVH Industries, Inc."},
  {max_prefix: "04-D7-83",vendor: "Y&H E&C Co.,LTD."},
  {max_prefix: "C8-3B-45",vendor: "JRI"},
  {max_prefix: "C0-58-A7",vendor: "Pico Systems Co., Ltd."},
  {max_prefix: "EC-3F-05",vendor: "Institute 706, The Second Academy China Aerospace Science & Industry Corp"},
  {max_prefix: "E8-DA-96",vendor: "Zhuhai Tianrui Electrical Power Tech. Co., Ltd."},
  {max_prefix: "F0-13-C3",vendor: "SHENZHEN FENDA TECHNOLOGY CO., LTD"},
  {max_prefix: "04-A8-2A",vendor: "Nokia Corporation"},
  {max_prefix: "E4-4E-18",vendor: "Gardasoft VisionLimited"},
  {max_prefix: "14-8A-70",vendor: "ADS GmbH"},
  {max_prefix: "B4-D8-DE",vendor: "iota Computing, Inc."},
  {max_prefix: "C8-90-3E",vendor: "Pakton Technologies"},
  {max_prefix: "DC-2E-6A",vendor: "HCT. Co., Ltd."},
  {max_prefix: "20-46-A1",vendor: "VECOW Co., Ltd"},
  {max_prefix: "FC-01-CD",vendor: "FUNDACION TEKNIKER"},
  {max_prefix: "9C-8B-F1",vendor: "The Warehouse Limited"},
  {max_prefix: "00-B3-38",vendor: "Kontron Asia Pacific Design Sdn. Bhd"},
  {max_prefix: "E4-35-FB",vendor: "Sabre Technology (Hull) Ltd"},
  {max_prefix: "F0-02-2B",vendor: "Chrontel"},
  {max_prefix: "B8-28-8B",vendor: "Parker Hannifin Manufacturing (UK) Ltd"},
  {max_prefix: "90-D1-1B",vendor: "Palomar Medical Technologies"},
  {max_prefix: "A0-6E-50",vendor: "Nanotek Elektronik Sistemler Ltd. Sti."},
  {max_prefix: "84-24-8D",vendor: "Zebra Technologies Inc"},
  {max_prefix: "FC-E8-92",vendor: "Hangzhou Lancable Technology Co.,Ltd"},
  {max_prefix: "34-A5-5D",vendor: "TECHNOSOFT INTERNATIONAL SRL"},
  {max_prefix: "4C-77-4F",vendor: "Embedded Wireless Labs"},
  {max_prefix: "EC-BD-09",vendor: "FUSION Electronics Ltd"},
  {max_prefix: "94-46-96",vendor: "BaudTec Corporation"},
  {max_prefix: "54-84-7B",vendor: "Digital Devices GmbH"},
  {max_prefix: "84-3F-4E",vendor: "Tri-Tech Manufacturing, Inc."},
  {max_prefix: "C8-32-32",vendor: "Hunting Innova"},
  {max_prefix: "D4-53-AF",vendor: "VIGO System S.A."},
  {max_prefix: "18-AD-4D",vendor: "Polostar Technology Corporation"},
  {max_prefix: "10-71-F9",vendor: "Cloud Telecomputers, LLC"},
  {max_prefix: "B8-62-1F",vendor: "Cisco Systems, Inc"},
  {max_prefix: "94-C6-EB",vendor: "NOVA electronics, Inc."},
  {max_prefix: "54-94-78",vendor: "Silvershore Technology Partners"},
  {max_prefix: "54-CD-A7",vendor: "Fujian Shenzhou Electronic Co.,Ltd"},
  {max_prefix: "3C-27-63",vendor: "SLE quality engineering GmbH & Co. KG"},
  {max_prefix: "B0-F1-BC",vendor: "Dhemax Ingenieros Ltda"},
  {max_prefix: "50-8A-CB",vendor: "SHENZHEN MAXMADE TECHNOLOGY CO., LTD."},
  {max_prefix: "D0-C2-82",vendor: "Cisco Systems, Inc"},
  {max_prefix: "14-7D-B3",vendor: "JOA TELECOM.CO.,LTD"},
  {max_prefix: "3C-D1-6E",vendor: "Telepower Communication Co., Ltd"},
  {max_prefix: "40-04-0C",vendor: "A&T"},
  {max_prefix: "94-8F-EE",vendor: "Verizon Telematics"},
  {max_prefix: "50-D6-D7",vendor: "Takahata Precision"},
  {max_prefix: "00-FC-70",vendor: "Intrepid Control Systems, Inc."},
  {max_prefix: "70-4A-AE",vendor: "Xstream Flow (Pty) Ltd"},
  {max_prefix: "40-B3-FC",vendor: "Logital Co. Limited"},
  {max_prefix: "DC-3C-84",vendor: "Ticom Geomatics, Inc."},
  {max_prefix: "D0-13-1E",vendor: "Sunrex Technology Corp"},
  {max_prefix: "C4-0F-09",vendor: "Hermes electronic GmbH"},
  {max_prefix: "80-2E-14",vendor: "azeti Networks AG"},
  {max_prefix: "D4-C1-FC",vendor: "Nokia Corporation"},
  {max_prefix: "34-BC-A6",vendor: "Beijing Ding Qing Technology, Ltd."},
  {max_prefix: "58-35-D9",vendor: "Cisco Systems, Inc"},
  {max_prefix: "64-D9-12",vendor: "Solidica, Inc."},
  {max_prefix: "C4-7B-2F",vendor: "Beijing JoinHope Image Technology Ltd."},
  {max_prefix: "FC-2E-2D",vendor: "Lorom Industrial Co.LTD."},
  {max_prefix: "58-75-21",vendor: "CJSC RTSoft"},
  {max_prefix: "18-E2-88",vendor: "STT Condigi"},
  {max_prefix: "B4-35-F7",vendor: "Zhejiang Pearmain Electronics Co.ltd."},
  {max_prefix: "98-66-EA",vendor: "Industrial Control Communications, Inc."},
  {max_prefix: "98-30-00",vendor: "Beijing KEMACOM Technologies Co., Ltd."},
  {max_prefix: "90-CF-15",vendor: "Nokia Corporation"},
  {max_prefix: "F0-81-AF",vendor: "IRZ AUTOMATION TECHNOLOGIES LTD"},
  {max_prefix: "70-14-04",vendor: "Limited Liability Company"},
  {max_prefix: "1C-35-F1",vendor: "NEW Lift Neue Elektronische Wege Steuerungsbau GmbH"},
  {max_prefix: "80-34-57",vendor: "OT Systems Limited"},
  {max_prefix: "B0-99-28",vendor: "FUJITSU LIMITED"},
  {max_prefix: "04-E1-C8",vendor: "IMS Solu\u00e7\u00f5es em Energia Ltda."},
  {max_prefix: "94-8B-03",vendor: "EAGET Innovation and Technology Co., Ltd."},
  {max_prefix: "48-F4-7D",vendor: "TechVision Holding  Internation Limited"},
  {max_prefix: "70-3A-D8",vendor: "Shenzhen Afoundry Electronic Co., Ltd"},
  {max_prefix: "88-F0-77",vendor: "Cisco Systems, Inc"},
  {max_prefix: "2C-00-33",vendor: "EControls, LLC"},
  {max_prefix: "AC-19-9F",vendor: "SUNGROW POWER SUPPLY CO.,LTD."},
  {max_prefix: "7C-4A-82",vendor: "Portsmith LLC"},
  {max_prefix: "AC-5E-8C",vendor: "Utillink"},
  {max_prefix: "94-E8-48",vendor: "FYLDE MICRO LTD"},
  {max_prefix: "94-AA-B8",vendor: "Joview(Beijing) Technology Co. Ltd."},
  {max_prefix: "28-F3-58",vendor: "2C - Trifonov & Co"},
  {max_prefix: "30-1A-28",vendor: "Mako Networks Ltd"},
  {max_prefix: "38-31-AC",vendor: "WEG"},
  {max_prefix: "58-4C-19",vendor: "Chongqing Guohong Technology Development Company Limited"},
  {max_prefix: "3C-A7-2B",vendor: "MRV Communications (Networks) LTD"},
  {max_prefix: "28-51-32",vendor: "Shenzhen Prayfly Technology Co.,Ltd"},
  {max_prefix: "E4-2F-F6",vendor: "Unicore communication Inc."},
  {max_prefix: "84-D9-C8",vendor: "Unipattern Co.,"},
  {max_prefix: "B8-0B-9D",vendor: "ROPEX Industrie-Elektronik GmbH"},
  {max_prefix: "50-70-E5",vendor: "He Shan World Fair Electronics Technology Limited"},
  {max_prefix: "80-22-75",vendor: "Beijing Beny Wave Technology Co Ltd"},
  {max_prefix: "24-C8-6E",vendor: "Chaney Instrument Co."},
  {max_prefix: "F0-AE-51",vendor: "Xi3 Corp"},
  {max_prefix: "14-F0-C5",vendor: "Xtremio Ltd."},
  {max_prefix: "E8-C2-29",vendor: "H-Displays (MSC) Bhd"},
  {max_prefix: "5C-0C-BB",vendor: "CELIZION Inc."},
  {max_prefix: "30-61-18",vendor: "Paradom Inc."},
  {max_prefix: "4C-73-67",vendor: "Genius Bytes Software Solutions GmbH"},
  {max_prefix: "90-EA-60",vendor: "SPI Lasers Ltd"},
  {max_prefix: "C4-24-2E",vendor: "Galvanic Applied Sciences Inc"},
  {max_prefix: "F8-EA-0A",vendor: "Dipl.-Math. Michael Rauch"},
  {max_prefix: "CC-F3-A5",vendor: "Chi Mei Communication Systems, Inc"},
  {max_prefix: "14-A9-E3",vendor: "MST CORPORATION"},
  {max_prefix: "14-B7-3D",vendor: "ARCHEAN Technologies"},
  {max_prefix: "94-8D-50",vendor: "Beamex Oy Ab"},
  {max_prefix: "A4-33-D1",vendor: "Fibrlink Communications Co.,Ltd."},
  {max_prefix: "64-69-BC",vendor: "Hytera Communications Co .,ltd"},
  {max_prefix: "B4-F3-23",vendor: "PETATEL INC."},
  {max_prefix: "14-C2-1D",vendor: "Sabtech Industries"},
  {max_prefix: "C8-84-39",vendor: "Sunrise Technologies"},
  {max_prefix: "F0-C2-7C",vendor: "Mianyang Netop Telecom Equipment Co.,Ltd."},
  {max_prefix: "28-34-10",vendor: "Enigma Diagnostics Limited"},
  {max_prefix: "0C-E8-2F",vendor: "Bonfiglioli Vectron GmbH"},
  {max_prefix: "40-F4-EC",vendor: "Cisco Systems, Inc"},
  {max_prefix: "A4-1B-C0",vendor: "Fastec Imaging Corporation"},
  {max_prefix: "20-5B-2A",vendor: "Private"},
  {max_prefix: "F4-03-21",vendor: "BeNeXt B.V."},
  {max_prefix: "A0-71-A9",vendor: "Nokia Corporation"},
  {max_prefix: "A4-E3-2E",vendor: "Silicon & Software Systems Ltd."},
  {max_prefix: "5C-BD-9E",vendor: "HONGKONG MIRACLE EAGLE TECHNOLOGY(GROUP) LIMITED"},
  {max_prefix: "F0-BF-97",vendor: "Sony Corporation"},
  {max_prefix: "C4-4A-D0",vendor: "FIREFLIES SYSTEMS"},
  {max_prefix: "88-E0-A0",vendor: "Shenzhen VisionSTOR Technologies Co., Ltd"},
  {max_prefix: "FC-10-BD",vendor: "Control Sistematizado S.A."},
  {max_prefix: "F8-7B-8C",vendor: "Amped Wireless"},
  {max_prefix: "24-1A-8C",vendor: "Squarehead Technology AS"},
  {max_prefix: "D4-4F-80",vendor: "Kemper Digital GmbH"},
  {max_prefix: "08-E6-72",vendor: "JEBSEE ELECTRONICS CO.,LTD."},
  {max_prefix: "B8-E5-89",vendor: "Payter BV"},
  {max_prefix: "EC-7D-9D",vendor: "CPI"},
  {max_prefix: "C8-1E-8E",vendor: "ADV Security (S) Pte Ltd"},
  {max_prefix: "A8-87-92",vendor: "Broadband Antenna Tracking Systems"},
  {max_prefix: "04-E6-62",vendor: "Acroname Inc."},
  {max_prefix: "64-DC-01",vendor: "Static Systems Group PLC"},
  {max_prefix: "60-76-88",vendor: "Velodyne"},
  {max_prefix: "78-CD-8E",vendor: "SMC Networks Inc"},
  {max_prefix: "C8-C1-26",vendor: "ZPM Industria e Comercio Ltda"},
  {max_prefix: "64-DE-1C",vendor: "Kingnetic Pte Ltd"},
  {max_prefix: "A8-62-A2",vendor: "JIWUMEDIA CO., LTD."},
  {max_prefix: "98-4E-97",vendor: "Starlight Marketing (H. K.) Ltd."},
  {max_prefix: "78-A6-83",vendor: "Precidata"},
  {max_prefix: "FC-1F-C0",vendor: "EURECAM"},
  {max_prefix: "BC-67-84",vendor: "Environics Oy"},
  {max_prefix: "B4-C4-4E",vendor: "VXL eTech Pvt Ltd"},
  {max_prefix: "68-DC-E8",vendor: "PacketStorm Communications"},
  {max_prefix: "48-8E-42",vendor: "DIGALOG GmbH"},
  {max_prefix: "2C-80-65",vendor: "HARTING Inc. of North America"},
  {max_prefix: "3C-C0-C6",vendor: "d&b audiotechnik GmbH"},
  {max_prefix: "44-68-AB",vendor: "JUIN COMPANY, LIMITED"},
  {max_prefix: "F8-10-37",vendor: "Atopia Systems, LP"},
  {max_prefix: "70-7E-DE",vendor: "NASTEC LTD."},
  {max_prefix: "E0-5F-B9",vendor: "Cisco Systems, Inc"},
  {max_prefix: "F0-25-72",vendor: "Cisco Systems, Inc"},
  {max_prefix: "04-FF-51",vendor: "NOVAMEDIA INNOVISION SP. Z O.O."},
  {max_prefix: "4C-B4-EA",vendor: "HRD (S) PTE., LTD."},
  {max_prefix: "D4-4C-24",vendor: "Vuppalamritha Magnetic Components LTD"},
  {max_prefix: "80-91-C0",vendor: "AgileMesh, Inc."},
  {max_prefix: "08-4E-BF",vendor: "Sumitomo Electric Industries, Ltd"},
  {max_prefix: "AC-02-CF",vendor: "RW Tecnologia Industria e Comercio Ltda"},
  {max_prefix: "F8-C6-78",vendor: "Carefusion"},
  {max_prefix: "6C-AB-4D",vendor: "Digital Payment Technologies"},
  {max_prefix: "2C-B0-DF",vendor: "Soliton Technologies Pvt Ltd"},
  {max_prefix: "EC-E5-55",vendor: "Hirschmann Automation"},
  {max_prefix: "58-F9-8E",vendor: "SECUDOS GmbH"},
  {max_prefix: "B4-74-9F",vendor: "ASKEY COMPUTER CORP"},
  {max_prefix: "7C-4A-A8",vendor: "MindTree Wireless PVT Ltd"},
  {max_prefix: "E0-14-3E",vendor: "Modoosis Inc."},
  {max_prefix: "C0-7E-40",vendor: "SHENZHEN XDK COMMUNICATION EQUIPMENT CO.,LTD"},
  {max_prefix: "E4-4F-29",vendor: "MA Lighting Technology GmbH"},
  {max_prefix: "1C-7C-11",vendor: "EID"},
  {max_prefix: "48-17-4C",vendor: "MicroPower technologies"},
  {max_prefix: "90-50-7B",vendor: "Advanced PANMOBIL Systems GmbH & Co. KG"},
  {max_prefix: "20-AA-25",vendor: "IP-NET LLC"},
  {max_prefix: "34-9A-0D",vendor: "ZBD Displays Ltd"},
  {max_prefix: "D4-12-96",vendor: "Anobit Technologies Ltd."},
  {max_prefix: "08-76-FF",vendor: "Thomson Telecom Belgium"},
  {max_prefix: "90-D8-52",vendor: "Comtec Co., Ltd."},
  {max_prefix: "38-01-97",vendor: "TSST Global,Inc"},
  {max_prefix: "58-8D-09",vendor: "Cisco Systems, Inc"},
  {max_prefix: "C0-C1-C0",vendor: "Cisco-Linksys, LLC"},
  {max_prefix: "00-34-F1",vendor: "Radicom Research, Inc."},
  {max_prefix: "60-15-C7",vendor: "IdaTech"},
  {max_prefix: "DC-20-08",vendor: "ASD Electronics Ltd"},
  {max_prefix: "A4-D1-D1",vendor: "ECOtality North America"},
  {max_prefix: "C4-93-13",vendor: "100fio networks technology llc"},
  {max_prefix: "68-59-7F",vendor: "Alcatel Lucent"},
  {max_prefix: "F0-65-DD",vendor: "Primax Electronics Ltd."},
  {max_prefix: "70-65-82",vendor: "Suzhou Hanming Technologies Co., Ltd."},
  {max_prefix: "C4-B5-12",vendor: "General Electric Digital Energy"},
  {max_prefix: "E0-25-38",vendor: "Titan Pet Products"},
  {max_prefix: "CC-7A-30",vendor: "CMAX Wireless Co., Ltd."},
  {max_prefix: "B8-8E-3A",vendor: "Infinite Technologies JLT"},
  {max_prefix: "D4-CB-AF",vendor: "Nokia Corporation"},
  {max_prefix: "10-E8-EE",vendor: "PhaseSpace"},
  {max_prefix: "A4-7C-1F",vendor: "Cobham plc"},
  {max_prefix: "D4-6C-DA",vendor: "CSM GmbH"},
  {max_prefix: "5C-D9-98",vendor: "D-Link Corporation"},
  {max_prefix: "94-33-DD",vendor: "Taco Inc"},
  {max_prefix: "E0-CF-2D",vendor: "Gemintek Corporation"},
  {max_prefix: "C4-EE-F5",vendor: "II-VI Incorporated"},
  {max_prefix: "D4-91-AF",vendor: "Electroacustica General Iberica, S.A."},
  {max_prefix: "04-5D-56",vendor: "camtron industrial inc."},
  {max_prefix: "68-23-4B",vendor: "Nihon Dengyo Kousaku"},
  {max_prefix: "A4-BE-61",vendor: "EutroVision System, Inc."},
  {max_prefix: "58-BC-27",vendor: "Cisco Systems, Inc"},
  {max_prefix: "34-D2-C4",vendor: "RENA GmbH Print Systeme"},
  {max_prefix: "7C-39-20",vendor: "SSOMA SECURITY"},
  {max_prefix: "9C-77-AA",vendor: "NADASNV"},
  {max_prefix: "1C-83-B0",vendor: "Linked IP GmbH"},
  {max_prefix: "1C-3D-E7",vendor: "Sigma Koki Co.,Ltd."},
  {max_prefix: "88-18-AE",vendor: "Tamron Co., Ltd"},
  {max_prefix: "20-D6-07",vendor: "Nokia Corporation"},
  {max_prefix: "44-3D-21",vendor: "Nuvolt"},
  {max_prefix: "30-49-3B",vendor: "Nanjing Z-Com Wireless Co.,Ltd"},
  {max_prefix: "D0-7D-E5",vendor: "Forward Pay Systems, Inc."},
  {max_prefix: "04-DD-4C",vendor: "Velocytech"},
  {max_prefix: "6C-E0-B0",vendor: "SOUND4"},
  {max_prefix: "9C-FF-BE",vendor: "OTSL Inc."},
  {max_prefix: "00-F8-60",vendor: "PT. Panggung Electric Citrabuana"},
  {max_prefix: "EC-66-D1",vendor: "B&W Group LTD"},
  {max_prefix: "38-5F-C3",vendor: "Yu Jeong System, Co.Ltd"},
  {max_prefix: "88-8B-5D",vendor: "Storage Appliance Corporation"},
  {max_prefix: "18-EF-63",vendor: "Cisco Systems, Inc"},
  {max_prefix: "20-6F-EC",vendor: "Braemac CA LLC"},
  {max_prefix: "A4-5C-27",vendor: "Nintendo Co., Ltd."},
  {max_prefix: "6C-04-60",vendor: "RBH Access Technologies Inc."},
  {max_prefix: "70-64-17",vendor: "ORBIS TECNOLOGIA ELECTRICA S.A."},
  {max_prefix: "B8-BA-72",vendor: "Cynove"},
  {max_prefix: "78-C6-BB",vendor: "Analog Devices, Inc."},
  {max_prefix: "10-0D-32",vendor: "Embedian, Inc."},
  {max_prefix: "7C-ED-8D",vendor: "Microsoft"},
  {max_prefix: "88-AC-C1",vendor: "Generiton Co., Ltd."},
  {max_prefix: "EC-7C-74",vendor: "Justone Technologies Co., Ltd."},
  {max_prefix: "A8-B1-D4",vendor: "Cisco Systems, Inc"},
  {max_prefix: "CC-FC-B1",vendor: "Wireless Technology, Inc."},
  {max_prefix: "3C-1A-79",vendor: "Huayuan Technology CO.,LTD"},
  {max_prefix: "C8-02-A6",vendor: "Beijing Newmine Technology"},
  {max_prefix: "C8-4C-75",vendor: "Cisco Systems, Inc"},
  {max_prefix: "4C-8B-55",vendor: "Grupo Digicon"},
  {max_prefix: "04-A3-F3",vendor: "Emicon"},
  {max_prefix: "1C-17-D3",vendor: "Cisco Systems, Inc"},
  {max_prefix: "7C-E0-44",vendor: "NEON Inc"},
  {max_prefix: "28-4C-53",vendor: "Intune Networks"},
  {max_prefix: "64-D0-2D",vendor: "NEXT GENERATION INTEGRATION LIMITED (NGI)"},
  {max_prefix: "68-78-4C",vendor: "Nortel Networks"},
  {max_prefix: "9C-F6-1A",vendor: "Carrier Fire & Security"},
  {max_prefix: "7C-F0-98",vendor: "Bee Beans Technologies, Inc."},
  {max_prefix: "88-41-C1",vendor: "ORBISAT DA AMAZONIA IND E AEROL SA"},
  {max_prefix: "9C-75-14",vendor: "Wildix srl"},
  {max_prefix: "4C-F7-37",vendor: "SamJi Electronics Co., Ltd"},
  {max_prefix: "F0-D7-67",vendor: "Axema Passagekontroll AB"},
  {max_prefix: "84-A9-91",vendor: "Cyber Trans Japan Co.,Ltd."},
  {max_prefix: "F8-D7-56",vendor: "Simm Tronic Limited"},
  {max_prefix: "A4-0C-C3",vendor: "Cisco Systems, Inc"},
  {max_prefix: "4C-BA-A3",vendor: "Bison Electronics Inc."},
  {max_prefix: "90-51-3F",vendor: "Elettronica Santerno SpA"},
  {max_prefix: "00-B5-D6",vendor: "Omnibit Inc."},
  {max_prefix: "54-89-22",vendor: "Zelfy Inc"},
  {max_prefix: "6C-5C-DE",vendor: "SunReports, Inc."},
  {max_prefix: "34-F3-9B",vendor: "WizLAN Ltd."},
  {max_prefix: "E8-6C-DA",vendor: "Supercomputers and Neurocomputers Research Center"},
  {max_prefix: "24-0B-2A",vendor: "Viettel Group"},
  {max_prefix: "84-F6-4C",vendor: "Cross Point BV"},
  {max_prefix: "C0-8B-6F",vendor: "S I Sistemas Inteligentes Eletr\u00f4nicos Ltda"},
  {max_prefix: "EC-B1-06",vendor: "Acuro Networks, Inc"},
  {max_prefix: "48-12-49",vendor: "Luxcom Technologies Inc."},
  {max_prefix: "24-A9-37",vendor: "PURE Storage"},
  {max_prefix: "34-83-02",vendor: "iFORCOM Co., Ltd"},
  {max_prefix: "F8-6E-CF",vendor: "Arcx Inc"},
  {max_prefix: "8C-84-01",vendor: "Private"},
  {max_prefix: "40-84-93",vendor: "Clavister AB"},
  {max_prefix: "6C-70-39",vendor: "Novar GmbH"},
  {max_prefix: "B4-3D-B2",vendor: "Degreane Horizon"},
  {max_prefix: "34-BA-51",vendor: "Se-Kure Controls, Inc."},
  {max_prefix: "08-9F-97",vendor: "LEROY AUTOMATION"},
  {max_prefix: "78-A6-BD",vendor: "DAEYEON Control&Instrument Co,.Ltd"},
  {max_prefix: "3C-19-15",vendor: "GFI Chrono Time"},
  {max_prefix: "14-73-73",vendor: "TUBITAK UEKAE"},
  {max_prefix: "C8-35-B8",vendor: "Ericsson, EAB/RWI/K"},
  {max_prefix: "98-2D-56",vendor: "Resolution Audio"},
  {max_prefix: "FC-CF-62",vendor: "IBM Corp"},
  {max_prefix: "08-4E-1C",vendor: "H2A Systems, LLC"},
  {max_prefix: "88-B6-27",vendor: "Gembird Europe BV"},
  {max_prefix: "F0-68-53",vendor: "Integrated Corporation"},
  {max_prefix: "A4-AD-B8",vendor: "Vitec Group, Camera Dynamics Ltd"},
  {max_prefix: "E0-26-30",vendor: "Intrigue Technologies, Inc."},
  {max_prefix: "10-43-69",vendor: "Soundmax Electronic Limited"},
  {max_prefix: "C0-6C-0F",vendor: "Dobbs Stanford"},
  {max_prefix: "50-2D-F4",vendor: "Phytec Messtechnik GmbH"},
  {max_prefix: "A4-B1-21",vendor: "Arantia 2010 S.L."},
  {max_prefix: "E0-26-36",vendor: "Nortel Networks"},
  {max_prefix: "5C-57-C8",vendor: "Nokia Corporation"},
  {max_prefix: "D4-6C-BF",vendor: "Goodrich ISR"},
  {max_prefix: "F8-9D-0D",vendor: "Control Technology Inc."},
  {max_prefix: "2C-3F-3E",vendor: "Alge-Timing GmbH"},
  {max_prefix: "EC-C8-82",vendor: "Cisco Systems, Inc"},
  {max_prefix: "6C-FD-B9",vendor: "Proware Technologies Co Ltd."},
  {max_prefix: "B4-2C-BE",vendor: "Direct Payment Solutions Limited"},
  {max_prefix: "CC-22-18",vendor: "InnoDigital Co., Ltd."},
  {max_prefix: "C8-6C-1E",vendor: "Display Systems Ltd"},
  {max_prefix: "90-A7-C1",vendor: "Pakedge Device and Software Inc."},
  {max_prefix: "80-BA-AC",vendor: "TeleAdapt Ltd"},
  {max_prefix: "74-6B-82",vendor: "MOVEK"},
  {max_prefix: "9C-C0-77",vendor: "PrintCounts, LLC"},
  {max_prefix: "3C-B1-7F",vendor: "Wattwatchers Pty Ld"},
  {max_prefix: "CC-54-59",vendor: "OnTime Networks AS"},
  {max_prefix: "D4-F1-43",vendor: "IPROAD.,Inc"},
  {max_prefix: "C8-6C-B6",vendor: "Optcom Co., Ltd."},
  {max_prefix: "A0-18-59",vendor: "Shenzhen Yidashi Electronics Co Ltd"},
  {max_prefix: "E8-05-6D",vendor: "Nortel Networks"},
  {max_prefix: "C4-59-76",vendor: "Fugoo Coorporation"},
  {max_prefix: "8C-56-C5",vendor: "Nintendo Co., Ltd."},
  {max_prefix: "CC-B8-88",vendor: "AnB Securite s.a."},
  {max_prefix: "6C-5E-7A",vendor: "Ubiquitous Internet Telecom Co., Ltd"},
  {max_prefix: "34-86-2A",vendor: "Heinz Lackmann GmbH & Co KG"},
  {max_prefix: "B4-ED-54",vendor: "Wohler Technologies"},
  {max_prefix: "2C-CD-27",vendor: "Precor Inc"},
  {max_prefix: "10-18-9E",vendor: "Elmo Motion Control"},
  {max_prefix: "58-49-BA",vendor: "Chitai Electronic Corp."},
  {max_prefix: "00-D1-1C",vendor: "ACETEL"},
  {max_prefix: "54-9A-16",vendor: "Uzushio Electric Co.,Ltd."},
  {max_prefix: "60-1D-0F",vendor: "Midnite Solar"},
  {max_prefix: "A8-F9-4B",vendor: "Eltex Enterprise Ltd."},
  {max_prefix: "0C-82-30",vendor: "SHENZHEN MAGNUS TECHNOLOGIES CO.,LTD"},
  {max_prefix: "50-A6-E3",vendor: "David Clark Company"},
  {max_prefix: "50-93-4F",vendor: "Gradual Tecnologia Ltda."},
  {max_prefix: "AC-E3-48",vendor: "MadgeTech, Inc"},
  {max_prefix: "90-18-AE",vendor: "Shanghai Meridian Technologies, Co. Ltd."},
  {max_prefix: "04-94-A1",vendor: "CATCH THE WIND INC"},
  {max_prefix: "00-3A-99",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-3A-9A",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-3A-98",vendor: "Cisco Systems, Inc"},
  {max_prefix: "70-72-CF",vendor: "EdgeCore Networks"},
  {max_prefix: "B8-B1-C7",vendor: "BT&COM CO.,LTD"},
  {max_prefix: "DC-2C-26",vendor: "Iton Technology Limited"},
  {max_prefix: "00-64-40",vendor: "Cisco Systems, Inc"},
  {max_prefix: "94-C4-E9",vendor: "PowerLayer Microsystems HongKong Limited"},
  {max_prefix: "88-43-E1",vendor: "Cisco Systems, Inc"},
  {max_prefix: "B8-F7-32",vendor: "Aryaka Networks Inc"},
  {max_prefix: "E8-DF-F2",vendor: "PRF Co., Ltd."},
  {max_prefix: "A4-AD-00",vendor: "Ragsdale Technology"},
  {max_prefix: "4C-9E-E4",vendor: "Hanyang Navicom Co.,Ltd."},
  {max_prefix: "C4-7D-4F",vendor: "Cisco Systems, Inc"},
  {max_prefix: "3C-DF-1E",vendor: "Cisco Systems, Inc"},
  {max_prefix: "9C-4E-8E",vendor: "ALT Systems Ltd"},
  {max_prefix: "04-2B-BB",vendor: "PicoCELA, Inc."},
  {max_prefix: "FC-08-77",vendor: "Prentke Romich Company"},
  {max_prefix: "EC-D0-0E",vendor: "MiraeRecognition Co., Ltd."},
  {max_prefix: "98-6D-C8",vendor: "TOSHIBA MITSUBISHI-ELECTRIC INDUSTRIAL SYSTEMS CORPORATION"},
  {max_prefix: "6C-AC-60",vendor: "Venetex Corp"},
  {max_prefix: "C8-72-48",vendor: "Aplicom Oy"},
  {max_prefix: "F0-4B-F2",vendor: "JTECH Communications, Inc."},
  {max_prefix: "1C-0F-CF",vendor: "Sypro Optics GmbH"},
  {max_prefix: "64-F9-70",vendor: "Kenade Electronics Technology Co.,LTD."},
  {max_prefix: "D4-11-D6",vendor: "ShotSpotter, Inc."},
  {max_prefix: "9C-AF-CA",vendor: "Cisco Systems, Inc"},
  {max_prefix: "C8-2E-94",vendor: "Halfa Enterprise Co., Ltd."},
  {max_prefix: "7C-7B-E4",vendor: "Z'SEDAI KENKYUSHO CORPORATION"},
  {max_prefix: "F0-DE-71",vendor: "Shanghai EDO Technologies Co.,Ltd."},
  {max_prefix: "60-D3-0A",vendor: "Quatius Limited"},
  {max_prefix: "24-CF-21",vendor: "Shenzhen State Micro Technology Co., Ltd"},
  {max_prefix: "10-BA-A5",vendor: "GANA I&C CO., LTD"},
  {max_prefix: "BC-9D-A5",vendor: "DASCOM Europe GmbH"},
  {max_prefix: "28-FB-D3",vendor: "Ragentek Technology Group"},
  {max_prefix: "58-6E-D6",vendor: "Private"},
  {max_prefix: "C8-D2-C1",vendor: "Jetlun (Shenzhen) Corporation"},
  {max_prefix: "E0-91-53",vendor: "XAVi Technologies Corp."},
  {max_prefix: "64-BC-11",vendor: "CombiQ AB"},
  {max_prefix: "EC-30-91",vendor: "Cisco Systems, Inc"},
  {max_prefix: "F4-AC-C1",vendor: "Cisco Systems, Inc"},
  {max_prefix: "40-97-D1",vendor: "BK Electronics cc"},
  {max_prefix: "0C-E9-36",vendor: "ELIMOS srl"},
  {max_prefix: "60-39-1F",vendor: "ABB Ltd"},
  {max_prefix: "E8-A4-C1",vendor: "Deep Sea Electronics Ltd"},
  {max_prefix: "D4-C7-66",vendor: "Acentic GmbH"},
  {max_prefix: "A0-2E-F3",vendor: "United Integrated Services Co., Led."},
  {max_prefix: "A0-98-05",vendor: "OpenVox Communication Co Ltd"},
  {max_prefix: "00-27-1F",vendor: "MIPRO Electronics Co., Ltd"},
  {max_prefix: "88-A5-BD",vendor: "QPCOM INC."},
  {max_prefix: "80-17-7D",vendor: "Nortel Networks"},
  {max_prefix: "00-26-A9",vendor: "Strong Technologies Pty Ltd"},
  {max_prefix: "00-26-A5",vendor: "MICROROBOT.CO.,LTD"},
  {max_prefix: "00-26-A3",vendor: "FQ Ingenieria Electronica S.A."},
  {max_prefix: "00-26-9D",vendor: "M2Mnet Co., Ltd."},
  {max_prefix: "00-26-C5",vendor: "Guangdong Gosun Telecommunications Co.,Ltd"},
  {max_prefix: "00-26-C4",vendor: "Cadmos microsystems S.r.l."},
  {max_prefix: "00-26-C8",vendor: "System Sensor"},
  {max_prefix: "00-26-C2",vendor: "SCDI Co. LTD"},
  {max_prefix: "00-26-97",vendor: "Alpha  Technologies Inc."},
  {max_prefix: "00-26-8A",vendor: "Terrier SC Ltd"},
  {max_prefix: "00-27-12",vendor: "MaxVision LLC"},
  {max_prefix: "00-26-CF",vendor: "DEKA R&D"},
  {max_prefix: "00-26-F3",vendor: "SMC Networks"},
  {max_prefix: "00-26-E7",vendor: "Shanghai ONLAN Communication Tech. Co., Ltd."},
  {max_prefix: "00-26-E0",vendor: "ASITEQ"},
  {max_prefix: "00-27-0C",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-27-03",vendor: "Testech Electronics Pte Ltd"},
  {max_prefix: "00-26-12",vendor: "Space Exploration Technologies"},
  {max_prefix: "00-26-16",vendor: "Rosemount Inc."},
  {max_prefix: "00-26-0B",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-25-FD",vendor: "OBR Centrum Techniki Morskiej S.A."},
  {max_prefix: "00-26-00",vendor: "TEAC Australia Pty Ltd."},
  {max_prefix: "00-26-89",vendor: "General Dynamics Robotic Systems"},
  {max_prefix: "00-26-85",vendor: "Digital Innovation"},
  {max_prefix: "00-26-23",vendor: "JRD Communication Inc"},
  {max_prefix: "00-26-27",vendor: "Truesell"},
  {max_prefix: "00-26-7A",vendor: "wuhan hongxin telecommunication technologies co.,ltd"},
  {max_prefix: "00-26-64",vendor: "Core System Japan"},
  {max_prefix: "00-26-59",vendor: "Nintendo Co., Ltd."},
  {max_prefix: "00-26-51",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-26-4E",vendor: "r2p GmbH"},
  {max_prefix: "00-26-4F",vendor: "Kr\u00fcger &Gothe GmbH"},
  {max_prefix: "00-26-21",vendor: "InteliCloud Technology Inc."},
  {max_prefix: "00-26-1C",vendor: "NEOVIA INC."},
  {max_prefix: "00-26-39",vendor: "T.M. Electronics, Inc."},
  {max_prefix: "00-26-72",vendor: "AAMP of America"},
  {max_prefix: "00-25-E9",vendor: "i-mate Development, Inc."},
  {max_prefix: "00-25-E6",vendor: "Belgian Monitoring Systems bvba"},
  {max_prefix: "00-25-E0",vendor: "CeedTec Sdn Bhd"},
  {max_prefix: "00-25-DE",vendor: "Probits Co., LTD."},
  {max_prefix: "00-25-B0",vendor: "Schmartz Inc"},
  {max_prefix: "00-25-AD",vendor: "Manufacturing Resources International"},
  {max_prefix: "00-25-AC",vendor: "I-Tech corporation"},
  {max_prefix: "00-25-AB",vendor: "AIO LCD PC BU / TPV"},
  {max_prefix: "00-25-BA",vendor: "Alcatel-Lucent IPD"},
  {max_prefix: "00-25-BB",vendor: "INNERINT Co., Ltd."},
  {max_prefix: "00-25-B1",vendor: "Maya-Creation Corporation"},
  {max_prefix: "00-25-B8",vendor: "Agile Communications, Inc."},
  {max_prefix: "00-25-CE",vendor: "InnerSpace"},
  {max_prefix: "00-25-C6",vendor: "kasercorp, ltd"},
  {max_prefix: "00-25-C5",vendor: "Star Link Communication Pvt. Ltd."},
  {max_prefix: "00-25-C7",vendor: "altek Corporation"},
  {max_prefix: "00-25-FF",vendor: "CreNova Multimedia Co., Ltd"},
  {max_prefix: "00-25-F3",vendor: "Nordwestdeutsche Z\u00e4hlerrevision"},
  {max_prefix: "00-25-EC",vendor: "Humanware"},
  {max_prefix: "00-25-ED",vendor: "NuVo Technologies LLC"},
  {max_prefix: "00-25-A1",vendor: "Enalasys"},
  {max_prefix: "00-25-9A",vendor: "CEStronics GmbH"},
  {max_prefix: "00-25-91",vendor: "NEXTEK, Inc."},
  {max_prefix: "00-25-8D",vendor: "Haier"},
  {max_prefix: "00-25-DD",vendor: "SUNNYTEK INFORMATION CO., LTD."},
  {max_prefix: "00-25-37",vendor: "Runcom Technologies Ltd."},
  {max_prefix: "00-25-38",vendor: "Samsung Electronics Co., Ltd., Memory Division"},
  {max_prefix: "00-25-44",vendor: "LoJack Corporation"},
  {max_prefix: "00-25-4A",vendor: "RingCube Technologies, Inc."},
  {max_prefix: "00-25-4F",vendor: "ELETTROLAB Srl"},
  {max_prefix: "00-25-49",vendor: "Jeorich Tech. Co.,Ltd."},
  {max_prefix: "00-25-39",vendor: "IfTA GmbH"},
  {max_prefix: "00-25-1C",vendor: "EDT"},
  {max_prefix: "00-25-1A",vendor: "Psiber Data Systems Inc."},
  {max_prefix: "00-25-14",vendor: "PC Worth Int'l Co., Ltd."},
  {max_prefix: "00-25-32",vendor: "Digital Recorders"},
  {max_prefix: "00-25-71",vendor: "Zhejiang Tianle Digital Electric Co.,Ltd"},
  {max_prefix: "00-25-83",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-25-5D",vendor: "Morningstar Corporation"},
  {max_prefix: "00-25-58",vendor: "MPEDIA"},
  {max_prefix: "00-24-F8",vendor: "Technical Solutions Company Ltd."},
  {max_prefix: "00-24-F9",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-24-F2",vendor: "Uniphone Telecommunication Co., Ltd."},
  {max_prefix: "00-25-07",vendor: "ASTAK Inc."},
  {max_prefix: "00-25-02",vendor: "NaturalPoint"},
  {max_prefix: "00-25-0B",vendor: "CENTROFACTOR  INC"},
  {max_prefix: "00-25-06",vendor: "A.I. ANTITACCHEGGIO ITALIA SRL"},
  {max_prefix: "00-24-B5",vendor: "Nortel Networks"},
  {max_prefix: "00-24-B0",vendor: "ESAB AB"},
  {max_prefix: "00-24-E1",vendor: "Convey Computer Corp."},
  {max_prefix: "00-24-D9",vendor: "BICOM, Inc."},
  {max_prefix: "00-24-EE",vendor: "Wynmax Inc."},
  {max_prefix: "00-24-E3",vendor: "CAO Group"},
  {max_prefix: "00-24-C3",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-24-C4",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-24-69",vendor: "Fasttel - Smart Doorphones"},
  {max_prefix: "00-24-64",vendor: "Bridge Technologies Co AS"},
  {max_prefix: "00-24-62",vendor: "Rayzone Corporation"},
  {max_prefix: "00-24-94",vendor: "Shenzhen Baoxin Tech CO., Ltd."},
  {max_prefix: "00-24-89",vendor: "Vodafone Omnitel N.V."},
  {max_prefix: "00-24-7F",vendor: "Nortel Networks"},
  {max_prefix: "00-24-75",vendor: "Compass System(Embedded Dept.)"},
  {max_prefix: "00-24-6F",vendor: "Onda Communication spa"},
  {max_prefix: "00-24-20",vendor: "NetUP Inc."},
  {max_prefix: "00-24-1E",vendor: "Nintendo Co., Ltd."},
  {max_prefix: "00-24-1F",vendor: "DCT-Delta GmbH"},
  {max_prefix: "00-24-60",vendor: "Giaval Science Development Co. Ltd."},
  {max_prefix: "00-24-5C",vendor: "Design-Com Technologies Pty. Ltd."},
  {max_prefix: "00-24-44",vendor: "Nintendo Co., Ltd."},
  {max_prefix: "00-24-3D",vendor: "Emerson Appliance Motors and Controls"},
  {max_prefix: "00-24-37",vendor: "Motorola - BSG"},
  {max_prefix: "00-24-9D",vendor: "NES Technology Inc."},
  {max_prefix: "00-24-8E",vendor: "Infoware ZRt."},
  {max_prefix: "00-24-4F",vendor: "Asantron Technologies Ltd."},
  {max_prefix: "00-23-FD",vendor: "AFT Atlas Fahrzeugtechnik GmbH"},
  {max_prefix: "00-23-E3",vendor: "Microtronic AG"},
  {max_prefix: "00-23-DD",vendor: "ELGIN S.A."},
  {max_prefix: "00-23-DE",vendor: "Ansync Inc."},
  {max_prefix: "00-23-D9",vendor: "Banner Engineering"},
  {max_prefix: "00-23-DA",vendor: "Industrial Computer Source (Deutschland)GmbH"},
  {max_prefix: "00-24-13",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-24-0E",vendor: "Inventec Besta Co., Ltd."},
  {max_prefix: "00-24-05",vendor: "Dilog Nordic AB"},
  {max_prefix: "00-23-EF",vendor: "Zuend Systemtechnik AG"},
  {max_prefix: "00-23-E8",vendor: "Demco Corp."},
  {max_prefix: "00-23-AC",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-23-B7",vendor: "Q-Light Co., Ltd."},
  {max_prefix: "00-23-D8",vendor: "Ball-It Oy"},
  {max_prefix: "00-23-1B",vendor: "Danaher Motion - Kollmorgen"},
  {max_prefix: "00-23-1E",vendor: "Cezzer Multimedia Technologies"},
  {max_prefix: "00-23-1F",vendor: "Guangda Electronic & Telecommunication Technology Development Co., Ltd."},
  {max_prefix: "00-22-70",vendor: "ABK North America, LLC"},
  {max_prefix: "00-23-13",vendor: "Qool Technologies Ltd."},
  {max_prefix: "00-23-10",vendor: "LNC Technology Co., Ltd."},
  {max_prefix: "00-23-5B",vendor: "Gulfstream"},
  {max_prefix: "00-23-59",vendor: "Benchmark Electronics ( Thailand ) Public Company Limited"},
  {max_prefix: "00-23-57",vendor: "Pitronot Technologies and Engineering P.T.E. Ltd."},
  {max_prefix: "00-23-55",vendor: "Kinco Automation(Shanghai) Ltd."},
  {max_prefix: "00-23-73",vendor: "GridIron Systems, Inc."},
  {max_prefix: "00-23-67",vendor: "UniControls a.s."},
  {max_prefix: "00-23-68",vendor: "Zebra Technologies Inc"},
  {max_prefix: "00-23-6E",vendor: "Burster GmbH & Co KG"},
  {max_prefix: "00-23-66",vendor: "Beijing Siasun Electronic System Co.,Ltd."},
  {max_prefix: "00-23-9F",vendor: "Institut f\u00fcr Pr\u00fcftechnik"},
  {max_prefix: "00-23-9D",vendor: "Mapower Electronics Co., Ltd"},
  {max_prefix: "00-23-98",vendor: "Vutlan sro"},
  {max_prefix: "00-23-8F",vendor: "NIDEC COPAL CORPORATION"},
  {max_prefix: "00-23-80",vendor: "Nanoteq"},
  {max_prefix: "00-23-34",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-23-3F",vendor: "Purechoice Inc"},
  {max_prefix: "00-22-CB",vendor: "IONODES Inc."},
  {max_prefix: "00-22-C6",vendor: "Sutus Inc"},
  {max_prefix: "00-22-C0",vendor: "Shenzhen Forcelink Electronic Co, Ltd"},
  {max_prefix: "00-22-96",vendor: "LinoWave Corporation"},
  {max_prefix: "00-22-CD",vendor: "Ared Technology Co., Ltd."},
  {max_prefix: "00-22-CC",vendor: "SciLog, Inc."},
  {max_prefix: "00-22-EB",vendor: "Data Respons A/S"},
  {max_prefix: "00-22-EF",vendor: "iWDL Technologies"},
  {max_prefix: "00-22-F2",vendor: "SunPower Corp"},
  {max_prefix: "00-22-E8",vendor: "Applition Co., Ltd."},
  {max_prefix: "00-22-E9",vendor: "ProVision Communications"},
  {max_prefix: "00-22-78",vendor: "Shenzhen  Tongfang Multimedia  Technology Co.,Ltd."},
  {max_prefix: "00-22-7A",vendor: "Telecom Design"},
  {max_prefix: "00-22-A7",vendor: "Tyco Electronics AMP GmbH"},
  {max_prefix: "00-22-A1",vendor: "Huawei Symantec Technologies Co.,Ltd."},
  {max_prefix: "00-22-9D",vendor: "PYUNG-HWA IND.CO.,LTD"},
  {max_prefix: "00-23-0C",vendor: "CLOVER ELECTRONICS CO.,LTD."},
  {max_prefix: "00-23-01",vendor: "Witron Technology Limited"},
  {max_prefix: "00-22-F7",vendor: "Conceptronic"},
  {max_prefix: "00-22-E6",vendor: "Intelligent Data"},
  {max_prefix: "00-22-E3",vendor: "Amerigon"},
  {max_prefix: "00-22-E2",vendor: "WABTEC Transit Division"},
  {max_prefix: "00-22-A6",vendor: "Sony Computer Entertainment America"},
  {max_prefix: "00-22-20",vendor: "Mitac Technology Corp"},
  {max_prefix: "00-22-27",vendor: "uv-electronic GmbH"},
  {max_prefix: "00-22-25",vendor: "Thales Avionics Ltd"},
  {max_prefix: "00-22-1E",vendor: "Media Devices Co., Ltd."},
  {max_prefix: "00-22-37",vendor: "Shinhint Group"},
  {max_prefix: "00-22-2F",vendor: "Open Grid Computing, Inc."},
  {max_prefix: "00-22-44",vendor: "Chengdu Linkon Communications Device Co., Ltd"},
  {max_prefix: "00-22-50",vendor: "Point Six Wireless, LLC"},
  {max_prefix: "00-22-6F",vendor: "3onedata Technology Co. Ltd."},
  {max_prefix: "00-22-60",vendor: "AFREEY Inc."},
  {max_prefix: "00-21-F6",vendor: "Oracle Corporation"},
  {max_prefix: "00-22-06",vendor: "Cyberdyne Inc."},
  {max_prefix: "00-22-02",vendor: "Excito Elektronik i Sk\u00e5ne AB"},
  {max_prefix: "00-22-0E",vendor: "Indigo Security Co., Ltd."},
  {max_prefix: "00-22-07",vendor: "Inteno Broadband Technology AB"},
  {max_prefix: "00-22-3E",vendor: "IRTrans GmbH"},
  {max_prefix: "00-21-CE",vendor: "NTC-Metrotek"},
  {max_prefix: "00-21-CA",vendor: "ART System Co., Ltd."},
  {max_prefix: "00-21-CB",vendor: "SMS TECNOLOGIA ELETRONICA LTDA"},
  {max_prefix: "00-21-C8",vendor: "LOHUIS Networks"},
  {max_prefix: "00-21-BF",vendor: "Hitachi High-Tech Control Systems Corporation"},
  {max_prefix: "00-21-EF",vendor: "Kapsys"},
  {max_prefix: "00-21-ED",vendor: "Telegesis"},
  {max_prefix: "00-21-EB",vendor: "ESP SYSTEMS, LLC"},
  {max_prefix: "00-21-9F",vendor: "SATEL OY"},
  {max_prefix: "00-21-96",vendor: "Telsey  S.p.A."},
  {max_prefix: "00-21-8D",vendor: "AP Router Ind. Eletronica LTDA"},
  {max_prefix: "00-21-90",vendor: "Goliath Solutions"},
  {max_prefix: "00-21-74",vendor: "AvaLAN Wireless"},
  {max_prefix: "00-21-79",vendor: "IOGEAR, Inc."},
  {max_prefix: "00-21-DB",vendor: "Santachi Video Technology (Shenzhen) Co., Ltd."},
  {max_prefix: "00-21-DF",vendor: "Martin Christ GmbH"},
  {max_prefix: "00-21-D4",vendor: "Vollmer Werke GmbH"},
  {max_prefix: "00-21-D6",vendor: "LXI Consortium"},
  {max_prefix: "00-21-A8",vendor: "Telephonics Corporation"},
  {max_prefix: "00-21-A9",vendor: "Mobilink Telecom Co.,Ltd"},
  {max_prefix: "00-21-A6",vendor: "Videotec Spa"},
  {max_prefix: "00-21-BC",vendor: "ZALA COMPUTER"},
  {max_prefix: "00-21-B4",vendor: "APRO MEDIA CO., LTD"},
  {max_prefix: "00-21-85",vendor: "MICRO-STAR INT'L CO.,LTD."},
  {max_prefix: "00-21-82",vendor: "SandLinks Systems, Ltd."},
  {max_prefix: "00-21-83",vendor: "ANDRITZ HYDRO GmbH"},
  {max_prefix: "00-21-68",vendor: "iVeia, LLC"},
  {max_prefix: "00-21-39",vendor: "Escherlogic Inc."},
  {max_prefix: "00-21-2C",vendor: "SemIndia System Private Limited"},
  {max_prefix: "00-21-2B",vendor: "MSA Auer"},
  {max_prefix: "00-21-3B",vendor: "Berkshire Products, Inc"},
  {max_prefix: "00-21-37",vendor: "Bay Controls, LLC"},
  {max_prefix: "00-21-5E",vendor: "IBM Corp"},
  {max_prefix: "00-21-55",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-21-57",vendor: "National Datacast, Inc."},
  {max_prefix: "00-21-0C",vendor: "Cymtec Systems, Inc."},
  {max_prefix: "00-21-0B",vendor: "GEMINI TRAZE RFID PVT. LTD."},
  {max_prefix: "00-21-04",vendor: "Gigaset Communications GmbH"},
  {max_prefix: "00-1F-FB",vendor: "Green Packet Bhd"},
  {max_prefix: "00-21-2A",vendor: "Audiovox Corporation"},
  {max_prefix: "00-21-4D",vendor: "Guangzhou Skytone Transmission Technology Com. Ltd."},
  {max_prefix: "00-21-50",vendor: "EYEVIEW ELECTRONICS"},
  {max_prefix: "00-1F-F0",vendor: "Audio Partnership"},
  {max_prefix: "00-1F-EA",vendor: "Applied Media Technologies Corporation"},
  {max_prefix: "00-21-10",vendor: "Clearbox Systems"},
  {max_prefix: "00-1F-8B",vendor: "Cache IQ"},
  {max_prefix: "00-1F-85",vendor: "Apriva ISS, LLC"},
  {max_prefix: "00-1F-87",vendor: "Skydigital Inc."},
  {max_prefix: "00-1F-88",vendor: "FMS Force Measuring Systems AG"},
  {max_prefix: "00-1F-86",vendor: "digEcor"},
  {max_prefix: "00-1F-80",vendor: "Lucas Holding bv"},
  {max_prefix: "00-1F-A5",vendor: "Blue-White Industries"},
  {max_prefix: "00-1F-9D",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-1F-9F",vendor: "Thomson Telecom Belgium"},
  {max_prefix: "00-1F-A1",vendor: "Gtran Inc"},
  {max_prefix: "00-1F-E9",vendor: "Printrex, Inc."},
  {max_prefix: "00-1F-DD",vendor: "GDI LLC"},
  {max_prefix: "00-1F-CE",vendor: "QTECH LLC"},
  {max_prefix: "00-1B-58",vendor: "ACE CAD Enterprise Co., Ltd."},
  {max_prefix: "00-1F-79",vendor: "Lodam Electronics A/S"},
  {max_prefix: "00-1F-71",vendor: "xG Technology, Inc."},
  {max_prefix: "00-1F-DA",vendor: "Nortel Networks"},
  {max_prefix: "00-1F-D9",vendor: "RSD Communications Ltd"},
  {max_prefix: "00-1F-B0",vendor: "TimeIPS, Inc."},
  {max_prefix: "00-1F-AE",vendor: "Blick South Africa (Pty) Ltd"},
  {max_prefix: "00-1F-99",vendor: "SERONICS co.ltd"},
  {max_prefix: "00-1F-96",vendor: "APROTECH CO.LTD"},
  {max_prefix: "00-1F-6C",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-1F-6F",vendor: "Fujian Sunnada Communication Co.,Ltd."},
  {max_prefix: "00-1F-60",vendor: "COMPASS SYSTEMS CORP."},
  {max_prefix: "00-1F-6A",vendor: "PacketFlux Technologies, Inc."},
  {max_prefix: "00-1F-65",vendor: "KOREA ELECTRIC TERMINAL CO., LTD."},
  {max_prefix: "00-1F-5E",vendor: "Dyna Technology Co.,Ltd."},
  {max_prefix: "00-1E-E8",vendor: "Mytek"},
  {max_prefix: "00-1E-EE",vendor: "ETL Systems Ltd"},
  {max_prefix: "00-1E-E0",vendor: "Urmet SpA"},
  {max_prefix: "00-1E-DA",vendor: "Wesemann Elektrotechniek B.V."},
  {max_prefix: "00-1F-17",vendor: "IDX Company, Ltd."},
  {max_prefix: "00-1F-55",vendor: "Honeywell Security (China) Co., Ltd."},
  {max_prefix: "00-1F-54",vendor: "Lorex Technology Inc."},
  {max_prefix: "00-1F-4B",vendor: "Lineage Power"},
  {max_prefix: "00-1F-40",vendor: "Speakercraft Inc."},
  {max_prefix: "00-1E-FC",vendor: "JSC MASSA-K"},
  {max_prefix: "00-1F-06",vendor: "Integrated Dispatch Solutions"},
  {max_prefix: "00-1E-FA",vendor: "PROTEI Ltd."},
  {max_prefix: "00-1E-FB",vendor: "Trio Motion Technology Ltd"},
  {max_prefix: "00-1E-F8",vendor: "Emfinity Inc."},
  {max_prefix: "00-1F-1B",vendor: "RoyalTek Company Ltd."},
  {max_prefix: "00-1F-0D",vendor: "L3 Communications - Telemetry West"},
  {max_prefix: "00-1F-2E",vendor: "Triangle Research Int'l Pte Ltd"},
  {max_prefix: "00-1E-CB",vendor: "RPC Energoautomatika Ltd"},
  {max_prefix: "00-1E-BA",vendor: "High Density Devices AS"},
  {max_prefix: "00-1E-9F",vendor: "Visioneering Systems, Inc."},
  {max_prefix: "00-1E-9B",vendor: "San-Eisha, Ltd."},
  {max_prefix: "00-1E-D7",vendor: "H-Stream Wireless, Inc."},
  {max_prefix: "00-1E-D5",vendor: "Tekon-Automatics"},
  {max_prefix: "00-1E-B4",vendor: "UNIFAT TECHNOLOGY LTD."},
  {max_prefix: "00-1E-A8",vendor: "Datang Mobile Communications Equipment CO.,LTD"},
  {max_prefix: "00-1E-AB",vendor: "TeleWell Oy"},
  {max_prefix: "00-1E-61",vendor: "ITEC GmbH"},
  {max_prefix: "00-1E-59",vendor: "Silicon Turnkey Express, LLC"},
  {max_prefix: "00-1E-71",vendor: "MIrcom Group of Companies"},
  {max_prefix: "00-1E-6B",vendor: "Cisco SPVTG"},
  {max_prefix: "00-1E-70",vendor: "Chelton Limited"},
  {max_prefix: "00-1E-51",vendor: "Converter Industry Srl"},
  {max_prefix: "00-1E-47",vendor: "PT. Hariff Daya Tunggal Engineering"},
  {max_prefix: "00-1E-48",vendor: "Wi-Links"},
  {max_prefix: "00-1E-8A",vendor: "eCopy, Inc"},
  {max_prefix: "00-1E-1B",vendor: "Digital Stream Technology, Inc."},
  {max_prefix: "00-1E-17",vendor: "STN BV"},
  {max_prefix: "00-1E-18",vendor: "Radio Activity srl"},
  {max_prefix: "00-1E-15",vendor: "Beech Hill Electronics"},
  {max_prefix: "00-1D-C4",vendor: "AIOI Systems Co., Ltd."},
  {max_prefix: "00-1D-C0",vendor: "Enphase Energy"},
  {max_prefix: "00-1D-BD",vendor: "Versamed Inc."},
  {max_prefix: "00-1D-F8",vendor: "Webpro Vision Technology Corporation"},
  {max_prefix: "00-1D-F9",vendor: "Cybiotronics (Far East) Limited"},
  {max_prefix: "00-1D-F7",vendor: "R. STAHL Schaltger\u00e4te GmbH"},
  {max_prefix: "00-1E-05",vendor: "Xseed Technologies & Computing"},
  {max_prefix: "00-1E-07",vendor: "Winy Technology Co., Ltd."},
  {max_prefix: "00-1E-0A",vendor: "Syba Tech Limited"},
  {max_prefix: "00-1E-03",vendor: "LiComm Co., Ltd."},
  {max_prefix: "00-1E-38",vendor: "Bluecard Software Technology Co., Ltd."},
  {max_prefix: "00-1E-30",vendor: "Shireen Inc"},
  {max_prefix: "00-1E-2E",vendor: "SIRTI S.p.A."},
  {max_prefix: "00-1D-EB",vendor: "DINEC International"},
  {max_prefix: "00-1D-DC",vendor: "HangZhou DeChangLong Tech&Info Co.,Ltd"},
  {max_prefix: "00-1E-3E",vendor: "KMW Inc."},
  {max_prefix: "00-1D-B7",vendor: "Tendril Networks, Inc."},
  {max_prefix: "00-1D-9D",vendor: "ARTJOY INTERNATIONAL LIMITED"},
  {max_prefix: "00-1D-9E",vendor: "AXION TECHNOLOGIES"},
  {max_prefix: "00-1D-9A",vendor: "GODEX INTERNATIONAL CO., LTD"},
  {max_prefix: "00-1D-97",vendor: "Alertus Technologies LLC"},
  {max_prefix: "00-1D-43",vendor: "Shenzhen G-link Digital Technology Co., Ltd."},
  {max_prefix: "00-1D-3F",vendor: "Mitron Pty Ltd"},
  {max_prefix: "00-1D-39",vendor: "MOOHADIGITAL CO., LTD"},
  {max_prefix: "00-1D-37",vendor: "Thales-Panda Transportation System"},
  {max_prefix: "00-1D-30",vendor: "YX Wireless S.A."},
  {max_prefix: "00-1D-7F",vendor: "Tekron International Ltd"},
  {max_prefix: "00-1D-70",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-1D-79",vendor: "SIGNAMAX LLC"},
  {max_prefix: "00-1D-6F",vendor: "Chainzone Technology Co., Ltd"},
  {max_prefix: "00-1D-78",vendor: "Invengo Information Technology Co.,Ltd"},
  {max_prefix: "00-1D-62",vendor: "InPhase Technologies"},
  {max_prefix: "00-1D-63",vendor: "Miele & Cie. KG"},
  {max_prefix: "00-1D-AE",vendor: "CHANG TSENG TECHNOLOGY CO., LTD"},
  {max_prefix: "00-1D-A6",vendor: "Media Numerics Limited"},
  {max_prefix: "00-1D-91",vendor: "Digitize, Inc"},
  {max_prefix: "00-1D-95",vendor: "Flash, Inc."},
  {max_prefix: "00-1D-8D",vendor: "Fluke Process Instruments GmbH"},
  {max_prefix: "00-1D-13",vendor: "NextGTV"},
  {max_prefix: "00-1D-10",vendor: "LightHaus Logic, Inc."},
  {max_prefix: "00-1D-14",vendor: "SPERADTONE INFORMATION TECHNOLOGY LIMITED"},
  {max_prefix: "00-1C-BB",vendor: "MusicianLink"},
  {max_prefix: "00-1C-B2",vendor: "BPT SPA"},
  {max_prefix: "00-1C-B5",vendor: "Neihua Network Technology Co.,LTD.(NHN)"},
  {max_prefix: "00-1C-B4",vendor: "Iridium Satellite LLC"},
  {max_prefix: "00-1C-B6",vendor: "Duzon CNT Co., Ltd."},
  {max_prefix: "00-1D-1F",vendor: "Siauliu Tauro Televizoriai, JSC"},
  {max_prefix: "00-1C-CD",vendor: "Alektrona Corporation"},
  {max_prefix: "00-1C-C7",vendor: "Rembrandt Technologies, LLC d/b/a REMSTREAM"},
  {max_prefix: "00-1D-04",vendor: "Zipit Wireless, Inc."},
  {max_prefix: "00-1C-D0",vendor: "Circleone Co.,Ltd."},
  {max_prefix: "00-1C-9F",vendor: "Razorstream, LLC"},
  {max_prefix: "00-1C-EC",vendor: "Mobilesoft (Aust.) Pty Ltd"},
  {max_prefix: "00-1C-E8",vendor: "Cummins Inc"},
  {max_prefix: "00-1C-F2",vendor: "Tenlon Technology Co.,Ltd."},
  {max_prefix: "00-1C-1F",vendor: "Quest Retail Technology Pty Ltd"},
  {max_prefix: "00-1C-16",vendor: "ThyssenKrupp Elevator"},
  {max_prefix: "00-1C-19",vendor: "secunet Security Networks AG"},
  {max_prefix: "00-1C-47",vendor: "Hangzhou Hollysys Automation Co., Ltd"},
  {max_prefix: "00-1C-49",vendor: "Zoltan Technology Inc."},
  {max_prefix: "00-1C-3F",vendor: "International Police Technologies, Inc."},
  {max_prefix: "00-1C-3B",vendor: "AmRoad Technology Inc."},
  {max_prefix: "00-1C-32",vendor: "Telian Corporation"},
  {max_prefix: "00-1C-63",vendor: "TRUEN"},
  {max_prefix: "00-1C-5C",vendor: "Integrated Medical Systems, Inc."},
  {max_prefix: "00-1C-52",vendor: "VISIONEE SRL"},
  {max_prefix: "00-1C-8D",vendor: "Mesa Imaging"},
  {max_prefix: "00-1C-89",vendor: "Force Communications, Inc."},
  {max_prefix: "00-1C-87",vendor: "Uriver Inc."},
  {max_prefix: "00-1C-7D",vendor: "Excelpoint Manufacturing Pte Ltd"},
  {max_prefix: "00-1C-77",vendor: "Prodys"},
  {max_prefix: "00-1C-6F",vendor: "Emfit Ltd"},
  {max_prefix: "00-1C-6C",vendor: "30805"},
  {max_prefix: "00-1C-61",vendor: "Galaxy  Microsystems LImited"},
  {max_prefix: "00-1C-28",vendor: "Sphairon Technologies GmbH"},
  {max_prefix: "00-1C-09",vendor: "SAE Electronic Co.,Ltd."},
  {max_prefix: "00-1C-0C",vendor: "TANITA Corporation"},
  {max_prefix: "00-1B-9B",vendor: "Hose-McCann Communications"},
  {max_prefix: "00-1B-9C",vendor: "SATEL sp. z o.o."},
  {max_prefix: "00-1B-92",vendor: "l-acoustics"},
  {max_prefix: "00-1B-8E",vendor: "Hulu Sweden AB"},
  {max_prefix: "00-1B-E8",vendor: "Ultratronik GmbH"},
  {max_prefix: "00-1B-E1",vendor: "ViaLogy"},
  {max_prefix: "00-1B-DF",vendor: "Iskra Sistemi d.d."},
  {max_prefix: "00-1B-EC",vendor: "Netio Technologies Co., Ltd"},
  {max_prefix: "00-1B-B4",vendor: "Airvod Limited"},
  {max_prefix: "00-1B-B6",vendor: "Bird Electronic Corp."},
  {max_prefix: "00-1B-A6",vendor: "intotech inc."},
  {max_prefix: "00-1B-8A",vendor: "2M Electronic A/S"},
  {max_prefix: "00-1B-80",vendor: "LORD Corporation"},
  {max_prefix: "00-1B-76",vendor: "Ripcode, Inc."},
  {max_prefix: "00-1B-70",vendor: "IRI Ubiteq, INC."},
  {max_prefix: "00-1B-C7",vendor: "StarVedia Technology Inc."},
  {max_prefix: "00-1B-A4",vendor: "S.A.E Afikim"},
  {max_prefix: "00-1B-93",vendor: "JC Decaux SA DNT"},
  {max_prefix: "00-1B-D9",vendor: "Edgewater Wireless Systems Inc"},
  {max_prefix: "00-1B-07",vendor: "Mendocino Software"},
  {max_prefix: "00-1B-08",vendor: "Danfoss Drives A/S"},
  {max_prefix: "00-1B-01",vendor: "Applied Radio Technologies"},
  {max_prefix: "00-1B-02",vendor: "ED Co.Ltd"},
  {max_prefix: "00-1A-FC",vendor: "ModusLink Corporation"},
  {max_prefix: "00-1B-6C",vendor: "LookX Digital Media BV"},
  {max_prefix: "00-1B-6B",vendor: "Swyx Solutions AG"},
  {max_prefix: "00-1B-69",vendor: "Equaline Corporation"},
  {max_prefix: "00-1B-68",vendor: "Modnnet Co., Ltd"},
  {max_prefix: "00-1B-62",vendor: "JHT Optoelectronics Co.,Ltd."},
  {max_prefix: "00-1B-3F",vendor: "ProCurve Networking by HP"},
  {max_prefix: "00-1B-41",vendor: "General Infinity Co.,Ltd."},
  {max_prefix: "00-1B-3E",vendor: "Curtis, Inc."},
  {max_prefix: "00-1B-37",vendor: "Computec Oy"},
  {max_prefix: "00-1B-10",vendor: "ShenZhen Kang Hui Technology Co.,ltd"},
  {max_prefix: "00-1B-0B",vendor: "Phidgets Inc."},
  {max_prefix: "00-1B-0C",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-1B-23",vendor: "SimpleComTools"},
  {max_prefix: "00-1B-18",vendor: "Tsuken Electric Ind. Co.,Ltd"},
  {max_prefix: "00-1A-F6",vendor: "Woven Systems, Inc."},
  {max_prefix: "00-1A-F9",vendor: "AeroVIronment (AV Inc)"},
  {max_prefix: "00-1A-E0",vendor: "Mythology Tech Express Inc."},
  {max_prefix: "00-1B-50",vendor: "Nizhny Novgorod Factory named after M.Frunze, FSUE (NZiF)"},
  {max_prefix: "00-1B-30",vendor: "Solitech Inc."},
  {max_prefix: "00-1B-47",vendor: "Futarque A/S"},
  {max_prefix: "00-1B-45",vendor: "ABB AS, Division Automation Products"},
  {max_prefix: "00-1A-BE",vendor: "COMPUTER HI-TECH INC."},
  {max_prefix: "00-1A-C1",vendor: "3Com Ltd"},
  {max_prefix: "00-1A-BB",vendor: "Fontal Technology Incorporation"},
  {max_prefix: "00-1A-BD",vendor: "Impatica Inc."},
  {max_prefix: "00-1A-AE",vendor: "Savant Systems LLC"},
  {max_prefix: "00-1A-58",vendor: "CCV Deutschland GmbH - Celectronic eHealth Div."},
  {max_prefix: "00-1A-5E",vendor: "Thincom Technology Co.,Ltd"},
  {max_prefix: "00-1A-87",vendor: "Canhold International Limited"},
  {max_prefix: "00-1A-88",vendor: "Venergy,Co,Ltd"},
  {max_prefix: "00-1A-74",vendor: "Procare International Co"},
  {max_prefix: "00-1A-B4",vendor: "FFEI Ltd."},
  {max_prefix: "00-1A-B5",vendor: "Home Network System"},
  {max_prefix: "00-1A-79",vendor: "TELECOMUNICATION TECHNOLOGIES LTD."},
  {max_prefix: "00-1A-AA",vendor: "Analogic Corp."},
  {max_prefix: "00-1A-8B",vendor: "CHUNIL ELECTRIC IND., CO."},
  {max_prefix: "00-1A-8D",vendor: "AVECS Bergen GmbH"},
  {max_prefix: "00-1A-95",vendor: "Hisense Mobile Communications Technoligy Co.,Ltd."},
  {max_prefix: "00-1A-81",vendor: "Zelax"},
  {max_prefix: "00-1A-D7",vendor: "Christie Digital Systems, Inc."},
  {max_prefix: "00-1A-CD",vendor: "Tidel Engineering LP"},
  {max_prefix: "00-1A-E2",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-1A-E7",vendor: "Aztek Networks, Inc."},
  {max_prefix: "00-1A-C9",vendor: "SUZUKEN CO.,LTD"},
  {max_prefix: "00-1A-A4",vendor: "Future University-Hakodate"},
  {max_prefix: "00-1A-9F",vendor: "A-Link Ltd"},
  {max_prefix: "00-1A-24",vendor: "Galaxy Telecom Technologies Ltd"},
  {max_prefix: "00-1A-20",vendor: "CMOTECH Co. Ltd."},
  {max_prefix: "00-1A-19",vendor: "Computer Engineering Limited"},
  {max_prefix: "00-1A-18",vendor: "Advanced Simulation Technology inc."},
  {max_prefix: "00-1A-5C",vendor: "Euchner GmbH+Co. KG"},
  {max_prefix: "00-1A-5B",vendor: "NetCare Service Co., Ltd."},
  {max_prefix: "00-1A-4E",vendor: "NTI AG / LinMot"},
  {max_prefix: "00-1A-52",vendor: "Meshlinx Wireless Inc."},
  {max_prefix: "00-1A-13",vendor: "Wanlida Group Co., LTD"},
  {max_prefix: "00-1A-0F",vendor: "ARTECHE GROUP"},
  {max_prefix: "00-1A-43",vendor: "Logical Link Communications"},
  {max_prefix: "00-1A-47",vendor: "Agami Systems, Inc."},
  {max_prefix: "00-1A-03",vendor: "Angel Electronics Co., Ltd."},
  {max_prefix: "00-19-F9",vendor: "TDK-Lambda"},
  {max_prefix: "00-19-F2",vendor: "Teradyne K.K."},
  {max_prefix: "00-1A-2F",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-1A-2D",vendor: "The Navvo Group"},
  {max_prefix: "00-19-8D",vendor: "Ocean Optics, Inc."},
  {max_prefix: "00-19-85",vendor: "IT Watchdogs, Inc"},
  {max_prefix: "00-19-86",vendor: "Cheng Hongjian"},
  {max_prefix: "00-19-A9",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-19-AE",vendor: "Hopling Technologies b.v."},
  {max_prefix: "00-19-AF",vendor: "Rigol Technologies, Inc."},
  {max_prefix: "00-19-A7",vendor: "ITU-T"},
  {max_prefix: "00-19-DE",vendor: "MOBITEK"},
  {max_prefix: "00-19-E5",vendor: "Lynx Studio Technology, Inc."},
  {max_prefix: "00-19-DB",vendor: "MICRO-STAR INTERNATIONAL CO., LTD."},
  {max_prefix: "00-19-9E",vendor: "Nifty"},
  {max_prefix: "00-19-6A",vendor: "MikroM GmbH"},
  {max_prefix: "00-19-CE",vendor: "Progressive Gaming International"},
  {max_prefix: "00-19-BD",vendor: "New Media Life"},
  {max_prefix: "00-19-6B",vendor: "Danpex Corporation"},
  {max_prefix: "00-19-9F",vendor: "DKT A/S"},
  {max_prefix: "00-18-FF",vendor: "PowerQuattro Co."},
  {max_prefix: "00-18-F4",vendor: "EO TECHNICS Co., Ltd."},
  {max_prefix: "00-18-F6",vendor: "Thomson Telecom Belgium"},
  {max_prefix: "00-18-FC",vendor: "Altec Electronic AG"},
  {max_prefix: "00-18-F5",vendor: "Shenzhen Streaming Video Technology Company Limited"},
  {max_prefix: "00-18-F9",vendor: "VVOND, Inc."},
  {max_prefix: "00-19-3B",vendor: "LigoWave"},
  {max_prefix: "00-19-35",vendor: "DUERR DENTAL AG"},
  {max_prefix: "00-19-32",vendor: "Gude Systems GmbH"},
  {max_prefix: "00-19-31",vendor: "Balluff GmbH"},
  {max_prefix: "00-19-14",vendor: "Winix Co., Ltd"},
  {max_prefix: "00-19-01",vendor: "F1MEDIA"},
  {max_prefix: "00-19-06",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-19-3D",vendor: "GMC Guardian Mobility Corp."},
  {max_prefix: "00-19-10",vendor: "Knick Elektronische Messgeraete GmbH & Co. KG"},
  {max_prefix: "00-19-13",vendor: "Chuang-Yi Network Equipment Co.Ltd."},
  {max_prefix: "00-19-51",vendor: "NETCONS, s.r.o."},
  {max_prefix: "00-18-FA",vendor: "Yushin Precision Equipment Co.,Ltd."},
  {max_prefix: "00-18-EA",vendor: "Alltec GmbH"},
  {max_prefix: "00-18-E8",vendor: "Hacetron Corporation"},
  {max_prefix: "00-19-57",vendor: "Saafnet Canada Inc."},
  {max_prefix: "00-19-56",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-19-58",vendor: "Bluetooth SIG, Inc."},
  {max_prefix: "00-18-E3",vendor: "Visualgate Systems, Inc."},
  {max_prefix: "00-18-99",vendor: "ShenZhen jieshun Science&Technology Industry CO,LTD."},
  {max_prefix: "00-18-9F",vendor: "Lenntek Corporation"},
  {max_prefix: "00-18-B7",vendor: "D3 LED, LLC"},
  {max_prefix: "00-18-C1",vendor: "Almitec Inform\u00e1tica e Com\u00e9rcio"},
  {max_prefix: "00-18-C4",vendor: "Raba Technologies LLC"},
  {max_prefix: "00-18-C9",vendor: "EOps Technology Limited"},
  {max_prefix: "00-18-BC",vendor: "ZAO NVP Bolid"},
  {max_prefix: "00-18-5D",vendor: "TAIGUEN TECHNOLOGY (SHEN-ZHEN) CO., LTD."},
  {max_prefix: "00-18-5E",vendor: "Nexterm Inc."},
  {max_prefix: "00-18-6D",vendor: "Zhenjiang Sapphire Electronic Industry CO."},
  {max_prefix: "00-18-6F",vendor: "Setha Industria Eletronica LTDA"},
  {max_prefix: "00-18-75",vendor: "AnaCise Testnology Pte Ltd"},
  {max_prefix: "00-18-D8",vendor: "ARCH METER Corporation"},
  {max_prefix: "00-18-D9",vendor: "Santosha Internatonal, Inc"},
  {max_prefix: "00-18-CF",vendor: "Baldor Electric Company"},
  {max_prefix: "00-18-8E",vendor: "Ekahau, Inc."},
  {max_prefix: "00-18-83",vendor: "FORMOSA21 INC."},
  {max_prefix: "00-18-95",vendor: "Hansun Technologies Inc."},
  {max_prefix: "00-18-4A",vendor: "Catcher, Inc."},
  {max_prefix: "00-18-4B",vendor: "Las Vegas Gaming, Inc."},
  {max_prefix: "00-17-BB",vendor: "Syrinx Industrial Electronics"},
  {max_prefix: "00-17-BC",vendor: "Touchtunes Music Corporation"},
  {max_prefix: "00-17-C1",vendor: "CM Precision Technology LTD."},
  {max_prefix: "00-17-B2",vendor: "SK Telesys"},
  {max_prefix: "00-17-B1",vendor: "ACIST Medical Systems, Inc."},
  {max_prefix: "00-17-E1",vendor: "DACOS Technologies Co., Ltd."},
  {max_prefix: "00-17-E0",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-17-D2",vendor: "THINLINX PTY LTD"},
  {max_prefix: "00-17-C7",vendor: "MARA Systems Consulting AB"},
  {max_prefix: "00-18-14",vendor: "Mitutoyo Corporation"},
  {max_prefix: "00-18-17",vendor: "D. E. Shaw Research, LLC"},
  {max_prefix: "00-18-11",vendor: "Neuros Technology International, LLC."},
  {max_prefix: "00-17-DE",vendor: "Advantage Six Ltd"},
  {max_prefix: "00-17-D7",vendor: "ION Geophysical Corporation Inc."},
  {max_prefix: "00-18-37",vendor: "Universal ABIT Co., Ltd."},
  {max_prefix: "00-18-22",vendor: "CEC TELECOM CO.,LTD."},
  {max_prefix: "00-18-20",vendor: "w5networks"},
  {max_prefix: "00-17-B8",vendor: "NOVATRON CO., LTD."},
  {max_prefix: "00-18-28",vendor: "e2v technologies (UK) ltd."},
  {max_prefix: "00-18-35",vendor: "Thoratec / ITC"},
  {max_prefix: "00-18-0E",vendor: "Avega Systems"},
  {max_prefix: "00-18-01",vendor: "Actiontec Electronics, Inc"},
  {max_prefix: "00-17-F3",vendor: "Harris Corporation"},
  {max_prefix: "00-17-50",vendor: "GSI Group, MicroE Systems"},
  {max_prefix: "00-17-55",vendor: "GE Security"},
  {max_prefix: "00-17-51",vendor: "Online Corporation"},
  {max_prefix: "00-17-A3",vendor: "MIX s.r.l."},
  {max_prefix: "00-17-A6",vendor: "YOSIN ELECTRONICS CO., LTD."},
  {max_prefix: "00-17-9C",vendor: "DEPRAG SCHULZ GMBH u. CO."},
  {max_prefix: "00-17-96",vendor: "Rittmeyer AG"},
  {max_prefix: "00-17-75",vendor: "TTE Germany GmbH"},
  {max_prefix: "00-17-76",vendor: "Meso Scale Diagnostics, LLC"},
  {max_prefix: "00-17-79",vendor: "QuickTel"},
  {max_prefix: "00-17-67",vendor: "Earforce AS"},
  {max_prefix: "00-17-7C",vendor: "Smartlink Network Systems Limited"},
  {max_prefix: "00-17-81",vendor: "Greystone Data System, Inc."},
  {max_prefix: "00-17-85",vendor: "Sparr Electronics Ltd"},
  {max_prefix: "00-17-2C",vendor: "TAEJIN INFOTECH"},
  {max_prefix: "00-17-4C",vendor: "Millipore"},
  {max_prefix: "00-17-45",vendor: "INNOTZ CO., Ltd"},
  {max_prefix: "00-17-48",vendor: "Neokoros Brasil Ltda"},
  {max_prefix: "00-17-8D",vendor: "Checkpoint Systems, Inc."},
  {max_prefix: "00-17-8E",vendor: "Gunnebo Cash Automation AB"},
  {max_prefix: "00-17-63",vendor: "Essentia S.p.A."},
  {max_prefix: "00-17-5D",vendor: "Dongseo system."},
  {max_prefix: "00-17-39",vendor: "Bright Headphone Electronics Company"},
  {max_prefix: "00-17-1D",vendor: "DIGIT"},
  {max_prefix: "00-17-18",vendor: "Vansco Electronics Oy"},
  {max_prefix: "00-17-19",vendor: "Audiocodes USA, Inc"},
  {max_prefix: "00-16-FF",vendor: "Wamin Optocomm Mfg Corp"},
  {max_prefix: "00-17-01",vendor: "KDE, Inc."},
  {max_prefix: "00-17-0E",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-17-05",vendor: "Methode Electronics"},
  {max_prefix: "00-16-DA",vendor: "Futronic Technology Co. Ltd."},
  {max_prefix: "00-16-D4",vendor: "Compal Communications, Inc."},
  {max_prefix: "00-16-D7",vendor: "Sunways AG"},
  {max_prefix: "00-16-D1",vendor: "ZAT a.s."},
  {max_prefix: "00-17-11",vendor: "Cytiva Sweden AB"},
  {max_prefix: "00-17-17",vendor: "Leica Geosystems AG"},
  {max_prefix: "00-17-15",vendor: "Qstik"},
  {max_prefix: "00-16-C5",vendor: "Shenzhen Xing Feng Industry Co.,Ltd"},
  {max_prefix: "00-16-CC",vendor: "Xcute Mobile Corp."},
  {max_prefix: "00-16-EE",vendor: "Royaldigital Inc."},
  {max_prefix: "00-16-ED",vendor: "Utility, Inc"},
  {max_prefix: "00-16-DE",vendor: "FAST Inc"},
  {max_prefix: "00-16-72",vendor: "Zenway enterprise ltd"},
  {max_prefix: "00-16-71",vendor: "Symphox Information Co."},
  {max_prefix: "00-16-69",vendor: "MRV Communication (Networks) LTD"},
  {max_prefix: "00-16-68",vendor: "Eishin Electronics"},
  {max_prefix: "00-16-55",vendor: "FUHO TECHNOLOGY Co., LTD"},
  {max_prefix: "00-16-46",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-16-48",vendor: "SSD Company Limited"},
  {max_prefix: "00-16-AA",vendor: "Kei Communication Technology Inc."},
  {max_prefix: "00-16-A8",vendor: "CWT CO., LTD."},
  {max_prefix: "00-16-A6",vendor: "Dovado FZ-LLC"},
  {max_prefix: "00-16-A2",vendor: "CentraLite Systems, Inc."},
  {max_prefix: "00-16-7D",vendor: "Sky-Line Information Co., Ltd."},
  {max_prefix: "00-16-77",vendor: "Bihl + Wiedemann GmbH"},
  {max_prefix: "00-16-5A",vendor: "Harman Specialty Group"},
  {max_prefix: "00-16-59",vendor: "Z.M.P. RADWAG"},
  {max_prefix: "00-16-C7",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-16-95",vendor: "AVC Technology (International) Limited"},
  {max_prefix: "00-15-DF",vendor: "Clivet S.p.A."},
  {max_prefix: "00-15-D8",vendor: "Interlink Electronics"},
  {max_prefix: "00-15-D2",vendor: "Xantech Corporation"},
  {max_prefix: "00-15-D4",vendor: "Emitor AB"},
  {max_prefix: "00-15-D6",vendor: "OSLiNK Sp. z o.o."},
  {max_prefix: "00-15-D5",vendor: "NICEVT"},
  {max_prefix: "00-15-F1",vendor: "KYLINK Communications Corp."},
  {max_prefix: "00-15-EA",vendor: "Tellumat (Pty) Ltd"},
  {max_prefix: "00-15-E2",vendor: "Dr.Ing. Herbert Knauer GmbH"},
  {max_prefix: "00-15-E1",vendor: "Picochip Ltd"},
  {max_prefix: "00-16-0C",vendor: "LPL  DEVELOPMENT S.A. DE C.V"},
  {max_prefix: "00-16-12",vendor: "Otsuka Electronics Co., Ltd."},
  {max_prefix: "00-16-0B",vendor: "TVWorks LLC"},
  {max_prefix: "00-16-03",vendor: "COOLKSKY Co., LTD"},
  {max_prefix: "00-16-09",vendor: "Unitech electronics co., ltd."},
  {max_prefix: "00-16-40",vendor: "Asmobile Communication Inc."},
  {max_prefix: "00-16-3C",vendor: "Rebox B.V."},
  {max_prefix: "00-16-19",vendor: "Lancelan Technologies S.L."},
  {max_prefix: "00-16-1D",vendor: "Innovative Wireless Technologies, Inc."},
  {max_prefix: "00-16-11",vendor: "Altecon Srl"},
  {max_prefix: "00-15-C7",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-16-25",vendor: "Impinj, Inc."},
  {max_prefix: "00-16-23",vendor: "Interval Media"},
  {max_prefix: "00-15-F5",vendor: "Sustainable Energy Systems"},
  {max_prefix: "00-15-8B",vendor: "Park Air Systems Ltd"},
  {max_prefix: "00-15-86",vendor: "Xiamen Overseas Chinese Electronic Co., Ltd."},
  {max_prefix: "00-15-7E",vendor: "Weidm\u00fcller Interface GmbH & Co. KG"},
  {max_prefix: "00-15-80",vendor: "U-WAY CORPORATION"},
  {max_prefix: "00-15-7C",vendor: "Dave Networks, Inc."},
  {max_prefix: "00-15-7F",vendor: "ChuanG International Holding CO.,LTD."},
  {max_prefix: "00-15-33",vendor: "NADAM.CO.,LTD"},
  {max_prefix: "00-15-37",vendor: "Ventus Networks"},
  {max_prefix: "00-15-34",vendor: "A Beltr\u00f3nica-Companhia de Comunica\u00e7\u00f5es, Lda"},
  {max_prefix: "00-15-9D",vendor: "Tripp Lite"},
  {max_prefix: "00-15-92",vendor: "Facom UK Ltd (Melksham)"},
  {max_prefix: "00-15-5C",vendor: "Dresser Wayne"},
  {max_prefix: "00-15-59",vendor: "Securaplane Technologies, Inc."},
  {max_prefix: "00-15-57",vendor: "Olivetti"},
  {max_prefix: "00-15-54",vendor: "Atalum Wireless S.A."},
  {max_prefix: "00-15-B3",vendor: "Caretech AB"},
  {max_prefix: "00-15-AA",vendor: "Rextechnik International Co.,"},
  {max_prefix: "00-15-3F",vendor: "Alcatel Alenia Space Italia"},
  {max_prefix: "00-15-76",vendor: "LABiTec - Labor Biomedical Technologies GmbH"},
  {max_prefix: "00-15-BE",vendor: "Iqua Ltd."},
  {max_prefix: "00-15-5E",vendor: "Morgan Stanley"},
  {max_prefix: "00-15-02",vendor: "BETA tech"},
  {max_prefix: "00-14-FD",vendor: "Thecus Technology Corp."},
  {max_prefix: "00-14-FC",vendor: "Extandon, Inc."},
  {max_prefix: "00-14-F8",vendor: "Scientific Atlanta"},
  {max_prefix: "00-14-F7",vendor: "CREVIS Co., LTD"},
  {max_prefix: "00-15-0F",vendor: "mingjong"},
  {max_prefix: "00-15-0B",vendor: "SAGE INFOTECH LTD."},
  {max_prefix: "00-15-07",vendor: "Renaissance Learning Inc"},
  {max_prefix: "00-15-08",vendor: "Global Target Enterprise Inc"},
  {max_prefix: "00-14-E5",vendor: "Alticast"},
  {max_prefix: "00-14-E0",vendor: "LET'S Corporation"},
  {max_prefix: "00-14-E2",vendor: "datacom systems inc."},
  {max_prefix: "00-14-E4",vendor: "infinias, LLC"},
  {max_prefix: "00-15-18",vendor: "Shenzhen 10MOONS Technology Development CO.,Ltd"},
  {max_prefix: "00-15-15",vendor: "Leipold+Co.GmbH"},
  {max_prefix: "00-15-26",vendor: "Remote Technologies Inc"},
  {max_prefix: "00-14-F1",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-14-EA",vendor: "S Digm Inc. (Safe Paradigm Inc.)"},
  {max_prefix: "00-14-CB",vendor: "LifeSync Corporation"},
  {max_prefix: "00-14-C6",vendor: "Quixant Ltd"},
  {max_prefix: "00-14-DB",vendor: "Elma Trenew Electronic GmbH"},
  {max_prefix: "00-14-CC",vendor: "Zetec, Inc."},
  {max_prefix: "00-14-74",vendor: "K40 Electronics"},
  {max_prefix: "00-14-6F",vendor: "Kohler Co"},
  {max_prefix: "00-14-66",vendor: "Kleinhenz Elektronik GmbH"},
  {max_prefix: "00-14-6B",vendor: "Anagran, Inc."},
  {max_prefix: "00-14-9F",vendor: "System and Chips, Inc."},
  {max_prefix: "00-14-98",vendor: "Viking Design Technology"},
  {max_prefix: "00-14-96",vendor: "Phonic Corp."},
  {max_prefix: "00-14-93",vendor: "Systimax Solutions"},
  {max_prefix: "00-14-3D",vendor: "Aevoe Inc."},
  {max_prefix: "00-14-3A",vendor: "RAYTALK INTERNATIONAL SRL"},
  {max_prefix: "00-14-61",vendor: "CORONA CORPORATION"},
  {max_prefix: "00-14-62",vendor: "Digiwell Technology, inc"},
  {max_prefix: "00-14-63",vendor: "IDCS N.V."},
  {max_prefix: "00-14-65",vendor: "Novo Nordisk A/S"},
  {max_prefix: "00-14-8D",vendor: "Cubic Defense Simulation Systems"},
  {max_prefix: "00-14-86",vendor: "Echo Digital Audio Corporation"},
  {max_prefix: "00-14-82",vendor: "Aurora Networks"},
  {max_prefix: "00-14-36",vendor: "Qwerty Elektronik AB"},
  {max_prefix: "00-14-33",vendor: "Empower Technologies(Canada) Inc."},
  {max_prefix: "00-14-34",vendor: "Keri Systems, Inc"},
  {max_prefix: "00-14-7F",vendor: "Thomson Telecom Belgium"},
  {max_prefix: "00-14-75",vendor: "Wiline Networks, Inc."},
  {max_prefix: "00-14-55",vendor: "Coder Electronics Corporation"},
  {max_prefix: "00-14-4E",vendor: "SRISA"},
  {max_prefix: "00-14-B3",vendor: "CoreStar International Corp"},
  {max_prefix: "00-14-B1",vendor: "Axell Wireless Limited"},
  {max_prefix: "00-13-A4",vendor: "KeyEye Communications"},
  {max_prefix: "00-13-A7",vendor: "BATTELLE MEMORIAL INSTITUTE"},
  {max_prefix: "00-13-A6",vendor: "Extricom Ltd"},
  {max_prefix: "00-13-9F",vendor: "Electronics Design Services, Co., Ltd."},
  {max_prefix: "00-13-A2",vendor: "MaxStream, Inc"},
  {max_prefix: "00-13-A0",vendor: "ALGOSYSTEM Co., Ltd."},
  {max_prefix: "00-13-98",vendor: "TrafficSim Co.,Ltd"},
  {max_prefix: "00-14-15",vendor: "Intec Automation inc."},
  {max_prefix: "00-14-10",vendor: "Suzhou Keda Technology CO.,Ltd"},
  {max_prefix: "00-14-17",vendor: "RSE Informations Technologie GmbH"},
  {max_prefix: "00-14-08",vendor: "Eka Systems Inc."},
  {max_prefix: "00-14-23",vendor: "J-S Co. NEUROCOM"},
  {max_prefix: "00-14-25",vendor: "Galactic Computing Corp."},
  {max_prefix: "00-14-19",vendor: "SIDSA"},
  {max_prefix: "00-13-E2",vendor: "GeoVision Inc."},
  {max_prefix: "00-13-DE",vendor: "Adapt4, LLC"},
  {max_prefix: "00-13-DD",vendor: "Abbott Diagnostics"},
  {max_prefix: "00-13-D7",vendor: "SPIDCOM Technologies SA"},
  {max_prefix: "00-13-C7",vendor: "IONOS Co.,Ltd."},
  {max_prefix: "00-13-B2",vendor: "Carallon Limited"},
  {max_prefix: "00-13-AD",vendor: "Sendo Ltd"},
  {max_prefix: "00-13-AA",vendor: "ALS  & TEC Ltd."},
  {max_prefix: "00-13-EE",vendor: "JBX Designs Inc."},
  {max_prefix: "00-13-E5",vendor: "TENOSYS, INC."},
  {max_prefix: "00-14-02",vendor: "kk-electronic a/s"},
  {max_prefix: "00-13-FF",vendor: "Dage-MTI of MC, Inc."},
  {max_prefix: "00-13-BC",vendor: "Artimi Ltd"},
  {max_prefix: "00-13-84",vendor: "Advanced Motion Controls"},
  {max_prefix: "00-13-7B",vendor: "Movon Corporation"},
  {max_prefix: "00-13-4A",vendor: "Engim, Inc."},
  {max_prefix: "00-13-3A",vendor: "VadaTech Inc."},
  {max_prefix: "00-13-2A",vendor: "Sitronics Telecom Solutions"},
  {max_prefix: "00-13-9B",vendor: "ioIMAGE Ltd."},
  {max_prefix: "00-13-96",vendor: "Acbel Polytech Inc."},
  {max_prefix: "00-13-93",vendor: "Panta Systems, Inc."},
  {max_prefix: "00-13-8B",vendor: "Phantom Technologies LLC"},
  {max_prefix: "00-13-88",vendor: "WiMedia Alliance"},
  {max_prefix: "00-13-53",vendor: "HYDAC Filtertechnik GMBH"},
  {max_prefix: "00-13-4D",vendor: "Inepro BV"},
  {max_prefix: "00-13-4B",vendor: "ToGoldenNet Technology Inc."},
  {max_prefix: "00-13-34",vendor: "Arkados, Inc."},
  {max_prefix: "00-13-37",vendor: "Orient Power Home Network Ltd."},
  {max_prefix: "00-13-32",vendor: "Beijing Topsec Network Security Technology Co., Ltd."},
  {max_prefix: "00-13-1F",vendor: "NxtPhase T&D, Corp."},
  {max_prefix: "00-13-6E",vendor: "Techmetro Corp."},
  {max_prefix: "00-13-6D",vendor: "Tentaculus AB"},
  {max_prefix: "00-13-6A",vendor: "Hach Lange Sarl"},
  {max_prefix: "00-13-0E",vendor: "Focusrite Audio Engineering Limited"},
  {max_prefix: "00-13-09",vendor: "Ocean Broadband Networks"},
  {max_prefix: "00-13-19",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-13-1C",vendor: "LiteTouch, Inc."},
  {max_prefix: "00-13-63",vendor: "Verascape, Inc."},
  {max_prefix: "00-12-FF",vendor: "Lely Industries N.V."},
  {max_prefix: "00-13-03",vendor: "GateConnect"},
  {max_prefix: "00-12-F9",vendor: "URYU SEISAKU, LTD."},
  {max_prefix: "00-13-04",vendor: "Flaircomm Technologies Co. LTD"},
  {max_prefix: "00-12-B3",vendor: "Advance Wireless Technology Corp."},
  {max_prefix: "00-12-B0",vendor: "Efore Oyj   (Plc)"},
  {max_prefix: "00-12-A6",vendor: "Dolby Australia"},
  {max_prefix: "00-12-A4",vendor: "ThingMagic, LLC"},
  {max_prefix: "00-12-A9",vendor: "3Com Ltd"},
  {max_prefix: "00-12-DC",vendor: "SunCorp Industrial Limited"},
  {max_prefix: "00-12-D7",vendor: "Invento Networks, Inc."},
  {max_prefix: "00-12-C4",vendor: "Viseon, Inc."},
  {max_prefix: "00-12-D0",vendor: "Gossen-Metrawatt-GmbH"},
  {max_prefix: "00-12-E5",vendor: "Time America, Inc."},
  {max_prefix: "00-12-B8",vendor: "G2 Microsystems"},
  {max_prefix: "00-12-99",vendor: "Ktech Telecommunications Inc"},
  {max_prefix: "00-12-80",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-12-75",vendor: "Sentilla Corporation"},
  {max_prefix: "00-12-76",vendor: "CG Power Systems Ireland Limited"},
  {max_prefix: "00-12-71",vendor: "Measurement Computing Corp"},
  {max_prefix: "00-12-73",vendor: "Stoke Inc"},
  {max_prefix: "00-12-69",vendor: "Value Electronics"},
  {max_prefix: "00-12-64",vendor: "daum electronic gmbh"},
  {max_prefix: "00-12-61",vendor: "Adaptix, Inc"},
  {max_prefix: "00-12-60",vendor: "Stanton Magnetics,inc."},
  {max_prefix: "00-12-8C",vendor: "Woodward Governor"},
  {max_prefix: "00-12-93",vendor: "ABB Switzerland Ltd."},
  {max_prefix: "00-12-94",vendor: "SUMITOMO ELECTRIC DEVICE INNOVATIONS, INC"},
  {max_prefix: "00-12-96",vendor: "Addlogix"},
  {max_prefix: "00-12-58",vendor: "TechVoIP Sp z o.o."},
  {max_prefix: "00-12-50",vendor: "Tokyo Aircaft Instrument Co., Ltd."},
  {max_prefix: "00-12-52",vendor: "Citronix, LLC"},
  {max_prefix: "00-12-7F",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-12-7B",vendor: "VIA Networking Technologies, Inc."},
  {max_prefix: "00-12-40",vendor: "AMOI ELECTRONICS CO.,LTD"},
  {max_prefix: "00-12-2E",vendor: "Signal Technology - AISD"},
  {max_prefix: "00-12-31",vendor: "Motion Control Systems, Inc."},
  {max_prefix: "00-12-24",vendor: "NexQL Corporation"},
  {max_prefix: "00-12-4B",vendor: "Texas Instruments"},
  {max_prefix: "00-12-4A",vendor: "Dedicated Devices, Inc."},
  {max_prefix: "00-12-43",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-12-03",vendor: "ActivNetworks"},
  {max_prefix: "00-11-F8",vendor: "AIRAYA Corp"},
  {max_prefix: "00-11-F4",vendor: "woori-net"},
  {max_prefix: "00-11-F6",vendor: "Asia Pacific Microsystems , Inc."},
  {max_prefix: "00-11-F0",vendor: "Wideful Limited"},
  {max_prefix: "00-11-F1",vendor: "QinetiQ Ltd"},
  {max_prefix: "00-11-AA",vendor: "Uniclass Technology, Co., LTD"},
  {max_prefix: "00-11-B1",vendor: "BlueExpert Technology Corp."},
  {max_prefix: "00-11-DB",vendor: "Land-Cellular Corporation"},
  {max_prefix: "00-11-D2",vendor: "Perception Digital Ltd"},
  {max_prefix: "00-11-D9",vendor: "TiVo"},
  {max_prefix: "00-11-CF",vendor: "Thrane & Thrane A/S"},
  {max_prefix: "00-11-D4",vendor: "NetEnrich, Inc"},
  {max_prefix: "00-11-D5",vendor: "Hangzhou Sunyard System Engineering Co.,Ltd."},
  {max_prefix: "00-11-CC",vendor: "Guangzhou Jinpeng Group Co.,Ltd."},
  {max_prefix: "00-11-C7",vendor: "Raymarine UK Ltd"},
  {max_prefix: "00-11-C9",vendor: "MTT Corporation"},
  {max_prefix: "00-12-11",vendor: "Protechna Herbst GmbH & Co. KG"},
  {max_prefix: "00-12-19",vendor: "General Datacomm LLC"},
  {max_prefix: "00-12-16",vendor: "ICP Internet Communication Payment AG"},
  {max_prefix: "00-12-15",vendor: "iStor Networks, Inc."},
  {max_prefix: "00-11-A6",vendor: "Sypixx Networks"},
  {max_prefix: "00-11-A2",vendor: "Manufacturing Technology Inc"},
  {max_prefix: "00-11-9E",vendor: "Solectron Brazil"},
  {max_prefix: "00-11-B5",vendor: "Shenzhen Powercom Co.,Ltd"},
  {max_prefix: "00-11-BB",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-11-BD",vendor: "Bombardier Transportation"},
  {max_prefix: "00-11-ED",vendor: "802 Global"},
  {max_prefix: "00-11-1C",vendor: "Pleora Technologies Inc."},
  {max_prefix: "00-11-25",vendor: "IBM Corp"},
  {max_prefix: "00-11-1F",vendor: "Doremi Labs, Inc."},
  {max_prefix: "00-11-1D",vendor: "Hectrix Limited"},
  {max_prefix: "00-11-19",vendor: "Solteras, Inc."},
  {max_prefix: "00-11-84",vendor: "Humo Laboratory,Ltd."},
  {max_prefix: "00-11-7E",vendor: "Midmark Corp"},
  {max_prefix: "00-11-52",vendor: "Eidsvoll Electronics AS"},
  {max_prefix: "00-11-50",vendor: "Belkin Corporation"},
  {max_prefix: "00-11-66",vendor: "Taelim Electronics Co., Ltd."},
  {max_prefix: "00-11-64",vendor: "ACARD Technology Corp."},
  {max_prefix: "00-11-55",vendor: "Sevis Systems"},
  {max_prefix: "00-11-46",vendor: "Telecard-Pribor Ltd"},
  {max_prefix: "00-11-4C",vendor: "caffeina applied research ltd."},
  {max_prefix: "00-11-41",vendor: "GoodMan Corporation"},
  {max_prefix: "00-11-8A",vendor: "Viewtran Technology Limited"},
  {max_prefix: "00-11-79",vendor: "Singular Technology Co. Ltd."},
  {max_prefix: "00-11-73",vendor: "SMART Storage Systems"},
  {max_prefix: "00-11-2C",vendor: "IZT GmbH"},
  {max_prefix: "00-0F-CF",vendor: "DataWind Research"},
  {max_prefix: "00-0F-D2",vendor: "EWA Technologies, Inc."},
  {max_prefix: "00-0F-CE",vendor: "Kikusui Electronics Corp."},
  {max_prefix: "00-0F-A1",vendor: "Gigabit Systems Inc."},
  {max_prefix: "00-0F-99",vendor: "APAC opto Electronics Inc."},
  {max_prefix: "00-0F-E8",vendor: "Lobos, Inc."},
  {max_prefix: "00-0F-EB",vendor: "Cylon Controls"},
  {max_prefix: "00-0F-DC",vendor: "Ueda Japan  Radio Co., Ltd."},
  {max_prefix: "00-0F-F4",vendor: "Guntermann & Drunck GmbH"},
  {max_prefix: "00-0F-F8",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-0F-F5",vendor: "GN&S company"},
  {max_prefix: "00-0F-D7",vendor: "Harman Music Group"},
  {max_prefix: "00-0F-D4",vendor: "Soundcraft"},
  {max_prefix: "00-11-14",vendor: "EverFocus Electronics Corp."},
  {max_prefix: "00-11-0E",vendor: "Tsurusaki Sealand Transportation Co. Ltd."},
  {max_prefix: "00-11-0D",vendor: "SANBlaze Technology, Inc."},
  {max_prefix: "00-0F-A5",vendor: "BWA Technology GmbH"},
  {max_prefix: "00-0F-B2",vendor: "Broadband Pacenet (India) Pvt. Ltd."},
  {max_prefix: "00-11-06",vendor: "Siemens NV (Belgium)"},
  {max_prefix: "00-0F-AF",vendor: "Dialog Inc."},
  {max_prefix: "00-0F-43",vendor: "Wasabi Systems Inc."},
  {max_prefix: "00-0F-48",vendor: "Polypix Inc."},
  {max_prefix: "00-0F-50",vendor: "StreamScale Limited"},
  {max_prefix: "00-0F-47",vendor: "ROBOX SPA"},
  {max_prefix: "00-0F-4E",vendor: "Cellink"},
  {max_prefix: "00-0F-31",vendor: "Allied Vision Technologies Canada Inc"},
  {max_prefix: "00-0F-29",vendor: "Augmentix Corporation"},
  {max_prefix: "00-0F-27",vendor: "TEAL Electronics, Inc."},
  {max_prefix: "00-0F-8E",vendor: "DONGYANG TELECOM CO.,LTD."},
  {max_prefix: "00-0F-91",vendor: "Aerotelecom Co.,Ltd."},
  {max_prefix: "00-0F-73",vendor: "RS Automation Co., Ltd"},
  {max_prefix: "00-0F-78",vendor: "Datacap Systems Inc"},
  {max_prefix: "00-0F-70",vendor: "Wintec Industries, inc."},
  {max_prefix: "00-0F-87",vendor: "Maxcess International"},
  {max_prefix: "00-0F-80",vendor: "Trinity Security Systems,Inc."},
  {max_prefix: "00-0F-5F",vendor: "Nicety Technologies Inc. (NTS)"},
  {max_prefix: "00-0F-5A",vendor: "Peribit Networks"},
  {max_prefix: "00-0F-74",vendor: "Qamcom Technology AB"},
  {max_prefix: "00-0F-6D",vendor: "Midas Engineering"},
  {max_prefix: "00-0F-3C",vendor: "Endeleo Limited"},
  {max_prefix: "00-0F-18",vendor: "Industrial Control Systems"},
  {max_prefix: "00-0F-1D",vendor: "Cosmo Techs Co., Ltd."},
  {max_prefix: "00-0F-1B",vendor: "Ego Systems Inc."},
  {max_prefix: "00-0F-0F",vendor: "Real ID Technology Co., Ltd."},
  {max_prefix: "00-0F-16",vendor: "JAY HOW TECHNOLOGY CO.,"},
  {max_prefix: "00-0E-F8",vendor: "SBC ASI"},
  {max_prefix: "00-0E-F9",vendor: "REA Elektronik GmbH"},
  {max_prefix: "00-0E-F6",vendor: "E-TEN Information Systems Co., Ltd."},
  {max_prefix: "00-0E-EA",vendor: "Shadong Luneng Jicheng Electronics,Co.,Ltd"},
  {max_prefix: "00-0E-AD",vendor: "Metanoia Technologies, Inc."},
  {max_prefix: "00-0E-AF",vendor: "CASTEL"},
  {max_prefix: "00-0E-E6",vendor: "Adimos Systems LTD"},
  {max_prefix: "00-0E-C6",vendor: "ASIX ELECTRONICS CORP."},
  {max_prefix: "00-0E-BF",vendor: "Remsdaq Limited"},
  {max_prefix: "00-0E-E0",vendor: "Mcharge"},
  {max_prefix: "00-0E-AB",vendor: "Cray Inc"},
  {max_prefix: "00-0E-9F",vendor: "TEMIC SDS GmbH"},
  {max_prefix: "00-0E-92",vendor: "Open Telecom"},
  {max_prefix: "00-0E-96",vendor: "Cubic Defense Applications, Inc."},
  {max_prefix: "00-0E-8E",vendor: "SparkLAN Communications, Inc."},
  {max_prefix: "00-0E-91",vendor: "Navico Auckland Ltd"},
  {max_prefix: "00-0E-14",vendor: "Visionary Solutions, Inc."},
  {max_prefix: "00-0E-1B",vendor: "IAV GmbH"},
  {max_prefix: "00-0E-0F",vendor: "ERMME"},
  {max_prefix: "00-0E-10",vendor: "C-guys, Inc."},
  {max_prefix: "00-0E-13",vendor: "Accu-Sort Systems inc."},
  {max_prefix: "00-0E-0A",vendor: "SAKUMA DESIGN OFFICE"},
  {max_prefix: "00-0E-7C",vendor: "Televes S.A."},
  {max_prefix: "00-0E-73",vendor: "Tpack A/S"},
  {max_prefix: "00-0E-6E",vendor: "MAT S.A. (Mircrelec Advanced Technology)"},
  {max_prefix: "00-0E-56",vendor: "4G Systems GmbH & Co. KG"},
  {max_prefix: "00-0E-55",vendor: "AUVITRAN"},
  {max_prefix: "00-0E-53",vendor: "AV TECH CORPORATION"},
  {max_prefix: "00-0E-66",vendor: "Hitachi Industry & Control Solutions, Ltd."},
  {max_prefix: "00-0E-68",vendor: "E-TOP Network Technology Inc."},
  {max_prefix: "00-0E-33",vendor: "Shuko Electronics Co.,Ltd"},
  {max_prefix: "00-0E-3A",vendor: "Cirrus Logic"},
  {max_prefix: "00-0E-3B",vendor: "Hawking Technologies, Inc."},
  {max_prefix: "00-0E-84",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-0E-87",vendor: "adp Gauselmann GmbH"},
  {max_prefix: "00-0E-75",vendor: "New York Air Brake Corp."},
  {max_prefix: "00-0E-48",vendor: "Lipman TransAction Solutions"},
  {max_prefix: "00-0E-3E",vendor: "Sun Optronics Inc"},
  {max_prefix: "00-0E-27",vendor: "Crere Networks, Inc."},
  {max_prefix: "00-0E-18",vendor: "MyA Technology"},
  {max_prefix: "00-0D-9B",vendor: "Heraeus Electro-Nite International N.V."},
  {max_prefix: "00-0D-8A",vendor: "Winners Electronics Co., Ltd."},
  {max_prefix: "00-0D-8E",vendor: "Koden Electronics Co., Ltd."},
  {max_prefix: "00-0D-CD",vendor: "GROUPE TXCOM"},
  {max_prefix: "00-0D-CF",vendor: "Cidra Corp."},
  {max_prefix: "00-0D-CA",vendor: "Tait Electronics"},
  {max_prefix: "00-0D-DE",vendor: "Joyteck Co., Ltd."},
  {max_prefix: "00-0D-E2",vendor: "CMZ Sistemi Elettronici"},
  {max_prefix: "00-0D-DA",vendor: "ALLIED TELESIS K.K."},
  {max_prefix: "00-0D-D6",vendor: "ITI    LTD"},
  {max_prefix: "00-0D-D5",vendor: "O'RITE TECHNOLOGY CO.,LTD"},
  {max_prefix: "00-0E-0E",vendor: "ESA elettronica S.P.A."},
  {max_prefix: "00-0D-FD",vendor: "Huges Hi-Tech Inc.,"},
  {max_prefix: "00-0D-F9",vendor: "NDS Limited"},
  {max_prefix: "00-0D-FB",vendor: "Komax AG"},
  {max_prefix: "00-0E-00",vendor: "Atrie"},
  {max_prefix: "00-0D-F4",vendor: "Watertek Co."},
  {max_prefix: "00-0D-FA",vendor: "Micro Control Systems Ltd."},
  {max_prefix: "00-0D-FC",vendor: "ITFOR Inc."},
  {max_prefix: "00-0D-FE",vendor: "Hauppauge Computer Works, Inc."},
  {max_prefix: "00-0D-B8",vendor: "SCHILLER AG"},
  {max_prefix: "00-0D-C4",vendor: "Emcore Corporation"},
  {max_prefix: "00-0D-EC",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-0D-F2",vendor: "Private"},
  {max_prefix: "00-0D-A0",vendor: "NEDAP N.V."},
  {max_prefix: "00-0D-4F",vendor: "Kenwood Corporation"},
  {max_prefix: "00-0D-47",vendor: "Collex"},
  {max_prefix: "00-0D-43",vendor: "DRS Tactical Systems Inc."},
  {max_prefix: "00-0D-44",vendor: "Audio BU - Logitech"},
  {max_prefix: "00-0D-3D",vendor: "Hammerhead Systems, Inc."},
  {max_prefix: "00-0D-3E",vendor: "APLUX Communications Ltd."},
  {max_prefix: "00-0D-5A",vendor: "Tiesse SpA"},
  {max_prefix: "00-0D-61",vendor: "Giga-Byte Technology Co., Ltd."},
  {max_prefix: "00-0D-36",vendor: "Wu Han Routon Electronic Co., Ltd"},
  {max_prefix: "00-0D-3B",vendor: "Microelectronics Technology Inc."},
  {max_prefix: "00-0D-1A",vendor: "Mustek System Inc."},
  {max_prefix: "00-0D-1E",vendor: "Control Techniques"},
  {max_prefix: "00-0D-0D",vendor: "ITSupported, LLC"},
  {max_prefix: "00-0D-06",vendor: "Compulogic Limited"},
  {max_prefix: "00-0D-52",vendor: "Comart system"},
  {max_prefix: "00-0D-4A",vendor: "Steag ETA-Optik"},
  {max_prefix: "00-0D-7C",vendor: "Codian Ltd"},
  {max_prefix: "00-0D-6B",vendor: "Mita-Teknik A/S"},
  {max_prefix: "00-0D-71",vendor: "boca systems"},
  {max_prefix: "00-0D-2D",vendor: "NCT Deutschland GmbH"},
  {max_prefix: "00-0D-7E",vendor: "Axiowave Networks, Inc."},
  {max_prefix: "00-0C-A7",vendor: "Metro (Suzhou) Technologies Co., Ltd."},
  {max_prefix: "00-0C-A0",vendor: "StorCase Technology, Inc."},
  {max_prefix: "00-0C-BE",vendor: "Innominate Security Technologies AG"},
  {max_prefix: "00-0C-BD",vendor: "Interface Masters, Inc"},
  {max_prefix: "00-0C-B2",vendor: "UNION co., ltd."},
  {max_prefix: "00-0C-B9",vendor: "LEA"},
  {max_prefix: "00-0C-B8",vendor: "MEDION AG"},
  {max_prefix: "00-0C-88",vendor: "Apache Micro Peripherals, Inc."},
  {max_prefix: "00-0C-82",vendor: "NETWORK TECHNOLOGIES INC"},
  {max_prefix: "00-0C-7C",vendor: "Internet Information Image Inc."},
  {max_prefix: "00-0D-03",vendor: "Matrics, Inc."},
  {max_prefix: "00-0C-FF",vendor: "MRO-TEK Realty Limited"},
  {max_prefix: "00-0C-FA",vendor: "Digital Systems Corp"},
  {max_prefix: "00-0C-FD",vendor: "Hyundai ImageQuest Co.,Ltd."},
  {max_prefix: "00-0C-BB",vendor: "ISKRAEMECO"},
  {max_prefix: "00-0C-C0",vendor: "Genera Oy"},
  {max_prefix: "00-0C-A8",vendor: "Garuda Networks Corporation"},
  {max_prefix: "00-0C-EB",vendor: "CNMP Networks, Inc."},
  {max_prefix: "00-0C-D3",vendor: "Prettl Elektronik Radeberg GmbH"},
  {max_prefix: "00-0C-D7",vendor: "Nallatech Ltd"},
  {max_prefix: "00-0C-D4",vendor: "Positron Public Safety Systems inc."},
  {max_prefix: "00-0C-99",vendor: "HITEL LINK Co.,Ltd"},
  {max_prefix: "00-0C-89",vendor: "AC Electric Vehicles, Ltd."},
  {max_prefix: "00-0C-CC",vendor: "Aeroscout Ltd."},
  {max_prefix: "00-0C-C7",vendor: "Intelligent Computer Solutions Inc."},
  {max_prefix: "00-0C-7B",vendor: "ALPHA PROJECT Co.,Ltd."},
  {max_prefix: "00-0C-77",vendor: "Life Racing Ltd"},
  {max_prefix: "00-0C-64",vendor: "X2 MSA Group"},
  {max_prefix: "00-0C-5A",vendor: "IBSmm Embedded Electronics Consulting"},
  {max_prefix: "00-0C-5E",vendor: "Calypso Medical"},
  {max_prefix: "00-0C-61",vendor: "AC Tech corporation DBA Advanced Digital"},
  {max_prefix: "00-0C-5F",vendor: "Avtec, Inc."},
  {max_prefix: "00-0C-4B",vendor: "Cheops Elektronik"},
  {max_prefix: "00-0C-05",vendor: "RPA Reserch Co., Ltd."},
  {max_prefix: "00-0C-27",vendor: "Sammy Corporation"},
  {max_prefix: "00-0C-2A",vendor: "OCTTEL Communication Co., Ltd."},
  {max_prefix: "00-0C-22",vendor: "Double D Electronics Ltd"},
  {max_prefix: "00-0C-1C",vendor: "MicroWeb Co., Ltd."},
  {max_prefix: "00-0C-69",vendor: "National Radio Astronomy Observatory"},
  {max_prefix: "00-0C-66",vendor: "Pronto Networks Inc"},
  {max_prefix: "00-0C-45",vendor: "Animation Technologies Inc."},
  {max_prefix: "00-0C-3C",vendor: "MediaChorus, Inc."},
  {max_prefix: "00-0C-0F",vendor: "Techno-One Co., Ltd"},
  {max_prefix: "00-0C-38",vendor: "TelcoBridges Inc."},
  {max_prefix: "00-0C-4E",vendor: "Winbest Technology CO,LT"},
  {max_prefix: "00-0B-C4",vendor: "BIOTRONIK GmbH & Co"},
  {max_prefix: "00-0B-AF",vendor: "WOOJU COMMUNICATIONS Co,.Ltd"},
  {max_prefix: "00-0B-B6",vendor: "Metalligence Technology Corp."},
  {max_prefix: "00-0B-B7",vendor: "Micro Systems Co.,Ltd."},
  {max_prefix: "00-0B-B3",vendor: "RiT technologies Ltd."},
  {max_prefix: "00-0B-E6",vendor: "Datel Electronics"},
  {max_prefix: "00-0B-AE",vendor: "Vitals System Inc."},
  {max_prefix: "00-0B-D9",vendor: "General Hydrogen"},
  {max_prefix: "00-0B-F2",vendor: "Chih-Kan Technology Co., Ltd."},
  {max_prefix: "00-0B-EB",vendor: "Systegra AG"},
  {max_prefix: "00-0B-EF",vendor: "Code Corporation"},
  {max_prefix: "00-0B-DF",vendor: "Shenzhen RouterD Networks Limited"},
  {max_prefix: "00-0B-F5",vendor: "Shanghai Sibo Telecom Technology Co.,Ltd"},
  {max_prefix: "00-0B-FE",vendor: "CASTEL Broadband Limited"},
  {max_prefix: "00-0B-7D",vendor: "SOLOMON EXTREME INTERNATIONAL LTD."},
  {max_prefix: "00-0B-94",vendor: "Digital Monitoring Products, Inc."},
  {max_prefix: "00-0B-AB",vendor: "Advantech Technology (CHINA) Co., Ltd."},
  {max_prefix: "00-0B-BA",vendor: "Harmonic, Inc"},
  {max_prefix: "00-0B-57",vendor: "Silicon Laboratories"},
  {max_prefix: "00-0B-51",vendor: "Micetek International Inc."},
  {max_prefix: "00-0B-53",vendor: "INITIUM Co., Ltd."},
  {max_prefix: "00-0B-6D",vendor: "SOLECTRON JAPAN NAKANIIDA"},
  {max_prefix: "00-0B-62",vendor: "ib-mohnen KG"},
  {max_prefix: "00-0B-64",vendor: "Kieback & Peter GmbH & Co KG"},
  {max_prefix: "00-0B-67",vendor: "Topview Technology Corporation"},
  {max_prefix: "00-0B-48",vendor: "sofrel"},
  {max_prefix: "00-0A-FB",vendor: "Ambri Limited"},
  {max_prefix: "00-0A-FF",vendor: "Kilchherr Elektronik AG"},
  {max_prefix: "00-0B-4A",vendor: "Visimetrics (UK) Ltd"},
  {max_prefix: "00-0B-37",vendor: "MANUFACTURE DES MONTRES ROLEX SA"},
  {max_prefix: "00-0B-2D",vendor: "Danfoss Inc."},
  {max_prefix: "00-0B-1E",vendor: "KAPPA opto-electronics GmbH"},
  {max_prefix: "00-0B-1C",vendor: "SIBCO bv"},
  {max_prefix: "00-0A-F8",vendor: "American Telecare Inc."},
  {max_prefix: "00-0B-17",vendor: "MKS Instruments"},
  {max_prefix: "00-0A-9A",vendor: "Aiptek International Inc"},
  {max_prefix: "00-0A-94",vendor: "ShangHai cellink CO., LTD"},
  {max_prefix: "00-0A-97",vendor: "SONICblue, Inc."},
  {max_prefix: "00-0A-92",vendor: "Presonus Corporation"},
  {max_prefix: "00-0A-64",vendor: "Eracom Technologies"},
  {max_prefix: "00-0A-62",vendor: "Crinis Networks, Inc."},
  {max_prefix: "00-0A-6A",vendor: "SVM Microwaves s.r.o."},
  {max_prefix: "00-0A-66",vendor: "MITSUBISHI ELECTRIC SYSTEM & SERVICE CO.,LTD."},
  {max_prefix: "00-0A-A3",vendor: "SHIMAFUJI ELECTRIC CO.,LTD."},
  {max_prefix: "00-0A-A7",vendor: "FEI Electron Optics"},
  {max_prefix: "00-0A-A6",vendor: "Hochiki Corporation"},
  {max_prefix: "00-0A-C7",vendor: "Unication Group"},
  {max_prefix: "00-0A-BF",vendor: "HIROTA SS"},
  {max_prefix: "00-0A-BC",vendor: "Seabridge Ltd."},
  {max_prefix: "00-0A-70",vendor: "MPLS Forum"},
  {max_prefix: "00-0A-72",vendor: "STEC, INC."},
  {max_prefix: "00-0A-71",vendor: "Avrio Technologies, Inc"},
  {max_prefix: "00-0A-DE",vendor: "Happy Communication Co., Ltd."},
  {max_prefix: "00-0A-E2",vendor: "Binatone Electronics International, Ltd"},
  {max_prefix: "00-0A-D4",vendor: "CoreBell Systems Inc."},
  {max_prefix: "00-0A-CA",vendor: "YOKOYAMA SHOKAI CO.,Ltd."},
  {max_prefix: "00-0A-CE",vendor: "RADIANTECH, INC."},
  {max_prefix: "00-0A-AC",vendor: "TerraTec Electronic GmbH"},
  {max_prefix: "00-0A-B8",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-0A-85",vendor: "PLAT'C2,Inc"},
  {max_prefix: "00-0A-83",vendor: "SALTO SYSTEMS S.L."},
  {max_prefix: "00-0A-86",vendor: "Lenze"},
  {max_prefix: "00-0A-50",vendor: "REMOTEK CORPORATION"},
  {max_prefix: "00-0A-58",vendor: "Freyer & Siegel Elektronik GmbH & Co. KG"},
  {max_prefix: "00-0A-4E",vendor: "UNITEK Electronics INC."},
  {max_prefix: "00-0A-3D",vendor: "Elo Sistemas Eletronicos S.A."},
  {max_prefix: "00-0A-46",vendor: "ARO WELDING TECHNOLOGIES SAS"},
  {max_prefix: "00-09-F6",vendor: "Shenzhen Eastern Digital Tech Ltd."},
  {max_prefix: "00-0A-01",vendor: "SOHOware, Inc."},
  {max_prefix: "00-09-EE",vendor: "MEIKYO ELECTRIC CO.,LTD"},
  {max_prefix: "00-0A-31",vendor: "HCV Consulting"},
  {max_prefix: "00-42-52",vendor: "RLX Technologies"},
  {max_prefix: "00-0A-20",vendor: "SVA Networks, Inc."},
  {max_prefix: "00-0A-24",vendor: "Octave Communications"},
  {max_prefix: "00-0A-19",vendor: "Valere Power, Inc."},
  {max_prefix: "00-09-E5",vendor: "Hottinger Br\u00fcel & Kjaer GmbH"},
  {max_prefix: "00-09-DE",vendor: "Samjin Information & Communications Co., Ltd."},
  {max_prefix: "00-09-E0",vendor: "XEMICS S.A."},
  {max_prefix: "00-09-CA",vendor: "iMaxNetworks(Shenzhen)Limited."},
  {max_prefix: "00-0A-11",vendor: "ExPet Technologies, Inc"},
  {max_prefix: "00-0A-0F",vendor: "Ilryung Telesys, Inc"},
  {max_prefix: "00-0A-0C",vendor: "Scientific Research Corporation"},
  {max_prefix: "00-09-EC",vendor: "Daktronics, Inc."},
  {max_prefix: "00-09-E7",vendor: "ADC Techonology"},
  {max_prefix: "00-09-CF",vendor: "iAd GmbH"},
  {max_prefix: "00-0A-3F",vendor: "Data East Corporation"},
  {max_prefix: "00-09-56",vendor: "Network Systems Group, Ltd. (NSG)"},
  {max_prefix: "00-09-48",vendor: "Vista Control Systems, Corp."},
  {max_prefix: "00-09-49",vendor: "Glyph Technologies Inc."},
  {max_prefix: "00-09-B1",vendor: "Kanematsu Electronics, Ltd."},
  {max_prefix: "00-09-B0",vendor: "Onkyo Technology K.K."},
  {max_prefix: "00-09-AB",vendor: "Netcontrol Oy"},
  {max_prefix: "00-09-9D",vendor: "Haliplex Communications"},
  {max_prefix: "00-09-68",vendor: "TECHNOVENTURE, INC."},
  {max_prefix: "00-09-61",vendor: "Switchgear and Instrumentation Ltd"},
  {max_prefix: "00-09-60",vendor: "YOZAN Inc."},
  {max_prefix: "00-09-9E",vendor: "Testech, Inc."},
  {max_prefix: "00-09-C1",vendor: "PROCES-DATA A/S"},
  {max_prefix: "00-09-99",vendor: "CP GEORGES RENAULT"},
  {max_prefix: "00-09-94",vendor: "Cronyx Engineering"},
  {max_prefix: "00-09-88",vendor: "Nudian Electron Co., Ltd."},
  {max_prefix: "00-09-8E",vendor: "ipcas GmbH"},
  {max_prefix: "00-09-79",vendor: "Advanced Television Systems Committee, Inc."},
  {max_prefix: "00-09-63",vendor: "Dominion Lasercom Inc."},
  {max_prefix: "00-09-66",vendor: "TRIMBLE EUROPE BV"},
  {max_prefix: "00-09-BB",vendor: "MathStar, Inc."},
  {max_prefix: "00-09-B9",vendor: "Action Imaging Solutions"},
  {max_prefix: "00-09-AC",vendor: "LANVOICE"},
  {max_prefix: "00-09-7C",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-09-7B",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-09-3C",vendor: "Jacques Technologies P/L"},
  {max_prefix: "00-09-35",vendor: "Sandvine Incorporated"},
  {max_prefix: "00-09-36",vendor: "Ipetronik GmbH & Co. KG"},
  {max_prefix: "00-09-37",vendor: "Inventec Appliance Corp"},
  {max_prefix: "00-08-FD",vendor: "BlueKorea Co., Ltd."},
  {max_prefix: "00-08-F8",vendor: "UTC CCS"},
  {max_prefix: "00-08-F3",vendor: "WANY"},
  {max_prefix: "00-08-D9",vendor: "Mitadenshi Co.,LTD"},
  {max_prefix: "00-08-D5",vendor: "Vanguard Networks Solutions, LLC"},
  {max_prefix: "00-08-CD",vendor: "With-Net Inc"},
  {max_prefix: "00-08-CC",vendor: "Remotec, Inc."},
  {max_prefix: "00-08-D1",vendor: "KAREL INC."},
  {max_prefix: "00-08-DE",vendor: "3UP Systems"},
  {max_prefix: "00-08-E0",vendor: "ATO Technology Ltd."},
  {max_prefix: "00-08-E4",vendor: "Envenergy Inc"},
  {max_prefix: "00-08-E3",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-08-E5",vendor: "IDK Corporation"},
  {max_prefix: "00-09-13",vendor: "SystemK Corporation"},
  {max_prefix: "00-09-0E",vendor: "Helix Technology Inc."},
  {max_prefix: "00-09-46",vendor: "Cluster Labs GmbH"},
  {max_prefix: "00-09-3F",vendor: "Double-Win Enterpirse CO., LTD"},
  {max_prefix: "00-09-2A",vendor: "MYTECS Co.,Ltd."},
  {max_prefix: "00-09-25",vendor: "VSN Systemen BV"},
  {max_prefix: "00-09-00",vendor: "TMT"},
  {max_prefix: "00-08-2C",vendor: "Homag AG"},
  {max_prefix: "00-08-21",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-08-22",vendor: "InPro Comm"},
  {max_prefix: "00-08-1D",vendor: "Ipsil, Incorporated"},
  {max_prefix: "00-08-23",vendor: "Texa Corp."},
  {max_prefix: "00-08-28",vendor: "Koei Engineering Ltd."},
  {max_prefix: "00-08-2D",vendor: "Indus Teqsite Private Limited"},
  {max_prefix: "00-08-20",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-08-24",vendor: "Nuance Document Imaging"},
  {max_prefix: "00-08-6C",vendor: "Plasmon LMS"},
  {max_prefix: "00-08-68",vendor: "PurOptix"},
  {max_prefix: "00-08-69",vendor: "Command-e Technology Co.,Ltd."},
  {max_prefix: "00-08-62",vendor: "NEC Eluminant Technologies, Inc."},
  {max_prefix: "00-08-61",vendor: "SoftEnergy Co., Ltd."},
  {max_prefix: "00-08-91",vendor: "Lyan Inc."},
  {max_prefix: "00-08-92",vendor: "EM Solutions"},
  {max_prefix: "00-08-8C",vendor: "Quanta Network Systems Inc."},
  {max_prefix: "00-08-8A",vendor: "Minds@Work"},
  {max_prefix: "00-08-87",vendor: "Maschinenfabrik Reinhausen GmbH"},
  {max_prefix: "00-08-77",vendor: "Liebert-Hiross Spa"},
  {max_prefix: "00-08-7B",vendor: "RTX Telecom A/S"},
  {max_prefix: "00-08-76",vendor: "SDSystem"},
  {max_prefix: "00-08-70",vendor: "Rasvia Systems, Inc."},
  {max_prefix: "00-08-6E",vendor: "Hyglo AB"},
  {max_prefix: "00-08-14",vendor: "TIL Technologies"},
  {max_prefix: "00-08-BD",vendor: "TEPG-US"},
  {max_prefix: "00-08-AE",vendor: "PacketFront Network Products AB"},
  {max_prefix: "00-08-4F",vendor: "Qualstar Corporation"},
  {max_prefix: "00-08-54",vendor: "Netronix, Inc."},
  {max_prefix: "00-08-5C",vendor: "Shanghai Dare Technologies Co. Ltd."},
  {max_prefix: "00-08-A7",vendor: "iLogic Inc."},
  {max_prefix: "00-08-99",vendor: "Netbind, Inc."},
  {max_prefix: "00-08-A0",vendor: "Stotz Feinmesstechnik GmbH"},
  {max_prefix: "00-08-C3",vendor: "Contex A/S"},
  {max_prefix: "00-08-BC",vendor: "Ilevo AB"},
  {max_prefix: "00-07-BD",vendor: "Radionet Ltd."},
  {max_prefix: "00-07-C4",vendor: "JEAN Co. Ltd."},
  {max_prefix: "00-07-C3",vendor: "Thomson"},
  {max_prefix: "00-07-D2",vendor: "Logopak Systeme GmbH & Co. KG"},
  {max_prefix: "00-07-C9",vendor: "Technol Seven Co., Ltd."},
  {max_prefix: "00-07-C7",vendor: "Synectics Systems Limited"},
  {max_prefix: "00-07-BF",vendor: "Armillaire Technologies, Inc."},
  {max_prefix: "00-07-BB",vendor: "Candera Inc."},
  {max_prefix: "00-07-A5",vendor: "Y.D.K Co. Ltd."},
  {max_prefix: "00-07-9C",vendor: "Golden Electronics Technology Co., Ltd."},
  {max_prefix: "00-07-B6",vendor: "Telecom Technology Ltd."},
  {max_prefix: "00-07-B7",vendor: "Samurai Ind. Prods Eletronicos Ltda"},
  {max_prefix: "00-07-B0",vendor: "Office Details, Inc."},
  {max_prefix: "00-08-03",vendor: "Cos Tron"},
  {max_prefix: "00-08-05",vendor: "Techno-Holon Corporation"},
  {max_prefix: "00-07-FD",vendor: "LANergy Ltd."},
  {max_prefix: "00-07-FE",vendor: "Rigaku Corporation"},
  {max_prefix: "00-07-D9",vendor: "Splicecom"},
  {max_prefix: "00-07-DA",vendor: "Neuro Telecom Co., Ltd."},
  {max_prefix: "00-07-CD",vendor: "Kumoh Electronic Co, Ltd"},
  {max_prefix: "00-07-CF",vendor: "Anoto AB"},
  {max_prefix: "00-07-77",vendor: "Motah Ltd."},
  {max_prefix: "00-07-80",vendor: "Bluegiga Technologies OY"},
  {max_prefix: "00-07-8E",vendor: "Garz & Fricke GmbH"},
  {max_prefix: "00-07-E4",vendor: "SoftRadio Co., Ltd."},
  {max_prefix: "00-07-54",vendor: "Xyterra Computing, Inc."},
  {max_prefix: "00-07-57",vendor: "Topcall International AG"},
  {max_prefix: "00-07-4C",vendor: "Beicom Inc."},
  {max_prefix: "00-07-53",vendor: "Beijing Qxcomm Technology Co., Ltd."},
  {max_prefix: "00-07-4D",vendor: "Zebra Technologies Corp."},
  {max_prefix: "00-07-2F",vendor: "Intransa, Inc."},
  {max_prefix: "00-07-32",vendor: "AAEON Technology Inc."},
  {max_prefix: "00-07-25",vendor: "Bematech International Corp."},
  {max_prefix: "00-07-23",vendor: "ELCON Systemtechnik GmbH"},
  {max_prefix: "00-07-1D",vendor: "Satelsa Sistemas Y Aplicaciones De Telecomunicaciones, S.A."},
  {max_prefix: "00-07-20",vendor: "Trutzschler GmbH & Co. KG"},
  {max_prefix: "00-07-6E",vendor: "Sinetica Corporation Limited"},
  {max_prefix: "00-07-6F",vendor: "Synoptics Limited"},
  {max_prefix: "00-07-73",vendor: "Ascom Powerline Communications Ltd."},
  {max_prefix: "00-07-6C",vendor: "Daehanet, Inc."},
  {max_prefix: "00-07-5D",vendor: "Celleritas Inc."},
  {max_prefix: "00-07-65",vendor: "Jade Quantum Technologies, Inc."},
  {max_prefix: "00-07-37",vendor: "Soriya Co. Ltd."},
  {max_prefix: "00-07-34",vendor: "ONStor, Inc."},
  {max_prefix: "00-07-3F",vendor: "Woojyun Systec Co., Ltd."},
  {max_prefix: "00-07-28",vendor: "Neo Telecom"},
  {max_prefix: "00-07-2C",vendor: "Fabricom"},
  {max_prefix: "00-07-2D",vendor: "CNSystems"},
  {max_prefix: "00-07-24",vendor: "Telemax Co., Ltd."},
  {max_prefix: "00-07-1B",vendor: "CDVI Americas Ltd"},
  {max_prefix: "00-07-15",vendor: "General Research of Electronics, Inc."},
  {max_prefix: "00-06-FD",vendor: "Comjet Information Systems Corp."},
  {max_prefix: "00-06-F9",vendor: "Mitsui Zosen Systems Research Inc."},
  {max_prefix: "00-06-F1",vendor: "Optillion"},
  {max_prefix: "00-06-E0",vendor: "MAT Co., Ltd."},
  {max_prefix: "00-06-D7",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-06-D5",vendor: "Diamond Systems Corp."},
  {max_prefix: "00-D0-B9",vendor: "MICROTEK INTERNATIONAL, INC."},
  {max_prefix: "00-D0-5F",vendor: "VALCOM, INC."},
  {max_prefix: "00-06-75",vendor: "Banderacom, Inc."},
  {max_prefix: "00-06-7B",vendor: "Toplink C&C Corporation"},
  {max_prefix: "00-06-70",vendor: "Upponetti Oy"},
  {max_prefix: "00-05-EA",vendor: "Rednix"},
  {max_prefix: "00-06-C9",vendor: "Technical Marketing Research, Inc."},
  {max_prefix: "00-06-C8",vendor: "Sumitomo Metal Micro Devices, Inc."},
  {max_prefix: "00-06-C0",vendor: "United Internetworks, Inc."},
  {max_prefix: "00-06-98",vendor: "egnite GmbH"},
  {max_prefix: "00-06-9C",vendor: "Transmode Systems AB"},
  {max_prefix: "00-06-AF",vendor: "Xalted Networks"},
  {max_prefix: "00-06-A7",vendor: "Primarion"},
  {max_prefix: "00-06-A9",vendor: "Universal Instruments Corp."},
  {max_prefix: "00-06-9E",vendor: "UNIQA, Inc."},
  {max_prefix: "00-06-68",vendor: "Vicon Industries Inc."},
  {max_prefix: "00-06-6D",vendor: "Compuprint S.P.A."},
  {max_prefix: "00-06-58",vendor: "Helmut Fischer GmbH Institut f\u00fcr Elektronik und Messtechnik"},
  {max_prefix: "00-06-49",vendor: "3M Deutschland GmbH"},
  {max_prefix: "00-06-43",vendor: "SONO Computer Co., Ltd."},
  {max_prefix: "00-06-3E",vendor: "Opthos Inc."},
  {max_prefix: "00-06-6F",vendor: "Korea Data Systems"},
  {max_prefix: "00-06-86",vendor: "ZARDCOM Co., Ltd."},
  {max_prefix: "00-06-89",vendor: "yLez Technologies Pte Ltd"},
  {max_prefix: "00-06-81",vendor: "Goepel Electronic GmbH"},
  {max_prefix: "00-06-B1",vendor: "Sonicwall"},
  {max_prefix: "00-06-AD",vendor: "KB Electronics Ltd."},
  {max_prefix: "00-06-3B",vendor: "Arcturus Networks Inc."},
  {max_prefix: "00-06-26",vendor: "MWE GmbH"},
  {max_prefix: "00-06-06",vendor: "RapidWAN, Inc."},
  {max_prefix: "00-06-03",vendor: "Baker Hughes Inc."},
  {max_prefix: "00-06-07",vendor: "Omni Directional Control Technology Inc."},
  {max_prefix: "00-05-F7",vendor: "Analog Devices, Inc."},
  {max_prefix: "00-06-13",vendor: "Kawasaki Microelectronics Incorporated"},
  {max_prefix: "00-05-D3",vendor: "eProduction Solutions, Inc."},
  {max_prefix: "00-06-04",vendor: "@Track Communications, Inc."},
  {max_prefix: "00-05-9C",vendor: "Kleinknecht GmbH, Ing. B\u00fcro"},
  {max_prefix: "00-05-A2",vendor: "CELOX Networks"},
  {max_prefix: "00-05-AA",vendor: "Moore Industries International Inc."},
  {max_prefix: "00-05-AE",vendor: "Mediaport USA"},
  {max_prefix: "00-05-B0",vendor: "Korea Computer Technology Co., Ltd."},
  {max_prefix: "00-05-B2",vendor: "Medison Co., Ltd."},
  {max_prefix: "00-06-18",vendor: "DigiPower Manufacturing Inc."},
  {max_prefix: "00-06-20",vendor: "Serial System Ltd."},
  {max_prefix: "00-06-17",vendor: "Redswitch Inc."},
  {max_prefix: "00-05-C8",vendor: "VERYTECH"},
  {max_prefix: "00-05-CB",vendor: "ROIS Technologies, Inc."},
  {max_prefix: "00-05-CD",vendor: "D&M Holdings Inc."},
  {max_prefix: "00-05-E7",vendor: "Netrake an AudioCodes Company"},
  {max_prefix: "00-05-F4",vendor: "System Base Co., Ltd."},
  {max_prefix: "00-05-E1",vendor: "Trellis Photonics, Ltd."},
  {max_prefix: "00-05-E2",vendor: "Creativ Network Technologies"},
  {max_prefix: "00-05-4D",vendor: "Brans Technologies, Inc."},
  {max_prefix: "00-05-47",vendor: "Starent Networks"},
  {max_prefix: "00-05-4E",vendor: "Philips"},
  {max_prefix: "00-05-46",vendor: "KDDI Network & Solultions Inc."},
  {max_prefix: "00-05-3C",vendor: "XIRCOM"},
  {max_prefix: "00-05-96",vendor: "Genotech Co., Ltd."},
  {max_prefix: "00-05-79",vendor: "Universal Control Solution Corp."},
  {max_prefix: "00-05-2E",vendor: "Cinta Networks"},
  {max_prefix: "00-05-2F",vendor: "Leviton Network Solutions"},
  {max_prefix: "00-05-3B",vendor: "Harbour Networks Ltd., Co. Beijing"},
  {max_prefix: "00-05-28",vendor: "New Focus, Inc."},
  {max_prefix: "00-05-75",vendor: "CDS-Electronics BV"},
  {max_prefix: "00-05-6B",vendor: "C.P. Technology Co., Ltd."},
  {max_prefix: "00-05-60",vendor: "LEADER COMM.CO., LTD"},
  {max_prefix: "00-05-5E",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-05-44",vendor: "Valley Technologies, Inc."},
  {max_prefix: "00-05-97",vendor: "Eagle Traffic Control Systems"},
  {max_prefix: "00-05-B5",vendor: "Broadcom Technologies"},
  {max_prefix: "00-05-8C",vendor: "Opentech Inc."},
  {max_prefix: "00-05-56",vendor: "360 Systems"},
  {max_prefix: "00-05-59",vendor: "Intracom S.A."},
  {max_prefix: "00-05-51",vendor: "F & S Elektronik Systeme GmbH"},
  {max_prefix: "00-05-24",vendor: "BTL System (HK) Limited"},
  {max_prefix: "00-05-22",vendor: "LEA*D Corporation, Inc."},
  {max_prefix: "00-05-20",vendor: "Smartronix, Inc."},
  {max_prefix: "00-05-7F",vendor: "Acqis Technology"},
  {max_prefix: "00-05-73",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-04-BE",vendor: "OptXCon, Inc."},
  {max_prefix: "00-04-C1",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-04-C4",vendor: "Audiotonix Group Limited"},
  {max_prefix: "00-04-B7",vendor: "AMB i.t. Holding"},
  {max_prefix: "00-04-B9",vendor: "S.I. Soubou, Inc."},
  {max_prefix: "00-04-BB",vendor: "Bardac Corporation"},
  {max_prefix: "00-04-BC",vendor: "Giantec, Inc."},
  {max_prefix: "00-04-AF",vendor: "Digital Fountain, Inc."},
  {max_prefix: "00-04-D6",vendor: "Takagi Industrial Co., Ltd."},
  {max_prefix: "00-04-D1",vendor: "Drew Technologies, Inc."},
  {max_prefix: "00-04-D0",vendor: "Softlink s.r.o."},
  {max_prefix: "00-80-87",vendor: "OKI ELECTRIC INDUSTRY CO., LTD"},
  {max_prefix: "00-04-D9",vendor: "Titan Electronics, Inc."},
  {max_prefix: "00-04-D8",vendor: "IPWireless, Inc."},
  {max_prefix: "00-04-B2",vendor: "ESSEGI SRL"},
  {max_prefix: "00-04-B4",vendor: "CIAC"},
  {max_prefix: "00-04-A9",vendor: "SandStream Technologies, Inc."},
  {max_prefix: "00-04-EB",vendor: "Paxonet Communications, Inc."},
  {max_prefix: "00-04-EF",vendor: "Polestar Corp."},
  {max_prefix: "00-05-14",vendor: "KDT Systems Co., Ltd."},
  {max_prefix: "00-05-0B",vendor: "SICOM Systems, Inc."},
  {max_prefix: "00-05-05",vendor: "Systems Integration Solutions, Inc."},
  {max_prefix: "00-04-FE",vendor: "Pelago Networks"},
  {max_prefix: "00-05-18",vendor: "Jupiters Technology"},
  {max_prefix: "00-04-61",vendor: "EPOX Computer Co., Ltd."},
  {max_prefix: "00-04-62",vendor: "DAKOS Data & Communication Co., Ltd."},
  {max_prefix: "00-04-5F",vendor: "Avalue Technology, Inc."},
  {max_prefix: "00-04-56",vendor: "Cambium Networks Limited"},
  {max_prefix: "00-04-46",vendor: "CYZENTECH Co., Ltd."},
  {max_prefix: "00-04-4A",vendor: "iPolicy Networks, Inc."},
  {max_prefix: "00-04-40",vendor: "cyberPIXIE, Inc."},
  {max_prefix: "00-04-3C",vendor: "SONOS Co., Ltd."},
  {max_prefix: "00-04-9C",vendor: "Surgient Networks, Inc."},
  {max_prefix: "00-04-9D",vendor: "Ipanema Technologies"},
  {max_prefix: "00-04-60",vendor: "Knilink Technology, Inc."},
  {max_prefix: "00-04-8F",vendor: "TD Systems Corporation"},
  {max_prefix: "00-04-19",vendor: "Fibercycle Networks, Inc."},
  {max_prefix: "00-04-1C",vendor: "ipDialog, Inc."},
  {max_prefix: "00-04-18",vendor: "Teltronic S.A.U."},
  {max_prefix: "00-03-70",vendor: "NXTV, Inc."},
  {max_prefix: "00-04-68",vendor: "Vivity, Inc."},
  {max_prefix: "00-04-6F",vendor: "Digitel S/A Industria Eletronica"},
  {max_prefix: "00-04-69",vendor: "Innocom, Inc."},
  {max_prefix: "00-04-02",vendor: "Nexsan Technologies, Ltd."},
  {max_prefix: "00-03-F7",vendor: "Plast-Control GmbH"},
  {max_prefix: "00-03-FC",vendor: "Intertex Data AB"},
  {max_prefix: "00-04-95",vendor: "Tejas Networks India Limited"},
  {max_prefix: "00-04-87",vendor: "Cogency Semiconductor, Inc."},
  {max_prefix: "00-04-2C",vendor: "Minet, Inc."},
  {max_prefix: "00-04-2A",vendor: "Wireless Networks, Inc."},
  {max_prefix: "00-04-58",vendor: "Fusion X Co., Ltd."},
  {max_prefix: "00-04-4F",vendor: "Schubert System Elektronik Gmbh"},
  {max_prefix: "00-03-E1",vendor: "Winmate Communication, Inc."},
  {max_prefix: "00-03-E4",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-03-DC",vendor: "Lexar Media, Inc."},
  {max_prefix: "00-03-D8",vendor: "iMPath Networks, Inc."},
  {max_prefix: "00-03-D5",vendor: "Advanced Communications Co., Ltd."},
  {max_prefix: "00-03-D6",vendor: "RADVision, Ltd."},
  {max_prefix: "00-03-C8",vendor: "CML Emergency Services"},
  {max_prefix: "00-03-C3",vendor: "Micronik Multimedia"},
  {max_prefix: "00-03-C0",vendor: "RFTNC Co., Ltd."},
  {max_prefix: "00-03-BD",vendor: "OmniCluster Technologies, Inc."},
  {max_prefix: "00-03-B8",vendor: "NetKit Solutions, LLC"},
  {max_prefix: "00-03-B7",vendor: "ZACCESS Systems"},
  {max_prefix: "00-03-B0",vendor: "Xsense Technology Corp."},
  {max_prefix: "00-03-AA",vendor: "Watlow"},
  {max_prefix: "00-03-A8",vendor: "IDOT Computers, Inc."},
  {max_prefix: "00-03-EF",vendor: "Oneline AG"},
  {max_prefix: "00-03-F1",vendor: "Cicada Semiconductor, Inc."},
  {max_prefix: "00-03-ED",vendor: "Shinkawa Electric Co., Ltd."},
  {max_prefix: "00-03-A0",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-03-A2",vendor: "Catapult Communications"},
  {max_prefix: "00-03-9C",vendor: "OptiMight Communications, Inc."},
  {max_prefix: "00-03-D4",vendor: "Alloptic, Inc."},
  {max_prefix: "00-03-CE",vendor: "ETEN Technologies, Inc."},
  {max_prefix: "00-03-85",vendor: "Actelis Networks, Inc."},
  {max_prefix: "00-03-69",vendor: "Nippon Antenna Co., Ltd."},
  {max_prefix: "00-03-73",vendor: "Aselsan A.S"},
  {max_prefix: "00-03-77",vendor: "Gigabit Wireless"},
  {max_prefix: "00-03-55",vendor: "TeraBeam Internet Systems"},
  {max_prefix: "00-03-54",vendor: "Fiber Logic Communications"},
  {max_prefix: "00-03-50",vendor: "BTICINO SPA"},
  {max_prefix: "00-03-4E",vendor: "Pos Data Company, Ltd."},
  {max_prefix: "00-03-48",vendor: "Norscan Instruments, Ltd."},
  {max_prefix: "00-03-44",vendor: "Tietech.Co., Ltd."},
  {max_prefix: "00-02-E6",vendor: "Gould Instrument Systems, Inc."},
  {max_prefix: "00-02-E4",vendor: "JC HYUN Systems, Inc."},
  {max_prefix: "00-02-DE",vendor: "Astrodesign, Inc."},
  {max_prefix: "00-02-E2",vendor: "NDC Infared Engineering"},
  {max_prefix: "00-02-DC",vendor: "Fujitsu General Limited"},
  {max_prefix: "00-02-E1",vendor: "Integrated Network Corporation"},
  {max_prefix: "00-02-D7",vendor: "EMPEG Ltd"},
  {max_prefix: "00-02-F8",vendor: "SEAKR Engineering, Inc."},
  {max_prefix: "00-02-F7",vendor: "ARM"},
  {max_prefix: "00-D0-24",vendor: "Cognex Corporation"},
  {max_prefix: "00-02-F1",vendor: "Pinetron Co., Ltd."},
  {max_prefix: "00-02-ED",vendor: "DXO Telecom Co., Ltd."},
  {max_prefix: "00-02-EC",vendor: "Maschoff Design Engineering"},
  {max_prefix: "00-02-D3",vendor: "NetBotz, Inc."},
  {max_prefix: "00-02-DA",vendor: "ExiO Communications, Inc."},
  {max_prefix: "00-02-D4",vendor: "PDA Peripherals, Inc."},
  {max_prefix: "00-02-D6",vendor: "NICE Systems"},
  {max_prefix: "00-03-43",vendor: "Martin Professional A/S"},
  {max_prefix: "00-03-35",vendor: "Mirae Technology"},
  {max_prefix: "00-03-36",vendor: "Zetes Technologies"},
  {max_prefix: "00-03-37",vendor: "Vaone, Inc."},
  {max_prefix: "00-03-65",vendor: "Kira Information & Communications, Ltd."},
  {max_prefix: "00-03-3B",vendor: "TAMI Tech Co., Ltd."},
  {max_prefix: "00-03-2D",vendor: "IBASE Technology, Inc."},
  {max_prefix: "00-03-2F",vendor: "Global Sun Technology, Inc."},
  {max_prefix: "00-B0-52",vendor: "Atheros Communications"},
  {max_prefix: "00-03-14",vendor: "Teleware Network Systems"},
  {max_prefix: "00-02-5C",vendor: "SCI Systems (Kunshan) Co., Ltd."},
  {max_prefix: "00-00-87",vendor: "HITACHI, LTD."},
  {max_prefix: "00-02-58",vendor: "Flying Packets Communications"},
  {max_prefix: "00-02-55",vendor: "IBM Corp"},
  {max_prefix: "00-02-52",vendor: "Carrier Corporation"},
  {max_prefix: "00-02-4E",vendor: "Datacard Group"},
  {max_prefix: "00-02-42",vendor: "Videoframe Systems"},
  {max_prefix: "00-02-44",vendor: "SURECOM Technology Co."},
  {max_prefix: "00-02-3E",vendor: "Selta Telematica S.p.a"},
  {max_prefix: "00-02-41",vendor: "Amer.com"},
  {max_prefix: "00-02-7D",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-02-7C",vendor: "Trilithic, Inc."},
  {max_prefix: "00-02-75",vendor: "SMART Technologies, Inc."},
  {max_prefix: "00-02-69",vendor: "Nadatel Co., Ltd"},
  {max_prefix: "00-02-64",vendor: "AudioRamp.com"},
  {max_prefix: "00-02-5F",vendor: "Nortel Networks"},
  {max_prefix: "00-02-99",vendor: "Apex, Inc."},
  {max_prefix: "00-02-98",vendor: "Broadframe Corporation"},
  {max_prefix: "00-02-97",vendor: "C-COR.net"},
  {max_prefix: "00-02-91",vendor: "Open Network Co., Ltd."},
  {max_prefix: "00-02-C6",vendor: "Data Track Technology PLC"},
  {max_prefix: "00-02-C1",vendor: "Innovative Electronic Designs, Inc."},
  {max_prefix: "00-02-C0",vendor: "Bencent Tzeng Industry Co., Ltd."},
  {max_prefix: "00-01-7A",vendor: "Chengdu Maipu Electric Industrial Co., Ltd."},
  {max_prefix: "00-02-38",vendor: "Serome Technology, Inc."},
  {max_prefix: "00-02-29",vendor: "Adtec Corporation"},
  {max_prefix: "00-02-8B",vendor: "VDSL Systems OY"},
  {max_prefix: "00-02-8C",vendor: "Micrel-Synergy Semiconductor"},
  {max_prefix: "00-02-8D",vendor: "Movita Technologies, Inc."},
  {max_prefix: "00-02-A6",vendor: "Effinet Systems Co., Ltd."},
  {max_prefix: "00-01-E5",vendor: "Supernet, Inc."},
  {max_prefix: "00-01-D4",vendor: "Leisure Time, Inc."},
  {max_prefix: "00-01-DD",vendor: "Avail Networks"},
  {max_prefix: "00-01-D5",vendor: "HAEDONG INFO & COMM CO., LTD"},
  {max_prefix: "00-01-D7",vendor: "F5 Networks, Inc."},
  {max_prefix: "00-01-DE",vendor: "Trango Systems, Inc."},
  {max_prefix: "00-01-DC",vendor: "Activetelco"},
  {max_prefix: "00-02-07",vendor: "VisionGlobal Network Corp."},
  {max_prefix: "00-02-08",vendor: "Unify Networks, Inc."},
  {max_prefix: "00-02-04",vendor: "Bodmann Industries Elektronik GmbH"},
  {max_prefix: "00-01-F9",vendor: "TeraGlobal Communications Corp."},
  {max_prefix: "00-01-FB",vendor: "DoTop Technology, Inc."},
  {max_prefix: "00-01-BF",vendor: "Teleforce Co., Ltd."},
  {max_prefix: "00-01-B4",vendor: "Wayport, Inc."},
  {max_prefix: "00-01-BA",vendor: "IC-Net, Inc."},
  {max_prefix: "00-01-B0",vendor: "Fulltek Technology Co., Ltd."},
  {max_prefix: "00-01-A9",vendor: "BMW AG"},
  {max_prefix: "00-01-AA",vendor: "Airspan Communications, Ltd."},
  {max_prefix: "00-01-9E",vendor: "ESS Technology, Inc."},
  {max_prefix: "00-02-11",vendor: "Nature Worldwide Technology Corp."},
  {max_prefix: "00-02-12",vendor: "SierraCom"},
  {max_prefix: "00-02-17",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-02-2D",vendor: "Agere Systems"},
  {max_prefix: "00-02-26",vendor: "XESystems, Inc."},
  {max_prefix: "00-02-25",vendor: "One Stop Systems"},
  {max_prefix: "00-02-20",vendor: "CANON FINETECH INC."},
  {max_prefix: "00-02-0D",vendor: "Micronpc.com"},
  {max_prefix: "00-01-CF",vendor: "Alpha Data Parallel Systems, Ltd."},
  {max_prefix: "00-01-D0",vendor: "VitalPoint, Inc."},
  {max_prefix: "00-01-C7",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-01-7D",vendor: "ThermoQuest"},
  {max_prefix: "00-01-81",vendor: "Nortel Networks"},
  {max_prefix: "00-01-94",vendor: "Capital Equipment Corporation"},
  {max_prefix: "00-01-98",vendor: "Darim Vision"},
  {max_prefix: "00-01-F8",vendor: "TEXIO TECHNOLOGY CORPORATION"},
  {max_prefix: "00-01-E8",vendor: "Force10 Networks, Inc."},
  {max_prefix: "00-01-E9",vendor: "Litton Marine Systems B.V."},
  {max_prefix: "00-01-BE",vendor: "Gigalink Co., Ltd."},
  {max_prefix: "00-01-B1",vendor: "General Bandwidth"},
  {max_prefix: "00-01-0A",vendor: "CIS TECHNOLOGY INC."},
  {max_prefix: "00-01-62",vendor: "Cygnet Technologies, Inc."},
  {max_prefix: "00-01-67",vendor: "HIOKI E.E. CORPORATION"},
  {max_prefix: "00-01-68",vendor: "VITANA CORPORATION"},
  {max_prefix: "00-01-5F",vendor: "DIGITAL DESIGN GmbH"},
  {max_prefix: "00-01-64",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-B0-4A",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-B0-48",vendor: "Marconi Communications Inc."},
  {max_prefix: "00-B0-CE",vendor: "Viveris Technologies"},
  {max_prefix: "00-B0-1C",vendor: "Westport Technologies"},
  {max_prefix: "00-01-19",vendor: "RTUnet (Australia)"},
  {max_prefix: "00-01-22",vendor: "Trend Communications, Ltd."},
  {max_prefix: "00-01-1A",vendor: "Hoffmann und Burmeister GbR"},
  {max_prefix: "00-01-24",vendor: "Acer Incorporated"},
  {max_prefix: "00-01-42",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-01-2C",vendor: "Aravox Technologies, Inc."},
  {max_prefix: "00-01-21",vendor: "WatchGuard Technologies, Inc."},
  {max_prefix: "00-01-29",vendor: "DFI Inc."},
  {max_prefix: "00-01-54",vendor: "G3M Corporation"},
  {max_prefix: "00-01-52",vendor: "CHROMATEK INC."},
  {max_prefix: "00-01-50",vendor: "GILAT COMMUNICATIONS, LTD."},
  {max_prefix: "00-01-51",vendor: "Ensemble Communications"},
  {max_prefix: "00-01-15",vendor: "EXTRATECH CORPORATION"},
  {max_prefix: "00-01-01",vendor: "Private"},
  {max_prefix: "00-01-0D",vendor: "Teledyne DALSA Inc."},
  {max_prefix: "00-01-05",vendor: "Beckhoff Automation GmbH"},
  {max_prefix: "00-B0-17",vendor: "InfoGear Technology Corp."},
  {max_prefix: "00-01-2B",vendor: "TELENET Co., Ltd."},
  {max_prefix: "00-01-3F",vendor: "Neighbor World Co., Ltd."},
  {max_prefix: "00-01-85",vendor: "Hitachi Aloka Medical, Ltd."},
  {max_prefix: "00-01-A2",vendor: "Logical Co., Ltd."},
  {max_prefix: "00-30-3A",vendor: "MAATEL"},
  {max_prefix: "00-30-2C",vendor: "SYLANTRO SYSTEMS CORPORATION"},
  {max_prefix: "00-30-DF",vendor: "KB/TEL TELECOMUNICACIONES"},
  {max_prefix: "00-30-4E",vendor: "BUSTEC PRODUCTION LTD."},
  {max_prefix: "00-30-63",vendor: "SANTERA SYSTEMS, INC."},
  {max_prefix: "00-30-30",vendor: "HARMONIX CORPORATION"},
  {max_prefix: "00-30-A3",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-30-FD",vendor: "INTEGRATED SYSTEMS DESIGN"},
  {max_prefix: "00-30-B9",vendor: "ECTEL"},
  {max_prefix: "00-30-7D",vendor: "GRE AMERICA, INC."},
  {max_prefix: "00-30-21",vendor: "HSING TECH. ENTERPRISE CO.,LTD"},
  {max_prefix: "00-B0-2D",vendor: "ViaGate Technologies, Inc."},
  {max_prefix: "00-30-EE",vendor: "DSG Technology, Inc."},
  {max_prefix: "00-30-9E",vendor: "WORKBIT CORPORATION."},
  {max_prefix: "00-30-DE",vendor: "WAGO Kontakttechnik GmbH"},
  {max_prefix: "00-30-3E",vendor: "Radcom Ltd."},
  {max_prefix: "00-30-D7",vendor: "Innovative Systems, L.L.C."},
  {max_prefix: "00-30-A4",vendor: "Woodwind Communications System"},
  {max_prefix: "00-30-3B",vendor: "PowerCom Technology"},
  {max_prefix: "00-30-BC",vendor: "Optronic AG"},
  {max_prefix: "00-30-B8",vendor: "RiverDelta Networks"},
  {max_prefix: "00-30-71",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-30-88",vendor: "Ericsson"},
  {max_prefix: "00-30-B5",vendor: "Tadiran Microwave Networks"},
  {max_prefix: "00-30-20",vendor: "TSI, Inc.."},
  {max_prefix: "00-30-EF",vendor: "NEON TECHNOLOGY, INC."},
  {max_prefix: "00-30-95",vendor: "Procomp Informatics, Ltd."},
  {max_prefix: "00-30-CA",vendor: "Discovery Com"},
  {max_prefix: "00-30-CE",vendor: "Zaffire"},
  {max_prefix: "00-30-96",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-30-39",vendor: "SOFTBOOK PRESS"},
  {max_prefix: "00-30-1B",vendor: "SHUTTLE, INC."},
  {max_prefix: "00-30-7B",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-D0-3B",vendor: "VISION PRODUCTS PTY. LTD."},
  {max_prefix: "00-D0-B3",vendor: "DRS Technologies Canada Ltd"},
  {max_prefix: "00-D0-00",vendor: "FERRAN SCIENTIFIC, INC."},
  {max_prefix: "00-D0-D0",vendor: "ZHONGXING TELECOM LTD."},
  {max_prefix: "00-D0-53",vendor: "CONNECTED SYSTEMS"},
  {max_prefix: "00-D0-97",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-D0-8E",vendor: "Grass Valley, A Belden Brand"},
  {max_prefix: "00-D0-56",vendor: "SOMAT CORPORATION"},
  {max_prefix: "00-D0-E0",vendor: "DOOIN ELECTRONICS CO."},
  {max_prefix: "00-D0-33",vendor: "DALIAN DAXIAN NETWORK"},
  {max_prefix: "00-D0-D6",vendor: "AETHRA TELECOMUNICAZIONI"},
  {max_prefix: "00-D0-63",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-D0-F8",vendor: "FUJIAN STAR TERMINAL"},
  {max_prefix: "00-D0-ED",vendor: "XIOX"},
  {max_prefix: "00-30-27",vendor: "KERBANGO, INC."},
  {max_prefix: "00-30-33",vendor: "ORIENT TELECOM CO., LTD."},
  {max_prefix: "00-30-DD",vendor: "INDIGITA CORPORATION"},
  {max_prefix: "00-30-99",vendor: "BOENIG UND KALLENBACH OHG"},
  {max_prefix: "00-30-F2",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-30-51",vendor: "ORBIT AVIONIC & COMMUNICATION"},
  {max_prefix: "00-30-08",vendor: "AVIO DIGITAL, INC."},
  {max_prefix: "00-30-1D",vendor: "SKYSTREAM, INC."},
  {max_prefix: "00-30-BA",vendor: "AC&T SYSTEM CO., LTD."},
  {max_prefix: "00-D0-30",vendor: "Safetran Systems Corp"},
  {max_prefix: "00-D0-47",vendor: "XN TECHNOLOGIES"},
  {max_prefix: "00-D0-55",vendor: "KATHREIN-WERKE KG"},
  {max_prefix: "00-D0-B0",vendor: "BITSWITCH LTD."},
  {max_prefix: "00-30-2A",vendor: "SOUTHERN INFORMATION"},
  {max_prefix: "00-30-E1",vendor: "Network Equipment Technologies, Inc."},
  {max_prefix: "00-30-01",vendor: "SMP"},
  {max_prefix: "00-D0-21",vendor: "REGENT ELECTRONICS CORP."},
  {max_prefix: "00-D0-9F",vendor: "NOVTEK TEST SYSTEMS"},
  {max_prefix: "00-D0-FE",vendor: "ASTRAL POINT"},
  {max_prefix: "00-D0-D4",vendor: "V-BITS, INC."},
  {max_prefix: "00-D0-E7",vendor: "VCON TELECOMMUNICATION LTD."},
  {max_prefix: "00-D0-84",vendor: "NEXCOMM SYSTEMS, INC."},
  {max_prefix: "00-D0-99",vendor: "Elcard Wireless Systems Oy"},
  {max_prefix: "00-D0-1B",vendor: "MIMAKI ENGINEERING CO., LTD."},
  {max_prefix: "00-D0-83",vendor: "INVERTEX, INC."},
  {max_prefix: "00-D0-BA",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-D0-E4",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-D0-8B",vendor: "ADVA Optical Networking Ltd."},
  {max_prefix: "00-D0-5A",vendor: "SYMBIONICS, LTD."},
  {max_prefix: "00-D0-79",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-D0-98",vendor: "Photon Dynamics Canada Inc."},
  {max_prefix: "00-D0-BE",vendor: "EMUTEC INC."},
  {max_prefix: "00-D0-F4",vendor: "CARINTHIAN TECH INSTITUTE"},
  {max_prefix: "00-D0-7D",vendor: "COSINE COMMUNICATIONS"},
  {max_prefix: "00-D0-AF",vendor: "CUTLER-HAMMER, INC."},
  {max_prefix: "00-D0-52",vendor: "ASCEND COMMUNICATIONS, INC."},
  {max_prefix: "00-D0-AD",vendor: "TL INDUSTRIES"},
  {max_prefix: "00-D0-A4",vendor: "ALANTRO COMMUNICATIONS"},
  {max_prefix: "00-D0-0D",vendor: "MICROMERITICS INSTRUMENT"},
  {max_prefix: "00-D0-4A",vendor: "PRESENCE TECHNOLOGY GMBH"},
  {max_prefix: "00-D0-74",vendor: "TAQUA SYSTEMS, INC."},
  {max_prefix: "00-D0-C7",vendor: "PATHWAY, INC."},
  {max_prefix: "00-D0-7A",vendor: "AMAQUEST COMPUTER CORP."},
  {max_prefix: "00-D0-B8",vendor: "Iomega Corporation"},
  {max_prefix: "00-50-9D",vendor: "THE INDUSTREE B.V."},
  {max_prefix: "00-D0-54",vendor: "SAS INSTITUTE INC."},
  {max_prefix: "00-D0-09",vendor: "HSING TECH. ENTERPRISE CO. LTD"},
  {max_prefix: "00-D0-92",vendor: "GLENAYRE WESTERN MULTIPLEX"},
  {max_prefix: "00-50-58",vendor: "Sangoma Technologies"},
  {max_prefix: "00-50-FE",vendor: "PCTVnet ASA"},
  {max_prefix: "00-50-AB",vendor: "NALTEC, Inc."},
  {max_prefix: "00-50-37",vendor: "KOGA ELECTRONICS CO."},
  {max_prefix: "00-50-06",vendor: "TAC AB"},
  {max_prefix: "00-50-09",vendor: "PHILIPS BROADBAND NETWORKS"},
  {max_prefix: "00-50-30",vendor: "FUTURE PLUS SYSTEMS"},
  {max_prefix: "00-50-D6",vendor: "ATLAS COPCO TOOLS AB"},
  {max_prefix: "00-50-82",vendor: "FORESSON CORPORATION"},
  {max_prefix: "00-50-DF",vendor: "AirFiber, Inc."},
  {max_prefix: "00-50-AA",vendor: "KONICA MINOLTA HOLDINGS, INC."},
  {max_prefix: "00-50-38",vendor: "DAIN TELECOM CO., LTD."},
  {max_prefix: "00-50-9E",vendor: "Les Technologies SoftAcoustik Inc."},
  {max_prefix: "00-50-71",vendor: "AIWA CO., LTD."},
  {max_prefix: "00-50-5F",vendor: "BRAND INNOVATORS"},
  {max_prefix: "00-50-B4",vendor: "SATCHWELL CONTROL SYSTEMS, LTD"},
  {max_prefix: "00-50-D0",vendor: "MINERVA SYSTEMS"},
  {max_prefix: "00-50-D8",vendor: "UNICORN COMPUTER CORP."},
  {max_prefix: "00-50-A8",vendor: "OpenCon Systems, Inc."},
  {max_prefix: "00-50-9C",vendor: "BETA RESEARCH"},
  {max_prefix: "00-50-23",vendor: "PG DESIGN ELECTRONICS, INC."},
  {max_prefix: "00-50-B1",vendor: "GIDDINGS & LEWIS"},
  {max_prefix: "00-50-B7",vendor: "BOSER TECHNOLOGY CO., LTD."},
  {max_prefix: "00-50-78",vendor: "MEGATON HOUSE, LTD."},
  {max_prefix: "00-50-02",vendor: "OMNISEC AG"},
  {max_prefix: "00-50-6A",vendor: "EDEVA, INC."},
  {max_prefix: "00-50-C5",vendor: "ADS Technologies, Inc"},
  {max_prefix: "00-50-8E",vendor: "OPTIMATION, INC."},
  {max_prefix: "00-50-28",vendor: "AVAL COMMUNICATIONS"},
  {max_prefix: "00-50-2F",vendor: "TollBridge Technologies, Inc."},
  {max_prefix: "00-50-74",vendor: "ADVANCED HI-TECH CORP."},
  {max_prefix: "00-50-0A",vendor: "IRIS TECHNOLOGIES, INC."},
  {max_prefix: "00-50-70",vendor: "CHAINTECH COMPUTER CO., LTD."},
  {max_prefix: "00-50-3F",vendor: "ANCHOR GAMES"},
  {max_prefix: "00-50-32",vendor: "PICAZO COMMUNICATIONS, INC."},
  {max_prefix: "00-50-6D",vendor: "VIDEOJET SYSTEMS"},
  {max_prefix: "00-90-08",vendor: "HanA Systems Inc."},
  {max_prefix: "00-90-88",vendor: "BAXALL SECURITY LTD."},
  {max_prefix: "00-90-6C",vendor: "Sartorius Hamburg GmbH"},
  {max_prefix: "00-90-A4",vendor: "ALTIGA NETWORKS"},
  {max_prefix: "00-90-F9",vendor: "Imagine Communications"},
  {max_prefix: "00-90-89",vendor: "SOFTCOM MICROSYSTEMS, INC."},
  {max_prefix: "00-90-EE",vendor: "PERSONAL COMMUNICATIONS TECHNOLOGIES"},
  {max_prefix: "00-90-80",vendor: "NOT LIMITED, INC."},
  {max_prefix: "00-90-E8",vendor: "MOXA TECHNOLOGIES CORP., LTD."},
  {max_prefix: "00-90-A1",vendor: "Flying Pig Systems/High End Systems Inc."},
  {max_prefix: "00-90-79",vendor: "ClearOne, Inc."},
  {max_prefix: "00-90-9A",vendor: "ONE WORLD SYSTEMS, INC."},
  {max_prefix: "00-90-C2",vendor: "JK microsystems, Inc."},
  {max_prefix: "00-90-AC",vendor: "OPTIVISION, INC."},
  {max_prefix: "00-50-B2",vendor: "BRODEL GmbH"},
  {max_prefix: "00-90-76",vendor: "FMT AIRCRAFT GATE SUPPORT SYSTEMS AB"},
  {max_prefix: "00-90-17",vendor: "Zypcom, Inc"},
  {max_prefix: "00-90-49",vendor: "ENTRIDIA CORPORATION"},
  {max_prefix: "00-90-43",vendor: "Tattile SRL"},
  {max_prefix: "00-90-AD",vendor: "ASPECT ELECTRONICS, INC."},
  {max_prefix: "00-90-E6",vendor: "ALi Corporation"},
  {max_prefix: "00-90-70",vendor: "NEO NETWORKS, INC."},
  {max_prefix: "00-90-30",vendor: "HONEYWELL-DATING"},
  {max_prefix: "00-90-C8",vendor: "WAVERIDER COMMUNICATIONS (CANADA) INC."},
  {max_prefix: "00-90-1B",vendor: "DIGITAL CONTROLS"},
  {max_prefix: "00-90-4E",vendor: "DELEM BV"},
  {max_prefix: "00-90-ED",vendor: "CENTRAL SYSTEM RESEARCH CO., LTD."},
  {max_prefix: "00-90-75",vendor: "NEC DO BRASIL S.A."},
  {max_prefix: "00-90-01",vendor: "NISHIMU ELECTRONICS INDUSTRIES CO., LTD."},
  {max_prefix: "00-90-1E",vendor: "Selesta Ingegneria S.p.A."},
  {max_prefix: "00-10-EC",vendor: "Embedded Planet"},
  {max_prefix: "00-10-59",vendor: "DIABLO RESEARCH CO. LLC"},
  {max_prefix: "00-10-69",vendor: "HELIOSS COMMUNICATIONS, INC."},
  {max_prefix: "00-10-BF",vendor: "InterAir Wireless"},
  {max_prefix: "00-10-36",vendor: "INTER-TEL INTEGRATED SYSTEMS"},
  {max_prefix: "00-10-26",vendor: "ACCELERATED NETWORKS, INC."},
  {max_prefix: "00-10-AC",vendor: "IMCI TECHNOLOGIES"},
  {max_prefix: "00-10-FC",vendor: "BROADBAND NETWORKS, INC."},
  {max_prefix: "00-10-31",vendor: "OBJECTIVE COMMUNICATIONS, INC."},
  {max_prefix: "00-10-6D",vendor: "Axxcelera Broadband Wireless"},
  {max_prefix: "00-10-D0",vendor: "WITCOM, LTD."},
  {max_prefix: "00-10-93",vendor: "CMS COMPUTERS, LTD."},
  {max_prefix: "00-10-8F",vendor: "RAPTOR SYSTEMS"},
  {max_prefix: "00-10-63",vendor: "STARGUIDE DIGITAL NETWORKS"},
  {max_prefix: "00-10-23",vendor: "Network Equipment Technologies"},
  {max_prefix: "00-10-2B",vendor: "UMAX DATA SYSTEMS, INC."},
  {max_prefix: "00-10-8D",vendor: "Johnson Controls, Inc."},
  {max_prefix: "00-90-F7",vendor: "NBASE COMMUNICATIONS LTD."},
  {max_prefix: "00-90-12",vendor: "GLOBESPAN SEMICONDUCTOR, INC."},
  {max_prefix: "00-90-8A",vendor: "BAYLY COMMUNICATIONS, INC."},
  {max_prefix: "00-90-0E",vendor: "HANDLINK TECHNOLOGIES, INC."},
  {max_prefix: "00-10-45",vendor: "Nortel Networks"},
  {max_prefix: "00-10-7D",vendor: "AURORA COMMUNICATIONS, LTD."},
  {max_prefix: "00-10-39",vendor: "Vectron Systems AG"},
  {max_prefix: "00-10-B6",vendor: "ENTRATA COMMUNICATIONS CORP."},
  {max_prefix: "00-90-E4",vendor: "NEC AMERICA, INC."},
  {max_prefix: "00-90-C1",vendor: "Peco II, Inc."},
  {max_prefix: "00-90-40",vendor: "Siemens Network Convergence LLC"},
  {max_prefix: "00-90-47",vendor: "GIGA FAST E. LTD."},
  {max_prefix: "00-90-E1",vendor: "TELENA S.P.A."},
  {max_prefix: "00-90-CB",vendor: "Wireless OnLine, Inc."},
  {max_prefix: "00-90-B7",vendor: "DIGITAL LIGHTWAVE, INC."},
  {max_prefix: "00-90-A0",vendor: "8X8 INC."},
  {max_prefix: "00-90-32",vendor: "PELCOMBE GROUP LTD."},
  {max_prefix: "00-10-4C",vendor: "Teledyne LeCroy, Inc"},
  {max_prefix: "00-10-CC",vendor: "CLP COMPUTER LOGISTIK PLANUNG GmbH"},
  {max_prefix: "00-10-30",vendor: "EION Inc."},
  {max_prefix: "00-10-62",vendor: "NX SERVER, ILNC."},
  {max_prefix: "00-10-F0",vendor: "RITTAL-WERK RUDOLF LOH GmbH & Co."},
  {max_prefix: "00-10-01",vendor: "Citel"},
  {max_prefix: "00-10-5C",vendor: "QUANTUM DESIGNS (H.K.) LTD."},
  {max_prefix: "00-10-75",vendor: "Segate Technology LLC"},
  {max_prefix: "00-10-B1",vendor: "FOR-A CO., LTD."},
  {max_prefix: "00-10-EE",vendor: "CTI PRODUCTS, INC."},
  {max_prefix: "00-10-41",vendor: "BRISTOL BABCOCK, INC."},
  {max_prefix: "00-10-AA",vendor: "MEDIA4, INC."},
  {max_prefix: "00-10-E8",vendor: "TELOCITY, INCORPORATED"},
  {max_prefix: "00-10-A2",vendor: "TNS"},
  {max_prefix: "00-10-00",vendor: "CABLE TELEVISION LABORATORIES, INC."},
  {max_prefix: "00-10-09",vendor: "HORANET"},
  {max_prefix: "00-10-F8",vendor: "TEXIO TECHNOLOGY CORPORATION"},
  {max_prefix: "00-10-C0",vendor: "ARMA, Inc."},
  {max_prefix: "00-10-02",vendor: "ACTIA"},
  {max_prefix: "00-10-5B",vendor: "NET INSIGHT AB"},
  {max_prefix: "00-10-EB",vendor: "SELSIUS SYSTEMS, INC."},
  {max_prefix: "00-10-1D",vendor: "WINBOND ELECTRONICS CORP."},
  {max_prefix: "00-10-9F",vendor: "PAVO, INC."},
  {max_prefix: "00-10-84",vendor: "K-BOT COMMUNICATIONS"},
  {max_prefix: "00-10-F9",vendor: "UNIQUE SYSTEMS, INC."},
  {max_prefix: "00-10-CF",vendor: "FIBERLANE COMMUNICATIONS"},
  {max_prefix: "00-10-A4",vendor: "XIRCOM"},
  {max_prefix: "00-10-F1",vendor: "I-O CORPORATION"},
  {max_prefix: "00-10-57",vendor: "Rebel.com, Inc."},
  {max_prefix: "00-10-65",vendor: "RADYNE CORPORATION"},
  {max_prefix: "00-10-66",vendor: "ADVANCED CONTROL SYSTEMS, INC."},
  {max_prefix: "00-E0-9F",vendor: "PIXEL VISION"},
  {max_prefix: "00-E0-CC",vendor: "HERO SYSTEMS, LTD."},
  {max_prefix: "00-E0-80",vendor: "CONTROL RESOURCES CORPORATION"},
  {max_prefix: "00-E0-04",vendor: "PMC-SIERRA, INC."},
  {max_prefix: "00-E0-3B",vendor: "PROMINET CORPORATION"},
  {max_prefix: "00-E0-F5",vendor: "TELES AG"},
  {max_prefix: "00-E0-D7",vendor: "SUNSHINE ELECTRONICS, INC."},
  {max_prefix: "00-E0-95",vendor: "ADVANCED-VISION TECHNOLGIES CORP."},
  {max_prefix: "00-E0-0E",vendor: "AVALON IMAGING SYSTEMS, INC."},
  {max_prefix: "00-E0-48",vendor: "SDL COMMUNICATIONS, INC."},
  {max_prefix: "00-E0-CB",vendor: "RESON, INC."},
  {max_prefix: "00-E0-C8",vendor: "VIRTUAL ACCESS, LTD."},
  {max_prefix: "00-E0-06",vendor: "SILICON INTEGRATED SYS. CORP."},
  {max_prefix: "00-E0-AC",vendor: "MIDSCO, INC."},
  {max_prefix: "00-E0-FA",vendor: "TRL TECHNOLOGY, LTD."},
  {max_prefix: "00-E0-2C",vendor: "AST COMPUTER"},
  {max_prefix: "00-E0-67",vendor: "eac AUTOMATION-CONSULTING GmbH"},
  {max_prefix: "00-E0-08",vendor: "AMAZING CONTROLS! INC."},
  {max_prefix: "00-E0-AE",vendor: "XAQTI CORPORATION"},
  {max_prefix: "00-E0-E0",vendor: "SI ELECTRONICS, LTD."},
  {max_prefix: "00-E0-03",vendor: "NOKIA WIRELESS BUSINESS COMMUN"},
  {max_prefix: "00-E0-F3",vendor: "WebSprint Communications, Inc."},
  {max_prefix: "08-BB-CC",vendor: "AK-NORD EDV VERTRIEBSGES. mbH"},
  {max_prefix: "00-E0-DB",vendor: "ViaVideo Communications, Inc."},
  {max_prefix: "00-E0-A6",vendor: "TELOGY NETWORKS, INC."},
  {max_prefix: "00-60-4B",vendor: "Safe-com GmbH & Co. KG"},
  {max_prefix: "00-60-6B",vendor: "Synclayer Inc."},
  {max_prefix: "00-60-3B",vendor: "AMTEC spa"},
  {max_prefix: "00-E0-39",vendor: "PARADYNE CORP."},
  {max_prefix: "00-60-0B",vendor: "LOGWARE GmbH"},
  {max_prefix: "00-E0-EF",vendor: "DIONEX"},
  {max_prefix: "00-E0-51",vendor: "TALX CORPORATION"},
  {max_prefix: "00-E0-2D",vendor: "InnoMediaLogic, Inc."},
  {max_prefix: "00-E0-35",vendor: "Artesyn Embedded Technologies"},
  {max_prefix: "00-E0-90",vendor: "BECKMAN LAB. AUTOMATION DIV."},
  {max_prefix: "00-E0-85",vendor: "GLOBAL MAINTECH, INC."},
  {max_prefix: "00-E0-A0",vendor: "WILTRON CO."},
  {max_prefix: "00-E0-C7",vendor: "EUROTECH SRL"},
  {max_prefix: "00-E0-F1",vendor: "THAT CORPORATION"},
  {max_prefix: "00-E0-AF",vendor: "GENERAL DYNAMICS INFORMATION SYSTEMS"},
  {max_prefix: "00-E0-54",vendor: "KODAI HITEC CO., LTD."},
  {max_prefix: "00-E0-B9",vendor: "BYAS SYSTEMS"},
  {max_prefix: "00-E0-23",vendor: "TELRAD"},
  {max_prefix: "00-E0-2A",vendor: "TANDBERG TELEVISION AS"},
  {max_prefix: "00-E0-4E",vendor: "SANYO DENKI CO., LTD."},
  {max_prefix: "00-E0-12",vendor: "PLUTO TECHNOLOGIES INTERNATIONAL INC."},
  {max_prefix: "00-E0-4C",vendor: "REALTEK SEMICONDUCTOR CORP."},
  {max_prefix: "00-60-B5",vendor: "KEBA GmbH"},
  {max_prefix: "00-60-01",vendor: "InnoSys, Inc."},
  {max_prefix: "00-60-FE",vendor: "LYNX SYSTEM DEVELOPERS, INC."},
  {max_prefix: "00-60-BD",vendor: "Enginuity Communications"},
  {max_prefix: "00-08-00",vendor: "MULTITECH SYSTEMS, INC."},
  {max_prefix: "00-E0-B5",vendor: "ARDENT COMMUNICATIONS CORP."},
  {max_prefix: "00-E0-68",vendor: "MERRIMAC SYSTEMS INC."},
  {max_prefix: "00-E0-49",vendor: "MICROWI ELECTRONIC GmbH"},
  {max_prefix: "00-E0-50",vendor: "EXECUTONE INFORMATION SYSTEMS, INC."},
  {max_prefix: "00-E0-BE",vendor: "GENROCO INTERNATIONAL, INC."},
  {max_prefix: "00-E0-B6",vendor: "Entrada Networks"},
  {max_prefix: "00-E0-F4",vendor: "INSIDE Technology A/S"},
  {max_prefix: "00-60-7E",vendor: "GIGALABS, INC."},
  {max_prefix: "00-60-3F",vendor: "PATAPSCO DESIGNS"},
  {max_prefix: "00-60-D2",vendor: "LUCENT TECHNOLOGIES TAIWAN TELECOMMUNICATIONS CO., LTD."},
  {max_prefix: "00-60-CD",vendor: "VideoServer, Inc."},
  {max_prefix: "00-60-AA",vendor: "INTELLIGENT DEVICES INC. (IDI)"},
  {max_prefix: "00-60-65",vendor: "B&R Industrial Automation GmbH"},
  {max_prefix: "00-60-5D",vendor: "SCANIVALVE CORP."},
  {max_prefix: "00-60-2C",vendor: "LINX Data Terminals, Inc."},
  {max_prefix: "00-60-AC",vendor: "RESILIENCE CORPORATION"},
  {max_prefix: "00-60-4E",vendor: "CYCLE COMPUTER CORPORATION, INC."},
  {max_prefix: "00-60-14",vendor: "EDEC CO., LTD."},
  {max_prefix: "00-60-E1",vendor: "ORCKIT COMMUNICATIONS LTD."},
  {max_prefix: "00-60-E6",vendor: "SHOMITI SYSTEMS INCORPORATED"},
  {max_prefix: "00-60-FF",vendor: "QuVis, Inc."},
  {max_prefix: "00-60-67",vendor: "ACER NETXUS INC."},
  {max_prefix: "00-60-9F",vendor: "PHAST CORPORATION"},
  {max_prefix: "00-60-42",vendor: "TKS (USA), INC."},
  {max_prefix: "00-60-79",vendor: "Mainstream Data, Inc."},
  {max_prefix: "00-60-9A",vendor: "NJK TECHNO CO."},
  {max_prefix: "00-60-2B",vendor: "PEAK AUDIO"},
  {max_prefix: "00-60-F1",vendor: "EXP COMPUTER, INC."},
  {max_prefix: "00-05-A8",vendor: "WYLE ELECTRONICS"},
  {max_prefix: "00-60-40",vendor: "NETRO CORP."},
  {max_prefix: "00-60-CC",vendor: "EMTRAK, INCORPORATED"},
  {max_prefix: "00-60-6F",vendor: "CLARION CORPORATION OF AMERICA"},
  {max_prefix: "00-60-90",vendor: "Artiza Networks Inc"},
  {max_prefix: "00-60-25",vendor: "ACTIVE IMAGING PLC"},
  {max_prefix: "00-60-A7",vendor: "MICROSENS GmbH & CO. KG"},
  {max_prefix: "00-60-30",vendor: "VILLAGE TRONIC ENTWICKLUNG"},
  {max_prefix: "00-A0-0D",vendor: "THE PANDA PROJECT"},
  {max_prefix: "00-A0-E9",vendor: "ELECTRONIC RETAILING SYSTEMS INTERNATIONAL"},
  {max_prefix: "00-A0-BE",vendor: "INTEGRATED CIRCUIT SYSTEMS, INC. COMMUNICATIONS GROUP"},
  {max_prefix: "00-A0-10",vendor: "SYSLOGIC DATENTECHNIK AG"},
  {max_prefix: "00-A0-16",vendor: "MICROPOLIS CORP."},
  {max_prefix: "00-A0-48",vendor: "QUESTECH, LTD."},
  {max_prefix: "00-A0-BD",vendor: "I-TECH CORP."},
  {max_prefix: "00-A0-59",vendor: "HAMILTON HALLMARK"},
  {max_prefix: "00-A0-39",vendor: "ROSS TECHNOLOGY, INC."},
  {max_prefix: "00-A0-AD",vendor: "MARCONI SPA"},
  {max_prefix: "00-60-E5",vendor: "FUJI AUTOMATION CO., LTD."},
  {max_prefix: "00-60-5E",vendor: "LIBERTY TECHNOLOGY NETWORKING"},
  {max_prefix: "00-60-C6",vendor: "DCS AG"},
  {max_prefix: "00-60-1E",vendor: "SOFTLAB, INC."},
  {max_prefix: "00-60-0D",vendor: "Digital Logic GmbH"},
  {max_prefix: "00-60-4A",vendor: "SAIC IDEAS GROUP"},
  {max_prefix: "00-A0-FC",vendor: "IMAGE SCIENCES, INC."},
  {max_prefix: "00-A0-9C",vendor: "Xyplex, Inc."},
  {max_prefix: "00-A0-D6",vendor: "SBE, Inc."},
  {max_prefix: "00-A0-2E",vendor: "BRAND COMMUNICATIONS, LTD."},
  {max_prefix: "00-A0-AF",vendor: "WMS INDUSTRIES"},
  {max_prefix: "00-A0-57",vendor: "LANCOM Systems GmbH"},
  {max_prefix: "00-A0-9F",vendor: "COMMVISION CORP."},
  {max_prefix: "00-A0-6E",vendor: "AUSTRON, INC."},
  {max_prefix: "00-A0-BA",vendor: "PATTON ELECTRONICS CO."},
  {max_prefix: "00-A0-B5",vendor: "3H TECHNOLOGY"},
  {max_prefix: "00-A0-4D",vendor: "EDA INSTRUMENTS, INC."},
  {max_prefix: "00-A0-86",vendor: "AMBER WAVE SYSTEMS, INC."},
  {max_prefix: "00-A0-1E",vendor: "EST CORPORATION"},
  {max_prefix: "00-A0-62",vendor: "AES PRODATA"},
  {max_prefix: "00-A0-AE",vendor: "NUCOM SYSTEMS, INC."},
  {max_prefix: "00-A0-76",vendor: "CARDWARE LAB, INC."},
  {max_prefix: "00-A0-A1",vendor: "EPIC DATA INC."},
  {max_prefix: "00-A0-CA",vendor: "FUJITSU DENSO LTD."},
  {max_prefix: "00-A0-22",vendor: "CENTRE FOR DEVELOPMENT OF ADVANCED COMPUTING"},
  {max_prefix: "00-A0-C0",vendor: "DIGITAL LINK CORP."},
  {max_prefix: "00-A0-44",vendor: "NTT IT CO., LTD."},
  {max_prefix: "00-A0-11",vendor: "MUTOH INDUSTRIES LTD."},
  {max_prefix: "00-A0-B6",vendor: "SANRITZ AUTOMATION CO., LTD."},
  {max_prefix: "00-A0-79",vendor: "ALPS ELECTRIC (USA), INC."},
  {max_prefix: "00-A0-8D",vendor: "JACOMO CORPORATION"},
  {max_prefix: "00-A0-8E",vendor: "Check Point Software Technologies"},
  {max_prefix: "00-A0-F5",vendor: "RADGUARD LTD."},
  {max_prefix: "00-A0-84",vendor: "Dataplex Pty Ltd"},
  {max_prefix: "00-A0-49",vendor: "DIGITECH INDUSTRIES, INC."},
  {max_prefix: "00-A0-9D",vendor: "JOHNATHON FREEMAN TECHNOLOGIES"},
  {max_prefix: "00-A0-F9",vendor: "BINTEC COMMUNICATIONS GMBH"},
  {max_prefix: "00-A0-03",vendor: "Siemens Switzerland Ltd., I B T HVP"},
  {max_prefix: "00-02-67",vendor: "NODE RUNNER, INC."},
  {max_prefix: "00-20-B1",vendor: "COMTECH RESEARCH INC."},
  {max_prefix: "00-20-32",vendor: "ALCATEL TAISEL"},
  {max_prefix: "00-20-E9",vendor: "DANTEL"},
  {max_prefix: "00-20-22",vendor: "NMS Communications"},
  {max_prefix: "00-20-AE",vendor: "ORNET DATA COMMUNICATION TECH."},
  {max_prefix: "00-20-AA",vendor: "Ericsson Television Limited"},
  {max_prefix: "00-A0-6B",vendor: "DMS DORSCH MIKROSYSTEM GMBH"},
  {max_prefix: "00-A0-30",vendor: "CAPTOR NV/SA"},
  {max_prefix: "00-A0-DE",vendor: "YAMAHA CORPORATION"},
  {max_prefix: "00-A0-F8",vendor: "Zebra Technologies Inc"},
  {max_prefix: "00-20-05",vendor: "SIMPLE TECHNOLOGY"},
  {max_prefix: "00-20-FA",vendor: "GDE SYSTEMS, INC."},
  {max_prefix: "00-20-38",vendor: "VME MICROSYSTEMS INTERNATIONAL CORPORATION"},
  {max_prefix: "00-20-A3",vendor: "Harmonic, Inc"},
  {max_prefix: "00-20-59",vendor: "MIRO COMPUTER PRODUCTS AG"},
  {max_prefix: "00-20-80",vendor: "SYNERGY (UK) LTD."},
  {max_prefix: "00-20-18",vendor: "CIS TECHNOLOGY INC."},
  {max_prefix: "00-20-98",vendor: "HECTRONIC AB"},
  {max_prefix: "00-20-FD",vendor: "ITV TECHNOLOGIES, INC."},
  {max_prefix: "00-20-34",vendor: "ROTEC INDUSTRIEAUTOMATION GMBH"},
  {max_prefix: "00-20-79",vendor: "MIKRON GMBH"},
  {max_prefix: "00-20-F5",vendor: "PANDATEL AG"},
  {max_prefix: "00-20-74",vendor: "SUNGWOON SYSTEMS"},
  {max_prefix: "00-20-3C",vendor: "EUROTIME AB"},
  {max_prefix: "00-20-28",vendor: "WEST EGG SYSTEMS, INC."},
  {max_prefix: "00-20-68",vendor: "ISDYNE"},
  {max_prefix: "00-20-C8",vendor: "LARSCOM INCORPORATED"},
  {max_prefix: "00-C0-23",vendor: "TUTANKHAMON ELECTRONICS"},
  {max_prefix: "00-C0-8B",vendor: "RISQ MODULAR SYSTEMS, INC."},
  {max_prefix: "00-20-C4",vendor: "INET,INC."},
  {max_prefix: "00-20-0E",vendor: "NSSLGlobal Technologies AS"},
  {max_prefix: "00-20-E7",vendor: "B&W NUCLEAR SERVICE COMPANY"},
  {max_prefix: "00-20-9D",vendor: "LIPPERT AUTOMATIONSTECHNIK"},
  {max_prefix: "00-20-9C",vendor: "PRIMARY ACCESS CORP."},
  {max_prefix: "00-20-6D",vendor: "DATA RACE, INC."},
  {max_prefix: "00-20-3A",vendor: "DIGITAL BI0METRICS INC."},
  {max_prefix: "00-20-A4",vendor: "MULTIPOINT NETWORKS"},
  {max_prefix: "00-20-51",vendor: "Verilink Corporation"},
  {max_prefix: "00-20-3B",vendor: "WISDM LTD."},
  {max_prefix: "00-20-BA",vendor: "CENTER FOR HIGH PERFORMANCE"},
  {max_prefix: "00-C0-C3",vendor: "ACUSON COMPUTED SONOGRAPHY"},
  {max_prefix: "00-C0-B3",vendor: "COMSTAT DATACOMM CORPORATION"},
  {max_prefix: "00-C0-E5",vendor: "GESPAC, S.A."},
  {max_prefix: "00-C0-4D",vendor: "MITEC, INC."},
  {max_prefix: "00-20-DC",vendor: "DENSITRON TAIWAN LTD."},
  {max_prefix: "00-20-48",vendor: "Marconi Communications"},
  {max_prefix: "00-20-0C",vendor: "ADASTRA SYSTEMS CORP."},
  {max_prefix: "00-20-11",vendor: "CANOPUS CO., LTD."},
  {max_prefix: "00-20-F0",vendor: "UNIVERSAL MICROELECTRONICS CO."},
  {max_prefix: "00-20-89",vendor: "T3PLUS NETWORKING, INC."},
  {max_prefix: "00-20-61",vendor: "GarrettCom, Inc."},
  {max_prefix: "00-C0-A1",vendor: "TOKYO DENSHI SEKEI CO."},
  {max_prefix: "00-C0-2E",vendor: "NETWIZ"},
  {max_prefix: "00-C0-47",vendor: "UNIMICRO SYSTEMS, INC."},
  {max_prefix: "00-C0-84",vendor: "DATA LINK CORP. LTD."},
  {max_prefix: "00-C0-41",vendor: "DIGITAL TRANSMISSION SYSTEMS"},
  {max_prefix: "00-C0-0D",vendor: "ADVANCED LOGIC RESEARCH, INC."},
  {max_prefix: "00-C0-B4",vendor: "MYSON TECHNOLOGY, INC."},
  {max_prefix: "00-C0-80",vendor: "NETSTAR, INC."},
  {max_prefix: "00-C0-45",vendor: "ISOLATION SYSTEMS, LTD."},
  {max_prefix: "00-70-B3",vendor: "DATA RECALL LTD."},
  {max_prefix: "00-E6-D3",vendor: "NIXDORF COMPUTER CORP."},
  {max_prefix: "00-70-B0",vendor: "M/A-COM INC. COMPANIES"},
  {max_prefix: "00-C0-1F",vendor: "S.E.R.C.E.L."},
  {max_prefix: "00-60-86",vendor: "LOGIC REPLACEMENT TECH. LTD."},
  {max_prefix: "00-C0-B6",vendor: "HVE, Inc."},
  {max_prefix: "00-C0-7A",vendor: "PRIVA B.V."},
  {max_prefix: "00-C0-6D",vendor: "BOCA RESEARCH, INC."},
  {max_prefix: "00-C0-EA",vendor: "ARRAY TECHNOLOGY LTD."},
  {max_prefix: "00-C0-09",vendor: "KT TECHNOLOGY (S) PTE LTD"},
  {max_prefix: "00-C0-81",vendor: "METRODATA LTD."},
  {max_prefix: "00-C0-3B",vendor: "MULTIACCESS COMPUTING CORP."},
  {max_prefix: "00-C0-82",vendor: "MOORE PRODUCTS CO."},
  {max_prefix: "00-C0-99",vendor: "YOSHIKI INDUSTRIAL CO.,LTD."},
  {max_prefix: "00-C0-DC",vendor: "EOS TECHNOLOGIES, INC."},
  {max_prefix: "00-C0-3C",vendor: "TOWER TECH S.R.L."},
  {max_prefix: "00-C0-1D",vendor: "GRAND JUNCTION NETWORKS, INC."},
  {max_prefix: "00-C0-70",vendor: "SECTRA SECURE-TRANSMISSION AB"},
  {max_prefix: "00-C0-72",vendor: "KNX LTD."},
  {max_prefix: "00-C0-AE",vendor: "TOWERCOM CO. INC. DBA PC HOUSE"},
  {max_prefix: "00-C0-D6",vendor: "J1 SYSTEMS, INC."},
  {max_prefix: "00-C0-F6",vendor: "CELAN TECHNOLOGY INC."},
  {max_prefix: "00-C0-C2",vendor: "INFINITE NETWORKS LTD."},
  {max_prefix: "00-C0-AF",vendor: "TEKLOGIX INC."},
  {max_prefix: "00-C0-59",vendor: "DENSO CORPORATION"},
  {max_prefix: "00-C0-F1",vendor: "SHINKO ELECTRIC CO., LTD."},
  {max_prefix: "00-C0-01",vendor: "DIATEK PATIENT MANAGMENT"},
  {max_prefix: "00-C0-F4",vendor: "INTERLINK SYSTEM CO., LTD."},
  {max_prefix: "00-C0-E2",vendor: "CALCOMP, INC."},
  {max_prefix: "00-C0-7B",vendor: "ASCEND COMMUNICATIONS, INC."},
  {max_prefix: "00-C0-98",vendor: "CHUNTEX ELECTRONIC CO., LTD."},
  {max_prefix: "00-C0-BE",vendor: "ALCATEL - SEL"},
  {max_prefix: "00-C0-6E",vendor: "HAFT TECHNOLOGY, INC."},
  {max_prefix: "00-C0-F8",vendor: "ABOUT COMPUTING INC."},
  {max_prefix: "00-C0-78",vendor: "COMPUTER SYSTEMS ENGINEERING"},
  {max_prefix: "00-C0-9A",vendor: "PHOTONICS CORPORATION"},
  {max_prefix: "00-C0-1A",vendor: "COROMETRICS MEDICAL SYSTEMS"},
  {max_prefix: "00-C0-68",vendor: "HME Clear-Com LTD."},
  {max_prefix: "00-C0-D8",vendor: "UNIVERSAL DATA SYSTEMS"},
  {max_prefix: "00-40-99",vendor: "NEWGEN SYSTEMS CORP."},
  {max_prefix: "00-40-11",vendor: "ANDOVER CONTROLS CORPORATION"},
  {max_prefix: "00-40-A1",vendor: "ERGO COMPUTING"},
  {max_prefix: "00-40-81",vendor: "MANNESMANN SCANGRAPHIC GMBH"},
  {max_prefix: "00-40-36",vendor: "Minim Inc."},
  {max_prefix: "00-40-16",vendor: "ADC - Global Connectivity Solutions Division"},
  {max_prefix: "00-40-6A",vendor: "KENTEK INFORMATION SYSTEMS,INC"},
  {max_prefix: "00-40-0A",vendor: "PIVOTAL TECHNOLOGIES, INC."},
  {max_prefix: "00-40-82",vendor: "LABORATORY EQUIPMENT CORP."},
  {max_prefix: "00-C0-8C",vendor: "PERFORMANCE TECHNOLOGIES, INC."},
  {max_prefix: "00-C0-07",vendor: "PINNACLE DATA SYSTEMS, INC."},
  {max_prefix: "00-C0-8A",vendor: "Lauterbach GmbH"},
  {max_prefix: "00-40-B7",vendor: "STEALTH COMPUTER SYSTEMS"},
  {max_prefix: "00-40-A8",vendor: "IMF INTERNATIONAL LTD."},
  {max_prefix: "00-40-70",vendor: "INTERWARE CO., LTD."},
  {max_prefix: "00-40-AC",vendor: "SUPER WORKSTATION, INC."},
  {max_prefix: "00-C0-F7",vendor: "ENGAGE COMMUNICATION, INC."},
  {max_prefix: "10-00-5A",vendor: "IBM Corp"},
  {max_prefix: "00-40-E0",vendor: "ATOMWIDE LTD."},
  {max_prefix: "00-40-D1",vendor: "FUKUDA DENSHI CO., LTD."},
  {max_prefix: "00-40-69",vendor: "LEMCOM SYSTEMS, INC."},
  {max_prefix: "00-40-F1",vendor: "CHUO ELECTRONICS CO., LTD."},
  {max_prefix: "00-40-A9",vendor: "DATACOM INC."},
  {max_prefix: "00-40-E3",vendor: "QUIN SYSTEMS LTD"},
  {max_prefix: "00-40-91",vendor: "PROCOMP INDUSTRIA ELETRONICA"},
  {max_prefix: "00-40-EA",vendor: "PLAIN TREE SYSTEMS INC"},
  {max_prefix: "00-40-8A",vendor: "TPS TELEPROCESSING SYS. GMBH"},
  {max_prefix: "00-40-FD",vendor: "LXE"},
  {max_prefix: "00-40-FA",vendor: "MICROBOARDS, INC."},
  {max_prefix: "00-80-3B",vendor: "APT COMMUNICATIONS, INC."},
  {max_prefix: "00-80-6A",vendor: "ERI (EMPAC RESEARCH INC.)"},
  {max_prefix: "00-C0-A8",vendor: "GVC CORPORATION"},
  {max_prefix: "00-40-3B",vendor: "SYNERJET INTERNATIONAL CORP."},
  {max_prefix: "00-40-3F",vendor: "SSANGYONG COMPUTER SYSTEMS"},
  {max_prefix: "00-40-14",vendor: "COMSOFT GMBH"},
  {max_prefix: "00-40-00",vendor: "PCI COMPONENTES DA AMZONIA LTD"},
  {max_prefix: "00-40-6C",vendor: "COPERNIQUE"},
  {max_prefix: "00-40-75",vendor: "Tattile SRL"},
  {max_prefix: "00-40-53",vendor: "AMPRO COMPUTERS"},
  {max_prefix: "00-80-38",vendor: "DATA RESEARCH & APPLICATIONS"},
  {max_prefix: "00-80-5E",vendor: "LSI LOGIC CORPORATION"},
  {max_prefix: "00-80-60",vendor: "NETWORK INTERFACE CORPORATION"},
  {max_prefix: "00-40-A7",vendor: "ITAUTEC PHILCO S.A."},
  {max_prefix: "00-40-64",vendor: "KLA INSTRUMENTS CORPORATION"},
  {max_prefix: "00-40-5A",vendor: "GOLDSTAR INFORMATION & COMM."},
  {max_prefix: "00-40-13",vendor: "NTT DATA COMM. SYSTEMS CORP."},
  {max_prefix: "00-80-F5",vendor: "Quantel Ltd"},
  {max_prefix: "00-80-B9",vendor: "ARCHE TECHNOLIGIES INC."},
  {max_prefix: "00-80-A7",vendor: "Honeywell International Inc"},
  {max_prefix: "00-40-0C",vendor: "GENERAL MICRO SYSTEMS, INC."},
  {max_prefix: "00-40-63",vendor: "VIA TECHNOLOGIES, INC."},
  {max_prefix: "00-80-28",vendor: "TRADPOST (HK) LTD"},
  {max_prefix: "00-80-61",vendor: "LITTON SYSTEMS, INC."},
  {max_prefix: "00-80-C3",vendor: "BICC INFORMATION SYSTEMS & SVC"},
  {max_prefix: "00-80-44",vendor: "SYSTECH COMPUTER CORP."},
  {max_prefix: "00-80-06",vendor: "COMPUADD CORPORATION"},
  {max_prefix: "00-80-8A",vendor: "SUMMIT MICROSYSTEMS CORP."},
  {max_prefix: "00-40-43",vendor: "Nokia Siemens Networks GmbH & Co. KG."},
  {max_prefix: "00-40-5E",vendor: "NORTH HILLS ISRAEL"},
  {max_prefix: "00-80-7C",vendor: "FIBERCOM, INC."},
  {max_prefix: "00-80-91",vendor: "TOKYO ELECTRIC CO.,LTD"},
  {max_prefix: "00-80-9D",vendor: "Commscraft Ltd."},
  {max_prefix: "00-80-F4",vendor: "TELEMECANIQUE ELECTRIQUE"},
  {max_prefix: "00-80-8B",vendor: "DACOLL LIMITED"},
  {max_prefix: "00-80-CB",vendor: "FALCO DATA PRODUCTS"},
  {max_prefix: "00-80-07",vendor: "DLOG NC-SYSTEME"},
  {max_prefix: "00-80-62",vendor: "INTERFACE  CO."},
  {max_prefix: "00-80-1E",vendor: "XINETRON, INC."},
  {max_prefix: "00-80-E2",vendor: "T.D.I. CO., LTD."},
  {max_prefix: "00-80-36",vendor: "REFLEX MANUFACTURING SYSTEMS"},
  {max_prefix: "00-80-83",vendor: "AMDAHL"},
  {max_prefix: "00-80-4D",vendor: "CYCLONE MICROSYSTEMS, INC."},
  {max_prefix: "00-80-0B",vendor: "CSK CORPORATION"},
  {max_prefix: "00-80-18",vendor: "KOBE STEEL, LTD."},
  {max_prefix: "00-80-9B",vendor: "JUSTSYSTEM CORPORATION"},
  {max_prefix: "00-80-DF",vendor: "ADC CODENOLL TECHNOLOGY CORP."},
  {max_prefix: "00-80-D4",vendor: "CHASE RESEARCH LTD."},
  {max_prefix: "00-80-3D",vendor: "SURIGIKEN CO.,  LTD."},
  {max_prefix: "00-80-49",vendor: "NISSIN ELECTRIC CO., LTD."},
  {max_prefix: "00-80-C1",vendor: "LANEX CORPORATION"},
  {max_prefix: "00-80-BC",vendor: "HITACHI ENGINEERING CO., LTD"},
  {max_prefix: "00-80-B2",vendor: "NETWORK EQUIPMENT TECHNOLOGIES"},
  {max_prefix: "00-80-76",vendor: "MCNC"},
  {max_prefix: "00-80-22",vendor: "SCAN-OPTICS"},
  {max_prefix: "00-00-B2",vendor: "TELEVIDEO SYSTEMS, INC."},
  {max_prefix: "00-00-EE",vendor: "NETWORK DESIGNERS, LTD."},
  {max_prefix: "00-00-E5",vendor: "SIGMEX LTD."},
  {max_prefix: "00-00-89",vendor: "CAYMAN SYSTEMS INC."},
  {max_prefix: "00-00-FF",vendor: "CAMTEC ELECTRONICS LTD."},
  {max_prefix: "00-00-B7",vendor: "DOVE COMPUTER CORPORATION"},
  {max_prefix: "00-00-F2",vendor: "SPIDER COMMUNICATIONS"},
  {max_prefix: "00-00-CC",vendor: "DENSAN CO., LTD."},
  {max_prefix: "00-80-A8",vendor: "VITACOM CORPORATION"},
  {max_prefix: "00-80-33",vendor: "EMS Aviation, Inc."},
  {max_prefix: "00-80-DD",vendor: "GMX INC/GIMIX"},
  {max_prefix: "00-80-FB",vendor: "BVM LIMITED"},
  {max_prefix: "00-00-DB",vendor: "British Telecommunications plc"},
  {max_prefix: "00-00-C1",vendor: "Madge Ltd."},
  {max_prefix: "00-00-F6",vendor: "APPLIED MICROSYSTEMS CORP."},
  {max_prefix: "00-00-3F",vendor: "SYNTREX, INC."},
  {max_prefix: "00-00-8E",vendor: "SOLBOURNE COMPUTER, INC."},
  {max_prefix: "00-00-DC",vendor: "HAYES MICROCOMPUTER PRODUCTS"},
  {max_prefix: "00-00-63",vendor: "BARCO CONTROL ROOMS GMBH"},
  {max_prefix: "00-80-B4",vendor: "SOPHIA SYSTEMS"},
  {max_prefix: "00-80-7F",vendor: "DY-4 INCORPORATED"},
  {max_prefix: "00-00-BD",vendor: "RYOSEI, Ltd."},
  {max_prefix: "00-00-2E",vendor: "SOCIETE EVIRA"},
  {max_prefix: "00-00-4E",vendor: "AMPEX CORPORATION"},
  {max_prefix: "00-00-C2",vendor: "INFORMATION PRESENTATION TECH."},
  {max_prefix: "00-00-FC",vendor: "MEIKO"},
  {max_prefix: "00-00-CD",vendor: "Allied Telesis Labs Ltd"},
  {max_prefix: "00-80-68",vendor: "YAMATECH SCIENTIFIC LTD."},
  {max_prefix: "00-00-6D",vendor: "CRAY COMMUNICATIONS, LTD."},
  {max_prefix: "00-00-DA",vendor: "ATEX"},
  {max_prefix: "00-80-2D",vendor: "XYLOGICS INC"},
  {max_prefix: "00-00-A0",vendor: "SANYO Electric Co., Ltd."},
  {max_prefix: "00-00-C0",vendor: "WESTERN DIGITAL CORPORATION"},
  {max_prefix: "00-00-33",vendor: "EGAN MACHINERY COMPANY"},
  {max_prefix: "00-00-EA",vendor: "UPNOD AB"},
  {max_prefix: "00-00-43",vendor: "MICRO TECHNOLOGY"},
  {max_prefix: "00-00-17",vendor: "Oracle"},
  {max_prefix: "00-00-65",vendor: "Network General Corporation"},
  {max_prefix: "00-00-11",vendor: "NORMEREL SYSTEMES"},
  {max_prefix: "00-00-C4",vendor: "WATERS DIV. OF MILLIPORE"},
  {max_prefix: "00-00-A2",vendor: "Bay Networks"},
  {max_prefix: "00-00-EC",vendor: "MICROPROCESS"},
  {max_prefix: "00-00-61",vendor: "GATEWAY COMMUNICATIONS"},
  {max_prefix: "00-00-A4",vendor: "ACORN COMPUTERS LIMITED"},
  {max_prefix: "00-00-DD",vendor: "TCL INCORPORATED"},
  {max_prefix: "00-00-AE",vendor: "DASSAULT ELECTRONIQUE"},
  {max_prefix: "00-00-77",vendor: "INTERPHASE CORPORATION"},
  {max_prefix: "00-00-99",vendor: "MTX, INC."},
  {max_prefix: "00-00-28",vendor: "PRODIGY SYSTEMS CORPORATION"},
  {max_prefix: "00-00-10",vendor: "SYTEK INC."},
  {max_prefix: "00-00-EB",vendor: "MATSUSHITA COMM. IND. CO. LTD."},
  {max_prefix: "08-00-43",vendor: "PIXEL COMPUTER INC."},
  {max_prefix: "08-00-45",vendor: "CONCURRENT COMPUTER CORP."},
  {max_prefix: "08-00-3B",vendor: "TORUS SYSTEMS LIMITED"},
  {max_prefix: "08-00-3C",vendor: "SCHLUMBERGER WELL SERVICES"},
  {max_prefix: "08-00-34",vendor: "FILENET CORPORATION"},
  {max_prefix: "08-00-36",vendor: "INTERGRAPH CORPORATION"},
  {max_prefix: "08-00-33",vendor: "BAUSCH & LOMB"},
  {max_prefix: "08-00-30",vendor: "NETWORK RESEARCH CORPORATION"},
  {max_prefix: "08-00-31",vendor: "LITTLE MACHINES INC."},
  {max_prefix: "08-00-7E",vendor: "AMALGAMATED WIRELESS(AUS) LTD"},
  {max_prefix: "08-00-7F",vendor: "CARNEGIE-MELLON UNIVERSITY"},
  {max_prefix: "08-00-29",vendor: "Megatek Corporation"},
  {max_prefix: "08-00-23",vendor: "Panasonic Communications Co., Ltd."},
  {max_prefix: "08-00-5E",vendor: "COUNTERPOINT COMPUTER INC."},
  {max_prefix: "08-00-56",vendor: "STANFORD LINEAR ACCEL. CENTER"},
  {max_prefix: "08-00-48",vendor: "EUROTHERM GAUGING SYSTEMS"},
  {max_prefix: "08-00-4F",vendor: "CYGNET SYSTEMS"},
  {max_prefix: "08-00-50",vendor: "DAISY SYSTEMS CORP."},
  {max_prefix: "08-00-2E",vendor: "METAPHOR COMPUTER SYSTEMS"},
  {max_prefix: "08-00-2B",vendor: "DIGITAL EQUIPMENT CORPORATION"},
  {max_prefix: "08-00-75",vendor: "DANSK DATA ELECTRONIK"},
  {max_prefix: "08-00-78",vendor: "ACCELL CORPORATION"},
  {max_prefix: "08-00-6D",vendor: "WHITECHAPEL COMPUTER WORKS"},
  {max_prefix: "00-00-9D",vendor: "LOCUS COMPUTING CORPORATION"},
  {max_prefix: "00-00-FD",vendor: "HIGH LEVEL HARDWARE"},
  {max_prefix: "08-00-0F",vendor: "MITEL CORPORATION"},
  {max_prefix: "02-70-B0",vendor: "M/A-COM INC. COMPANIES"},
  {max_prefix: "00-00-53",vendor: "COMPUCORP"},
  {max_prefix: "08-00-0A",vendor: "NESTAR SYSTEMS INCORPORATED"},
  {max_prefix: "00-00-04",vendor: "XEROX CORPORATION"},
  {max_prefix: "00-DD-0E",vendor: "UNGERMANN-BASS INC."},
  {max_prefix: "08-00-18",vendor: "PIRELLI FOCOM NETWORKS"},
  {max_prefix: "00-00-A6",vendor: "NETWORK GENERAL CORPORATION"},
  {max_prefix: "00-BB-F0",vendor: "UNGERMANN-BASS INC."},
  {max_prefix: "00-40-8E",vendor: "Tattile SRL"},
  {max_prefix: "08-00-1C",vendor: "KDD-KOKUSAI DEBNSIN DENWA CO."},
  {max_prefix: "08-00-90",vendor: "SONOMA SYSTEMS"},
  {max_prefix: "00-80-0F",vendor: "STANDARD MICROSYSTEMS"},
  {max_prefix: "00-40-6B",vendor: "SYSGEN"},
  {max_prefix: "00-DD-0C",vendor: "UNGERMANN-BASS INC."},
  {max_prefix: "00-01-C8",vendor: "THOMAS CONRAD CORP."},
  {max_prefix: "CC-45-A5",vendor: "Texas Instruments"},
  {max_prefix: "60-26-02",vendor: "Texas Instruments"},
  {max_prefix: "EC-BF-D0",vendor: "Texas Instruments"},
  {max_prefix: "C4-E7-33",vendor: "Clear Align LLC"},
  {max_prefix: "5C-FC-E1",vendor: "Resideo"},
  {max_prefix: "F0-A7-31",vendor: "TP-Link Corporation Limited"},
  {max_prefix: "54-DE-D3",vendor: "zte corporation"},
  {max_prefix: "74-9B-89",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "5C-53-B4",vendor: "EM Microelectronic"},
  {max_prefix: "50-A6-D8",vendor: "Apple, Inc."},
  {max_prefix: "68-CA-C4",vendor: "Apple, Inc."},
  {max_prefix: "00-30-8E",vendor: "Crossmatch Technologies/HID Global"},
  {max_prefix: "00-06-33",vendor: "Crossmatch Technologies/HID Global"},
  {max_prefix: "80-F5-AE",vendor: "Hangzhou Hikvision Digital Technology Co.,Ltd."},
  {max_prefix: "94-15-B2",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "E8-B7-22",vendor: "GreenTrol Automation"},
  {max_prefix: "60-61-34",vendor: "Arcade Communications Ltd."},
  {max_prefix: "A4-97-8A",vendor: "LEAR"},
  {max_prefix: "4C-EA-41",vendor: "IEEE Registration Authority"},
  {max_prefix: "74-20-5F",vendor: "Shenzhen Zhongruixin Intelligent Technology Co., Ltd."},
  {max_prefix: "14-06-4C",vendor: "Vogl Electronic GmbH"},
  {max_prefix: "8C-61-20",vendor: "EM Microelectronic"},
  {max_prefix: "8C-8E-4E",vendor: "Baylan Olcu Aletleri San. ve Tic.A.S."},
  {max_prefix: "F8-AC-C1",vendor: "InnoXings Co., LTD."},
  {max_prefix: "40-64-DC",vendor: "X-speed lnformation Technology Co.,Ltd"},
  {max_prefix: "5C-C7-87",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "84-50-75",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "5C-F8-38",vendor: "IEEE Registration Authority"},
  {max_prefix: "30-D9-7F",vendor: "Tech4home, Lda"},
  {max_prefix: "40-80-E1",vendor: "FN-LINK TECHNOLOGY Ltd."},
  {max_prefix: "C4-48-38",vendor: "Satcom Direct, Inc."},
  {max_prefix: "9C-37-CB",vendor: "Sony Interactive Entertainment Inc."},
  {max_prefix: "3C-B0-ED",vendor: "Nothing Technology Limited"},
  {max_prefix: "EC-CF-70",vendor: "FUJIAN STAR-NET COMMUNICATION CO.,LTD"},
  {max_prefix: "04-F0-E4",vendor: "ShenZhen Hosecom Electronic Technology Co.,LTD"},
  {max_prefix: "D8-E2-3F",vendor: "Qingdao Haier Technology Co.,Ltd"},
  {max_prefix: "D8-03-2A",vendor: "COMMTACT LTD"},
  {max_prefix: "84-70-51",vendor: "ALPSALPINE CO,.LTD"},
  {max_prefix: "50-13-1D",vendor: "Motorola Mobility LLC, a Lenovo Company"},
  {max_prefix: "D4-7F-78",vendor: "Dopple B.V."},
  {max_prefix: "90-A9-F7",vendor: "IEEE Registration Authority"},
  {max_prefix: "F0-F7-FC",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "30-FF-FD",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "B0-99-5A",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "00-F7-AD",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "D4-CB-CC",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "3C-84-27",vendor: "Espressif Inc."},
  {max_prefix: "74-0B-B0",vendor: "Hirschmann Automation and Control GmbH"},
  {max_prefix: "20-ED-47",vendor: "Juniper Networks"},
  {max_prefix: "94-EF-49",vendor: "BDR Thermea Group B.V"},
  {max_prefix: "90-B6-85",vendor: "WEIFANG GOERTEK ELECTRONICS CO.,LTD"},
  {max_prefix: "A0-D1-B3",vendor: "Apple, Inc."},
  {max_prefix: "7C-4B-26",vendor: "Apple, Inc."},
  {max_prefix: "24-D5-E4",vendor: "Cisco Systems, Inc"},
  {max_prefix: "CC-11-5A",vendor: "Apple, Inc."},
  {max_prefix: "00-80-A3",vendor: "Lantronix"},
  {max_prefix: "90-D4-32",vendor: "zte corporation"},
  {max_prefix: "18-E8-3B",vendor: "Citadel Wallet LLC"},
  {max_prefix: "00-80-66",vendor: "Eurotech S.p.A."},
  {max_prefix: "78-53-33",vendor: "Beijing Xiaomi Electronics Co.,Ltd"},
  {max_prefix: "9C-F8-B8",vendor: "Shenzhen Skyworth Digital  Technology  CO., Ltd"},
  {max_prefix: "94-32-C1",vendor: "Honor Device Co., Ltd."},
  {max_prefix: "18-95-78",vendor: "DENSO Corporation"},
  {max_prefix: "14-7F-67",vendor: "LG Innotek"},
  {max_prefix: "EC-9A-0C",vendor: "IEEE Registration Authority"},
  {max_prefix: "CC-4D-75",vendor: "Beijing Xiaomi Mobile Software Co., Ltd"},
  {max_prefix: "10-7C-61",vendor: "ASUSTek COMPUTER INC."},
  {max_prefix: "00-8D-F4",vendor: "IEEE Registration Authority"},
  {max_prefix: "78-18-EC",vendor: "Fortinet, Inc."},
  {max_prefix: "6C-7F-0C",vendor: "Cisco Meraki"},
  {max_prefix: "5C-B2-6D",vendor: "Intel Corporate"},
  {max_prefix: "48-1C-B9",vendor: "SZ DJI TECHNOLOGY CO.,LTD"},
  {max_prefix: "E0-2E-FE",vendor: "Zhejiang Dahua Technology Co., Ltd."},
  {max_prefix: "CC-A0-8F",vendor: "zte corporation"},
  {max_prefix: "E8-B0-C5",vendor: "Intel Corporate"},
  {max_prefix: "FC-B5-85",vendor: "Shenzhen Water World Information Co.,Ltd."},
  {max_prefix: "D4-24-DD",vendor: "AVM Audiovisuelles Marketing und Computersysteme GmbH"},
  {max_prefix: "6C-51-E4",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "B0-FA-8B",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "EC-E6-60",vendor: "Qingdao Hisense Communications Co.,Ltd."},
  {max_prefix: "E8-28-8D",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "F4-62-DC",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "C4-62-37",vendor: "sunweit industrial limited"},
  {max_prefix: "F4-D5-8A",vendor: "Palo Alto Networks"},
  {max_prefix: "C8-C6-FE",vendor: "eero inc."},
  {max_prefix: "18-89-DF",vendor: "OMNIVISION"},
  {max_prefix: "44-30-3F",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "5C-07-A6",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "CC-1E-56",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "A0-55-1F",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "4C-B9-11",vendor: "Raisecom Technology CO., LTD"},
  {max_prefix: "00-0E-5E",vendor: "Raisecom Technology CO., LTD"},
  {max_prefix: "38-C6-BD",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "00-0C-8D",vendor: "Balluff MV GmbH"},
  {max_prefix: "90-89-38",vendor: "Hefei Linkin Technology Co., Ltd."},
  {max_prefix: "A8-BA-69",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "A8-3C-A5",vendor: "Dell Inc."},
  {max_prefix: "80-F7-A6",vendor: "Shenzhen C-Data Technology Co., Ltd."},
  {max_prefix: "30-2F-AC",vendor: "Zhejiang HuaRay Technology Co.,Ltd"},
  {max_prefix: "B8-0B-DA",vendor: "GD Midea Air-Conditioning Equipment Co.,Ltd."},
  {max_prefix: "34-2D-A3",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "0C-6A-C4",vendor: "Apple, Inc."},
  {max_prefix: "BC-37-D3",vendor: "Apple, Inc."},
  {max_prefix: "20-CC-27",vendor: "Cisco Systems, Inc"},
  {max_prefix: "0C-51-7E",vendor: "Apple, Inc."},
  {max_prefix: "28-D2-5A",vendor: "Infinix mobility limited"},
  {max_prefix: "80-EA-0B",vendor: "Zyxel Communications Corporation"},
  {max_prefix: "98-2F-86",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "04-E4-B6",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "BC-EA-9C",vendor: "ITEL MOBILE LIMITED"},
  {max_prefix: "04-79-FD",vendor: "Ciena Corporation"},
  {max_prefix: "50-98-93",vendor: "Texas Instruments"},
  {max_prefix: "6C-83-38",vendor: "Ubihere"},
  {max_prefix: "00-0C-EF",vendor: " ONE Investment Group Limited"},
  {max_prefix: "50-3A-A0",vendor: "SHENZHEN MERCURY COMMUNICATION TECHNOLOGIES CO.,LTD."},
  {max_prefix: "5C-DE-34",vendor: "SHENZHEN MERCURY COMMUNICATION TECHNOLOGIES CO.,LTD."},
  {max_prefix: "84-27-12",vendor: "Silicon Laboratories"},
  {max_prefix: "7C-C6-B6",vendor: "Silicon Laboratories"},
  {max_prefix: "98-0C-33",vendor: "Silicon Laboratories"},
  {max_prefix: "38-39-8F",vendor: "Silicon Laboratories"},
  {max_prefix: "7C-F0-E5",vendor: "OnePlus Technology (Shenzhen) Co., Ltd"},
  {max_prefix: "50-5E-5C",vendor: "SUNITEC TECHNOLOGY CO.,LIMITED"},
  {max_prefix: "38-02-E3",vendor: "YICHEN (SHENZHEN) TECHNOLOGY CO.,LTD"},
  {max_prefix: "E4-A7-D0",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "B4-2B-B9",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "34-C7-E9",vendor: "Google, Inc."},
  {max_prefix: "AC-8C-46",vendor: "Beijing Xiaomi Mobile Software Co., Ltd"},
  {max_prefix: "48-2D-63",vendor: "Wavarts Technologies Co., Ltd"},
  {max_prefix: "80-99-E7",vendor: "Sony Corporation"},
  {max_prefix: "20-93-39",vendor: "Juniper Networks"},
  {max_prefix: "E8-FF-1E",vendor: "IEEE Registration Authority"},
  {max_prefix: "1C-54-E6",vendor: "Shenzhen Yisheng Technology Co.,Ltd"},
  {max_prefix: "0C-4B-48",vendor: "Nokia"},
  {max_prefix: "7C-7B-1C",vendor: "Motorola Mobility LLC, a Lenovo Company"},
  {max_prefix: "78-34-FD",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "FC-3C-D7",vendor: "Tuya Smart Inc."},
  {max_prefix: "B0-9C-63",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "E8-98-47",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "FC-38-6A",vendor: "Shenzhen Skyworth Digital  Technology  CO., Ltd"},
  {max_prefix: "50-88-11",vendor: "Beijing Xiaomi Mobile Software Co., Ltd"},
  {max_prefix: "F8-C4-FA",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "AC-CE-92",vendor: "New H3C Technologies Co., Ltd"},
  {max_prefix: "AC-81-B5",vendor: "Accton Technology Corporation"},
  {max_prefix: "D0-46-0C",vendor: "Dell Inc."},
  {max_prefix: "84-D5-A0",vendor: "MCOT INC."},
  {max_prefix: "84-E8-CB",vendor: "BUFFALO.INC"},
  {max_prefix: "90-A0-BE",vendor: "Cannice"},
  {max_prefix: "38-E1-F4",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "64-53-E0",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "68-8B-F4",vendor: "Arista Network, Inc."},
  {max_prefix: "94-01-7D",vendor: "SHENZHEN SHLINK.CO.,LIMITED"},
  {max_prefix: "C0-E5-DA",vendor: "Qingdao Intelligent&Precise Electronics Co.,Ltd."},
  {max_prefix: "D8-A6-FD",vendor: "Ghost Autonomy Inc."},
  {max_prefix: "20-23-51",vendor: "TP-LINK CORPORATION PTE. LTD."},
  {max_prefix: "8C-84-74",vendor: "Broadcom Limited"},
  {max_prefix: "D8-3D-3F",vendor: "JOYNED GmbH"},
  {max_prefix: "D4-48-67",vendor: "Silicon Laboratories"},
  {max_prefix: "D8-C6-F9",vendor: "Tracklab Inc"},
  {max_prefix: "6C-48-3F",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "3C-AF-B7",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "54-08-53",vendor: "Qingdao Haier Technology Co.,Ltd"},
  {max_prefix: "BC-08-66",vendor: "Nestle Purina PetCare"},
  {max_prefix: "DC-9B-95",vendor: "Phyplus Technology (Shanghai) Co., Ltd"},
  {max_prefix: "48-00-B3",vendor: "Cisco Systems, Inc"},
  {max_prefix: "2C-B6-8F",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "C8-D1-A9",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "40-BB-56",vendor: "TeraNXT Global India Pvt Ltd."},
  {max_prefix: "E0-5D-54",vendor: "Ruijie Networks Co.,LTD"},
  {max_prefix: "18-18-8B",vendor: "FCNT LLC"},
  {max_prefix: "DC-71-D0",vendor: "Apple, Inc."},
  {max_prefix: "9C-FA-76",vendor: "Apple, Inc."},
  {max_prefix: "20-FA-85",vendor: "Apple, Inc."},
  {max_prefix: "9C-1A-25",vendor: "Apple, Inc."},
  {max_prefix: "6C-60-16",vendor: "OnePlus Technology (Shenzhen) Co., Ltd"},
  {max_prefix: "A0-78-2D",vendor: "Apple, Inc."},
  {max_prefix: "D0-11-E5",vendor: "Apple, Inc."},
  {max_prefix: "40-DA-5C",vendor: "Apple, Inc."},
  {max_prefix: "5C-91-75",vendor: "Apple, Inc."},
  {max_prefix: "D8-44-89",vendor: "TP-LINK CORPORATION PTE. LTD."},
  {max_prefix: "44-F7-70",vendor: "Beijing Xiaomi Mobile Software Co., Ltd"},
  {max_prefix: "A8-31-62",vendor: "Hangzhou Huacheng Network Technology Co.,Ltd"},
  {max_prefix: "64-FE-15",vendor: "Flaircomm Microelectronics,Inc."},
  {max_prefix: "D8-6B-FC",vendor: "zte corporation"},
  {max_prefix: "E0-48-24",vendor: "Garmin International"},
  {max_prefix: "08-F0-B6",vendor: "Edifier International"},
  {max_prefix: "C0-55-5C",vendor: "Impulse Labs"},
  {max_prefix: "A8-59-5F",vendor: "Intel Corporate"},
  {max_prefix: "CC-28-AA",vendor: "ASUSTek COMPUTER INC."},
  {max_prefix: "3C-9F-CD",vendor: "Shenzhen Neoway Technology Co.,Ltd."},
  {max_prefix: "34-14-53",vendor: "Gantner Electronic GmbH"},
  {max_prefix: "CC-64-1A",vendor: "SHENZHEN BILIAN ELECTRONIC CO.\uff0cLTD"},
  {max_prefix: "A8-96-09",vendor: "FN-LINK TECHNOLOGY Ltd."},
  {max_prefix: "68-B4-1E",vendor: "ZEASN TECHNOLOGY PRIVATE LIMITED"},
  {max_prefix: "84-15-71",vendor: "TCT mobile ltd"},
  {max_prefix: "E8-BF-B8",vendor: "Intel Corporate"},
  {max_prefix: "DC-4B-A1",vendor: "Wistron Neweb Corporation"},
  {max_prefix: "34-A6-EF",vendor: "Shenzhen Phaten Tech. LTD"},
  {max_prefix: "BC-3F-4E",vendor: "Great Talent Technology Limited"},
  {max_prefix: "54-04-63",vendor: "CIG SHANGHAI CO LTD"},
  {max_prefix: "88-20-67",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "E8-54-97",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "14-E0-1D",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "DC-54-AD",vendor: "Hangzhou RunZhou Fiber Technologies Co.,Ltd"},
  {max_prefix: "B0-92-4A",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "40-DE-24",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "10-83-B4",vendor: "Sidora Srl"},
  {max_prefix: "40-30-59",vendor: "Silicon Laboratories"},
  {max_prefix: "B8-5C-5C",vendor: "Microsoft Corporation"},
  {max_prefix: "F0-D8-05",vendor: "Cisco Systems, Inc"},
  {max_prefix: "08-63-32",vendor: "IEEE Registration Authority"},
  {max_prefix: "B4-92-FE",vendor: "Arista Network, Inc."},
  {max_prefix: "44-71-B3",vendor: "Logitech Far East"},
  {max_prefix: "A4-FC-A1",vendor: "Nokia Solutions and Networks GmbH & Co. KG"},
  {max_prefix: "78-7E-42",vendor: "zte corporation"},
  {max_prefix: "34-5A-18",vendor: "Alignment Engine Inc."},
  {max_prefix: "0C-68-25",vendor: "Suzhou HYC technology Co., Ltd."},
  {max_prefix: "90-B4-DD",vendor: "ZPT R&D"},
  {max_prefix: "A0-FA-C8",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "64-29-FF",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "C0-9B-63",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "10-A1-45",vendor: "nexzo india pvt ltd"},
  {max_prefix: "C4-E7-AE",vendor: "Chengdu Meross Technology Co., Ltd."},
  {max_prefix: "AC-15-18",vendor: "Espressif Inc."},
  {max_prefix: "64-8F-DB",vendor: "Huaqin Technology Co.LTD"},
  {max_prefix: "00-1F-23",vendor: "Interacoustics"},
  {max_prefix: "94-E6-BA",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "74-6D-FA",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "98-07-09",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "60-83-E7",vendor: "TP-LINK CORPORATION PTE. LTD."},
  {max_prefix: "04-41-A5",vendor: "Apple, Inc."},
  {max_prefix: "1C-0E-C2",vendor: "Apple, Inc."},
  {max_prefix: "B0-8B-A8",vendor: "Amazon Technologies Inc."},
  {max_prefix: "38-AC-DD",vendor: "Valenco GmbH"},
  {max_prefix: "C4-CC-37",vendor: "IEEE Registration Authority"},
  {max_prefix: "7C-7E-F9",vendor: "eero inc."},
  {max_prefix: "C4-08-98",vendor: "Dropbeats Technology Co., Ltd."},
  {max_prefix: "3C-99-6D",vendor: "Marelli Europe s.p.a."},
  {max_prefix: "88-50-DD",vendor: "Infiniband Trade Association"},
  {max_prefix: "5C-35-FC",vendor: "Actiontec Electronics Inc."},
  {max_prefix: "E8-5F-B4",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "AC-EA-EA",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "38-F1-95",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "98-2A-0A",vendor: "Intelbras"},
  {max_prefix: "14-02-EC",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "1C-98-EC",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "24-F2-7F",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "80-8D-B7",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "94-18-82",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "34-FC-B9",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "48-4A-E9",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "4C-AE-A3",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "B8-97-7A",vendor: "Nokia Solutions and Networks GmbH & Co. KG"},
  {max_prefix: "20-A6-CD",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "70-3A-0E",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "6C-C4-9F",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "EC-50-AA",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "F0-61-C0",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "B0-1F-8C",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "48-B4-C3",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "20-9C-B4",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "4C-D5-87",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "9C-37-08",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "34-C5-15",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "60-26-EF",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "BC-D7-A5",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "18-7A-3B",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "B8-6C-E0",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "20-4C-03",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "F0-1A-A0",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "A0-A0-01",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "8C-79-09",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "00-0B-86",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "88-3A-30",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "7C-A6-2A",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "24-62-CE",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "D8-D8-E5",vendor: "Vantiva Connected Home - Technologies Telco"},
  {max_prefix: "4C-23-38",vendor: "CLOUD NETWORK TECHNOLOGY SINGAPORE PTE. LTD."},
  {max_prefix: "44-72-AC",vendor: "AltoBeam Inc."},
  {max_prefix: "A8-6E-84",vendor: "TP-LINK CORPORATION PTE. LTD."},
  {max_prefix: "88-8C-1B",vendor: "EM Microelectronic"},
  {max_prefix: "3C-F0-83",vendor: "Arcadyan Corporation"},
  {max_prefix: "C0-D9-41",vendor: "Shenzhen VMAX Software Co., Ltd."},
  {max_prefix: "68-B7-6B",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "9C-12-21",vendor: "Sichuan AI-Link Technology Co., Ltd."},
  {max_prefix: "10-3F-8C",vendor: "New H3C Technologies Co., Ltd"},
  {max_prefix: "F0-9E-9E",vendor: "Espressif Inc."},
  {max_prefix: "48-B3-13",vendor: "Idesco Oy"},
  {max_prefix: "68-C6-AC",vendor: "Intel Corporate"},
  {max_prefix: "BC-38-98",vendor: "Intel Corporate"},
  {max_prefix: "A0-4F-52",vendor: "Intel Corporate"},
  {max_prefix: "3C-94-FD",vendor: "Mist Systems, Inc."},
  {max_prefix: "94-CA-9A",vendor: "Paul Vahle GmbH & Co. KG"},
  {max_prefix: "74-2E-C1",vendor: "Dixon Electro Appliances Pvt Ltd"},
  {max_prefix: "30-23-BA",vendor: "Accelerated Memory Production Inc."},
  {max_prefix: "84-F2-C1",vendor: "zte corporation"},
  {max_prefix: "40-82-7B",vendor: "STMicroelectronics Rousset SAS"},
  {max_prefix: "D0-6D-C8",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "40-62-EE",vendor: "Chicony Electronics Co., Ltd."},
  {max_prefix: "74-29-72",vendor: "Juniper Networks"},
  {max_prefix: "E4-5E-CC",vendor: "Juniper Networks"},
  {max_prefix: "28-6B-5C",vendor: "Cisco Systems, Inc"},
  {max_prefix: "58-79-E0",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "C4-77-64",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "60-7F-CB",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "6C-B3-4D",vendor: "SharkNinja Operating LLC"},
  {max_prefix: "5C-5A-4C",vendor: "IEEE Registration Authority"},
  {max_prefix: "98-08-02",vendor: "ORBIS BV"},
  {max_prefix: "FC-B3-87",vendor: "Leapmotor (Jinhua) New Energy Vehicle Parts Technology Co., Ltd."},
  {max_prefix: "70-35-7B",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "A8-A9-13",vendor: "GN Hearing A/S"},
  {max_prefix: "BC-29-78",vendor: "Prama Hikvision India Private Limited"},
  {max_prefix: "14-23-F3",vendor: "Broadcom Limited"},
  {max_prefix: "9C-0E-51",vendor: "Schneider Electric"},
  {max_prefix: "1C-F6-4C",vendor: "Apple, Inc."},
  {max_prefix: "AC-5C-2C",vendor: "Apple, Inc."},
  {max_prefix: "50-F2-65",vendor: "Apple, Inc."},
  {max_prefix: "C0-6C-0C",vendor: "Apple, Inc."},
  {max_prefix: "EC-09-C9",vendor: "Texas Instruments"},
  {max_prefix: "6C-3A-36",vendor: "Glowforge Inc"},
  {max_prefix: "C8-EE-D7",vendor: "Lightspeed Technologies Inc."},
  {max_prefix: "60-31-92",vendor: "OVT India pvt Ltd"},
  {max_prefix: "58-D1-5A",vendor: "Texas Instruments"},
  {max_prefix: "9C-DA-A8",vendor: "Apple, Inc."},
  {max_prefix: "0C-07-DF",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "F0-AB-F3",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "7C-E7-12",vendor: "Quectel Wireless Solutions Co.,Ltd."},
  {max_prefix: "0C-EA-14",vendor: "Ubiquiti Inc"},
  {max_prefix: "E8-17-FC",vendor: "Fujitsu Limited"},
  {max_prefix: "E8-22-81",vendor: "GD Midea Air-Conditioning Equipment Co.,Ltd."},
  {max_prefix: "20-3B-34",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "B4-3D-6B",vendor: "NXP Semiconductor (Tianjin) LTD."},
  {max_prefix: "4C-D7-C8",vendor: "Guangzhou V-Solution Telecommunication Technology Co.,Ltd."},
  {max_prefix: "A4-29-02",vendor: "Hangzhou Hikvision Digital Technology Co.,Ltd."},
  {max_prefix: "6C-D0-08",vendor: "zte corporation"},
  {max_prefix: "50-8C-C9",vendor: "zte corporation"},
  {max_prefix: "34-6E-68",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "CC-90-96",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "2C-E2-D9",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "84-92-E5",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "FC-AE-2B",vendor: "Titan Products Ltd."},
  {max_prefix: "5C-EB-52",vendor: "zte corporation"},
  {max_prefix: "FC-AB-F5",vendor: "zte corporation"},
  {max_prefix: "08-3B-E9",vendor: "New H3C Technologies Co., Ltd"},
  {max_prefix: "10-B6-5E",vendor: "New H3C Technologies Co., Ltd"},
  {max_prefix: "28-EB-B7",vendor: "ambie corporation"},
  {max_prefix: "60-CF-84",vendor: "ASUSTek COMPUTER INC."},
  {max_prefix: "68-51-34",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "F0-CF-4D",vendor: "BitRecords GmbH"},
  {max_prefix: "00-15-3B",vendor: "EMH Metering GmbH & Co. KG"},
  {max_prefix: "B8-48-EC",vendor: "Private"},
  {max_prefix: "F4-1E-57",vendor: "Routerboard.com"},
  {max_prefix: "08-10-31",vendor: "Lithiunal Energy"},
  {max_prefix: "CC-6E-2A",vendor: "Cisco Meraki"},
  {max_prefix: "30-F2-3C",vendor: "TECNO MOBILE LIMITED"},
  {max_prefix: "1C-11-2F",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "E4-1E-33",vendor: "Continental Automotive Technologies GmbH"},
  {max_prefix: "74-AD-45",vendor: "Valeo Auto- Electric Hungary Ltd"},
  {max_prefix: "EC-30-8E",vendor: "Lierda Science & Technology Group Co., Ltd"},
  {max_prefix: "70-C5-9C",vendor: "Silicon Laboratories"},
  {max_prefix: "E4-06-BF",vendor: "Silicon Laboratories"},
  {max_prefix: "0C-C5-6C",vendor: "Apple, Inc."},
  {max_prefix: "98-3F-E8",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "10-AB-C9",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "E8-6A-64",vendor: "LCFC(Hefei) Electronics Technology co., ltd"},
  {max_prefix: "90-2E-16",vendor: "LCFC(Hefei) Electronics Technology co., ltd"},
  {max_prefix: "CC-4B-04",vendor: "Apple, Inc."},
  {max_prefix: "44-09-DA",vendor: "Apple, Inc."},
  {max_prefix: "80-96-98",vendor: "Apple, Inc."},
  {max_prefix: "C4-84-FC",vendor: "Apple, Inc."},
  {max_prefix: "60-FF-9E",vendor: "AzureWave Technology Inc."},
  {max_prefix: "00-1E-96",vendor: "Sepura Limited"},
  {max_prefix: "E8-80-88",vendor: "LCFC(Hefei) Electronics Technology co., ltd"},
  {max_prefix: "74-5D-22",vendor: "LCFC(Hefei) Electronics Technology co., ltd"},
  {max_prefix: "40-85-56",vendor: "Continental Automotive Romania SLR"},
  {max_prefix: "9C-69-ED",vendor: "Arista Networks"},
  {max_prefix: "64-55-63",vendor: "Q-Free America, Inc."},
  {max_prefix: "C8-A9-13",vendor: "Lontium Semiconductor Corporation"},
  {max_prefix: "10-1A-92",vendor: "AKEBONO BRAKE INDUSTRY CO.,LTD."},
  {max_prefix: "78-1F-7C",vendor: "Nokia"},
  {max_prefix: "48-5C-2C",vendor: "Earda Technologies co Ltd"},
  {max_prefix: "88-F4-DA",vendor: "Intel Corporate"},
  {max_prefix: "EC-8E-77",vendor: "Intel Corporate"},
  {max_prefix: "84-07-C4",vendor: "Walter Kidde Portable Equipment, Inc."},
  {max_prefix: "EC-FC-2F",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "60-45-CD",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "2C-56-83",vendor: "China Mobile Group Device Co.,Ltd."},
  {max_prefix: "30-E3-A4",vendor: "Intel Corporate"},
  {max_prefix: "A0-85-E3",vendor: "Espressif Inc."},
  {max_prefix: "08-48-57",vendor: "Suteng Innovation Technology Co., Ltd."},
  {max_prefix: "74-F9-0F",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "20-14-C4",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "0C-95-15",vendor: "Palltronics, Inc."},
  {max_prefix: "70-7D-AF",vendor: "Plucent AB"},
  {max_prefix: "64-E8-15",vendor: "Arcelik A.S"},
  {max_prefix: "50-05-6E",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "EC-47-0C",vendor: "Universal Electronics, Inc."},
  {max_prefix: "38-54-F5",vendor: "AltoBeam Inc."},
  {max_prefix: "70-F7-4F",vendor: "Robert Bosch JuP1"},
  {max_prefix: "48-5E-0E",vendor: "IEEE Registration Authority"},
  {max_prefix: "C0-BA-E6",vendor: "Zenitel GB Ltd"},
  {max_prefix: "E0-1F-34",vendor: "HMD Global Oy"},
  {max_prefix: "4C-F4-75",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "3C-4A-C9",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "2C-0D-27",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "5C-BE-69",vendor: "Oraimo Technology Limited"},
  {max_prefix: "94-CF-B0",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "D8-CF-61",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "FC-41-16",vendor: "Google, Inc."},
  {max_prefix: "48-B4-24",vendor: "eero inc."},
  {max_prefix: "A0-24-42",vendor: "Shenzhenshi Xinzhongxin Technology Co.Ltd"},
  {max_prefix: "3C-57-65",vendor: "UNIONMAN TECHNOLOGY CO.,LTD"},
  {max_prefix: "58-05-D9",vendor: "Seiko Epson Corporation"},
  {max_prefix: "EC-5A-31",vendor: "BUFFALO.INC"},
  {max_prefix: "F0-1E-AC",vendor: "Rentokil Initial"},
  {max_prefix: "34-E6-E6",vendor: "LG Innotek"},
  {max_prefix: "EC-A7-B1",vendor: "IEEE Registration Authority"},
  {max_prefix: "B4-86-18",vendor: "GX India Pvt Ltd"},
  {max_prefix: "EC-1D-A9",vendor: "YEALINK(XIAMEN) NETWORK TECHNOLOGY CO.,LTD."},
  {max_prefix: "A4-A5-84",vendor: "Cisco Systems, Inc"},
  {max_prefix: "C4-A9-B8",vendor: "XIAMENSHI C-CHIP TECHNOLOGY CO.,LTD"},
  {max_prefix: "34-5C-F3",vendor: "Chipsea Technologies (Shenzhen) Corp."},
  {max_prefix: "58-ED-99",vendor: "zte corporation"},
  {max_prefix: "24-41-FE",vendor: "Wistron Neweb Corporation"},
  {max_prefix: "F4-BD-B9",vendor: "Ubiqcom India Pvt Ltd"},
  {max_prefix: "20-B5-C6",vendor: "Mimosa Networks"},
  {max_prefix: "98-1C-A2",vendor: "Apple, Inc."},
  {max_prefix: "E8-FF-F4",vendor: "Apple, Inc."},
  {max_prefix: "0C-15-63",vendor: "Apple, Inc."},
  {max_prefix: "3C-DD-57",vendor: "Apple, Inc."},
  {max_prefix: "7C-3B-2D",vendor: "Apple, Inc."},
  {max_prefix: "EC-53-6F",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "D8-01-D0",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "84-EE-7F",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "7C-FA-D6",vendor: "Realme Chongqing Mobile Telecommunications Corp.,Ltd."},
  {max_prefix: "78-83-71",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "E8-F0-A4",vendor: "Antonios A. Chariton"},
  {max_prefix: "F4-3A-FA",vendor: "IEEE Registration Authority"},
  {max_prefix: "CC-26-14",vendor: "Sichuan Tianyi Comheart Telecom Co.,LTD"},
  {max_prefix: "3C-38-24",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "D0-C0-BF",vendor: "Actions Microelectronics"},
  {max_prefix: "DC-2F-FA",vendor: "GE Lighting"},
  {max_prefix: "28-4D-7D",vendor: "zte corporation"},
  {max_prefix: "B8-29-03",vendor: "VIETNAM POST AND TELECOMMUNICATION INDUSTRY TECHNOLOGY JOIN STOCK COMPANY"},
  {max_prefix: "A8-FE-CE",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "28-6D-DA",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "84-CC-11",vendor: "LG Electornics"},
  {max_prefix: "C4-57-46",vendor: "Texas Instruments"},
  {max_prefix: "20-BE-B4",vendor: "Hisense broadband multimedia technology Co.,Ltd"},
  {max_prefix: "C4-7D-A8",vendor: "NXP Semiconductor (Tianjin) LTD."},
  {max_prefix: "B8-A0-B8",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "44-0B-AB",vendor: "Positivo Tecnologia S.A."},
  {max_prefix: "DC-7E-1D",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "2C-A7-97",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "98-E2-55",vendor: "Nintendo Co.,Ltd"},
  {max_prefix: "08-71-58",vendor: "HANSHOW TECHNOLOGY CO.,LTD."},
  {max_prefix: "AC-17-94",vendor: "SHENZHEN CHUANGWEI-RGB ELECTRONICS CO.,LTD"},
  {max_prefix: "58-25-7A",vendor: "MOBIWIRE MOBILES(NINGBO) CO.,LTD"},
  {max_prefix: "88-92-CC",vendor: "Tonly Technology Co. Ltd"},
  {max_prefix: "34-90-EA",vendor: "Murata Manufacturing Co., Ltd."},
  {max_prefix: "DC-06-75",vendor: "Espressif Inc."},
  {max_prefix: "00-60-D5",vendor: "AMADA CO., LTD"},
  {max_prefix: "00-22-C8",vendor: "ModuVision Technologies"},
  {max_prefix: "EC-41-F9",vendor: "Shenzhen iComm Semiconductor CO.,LTD"},
  {max_prefix: "B8-CC-5F",vendor: "Shenzhen iComm Semiconductor CO.,LTD"},
  {max_prefix: "A4-60-11",vendor: "Verifone, Inc."},
  {max_prefix: "60-C7-98",vendor: "Verifone, Inc."},
  {max_prefix: "00-0B-4F",vendor: "Verifone, Inc."},
  {max_prefix: "88-BF-35",vendor: "Verifone, Inc."},
  {max_prefix: "30-8E-7A",vendor: "Shenzhen iComm Semiconductor CO.,LTD"},
  {max_prefix: "2C-DD-5F",vendor: "Shenzhen iComm Semiconductor CO.,LTD"},
  {max_prefix: "08-1A-1E",vendor: "Shenzhen iComm Semiconductor CO.,LTD"},
  {max_prefix: "88-B5-FF",vendor: "Shenzhen iComm Semiconductor CO.,LTD"},
  {max_prefix: "4C-A3-8F",vendor: "shenzhen trolink  Technology Co.,Ltd"},
  {max_prefix: "94-EC-13",vendor: "Hangzhou Ezviz Software Co.,Ltd."},
  {max_prefix: "90-56-71",vendor: "Cisco Systems, Inc"},
  {max_prefix: "70-BD-96",vendor: "Cisco Systems, Inc"},
  {max_prefix: "BC-FC-E7",vendor: "ASUSTek COMPUTER INC."},
  {max_prefix: "9C-DE-F0",vendor: "Jiangxi Risound Electronics Co.,LTD"},
  {max_prefix: "08-12-87",vendor: "Jiangxi Risound Electronics Co.,LTD"},
  {max_prefix: "B4-6E-10",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "6C-4F-89",vendor: "Zyxel Communications Corporation"},
  {max_prefix: "A8-09-B1",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "34-A8-DB",vendor: "SenArch ApS"},
  {max_prefix: "EC-D9-09",vendor: "ALPSALPINE CO,.LTD"},
  {max_prefix: "40-5B-7F",vendor: "Broadcom Limited"},
  {max_prefix: "28-40-DD",vendor: "Sony Interactive Entertainment Inc."},
  {max_prefix: "5C-19-23",vendor: "Hangzhou Lanly Technology Co., Ltd."},
  {max_prefix: "18-50-73",vendor: "Tianjin HuaLai Technology CO., Ltd."},
  {max_prefix: "D8-20-A2",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "AC-69-CF",vendor: "Cisco Meraki"},
  {max_prefix: "FC-94-2E",vendor: "Cisco Meraki"},
  {max_prefix: "B8-A1-B8",vendor: "Arista Networks"},
  {max_prefix: "C0-D6-D5",vendor: "Microsoft Corporation"},
  {max_prefix: "14-06-A7",vendor: "CRESYN"},
  {max_prefix: "CC-D9-20",vendor: "Huizhou Desay SV Automotive Co., Ltd."},
  {max_prefix: "40-87-E5",vendor: "Wu Qi Technologies,Inc."},
  {max_prefix: "C4-F0-35",vendor: "Hughes Network Systems, LLC"},
  {max_prefix: "B4-E7-B3",vendor: "Edifier International"},
  {max_prefix: "D4-59-44",vendor: "tonies GmbH"},
  {max_prefix: "68-44-65",vendor: "Apple, Inc."},
  {max_prefix: "24-78-EF",vendor: "Nokia"},
  {max_prefix: "00-9D-85",vendor: "Sigmastar Technology Ltd."},
  {max_prefix: "D8-C7-C8",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "C0-B2-2F",vendor: "Apple, Inc."},
  {max_prefix: "60-81-10",vendor: "Apple, Inc."},
  {max_prefix: "64-A3-CC",vendor: "LeoLabs"},
  {max_prefix: "E8-EA-FA",vendor: "Qingdao Haier Technology Co.,Ltd"},
  {max_prefix: "AC-F4-73",vendor: "iRobot Corporation"},
  {max_prefix: "64-4F-56",vendor: "YEALINK(XIAMEN) NETWORK TECHNOLOGY CO.,LTD."},
  {max_prefix: "38-17-B1",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "DC-90-09",vendor: "Intel Corporate"},
  {max_prefix: "54-E4-ED",vendor: "Intel Corporate"},
  {max_prefix: "38-F0-BB",vendor: "CompuSoft A/S"},
  {max_prefix: "08-B8-D0",vendor: "Chipsea Technologies (Shenzhen) Corp."},
  {max_prefix: "00-15-D1",vendor: "Commscope"},
  {max_prefix: "00-00-CA",vendor: "Commscope"},
  {max_prefix: "00-15-A4",vendor: "Commscope"},
  {max_prefix: "00-15-A3",vendor: "Commscope"},
  {max_prefix: "00-15-A2",vendor: "Commscope"},
  {max_prefix: "00-0F-CC",vendor: "Commscope"},
  {max_prefix: "D0-39-B3",vendor: "Commscope"},
  {max_prefix: "BC-CA-B5",vendor: "Commscope"},
  {max_prefix: "5C-8F-E0",vendor: "Commscope"},
  {max_prefix: "48-96-D9",vendor: "zte corporation"},
  {max_prefix: "84-61-A0",vendor: "Commscope"},
  {max_prefix: "3C-DF-A9",vendor: "Commscope"},
  {max_prefix: "0C-F8-93",vendor: "Commscope"},
  {max_prefix: "AC-B3-13",vendor: "Commscope"},
  {max_prefix: "10-86-8C",vendor: "Commscope"},
  {max_prefix: "38-4C-90",vendor: "Commscope"},
  {max_prefix: "00-1D-D3",vendor: "Commscope"},
  {max_prefix: "00-1D-D0",vendor: "Commscope"},
  {max_prefix: "00-15-CE",vendor: "Commscope"},
  {max_prefix: "00-04-BD",vendor: "Commscope"},
  {max_prefix: "00-0F-9F",vendor: "Commscope"},
  {max_prefix: "00-11-1A",vendor: "Commscope"},
  {max_prefix: "00-17-EE",vendor: "Commscope"},
  {max_prefix: "00-15-2F",vendor: "Commscope"},
  {max_prefix: "F8-7B-7A",vendor: "Commscope"},
  {max_prefix: "C8-AA-21",vendor: "Commscope"},
  {max_prefix: "DC-45-17",vendor: "Commscope"},
  {max_prefix: "00-1F-C4",vendor: "Commscope"},
  {max_prefix: "00-23-AF",vendor: "Commscope"},
  {max_prefix: "00-23-95",vendor: "Commscope"},
  {max_prefix: "00-22-B4",vendor: "Commscope"},
  {max_prefix: "00-22-10",vendor: "Commscope"},
  {max_prefix: "00-24-A1",vendor: "Commscope"},
  {max_prefix: "80-96-B1",vendor: "Commscope"},
  {max_prefix: "00-E0-6F",vendor: "Commscope"},
  {max_prefix: "00-14-9A",vendor: "Commscope"},
  {max_prefix: "00-1C-FB",vendor: "Commscope"},
  {max_prefix: "00-19-C0",vendor: "Commscope"},
  {max_prefix: "00-14-E8",vendor: "Commscope"},
  {max_prefix: "70-7E-43",vendor: "Commscope"},
  {max_prefix: "00-26-42",vendor: "Commscope"},
  {max_prefix: "2C-58-4F",vendor: "Commscope"},
  {max_prefix: "4C-12-65",vendor: "Commscope"},
  {max_prefix: "9C-C8-FC",vendor: "Commscope"},
  {max_prefix: "2C-99-24",vendor: "Commscope"},
  {max_prefix: "E0-22-02",vendor: "Commscope"},
  {max_prefix: "2C-7E-81",vendor: "Commscope"},
  {max_prefix: "8C-5B-F0",vendor: "Commscope"},
  {max_prefix: "0C-EA-C9",vendor: "Commscope"},
  {max_prefix: "3C-04-61",vendor: "Commscope"},
  {max_prefix: "64-12-69",vendor: "Commscope"},
  {max_prefix: "28-C8-7A",vendor: "Commscope"},
  {max_prefix: "D4-3F-CB",vendor: "Commscope"},
  {max_prefix: "60-92-F5",vendor: "Commscope"},
  {max_prefix: "4C-38-D8",vendor: "Commscope"},
  {max_prefix: "D4-B2-7A",vendor: "Commscope"},
  {max_prefix: "D8-25-22",vendor: "Commscope"},
  {max_prefix: "E0-B7-B1",vendor: "Commscope"},
  {max_prefix: "00-50-94",vendor: "Commscope"},
  {max_prefix: "F8-0B-BE",vendor: "Commscope"},
  {max_prefix: "58-56-E8",vendor: "Commscope"},
  {max_prefix: "44-34-A7",vendor: "Commscope"},
  {max_prefix: "68-38-E3",vendor: "EYESON SOLUTION CO.,Ltd"},
  {max_prefix: "3C-39-A8",vendor: "Shenzhen Taichi Technology Limited"},
  {max_prefix: "A0-E7-AE",vendor: "Commscope"},
  {max_prefix: "90-93-5A",vendor: "Commscope"},
  {max_prefix: "24-A1-86",vendor: "Commscope"},
  {max_prefix: "14-C0-3E",vendor: "Commscope"},
  {max_prefix: "C0-89-AB",vendor: "Commscope"},
  {max_prefix: "0C-B7-71",vendor: "Commscope"},
  {max_prefix: "90-C7-92",vendor: "Commscope"},
  {max_prefix: "D4-6C-6D",vendor: "Commscope"},
  {max_prefix: "BC-5B-D5",vendor: "Commscope"},
  {max_prefix: "14-5D-34",vendor: "SHENZHEN BILIAN ELECTRONIC CO.\uff0cLTD"},
  {max_prefix: "AC-C5-B4",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "B4-D5-E5",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "C4-EF-3D",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "C0-D5-E2",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "68-ED-57",vendor: "Juniper Networks"},
  {max_prefix: "40-36-B7",vendor: "Juniper Networks"},
  {max_prefix: "7C-C7-90",vendor: "Palo Alto Networks"},
  {max_prefix: "00-05-40",vendor: "Tokyo Electron Device Limited"},
  {max_prefix: "4C-30-89",vendor: " Hitachi Rail GTS Deutschland GmbH"},
  {max_prefix: "2C-66-F5",vendor: "SHENZHEN ELECTRICAL APPLIANCES CO."},
  {max_prefix: "BC-BE-FB",vendor: "ASL Xiamen Technology CO., LTD"},
  {max_prefix: "00-50-4D",vendor: "Tokyo Electron Device Limited"},
  {max_prefix: "E4-64-47",vendor: "EM Microelectronic"},
  {max_prefix: "34-36-07",vendor: "PINEWAVE PTE. LTD."},
  {max_prefix: "C8-70-23",vendor: "Altice Labs"},
  {max_prefix: "38-22-28",vendor: "Telink Micro LLC"},
  {max_prefix: "54-27-22",vendor: "Lacroix"},
  {max_prefix: "A4-7D-78",vendor: "Edgecore Americas Networking Corporation"},
  {max_prefix: "50-E0-99",vendor: "HangZhou Atuo Future Technology Co., Ltd"},
  {max_prefix: "54-7E-1A",vendor: "Kaon Group Co., Ltd."},
  {max_prefix: "B8-D4-F7",vendor: "New H3C Technologies Co., Ltd"},
  {max_prefix: "68-50-8C",vendor: "Shanghai Sunmi Technology Co.,Ltd."},
  {max_prefix: "A8-8F-99",vendor: "Arista Networks"},
  {max_prefix: "E8-CF-83",vendor: "Dell Inc."},
  {max_prefix: "48-BC-0E",vendor: "Sichuan AI-Link Technology Co., Ltd."},
  {max_prefix: "6C-22-F7",vendor: "Nokia Shanghai Bell Co., Ltd."},
  {max_prefix: "24-48-85",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "CC-8A-84",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "80-2E-DE",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "98-A4-4E",vendor: "IEC Technologies S. de R.L de C.V."},
  {max_prefix: "38-4D-D2",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "BC-F2-E5",vendor: "Powerful Devices"},
  {max_prefix: "04-FD-E8",vendor: "Technoalpin"},
  {max_prefix: "90-88-A9",vendor: "IEEE Registration Authority"},
  {max_prefix: "58-8C-81",vendor: "Espressif Inc."},
  {max_prefix: "34-86-DA",vendor: "Honor Device Co., Ltd."},
  {max_prefix: "F8-CE-21",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "F8-C9-03",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "44-11-46",vendor: "The Chamberlain Group, Inc"},
  {max_prefix: "F4-33-92",vendor: "Cisco Systems, Inc"},
  {max_prefix: "38-92-31",vendor: "TECNO MOBILE LIMITED"},
  {max_prefix: "6C-05-D3",vendor: "CHINA DRAGON TECHNOLOGY LIMITED"},
  {max_prefix: "7C-8C-09",vendor: "Mellanox Technologies, Inc."},
  {max_prefix: "44-3E-8A",vendor: "Texas Instruments"},
  {max_prefix: "34-8E-89",vendor: "Hangzhou BroadLink Technology Co., Ltd"},
  {max_prefix: "84-28-D6",vendor: "SERVERCOM (INDIA) PRIVATE LIMITED"},
  {max_prefix: "AC-D9-29",vendor: "Quectel Wireless Solutions Co.,Ltd."},
  {max_prefix: "10-C7-35",vendor: "Microsoft Corporation"},
  {max_prefix: "64-02-8F",vendor: "Silicon Laboratories"},
  {max_prefix: "CC-B1-48",vendor: "Arcadyan Corporation"},
  {max_prefix: "DC-56-7B",vendor: "CLOUD NETWORK TECHNOLOGY SINGAPORE PTE. LTD."},
  {max_prefix: "74-FB-17",vendor: "Qingdao Goertek  Horizons Tecnology  Co.,LTD"},
  {max_prefix: "C4-E3-CE",vendor: "EnGenius Technologies, Inc."},
  {max_prefix: "D4-90-E0",vendor: "Wachendorff Automation GmbH & CO.KG"},
  {max_prefix: "88-9E-96",vendor: "zte corporation"},
  {max_prefix: "54-67-49",vendor: "Google, Inc."},
  {max_prefix: "BC-85-D0",vendor: "SKY UK LIMITED"},
  {max_prefix: "14-08-05",vendor: "SKY UK LIMITED"},
  {max_prefix: "AC-CA-0F",vendor: "INDISR COMMUNICATION SERVICES AND TECHNOLOGIES INDIA"},
  {max_prefix: "9C-79-E3",vendor: "Apple, Inc."},
  {max_prefix: "88-51-F2",vendor: "Apple, Inc."},
  {max_prefix: "5C-AD-BA",vendor: "Apple, Inc."},
  {max_prefix: "1C-F9-D5",vendor: "Apple, Inc."},
  {max_prefix: "78-3F-4D",vendor: "Apple, Inc."},
  {max_prefix: "50-13-9D",vendor: "Sierra Wireless, ULC"},
  {max_prefix: "58-FC-C8",vendor: "Honeywell"},
  {max_prefix: "F8-DC-7A",vendor: "Variscite LTD"},
  {max_prefix: "24-5D-92",vendor: "Juniper Networks"},
  {max_prefix: "7C-BE-CE",vendor: "Private"},
  {max_prefix: "C8-90-A8",vendor: "Bouffalo Lab (Nanjing) Co., Ltd."},
  {max_prefix: "FC-01-2C",vendor: "Espressif Inc."},
  {max_prefix: "C8-A4-CD",vendor: "Realme Chongqing Mobile Telecommunications Corp.,Ltd."},
  {max_prefix: "78-49-46",vendor: "Cambridge Mobile Telematics, Inc."},
  {max_prefix: "48-EE-E2",vendor: "ROAMWIFI TECHNOLOGY(HK) LIMITED"},
  {max_prefix: "A0-BD-71",vendor: "QUALCOMM Incorporated"},
  {max_prefix: "88-0F-62",vendor: "Silicon Laboratories"},
  {max_prefix: "30-34-DB",vendor: "LG Innotek"},
  {max_prefix: "74-10-E0",vendor: "Broadcom Limited"},
  {max_prefix: "58-23-BC",vendor: "IEEE Registration Authority"},
  {max_prefix: "98-77-D5",vendor: "WiZ"},
  {max_prefix: "A0-E0-6D",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "90-3C-DA",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "3C-97-22",vendor: "shenzhen worldelite electronics co., LTD"},
  {max_prefix: "04-20-8A",vendor: "\u6d59\u6c5f\u8def\u5ddd\u79d1\u6280\u6709\u9650\u516c\u53f8"},
  {max_prefix: "20-F1-20",vendor: "Cisco Systems, Inc"},
  {max_prefix: "EC-DD-24",vendor: "Cisco Systems, Inc"},
  {max_prefix: "30-BE-29",vendor: "AltoBeam Inc."},
  {max_prefix: "84-49-EE",vendor: "Demant Enterprise A/S"},
  {max_prefix: "C4-00-B5",vendor: "HongKong Tenry Technology Co., Ltd."},
  {max_prefix: "84-52-34",vendor: "Juniper Networks"},
  {max_prefix: "34-49-51",vendor: "Eliyan Corp."},
  {max_prefix: "A4-43-43",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "44-B3-C5",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "64-C0-1A",vendor: "Sichuan Tianyi Comheart Telecom Co.,LTD"},
  {max_prefix: "78-25-99",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "C4-FF-99",vendor: "Intel Corporate"},
  {max_prefix: "28-95-29",vendor: "Intel Corporate"},
  {max_prefix: "A4-99-A8",vendor: "eero inc."},
  {max_prefix: "64-31-35",vendor: "Apple, Inc."},
  {max_prefix: "E0-BF-B2",vendor: "Apple, Inc."},
  {max_prefix: "08-62-02",vendor: "Apple, Inc."},
  {max_prefix: "D8-23-E0",vendor: "SPEEDTECH CORP."},
  {max_prefix: "BC-86-A5",vendor: "Cambridge Mobile Telematics, Inc."},
  {max_prefix: "F8-10-A0",vendor: "Xtreme Testek Inc."},
  {max_prefix: "A0-9D-22",vendor: "Apple, Inc."},
  {max_prefix: "4C-C4-49",vendor: "Icotera A/S"},
  {max_prefix: "68-EF-DC",vendor: "Apple, Inc."},
  {max_prefix: "B8-CB-93",vendor: "IC BOSS.COM TECH INC"},
  {max_prefix: "C4-D7-DC",vendor: "Zhejiang Weilai Jingling Artificial Intelligence Technology Co., Ltd."},
  {max_prefix: "1C-79-2D",vendor: "CHINA DRAGON TECHNOLOGY LIMITED"},
  {max_prefix: "14-80-1F",vendor: "Sunnovo International Limited"},
  {max_prefix: "E8-45-EB",vendor: "Kohler Ventures, Inc."},
  {max_prefix: "78-9F-6A",vendor: "Ruckus Wireless"},
  {max_prefix: "00-03-46",vendor: "KOKUSAI DENKI Electric Inc."},
  {max_prefix: "54-B2-9D",vendor: "Shenzhen YOUHUA Technology Co., Ltd"},
  {max_prefix: "80-0E-A9",vendor: "TCL Yuxin Zhixing Technology (Huizhou) Co.,Ltd"},
  {max_prefix: "80-9D-65",vendor: "FN-LINK TECHNOLOGY Ltd."},
  {max_prefix: "B0-EE-7B",vendor: "Roku, Inc"},
  {max_prefix: "D8-31-34",vendor: "Roku, Inc"},
  {max_prefix: "10-59-32",vendor: "Roku, Inc"},
  {max_prefix: "A8-B5-7C",vendor: "Roku, Inc"},
  {max_prefix: "34-5E-08",vendor: "Roku, Inc"},
  {max_prefix: "C4-79-81",vendor: "Ehya LTD"},
  {max_prefix: "4C-2F-7B",vendor: "AltoBeam Inc."},
  {max_prefix: "00-A6-2B",vendor: "Shanghai High-Flying Electronics Technology Co.,Ltd"},
  {max_prefix: "3C-56-6E",vendor: "Infinix mobility limited"},
  {max_prefix: "9C-DF-B3",vendor: "NXP Semiconductor (Tianjin) LTD."},
  {max_prefix: "BC-8D-7E",vendor: "Silicon Laboratories"},
  {max_prefix: "64-08-64",vendor: "Cisco Systems, Inc"},
  {max_prefix: "A4-A4-04",vendor: "Bubendorff SAS"},
  {max_prefix: "00-1C-2B",vendor: "Hive"},
  {max_prefix: "E0-EF-BF",vendor: "Nintendo Co.,Ltd"},
  {max_prefix: "88-2A-E1",vendor: "MRC INC."},
  {max_prefix: "98-52-07",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "D4-00-CA",vendor: "Continental Automotive Systems S.R.L"},
  {max_prefix: "A8-0D-E1",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "C0-E9-11",vendor: "RealNetworks"},
  {max_prefix: "B4-E9-B8",vendor: "Dell Inc."},
  {max_prefix: "A4-BA-70",vendor: "Beijing Xiaomi Mobile Software Co., Ltd"},
  {max_prefix: "60-6E-53",vendor: "Beijing Wisdomstar Technology Co., Ltd"},
  {max_prefix: "78-28-CA",vendor: "Sonos, Inc."},
  {max_prefix: "54-2A-1B",vendor: "Sonos, Inc."},
  {max_prefix: "F0-F6-C1",vendor: "Sonos, Inc."},
  {max_prefix: "D4-2D-C5",vendor: "i-PRO Co., Ltd."},
  {max_prefix: "6C-B0-77",vendor: "ALL Winner (Hong Kong) Limited"},
  {max_prefix: "D0-92-88",vendor: "Powertek Limited"},
  {max_prefix: "68-82-F2",vendor: "FIXME GmbH"},
  {max_prefix: "94-3E-FD",vendor: "IEEE Registration Authority"},
  {max_prefix: "00-74-9C",vendor: "Ruijie Networks Co.,LTD"},
  {max_prefix: "E0-1C-41",vendor: "Extreme Networks Headquarters"},
  {max_prefix: "BC-F3-10",vendor: "Extreme Networks Headquarters"},
  {max_prefix: "48-9B-D5",vendor: "Extreme Networks Headquarters"},
  {max_prefix: "DC-B8-08",vendor: "Extreme Networks Headquarters"},
  {max_prefix: "8C-49-7A",vendor: "Extreme Networks Headquarters"},
  {max_prefix: "B0-27-CF",vendor: "Extreme Networks Headquarters"},
  {max_prefix: "D8-54-A2",vendor: "Extreme Networks Headquarters"},
  {max_prefix: "F4-6E-95",vendor: "Extreme Networks Headquarters"},
  {max_prefix: "00-01-30",vendor: "Extreme Networks Headquarters"},
  {max_prefix: "FC-0A-81",vendor: "Extreme Networks Headquarters"},
  {max_prefix: "C8-51-FB",vendor: "Extreme Networks Headquarters"},
  {max_prefix: "DC-23-3B",vendor: "Extreme Networks Headquarters"},
  {max_prefix: "C8-66-5D",vendor: "Extreme Networks Headquarters"},
  {max_prefix: "E4-DB-AE",vendor: "Extreme Networks Headquarters"},
  {max_prefix: "00-0F-EA",vendor: "GIGA-BYTE TECHNOLOGY CO.,LTD."},
  {max_prefix: "48-41-7B",vendor: "Nokia Solutions and Networks GmbH & Co. KG"},
  {max_prefix: "38-AD-2B",vendor: "Hitron Technologies. Inc"},
  {max_prefix: "E8-45-8B",vendor: "MitraStar Technology Corp."},
  {max_prefix: "1C-AB-48",vendor: "TECNO MOBILE LIMITED"},
  {max_prefix: "60-8C-DF",vendor: "Beamtrail-Sole Proprietorship"},
  {max_prefix: "30-27-CF",vendor: "Canopy Growth Corp"},
  {max_prefix: "D0-44-33",vendor: "Clourney Semiconductor"},
  {max_prefix: "00-12-77",vendor: "Beijer Electronics Corp."},
  {max_prefix: "94-45-60",vendor: "Google, Inc."},
  {max_prefix: "5C-33-7B",vendor: "Google, Inc."},
  {max_prefix: "40-86-CB",vendor: "D-Link Corporation"},
  {max_prefix: "20-47-B5",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "60-45-2E",vendor: "Intel Corporate"},
  {max_prefix: "8C-E9-EE",vendor: "Intel Corporate"},
  {max_prefix: "CC-D3-42",vendor: "Cisco Systems, Inc"},
  {max_prefix: "40-B8-C2",vendor: "OSMOZIS"},
  {max_prefix: "88-B4-36",vendor: "FUJIFILM Corporation"},
  {max_prefix: "2C-9C-58",vendor: "CLOUD NETWORK TECHNOLOGY SINGAPORE PTE. LTD."},
  {max_prefix: "B8-F4-4F",vendor: "u-blox AG"},
  {max_prefix: "54-09-29",vendor: "Inventus Power Eletronica do Brasil LTDA"},
  {max_prefix: "2C-BA-CA",vendor: "Cosonic Electroacoustic Technology Co., Ltd."},
  {max_prefix: "00-9C-C0",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "60-66-82",vendor: "SHENZHEN ATEKO PHOTOELECTRICITY CO.,LTD"},
  {max_prefix: "78-8D-AF",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "BC-0F-FE",vendor: "Juniper Networks"},
  {max_prefix: "BC-18-96",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "28-31-F8",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "C4-AA-99",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "A8-F5-E1",vendor: "Shenzhen Shokz Co., Ltd."},
  {max_prefix: "E8-9E-13",vendor: "CRESYN"},
  {max_prefix: "7C-FC-FD",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "48-87-B8",vendor: "TCL King Electrical Appliances(Huizhou)Co.,Ltd"},
  {max_prefix: "70-58-A4",vendor: "Actiontec Electronics Inc."},
  {max_prefix: "A4-94-DC",vendor: "Infinite Clouds"},
  {max_prefix: "E4-78-76",vendor: "Arista Networks"},
  {max_prefix: "A4-A9-30",vendor: "Beijing Xiaomi Mobile Software Co., Ltd"},
  {max_prefix: "4C-43-41",vendor: "Calix Inc."},
  {max_prefix: "E4-6C-D1",vendor: "Calix Inc."},
  {max_prefix: "D0-F2-7F",vendor: "BrewLogix, LLC"},
  {max_prefix: "18-80-25",vendor: "Hangzhou Hikvision Digital Technology Co.,Ltd."},
  {max_prefix: "E0-D3-B4",vendor: "Cisco Meraki"},
  {max_prefix: "FC-8C-11",vendor: "Microsoft Corporation"},
  {max_prefix: "04-FA-3F",vendor: "OptiCore Inc."},
  {max_prefix: "44-1D-B1",vendor: "APTIV SERVICES US, LLC"},
  {max_prefix: "88-F0-0F",vendor: "Miraeil"},
  {max_prefix: "44-35-D3",vendor: "GD Midea Air-Conditioning Equipment Co.,Ltd."},
  {max_prefix: "F8-F0-9D",vendor: "Hangzhou Prevail Communication Technology Co., Ltd"},
  {max_prefix: "7C-75-2D",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "84-EE-E4",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "FC-93-6B",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "BC-B6-FB",vendor: "P4Q ELECTRONICS, S.L."},
  {max_prefix: "A0-22-52",vendor: "Astra Wireless Technology FZ-LLC"},
  {max_prefix: "BC-10-2F",vendor: "SJI Industry Company"},
  {max_prefix: "74-C5-30",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "18-A7-88",vendor: "Shenzhen MEK Intellisys Pte Ltd"},
  {max_prefix: "BC-96-E5",vendor: "SERCOMM PHILIPPINES INC"},
  {max_prefix: "64-DA-ED",vendor: "eero inc."},
  {max_prefix: "28-94-01",vendor: "NETGEAR"},
  {max_prefix: "D8-78-C9",vendor: "SERVERCOM (INDIA) PRIVATE LIMITED"},
  {max_prefix: "A8-5E-F2",vendor: "TECNO MOBILE LIMITED"},
  {max_prefix: "78-9A-18",vendor: "Routerboard.com"},
  {max_prefix: "A8-99-AD",vendor: "Chaoyue Technology Co., Ltd."},
  {max_prefix: "D0-15-BB",vendor: "IEEE Registration Authority"},
  {max_prefix: "64-CE-6E",vendor: "Sierra Wireless, ULC"},
  {max_prefix: "84-DB-2F",vendor: "Sierra Wireless, ULC"},
  {max_prefix: "D0-48-4F",vendor: "Nokia Solutions and Networks GmbH & Co. KG"},
  {max_prefix: "78-DF-72",vendor: "Shanghai Imilab Technology Co.Ltd"},
  {max_prefix: "60-2B-58",vendor: "EM Microelectronic"},
  {max_prefix: "10-9D-9C",vendor: "EM Microelectronic"},
  {max_prefix: "28-FF-5F",vendor: "HG Genuine Intelligent Terminal (Xiaogan) Co.,Ltd."},
  {max_prefix: "2C-45-9A",vendor: "Dixon Technologies (India) Limited"},
  {max_prefix: "6C-6E-07",vendor: "CE LINK LIMITED"},
  {max_prefix: "6C-62-86",vendor: "Nokia"},
  {max_prefix: "BC-85-29",vendor: "Jiangxi Remote lntelligence Technology Co.,Ltd"},
  {max_prefix: "74-92-BA",vendor: "Movesense Ltd"},
  {max_prefix: "60-76-23",vendor: "Shenzhen E-Superlink Technology Co., Ltd"},
  {max_prefix: "B8-FC-28",vendor: "Valeo Vision Systems"},
  {max_prefix: "00-20-FC",vendor: "Matrox Central Services Inc"},
  {max_prefix: "88-81-87",vendor: "Umeox Innovations Co.,Ltd"},
  {max_prefix: "50-48-2C",vendor: "IEEE Registration Authority"},
  {max_prefix: "48-C4-61",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "B8-B7-DB",vendor: "GOIP Global Services Pvt. Ltd."},
  {max_prefix: "88-0C-E0",vendor: "Texas Instruments"},
  {max_prefix: "B8-3D-F6",vendor: "Texas Instruments"},
  {max_prefix: "B4-DD-E0",vendor: "Shanghai Amphenol Airwave Communication Electronics Co.,Ltd."},
  {max_prefix: "C4-27-95",vendor: "Vantiva USA LLC"},
  {max_prefix: "E0-88-5D",vendor: "Vantiva USA LLC"},
  {max_prefix: "80-29-94",vendor: "Vantiva USA LLC"},
  {max_prefix: "94-04-E3",vendor: "Vantiva USA LLC"},
  {max_prefix: "90-64-AD",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "D4-D8-92",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "30-E0-4F",vendor: "Apple, Inc."},
  {max_prefix: "64-E4-A5",vendor: "LG Electronics"},
  {max_prefix: "6C-A3-67",vendor: "Avlinkpro"},
  {max_prefix: "58-96-30",vendor: "Vantiva USA LLC"},
  {max_prefix: "98-9D-5D",vendor: "Vantiva USA LLC"},
  {max_prefix: "48-4B-D4",vendor: "Vantiva USA LLC"},
  {max_prefix: "E0-DB-D1",vendor: "Vantiva USA LLC"},
  {max_prefix: "EC-A8-1F",vendor: "Vantiva USA LLC"},
  {max_prefix: "54-A6-5C",vendor: "Vantiva USA LLC"},
  {max_prefix: "DC-EB-69",vendor: "Vantiva USA LLC"},
  {max_prefix: "94-6A-77",vendor: "Vantiva USA LLC"},
  {max_prefix: "D4-B9-2F",vendor: "Vantiva USA LLC"},
  {max_prefix: "D4-0F-9E",vendor: "Apple, Inc."},
  {max_prefix: "98-0D-AF",vendor: "Apple, Inc."},
  {max_prefix: "DC-6D-BC",vendor: "Apple, Inc."},
  {max_prefix: "A4-D2-3E",vendor: "Apple, Inc."},
  {max_prefix: "B4-2A-0E",vendor: "Vantiva USA LLC"},
  {max_prefix: "60-3D-26",vendor: "Vantiva USA LLC"},
  {max_prefix: "48-BD-CE",vendor: "Vantiva USA LLC"},
  {max_prefix: "98-D3-D7",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "88-6E-EB",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "E4-0D-3B",vendor: "Ericsson AB"},
  {max_prefix: "EC-53-82",vendor: "Honor Device Co., Ltd."},
  {max_prefix: "00-18-AD",vendor: "NIDEC INSTRUMENTS CORPORATION"},
  {max_prefix: "A4-CC-B3",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "9C-09-71",vendor: "New H3C Technologies Co., Ltd"},
  {max_prefix: "98-AB-15",vendor: "Fujian Youyike Technology Co.,Ltd"},
  {max_prefix: "70-93-C1",vendor: "eero inc."},
  {max_prefix: "54-49-FC",vendor: "Ubee Interactive Co., Limited"},
  {max_prefix: "20-17-46",vendor: "Paradromics, Inc."},
  {max_prefix: "CC-40-85",vendor: "WiZ"},
  {max_prefix: "DC-CD-18",vendor: "Nintendo Co.,Ltd"},
  {max_prefix: "18-F9-35",vendor: "Cisco Systems, Inc"},
  {max_prefix: "58-8B-1C",vendor: "Cisco Systems, Inc"},
  {max_prefix: "BC-31-98",vendor: "IEEE Registration Authority"},
  {max_prefix: "EC-A2-A0",vendor: "Taicang T&W Electronics"},
  {max_prefix: "0C-70-43",vendor: "Sony Interactive Entertainment Inc."},
  {max_prefix: "C8-15-4E",vendor: "Intel Corporate"},
  {max_prefix: "4C-49-6C",vendor: "Intel Corporate"},
  {max_prefix: "00-11-A9",vendor: "Nurivoice Co., Ltd"},
  {max_prefix: "EC-E7-C2",vendor: "China Mobile Group Device Co.,Ltd."},
  {max_prefix: "3C-0D-2C",vendor: "Liquid-Markets GmbH"},
  {max_prefix: "F4-BF-BB",vendor: "China Mobile Group Device Co.,Ltd."},
  {max_prefix: "78-9F-38",vendor: "Shenzhen Feasycom Co., Ltd"},
  {max_prefix: "54-4C-8A",vendor: "Microsoft Corporation"},
  {max_prefix: "84-AC-60",vendor: "Guangxi Hesheng Electronics Co., Ltd."},
  {max_prefix: "9C-A3-89",vendor: "Nokia"},
  {max_prefix: "5C-10-1E",vendor: "zte corporation"},
  {max_prefix: "90-5A-08",vendor: "Super Micro Computer, Inc."},
  {max_prefix: "90-70-D3",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "6C-48-A6",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "FC-B9-DF",vendor: "Motorola Mobility LLC, a Lenovo Company"},
  {max_prefix: "D0-81-C5",vendor: "Juniper Networks"},
  {max_prefix: "FC-F7-63",vendor: "KunGao Micro (JiangSu) Co., LTd"},
  {max_prefix: "80-8C-97",vendor: "Kaon Group Co., Ltd."},
  {max_prefix: "C4-60-26",vendor: "SKY UK LIMITED"},
  {max_prefix: "00-0D-39",vendor: "Nevion"},
  {max_prefix: "4C-12-E8",vendor: "VIETNAM POST AND TELECOMMUNICATION INDUSTRY TECHNOLOGY JOIN STOCK COMPANY"},
  {max_prefix: "20-02-FE",vendor: "Hangzhou Dangbei Network Technology Co., Ltd"},
  {max_prefix: "74-27-2C",vendor: "Advanced Micro Devices, Inc."},
  {max_prefix: "C0-5B-44",vendor: "Beijing Xiaomi Mobile Software Co., Ltd"},
  {max_prefix: "20-88-10",vendor: "Dell Inc."},
  {max_prefix: "A0-62-60",vendor: "Private"},
  {max_prefix: "E4-45-19",vendor: "Beijing Xiaomi Electronics Co.,Ltd"},
  {max_prefix: "E4-B2-24",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "A8-3E-D3",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "64-67-CD",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "34-EC-B6",vendor: "Phyplus Microelectronics Limited"},
  {max_prefix: "80-76-77",vendor: "hangzhou puwell cloud tech co., ltd."},
  {max_prefix: "9C-1E-CF",vendor: "Valeo Telematik und Akustik GmbH"},
  {max_prefix: "F4-2B-8C",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "4C-D2-FB",vendor: "UNIONMAN TECHNOLOGY CO.,LTD"},
  {max_prefix: "E4-1A-1D",vendor: "NOVEA ENERGIES"},
  {max_prefix: "F4-CA-E7",vendor: "Arcadyan Corporation"},
  {max_prefix: "3C-EF-42",vendor: "TCT mobile ltd"},
  {max_prefix: "B0-A3-F2",vendor: "Huaqin Technology Co. LTD"},
  {max_prefix: "84-F1-75",vendor: "Jiangxi Xunte Intelligent Terminal Co., Ltd"},
  {max_prefix: "20-E4-6F",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "C8-98-28",vendor: "zte corporation"},
  {max_prefix: "DC-36-42",vendor: "zte corporation"},
  {max_prefix: "78-8A-86",vendor: "China Dragon Technology Limited"},
  {max_prefix: "E8-24-04",vendor: "Quectel Wireless Solutions Co.,Ltd."},
  {max_prefix: "F8-66-91",vendor: "Sichuan Tianyi Comheart Telecom Co.,LTD"},
  {max_prefix: "6C-2A-DF",vendor: "IEEE Registration Authority"},
  {max_prefix: "88-30-37",vendor: "Juniper Networks"},
  {max_prefix: "E4-38-19",vendor: "Shenzhen Hi-Link Electronic CO.,Ltd."},
  {max_prefix: "60-DC-81",vendor: "AltoBeam Inc."},
  {max_prefix: "84-72-93",vendor: "Texas Instruments"},
  {max_prefix: "F8-2E-0C",vendor: "Texas Instruments"},
  {max_prefix: "90-06-F2",vendor: "Texas Instruments"},
  {max_prefix: "E8-6E-3A",vendor: "Sony Interactive Entertainment Inc."},
  {max_prefix: "BC-B1-D3",vendor: "Cisco Meraki"},
  {max_prefix: "B8-7B-D4",vendor: "Google, Inc."},
  {max_prefix: "C8-13-8B",vendor: "Shenzhen Skyworth Digital  Technology  CO., Ltd"},
  {max_prefix: "34-AA-31",vendor: "Shenzhen Skyworth Digital  Technology  CO., Ltd"},
  {max_prefix: "A0-4C-0C",vendor: "Shenzhen Skyworth Digital  Technology  CO., Ltd"},
  {max_prefix: "6C-C2-42",vendor: "Shenzhen Skyworth Digital  Technology  CO., Ltd"},
  {max_prefix: "E0-73-E7",vendor: "HP Inc."},
  {max_prefix: "60-82-46",vendor: "Apple, Inc."},
  {max_prefix: "98-B3-79",vendor: "Apple, Inc."},
  {max_prefix: "04-9D-05",vendor: "Apple, Inc."},
  {max_prefix: "54-4E-45",vendor: "Private"},
  {max_prefix: "54-08-3B",vendor: "IEEE Registration Authority"},
  {max_prefix: "54-EF-43",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "D8-1B-B5",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "84-64-DD",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "68-A4-6A",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "F8-DB-88",vendor: "Dell Inc."},
  {max_prefix: "78-45-C4",vendor: "Dell Inc."},
  {max_prefix: "5C-26-0A",vendor: "Dell Inc."},
  {max_prefix: "F4-8E-38",vendor: "Dell Inc."},
  {max_prefix: "28-F1-0E",vendor: "Dell Inc."},
  {max_prefix: "10-98-36",vendor: "Dell Inc."},
  {max_prefix: "00-14-22",vendor: "Dell Inc."},
  {max_prefix: "00-15-C5",vendor: "Dell Inc."},
  {max_prefix: "90-B1-1C",vendor: "Dell Inc."},
  {max_prefix: "64-00-6A",vendor: "Dell Inc."},
  {max_prefix: "E4-43-4B",vendor: "Dell Inc."},
  {max_prefix: "00-1E-4F",vendor: "Dell Inc."},
  {max_prefix: "00-26-B9",vendor: "Dell Inc."},
  {max_prefix: "C8-1F-66",vendor: "Dell Inc."},
  {max_prefix: "D0-67-E5",vendor: "Dell Inc."},
  {max_prefix: "20-97-27",vendor: "TELTONIKA NETWORKS UAB"},
  {max_prefix: "00-1A-E8",vendor: "Unify Software and Solutions GmbH & Co. KG"},
  {max_prefix: "2C-FE-4F",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "90-7E-43",vendor: "zte corporation"},
  {max_prefix: "94-3E-E4",vendor: "WiSA Technologies Inc"},
  {max_prefix: "A8-BD-3A",vendor: "UNION MAN TECHNOLOGY CO.,LTD"},
  {max_prefix: "48-CA-C6",vendor: "UNION MAN TECHNOLOGY CO.,LTD"},
  {max_prefix: "D4-38-44",vendor: "UNION MAN TECHNOLOGY CO.,LTD"},
  {max_prefix: "D4-53-47",vendor: "Merytronic 2012, S.L."},
  {max_prefix: "6C-3C-8C",vendor: "Dell Inc."},
  {max_prefix: "C4-5A-B1",vendor: "Dell Inc."},
  {max_prefix: "A8-B0-28",vendor: "CubePilot Pty Ltd"},
  {max_prefix: "40-7F-5F",vendor: "Juniper Networks"},
  {max_prefix: "2C-EA-7F",vendor: "Dell Inc."},
  {max_prefix: "F0-D4-E2",vendor: "Dell Inc."},
  {max_prefix: "CC-48-3A",vendor: "Dell Inc."},
  {max_prefix: "30-D0-42",vendor: "Dell Inc."},
  {max_prefix: "8C-04-BA",vendor: "Dell Inc."},
  {max_prefix: "E4-54-E8",vendor: "Dell Inc."},
  {max_prefix: "A4-BB-6D",vendor: "Dell Inc."},
  {max_prefix: "E0-1F-6A",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "00-56-6D",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "90-CC-7A",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "8C-C5-8C",vendor: "ShenZhen Elsky Technology Co.,LTD"},
  {max_prefix: "2C-70-4F",vendor: "zte corporation"},
  {max_prefix: "64-52-34",vendor: "Sichuan Tianyi Comheart Telecom Co.,LTD"},
  {max_prefix: "5C-88-16",vendor: "Rockwell Automation"},
  {max_prefix: "38-16-72",vendor: "Shenzhen SuperElectron Technology Co.,Ltd."},
  {max_prefix: "6C-29-D2",vendor: "Cisco Systems, Inc"},
  {max_prefix: "08-CC-81",vendor: "Hangzhou Hikvision Digital Technology Co.,Ltd."},
  {max_prefix: "C0-DD-8A",vendor: "Meta Platforms Technologies, LLC"},
  {max_prefix: "CC-A1-74",vendor: "Meta Platforms Technologies, LLC"},
  {max_prefix: "18-F4-6B",vendor: "Telenor Connexion AB"},
  {max_prefix: "28-EA-0B",vendor: "Microsoft Corporation"},
  {max_prefix: "08-15-AE",vendor: "China Mobile Group Device Co.,Ltd."},
  {max_prefix: "04-B4-FE",vendor: "AVM Audiovisuelles Marketing und Computersysteme GmbH"},
  {max_prefix: "F4-54-33",vendor: "Rockwell Automation"},
  {max_prefix: "34-C0-F9",vendor: "Rockwell Automation"},
  {max_prefix: "D0-7B-6F",vendor: "Zhuhai Yunmai Technology Co.,Ltd"},
  {max_prefix: "04-A5-26",vendor: "Nokia"},
  {max_prefix: "20-BA-36",vendor: "u-blox AG"},
  {max_prefix: "D8-0A-E6",vendor: "zte corporation"},
  {max_prefix: "48-9E-9D",vendor: "Hui Zhou Gaoshengda Technology Co.,LTD"},
  {max_prefix: "A4-14-37",vendor: "Hangzhou Hikvision Digital Technology Co.,Ltd."},
  {max_prefix: "F8-4D-FC",vendor: "Hangzhou Hikvision Digital Technology Co.,Ltd."},
  {max_prefix: "84-9A-40",vendor: "Hangzhou Hikvision Digital Technology Co.,Ltd."},
  {max_prefix: "C0-51-7E",vendor: "Hangzhou Hikvision Digital Technology Co.,Ltd."},
  {max_prefix: "2C-A5-9C",vendor: "Hangzhou Hikvision Digital Technology Co.,Ltd."},
  {max_prefix: "40-AC-BF",vendor: "Hangzhou Hikvision Digital Technology Co.,Ltd."},
  {max_prefix: "98-F1-12",vendor: "Hangzhou Hikvision Digital Technology Co.,Ltd."},
  {max_prefix: "98-9D-E5",vendor: "Hangzhou Hikvision Digital Technology Co.,Ltd."},
  {max_prefix: "3C-1B-F8",vendor: "Hangzhou Hikvision Digital Technology Co.,Ltd."},
  {max_prefix: "78-45-58",vendor: "Ubiquiti Inc"},
  {max_prefix: "AC-8B-A9",vendor: "Ubiquiti Inc"},
  {max_prefix: "9C-65-EE",vendor: "DZS Inc."},
  {max_prefix: "9C-54-16",vendor: "Cisco Systems, Inc"},
  {max_prefix: "A0-AF-12",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "60-96-A4",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "48-BD-A7",vendor: "Honor Device Co., Ltd."},
  {max_prefix: "EC-21-50",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "30-B6-4F",vendor: "Juniper Networks"},
  {max_prefix: "08-B2-58",vendor: "Juniper Networks"},
  {max_prefix: "F4-A7-39",vendor: "Juniper Networks"},
  {max_prefix: "4C-16-FC",vendor: "Juniper Networks"},
  {max_prefix: "C8-E7-F0",vendor: "Juniper Networks"},
  {max_prefix: "7C-25-86",vendor: "Juniper Networks"},
  {max_prefix: "00-17-CB",vendor: "Juniper Networks"},
  {max_prefix: "00-1F-12",vendor: "Juniper Networks"},
  {max_prefix: "00-24-DC",vendor: "Juniper Networks"},
  {max_prefix: "5C-5E-AB",vendor: "Juniper Networks"},
  {max_prefix: "78-19-F7",vendor: "Juniper Networks"},
  {max_prefix: "9C-05-D6",vendor: "Ubiquiti Inc"},
  {max_prefix: "28-70-4E",vendor: "Ubiquiti Inc"},
  {max_prefix: "00-12-1E",vendor: "Juniper Networks"},
  {max_prefix: "00-10-DB",vendor: "Juniper Networks"},
  {max_prefix: "00-22-83",vendor: "Juniper Networks"},
  {max_prefix: "10-0E-7E",vendor: "Juniper Networks"},
  {max_prefix: "44-F4-77",vendor: "Juniper Networks"},
  {max_prefix: "2C-21-72",vendor: "Juniper Networks"},
  {max_prefix: "4C-96-14",vendor: "Juniper Networks"},
  {max_prefix: "5C-6A-EC",vendor: "IEEE Registration Authority"},
  {max_prefix: "CC-9F-7A",vendor: "Chiun Mai Communication System, Inc"},
  {max_prefix: "54-14-A7",vendor: "Nanjing Qinheng Microelectronics Co., Ltd."},
  {max_prefix: "4C-E7-05",vendor: "Siemens Industrial Automation Products Ltd., Chengdu"},
  {max_prefix: "0C-72-74",vendor: "AVM Audiovisuelles Marketing und Computersysteme GmbH"},
  {max_prefix: "80-DB-17",vendor: "Juniper Networks"},
  {max_prefix: "B8-F0-15",vendor: "Juniper Networks"},
  {max_prefix: "E4-23-3C",vendor: "Juniper Networks"},
  {max_prefix: "98-86-8B",vendor: "Juniper Networks"},
  {max_prefix: "00-58-28",vendor: "Axon Networks Inc."},
  {max_prefix: "FC-33-42",vendor: "Juniper Networks"},
  {max_prefix: "3C-8C-93",vendor: "Juniper Networks"},
  {max_prefix: "0C-81-26",vendor: "Juniper Networks"},
  {max_prefix: "18-2A-D3",vendor: "Juniper Networks"},
  {max_prefix: "94-BF-94",vendor: "Juniper Networks"},
  {max_prefix: "38-07-16",vendor: "FREEBOX SAS"},
  {max_prefix: "68-22-8E",vendor: "Juniper Networks"},
  {max_prefix: "D8-53-9A",vendor: "Juniper Networks"},
  {max_prefix: "F8-C1-16",vendor: "Juniper Networks"},
  {max_prefix: "88-0A-A3",vendor: "Juniper Networks"},
  {max_prefix: "EC-38-73",vendor: "Juniper Networks"},
  {max_prefix: "C0-03-80",vendor: "Juniper Networks"},
  {max_prefix: "20-D8-0B",vendor: "Juniper Networks"},
  {max_prefix: "4C-6D-58",vendor: "Juniper Networks"},
  {max_prefix: "40-8F-9D",vendor: "Juniper Networks"},
  {max_prefix: "AC-78-D1",vendor: "Juniper Networks"},
  {max_prefix: "A0-A3-B3",vendor: "Espressif Inc."},
  {max_prefix: "34-98-7A",vendor: "Espressif Inc."},
  {max_prefix: "68-59-32",vendor: "Sunitec Enterprise Co.,Ltd"},
  {max_prefix: "B4-B9-E6",vendor: "eero inc."},
  {max_prefix: "30-B2-16",vendor: "Hitachi Energy Germany AG"},
  {max_prefix: "74-95-A7",vendor: "Keyence Corporation"},
  {max_prefix: "88-62-5D",vendor: "BITNETWORKS CO.,LTD"},
  {max_prefix: "C8-36-A3",vendor: "GERTEC BRASIL LTDA"},
  {max_prefix: "F4-EE-31",vendor: "Cisco Systems, Inc"},
  {max_prefix: "14-1A-97",vendor: "Apple, Inc."},
  {max_prefix: "40-45-C4",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "74-87-2E",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "3C-1E-B5",vendor: "Apple, Inc."},
  {max_prefix: "AC-86-A3",vendor: "Apple, Inc."},
  {max_prefix: "00-1F-D6",vendor: "Shenzhen Allywll"},
  {max_prefix: "80-D2-66",vendor: "ScaleFlux"},
  {max_prefix: "E4-21-50",vendor: "Shanghai Chint low voltage electrical technology Co.,Ltd."},
  {max_prefix: "D4-04-E6",vendor: "Broadcom Limited"},
  {max_prefix: "28-EB-A6",vendor: "Nex-T LLC"},
  {max_prefix: "C0-B3-C8",vendor: "LLC NTC Rotek"},
  {max_prefix: "CC-4D-74",vendor: "Fujian Newland Payment Technology Co., Ltd."},
  {max_prefix: "10-BE-99",vendor: "Netberg"},
  {max_prefix: "84-D3-52",vendor: "Tonly Technology Co. Ltd"},
  {max_prefix: "F8-D7-58",vendor: "Veratron AG"},
  {max_prefix: "24-FE-9A",vendor: "CyberTAN Technology Inc."},
  {max_prefix: "B4-CB-B8",vendor: "Universal Electronics, Inc."},
  {max_prefix: "A0-31-EB",vendor: "Semikron Elektronik GmbH & Co. KG"},
  {max_prefix: "BC-32-B2",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "EC-8A-48",vendor: "Arista Networks"},
  {max_prefix: "8C-5D-B2",vendor: "IEEE Registration Authority"},
  {max_prefix: "88-CE-3F",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "EC-F8-D0",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "30-DF-17",vendor: "ALPSALPINE CO,.LTD"},
  {max_prefix: "D8-02-8A",vendor: "Shenzhen YOUHUA Technology Co., Ltd"},
  {max_prefix: "B8-3C-28",vendor: "Apple, Inc."},
  {max_prefix: "3C-6D-89",vendor: "Apple, Inc."},
  {max_prefix: "AC-45-00",vendor: "Apple, Inc."},
  {max_prefix: "E8-65-38",vendor: "CLOUD NETWORK TECHNOLOGY SINGAPORE PTE. LTD."},
  {max_prefix: "24-2A-04",vendor: "Cisco Systems, Inc"},
  {max_prefix: "14-1A-AA",vendor: "Metal Work SpA"},
  {max_prefix: "CC-DE-DE",vendor: "Nokia"},
  {max_prefix: "74-86-69",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "C8-0A-35",vendor: "Qingdao Hisense Smart Life Technology Co., Ltd"},
  {max_prefix: "24-95-2F",vendor: "Google, Inc."},
  {max_prefix: "04-70-56",vendor: "Arcadyan Corporation"},
  {max_prefix: "88-6D-2D",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "40-FF-40",vendor: "GloquadTech"},
  {max_prefix: "10-68-38",vendor: "AzureWave Technology Inc."},
  {max_prefix: "B8-94-D9",vendor: "Texas Instruments"},
  {max_prefix: "7C-E2-69",vendor: "Texas Instruments"},
  {max_prefix: "08-04-B4",vendor: "Texas Instruments"},
  {max_prefix: "30-AF-7E",vendor: "Texas Instruments"},
  {max_prefix: "84-B1-E4",vendor: "Apple, Inc."},
  {max_prefix: "54-EB-E9",vendor: "Apple, Inc."},
  {max_prefix: "AC-16-15",vendor: "Apple, Inc."},
  {max_prefix: "EC-73-79",vendor: "Apple, Inc."},
  {max_prefix: "FC-2A-46",vendor: "Realme Chongqing Mobile Telecommunications Corp.,Ltd."},
  {max_prefix: "9C-FA-3C",vendor: "Daeyoung Electronics"},
  {max_prefix: "80-61-6C",vendor: "New H3C Technologies Co., Ltd"},
  {max_prefix: "40-B6-07",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "0C-35-26",vendor: "Microsoft Corporation"},
  {max_prefix: "28-CF-51",vendor: "Nintendo Co.,Ltd"},
  {max_prefix: "FC-B0-DE",vendor: "CLOUD NETWORK TECHNOLOGY SINGAPORE PTE. LTD."},
  {max_prefix: "F4-4D-5C",vendor: "Zyxel Communications Corporation"},
  {max_prefix: "1C-C3-16",vendor: "Xiamen Milesight IoT Co., Ltd."},
  {max_prefix: "3C-6A-48",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "5C-B1-2E",vendor: "Cisco Systems, Inc"},
  {max_prefix: "04-4A-6A",vendor: "niliwi nanjing big data Co,.Ltd"},
  {max_prefix: "64-C6-D2",vendor: "Seiko Epson Corporation"},
  {max_prefix: "48-1F-66",vendor: "China Mobile Group Device Co.,Ltd."},
  {max_prefix: "78-24-59",vendor: "Alcatel-Lucent Enterprise"},
  {max_prefix: "A4-6C-24",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "78-07-1C",vendor: "Green Energy Options Ltd"},
  {max_prefix: "1C-8B-EF",vendor: "Beijing Xiaomi Electronics Co.,Ltd"},
  {max_prefix: "28-E2-97",vendor: "Shanghai InfoTM Microelectronics Co.,Ltd"},
  {max_prefix: "58-F8-5C",vendor: "LLC Proizvodstvennaya Kompania TransService"},
  {max_prefix: "BC-6B-FF",vendor: "Guangzhou Shiyuan Electronic Technology Company Limited"},
  {max_prefix: "30-44-49",vendor: "PLATH Signal Products GmbH & Co. KG"},
  {max_prefix: "EC-C3-B0",vendor: "zte corporation"},
  {max_prefix: "08-7B-12",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "B4-57-E6",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "BC-BD-84",vendor: "zte corporation"},
  {max_prefix: "50-D4-5C",vendor: "Amazon Technologies Inc."},
  {max_prefix: "00-30-1A",vendor: "SMARTBRIDGES PTE. LTD."},
  {max_prefix: "70-03-3F",vendor: "Pimax Technology(ShangHai)Co.,Ltd"},
  {max_prefix: "80-F1-A4",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "D0-CF-0E",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "08-08-5C",vendor: "Luna Products"},
  {max_prefix: "44-D5-06",vendor: "Sichuan Tianyi Comheart Telecom Co.,LTD"},
  {max_prefix: "2C-69-CC",vendor: "Valeo Detection Systems"},
  {max_prefix: "D4-61-37",vendor: "IEEE Registration Authority"},
  {max_prefix: "C4-35-D9",vendor: "Apple, Inc."},
  {max_prefix: "AC-C9-06",vendor: "Apple, Inc."},
  {max_prefix: "04-BC-6D",vendor: "Apple, Inc."},
  {max_prefix: "2C-8A-C7",vendor: "Ubee Interactive Co., Limited"},
  {max_prefix: "80-DE-CC",vendor: "HYBE Co.,LTD"},
  {max_prefix: "A0-ED-6D",vendor: "Ubee Interactive Co., Limited"},
  {max_prefix: "B0-8B-BE",vendor: "ABL GmbH"},
  {max_prefix: "C4-EB-42",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "F8-34-5A",vendor: "Hitron Technologies. Inc"},
  {max_prefix: "70-C9-32",vendor: "Dreame Technology (Suzhou) Limited"},
  {max_prefix: "74-4D-6D",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "30-19-84",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "F8-C2-49",vendor: "AMPERE COMPUTING LLC"},
  {max_prefix: "64-8C-BB",vendor: "Texas Instruments"},
  {max_prefix: "74-B8-39",vendor: "Texas Instruments"},
  {max_prefix: "C4-D3-6A",vendor: "Texas Instruments"},
  {max_prefix: "98-89-24",vendor: "Texas Instruments"},
  {max_prefix: "34-15-93",vendor: "Ruckus Wireless"},
  {max_prefix: "0C-B9-37",vendor: "Ubee Interactive Co., Limited"},
  {max_prefix: "44-0C-EE",vendor: "Robert Bosch Elektronikai Kft."},
  {max_prefix: "A0-17-F1",vendor: "Allwinner Technology Co., Ltd"},
  {max_prefix: "2C-6F-4E",vendor: "Hubei Yuan Times Technology Co.,Ltd."},
  {max_prefix: "90-F8-2E",vendor: "Amazon Technologies Inc."},
  {max_prefix: "48-8F-4C",vendor: "shenzhen trolink  Technology Co.,Ltd"},
  {max_prefix: "A8-F1-B2",vendor: "Allwinner Technology Co., Ltd"},
  {max_prefix: "FC-E9-D8",vendor: "Amazon Technologies Inc."},
  {max_prefix: "64-29-43",vendor: "D-Link Corporation"},
  {max_prefix: "58-5B-69",vendor: "TVT CO., LTD"},
  {max_prefix: "B8-B2-F7",vendor: "DRIMAES INC."},
  {max_prefix: "C4-98-94",vendor: "IEEE Registration Authority"},
  {max_prefix: "8C-FA-DD",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "F8-9A-25",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "08-3A-8D",vendor: "Espressif Inc."},
  {max_prefix: "84-23-88",vendor: "Ruckus Wireless"},
  {max_prefix: "38-65-04",vendor: "Honor Device Co., Ltd."},
  {max_prefix: "F8-71-0C",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "3C-13-5A",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "C0-84-E0",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "90-01-17",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "E0-B6-68",vendor: "zte corporation"},
  {max_prefix: "A4-9D-DD",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "6C-55-63",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "10-9F-4F",vendor: "New H3C Intelligence Terminal Co., Ltd."},
  {max_prefix: "38-9E-80",vendor: "zte corporation"},
  {max_prefix: "88-0F-A2",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "EC-96-BF",vendor: "eSystems MTG GmbH"},
  {max_prefix: "D4-20-00",vendor: "IEEE Registration Authority"},
  {max_prefix: "70-4D-E7",vendor: "TECNO MOBILE LIMITED"},
  {max_prefix: "74-D8-73",vendor: "GUANGDONG GENIUS TECHNOLOGY CO., LTD."},
  {max_prefix: "30-DE-4B",vendor: "TP-Link Corporation Limited"},
  {max_prefix: "1C-22-85",vendor: "Serrature Meroni SpA"},
  {max_prefix: "D4-92-B9",vendor: "ORION NOVA, S.L."},
  {max_prefix: "00-1B-B5",vendor: "Cherry GmbH"},
  {max_prefix: "DC-97-E6",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "68-B8-BB",vendor: "Beijing Xiaomi Electronics Co.,Ltd"},
  {max_prefix: "14-AC-60",vendor: "CLOUD NETWORK TECHNOLOGY SINGAPORE PTE. LTD."},
  {max_prefix: "80-FD-7B",vendor: "BLU Products Inc"},
  {max_prefix: "58-93-51",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "2C-A7-EF",vendor: "OnePlus Technology (Shenzhen) Co., Ltd"},
  {max_prefix: "50-FD-D5",vendor: "SJI Industry Company"},
  {max_prefix: "D0-F4-F7",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "D4-E9-8A",vendor: "Intel Corporate"},
  {max_prefix: "28-80-8A",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "34-66-79",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "44-8C-AB",vendor: "Beijing Flitlink Vientiane Technology Co., LTD"},
  {max_prefix: "68-26-24",vendor: "Ergatta"},
  {max_prefix: "A0-21-8B",vendor: "ACE Antenna Co., ltd"},
  {max_prefix: "20-84-F5",vendor: "Yufei Innovation Software(Shenzhen) Co., Ltd."},
  {max_prefix: "14-21-03",vendor: "Calix Inc."},
  {max_prefix: "40-B1-5C",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "C4-D4-96",vendor: "Shenzhen Excelsecu Data Technology Co.,Ltd"},
  {max_prefix: "2C-32-6A",vendor: "Apple, Inc."},
  {max_prefix: "6C-B1-33",vendor: "Apple, Inc."},
  {max_prefix: "28-E7-1D",vendor: "Arista Networks"},
  {max_prefix: "88-28-7D",vendor: "AltoBeam (China) Inc."},
  {max_prefix: "6C-97-AA",vendor: "AI TECHNOLOGY CO.,LTD."},
  {max_prefix: "6C-65-67",vendor: "BELIMO Automation AG"},
  {max_prefix: "4C-24-CE",vendor: "Sichuan AI-Link Technology Co., Ltd."},
  {max_prefix: "1C-97-FB",vendor: "CoolBitX Ltd."},
  {max_prefix: "44-5A-DF",vendor: "MIKAMI & CO., LTD."},
  {max_prefix: "08-6E-9C",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "D0-DA-D7",vendor: "Apple, Inc."},
  {max_prefix: "C4-AC-AA",vendor: "Apple, Inc."},
  {max_prefix: "44-16-FA",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "FC-67-1F",vendor: "Tuya Smart Inc."},
  {max_prefix: "78-C5-7D",vendor: "Zyxel Communications Corporation"},
  {max_prefix: "78-F1-C6",vendor: "Cisco Systems, Inc"},
  {max_prefix: "34-1B-2D",vendor: "Cisco Systems, Inc"},
  {max_prefix: "84-3C-4C",vendor: "Robert Bosch SRL"},
  {max_prefix: "6C-72-E2",vendor: "amitek"},
  {max_prefix: "38-A8-9B",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "64-37-A4",vendor: "TOKYOSHUHA CO.,LTD."},
  {max_prefix: "90-CA-FA",vendor: "Google, Inc."},
  {max_prefix: "10-E8-40",vendor: "ZOWEE TECHNOLOGY(HEYUAN) CO., LTD."},
  {max_prefix: "44-05-E8",vendor: "twareLAB"},
  {max_prefix: "8C-35-92",vendor: "Guangzhou Shiyuan Electronic Technology Company Limited"},
  {max_prefix: "A0-46-5A",vendor: "Motorola Mobility LLC, a Lenovo Company"},
  {max_prefix: "64-B5-F2",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "8C-06-CB",vendor: "Toradex AG"},
  {max_prefix: "30-3D-51",vendor: "IEEE Registration Authority"},
  {max_prefix: "70-B3-06",vendor: "Apple, Inc."},
  {max_prefix: "B8-49-6D",vendor: "Apple, Inc."},
  {max_prefix: "9C-92-4F",vendor: "Apple, Inc."},
  {max_prefix: "20-0E-2B",vendor: "Apple, Inc."},
  {max_prefix: "F0-D7-93",vendor: "Apple, Inc."},
  {max_prefix: "04-D9-C8",vendor: "Hon Hai Precision Industry Co., Ltd."},
  {max_prefix: "DC-AA-43",vendor: "Shenzhen Terca Information Technology Co., Ltd."},
  {max_prefix: "00-FB-F9",vendor: "Axiado Corporation"},
  {max_prefix: "DC-0B-09",vendor: "Cisco Systems, Inc"},
  {max_prefix: "08-F3-FB",vendor: "Cisco Systems, Inc"},
  {max_prefix: "A0-36-BC",vendor: "ASUSTek COMPUTER INC."},
  {max_prefix: "84-0B-BB",vendor: "MitraStar Technology Corp."},
  {max_prefix: "44-71-47",vendor: "Beijing Xiaomi Electronics Co.,Ltd"},
  {max_prefix: "F4-BB-C7",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "98-66-10",vendor: "zte corporation"},
  {max_prefix: "1C-24-CD",vendor: "ASKEY COMPUTER CORP"},
  {max_prefix: "A8-DC-5A",vendor: "Digital Watchdog"},
  {max_prefix: "A0-FB-83",vendor: "Honor Device Co., Ltd."},
  {max_prefix: "90-65-60",vendor: "EM Microelectronic"},
  {max_prefix: "28-BC-05",vendor: "BLU Products Inc"},
  {max_prefix: "1C-EF-03",vendor: "Guangzhou V-SOLUTION Electronic Technology Co., Ltd."},
  {max_prefix: "58-B0-3E",vendor: "Nintendo Co.,Ltd"},
  {max_prefix: "AC-4E-65",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "BC-5D-A3",vendor: "Sichuan Tianyi Comheart Telecom Co.,LTD"},
  {max_prefix: "E0-08-71",vendor: "Dongguan Liesheng Electronic Co., Ltd."},
  {max_prefix: "9C-95-6E",vendor: "Microchip Technology Inc."},
  {max_prefix: "10-73-EB",vendor: "Infiniti Electro-Optics"},
  {max_prefix: "2C-A7-74",vendor: "Texas Instruments"},
  {max_prefix: "18-7A-3E",vendor: "Silicon Laboratories"},
  {max_prefix: "30-05-05",vendor: "Intel Corporate"},
  {max_prefix: "B0-DC-EF",vendor: "Intel Corporate"},
  {max_prefix: "74-13-EA",vendor: "Intel Corporate"},
  {max_prefix: "A8-80-38",vendor: "ShenZhen MovingComm Technology Co., Limited"},
  {max_prefix: "DC-F3-1C",vendor: "Texas Instruments"},
  {max_prefix: "54-45-38",vendor: "Texas Instruments"},
  {max_prefix: "78-C2-13",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "40-22-D8",vendor: "Espressif Inc."},
  {max_prefix: "18-4E-03",vendor: "HMD Global Oy"},
  {max_prefix: "38-F0-C8",vendor: "Logitech"},
  {max_prefix: "34-FE-1C",vendor: "CHOUNG HWA TECH CO.,LTD"},
  {max_prefix: "60-CF-69",vendor: "meerecompany"},
  {max_prefix: "4C-62-7B",vendor: "SmartCow AI Technologies Taiwan Ltd."},
  {max_prefix: "BC-7B-72",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "38-FD-F5",vendor: "Renesas Electronics (Penang) Sdn. Bhd."},
  {max_prefix: "3C-26-E4",vendor: "Cisco Systems, Inc"},
  {max_prefix: "38-91-B7",vendor: "Cisco Systems, Inc"},
  {max_prefix: "34-5D-A8",vendor: "Cisco Systems, Inc"},
  {max_prefix: "38-12-7B",vendor: "Crenet Labs Co., Ltd."},
  {max_prefix: "B0-E4-5C",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "D8-68-A0",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "04-29-2E",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "F8-2B-7F",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "40-C3-BC",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "28-53-E0",vendor: "Sintela Ltd"},
  {max_prefix: "C4-12-EC",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "DC-36-0C",vendor: "Hitron Technologies. Inc"},
  {max_prefix: "68-7F-F0",vendor: "TP-Link Corporation Limited"},
  {max_prefix: "BC-4C-A0",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "74-34-2B",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "78-91-DE",vendor: "Guangdong ACIGA Science&Technology Co.,Ltd"},
  {max_prefix: "88-F2-BD",vendor: "GD Midea Air-Conditioning Equipment Co.,Ltd."},
  {max_prefix: "6C-08-31",vendor: "ANALOG SYSTEMS"},
  {max_prefix: "A4-7E-FA",vendor: "Withings"},
  {max_prefix: "E0-80-6B",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "70-50-E7",vendor: "IEEE Registration Authority"},
  {max_prefix: "70-AC-08",vendor: "Silicon Laboratories"},
  {max_prefix: "20-0B-CF",vendor: "Nintendo Co.,Ltd"},
  {max_prefix: "70-70-FC",vendor: "GOLD&WATER INDUSTRIAL LIMITED"},
  {max_prefix: "98-D9-3D",vendor: "Demant Enterprise A/S"},
  {max_prefix: "B4-A6-78",vendor: "Zhejiang Tmall Technology Co., Ltd."},
  {max_prefix: "AC-C4-BD",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "E4-B6-33",vendor: "Wuxi Stars Microsystem Technology Co., Ltd"},
  {max_prefix: "08-51-04",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "78-5B-64",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "54-E1-5B",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "8C-98-06",vendor: "SHENZHEN SEI ROBOTICS CO.,LTD"},
  {max_prefix: "20-08-89",vendor: "zte corporation"},
  {max_prefix: "AC-A3-2F",vendor: "Solidigm Technology"},
  {max_prefix: "4C-9E-6C",vendor: "BROADEX TECHNOLOGIES CO.LTD"},
  {max_prefix: "20-0B-16",vendor: "Texas Instruments"},
  {max_prefix: "88-01-F9",vendor: "Texas Instruments"},
  {max_prefix: "F8-55-48",vendor: "Texas Instruments"},
  {max_prefix: "68-E7-4A",vendor: "Texas Instruments"},
  {max_prefix: "70-A6-BD",vendor: "Honor Device Co., Ltd."},
  {max_prefix: "24-26-D6",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "EC-81-9C",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "D0-98-9C",vendor: "ConMet"},
  {max_prefix: "84-69-93",vendor: "HP Inc."},
  {max_prefix: "C4-A1-0E",vendor: "IEEE Registration Authority"},
  {max_prefix: "54-2F-04",vendor: "Shanghai Longcheer Technology Co., Ltd."},
  {max_prefix: "74-6F-88",vendor: "zte corporation"},
  {max_prefix: "1C-A4-10",vendor: "Amlogic, Inc."},
  {max_prefix: "B4-19-74",vendor: "Apple, Inc."},
  {max_prefix: "60-95-BD",vendor: "Apple, Inc."},
  {max_prefix: "EC-11-27",vendor: "Texas Instruments"},
  {max_prefix: "04-E8-92",vendor: "SHENNAN CIRCUITS CO.,LTD"},
  {max_prefix: "98-C8-1C",vendor: "BAYTEC LIMITED"},
  {max_prefix: "74-84-69",vendor: "Nintendo Co.,Ltd"},
  {max_prefix: "8C-CB-DF",vendor: "FOXCONN INTERCONNECT TECHNOLOGY"},
  {max_prefix: "BC-E9-E2",vendor: "Brocade Communications Systems LLC"},
  {max_prefix: "18-A5-9C",vendor: "IEEE Registration Authority"},
  {max_prefix: "10-96-1A",vendor: "CHIPSEA TECHNOLOGIES (SHENZHEN) CORP."},
  {max_prefix: "74-71-8B",vendor: "Apple, Inc."},
  {max_prefix: "70-31-7F",vendor: "Apple, Inc."},
  {max_prefix: "A4-CF-99",vendor: "Apple, Inc."},
  {max_prefix: "4C-2E-B4",vendor: "Apple, Inc."},
  {max_prefix: "34-AD-61",vendor: "CELESTICA INC."},
  {max_prefix: "2C-55-3C",vendor: "Vecima Networks Inc."},
  {max_prefix: "54-43-B2",vendor: "Espressif Inc."},
  {max_prefix: "04-B9-7D",vendor: "AiVIS Co., Itd."},
  {max_prefix: "C4-C0-63",vendor: "New H3C Technologies Co., Ltd"},
  {max_prefix: "00-25-CA",vendor: "Laird Connectivity"},
  {max_prefix: "E0-6C-C5",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "30-96-3B",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "8C-6B-DB",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "10-DA-49",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "60-18-3A",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "18-C0-07",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "AC-BF-71",vendor: "Bose Corporation"},
  {max_prefix: "B4-83-51",vendor: "Intel Corporate"},
  {max_prefix: "BC-F4-D4",vendor: "CLOUD NETWORK TECHNOLOGY SINGAPORE PTE. LTD."},
  {max_prefix: "74-56-3C",vendor: "GIGA-BYTE TECHNOLOGY CO.,LTD."},
  {max_prefix: "EC-55-1C",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "E0-79-8D",vendor: "Silicon Laboratories"},
  {max_prefix: "D8-9C-8E",vendor: "Comcast Cable Corporation"},
  {max_prefix: "AC-D3-1D",vendor: "Cisco Meraki"},
  {max_prefix: "B0-4A-6A",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "A8-79-8D",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "5C-ED-F4",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "64-C2-69",vendor: "eero inc."},
  {max_prefix: "BC-6E-6D",vendor: "EM Microelectronic"},
  {max_prefix: "CC-F3-05",vendor: "SHENZHEN TIAN XING CHUANG ZHAN ELECTRONIC CO.,LTD"},
  {max_prefix: "34-BD-20",vendor: "Hangzhou Hikrobot Technology Co., Ltd."},
  {max_prefix: "AC-2A-A1",vendor: "Cisco Systems, Inc"},
  {max_prefix: "F8-E9-4F",vendor: "Cisco Systems, Inc"},
  {max_prefix: "30-89-4A",vendor: "Intel Corporate"},
  {max_prefix: "98-59-7A",vendor: "Intel Corporate"},
  {max_prefix: "64-49-7D",vendor: "Intel Corporate"},
  {max_prefix: "B8-D6-1A",vendor: "Espressif Inc."},
  {max_prefix: "28-3D-C2",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "00-D4-9E",vendor: "Intel Corporate"},
  {max_prefix: "DC-9A-7D",vendor: "HISENSE VISUAL TECHNOLOGY CO.,LTD"},
  {max_prefix: "84-F1-D0",vendor: "EHOOME IOT PRIVATE LIMITED"},
  {max_prefix: "30-CB-36",vendor: "Belden Singapore Pte. Ltd."},
  {max_prefix: "30-BB-7D",vendor: "OnePlus Technology (Shenzhen) Co., Ltd"},
  {max_prefix: "6C-67-EF",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "88-69-3D",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "00-99-1D",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "20-8B-D1",vendor: "NXP Semiconductor (Tianjin) LTD."},
  {max_prefix: "2C-07-F6",vendor: "SKG Health Technologies Co., Ltd."},
  {max_prefix: "38-68-BE",vendor: "Sichuan Tianyi Comheart Telecom Co.,LTD"},
  {max_prefix: "34-92-C2",vendor: "Square Route Co., Ltd."},
  {max_prefix: "6C-A4-01",vendor: "essensys plc"},
  {max_prefix: "8C-17-59",vendor: "Intel Corporate"},
  {max_prefix: "B8-3F-D2",vendor: "Mellanox Technologies, Inc."},
  {max_prefix: "28-3E-0C",vendor: "Preferred Robotics, Inc."},
  {max_prefix: "04-BC-9F",vendor: "Calix Inc."},
  {max_prefix: "28-A5-3F",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "8C-49-B6",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "48-51-D0",vendor: "Jiangsu Xinsheng Intelligent Technology Co., Ltd."},
  {max_prefix: "80-77-A4",vendor: "TECNO MOBILE LIMITED"},
  {max_prefix: "00-C3-0A",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "88-52-EB",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "B4-28-75",vendor: "Futecho Solutions Private Limited"},
  {max_prefix: "30-23-64",vendor: "Nokia Shanghai Bell Co., Ltd."},
  {max_prefix: "0C-1C-1A",vendor: "eero inc."},
  {max_prefix: "D4-FB-8E",vendor: "Apple, Inc."},
  {max_prefix: "B0-DE-28",vendor: "Apple, Inc."},
  {max_prefix: "7C-13-1D",vendor: "SERNET (SUZHOU) TECHNOLOGIES CORPORATION"},
  {max_prefix: "D4-9F-DD",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "00-D8-A2",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "D8-50-A1",vendor: "Hunan Danuo Technology Co.,LTD"},
  {max_prefix: "44-1A-AC",vendor: "Elektrik Uretim AS EOS"},
  {max_prefix: "7C-6C-F0",vendor: "Shenzhen TINNO Mobile Technology Corp."},
  {max_prefix: "34-28-40",vendor: "Apple, Inc."},
  {max_prefix: "18-E7-B0",vendor: "Apple, Inc."},
  {max_prefix: "50-57-8A",vendor: "Apple, Inc."},
  {max_prefix: "D8-33-B7",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "2C-78-4C",vendor: "Iton Technology Corp."},
  {max_prefix: "48-87-59",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "AC-1E-9E",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "E0-AE-A2",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "44-45-6F",vendor: "SHENZHEN ONEGA TECHNOLOGY CO.,LTD"},
  {max_prefix: "E8-FD-F8",vendor: "Shanghai High-Flying Electronics  Technology Co., Ltd"},
  {max_prefix: "DC-6B-1B",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "98-CA-20",vendor: "Shanghai SIMCOM Ltd."},
  {max_prefix: "D0-6D-C9",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "DC-15-2D",vendor: "China Mobile Group Device Co.,Ltd."},
  {max_prefix: "08-57-FB",vendor: "Amazon Technologies Inc."},
  {max_prefix: "00-D0-FC",vendor: "GRANITE MICROSYSTEMS"},
  {max_prefix: "34-97-6F",vendor: "Rootech, Inc."},
  {max_prefix: "C0-18-03",vendor: "HP Inc."},
  {max_prefix: "04-CF-4B",vendor: "Intel Corporate"},
  {max_prefix: "F8-97-A9",vendor: "Ericsson AB"},
  {max_prefix: "6C-6C-0F",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "EC-9B-2D",vendor: "China Mobile Group Device Co.,Ltd."},
  {max_prefix: "FC-38-C4",vendor: "China Grand Communications Co.,Ltd."},
  {max_prefix: "5C-F5-1A",vendor: "Zhejiang Dahua Technology Co., Ltd."},
  {max_prefix: "10-1D-51",vendor: "8Mesh Networks Limited"},
  {max_prefix: "4C-FE-2E",vendor: "DongGuan Siyoto Electronics Co., Ltd"},
  {max_prefix: "A8-13-06",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "1C-34-F1",vendor: "Silicon Laboratories"},
  {max_prefix: "AC-93-C4",vendor: "GD Midea Air-Conditioning Equipment Co.,Ltd."},
  {max_prefix: "00-06-88",vendor: "Telways Communication Co., Ltd."},
  {max_prefix: "D0-1B-1F",vendor: "OHSUNG"},
  {max_prefix: "CC-7D-5B",vendor: "Telink Semiconductor (Shanghai) Co., Ltd."},
  {max_prefix: "74-95-52",vendor: "Xuzhou WIKA Electronics Control Technology Co., Ltd."},
  {max_prefix: "0C-4E-C0",vendor: "Maxlinear Inc"},
  {max_prefix: "CC-8C-BF",vendor: "Tuya Smart Inc."},
  {max_prefix: "90-11-95",vendor: "Amazon Technologies Inc."},
  {max_prefix: "44-EA-30",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "84-5F-04",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "9C-2F-9D",vendor: "Liteon Technology Corporation"},
  {max_prefix: "A4-F3-3B",vendor: "zte corporation"},
  {max_prefix: "30-E7-BC",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "7C-B5-66",vendor: "Intel Corporate"},
  {max_prefix: "50-E9-DF",vendor: "Quectel Wireless Solutions Co.,Ltd."},
  {max_prefix: "6C-4B-B4",vendor: "HUMAX Co., Ltd."},
  {max_prefix: "58-9B-F7",vendor: "Hefei Radio Communication Technology Co., Ltd"},
  {max_prefix: "50-A0-30",vendor: "IEEE Registration Authority"},
  {max_prefix: "60-10-9E",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "40-32-9D",vendor: "Union Image Co.,Ltd"},
  {max_prefix: "E8-FA-23",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "EC-3A-52",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "44-1B-88",vendor: "Apple, Inc."},
  {max_prefix: "80-04-5F",vendor: "Apple, Inc."},
  {max_prefix: "9C-3E-53",vendor: "Apple, Inc."},
  {max_prefix: "C8-89-F3",vendor: "Apple, Inc."},
  {max_prefix: "10-B9-C4",vendor: "Apple, Inc."},
  {max_prefix: "00-0E-24",vendor: "Huwell Technology Inc."},
  {max_prefix: "4C-77-13",vendor: "Renesas Electronics (Penang) Sdn. Bhd."},
  {max_prefix: "D8-AA-59",vendor: "Tonly Technology Co. Ltd"},
  {max_prefix: "64-CF-13",vendor: "Weigao Nikkiso(Weihai)Dialysis Equipment Co.,Ltd"},
  {max_prefix: "9C-74-03",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "3C-EC-DE",vendor: "FUJIAN STAR-NET COMMUNICATION CO.,LTD"},
  {max_prefix: "9C-6B-00",vendor: "ASRock Incorporation"},
  {max_prefix: "54-F8-2A",vendor: "u-blox AG"},
  {max_prefix: "D4-35-38",vendor: "Beijing Xiaomi Mobile Software Co., Ltd"},
  {max_prefix: "10-CE-02",vendor: "Amazon Technologies Inc."},
  {max_prefix: "04-0D-84",vendor: "Silicon Laboratories"},
  {max_prefix: "4C-19-5D",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "00-13-DC",vendor: "IBTEK INC."},
  {max_prefix: "D8-4A-2B",vendor: "zte corporation"},
  {max_prefix: "D0-F9-9B",vendor: "zte corporation"},
  {max_prefix: "F4-13-99",vendor: "Aerospace new generation communications Co.,Ltd"},
  {max_prefix: "60-26-AA",vendor: "Cisco Systems, Inc"},
  {max_prefix: "5C-31-92",vendor: "Cisco Systems, Inc"},
  {max_prefix: "64-1A-BA",vendor: "Dryad Networks GmbH"},
  {max_prefix: "34-94-54",vendor: "Espressif Inc."},
  {max_prefix: "A8-3A-48",vendor: "Ubiqcom India Pvt Ltd"},
  {max_prefix: "C8-5A-CF",vendor: "HP Inc."},
  {max_prefix: "FC-29-E3",vendor: "Infinix mobility limited"},
  {max_prefix: "9C-1C-6D",vendor: "HEFEI DATANG STORAGE TECHNOLOGY CO.,LTD"},
  {max_prefix: "88-C2-27",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "80-54-D9",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "9C-85-66",vendor: "Wingtech Mobile Communications Co.,Ltd."},
  {max_prefix: "70-70-AA",vendor: "Amazon Technologies Inc."},
  {max_prefix: "D4-13-F8",vendor: "Peplink International Ltd."},
  {max_prefix: "98-67-2E",vendor: "Skullcandy"},
  {max_prefix: "48-02-86",vendor: "Realme Chongqing Mobile Telecommunications Corp.,Ltd."},
  {max_prefix: "94-DE-B8",vendor: "Silicon Laboratories"},
  {max_prefix: "64-2F-C7",vendor: "New H3C Technologies Co., Ltd"},
  {max_prefix: "94-A9-A8",vendor: "Texas Instruments"},
  {max_prefix: "48-B4-23",vendor: "Amazon Technologies Inc."},
  {max_prefix: "9C-00-D3",vendor: "SHENZHEN IK WORLD Technology Co., Ltd"},
  {max_prefix: "28-2D-06",vendor: "AMPAK Technology,Inc."},
  {max_prefix: "20-2B-20",vendor: "CLOUD NETWORK TECHNOLOGY SINGAPORE PTE. LTD."},
  {max_prefix: "E8-CA-C8",vendor: "Hui Zhou Gaoshengda Technology Co.,LTD"},
  {max_prefix: "D4-19-F6",vendor: "NXP Semiconductor (Tianjin) LTD."},
  {max_prefix: "64-BF-6B",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "30-49-9E",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "9C-EC-61",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "74-70-69",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "14-FB-70",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "84-D3-D5",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "00-11-17",vendor: "CESNET"},
  {max_prefix: "B0-33-66",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "7C-C2-25",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "FC-76-92",vendor: "Semptian Co.,Ltd."},
  {max_prefix: "FC-AF-BE",vendor: "TireCheck GmbH"},
  {max_prefix: "88-6E-E1",vendor: "Erbe Elektromedizin GmbH"},
  {max_prefix: "C4-75-AB",vendor: "Intel Corporate"},
  {max_prefix: "80-B7-45",vendor: "The Silk Technologies ILC LTD"},
  {max_prefix: "4C-AB-F8",vendor: "ASKEY COMPUTER CORP"},
  {max_prefix: "C8-41-8A",vendor: "Samsung Electronics.,LTD"},
  {max_prefix: "DC-97-3A",vendor: "Verana Networks"},
  {max_prefix: "20-66-FD",vendor: "CONSTELL8 NV"},
  {max_prefix: "0C-B0-88",vendor: "AITelecom"},
  {max_prefix: "68-67-25",vendor: "Espressif Inc."},
  {max_prefix: "20-20-27",vendor: "Shenzhen Sundray Technologies Company Limited"},
  {max_prefix: "A0-A3-09",vendor: "Apple, Inc."},
  {max_prefix: "5C-50-D9",vendor: "Apple, Inc."},
  {max_prefix: "88-4D-7C",vendor: "Apple, Inc."},
  {max_prefix: "A8-FE-9D",vendor: "Apple, Inc."},
  {max_prefix: "18-48-BE",vendor: "Amazon Technologies Inc."},
  {max_prefix: "F8-9E-94",vendor: "Intel Corporate"},
  {max_prefix: "64-42-12",vendor: "Shenzhen Water World Information Co.,Ltd."},
  {max_prefix: "C4-03-A8",vendor: "Intel Corporate"},
  {max_prefix: "D4-63-DE",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "0C-90-43",vendor: "Realme Chongqing Mobile Telecommunications Corp.,Ltd."},
  {max_prefix: "B0-F7-C4",vendor: "Amazon Technologies Inc."},
  {max_prefix: "8C-56-46",vendor: "LG Electronics"},
  {max_prefix: "CC-71-90",vendor: "VIETNAM POST AND TELECOMMUNICATION INDUSTRY TECHNOLOGY JOINT STOCK COMPANY"},
  {max_prefix: "14-00-E9",vendor: "Mitel Networks Corporation"},
  {max_prefix: "54-1F-8D",vendor: "zte corporation"},
  {max_prefix: "2C-F1-BB",vendor: "zte corporation"},
  {max_prefix: "DC-2C-6E",vendor: "Routerboard.com"},
  {max_prefix: "18-A9-A6",vendor: "Nebra Ltd"},
  {max_prefix: "54-7D-40",vendor: "Powervision Tech Inc."},
  {max_prefix: "C0-D7-AA",vendor: "Arcadyan Corporation"},
  {max_prefix: "6C-0F-0B",vendor: "China Mobile Group Device Co.,Ltd."},
  {max_prefix: "D4-B7-D0",vendor: "Ciena Corporation"},
  {max_prefix: "AC-AC-E2",vendor: "CHANGHONG (HONGKONG) TRADING LIMITED"},
  {max_prefix: "4C-8D-53",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "BC-2C-E6",vendor: "Cisco Systems, Inc"},
  {max_prefix: "CC-ED-4D",vendor: "Cisco Systems, Inc"},
  {max_prefix: "48-BD-4A",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "A8-D4-E0",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "A0-40-6F",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "34-29-EF",vendor: "Qingdao Haier Technology Co.,Ltd"},
  {max_prefix: "A0-B5-3C",vendor: "Technicolor Delivery Technologies Belgium NV"},
  {max_prefix: "C4-BD-E5",vendor: "Intel Corporate"},
  {max_prefix: "6C-B0-FD",vendor: "Shenzhen Xinghai Iot Technology Co.,Ltd"},
  {max_prefix: "FC-5F-49",vendor: "Zhejiang Dahua Technology Co., Ltd."},
  {max_prefix: "30-35-C5",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "00-4F-1A",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "74-33-A6",vendor: "Shenzhen SuperElectron Technology Co.,Ltd."},
  {max_prefix: "2C-0D-A7",vendor: "Intel Corporate"},
  {max_prefix: "64-9E-31",vendor: "Beijing Xiaomi Mobile Software Co., Ltd"},
  {max_prefix: "24-CD-8D",vendor: "Murata Manufacturing Co., Ltd."},
  {max_prefix: "54-1D-61",vendor: "YEESTOR Microelectronics Co., Ltd"},
  {max_prefix: "70-09-71",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "D0-1B-49",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "F8-20-A9",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "A0-FF-22",vendor: "SHENZHEN APICAL TECHNOLOGY CO., LTD"},
  {max_prefix: "5C-02-14",vendor: "Beijing Xiaomi Mobile Software Co., Ltd"},
  {max_prefix: "E4-93-6A",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "48-77-BD",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "F8-56-C3",vendor: "zte corporation"},
  {max_prefix: "00-B0-EC",vendor: "EACEM"},
  {max_prefix: "6C-D3-EE",vendor: "ZIMI CORPORATION"},
  {max_prefix: "04-CD-15",vendor: "Silicon Laboratories"},
  {max_prefix: "E4-28-05",vendor: "Pivotal Optics"},
  {max_prefix: "38-1F-8D",vendor: "Tuya Smart Inc."},
  {max_prefix: "20-8C-47",vendor: "Tenstorrent Inc"},
  {max_prefix: "34-53-D2",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "18-EF-3A",vendor: "Sichuan AI-Link Technology Co., Ltd."},
  {max_prefix: "78-5E-A2",vendor: "Sunitec Enterprise Co.,Ltd"},
  {max_prefix: "DC-F4-CA",vendor: "Apple, Inc."},
  {max_prefix: "7C-FC-16",vendor: "Apple, Inc."},
  {max_prefix: "88-B9-45",vendor: "Apple, Inc."},
  {max_prefix: "B0-BE-83",vendor: "Apple, Inc."},
  {max_prefix: "10-32-1D",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "38-FD-F8",vendor: "Cisco Systems, Inc"},
  {max_prefix: "3C-9F-C3",vendor: "Beijing Sinead Technology Co., Ltd."},
  {max_prefix: "60-8F-A4",vendor: "Nokia Solutions and Networks GmbH & Co. KG"},
  {max_prefix: "78-76-D9",vendor: "EXARA Group"},
  {max_prefix: "94-A4-08",vendor: "Shenzhen Trolink Technology CO, LTD"},
  {max_prefix: "D0-F8-65",vendor: "ITEL MOBILE LIMITED"},
  {max_prefix: "7C-84-37",vendor: "China Post Communications Equipment Co., Ltd."},
  {max_prefix: "B8-4D-43",vendor: "HUNAN FN-LINK TECHNOLOGY LIMITED"},
  {max_prefix: "A8-4A-63",vendor: "TPV Display Technology(Xiamen) Co.,Ltd."},
  {max_prefix: "24-3F-AA",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "D8-67-D3",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "48-47-4B",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "80-6F-1C",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "68-15-D3",vendor: "Zaklady Elektroniki i Mechaniki Precyzyjnej R&G S.A."},
  {max_prefix: "00-80-E7",vendor: "Leonardo UK Ltd"},
  {max_prefix: "7C-C1-77",vendor: "INGRAM MICRO SERVICES"},
  {max_prefix: "E0-A2-58",vendor: "Wanbang Digital Energy Co.,Ltd"},
  {max_prefix: "4C-BC-E9",vendor: "LG Innotek"},
  {max_prefix: "38-5C-76",vendor: "PLANTRONICS, INC."},
  {max_prefix: "C8-9F-1A",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "D8-68-52",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "AC-64-90",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "9C-50-D1",vendor: "Murata Manufacturing Co., Ltd."},
  {max_prefix: "14-00-20",vendor: " LongSung Technology (Shanghai) Co.,Ltd."},
  {max_prefix: "18-E1-DE",vendor: "Chengdu ChipIntelli Technology Co., Ltd"},
  {max_prefix: "8C-F6-81",vendor: "Silicon Laboratories"},
  {max_prefix: "7C-66-EF",vendor: "Hon Hai Precision IND.CO.,LTD"},
  {max_prefix: "44-17-93",vendor: "Espressif Inc."},
  {max_prefix: "74-5C-FA",vendor: "Shenzhen Shunrui Gaojie Technology Co., Ltd."},
  {max_prefix: "5C-DF-B8",vendor: "Shenzhen Unionmemory Information System Limited"},
  {max_prefix: "E0-0A-F6",vendor: "Liteon Technology Corporation"},
  {max_prefix: "2C-1A-05",vendor: "Cisco Systems, Inc"},
  {max_prefix: "B4-0E-CF",vendor: "Bouffalo Lab (Nanjing) Co., Ltd."},
  {max_prefix: "00-A3-88",vendor: "SKY UK LIMITED"},
  {max_prefix: "50-3D-EB",vendor: "Zhejiang Tmall Technology Co., Ltd."},
  {max_prefix: "F8-7F-A5",vendor: "GREATEK"},
  {max_prefix: "F8-38-69",vendor: "LG Electronics"},
  {max_prefix: "F0-5E-CD",vendor: "Texas Instruments"},
  {max_prefix: "2C-05-47",vendor: "Shenzhen Phaten Tech. LTD"},
  {max_prefix: "E0-C5-8F",vendor: "China Mobile IOT Company Limited"},
  {max_prefix: "30-B9-30",vendor: "zte corporation"},
  {max_prefix: "94-98-69",vendor: "zte corporation"},
  {max_prefix: "78-50-05",vendor: "MOKO TECHNOLOGY Ltd"},
  {max_prefix: "FC-2E-19",vendor: "China Mobile Group Device Co.,Ltd."},
  {max_prefix: "50-F9-08",vendor: "Wizardlab Co., Ltd."},
  {max_prefix: "AC-76-4C",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "5C-58-E6",vendor: "Palo Alto Networks"},
  {max_prefix: "EC-94-CB",vendor: "Espressif Inc."},
  {max_prefix: "EC-97-E0",vendor: "Hangzhou Ezviz Software Co.,Ltd."},
  {max_prefix: "D0-B6-6F",vendor: "SERNET (SUZHOU) TECHNOLOGIES CORPORATION"},
  {max_prefix: "F0-B6-1E",vendor: "Intel Corporate"},
  {max_prefix: "C8-4D-44",vendor: "Shenzhen Jiapeng Huaxiang Technology Co.,Ltd"},
  {max_prefix: "2C-3A-91",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "64-F7-05",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "60-CE-41",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "28-17-09",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "60-6E-E8",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "0C-E1-59",vendor: "Shenzhen iStartek Technology Co., Ltd."},
  {max_prefix: "50-C2-E8",vendor: "CLOUD NETWORK TECHNOLOGY SINGAPORE PTE. LTD."},
  {max_prefix: "30-09-C0",vendor: "Motorola Mobility LLC, a Lenovo Company"},
  {max_prefix: "B0-46-92",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "70-86-CE",vendor: "GD Midea Air-Conditioning Equipment Co.,Ltd."},
  {max_prefix: "50-0F-59",vendor: "STMicrolectronics International NV"},
  {max_prefix: "E4-2A-AC",vendor: "Microsoft Corporation"},
  {max_prefix: "C0-F8-27",vendor: "Rapidmax Technology Corporation"},
  {max_prefix: "00-14-13",vendor: "Trebing & Himstedt Proze\u00dfautomation GmbH & Co. KG"},
  {max_prefix: "00-07-99",vendor: "Tipping Point Technologies, Inc."},
  {max_prefix: "DC-8D-8A",vendor: "Nokia Solutions and Networks GmbH & Co. KG"},
  {max_prefix: "98-B1-77",vendor: "LANDIS + GYR"},
  {max_prefix: "A0-29-BD",vendor: "Team Group Inc"},
  {max_prefix: "64-DC-DE",vendor: "ZheJiang FuChunJiang Information Technology Co.,Ltd"},
  {max_prefix: "00-91-9E",vendor: "Intel Corporate"},
  {max_prefix: "24-1D-48",vendor: "Sichuan Tianyi Comheart Telecom Co.,LTD"},
  {max_prefix: "28-77-77",vendor: "zte corporation"},
  {max_prefix: "60-06-E3",vendor: "Apple, Inc."},
  {max_prefix: "D4-3D-F3",vendor: "Zyxel Communications Corporation"},
  {max_prefix: "20-57-9E",vendor: "HUNAN FN-LINK TECHNOLOGY LIMITED"},
  {max_prefix: "C8-84-CF",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "20-E7-B6",vendor: "Universal Electronics, Inc."},
  {max_prefix: "80-8A-BD",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "3C-CD-57",vendor: "Beijing Xiaomi Mobile Software Co., Ltd"},
  {max_prefix: "24-5E-48",vendor: "Apple, Inc."},
  {max_prefix: "08-C7-29",vendor: "Apple, Inc."},
  {max_prefix: "C4-C3-6B",vendor: "Apple, Inc."},
  {max_prefix: "E8-A7-30",vendor: "Apple, Inc."},
  {max_prefix: "E0-23-D7",vendor: "Sleep Number"},
  {max_prefix: "80-6A-10",vendor: "Whisker Labs - Ting"},
  {max_prefix: "60-E3-2B",vendor: "Intel Corporate"},
  {max_prefix: "D8-BE-65",vendor: "Amazon Technologies Inc."},
  {max_prefix: "10-6F-D9",vendor: "CLOUD NETWORK TECHNOLOGY SINGAPORE PTE. LTD."},
  {max_prefix: "58-4D-42",vendor: "Dragos, Inc."},
  {max_prefix: "B8-DA-E8",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "B8-AE-1D",vendor: "Guangzhou Xingyi Electronic Technology Co.,Ltd"},
  {max_prefix: "28-DE-A8",vendor: "zte corporation"},
  {max_prefix: "AC-EC-85",vendor: "eero inc."},
  {max_prefix: "E0-67-81",vendor: "Dongguan Liesheng Electronic Co., Ltd."},
  {max_prefix: "60-A5-E2",vendor: "Intel Corporate"},
  {max_prefix: "D4-54-8B",vendor: "Intel Corporate"},
  {max_prefix: "10-09-F9",vendor: "Amazon Technologies Inc."},
  {max_prefix: "90-69-76",vendor: "Withrobot Inc."},
  {max_prefix: "FC-92-57",vendor: "Renesas Electronics (Penang) Sdn. Bhd."},
  {max_prefix: "D0-3E-7D",vendor: "CHIPSEA TECHNOLOGIES (SHENZHEN) CORP."},
  {max_prefix: "60-B6-E1",vendor: "Texas Instruments"},
  {max_prefix: "6C-79-B8",vendor: "Texas Instruments"},
  {max_prefix: "8C-A3-99",vendor: "SERVERCOM (INDIA) PRIVATE LIMITED"},
  {max_prefix: "60-DB-15",vendor: "New H3C Technologies Co., Ltd"},
  {max_prefix: "5C-A7-21",vendor: "New H3C Technologies Co., Ltd"},
  {max_prefix: "98-F2-17",vendor: "Castlenet Technology Inc."},
  {max_prefix: "44-67-52",vendor: "Wistron INFOCOMM (Zhongshan) CORPORATION"},
  {max_prefix: "A8-23-16",vendor: "Nokia"},
  {max_prefix: "38-E3-9F",vendor: "Motorola Mobility LLC, a Lenovo Company"},
  {max_prefix: "D0-1E-1D",vendor: "SaiNXT Technologies LLP"},
  {max_prefix: "78-BB-88",vendor: "Maxio Technology (Hangzhou) Ltd."},
  {max_prefix: "60-8A-10",vendor: "Microchip Technology Inc."},
  {max_prefix: "A8-64-F1",vendor: "Intel Corporate"},
  {max_prefix: "A4-2A-71",vendor: "Sichuan Tianyi Comheart Telecom Co.,LTD"},
  {max_prefix: "84-EB-EF",vendor: "Cisco Systems, Inc"},
  {max_prefix: "90-80-60",vendor: "Nilfisk A/S"},
  {max_prefix: "70-A6-CC",vendor: "Intel Corporate"},
  {max_prefix: "D4-8D-D9",vendor: "Meld Technology, Inc"},
  {max_prefix: "58-2F-F7",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "AC-E7-7B",vendor: "Sichuan Tianyi Comheart Telecom Co.,LTD"},
  {max_prefix: "6C-43-3C",vendor: "TECNO MOBILE LIMITED"},
  {max_prefix: "48-F8-FF",vendor: "CHENGDU KT ELECTRONIC HI-TECH CO.,LTD"},
  {max_prefix: "E8-C1-E8",vendor: "Shenzhen Xiao Bi En Culture Education Technology Co.,Ltd."},
  {max_prefix: "7C-70-DB",vendor: "Intel Corporate"},
  {max_prefix: "8C-94-CC",vendor: "SFR"},
  {max_prefix: "2C-DD-E9",vendor: "Arista Networks"},
  {max_prefix: "70-97-41",vendor: "Arcadyan Corporation"},
  {max_prefix: "E4-08-E7",vendor: "Quectel Wireless Solutions Co.,Ltd."},
  {max_prefix: "8C-FD-15",vendor: "Imagine Marketing Private Limited"},
  {max_prefix: "C4-FF-22",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "A0-A0-DC",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "C4-80-8A",vendor: "Cloud Diagnostics Canada ULC"},
  {max_prefix: "40-F4-20",vendor: "Sichuan Tianyi Comheart Telecom Co.,LTD"},
  {max_prefix: "64-5D-92",vendor: "Sichuan Tianyi Comheart Telecom Co.,LTD"},
  {max_prefix: "CC-A2-60",vendor: "Sichuan Tianyi Comheart Telecom Co.,LTD"},
  {max_prefix: "9C-61-21",vendor: "Sichuan Tianyi Comheart Telecom Co.,LTD"},
  {max_prefix: "E0-4F-BD",vendor: "Sichuan Tianyi Comheart Telecom Co.,LTD"},
  {max_prefix: "AC-D8-29",vendor: "Bouffalo Lab (Nanjing) Co., Ltd."},
  {max_prefix: "5C-A1-76",vendor: "Sichuan Tianyi Comheart Telecom Co.,LTD"},
  {max_prefix: "4C-D3-AF",vendor: "HMD Global Oy"},
  {max_prefix: "C8-51-42",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "14-22-3B",vendor: "Google, Inc."},
  {max_prefix: "68-3A-48",vendor: "SAMJIN Co., Ltd."},
  {max_prefix: "F4-45-88",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "B0-C9-52",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "10-E4-C2",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "0C-8D-CA",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "CC-0D-E7",vendor: "B METERS S.R.L."},
  {max_prefix: "44-56-E2",vendor: "Sichuan Tianyi Comheart Telecom Co.,LTD"},
  {max_prefix: "3C-37-12",vendor: "AVM Audiovisuelles Marketing und Computersysteme GmbH"},
  {max_prefix: "C8-BB-81",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "1C-47-2F",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "20-5E-64",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "F4-41-9E",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "90-F9-B7",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "C4-74-69",vendor: "BT9"},
  {max_prefix: "F4-6F-A4",vendor: "Physik Instrumente GmbH & Co. KG"},
  {max_prefix: "78-F2-35",vendor: "Sichuan AI-Link Technology Co., Ltd."},
  {max_prefix: "54-AE-D2",vendor: "CSL Dualcom Ltd"},
  {max_prefix: "80-B6-55",vendor: "Intel Corporate"},
  {max_prefix: "0C-CB-0C",vendor: "iSYS RTS GmbH"},
  {max_prefix: "D0-E0-42",vendor: "Cisco Systems, Inc"},
  {max_prefix: "F4-4E-E3",vendor: "Intel Corporate"},
  {max_prefix: "60-57-7D",vendor: "eero inc."},
  {max_prefix: "CC-24-2E",vendor: "Shenzhen SuperElectron Technology Co.,Ltd."},
  {max_prefix: "F0-01-6E",vendor: "Tianyi Telecom Terminals Company Limited"},
  {max_prefix: "EC-0B-AE",vendor: "Hangzhou BroadLink Technology Co.,Ltd"},
  {max_prefix: "80-25-11",vendor: "ITEL MOBILE LIMITED"},
  {max_prefix: "38-02-DE",vendor: "Sercomm Corporation."},
  {max_prefix: "9C-76-0E",vendor: "Apple, Inc."},
  {max_prefix: "94-EA-32",vendor: "Apple, Inc."},
  {max_prefix: "50-F4-EB",vendor: "Apple, Inc."},
  {max_prefix: "28-C7-09",vendor: "Apple, Inc."},
  {max_prefix: "D0-CF-D8",vendor: "Huizhou Boshijie Technology Co.,Ltd"},
  {max_prefix: "5C-62-5A",vendor: "CANON INC."},
  {max_prefix: "7C-0A-3F",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "08-AA-89",vendor: "zte corporation"},
  {max_prefix: "04-D6-0E",vendor: "FUNAI ELECTRIC CO., LTD."},
  {max_prefix: "A4-35-2D",vendor: "TRIZ Networks corp."},
  {max_prefix: "C8-BF-FE",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "08-2F-E9",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "98-48-74",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "1C-A6-81",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "E8-A6-60",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "B8-81-FA",vendor: "Apple, Inc."},
  {max_prefix: "08-2E-36",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "04-9F-81",vendor: "NETSCOUT SYSTEMS INC"},
  {max_prefix: "00-80-8C",vendor: "NETSCOUT SYSTEMS INC"},
  {max_prefix: "20-A7-F9",vendor: "SHENZHEN OLANBOA TECHNOLOGY CO., LTD"},
  {max_prefix: "C0-06-C3",vendor: "TP-Link Corporation Limited"},
  {max_prefix: "18-48-59",vendor: "Castlenet Technology Inc."},
  {max_prefix: "14-51-7E",vendor: "New H3C Technologies Co., Ltd"},
  {max_prefix: "08-3A-38",vendor: "New H3C Technologies Co., Ltd"},
  {max_prefix: "F8-AB-E5",vendor: "shenzhen worldelite electronics co., LTD"},
  {max_prefix: "08-BB-3C",vendor: "Flextronics Tech.(Ind) Pvt Ltd"},
  {max_prefix: "04-BA-1C",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "7C-3D-2B",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "18-C2-41",vendor: "SonicWall"},
  {max_prefix: "58-45-4C",vendor: "Ericsson AB"},
  {max_prefix: "2C-BE-EB",vendor: "Nothing Technology Limited"},
  {max_prefix: "74-09-AC",vendor: "Quext, LLC"},
  {max_prefix: "F4-C7-AA",vendor: "Marvell Semiconductors"},
  {max_prefix: "70-F0-88",vendor: "Nintendo Co.,Ltd"},
  {max_prefix: "CC-ED-21",vendor: "Nokia Shanghai Bell Co., Ltd."},
  {max_prefix: "F0-4A-02",vendor: "Cisco Systems, Inc"},
  {max_prefix: "10-39-4E",vendor: "Hisense broadband multimedia technology Co.,Ltd"},
  {max_prefix: "50-9A-46",vendor: "Safetrust Inc"},
  {max_prefix: "10-D5-61",vendor: "Tuya Smart Inc."},
  {max_prefix: "F0-A3-B2",vendor: "Hui Zhou Gaoshengda Technology Co.,LTD"},
  {max_prefix: "FC-4D-A6",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "B4-14-E6",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "AC-99-29",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "3C-15-12",vendor: "Shenzhen Huanhu Technology Co.,Ltd"},
  {max_prefix: "40-24-B2",vendor: "Sichuan AI-Link Technology Co., Ltd."},
  {max_prefix: "64-0D-22",vendor: "LG Electronics (Mobile Communications)"},
  {max_prefix: "2C-FD-B3",vendor: "Tonly Technology Co. Ltd"},
  {max_prefix: "9C-95-67",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "A4-7B-1A",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "58-AE-F1",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "78-7A-6F",vendor: "Juice Technology AG"},
  {max_prefix: "C0-D4-6B",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "8C-31-E2",vendor: "DAYOUPLUS"},
  {max_prefix: "E8-6C-C7",vendor: "IEEE Registration Authority"},
  {max_prefix: "64-17-59",vendor: "Intellivision Holdings, LLC"},
  {max_prefix: "50-43-48",vendor: "ThingsMatrix Inc."},
  {max_prefix: "D8-59-82",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "48-B2-5D",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "A4-1B-34",vendor: "China Mobile Group Device Co.,Ltd."},
  {max_prefix: "80-45-DD",vendor: "Intel Corporate"},
  {max_prefix: "14-7D-05",vendor: "SERCOMM PHILIPPINES INC"},
  {max_prefix: "24-E8-53",vendor: "LG Innotek"},
  {max_prefix: "48-29-52",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "A4-7E-36",vendor: "EM Microelectronic"},
  {max_prefix: "98-38-7D",vendor: "ITRONIC  TECHNOLOGY CO . , LTD ."},
  {max_prefix: "2C-CE-1E",vendor: "Cloudtronics Pty Ltd"},
  {max_prefix: "AC-D6-18",vendor: "OnePlus Technology (Shenzhen) Co., Ltd"},
  {max_prefix: "6C-56-40",vendor: "BLU Products Inc"},
  {max_prefix: "C8-D8-84",vendor: "Universal Electronics, Inc."},
  {max_prefix: "B4-A2-5C",vendor: "Cambium Networks Limited"},
  {max_prefix: "2C-71-FF",vendor: "Amazon Technologies Inc."},
  {max_prefix: "48-78-5E",vendor: "Amazon Technologies Inc."},
  {max_prefix: "20-C7-4F",vendor: "SensorPush"},
  {max_prefix: "DC-9A-8E",vendor: "Nanjing Cocomm electronics co., LTD"},
  {max_prefix: "DC-00-77",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "18-4C-AE",vendor: "CONTINENTAL"},
  {max_prefix: "C4-D0-E3",vendor: "Intel Corporate"},
  {max_prefix: "F0-21-E0",vendor: "eero inc."},
  {max_prefix: "30-7C-4A",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "14-50-51",vendor: "SHARP Corporation"},
  {max_prefix: "3C-C7-86",vendor: "DONGGUAN HUARONG COMMUNICATION TECHNOLOGIES CO.,LTD."},
  {max_prefix: "88-C3-E5",vendor: "Betop Techonologies"},
  {max_prefix: "E4-28-A4",vendor: "Prama India Private Limited"},
  {max_prefix: "94-3A-91",vendor: "Amazon Technologies Inc."},
  {max_prefix: "80-C5-01",vendor: "OctoGate IT Security Systems GmbH"},
  {max_prefix: "14-D1-9E",vendor: "Apple, Inc."},
  {max_prefix: "40-C7-11",vendor: "Apple, Inc."},
  {max_prefix: "5C-70-17",vendor: "Apple, Inc."},
  {max_prefix: "E4-5E-1B",vendor: "Google, Inc."},
  {max_prefix: "C4-30-CA",vendor: "SD Biosensor"},
  {max_prefix: "28-05-2E",vendor: "Dematic Corp"},
  {max_prefix: "8C-EC-7B",vendor: "Apple, Inc."},
  {max_prefix: "00-40-DD",vendor: "HONG TECHNOLOGIES"},
  {max_prefix: "40-8C-1F",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "00-2D-B3",vendor: "AMPAK Technology,Inc."},
  {max_prefix: "E4-FD-45",vendor: "Intel Corporate"},
  {max_prefix: "28-C8-7C",vendor: "zte corporation"},
  {max_prefix: "04-F0-3E",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "78-E2-2C",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "C0-D0-26",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "50-2D-FB",vendor: "IGShare Co., Ltd."},
  {max_prefix: "40-EE-15",vendor: "Zioncom Electronics (Shenzhen) Ltd."},
  {max_prefix: "60-6C-63",vendor: "Hitron Technologies. Inc"},
  {max_prefix: "00-F3-61",vendor: "Amazon Technologies Inc."},
  {max_prefix: "A4-42-3B",vendor: "Intel Corporate"},
  {max_prefix: "EC-BE-5F",vendor: "Vestel Elektronik San ve Tic. A.S."},
  {max_prefix: "F4-2A-7D",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "04-F9-93",vendor: "Infinix mobility limited"},
  {max_prefix: "BC-BD-9E",vendor: "ITEL MOBILE LIMITED"},
  {max_prefix: "70-CF-49",vendor: "Intel Corporate"},
  {max_prefix: "48-51-C5",vendor: "Intel Corporate"},
  {max_prefix: "6C-03-09",vendor: "Cisco Systems, Inc"},
  {max_prefix: "BC-D2-95",vendor: "Cisco Systems, Inc"},
  {max_prefix: "F0-39-65",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "EC-7C-B6",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "58-A6-39",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "A4-E5-7C",vendor: "Espressif Inc."},
  {max_prefix: "30-4E-1B",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "74-50-4E",vendor: "New H3C Technologies Co., Ltd"},
  {max_prefix: "6C-0D-E1",vendor: "Dongguan Cannice Precision Manufacturing Co., Ltd."},
  {max_prefix: "8C-0F-C9",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "E8-D7-65",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "CC-20-8C",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "88-5A-85",vendor: "Wistron Neweb Corporation"},
  {max_prefix: "D8-00-93",vendor: "Aurender Inc."},
  {max_prefix: "BC-6D-05",vendor: "Dusun Electron Co.,Ltd."},
  {max_prefix: "C0-E0-18",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "5C-E7-47",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "08-9A-C7",vendor: "zte corporation"},
  {max_prefix: "74-4C-A1",vendor: "Liteon Technology Corporation"},
  {max_prefix: "B4-B2-91",vendor: "LG Electronics"},
  {max_prefix: "A8-FF-BA",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "7C-C2-C6",vendor: "TP-Link Corporation Limited"},
  {max_prefix: "A0-02-4A",vendor: "IEEE Registration Authority"},
  {max_prefix: "C8-6C-3D",vendor: "Amazon Technologies Inc."},
  {max_prefix: "38-90-52",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "C0-F6-EC",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "70-61-7B",vendor: "Cisco Systems, Inc"},
  {max_prefix: "04-E7-7E",vendor: "We Corporation Inc."},
  {max_prefix: "74-EC-B2",vendor: "Amazon Technologies Inc."},
  {max_prefix: "4C-52-EC",vendor: "SOLARWATT GmbH"},
  {max_prefix: "14-6B-9A",vendor: "zte corporation"},
  {max_prefix: "30-D9-41",vendor: "Raydium Semiconductor Corp."},
  {max_prefix: "68-76-27",vendor: "Zhuhai Dingzhi Electronic Technology Co., Ltd"},
  {max_prefix: "94-8E-D3",vendor: "Arista Networks"},
  {max_prefix: "E0-1F-ED",vendor: "Nokia Shanghai Bell Co., Ltd."},
  {max_prefix: "70-40-FF",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "34-D6-93",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "24-E4-C8",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "24-94-93",vendor: "FibRSol Global Network Limited"},
  {max_prefix: "28-D0-44",vendor: "Shenzhen Xinyin technology company"},
  {max_prefix: "9C-9A-C0",vendor: "LEGO System A/S"},
  {max_prefix: "A0-9F-10",vendor: "SHENZHEN BILIAN ELECTRONIC CO.\uff0cLTD"},
  {max_prefix: "20-1B-88",vendor: "Dongguan Liesheng Electronic Co., Ltd."},
  {max_prefix: "74-B7-B3",vendor: "Shenzhen YOUHUA Technology Co., Ltd"},
  {max_prefix: "DC-9B-D6",vendor: "TCT mobile ltd"},
  {max_prefix: "DC-8C-1B",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "3C-BD-C5",vendor: "Arcadyan Corporation"},
  {max_prefix: "A8-DA-0C",vendor: "SERVERCOM (INDIA) PRIVATE LIMITED"},
  {max_prefix: "A4-AA-FE",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "F8-3B-7E",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "88-15-C5",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "9C-28-B3",vendor: "Apple, Inc."},
  {max_prefix: "A0-78-17",vendor: "Apple, Inc."},
  {max_prefix: "5C-87-30",vendor: "Apple, Inc."},
  {max_prefix: "B4-1B-B0",vendor: "Apple, Inc."},
  {max_prefix: "58-D3-49",vendor: "Apple, Inc."},
  {max_prefix: "F4-34-F0",vendor: "Apple, Inc."},
  {max_prefix: "F8-53-29",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "B0-8C-75",vendor: "Apple, Inc."},
  {max_prefix: "98-3F-60",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "C0-3F-DD",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "30-32-35",vendor: "Qingdao Intelligent&Precise Electronics Co.,Ltd."},
  {max_prefix: "00-30-1F",vendor: "OPTICAL NETWORKS, INC."},
  {max_prefix: "08-58-A5",vendor: "Beijing Vrv Software Corpoaration Limited."},
  {max_prefix: "7C-C2-94",vendor: "Beijing Xiaomi Mobile Software Co., Ltd"},
  {max_prefix: "E0-E2-E6",vendor: "Espressif Inc."},
  {max_prefix: "9C-73-70",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "68-D4-8B",vendor: "Hailo Technologies Ltd."},
  {max_prefix: "40-D4-BD",vendor: "SK Networks Service CO., LTD."},
  {max_prefix: "84-1B-77",vendor: "Intel Corporate"},
  {max_prefix: "5C-10-C5",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "A8-40-7D",vendor: "GD Midea Air-Conditioning Equipment Co.,Ltd."},
  {max_prefix: "FC-4B-57",vendor: "Peerless Instrument Division of Curtiss-Wright"},
  {max_prefix: "18-EE-86",vendor: "Novatel Wireless Solutions, Inc."},
  {max_prefix: "3C-D2-E5",vendor: "New H3C Technologies Co., Ltd"},
  {max_prefix: "00-13-91",vendor: "OUEN CO.,LTD."},
  {max_prefix: "58-24-29",vendor: "Google, Inc."},
  {max_prefix: "C4-37-72",vendor: "Virtuozzo International GmbH"},
  {max_prefix: "CC-3B-27",vendor: "TECNO MOBILE LIMITED"},
  {max_prefix: "E8-0A-EC",vendor: "Jiangsu Hengtong Optic-Electric Co., LTD"},
  {max_prefix: "C4-DE-7B",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "6C-1A-75",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "6C-76-37",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "E8-EB-34",vendor: "Cisco Systems, Inc"},
  {max_prefix: "A4-17-8B",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "94-B2-71",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "78-05-8C",vendor: "mMax Communications, Inc."},
  {max_prefix: "C4-A7-2B",vendor: "SHENZHEN CHUANGWEI-RGB ELECTRONICS CO.,LTD"},
  {max_prefix: "D0-C3-1E",vendor: "JUNGJIN Electronics Co.,Ltd"},
  {max_prefix: "D8-10-CB",vendor: "Andrea Informatique"},
  {max_prefix: "24-01-6F",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "CC-F5-5F",vendor: "E FOCUS INSTRUMENTS INDIA PRIVATE LIMITED"},
  {max_prefix: "08-7C-39",vendor: "Amazon Technologies Inc."},
  {max_prefix: "14-13-33",vendor: "AzureWave Technology Inc."},
  {max_prefix: "C0-C9-E3",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "F8-8C-21",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "C4-27-8C",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "7C-73-EB",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "E4-8F-1D",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "C0-3C-59",vendor: "Intel Corporate"},
  {max_prefix: "4C-3B-DF",vendor: "Microsoft Corporation"},
  {max_prefix: "84-E3-42",vendor: "Tuya Smart Inc."},
  {max_prefix: "FC-CD-2F",vendor: "IEEE Registration Authority"},
  {max_prefix: "80-8F-E8",vendor: "Intelbras"},
  {max_prefix: "18-CC-18",vendor: "Intel Corporate"},
  {max_prefix: "E0-91-3C",vendor: "Kyeungin CNS Co., Ltd."},
  {max_prefix: "D0-59-19",vendor: "zte corporation"},
  {max_prefix: "00-B8-81",vendor: "New platforms LLC"},
  {max_prefix: "B0-98-BC",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "5C-34-00",vendor: "Hisense Electric Co.,Ltd"},
  {max_prefix: "B4-31-61",vendor: "Realme Chongqing Mobile Telecommunications Corp.,Ltd."},
  {max_prefix: "10-D7-B0",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "34-1C-F0",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "CC-4F-5C",vendor: "IEEE Registration Authority"},
  {max_prefix: "FC-6D-D1",vendor: "APRESIA Systems, Ltd."},
  {max_prefix: "30-AF-CE",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "B4-FB-E3",vendor: "AltoBeam (China) Inc."},
  {max_prefix: "18-87-40",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "6C-09-BF",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "64-F9-47",vendor: "Senscomm Semiconductor Co., Ltd."},
  {max_prefix: "DC-00-B0",vendor: "FREEBOX SAS"},
  {max_prefix: "70-74-14",vendor: "Murata Manufacturing Co., Ltd."},
  {max_prefix: "A0-76-4E",vendor: "Espressif Inc."},
  {max_prefix: "C8-E6-00",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "D4-D5-1B",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "24-91-BB",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "64-5E-10",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "EC-F2-2B",vendor: "TECNO MOBILE LIMITED"},
  {max_prefix: "30-96-10",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "44-59-43",vendor: "zte corporation"},
  {max_prefix: "34-36-54",vendor: "zte corporation"},
  {max_prefix: "30-57-8E",vendor: "eero inc."},
  {max_prefix: "FC-B3-BC",vendor: "Intel Corporate"},
  {max_prefix: "C8-16-DA",vendor: "Realme Chongqing Mobile Telecommunications Corp.,Ltd."},
  {max_prefix: "B0-44-14",vendor: "New H3C Technologies Co., Ltd"},
  {max_prefix: "74-8F-3C",vendor: "Apple, Inc."},
  {max_prefix: "40-F9-46",vendor: "Apple, Inc."},
  {max_prefix: "1C-FE-2B",vendor: "Amazon Technologies Inc."},
  {max_prefix: "A4-AE-12",vendor: "Hon Hai Precision Industry Co., Ltd."},
  {max_prefix: "9C-9D-7E",vendor: "Beijing Xiaomi Mobile Software Co., Ltd"},
  {max_prefix: "DC-A3-A2",vendor: "Feng mi(Beijing)technology co., LTD"},
  {max_prefix: "EC-57-0D",vendor: "AFE Inc."},
  {max_prefix: "7C-25-DA",vendor: "FN-LINK TECHNOLOGY LIMITED"},
  {max_prefix: "30-59-B7",vendor: "Microsoft"},
  {max_prefix: "44-A5-4E",vendor: "Qorvo International Pte. Ltd."},
  {max_prefix: "A8-69-8C",vendor: "Oracle Corporation"},
  {max_prefix: "A4-AC-0F",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "CC-FF-90",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "00-30-54",vendor: "Castlenet Technology Inc."},
  {max_prefix: "E4-5F-01",vendor: "Raspberry Pi Trading Ltd"},
  {max_prefix: "00-13-68",vendor: "Saab Danmark A/S"},
  {max_prefix: "A8-35-12",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "B0-30-C8",vendor: "Teal Drones, Inc."},
  {max_prefix: "D4-F3-37",vendor: "Xunison Ltd."},
  {max_prefix: "48-A2-B8",vendor: "Chengdu Vision-Zenith Tech.Co,.Ltd"},
  {max_prefix: "6C-44-2A",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "A4-7C-C9",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "C4-0D-96",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "58-E8-73",vendor: "HANGZHOU DANGBEI NETWORK TECH.Co.,Ltd"},
  {max_prefix: "C0-B8-E6",vendor: "Ruijie Networks Co.,LTD"},
  {max_prefix: "74-12-B3",vendor: "CHONGQING FUGUI ELECTRONICS CO.,LTD."},
  {max_prefix: "94-47-B0",vendor: "BEIJING ESWIN COMPUTING TECHNOLOGY CO., LTD"},
  {max_prefix: "E4-5A-D4",vendor: "Eltex Enterprise Ltd."},
  {max_prefix: "84-22-5E",vendor: "SHENZHEN TECHNEWCHIP TECHNOLOGY CO.,LTD."},
  {max_prefix: "F8-5C-7D",vendor: "Shenzhen Honesty Electronics Co.,Ltd."},
  {max_prefix: "44-AF-28",vendor: "Intel Corporate"},
  {max_prefix: "D4-A6-51",vendor: "Tuya Smart Inc."},
  {max_prefix: "84-2A-FD",vendor: "HP Inc."},
  {max_prefix: "A0-68-1C",vendor: "GD Midea Air-Conditioning Equipment Co.,Ltd."},
  {max_prefix: "90-F6-44",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "84-6B-48",vendor: "ShenZhen EepuLink Co., Ltd."},
  {max_prefix: "B4-60-ED",vendor: "Beijing Xiaomi Mobile Software Co., Ltd"},
  {max_prefix: "00-30-0B",vendor: "mPHASE Technologies, Inc."},
  {max_prefix: "C0-18-50",vendor: "Quanta Computer Inc."},
  {max_prefix: "44-27-F3",vendor: "70mai Co.,Ltd."},
  {max_prefix: "30-CC-21",vendor: "zte corporation"},
  {max_prefix: "64-6C-80",vendor: "CHONGQING FUGUI ELECTRONICS CO.,LTD."},
  {max_prefix: "00-26-C0",vendor: "EnergyHub"},
  {max_prefix: "80-EA-CA",vendor: "Dialog Semiconductor Hellas SA"},
  {max_prefix: "00-08-96",vendor: "Printronix, Inc."},
  {max_prefix: "00-25-DC",vendor: "Sumitomo Electric Industries, Ltd"},
  {max_prefix: "04-7D-7B",vendor: "Quanta Computer Inc."},
  {max_prefix: "08-9E-01",vendor: "Quanta Computer Inc."},
  {max_prefix: "A8-1E-84",vendor: "Quanta Computer Inc."},
  {max_prefix: "DC-41-A9",vendor: "Intel Corporate"},
  {max_prefix: "8C-94-1F",vendor: "Cisco Systems, Inc"},
  {max_prefix: "68-7D-B4",vendor: "Cisco Systems, Inc"},
  {max_prefix: "BC-7F-7B",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "F0-FA-C7",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "B4-6F-2D",vendor: "Wahoo Fitness"},
  {max_prefix: "5C-85-7E",vendor: "IEEE Registration Authority"},
  {max_prefix: "88-03-E9",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "DC-EF-80",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "0C-DC-7E",vendor: "Espressif Inc."},
  {max_prefix: "20-98-D8",vendor: "Shenzhen Yingdakang Technology CO., LTD"},
  {max_prefix: "18-26-49",vendor: "Intel Corporate"},
  {max_prefix: "34-C9-3D",vendor: "Intel Corporate"},
  {max_prefix: "1C-01-2D",vendor: "Ficer Technology"},
  {max_prefix: "3C-A6-2F",vendor: "AVM Audiovisuelles Marketing und Computersysteme GmbH"},
  {max_prefix: "7C-C7-7E",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "A0-CF-F5",vendor: "zte corporation"},
  {max_prefix: "98-B3-EF",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "50-F9-58",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "C0-A3-6E",vendor: "SKY UK LIMITED"},
  {max_prefix: "60-32-B1",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "7C-FD-6B",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "00-05-C9",vendor: "LG Innotek"},
  {max_prefix: "AC-F1-08",vendor: "LG Innotek"},
  {max_prefix: "34-58-40",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "5C-64-7A",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "E4-1F-7B",vendor: "Cisco Systems, Inc"},
  {max_prefix: "E8-6D-CB",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "30-49-50",vendor: "IEEE Registration Authority"},
  {max_prefix: "18-95-52",vendor: "1MORE"},
  {max_prefix: "98-C7-A4",vendor: "Shenzhen HS Fiber Communication Equipment CO., LTD"},
  {max_prefix: "00-BE-D5",vendor: "New H3C Technologies Co., Ltd"},
  {max_prefix: "AC-5F-EA",vendor: "OnePlus Technology (Shenzhen) Co., Ltd"},
  {max_prefix: "04-4A-C6",vendor: "Aipon Electronics Co., Ltd"},
  {max_prefix: "C0-FF-A8",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "FC-14-99",vendor: "Aimore Acoustics Incorporation"},
  {max_prefix: "88-94-8F",vendor: "Xi'an Zhisensor Technologies Co.,Ltd"},
  {max_prefix: "5C-D5-B5",vendor: "Shenzhen WiSiYiLink Technology Co.,Ltd"},
  {max_prefix: "C8-7E-A1",vendor: "TCL MOKA International Limited"},
  {max_prefix: "64-2C-0F",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "08-ED-9D",vendor: "TECNO MOBILE LIMITED"},
  {max_prefix: "20-9E-79",vendor: "Universal Electronics, Inc."},
  {max_prefix: "5C-FE-9E",vendor: "Wiwynn Corporation Tainan Branch"},
  {max_prefix: "88-1C-95",vendor: "ITEL MOBILE LIMITED"},
  {max_prefix: "00-20-03",vendor: "PIXEL POWER LTD."},
  {max_prefix: "F0-2E-51",vendor: "Casa Systems"},
  {max_prefix: "C8-E4-2F",vendor: "Technical Research Design and Development"},
  {max_prefix: "68-0A-E2",vendor: "Silicon Laboratories"},
  {max_prefix: "F4-69-42",vendor: "ASKEY COMPUTER CORP"},
  {max_prefix: "08-E9-F6",vendor: "AMPAK Technology,Inc."},
  {max_prefix: "CC-C2-61",vendor: "IEEE Registration Authority"},
  {max_prefix: "30-66-D0",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "3C-B2-33",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "64-0B-D7",vendor: "Apple, Inc."},
  {max_prefix: "A8-91-3D",vendor: "Apple, Inc."},
  {max_prefix: "0C-3B-50",vendor: "Apple, Inc."},
  {max_prefix: "4C-C9-5E",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "FC-A5-D0",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "C8-5B-A0",vendor: "Shenzhen Qihu Intelligent Technology Company Limited"},
  {max_prefix: "E0-BE-03",vendor: "Lite-On Network Communication (Dongguan) Limited"},
  {max_prefix: "A4-88-73",vendor: "Cisco Systems, Inc"},
  {max_prefix: "A4-BD-C4",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "5C-91-57",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "04-CB-88",vendor: "Shenzhen Jingxun Software Telecommunication Technology Co.,Ltd"},
  {max_prefix: "B8-80-4F",vendor: "Texas Instruments"},
  {max_prefix: "00-0F-2D",vendor: "CHUNG-HSIN ELECTRIC & MACHINERY MFG.CORP."},
  {max_prefix: "B4-62-93",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "00-0D-A1",vendor: "MIRAE ITS Co.,LTD."},
  {max_prefix: "24-14-07",vendor: "Xiamen Sigmastar Technology Ltd."},
  {max_prefix: "B4-79-47",vendor: "Nutanix"},
  {max_prefix: "98-CB-A4",vendor: "Benchmark Electronics"},
  {max_prefix: "18-3C-B7",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "5C-90-12",vendor: "Owl Cyber Defense Solutions, LLC"},
  {max_prefix: "38-F7-CD",vendor: "IEEE Registration Authority"},
  {max_prefix: "9C-29-76",vendor: "Intel Corporate"},
  {max_prefix: "04-F8-F8",vendor: "Edgecore Networks Corporation"},
  {max_prefix: "48-16-93",vendor: "Lear Corporation GmbH"},
  {max_prefix: "18-F6-97",vendor: "Axiom Memory Solutions, Inc."},
  {max_prefix: "5C-6B-D7",vendor: "Foshan VIOMI Electric Appliance Technology Co. Ltd."},
  {max_prefix: "18-48-CA",vendor: "Murata Manufacturing Co., Ltd."},
  {max_prefix: "A8-40-25",vendor: "Oxide Computer Company"},
  {max_prefix: "CC-D9-AC",vendor: "Intel Corporate"},
  {max_prefix: "34-7D-F6",vendor: "Intel Corporate"},
  {max_prefix: "80-22-A7",vendor: "NEC Platforms, Ltd."},
  {max_prefix: "5C-23-16",vendor: "Squirrels Research Labs LLC"},
  {max_prefix: "04-21-44",vendor: "Sunitec Enterprise Co.,Ltd"},
  {max_prefix: "64-E1-72",vendor: "Shenzhen Qihoo Intelligent Technology Co.,Ltd"},
  {max_prefix: "CC-0D-F2",vendor: "Motorola Mobility LLC, a Lenovo Company"},
  {max_prefix: "94-43-4D",vendor: "Ciena Corporation"},
  {max_prefix: "A0-27-B6",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "10-39-17",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "98-80-EE",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "90-EE-C7",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "10-29-AB",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "18-4E-CB",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "60-F4-3A",vendor: "Edifier International"},
  {max_prefix: "FC-B6-98",vendor: "Cambridge Industries(Group) Co.,Ltd."},
  {max_prefix: "C4-D8-F3",vendor: "iZotope"},
  {max_prefix: "00-01-CD",vendor: "ARtem"},
  {max_prefix: "58-A8-7B",vendor: "Fitbit, Inc."},
  {max_prefix: "C4-89-ED",vendor: "Solid Optics EU N.V."},
  {max_prefix: "48-8F-5A",vendor: "Routerboard.com"},
  {max_prefix: "10-06-45",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "AC-67-B2",vendor: "Espressif Inc."},
  {max_prefix: "98-59-49",vendor: "LUXOTTICA GROUP S.P.A."},
  {max_prefix: "68-33-2C",vendor: "KENSTEL NETWORKS LIMITED"},
  {max_prefix: "D8-78-7F",vendor: "Ubee Interactive Co., Limited"},
  {max_prefix: "9C-28-BF",vendor: "Continental Automotive Czech Republic s.r.o."},
  {max_prefix: "78-2B-46",vendor: "Intel Corporate"},
  {max_prefix: "78-57-73",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "AC-60-89",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "00-06-B3",vendor: "Diagraph Corporation"},
  {max_prefix: "84-3E-92",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "70-8C-B6",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "50-46-4A",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "C4-A4-02",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "9C-BD-6E",vendor: "DERA Co., Ltd"},
  {max_prefix: "4C-33-29",vendor: "Sweroam"},
  {max_prefix: "34-E5-EC",vendor: "Palo Alto Networks"},
  {max_prefix: "00-B7-A8",vendor: "Heinzinger electronic GmbH"},
  {max_prefix: "34-CF-F6",vendor: "Intel Corporate"},
  {max_prefix: "D4-D2-D6",vendor: "FN-LINK TECHNOLOGY LIMITED"},
  {max_prefix: "10-50-72",vendor: "Sercomm Corporation."},
  {max_prefix: "98-FA-A7",vendor: "INNONET"},
  {max_prefix: "AC-4A-67",vendor: "Cisco Systems, Inc"},
  {max_prefix: "90-0A-84",vendor: "Mellanox Technologies, Inc."},
  {max_prefix: "EC-79-49",vendor: "FUJITSU LIMITED"},
  {max_prefix: "B8-E3-B1",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "98-0D-51",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "DC-33-3D",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "28-54-71",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "B8-8E-82",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "70-CA-97",vendor: "Ruckus Wireless"},
  {max_prefix: "08-87-C6",vendor: "INGRAM MICRO SERVICES"},
  {max_prefix: "3C-B5-3D",vendor: "HUNAN GOKE MICROELECTRONICS CO.,LTD"},
  {max_prefix: "AC-3A-67",vendor: "Cisco Systems, Inc"},
  {max_prefix: "08-6B-D1",vendor: "Shenzhen SuperElectron Technology Co.,Ltd."},
  {max_prefix: "34-51-80",vendor: "TCL King Electrical Appliances (Huizhou) Co., Ltd"},
  {max_prefix: "F4-73-35",vendor: "Logitech Far East"},
  {max_prefix: "90-AD-FC",vendor: "Telechips, Inc."},
  {max_prefix: "5C-A6-2D",vendor: "Cisco Systems, Inc"},
  {max_prefix: "40-2B-69",vendor: "Kumho Electric Inc."},
  {max_prefix: "A4-08-01",vendor: "Amazon Technologies Inc."},
  {max_prefix: "00-AD-D5",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "90-5D-7C",vendor: "New H3C Technologies Co., Ltd"},
  {max_prefix: "A4-CF-D2",vendor: "Ubee Interactive Co., Limited"},
  {max_prefix: "A8-A0-97",vendor: "ScioTeq bvba"},
  {max_prefix: "BC-16-95",vendor: "zte corporation"},
  {max_prefix: "E8-E9-8E",vendor: "SOLAR controls s.r.o."},
  {max_prefix: "84-2E-14",vendor: "Silicon Laboratories"},
  {max_prefix: "00-20-E1",vendor: "ALAMAR ELECTRONICS"},
  {max_prefix: "08-F4-58",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "EC-97-B2",vendor: "SUMEC Machinery & Electric Co.,Ltd."},
  {max_prefix: "28-FA-7A",vendor: "Zhejiang Tmall Technology Co., Ltd."},
  {max_prefix: "64-F6-BB",vendor: "Fibocom Wireless Inc."},
  {max_prefix: "44-10-FE",vendor: "Huizhou Foryou General Electronics Co., Ltd."},
  {max_prefix: "EC-31-6D",vendor: "Hansgrohe"},
  {max_prefix: "BC-54-2F",vendor: "Intel Corporate"},
  {max_prefix: "6C-D9-4C",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "AC-1E-D0",vendor: "Temic Automotive Philippines Inc."},
  {max_prefix: "00-88-BA",vendor: "NC&C"},
  {max_prefix: "10-05-E1",vendor: "Nokia"},
  {max_prefix: "34-37-94",vendor: "Hamee Corp."},
  {max_prefix: "4C-A6-4D",vendor: "Cisco Systems, Inc"},
  {max_prefix: "CC-7F-75",vendor: "Cisco Systems, Inc"},
  {max_prefix: "20-E8-74",vendor: "Apple, Inc."},
  {max_prefix: "D0-3F-AA",vendor: "Apple, Inc."},
  {max_prefix: "7C-AB-60",vendor: "Apple, Inc."},
  {max_prefix: "44-C6-5D",vendor: "Apple, Inc."},
  {max_prefix: "18-7E-B9",vendor: "Apple, Inc."},
  {max_prefix: "D4-DC-09",vendor: "Mist Systems, Inc."},
  {max_prefix: "38-01-46",vendor: "SHENZHEN BILIAN ELECTRONIC CO.\uff0cLTD"},
  {max_prefix: "78-94-E8",vendor: "Radio Bridge"},
  {max_prefix: "40-EC-99",vendor: "Intel Corporate"},
  {max_prefix: "98-00-6A",vendor: "zte corporation"},
  {max_prefix: "1C-97-C5",vendor: "Ynomia Pty Ltd"},
  {max_prefix: "5C-C1-D7",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "88-96-55",vendor: "Zitte corporation"},
  {max_prefix: "74-B6-B6",vendor: "eero inc."},
  {max_prefix: "7C-DF-A1",vendor: "Espressif Inc."},
  {max_prefix: "F4-A4-D6",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "FC-E1-4F",vendor: "BRK Brands, Inc."},
  {max_prefix: "A8-4D-4A",vendor: "Audiowise Technology Inc."},
  {max_prefix: "54-7F-BC",vendor: "iodyne"},
  {max_prefix: "5C-27-D4",vendor: "Shenzhen Qihu Intelligent Technology Company Limited"},
  {max_prefix: "74-D8-3E",vendor: "Intel Corporate"},
  {max_prefix: "3C-BF-60",vendor: "Apple, Inc."},
  {max_prefix: "A8-7E-EA",vendor: "Intel Corporate"},
  {max_prefix: "B0-0A-D5",vendor: "zte corporation"},
  {max_prefix: "08-D2-3E",vendor: "Intel Corporate"},
  {max_prefix: "20-11-4E",vendor: "MeteRSit S.R.L."},
  {max_prefix: "FC-F2-9F",vendor: "China Mobile Iot Limited company"},
  {max_prefix: "F8-1F-32",vendor: "Motorola Mobility LLC, a Lenovo Company"},
  {max_prefix: "04-72-95",vendor: "Apple, Inc."},
  {max_prefix: "D4-46-E1",vendor: "Apple, Inc."},
  {max_prefix: "14-14-59",vendor: "Vodafone Italia S.p.A."},
  {max_prefix: "50-43-B9",vendor: "OktoInform RUS"},
  {max_prefix: "88-B6-EE",vendor: "Dish Technologies Corp"},
  {max_prefix: "1C-91-9D",vendor: "Dongguan Liesheng Electronic Co., Ltd."},
  {max_prefix: "2C-57-41",vendor: "Cisco Systems, Inc"},
  {max_prefix: "B0-B3-53",vendor: "IEEE Registration Authority"},
  {max_prefix: "48-7B-5E",vendor: "SMT TELECOMM HK"},
  {max_prefix: "18-30-0C",vendor: "Hisense Electric Co.,Ltd"},
  {max_prefix: "44-A5-6E",vendor: "NETGEAR"},
  {max_prefix: "AC-15-F4",vendor: "Apple, Inc."},
  {max_prefix: "78-D1-62",vendor: "Apple, Inc."},
  {max_prefix: "08-F8-BC",vendor: "Apple, Inc."},
  {max_prefix: "90-A2-5B",vendor: "Apple, Inc."},
  {max_prefix: "88-A4-79",vendor: "Apple, Inc."},
  {max_prefix: "00-10-4F",vendor: "Oracle Corporation"},
  {max_prefix: "F4-49-55",vendor: "MIMO TECH Co., Ltd."},
  {max_prefix: "3C-28-A6",vendor: "Alcatel-Lucent Enterprise (China)"},
  {max_prefix: "50-50-A4",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "80-86-D9",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "38-6A-77",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "08-09-C7",vendor: "Zhuhai Unitech Power Technology Co., Ltd."},
  {max_prefix: "04-1D-C7",vendor: "zte corporation"},
  {max_prefix: "00-1A-4D",vendor: "GIGA-BYTE TECHNOLOGY CO.,LTD."},
  {max_prefix: "8C-AA-B5",vendor: "Espressif Inc."},
  {max_prefix: "58-96-1D",vendor: "Intel Corporate"},
  {max_prefix: "68-AF-FF",vendor: "Shanghai Cambricon Information Technology Co., Ltd."},
  {max_prefix: "40-2C-76",vendor: "IEEE Registration Authority"},
  {max_prefix: "80-C9-55",vendor: "Redpine Signals, Inc."},
  {max_prefix: "78-85-F4",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "88-54-1F",vendor: "Google, Inc."},
  {max_prefix: "90-0C-C8",vendor: "Google, Inc."},
  {max_prefix: "68-21-5F",vendor: "Edgecore Networks Corporation"},
  {max_prefix: "44-C7-FC",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "00-1F-D0",vendor: "GIGA-BYTE TECHNOLOGY CO.,LTD."},
  {max_prefix: "18-C0-4D",vendor: "GIGA-BYTE TECHNOLOGY CO.,LTD."},
  {max_prefix: "00-25-A9",vendor: "Shanghai Embedway Information Technologies Co.,Ltd"},
  {max_prefix: "5C-68-D0",vendor: "Aurora Innovation Inc."},
  {max_prefix: "10-36-4A",vendor: "Boston Dynamics"},
  {max_prefix: "00-0B-CC",vendor: "JUSAN, S.A."},
  {max_prefix: "00-E0-59",vendor: "CONTROLLED ENVIRONMENTS, LTD."},
  {max_prefix: "00-B8-10",vendor: "Yichip\u00a0Microelectronics (Hangzhou) Co.,Ltd"},
  {max_prefix: "A4-B2-39",vendor: "Cisco Systems, Inc"},
  {max_prefix: "54-8D-5A",vendor: "Intel Corporate"},
  {max_prefix: "D0-1C-3C",vendor: "TECNO MOBILE LIMITED"},
  {max_prefix: "B8-9A-2A",vendor: "Intel Corporate"},
  {max_prefix: "DC-21-E2",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "00-0A-23",vendor: "Parama Networks Inc"},
  {max_prefix: "38-43-E5",vendor: "Grotech Inc"},
  {max_prefix: "FC-1B-D1",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "58-25-75",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "28-DE-E5",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "0C-8E-29",vendor: "Arcadyan Corporation"},
  {max_prefix: "A0-22-4E",vendor: "IEEE Registration Authority"},
  {max_prefix: "18-E7-77",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "0C-7A-15",vendor: "Intel Corporate"},
  {max_prefix: "94-D6-DB",vendor: "NexFi"},
  {max_prefix: "C4-98-78",vendor: "SHANGHAI MOAAN INTELLIGENT TECHNOLOGY CO.,LTD"},
  {max_prefix: "B4-EC-F2",vendor: "Shanghai Listent Medical Tech Co., Ltd."},
  {max_prefix: "C4-95-4D",vendor: "IEEE Registration Authority"},
  {max_prefix: "A4-4B-D5",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "64-95-6C",vendor: "LG Electronics"},
  {max_prefix: "40-77-A9",vendor: "New H3C Technologies Co., Ltd"},
  {max_prefix: "64-69-4E",vendor: "Texas Instruments"},
  {max_prefix: "F8-33-31",vendor: "Texas Instruments"},
  {max_prefix: "6C-06-D6",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "1C-BF-C0",vendor: "CHONGQING FUGUI ELECTRONICS CO.,LTD."},
  {max_prefix: "34-49-5B",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "80-16-09",vendor: "Sleep Number"},
  {max_prefix: "78-B4-6A",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "6C-EB-B6",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "4C-F5-5B",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "64-31-39",vendor: "IEEE Registration Authority"},
  {max_prefix: "E8-3F-67",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "34-46-EC",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "40-A6-B7",vendor: "Intel Corporate"},
  {max_prefix: "C8-F3-19",vendor: "LG Electronics (Mobile Communications)"},
  {max_prefix: "14-87-6A",vendor: "Apple, Inc."},
  {max_prefix: "E0-B5-5F",vendor: "Apple, Inc."},
  {max_prefix: "F8-FF-C2",vendor: "Apple, Inc."},
  {max_prefix: "E0-EB-40",vendor: "Apple, Inc."},
  {max_prefix: "5C-71-0D",vendor: "Cisco Systems, Inc"},
  {max_prefix: "98-AF-65",vendor: "Intel Corporate"},
  {max_prefix: "00-90-96",vendor: "ASKEY COMPUTER CORP"},
  {max_prefix: "44-22-95",vendor: "China Mobile Iot Limited company"},
  {max_prefix: "E8-5A-8B",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "80-75-1F",vendor: "SKY UK LIMITED"},
  {max_prefix: "F8-55-CD",vendor: "Visteon Corporation"},
  {max_prefix: "44-18-47",vendor: "HUNAN SCROWN ELECTRONIC INFORMATION TECH.CO.,LTD"},
  {max_prefix: "2C-3A-FD",vendor: "AVM Audiovisuelles Marketing und Computersysteme GmbH"},
  {max_prefix: "AC-61-B9",vendor: "WAMA Technology Limited"},
  {max_prefix: "1C-C1-BC",vendor: "Yichip\u00a0Microelectronics (Hangzhou) Co.,Ltd"},
  {max_prefix: "AC-8B-9C",vendor: "Primera Technology, Inc."},
  {max_prefix: "00-AB-48",vendor: "eero inc."},
  {max_prefix: "00-30-B7",vendor: "Teletrol Systems, Inc."},
  {max_prefix: "24-74-F7",vendor: "GoPro"},
  {max_prefix: "7C-D5-66",vendor: "Amazon Technologies Inc."},
  {max_prefix: "68-63-50",vendor: "Hella India Automotive Pvt Ltd"},
  {max_prefix: "18-70-3B",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "D8-9E-61",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "34-7E-00",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "5C-E5-0C",vendor: "Beijing Xiaomi Mobile Software Co., Ltd"},
  {max_prefix: "F8-89-3C",vendor: "Inventec Appliances Corp."},
  {max_prefix: "74-9E-F5",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "68-BF-C4",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "B8-2A-DC",vendor: "EFR Europ\u00e4ische Funk-Rundsteuerung GmbH"},
  {max_prefix: "04-B1-A1",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "CC-46-4E",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "30-6F-07",vendor: "Nations Technologies Inc."},
  {max_prefix: "A0-DF-15",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "C4-AD-34",vendor: "Routerboard.com"},
  {max_prefix: "00-07-33",vendor: "DANCONTROL Engineering"},
  {max_prefix: "A8-5E-45",vendor: "ASUSTek COMPUTER INC."},
  {max_prefix: "9C-FF-C2",vendor: "AVI Systems GmbH"},
  {max_prefix: "44-D8-78",vendor: "Hui Zhou Gaoshengda Technology Co.,LTD"},
  {max_prefix: "78-97-C3",vendor: "DINGXIN INFORMATION TECHNOLOGY CO.,LTD"},
  {max_prefix: "64-C9-01",vendor: "INVENTEC Corporation"},
  {max_prefix: "B4-39-39",vendor: "Shenzhen TINNO Mobile Technology Corp."},
  {max_prefix: "5C-CD-5B",vendor: "Intel Corporate"},
  {max_prefix: "84-C8-07",vendor: "ADVA Optical Networking Ltd."},
  {max_prefix: "10-B3-C6",vendor: "Cisco Systems, Inc"},
  {max_prefix: "10-B3-D6",vendor: "Cisco Systems, Inc"},
  {max_prefix: "A0-D8-07",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "2C-78-0E",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "34-B2-0A",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "98-F4-AB",vendor: "Espressif Inc."},
  {max_prefix: "D8-BF-C0",vendor: "Espressif Inc."},
  {max_prefix: "A0-AB-51",vendor: "WEIFANG GOERTEK ELECTRONICS CO.,LTD"},
  {max_prefix: "3C-89-4D",vendor: "Dr. Ing. h.c. F. Porsche AG"},
  {max_prefix: "F8-54-B8",vendor: "Amazon Technologies Inc."},
  {max_prefix: "78-17-35",vendor: "Nokia Shanghai Bell Co., Ltd."},
  {max_prefix: "4C-90-DB",vendor: "JL Audio"},
  {max_prefix: "8C-F1-12",vendor: "Motorola Mobility LLC, a Lenovo Company"},
  {max_prefix: "B8-99-AE",vendor: "Shenzhen MiaoMing  Intelligent Technology Co.,Ltd"},
  {max_prefix: "E8-D0-B9",vendor: "Taicang T&W Electronics"},
  {max_prefix: "F4-D6-20",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "94-90-34",vendor: "SHENZHEN CHUANGWEI-RGB ELECTRONICS CO.,LTD"},
  {max_prefix: "3C-8F-06",vendor: "Shenzhen Libtor Technology Co.,Ltd"},
  {max_prefix: "98-7A-10",vendor: "Ericsson AB"},
  {max_prefix: "20-26-81",vendor: "TECNO MOBILE LIMITED"},
  {max_prefix: "B0-08-75",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "64-FB-92",vendor: "PPC Broadband Inc."},
  {max_prefix: "14-13-46",vendor: "Skyworth Digital Technology(Shenzhen) Co.,Ltd"},
  {max_prefix: "54-2B-DE",vendor: "New H3C Technologies Co., Ltd"},
  {max_prefix: "58-85-A2",vendor: "Realme Chongqing MobileTelecommunications Corp Ltd"},
  {max_prefix: "A8-C0-EA",vendor: "Pepwave Limited"},
  {max_prefix: "00-07-1C",vendor: "AT&T"},
  {max_prefix: "2C-F8-9B",vendor: "Cisco Systems, Inc"},
  {max_prefix: "84-76-37",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "FC-94-35",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "E0-24-81",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "44-AE-AB",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "A4-F0-5E",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "1C-68-7E",vendor: "Shenzhen Qihu Intelligent Technology Company Limited"},
  {max_prefix: "C0-36-56",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "B0-3E-51",vendor: "SKY UK LIMITED"},
  {max_prefix: "28-16-7F",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "08-71-90",vendor: "Intel Corporate"},
  {max_prefix: "80-B0-7B",vendor: "zte corporation"},
  {max_prefix: "C8-5A-9F",vendor: "zte corporation"},
  {max_prefix: "50-02-91",vendor: "Espressif Inc."},
  {max_prefix: "00-1D-DF",vendor: "Sunitec Enterprise Co.,Ltd"},
  {max_prefix: "8C-0F-FA",vendor: "Hutec co.,ltd"},
  {max_prefix: "AC-FE-05",vendor: "ITEL MOBILE LIMITED"},
  {max_prefix: "BC-C3-1B",vendor: "Kygo Life A"},
  {max_prefix: "64-DF-10",vendor: "JingLue Semiconductor(SH) Ltd."},
  {max_prefix: "10-01-77",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "44-A1-91",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "24-52-6A",vendor: "Zhejiang Dahua Technology Co., Ltd."},
  {max_prefix: "20-DF-B9",vendor: "Google, Inc."},
  {max_prefix: "5C-CA-D3",vendor: "CHIPSEA TECHNOLOGIES (SHENZHEN) CORP."},
  {max_prefix: "78-2A-79",vendor: "Integrated Device Technology (Malaysia) Sdn. Bhd."},
  {max_prefix: "78-65-59",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "50-D2-F5",vendor: "Beijing Xiaomi Mobile Software Co., Ltd"},
  {max_prefix: "D0-5F-64",vendor: "IEEE Registration Authority"},
  {max_prefix: "5C-E8-83",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "C4-63-FB",vendor: "Neatframe AS"},
  {max_prefix: "30-57-14",vendor: "Apple, Inc."},
  {max_prefix: "04-A2-22",vendor: "Arcadyan Corporation"},
  {max_prefix: "04-AB-6A",vendor: "Chun-il Co.,Ltd."},
  {max_prefix: "60-23-A4",vendor: "Sichuan\u00a0AI-Link\u00a0Technology\u00a0Co.,\u00a0Ltd."},
  {max_prefix: "A4-53-0E",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-40-3A",vendor: "IMPACT TECHNOLOGIES"},
  {max_prefix: "C8-B1-CD",vendor: "Apple, Inc."},
  {max_prefix: "14-60-CB",vendor: "Apple, Inc."},
  {max_prefix: "B8-F1-2A",vendor: "Apple, Inc."},
  {max_prefix: "F8-87-F1",vendor: "Apple, Inc."},
  {max_prefix: "28-FE-65",vendor: "DongGuan Siyoto Electronics Co., Ltd"},
  {max_prefix: "18-06-F5",vendor: "RAD Data Communications, Ltd."},
  {max_prefix: "60-44-7A",vendor: "Water-i.d. GmbH"},
  {max_prefix: "80-72-15",vendor: "SKY UK LIMITED"},
  {max_prefix: "74-D6-37",vendor: "Amazon Technologies Inc."},
  {max_prefix: "74-84-E1",vendor: "Dongguan Haoyuan Electronics Co.,Ltd"},
  {max_prefix: "40-74-E0",vendor: "Intel Corporate"},
  {max_prefix: "44-FB-5A",vendor: "zte corporation"},
  {max_prefix: "00-35-FF",vendor: "Texas Instruments"},
  {max_prefix: "DC-54-D7",vendor: "Amazon Technologies Inc."},
  {max_prefix: "20-96-8A",vendor: "China Mobile (Hangzhou) Information Technology Co., Ltd."},
  {max_prefix: "8C-18-50",vendor: "China Mobile (Hangzhou) Information Technology Co., Ltd."},
  {max_prefix: "D8-D4-E6",vendor: "Hytec Inter Co., Ltd."},
  {max_prefix: "84-0B-7C",vendor: "Hitron Technologies. Inc"},
  {max_prefix: "88-9F-AA",vendor: "Hella Gutmann Solutions GmbH"},
  {max_prefix: "48-A7-3C",vendor: "Sichuan tianyi kanghe communications co., LTD"},
  {max_prefix: "04-C8-07",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "F8-E7-A0",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "2C-FF-EE",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "44-59-E3",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "68-3F-1E",vendor: "EFFECT Photonics B.V."},
  {max_prefix: "44-D3-CA",vendor: "Cisco Systems, Inc"},
  {max_prefix: "F8-A7-63",vendor: "Zhejiang Tmall Technology Co., Ltd."},
  {max_prefix: "6C-29-90",vendor: "WiZ Connected Lighting Company Limited"},
  {max_prefix: "98-35-ED",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "80-76-93",vendor: "Newag SA"},
  {max_prefix: "BC-97-40",vendor: "IEEE Registration Authority"},
  {max_prefix: "08-4F-0A",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "A8-49-4D",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "44-00-4D",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "18-CF-24",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "14-49-BC",vendor: "DrayTek Corp."},
  {max_prefix: "20-F4-78",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "18-B6-F7",vendor: "NEW POS TECHNOLOGY LIMITED"},
  {max_prefix: "C8-5D-38",vendor: "HUMAX Co., Ltd."},
  {max_prefix: "50-F8-A5",vendor: "eWBM Co., Ltd."},
  {max_prefix: "04-88-5F",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "C8-50-CE",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "D8-9B-3B",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "88-40-3B",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "FC-87-43",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "00-FA-21",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "7C-23-02",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "6C-AB-05",vendor: "Cisco Systems, Inc"},
  {max_prefix: "5C-B1-5F",vendor: "Oceanblue Cloud Technology Limited"},
  {max_prefix: "00-0B-E4",vendor: "Hosiden Corporation"},
  {max_prefix: "44-13-D0",vendor: "zte corporation"},
  {max_prefix: "24-62-AB",vendor: "Espressif Inc."},
  {max_prefix: "18-AA-CA",vendor: "Sichuan tianyi kanghe communications co., LTD"},
  {max_prefix: "00-04-DF",vendor: "TERACOM TELEMATICA S.A"},
  {max_prefix: "74-38-B7",vendor: "CANON INC."},
  {max_prefix: "90-73-5A",vendor: "Motorola Mobility LLC, a Lenovo Company"},
  {max_prefix: "1C-82-59",vendor: "IEEE Registration Authority"},
  {max_prefix: "48-5D-EB",vendor: "Just Add Power"},
  {max_prefix: "50-13-95",vendor: "Sichuan\u00a0AI-Link\u00a0Technology\u00a0Co.,\u00a0Ltd."},
  {max_prefix: "18-D9-EF",vendor: "Shuttle Inc."},
  {max_prefix: "88-DA-33",vendor: "Beijing Xiaoyuer Network Technology Co., Ltd"},
  {max_prefix: "D4-9D-C0",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "D0-19-6A",vendor: "Ciena Corporation"},
  {max_prefix: "84-FD-D1",vendor: "Intel Corporate"},
  {max_prefix: "B0-70-0D",vendor: "Nokia"},
  {max_prefix: "50-41-B9",vendor: "I-O DATA DEVICE,INC."},
  {max_prefix: "84-C7-8F",vendor: "APS Networks GmbH"},
  {max_prefix: "C0-9F-E1",vendor: "zte corporation"},
  {max_prefix: "80-DA-BC",vendor: "Megafone Limited"},
  {max_prefix: "18-46-44",vendor: "Home Control Singapore Pte Ltd"},
  {max_prefix: "00-21-75",vendor: "Pacific Satellite International Ltd."},
  {max_prefix: "34-6B-5B",vendor: "New H3C Technologies Co., Ltd"},
  {max_prefix: "84-E8-92",vendor: "Actiontec Electronics, Inc"},
  {max_prefix: "20-DA-22",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "E0-B6-55",vendor: "Beijing Xiaomi Electronics Co., Ltd."},
  {max_prefix: "C8-21-DA",vendor: "Shenzhen YOUHUA Technology Co., Ltd"},
  {max_prefix: "8C-0F-A0",vendor: "di-soric GmbH & Co. KG"},
  {max_prefix: "78-E2-BD",vendor: "Vodafone Automotive S.p.A."},
  {max_prefix: "F8-48-FD",vendor: "China Mobile Group Device Co.,Ltd."},
  {max_prefix: "F8-BB-BF",vendor: "eero inc."},
  {max_prefix: "DC-FB-48",vendor: "Intel Corporate"},
  {max_prefix: "84-7C-9B",vendor: "GD Midea Air-Conditioning Equipment Co.,Ltd."},
  {max_prefix: "34-41-A8",vendor: "ER-Telecom"},
  {max_prefix: "34-DB-9C",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "74-40-BE",vendor: "LG Innotek"},
  {max_prefix: "20-65-8E",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "18-3D-5E",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "88-97-46",vendor: "Sichuan\u00a0AI-Link\u00a0Technology\u00a0Co.,\u00a0Ltd."},
  {max_prefix: "1C-DE-57",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "E0-DC-FF",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "84-6F-CE",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "B0-E7-1D",vendor: "Shanghai Maigantech Co.,Ltd"},
  {max_prefix: "C4-68-D0",vendor: "VTech Telecommunications Ltd."},
  {max_prefix: "14-AE-DB",vendor: "VTech Telecommunications Ltd."},
  {max_prefix: "78-DB-2F",vendor: "Texas Instruments"},
  {max_prefix: "00-77-8D",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-0E-8C",vendor: "Siemens AG"},
  {max_prefix: "00-87-64",vendor: "Cisco Systems, Inc"},
  {max_prefix: "FC-D2-B6",vendor: "IEEE Registration Authority"},
  {max_prefix: "A8-E2-C3",vendor: "Shenzhen YOUHUA Technology Co., Ltd"},
  {max_prefix: "DC-71-37",vendor: "zte corporation"},
  {max_prefix: "80-4A-14",vendor: "Apple, Inc."},
  {max_prefix: "70-3C-69",vendor: "Apple, Inc."},
  {max_prefix: "4C-6A-F6",vendor: "HMD Global Oy"},
  {max_prefix: "48-9D-D1",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "74-88-BB",vendor: "Cisco Systems, Inc"},
  {max_prefix: "A4-CF-12",vendor: "Espressif Inc."},
  {max_prefix: "FC-B6-62",vendor: "IC Holdings LLC"},
  {max_prefix: "48-04-9F",vendor: "ELECOM CO., LTD"},
  {max_prefix: "08-7F-98",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "C0-41-21",vendor: "Nokia Solutions and Networks GmbH & Co. KG"},
  {max_prefix: "70-BF-92",vendor: "GN Audio A/S"},
  {max_prefix: "0C-A0-6C",vendor: "Industrial Cyber Sensing Inc."},
  {max_prefix: "B0-6F-E0",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "44-B9-94",vendor: "Douglas Lighting Controls"},
  {max_prefix: "04-D4-C4",vendor: "ASUSTek COMPUTER INC."},
  {max_prefix: "A0-51-0B",vendor: "Intel Corporate"},
  {max_prefix: "AC-2D-A9",vendor: "TECNO MOBILE LIMITED"},
  {max_prefix: "40-A9-3F",vendor: "Pivotal Commware, Inc."},
  {max_prefix: "00-2F-5C",vendor: "Cisco Systems, Inc"},
  {max_prefix: "F4-64-5D",vendor: "Toshiba"},
  {max_prefix: "C0-8C-71",vendor: "Motorola Mobility LLC, a Lenovo Company"},
  {max_prefix: "F4-6F-4E",vendor: "Echowell"},
  {max_prefix: "08-EC-F5",vendor: "Cisco Systems, Inc"},
  {max_prefix: "D0-76-50",vendor: "IEEE Registration Authority"},
  {max_prefix: "38-F8-5E",vendor: "HUMAX Co., Ltd."},
  {max_prefix: "00-CB-51",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "C4-64-B7",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "24-79-F8",vendor: "KUPSON spol. s r.o."},
  {max_prefix: "AC-BB-61",vendor: "YSTen Technology Co.,Ltd"},
  {max_prefix: "34-20-03",vendor: "Shenzhen Feitengyun Technology Co.,LTD"},
  {max_prefix: "7C-FD-82",vendor: "GUANGDONG GENIUS TECHNOLOGY CO., LTD."},
  {max_prefix: "68-9A-87",vendor: "Amazon Technologies Inc."},
  {max_prefix: "64-AE-88",vendor: "Polytec GmbH"},
  {max_prefix: "00-D0-50",vendor: "Iskratel d.o.o."},
  {max_prefix: "F0-7D-68",vendor: "D-Link Corporation"},
  {max_prefix: "EC-41-18",vendor: "XIAOMI Electronics,CO.,LTD"},
  {max_prefix: "04-2D-B4",vendor: "First Property (Beijing) Co., Ltd Modern MOMA Branch"},
  {max_prefix: "04-E0-B0",vendor: "Shenzhen YOUHUA Technology Co., Ltd"},
  {max_prefix: "60-D0-A9",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "88-CE-FA",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "00-27-06",vendor: "YOISYS"},
  {max_prefix: "18-0D-2C",vendor: "Intelbras"},
  {max_prefix: "98-DA-C4",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "D8-86-0B",vendor: "IEEE Registration Authority"},
  {max_prefix: "00-17-7B",vendor: "Azalea Networks inc"},
  {max_prefix: "78-DA-A2",vendor: "Cynosure Technologies Co.,Ltd"},
  {max_prefix: "38-B1-9E",vendor: "IEEE Registration Authority"},
  {max_prefix: "50-2B-98",vendor: "Es-tech International"},
  {max_prefix: "C8-28-32",vendor: "Beijing Xiaomi Electronics Co., Ltd."},
  {max_prefix: "38-E2-6E",vendor: "ShenZhen Sweet Rain Electronics Co.,Ltd."},
  {max_prefix: "70-C9-C6",vendor: "Cisco Systems, Inc"},
  {max_prefix: "C4-34-6B",vendor: "Hewlett Packard"},
  {max_prefix: "48-F1-7F",vendor: "Intel Corporate"},
  {max_prefix: "80-84-A9",vendor: "oshkosh Corporation"},
  {max_prefix: "4C-C7-D6",vendor: "FLEXTRONICS MANUFACTURING(ZHUHAI)CO.,LTD."},
  {max_prefix: "C8-08-73",vendor: "Ruckus Wireless"},
  {max_prefix: "70-1B-FB",vendor: "Integrated Device Technology (Malaysia) Sdn. Bhd."},
  {max_prefix: "04-76-6E",vendor: "ALPSALPINE CO,.LTD"},
  {max_prefix: "AC-7A-4D",vendor: "ALPSALPINE CO,.LTD"},
  {max_prefix: "38-C0-96",vendor: "ALPSALPINE CO,.LTD"},
  {max_prefix: "00-26-43",vendor: "ALPSALPINE CO,.LTD"},
  {max_prefix: "00-16-97",vendor: "NEC Corporation"},
  {max_prefix: "00-30-13",vendor: "NEC Corporation"},
  {max_prefix: "04-9D-FE",vendor: "Hivesystem"},
  {max_prefix: "BC-3E-07",vendor: "Hitron Technologies. Inc"},
  {max_prefix: "48-FD-A3",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "28-80-88",vendor: "NETGEAR"},
  {max_prefix: "1C-34-77",vendor: "Innovation Wireless"},
  {max_prefix: "D0-51-57",vendor: "LEAX Arkivator Telecom"},
  {max_prefix: "94-58-CB",vendor: "Nintendo Co.,Ltd"},
  {max_prefix: "28-EC-9A",vendor: "Texas Instruments"},
  {max_prefix: "0C-EC-84",vendor: "Shenzhen TINNO Mobile Technology Corp."},
  {max_prefix: "9C-DB-07",vendor: "Yellowtec GmbH"},
  {max_prefix: "E0-18-9F",vendor: "EM Microelectronic"},
  {max_prefix: "F8-13-08",vendor: "Nokia"},
  {max_prefix: "F8-A2-D6",vendor: "Liteon Technology Corporation"},
  {max_prefix: "C4-E5-06",vendor: "Piper Networks, Inc."},
  {max_prefix: "30-EB-5A",vendor: "LANDIS + GYR"},
  {max_prefix: "F8-0F-6F",vendor: "Cisco Systems, Inc"},
  {max_prefix: "28-25-36",vendor: "SHENZHEN HOLATEK CO.,LTD"},
  {max_prefix: "B8-A1-75",vendor: "Roku, Inc."},
  {max_prefix: "FC-BE-7B",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "B4-0F-B3",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "EC-5C-68",vendor: "CHONGQING FUGUI ELECTRONICS CO.,LTD."},
  {max_prefix: "FC-94-CE",vendor: "zte corporation"},
  {max_prefix: "90-86-9B",vendor: "zte corporation"},
  {max_prefix: "18-2A-44",vendor: "HIROSE ELECTRONIC SYSTEM"},
  {max_prefix: "74-36-6D",vendor: "Vodafone Italia S.p.A."},
  {max_prefix: "CC-D3-C1",vendor: "Vestel Elektronik San ve Tic. A.S."},
  {max_prefix: "74-5F-90",vendor: "LAM Technologies"},
  {max_prefix: "A4-26-55",vendor: "LTI Motion (Shanghai) Co., Ltd."},
  {max_prefix: "60-A7-30",vendor: "Shenzhen Yipinfang Internet Technology Co.,Ltd"},
  {max_prefix: "18-04-ED",vendor: "Texas Instruments"},
  {max_prefix: "D4-32-60",vendor: "GoPro"},
  {max_prefix: "F4-DD-9E",vendor: "GoPro"},
  {max_prefix: "D4-D9-19",vendor: "GoPro"},
  {max_prefix: "00-80-E3",vendor: "CORAL NETWORK CORPORATION"},
  {max_prefix: "D8-F2-CA",vendor: "Intel Corporate"},
  {max_prefix: "B4-C6-2E",vendor: "Molex CMS"},
  {max_prefix: "0C-D0-F8",vendor: "Cisco Systems, Inc"},
  {max_prefix: "3C-9B-D6",vendor: "Vizio, Inc"},
  {max_prefix: "50-DB-3F",vendor: "SHENZHEN GONGJIN ELECTRONICS CO.,LT"},
  {max_prefix: "10-81-B4",vendor: "Hunan Greatwall Galaxy Science and Technology Co.,Ltd."},
  {max_prefix: "00-D8-61",vendor: "Micro-Star INTL CO., LTD."},
  {max_prefix: "74-C1-7D",vendor: "Infinix mobility limited"},
  {max_prefix: "B8-25-9A",vendor: "Thalmic Labs"},
  {max_prefix: "00-42-79",vendor: "Sunitec Enterprise Co.,Ltd"},
  {max_prefix: "A4-50-46",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "38-81-D7",vendor: "Texas Instruments"},
  {max_prefix: "F8-5B-9C",vendor: "SB SYSTEMS Co.,Ltd"},
  {max_prefix: "6C-A9-28",vendor: "HMD Global Oy"},
  {max_prefix: "DC-DA-80",vendor: "New H3C Technologies Co., Ltd"},
  {max_prefix: "F8-50-1C",vendor: "Tianjin Geneuo Technology Co.,Ltd"},
  {max_prefix: "44-07-0B",vendor: "Google, Inc."},
  {max_prefix: "CC-72-86",vendor: "Xi'an Fengyu Information Technology Co., Ltd."},
  {max_prefix: "14-11-14",vendor: "TECNO MOBILE LIMITED"},
  {max_prefix: "00-B8-B3",vendor: "Cisco Systems, Inc"},
  {max_prefix: "EC-F6-BD",vendor: "SNCF MOBILIT\u00c9S"},
  {max_prefix: "B8-31-B5",vendor: "Microsoft Corporation"},
  {max_prefix: "00-7C-2D",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "E8-93-63",vendor: "Nokia"},
  {max_prefix: "7C-0C-F6",vendor: "Guangdong Huiwei High-tech Co., Ltd."},
  {max_prefix: "20-AD-56",vendor: "Continental Automotive Systems Inc."},
  {max_prefix: "50-29-F5",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "FC-8F-7D",vendor: "SHENZHEN GONGJIN ELECTRONICS CO.,LT"},
  {max_prefix: "24-BE-18",vendor: "DADOUTEK COMPANY LIMITED"},
  {max_prefix: "74-9D-79",vendor: "Sercomm Corporation."},
  {max_prefix: "00-D6-FE",vendor: "Cisco Systems, Inc"},
  {max_prefix: "C8-47-82",vendor: "Areson Technology Corp."},
  {max_prefix: "0C-BF-74",vendor: "Morse Micro"},
  {max_prefix: "B4-1D-2B",vendor: "Shenzhen YOUHUA Technology Co., Ltd"},
  {max_prefix: "14-C2-13",vendor: "Apple, Inc."},
  {max_prefix: "A4-D9-31",vendor: "Apple, Inc."},
  {max_prefix: "BC-FE-D9",vendor: "Apple, Inc."},
  {max_prefix: "80-82-23",vendor: "Apple, Inc."},
  {max_prefix: "70-D3-13",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "9C-1D-36",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "CC-BB-FE",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "38-B4-D3",vendor: "BSH Hausgeraete GmbH"},
  {max_prefix: "28-31-66",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "C0-40-04",vendor: "Medicaroid Corporation"},
  {max_prefix: "A4-ED-43",vendor: "IEEE Registration Authority"},
  {max_prefix: "30-0A-60",vendor: "IEEE Registration Authority"},
  {max_prefix: "80-D0-65",vendor: "CKS Corporation"},
  {max_prefix: "0C-7C-28",vendor: "Nokia Solutions and Networks GmbH & Co. KG"},
  {max_prefix: "68-43-D7",vendor: "Agilecom Photonics Solutions Guangdong Limited"},
  {max_prefix: "00-72-04",vendor: "Samsung Electronics Co., Ltd. ARTIK"},
  {max_prefix: "40-C8-1F",vendor: "Shenzhen Xinguodu Technology Co., Ltd."},
  {max_prefix: "14-59-C0",vendor: "NETGEAR"},
  {max_prefix: "CC-08-FB",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "BC-AF-91",vendor: "TE Connectivity Sensor Solutions"},
  {max_prefix: "F0-D7-DC",vendor: "Wesine (Wuhan) Technology Co., Ltd."},
  {max_prefix: "A8-10-87",vendor: "Texas Instruments"},
  {max_prefix: "94-29-8D",vendor: "Shanghai AdaptComm Technology Co., Ltd."},
  {max_prefix: "00-AA-6E",vendor: "Cisco Systems, Inc"},
  {max_prefix: "B8-6A-97",vendor: "Edgecore Networks Corporation"},
  {max_prefix: "C8-C2-F5",vendor: "FLEXTRONICS MANUFACTURING(ZHUHAI)CO.,LTD."},
  {max_prefix: "F0-58-49",vendor: "CareView Communications"},
  {max_prefix: "8C-FE-74",vendor: "Ruckus Wireless"},
  {max_prefix: "E4-34-93",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "34-29-12",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "00-0A-5E",vendor: "3COM"},
  {max_prefix: "00-10-5A",vendor: "3COM"},
  {max_prefix: "00-60-97",vendor: "3COM"},
  {max_prefix: "00-60-08",vendor: "3COM"},
  {max_prefix: "00-01-02",vendor: "3COM"},
  {max_prefix: "8C-8F-8B",vendor: "China Mobile Chongqing branch"},
  {max_prefix: "A0-28-33",vendor: "IEEE Registration Authority"},
  {max_prefix: "00-1B-6E",vendor: "Keysight Technologies, Inc."},
  {max_prefix: "00-04-0B",vendor: "3COM EUROPE LTD"},
  {max_prefix: "08-90-BA",vendor: "Danlaw Inc"},
  {max_prefix: "4C-36-4E",vendor: "Panasonic Connect Co., Ltd."},
  {max_prefix: "BC-A5-8B",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "80-CE-B9",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "D0-D3-FC",vendor: "Mios, Ltd."},
  {max_prefix: "6C-6C-D3",vendor: "Cisco Systems, Inc"},
  {max_prefix: "E0-49-ED",vendor: "Audeze LLC"},
  {max_prefix: "14-37-19",vendor: "PT Prakarsa Visi Valutama"},
  {max_prefix: "58-2F-40",vendor: "Nintendo Co.,Ltd"},
  {max_prefix: "94-A3-CA",vendor: "KonnectONE, LLC"},
  {max_prefix: "E8-5D-86",vendor: "CHANG YOW TECHNOLOGIES INTERNATIONAL CO.,LTD."},
  {max_prefix: "60-4B-AA",vendor: "Magic Leap, Inc."},
  {max_prefix: "4C-01-43",vendor: "eero inc."},
  {max_prefix: "A8-01-6D",vendor: "Aiwa Corporation"},
  {max_prefix: "04-40-A9",vendor: "New H3C Technologies Co., Ltd"},
  {max_prefix: "48-A4-72",vendor: "Intel Corporate"},
  {max_prefix: "B8-BE-F4",vendor: "devolo AG"},
  {max_prefix: "14-D1-69",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "10-62-E5",vendor: "Hewlett Packard"},
  {max_prefix: "00-20-B5",vendor: "YASKAWA ELECTRIC CORPORATION"},
  {max_prefix: "28-63-36",vendor: "Siemens AG"},
  {max_prefix: "04-C3-E6",vendor: "IEEE Registration Authority"},
  {max_prefix: "58-FD-BE",vendor: "Shenzhen Taikaida Technology Co., Ltd"},
  {max_prefix: "24-4C-E3",vendor: "Amazon Technologies Inc."},
  {max_prefix: "00-01-57",vendor: "SYSWAVE CO., LTD"},
  {max_prefix: "08-25-25",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "C4-95-00",vendor: "Amazon Technologies Inc."},
  {max_prefix: "68-DD-26",vendor: "Shanghai Focus Vision Security Technology Co.,Ltd"},
  {max_prefix: "E0-62-67",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "70-B7-AA",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "84-B3-1B",vendor: "Kinexon GmbH"},
  {max_prefix: "F8-27-2E",vendor: "Mercku"},
  {max_prefix: "F4-60-E2",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "E4-D1-24",vendor: " Mojo Networks, Inc."},
  {max_prefix: "00-13-A3",vendor: "Siemens Home & Office Comm. Devices"},
  {max_prefix: "F4-F1-97",vendor: "EMTAKE Inc"},
  {max_prefix: "6C-ED-51",vendor: "NEXCONTROL Co.,Ltd"},
  {max_prefix: "98-BB-99",vendor: "Phicomm (Sichuan) Co.,Ltd."},
  {max_prefix: "00-26-22",vendor: "COMPAL INFORMATION (KUNSHAN) CO., LTD."},
  {max_prefix: "9C-C9-50",vendor: "Baumer Holding"},
  {max_prefix: "F8-99-10",vendor: "Integrated Device Technology (Malaysia) Sdn. Bhd."},
  {max_prefix: "50-E0-EF",vendor: "Nokia"},
  {max_prefix: "C0-B6-F9",vendor: "Intel Corporate"},
  {max_prefix: "00-0E-EE",vendor: "Muco Industrie BV"},
  {max_prefix: "7C-1C-4E",vendor: "LG Innotek"},
  {max_prefix: "D8-B6-B7",vendor: "Comtrend Corporation"},
  {max_prefix: "28-66-E3",vendor: "AzureWave Technology Inc."},
  {max_prefix: "84-8A-8D",vendor: "Cisco Systems, Inc"},
  {max_prefix: "1C-C3-EB",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "60-F1-8A",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "8C-14-B4",vendor: "zte corporation"},
  {max_prefix: "3C-98-72",vendor: "Sercomm Corporation."},
  {max_prefix: "40-C3-C6",vendor: "SnapRoute"},
  {max_prefix: "50-4C-7E",vendor: "THE 41ST INSTITUTE OF CETC"},
  {max_prefix: "D0-1C-BB",vendor: "Beijing Ctimes Digital Technology Co., Ltd."},
  {max_prefix: "74-87-BB",vendor: "Ciena Corporation"},
  {max_prefix: "CC-50-E3",vendor: "Espressif Inc."},
  {max_prefix: "DC-E3-05",vendor: "AO"},
  {max_prefix: "A4-DA-32",vendor: "Texas Instruments"},
  {max_prefix: "78-04-73",vendor: "Texas Instruments"},
  {max_prefix: "2C-47-59",vendor: "Beijing MEGA preponderance Science & Technology Co. Ltd"},
  {max_prefix: "14-4F-8A",vendor: "Intel Corporate"},
  {max_prefix: "00-21-06",vendor: "RIM Testing Services"},
  {max_prefix: "A8-3E-0E",vendor: "HMD Global Oy"},
  {max_prefix: "10-C1-72",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "00-13-8A",vendor: "Qingdao GoerTek Technology Co., Ltd."},
  {max_prefix: "A8-30-AD",vendor: "WEIFANG GOERTEK ELECTRONICS CO.,LTD"},
  {max_prefix: "A4-15-66",vendor: "WEIFANG GOERTEK ELECTRONICS CO.,LTD"},
  {max_prefix: "1C-96-5A",vendor: "WEIFANG GOERTEK ELECTRONICS CO.,LTD"},
  {max_prefix: "40-1B-5F",vendor: "WEIFANG GOERTEK ELECTRONICS CO.,LTD"},
  {max_prefix: "00-15-1E",vendor: "ETHERNET Powerlink Standarization Group (EPSG)"},
  {max_prefix: "00-11-1E",vendor: "ETHERNET Powerlink Standarization Group (EPSG)"},
  {max_prefix: "00-40-9D",vendor: "DigiBoard"},
  {max_prefix: "DC-E0-EB",vendor: "Nanjing Aozheng Information Technology Co.Ltd"},
  {max_prefix: "BC-5F-F6",vendor: "MERCURY COMMUNICATION TECHNOLOGIES CO.,LTD."},
  {max_prefix: "5C-96-56",vendor: "AzureWave Technology Inc."},
  {max_prefix: "E0-60-66",vendor: "Sercomm Corporation."},
  {max_prefix: "C8-E7-D8",vendor: "MERCURY COMMUNICATION TECHNOLOGIES CO.,LTD."},
  {max_prefix: "60-D2-1C",vendor: "Sunnovo International Limited"},
  {max_prefix: "CC-51-B4",vendor: "Integrated Device Technology (Malaysia) Sdn. Bhd."},
  {max_prefix: "00-C3-F4",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "B8-8A-EC",vendor: "Nintendo Co.,Ltd"},
  {max_prefix: "AC-3B-77",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "78-72-5D",vendor: "Cisco Systems, Inc"},
  {max_prefix: "14-93-46",vendor: "PNI sensor corporation"},
  {max_prefix: "5C-CD-7C",vendor: "MEIZU Technology Co.,Ltd."},
  {max_prefix: "EC-8C-9A",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "B4-86-55",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "D0-D7-83",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "A8-E5-52",vendor: "JUWEL Aquarium AG & Co. KG"},
  {max_prefix: "FC-E6-6A",vendor: "Industrial Software Co"},
  {max_prefix: "78-36-CC",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "CC-C9-2C",vendor: "Schindler - PORT Technology"},
  {max_prefix: "00-1E-39",vendor: "Comsys Communication Ltd."},
  {max_prefix: "8C-CF-5C",vendor: "BEFEGA GmbH"},
  {max_prefix: "B4-6B-FC",vendor: "Intel Corporate"},
  {max_prefix: "B0-FC-0D",vendor: "Amazon Technologies Inc."},
  {max_prefix: "04-8A-E1",vendor: "FLEXTRONICS MANUFACTURING(ZHUHAI)CO.,LTD."},
  {max_prefix: "50-1D-93",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "C8-D7-79",vendor: "QING DAO HAIER TELECOM CO.,LTD."},
  {max_prefix: "70-C8-33",vendor: "Wirepas Oy"},
  {max_prefix: "0C-73-EB",vendor: "IEEE Registration Authority"},
  {max_prefix: "48-0B-B2",vendor: "IEEE Registration Authority"},
  {max_prefix: "CC-C0-79",vendor: "Murata Manufacturing Co., Ltd."},
  {max_prefix: "F0-9C-D7",vendor: "Guangzhou Blue Cheetah Intelligent Technology Co., Ltd."},
  {max_prefix: "BC-E1-43",vendor: "Apple, Inc."},
  {max_prefix: "10-B3-6F",vendor: "Bowei Technology Company Limited"},
  {max_prefix: "FC-9B-C6",vendor: "Sumavision Technologies Co.,Ltd"},
  {max_prefix: "C8-29-2A",vendor: "Barun Electronics"},
  {max_prefix: "00-80-BA",vendor: "SPECIALIX (ASIA) PTE, LTD"},
  {max_prefix: "F0-B5-D1",vendor: "Texas Instruments"},
  {max_prefix: "00-E0-00",vendor: "FUJITSU LIMITED"},
  {max_prefix: "90-84-8B",vendor: "HDR10+ Technologies, LLC"},
  {max_prefix: "E4-82-CC",vendor: "Jumptronic GmbH"},
  {max_prefix: "48-60-5F",vendor: "LG Electronics (Mobile Communications)"},
  {max_prefix: "28-16-A8",vendor: "Microsoft Corporation"},
  {max_prefix: "B0-41-6F",vendor: "Shenzhen Maxtang Computer Co.,Ltd"},
  {max_prefix: "0C-F5-A4",vendor: "Cisco Systems, Inc"},
  {max_prefix: "80-C7-C5",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "BC-AB-7C",vendor: "TRnP KOREA Co Ltd"},
  {max_prefix: "6C-38-38",vendor: "Marking System Technology Co., Ltd."},
  {max_prefix: "9C-2E-A1",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "0C-6A-BC",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "E0-19-D8",vendor: "BH TECHNOLOGIES"},
  {max_prefix: "30-D9-D9",vendor: "Apple, Inc."},
  {max_prefix: "60-30-D4",vendor: "Apple, Inc."},
  {max_prefix: "F8-95-EA",vendor: "Apple, Inc."},
  {max_prefix: "18-F1-D8",vendor: "Apple, Inc."},
  {max_prefix: "64-70-33",vendor: "Apple, Inc."},
  {max_prefix: "84-68-78",vendor: "Apple, Inc."},
  {max_prefix: "C8-D0-83",vendor: "Apple, Inc."},
  {max_prefix: "3C-CD-5D",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "7C-76-68",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "A4-38-CC",vendor: "Nintendo Co.,Ltd"},
  {max_prefix: "74-72-1E",vendor: "Edison Labs Inc."},
  {max_prefix: "78-0F-77",vendor: "HangZhou Gubei Electronics Technology Co.,Ltd"},
  {max_prefix: "8C-4C-DC",vendor: "PLANEX COMMUNICATIONS INC."},
  {max_prefix: "50-65-F3",vendor: "Hewlett Packard"},
  {max_prefix: "3C-95-09",vendor: "Liteon Technology Corporation"},
  {max_prefix: "58-21-E9",vendor: "TWPI"},
  {max_prefix: "30-FD-38",vendor: "Google, Inc."},
  {max_prefix: "64-CB-5D",vendor: "SIA TeleSet"},
  {max_prefix: "00-13-86",vendor: "ABB Inc/Totalflow"},
  {max_prefix: "00-3C-10",vendor: "Cisco Systems, Inc"},
  {max_prefix: "F0-41-C8",vendor: "IEEE Registration Authority"},
  {max_prefix: "CC-99-16",vendor: "Integrated Device Technology (Malaysia) Sdn. Bhd."},
  {max_prefix: "EC-7F-C6",vendor: "ECCEL CORPORATION SAS"},
  {max_prefix: "F0-E3-DC",vendor: "Tecon MT, LLC"},
  {max_prefix: "A8-DA-01",vendor: "Shenzhen NUOLIJIA Digital Technology Co.,Ltd"},
  {max_prefix: "88-E9-0F",vendor: "innomdlelab"},
  {max_prefix: "00-10-D8",vendor: "CALISTA"},
  {max_prefix: "00-21-94",vendor: "Ping Communication"},
  {max_prefix: "4C-AB-FC",vendor: "zte corporation"},
  {max_prefix: "7C-2A-31",vendor: "Intel Corporate"},
  {max_prefix: "0C-F3-46",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "5C-5A-EA",vendor: "FORD"},
  {max_prefix: "00-0B-7B",vendor: "Test-Um Inc."},
  {max_prefix: "BC-DD-C2",vendor: "Espressif Inc."},
  {max_prefix: "98-D8-63",vendor: "Shanghai High-Flying Electronics Technology Co., Ltd"},
  {max_prefix: "7C-FF-4D",vendor: "AVM Audiovisuelles Marketing und Computersysteme GmbH"},
  {max_prefix: "74-70-FD",vendor: "Intel Corporate"},
  {max_prefix: "C4-9F-4C",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "0C-70-4A",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "C8-8F-26",vendor: "Skyworth Digital Technology(Shenzhen) Co.,Ltd"},
  {max_prefix: "70-3A-73",vendor: "Shenzhen Sundray Technologies Company Limited"},
  {max_prefix: "10-F9-EB",vendor: "Industria Fueguina de Relojer\u00eda Electr\u00f3nica s.a."},
  {max_prefix: "80-AD-16",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "04-4E-AF",vendor: "LG Innotek"},
  {max_prefix: "00-30-74",vendor: "EQUIINET LTD."},
  {max_prefix: "B0-B3-AD",vendor: "HUMAX Co., Ltd."},
  {max_prefix: "40-BD-32",vendor: "Texas Instruments"},
  {max_prefix: "CC-8E-71",vendor: "Cisco Systems, Inc"},
  {max_prefix: "18-94-C6",vendor: "ShenZhen Chenyee Technology Co., Ltd."},
  {max_prefix: "70-D0-81",vendor: "Beijing Netpower Technologies Inc."},
  {max_prefix: "70-C9-4E",vendor: "Liteon Technology Corporation"},
  {max_prefix: "38-F5-54",vendor: "HISENSE ELECTRIC CO.,LTD"},
  {max_prefix: "18-A2-8A",vendor: "Essel-T Co., Ltd"},
  {max_prefix: "3C-E8-24",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "54-B7-E5",vendor: "Rayson Technology Co., Ltd."},
  {max_prefix: "94-63-72",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "BC-0F-A7",vendor: "Ouster"},
  {max_prefix: "20-36-5B",vendor: "Megafone Limited"},
  {max_prefix: "E8-DE-00",vendor: "ChongQing GuanFang Technology Co.,LTD"},
  {max_prefix: "FC-64-3A",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "F8-C1-20",vendor: "Xi'an Link-Science Technology Co.,Ltd"},
  {max_prefix: "A8-51-5B",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "B4-FB-F9",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "50-6F-77",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "0C-41-E9",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "00-19-36",vendor: "STERLITE OPTICAL TECHNOLOGIES LIMITED"},
  {max_prefix: "F0-C9-D1",vendor: "GD Midea Air-Conditioning Equipment Co.,Ltd."},
  {max_prefix: "B4-DE-31",vendor: "Cisco Systems, Inc"},
  {max_prefix: "A4-40-27",vendor: "zte corporation"},
  {max_prefix: "70-F2-20",vendor: "Actiontec Electronics, Inc"},
  {max_prefix: "1C-11-61",vendor: "Ciena Corporation"},
  {max_prefix: "B4-F7-A1",vendor: "LG Electronics (Mobile Communications)"},
  {max_prefix: "70-EF-00",vendor: "Apple, Inc."},
  {max_prefix: "28-ED-E0",vendor: "AMPAK Technology, Inc."},
  {max_prefix: "C8-77-65",vendor: "Tiesse SpA"},
  {max_prefix: "D0-81-7A",vendor: "Apple, Inc."},
  {max_prefix: "98-CA-33",vendor: "Apple, Inc."},
  {max_prefix: "68-AB-1E",vendor: "Apple, Inc."},
  {max_prefix: "34-5A-06",vendor: "SHARP Corporation"},
  {max_prefix: "BC-FF-EB",vendor: "Motorola Mobility LLC, a Lenovo Company"},
  {max_prefix: "2C-37-C5",vendor: "Qingdao Haier Intelligent Home Appliance Technology Co.,Ltd"},
  {max_prefix: "CC-40-D0",vendor: "NETGEAR"},
  {max_prefix: "7C-76-30",vendor: "Shenzhen YOUHUA Technology Co., Ltd"},
  {max_prefix: "98-22-EF",vendor: "Liteon Technology Corporation"},
  {max_prefix: "3C-FB-5C",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "74-40-BB",vendor: "Hon Hai Precision Ind. Co.,Ltd."},
  {max_prefix: "88-BD-45",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "54-FC-F0",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "30-6A-85",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "B8-9F-09",vendor: "Wistron Neweb Corporation"},
  {max_prefix: "04-02-CA",vendor: "Shenzhen Vtsonic Co.,ltd"},
  {max_prefix: "7C-76-35",vendor: "Intel Corporate"},
  {max_prefix: "4C-DD-31",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "78-80-38",vendor: "FUNAI ELECTRIC CO., LTD."},
  {max_prefix: "40-48-FD",vendor: "IEEE Registration Authority"},
  {max_prefix: "B8-07-16",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "1C-EE-C9",vendor: "Elo touch solutions"},
  {max_prefix: "00-18-62",vendor: "Seagate Technology"},
  {max_prefix: "00-0C-50",vendor: "Seagate Technology"},
  {max_prefix: "F0-45-DA",vendor: "Texas Instruments"},
  {max_prefix: "A8-EE-C6",vendor: "Muuselabs NV/SA"},
  {max_prefix: "E4-F0-42",vendor: "Google, Inc."},
  {max_prefix: "20-B3-99",vendor: "Enterasys"},
  {max_prefix: "CC-2D-21",vendor: "Tenda Technology Co.,Ltd.Dongguan branch"},
  {max_prefix: "C8-DE-C9",vendor: "Coriant"},
  {max_prefix: "D8-96-E0",vendor: "Alibaba Cloud Computing Ltd."},
  {max_prefix: "20-78-52",vendor: "Nokia Solutions and Networks GmbH & Co. KG"},
  {max_prefix: "F4-17-B8",vendor: "AirTies Wireless Networks"},
  {max_prefix: "38-F7-3D",vendor: "Amazon Technologies Inc."},
  {max_prefix: "00-40-97",vendor: "DATEX DIVISION OF"},
  {max_prefix: "9C-4F-CF",vendor: "TCT mobile ltd"},
  {max_prefix: "34-2A-F1",vendor: "Texas Instruments"},
  {max_prefix: "44-D5-A5",vendor: "AddOn Computer"},
  {max_prefix: "58-C1-7A",vendor: "Cambium Networks Limited"},
  {max_prefix: "E0-AA-DB",vendor: "Nanjing PANENG Technology Development Co.,Ltd"},
  {max_prefix: "00-05-FF",vendor: "SNS Solutions, Inc."},
  {max_prefix: "F8-7B-20",vendor: "Cisco Systems, Inc"},
  {max_prefix: "1C-33-0E",vendor: "PernixData"},
  {max_prefix: "B8-F7-4A",vendor: "RCNTEC"},
  {max_prefix: "EC-F4-51",vendor: "Arcadyan Corporation"},
  {max_prefix: "64-51-06",vendor: "Hewlett Packard"},
  {max_prefix: "0C-15-39",vendor: "Apple, Inc."},
  {max_prefix: "0C-61-11",vendor: "Anda Technologies SAC"},
  {max_prefix: "58-12-43",vendor: "AcSiP Technology Corp."},
  {max_prefix: "00-22-C4",vendor: "epro GmbH"},
  {max_prefix: "00-21-28",vendor: "Oracle Corporation"},
  {max_prefix: "00-1C-73",vendor: "Arista Networks"},
  {max_prefix: "2C-8A-72",vendor: "HTC Corporation"},
  {max_prefix: "6C-56-97",vendor: "Amazon Technologies Inc."},
  {max_prefix: "38-01-9F",vendor: "SHENZHEN FAST TECHNOLOGIES CO.,LTD"},
  {max_prefix: "24-5C-CB",vendor: "AXIe Consortium, Inc."},
  {max_prefix: "EC-FA-F4",vendor: "SenRa Tech Pvt. Ltd"},
  {max_prefix: "38-AD-8E",vendor: "New H3C Technologies Co., Ltd"},
  {max_prefix: "34-D0-B8",vendor: "IEEE Registration Authority"},
  {max_prefix: "00-BE-9E",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "54-C5-7A",vendor: "Sunnovo International Limited"},
  {max_prefix: "D8-8F-76",vendor: "Apple, Inc."},
  {max_prefix: "40-9C-28",vendor: "Apple, Inc."},
  {max_prefix: "78-2D-7E",vendor: "TRENDnet, Inc."},
  {max_prefix: "74-1A-E0",vendor: "IEEE Registration Authority"},
  {max_prefix: "B0-6E-BF",vendor: "ASUSTek COMPUTER INC."},
  {max_prefix: "24-B2-09",vendor: "Avaya Inc"},
  {max_prefix: "54-DF-24",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "AC-1D-DF",vendor: "IEEE Registration Authority"},
  {max_prefix: "E8-D8-19",vendor: "AzureWave Technology Inc."},
  {max_prefix: "60-9B-C8",vendor: "Hipad Intelligent Technology Co., Ltd."},
  {max_prefix: "40-6A-8E",vendor: "Hangzhou Puwell OE Tech Ltd."},
  {max_prefix: "1C-0F-AF",vendor: "Lucid Vision Labs"},
  {max_prefix: "BC-54-51",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "74-86-0B",vendor: "Cisco Systems, Inc"},
  {max_prefix: "18-2D-98",vendor: "Jinwoo Industrial system"},
  {max_prefix: "88-B4-A6",vendor: "Motorola Mobility LLC, a Lenovo Company"},
  {max_prefix: "28-CF-08",vendor: "ESSYS"},
  {max_prefix: "BC-90-3A",vendor: "Robert Bosch GmbH"},
  {max_prefix: "D0-B1-28",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "FC-65-DE",vendor: "Amazon Technologies Inc."},
  {max_prefix: "F4-49-EF",vendor: "EMSTONE"},
  {max_prefix: "EC-51-BC",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "F0-79-E8",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "58-38-79",vendor: "RICOH COMPANY, LTD."},
  {max_prefix: "F4-4C-70",vendor: "Skyworth Digital Technology(Shenzhen) Co.,Ltd"},
  {max_prefix: "D8-A5-34",vendor: "Spectronix Corporation"},
  {max_prefix: "00-A0-96",vendor: "MITSUMI ELECTRIC CO.,LTD."},
  {max_prefix: "78-61-7C",vendor: "MITSUMI ELECTRIC CO.,LTD."},
  {max_prefix: "60-18-03",vendor: "Daikin Air-conditioning (Shanghai) Co., Ltd."},
  {max_prefix: "78-32-1B",vendor: "D-Link International"},
  {max_prefix: "94-0E-6B",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "08-66-1F",vendor: "Palo Alto Networks"},
  {max_prefix: "74-EA-C8",vendor: "New H3C Technologies Co., Ltd"},
  {max_prefix: "B4-D6-4E",vendor: "Caldero Limited"},
  {max_prefix: "F8-9D-BB",vendor: "Tintri"},
  {max_prefix: "C4-F3-12",vendor: "Texas Instruments"},
  {max_prefix: "90-4E-91",vendor: "IEEE Registration Authority"},
  {max_prefix: "5C-E8-B7",vendor: "Oraimo Technology Limited"},
  {max_prefix: "CC-66-B2",vendor: "Nokia"},
  {max_prefix: "D8-A0-1D",vendor: "Espressif Inc."},
  {max_prefix: "DC-0C-2D",vendor: "WEIFANG GOERTEK ELECTRONICS CO.,LTD"},
  {max_prefix: "8C-FE-B4",vendor: "VSOONTECH ELECTRONICS CO., LIMITED"},
  {max_prefix: "68-EC-C5",vendor: "Intel Corporate"},
  {max_prefix: "64-FB-50",vendor: "RoomReady/Zdi, Inc."},
  {max_prefix: "34-29-8F",vendor: "IEEE Registration Authority"},
  {max_prefix: "5C-EA-1D",vendor: "Hon Hai Precision Ind. Co.,Ltd."},
  {max_prefix: "18-14-56",vendor: "Nokia Corporation"},
  {max_prefix: "58-B4-2D",vendor: "YSTen Technology Co.,Ltd"},
  {max_prefix: "3C-11-B2",vendor: "Fraunhofer FIT"},
  {max_prefix: "10-4B-46",vendor: "Mitsubishi Electric Corporation"},
  {max_prefix: "38-E2-DD",vendor: "zte corporation"},
  {max_prefix: "88-5D-FB",vendor: "zte corporation"},
  {max_prefix: "78-CA-04",vendor: "Nokia Corporation"},
  {max_prefix: "B0-09-DA",vendor: "Ring Solutions"},
  {max_prefix: "00-05-4F",vendor: "Garmin International"},
  {max_prefix: "58-E2-8F",vendor: "Apple, Inc."},
  {max_prefix: "78-7B-8A",vendor: "Apple, Inc."},
  {max_prefix: "E0-48-D3",vendor: "MOBIWIRE MOBILES (NINGBO) CO.,LTD"},
  {max_prefix: "00-17-C8",vendor: "KYOCERA Display Corporation"},
  {max_prefix: "78-BC-1A",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-0E-59",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "48-BC-A6",vendor: "\u200bASUNG TECHNO CO.,Ltd"},
  {max_prefix: "00-5C-86",vendor: "SHENZHEN FAST TECHNOLOGIES CO.,LTD"},
  {max_prefix: "28-8C-B8",vendor: "zte corporation"},
  {max_prefix: "B8-DB-1C",vendor: "Integrated Device Technology (Malaysia) Sdn. Bhd."},
  {max_prefix: "3C-10-E6",vendor: "PHAZR Inc."},
  {max_prefix: "90-45-06",vendor: "Tokyo Boeki Medisys Inc."},
  {max_prefix: "00-21-A1",vendor: "Cisco Systems, Inc"},
  {max_prefix: "5C-54-6D",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "30-05-3F",vendor: "JTI Co.,Ltd."},
  {max_prefix: "F4-93-9F",vendor: "Hon Hai Precision Industry Co., Ltd."},
  {max_prefix: "00-07-26",vendor: "SHENZHEN GONGJIN ELECTRONICS CO.,LT"},
  {max_prefix: "FC-8B-97",vendor: "SHENZHEN GONGJIN ELECTRONICS CO.,LT"},
  {max_prefix: "2C-AB-25",vendor: "SHENZHEN GONGJIN ELECTRONICS CO.,LT"},
  {max_prefix: "1C-A5-32",vendor: "SHENZHEN GONGJIN ELECTRONICS CO.,LT"},
  {max_prefix: "78-8C-4D",vendor: "Indyme Solutions, LLC"},
  {max_prefix: "24-B2-DE",vendor: "Espressif Inc."},
  {max_prefix: "EC-8A-C7",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "88-36-5F",vendor: "LG Electronics (Mobile Communications)"},
  {max_prefix: "FC-7F-56",vendor: "CoSyst Control Systems GmbH"},
  {max_prefix: "2C-40-53",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "78-81-02",vendor: "Sercomm Corporation."},
  {max_prefix: "84-AA-9C",vendor: "MitraStar Technology Corp."},
  {max_prefix: "28-80-A2",vendor: "Novatel Wireless Solutions, Inc."},
  {max_prefix: "84-A1-D1",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "F0-EF-D2",vendor: "TF PAYMENT SERVICE CO., LTD"},
  {max_prefix: "DC-EB-53",vendor: "Wuhan QianXiao Elecronic Technology CO.,LTD"},
  {max_prefix: "7C-BA-CC",vendor: "IEEE Registration Authority"},
  {max_prefix: "54-02-37",vendor: "Teltronic AG"},
  {max_prefix: "00-1F-92",vendor: "Motorola Solutions Inc."},
  {max_prefix: "00-0C-03",vendor: "HDMI Licensing, LLC"},
  {max_prefix: "0C-8F-FF",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "54-B1-21",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "A8-0C-63",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "5C-C3-07",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "4C-B0-08",vendor: "Shenzhen Gwelltimes Technology Co.,Ltd"},
  {max_prefix: "E8-6F-F2",vendor: "Actiontec Electronics, Inc"},
  {max_prefix: "00-50-18",vendor: "AMIT, Inc."},
  {max_prefix: "70-DE-F9",vendor: "FAI WAH INTERNATIONAL (HONG KONG) LIMITED"},
  {max_prefix: "40-42-29",vendor: "Layer3TV, Inc"},
  {max_prefix: "10-1B-54",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "C4-01-7C",vendor: "Ruckus Wireless"},
  {max_prefix: "00-25-C4",vendor: "Ruckus Wireless"},
  {max_prefix: "C0-C5-20",vendor: "Ruckus Wireless"},
  {max_prefix: "FC-2F-6B",vendor: "Everspin Technologies, Inc."},
  {max_prefix: "28-7B-09",vendor: "zte corporation"},
  {max_prefix: "E0-10-7F",vendor: "Ruckus Wireless"},
  {max_prefix: "B0-EA-BC",vendor: "ASKEY COMPUTER CORP"},
  {max_prefix: "94-C6-91",vendor: "EliteGroup Computer Systems Co., LTD"},
  {max_prefix: "3C-F5-91",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "60-21-01",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "7C-EB-7F",vendor: "Dmet Products Corp."},
  {max_prefix: "8C-85-80",vendor: "Smart Innovation LLC"},
  {max_prefix: "78-B2-8D",vendor: "Beijing Tengling Technology CO.Ltd"},
  {max_prefix: "F8-1D-90",vendor: "Solidwintech"},
  {max_prefix: "A0-6A-44",vendor: "Vizio, Inc"},
  {max_prefix: "DC-BE-7A",vendor: "Zhejiang Nurotron Biotechnology Co."},
  {max_prefix: "34-38-B7",vendor: "HUMAX Co., Ltd."},
  {max_prefix: "CC-06-77",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "78-45-01",vendor: "Biamp Systems"},
  {max_prefix: "88-B1-11",vendor: "Intel Corporate"},
  {max_prefix: "54-D7-51",vendor: "Proximus"},
  {max_prefix: "14-78-0B",vendor: "Varex Imaging Deutschland AG"},
  {max_prefix: "AC-AF-B9",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "14-5E-45",vendor: "Bamboo Systems Group"},
  {max_prefix: "8C-39-5C",vendor: "Bit4id Srl"},
  {max_prefix: "00-80-C2",vendor: "IEEE 802.1 Chair"},
  {max_prefix: "EC-F3-42",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "04-1B-6D",vendor: "LG Electronics (Mobile Communications)"},
  {max_prefix: "F4-41-56",vendor: "Arrikto Inc."},
  {max_prefix: "68-8D-B6",vendor: "AETEK INC."},
  {max_prefix: "98-6C-5C",vendor: "Jiangxi Gosun Guard Security Co.,Ltd"},
  {max_prefix: "24-79-2A",vendor: "Ruckus Wireless"},
  {max_prefix: "00-F8-2C",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-C1-B1",vendor: "Cisco Systems, Inc"},
  {max_prefix: "F4-FC-B1",vendor: "JJ Corp"},
  {max_prefix: "50-FF-20",vendor: "Keenetic Limited"},
  {max_prefix: "D4-25-8B",vendor: "Intel Corporate"},
  {max_prefix: "30-9C-23",vendor: "Micro-Star INTL CO., LTD."},
  {max_prefix: "70-D9-23",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "B8-3A-08",vendor: "Tenda Technology Co.,Ltd.Dongguan branch"},
  {max_prefix: "50-D3-7F",vendor: "Yu Fly Mikly Way Science and Technology Co., Ltd."},
  {max_prefix: "D8-C8-E9",vendor: "Phicomm (Shanghai) Co., Ltd."},
  {max_prefix: "7C-B9-60",vendor: "Shanghai X-Cheng telecom LTD"},
  {max_prefix: "B0-3D-96",vendor: "Vision Valley FZ LLC"},
  {max_prefix: "F8-94-C2",vendor: "Intel Corporate"},
  {max_prefix: "28-B4-48",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "70-D3-79",vendor: "Cisco Systems, Inc"},
  {max_prefix: "70-DB-98",vendor: "Cisco Systems, Inc"},
  {max_prefix: "10-05-01",vendor: "PEGATRON CORPORATION"},
  {max_prefix: "30-D3-86",vendor: "zte corporation"},
  {max_prefix: "18-12-12",vendor: "Cepton Technologies"},
  {max_prefix: "A4-9B-F5",vendor: "Hybridserver Tec GmbH"},
  {max_prefix: "F4-70-AB",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "9C-C8-AE",vendor: "Becton, Dickinson  and Company"},
  {max_prefix: "B0-35-9F",vendor: "Intel Corporate"},
  {max_prefix: "C0-D9-62",vendor: "ASKEY COMPUTER CORP"},
  {max_prefix: "F8-0B-CB",vendor: "Cisco Systems, Inc"},
  {max_prefix: "B4-7C-9C",vendor: "Amazon Technologies Inc."},
  {max_prefix: "08-02-8E",vendor: "NETGEAR"},
  {max_prefix: "2C-5A-0F",vendor: "Cisco Systems, Inc"},
  {max_prefix: "2C-31-24",vendor: "Cisco Systems, Inc"},
  {max_prefix: "50-32-37",vendor: "Apple, Inc."},
  {max_prefix: "B0-48-1A",vendor: "Apple, Inc."},
  {max_prefix: "B4-9C-DF",vendor: "Apple, Inc."},
  {max_prefix: "48-BF-6B",vendor: "Apple, Inc."},
  {max_prefix: "2C-FA-A2",vendor: "Alcatel-Lucent Enterprise"},
  {max_prefix: "E8-E7-32",vendor: "Alcatel-Lucent Enterprise"},
  {max_prefix: "90-06-28",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "50-3D-A1",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "F0-97-E5",vendor: "TAMIO, INC"},
  {max_prefix: "4C-1A-3D",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "1C-A0-D3",vendor: "IEEE Registration Authority"},
  {max_prefix: "7C-26-64",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "B4-A9-FE",vendor: "GHIA Technology (Shenzhen) LTD"},
  {max_prefix: "58-82-1D",vendor: "H. Schom\u00e4cker GmbH"},
  {max_prefix: "D8-A1-05",vendor: "Syslane, Co., Ltd."},
  {max_prefix: "9C-84-BF",vendor: "Apple, Inc."},
  {max_prefix: "BC-A0-42",vendor: "SHANGHAI FLYCO ELECTRICAL APPLIANCE CO.,LTD"},
  {max_prefix: "9C-FC-D1",vendor: "Aetheris Technology (Shanghai) Co., Ltd."},
  {max_prefix: "3C-05-18",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "AC-6B-0F",vendor: "CADENCE DESIGN SYSTEMS INC"},
  {max_prefix: "7C-38-66",vendor: "Texas Instruments"},
  {max_prefix: "0C-61-CF",vendor: "Texas Instruments"},
  {max_prefix: "9C-1D-58",vendor: "Texas Instruments"},
  {max_prefix: "00-11-1B",vendor: "Targa Systems Div L-3 Communications"},
  {max_prefix: "CC-CE-1E",vendor: "AVM Audiovisuelles Marketing und Computersysteme GmbH"},
  {max_prefix: "0C-F4-D5",vendor: "Ruckus Wireless"},
  {max_prefix: "BC-3F-8F",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "14-30-04",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "6C-75-0D",vendor: "WiFiSONG"},
  {max_prefix: "38-05-AC",vendor: "Piller Group GmbH"},
  {max_prefix: "34-6E-9D",vendor: "Ericsson AB"},
  {max_prefix: "38-AA-3C",vendor: "SAMSUNG ELECTRO MECHANICS CO., LTD."},
  {max_prefix: "F4-DC-41",vendor: "YOUNGZONE CULTURE (SHANGHAI) CORP"},
  {max_prefix: "68-54-C1",vendor: "ColorTokens, Inc."},
  {max_prefix: "00-0F-4F",vendor: "PCS Systemtechnik GmbH"},
  {max_prefix: "50-A4-D0",vendor: "IEEE Registration Authority"},
  {max_prefix: "80-00-10",vendor: "AT&T"},
  {max_prefix: "0C-3C-CD",vendor: "Universal Global Scientific Industrial Co., Ltd."},
  {max_prefix: "14-AB-C5",vendor: "Intel Corporate"},
  {max_prefix: "7C-C6-C4",vendor: "Kolff Computer Supplies b.v."},
  {max_prefix: "C4-83-6F",vendor: "Ciena Corporation"},
  {max_prefix: "A8-0C-CA",vendor: "Shenzhen Sundray Technologies Company Limited"},
  {max_prefix: "50-D2-13",vendor: "CviLux Corporation"},
  {max_prefix: "00-1E-29",vendor: "Hypertherm Inc"},
  {max_prefix: "50-04-B8",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "00-03-02",vendor: "Charles Industries, Ltd."},
  {max_prefix: "00-24-F1",vendor: "Shenzhen Fanhai Sanjiang Electronics Co., Ltd."},
  {max_prefix: "14-2F-FD",vendor: "LT SECURITY INC"},
  {max_prefix: "C8-91-F9",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "9C-50-EE",vendor: "Cambridge Industries(Group) Co.,Ltd."},
  {max_prefix: "40-ED-98",vendor: "IEEE Registration Authority"},
  {max_prefix: "78-F2-9E",vendor: "PEGATRON CORPORATION"},
  {max_prefix: "64-77-7D",vendor: "Hitron Technologies. Inc"},
  {max_prefix: "60-D2-62",vendor: "Tzukuri Pty Ltd"},
  {max_prefix: "84-04-D2",vendor: "Kirale Technologies SL"},
  {max_prefix: "AC-DC-E5",vendor: "Procter & Gamble Company"},
  {max_prefix: "00-B3-62",vendor: "Apple, Inc."},
  {max_prefix: "E4-E4-AB",vendor: "Apple, Inc."},
  {max_prefix: "00-D0-B2",vendor: "Xiotech Corporation"},
  {max_prefix: "5C-FF-35",vendor: "Wistron Corporation"},
  {max_prefix: "64-EB-8C",vendor: "Seiko Epson Corporation"},
  {max_prefix: "F4-8C-50",vendor: "Intel Corporate"},
  {max_prefix: "00-13-51",vendor: "Niles Audio Corporation"},
  {max_prefix: "00-03-20",vendor: "Xpeed, Inc."},
  {max_prefix: "54-FA-96",vendor: "Nokia Solutions and Networks GmbH & Co. KG"},
  {max_prefix: "60-33-4B",vendor: "Apple, Inc."},
  {max_prefix: "DC-D2-55",vendor: "Kinpo Electronics, Inc."},
  {max_prefix: "A0-2C-36",vendor: "FN-LINK TECHNOLOGY LIMITED"},
  {max_prefix: "38-AF-D7",vendor: "FUJITSU LIMITED"},
  {max_prefix: "28-99-3A",vendor: "Arista Networks"},
  {max_prefix: "AC-1F-6B",vendor: "Super Micro Computer, Inc."},
  {max_prefix: "50-8A-0F",vendor: "SHENZHEN FISE TECHNOLOGY HOLDING CO.,LTD."},
  {max_prefix: "7C-CB-E2",vendor: "IEEE Registration Authority"},
  {max_prefix: "A8-A5-E2",vendor: "MSF-Vathauer Antriebstechnik GmbH & Co KG"},
  {max_prefix: "E0-50-8B",vendor: "Zhejiang Dahua Technology Co., Ltd."},
  {max_prefix: "9C-1E-95",vendor: "Actiontec Electronics, Inc"},
  {max_prefix: "64-DB-43",vendor: "Motorola (Wuhan) Mobility Technologies Communication Co., Ltd."},
  {max_prefix: "60-42-7F",vendor: "SHENZHEN CHUANGWEI-RGB ELECTRONICS CO.,LTD"},
  {max_prefix: "BC-A8-A6",vendor: "Intel Corporate"},
  {max_prefix: "68-AF-13",vendor: "Futura Mobility"},
  {max_prefix: "68-1A-B2",vendor: "zte corporation"},
  {max_prefix: "7C-EB-AE",vendor: "Ridgeline Instruments"},
  {max_prefix: "0C-49-33",vendor: "Sichuan Jiuzhou Electronic Technology Co., Ltd."},
  {max_prefix: "00-00-64",vendor: "Yokogawa Digital Computer Corporation"},
  {max_prefix: "E8-9E-B4",vendor: "Hon Hai Precision Ind. Co.,Ltd."},
  {max_prefix: "D4-6A-6A",vendor: "Hon Hai Precision Ind. Co.,Ltd."},
  {max_prefix: "98-FD-74",vendor: "ACT.CO.LTD"},
  {max_prefix: "74-FF-4C",vendor: "Skyworth Digital Technology(Shenzhen) Co.,Ltd"},
  {max_prefix: "00-25-90",vendor: "Super Micro Computer, Inc."},
  {max_prefix: "D0-F7-3B",vendor: "Helmut Mauell GmbH Werk Weida"},
  {max_prefix: "18-06-75",vendor: "Dilax Intelcom GmbH"},
  {max_prefix: "00-0F-C2",vendor: "Uniwell Corporation"},
  {max_prefix: "CC-C5-EF",vendor: "Co-Comm Servicios Telecomunicaciones S.L."},
  {max_prefix: "90-02-A9",vendor: "Zhejiang Dahua Technology Co., Ltd."},
  {max_prefix: "C0-28-8D",vendor: "Logitech, Inc"},
  {max_prefix: "6C-EC-5A",vendor: "Hon Hai Precision Ind. CO.,Ltd."},
  {max_prefix: "44-C3-46",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "30-74-96",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "70-8A-09",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "F4-F5-24",vendor: "Motorola Mobility LLC, a Lenovo Company"},
  {max_prefix: "00-BB-C1",vendor: "CANON INC."},
  {max_prefix: "00-13-A5",vendor: "General Solutions, LTD."},
  {max_prefix: "00-0B-2E",vendor: "Cal-Comp Electronics & Communications Company Ltd."},
  {max_prefix: "48-65-EE",vendor: "IEEE Registration Authority"},
  {max_prefix: "24-C1-BD",vendor: "CRRC DALIAN R&D CO.,LTD."},
  {max_prefix: "00-A2-EE",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-59-DC",vendor: "Cisco Systems, Inc"},
  {max_prefix: "C8-D3-FF",vendor: "Hewlett Packard"},
  {max_prefix: "C4-BE-84",vendor: "Texas Instruments"},
  {max_prefix: "50-98-F3",vendor: "Rheem Australia Pty Ltd"},
  {max_prefix: "50-6B-8D",vendor: "Nutanix"},
  {max_prefix: "00-38-DF",vendor: "Cisco Systems, Inc"},
  {max_prefix: "F4-CA-E5",vendor: "FREEBOX SAS"},
  {max_prefix: "90-00-4E",vendor: "Hon Hai Precision Ind. Co.,Ltd."},
  {max_prefix: "40-F4-13",vendor: "Rubezh"},
  {max_prefix: "E0-0D-B9",vendor: "Cree, Inc."},
  {max_prefix: "24-88-94",vendor: "shenzhen lensun Communication Technology LTD"},
  {max_prefix: "48-DA-96",vendor: "Eddy Smart Home Solutions Inc."},
  {max_prefix: "F0-74-E4",vendor: "Thundercomm Technology Co., Ltd"},
  {max_prefix: "A0-72-2C",vendor: "HUMAX Co., Ltd."},
  {max_prefix: "B0-4B-BF",vendor: "PT HAN SUNG ELECTORONICS INDONESIA"},
  {max_prefix: "CC-2D-83",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "00-15-FF",vendor: "Novatel Wireless Solutions, Inc."},
  {max_prefix: "B4-D1-35",vendor: "Cloudistics"},
  {max_prefix: "EC-10-7B",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "1C-23-2C",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "78-88-8A",vendor: "CDR Sp. z o.o. Sp. k."},
  {max_prefix: "9C-3D-CF",vendor: "NETGEAR"},
  {max_prefix: "D4-6E-0E",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "88-36-6C",vendor: "EFM Networks"},
  {max_prefix: "FC-D8-48",vendor: "Apple, Inc."},
  {max_prefix: "FC-83-C6",vendor: "N-Radio Technologies Co., Ltd."},
  {max_prefix: "DC-0D-30",vendor: "Shenzhen Feasycom Technology Co., Ltd."},
  {max_prefix: "F0-AC-D7",vendor: "IEEE Registration Authority"},
  {max_prefix: "94-95-A0",vendor: "Google, Inc."},
  {max_prefix: "00-A6-CA",vendor: "Cisco Systems, Inc"},
  {max_prefix: "D8-45-2B",vendor: "Integrated Device Technology (Malaysia) Sdn. Bhd."},
  {max_prefix: "E4-18-6B",vendor: "Zyxel Communications Corporation"},
  {max_prefix: "C8-3D-D4",vendor: "CyberTAN Technology Inc."},
  {max_prefix: "E0-B9-4D",vendor: "SHENZHEN BILIAN ELECTRONIC CO.\uff0cLTD"},
  {max_prefix: "38-D5-47",vendor: "ASUSTek COMPUTER INC."},
  {max_prefix: "D4-1D-71",vendor: "Palo Alto Networks"},
  {max_prefix: "00-87-31",vendor: "Cisco Systems, Inc"},
  {max_prefix: "88-DE-A9",vendor: "Roku, Inc."},
  {max_prefix: "F0-2F-A7",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "18-DE-D7",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "60-A1-0A",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "8C-71-F8",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "CC-05-1B",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "8C-77-12",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "94-63-D1",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "1C-9D-3E",vendor: "Integrated Device Technology (Malaysia) Sdn. Bhd."},
  {max_prefix: "00-21-D2",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "00-21-D1",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "00-1F-CC",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "A4-29-83",vendor: "Boeing Defence Australia"},
  {max_prefix: "EC-88-92",vendor: "Motorola Mobility LLC, a Lenovo Company"},
  {max_prefix: "00-4A-77",vendor: "zte corporation"},
  {max_prefix: "2C-3F-0B",vendor: "Cisco Meraki"},
  {max_prefix: "5C-49-7D",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "98-23-4E",vendor: "Micromedia AG"},
  {max_prefix: "74-87-A9",vendor: "OCT Technology Co., Ltd."},
  {max_prefix: "50-3F-98",vendor: "CMITECH"},
  {max_prefix: "78-20-79",vendor: "ID Tech"},
  {max_prefix: "58-94-CF",vendor: "Vertex Standard LMR, Inc."},
  {max_prefix: "20-F8-5E",vendor: "Delta Electronics"},
  {max_prefix: "00-23-E4",vendor: "IPnect co. ltd."},
  {max_prefix: "70-D4-F2",vendor: "RIM"},
  {max_prefix: "E4-D5-3D",vendor: "Hon Hai Precision Ind. Co.,Ltd."},
  {max_prefix: "C0-14-3D",vendor: "Hon Hai Precision Ind. Co.,Ltd."},
  {max_prefix: "C0-18-85",vendor: "Hon Hai Precision Ind. Co.,Ltd."},
  {max_prefix: "0C-60-76",vendor: "Hon Hai Precision Ind. Co.,Ltd."},
  {max_prefix: "0C-EE-E6",vendor: "Hon Hai Precision Ind. Co.,Ltd."},
  {max_prefix: "08-D4-2B",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "78-9E-D0",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "B0-C4-E7",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "C8-14-79",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "1C-AF-05",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "24-C6-96",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "94-D7-71",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "E8-4E-84",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "B0-DF-3A",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "80-57-19",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "54-9B-12",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "FC-A1-3E",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "A0-07-98",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "00-1F-CD",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "38-EC-E4",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "78-25-AD",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "EC-E0-9B",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "00-16-32",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "00-16-DB",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "00-1E-E2",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "00-24-90",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "00-23-D7",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "94-51-03",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "5C-E8-EB",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "BC-20-A4",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "E4-E0-C5",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "20-D5-BF",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "34-BE-00",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "78-52-1A",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "78-D6-F0",vendor: "SAMSUNG ELECTRO MECHANICS CO., LTD."},
  {max_prefix: "04-FE-A1",vendor: "Fihonest communication co.,Ltd"},
  {max_prefix: "1C-25-E1",vendor: "China Mobile IOT Company Limited"},
  {max_prefix: "C0-F6-36",vendor: "Hangzhou Kuaiyue Technologies, Ltd."},
  {max_prefix: "7C-11-CB",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "00-1A-22",vendor: "eQ-3 Entwicklung GmbH"},
  {max_prefix: "C0-BD-D1",vendor: "SAMSUNG ELECTRO-MECHANICS(THAILAND)"},
  {max_prefix: "B4-79-A7",vendor: "SAMSUNG ELECTRO-MECHANICS(THAILAND)"},
  {max_prefix: "24-0D-C2",vendor: "TCT mobile ltd"},
  {max_prefix: "20-BB-C6",vendor: "Jabil Circuit Hungary Ltd."},
  {max_prefix: "EC-8C-A2",vendor: "Ruckus Wireless"},
  {max_prefix: "AC-48-2D",vendor: "Ralinwi Nanjing Electronic Technology Co., Ltd."},
  {max_prefix: "00-54-9F",vendor: "Avaya Inc"},
  {max_prefix: "04-9F-CA",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "50-01-6B",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "54-6C-0E",vendor: "Texas Instruments"},
  {max_prefix: "EC-8E-AE",vendor: "Nagravision SA"},
  {max_prefix: "00-90-58",vendor: "Ultra Electronics Command & Control Systems"},
  {max_prefix: "00-1C-FD",vendor: "Universal Electronics, Inc."},
  {max_prefix: "B8-00-18",vendor: "Htel"},
  {max_prefix: "A0-43-DB",vendor: "Sitael S.p.A."},
  {max_prefix: "E0-E7-BB",vendor: "Nureva, Inc."},
  {max_prefix: "74-72-B0",vendor: "Guangzhou Shiyuan Electronics Co., Ltd."},
  {max_prefix: "DC-1A-01",vendor: "Ecoliv Technology ( Shenzhen ) Ltd."},
  {max_prefix: "00-10-87",vendor: "XSTREAMIS PLC"},
  {max_prefix: "00-B0-B3",vendor: "XSTREAMIS PLC"},
  {max_prefix: "00-23-47",vendor: "ProCurve Networking by HP"},
  {max_prefix: "00-24-A8",vendor: "ProCurve Networking by HP"},
  {max_prefix: "C0-91-34",vendor: "ProCurve Networking by HP"},
  {max_prefix: "00-1C-EF",vendor: "Primax Electronics Ltd."},
  {max_prefix: "00-02-76",vendor: "Primax Electronics Ltd."},
  {max_prefix: "4C-B2-1C",vendor: "Maxphotonics Co.,Ltd"},
  {max_prefix: "20-5E-F7",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "14-1F-78",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "D8-47-10",vendor: "Sichuan Changhong Electric Ltd."},
  {max_prefix: "00-19-72",vendor: "Plexus (Xiamen) Co.,ltd."},
  {max_prefix: "08-00-87",vendor: "Xyplex, Inc."},
  {max_prefix: "00-23-63",vendor: "Zhuhai Raysharp Technology Co.,Ltd"},
  {max_prefix: "00-00-1B",vendor: "Novell, Inc."},
  {max_prefix: "00-14-0E",vendor: "Nortel Networks"},
  {max_prefix: "00-1E-1F",vendor: "Nortel Networks"},
  {max_prefix: "00-19-69",vendor: "Nortel Networks"},
  {max_prefix: "00-18-B0",vendor: "Nortel Networks"},
  {max_prefix: "00-16-CA",vendor: "Nortel Networks"},
  {max_prefix: "00-0F-CD",vendor: "Nortel Networks"},
  {max_prefix: "00-1B-BA",vendor: "Nortel Networks"},
  {max_prefix: "00-04-DC",vendor: "Nortel Networks"},
  {max_prefix: "00-0C-F7",vendor: "Nortel Networks"},
  {max_prefix: "28-24-FF",vendor: "Wistron Neweb Corporation"},
  {max_prefix: "38-25-6B",vendor: "Microsoft Mobile Oy"},
  {max_prefix: "20-3A-EF",vendor: "Sivantos GmbH"},
  {max_prefix: "00-59-79",vendor: "Networked Energy Services"},
  {max_prefix: "B8-BB-23",vendor: "Guangdong Nufront CSC Co., Ltd"},
  {max_prefix: "34-EA-34",vendor: "HangZhou Gubei Electronics Technology Co.,Ltd"},
  {max_prefix: "EC-26-FB",vendor: "TECC CO.,LTD."},
  {max_prefix: "00-20-F4",vendor: "SPECTRIX CORPORATION"},
  {max_prefix: "04-EE-91",vendor: "x-fabric GmbH"},
  {max_prefix: "20-7C-8F",vendor: "Quanta Microsystems,Inc."},
  {max_prefix: "00-0B-34",vendor: "ShangHai Broadband Technologies CO.LTD"},
  {max_prefix: "30-92-F6",vendor: "SHANGHAI SUNMON COMMUNICATION TECHNOGY CO.,LTD"},
  {max_prefix: "A8-AD-3D",vendor: "Alcatel-Lucent Shanghai Bell Co., Ltd"},
  {max_prefix: "44-DC-91",vendor: "PLANEX COMMUNICATIONS INC."},
  {max_prefix: "E0-9D-B8",vendor: "PLANEX COMMUNICATIONS INC."},
  {max_prefix: "00-0F-59",vendor: "Phonak AG"},
  {max_prefix: "74-B5-7E",vendor: "zte corporation"},
  {max_prefix: "24-AF-4A",vendor: "Alcatel-Lucent IPD"},
  {max_prefix: "7C-20-64",vendor: "Alcatel-Lucent IPD"},
  {max_prefix: "48-F8-E1",vendor: "Nokia"},
  {max_prefix: "8C-90-D3",vendor: "Nokia"},
  {max_prefix: "00-14-78",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "6C-B9-C5",vendor: "Delta Networks, Inc."},
  {max_prefix: "00-28-F8",vendor: "Intel Corporate"},
  {max_prefix: "58-BC-8F",vendor: "Cognitive Systems Corp."},
  {max_prefix: "D4-55-BE",vendor: "SHENZHEN FAST TECHNOLOGIES CO.,LTD"},
  {max_prefix: "54-D2-72",vendor: "Nuki Home Solutions GmbH"},
  {max_prefix: "00-1A-6A",vendor: "Tranzas, Inc."},
  {max_prefix: "B4-74-43",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "FC-F6-47",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "F8-0C-F3",vendor: "LG Electronics (Mobile Communications)"},
  {max_prefix: "C4-4B-D1",vendor: "Wallys Communications  Teachnologies Co.,Ltd."},
  {max_prefix: "18-68-6A",vendor: "zte corporation"},
  {max_prefix: "DC-44-27",vendor: "IEEE Registration Authority"},
  {max_prefix: "C4-9A-02",vendor: "LG Electronics (Mobile Communications)"},
  {max_prefix: "00-1F-6B",vendor: "LG Electronics (Mobile Communications)"},
  {max_prefix: "00-26-E2",vendor: "LG Electronics (Mobile Communications)"},
  {max_prefix: "30-76-6F",vendor: "LG Electronics (Mobile Communications)"},
  {max_prefix: "A8-92-2C",vendor: "LG Electronics (Mobile Communications)"},
  {max_prefix: "BC-34-00",vendor: "IEEE Registration Authority"},
  {max_prefix: "F4-CB-52",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "B8-08-D7",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "94-61-1E",vendor: "Wata Electronics Co.,Ltd."},
  {max_prefix: "00-03-B2",vendor: "Radware"},
  {max_prefix: "00-A0-C6",vendor: "Qualcomm Inc."},
  {max_prefix: "64-9C-81",vendor: "Qualcomm Inc."},
  {max_prefix: "00-24-FF",vendor: "QLogic Corporation"},
  {max_prefix: "00-1E-21",vendor: "Qisda Corporation"},
  {max_prefix: "00-03-9D",vendor: "Qisda Corporation"},
  {max_prefix: "00-21-86",vendor: "Universal Global Scientific Industrial Co., Ltd."},
  {max_prefix: "18-39-19",vendor: "Unicoi Systems"},
  {max_prefix: "00-C0-E4",vendor: "SIEMENS BUILDING"},
  {max_prefix: "00-0D-10",vendor: "Embedtronics Oy"},
  {max_prefix: "00-1F-A8",vendor: "Smart Energy Instruments Inc."},
  {max_prefix: "00-0F-DB",vendor: "Westell Technologies Inc."},
  {max_prefix: "E8-88-6C",vendor: "Shenzhen SC Technologies Co.,LTD"},
  {max_prefix: "DC-35-F1",vendor: "Positivo Tecnologia S.A."},
  {max_prefix: "A4-71-74",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "78-44-76",vendor: "Zioncom Electronics (Shenzhen) Ltd."},
  {max_prefix: "00-11-65",vendor: "ZNYX Networks, Inc."},
  {max_prefix: "00-0A-68",vendor: "Solarflare Communications Inc."},
  {max_prefix: "3C-07-71",vendor: "Sony Corporation"},
  {max_prefix: "80-41-4E",vendor: "BBK EDUCATIONAL ELECTRONICS CORP.,LTD."},
  {max_prefix: "24-94-42",vendor: "OPEN ROAD SOLUTIONS , INC."},
  {max_prefix: "C4-64-13",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-08-0D",vendor: "Toshiba"},
  {max_prefix: "00-0E-7B",vendor: "Toshiba"},
  {max_prefix: "E8-E0-B7",vendor: "Toshiba"},
  {max_prefix: "50-52-D2",vendor: "Hangzhou Telin Technologies Co., Limited"},
  {max_prefix: "90-EE-D9",vendor: "UNIVERSAL DE DESARROLLOS ELECTR\u00d3NICOS, SA"},
  {max_prefix: "34-0A-FF",vendor: "Qingdao Hisense Communications Co.,Ltd."},
  {max_prefix: "58-7E-61",vendor: "Qingdao Hisense Communications Co.,Ltd."},
  {max_prefix: "C0-A1-A2",vendor: "MarqMetrix"},
  {max_prefix: "08-D0-B7",vendor: "Qingdao Hisense Communications Co.,Ltd."},
  {max_prefix: "EC-D6-8A",vendor: "Shenzhen JMicron Intelligent Technology Developmen"},
  {max_prefix: "58-52-8A",vendor: "Mitsubishi Electric Corporation"},
  {max_prefix: "68-07-15",vendor: "Intel Corporate"},
  {max_prefix: "3C-B6-B7",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "00-10-CA",vendor: "Telco Systems, Inc."},
  {max_prefix: "00-E0-9E",vendor: "Quantum Corporation"},
  {max_prefix: "00-0A-08",vendor: "Alps Alpine"},
  {max_prefix: "20-6A-8A",vendor: "Wistron Infocomm (Zhongshan) Corporation"},
  {max_prefix: "2C-36-A0",vendor: "Capisco Limited"},
  {max_prefix: "B0-B2-DC",vendor: "Zyxel Communications Corporation"},
  {max_prefix: "CC-5D-4E",vendor: "Zyxel Communications Corporation"},
  {max_prefix: "40-4A-03",vendor: "Zyxel Communications Corporation"},
  {max_prefix: "C8-6C-87",vendor: "Zyxel Communications Corporation"},
  {max_prefix: "A0-20-A6",vendor: "Espressif Inc."},
  {max_prefix: "C4-F1-D1",vendor: "BEIJING SOGOU TECHNOLOGY DEVELOPMENT CO., LTD."},
  {max_prefix: "38-BC-1A",vendor: "MEIZU Technology Co., Ltd."},
  {max_prefix: "00-1E-C0",vendor: "Microchip Technology Inc."},
  {max_prefix: "00-FE-C8",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-25-3E",vendor: "Sensus Metering Systems"},
  {max_prefix: "7C-57-4E",vendor: "COBI GmbH"},
  {max_prefix: "04-56-04",vendor: "Gionee Communication Equipment Co.,Ltd."},
  {max_prefix: "10-BE-F5",vendor: "D-Link International"},
  {max_prefix: "60-64-53",vendor: "AOD Co.,Ltd."},
  {max_prefix: "6C-98-EB",vendor: "Riverbed Technology, Inc."},
  {max_prefix: "00-9E-1E",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-18-48",vendor: "Vecima Networks Inc."},
  {max_prefix: "00-16-FB",vendor: "SHENZHEN MTC CO LTD"},
  {max_prefix: "74-CC-39",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "0C-8A-87",vendor: "AgLogica Holdings, Inc"},
  {max_prefix: "54-ED-A3",vendor: "Navdy, Inc."},
  {max_prefix: "94-59-07",vendor: "Shanghai HITE-BELDEN Network Technology Co., Ltd."},
  {max_prefix: "C8-AF-E3",vendor: "Hefei Radio Communication Technology Co., Ltd"},
  {max_prefix: "34-A2-A2",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "74-9D-8F",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "A0-41-5E",vendor: "Opsens Solution Inc."},
  {max_prefix: "2C-5A-8D",vendor: "SYSTRONIK Elektronik u. Systemtechnik GmbH"},
  {max_prefix: "E4-7B-3F",vendor: "BEIJING CO-CLOUD TECHNOLOGY LTD."},
  {max_prefix: "00-22-E7",vendor: "WPS Parking Systems"},
  {max_prefix: "A8-60-B6",vendor: "Apple, Inc."},
  {max_prefix: "C4-B3-01",vendor: "Apple, Inc."},
  {max_prefix: "E0-5F-45",vendor: "Apple, Inc."},
  {max_prefix: "48-3B-38",vendor: "Apple, Inc."},
  {max_prefix: "1C-91-48",vendor: "Apple, Inc."},
  {max_prefix: "48-51-B7",vendor: "Intel Corporate"},
  {max_prefix: "B8-E7-79",vendor: "9Solutions Oy"},
  {max_prefix: "C8-64-C7",vendor: "zte corporation"},
  {max_prefix: "38-22-D6",vendor: "Hangzhou H3C Technologies Co., Limited"},
  {max_prefix: "90-5F-2E",vendor: "TCT mobile ltd"},
  {max_prefix: "28-F3-66",vendor: "Shenzhen Bilian electronic CO.,LTD"},
  {max_prefix: "E0-A3-AC",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "BC-75-74",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "20-A6-80",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "88-28-B3",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "F8-23-B2",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "34-12-90",vendor: "Treeview Co.,Ltd."},
  {max_prefix: "7C-FE-4E",vendor: "Shenzhen Safe vision Technology Co.,LTD"},
  {max_prefix: "64-4F-B0",vendor: "Hyunjin.com"},
  {max_prefix: "A4-E5-97",vendor: "Gessler GmbH"},
  {max_prefix: "A8-6B-AD",vendor: "Hon Hai Precision Ind. Co.,Ltd."},
  {max_prefix: "D8-0F-99",vendor: "Hon Hai Precision Ind. Co.,Ltd."},
  {max_prefix: "B4-B1-5A",vendor: "Siemens AG Energy Management Division"},
  {max_prefix: "00-E0-E6",vendor: "INCAA Computers"},
  {max_prefix: "6C-3B-6B",vendor: "Routerboard.com"},
  {max_prefix: "00-6C-BC",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-A0-A4",vendor: "Oracle Corporation"},
  {max_prefix: "74-97-81",vendor: "zte corporation"},
  {max_prefix: "00-1D-08",vendor: "Jiangsu Yinhe  Electronics Co.,Ltd."},
  {max_prefix: "5C-70-A3",vendor: "LG Electronics (Mobile Communications)"},
  {max_prefix: "9C-DF-03",vendor: "Harman/Becker Automotive Systems GmbH"},
  {max_prefix: "00-11-88",vendor: "Enterasys"},
  {max_prefix: "00-01-F4",vendor: "Enterasys"},
  {max_prefix: "00-10-9B",vendor: "Emulex Corporation"},
  {max_prefix: "44-87-FC",vendor: "Elitegroup Computer Systems Co.,Ltd."},
  {max_prefix: "00-24-65",vendor: "Elentec"},
  {max_prefix: "00-08-9F",vendor: "EFM Networks"},
  {max_prefix: "00-50-FC",vendor: "Edimax Technology Co. Ltd."},
  {max_prefix: "00-14-2A",vendor: "Elitegroup Computer Systems Co.,Ltd."},
  {max_prefix: "00-11-5B",vendor: "Elitegroup Computer Systems Co.,Ltd."},
  {max_prefix: "C0-3F-D5",vendor: "Elitegroup Computer Systems Co.,Ltd."},
  {max_prefix: "EC-A8-6B",vendor: "Elitegroup Computer Systems Co.,Ltd."},
  {max_prefix: "C8-9C-DC",vendor: "Elitegroup Computer Systems Co.,Ltd."},
  {max_prefix: "00-25-11",vendor: "Elitegroup Computer Systems Co.,Ltd."},
  {max_prefix: "94-51-3D",vendor: "iSmart Alarm, Inc."},
  {max_prefix: "00-1D-82",vendor: "GN Netcom A/S"},
  {max_prefix: "00-13-17",vendor: "GN Netcom A/S"},
  {max_prefix: "00-16-FA",vendor: "ECI Telecom Ltd."},
  {max_prefix: "70-85-C2",vendor: "ASRock Incorporation"},
  {max_prefix: "EC-93-ED",vendor: "DDoS-Guard LTD"},
  {max_prefix: "14-D1-1F",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "DC-09-4C",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "1C-67-58",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "24-BC-F8",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "DC-EE-06",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "00-11-74",vendor: " Mojo Networks, Inc."},
  {max_prefix: "00-19-54",vendor: "Leaf Corporation."},
  {max_prefix: "94-66-E7",vendor: "WOM Engineering"},
  {max_prefix: "F8-A1-88",vendor: "LED Roadway Lighting"},
  {max_prefix: "A0-82-AC",vendor: "Linear DMS Solutions Sdn. Bhd."},
  {max_prefix: "A8-6A-C1",vendor: "HanbitEDS Co., Ltd."},
  {max_prefix: "D4-63-FE",vendor: "Arcadyan Corporation"},
  {max_prefix: "E8-56-59",vendor: "Advanced-Connectek Inc."},
  {max_prefix: "30-FC-68",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "00-8A-96",vendor: "Cisco Systems, Inc"},
  {max_prefix: "BC-60-A7",vendor: "Sony Interactive Entertainment Inc."},
  {max_prefix: "C8-45-29",vendor: "IMK Networks Co.,Ltd"},
  {max_prefix: "68-93-61",vendor: "Integrated Device Technology (Malaysia) Sdn. Bhd."},
  {max_prefix: "A0-04-3E",vendor: "Parker Hannifin Manufacturing Germany GmbH & Co. KG"},
  {max_prefix: "34-BF-90",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "CC-B3-F8",vendor: "FUJITSU ISOTEC LIMITED"},
  {max_prefix: "E4-A4-71",vendor: "Intel Corporate"},
  {max_prefix: "10-F0-05",vendor: "Intel Corporate"},
  {max_prefix: "64-CC-2E",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "88-01-F2",vendor: "Vitec System Engineering Inc."},
  {max_prefix: "00-BD-82",vendor: "Shenzhen YOUHUA Technology Co., Ltd"},
  {max_prefix: "4C-B8-B5",vendor: "Shenzhen YOUHUA Technology Co., Ltd"},
  {max_prefix: "BC-15-AC",vendor: "Vodafone Italia S.p.A."},
  {max_prefix: "7C-47-7C",vendor: "IEEE Registration Authority"},
  {max_prefix: "F8-77-B8",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "F0-D2-F1",vendor: "Amazon Technologies Inc."},
  {max_prefix: "04-52-C7",vendor: "Bose Corporation"},
  {max_prefix: "F0-27-45",vendor: "F-Secure Corporation"},
  {max_prefix: "54-D0-B4",vendor: "Xiamen Four-Faith Communication Technology Co.,Ltd"},
  {max_prefix: "00-13-7C",vendor: "Kaicom co., Ltd."},
  {max_prefix: "A8-E3-EE",vendor: "Sony Interactive Entertainment Inc."},
  {max_prefix: "00-24-8D",vendor: "Sony Interactive Entertainment Inc."},
  {max_prefix: "00-04-1F",vendor: "Sony Interactive Entertainment Inc."},
  {max_prefix: "20-A9-0E",vendor: "TCT mobile ltd"},
  {max_prefix: "0C-A2-F4",vendor: "Chameleon Technology (UK) Limited"},
  {max_prefix: "EC-43-8B",vendor: "YAPTV"},
  {max_prefix: "98-0C-A5",vendor: "Motorola (Wuhan) Mobility Technologies Communication Co., Ltd."},
  {max_prefix: "44-11-02",vendor: "EDMI  Europe Ltd"},
  {max_prefix: "24-5E-BE",vendor: "QNAP Systems, Inc."},
  {max_prefix: "04-04-EA",vendor: "Valens Semiconductor Ltd."},
  {max_prefix: "80-0D-D7",vendor: "Latticework, Inc"},
  {max_prefix: "A8-5E-E4",vendor: "12Sided Technology, LLC"},
  {max_prefix: "4C-CC-6A",vendor: "Micro-Star INTL CO., LTD."},
  {max_prefix: "18-21-95",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "44-78-3E",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "54-67-51",vendor: "Compal Broadband Networks, Inc."},
  {max_prefix: "24-0B-0A",vendor: "Palo Alto Networks"},
  {max_prefix: "D0-99-D5",vendor: "Alcatel-Lucent"},
  {max_prefix: "00-24-7D",vendor: "Nokia Danmark A/S"},
  {max_prefix: "00-22-65",vendor: "Nokia Danmark A/S"},
  {max_prefix: "F4-ED-5F",vendor: "SHENZHEN KTC TECHNOLOGY GROUP"},
  {max_prefix: "00-E0-E4",vendor: "FANUC ROBOTICS NORTH AMERICA, Inc."},
  {max_prefix: "D0-B5-3D",vendor: "SEPRO ROBOTIQUE"},
  {max_prefix: "00-D0-EC",vendor: "NAKAYO Inc"},
  {max_prefix: "BC-44-B0",vendor: "Elastifile"},
  {max_prefix: "74-BF-B7",vendor: "Nusoft Corporation"},
  {max_prefix: "50-DA-00",vendor: "Hangzhou H3C Technologies Co., Limited"},
  {max_prefix: "14-BB-6E",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "14-C3-C2",vendor: "K.A. Schmersal GmbH & Co. KG"},
  {max_prefix: "10-78-5B",vendor: "Actiontec Electronics, Inc"},
  {max_prefix: "30-63-6B",vendor: "Apple, Inc."},
  {max_prefix: "70-88-4D",vendor: "JAPAN RADIO CO., LTD."},
  {max_prefix: "18-86-AC",vendor: "Nokia Danmark A/S"},
  {max_prefix: "00-1F-5C",vendor: "Nokia Danmark A/S"},
  {max_prefix: "00-1F-00",vendor: "Nokia Danmark A/S"},
  {max_prefix: "A4-F1-E8",vendor: "Apple, Inc."},
  {max_prefix: "00-16-4E",vendor: "Nokia Danmark A/S"},
  {max_prefix: "00-0D-4B",vendor: "Roku, Inc."},
  {max_prefix: "F8-80-96",vendor: "Elsys Equipamentos Eletr\u00f4nicos Ltda"},
  {max_prefix: "00-1D-AA",vendor: "DrayTek Corp."},
  {max_prefix: "E4-98-D1",vendor: "Microsoft Mobile Oy"},
  {max_prefix: "6C-27-79",vendor: "Microsoft Mobile Oy"},
  {max_prefix: "00-CF-1C",vendor: "Communication Machinery Corporation"},
  {max_prefix: "28-CC-01",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "00-18-C5",vendor: "Nokia Danmark A/S"},
  {max_prefix: "F4-F5-A5",vendor: "Nokia Corporation"},
  {max_prefix: "EC-9B-5B",vendor: "Nokia Corporation"},
  {max_prefix: "2C-CC-15",vendor: "Nokia Corporation"},
  {max_prefix: "D8-FB-5E",vendor: "ASKEY COMPUTER CORP"},
  {max_prefix: "6C-F3-73",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "9C-3A-AF",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "78-1F-DB",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "18-3F-47",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "0C-BF-15",vendor: "Genetec Inc."},
  {max_prefix: "00-23-26",vendor: "FUJITSU LIMITED"},
  {max_prefix: "00-26-68",vendor: "Nokia Danmark A/S"},
  {max_prefix: "00-25-47",vendor: "Nokia Danmark A/S"},
  {max_prefix: "00-00-1D",vendor: "Cabletron Systems, Inc."},
  {max_prefix: "00-1B-A9",vendor: "Brother industries, LTD."},
  {max_prefix: "30-A2-20",vendor: "ARG Telecom"},
  {max_prefix: "00-40-FB",vendor: "CASCADE COMMUNICATIONS"},
  {max_prefix: "D0-54-2D",vendor: "Cambridge Industries(Group) Co.,Ltd."},
  {max_prefix: "00-26-4D",vendor: "Arcadyan Technology Corporation"},
  {max_prefix: "84-9C-A6",vendor: "Arcadyan Technology Corporation"},
  {max_prefix: "00-25-57",vendor: "BlackBerry RTS"},
  {max_prefix: "00-1C-CC",vendor: "BlackBerry RTS"},
  {max_prefix: "74-4A-A4",vendor: "zte corporation"},
  {max_prefix: "B8-6C-E8",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "0C-B3-19",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "18-67-B0",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "C0-FF-D4",vendor: "NETGEAR"},
  {max_prefix: "6C-B0-CE",vendor: "NETGEAR"},
  {max_prefix: "00-8E-F2",vendor: "NETGEAR"},
  {max_prefix: "9C-D3-6D",vendor: "NETGEAR"},
  {max_prefix: "C4-04-15",vendor: "NETGEAR"},
  {max_prefix: "50-A4-C8",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "6C-83-36",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "4C-A5-6D",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "00-30-0A",vendor: "Aztech Electronics Pte Ltd"},
  {max_prefix: "00-1F-3F",vendor: "AVM GmbH"},
  {max_prefix: "24-65-11",vendor: "AVM GmbH"},
  {max_prefix: "E0-3E-44",vendor: "Broadcom"},
  {max_prefix: "DC-D8-7C",vendor: "Beijing Jingdong Century Trading Co., LTD."},
  {max_prefix: "C4-DA-7D",vendor: "Ivium Technologies B.V."},
  {max_prefix: "00-0B-6A",vendor: "Asiarock Technology Limited"},
  {max_prefix: "00-1B-9E",vendor: "ASKEY COMPUTER CORP"},
  {max_prefix: "E0-CA-94",vendor: "ASKEY COMPUTER CORP"},
  {max_prefix: "00-26-B6",vendor: "ASKEY COMPUTER CORP"},
  {max_prefix: "E8-FC-AF",vendor: "NETGEAR"},
  {max_prefix: "84-1B-5E",vendor: "NETGEAR"},
  {max_prefix: "2C-B0-5D",vendor: "NETGEAR"},
  {max_prefix: "A0-21-B7",vendor: "NETGEAR"},
  {max_prefix: "00-24-B2",vendor: "NETGEAR"},
  {max_prefix: "00-1B-2F",vendor: "NETGEAR"},
  {max_prefix: "00-1F-33",vendor: "NETGEAR"},
  {max_prefix: "74-75-48",vendor: "Amazon Technologies Inc."},
  {max_prefix: "00-00-B1",vendor: "Alpha Micro"},
  {max_prefix: "00-18-02",vendor: "Alpha Networks Inc."},
  {max_prefix: "00-1C-A8",vendor: "AirTies Wireless Networks"},
  {max_prefix: "74-DE-2B",vendor: "Liteon Technology Corporation"},
  {max_prefix: "00-22-5F",vendor: "Liteon Technology Corporation"},
  {max_prefix: "5C-93-A2",vendor: "Liteon Technology Corporation"},
  {max_prefix: "AC-E0-10",vendor: "Liteon Technology Corporation"},
  {max_prefix: "00-1C-A4",vendor: "Sony Corporation"},
  {max_prefix: "00-1A-75",vendor: "Sony Corporation"},
  {max_prefix: "78-84-3C",vendor: "Sony Corporation"},
  {max_prefix: "00-23-F1",vendor: "Sony Corporation"},
  {max_prefix: "6C-FA-A7",vendor: "AMPAK Technology, Inc."},
  {max_prefix: "94-CE-2C",vendor: "Sony Corporation"},
  {max_prefix: "FC-0F-E6",vendor: "Sony Interactive Entertainment Inc."},
  {max_prefix: "24-FD-52",vendor: "Liteon Technology Corporation"},
  {max_prefix: "2C-D0-5A",vendor: "Liteon Technology Corporation"},
  {max_prefix: "74-E5-43",vendor: "Liteon Technology Corporation"},
  {max_prefix: "00-13-A9",vendor: "Sony Corporation"},
  {max_prefix: "00-0A-D9",vendor: "Sony Corporation"},
  {max_prefix: "00-0E-07",vendor: "Sony Corporation"},
  {max_prefix: "00-1E-45",vendor: "Sony Corporation"},
  {max_prefix: "28-C2-DD",vendor: "AzureWave Technology Inc."},
  {max_prefix: "80-A5-89",vendor: "AzureWave Technology Inc."},
  {max_prefix: "C4-09-38",vendor: "FUJIAN STAR-NET COMMUNICATION CO.,LTD"},
  {max_prefix: "00-1C-50",vendor: "TCL Technoly Electronics (Huizhou) Co., Ltd."},
  {max_prefix: "0C-6A-E6",vendor: "Stanley Security Solutions"},
  {max_prefix: "54-E4-BD",vendor: "FN-LINK TECHNOLOGY LIMITED"},
  {max_prefix: "54-14-FD",vendor: "Orbbec 3D Technology International"},
  {max_prefix: "48-5D-60",vendor: "AzureWave Technology Inc."},
  {max_prefix: "74-C6-3B",vendor: "AzureWave Technology Inc."},
  {max_prefix: "90-0B-C1",vendor: "Sprocomm Technologies CO.,Ltd"},
  {max_prefix: "AC-E5-F0",vendor: "Doppler Labs"},
  {max_prefix: "00-AA-02",vendor: "Intel Corporation"},
  {max_prefix: "7C-7A-91",vendor: "Intel Corporate"},
  {max_prefix: "AC-7B-A1",vendor: "Intel Corporate"},
  {max_prefix: "6C-29-95",vendor: "Intel Corporate"},
  {max_prefix: "98-4F-EE",vendor: "Intel Corporate"},
  {max_prefix: "E8-2A-EA",vendor: "Intel Corporate"},
  {max_prefix: "60-57-18",vendor: "Intel Corporate"},
  {max_prefix: "DC-85-DE",vendor: "AzureWave Technology Inc."},
  {max_prefix: "B0-EE-45",vendor: "AzureWave Technology Inc."},
  {max_prefix: "54-27-1E",vendor: "AzureWave Technology Inc."},
  {max_prefix: "00-23-8E",vendor: "ADB Broadband Italia"},
  {max_prefix: "00-1D-8B",vendor: "ADB Broadband Italia"},
  {max_prefix: "00-13-C8",vendor: "ADB Broadband Italia"},
  {max_prefix: "DC-0B-1A",vendor: "ADB Broadband Italia"},
  {max_prefix: "84-26-15",vendor: "ADB Broadband Italia"},
  {max_prefix: "F0-84-2F",vendor: "ADB Broadband Italia"},
  {max_prefix: "5C-E0-C5",vendor: "Intel Corporate"},
  {max_prefix: "58-A8-39",vendor: "Intel Corporate"},
  {max_prefix: "7C-5C-F8",vendor: "Intel Corporate"},
  {max_prefix: "B4-E1-C4",vendor: "Microsoft Mobile Oy"},
  {max_prefix: "34-84-46",vendor: "Ericsson AB"},
  {max_prefix: "04-4E-06",vendor: "Ericsson AB"},
  {max_prefix: "00-D0-9E",vendor: "2Wire Inc"},
  {max_prefix: "E0-75-7D",vendor: "Motorola Mobility LLC, a Lenovo Company"},
  {max_prefix: "34-BB-26",vendor: "Motorola Mobility LLC, a Lenovo Company"},
  {max_prefix: "80-6C-1B",vendor: "Motorola Mobility LLC, a Lenovo Company"},
  {max_prefix: "00-19-E4",vendor: "2Wire Inc"},
  {max_prefix: "00-1A-C4",vendor: "2Wire Inc"},
  {max_prefix: "00-1B-5B",vendor: "2Wire Inc"},
  {max_prefix: "00-1E-C7",vendor: "2Wire Inc"},
  {max_prefix: "00-26-50",vendor: "2Wire Inc"},
  {max_prefix: "00-23-51",vendor: "2Wire Inc"},
  {max_prefix: "00-1E-67",vendor: "Intel Corporate"},
  {max_prefix: "00-22-FA",vendor: "Intel Corporate"},
  {max_prefix: "00-15-00",vendor: "Intel Corporate"},
  {max_prefix: "00-16-EB",vendor: "Intel Corporate"},
  {max_prefix: "00-18-DE",vendor: "Intel Corporate"},
  {max_prefix: "60-67-20",vendor: "Intel Corporate"},
  {max_prefix: "FC-F8-AE",vendor: "Intel Corporate"},
  {max_prefix: "60-36-DD",vendor: "Intel Corporate"},
  {max_prefix: "10-0B-A9",vendor: "Intel Corporate"},
  {max_prefix: "64-80-99",vendor: "Intel Corporate"},
  {max_prefix: "D0-7E-35",vendor: "Intel Corporate"},
  {max_prefix: "00-1E-65",vendor: "Intel Corporate"},
  {max_prefix: "C4-D9-87",vendor: "Intel Corporate"},
  {max_prefix: "8C-70-5A",vendor: "Intel Corporate"},
  {max_prefix: "00-27-0E",vendor: "Intel Corporate"},
  {max_prefix: "A0-88-B4",vendor: "Intel Corporate"},
  {max_prefix: "CC-3B-3E",vendor: "Lester Electrical"},
  {max_prefix: "20-82-C0",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "00-17-3F",vendor: "Belkin International Inc."},
  {max_prefix: "00-1C-DF",vendor: "Belkin International Inc."},
  {max_prefix: "C0-56-27",vendor: "Belkin International Inc."},
  {max_prefix: "4C-17-EB",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "CC-33-BB",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "3C-A3-48",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "E4-5A-A2",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "3C-EA-4F",vendor: "2Wire Inc"},
  {max_prefix: "DC-7F-A4",vendor: "2Wire Inc"},
  {max_prefix: "B0-D5-CC",vendor: "Texas Instruments"},
  {max_prefix: "38-29-DD",vendor: "ONvocal Inc"},
  {max_prefix: "D8-6C-E9",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "E8-F1-B0",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "DC-6D-CD",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "C4-28-2D",vendor: "Embedded Intellect Pty Ltd"},
  {max_prefix: "80-0A-80",vendor: "IEEE Registration Authority"},
  {max_prefix: "58-46-E1",vendor: "Baxter International Inc"},
  {max_prefix: "1C-BA-8C",vendor: "Texas Instruments"},
  {max_prefix: "20-55-32",vendor: "Gotech International Technology Limited"},
  {max_prefix: "2C-FF-65",vendor: "Oki Electric Industry Co., Ltd."},
  {max_prefix: "00-15-E9",vendor: "D-Link Corporation"},
  {max_prefix: "00-1B-11",vendor: "D-Link Corporation"},
  {max_prefix: "00-26-5A",vendor: "D-Link Corporation"},
  {max_prefix: "C8-BE-19",vendor: "D-Link International"},
  {max_prefix: "24-BA-13",vendor: "RISO KAGAKU CORPORATION"},
  {max_prefix: "5C-6B-32",vendor: "Texas Instruments"},
  {max_prefix: "84-DD-20",vendor: "Texas Instruments"},
  {max_prefix: "00-17-E5",vendor: "Texas Instruments"},
  {max_prefix: "00-17-EC",vendor: "Texas Instruments"},
  {max_prefix: "00-17-E7",vendor: "Texas Instruments"},
  {max_prefix: "00-17-E9",vendor: "Texas Instruments"},
  {max_prefix: "00-29-26",vendor: "Applied Optoelectronics, Inc Taiwan Branch"},
  {max_prefix: "2C-27-D7",vendor: "Hewlett Packard"},
  {max_prefix: "98-4B-E1",vendor: "Hewlett Packard"},
  {max_prefix: "00-18-31",vendor: "Texas Instruments"},
  {max_prefix: "24-FD-5B",vendor: "SmartThings, Inc."},
  {max_prefix: "28-76-CD",vendor: "Funshion Online Technologies Co.,Ltd"},
  {max_prefix: "00-1A-11",vendor: "Google, Inc."},
  {max_prefix: "28-BC-56",vendor: "EMAC, Inc."},
  {max_prefix: "C8-47-8C",vendor: "Beken Corporation"},
  {max_prefix: "E4-98-D6",vendor: "Apple, Inc."},
  {max_prefix: "00-60-37",vendor: "NXP Semiconductors"},
  {max_prefix: "DC-C0-EB",vendor: "ASSA ABLOY C\u00d4TE PICARDE"},
  {max_prefix: "48-DB-50",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "A4-BA-76",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "00-50-C2",vendor: "IEEE Registration Authority"},
  {max_prefix: "A0-F8-95",vendor: "Shenzhen TINNO Mobile Technology Corp."},
  {max_prefix: "00-78-CD",vendor: "Ignition Design Labs"},
  {max_prefix: "70-CA-4D",vendor: "Shenzhen lnovance Technology Co.,Ltd."},
  {max_prefix: "44-00-10",vendor: "Apple, Inc."},
  {max_prefix: "00-56-CD",vendor: "Apple, Inc."},
  {max_prefix: "00-CD-FE",vendor: "Apple, Inc."},
  {max_prefix: "E4-FA-FD",vendor: "Intel Corporate"},
  {max_prefix: "94-65-9C",vendor: "Intel Corporate"},
  {max_prefix: "48-45-20",vendor: "Intel Corporate"},
  {max_prefix: "C8-0E-77",vendor: "Le Shi Zhi Xin Electronic Technology (Tianjin) Limited"},
  {max_prefix: "2C-6E-85",vendor: "Intel Corporate"},
  {max_prefix: "90-49-FA",vendor: "Intel Corporate"},
  {max_prefix: "BC-0F-64",vendor: "Intel Corporate"},
  {max_prefix: "9C-EF-D5",vendor: "Panda Wireless, Inc."},
  {max_prefix: "00-02-B3",vendor: "Intel Corporation"},
  {max_prefix: "00-03-47",vendor: "Intel Corporation"},
  {max_prefix: "00-0E-0C",vendor: "Intel Corporation"},
  {max_prefix: "00-13-20",vendor: "Intel Corporate"},
  {max_prefix: "10-6F-3F",vendor: "BUFFALO.INC"},
  {max_prefix: "B0-C7-45",vendor: "BUFFALO.INC"},
  {max_prefix: "00-23-4D",vendor: "Hon Hai Precision Ind. Co.,Ltd."},
  {max_prefix: "00-25-56",vendor: "Hon Hai Precision Ind. Co.,Ltd."},
  {max_prefix: "60-18-88",vendor: "zte corporation"},
  {max_prefix: "D8-60-B0",vendor: "bioM\u00e9rieux Italia S.p.A."},
  {max_prefix: "F8-DB-7F",vendor: "HTC Corporation"},
  {max_prefix: "64-A7-69",vendor: "HTC Corporation"},
  {max_prefix: "E8-99-C4",vendor: "HTC Corporation"},
  {max_prefix: "BC-CF-CC",vendor: "HTC Corporation"},
  {max_prefix: "28-56-5A",vendor: "Hon Hai Precision Ind. Co.,Ltd."},
  {max_prefix: "00-08-02",vendor: "Hewlett Packard"},
  {max_prefix: "00-02-A5",vendor: "Hewlett Packard"},
  {max_prefix: "6C-C2-17",vendor: "Hewlett Packard"},
  {max_prefix: "14-58-D0",vendor: "Hewlett Packard"},
  {max_prefix: "5C-8A-38",vendor: "Hewlett Packard"},
  {max_prefix: "00-80-E1",vendor: "STMicroelectronics SRL"},
  {max_prefix: "00-18-71",vendor: "Hewlett Packard"},
  {max_prefix: "00-0E-7F",vendor: "Hewlett Packard"},
  {max_prefix: "00-11-85",vendor: "Hewlett Packard"},
  {max_prefix: "00-12-79",vendor: "Hewlett Packard"},
  {max_prefix: "00-13-21",vendor: "Hewlett Packard"},
  {max_prefix: "00-04-EA",vendor: "Hewlett Packard"},
  {max_prefix: "D8-D3-85",vendor: "Hewlett Packard"},
  {max_prefix: "18-A9-05",vendor: "Hewlett Packard"},
  {max_prefix: "00-1B-78",vendor: "Hewlett Packard"},
  {max_prefix: "EC-9A-74",vendor: "Hewlett Packard"},
  {max_prefix: "10-60-4B",vendor: "Hewlett Packard"},
  {max_prefix: "C8-CB-B8",vendor: "Hewlett Packard"},
  {max_prefix: "84-34-97",vendor: "Hewlett Packard"},
  {max_prefix: "2C-59-E5",vendor: "Hewlett Packard"},
  {max_prefix: "D8-FC-38",vendor: "Giantec Semiconductor Inc"},
  {max_prefix: "AC-2A-0C",vendor: "CSR ZHUZHOU INSTITUTE CO.,LTD."},
  {max_prefix: "2C-67-98",vendor: "InTalTech Ltd."},
  {max_prefix: "6C-B5-6B",vendor: "HUMAX Co., Ltd."},
  {max_prefix: "E4-22-A5",vendor: "PLANTRONICS, INC."},
  {max_prefix: "60-21-C0",vendor: "Murata Manufacturing Co., Ltd."},
  {max_prefix: "88-30-8A",vendor: "Murata Manufacturing Co., Ltd."},
  {max_prefix: "5C-DA-D4",vendor: "Murata Manufacturing Co., Ltd."},
  {max_prefix: "00-26-E8",vendor: "Murata Manufacturing Co., Ltd."},
  {max_prefix: "00-25-86",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "54-E6-FC",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "74-EA-3A",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "F8-1A-67",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "EC-17-2F",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "00-22-68",vendor: "Hon Hai Precision Ind. Co.,Ltd."},
  {max_prefix: "BC-3A-EA",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "F4-31-C3",vendor: "Apple, Inc."},
  {max_prefix: "64-A5-C3",vendor: "Apple, Inc."},
  {max_prefix: "00-19-E0",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "84-77-78",vendor: "Cochlear Limited"},
  {max_prefix: "88-7F-03",vendor: "Comper Technology Investment Limited"},
  {max_prefix: "50-EB-1A",vendor: "Brocade Communications Systems LLC"},
  {max_prefix: "00-27-F8",vendor: "Brocade Communications Systems LLC"},
  {max_prefix: "74-8E-F8",vendor: "Brocade Communications Systems LLC"},
  {max_prefix: "14-E6-E4",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "D8-1F-CC",vendor: "Brocade Communications Systems LLC"},
  {max_prefix: "00-18-82",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "D4-EA-0E",vendor: "Avaya Inc"},
  {max_prefix: "6C-FA-58",vendor: "Avaya Inc"},
  {max_prefix: "20-F3-A3",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "0C-37-DC",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "BC-76-70",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "24-DB-AC",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "1C-1D-67",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "84-A8-E4",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "FC-C8-97",vendor: "zte corporation"},
  {max_prefix: "00-25-12",vendor: "zte corporation"},
  {max_prefix: "00-1C-26",vendor: "Hon Hai Precision Ind. Co.,Ltd."},
  {max_prefix: "00-16-CE",vendor: "Hon Hai Precision Ind. Co.,Ltd."},
  {max_prefix: "D0-2D-B3",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "E8-CD-2D",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "24-69-A5",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "EC-23-3D",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "78-F5-FD",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "5C-7D-5E",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "F8-66-D1",vendor: "Hon Hai Precision Ind. Co.,Ltd."},
  {max_prefix: "E8-08-8B",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "00-24-38",vendor: "Brocade Communications Systems LLC"},
  {max_prefix: "00-14-C9",vendor: "Brocade Communications Systems LLC"},
  {max_prefix: "34-4B-50",vendor: "zte corporation"},
  {max_prefix: "30-0E-D5",vendor: "Hon Hai Precision Ind. Co.,Ltd."},
  {max_prefix: "48-5A-B6",vendor: "Hon Hai Precision Ind. Co.,Ltd."},
  {max_prefix: "54-35-30",vendor: "Hon Hai Precision Ind. Co.,Ltd."},
  {max_prefix: "90-67-1C",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "BC-25-E0",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "F4-E3-FB",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "0C-96-BF",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "60-E7-01",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "CC-53-B5",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "60-DE-44",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "10-51-72",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "08-E8-4F",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "64-3E-8C",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "00-12-D2",vendor: "Texas Instruments"},
  {max_prefix: "F0-EB-D0",vendor: "Shanghai Feixun Communication Co.,Ltd."},
  {max_prefix: "88-86-03",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "04-F9-38",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "AC-85-3D",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "48-46-FB",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "70-E4-22",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-50-0F",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-50-A2",vendor: "Cisco Systems, Inc"},
  {max_prefix: "58-16-26",vendor: "Avaya Inc"},
  {max_prefix: "A0-51-C6",vendor: "Avaya Inc"},
  {max_prefix: "A8-63-F2",vendor: "Texas Instruments"},
  {max_prefix: "D0-FF-50",vendor: "Texas Instruments"},
  {max_prefix: "20-C3-8F",vendor: "Texas Instruments"},
  {max_prefix: "7C-66-9D",vendor: "Texas Instruments"},
  {max_prefix: "D8-DD-FD",vendor: "Texas Instruments"},
  {max_prefix: "D0-5F-B8",vendor: "Texas Instruments"},
  {max_prefix: "7C-1D-D9",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "A0-86-C6",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "9C-99-A0",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "58-44-98",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "E0-24-7F",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "00-46-4B",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "80-FB-06",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "7C-60-97",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "6C-A8-49",vendor: "Avaya Inc"},
  {max_prefix: "64-C3-54",vendor: "Avaya Inc"},
  {max_prefix: "50-CD-22",vendor: "Avaya Inc"},
  {max_prefix: "B4-A9-5A",vendor: "Avaya Inc"},
  {max_prefix: "84-EB-18",vendor: "Texas Instruments"},
  {max_prefix: "00-60-3E",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-60-2F",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-60-47",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-E0-B0",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-E0-FE",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-E0-34",vendor: "Cisco Systems, Inc"},
  {max_prefix: "E8-ED-F3",vendor: "Cisco Systems, Inc"},
  {max_prefix: "E4-C7-22",vendor: "Cisco Systems, Inc"},
  {max_prefix: "64-E9-50",vendor: "Cisco Systems, Inc"},
  {max_prefix: "C0-7B-BC",vendor: "Cisco Systems, Inc"},
  {max_prefix: "24-E9-B3",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-E0-F9",vendor: "Cisco Systems, Inc"},
  {max_prefix: "C8-D7-19",vendor: "Cisco-Linksys, LLC"},
  {max_prefix: "20-3A-07",vendor: "Cisco Systems, Inc"},
  {max_prefix: "F4-1F-C2",vendor: "Cisco Systems, Inc"},
  {max_prefix: "44-AD-D9",vendor: "Cisco Systems, Inc"},
  {max_prefix: "0C-68-03",vendor: "Cisco Systems, Inc"},
  {max_prefix: "C0-8C-60",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-10-79",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-10-29",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-0E-08",vendor: "Cisco-Linksys, LLC"},
  {max_prefix: "44-03-A7",vendor: "Cisco Systems, Inc"},
  {max_prefix: "B0-FA-EB",vendor: "Cisco Systems, Inc"},
  {max_prefix: "7C-AD-74",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-02-3D",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-50-2A",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-18-F3",vendor: "ASUSTek COMPUTER INC."},
  {max_prefix: "00-1A-92",vendor: "ASUSTek COMPUTER INC."},
  {max_prefix: "00-11-D8",vendor: "ASUSTek COMPUTER INC."},
  {max_prefix: "F0-78-16",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-22-3A",vendor: "Cisco SPVTG"},
  {max_prefix: "00-21-BE",vendor: "Cisco SPVTG"},
  {max_prefix: "00-0C-41",vendor: "Cisco-Linksys, LLC"},
  {max_prefix: "00-16-B6",vendor: "Cisco-Linksys, LLC"},
  {max_prefix: "00-18-F8",vendor: "Cisco-Linksys, LLC"},
  {max_prefix: "00-25-2E",vendor: "Cisco SPVTG"},
  {max_prefix: "54-D4-6F",vendor: "Cisco SPVTG"},
  {max_prefix: "A4-A2-4A",vendor: "Cisco SPVTG"},
  {max_prefix: "44-E0-8E",vendor: "Cisco SPVTG"},
  {max_prefix: "BC-C8-10",vendor: "Cisco SPVTG"},
  {max_prefix: "7C-B2-1B",vendor: "Cisco SPVTG"},
  {max_prefix: "24-76-7D",vendor: "Cisco SPVTG"},
  {max_prefix: "48-1D-70",vendor: "Cisco SPVTG"},
  {max_prefix: "00-E0-36",vendor: "PIONEER CORPORATION"},
  {max_prefix: "00-E0-4F",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-10-FF",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-10-54",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-10-F6",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-10-A6",vendor: "Cisco Systems, Inc"},
  {max_prefix: "1C-DE-A7",vendor: "Cisco Systems, Inc"},
  {max_prefix: "F0-7F-06",vendor: "Cisco Systems, Inc"},
  {max_prefix: "BC-16-F5",vendor: "Cisco Systems, Inc"},
  {max_prefix: "FC-5B-39",vendor: "Cisco Systems, Inc"},
  {max_prefix: "34-6F-90",vendor: "Cisco Systems, Inc"},
  {max_prefix: "14-DA-E9",vendor: "ASUSTek COMPUTER INC."},
  {max_prefix: "F4-CF-E2",vendor: "Cisco Systems, Inc"},
  {max_prefix: "A8-0C-0D",vendor: "Cisco Systems, Inc"},
  {max_prefix: "F0-B2-E5",vendor: "Cisco Systems, Inc"},
  {max_prefix: "58-97-BD",vendor: "Cisco Systems, Inc"},
  {max_prefix: "5C-83-8F",vendor: "Cisco Systems, Inc"},
  {max_prefix: "EC-BD-1D",vendor: "Cisco Systems, Inc"},
  {max_prefix: "5C-FC-66",vendor: "Cisco Systems, Inc"},
  {max_prefix: "D4-6D-50",vendor: "Cisco Systems, Inc"},
  {max_prefix: "74-A0-2F",vendor: "Cisco Systems, Inc"},
  {max_prefix: "88-F0-31",vendor: "Cisco Systems, Inc"},
  {max_prefix: "88-90-8D",vendor: "Cisco Systems, Inc"},
  {max_prefix: "1C-AB-A7",vendor: "Apple, Inc."},
  {max_prefix: "C0-84-7A",vendor: "Apple, Inc."},
  {max_prefix: "00-19-E3",vendor: "Apple, Inc."},
  {max_prefix: "00-1B-63",vendor: "Apple, Inc."},
  {max_prefix: "00-1E-C2",vendor: "Apple, Inc."},
  {max_prefix: "00-1F-F3",vendor: "Apple, Inc."},
  {max_prefix: "00-23-32",vendor: "Apple, Inc."},
  {max_prefix: "00-23-6C",vendor: "Apple, Inc."},
  {max_prefix: "00-23-DF",vendor: "Apple, Inc."},
  {max_prefix: "28-6A-B8",vendor: "Apple, Inc."},
  {max_prefix: "28-E0-2C",vendor: "Apple, Inc."},
  {max_prefix: "E0-B9-BA",vendor: "Apple, Inc."},
  {max_prefix: "00-C6-10",vendor: "Apple, Inc."},
  {max_prefix: "78-A3-E4",vendor: "Apple, Inc."},
  {max_prefix: "E4-CE-8F",vendor: "Apple, Inc."},
  {max_prefix: "E8-04-0B",vendor: "Apple, Inc."},
  {max_prefix: "14-5A-05",vendor: "Apple, Inc."},
  {max_prefix: "14-8F-C6",vendor: "Apple, Inc."},
  {max_prefix: "58-B0-35",vendor: "Apple, Inc."},
  {max_prefix: "5C-59-48",vendor: "Apple, Inc."},
  {max_prefix: "C8-BC-C8",vendor: "Apple, Inc."},
  {max_prefix: "24-AB-81",vendor: "Apple, Inc."},
  {max_prefix: "E0-F8-47",vendor: "Apple, Inc."},
  {max_prefix: "28-E7-CF",vendor: "Apple, Inc."},
  {max_prefix: "00-25-00",vendor: "Apple, Inc."},
  {max_prefix: "00-25-BC",vendor: "Apple, Inc."},
  {max_prefix: "34-15-9E",vendor: "Apple, Inc."},
  {max_prefix: "00-10-FA",vendor: "Apple, Inc."},
  {max_prefix: "00-50-E4",vendor: "Apple, Inc."},
  {max_prefix: "00-0D-93",vendor: "Apple, Inc."},
  {max_prefix: "7C-FA-DF",vendor: "Apple, Inc."},
  {max_prefix: "0C-30-21",vendor: "Apple, Inc."},
  {max_prefix: "DC-86-D8",vendor: "Apple, Inc."},
  {max_prefix: "90-B9-31",vendor: "Apple, Inc."},
  {max_prefix: "D0-E1-40",vendor: "Apple, Inc."},
  {max_prefix: "24-A2-E1",vendor: "Apple, Inc."},
  {max_prefix: "04-21-4C",vendor: "Insight Energy Ventures LLC"},
  {max_prefix: "F8-32-E4",vendor: "ASUSTek COMPUTER INC."},
  {max_prefix: "80-EA-96",vendor: "Apple, Inc."},
  {max_prefix: "60-03-08",vendor: "Apple, Inc."},
  {max_prefix: "B8-F6-B1",vendor: "Apple, Inc."},
  {max_prefix: "8C-FA-BA",vendor: "Apple, Inc."},
  {max_prefix: "7C-D1-C3",vendor: "Apple, Inc."},
  {max_prefix: "F0-DC-E2",vendor: "Apple, Inc."},
  {max_prefix: "04-F1-3E",vendor: "Apple, Inc."},
  {max_prefix: "98-F0-AB",vendor: "Apple, Inc."},
  {max_prefix: "78-31-C1",vendor: "Apple, Inc."},
  {max_prefix: "A8-20-66",vendor: "Apple, Inc."},
  {max_prefix: "BC-52-B7",vendor: "Apple, Inc."},
  {max_prefix: "88-1F-A1",vendor: "Apple, Inc."},
  {max_prefix: "C8-E0-EB",vendor: "Apple, Inc."},
  {max_prefix: "98-B8-E3",vendor: "Apple, Inc."},
  {max_prefix: "88-53-95",vendor: "Apple, Inc."},
  {max_prefix: "0C-3E-9F",vendor: "Apple, Inc."},
  {max_prefix: "78-3A-84",vendor: "Apple, Inc."},
  {max_prefix: "78-6C-1C",vendor: "Apple, Inc."},
  {max_prefix: "4C-8D-79",vendor: "Apple, Inc."},
  {max_prefix: "1C-E6-2B",vendor: "Apple, Inc."},
  {max_prefix: "98-E0-D9",vendor: "Apple, Inc."},
  {max_prefix: "CC-29-F5",vendor: "Apple, Inc."},
  {max_prefix: "28-5A-EB",vendor: "Apple, Inc."},
  {max_prefix: "FC-FC-48",vendor: "Apple, Inc."},
  {max_prefix: "9C-29-3F",vendor: "Apple, Inc."},
  {max_prefix: "80-A1-AB",vendor: "Intellisis"},
  {max_prefix: "84-28-5A",vendor: "Saffron Solutions Inc"},
  {max_prefix: "D4-B8-FF",vendor: "Home Control Singapore Pte Ltd"},
  {max_prefix: "24-A0-74",vendor: "Apple, Inc."},
  {max_prefix: "F0-24-75",vendor: "Apple, Inc."},
  {max_prefix: "2C-1F-23",vendor: "Apple, Inc."},
  {max_prefix: "54-9F-13",vendor: "Apple, Inc."},
  {max_prefix: "F0-DB-E2",vendor: "Apple, Inc."},
  {max_prefix: "74-81-14",vendor: "Apple, Inc."},
  {max_prefix: "18-F6-43",vendor: "Apple, Inc."},
  {max_prefix: "A4-5E-60",vendor: "Apple, Inc."},
  {max_prefix: "A0-18-28",vendor: "Apple, Inc."},
  {max_prefix: "D0-03-4B",vendor: "Apple, Inc."},
  {max_prefix: "10-41-7F",vendor: "Apple, Inc."},
  {max_prefix: "A8-66-7F",vendor: "Apple, Inc."},
  {max_prefix: "D0-25-98",vendor: "Apple, Inc."},
  {max_prefix: "08-74-02",vendor: "Apple, Inc."},
  {max_prefix: "94-F6-A3",vendor: "Apple, Inc."},
  {max_prefix: "5C-8D-4E",vendor: "Apple, Inc."},
  {max_prefix: "88-63-DF",vendor: "Apple, Inc."},
  {max_prefix: "84-78-8B",vendor: "Apple, Inc."},
  {max_prefix: "80-BE-05",vendor: "Apple, Inc."},
  {max_prefix: "84-A4-23",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "3C-78-73",vendor: "Airsonics"},
  {max_prefix: "9C-88-AD",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "C8-A2-CE",vendor: "Oasis Media Systems LLC"},
  {max_prefix: "88-94-7E",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "40-54-E4",vendor: "Wearsafe Labs Inc"},
  {max_prefix: "58-F4-96",vendor: "Source Chain"},
  {max_prefix: "58-7F-57",vendor: "Apple, Inc."},
  {max_prefix: "D0-7C-2D",vendor: "Leie IOT technology Co., Ltd"},
  {max_prefix: "EC-64-E7",vendor: "MOCACARE Corporation"},
  {max_prefix: "40-86-2E",vendor: "JDM MOBILE INTERNET SOLUTION CO., LTD."},
  {max_prefix: "98-F4-28",vendor: "zte corporation"},
  {max_prefix: "E8-34-3E",vendor: "Beijing Infosec Technologies Co., LTD."},
  {max_prefix: "34-69-87",vendor: "zte corporation"},
  {max_prefix: "A4-CC-32",vendor: "Inficomm Co., Ltd"},
  {max_prefix: "C4-BB-EA",vendor: "Pakedge Device and Software Inc"},
  {max_prefix: "00-6D-52",vendor: "Apple, Inc."},
  {max_prefix: "30-5A-3A",vendor: "ASUSTek COMPUTER INC."},
  {max_prefix: "98-87-44",vendor: "Wuxi Hongda Science and Technology Co.,LTD"},
  {max_prefix: "38-F5-57",vendor: "JOLATA, INC."},
  {max_prefix: "70-BF-3E",vendor: "Charles River Laboratories"},
  {max_prefix: "A8-C8-7F",vendor: "Roqos, Inc."},
  {max_prefix: "A0-32-99",vendor: "Lenovo (Beijing) Co., Ltd."},
  {max_prefix: "24-6C-8A",vendor: "YUKAI Engineering"},
  {max_prefix: "5C-CF-7F",vendor: "Espressif Inc."},
  {max_prefix: "3C-83-1E",vendor: "CKD Corporation"},
  {max_prefix: "90-DF-FB",vendor: "HOMERIDER SYSTEMS"},
  {max_prefix: "54-A3-FA",vendor: "BQT Solutions (Australia)Pty Ltd"},
  {max_prefix: "2C-08-1C",vendor: "OVH"},
  {max_prefix: "C0-84-88",vendor: "Finis Inc"},
  {max_prefix: "B8-44-D9",vendor: "Apple, Inc."},
  {max_prefix: "38-5F-66",vendor: "Cisco SPVTG"},
  {max_prefix: "9C-7A-03",vendor: "Ciena Corporation"},
  {max_prefix: "70-11-AE",vendor: "Music Life LTD"},
  {max_prefix: "AC-C5-1B",vendor: "Zhuhai Pantum Electronics Co., Ltd."},
  {max_prefix: "AC-EE-9E",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "B8-57-D8",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "68-12-95",vendor: "Lupine Lighting Systems GmbH"},
  {max_prefix: "04-1E-7A",vendor: "DSPWorks"},
  {max_prefix: "C8-A9-FC",vendor: "Goyoo Networks Inc."},
  {max_prefix: "1C-49-7B",vendor: "Gemtek Technology Co., Ltd."},
  {max_prefix: "2C-CF-58",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "D0-93-80",vendor: "Ducere Technologies Pvt. Ltd."},
  {max_prefix: "68-F9-56",vendor: "Objetivos y Servicio de Valor A\u00f1adido"},
  {max_prefix: "84-A7-88",vendor: "Perples"},
  {max_prefix: "AC-60-B6",vendor: "Ericsson AB"},
  {max_prefix: "14-B3-70",vendor: "Gigaset Digital Technology (Shenzhen) Co., Ltd."},
  {max_prefix: "68-89-C1",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "FC-FE-C2",vendor: "Invensys Controls UK Limited"},
  {max_prefix: "68-9A-B7",vendor: "Atelier Vision Corporation"},
  {max_prefix: "44-4C-A8",vendor: "Arista Networks"},
  {max_prefix: "7C-2B-E1",vendor: "Shenzhen Ferex Electrical Co.,Ltd"},
  {max_prefix: "50-31-AD",vendor: "ABB Global Industries and Services Private Limited"},
  {max_prefix: "A4-C1-38",vendor: "Telink Semiconductor (Taipei) Co. Ltd."},
  {max_prefix: "14-3E-BF",vendor: "zte corporation"},
  {max_prefix: "FC-2F-EF",vendor: "UTT Technologies Co., Ltd."},
  {max_prefix: "04-61-69",vendor: "MEDIA GLOBAL LINKS CO., LTD."},
  {max_prefix: "20-F5-10",vendor: "Codex Digital Limited"},
  {max_prefix: "1C-56-FE",vendor: "Motorola Mobility LLC, a Lenovo Company"},
  {max_prefix: "F0-9A-51",vendor: "Shanghai Viroyal Electronic Technology Company Limited"},
  {max_prefix: "BC-EB-5F",vendor: "Fujian Beifeng Telecom Technology Co., Ltd."},
  {max_prefix: "B8-99-B0",vendor: "Cohere Technologies"},
  {max_prefix: "A8-D8-28",vendor: "Ascensia Diabetes Care"},
  {max_prefix: "B8-69-C2",vendor: "Sunitec Enterprise Co., Ltd."},
  {max_prefix: "88-CB-A5",vendor: "Suzhou Torchstar Intelligent Technology Co.,Ltd"},
  {max_prefix: "E4-A3-2F",vendor: "Shanghai Artimen Technology Co., Ltd."},
  {max_prefix: "7C-A2-3E",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "50-1A-A5",vendor: "GN Netcom A/S"},
  {max_prefix: "F4-67-2D",vendor: "ShenZhen Topstar Technology Company"},
  {max_prefix: "A4-8D-3B",vendor: "Vizio, Inc"},
  {max_prefix: "88-A2-D7",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "D0-9D-AB",vendor: "TCT mobile ltd"},
  {max_prefix: "88-73-84",vendor: "Toshiba"},
  {max_prefix: "F0-AB-54",vendor: "MITSUMI ELECTRIC CO.,LTD."},
  {max_prefix: "14-15-7C",vendor: "TOKYO COSMOS ELECTRIC CO.,LTD."},
  {max_prefix: "20-E4-07",vendor: "Spark srl"},
  {max_prefix: "80-9F-AB",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "E0-03-70",vendor: "ShenZhen Continental Wireless Technology Co., Ltd."},
  {max_prefix: "D8-5D-EF",vendor: "Busch-Jaeger Elektro GmbH"},
  {max_prefix: "3C-31-78",vendor: "Qolsys Inc."},
  {max_prefix: "08-EC-A9",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "AC-5A-14",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "00-32-3A",vendor: "so-logic"},
  {max_prefix: "24-B0-A9",vendor: "Shanghai Mobiletek Communication Ltd."},
  {max_prefix: "5C-EB-68",vendor: "Cheerstar Technology Co., Ltd"},
  {max_prefix: "E0-4B-45",vendor: "Hi-P Electronics Pte Ltd"},
  {max_prefix: "F4-6A-92",vendor: "SHENZHEN FAST TECHNOLOGIES CO.,LTD"},
  {max_prefix: "F0-D6-57",vendor: "ECHOSENS"},
  {max_prefix: "AC-56-2C",vendor: "LAVA INTERNATIONAL(H.K) LIMITED"},
  {max_prefix: "FC-9A-FA",vendor: "Motus Global Inc."},
  {max_prefix: "C8-E1-30",vendor: "Milkyway Group Ltd"},
  {max_prefix: "1C-C5-86",vendor: "Absolute Acoustics"},
  {max_prefix: "24-69-3E",vendor: "innodisk Corporation"},
  {max_prefix: "C0-DC-6A",vendor: "Qingdao Eastsoft Communication Technology Co.,LTD"},
  {max_prefix: "9C-37-F4",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "3C-47-11",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "1C-F0-3E",vendor: "Wearhaus Inc."},
  {max_prefix: "48-6E-FB",vendor: "Davit System Technology Co., Ltd."},
  {max_prefix: "C4-3A-BE",vendor: "Sony Corporation"},
  {max_prefix: "88-3B-8B",vendor: "Cheering Connection Co. Ltd."},
  {max_prefix: "E4-F9-39",vendor: "Minxon Hotel Technology INC."},
  {max_prefix: "24-76-56",vendor: "Shanghai Net Miles Fiber Optics Technology Co., LTD."},
  {max_prefix: "F8-CF-C5",vendor: "Motorola Mobility LLC, a Lenovo Company"},
  {max_prefix: "40-7F-E0",vendor: "Glory Star Technics (ShenZhen) Limited"},
  {max_prefix: "14-6B-72",vendor: "Shenzhen Fortune Ship Technology Co., Ltd."},
  {max_prefix: "B8-F0-80",vendor: "SPS, INC."},
  {max_prefix: "70-DA-9C",vendor: "TECSEN"},
  {max_prefix: "78-40-E4",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "E0-99-71",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "80-50-67",vendor: "W & D TECHNOLOGY CORPORATION"},
  {max_prefix: "78-F9-44",vendor: "Private"},
  {max_prefix: "5C-5B-35",vendor: "Mist Systems, Inc."},
  {max_prefix: "B0-96-6C",vendor: "Lanbowan Technology Ltd."},
  {max_prefix: "A4-7B-85",vendor: "ULTIMEDIA Co Ltd,"},
  {max_prefix: "24-1B-44",vendor: "Hangzhou Tuners Electronics Co., Ltd"},
  {max_prefix: "80-A8-5D",vendor: "Osterhout Design Group"},
  {max_prefix: "AC-CA-AB",vendor: "Virtual Electric Inc"},
  {max_prefix: "EC-BA-FE",vendor: "GIROPTIC"},
  {max_prefix: "3C-2C-94",vendor: "\u676d\u5dde\u5fb7\u6f9c\u79d1\u6280\u6709\u9650\u516c\u53f8\uff08HangZhou Delan Technology Co.,Ltd\uff09"},
  {max_prefix: "84-73-03",vendor: "Letv Mobile and Intelligent Information Technology (Beijing) Corporation Ltd."},
  {max_prefix: "3C-C2-E1",vendor: "XINHUA CONTROL ENGINEERING CO.,LTD"},
  {max_prefix: "E4-85-01",vendor: "Geberit International AG"},
  {max_prefix: "8C-87-3B",vendor: "Leica Camera AG"},
  {max_prefix: "2C-A2-B4",vendor: "Fortify Technologies, LLC"},
  {max_prefix: "10-D3-8A",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "20-62-74",vendor: "Microsoft Corporation"},
  {max_prefix: "E8-16-2B",vendor: "IDEO Security Co., Ltd."},
  {max_prefix: "48-54-15",vendor: "NET RULES TECNOLOGIA EIRELI"},
  {max_prefix: "34-9E-34",vendor: "Evervictory Electronic Co.Ltd"},
  {max_prefix: "BC-74-D7",vendor: "HangZhou JuRu Technology CO.,LTD"},
  {max_prefix: "28-D9-8A",vendor: "Hangzhou Konke Technology Co.,Ltd."},
  {max_prefix: "70-0F-C7",vendor: "SHENZHEN IKINLOOP TECHNOLOGY CO.,LTD."},
  {max_prefix: "14-29-71",vendor: "NEMOA ELECTRONICS (HK) CO. LTD"},
  {max_prefix: "B4-73-56",vendor: "Hangzhou Treebear Networking Co., Ltd."},
  {max_prefix: "78-E9-80",vendor: "RainUs Co.,Ltd"},
  {max_prefix: "3C-B7-92",vendor: "Hitachi Maxell, Ltd., Optronics Division"},
  {max_prefix: "D8-93-41",vendor: "General Electric Global Research"},
  {max_prefix: "34-68-95",vendor: "Hon Hai Precision Ind. Co.,Ltd."},
  {max_prefix: "E0-FF-F7",vendor: "Softiron Inc."},
  {max_prefix: "3C-6A-9D",vendor: "Dexatek Technology LTD."},
  {max_prefix: "1C-9E-CB",vendor: "Beijing Nari Smartchip Microelectronics Company Limited"},
  {max_prefix: "DC-C6-22",vendor: "BUHEUNG SYSTEM"},
  {max_prefix: "90-2C-C7",vendor: "C-MAX Asia Limited"},
  {max_prefix: "94-C0-38",vendor: "Tallac Networks"},
  {max_prefix: "68-36-B5",vendor: "DriveScale, Inc."},
  {max_prefix: "88-70-EF",vendor: "SC Professional Trading Co., Ltd."},
  {max_prefix: "3C-49-37",vendor: "ASSMANN Electronic GmbH"},
  {max_prefix: "84-44-64",vendor: "ServerU Inc"},
  {max_prefix: "00-35-60",vendor: "Rosen Aviation"},
  {max_prefix: "F8-BC-41",vendor: "Rosslare Enterprises Limited"},
  {max_prefix: "5C-96-6A",vendor: "RTNET"},
  {max_prefix: "BC-BC-46",vendor: "SKS Welding Systems GmbH"},
  {max_prefix: "C4-08-80",vendor: "Shenzhen UTEPO Tech Co., Ltd."},
  {max_prefix: "D8-80-39",vendor: "Microchip Technology Inc."},
  {max_prefix: "F8-B2-F3",vendor: "GUANGZHOU BOSMA TECHNOLOGY CO.,LTD"},
  {max_prefix: "78-EB-14",vendor: "SHENZHEN FAST TECHNOLOGIES CO.,LTD"},
  {max_prefix: "84-93-0C",vendor: "InCoax Networks Europe AB"},
  {max_prefix: "1C-A2-B1",vendor: "ruwido austria gmbh"},
  {max_prefix: "38-4B-76",vendor: "AIRTAME ApS"},
  {max_prefix: "34-B7-FD",vendor: "Guangzhou Younghead Electronic Technology Co.,Ltd"},
  {max_prefix: "90-17-9B",vendor: "Nanomegas"},
  {max_prefix: "14-F8-93",vendor: "Wuhan FiberHome Digital Technology Co.,Ltd."},
  {max_prefix: "98-16-EC",vendor: "IC Intracom"},
  {max_prefix: "34-F6-D2",vendor: "Panasonic Taiwan Co.,Ltd."},
  {max_prefix: "30-75-12",vendor: "Sony Corporation"},
  {max_prefix: "D4-8F-33",vendor: "Microsoft Corporation"},
  {max_prefix: "54-F8-76",vendor: "ABB AG"},
  {max_prefix: "58-21-36",vendor: "KMB systems, s.r.o."},
  {max_prefix: "64-00-2D",vendor: "Powerlinq Co., LTD"},
  {max_prefix: "38-B1-DB",vendor: "Hon Hai Precision Ind. Co.,Ltd."},
  {max_prefix: "D0-62-A0",vendor: "China Essence Technology (Zhumadian) Co., Ltd."},
  {max_prefix: "70-FF-5C",vendor: "Cheerzing Communication(Xiamen)Technology Co.,Ltd"},
  {max_prefix: "80-09-02",vendor: "Keysight Technologies, Inc."},
  {max_prefix: "04-99-E6",vendor: "Shenzhen Yoostar Technology Co., Ltd"},
  {max_prefix: "4C-48-DA",vendor: "Beijing Autelan Technology Co.,Ltd"},
  {max_prefix: "20-5C-FA",vendor: "Yangzhou ChangLian Network Technology Co,ltd."},
  {max_prefix: "B4-7C-29",vendor: "Shenzhen Guzidi Technology Co.,Ltd"},
  {max_prefix: "48-9D-18",vendor: "Flashbay Limited"},
  {max_prefix: "20-A9-9B",vendor: "Microsoft Corporation"},
  {max_prefix: "60-48-26",vendor: "Newbridge Technologies Int. Ltd."},
  {max_prefix: "4C-BB-58",vendor: "Chicony Electronics Co., Ltd."},
  {max_prefix: "A4-12-42",vendor: "NEC Platforms, Ltd."},
  {max_prefix: "C4-00-06",vendor: "Lipi Data Systems Ltd."},
  {max_prefix: "B4-17-80",vendor: "DTI Group Ltd"},
  {max_prefix: "38-F3-3F",vendor: "TATSUNO CORPORATION"},
  {max_prefix: "D8-0C-CF",vendor: "C.G.V. S.A.S."},
  {max_prefix: "90-20-3A",vendor: "BYD Precision Manufacture Co.,Ltd"},
  {max_prefix: "38-26-2B",vendor: "UTran Technology"},
  {max_prefix: "48-0C-49",vendor: "NAKAYO Inc"},
  {max_prefix: "3C-D9-CE",vendor: "Eclipse WiFi"},
  {max_prefix: "60-77-E2",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "FC-19-10",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "D0-0A-AB",vendor: "Yokogawa Digital Computer Corporation"},
  {max_prefix: "FC-79-0B",vendor: "Hitachi High Technologies America, Inc."},
  {max_prefix: "60-81-F9",vendor: "Helium Systems, Inc"},
  {max_prefix: "84-01-A7",vendor: "Greyware Automation Products, Inc"},
  {max_prefix: "98-F1-70",vendor: "Murata Manufacturing Co., Ltd."},
  {max_prefix: "04-C9-91",vendor: "Phistek INC."},
  {max_prefix: "C4-C9-EC",vendor: "Gugaoo   HK Limited"},
  {max_prefix: "68-6E-48",vendor: "Prophet Electronic Technology Corp.,Ltd"},
  {max_prefix: "14-B4-84",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "F4-C4-47",vendor: "Coagent International Enterprise Limited"},
  {max_prefix: "A4-3D-78",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "A0-FC-6E",vendor: "Telegrafia a.s."},
  {max_prefix: "3C-A1-0D",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "64-6C-B2",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "68-05-71",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "20-9A-E9",vendor: "Volacomm Co., Ltd"},
  {max_prefix: "34-5D-10",vendor: "Wytek"},
  {max_prefix: "6C-14-F7",vendor: "Erhardt+Leimer GmbH"},
  {max_prefix: "B0-D5-9D",vendor: "Shenzhen Zowee Technology Co., Ltd"},
  {max_prefix: "68-28-BA",vendor: "Dejai"},
  {max_prefix: "78-D6-6F",vendor: "Aristocrat Technologies Australia Pty. Ltd."},
  {max_prefix: "44-1E-91",vendor: "ARVIDA Intelligent Electronics Technology  Co.,Ltd."},
  {max_prefix: "50-C7-BF",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "20-53-CA",vendor: "Risk Technology Ltd"},
  {max_prefix: "A8-A6-68",vendor: "zte corporation"},
  {max_prefix: "AC-A9-A0",vendor: "Audioengine, Ltd."},
  {max_prefix: "A4-81-EE",vendor: "Nokia Corporation"},
  {max_prefix: "78-92-3E",vendor: "Nokia Corporation"},
  {max_prefix: "34-E4-2A",vendor: "Automatic Bar Controls Inc."},
  {max_prefix: "20-A7-87",vendor: "Bointec Taiwan Corporation Limited"},
  {max_prefix: "04-57-2F",vendor: "Sertel Electronics UK Ltd"},
  {max_prefix: "D8-97-7C",vendor: "Grey Innovation"},
  {max_prefix: "BC-8D-0E",vendor: "Nokia"},
  {max_prefix: "20-5A-00",vendor: "Coval"},
  {max_prefix: "A4-9F-85",vendor: "Lyve Minds, Inc"},
  {max_prefix: "B0-57-06",vendor: "Vallox Oy"},
  {max_prefix: "48-EE-07",vendor: "Silver Palm Technologies LLC"},
  {max_prefix: "3C-89-A6",vendor: "KAPELSE"},
  {max_prefix: "A4-6C-C1",vendor: "LTi REEnergy GmbH"},
  {max_prefix: "C4-0E-45",vendor: "ACK Networks,Inc."},
  {max_prefix: "C4-62-6B",vendor: "ZPT Vigantice"},
  {max_prefix: "EC-17-66",vendor: "Research Centre Module"},
  {max_prefix: "A0-D1-2A",vendor: "AXPRO Technology Inc."},
  {max_prefix: "00-EE-BD",vendor: "HTC Corporation"},
  {max_prefix: "A8-24-EB",vendor: "ZAO NPO Introtest"},
  {max_prefix: "A8-B9-B3",vendor: "ESSYS"},
  {max_prefix: "6C-09-D6",vendor: "Digiquest Electronics LTD"},
  {max_prefix: "48-18-42",vendor: "Shanghai Winaas Co. Equipment Co. Ltd."},
  {max_prefix: "44-70-98",vendor: "MING HONG TECHNOLOGY (SHEN ZHEN) LIMITED"},
  {max_prefix: "48-B9-77",vendor: "PulseOn Oy"},
  {max_prefix: "2C-53-4A",vendor: "Shenzhen Winyao Electronic Limited"},
  {max_prefix: "A4-BB-AF",vendor: "Lime Instruments"},
  {max_prefix: "F4-90-CA",vendor: "Tensorcom"},
  {max_prefix: "D4-31-9D",vendor: "Sinwatec"},
  {max_prefix: "B0-68-B6",vendor: "Hangzhou OYE Technology Co. Ltd"},
  {max_prefix: "E4-4C-6C",vendor: "Shenzhen Guo Wei Electronic Co,. Ltd."},
  {max_prefix: "38-F7-08",vendor: "National Resource Management, Inc."},
  {max_prefix: "E0-DB-88",vendor: "Open Standard Digital-IF Interface for SATCOM Systems"},
  {max_prefix: "28-22-46",vendor: "Beijing Sinoix Communication Co., LTD"},
  {max_prefix: "9C-65-F9",vendor: "AcSiP Technology Corp."},
  {max_prefix: "48-76-04",vendor: "Private"},
  {max_prefix: "9C-BD-9D",vendor: "SkyDisk, Inc."},
  {max_prefix: "74-C6-21",vendor: "Zhejiang Hite Renewable Energy Co.,LTD"},
  {max_prefix: "A8-57-4E",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "B8-43-E4",vendor: "Vlatacom"},
  {max_prefix: "C4-82-4E",vendor: "Changzhou Uchip Electronics Co., LTD."},
  {max_prefix: "B8-98-F7",vendor: "Gionee Communication Equipment Co,Ltd.ShenZhen"},
  {max_prefix: "84-83-36",vendor: "Newrun"},
  {max_prefix: "64-E6-25",vendor: "Woxu Wireless Co., Ltd"},
  {max_prefix: "10-B7-13",vendor: "Private"},
  {max_prefix: "20-89-86",vendor: "zte corporation"},
  {max_prefix: "A4-77-60",vendor: "Nokia Corporation"},
  {max_prefix: "C8-56-63",vendor: "Sunflex Europe GmbH"},
  {max_prefix: "88-FE-D6",vendor: "ShangHai WangYong Software Co., Ltd."},
  {max_prefix: "7C-72-E4",vendor: "Unikey Technologies"},
  {max_prefix: "7C-20-48",vendor: "KoamTac"},
  {max_prefix: "8C-B7-F7",vendor: "Shenzhen UniStrong Science & Technology Co., Ltd"},
  {max_prefix: "B8-26-6C",vendor: "ANOV France"},
  {max_prefix: "18-20-12",vendor: "Aztech Associates Inc."},
  {max_prefix: "3C-30-0C",vendor: "Dewar Electronics Pty Ltd"},
  {max_prefix: "98-FF-D0",vendor: "Lenovo Mobile Communication Technology Ltd."},
  {max_prefix: "38-BF-2F",vendor: "Espec Corp."},
  {max_prefix: "A8-75-E2",vendor: "Aventura Technologies, Inc."},
  {max_prefix: "B8-7A-C9",vendor: "Siemens Ltd."},
  {max_prefix: "F0-61-30",vendor: "Advantage Pharmacy Services, LLC"},
  {max_prefix: "44-C5-6F",vendor: "NGN Easy Satfinder (Tianjin) Electronic Co., Ltd"},
  {max_prefix: "44-C4-A9",vendor: "Opticom Communication, LLC"},
  {max_prefix: "6C-3C-53",vendor: "SoundHawk Corp"},
  {max_prefix: "5C-2A-EF",vendor: "  r2p Asia-Pacific Pty Ltd"},
  {max_prefix: "08-40-27",vendor: "Gridstore Inc."},
  {max_prefix: "1C-63-B7",vendor: "OpenProducts 237 AB"},
  {max_prefix: "B4-52-7E",vendor: "Sony Corporation"},
  {max_prefix: "18-AA-45",vendor: "Fon Technology"},
  {max_prefix: "94-B9-B4",vendor: "Aptos Technology"},
  {max_prefix: "40-7A-80",vendor: "Nokia Corporation"},
  {max_prefix: "64-42-14",vendor: "Swisscom Energy Solutions AG"},
  {max_prefix: "0C-A6-94",vendor: "Sunitec Enterprise Co.,Ltd"},
  {max_prefix: "18-44-62",vendor: "Riava Networks, Inc."},
  {max_prefix: "14-60-80",vendor: "zte corporation"},
  {max_prefix: "9C-BB-98",vendor: "Shen Zhen RND Electronic Co.,LTD"},
  {max_prefix: "70-60-DE",vendor: "LaVision GmbH"},
  {max_prefix: "50-2E-5C",vendor: "HTC Corporation"},
  {max_prefix: "FC-FE-77",vendor: "Hitachi Reftechno, Inc."},
  {max_prefix: "70-53-3F",vendor: "Alfa Instrumentos Eletronicos Ltda."},
  {max_prefix: "B0-79-08",vendor: "Cummings Engineering"},
  {max_prefix: "E4-77-23",vendor: "zte corporation"},
  {max_prefix: "9C-A9-E4",vendor: "zte corporation"},
  {max_prefix: "90-F3-B7",vendor: "Kirisun Communications Co., Ltd."},
  {max_prefix: "38-B7-4D",vendor: "Fijowave Limited"},
  {max_prefix: "18-0C-14",vendor: "iSonea Limited"},
  {max_prefix: "C4-E9-2F",vendor: "AB Sciex"},
  {max_prefix: "DC-3E-F8",vendor: "Nokia Corporation"},
  {max_prefix: "A4-9F-89",vendor: "Shanghai Rui Rui Communication Technology Co.Ltd."},
  {max_prefix: "50-C2-71",vendor: "SECURETECH INC"},
  {max_prefix: "90-20-83",vendor: "General Engine Management Systems Ltd."},
  {max_prefix: "14-B1-26",vendor: "Industrial Software Co"},
  {max_prefix: "D8-50-E6",vendor: "ASUSTek COMPUTER INC."},
  {max_prefix: "A8-8D-7B",vendor: "SunDroid Global limited."},
  {max_prefix: "3C-F7-48",vendor: "Shenzhen Linsn Technology Development Co.,Ltd"},
  {max_prefix: "6C-15-F9",vendor: "Nautronix Limited"},
  {max_prefix: "CC-72-0F",vendor: "Viscount Systems Inc."},
  {max_prefix: "64-3F-5F",vendor: "Exablaze"},
  {max_prefix: "2C-CD-69",vendor: "Aqavi.com"},
  {max_prefix: "8C-2F-39",vendor: "IBA Dosimetry GmbH"},
  {max_prefix: "C0-A0-BB",vendor: "D-Link International"},
  {max_prefix: "F4-5F-69",vendor: "Matsufu Electronics distribution Company"},
  {max_prefix: "28-A1-EB",vendor: "ETEK TECHNOLOGY (SHENZHEN) CO.,LTD"},
  {max_prefix: "B8-F8-28",vendor: "Changshu Gaoshida Optoelectronic Technology Co. Ltd."},
  {max_prefix: "3C-1A-57",vendor: "Cardiopulmonary Corp"},
  {max_prefix: "54-1B-5D",vendor: "Techno-Innov"},
  {max_prefix: "D0-8A-55",vendor: "Skullcandy"},
  {max_prefix: "34-4F-3F",vendor: "IO-Power Technology Co., Ltd."},
  {max_prefix: "14-46-E4",vendor: "AVISTEL"},
  {max_prefix: "D0-95-C7",vendor: "Pantech Co., Ltd."},
  {max_prefix: "9C-E7-BD",vendor: "Winduskorea co., Ltd"},
  {max_prefix: "38-42-A6",vendor: "Ingenieurbuero Stahlkopf"},
  {max_prefix: "94-0B-D5",vendor: "Himax Technologies, Inc"},
  {max_prefix: "24-80-00",vendor: "Westcontrol AS"},
  {max_prefix: "1C-4B-B9",vendor: "SMG ENTERPRISE, LLC"},
  {max_prefix: "34-61-78",vendor: "The Boeing Company"},
  {max_prefix: "04-4F-8B",vendor: "Adapteva, Inc."},
  {max_prefix: "2C-7B-84",vendor: "OOO Petr Telegin"},
  {max_prefix: "A4-C0-C7",vendor: "ShenZhen Hitom Communication Technology Co..LTD"},
  {max_prefix: "D0-2C-45",vendor: "littleBits Electronics, Inc."},
  {max_prefix: "B4-A8-2B",vendor: "Histar Digital Electronics Co., Ltd."},
  {max_prefix: "74-2B-62",vendor: "FUJITSU LIMITED"},
  {max_prefix: "C0-35-80",vendor: "A&R TECH"},
  {max_prefix: "54-FB-58",vendor: "WISEWARE, Lda"},
  {max_prefix: "E0-D1-E6",vendor: "Aliph dba Jawbone"},
  {max_prefix: "D8-2D-9B",vendor: "Shenzhen G.Credit Communication Technology Co., Ltd"},
  {max_prefix: "70-9B-FC",vendor: "Bryton Inc."},
  {max_prefix: "AC-E4-2E",vendor: "SK hynix"},
  {max_prefix: "30-61-12",vendor: "PAV GmbH"},
  {max_prefix: "04-1B-94",vendor: "Host Mobility AB"},
  {max_prefix: "A0-CE-C8",vendor: "CE LINK LIMITED"},
  {max_prefix: "90-7A-28",vendor: "Beijing Morncloud Information And Technology Co. Ltd."},
  {max_prefix: "88-36-12",vendor: "SRC Computers, LLC"},
  {max_prefix: "08-35-71",vendor: "CASwell INC."},
  {max_prefix: "98-76-B6",vendor: "Adafruit"},
  {max_prefix: "50-3C-C4",vendor: "Lenovo Mobile Communication Technology Ltd."},
  {max_prefix: "E0-3E-4A",vendor: "Cavanagh Group International"},
  {max_prefix: "DC-6F-00",vendor: "Livescribe, Inc."},
  {max_prefix: "54-E3-B0",vendor: "JVL Industri Elektronik"},
  {max_prefix: "78-FE-41",vendor: "Socus networks"},
  {max_prefix: "20-57-21",vendor: "Salix Technology CO., Ltd."},
  {max_prefix: "18-10-4E",vendor: "CEDINT-UPM"},
  {max_prefix: "78-9F-4C",vendor: "HOERBIGER Elektronik GmbH"},
  {max_prefix: "04-3D-98",vendor: "ChongQing QingJia Electronics CO.,LTD"},
  {max_prefix: "FC-4B-BC",vendor: "Sunplus Technology Co., Ltd."},
  {max_prefix: "9C-14-65",vendor: "Edata Elektronik San. ve Tic. A.\u015e."},
  {max_prefix: "4C-55-CC",vendor: "Zentri Pty Ltd"},
  {max_prefix: "00-C5-DB",vendor: "Datatech Sistemas Digitales Avanzados SL"},
  {max_prefix: "8C-F9-45",vendor: "Power Automation pte Ltd"},
  {max_prefix: "F8-42-FB",vendor: "Yasuda Joho Co.,ltd."},
  {max_prefix: "40-BD-9E",vendor: "Physio-Control, Inc"},
  {max_prefix: "6C-57-79",vendor: "Aclima, Inc."},
  {max_prefix: "C0-DA-74",vendor: "Hangzhou Sunyard Technology Co., Ltd."},
  {max_prefix: "88-73-98",vendor: "K2E Tekpoint"},
  {max_prefix: "2C-92-2C",vendor: "Kishu Giken Kogyou Company Ltd,."},
  {max_prefix: "D8-FE-E3",vendor: "D-Link International"},
  {max_prefix: "50-A0-BF",vendor: "Alba Fiber Systems Inc."},
  {max_prefix: "B8-36-D8",vendor: "Videoswitch"},
  {max_prefix: "58-F3-87",vendor: "Airios"},
  {max_prefix: "3C-97-7E",vendor: "IPS Technology Limited"},
  {max_prefix: "A4-FB-8D",vendor: "Hangzhou Dunchong Technology Co.Ltd"},
  {max_prefix: "10-7A-86",vendor: "U&U ENGINEERING INC."},
  {max_prefix: "88-23-64",vendor: "Watchnet DVR Inc"},
  {max_prefix: "58-1C-BD",vendor: "Affinegy"},
  {max_prefix: "28-4F-CE",vendor: "Liaoning Wontel Science and Technology Development Co.,Ltd."},
  {max_prefix: "F4-CD-90",vendor: "Vispiron Rotec GmbH"},
  {max_prefix: "54-21-60",vendor: "Alula"},
  {max_prefix: "80-6C-8B",vendor: "KAESER KOMPRESSOREN AG"},
  {max_prefix: "30-65-EC",vendor: "Wistron (ChongQing)"},
  {max_prefix: "10-01-CA",vendor: "Ashley Butterworth"},
  {max_prefix: "24-6A-AB",vendor: "IT-IS International"},
  {max_prefix: "54-2C-EA",vendor: "PROTECTRON"},
  {max_prefix: "DC-82-5B",vendor: "JANUS, spol. s r.o."},
  {max_prefix: "9C-A5-77",vendor: "Osorno Enterprises Inc."},
  {max_prefix: "C0-43-01",vendor: "Epec Oy"},
  {max_prefix: "E0-7C-62",vendor: "Whistle Labs, Inc."},
  {max_prefix: "60-1E-02",vendor: "EltexAlatau"},
  {max_prefix: "F0-7F-0C",vendor: "Leopold Kostal GmbH &Co. KG"},
  {max_prefix: "4C-62-55",vendor: "SANMINA-SCI SYSTEM DE MEXICO S.A. DE C.V."},
  {max_prefix: "18-D6-CF",vendor: "Kurth Electronic GmbH"},
  {max_prefix: "F4-81-39",vendor: "CANON INC."},
  {max_prefix: "18-36-FC",vendor: "Elecsys International Corporation"},
  {max_prefix: "A4-D0-94",vendor: "VIVAVIS AG"},
  {max_prefix: "2C-94-64",vendor: "Cincoze Co., Ltd."},
  {max_prefix: "80-4B-20",vendor: "Ventilation Control"},
  {max_prefix: "28-79-94",vendor: "Realplay Digital Technology(Shenzhen) Co.,Ltd"},
  {max_prefix: "94-B8-C5",vendor: "RuggedCom Inc."},
  {max_prefix: "60-4A-1C",vendor: "SUYIN Corporation"},
  {max_prefix: "08-27-19",vendor: "APS systems/electronic AG"},
  {max_prefix: "50-5A-C6",vendor: "GUANGDONG SUPER TELECOM CO.,LTD."},
  {max_prefix: "B8-63-BC",vendor: "ROBOTIS, Co, Ltd"},
  {max_prefix: "98-0D-2E",vendor: "HTC Corporation"},
  {max_prefix: "9C-79-AC",vendor: "Suntec Software(Shanghai) Co., Ltd."},
  {max_prefix: "C4-19-EC",vendor: "Qualisys AB"},
  {max_prefix: "D4-64-F7",vendor: "CHENGDU USEE DIGITAL TECHNOLOGY CO., LTD"},
  {max_prefix: "64-72-D8",vendor: "GooWi Technology Co.,Limited"},
  {max_prefix: "84-62-23",vendor: "Shenzhen Coship Electronics Co., Ltd."},
  {max_prefix: "1C-FA-68",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "DC-1D-D4",vendor: "Microstep-MIS spol. s r.o."},
  {max_prefix: "FC-DD-55",vendor: "Shenzhen WeWins wireless Co.,Ltd"},
  {max_prefix: "B0-17-43",vendor: "EDISON GLOBAL CIRCUITS LLC"},
  {max_prefix: "60-E0-0E",vendor: "SHINSEI ELECTRONICS CO LTD"},
  {max_prefix: "54-54-14",vendor: "Digital RF Corea, Inc"},
  {max_prefix: "24-EB-65",vendor: "SAET I.S. S.r.l."},
  {max_prefix: "E8-94-F6",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "50-CD-32",vendor: "NanJing Chaoran Science & Technology Co.,Ltd."},
  {max_prefix: "E0-ED-C7",vendor: "Shenzhen Friendcom Technology Development Co., Ltd"},
  {max_prefix: "D4-A4-99",vendor: "InView Technology Corporation"},
  {max_prefix: "08-48-2C",vendor: "Raycore Taiwan Co., LTD."},
  {max_prefix: "94-98-A2",vendor: "Shanghai LISTEN TECH.LTD"},
  {max_prefix: "DC-2B-CA",vendor: "Zera GmbH"},
  {max_prefix: "5C-84-86",vendor: "Brightsource Industries Israel LTD"},
  {max_prefix: "BC-BA-E1",vendor: "AREC Inc."},
  {max_prefix: "18-FA-6F",vendor: "ISC applied systems corp"},
  {max_prefix: "B4-DD-15",vendor: "ControlThings Oy Ab"},
  {max_prefix: "E0-C6-B3",vendor: "MilDef AB"},
  {max_prefix: "18-84-10",vendor: "CoreTrust Inc."},
  {max_prefix: "FC-22-9C",vendor: "Han Kyung I Net Co.,Ltd."},
  {max_prefix: "18-32-A2",vendor: "LAON TECHNOLOGY CO., LTD."},
  {max_prefix: "40-51-6C",vendor: "Grandex International Corporation"},
  {max_prefix: "74-D0-2B",vendor: "ASUSTek COMPUTER INC."},
  {max_prefix: "A0-1C-05",vendor: "NIMAX TELECOM CO.,LTD."},
  {max_prefix: "D0-BE-2C",vendor: "CNSLink Co., Ltd."},
  {max_prefix: "2C-26-C5",vendor: "zte corporation"},
  {max_prefix: "94-C9-62",vendor: "Teseq AG"},
  {max_prefix: "DC-2A-14",vendor: "Shanghai Longjing Technology Co."},
  {max_prefix: "F8-2E-DB",vendor: "RTW GmbH & Co. KG"},
  {max_prefix: "C0-4A-00",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "04-5F-A7",vendor: "Shenzhen Yichen Technology Development Co.,LTD"},
  {max_prefix: "80-8B-5C",vendor: "Shenzhen Runhuicheng Technology Co., Ltd"},
  {max_prefix: "C0-5E-6F",vendor: "V. Stonkaus firma Kodinis Raktas"},
  {max_prefix: "C0-88-5B",vendor: "SnD Tech Co., Ltd."},
  {max_prefix: "3C-FB-96",vendor: "Emcraft Systems LLC"},
  {max_prefix: "08-1F-3F",vendor: "WondaLink Inc."},
  {max_prefix: "08-79-99",vendor: "AIM GmbH"},
  {max_prefix: "C0-11-A6",vendor: "Fort-Telecom ltd."},
  {max_prefix: "C0-B8-B1",vendor: "BitBox Ltd"},
  {max_prefix: "10-5F-06",vendor: "Actiontec Electronics, Inc"},
  {max_prefix: "18-53-E0",vendor: "Hanyang Digitech Co.Ltd"},
  {max_prefix: "00-C1-4F",vendor: "DDL Co,.ltd."},
  {max_prefix: "E0-CE-C3",vendor: "ASKEY COMPUTER CORP"},
  {max_prefix: "F0-21-9D",vendor: "Cal-Comp Electronics & Communications Company Ltd."},
  {max_prefix: "18-17-25",vendor: "Cameo Communications, Inc."},
  {max_prefix: "84-62-A6",vendor: "EuroCB (Phils), Inc."},
  {max_prefix: "E4-F3-65",vendor: "Time-O-Matic, Inc."},
  {max_prefix: "DC-C0-DB",vendor: "Shenzhen Kaiboer Technology Co., Ltd."},
  {max_prefix: "AC-5D-10",vendor: "Pace Americas"},
  {max_prefix: "88-F4-90",vendor: "Jetmobile Pte Ltd"},
  {max_prefix: "1C-91-79",vendor: "Integrated System Technologies Ltd"},
  {max_prefix: "84-C8-B1",vendor: "Incognito Software Systems Inc."},
  {max_prefix: "30-D3-57",vendor: "Logosol, Inc."},
  {max_prefix: "BC-39-A6",vendor: "CSUN System Technology Co.,LTD"},
  {max_prefix: "EC-B5-41",vendor: "SHINANO E and E Co.Ltd."},
  {max_prefix: "74-37-2F",vendor: "Tongfang Shenzhen Cloudcomputing Technology Co.,Ltd"},
  {max_prefix: "E8-A3-64",vendor: "Signal Path International / Peachtree Audio"},
  {max_prefix: "BC-51-FE",vendor: "Swann communications Pty Ltd"},
  {max_prefix: "FC-62-6E",vendor: "Beijing MDC Telecom"},
  {max_prefix: "68-86-E7",vendor: "Orbotix, Inc."},
  {max_prefix: "D8-19-CE",vendor: "Telesquare"},
  {max_prefix: "38-F5-97",vendor: "home2net GmbH"},
  {max_prefix: "80-7B-1E",vendor: "Corsair Memory, Inc."},
  {max_prefix: "B4-AB-2C",vendor: "MtM Technology Corporation"},
  {max_prefix: "74-E4-24",vendor: "APISTE CORPORATION"},
  {max_prefix: "A0-BA-B8",vendor: "Pixon Imaging"},
  {max_prefix: "D8-D5-B9",vendor: "Rainforest Automation, Inc."},
  {max_prefix: "24-11-D0",vendor: "Chongqing Ehs Science and Technology Development Co.,Ltd."},
  {max_prefix: "B4-61-FF",vendor: "Lumigon A/S"},
  {max_prefix: "A0-A1-30",vendor: "DLI Taiwan Branch office"},
  {max_prefix: "30-21-5B",vendor: "Shenzhen Ostar Display Electronic Co.,Ltd"},
  {max_prefix: "CC-59-3E",vendor: "Sensium Healthcare Limited"},
  {max_prefix: "EC-E9-15",vendor: "STI Ltd"},
  {max_prefix: "80-D7-33",vendor: "QSR Automations, Inc."},
  {max_prefix: "34-FA-40",vendor: "Guangzhou Robustel Technologies Co., Limited"},
  {max_prefix: "1C-5A-6B",vendor: "Philips Electronics Nederland BV"},
  {max_prefix: "A8-75-D6",vendor: "FreeTek International Co., Ltd."},
  {max_prefix: "10-A7-43",vendor: "SK Mtek Limited"},
  {max_prefix: "54-7F-A8",vendor: "TELCO systems, s.r.o."},
  {max_prefix: "54-74-E6",vendor: "Webtech Wireless"},
  {max_prefix: "C4-6D-F1",vendor: "DataGravity"},
  {max_prefix: "30-3D-08",vendor: "GLINTT TES S.A."},
  {max_prefix: "A8-1F-AF",vendor: "KRYPTON POLSKA"},
  {max_prefix: "08-E5-DA",vendor: "NANJING FUJITSU COMPUTER PRODUCTS CO.,LTD."},
  {max_prefix: "E4-C1-46",vendor: "Objetivos y Servicios de Valor A"},
  {max_prefix: "D4-00-57",vendor: "MC Technologies GmbH"},
  {max_prefix: "D4-10-CF",vendor: "Huanshun Network Science and Technology Co., Ltd."},
  {max_prefix: "6C-B3-11",vendor: "Shenzhen Lianrui Electronics Co.,Ltd"},
  {max_prefix: "94-FD-2E",vendor: "Shanghai Uniscope Technologies Co.,Ltd"},
  {max_prefix: "58-84-E4",vendor: "IP500 Alliance e.V."},
  {max_prefix: "B0-35-8D",vendor: "Nokia Corporation"},
  {max_prefix: "F8-E4-FB",vendor: "Actiontec Electronics, Inc"},
  {max_prefix: "8C-4A-EE",vendor: "GIGA TMS INC"},
  {max_prefix: "34-C9-9D",vendor: "EIDOLON COMMUNICATIONS TECHNOLOGY CO. LTD."},
  {max_prefix: "78-9F-87",vendor: "Siemens AG I IA PP PRM"},
  {max_prefix: "5C-E0-F6",vendor: "NIC.br- Nucleo de Informacao e Coordenacao do Ponto BR"},
  {max_prefix: "C8-3D-97",vendor: "Nokia Corporation"},
  {max_prefix: "0C-F3-61",vendor: "Java Information"},
  {max_prefix: "C8-C7-91",vendor: "Zero1.tv GmbH"},
  {max_prefix: "EC-D9-25",vendor: "RAMI"},
  {max_prefix: "00-5D-03",vendor: "Xilinx, Inc"},
  {max_prefix: "E8-71-8D",vendor: "Elsys Equipamentos Eletronicos Ltda"},
  {max_prefix: "D0-73-8E",vendor: "DONG OH PRECISION CO., LTD."},
  {max_prefix: "64-C9-44",vendor: "LARK Technologies, Inc"},
  {max_prefix: "60-0F-77",vendor: "SilverPlus, Inc"},
  {max_prefix: "0C-93-FB",vendor: "BNS Solutions"},
  {max_prefix: "E4-4F-5F",vendor: "EDS Elektronik Destek San.Tic.Ltd.Sti"},
  {max_prefix: "E8-6D-54",vendor: "Digit Mobile Inc"},
  {max_prefix: "80-2F-DE",vendor: "Zurich Instruments AG"},
  {max_prefix: "5C-38-E0",vendor: "Shanghai Super Electronics Technology Co.,LTD"},
  {max_prefix: "08-AF-78",vendor: "Totus Solutions, Inc."},
  {max_prefix: "1C-94-92",vendor: "RUAG Schweiz AG"},
  {max_prefix: "B8-89-CA",vendor: "ILJIN ELECTRIC Co., Ltd."},
  {max_prefix: "64-F5-0E",vendor: "Kinion Technology Company Limited"},
  {max_prefix: "AC-E6-4B",vendor: "Shenzhen Baojia Battery Technology Co., Ltd."},
  {max_prefix: "04-4B-FF",vendor: "GuangZhou Hedy Digital Technology Co., Ltd"},
  {max_prefix: "28-29-D9",vendor: "GlobalBeiMing technology (Beijing)Co. Ltd"},
  {max_prefix: "88-61-5A",vendor: "Siano Mobile Silicon Ltd."},
  {max_prefix: "70-E2-4C",vendor: "SAE IT-systems GmbH & Co. KG"},
  {max_prefix: "00-FD-4C",vendor: "NEVATEC"},
  {max_prefix: "14-43-19",vendor: "Creative&Link Technology Limited"},
  {max_prefix: "7C-C8-D0",vendor: "TIANJIN YAAN TECHNOLOGY CO., LTD."},
  {max_prefix: "88-E9-17",vendor: "Tamaggo"},
  {max_prefix: "90-9D-E0",vendor: "Newland Design + Assoc. Inc."},
  {max_prefix: "D8-AF-F1",vendor: "Panasonic Appliances Company"},
  {max_prefix: "2C-62-89",vendor: "Regenersis (Glenrothes) Ltd"},
  {max_prefix: "18-48-D8",vendor: "Fastback Networks"},
  {max_prefix: "F0-D3-E7",vendor: "Sensometrix SA"},
  {max_prefix: "B0-12-66",vendor: "Futaba-Kikaku"},
  {max_prefix: "58-EC-E1",vendor: "Newport Corporation"},
  {max_prefix: "4C-09-B4",vendor: "zte corporation"},
  {max_prefix: "58-CF-4B",vendor: "Lufkin Industries"},
  {max_prefix: "68-B4-3A",vendor: "WaterFurnace International, Inc."},
  {max_prefix: "60-D2-B9",vendor: "REALAND BIO CO., LTD."},
  {max_prefix: "30-FD-11",vendor: "MACROTECH (USA) INC."},
  {max_prefix: "60-32-F0",vendor: "Mplus technology"},
  {max_prefix: "D8-AF-3B",vendor: "Hangzhou Bigbright Integrated communications system Co.,Ltd"},
  {max_prefix: "C4-DA-26",vendor: "NOBLEX SA"},
  {max_prefix: "7C-C8-AB",vendor: "Acro Associates, Inc."},
  {max_prefix: "10-F3-DB",vendor: "Gridco Systems, Inc."},
  {max_prefix: "60-19-29",vendor: "VOLTRONIC POWER TECHNOLOGY(SHENZHEN) CORP."},
  {max_prefix: "48-B2-53",vendor: "Marketaxess Corporation"},
  {max_prefix: "30-5D-38",vendor: "Beissbarth"},
  {max_prefix: "D0-4C-C1",vendor: "SINTRONES Technology Corp."},
  {max_prefix: "50-3F-56",vendor: "Syncmold Enterprise Corp"},
  {max_prefix: "8C-EE-C6",vendor: "Precepscion Pty. Ltd."},
  {max_prefix: "10-12-48",vendor: "ITG, Inc."},
  {max_prefix: "74-94-3D",vendor: "AgJunction"},
  {max_prefix: "3C-F3-92",vendor: "Virtualtek. Co. Ltd"},
  {max_prefix: "14-9F-E8",vendor: "Lenovo Mobile Communication Technology Ltd."},
  {max_prefix: "BC-D9-40",vendor: "ASR Co,.Ltd."},
  {max_prefix: "04-9C-62",vendor: "BMT Medical Technology s.r.o."},
  {max_prefix: "0C-2A-69",vendor: "electric imp, incorporated"},
  {max_prefix: "C4-55-C2",vendor: "Bach-Simpson"},
  {max_prefix: "00-E8-AB",vendor: "Meggitt Training Systems, Inc."},
  {max_prefix: "B4-21-8A",vendor: "Dog Hunter LLC"},
  {max_prefix: "4C-78-97",vendor: "Arrowhead Alarm Products Ltd"},
  {max_prefix: "44-E8-A5",vendor: "Myreka Technologies Sdn. Bhd."},
  {max_prefix: "B4-82-C5",vendor: "Relay2, Inc."},
  {max_prefix: "98-5E-1B",vendor: "ConversDigital Co., Ltd."},
  {max_prefix: "60-D1-AA",vendor: "Vishal Telecommunications Pvt Ltd"},
  {max_prefix: "70-9B-A5",vendor: "Shenzhen Y&D Electronics Co.,LTD."},
  {max_prefix: "B4-89-10",vendor: "Coster T.E. S.P.A."},
  {max_prefix: "B0-C8-3F",vendor: "Jiangsu Cynray IOT Co., Ltd."},
  {max_prefix: "38-8A-B7",vendor: "ITC Networks"},
  {max_prefix: "BC-C2-3A",vendor: "Thomson Video Networks"},
  {max_prefix: "D4-8C-B5",vendor: "Cisco Systems, Inc"},
  {max_prefix: "AC-C2-EC",vendor: "CLT INT'L IND. CORP."},
  {max_prefix: "DC-37-D2",vendor: "Hunan HKT Electronic Technology Co., Ltd"},
  {max_prefix: "54-9D-85",vendor: "EnerAccess inc"},
  {max_prefix: "B0-75-0C",vendor: "QA Cafe"},
  {max_prefix: "B4-E1-EB",vendor: "Private"},
  {max_prefix: "A8-65-B2",vendor: "DONGGUAN YISHANG ELECTRONIC TECHNOLOGY CO., LIMITED"},
  {max_prefix: "E8-D0-FA",vendor: "MKS Instruments Deutschland GmbH"},
  {max_prefix: "98-26-2A",vendor: "Applied Research Associates, Inc"},
  {max_prefix: "3C-91-74",vendor: "ALONG COMMUNICATION TECHNOLOGY"},
  {max_prefix: "FC-50-90",vendor: "SIMEX Sp. z o.o."},
  {max_prefix: "60-B9-82",vendor: "RO.VE.R. Laboratories S.p.A."},
  {max_prefix: "34-81-37",vendor: "UNICARD SA"},
  {max_prefix: "38-B1-2D",vendor: "Sonotronic Nagel GmbH"},
  {max_prefix: "AC-EE-3B",vendor: "6harmonics Inc"},
  {max_prefix: "1C-6B-CA",vendor: "Mitsunami Co., Ltd."},
  {max_prefix: "E8-3E-FB",vendor: "GEODESIC LTD."},
  {max_prefix: "64-24-00",vendor: "Xorcom Ltd."},
  {max_prefix: "9C-E1-0E",vendor: "NCTech Ltd"},
  {max_prefix: "A0-6D-09",vendor: "Intelcan Technosystems Inc."},
  {max_prefix: "60-F3-DA",vendor: "Logic Way GmbH"},
  {max_prefix: "B4-62-38",vendor: "Exablox"},
  {max_prefix: "C8-BB-D3",vendor: "Embrane"},
  {max_prefix: "EC-D1-9A",vendor: "Zhuhai Liming Industries Co., Ltd"},
  {max_prefix: "3C-36-3D",vendor: "Nokia Corporation"},
  {max_prefix: "80-86-98",vendor: "Netronics Technologies Inc."},
  {max_prefix: "40-70-74",vendor: "Life Technology (China) Co., Ltd"},
  {max_prefix: "20-F0-02",vendor: "MTData Developments Pty. Ltd."},
  {max_prefix: "1C-F4-CA",vendor: "Private"},
  {max_prefix: "B4-A4-B5",vendor: "Zen Eye Co.,Ltd"},
  {max_prefix: "AC-14-61",vendor: "ATAW  Co., Ltd."},
  {max_prefix: "E4-C6-E6",vendor: "Mophie, LLC"},
  {max_prefix: "50-2D-1D",vendor: "Nokia Corporation"},
  {max_prefix: "F4-8E-09",vendor: "Nokia Corporation"},
  {max_prefix: "58-48-C0",vendor: "COFLEC"},
  {max_prefix: "8C-60-4F",vendor: "Cisco Systems, Inc"},
  {max_prefix: "A4-B9-80",vendor: "Parking BOXX Inc."},
  {max_prefix: "A4-7C-14",vendor: "ChargeStorm AB"},
  {max_prefix: "3C-9F-81",vendor: "Shenzhen CATIC Bit Communications Technology Co.,Ltd"},
  {max_prefix: "44-5F-7A",vendor: "Shihlin Electric & Engineering Corp."},
  {max_prefix: "14-1A-51",vendor: "Treetech Sistemas Digitais"},
  {max_prefix: "58-7F-C8",vendor: "S2M"},
  {max_prefix: "20-05-05",vendor: "RADMAX COMMUNICATION PRIVATE LIMITED"},
  {max_prefix: "F8-DB-4C",vendor: "PNY Technologies, INC."},
  {max_prefix: "0C-9D-56",vendor: "Consort Controls Ltd"},
  {max_prefix: "00-2D-76",vendor: "TITECH GmbH"},
  {max_prefix: "80-20-AF",vendor: "Trade FIDES, a.s."},
  {max_prefix: "3C-B8-7A",vendor: "Private"},
  {max_prefix: "08-8F-2C",vendor: "Amber Technology Ltd."},
  {max_prefix: "44-13-19",vendor: "WKK TECHNOLOGY LTD."},
  {max_prefix: "98-02-84",vendor: "Theobroma Systems GmbH"},
  {max_prefix: "1C-D4-0C",vendor: "Kriwan Industrie-Elektronik GmbH"},
  {max_prefix: "2C-75-0F",vendor: "Shanghai Dongzhou-Lawton Communication Technology Co. Ltd."},
  {max_prefix: "5C-50-15",vendor: "Cisco Systems, Inc"},
  {max_prefix: "C0-35-BD",vendor: "Velocytech Aps"},
  {max_prefix: "28-71-84",vendor: "Spire Payments"},
  {max_prefix: "7C-B0-3E",vendor: "OSRAM GmbH"},
  {max_prefix: "BC-8B-55",vendor: "NPP ELIKS America Inc. DBA T&M Atlantic"},
  {max_prefix: "C0-49-3D",vendor: "MAITRISE TECHNOLOGIQUE"},
  {max_prefix: "C8-45-44",vendor: "Asia Pacific CIS (Wuxi) Co, Ltd"},
  {max_prefix: "E0-EF-25",vendor: "Lintes Technology Co., Ltd."},
  {max_prefix: "8C-57-FD",vendor: "LVX Western"},
  {max_prefix: "54-E6-3F",vendor: "ShenZhen LingKeWeiEr Technology Co., Ltd."},
  {max_prefix: "20-FA-BB",vendor: "Cambridge Executive Limited"},
  {max_prefix: "2C-BE-97",vendor: "Ingenieurbuero Bickele und Buehler GmbH"},
  {max_prefix: "B0-1C-91",vendor: "Elim Co"},
  {max_prefix: "04-F1-7D",vendor: "Tarana Wireless"},
  {max_prefix: "50-ED-94",vendor: "EGATEL SL"},
  {max_prefix: "48-A2-2D",vendor: "Shenzhen Huaxuchang Telecom Technology Co.,Ltd"},
  {max_prefix: "C8-60-00",vendor: "ASUSTek COMPUTER INC."},
  {max_prefix: "70-A6-6A",vendor: "Prox Dynamics AS"},
  {max_prefix: "DC-3E-51",vendor: "Solberg & Andersen AS"},
  {max_prefix: "18-B5-91",vendor: "I-Storm"},
  {max_prefix: "84-49-15",vendor: "vArmour Networks, Inc."},
  {max_prefix: "D8-78-E5",vendor: "KUHN SA"},
  {max_prefix: "D8-24-BD",vendor: "Cisco Systems, Inc"},
  {max_prefix: "28-CD-1C",vendor: "Espotel Oy"},
  {max_prefix: "D4-43-A8",vendor: "Changzhou Haojie Electric Co., Ltd."},
  {max_prefix: "BC-E5-9F",vendor: "WATERWORLD Technology Co.,LTD"},
  {max_prefix: "70-41-B7",vendor: "Edwards Lifesciences LLC"},
  {max_prefix: "DC-A8-CF",vendor: "New Spin Golf, LLC."},
  {max_prefix: "A8-49-A5",vendor: "Lisantech Co., Ltd."},
  {max_prefix: "A0-5E-6B",vendor: "MELPER Co., Ltd."},
  {max_prefix: "F8-35-53",vendor: "Magenta Research Ltd."},
  {max_prefix: "F4-04-4C",vendor: "ValenceTech Limited"},
  {max_prefix: "34-97-FB",vendor: "ADVANCED RF TECHNOLOGIES INC"},
  {max_prefix: "F0-3A-55",vendor: "Omega Elektronik AS"},
  {max_prefix: "C4-67-B5",vendor: "Libratone A/S"},
  {max_prefix: "4C-39-10",vendor: "Newtek Electronics co., Ltd."},
  {max_prefix: "90-3A-A0",vendor: "Nokia"},
  {max_prefix: "B0-6C-BF",vendor: "3ality Digital Systems GmbH"},
  {max_prefix: "98-BC-57",vendor: "SVA TECHNOLOGIES CO.LTD"},
  {max_prefix: "DC-3C-2E",vendor: "Manufacturing System Insights, Inc."},
  {max_prefix: "AC-0D-FE",vendor: "Ekon GmbH - myGEKKO"},
  {max_prefix: "FC-5B-26",vendor: "MikroBits"},
  {max_prefix: "40-F4-07",vendor: "Nintendo Co., Ltd."},
  {max_prefix: "90-0A-3A",vendor: "PSG Plastic Service GmbH"},
  {max_prefix: "54-D0-ED",vendor: "AXIM Communications"},
  {max_prefix: "64-4B-F0",vendor: "CalDigit, Inc"},
  {max_prefix: "84-36-11",vendor: "hyungseul publishing networks"},
  {max_prefix: "34-40-B5",vendor: "IBM"},
  {max_prefix: "64-ED-62",vendor: "WOORI SYSTEMS Co., Ltd"},
  {max_prefix: "5C-C9-D3",vendor: "PALLADIUM ENERGY ELETRONICA DA AMAZONIA LTDA"},
  {max_prefix: "C8-1A-FE",vendor: "DLOGIC GmbH"},
  {max_prefix: "EC-63-E5",vendor: "ePBoard Design LLC"},
  {max_prefix: "94-DB-49",vendor: "SITCORP"},
  {max_prefix: "B4-94-4E",vendor: "WeTelecom Co., Ltd."},
  {max_prefix: "E0-0B-28",vendor: "Inovonics"},
  {max_prefix: "48-02-2A",vendor: "B-Link Electronic Limited"},
  {max_prefix: "34-5B-11",vendor: "EVI HEAT AB"},
  {max_prefix: "F0-62-0D",vendor: "Shenzhen Egreat Tech Corp.,Ltd"},
  {max_prefix: "2C-67-FB",vendor: "ShenZhen Zhengjili Electronics Co., LTD"},
  {max_prefix: "D4-D7-48",vendor: "Cisco Systems, Inc"},
  {max_prefix: "34-4F-69",vendor: "EKINOPS SAS"},
  {max_prefix: "F8-31-3E",vendor: "endeavour GmbH"},
  {max_prefix: "14-36-05",vendor: "Nokia Corporation"},
  {max_prefix: "3C-E5-B4",vendor: "KIDASEN INDUSTRIA E COMERCIO DE ANTENAS LTDA"},
  {max_prefix: "08-D0-9F",vendor: "Cisco Systems, Inc"},
  {max_prefix: "2C-00-2C",vendor: "UNOWHY"},
  {max_prefix: "70-CA-9B",vendor: "Cisco Systems, Inc"},
  {max_prefix: "C8-7C-BC",vendor: "Valink Co., Ltd."},
  {max_prefix: "B8-14-13",vendor: "Keen High Holding(HK) Ltd."},
  {max_prefix: "CC-A3-74",vendor: "Guangdong Guanglian Electronic Technology Co.Ltd"},
  {max_prefix: "58-67-7F",vendor: "Clare Controls Inc."},
  {max_prefix: "8C-8A-6E",vendor: "ESTUN AUTOMATION TECHNOLOY CO., LTD"},
  {max_prefix: "68-BC-0C",vendor: "Cisco Systems, Inc"},
  {max_prefix: "CC-6B-F1",vendor: "Sound Masking Inc."},
  {max_prefix: "18-E8-0F",vendor: "Viking Electronics Inc."},
  {max_prefix: "2C-97-17",vendor: "I.C.Y. B.V."},
  {max_prefix: "78-BA-D0",vendor: "Shinybow Technology Co. Ltd."},
  {max_prefix: "2C-3F-38",vendor: "Cisco Systems, Inc"},
  {max_prefix: "40-50-E0",vendor: "Milton Security Group LLC"},
  {max_prefix: "00-08-30",vendor: "Cisco Systems, Inc"},
  {max_prefix: "C4-EE-AE",vendor: "VSS Monitoring"},
  {max_prefix: "F8-D3-A9",vendor: "AXAN Networks"},
  {max_prefix: "24-E6-BA",vendor: "JSC Zavod im. Kozitsky"},
  {max_prefix: "0C-5A-19",vendor: "Axtion Sdn Bhd"},
  {max_prefix: "A8-BD-1A",vendor: "Honey Bee (Hong Kong) Limited"},
  {max_prefix: "98-82-17",vendor: "Disruptive Ltd"},
  {max_prefix: "9C-A3-BA",vendor: "SAKURA Internet Inc."},
  {max_prefix: "A0-78-BA",vendor: "Pantech Co., Ltd."},
  {max_prefix: "9C-5C-8D",vendor: "FIREMAX IND\u00daSTRIA E COM\u00c9RCIO DE PRODUTOS ELETR\u00d4NICOS  LTDA"},
  {max_prefix: "D4-20-6D",vendor: "HTC Corporation"},
  {max_prefix: "7C-1E-52",vendor: "Microsoft"},
  {max_prefix: "DC-B4-C4",vendor: "Microsoft XCG"},
  {max_prefix: "AC-CB-09",vendor: "Hefcom Metering (Pty) Ltd"},
  {max_prefix: "18-66-E3",vendor: "Veros Systems, Inc."},
  {max_prefix: "74-FD-A0",vendor: "Compupal (Group) Corporation"},
  {max_prefix: "CC-B8-F1",vendor: "EAGLE KINGDOM TECHNOLOGIES LIMITED"},
  {max_prefix: "A4-29-B7",vendor: "bluesky"},
  {max_prefix: "48-F3-17",vendor: "Private"},
  {max_prefix: "D4-1C-1C",vendor: "RCF S.P.A."},
  {max_prefix: "04-88-8C",vendor: "Eifelwerk Butler Systeme GmbH"},
  {max_prefix: "CC-F8-F0",vendor: "Xi'an HISU Multimedia Technology Co.,Ltd."},
  {max_prefix: "BC-77-9F",vendor: "SBM Co., Ltd."},
  {max_prefix: "40-6A-AB",vendor: "RIM"},
  {max_prefix: "24-87-07",vendor: "SEnergy Corporation"},
  {max_prefix: "D4-5A-B2",vendor: "Galleon Systems"},
  {max_prefix: "C4-C1-9F",vendor: "National Oilwell Varco Instrumentation, Monitoring, and Optimization (NOV IMO)"},
  {max_prefix: "58-92-0D",vendor: "Kinetic Avionics Limited"},
  {max_prefix: "30-DE-86",vendor: "Cedac Software S.r.l."},
  {max_prefix: "18-C4-51",vendor: "Tucson Embedded Systems"},
  {max_prefix: "70-46-42",vendor: "CHYNG HONG ELECTRONIC CO., LTD."},
  {max_prefix: "28-0C-B8",vendor: "Mikrosay Yazilim ve Elektronik A.S."},
  {max_prefix: "3C-C9-9E",vendor: "Huiyang Technology Co., Ltd"},
  {max_prefix: "AC-02-EF",vendor: "Comsis"},
  {max_prefix: "B8-B4-2E",vendor: "Gionee Communication Equipment Co,Ltd.ShenZhen"},
  {max_prefix: "44-3E-B2",vendor: "DEOTRON Co., LTD."},
  {max_prefix: "D0-59-C3",vendor: "CeraMicro Technology Corporation"},
  {max_prefix: "B4-FC-75",vendor: "SEMA Electronics(HK) CO.,LTD"},
  {max_prefix: "B0-BF-99",vendor: "WIZITDONGDO"},
  {max_prefix: "FC-17-94",vendor: "InterCreative Co., Ltd"},
  {max_prefix: "B4-0B-7A",vendor: "Brusa Elektronik AG"},
  {max_prefix: "18-2C-91",vendor: "Concept Development, Inc."},
  {max_prefix: "1C-8E-8E",vendor: "DB Communication & Systems Co., ltd."},
  {max_prefix: "24-EC-99",vendor: "ASKEY COMPUTER CORP"},
  {max_prefix: "2C-1E-EA",vendor: "AERODEV"},
  {max_prefix: "78-BE-B6",vendor: "Enhanced Vision"},
  {max_prefix: "A4-4B-15",vendor: "Sun Cupid Technology (HK) LTD"},
  {max_prefix: "48-C8-62",vendor: "Simo Wireless,Inc."},
  {max_prefix: "A0-16-5C",vendor: "Triteka LTD"},
  {max_prefix: "44-9C-B5",vendor: "Alcomp, Inc"},
  {max_prefix: "40-F1-4C",vendor: "ISE Europe SPRL"},
  {max_prefix: "E8-94-4C",vendor: "Cogent Healthcare Systems Ltd"},
  {max_prefix: "90-67-F3",vendor: "Alcatel Lucent"},
  {max_prefix: "9C-41-7C",vendor: "Hame  Technology Co.,  Limited"},
  {max_prefix: "9C-6A-BE",vendor: "QEES ApS."},
  {max_prefix: "9C-93-4E",vendor: "Xerox Corporation"},
  {max_prefix: "2C-8B-F2",vendor: "Hitachi Metals America Ltd"},
  {max_prefix: "68-F8-95",vendor: "Redflow Limited"},
  {max_prefix: "70-B9-21",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "A0-E2-95",vendor: "DAT System Co.,Ltd"},
  {max_prefix: "38-26-CD",vendor: "ANDTEK"},
  {max_prefix: "D8-97-3B",vendor: "Artesyn Embedded Technologies"},
  {max_prefix: "D4-F0-B4",vendor: "Napco Security Technologies"},
  {max_prefix: "90-09-17",vendor: "Far-sighted mobile"},
  {max_prefix: "F4-A5-2A",vendor: "Hawa Technologies Inc"},
  {max_prefix: "E8-B7-48",vendor: "Cisco Systems, Inc"},
  {max_prefix: "0C-6E-4F",vendor: "PrimeVOLT Co., Ltd."},
  {max_prefix: "BC-99-BC",vendor: "FonSee Technology Inc."},
  {max_prefix: "78-3F-15",vendor: "EasySYNC Ltd."},
  {max_prefix: "14-74-11",vendor: "RIM"},
  {max_prefix: "F8-A9-DE",vendor: "PUISSANCE PLUS"},
  {max_prefix: "B8-D4-9D",vendor: "M Seven System Ltd."},
  {max_prefix: "88-BF-D5",vendor: "Simple Audio Ltd"},
  {max_prefix: "24-CB-E7",vendor: "MYK, Inc."},
  {max_prefix: "B0-A1-0A",vendor: "Pivotal Systems Corporation"},
  {max_prefix: "80-2D-E1",vendor: "Solarbridge Technologies"},
  {max_prefix: "98-60-22",vendor: "EMW Co., Ltd."},
  {max_prefix: "18-D0-71",vendor: "DASAN CO., LTD."},
  {max_prefix: "3C-67-2C",vendor: "Sciovid Inc."},
  {max_prefix: "58-E4-76",vendor: "CENTRON COMMUNICATIONS TECHNOLOGIES FUJIAN CO.,LTD"},
  {max_prefix: "44-7E-95",vendor: "Alpha and Omega, Inc"},
  {max_prefix: "04-46-65",vendor: "Murata Manufacturing Co., Ltd."},
  {max_prefix: "18-AE-BB",vendor: "Siemens Convergence Creators GmbH&Co.KG"},
  {max_prefix: "50-FA-AB",vendor: "L-tek d.o.o."},
  {max_prefix: "38-91-FB",vendor: "Xenox Holding BV"},
  {max_prefix: "A8-E0-18",vendor: "Nokia Corporation"},
  {max_prefix: "BC-5F-F4",vendor: "ASRock Incorporation"},
  {max_prefix: "E8-B4-AE",vendor: "Shenzhen C&D Electronics Co.,Ltd"},
  {max_prefix: "78-1D-FD",vendor: "Jabil Inc"},
  {max_prefix: "CC-C6-2B",vendor: "Tri-Systems Corporation"},
  {max_prefix: "D8-C0-68",vendor: "Netgenetech.co.,ltd."},
  {max_prefix: "60-11-99",vendor: "Siama Systems Inc"},
  {max_prefix: "A8-8C-EE",vendor: "MicroMade Galka i Drozdz sp.j."},
  {max_prefix: "DC-2B-66",vendor: "InfoBLOCK S.A. de C.V."},
  {max_prefix: "6C-81-FE",vendor: "Mitsuba Corporation"},
  {max_prefix: "C0-27-B9",vendor: "Beijing National Railway Research & Design Institute  of Signal & Communication Co., Ltd."},
  {max_prefix: "B0-BD-A1",vendor: "ZAKLAD ELEKTRONICZNY SIMS"},
  {max_prefix: "70-B2-65",vendor: "Hiltron s.r.l."},
  {max_prefix: "64-D1-A3",vendor: "Sitecom Europe BV"},
  {max_prefix: "F4-3E-9D",vendor: "Benu Networks, Inc."},
  {max_prefix: "DC-9B-1E",vendor: "Intercom, Inc."},
  {max_prefix: "B8-87-1E",vendor: "Good Mind Industries Co., Ltd."},
  {max_prefix: "D4-F0-27",vendor: "Trust Power Ltd."},
  {max_prefix: "64-09-4C",vendor: "Beijing Superbee Wireless Technology Co.,Ltd"},
  {max_prefix: "04-E2-F8",vendor: "AEP Ticketing solutions srl"},
  {max_prefix: "EC-9E-CD",vendor: "Artesyn Embedded Technologies"},
  {max_prefix: "8C-51-05",vendor: "Shenzhen ireadygo Information Technology CO.,LTD."},
  {max_prefix: "C8-20-8E",vendor: "Storagedata"},
  {max_prefix: "44-D2-CA",vendor: "Anvia TV Oy"},
  {max_prefix: "38-6E-21",vendor: "Wasion Group Ltd."},
  {max_prefix: "60-9E-64",vendor: "Vivonic GmbH"},
  {max_prefix: "BC-15-A6",vendor: "Taiwan Jantek Electronics,Ltd."},
  {max_prefix: "1C-19-DE",vendor: "eyevis GmbH"},
  {max_prefix: "9C-80-7D",vendor: "SYSCABLE Korea Inc."},
  {max_prefix: "00-54-AF",vendor: "Continental Automotive Systems Inc."},
  {max_prefix: "AC-CA-BA",vendor: "Midokura Co., Ltd."},
  {max_prefix: "0C-81-12",vendor: "Private"},
  {max_prefix: "9C-95-F8",vendor: "SmartDoor Systems, LLC"},
  {max_prefix: "A4-85-6B",vendor: "Q Electronics Ltd"},
  {max_prefix: "20-D5-AB",vendor: "Korea Infocom Co.,Ltd."},
  {max_prefix: "74-38-89",vendor: "ANNAX Anzeigesysteme GmbH"},
  {max_prefix: "7C-7D-41",vendor: "Jinmuyu Electronics Co., Ltd."},
  {max_prefix: "4C-14-80",vendor: "NOREGON SYSTEMS, INC"},
  {max_prefix: "0C-F3-EE",vendor: "EM Microelectronic"},
  {max_prefix: "DC-DE-CA",vendor: "Akyllor"},
  {max_prefix: "A0-AA-FD",vendor: "EraThink Technologies Corp."},
  {max_prefix: "28-72-F0",vendor: "ATHENA"},
  {max_prefix: "04-55-CA",vendor: "BriView (Xiamen) Corp."},
  {max_prefix: "AC-93-2F",vendor: "Nokia Corporation"},
  {max_prefix: "30-14-2D",vendor: "Piciorgros GmbH"},
  {max_prefix: "50-AF-73",vendor: "Shenzhen Bitland Information Technology Co., Ltd."},
  {max_prefix: "6C-A9-06",vendor: "Telefield Ltd"},
  {max_prefix: "78-22-3D",vendor: "Affirmed Networks"},
  {max_prefix: "3C-02-B1",vendor: "Creation Technologies LP"},
  {max_prefix: "E4-41-E6",vendor: "Ottec Technology GmbH"},
  {max_prefix: "60-DA-23",vendor: "Estech Co.,Ltd"},
  {max_prefix: "44-DC-CB",vendor: "SEMINDIA SYSTEMS PVT LTD"},
  {max_prefix: "F8-F0-14",vendor: "RackWare Inc."},
  {max_prefix: "BC-71-C1",vendor: "XTrillion, Inc."},
  {max_prefix: "E0-E8-E8",vendor: "Olive Telecommunication Pvt. Ltd"},
  {max_prefix: "60-52-D0",vendor: "FACTS Engineering"},
  {max_prefix: "5C-9A-D8",vendor: "FUJITSU LIMITED"},
  {max_prefix: "A4-C0-E1",vendor: "Nintendo Co., Ltd."},
  {max_prefix: "4C-3B-74",vendor: "VOGTEC(H.K.) Co., Ltd"},
  {max_prefix: "68-43-52",vendor: "Bhuu Limited"},
  {max_prefix: "EC-E9-0B",vendor: "SISTEMA SOLUCOES ELETRONICAS LTDA - EASYTECH"},
  {max_prefix: "A0-8C-9B",vendor: "Xtreme Technologies Corp"},
  {max_prefix: "0C-F0-B4",vendor: "Globalsat International Technology Ltd"},
  {max_prefix: "48-DF-1C",vendor: "Wuhan NEC Fibre Optic Communications industry Co. Ltd"},
  {max_prefix: "A8-39-44",vendor: "Actiontec Electronics, Inc"},
  {max_prefix: "74-E0-6E",vendor: "Ergophone GmbH"},
  {max_prefix: "B0-89-91",vendor: "LGE"},
  {max_prefix: "34-B5-71",vendor: "PLDS"},
  {max_prefix: "3C-74-37",vendor: "RIM"},
  {max_prefix: "EC-92-33",vendor: "Eddyfi NDT Inc"},
  {max_prefix: "D4-9C-8E",vendor: "University of FUKUI"},
  {max_prefix: "34-A1-83",vendor: "AWare, Inc"},
  {max_prefix: "98-73-C4",vendor: "Sage Electronic Engineering LLC"},
  {max_prefix: "B4-01-42",vendor: "GCI Science & Technology Co.,LTD"},
  {max_prefix: "74-0A-BC",vendor: "LightwaveRF Technology Ltd"},
  {max_prefix: "10-A1-3B",vendor: "FUJIKURA RUBBER LTD."},
  {max_prefix: "CC-34-D7",vendor: "GEWISS S.P.A."},
  {max_prefix: "F0-2A-61",vendor: "Waldo Networks, Inc."},
  {max_prefix: "C8-A7-0A",vendor: "Verizon Business"},
  {max_prefix: "30-4E-C3",vendor: "Tianjin Techua Technology Co., Ltd."},
  {max_prefix: "B4-CF-DB",vendor: "Shenzhen Jiuzhou Electric Co.,LTD"},
  {max_prefix: "C0-12-42",vendor: "Alpha Security Products"},
  {max_prefix: "BC-20-BA",vendor: "Inspur (Shandong) Electronic Information Co., Ltd"},
  {max_prefix: "1C-FE-A7",vendor: "IDentytech Solutins Ltd."},
  {max_prefix: "A0-DE-05",vendor: "JSC Irbis-T"},
  {max_prefix: "28-26-A6",vendor: "PBR electronics GmbH"},
  {max_prefix: "B4-28-F1",vendor: "E-Prime Co., Ltd."},
  {max_prefix: "AC-2F-A8",vendor: "Humannix Co.,Ltd."},
  {max_prefix: "10-64-E2",vendor: "ADFweb.com s.r.l."},
  {max_prefix: "CC-D8-11",vendor: "Aiconn Technology Corporation"},
  {max_prefix: "44-59-9F",vendor: "Criticare Systems, Inc"},
  {max_prefix: "3C-2F-3A",vendor: "SFORZATO Corp."},
  {max_prefix: "74-CE-56",vendor: "Packet Force Technology Limited Company"},
  {max_prefix: "AC-81-12",vendor: "Gemtek Technology Co., Ltd."},
  {max_prefix: "68-63-59",vendor: "Advanced Digital Broadcast SA"},
  {max_prefix: "18-92-2C",vendor: "Virtual Instruments"},
  {max_prefix: "28-06-1E",vendor: "NINGBO GLOBAL USEFUL ELECTRIC CO.,LTD"},
  {max_prefix: "64-E8-E6",vendor: "global moisture management system"},
  {max_prefix: "5C-6A-7D",vendor: "KENTKART EGE ELEKTRONIK SAN. VE TIC. LTD. STI."},
  {max_prefix: "FC-D4-F2",vendor: "The Coca Cola Company"},
  {max_prefix: "08-17-F4",vendor: "IBM Corp"},
  {max_prefix: "F4-38-14",vendor: "Shanghai Howell Electronic Co.,Ltd"},
  {max_prefix: "90-61-0C",vendor: "Fida International (S) Pte Ltd"},
  {max_prefix: "3C-5F-01",vendor: "Synerchip Co., Ltd."},
  {max_prefix: "EC-BB-AE",vendor: "Digivoice Tecnologia em Eletronica Ltda"},
  {max_prefix: "F4-E1-42",vendor: "Delta Elektronika BV"},
  {max_prefix: "E0-D1-0A",vendor: "Katoudenkikougyousyo co ltd"},
  {max_prefix: "C4-4B-44",vendor: "Omniprint Inc."},
  {max_prefix: "48-C8-B6",vendor: "SysTec GmbH"},
  {max_prefix: "3C-62-78",vendor: "SHENZHEN JETNET TECHNOLOGY CO.,LTD."},
  {max_prefix: "D4-3D-67",vendor: "Carma Industries Inc."},
  {max_prefix: "C8-D5-FE",vendor: "Shenzhen Zowee Technology Co., Ltd"},
  {max_prefix: "2C-30-68",vendor: "Pantech Co.,Ltd"},
  {max_prefix: "00-BD-27",vendor: "Exar Corp."},
  {max_prefix: "5C-40-58",vendor: "Jefferson Audio Video Systems, Inc."},
  {max_prefix: "58-D0-8F",vendor: "IEEE 1904.1 Working Group"},
  {max_prefix: "CC-09-C8",vendor: "IMAQLIQ LTD"},
  {max_prefix: "9C-45-63",vendor: "DIMEP Sistemas"},
  {max_prefix: "E4-46-BD",vendor: "C&C TECHNIC TAIWAN CO., LTD."},
  {max_prefix: "8C-DD-8D",vendor: "Wifly-City System Inc."},
  {max_prefix: "20-A2-E7",vendor: "Lee-Dickens Ltd"},
  {max_prefix: "FC-ED-B9",vendor: "Arrayent"},
  {max_prefix: "44-ED-57",vendor: "Longicorn, inc."},
  {max_prefix: "EC-98-C1",vendor: "Beijing Risbo Network Technology Co.,Ltd"},
  {max_prefix: "38-A9-5F",vendor: "Actifio Inc"},
  {max_prefix: "C4-63-54",vendor: "U-Raku, Inc."},
  {max_prefix: "40-5F-BE",vendor: "RIM"},
  {max_prefix: "58-DB-8D",vendor: "Fast Co., Ltd."},
  {max_prefix: "F4-DC-DA",vendor: "Zhuhai Jiahe Communication Technology Co., limited"},
  {max_prefix: "E8-04-62",vendor: "Cisco Systems, Inc"},
  {max_prefix: "DC-D0-F7",vendor: "Bentek Systems Ltd."},
  {max_prefix: "D4-A9-28",vendor: "GreenWave Reality Inc"},
  {max_prefix: "E0-62-90",vendor: "Jinan Jovision Science & Technology Co., Ltd."},
  {max_prefix: "10-0E-2B",vendor: "NEC CASIO Mobile Communications"},
  {max_prefix: "7C-B5-42",vendor: "ACES Technology"},
  {max_prefix: "90-54-46",vendor: "TES ELECTRONIC SOLUTIONS"},
  {max_prefix: "54-4A-05",vendor: "wenglor sensoric gmbh"},
  {max_prefix: "6C-9C-E9",vendor: "Nimble Storage"},
  {max_prefix: "A4-9B-13",vendor: "Digital Check"},
  {max_prefix: "C8-EE-08",vendor: "TANGTOP TECHNOLOGY CO.,LTD"},
  {max_prefix: "70-E1-39",vendor: "3view Ltd"},
  {max_prefix: "18-42-2F",vendor: "Alcatel Lucent"},
  {max_prefix: "68-54-F5",vendor: "enLighted Inc"},
  {max_prefix: "74-72-F2",vendor: "Chipsip Technology Co., Ltd."},
  {max_prefix: "E0-A6-70",vendor: "Nokia Corporation"},
  {max_prefix: "98-E1-65",vendor: "Accutome"},
  {max_prefix: "78-57-12",vendor: "Mobile Integration Workgroup"},
  {max_prefix: "38-0A-0A",vendor: "Sky-City Communication and Electronics Limited Company"},
  {max_prefix: "0C-D6-96",vendor: "Amimon Ltd"},
  {max_prefix: "F4-DC-4D",vendor: "Beijing CCD Digital Technology Co., Ltd"},
  {max_prefix: "DC-4E-DE",vendor: "SHINYEI TECHNOLOGY CO., LTD."},
  {max_prefix: "F0-F8-42",vendor: "KEEBOX, Inc."},
  {max_prefix: "40-83-DE",vendor: "Zebra Technologies Inc"},
  {max_prefix: "88-97-DF",vendor: "Entrypass Corporation Sdn. Bhd."},
  {max_prefix: "40-13-D9",vendor: "Global ES"},
  {max_prefix: "AC-4F-FC",vendor: "SVS-VISTEK GmbH"},
  {max_prefix: "B4-37-41",vendor: "Consert, Inc."},
  {max_prefix: "94-85-7A",vendor: "Evantage Industries Corp"},
  {max_prefix: "24-AF-54",vendor: "NEXGEN Mediatech Inc."},
  {max_prefix: "F0-AD-4E",vendor: "Globalscale Technologies, Inc."},
  {max_prefix: "08-FA-E0",vendor: "Fohhn Audio AG"},
  {max_prefix: "90-3D-5A",vendor: "Shenzhen Wision Technology Holding Limited"},
  {max_prefix: "E0-87-B1",vendor: "Nata-Info Ltd."},
  {max_prefix: "44-7C-7F",vendor: "Innolight Technology Corporation"},
  {max_prefix: "5C-86-4A",vendor: "Secret Labs LLC"},
  {max_prefix: "D4-96-DF",vendor: "SUNGJIN C&T CO.,LTD"},
  {max_prefix: "58-B9-E1",vendor: "Crystalfontz America, Inc."},
  {max_prefix: "20-D9-06",vendor: "Iota, Inc."},
  {max_prefix: "F4-55-95",vendor: "HENGBAO Corporation LTD."},
  {max_prefix: "18-0C-77",vendor: "Westinghouse Electric Company, LLC"},
  {max_prefix: "9C-4E-20",vendor: "Cisco Systems, Inc"},
  {max_prefix: "1C-3A-4F",vendor: "AccuSpec Electronics, LLC"},
  {max_prefix: "D8-75-33",vendor: "Nokia Corporation"},
  {max_prefix: "4C-5D-CD",vendor: "Oy Finnish Electric Vehicle Technologies Ltd"},
  {max_prefix: "70-D5-7E",vendor: "Scalar Corporation"},
  {max_prefix: "98-35-B8",vendor: "Assembled Products Corporation"},
  {max_prefix: "28-89-15",vendor: "CashGuard Sverige AB"},
  {max_prefix: "7C-A2-9B",vendor: "D.SignT GmbH & Co. KG"},
  {max_prefix: "A0-40-41",vendor: "SAMWONFA Co.,Ltd."},
  {max_prefix: "30-18-CF",vendor: "DEOS control systems GmbH"},
  {max_prefix: "40-40-6B",vendor: "Icomera"},
  {max_prefix: "6C-22-AB",vendor: "Ainsworth Game Technology"},
  {max_prefix: "68-CA-00",vendor: "Octopus Systems Limited"},
  {max_prefix: "E0-58-9E",vendor: "Laerdal Medical"},
  {max_prefix: "98-DC-D9",vendor: "UNITEC Co., Ltd."},
  {max_prefix: "C0-CF-A3",vendor: "Creative Electronics & Software, Inc."},
  {max_prefix: "60-89-B7",vendor: "KAEL M\u00dcHEND\u0130SL\u0130K ELEKTRON\u0130K T\u0130CARET SANAY\u0130 L\u0130M\u0130TED \u015e\u0130RKET\u0130"},
  {max_prefix: "30-52-5A",vendor: "NST Co., LTD"},
  {max_prefix: "2C-A7-80",vendor: "True Technologies Inc."},
  {max_prefix: "B0-E3-9D",vendor: "CAT SYSTEM CO.,LTD."},
  {max_prefix: "7C-2E-0D",vendor: "Blackmagic Design"},
  {max_prefix: "5C-E2-86",vendor: "Nortel Networks"},
  {max_prefix: "8C-64-0B",vendor: "Beyond Devices d.o.o."},
  {max_prefix: "FC-E1-92",vendor: "Sichuan Jinwangtong Electronic Science&Technology Co,.Ltd"},
  {max_prefix: "94-9C-55",vendor: "Alta Data Technologies"},
  {max_prefix: "D4-79-C3",vendor: "Cameronet GmbH & Co. KG"},
  {max_prefix: "0C-1D-C2",vendor: "SeAH Networks"},
  {max_prefix: "54-75-D0",vendor: "Cisco Systems, Inc"},
  {max_prefix: "94-23-6E",vendor: "Shenzhen Junlan Electronic Ltd"},
  {max_prefix: "10-E6-AE",vendor: "Source Technologies, LLC"},
  {max_prefix: "7C-6F-06",vendor: "Caterpillar Trimble Control Technologies"},
  {max_prefix: "70-D5-E7",vendor: "Wellcore Corporation"},
  {max_prefix: "3C-F7-2A",vendor: "Nokia Corporation"},
  {max_prefix: "54-5F-A9",vendor: "Teracom Limited"},
  {max_prefix: "40-8A-9A",vendor: "TITENG CO., Ltd."},
  {max_prefix: "F4-45-ED",vendor: "Portable Innovation Technology Ltd."},
  {max_prefix: "6C-32-DE",vendor: "Indieon Technologies Pvt. Ltd."},
  {max_prefix: "60-12-83",vendor: "TSB REAL TIME LOCATION SYSTEMS S.L."},
  {max_prefix: "54-7F-EE",vendor: "Cisco Systems, Inc"},
  {max_prefix: "AC-EA-6A",vendor: "GENIX INFOCOMM CO., LTD."},
  {max_prefix: "A8-F4-70",vendor: "Fujian Newland Communication Science Technologies Co.,Ltd."},
  {max_prefix: "8C-73-6E",vendor: "FUJITSU LIMITED"},
  {max_prefix: "04-FE-7F",vendor: "Cisco Systems, Inc"},
  {max_prefix: "EC-44-76",vendor: "Cisco Systems, Inc"},
  {max_prefix: "E0-BC-43",vendor: "C2 Microsystems, Inc."},
  {max_prefix: "2C-A8-35",vendor: "RIM"},
  {max_prefix: "C4-1E-CE",vendor: "HMI Sources Ltd."},
  {max_prefix: "14-A6-2C",vendor: "S.M. Dezac S.A."},
  {max_prefix: "50-F0-03",vendor: "Open Stack, Inc."},
  {max_prefix: "DC-49-C9",vendor: "CASCO SIGNAL LTD"},
  {max_prefix: "70-D8-80",vendor: "Upos System sp. z o.o."},
  {max_prefix: "A0-5D-C1",vendor: "TMCT Co., LTD."},
  {max_prefix: "B8-64-91",vendor: "CK Telecom Ltd"},
  {max_prefix: "58-3C-C6",vendor: "Omneality Ltd."},
  {max_prefix: "B0-C8-AD",vendor: "People Power Company"},
  {max_prefix: "18-17-14",vendor: "DAEWOOIS"},
  {max_prefix: "F0-EC-39",vendor: "Essec"},
  {max_prefix: "20-46-F9",vendor: "Advanced Network Devices (dba:AND)"},
  {max_prefix: "48-71-19",vendor: "SGB GROUP LTD."},
  {max_prefix: "AC-58-3B",vendor: "Human Assembler, Inc."},
  {max_prefix: "E8-E7-76",vendor: "Shenzhen Kootion Technology Co., Ltd"},
  {max_prefix: "A4-B1-EE",vendor: "H. ZANDER GmbH & Co. KG"},
  {max_prefix: "84-21-41",vendor: "Shenzhen Ginwave Technologies Ltd."},
  {max_prefix: "A8-99-5C",vendor: "aizo ag"},
  {max_prefix: "40-12-E4",vendor: "Compass-EOS"},
  {max_prefix: "44-6C-24",vendor: "Reallin Electronic Co.,Ltd"},
  {max_prefix: "68-1F-D8",vendor: "Siemens Industry, Inc."},
  {max_prefix: "A0-23-1B",vendor: "TeleComp R&D Corp."},
  {max_prefix: "B8-A3-E0",vendor: "BenRui Technology Co.,Ltd"},
  {max_prefix: "3C-F5-2C",vendor: "DSPECIALISTS GmbH"},
  {max_prefix: "54-03-F5",vendor: "EBN Technology Corp."},
  {max_prefix: "6C-18-11",vendor: "Decatur Electronics"},
  {max_prefix: "F8-E9-68",vendor: "Egker Kft."},
  {max_prefix: "80-38-FD",vendor: "LeapFrog Enterprises, Inc."},
  {max_prefix: "AC-BE-B6",vendor: "Visualedge Technology Co., Ltd."},
  {max_prefix: "2C-91-27",vendor: "Eintechno Corporation"},
  {max_prefix: "5C-14-37",vendor: "Thyssenkrupp Aufzugswerke GmbH"},
  {max_prefix: "9C-55-B4",vendor: "I.S.E. S.r.l."},
  {max_prefix: "E4-75-1E",vendor: "Getinge Sterilization AB"},
  {max_prefix: "10-65-A3",vendor: "Panamax LLC"},
  {max_prefix: "40-01-C6",vendor: "3COM EUROPE LTD"},
  {max_prefix: "9C-5E-73",vendor: "Calibre UK LTD"},
  {max_prefix: "04-C0-5B",vendor: "Tigo Energy"},
  {max_prefix: "78-B8-1A",vendor: "INTER SALES A/S"},
  {max_prefix: "B0-E9-7E",vendor: "Advanced Micro Peripherals"},
  {max_prefix: "50-25-2B",vendor: "Nethra Imaging Incorporated"},
  {max_prefix: "F8-81-1A",vendor: "OVERKIZ"},
  {max_prefix: "4C-63-EB",vendor: "Application Solutions (Electronics and Vision) Ltd"},
  {max_prefix: "70-2F-97",vendor: "Aava Mobile Oy"},
  {max_prefix: "10-CA-81",vendor: "PRECIA"},
  {max_prefix: "38-63-F6",vendor: "3NOD MULTIMEDIA(SHENZHEN)CO.,LTD"},
  {max_prefix: "20-2C-B7",vendor: "Kong Yue Electronics & Information Industry (Xinhui) Ltd."},
  {max_prefix: "74-E5-37",vendor: "RADSPIN"},
  {max_prefix: "CC-00-80",vendor: "BETTINI SRL"},
  {max_prefix: "64-4B-C3",vendor: "Shanghai WOASiS Telecommunications Ltd., Co."},
  {max_prefix: "60-9F-9D",vendor: "CloudSwitch"},
  {max_prefix: "CC-CC-4E",vendor: "Sun Fountainhead USA. Corp"},
  {max_prefix: "68-85-40",vendor: "IGI Mobile, Inc."},
  {max_prefix: "A0-9A-5A",vendor: "Time Domain"},
  {max_prefix: "64-A8-37",vendor: "Juni Korea Co., Ltd"},
  {max_prefix: "94-2E-63",vendor: "Fins\u00e9cur"},
  {max_prefix: "AC-83-17",vendor: "Shenzhen Furtunetel Communication Co., Ltd"},
  {max_prefix: "AC-D1-80",vendor: "Crexendo Business Solutions, Inc."},
  {max_prefix: "40-A6-A4",vendor: "PassivSystems Ltd"},
  {max_prefix: "94-BA-31",vendor: "Visiontec da Amaz\u00f4nia Ltda."},
  {max_prefix: "9C-5B-96",vendor: "NMR Corporation"},
  {max_prefix: "60-F1-3D",vendor: "JABLOCOM s.r.o."},
  {max_prefix: "B8-94-D2",vendor: "Retail Innovation HTT AB"},
  {max_prefix: "F0-C2-4C",vendor: "Zhejiang FeiYue Digital Technology Co., Ltd"},
  {max_prefix: "48-34-3D",vendor: "IEP GmbH"},
  {max_prefix: "D4-AA-FF",vendor: "MICRO WORLD"},
  {max_prefix: "00-27-17",vendor: "CE Digital(Zhenjiang)Co.,Ltd"},
  {max_prefix: "00-27-16",vendor: "Adachi-Syokai Co., Ltd."},
  {max_prefix: "00-26-E1",vendor: "Stanford University, OpenFlow Group"},
  {max_prefix: "00-26-DC",vendor: "Optical Systems Design"},
  {max_prefix: "00-26-C3",vendor: "Insightek Corp."},
  {max_prefix: "00-26-C1",vendor: "ARTRAY CO., LTD."},
  {max_prefix: "00-26-EC",vendor: "Legrand Home Systems, Inc"},
  {max_prefix: "00-26-E9",vendor: "SP Corp"},
  {max_prefix: "00-26-EB",vendor: "Advanced Spectrum Technology Co., Ltd."},
  {max_prefix: "00-27-00",vendor: "Shenzhen Siglent Technology Co., Ltd."},
  {max_prefix: "00-26-D4",vendor: "IRCA SpA"},
  {max_prefix: "00-26-63",vendor: "Shenzhen Huitaiwei Tech. Ltd, co."},
  {max_prefix: "00-26-61",vendor: "Irumtek Co., Ltd."},
  {max_prefix: "00-26-5B",vendor: "Hitron Technologies. Inc"},
  {max_prefix: "00-26-56",vendor: "Sansonic Electronics USA"},
  {max_prefix: "00-26-58",vendor: "T-Platforms (Cyprus) Limited"},
  {max_prefix: "00-26-7F",vendor: "Oregan Networks Ltd."},
  {max_prefix: "00-26-7D",vendor: "A-Max Technology Macao Commercial Offshore Company Limited"},
  {max_prefix: "00-26-7C",vendor: "Metz-Werke GmbH & Co KG"},
  {max_prefix: "00-26-74",vendor: "Hunter Douglas"},
  {max_prefix: "00-26-73",vendor: "RICOH COMPANY,LTD."},
  {max_prefix: "00-26-6D",vendor: "MobileAccess Networks"},
  {max_prefix: "00-26-4C",vendor: "Shanghai DigiVision Technology Co., Ltd."},
  {max_prefix: "00-26-46",vendor: "SHENYANG TONGFANG MULTIMEDIA TECHNOLOGY COMPANY LIMITED"},
  {max_prefix: "00-26-44",vendor: "Thomson Telecom Belgium"},
  {max_prefix: "00-26-8B",vendor: "Guangzhou Escene Computer Technology Limited"},
  {max_prefix: "00-26-81",vendor: "Interspiro AB"},
  {max_prefix: "00-26-83",vendor: "Ajoho Enterprise Co., Ltd."},
  {max_prefix: "00-26-6F",vendor: "Coordiwise Technology Corp."},
  {max_prefix: "00-26-6E",vendor: "Nissho-denki Co.,LTD."},
  {max_prefix: "00-26-BE",vendor: "Schoonderbeek Elektronica Systemen B.V."},
  {max_prefix: "00-26-B5",vendor: "ICOMM Tele Ltd"},
  {max_prefix: "00-26-3F",vendor: "LIOS Technology GmbH"},
  {max_prefix: "00-26-3B",vendor: "Onbnetech"},
  {max_prefix: "00-26-34",vendor: "Infineta Systems, Inc"},
  {max_prefix: "00-26-98",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-26-9B",vendor: "SOKRAT Ltd."},
  {max_prefix: "00-26-8C",vendor: "StarLeaf Ltd."},
  {max_prefix: "00-26-A1",vendor: "Megger"},
  {max_prefix: "00-25-D4",vendor: "General Dynamics Mission Systems"},
  {max_prefix: "00-26-06",vendor: "RAUMFELD GmbH"},
  {max_prefix: "00-26-07",vendor: "Enabling Technology Pty Ltd"},
  {max_prefix: "00-26-02",vendor: "SMART Temps LLC"},
  {max_prefix: "00-26-05",vendor: "CC Systems AB"},
  {max_prefix: "00-26-2F",vendor: "HAMAMATSU TOA ELECTRONICS"},
  {max_prefix: "00-26-31",vendor: "COMMTACT LTD"},
  {max_prefix: "00-26-24",vendor: "Thomson Inc."},
  {max_prefix: "00-25-FB",vendor: "Tunstall Healthcare A/S"},
  {max_prefix: "00-25-F4",vendor: "KoCo Connector AG"},
  {max_prefix: "00-25-EF",vendor: "I-TEC Co., Ltd."},
  {max_prefix: "00-25-C2",vendor: "RingBell Co.,Ltd."},
  {max_prefix: "00-26-1A",vendor: "Femtocomm System Technology Corp."},
  {max_prefix: "00-25-E3",vendor: "Hanshinit Inc."},
  {max_prefix: "00-25-B4",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-25-B2",vendor: "MBDA Deutschland GmbH"},
  {max_prefix: "00-25-A7",vendor: "itron"},
  {max_prefix: "00-25-29",vendor: "COMELIT GROUP S.P.A"},
  {max_prefix: "00-25-2A",vendor: "Chengdu GeeYa Technology Co.,LTD"},
  {max_prefix: "00-25-28",vendor: "Daido Signal Co., Ltd."},
  {max_prefix: "00-25-9F",vendor: "TechnoDigital Technologies GmbH"},
  {max_prefix: "00-25-9D",vendor: "Private"},
  {max_prefix: "00-25-98",vendor: "Zhong Shan City Litai Electronic Industrial Co. Ltd"},
  {max_prefix: "00-25-99",vendor: "Hedon e.d. B.V."},
  {max_prefix: "00-25-26",vendor: "Genuine Technologies Co., Ltd."},
  {max_prefix: "00-25-21",vendor: "Logitek Electronic Systems, Inc."},
  {max_prefix: "00-25-1F",vendor: "ZYNUS VISION INC."},
  {max_prefix: "00-25-1E",vendor: "ROTEL TECHNOLOGIES"},
  {max_prefix: "00-25-6B",vendor: "ATENIX E.E. s.r.l."},
  {max_prefix: "00-25-6E",vendor: "Van Breda B.V."},
  {max_prefix: "00-25-65",vendor: "Vizimax Inc."},
  {max_prefix: "00-25-5E",vendor: "Shanghai Dare Technologies Co.,Ltd."},
  {max_prefix: "00-25-5F",vendor: "SenTec AG"},
  {max_prefix: "00-25-97",vendor: "Kalki Communication Technologies"},
  {max_prefix: "00-25-92",vendor: "Guangzhou Shirui Electronic Co., Ltd"},
  {max_prefix: "00-25-94",vendor: "Eurodesign BG LTD"},
  {max_prefix: "00-25-8A",vendor: "Pole/Zero Corporation"},
  {max_prefix: "00-25-3B",vendor: "din Dietmar Nocker Facilitymanagement GmbH"},
  {max_prefix: "00-25-3D",vendor: "DRS Consolidated Controls"},
  {max_prefix: "00-25-35",vendor: "Minimax GmbH & Co KG"},
  {max_prefix: "00-25-84",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-25-79",vendor: "J & F Labs"},
  {max_prefix: "00-25-7F",vendor: "CallTechSolution Co.,Ltd"},
  {max_prefix: "00-25-77",vendor: "D-BOX Technologies"},
  {max_prefix: "00-25-72",vendor: "Nemo-Q International AB"},
  {max_prefix: "00-24-C2",vendor: "Asumo Co.,Ltd."},
  {max_prefix: "00-24-BF",vendor: "Carrier Culoz SA"},
  {max_prefix: "00-24-C0",vendor: "NTI COMODO INC"},
  {max_prefix: "00-24-BB",vendor: "CENTRAL Corporation"},
  {max_prefix: "00-24-BC",vendor: "HuRob Co.,Ltd"},
  {max_prefix: "00-24-B7",vendor: "GridPoint, Inc."},
  {max_prefix: "00-24-E4",vendor: "Withings"},
  {max_prefix: "00-24-DE",vendor: "GLOBAL Technology Inc."},
  {max_prefix: "00-24-DB",vendor: "Alcohol Monitoring Systems"},
  {max_prefix: "00-24-EA",vendor: "iris-GmbH infrared & intelligent sensors"},
  {max_prefix: "00-24-ED",vendor: "YT Elec. Co,.Ltd."},
  {max_prefix: "00-24-EC",vendor: "United Information Technology Co.,Ltd."},
  {max_prefix: "00-24-E6",vendor: "In Motion Technology Inc."},
  {max_prefix: "00-24-E7",vendor: "Plaster Networks"},
  {max_prefix: "00-24-A7",vendor: "Advanced Video Communications Inc."},
  {max_prefix: "00-24-AB",vendor: "A7 Engineering, Inc."},
  {max_prefix: "00-24-A4",vendor: "Siklu Communication"},
  {max_prefix: "00-24-9A",vendor: "Beijing Zhongchuang Telecommunication Test Co., Ltd."},
  {max_prefix: "00-24-DD",vendor: "Centrak, Inc."},
  {max_prefix: "00-24-D5",vendor: "Winward Industrial Limited"},
  {max_prefix: "00-24-AE",vendor: "IDEMIA"},
  {max_prefix: "00-24-AD",vendor: "Adolf Thies Gmbh & Co. KG"},
  {max_prefix: "00-25-19",vendor: "Viaas Inc"},
  {max_prefix: "00-25-13",vendor: "CXP DIGITAL BV"},
  {max_prefix: "00-25-03",vendor: "IBM Corp"},
  {max_prefix: "00-25-04",vendor: "Valiant Communications Limited"},
  {max_prefix: "00-24-5F",vendor: "Vine Telecom CO.,Ltd."},
  {max_prefix: "00-24-55",vendor: "MuLogic BV"},
  {max_prefix: "00-24-5A",vendor: "Nanjing Panda Electronics Company Limited"},
  {max_prefix: "00-24-5B",vendor: "RAIDON TECHNOLOGY, INC."},
  {max_prefix: "00-24-59",vendor: "ABB Automation products GmbH"},
  {max_prefix: "00-24-9E",vendor: "ADC-Elektronik GmbH"},
  {max_prefix: "00-24-9F",vendor: "RIM Testing Services"},
  {max_prefix: "00-24-88",vendor: "Centre For Development Of Telematics"},
  {max_prefix: "00-24-8F",vendor: "DO-MONIX"},
  {max_prefix: "00-24-39",vendor: "Digital Barriers Advanced Technologies"},
  {max_prefix: "00-24-34",vendor: "Lectrosonics, Inc."},
  {max_prefix: "00-24-2F",vendor: "Micron"},
  {max_prefix: "00-24-3A",vendor: "Ludl Electronic Products"},
  {max_prefix: "00-24-79",vendor: "Optec Displays, Inc."},
  {max_prefix: "00-24-68",vendor: "Sumavision Technologies Co.,Ltd"},
  {max_prefix: "00-24-66",vendor: "Unitron nv"},
  {max_prefix: "00-24-26",vendor: "NOHMI BOSAI LTD."},
  {max_prefix: "00-24-29",vendor: "MK MASTER INC."},
  {max_prefix: "00-24-4E",vendor: "RadChips, Inc."},
  {max_prefix: "00-24-0B",vendor: "Virtual Computer Inc."},
  {max_prefix: "00-24-02",vendor: "Op-Tection GmbH"},
  {max_prefix: "00-23-C1",vendor: "Securitas Direct AB"},
  {max_prefix: "00-23-EB",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-23-EC",vendor: "Algorithmix GmbH"},
  {max_prefix: "00-23-C8",vendor: "TEAM-R"},
  {max_prefix: "00-24-18",vendor: "Nextwave Semiconductor"},
  {max_prefix: "00-24-12",vendor: "Benign Technologies Co, Ltd."},
  {max_prefix: "00-24-0D",vendor: "OnePath Networks LTD."},
  {max_prefix: "00-23-FC",vendor: "Ultra Stereo Labs, Inc"},
  {max_prefix: "00-23-DB",vendor: "saxnet gmbh"},
  {max_prefix: "00-23-AA",vendor: "HFR, Inc."},
  {max_prefix: "00-23-A5",vendor: "SageTV, LLC"},
  {max_prefix: "00-23-BB",vendor: "Accretech SBS, Inc."},
  {max_prefix: "00-23-8D",vendor: "Techno Design Co., Ltd."},
  {max_prefix: "00-23-87",vendor: "ThinkFlood, Inc."},
  {max_prefix: "00-23-84",vendor: "GGH Engineering s.r.l."},
  {max_prefix: "00-23-2D",vendor: "SandForce"},
  {max_prefix: "00-23-23",vendor: "Zylin AS"},
  {max_prefix: "00-22-F8",vendor: "PIMA Electronic Systems Ltd."},
  {max_prefix: "00-23-1C",vendor: "Fourier Systems Ltd."},
  {max_prefix: "00-23-1D",vendor: "Deltacom Electronics Ltd"},
  {max_prefix: "00-23-6F",vendor: "DAQ System"},
  {max_prefix: "00-23-69",vendor: "Cisco-Linksys, LLC"},
  {max_prefix: "00-23-9E",vendor: "Jiangsu Lemote Technology Corporation Limited"},
  {max_prefix: "00-23-91",vendor: "Maxian"},
  {max_prefix: "00-23-92",vendor: "Proteus Industries Inc."},
  {max_prefix: "00-23-93",vendor: "AJINEXTEK"},
  {max_prefix: "00-23-30",vendor: "DIZIPIA, INC."},
  {max_prefix: "00-23-0A",vendor: "ARBURG GmbH & Co KG"},
  {max_prefix: "00-23-16",vendor: "KISAN ELECTRONICS CO"},
  {max_prefix: "00-23-0F",vendor: "Hirsch Electronics Corporation"},
  {max_prefix: "00-23-7F",vendor: "PLANTRONICS, INC."},
  {max_prefix: "00-22-95",vendor: "SGM Technology for lighting spa"},
  {max_prefix: "00-22-87",vendor: "Titan Wireless LLC"},
  {max_prefix: "00-22-88",vendor: "Sagrad, Inc."},
  {max_prefix: "00-22-D7",vendor: "Nintendo Co., Ltd."},
  {max_prefix: "00-22-D6",vendor: "Cypak AB"},
  {max_prefix: "00-22-D0",vendor: "Polar Electro Oy"},
  {max_prefix: "00-22-85",vendor: "NOMUS COMM SYSTEMS"},
  {max_prefix: "00-22-81",vendor: "Daintree Networks Pty"},
  {max_prefix: "00-22-73",vendor: "Techway"},
  {max_prefix: "00-22-6B",vendor: "Cisco-Linksys, LLC"},
  {max_prefix: "00-22-67",vendor: "Nortel Networks"},
  {max_prefix: "00-22-A2",vendor: "Xtramus Technologies"},
  {max_prefix: "00-22-9E",vendor: "Social Aid Research Co., Ltd."},
  {max_prefix: "00-22-C3",vendor: "Zeeport Technology Inc."},
  {max_prefix: "00-22-DE",vendor: "OPPO Digital, Inc."},
  {max_prefix: "00-22-F1",vendor: "Private"},
  {max_prefix: "00-22-42",vendor: "Alacron Inc."},
  {max_prefix: "00-22-34",vendor: "Corventis Inc."},
  {max_prefix: "00-22-32",vendor: "Design Design Technology Ltd"},
  {max_prefix: "00-22-2B",vendor: "Nucomm, Inc."},
  {max_prefix: "00-22-21",vendor: "ITOH DENKI CO,LTD."},
  {max_prefix: "00-22-26",vendor: "Avaak, Inc."},
  {max_prefix: "00-22-1D",vendor: "Freegene Technology LTD"},
  {max_prefix: "00-21-FF",vendor: "Cyfrowy Polsat SA"},
  {max_prefix: "00-21-F7",vendor: "HPN Supply Chain"},
  {max_prefix: "00-21-F4",vendor: "INRange Systems, Inc"},
  {max_prefix: "00-21-F5",vendor: "Western Engravers Supply, Inc."},
  {max_prefix: "00-22-55",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-22-4E",vendor: "SEEnergy Corp."},
  {max_prefix: "00-22-45",vendor: "Leine & Linde AB"},
  {max_prefix: "00-22-0A",vendor: "OnLive, Inc"},
  {max_prefix: "00-22-03",vendor: "Glensound Electronics Ltd"},
  {max_prefix: "00-22-04",vendor: "KORATEK"},
  {max_prefix: "00-22-24",vendor: "Good Will Instrument Co., Ltd."},
  {max_prefix: "00-22-1B",vendor: "Morega Systems"},
  {max_prefix: "00-22-49",vendor: "HOME MULTIENERGY SL"},
  {max_prefix: "00-22-40",vendor: "Universal Telecom S/A"},
  {max_prefix: "00-22-0F",vendor: "MoCA (Multimedia over Coax Alliance)"},
  {max_prefix: "00-22-5A",vendor: "Garde Security AB"},
  {max_prefix: "00-21-8F",vendor: "Avantgarde Acoustic Lautsprechersysteme GmbH"},
  {max_prefix: "00-21-87",vendor: "Imacs GmbH"},
  {max_prefix: "00-21-81",vendor: "Si2 Microsystems Limited"},
  {max_prefix: "00-21-7E",vendor: "Telit Communication s.p.a"},
  {max_prefix: "00-21-AC",vendor: "Infrared Integrated Systems Ltd"},
  {max_prefix: "00-21-A2",vendor: "EKE-Electronics Ltd."},
  {max_prefix: "00-21-6E",vendor: "Function ATI (Huizhou) Telecommunications Co., Ltd."},
  {max_prefix: "00-21-6D",vendor: "Soltech Co., Ltd."},
  {max_prefix: "00-21-65",vendor: "Presstek Inc."},
  {max_prefix: "00-21-E4",vendor: "I-WIN"},
  {max_prefix: "00-21-E5",vendor: "Display Solution AG"},
  {max_prefix: "00-21-E2",vendor: "visago Systems & Controls GmbH & Co. KG"},
  {max_prefix: "00-21-8E",vendor: "MEKICS CO., LTD."},
  {max_prefix: "00-21-C9",vendor: "Wavecom Asia Pacific Limited"},
  {max_prefix: "00-21-C2",vendor: "GL Communications Inc"},
  {max_prefix: "00-21-DD",vendor: "Northstar Systems Corp"},
  {max_prefix: "00-21-D5",vendor: "X2E GmbH"},
  {max_prefix: "00-21-B2",vendor: "Fiberblaze A/S"},
  {max_prefix: "00-21-4A",vendor: "Pixel Velocity, Inc"},
  {max_prefix: "00-21-46",vendor: "Sanmina-SCI"},
  {max_prefix: "00-1F-F8",vendor: "Siemens AG, Sector Industry, Drive Technologies, Motion Control Systems"},
  {max_prefix: "00-1F-FA",vendor: "Coretree, Co, Ltd"},
  {max_prefix: "00-1F-F5",vendor: "Kongsberg Defence & Aerospace"},
  {max_prefix: "00-21-2E",vendor: "dresden-elektronik"},
  {max_prefix: "00-21-30",vendor: "Keico Hightech Inc."},
  {max_prefix: "00-21-33",vendor: "Building B, Inc"},
  {max_prefix: "00-21-34",vendor: "Brandywine Communications"},
  {max_prefix: "00-1F-F2",vendor: "VIA Technologies, Inc."},
  {max_prefix: "00-1F-F1",vendor: "Paradox Hellas S.A."},
  {max_prefix: "00-21-5B",vendor: "SenseAnywhere"},
  {max_prefix: "00-21-4E",vendor: "GS Yuasa Power Supply Ltd."},
  {max_prefix: "00-21-1F",vendor: "SHINSUNG DELTATECH CO.,LTD."},
  {max_prefix: "00-21-24",vendor: "Optos Plc"},
  {max_prefix: "00-21-17",vendor: "Tellord"},
  {max_prefix: "00-21-0F",vendor: "Cernium Corp"},
  {max_prefix: "00-21-01",vendor: "Aplicaciones Electronicas Quasar (AEQ)"},
  {max_prefix: "00-21-03",vendor: "GHI Electronics, LLC"},
  {max_prefix: "00-21-42",vendor: "Advanced Control Systems doo"},
  {max_prefix: "00-21-3A",vendor: "Winchester Systems Inc."},
  {max_prefix: "00-1F-90",vendor: "Actiontec Electronics, Inc"},
  {max_prefix: "00-1F-91",vendor: "DBS Lodging Technologies, LLC"},
  {max_prefix: "00-1F-98",vendor: "DAIICHI-DENTSU LTD."},
  {max_prefix: "00-1F-93",vendor: "Xiotech Corporation"},
  {max_prefix: "00-1F-D1",vendor: "OPTEX CO.,LTD."},
  {max_prefix: "00-1F-C9",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-1F-E6",vendor: "Alphion Corporation"},
  {max_prefix: "00-1F-DC",vendor: "Mobile Safe Track Ltd"},
  {max_prefix: "00-1F-A9",vendor: "Atlanta DTH, Inc."},
  {max_prefix: "00-1F-A3",vendor: "T&W Electronics(Shenzhen)Co.,Ltd."},
  {max_prefix: "00-1F-A2",vendor: "Datron World Communications, Inc."},
  {max_prefix: "00-1F-70",vendor: "Botik Technologies LTD"},
  {max_prefix: "00-1F-CF",vendor: "MSI Technology GmbH"},
  {max_prefix: "00-1F-2C",vendor: "Starbridge Networks"},
  {max_prefix: "00-1F-31",vendor: "Radiocomp"},
  {max_prefix: "00-1F-2B",vendor: "Orange Logic"},
  {max_prefix: "00-1F-2A",vendor: "ACCM"},
  {max_prefix: "00-1F-30",vendor: "Travelping"},
  {max_prefix: "00-1F-51",vendor: "HD Communications Corp"},
  {max_prefix: "00-1F-53",vendor: "GEMAC Chemnitz GmbH"},
  {max_prefix: "00-1F-4C",vendor: "Roseman Engineering Ltd"},
  {max_prefix: "00-1F-50",vendor: "Swissdis AG"},
  {max_prefix: "00-1F-48",vendor: "Mojix Inc."},
  {max_prefix: "00-1F-24",vendor: "DIGITVIEW TECHNOLOGY CO., LTD."},
  {max_prefix: "00-1F-21",vendor: "Inner Mongolia Yin An Science & Technology Development Co.,L"},
  {max_prefix: "00-1F-22",vendor: "Source Photonics, Inc."},
  {max_prefix: "00-1F-1D",vendor: "Atlas Material Testing Technology LLC"},
  {max_prefix: "00-1F-15",vendor: "Bioscrypt Inc"},
  {max_prefix: "00-1E-DF",vendor: "Master Industrialization Center Kista"},
  {max_prefix: "00-1E-E3",vendor: "T&W Electronics (ShenZhen) Co.,Ltd"},
  {max_prefix: "00-1E-D9",vendor: "Mitsubishi Precision Co.,LTd."},
  {max_prefix: "00-1F-3E",vendor: "RP-Technik e.K."},
  {max_prefix: "00-1F-37",vendor: "Genesis I&C"},
  {max_prefix: "00-1E-F0",vendor: "Gigafin Networks"},
  {max_prefix: "00-1E-F2",vendor: "Micro Motion Inc"},
  {max_prefix: "00-1E-EA",vendor: "Sensor Switch, Inc."},
  {max_prefix: "00-1F-6D",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-1F-64",vendor: "Beijing Autelan Technology Inc."},
  {max_prefix: "00-1F-04",vendor: "Granch Ltd."},
  {max_prefix: "00-1E-83",vendor: "LAN/MAN Standards Association (LMSC)"},
  {max_prefix: "00-1E-7C",vendor: "Taiwick Limited"},
  {max_prefix: "00-1E-78",vendor: "Owitek Technology Ltd.,"},
  {max_prefix: "00-1E-7A",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-1E-62",vendor: "Siemon"},
  {max_prefix: "00-1E-5C",vendor: "RB GeneralEkonomik"},
  {max_prefix: "00-1E-5D",vendor: "Holosys d.o.o."},
  {max_prefix: "00-1E-60",vendor: "Digital Lighting Systems, Inc"},
  {max_prefix: "00-1E-A0",vendor: "XLN-t"},
  {max_prefix: "00-1E-98",vendor: "GreenLine Communications"},
  {max_prefix: "00-1E-9A",vendor: "HAMILTON Bonaduz AG"},
  {max_prefix: "00-1E-A6",vendor: "Best IT World (India) Pvt. Ltd."},
  {max_prefix: "00-1E-A5",vendor: "ROBOTOUS, Inc."},
  {max_prefix: "00-1E-A7",vendor: "Actiontec Electronics, Inc"},
  {max_prefix: "00-1E-94",vendor: "SUPERCOM TECHNOLOGY CORPORATION"},
  {max_prefix: "00-1E-8F",vendor: "CANON INC."},
  {max_prefix: "00-1E-D3",vendor: "Dot Technology Int'l Co., Ltd."},
  {max_prefix: "00-1E-CF",vendor: "PHILIPS ELECTRONICS UK LTD"},
  {max_prefix: "00-1E-6C",vendor: "Opaque Systems"},
  {max_prefix: "00-1D-EC",vendor: "Marusys"},
  {max_prefix: "00-1D-E8",vendor: "Nikko Denki Tsushin Corporation(NDTC)"},
  {max_prefix: "00-1D-DA",vendor: "Mikroelektronika spol. s r. o."},
  {max_prefix: "00-1D-E3",vendor: "Intuicom"},
  {max_prefix: "00-1D-DE",vendor: "Zhejiang Broadcast&Television Technology Co.,Ltd."},
  {max_prefix: "00-1D-E5",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-1E-22",vendor: "ARVOO Imaging Products BV"},
  {max_prefix: "00-1E-1A",vendor: "Best Source Taiwan Inc."},
  {max_prefix: "00-1E-19",vendor: "GTRI"},
  {max_prefix: "00-1E-14",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-1D-B8",vendor: "Intoto Inc."},
  {max_prefix: "00-1D-B0",vendor: "FuJian HengTong Information Technology Co.,Ltd"},
  {max_prefix: "00-1E-0E",vendor: "MAXI VIEW HOLDINGS LIMITED"},
  {max_prefix: "00-1E-0F",vendor: "Briot International"},
  {max_prefix: "00-1D-CB",vendor: "Ex\u00e9ns Development Oy"},
  {max_prefix: "00-1D-CA",vendor: "PAV Electronics Limited"},
  {max_prefix: "00-1E-2F",vendor: "DiMoto Pty Ltd"},
  {max_prefix: "00-1E-36",vendor: "IPTE"},
  {max_prefix: "00-1E-27",vendor: "SBN TECH Co.,Ltd."},
  {max_prefix: "00-1D-FE",vendor: "Palm, Inc"},
  {max_prefix: "00-1D-F5",vendor: "Sunshine Co,LTD"},
  {max_prefix: "00-1D-F0",vendor: "Vidient Systems, Inc."},
  {max_prefix: "00-1D-C2",vendor: "XORTEC OY"},
  {max_prefix: "00-1E-44",vendor: "SANTEC"},
  {max_prefix: "00-1D-A7",vendor: "Seamless Internet"},
  {max_prefix: "00-1D-A8",vendor: "Takahata Electronics Co.,Ltd"},
  {max_prefix: "00-1D-A9",vendor: "Castles Technology, Co., LTD"},
  {max_prefix: "00-1D-A5",vendor: "WB Electronics"},
  {max_prefix: "00-1D-96",vendor: "WatchGuard Video"},
  {max_prefix: "00-1D-8F",vendor: "PureWave Networks"},
  {max_prefix: "00-1D-55",vendor: "ZANTAZ, Inc"},
  {max_prefix: "00-1D-52",vendor: "Defzone B.V."},
  {max_prefix: "00-1D-4A",vendor: "Carestream Health, Inc."},
  {max_prefix: "00-1D-36",vendor: "ELECTRONICS CORPORATION OF INDIA LIMITED"},
  {max_prefix: "00-1D-33",vendor: "Maverick Systems Inc."},
  {max_prefix: "00-1D-2C",vendor: "Wavetrend Technologies (Pty) Limited"},
  {max_prefix: "00-1D-71",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-1D-65",vendor: "Microwave Radio Communications"},
  {max_prefix: "00-1D-64",vendor: "Adam Communications Systems Int Ltd"},
  {max_prefix: "00-1D-5E",vendor: "COMING MEDIA CORP."},
  {max_prefix: "00-1D-A1",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-1D-8C",vendor: "La Crosse Technology LTD"},
  {max_prefix: "00-1D-50",vendor: "SPINETIX SA"},
  {max_prefix: "00-1D-45",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-1D-69",vendor: "Knorr-Bremse IT-Services GmbH"},
  {max_prefix: "00-1C-F8",vendor: "Parade Technologies, Ltd."},
  {max_prefix: "00-1C-F7",vendor: "AudioScience"},
  {max_prefix: "00-1C-F6",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-1C-F5",vendor: "Wiseblue Technology Limited"},
  {max_prefix: "00-1C-EE",vendor: "SHARP Corporation"},
  {max_prefix: "00-1C-CA",vendor: "Shanghai Gaozhi Science & Technology Development Co."},
  {max_prefix: "00-1C-C9",vendor: "Kaise Electronic Technology Co., Ltd."},
  {max_prefix: "00-1C-C8",vendor: "INDUSTRONIC Industrie-Electronic GmbH & Co. KG"},
  {max_prefix: "00-1C-C6",vendor: "ProStor Systems"},
  {max_prefix: "00-1C-BE",vendor: "Nintendo Co., Ltd."},
  {max_prefix: "00-1D-27",vendor: "NAC-INTERCOM"},
  {max_prefix: "00-1D-24",vendor: "Aclara Power-Line Systems Inc."},
  {max_prefix: "00-1C-DE",vendor: "Interactive Multimedia eXchange Inc."},
  {max_prefix: "00-1C-CF",vendor: "LIMETEK"},
  {max_prefix: "00-1C-AB",vendor: "Meyer Sound Laboratories, Inc."},
  {max_prefix: "00-1C-9E",vendor: "Dualtech IT AB"},
  {max_prefix: "00-1C-94",vendor: "LI-COR Biosciences"},
  {max_prefix: "00-1C-AD",vendor: "Wuhan Telecommunication Devices Co.,Ltd"},
  {max_prefix: "00-1C-A7",vendor: "International Quartz Limited"},
  {max_prefix: "00-1D-07",vendor: "Shenzhen Sang Fei Consumer Communications Co.,Ltd"},
  {max_prefix: "00-1D-06",vendor: "HM Electronics, Inc."},
  {max_prefix: "00-1D-01",vendor: "Neptune Digital"},
  {max_prefix: "00-1D-18",vendor: "Power Innovation GmbH"},
  {max_prefix: "00-1D-1B",vendor: "Sangean Electronics Inc."},
  {max_prefix: "00-1D-17",vendor: "Digital Sky Corporation"},
  {max_prefix: "00-1C-E3",vendor: "Optimedical Systems"},
  {max_prefix: "00-1C-48",vendor: "WiDeFi, Inc."},
  {max_prefix: "00-1C-46",vendor: "QTUM"},
  {max_prefix: "00-1C-42",vendor: "Parallels, Inc."},
  {max_prefix: "00-1C-3E",vendor: "ECKey Corporation"},
  {max_prefix: "00-1C-80",vendor: "New Business Division/Rhea-Information CO., LTD."},
  {max_prefix: "00-1C-83",vendor: "New Level Telecom Co., Ltd."},
  {max_prefix: "00-1C-76",vendor: "The Wandsworth Group Ltd"},
  {max_prefix: "00-1C-72",vendor: "Mayer & Cie GmbH & Co KG"},
  {max_prefix: "00-1C-8C",vendor: "DIAL TECHNOLOGY LTD."},
  {max_prefix: "00-1C-93",vendor: "ExaDigm Inc"},
  {max_prefix: "00-1C-85",vendor: "Eunicorn"},
  {max_prefix: "00-1C-0A",vendor: "Shenzhen AEE Technology Co.,Ltd."},
  {max_prefix: "00-1C-0D",vendor: "G-Technology, Inc."},
  {max_prefix: "00-1C-03",vendor: "Betty TV Technology AG"},
  {max_prefix: "00-1C-6A",vendor: "Weiss Engineering Ltd."},
  {max_prefix: "00-1C-67",vendor: "Pumpkin Networks, Inc."},
  {max_prefix: "00-1C-60",vendor: "CSP Frontier Technologies,Inc."},
  {max_prefix: "00-1C-2F",vendor: "Pfister GmbH"},
  {max_prefix: "00-1C-27",vendor: "Sunell Electronics Co."},
  {max_prefix: "00-1C-22",vendor: "Aeris Elettronica s.r.l."},
  {max_prefix: "00-1C-51",vendor: "Celeno Communications"},
  {max_prefix: "00-1C-54",vendor: "Hillstone Networks Inc"},
  {max_prefix: "00-1C-59",vendor: "DEVON IT"},
  {max_prefix: "00-1C-39",vendor: "S Netsystems Inc."},
  {max_prefix: "00-1C-37",vendor: "Callpod, Inc."},
  {max_prefix: "00-1C-33",vendor: "Sutron"},
  {max_prefix: "00-1C-1D",vendor: "CHENZHOU GOSPELL DIGITAL TECHNOLOGY CO.,LTD"},
  {max_prefix: "00-1C-0F",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-1B-F6",vendor: "CONWISE Technology Corporation Ltd."},
  {max_prefix: "00-1B-F8",vendor: "Digitrax Inc."},
  {max_prefix: "00-1B-F1",vendor: "Nanjing SilverNet Software Co., Ltd."},
  {max_prefix: "00-1B-EF",vendor: "Blossoms Digital Technology Co.,Ltd."},
  {max_prefix: "00-1B-EB",vendor: "DMP Electronics INC."},
  {max_prefix: "00-1B-CE",vendor: "Measurement Devices Ltd"},
  {max_prefix: "00-1B-C9",vendor: "FSN DISPLAY INC"},
  {max_prefix: "00-1B-C3",vendor: "Mobisolution Co.,Ltd"},
  {max_prefix: "00-1B-B8",vendor: "BLUEWAY ELECTRONIC CO;LTD"},
  {max_prefix: "00-1B-B2",vendor: "Intellect International NV"},
  {max_prefix: "00-1B-B0",vendor: "Bharat Electronics Limited"},
  {max_prefix: "00-1B-AD",vendor: "iControl Incorporated"},
  {max_prefix: "00-1B-A7",vendor: "Lorica Solutions"},
  {max_prefix: "00-1B-87",vendor: "Deepsound Tech. Co., Ltd"},
  {max_prefix: "00-1B-7F",vendor: "TMN Technologies Telecomunicacoes Ltda"},
  {max_prefix: "00-1B-7E",vendor: "Beckmann GmbH"},
  {max_prefix: "00-1B-A5",vendor: "MyungMin Systems, Inc."},
  {max_prefix: "00-1B-A2",vendor: "IDS Imaging Development Systems GmbH"},
  {max_prefix: "00-1B-9D",vendor: "Novus Security Sp. z o.o."},
  {max_prefix: "00-1B-7A",vendor: "Nintendo Co., Ltd."},
  {max_prefix: "00-1B-72",vendor: "Sicep s.p.a."},
  {max_prefix: "00-1B-74",vendor: "MiraLink Corporation"},
  {max_prefix: "00-1B-6D",vendor: "Midtronics, Inc."},
  {max_prefix: "00-1B-6F",vendor: "Teletrak Ltd"},
  {max_prefix: "00-1B-DC",vendor: "Vencer Co., Ltd."},
  {max_prefix: "00-1B-D5",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-1B-E7",vendor: "Postek Electronics Co., Ltd."},
  {max_prefix: "00-1B-E3",vendor: "Health Hero Network, Inc."},
  {max_prefix: "00-1B-95",vendor: "VIDEO SYSTEMS SRL"},
  {max_prefix: "00-1B-90",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-1A-F1",vendor: "Embedded Artists AB"},
  {max_prefix: "00-1A-F8",vendor: "Copley Controls Corporation"},
  {max_prefix: "00-1A-F5",vendor: "PENTAONE. CO., LTD."},
  {max_prefix: "00-1A-ED",vendor: "INCOTEC GmbH"},
  {max_prefix: "00-1A-EE",vendor: "Shenztech Ltd"},
  {max_prefix: "00-1B-1D",vendor: "Phoenix International Co., Ltd"},
  {max_prefix: "00-1B-1A",vendor: "e-trees Japan, Inc."},
  {max_prefix: "00-1B-12",vendor: "Apprion"},
  {max_prefix: "00-1B-5F",vendor: "Alien Technology"},
  {max_prefix: "00-1B-5E",vendor: "BPL Limited"},
  {max_prefix: "00-1B-61",vendor: "Digital Acoustics, LLC"},
  {max_prefix: "00-1B-5C",vendor: "Azuretec Co., Ltd."},
  {max_prefix: "00-1B-34",vendor: "Focus System Inc."},
  {max_prefix: "00-1B-3A",vendor: "SIMS Corp."},
  {max_prefix: "00-1A-FE",vendor: "SOFACREAL"},
  {max_prefix: "00-1B-4B",vendor: "SANION Co., Ltd."},
  {max_prefix: "00-1B-4D",vendor: "Areca Technology Corporation"},
  {max_prefix: "00-1B-0D",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-1B-0A",vendor: "Intelligent Distributed Controls Ltd"},
  {max_prefix: "00-1B-2E",vendor: "Sinkyo Electron Inc"},
  {max_prefix: "00-1B-2D",vendor: "Med-Eng Systems Inc."},
  {max_prefix: "00-1A-E3",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-1A-DF",vendor: "Interactivetv Pty Limited"},
  {max_prefix: "00-1A-E1",vendor: "EDGE ACCESS INC"},
  {max_prefix: "00-1A-E9",vendor: "Nintendo Co., Ltd."},
  {max_prefix: "00-1A-E5",vendor: "Mvox Technologies Inc."},
  {max_prefix: "00-1A-E4",vendor: "Medicis Technologies Corporation"},
  {max_prefix: "00-1A-98",vendor: "Asotel Communication Limited Taiwan Branch"},
  {max_prefix: "00-1A-97",vendor: "fitivision technology Inc."},
  {max_prefix: "00-1A-90",vendor: "Tr\u00f3pico Sistemas e Telecomunica\u00e7\u00f5es da Amaz\u00f4nia LTDA."},
  {max_prefix: "00-1A-94",vendor: "Votronic GmbH"},
  {max_prefix: "00-1A-86",vendor: "New Wave Design & Verification"},
  {max_prefix: "00-1A-CE",vendor: "YUPITERU CORPORATION"},
  {max_prefix: "00-1A-CC",vendor: "Celestial Semiconductor, Ltd"},
  {max_prefix: "00-1A-C7",vendor: "UNIPOINT"},
  {max_prefix: "00-1A-B1",vendor: "Asia Pacific Satellite Industries Co., Ltd."},
  {max_prefix: "00-1A-B2",vendor: "Cyber Solutions Inc."},
  {max_prefix: "00-1A-B7",vendor: "Ethos Networks LTD."},
  {max_prefix: "00-1A-A2",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-1A-A5",vendor: "BRN Phoenix"},
  {max_prefix: "00-1A-9C",vendor: "RightHand Technologies, Inc."},
  {max_prefix: "00-1A-9E",vendor: "ICON Digital International Limited"},
  {max_prefix: "00-1A-D2",vendor: "Eletronica Nitron Ltda"},
  {max_prefix: "00-1A-C0",vendor: "JOYBIEN TECHNOLOGIES CO., LTD."},
  {max_prefix: "00-1A-C2",vendor: "YEC Co.,Ltd."},
  {max_prefix: "00-1A-7E",vendor: "LN Srithai Comm Ltd."},
  {max_prefix: "00-1A-72",vendor: "Mosart Semiconductor Corp."},
  {max_prefix: "00-1A-37",vendor: "Lear Corporation"},
  {max_prefix: "00-1A-38",vendor: "Sanmina-SCI"},
  {max_prefix: "00-1A-2B",vendor: "Ayecom Technology Co., Ltd."},
  {max_prefix: "00-1A-28",vendor: "ASWT Co., LTD. Taiwan Branch H.K."},
  {max_prefix: "00-1A-2C",vendor: "SATEC Co.,LTD"},
  {max_prefix: "00-1A-27",vendor: "Ubistar"},
  {max_prefix: "00-1A-68",vendor: "Weltec Enterprise Co., Ltd."},
  {max_prefix: "00-1A-5A",vendor: "Korea Electric Power Data Network  (KDN) Co., Ltd"},
  {max_prefix: "00-1A-5F",vendor: "KitWorks.fi Ltd."},
  {max_prefix: "00-1A-2E",vendor: "Ziova Coporation"},
  {max_prefix: "00-1A-32",vendor: "ACTIVA MULTIMEDIA"},
  {max_prefix: "00-1A-35",vendor: "BARTEC GmbH"},
  {max_prefix: "00-1A-48",vendor: "Takacom Corporation"},
  {max_prefix: "00-1A-21",vendor: "Brookhuis Applied Technologies BV"},
  {max_prefix: "00-1A-0B",vendor: "BONA TECHNOLOGY INC."},
  {max_prefix: "00-1A-06",vendor: "OpVista, Inc."},
  {max_prefix: "00-1A-5D",vendor: "Mobinnova Corp."},
  {max_prefix: "00-1A-00",vendor: "MATRIX INC."},
  {max_prefix: "00-19-FF",vendor: "Finnzymes"},
  {max_prefix: "00-19-FA",vendor: "Cable Vision Electronics CO., LTD."},
  {max_prefix: "00-19-F1",vendor: "Star Communication Network Technology Co.,Ltd"},
  {max_prefix: "00-19-EC",vendor: "Sagamore Systems, Inc."},
  {max_prefix: "00-19-A5",vendor: "RadarFind Corporation"},
  {max_prefix: "00-19-93",vendor: "Changshu Switchgear MFG. Co.,Ltd. (Former Changshu Switchgea"},
  {max_prefix: "00-19-C8",vendor: "AnyDATA Corporation"},
  {max_prefix: "00-19-C4",vendor: "Infocrypt Inc."},
  {max_prefix: "00-19-BC",vendor: "ELECTRO CHANCE SRL"},
  {max_prefix: "00-19-73",vendor: "Zeugma Systems"},
  {max_prefix: "00-19-75",vendor: "Beijing Huisen networks technology Inc"},
  {max_prefix: "00-19-7B",vendor: "Picotest Corp."},
  {max_prefix: "00-19-D8",vendor: "MAXFOR"},
  {max_prefix: "00-19-D5",vendor: "IP Innovations, Inc."},
  {max_prefix: "00-19-CC",vendor: "RCG (HK) Ltd"},
  {max_prefix: "00-19-81",vendor: "Vivox Inc"},
  {max_prefix: "00-19-EA",vendor: "TeraMage Technologies Co., Ltd."},
  {max_prefix: "00-19-9B",vendor: "Diversified Technical Systems, Inc."},
  {max_prefix: "00-19-90",vendor: "ELM DATA Co., Ltd."},
  {max_prefix: "00-19-8F",vendor: "Nokia Bell N.V."},
  {max_prefix: "00-19-A3",vendor: "asteel electronique atlantique"},
  {max_prefix: "00-19-AD",vendor: "BOBST SA"},
  {max_prefix: "00-19-A2",vendor: "ORDYN TECHNOLOGIES"},
  {max_prefix: "00-19-26",vendor: "BitsGen Co., Ltd."},
  {max_prefix: "00-19-29",vendor: "2M2B Montadora de Maquinas Bahia Brasil LTDA"},
  {max_prefix: "00-19-2A",vendor: "Antiope Associates"},
  {max_prefix: "00-19-0F",vendor: "Advansus Corp."},
  {max_prefix: "00-19-11",vendor: "Just In Mobile Information Technologies (Shanghai) Co., Ltd."},
  {max_prefix: "00-19-2E",vendor: "Spectral Instruments, Inc."},
  {max_prefix: "00-19-2B",vendor: "Aclara RF Systems Inc."},
  {max_prefix: "00-19-1E",vendor: "Beyondwiz Co., Ltd."},
  {max_prefix: "00-19-1F",vendor: "Microlink communications Inc."},
  {max_prefix: "00-19-20",vendor: "KUME electric Co.,Ltd."},
  {max_prefix: "00-18-F1",vendor: "Chunichi Denshi Co.,LTD."},
  {max_prefix: "00-18-F2",vendor: "Beijing Tianyu Communication Equipment Co., Ltd"},
  {max_prefix: "00-18-EC",vendor: "Welding Technology Corporation"},
  {max_prefix: "00-19-6E",vendor: "Metacom (Pty) Ltd."},
  {max_prefix: "00-19-65",vendor: "YuHua TelTech (ShangHai) Co., Ltd."},
  {max_prefix: "00-19-66",vendor: "Asiarock Technology Limited"},
  {max_prefix: "00-19-17",vendor: "Posiflex Inc."},
  {max_prefix: "00-19-18",vendor: "Interactive Wear AG"},
  {max_prefix: "00-19-0B",vendor: "Southern Vision Systems, Inc."},
  {max_prefix: "00-19-03",vendor: "Bigfoot Networks Inc"},
  {max_prefix: "00-19-5C",vendor: "Innotech Corporation"},
  {max_prefix: "00-19-5F",vendor: "Valemount Networks Corporation"},
  {max_prefix: "00-19-00",vendor: "Intelliverese - DBA Voicecom"},
  {max_prefix: "00-19-02",vendor: "Cambridge Consultants Ltd"},
  {max_prefix: "00-19-45",vendor: "RF COncepts, LLC"},
  {max_prefix: "00-19-48",vendor: "AireSpider Networks"},
  {max_prefix: "00-19-43",vendor: "Belden"},
  {max_prefix: "00-19-40",vendor: "Rackable Systems"},
  {max_prefix: "00-19-3C",vendor: "HighPoint Technologies Incorporated"},
  {max_prefix: "00-18-9B",vendor: "Thomson Inc."},
  {max_prefix: "00-18-94",vendor: "NPCore, Inc."},
  {max_prefix: "00-18-98",vendor: "KINGSTATE ELECTRONICS CORPORATION"},
  {max_prefix: "00-18-92",vendor: "ads-tec GmbH"},
  {max_prefix: "00-18-91",vendor: "Zhongshan General K-mate Electronics Co., Ltd"},
  {max_prefix: "00-18-89",vendor: "WinNet Solutions Limited"},
  {max_prefix: "00-18-B2",vendor: "ADEUNIS RF"},
  {max_prefix: "00-18-B3",vendor: "TEC WizHome Co., Ltd."},
  {max_prefix: "00-18-AC",vendor: "Shanghai Jiao Da HISYS Technology Co. Ltd."},
  {max_prefix: "00-18-AB",vendor: "BEIJING LHWT MICROELECTRONICS INC."},
  {max_prefix: "00-18-ED",vendor: "Accutech Ultrasystems Co., Ltd."},
  {max_prefix: "00-18-E0",vendor: "ANAVEO"},
  {max_prefix: "00-18-CB",vendor: "Tecobest Technology Limited"},
  {max_prefix: "00-18-BD",vendor: "SHENZHEN DVBWORLD TECHNOLOGY CO., LTD."},
  {max_prefix: "00-18-A5",vendor: "ADigit Technologies Corp."},
  {max_prefix: "00-18-A6",vendor: "Persistent Systems, LLC"},
  {max_prefix: "00-18-67",vendor: "Datalogic ADC"},
  {max_prefix: "00-18-65",vendor: "Siemens Healthcare Diagnostics Manufacturing Ltd"},
  {max_prefix: "00-18-6A",vendor: "Global Link Digital Technology Co,.LTD"},
  {max_prefix: "00-18-78",vendor: "Mackware GmbH"},
  {max_prefix: "00-18-6E",vendor: "3Com Ltd"},
  {max_prefix: "00-18-6B",vendor: "Sambu Communics CO., LTD."},
  {max_prefix: "00-18-77",vendor: "Amplex A/S"},
  {max_prefix: "00-18-CE",vendor: "Dreamtech Co., Ltd"},
  {max_prefix: "00-17-D3",vendor: "Etymotic Research, Inc."},
  {max_prefix: "00-17-CC",vendor: "Alcatel-Lucent"},
  {max_prefix: "00-17-C4",vendor: "Quanta Microsystems, INC."},
  {max_prefix: "00-17-BD",vendor: "Tibetsystem"},
  {max_prefix: "00-17-BF",vendor: "Coherent Research Limited"},
  {max_prefix: "00-18-47",vendor: "AceNet Technology Inc."},
  {max_prefix: "00-18-43",vendor: "Dawevision Ltd"},
  {max_prefix: "00-18-2C",vendor: "Ascend Networks, Inc."},
  {max_prefix: "00-18-36",vendor: "REJ Co.,Ltd"},
  {max_prefix: "00-17-ED",vendor: "WooJooIT Ltd."},
  {max_prefix: "00-17-DD",vendor: "Clipsal Australia"},
  {max_prefix: "00-17-D9",vendor: "AAI Corporation"},
  {max_prefix: "00-17-DC",vendor: "DAEMYUNG ZERO1"},
  {max_prefix: "00-18-1B",vendor: "TaiJin Metal Co., Ltd."},
  {max_prefix: "00-18-05",vendor: "Beijing InHand Networking Technology Co.,Ltd."},
  {max_prefix: "00-17-F9",vendor: "Forcom Sp. z o.o."},
  {max_prefix: "00-17-F4",vendor: "ZERON ALLIANCE"},
  {max_prefix: "00-17-F7",vendor: "CEM Solutions Pvt Ltd"},
  {max_prefix: "00-18-1D",vendor: "ASIA ELECTRONICS CO.,LTD"},
  {max_prefix: "00-18-1F",vendor: "Palmmicro Communications"},
  {max_prefix: "00-18-0D",vendor: "Terabytes Server Storage Tech Corp"},
  {max_prefix: "00-18-54",vendor: "Argard Co., Ltd"},
  {max_prefix: "00-17-AD",vendor: "AceNet Corporation"},
  {max_prefix: "00-17-9E",vendor: "Sirit Inc"},
  {max_prefix: "00-17-A7",vendor: "Mobile Computing Promotion Consortium"},
  {max_prefix: "00-17-9F",vendor: "Apricorn"},
  {max_prefix: "00-17-A1",vendor: "3soft inc."},
  {max_prefix: "00-17-3C",vendor: "Extreme Engineering Solutions"},
  {max_prefix: "00-17-36",vendor: "iiTron Inc."},
  {max_prefix: "00-17-37",vendor: "Industrie Dial Face S.p.A."},
  {max_prefix: "00-17-33",vendor: "SFR"},
  {max_prefix: "00-17-4F",vendor: "iCatch Inc."},
  {max_prefix: "00-17-4A",vendor: "SOCOMEC"},
  {max_prefix: "00-17-4E",vendor: "Parama-tech Co.,Ltd."},
  {max_prefix: "00-17-5B",vendor: "ACS Solutions Switzerland Ltd."},
  {max_prefix: "00-17-58",vendor: "ThruVision Ltd"},
  {max_prefix: "00-17-92",vendor: "Falcom Wireless Comunications Gmbh"},
  {max_prefix: "00-17-97",vendor: "Telsy Elettronica S.p.A."},
  {max_prefix: "00-17-8C",vendor: "Independent Witness, Inc"},
  {max_prefix: "00-17-6F",vendor: "PAX Computer Technology(Shenzhen) Ltd."},
  {max_prefix: "00-17-71",vendor: "APD Communications Ltd"},
  {max_prefix: "00-17-66",vendor: "Accense Technology, Inc."},
  {max_prefix: "00-17-64",vendor: "ATMedia GmbH"},
  {max_prefix: "00-17-5E",vendor: "Zed-3"},
  {max_prefix: "00-17-B4",vendor: "Remote Security Systems, LLC"},
  {max_prefix: "00-17-AC",vendor: "O'Neil Product Development Inc."},
  {max_prefix: "00-17-88",vendor: "Philips Lighting BV"},
  {max_prefix: "00-17-29",vendor: "Ubicod Co.LTD"},
  {max_prefix: "00-17-27",vendor: "Thermo Ramsey Italia s.r.l."},
  {max_prefix: "00-17-2A",vendor: "Proware Technology Corp.(By Unifosa)"},
  {max_prefix: "00-17-1B",vendor: "Innovation Lab Corp."},
  {max_prefix: "00-17-14",vendor: "BR Controls Nederland bv"},
  {max_prefix: "00-17-09",vendor: "Exalt Communications"},
  {max_prefix: "00-17-03",vendor: "MOSDAN Internation Co.,Ltd"},
  {max_prefix: "00-16-FC",vendor: "TOHKEN CO.,LTD."},
  {max_prefix: "00-16-F8",vendor: "AVIQTECH TECHNOLOGY CO., LTD."},
  {max_prefix: "00-16-E8",vendor: "Lumissil Microsystems"},
  {max_prefix: "00-16-E9",vendor: "Tiba Medical Inc"},
  {max_prefix: "00-16-DC",vendor: "ARCHOS"},
  {max_prefix: "00-16-D0",vendor: "ATech elektronika d.o.o."},
  {max_prefix: "00-17-25",vendor: "Liquid Computing"},
  {max_prefix: "00-16-F0",vendor: "Dell"},
  {max_prefix: "00-16-A9",vendor: "2EI"},
  {max_prefix: "00-16-B0",vendor: "VK Corporation"},
  {max_prefix: "00-16-B1",vendor: "KBS"},
  {max_prefix: "00-16-7F",vendor: "Bluebird Soft Inc."},
  {max_prefix: "00-16-7C",vendor: "iRex Technologies BV"},
  {max_prefix: "00-16-73",vendor: "Bury GmbH & Co. KG"},
  {max_prefix: "00-16-8C",vendor: "DSL Partner AS"},
  {max_prefix: "00-16-83",vendor: "WEBIO International Co.,.Ltd."},
  {max_prefix: "00-16-84",vendor: "Donjin Co.,Ltd."},
  {max_prefix: "00-16-87",vendor: "Chubb CSC-Vendor AP"},
  {max_prefix: "00-16-C3",vendor: "BA Systems Inc"},
  {max_prefix: "00-16-C1",vendor: "Eleksen Ltd"},
  {max_prefix: "00-16-9C",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-16-94",vendor: "Sennheiser Communications A/S"},
  {max_prefix: "00-16-5F",vendor: "Fairmount Automation"},
  {max_prefix: "00-16-57",vendor: "Aegate Ltd"},
  {max_prefix: "00-16-AE",vendor: "INVENTEL"},
  {max_prefix: "00-16-AC",vendor: "Toho Technology Corp."},
  {max_prefix: "00-16-4C",vendor: "PLANET INT Co., Ltd"},
  {max_prefix: "00-16-49",vendor: "SetOne GmbH"},
  {max_prefix: "00-16-65",vendor: "Cellon France"},
  {max_prefix: "00-16-1B",vendor: "Micronet Corporation"},
  {max_prefix: "00-16-18",vendor: "HIVION Co., Ltd."},
  {max_prefix: "00-16-1E",vendor: "Woojinnet"},
  {max_prefix: "00-16-1F",vendor: "SUNWAVETEC Co., Ltd."},
  {max_prefix: "00-16-14",vendor: "Picosecond Pulse Labs"},
  {max_prefix: "00-16-0E",vendor: "Optica Technologies Inc."},
  {max_prefix: "00-15-E3",vendor: "Dream Technologies Corporation"},
  {max_prefix: "00-15-E0",vendor: "Ericsson"},
  {max_prefix: "00-15-D3",vendor: "Pantech&Curitel Communications, Inc."},
  {max_prefix: "00-15-CA",vendor: "TeraRecon, Inc."},
  {max_prefix: "00-16-47",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-16-43",vendor: "Sunhillo Corporation"},
  {max_prefix: "00-16-44",vendor: "LITE-ON Technology Corp."},
  {max_prefix: "00-16-3E",vendor: "Xensource, Inc."},
  {max_prefix: "00-16-34",vendor: "Mathtech, Inc."},
  {max_prefix: "00-15-F9",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-15-FD",vendor: "Complete Media Systems"},
  {max_prefix: "00-15-F6",vendor: "SCIENCE AND ENGINEERING SERVICES, INC."},
  {max_prefix: "00-16-05",vendor: "YORKVILLE SOUND INC."},
  {max_prefix: "00-16-2C",vendor: "Xanboo"},
  {max_prefix: "00-15-F3",vendor: "PELTOR AB"},
  {max_prefix: "00-15-E7",vendor: "Quantec Tontechnik"},
  {max_prefix: "00-15-4B",vendor: "Wonde Proud Technology Co., Ltd"},
  {max_prefix: "00-15-48",vendor: "CUBE TECHNOLOGIES"},
  {max_prefix: "00-15-70",vendor: "Zebra Technologies Inc"},
  {max_prefix: "00-15-6E",vendor: "A. W. Communication Systems Ltd"},
  {max_prefix: "00-15-68",vendor: "Dilithium Networks"},
  {max_prefix: "00-15-5F",vendor: "GreenPeak Technologies"},
  {max_prefix: "00-15-AD",vendor: "Accedian Networks"},
  {max_prefix: "00-15-AC",vendor: "Capelon AB"},
  {max_prefix: "00-15-A9",vendor: "KWANG WOO I&C CO.,LTD"},
  {max_prefix: "00-15-3C",vendor: "Kprotech Co., Ltd."},
  {max_prefix: "00-15-8E",vendor: "Plustek.INC"},
  {max_prefix: "00-15-89",vendor: "D-MAX Technology Co.,Ltd"},
  {max_prefix: "00-15-74",vendor: "Horizon Semiconductors Ltd."},
  {max_prefix: "00-15-5A",vendor: "DAINIPPON PHARMACEUTICAL CO., LTD."},
  {max_prefix: "00-E0-A8",vendor: "SAT GmbH & Co."},
  {max_prefix: "00-15-98",vendor: "Kolektor group"},
  {max_prefix: "00-15-8A",vendor: "SURECOM Technology Corp."},
  {max_prefix: "00-15-B4",vendor: "Polymap  Wireless LLC"},
  {max_prefix: "00-14-FA",vendor: "AsGa S.A."},
  {max_prefix: "00-14-FB",vendor: "Technical Solutions Inc."},
  {max_prefix: "00-14-F4",vendor: "DekTec Digital Video B.V."},
  {max_prefix: "00-14-F5",vendor: "OSI Security Devices"},
  {max_prefix: "00-14-EC",vendor: "Acro Telecom"},
  {max_prefix: "00-15-12",vendor: "Zurich University of Applied Sciences"},
  {max_prefix: "00-15-0A",vendor: "Sonoa Systems, Inc"},
  {max_prefix: "00-14-FF",vendor: "Precise Automation, Inc."},
  {max_prefix: "00-14-D2",vendor: "Kyuden Technosystems Corporation"},
  {max_prefix: "00-14-DC",vendor: "Communication System Design & Manufacturing (CSDM)"},
  {max_prefix: "00-14-EB",vendor: "AwarePoint Corporation"},
  {max_prefix: "00-15-3A",vendor: "Shenzhen Syscan Technology Co.,Ltd."},
  {max_prefix: "00-15-32",vendor: "Consumer Technologies Group, LLC"},
  {max_prefix: "00-14-CD",vendor: "DigitalZone Co., Ltd."},
  {max_prefix: "00-14-C0",vendor: "Symstream Technology Group Ltd"},
  {max_prefix: "00-15-1D",vendor: "M2I CORPORATION"},
  {max_prefix: "00-14-AD",vendor: "Gassner Wiege- und Me\u00dftechnik GmbH"},
  {max_prefix: "00-14-AF",vendor: "Datasym POS Inc."},
  {max_prefix: "00-14-A9",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-14-9B",vendor: "Nokota Communications, LLC"},
  {max_prefix: "00-14-A1",vendor: "Synchronous Communication Corp"},
  {max_prefix: "00-14-9E",vendor: "UbONE Co., Ltd"},
  {max_prefix: "00-14-C1",vendor: "U.S. Robotics Corporation"},
  {max_prefix: "00-14-C4",vendor: "Vitelcom Mobile Technology"},
  {max_prefix: "00-14-AB",vendor: "Senhai Electronic Technology Co., Ltd."},
  {max_prefix: "00-14-B0",vendor: "Naeil Community"},
  {max_prefix: "00-14-8E",vendor: "Tele Power Inc."},
  {max_prefix: "00-14-8F",vendor: "Protronic (Far East) Ltd."},
  {max_prefix: "00-14-8C",vendor: "General Dynamics Mission Systems"},
  {max_prefix: "00-14-87",vendor: "American Technology Integrators"},
  {max_prefix: "00-14-4D",vendor: "Intelligent Systems"},
  {max_prefix: "00-14-4A",vendor: "Taiwan Thick-Film Ind. Corp."},
  {max_prefix: "00-14-45",vendor: "Telefon-Gradnja d.o.o."},
  {max_prefix: "00-14-44",vendor: "Grundfos Holding"},
  {max_prefix: "00-14-68",vendor: "CelPlan International, Inc."},
  {max_prefix: "00-14-81",vendor: "Multilink Inc"},
  {max_prefix: "00-14-7C",vendor: "3Com Ltd"},
  {max_prefix: "00-14-64",vendor: "Cryptosoft"},
  {max_prefix: "00-14-5E",vendor: "IBM Corp"},
  {max_prefix: "00-14-A2",vendor: "Core Micro Systems Inc."},
  {max_prefix: "00-14-58",vendor: "HS Automatic ApS"},
  {max_prefix: "00-14-37",vendor: "GSTeletech Co.,Ltd."},
  {max_prefix: "00-14-31",vendor: "PDL Electronics Ltd"},
  {max_prefix: "00-14-2B",vendor: "Edata Communication Inc."},
  {max_prefix: "00-14-2C",vendor: "Koncept International, Inc."},
  {max_prefix: "00-13-BA",vendor: "ReadyLinks Inc"},
  {max_prefix: "00-13-B8",vendor: "RyCo Electronic Systems Limited"},
  {max_prefix: "00-13-B6",vendor: "Sling Media, Inc."},
  {max_prefix: "00-14-24",vendor: "Merry Electrics CO., LTD."},
  {max_prefix: "00-14-21",vendor: "Total Wireless Technologies Pte. Ltd."},
  {max_prefix: "00-14-20",vendor: "G-Links networking company"},
  {max_prefix: "00-14-18",vendor: "C4Line"},
  {max_prefix: "00-14-1B",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-14-0F",vendor: "Federal State Unitary Enterprise Leningrad R&D Institute of"},
  {max_prefix: "00-14-07",vendor: "Sperian Protection Instrumentation"},
  {max_prefix: "00-14-06",vendor: "Go Networks"},
  {max_prefix: "00-13-F0",vendor: "Wavefront Semiconductor"},
  {max_prefix: "00-13-EF",vendor: "Kingjon Digital Technology Co.,Ltd"},
  {max_prefix: "00-13-EB",vendor: "Sysmaster Corporation"},
  {max_prefix: "00-13-EC",vendor: "Netsnapper Technologies SARL"},
  {max_prefix: "00-14-0A",vendor: "WEPIO Co., Ltd."},
  {max_prefix: "00-13-FA",vendor: "LifeSize Communications, Inc"},
  {max_prefix: "00-13-FB",vendor: "RKC INSTRUMENT INC."},
  {max_prefix: "00-13-C3",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-13-BD",vendor: "HYMATOM SA"},
  {max_prefix: "00-13-D0",vendor: "t+ Medical Ltd"},
  {max_prefix: "00-13-D2",vendor: "PAGE IBERICA, S.A."},
  {max_prefix: "00-13-D1",vendor: "KIRK telecom A/S"},
  {max_prefix: "00-13-66",vendor: "Neturity Technologies Inc."},
  {max_prefix: "00-13-5B",vendor: "PanelLink Cinema, LLC"},
  {max_prefix: "00-13-5E",vendor: "EAB/RWI/K"},
  {max_prefix: "00-13-73",vendor: "BLwave Electronics Co., Ltd"},
  {max_prefix: "00-13-6F",vendor: "PacketMotion, Inc."},
  {max_prefix: "00-13-4E",vendor: "Valox Systems, Inc."},
  {max_prefix: "00-13-44",vendor: "Fargo Electronics Inc."},
  {max_prefix: "00-13-48",vendor: "Artila Electronics Co., Ltd."},
  {max_prefix: "00-13-7D",vendor: "Dynalab, Inc."},
  {max_prefix: "00-13-83",vendor: "Application Technologies and Engineering Research Laboratory"},
  {max_prefix: "00-13-87",vendor: "27M Technologies AB"},
  {max_prefix: "00-13-B4",vendor: "Appear AS"},
  {max_prefix: "00-13-AE",vendor: "Radiance Technologies, Inc."},
  {max_prefix: "00-13-97",vendor: "Oracle Corporation"},
  {max_prefix: "00-13-36",vendor: "Tianjin 712 Communication Broadcasting co., ltd."},
  {max_prefix: "00-12-DB",vendor: "ZIEHL industrie-elektronik GmbH + Co KG"},
  {max_prefix: "00-12-D6",vendor: "Jiangsu Yitong High-Tech Co.,Ltd"},
  {max_prefix: "00-12-DA",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-12-D3",vendor: "Zetta Systems, Inc."},
  {max_prefix: "00-12-D5",vendor: "Motion Reality Inc."},
  {max_prefix: "00-12-D8",vendor: "International Games System Co., Ltd."},
  {max_prefix: "00-13-22",vendor: "DAQ Electronics, Inc."},
  {max_prefix: "00-13-23",vendor: "Cap Co., Ltd."},
  {max_prefix: "00-13-14",vendor: "Asiamajor Inc."},
  {max_prefix: "00-13-16",vendor: "L-S-B Broadcast Technologies GmbH"},
  {max_prefix: "00-12-BA",vendor: "FSI Systems, Inc."},
  {max_prefix: "00-12-B2",vendor: "AVOLITES LTD."},
  {max_prefix: "00-12-AE",vendor: "HLS HARD-LINE Solutions Inc."},
  {max_prefix: "00-12-AF",vendor: "ELPRO Technologies"},
  {max_prefix: "00-12-E9",vendor: "Abbey Systems Ltd"},
  {max_prefix: "00-12-E6",vendor: "SPECTEC COMPUTER CO., LTD."},
  {max_prefix: "00-12-E3",vendor: "Agat Soft LLC"},
  {max_prefix: "00-13-12",vendor: "Amedia Networks Inc."},
  {max_prefix: "00-13-00",vendor: "IT-FACTORY, INC."},
  {max_prefix: "00-12-EF",vendor: "OneAccess SA"},
  {max_prefix: "00-12-3B",vendor: "KeRo Systems ApS"},
  {max_prefix: "00-12-36",vendor: "ConSentry Networks"},
  {max_prefix: "00-12-35",vendor: "Andrew Corporation"},
  {max_prefix: "00-12-57",vendor: "LeapComm Communication Technologies Inc."},
  {max_prefix: "00-12-51",vendor: "SILINK"},
  {max_prefix: "00-12-45",vendor: "Zellweger Analytics, Inc."},
  {max_prefix: "00-12-A2",vendor: "VITA"},
  {max_prefix: "00-12-A8",vendor: "intec GmbH"},
  {max_prefix: "00-12-A5",vendor: "Dolphin Interconnect Solutions AS"},
  {max_prefix: "00-12-9E",vendor: "Surf Communications Inc."},
  {max_prefix: "00-12-9A",vendor: "IRT Electronics Pty Ltd"},
  {max_prefix: "00-12-82",vendor: "Qovia"},
  {max_prefix: "00-12-87",vendor: "Digital Everywhere Unterhaltungselektronik GmbH"},
  {max_prefix: "00-12-85",vendor: "Gizmondo Europe Ltd"},
  {max_prefix: "00-12-42",vendor: "Millennial Net"},
  {max_prefix: "00-12-41",vendor: "a2i marketing center"},
  {max_prefix: "00-12-6D",vendor: "University of California, Berkeley"},
  {max_prefix: "00-12-67",vendor: "Panasonic Corporation"},
  {max_prefix: "00-12-65",vendor: "Enerdyne Technologies, Inc."},
  {max_prefix: "00-12-8D",vendor: "STB Datenservice GmbH"},
  {max_prefix: "00-12-8E",vendor: "Q-Free ASA"},
  {max_prefix: "00-12-92",vendor: "Griffin Technology"},
  {max_prefix: "00-11-F3",vendor: "NeoMedia Europe AG"},
  {max_prefix: "00-11-E9",vendor: "STARNEX CO., LTD."},
  {max_prefix: "00-11-EC",vendor: "AVIX INC."},
  {max_prefix: "00-11-E7",vendor: "WORLDSAT - Texas de France"},
  {max_prefix: "00-11-E8",vendor: "Tixi.Com"},
  {max_prefix: "00-11-FD",vendor: "KORG INC."},
  {max_prefix: "00-11-FA",vendor: "Rane Corporation"},
  {max_prefix: "00-11-F2",vendor: "Institute of Network Technologies"},
  {max_prefix: "00-12-28",vendor: "Data Ltd."},
  {max_prefix: "00-12-1F",vendor: "Harding Instruments"},
  {max_prefix: "00-12-20",vendor: "Cadco Systems"},
  {max_prefix: "00-12-10",vendor: "WideRay Corp"},
  {max_prefix: "00-12-0D",vendor: "Advanced Telecommunication Technologies, Inc."},
  {max_prefix: "00-12-0E",vendor: "AboCom"},
  {max_prefix: "00-12-02",vendor: "Decrane Aerospace - Audio International Inc."},
  {max_prefix: "00-11-FE",vendor: "Keiyo System Research, Inc."},
  {max_prefix: "00-11-CE",vendor: "Ubisense Limited"},
  {max_prefix: "00-11-D0",vendor: "Tandberg Data ASA"},
  {max_prefix: "00-11-C3",vendor: "Transceiving System Technology Corporation"},
  {max_prefix: "00-11-C2",vendor: "United Fiber Optic Communication"},
  {max_prefix: "00-11-B7",vendor: "Octalix B.V."},
  {max_prefix: "00-11-B9",vendor: "Inner Range Pty. Ltd."},
  {max_prefix: "00-11-A7",vendor: "Infilco Degremont Inc."},
  {max_prefix: "00-12-26",vendor: "Japan Direx Corporation"},
  {max_prefix: "00-12-29",vendor: "BroadEasy Technologies Co.,Ltd"},
  {max_prefix: "00-12-22",vendor: "Skardin (UK) Ltd"},
  {max_prefix: "00-11-BF",vendor: "AESYS S.p.A."},
  {max_prefix: "00-11-E0",vendor: "U-MEDIA Communications, Inc."},
  {max_prefix: "00-11-A3",vendor: "LanReady Technologies Inc."},
  {max_prefix: "00-11-A4",vendor: "JStream Technologies Inc."},
  {max_prefix: "00-11-98",vendor: "Prism Media Products Limited"},
  {max_prefix: "00-11-97",vendor: "Monitoring Technologies Limited"},
  {max_prefix: "00-11-99",vendor: "2wcom Systems GmbH"},
  {max_prefix: "00-11-76",vendor: "Intellambda Systems, Inc."},
  {max_prefix: "00-11-77",vendor: "Coaxial Networks, Inc."},
  {max_prefix: "00-11-70",vendor: "GSC SRL"},
  {max_prefix: "00-11-6B",vendor: "Digital Data Communications Asia Co.,Ltd"},
  {max_prefix: "00-11-69",vendor: "EMS Satcom"},
  {max_prefix: "00-11-62",vendor: "STAR MICRONICS CO.,LTD."},
  {max_prefix: "00-11-61",vendor: "NetStreams, LLC"},
  {max_prefix: "00-11-56",vendor: "Pharos Systems NZ"},
  {max_prefix: "00-11-59",vendor: "MATISSE NETWORKS INC"},
  {max_prefix: "00-11-5C",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-11-81",vendor: "InterEnergy Co.Ltd,"},
  {max_prefix: "00-11-94",vendor: "Chi Mei Communication Systems, Inc."},
  {max_prefix: "00-11-3D",vendor: "KN SOLTEC CO.,LTD."},
  {max_prefix: "00-11-34",vendor: "MediaCell, Inc."},
  {max_prefix: "00-11-5D",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-11-49",vendor: "Proliphix Inc."},
  {max_prefix: "00-11-3B",vendor: "Micronet Communications Inc."},
  {max_prefix: "00-11-35",vendor: "Grandeye Ltd"},
  {max_prefix: "00-11-26",vendor: "Venstar Inc."},
  {max_prefix: "00-0F-C0",vendor: "DELCOMp"},
  {max_prefix: "00-0F-BA",vendor: "Tevebox AB"},
  {max_prefix: "00-0F-B8",vendor: "CallURL Inc."},
  {max_prefix: "00-0F-B7",vendor: "Cavium"},
  {max_prefix: "00-0F-D1",vendor: "Applied Wireless Identifications Group, Inc."},
  {max_prefix: "00-0F-C1",vendor: "WAVE Corporation"},
  {max_prefix: "00-0F-C4",vendor: "NST co.,LTD."},
  {max_prefix: "00-0F-C9",vendor: "Allnet GmbH"},
  {max_prefix: "00-0F-C6",vendor: "Eurocom Industries A/S"},
  {max_prefix: "00-0F-BE",vendor: "e-w/you Inc."},
  {max_prefix: "00-11-16",vendor: "COTEAU VERT CO., LTD."},
  {max_prefix: "00-11-10",vendor: "Maxanna Technology Co., Ltd."},
  {max_prefix: "00-11-09",vendor: "Micro-Star International"},
  {max_prefix: "00-11-04",vendor: "TELEXY"},
  {max_prefix: "00-11-00",vendor: "Schneider Electric"},
  {max_prefix: "00-0F-FA",vendor: "Optinel Systems, Inc."},
  {max_prefix: "00-0F-FD",vendor: "Glorytek Network Inc."},
  {max_prefix: "00-11-2E",vendor: "CEICOM"},
  {max_prefix: "00-11-21",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-11-22",vendor: "CIMSYS Inc"},
  {max_prefix: "00-0F-D8",vendor: "Force, Inc."},
  {max_prefix: "00-0F-D3",vendor: "Digium"},
  {max_prefix: "00-0F-F9",vendor: "Valcretec, Inc."},
  {max_prefix: "00-0F-F7",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-0F-A9",vendor: "PC Fabrik"},
  {max_prefix: "00-0F-85",vendor: "ADDO-Japan Corporation"},
  {max_prefix: "00-0F-82",vendor: "Mortara Instrument, Inc."},
  {max_prefix: "00-0F-81",vendor: "PAL Pacific Inc."},
  {max_prefix: "00-0F-7F",vendor: "UBSTORAGE Co.,Ltd."},
  {max_prefix: "00-0F-9A",vendor: "Synchrony, Inc."},
  {max_prefix: "00-0F-A0",vendor: "Canon Korea Inc."},
  {max_prefix: "00-0F-95",vendor: "ELECOM Co.,LTD Laneed Division"},
  {max_prefix: "00-0F-4B",vendor: "Oracle Corporation"},
  {max_prefix: "00-0F-4A",vendor: "Kyushu-kyohan co.,ltd"},
  {max_prefix: "00-0F-44",vendor: "Tivella Inc."},
  {max_prefix: "00-0F-40",vendor: "Optical Internetworking Forum"},
  {max_prefix: "00-0F-60",vendor: "Lifetron Co.,Ltd"},
  {max_prefix: "00-0F-5B",vendor: "Delta Information Systems, Inc."},
  {max_prefix: "00-0F-55",vendor: "Datawire Communication Networks Inc."},
  {max_prefix: "00-0F-56",vendor: "Continuum Photonics Inc"},
  {max_prefix: "00-0F-49",vendor: "Northover Solutions Limited"},
  {max_prefix: "00-0F-77",vendor: "DENTUM CO.,LTD"},
  {max_prefix: "00-0F-8A",vendor: "WideView"},
  {max_prefix: "00-0F-90",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-0F-8D",vendor: "FAST TV-Server AG"},
  {max_prefix: "00-0F-7B",vendor: "Arce Sistemas, S.A."},
  {max_prefix: "00-0F-68",vendor: "Vavic Network Technology, Inc."},
  {max_prefix: "00-0F-33",vendor: "DUALi Inc."},
  {max_prefix: "00-0F-2F",vendor: "W-LINX TECHNOLOGY CO., LTD."},
  {max_prefix: "00-0E-EB",vendor: "Sandmartin(zhong shan)Electronics Co.,Ltd"},
  {max_prefix: "00-0E-EC",vendor: "Orban"},
  {max_prefix: "00-0E-EF",vendor: "Private"},
  {max_prefix: "00-0E-F1",vendor: "EZQUEST INC."},
  {max_prefix: "00-0E-E7",vendor: "AAC ELECTRONICS CORP."},
  {max_prefix: "00-0E-E2",vendor: "Custom Engineering"},
  {max_prefix: "00-0E-D0",vendor: "Privaris, Inc."},
  {max_prefix: "00-0E-C3",vendor: "Logic Controls, Inc."},
  {max_prefix: "00-0E-C4",vendor: "Iskra Transmission d.d."},
  {max_prefix: "00-0E-C1",vendor: "MYNAH Technologies"},
  {max_prefix: "00-0E-BD",vendor: "Burdick, a Quinton Compny"},
  {max_prefix: "00-0E-E3",vendor: "Chiyu Technology Co.,Ltd"},
  {max_prefix: "00-0E-E5",vendor: "bitWallet, Inc."},
  {max_prefix: "00-0E-CF",vendor: "PROFIBUS Nutzerorganisation e.V."},
  {max_prefix: "00-0E-DA",vendor: "C-TECH UNITED CORP."},
  {max_prefix: "00-0E-C9",vendor: "YOKO Technology Corp."},
  {max_prefix: "00-0F-0A",vendor: "Clear Edge Networks"},
  {max_prefix: "00-0F-02",vendor: "Digicube Technology Co., Ltd"},
  {max_prefix: "00-0F-05",vendor: "3B SYSTEM INC."},
  {max_prefix: "00-0F-1C",vendor: "DigitAll World Co., Ltd"},
  {max_prefix: "00-0F-1A",vendor: "Gaming Support B.V."},
  {max_prefix: "00-0E-B9",vendor: "HASHIMOTO Electronics Industry Co.,Ltd."},
  {max_prefix: "00-0E-B2",vendor: "Micro-Research Finland Oy"},
  {max_prefix: "00-0E-B5",vendor: "Ecastle Electronics Co., Ltd."},
  {max_prefix: "00-0E-A1",vendor: "Formosa Teletek Corporation"},
  {max_prefix: "00-0E-98",vendor: "HME Clear-Com LTD."},
  {max_prefix: "00-0E-99",vendor: "Spectrum Digital, Inc"},
  {max_prefix: "00-0E-F7",vendor: "Vulcan Portals Inc"},
  {max_prefix: "00-0E-E9",vendor: "WayTech Development, Inc."},
  {max_prefix: "00-0E-F0",vendor: "Festo AG & Co. KG"},
  {max_prefix: "00-0E-A7",vendor: "Endace Technology"},
  {max_prefix: "00-0E-95",vendor: "Fujiya Denki Seisakusho Co.,Ltd."},
  {max_prefix: "00-0E-97",vendor: "Ultracker Technology CO., Inc"},
  {max_prefix: "00-0E-45",vendor: "Beijing Newtry Electronic Technology Ltd"},
  {max_prefix: "00-0E-3F",vendor: "Soronti, Inc."},
  {max_prefix: "00-0E-82",vendor: "Infinity Tech"},
  {max_prefix: "00-0E-89",vendor: "CLEMATIC"},
  {max_prefix: "00-0E-79",vendor: "Ample Communications Inc."},
  {max_prefix: "00-0E-15",vendor: "Tadlys LTD"},
  {max_prefix: "00-0E-1D",vendor: "ARION Technology Inc."},
  {max_prefix: "00-0E-12",vendor: "Adaptive Micro Systems Inc."},
  {max_prefix: "00-0E-4F",vendor: "Trajet GmbH"},
  {max_prefix: "00-0E-50",vendor: "Thomson Telecom Belgium"},
  {max_prefix: "00-0E-4B",vendor: "atrium c and i"},
  {max_prefix: "00-0E-38",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-0E-36",vendor: "HEINESYS, Inc."},
  {max_prefix: "00-0E-2B",vendor: "Safari Technologies"},
  {max_prefix: "00-0E-28",vendor: "Dynamic Ratings P/L"},
  {max_prefix: "00-0E-17",vendor: "Private"},
  {max_prefix: "00-0E-6F",vendor: "IRIS Corporation Berhad"},
  {max_prefix: "00-0D-CB",vendor: "Petcomkorea Co., Ltd."},
  {max_prefix: "00-0D-CC",vendor: "NEOSMART Corp."},
  {max_prefix: "00-0D-CE",vendor: "Dynavac Technology Pte Ltd"},
  {max_prefix: "00-0D-B9",vendor: "PC Engines GmbH"},
  {max_prefix: "00-0D-B7",vendor: "SANKO ELECTRIC CO,.LTD"},
  {max_prefix: "00-0D-C3",vendor: "First Communication, Inc."},
  {max_prefix: "00-0D-E6",vendor: "YOUNGBO ENGINEERING CO.,LTD"},
  {max_prefix: "00-0D-DF",vendor: "Japan Image & Network Inc."},
  {max_prefix: "00-0E-04",vendor: "CMA/Microdialysis AB"},
  {max_prefix: "00-0D-E8",vendor: "Nasaco Electronics Pte. Ltd"},
  {max_prefix: "00-0D-E9",vendor: "Napatech Aps"},
  {max_prefix: "00-0D-DB",vendor: "AIRWAVE TECHNOLOGIES INC."},
  {max_prefix: "00-0D-AC",vendor: "Japan CBM Corporation"},
  {max_prefix: "00-0E-01",vendor: "ASIP Technologies Inc."},
  {max_prefix: "00-0D-45",vendor: "Tottori SANYO Electric Co., Ltd."},
  {max_prefix: "00-0D-46",vendor: "Parker SSD Drives"},
  {max_prefix: "00-0D-41",vendor: "Siemens AG ICM MP UC RD IT KLF1"},
  {max_prefix: "00-0D-42",vendor: "Newbest Development Limited"},
  {max_prefix: "00-0D-3C",vendor: "i.Tech Dynamic Ltd"},
  {max_prefix: "00-0D-2A",vendor: "Scanmatic AS"},
  {max_prefix: "00-0D-29",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-0D-26",vendor: "Primagraphics Limited"},
  {max_prefix: "00-0D-65",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-0D-55",vendor: "SANYCOM Technology Co.,Ltd"},
  {max_prefix: "00-0D-57",vendor: "Fujitsu I-Network Systems Limited."},
  {max_prefix: "00-0D-58",vendor: "Private"},
  {max_prefix: "00-0D-51",vendor: "DIVR Systems, Inc."},
  {max_prefix: "00-0D-19",vendor: "ROBE Show lighting"},
  {max_prefix: "00-0D-1C",vendor: "Amesys Defense"},
  {max_prefix: "00-0D-13",vendor: "Wilhelm Rutenbeck GmbH&Co.KG"},
  {max_prefix: "00-0D-16",vendor: "UHS Systems Pty Ltd"},
  {max_prefix: "00-0D-1F",vendor: "AV Digital"},
  {max_prefix: "00-0D-0C",vendor: "MDI Security Systems"},
  {max_prefix: "00-0D-01",vendor: "P&E Microcomputer Systems, Inc."},
  {max_prefix: "00-0D-35",vendor: "PAC International Ltd"},
  {max_prefix: "00-0D-95",vendor: "Opti-cell, Inc."},
  {max_prefix: "00-0D-91",vendor: "Eclipse (HQ Espana) S.L."},
  {max_prefix: "00-0D-62",vendor: "Funkwerk Dabendorf GmbH"},
  {max_prefix: "00-0D-80",vendor: "Online Development Inc"},
  {max_prefix: "00-0C-A3",vendor: "Rancho Technology, Inc."},
  {max_prefix: "00-0C-AA",vendor: "Cubic Transportation Systems Inc"},
  {max_prefix: "00-0C-AC",vendor: "Citizen Watch Co., Ltd."},
  {max_prefix: "00-0C-AE",vendor: "Ailocom Oy"},
  {max_prefix: "00-0C-9E",vendor: "MemoryLink Corp."},
  {max_prefix: "00-0C-95",vendor: "PrimeNet"},
  {max_prefix: "00-0C-9C",vendor: "Chongho information & communications"},
  {max_prefix: "00-0C-97",vendor: "NV ADB TTV Technologies SA"},
  {max_prefix: "00-0C-8F",vendor: "Nergal s.r.l."},
  {max_prefix: "00-0C-92",vendor: "WolfVision Gmbh"},
  {max_prefix: "00-0C-93",vendor: "Xeline Co., Ltd."},
  {max_prefix: "00-0C-7F",vendor: "synertronixx GmbH"},
  {max_prefix: "00-0C-81",vendor: "Schneider Electric (Australia)"},
  {max_prefix: "00-0C-80",vendor: "Opelcomm Inc."},
  {max_prefix: "00-0C-7D",vendor: "TEIKOKU ELECTRIC MFG. CO., LTD"},
  {max_prefix: "00-0C-F0",vendor: "M & N GmbH"},
  {max_prefix: "00-0C-F4",vendor: "AKATSUKI ELECTRIC MFG.CO.,LTD."},
  {max_prefix: "00-0C-F3",vendor: "CALL IMAGE SA"},
  {max_prefix: "00-0C-E0",vendor: "Trek Diagnostics Inc."},
  {max_prefix: "00-0C-E2",vendor: "Rolls-Royce"},
  {max_prefix: "00-0C-D1",vendor: "SFOM Technology Corp."},
  {max_prefix: "00-0C-D8",vendor: "M. K. Juchheim GmbH & Co"},
  {max_prefix: "00-0C-CE",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-0C-CF",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-0D-00",vendor: "Seaway Networks Inc."},
  {max_prefix: "00-0C-F9",vendor: "Xylem Water Solutions"},
  {max_prefix: "00-0C-ED",vendor: "Real Digital Media"},
  {max_prefix: "00-0C-C6",vendor: "Ka-Ro electronics GmbH"},
  {max_prefix: "00-0C-C4",vendor: "Tiptel AG"},
  {max_prefix: "00-0C-BA",vendor: "Jamex, Inc."},
  {max_prefix: "00-0C-B7",vendor: "Nanjing Huazhuo Electronics Co., Ltd."},
  {max_prefix: "00-0C-AD",vendor: "BTU International"},
  {max_prefix: "00-0C-0A",vendor: "Guangdong Province Electronic Technology Research Institute"},
  {max_prefix: "00-0C-0B",vendor: "Broadbus Technologies"},
  {max_prefix: "00-0C-07",vendor: "Iftest AG"},
  {max_prefix: "00-0C-0C",vendor: "APPRO TECHNOLOGY INC."},
  {max_prefix: "00-0C-18",vendor: "Zenisu Keisoku Inc."},
  {max_prefix: "00-0C-0E",vendor: "XtremeSpectrum, Inc."},
  {max_prefix: "00-0C-12",vendor: "Micro-Optronic-Messtechnik GmbH"},
  {max_prefix: "00-0C-10",vendor: "PNI Corporation"},
  {max_prefix: "00-0C-40",vendor: "Altech Controls"},
  {max_prefix: "00-0C-3E",vendor: "Crest Audio"},
  {max_prefix: "00-0C-3A",vendor: "Oxance"},
  {max_prefix: "00-0C-35",vendor: "KaVo Dental GmbH & Co. KG"},
  {max_prefix: "00-0C-37",vendor: "Geomation, Inc."},
  {max_prefix: "00-0C-56",vendor: "Megatel Computer (1986) Corp."},
  {max_prefix: "00-0C-57",vendor: "MACKIE Engineering Services Belgium BVBA"},
  {max_prefix: "00-0C-76",vendor: "MICRO-STAR INTERNATIONAL CO., LTD."},
  {max_prefix: "00-0C-4A",vendor: "Cygnus Microsystems (P) Limited"},
  {max_prefix: "00-0C-23",vendor: "Beijing Lanchuan Tech. Co., Ltd."},
  {max_prefix: "00-0C-25",vendor: "Allied Telesis Labs, Inc."},
  {max_prefix: "00-0C-54",vendor: "Pedestal Networks, Inc"},
  {max_prefix: "00-0C-68",vendor: "SigmaTel, Inc."},
  {max_prefix: "00-0B-E5",vendor: "HIMS International Corporation"},
  {max_prefix: "00-0B-E9",vendor: "Actel Corporation"},
  {max_prefix: "00-0B-E3",vendor: "Key Stream Co., Ltd."},
  {max_prefix: "00-0B-E8",vendor: "AOIP"},
  {max_prefix: "00-0B-DC",vendor: "AKCP"},
  {max_prefix: "00-0B-D8",vendor: "Industrial Scientific Corp."},
  {max_prefix: "00-0B-D7",vendor: "DORMA Time + Access GmbH"},
  {max_prefix: "00-0B-D3",vendor: "cd3o"},
  {max_prefix: "00-0B-D5",vendor: "Nvergence, Inc."},
  {max_prefix: "00-0B-D1",vendor: "Aeronix, Inc."},
  {max_prefix: "00-0B-D2",vendor: "Remopro Technology Inc."},
  {max_prefix: "00-0B-C7",vendor: "ICET S.p.A."},
  {max_prefix: "00-0B-9F",vendor: "Neue ELSA GmbH"},
  {max_prefix: "00-0B-95",vendor: "eBet Gaming Systems Pty Ltd"},
  {max_prefix: "00-0B-98",vendor: "NiceTechVision"},
  {max_prefix: "00-0B-9E",vendor: "Yasing Technology Corp."},
  {max_prefix: "00-0B-88",vendor: "Vidisco ltd."},
  {max_prefix: "00-0B-B8",vendor: "Kihoku Electronic Co."},
  {max_prefix: "00-0B-C0",vendor: "China IWNComm Co., Ltd."},
  {max_prefix: "00-0B-B0",vendor: "Sysnet Telematica srl"},
  {max_prefix: "00-0B-B4",vendor: "RDC Semiconductor Inc.,"},
  {max_prefix: "00-0B-8A",vendor: "MITEQ Inc."},
  {max_prefix: "00-0B-8C",vendor: "Flextronics"},
  {max_prefix: "00-0B-90",vendor: "ADVA Optical Networking Ltd."},
  {max_prefix: "00-0B-79",vendor: "X-COM, Inc."},
  {max_prefix: "00-0B-F9",vendor: "Gemstone Communications, Inc."},
  {max_prefix: "00-0B-FC",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-0B-AC",vendor: "3Com Ltd"},
  {max_prefix: "00-0B-EA",vendor: "Zultys Technologies"},
  {max_prefix: "00-0B-DD",vendor: "TOHOKU RICOH Co., LTD."},
  {max_prefix: "00-0B-4C",vendor: "Clarion (M) Sdn Bhd"},
  {max_prefix: "00-0B-4B",vendor: "VISIOWAVE SA"},
  {max_prefix: "00-0B-36",vendor: "Productivity Systems, Inc."},
  {max_prefix: "00-0B-35",vendor: "Quad Bit System co., Ltd."},
  {max_prefix: "00-0B-3F",vendor: "Anthology Solutions Inc."},
  {max_prefix: "00-0B-15",vendor: "Platypus Technology"},
  {max_prefix: "00-0B-18",vendor: "Private"},
  {max_prefix: "00-0B-0D",vendor: "Air2U, Inc."},
  {max_prefix: "00-0B-0B",vendor: "Corrent Corporation"},
  {max_prefix: "00-0B-08",vendor: "Pillar Data Systems"},
  {max_prefix: "00-0B-54",vendor: "BiTMICRO Networks, Inc."},
  {max_prefix: "00-0B-43",vendor: "Microscan Systems, Inc."},
  {max_prefix: "00-0B-45",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-0B-21",vendor: "G-Star Communications Inc."},
  {max_prefix: "00-0B-25",vendor: "Aeluros"},
  {max_prefix: "00-0B-1A",vendor: "Industrial Defender, Inc."},
  {max_prefix: "00-0B-68",vendor: "Addvalue Communications Pte Ltd"},
  {max_prefix: "00-0B-58",vendor: "Astronautics C.A  LTD"},
  {max_prefix: "00-0B-55",vendor: "ADInstruments"},
  {max_prefix: "00-0B-87",vendor: "American Reliance Inc."},
  {max_prefix: "00-0B-75",vendor: "Iosoft Ltd."},
  {max_prefix: "00-0B-70",vendor: "Load Technology, Inc."},
  {max_prefix: "00-0B-6F",vendor: "Media Streaming Networks Inc"},
  {max_prefix: "00-0A-FA",vendor: "Traverse Technologies Australia"},
  {max_prefix: "00-0A-FC",vendor: "Core Tec Communications, LLC"},
  {max_prefix: "00-0B-3C",vendor: "Cygnal Integrated Products, Inc."},
  {max_prefix: "00-0B-27",vendor: "Scion Corporation"},
  {max_prefix: "00-0B-63",vendor: "Kaleidescape"},
  {max_prefix: "00-0A-BA",vendor: "Arcon Technology Limited"},
  {max_prefix: "00-0A-AF",vendor: "Pipal Systems"},
  {max_prefix: "00-0A-B2",vendor: "Fresnel Wireless Systems"},
  {max_prefix: "00-0A-B6",vendor: "COMPUNETIX, INC"},
  {max_prefix: "00-0A-AA",vendor: "AltiGen Communications Inc."},
  {max_prefix: "00-0A-BE",vendor: "OPNET Technologies CO., LTD."},
  {max_prefix: "00-0A-C0",vendor: "Fuyoh Video Industry CO., LTD."},
  {max_prefix: "00-0A-AE",vendor: "Rosemount Process Analytical"},
  {max_prefix: "00-0A-B3",vendor: "Fa. GIRA"},
  {max_prefix: "00-0A-C9",vendor: "Zambeel Inc"},
  {max_prefix: "00-0A-C1",vendor: "Futuretel"},
  {max_prefix: "00-0A-C3",vendor: "eM Technics Co., Ltd."},
  {max_prefix: "00-0A-C4",vendor: "Daewoo Teletech Co., Ltd"},
  {max_prefix: "00-0A-E8",vendor: "Cathay Roxus Information Technology Co. LTD"},
  {max_prefix: "00-0A-DD",vendor: "Allworx Corp."},
  {max_prefix: "00-0A-E1",vendor: "EG Technology"},
  {max_prefix: "00-0A-DF",vendor: "Gennum Corporation"},
  {max_prefix: "00-0A-DA",vendor: "Vindicator Technologies"},
  {max_prefix: "00-0A-8D",vendor: "EUROTHERM LIMITED"},
  {max_prefix: "00-0A-9F",vendor: "Pannaway Technologies, Inc."},
  {max_prefix: "00-0A-8F",vendor: "Aska International Inc."},
  {max_prefix: "00-0A-A0",vendor: "Cedar Point Communications"},
  {max_prefix: "00-0A-8E",vendor: "Invacom Ltd"},
  {max_prefix: "00-0A-F1",vendor: "Clarity Design, Inc."},
  {max_prefix: "00-0A-F3",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-0A-EC",vendor: "Koatsu Gas Kogyo Co., Ltd."},
  {max_prefix: "00-0A-E7",vendor: "ELIOP S.A."},
  {max_prefix: "00-0A-76",vendor: "Beida Jade Bird Huaguang Technology Co.,Ltd"},
  {max_prefix: "00-0A-99",vendor: "Calamp Wireless Networks Inc"},
  {max_prefix: "00-0A-93",vendor: "W2 Networks, Inc."},
  {max_prefix: "00-0A-89",vendor: "Creval Systems, Inc."},
  {max_prefix: "00-0A-80",vendor: "Telkonet Inc."},
  {max_prefix: "00-0A-79",vendor: "corega K.K"},
  {max_prefix: "00-0A-30",vendor: "Visteon Corporation"},
  {max_prefix: "00-0A-2F",vendor: "Artnix Inc."},
  {max_prefix: "00-0A-2C",vendor: "Active Tchnology Corporation"},
  {max_prefix: "00-0A-2A",vendor: "QSI Systems Inc."},
  {max_prefix: "00-09-D6",vendor: "KNC One GmbH"},
  {max_prefix: "00-09-C7",vendor: "Movistec"},
  {max_prefix: "00-09-C9",vendor: "BlueWINC Co., Ltd."},
  {max_prefix: "00-09-D4",vendor: "Transtech Networks"},
  {max_prefix: "00-0A-60",vendor: "Autostar Technology Pte Ltd"},
  {max_prefix: "00-0A-5D",vendor: "FingerTec Worldwide Sdn Bhd"},
  {max_prefix: "00-0A-5C",vendor: "Carel s.p.a."},
  {max_prefix: "00-0A-5A",vendor: "GreenNET Technologies Co.,Ltd."},
  {max_prefix: "00-0A-04",vendor: "3Com Ltd"},
  {max_prefix: "00-09-F1",vendor: "Yamaki Electric Corporation"},
  {max_prefix: "00-09-F4",vendor: "Alcon Laboratories, Inc."},
  {max_prefix: "00-0A-1F",vendor: "ART WARE Telecommunication Co., Ltd."},
  {max_prefix: "00-0A-15",vendor: "Silicon Data, Inc"},
  {max_prefix: "00-0A-1B",vendor: "Stream Labs"},
  {max_prefix: "00-0A-56",vendor: "HITACHI Maxell Ltd."},
  {max_prefix: "00-0A-51",vendor: "GyroSignal Technology Co., Ltd."},
  {max_prefix: "00-0A-53",vendor: "Intronics, Incorporated"},
  {max_prefix: "00-0A-4A",vendor: "Targa Systems Ltd."},
  {max_prefix: "00-0A-39",vendor: "LoPA Information Technology"},
  {max_prefix: "00-0A-37",vendor: "Procera Networks, Inc."},
  {max_prefix: "00-0A-34",vendor: "Identicard Systems Incorporated"},
  {max_prefix: "00-09-F5",vendor: "Emerson Network Power Co.,Ltd"},
  {max_prefix: "00-09-EA",vendor: "YEM Inc."},
  {max_prefix: "00-09-E3",vendor: "Angel Iglesias S.A."},
  {max_prefix: "00-0A-1A",vendor: "Imerge Ltd"},
  {max_prefix: "00-0A-0A",vendor: "SUNIX Co., Ltd."},
  {max_prefix: "00-0A-05",vendor: "Widax Corp."},
  {max_prefix: "00-09-CB",vendor: "HBrain"},
  {max_prefix: "00-09-C5",vendor: "KINGENE Technology Corporation"},
  {max_prefix: "00-09-CD",vendor: "HUDSON SOFT CO.,LTD."},
  {max_prefix: "00-09-C0",vendor: "6WIND"},
  {max_prefix: "00-09-BF",vendor: "Nintendo Co., Ltd."},
  {max_prefix: "00-09-7E",vendor: "IMI TECHNOLOGY CO., LTD"},
  {max_prefix: "00-09-71",vendor: "Time Management, Inc."},
  {max_prefix: "00-09-6A",vendor: "Cloverleaf Communications Inc."},
  {max_prefix: "00-09-5A",vendor: "RACEWOOD TECHNOLOGY"},
  {max_prefix: "00-09-B4",vendor: "KISAN TELECOM CO., LTD."},
  {max_prefix: "00-09-AE",vendor: "OKANO ELECTRIC CO.,LTD"},
  {max_prefix: "00-09-74",vendor: "Innopia Technologies, Inc."},
  {max_prefix: "00-09-67",vendor: "Tachyon, Inc"},
  {max_prefix: "00-09-96",vendor: "RDI"},
  {max_prefix: "00-09-8B",vendor: "Entropic Communications, Inc."},
  {max_prefix: "00-09-91",vendor: "Intelligent Platforms, LLC."},
  {max_prefix: "00-09-BA",vendor: "MAKU Informationstechik GmbH"},
  {max_prefix: "00-09-2F",vendor: "Akom Technology Corporation"},
  {max_prefix: "00-09-1F",vendor: "A&D Co., Ltd."},
  {max_prefix: "00-09-24",vendor: "Telebau GmbH"},
  {max_prefix: "00-09-0D",vendor: "LEADER ELECTRONICS CORP."},
  {max_prefix: "00-08-FE",vendor: "UNIK C&C Co.,Ltd."},
  {max_prefix: "00-08-FF",vendor: "Trilogy Communications Ltd"},
  {max_prefix: "00-09-04",vendor: "MONDIAL electronic"},
  {max_prefix: "00-09-21",vendor: "Planmeca Oy"},
  {max_prefix: "00-09-19",vendor: "MDS Gateways"},
  {max_prefix: "00-09-18",vendor: "SAMSUNG TECHWIN CO.,LTD"},
  {max_prefix: "00-09-10",vendor: "Simple Access Inc."},
  {max_prefix: "00-08-FC",vendor: "Gigaphoton Inc."},
  {max_prefix: "00-08-EF",vendor: "DIBAL,S.A."},
  {max_prefix: "00-08-F0",vendor: "Next Generation Systems, Inc."},
  {max_prefix: "00-09-47",vendor: "Aztek, Inc."},
  {max_prefix: "00-09-3D",vendor: "Newisys,Inc."},
  {max_prefix: "00-09-51",vendor: "Apogee Imaging Systems"},
  {max_prefix: "00-09-4E",vendor: "BARTECH SYSTEMS INTERNATIONAL, INC"},
  {max_prefix: "00-08-E9",vendor: "NextGig"},
  {max_prefix: "00-08-E7",vendor: "SHI ControlSystems,Ltd."},
  {max_prefix: "00-09-14",vendor: "COMPUTROLS INC."},
  {max_prefix: "00-09-0C",vendor: "Mayekawa Mfg. Co. Ltd."},
  {max_prefix: "00-09-38",vendor: "Allot Communications"},
  {max_prefix: "00-08-C1",vendor: "Avistar Communications Corporation"},
  {max_prefix: "00-08-C6",vendor: "Philips Consumer Communications"},
  {max_prefix: "00-08-BF",vendor: "Aptus Elektronik AB"},
  {max_prefix: "00-08-B8",vendor: "E.F. Johnson"},
  {max_prefix: "00-08-BB",vendor: "NetExcell"},
  {max_prefix: "00-08-BE",vendor: "XENPAK MSA Group"},
  {max_prefix: "00-08-65",vendor: "JASCOM CO., LTD"},
  {max_prefix: "00-08-64",vendor: "Fasy S.p.A."},
  {max_prefix: "00-08-60",vendor: "LodgeNet Entertainment Corp."},
  {max_prefix: "00-08-97",vendor: "Quake Technologies"},
  {max_prefix: "00-08-90",vendor: "AVILINKS SA"},
  {max_prefix: "00-08-8D",vendor: "Sigma-Links Inc."},
  {max_prefix: "00-08-84",vendor: "Index Braille AB"},
  {max_prefix: "00-08-51",vendor: "Canadian Bank Note Company, Ltd."},
  {max_prefix: "00-08-59",vendor: "ShenZhen Unitone Electronics Co., Ltd."},
  {max_prefix: "00-08-4E",vendor: "DivergeNet, Inc."},
  {max_prefix: "00-08-25",vendor: "Acme Packet"},
  {max_prefix: "00-08-1F",vendor: "Pou Yuen Tech Corp. Ltd."},
  {max_prefix: "00-08-A8",vendor: "Systec Co., Ltd."},
  {max_prefix: "00-08-A4",vendor: "Cisco Systems, Inc"},
  {max_prefix: "08-00-6B",vendor: "ACCEL TECHNOLOGIES INC."},
  {max_prefix: "00-08-D7",vendor: "HOW CORPORATION"},
  {max_prefix: "00-07-94",vendor: "Simple Devices, Inc."},
  {max_prefix: "00-07-97",vendor: "Netpower Co., Ltd."},
  {max_prefix: "00-07-8C",vendor: "Elektronikspecialisten i Borlange AB"},
  {max_prefix: "00-07-C6",vendor: "VDS Vosskuhler GmbH"},
  {max_prefix: "00-07-CC",vendor: "Kaba Benzing GmbH"},
  {max_prefix: "00-07-C0",vendor: "NetZerver Inc."},
  {max_prefix: "00-08-07",vendor: "Access Devices Limited"},
  {max_prefix: "00-07-FC",vendor: "Adept Systems Inc."},
  {max_prefix: "00-07-E8",vendor: "EdgeWave"},
  {max_prefix: "00-07-A9",vendor: "Novasonics"},
  {max_prefix: "00-07-A1",vendor: "VIASYS Healthcare GmbH"},
  {max_prefix: "00-07-9E",vendor: "Ilinx Co., Ltd."},
  {max_prefix: "00-07-A0",vendor: "e-Watch Inc."},
  {max_prefix: "00-08-19",vendor: "Banksys"},
  {max_prefix: "00-08-1A",vendor: "Sanrad Intelligence Storage Communications (2000) Ltd."},
  {max_prefix: "00-08-10",vendor: "Key Technology, Inc."},
  {max_prefix: "00-07-BC",vendor: "Identix Inc."},
  {max_prefix: "00-07-EB",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-07-F5",vendor: "Bridgeco Co AG"},
  {max_prefix: "00-07-EC",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-07-B8",vendor: "Corvalent Corporation"},
  {max_prefix: "00-07-DC",vendor: "Atek Co, Ltd."},
  {max_prefix: "00-07-48",vendor: "The Imaging Source Europe"},
  {max_prefix: "00-07-46",vendor: "TURCK, Inc."},
  {max_prefix: "00-07-41",vendor: "Sierra Automated Systems"},
  {max_prefix: "00-07-45",vendor: "Radlan Computer Communications Ltd."},
  {max_prefix: "00-07-3E",vendor: "China Great-Wall Computer Shenzhen Co., Ltd."},
  {max_prefix: "00-07-1E",vendor: "Tri-M Engineering / Nupak Dev. Corp."},
  {max_prefix: "00-07-17",vendor: "Wieland Electric GmbH"},
  {max_prefix: "00-07-11",vendor: "Acterna"},
  {max_prefix: "00-07-0A",vendor: "Unicom Automation Co., Ltd."},
  {max_prefix: "00-07-02",vendor: "Varex Imaging"},
  {max_prefix: "00-07-05",vendor: "Endress & Hauser GmbH & Co"},
  {max_prefix: "00-06-FF",vendor: "Sheba Systems Co., Ltd."},
  {max_prefix: "00-07-2B",vendor: "Jung Myung Telecom Co., Ltd."},
  {max_prefix: "00-07-18",vendor: "iCanTek Co., Ltd."},
  {max_prefix: "00-07-16",vendor: "J & S Marine Ltd."},
  {max_prefix: "00-07-1A",vendor: "Finedigital Inc."},
  {max_prefix: "00-07-5C",vendor: "Eastman Kodak Company"},
  {max_prefix: "00-07-56",vendor: "Juyoung Telecom"},
  {max_prefix: "00-07-5A",vendor: "Air Products and Chemicals, Inc."},
  {max_prefix: "00-07-81",vendor: "Itron Inc."},
  {max_prefix: "00-07-88",vendor: "Clipcomm, Inc."},
  {max_prefix: "00-07-7B",vendor: "Millimetrix Broadband Networks"},
  {max_prefix: "00-07-69",vendor: "Italiana Macchi SpA"},
  {max_prefix: "00-07-6B",vendor: "Stralfors AB"},
  {max_prefix: "00-07-68",vendor: "Danfoss A/S"},
  {max_prefix: "00-07-5F",vendor: "VCS Video Communication Systems AG"},
  {max_prefix: "00-07-3B",vendor: "Tenovis GmbH & Co KG"},
  {max_prefix: "00-07-31",vendor: "Ophir-Spiricon LLC"},
  {max_prefix: "00-07-09",vendor: "Westerstrand Urfabrik AB"},
  {max_prefix: "00-06-D6",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-06-47",vendor: "Etrali S.A."},
  {max_prefix: "00-06-CB",vendor: "Jotron Electronics A/S"},
  {max_prefix: "00-06-CD",vendor: "Leaf Imaging Ltd."},
  {max_prefix: "00-06-C7",vendor: "RFNET Technologies Pte Ltd (S)"},
  {max_prefix: "00-06-74",vendor: "Spectrum Control, Inc."},
  {max_prefix: "00-06-63",vendor: "Human Technology Co., Ltd."},
  {max_prefix: "00-06-65",vendor: "Sunny Giken, Inc."},
  {max_prefix: "00-06-62",vendor: "MBM Technology Ltd."},
  {max_prefix: "00-06-8B",vendor: "AirRunner Technologies, Inc."},
  {max_prefix: "00-06-87",vendor: "Omnitron Systems Technology, Inc."},
  {max_prefix: "00-06-E4",vendor: "Citel Technologies Ltd."},
  {max_prefix: "00-06-D9",vendor: "IPM-Net S.p.A."},
  {max_prefix: "00-06-D3",vendor: "Alpha Telecom, Inc. U.S.A."},
  {max_prefix: "00-06-A4",vendor: "INNOWELL Corp."},
  {max_prefix: "00-06-94",vendor: "Mobillian Corporation"},
  {max_prefix: "00-06-95",vendor: "Ensure Technologies, Inc."},
  {max_prefix: "00-06-91",vendor: "PT Inovacao"},
  {max_prefix: "00-06-92",vendor: "Intruvert Networks, Inc."},
  {max_prefix: "00-06-A1",vendor: "Celsian Technologies, Inc."},
  {max_prefix: "00-06-AB",vendor: "W-Link Systems, Inc."},
  {max_prefix: "00-06-AC",vendor: "Intersoft Co."},
  {max_prefix: "00-06-EB",vendor: "Global Data"},
  {max_prefix: "00-06-E3",vendor: "Quantitative Imaging Corporation"},
  {max_prefix: "00-06-B9",vendor: "A5TEK Corp."},
  {max_prefix: "00-05-C0",vendor: "Digital Network Alacarte Co., Ltd."},
  {max_prefix: "00-05-B8",vendor: "Electronic Design Associates, Inc."},
  {max_prefix: "00-05-BA",vendor: "Area Netwoeks, Inc."},
  {max_prefix: "00-05-BF",vendor: "JustEzy Technology, Inc."},
  {max_prefix: "00-05-AC",vendor: "Northern Digital, Inc."},
  {max_prefix: "00-05-D1",vendor: "Metavector Technologies"},
  {max_prefix: "00-05-D5",vendor: "Speedcom Wireless"},
  {max_prefix: "00-05-D2",vendor: "DAP Technologies"},
  {max_prefix: "00-05-C5",vendor: "Flaga HF"},
  {max_prefix: "00-05-CA",vendor: "Hitron Technology, Inc."},
  {max_prefix: "00-06-69",vendor: "Datasound Laboratories Ltd"},
  {max_prefix: "00-06-6E",vendor: "Delta Electronics, Inc."},
  {max_prefix: "00-06-5A",vendor: "Strix Systems"},
  {max_prefix: "00-06-52",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-06-56",vendor: "Tactel AB"},
  {max_prefix: "00-06-41",vendor: "ITCN"},
  {max_prefix: "00-06-48",vendor: "Seedsware, Inc."},
  {max_prefix: "00-06-4C",vendor: "Invicta Networks, Inc."},
  {max_prefix: "00-06-38",vendor: "Sungjin C&C Co., Ltd."},
  {max_prefix: "00-06-0F",vendor: "Narad Networks Inc"},
  {max_prefix: "00-06-10",vendor: "Abeona Networks Inc"},
  {max_prefix: "00-06-11",vendor: "Zeus Wireless, Inc."},
  {max_prefix: "00-05-EC",vendor: "Mosaic Systems Inc."},
  {max_prefix: "00-06-1A",vendor: "Zetari Inc."},
  {max_prefix: "00-06-1F",vendor: "Vision Components GmbH"},
  {max_prefix: "00-06-1B",vendor: "Notebook Development Lab.  Lenovo Japan Ltd."},
  {max_prefix: "00-06-02",vendor: "Cirkitech Electronics Co."},
  {max_prefix: "00-05-FB",vendor: "ShareGate, Inc."},
  {max_prefix: "00-06-35",vendor: "PacketAir Networks, Inc."},
  {max_prefix: "00-05-F0",vendor: "SATEC"},
  {max_prefix: "00-05-FE",vendor: "Traficon N.V."},
  {max_prefix: "00-05-7D",vendor: "Sun Communications, Inc."},
  {max_prefix: "00-05-81",vendor: "Snell"},
  {max_prefix: "00-05-86",vendor: "Lucent Technologies"},
  {max_prefix: "00-05-7B",vendor: "Chung Nam Electronic Co., Ltd."},
  {max_prefix: "00-05-71",vendor: "Seiwa Electronics Co."},
  {max_prefix: "00-05-AD",vendor: "Topspin Communications, Inc."},
  {max_prefix: "00-05-B1",vendor: "ASB Technology BV"},
  {max_prefix: "00-05-99",vendor: "DRS Test and Energy Management or DRS-TEM"},
  {max_prefix: "00-05-9A",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-05-AB",vendor: "Cyber Fone, Inc."},
  {max_prefix: "00-05-92",vendor: "Pultek Corp."},
  {max_prefix: "00-05-8B",vendor: "IPmental, Inc."},
  {max_prefix: "00-05-49",vendor: "Salira Optical Network Systems"},
  {max_prefix: "00-05-4C",vendor: "RF Innovations Pty Ltd"},
  {max_prefix: "00-05-43",vendor: "IQ Wireless GmbH"},
  {max_prefix: "00-05-70",vendor: "Baydel Ltd."},
  {max_prefix: "00-05-77",vendor: "SM Information & Communication"},
  {max_prefix: "00-05-6E",vendor: "National Enhance Technology, Inc."},
  {max_prefix: "00-05-6D",vendor: "Pacific Corporation"},
  {max_prefix: "00-05-38",vendor: "Merilus, Inc."},
  {max_prefix: "00-05-30",vendor: "Andiamo Systems, Inc."},
  {max_prefix: "00-05-2B",vendor: "HORIBA, Ltd."},
  {max_prefix: "00-05-5C",vendor: "Kowa Company, Ltd."},
  {max_prefix: "00-04-E9",vendor: "Infiniswitch Corporation"},
  {max_prefix: "00-04-E8",vendor: "IER, Inc."},
  {max_prefix: "00-04-E0",vendor: "Procket Networks"},
  {max_prefix: "00-04-DB",vendor: "Tellus Group Corp."},
  {max_prefix: "00-04-DD",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-80-86",vendor: "COMPUTER GENERATION INC."},
  {max_prefix: "00-05-13",vendor: "VTLinx Multimedia Systems, Inc."},
  {max_prefix: "00-05-0E",vendor: "3ware, Inc."},
  {max_prefix: "00-05-10",vendor: "Infinite Shanghai Communication Terminals Ltd."},
  {max_prefix: "00-05-01",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-05-08",vendor: "Inetcam, Inc."},
  {max_prefix: "00-04-FF",vendor: "Acronet Co., Ltd."},
  {max_prefix: "00-05-04",vendor: "Naray Information & Communication Enterprise"},
  {max_prefix: "00-05-09",vendor: "AVOC Nishimura Ltd."},
  {max_prefix: "00-04-FB",vendor: "Commtech, Inc."},
  {max_prefix: "00-05-16",vendor: "SMART Modular Technologies"},
  {max_prefix: "00-05-15",vendor: "Nuark Co., Ltd."},
  {max_prefix: "00-05-1B",vendor: "Magic Control Technology Corporation"},
  {max_prefix: "00-04-D4",vendor: "Proview Electronics Co., Ltd."},
  {max_prefix: "00-04-CD",vendor: "Extenway Solutions Inc"},
  {max_prefix: "00-04-C0",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-04-BA",vendor: "KDD Media Will Corporation"},
  {max_prefix: "00-04-B6",vendor: "Stratex Networks, Inc."},
  {max_prefix: "00-04-D7",vendor: "Omitec Instrumentation Ltd."},
  {max_prefix: "00-05-1D",vendor: "Airocon, Inc."},
  {max_prefix: "00-04-B3",vendor: "Videotek, Inc."},
  {max_prefix: "00-04-A4",vendor: "NetEnabled, Inc."},
  {max_prefix: "00-04-44",vendor: "Western Multiplex Corporation"},
  {max_prefix: "00-04-39",vendor: "Rosco Entertainment Technology, Inc."},
  {max_prefix: "00-04-3A",vendor: "Intelligent Telecommunications, Inc."},
  {max_prefix: "00-04-3F",vendor: "ESTeem Wireless Modems, Inc"},
  {max_prefix: "00-04-33",vendor: "Cyberboard A/S"},
  {max_prefix: "00-04-73",vendor: "Photonex Corporation"},
  {max_prefix: "00-04-70",vendor: "ipUnplugged AB"},
  {max_prefix: "00-04-6C",vendor: "Cyber Technology Co., Ltd."},
  {max_prefix: "00-04-71",vendor: "IPrad"},
  {max_prefix: "00-04-6E",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-04-74",vendor: "LEGRAND"},
  {max_prefix: "00-04-34",vendor: "Accelent Systems, Inc."},
  {max_prefix: "00-04-2D",vendor: "Sarian Systems, Ltd."},
  {max_prefix: "00-04-2E",vendor: "Netous Technologies, Ltd."},
  {max_prefix: "00-04-25",vendor: "Atmel Corporation"},
  {max_prefix: "00-04-1B",vendor: "Bridgeworks Ltd."},
  {max_prefix: "00-04-92",vendor: "Hive Internet, Ltd."},
  {max_prefix: "00-04-8C",vendor: "Nayna Networks, Inc."},
  {max_prefix: "00-04-91",vendor: "Technovision, Inc."},
  {max_prefix: "00-04-93",vendor: "Tsinghua Unisplendour Co., Ltd."},
  {max_prefix: "00-04-94",vendor: "Breezecom, Ltd."},
  {max_prefix: "00-04-89",vendor: "YAFO Networks, Inc."},
  {max_prefix: "00-04-8A",vendor: "Temia Vertriebs GmbH"},
  {max_prefix: "00-04-81",vendor: "Econolite Control Products, Inc."},
  {max_prefix: "00-04-77",vendor: "Scalant Systems, Inc."},
  {max_prefix: "00-04-16",vendor: "Parks S/A Comunicacoes Digitais"},
  {max_prefix: "00-04-0F",vendor: "Asus Network Technologies, Inc."},
  {max_prefix: "00-04-0A",vendor: "Sage Systems"},
  {max_prefix: "00-04-04",vendor: "Makino Milling Machine Co., Ltd."},
  {max_prefix: "00-04-05",vendor: "ACN Technologies"},
  {max_prefix: "00-04-01",vendor: "Osaki Electric Co., Ltd."},
  {max_prefix: "00-04-5D",vendor: "BEKA Elektronik"},
  {max_prefix: "00-04-59",vendor: "Veristar Corporation"},
  {max_prefix: "00-04-4C",vendor: "JENOPTIK"},
  {max_prefix: "00-03-A7",vendor: "Unixtar Technology, Inc."},
  {max_prefix: "00-03-AE",vendor: "Allied Advanced Manufacturing Pte, Ltd."},
  {max_prefix: "00-03-A3",vendor: "MAVIX, Ltd."},
  {max_prefix: "00-03-A1",vendor: "HIPER Information & Communication, Inc."},
  {max_prefix: "00-03-96",vendor: "EZ Cast Co., Ltd."},
  {max_prefix: "00-03-9A",vendor: "SiConnect"},
  {max_prefix: "00-03-71",vendor: "Acomz Networks Corp."},
  {max_prefix: "00-03-63",vendor: "Miraesys Co., Ltd."},
  {max_prefix: "00-03-5F",vendor: "Pr\u00fcftechnik Condition Monitoring GmbH & Co. KG"},
  {max_prefix: "00-03-60",vendor: "PAC Interactive Technology, Inc."},
  {max_prefix: "00-03-61",vendor: "Widcomm, Inc."},
  {max_prefix: "00-03-59",vendor: "DigitalSis"},
  {max_prefix: "00-03-52",vendor: "Colubris Networks"},
  {max_prefix: "00-03-D2",vendor: "Crossbeam Systems, Inc."},
  {max_prefix: "00-03-D0",vendor: "KOANKEISO Co., Ltd."},
  {max_prefix: "00-03-CF",vendor: "Muxcom, Inc."},
  {max_prefix: "00-03-D1",vendor: "Takaya Corporation"},
  {max_prefix: "00-03-F4",vendor: "NetBurner"},
  {max_prefix: "00-03-F2",vendor: "Seneca Networks"},
  {max_prefix: "00-03-F0",vendor: "Redfern Broadband Networks"},
  {max_prefix: "00-03-EB",vendor: "Atrica"},
  {max_prefix: "00-03-E7",vendor: "Logostek Co. Ltd."},
  {max_prefix: "00-03-74",vendor: "Control Microsystems"},
  {max_prefix: "00-03-76",vendor: "Graphtec Technology, Inc."},
  {max_prefix: "00-03-78",vendor: "HUMAX Co., Ltd."},
  {max_prefix: "00-03-6D",vendor: "Runtop, Inc."},
  {max_prefix: "00-03-6E",vendor: "Nicon Systems (Pty) Limited"},
  {max_prefix: "00-03-94",vendor: "Connect One"},
  {max_prefix: "00-03-8A",vendor: "America Online, Inc."},
  {max_prefix: "00-03-8D",vendor: "PCS Revenue Control Systems, Inc."},
  {max_prefix: "00-03-88",vendor: "Fastfame Technology Co., Ltd."},
  {max_prefix: "00-03-B6",vendor: "QSI Corporation"},
  {max_prefix: "00-03-B1",vendor: "ICU Medical, Inc."},
  {max_prefix: "00-03-B3",vendor: "IA Link Systems Co., Ltd."},
  {max_prefix: "00-03-AD",vendor: "Emerson Energy Systems AB"},
  {max_prefix: "00-03-7E",vendor: "PORTech Communications, Inc."},
  {max_prefix: "00-03-E3",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-03-DB",vendor: "Apogee Electronics Corp."},
  {max_prefix: "00-03-1F",vendor: "Condev Ltd."},
  {max_prefix: "00-02-9F",vendor: "L-3 Communication Aviation Recorders"},
  {max_prefix: "00-03-1B",vendor: "Cellvision Systems, Inc."},
  {max_prefix: "00-03-1C",vendor: "Svenska Hardvarufabriken AB"},
  {max_prefix: "00-01-A8",vendor: "Welltech Computer Co., Ltd."},
  {max_prefix: "00-03-17",vendor: "Merlin Systems, Inc."},
  {max_prefix: "00-03-18",vendor: "Cyras Systems, Inc."},
  {max_prefix: "00-03-0C",vendor: "Telesoft Technologies Ltd."},
  {max_prefix: "00-03-08",vendor: "AM Communications, Inc."},
  {max_prefix: "00-03-07",vendor: "Secure Works, Inc."},
  {max_prefix: "00-03-06",vendor: "Fusion In Tech Co., Ltd."},
  {max_prefix: "00-02-FC",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-03-2A",vendor: "UniData Communication Systems, Inc."},
  {max_prefix: "00-03-25",vendor: "Arima Computer Corp."},
  {max_prefix: "00-02-FA",vendor: "DX Antenna Co., Ltd."},
  {max_prefix: "00-02-FB",vendor: "Baumuller Aulugen-Systemtechnik GmbH"},
  {max_prefix: "00-02-F6",vendor: "Equipe Communications"},
  {max_prefix: "00-02-23",vendor: "ClickTV"},
  {max_prefix: "00-02-CB",vendor: "TriState Ltd."},
  {max_prefix: "00-02-CA",vendor: "EndPoints, Inc."},
  {max_prefix: "00-02-C4",vendor: "OPT Machine Vision Tech Co., Ltd"},
  {max_prefix: "00-02-BF",vendor: "dotRocket, Inc."},
  {max_prefix: "00-02-E3",vendor: "LITE-ON Communications, Inc."},
  {max_prefix: "00-02-DD",vendor: "Bromax Communications, Ltd."},
  {max_prefix: "00-03-4C",vendor: "Shanghai DigiVision Technology Co., Ltd."},
  {max_prefix: "00-03-49",vendor: "Vidicode Datacommunicatie B.V."},
  {max_prefix: "00-03-3E",vendor: "Tateyama System Laboratory Co., Ltd."},
  {max_prefix: "00-03-3C",vendor: "Daiden Co., Ltd."},
  {max_prefix: "00-02-72",vendor: "CC&C Technologies, Inc."},
  {max_prefix: "00-02-6D",vendor: "Adept Telecom"},
  {max_prefix: "00-02-6B",vendor: "BCM Computers Co., Ltd."},
  {max_prefix: "00-02-66",vendor: "Thermalogic Corporation"},
  {max_prefix: "00-02-68",vendor: "Harris Government Communications"},
  {max_prefix: "00-02-5E",vendor: "High Technology Ltd"},
  {max_prefix: "00-02-60",vendor: "Accordion Networks, Inc."},
  {max_prefix: "00-01-2F",vendor: "Twinhead International Corp"},
  {max_prefix: "00-02-47",vendor: "Great Dragon Information Technology (Group) Co., Ltd."},
  {max_prefix: "00-02-43",vendor: "Raysis Co., Ltd."},
  {max_prefix: "00-02-89",vendor: "DNE Technologies"},
  {max_prefix: "00-02-83",vendor: "Spectrum Controls, Inc."},
  {max_prefix: "00-02-84",vendor: "UK Grid Solutions Limited"},
  {max_prefix: "00-02-80",vendor: "Mu Net, Inc."},
  {max_prefix: "00-90-64",vendor: "Thomson Inc."},
  {max_prefix: "00-02-9E",vendor: "Information Equipment Co., Ltd."},
  {max_prefix: "00-02-9B",vendor: "Kreatel Communications AB"},
  {max_prefix: "00-02-93",vendor: "Solid Data Systems"},
  {max_prefix: "00-02-31",vendor: "Ingersoll-Rand"},
  {max_prefix: "00-02-34",vendor: "Imperial Technology, Inc."},
  {max_prefix: "00-02-36",vendor: "INIT GmbH"},
  {max_prefix: "00-02-2B",vendor: "SAXA, Inc."},
  {max_prefix: "00-02-7A",vendor: "IOI Technology Corporation"},
  {max_prefix: "00-02-74",vendor: "Tommy Technologies Corp."},
  {max_prefix: "00-02-24",vendor: "C-COR"},
  {max_prefix: "00-02-1F",vendor: "Aculab PLC"},
  {max_prefix: "00-02-1A",vendor: "Zuma Networks"},
  {max_prefix: "00-02-22",vendor: "Chromisys, Inc."},
  {max_prefix: "00-02-A7",vendor: "Vivace Networks"},
  {max_prefix: "00-02-13",vendor: "S.D.E.L."},
  {max_prefix: "00-02-0A",vendor: "Gefran Spa"},
  {max_prefix: "00-01-D1",vendor: "CoNet Communications, Inc."},
  {max_prefix: "00-01-B2",vendor: "Digital Processing Systems, Inc."},
  {max_prefix: "00-01-B8",vendor: "Netsensity, Inc."},
  {max_prefix: "00-01-B9",vendor: "SKF (U.K.) Limited"},
  {max_prefix: "00-01-BD",vendor: "Peterson Electro-Musical Products, Inc."},
  {max_prefix: "00-01-B3",vendor: "Precision Electronic Manufacturing"},
  {max_prefix: "00-01-B7",vendor: "Centos, Inc."},
  {max_prefix: "00-02-09",vendor: "Shenzhen SED Information Technology Co., Ltd."},
  {max_prefix: "00-02-06",vendor: "Telital R&D Denmark A/S"},
  {max_prefix: "00-02-02",vendor: "Amino Communications, Ltd."},
  {max_prefix: "00-02-01",vendor: "IFM Electronic gmbh"},
  {max_prefix: "00-01-84",vendor: "SIEB & MEYER AG"},
  {max_prefix: "00-01-8E",vendor: "Logitec Corporation"},
  {max_prefix: "00-01-79",vendor: "WIRELESS TECHNOLOGY, INC."},
  {max_prefix: "00-01-60",vendor: "ELMEX Co., LTD."},
  {max_prefix: "00-01-4E",vendor: "WIN Enterprises, Inc."},
  {max_prefix: "00-01-A4",vendor: "Microlink Corporation"},
  {max_prefix: "00-01-95",vendor: "Sena Technologies, Inc."},
  {max_prefix: "00-01-7E",vendor: "ADTEK System Science Co., Ltd."},
  {max_prefix: "00-01-83",vendor: "ANITE TELECOMS"},
  {max_prefix: "00-01-DB",vendor: "Freecom Technologies GmbH"},
  {max_prefix: "00-01-DF",vendor: "ISDN Communications, Ltd."},
  {max_prefix: "00-01-CA",vendor: "Geocast Network Systems, Inc."},
  {max_prefix: "00-01-EF",vendor: "Camtel Technology Corp."},
  {max_prefix: "00-01-B5",vendor: "Turin Networks, Inc."},
  {max_prefix: "00-B0-F5",vendor: "NetWorth Technologies, Inc."},
  {max_prefix: "00-B0-DB",vendor: "Nextcell, Inc."},
  {max_prefix: "00-B0-AE",vendor: "Symmetricom"},
  {max_prefix: "00-B0-E7",vendor: "British Federal Ltd."},
  {max_prefix: "00-B0-8E",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-01-18",vendor: "EZ Digital Co., Ltd."},
  {max_prefix: "00-01-28",vendor: "EnjoyWeb, Inc."},
  {max_prefix: "00-01-1C",vendor: "Universal Talkware Corporation"},
  {max_prefix: "00-01-0C",vendor: "System Talks Inc."},
  {max_prefix: "00-01-11",vendor: "iDigm Inc."},
  {max_prefix: "00-30-73",vendor: "International Microsystems, In"},
  {max_prefix: "00-30-3F",vendor: "TurboComm Tech Inc."},
  {max_prefix: "00-01-6D",vendor: "CarrierComm Inc."},
  {max_prefix: "00-01-6A",vendor: "ALITEC"},
  {max_prefix: "00-01-6F",vendor: "Inkel Corp."},
  {max_prefix: "00-01-70",vendor: "ESE Embedded System Engineer'g"},
  {max_prefix: "00-01-35",vendor: "KDC Corp."},
  {max_prefix: "00-01-41",vendor: "CABLE PRINT"},
  {max_prefix: "00-01-46",vendor: "Tesco Controls, Inc."},
  {max_prefix: "00-01-49",vendor: "TDT AG"},
  {max_prefix: "00-01-65",vendor: "AirSwitch Corporation"},
  {max_prefix: "00-01-56",vendor: "FIREWIREDIRECT.COM, INC."},
  {max_prefix: "00-01-53",vendor: "ARCHTEK TELECOM CORPORATION"},
  {max_prefix: "00-01-14",vendor: "KANDA TSUSHIN KOGYO CO., LTD."},
  {max_prefix: "00-01-07",vendor: "Leiser GmbH"},
  {max_prefix: "00-01-0B",vendor: "Space CyberLink, Inc."},
  {max_prefix: "00-B0-DF",vendor: "Starboard Storage Systems"},
  {max_prefix: "00-01-31",vendor: "Bosch Security Systems, Inc."},
  {max_prefix: "00-30-4F",vendor: "PLANET Technology Corporation"},
  {max_prefix: "00-30-22",vendor: "Fong Kai Industrial Co., Ltd."},
  {max_prefix: "00-30-70",vendor: "1Net Corporation"},
  {max_prefix: "00-30-F8",vendor: "Dynapro Systems, Inc."},
  {max_prefix: "00-30-B3",vendor: "San Valley Systems, Inc."},
  {max_prefix: "00-30-09",vendor: "Tachion Networks, Inc."},
  {max_prefix: "00-30-7A",vendor: "Advanced Technology & Systems"},
  {max_prefix: "00-30-61",vendor: "MobyTEL"},
  {max_prefix: "00-30-56",vendor: "HMS Industrial Networks"},
  {max_prefix: "00-30-E7",vendor: "CNF MOBILE SOLUTIONS, INC."},
  {max_prefix: "00-30-43",vendor: "IDREAM TECHNOLOGIES, PTE. LTD."},
  {max_prefix: "00-30-B4",vendor: "INTERSIL CORP."},
  {max_prefix: "00-30-00",vendor: "ALLWELL TECHNOLOGY CORP."},
  {max_prefix: "00-30-11",vendor: "HMS Industrial Networks"},
  {max_prefix: "00-30-8F",vendor: "MICRILOR, Inc."},
  {max_prefix: "00-30-9C",vendor: "Timing Applications, Inc."},
  {max_prefix: "00-30-7E",vendor: "Redflex Communication Systems"},
  {max_prefix: "00-30-04",vendor: "LEADTEK RESEARCH INC."},
  {max_prefix: "00-30-F9",vendor: "Sollae Systems Co., Ltd."},
  {max_prefix: "00-30-02",vendor: "Expand Networks"},
  {max_prefix: "00-30-78",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-30-BE",vendor: "City-Net Technology, Inc."},
  {max_prefix: "00-30-92",vendor: "Kontron Electronics AG"},
  {max_prefix: "00-30-5C",vendor: "SMAR Laboratories Corp."},
  {max_prefix: "00-30-B1",vendor: "TrunkNet"},
  {max_prefix: "00-30-E0",vendor: "OXFORD SEMICONDUCTOR LTD."},
  {max_prefix: "00-30-64",vendor: "ADLINK TECHNOLOGY, INC."},
  {max_prefix: "00-30-50",vendor: "Versa Technology"},
  {max_prefix: "00-30-C0",vendor: "Lara Technology, Inc."},
  {max_prefix: "00-30-05",vendor: "Fujitsu Siemens Computers"},
  {max_prefix: "00-30-DB",vendor: "Mindready Solutions, Inc."},
  {max_prefix: "00-30-C7",vendor: "Macromate Corp."},
  {max_prefix: "00-30-E4",vendor: "CHIYODA SYSTEM RIKEN"},
  {max_prefix: "00-30-66",vendor: "RFM"},
  {max_prefix: "00-30-31",vendor: "LIGHTWAVE COMMUNICATIONS, INC."},
  {max_prefix: "00-30-60",vendor: "Powerfile, Inc."},
  {max_prefix: "00-30-A0",vendor: "TYCO SUBMARINE SYSTEMS, LTD."},
  {max_prefix: "00-30-15",vendor: "CP CLARE CORP."},
  {max_prefix: "00-30-4B",vendor: "ORBACOM SYSTEMS, INC."},
  {max_prefix: "00-30-FA",vendor: "TELICA, INC."},
  {max_prefix: "00-30-A5",vendor: "ACTIVE POWER"},
  {max_prefix: "00-30-84",vendor: "ALLIED TELESYN INTERNAIONAL"},
  {max_prefix: "00-30-E9",vendor: "GMA COMMUNICATION MANUFACT'G"},
  {max_prefix: "00-D0-3E",vendor: "ROCKETCHIPS, INC."},
  {max_prefix: "00-D0-93",vendor: "TQ - COMPONENTS GMBH"},
  {max_prefix: "00-D0-3F",vendor: "AMERICAN COMMUNICATION"},
  {max_prefix: "00-D0-F9",vendor: "ACUTE COMMUNICATIONS CORP."},
  {max_prefix: "00-D0-F7",vendor: "NEXT NETS CORPORATION"},
  {max_prefix: "00-D0-03",vendor: "COMDA ENTERPRISES CORP."},
  {max_prefix: "00-D0-D2",vendor: "EPILOG CORPORATION"},
  {max_prefix: "00-30-68",vendor: "CYBERNETICS TECH. CO., LTD."},
  {max_prefix: "00-30-91",vendor: "TAIWAN FIRST LINE ELEC. CORP."},
  {max_prefix: "00-30-CD",vendor: "CONEXANT SYSTEMS, INC."},
  {max_prefix: "00-30-5B",vendor: "Toko Inc."},
  {max_prefix: "00-30-29",vendor: "OPICOM"},
  {max_prefix: "00-30-83",vendor: "Ivron Systems"},
  {max_prefix: "00-30-B6",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-D0-28",vendor: "Harmonic, Inc"},
  {max_prefix: "00-D0-25",vendor: "XROSSTECH, INC."},
  {max_prefix: "00-D0-44",vendor: "ALIDIAN NETWORKS, INC."},
  {max_prefix: "00-D0-18",vendor: "QWES. COM, INC."},
  {max_prefix: "00-D0-07",vendor: "MIC ASSOCIATES, INC."},
  {max_prefix: "00-D0-FF",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-D0-77",vendor: "LUCENT TECHNOLOGIES"},
  {max_prefix: "00-D0-8C",vendor: "GENOA TECHNOLOGY, INC."},
  {max_prefix: "00-D0-59",vendor: "AMBIT MICROSYSTEMS CORP."},
  {max_prefix: "00-D0-FD",vendor: "OPTIMA TELE.COM, INC."},
  {max_prefix: "00-D0-E6",vendor: "IBOND INC."},
  {max_prefix: "00-D0-D1",vendor: "Sycamore Networks"},
  {max_prefix: "00-D0-87",vendor: "MICROFIRST INC."},
  {max_prefix: "00-D0-91",vendor: "SMARTSAN SYSTEMS, INC."},
  {max_prefix: "00-D0-80",vendor: "EXABYTE CORPORATION"},
  {max_prefix: "00-D0-4E",vendor: "LOGIBAG"},
  {max_prefix: "00-D0-27",vendor: "APPLIED AUTOMATION, INC."},
  {max_prefix: "00-D0-A9",vendor: "SHINANO KENSHI CO., LTD."},
  {max_prefix: "00-D0-DD",vendor: "SUNRISE TELECOM, INC."},
  {max_prefix: "00-D0-31",vendor: "INDUSTRIAL LOGIC CORPORATION"},
  {max_prefix: "00-D0-38",vendor: "FIVEMERE, LTD."},
  {max_prefix: "00-D0-C6",vendor: "THOMAS & BETTS CORP."},
  {max_prefix: "00-01-A7",vendor: "UNEX TECHNOLOGY CORPORATION"},
  {max_prefix: "00-D0-89",vendor: "DYNACOLOR, INC."},
  {max_prefix: "00-D0-72",vendor: "BROADLOGIC"},
  {max_prefix: "00-D0-E2",vendor: "MRT MICRO, INC."},
  {max_prefix: "00-D0-A6",vendor: "LANBIRD TECHNOLOGY CO., LTD."},
  {max_prefix: "00-D0-49",vendor: "IMPRESSTEK CO., LTD."},
  {max_prefix: "00-D0-5B",vendor: "ACROLOOP MOTION CONTROL"},
  {max_prefix: "00-D0-42",vendor: "MAHLO GMBH & CO. UG"},
  {max_prefix: "00-D0-2C",vendor: "CAMPBELL SCIENTIFIC, INC."},
  {max_prefix: "00-D0-CD",vendor: "ATAN TECHNOLOGY INC."},
  {max_prefix: "00-D0-40",vendor: "SYSMATE CO., LTD."},
  {max_prefix: "00-D0-6F",vendor: "KMC CONTROLS"},
  {max_prefix: "00-D0-1A",vendor: "URMET  TLC S.P.A."},
  {max_prefix: "00-D0-6A",vendor: "LINKUP SYSTEMS CORPORATION"},
  {max_prefix: "00-50-4E",vendor: "SIERRA MONITOR CORP."},
  {max_prefix: "00-50-D7",vendor: "TELSTRAT"},
  {max_prefix: "00-50-F6",vendor: "PAN-INTERNATIONAL INDUSTRIAL CORP."},
  {max_prefix: "00-50-6C",vendor: "Beijer Electronics Products AB"},
  {max_prefix: "00-50-44",vendor: "ASACA CORPORATION"},
  {max_prefix: "00-50-0E",vendor: "CHROMATIS NETWORKS, INC."},
  {max_prefix: "00-50-60",vendor: "TANDBERG TELECOM AS"},
  {max_prefix: "00-50-EE",vendor: "TEK DIGITEL CORPORATION"},
  {max_prefix: "00-50-FF",vendor: "HAKKO ELECTRONICS CO., LTD."},
  {max_prefix: "00-50-D2",vendor: "CMC Electronics Inc"},
  {max_prefix: "00-50-F9",vendor: "Sensormatic Electronics LLC"},
  {max_prefix: "00-50-48",vendor: "INFOLIBRIA"},
  {max_prefix: "00-50-1C",vendor: "JATOM SYSTEMS, INC."},
  {max_prefix: "00-50-7A",vendor: "XPEED, INC."},
  {max_prefix: "00-50-4F",vendor: "OLENCOM ELECTRONICS"},
  {max_prefix: "00-50-6E",vendor: "CORDER ENGINEERING CORPORATION"},
  {max_prefix: "00-50-2C",vendor: "SOYO COMPUTER, INC."},
  {max_prefix: "00-50-E6",vendor: "HAKUSAN CORPORATION"},
  {max_prefix: "00-50-3C",vendor: "TSINGHUA NOVEL ELECTRONICS"},
  {max_prefix: "00-50-33",vendor: "MAYAN NETWORKS"},
  {max_prefix: "00-50-45",vendor: "RIOWORKS SOLUTIONS, INC."},
  {max_prefix: "00-50-2B",vendor: "GENRAD LTD."},
  {max_prefix: "00-50-2E",vendor: "CAMBEX CORPORATION"},
  {max_prefix: "00-50-07",vendor: "SIEMENS TELECOMMUNICATION SYSTEMS LIMITED"},
  {max_prefix: "00-50-22",vendor: "ZONET TECHNOLOGY, INC."},
  {max_prefix: "00-50-40",vendor: "Panasonic Electric Works Co., Ltd."},
  {max_prefix: "00-50-C9",vendor: "MASPRO DENKOH CORP."},
  {max_prefix: "00-50-69",vendor: "PixStream Incorporated"},
  {max_prefix: "00-50-68",vendor: "ELECTRONIC INDUSTRIES ASSOCIATION"},
  {max_prefix: "00-D0-CC",vendor: "TECHNOLOGIES LYRE INC."},
  {max_prefix: "00-50-77",vendor: "PROLIFIC TECHNOLOGY, INC."},
  {max_prefix: "00-50-EC",vendor: "OLICOM A/S"},
  {max_prefix: "00-50-F8",vendor: "ENTREGA TECHNOLOGIES, INC."},
  {max_prefix: "00-50-42",vendor: "SCI MANUFACTURING SINGAPORE PTE, LTD."},
  {max_prefix: "00-50-C0",vendor: "GATAN, INC."},
  {max_prefix: "00-50-51",vendor: "IWATSU ELECTRIC CO., LTD."},
  {max_prefix: "00-50-BB",vendor: "CMS TECHNOLOGIES"},
  {max_prefix: "00-50-62",vendor: "KOUWELL ELECTRONICS CORP.  **"},
  {max_prefix: "00-50-97",vendor: "MMC-EMBEDDED COMPUTERTECHNIK GmbH"},
  {max_prefix: "00-50-10",vendor: "NovaNET Learning, Inc."},
  {max_prefix: "00-50-9A",vendor: "TAG ELECTRONIC SYSTEMS"},
  {max_prefix: "00-50-EB",vendor: "ALPHA-TOP CORPORATION"},
  {max_prefix: "00-50-EF",vendor: "SPE Systemhaus GmbH"},
  {max_prefix: "00-50-98",vendor: "GLOBALOOP, LTD."},
  {max_prefix: "00-50-BC",vendor: "HAMMER STORAGE SOLUTIONS"},
  {max_prefix: "00-90-22",vendor: "IVEX"},
  {max_prefix: "00-90-16",vendor: "ZAC"},
  {max_prefix: "00-90-FF",vendor: "TELLUS TECHNOLOGY INC."},
  {max_prefix: "00-90-3E",vendor: "N.V. PHILIPS INDUSTRIAL ACTIVITIES"},
  {max_prefix: "00-90-BA",vendor: "VALID NETWORKS, INC."},
  {max_prefix: "00-90-18",vendor: "ITO ELECTRIC INDUSTRY CO, LTD."},
  {max_prefix: "00-50-D5",vendor: "AD SYSTEMS CORP."},
  {max_prefix: "00-50-F3",vendor: "GLOBAL NET INFORMATION CO., Ltd."},
  {max_prefix: "00-50-BE",vendor: "FAST MULTIMEDIA AG"},
  {max_prefix: "00-50-6F",vendor: "G-CONNECT"},
  {max_prefix: "00-50-7D",vendor: "IFP"},
  {max_prefix: "00-90-B3",vendor: "AGRANAT SYSTEMS"},
  {max_prefix: "00-90-5D",vendor: "NETCOM SICHERHEITSTECHNIK GMBH"},
  {max_prefix: "00-90-D1",vendor: "LEICHU ENTERPRISE CO., LTD."},
  {max_prefix: "00-90-46",vendor: "DEXDYNE, LTD."},
  {max_prefix: "00-90-CD",vendor: "ENT-EMPRESA NACIONAL DE TELECOMMUNICACOES, S.A."},
  {max_prefix: "00-90-D0",vendor: "Thomson Telecom Belgium"},
  {max_prefix: "00-90-9B",vendor: "MARKEM-IMAJE"},
  {max_prefix: "00-90-FB",vendor: "PORTWELL, INC."},
  {max_prefix: "00-90-94",vendor: "OSPREY TECHNOLOGIES, INC."},
  {max_prefix: "00-90-DA",vendor: "DYNARC, INC."},
  {max_prefix: "00-90-E0",vendor: "SYSTRAN CORP."},
  {max_prefix: "00-90-71",vendor: "Applied Innovation Inc."},
  {max_prefix: "00-90-DC",vendor: "TECO INFORMATION SYSTEMS"},
  {max_prefix: "00-90-E2",vendor: "DISTRIBUTED PROCESSING TECHNOLOGY"},
  {max_prefix: "00-90-C7",vendor: "ICOM INC."},
  {max_prefix: "00-90-35",vendor: "ALPHA TELECOM, INC."},
  {max_prefix: "00-90-F0",vendor: "Harmonic Video Systems Ltd."},
  {max_prefix: "00-90-7A",vendor: "Spectralink, Inc"},
  {max_prefix: "00-90-20",vendor: "PHILIPS ANALYTICAL X-RAY B.V."},
  {max_prefix: "00-10-A3",vendor: "OMNITRONIX, INC."},
  {max_prefix: "00-10-AD",vendor: "SOFTRONICS USB, INC."},
  {max_prefix: "00-10-A7",vendor: "UNEX TECHNOLOGY CORPORATION"},
  {max_prefix: "00-10-D5",vendor: "IMASDE CANARIAS, S.A."},
  {max_prefix: "00-10-55",vendor: "FUJITSU MICROELECTRONICS, INC."},
  {max_prefix: "00-90-4F",vendor: "ABB POWER T&D COMPANY, INC."},
  {max_prefix: "00-90-60",vendor: "SYSTEM CREATE CORP."},
  {max_prefix: "00-90-13",vendor: "SAMSAN CORP."},
  {max_prefix: "00-90-85",vendor: "GOLDEN ENTERPRISES, INC."},
  {max_prefix: "00-90-53",vendor: "DAEWOO ELECTRONICS CO., LTD."},
  {max_prefix: "00-90-3C",vendor: "ATLANTIC NETWORK SYSTEMS"},
  {max_prefix: "00-90-77",vendor: "ADVANCED FIBRE COMMUNICATIONS"},
  {max_prefix: "00-90-99",vendor: "ALLIED TELESIS, K.K."},
  {max_prefix: "00-90-55",vendor: "PARKER HANNIFIN CORPORATION COMPUMOTOR DIVISION"},
  {max_prefix: "00-90-5C",vendor: "EDMI"},
  {max_prefix: "00-90-E3",vendor: "AVEX ELECTRONICS INC."},
  {max_prefix: "00-90-A9",vendor: "WESTERN DIGITAL"},
  {max_prefix: "00-90-F3",vendor: "ASPECT COMMUNICATIONS"},
  {max_prefix: "00-10-52",vendor: "METTLER-TOLEDO (ALBSTADT) GMBH"},
  {max_prefix: "00-10-6B",vendor: "SONUS NETWORKS, INC."},
  {max_prefix: "00-10-C3",vendor: "CSI-CONTROL SYSTEMS"},
  {max_prefix: "00-90-0F",vendor: "KAWASAKI HEAVY INDUSTRIES, LTD"},
  {max_prefix: "00-90-EA",vendor: "ALPHA TECHNOLOGIES, INC."},
  {max_prefix: "00-90-A7",vendor: "CLIENTEC CORPORATION"},
  {max_prefix: "00-10-51",vendor: "CMICRO CORPORATION"},
  {max_prefix: "00-10-37",vendor: "CYQ've Technology Co., Ltd."},
  {max_prefix: "00-10-1B",vendor: "CORNET TECHNOLOGY, INC."},
  {max_prefix: "00-10-DC",vendor: "MICRO-STAR INTERNATIONAL CO., LTD."},
  {max_prefix: "00-10-0A",vendor: "WILLIAMS COMMUNICATIONS GROUP"},
  {max_prefix: "00-10-32",vendor: "ALTA TECHNOLOGY"},
  {max_prefix: "00-10-F4",vendor: "Vertical Communications"},
  {max_prefix: "00-10-77",vendor: "SAF DRIVE SYSTEMS, LTD."},
  {max_prefix: "00-10-B3",vendor: "NOKIA MULTIMEDIA TERMINALS"},
  {max_prefix: "00-10-7A",vendor: "AmbiCom, Inc."},
  {max_prefix: "00-10-2D",vendor: "HITACHI SOFTWARE ENGINEERING"},
  {max_prefix: "00-10-33",vendor: "ACCESSLAN COMMUNICATIONS, INC."},
  {max_prefix: "00-10-DD",vendor: "ENABLE SEMICONDUCTOR, INC."},
  {max_prefix: "00-10-78",vendor: "NUERA COMMUNICATIONS, INC."},
  {max_prefix: "00-10-15",vendor: "OOmon Inc."},
  {max_prefix: "00-10-B9",vendor: "MAXTOR CORP."},
  {max_prefix: "00-10-5D",vendor: "Draeger Medical"},
  {max_prefix: "00-10-12",vendor: "PROCESSOR SYSTEMS (I) PVT LTD"},
  {max_prefix: "00-10-91",vendor: "NO WIRES NEEDED BV"},
  {max_prefix: "00-10-80",vendor: "METAWAVE COMMUNICATIONS"},
  {max_prefix: "00-10-1E",vendor: "MATSUSHITA ELECTRONIC INSTRUMENTS CORP."},
  {max_prefix: "00-10-4D",vendor: "SURTEC INDUSTRIES, INC."},
  {max_prefix: "00-E0-BB",vendor: "NBX CORPORATION"},
  {max_prefix: "00-E0-8A",vendor: "GEC AVERY, LTD."},
  {max_prefix: "00-E0-86",vendor: "Emerson Network Power, Avocent Division"},
  {max_prefix: "00-E0-1B",vendor: "SPHERE COMMUNICATIONS, INC."},
  {max_prefix: "00-E0-7F",vendor: "LOGISTISTEM s.r.l."},
  {max_prefix: "00-E0-13",vendor: "EASTERN ELECTRONIC CO., LTD."},
  {max_prefix: "00-E0-FD",vendor: "A-TREND TECHNOLOGY CO., LTD."},
  {max_prefix: "00-E0-BD",vendor: "INTERFACE SYSTEMS, INC."},
  {max_prefix: "00-E0-C5",vendor: "BCOM ELECTRONICS INC."},
  {max_prefix: "00-E0-EE",vendor: "MAREL HF"},
  {max_prefix: "00-E0-8E",vendor: "UTSTARCOM"},
  {max_prefix: "00-E0-3F",vendor: "JATON CORPORATION"},
  {max_prefix: "00-E0-D4",vendor: "EXCELLENT COMPUTER"},
  {max_prefix: "00-E0-05",vendor: "TECHNICAL CORP."},
  {max_prefix: "00-E0-C1",vendor: "MEMOREX TELEX JAPAN, LTD."},
  {max_prefix: "00-E0-84",vendor: "COMPULITE R&D"},
  {max_prefix: "00-E0-6E",vendor: "FAR SYSTEMS S.p.A."},
  {max_prefix: "00-E0-6D",vendor: "COMPUWARE CORPORATION"},
  {max_prefix: "00-60-7F",vendor: "AURORA TECHNOLOGIES, INC."},
  {max_prefix: "00-E0-29",vendor: "STANDARD MICROSYSTEMS CORP."},
  {max_prefix: "00-60-A5",vendor: "PERFORMANCE TELECOM CORP."},
  {max_prefix: "00-E0-38",vendor: "PROXIMA CORPORATION"},
  {max_prefix: "00-E0-9C",vendor: "MII"},
  {max_prefix: "00-E0-E9",vendor: "DATA LABS, INC."},
  {max_prefix: "00-E0-0C",vendor: "MOTOROLA"},
  {max_prefix: "00-E0-0A",vendor: "DIBA, INC."},
  {max_prefix: "00-E0-C4",vendor: "HORNER ELECTRIC, INC."},
  {max_prefix: "00-E0-96",vendor: "SHIMADZU CORPORATION"},
  {max_prefix: "00-E0-17",vendor: "EXXACT GmbH"},
  {max_prefix: "00-60-A1",vendor: "VPNet, Inc."},
  {max_prefix: "00-60-27",vendor: "Superior Modular Products"},
  {max_prefix: "00-60-BC",vendor: "KeunYoung Electronics & Communication Co., Ltd."},
  {max_prefix: "00-E0-15",vendor: "HEIWA CORPORATION"},
  {max_prefix: "00-E0-69",vendor: "JAYCOR"},
  {max_prefix: "00-E0-A4",vendor: "ESAOTE S.p.A."},
  {max_prefix: "00-E0-DE",vendor: "DATAX NV"},
  {max_prefix: "00-E0-EA",vendor: "INNOVAT COMMUNICATIONS, INC."},
  {max_prefix: "00-E0-64",vendor: "SAMSUNG ELECTRONICS"},
  {max_prefix: "00-E0-C9",vendor: "AutomatedLogic Corporation"},
  {max_prefix: "00-E0-E8",vendor: "GRETACODER Data Systems AG"},
  {max_prefix: "00-E0-16",vendor: "RAPID CITY COMMUNICATIONS"},
  {max_prefix: "00-E0-A5",vendor: "ComCore Semiconductor, Inc."},
  {max_prefix: "00-E0-A9",vendor: "FUNAI ELECTRIC CO., LTD."},
  {max_prefix: "00-60-29",vendor: "CARY PERIPHERALS INC."},
  {max_prefix: "00-60-A8",vendor: "TIDOMAT AB"},
  {max_prefix: "00-60-D4",vendor: "ELDAT COMMUNICATION LTD."},
  {max_prefix: "00-60-FC",vendor: "CONSERVATION THROUGH INNOVATION LTD."},
  {max_prefix: "00-60-18",vendor: "STELLAR ONE CORPORATION"},
  {max_prefix: "00-60-B6",vendor: "LAND COMPUTER CO., LTD."},
  {max_prefix: "00-60-6C",vendor: "ARESCOM"},
  {max_prefix: "00-60-2E",vendor: "CYCLADES CORPORATION"},
  {max_prefix: "00-60-74",vendor: "QSC LLC"},
  {max_prefix: "00-60-76",vendor: "SCHLUMBERGER TECHNOLOGIES RETAIL PETROLEUM SYSTEMS"},
  {max_prefix: "00-60-61",vendor: "WHISTLE COMMUNICATIONS CORP."},
  {max_prefix: "00-60-F9",vendor: "DIAMOND LANE COMMUNICATIONS"},
  {max_prefix: "00-60-EA",vendor: "StreamLogic"},
  {max_prefix: "00-60-E3",vendor: "ARBIN INSTRUMENTS"},
  {max_prefix: "00-60-71",vendor: "MIDAS LAB, INC."},
  {max_prefix: "00-60-D9",vendor: "TRANSYS NETWORKS INC."},
  {max_prefix: "00-60-1F",vendor: "STALLION TECHNOLOGIES"},
  {max_prefix: "00-60-1B",vendor: "MESA ELECTRONICS"},
  {max_prefix: "00-60-0A",vendor: "SORD COMPUTER CORPORATION"},
  {max_prefix: "00-60-A4",vendor: "GEW Technologies (PTY)Ltd"},
  {max_prefix: "00-60-64",vendor: "NETCOMM LIMITED"},
  {max_prefix: "00-60-C5",vendor: "ANCOT CORP."},
  {max_prefix: "00-60-A9",vendor: "GESYTEC MBH"},
  {max_prefix: "00-60-F2",vendor: "LASERGRAPHICS, INC."},
  {max_prefix: "00-A0-7A",vendor: "ADVANCED PERIPHERALS TECHNOLOGIES, INC."},
  {max_prefix: "00-A0-4E",vendor: "VOELKER TECHNOLOGIES, INC."},
  {max_prefix: "00-A0-5A",vendor: "KOFAX IMAGE PRODUCTS"},
  {max_prefix: "00-A0-52",vendor: "STANILITE ELECTRONICS PTY. LTD"},
  {max_prefix: "00-A0-5E",vendor: "MYRIAD LOGIC INC."},
  {max_prefix: "00-A0-95",vendor: "ACACIA NETWORKS, INC."},
  {max_prefix: "00-A0-F2",vendor: "INFOTEK COMMUNICATIONS, INC."},
  {max_prefix: "00-A0-DF",vendor: "STS TECHNOLOGIES, INC."},
  {max_prefix: "00-A0-94",vendor: "COMSAT CORPORATION"},
  {max_prefix: "00-60-54",vendor: "CONTROLWARE GMBH"},
  {max_prefix: "00-60-C2",vendor: "MPL AG"},
  {max_prefix: "00-60-9B",vendor: "AstroNova, Inc"},
  {max_prefix: "00-60-DB",vendor: "NTP ELEKTRONIK A/S"},
  {max_prefix: "00-60-52",vendor: "PERIPHERALS ENTERPRISE CO., Ltd."},
  {max_prefix: "00-60-B2",vendor: "PROCESS CONTROL CORP."},
  {max_prefix: "00-60-81",vendor: "TV/COM INTERNATIONAL"},
  {max_prefix: "00-A0-05",vendor: "DANIEL INSTRUMENTS, LTD."},
  {max_prefix: "00-A0-53",vendor: "COMPACT DEVICES, INC."},
  {max_prefix: "00-A0-69",vendor: "Symmetricom, Inc."},
  {max_prefix: "00-60-C3",vendor: "NETVISION CORPORATION"},
  {max_prefix: "00-60-82",vendor: "NOVALINK TECHNOLOGIES, INC."},
  {max_prefix: "00-60-E7",vendor: "RANDATA"},
  {max_prefix: "00-A0-EF",vendor: "LUCIDATA LTD."},
  {max_prefix: "00-A0-CE",vendor: "Ecessa"},
  {max_prefix: "00-A0-20",vendor: "CITICORP/TTI"},
  {max_prefix: "00-A0-67",vendor: "NETWORK SERVICES GROUP"},
  {max_prefix: "00-A0-E0",vendor: "TENNYSON TECHNOLOGIES PTY LTD"},
  {max_prefix: "00-A0-99",vendor: "K-NET LTD."},
  {max_prefix: "00-A0-EC",vendor: "TRANSMITTON LTD."},
  {max_prefix: "00-A0-AB",vendor: "NETCS INFORMATIONSTECHNIK GMBH"},
  {max_prefix: "00-A0-D8",vendor: "SPECTRA - TEK"},
  {max_prefix: "00-A0-80",vendor: "Tattile SRL"},
  {max_prefix: "00-A0-2B",vendor: "TRANSITIONS RESEARCH CORP."},
  {max_prefix: "00-A0-E8",vendor: "REUTERS HOLDINGS PLC"},
  {max_prefix: "00-A0-08",vendor: "NETCORP"},
  {max_prefix: "00-A0-C3",vendor: "UNICOMPUTER GMBH"},
  {max_prefix: "00-A0-0A",vendor: "Airspan"},
  {max_prefix: "00-A0-E7",vendor: "CENTRAL DATA CORPORATION"},
  {max_prefix: "00-A0-FA",vendor: "Marconi Communication GmbH"},
  {max_prefix: "00-A0-CB",vendor: "ARK TELECOMMUNICATIONS, INC."},
  {max_prefix: "00-A0-50",vendor: "CYPRESS SEMICONDUCTOR"},
  {max_prefix: "00-A0-DD",vendor: "AZONIX CORPORATION"},
  {max_prefix: "00-A0-28",vendor: "CONNER PERIPHERALS"},
  {max_prefix: "00-A0-9E",vendor: "ICTV"},
  {max_prefix: "00-A0-FD",vendor: "SCITEX DIGITAL PRINTING, INC."},
  {max_prefix: "00-A0-0F",vendor: "Broadband Technologies"},
  {max_prefix: "00-A0-02",vendor: "LEEDS & NORTHRUP AUSTRALIA PTY LTD"},
  {max_prefix: "00-A0-E4",vendor: "OPTIQUEST"},
  {max_prefix: "00-A0-EE",vendor: "NASHOBA NETWORKS"},
  {max_prefix: "00-A0-66",vendor: "ISA CO., LTD."},
  {max_prefix: "00-A0-34",vendor: "AXEL"},
  {max_prefix: "00-A0-01",vendor: "DRS Signal Solutions"},
  {max_prefix: "00-A0-75",vendor: "MICRON TECHNOLOGY, INC."},
  {max_prefix: "00-A0-09",vendor: "WHITETREE NETWORK"},
  {max_prefix: "00-20-23",vendor: "T.C. TECHNOLOGIES PTY. LTD"},
  {max_prefix: "00-20-B2",vendor: "GKD Gesellschaft Fur Kommunikation Und Datentechnik"},
  {max_prefix: "00-20-52",vendor: "RAGULA SYSTEMS"},
  {max_prefix: "00-20-FE",vendor: "TOPWARE INC. / GRAND COMPUTER"},
  {max_prefix: "00-20-73",vendor: "FUSION SYSTEMS CORPORATION"},
  {max_prefix: "00-20-35",vendor: "IBM Corp"},
  {max_prefix: "00-20-7A",vendor: "WiSE Communications, Inc."},
  {max_prefix: "00-20-3E",vendor: "LogiCan Technologies, Inc."},
  {max_prefix: "00-20-58",vendor: "ALLIED SIGNAL INC."},
  {max_prefix: "00-20-5A",vendor: "COMPUTER IDENTICS"},
  {max_prefix: "00-20-00",vendor: "LEXMARK INTERNATIONAL, INC."},
  {max_prefix: "00-20-1D",vendor: "KATANA PRODUCTS"},
  {max_prefix: "00-A0-0C",vendor: "KINGMAX TECHNOLOGY, INC."},
  {max_prefix: "00-A0-BB",vendor: "HILAN GMBH"},
  {max_prefix: "00-A0-91",vendor: "APPLICOM INTERNATIONAL"},
  {max_prefix: "00-A0-A5",vendor: "TEKNOR MICROSYSTEME, INC."},
  {max_prefix: "00-A0-17",vendor: "J B M CORPORATION"},
  {max_prefix: "00-A0-25",vendor: "REDCOM LABS INC."},
  {max_prefix: "00-A0-A2",vendor: "B810 S.R.L."},
  {max_prefix: "00-20-B7",vendor: "NAMAQUA COMPUTERWARE"},
  {max_prefix: "00-20-E3",vendor: "MCD KENCOM CORPORATION"},
  {max_prefix: "00-20-13",vendor: "DIVERSIFIED TECHNOLOGY, INC."},
  {max_prefix: "00-20-AB",vendor: "MICRO INDUSTRIES CORP."},
  {max_prefix: "00-20-8D",vendor: "CMD TECHNOLOGY"},
  {max_prefix: "00-20-DD",vendor: "Cybertec Pty Ltd"},
  {max_prefix: "00-20-E6",vendor: "LIDKOPING MACHINE TOOLS AB"},
  {max_prefix: "00-20-86",vendor: "MICROTECH ELECTRONICS LIMITED"},
  {max_prefix: "00-20-46",vendor: "CIPRICO, INC."},
  {max_prefix: "00-20-26",vendor: "AMKLY SYSTEMS, INC."},
  {max_prefix: "00-A0-54",vendor: "Private"},
  {max_prefix: "00-20-8E",vendor: "CHEVIN SOFTWARE ENG. LTD."},
  {max_prefix: "00-20-9B",vendor: "ERSAT ELECTRONIC GMBH"},
  {max_prefix: "00-20-1C",vendor: "EXCEL, INC."},
  {max_prefix: "00-20-7F",vendor: "KYOEI SANGYO CO., LTD."},
  {max_prefix: "00-20-08",vendor: "CABLE & COMPUTER TECHNOLOGY"},
  {max_prefix: "00-C0-0B",vendor: "NORCONTROL A.S."},
  {max_prefix: "00-20-B0",vendor: "GATEWAY DEVICES, INC."},
  {max_prefix: "00-20-5B",vendor: "Kentrox, LLC"},
  {max_prefix: "00-20-65",vendor: "SUPERNET NETWORKING INC."},
  {max_prefix: "00-20-19",vendor: "OHLER GMBH"},
  {max_prefix: "00-20-9E",vendor: "BROWN'S OPERATING SYSTEM SERVICES, LTD."},
  {max_prefix: "00-20-2A",vendor: "N.V. DZINE"},
  {max_prefix: "00-20-83",vendor: "PRESTICOM INCORPORATED"},
  {max_prefix: "00-20-D3",vendor: "OST (OUEST STANDARD TELEMATIQU"},
  {max_prefix: "00-20-F6",vendor: "NET TEK  AND KARLNET, INC."},
  {max_prefix: "00-20-C9",vendor: "VICTRON BV"},
  {max_prefix: "00-20-77",vendor: "KARDIOS SYSTEMS CORP."},
  {max_prefix: "00-20-97",vendor: "APPLIED SIGNAL TECHNOLOGY"},
  {max_prefix: "00-20-C6",vendor: "NECTEC"},
  {max_prefix: "00-C0-48",vendor: "BAY TECHNICAL ASSOCIATES"},
  {max_prefix: "00-C0-0E",vendor: "PSITECH, INC."},
  {max_prefix: "00-C0-31",vendor: "DESIGN RESEARCH SYSTEMS, INC."},
  {max_prefix: "00-07-01",vendor: "RACAL-DATACOM"},
  {max_prefix: "00-C0-9C",vendor: "HIOKI E.E. CORPORATION"},
  {max_prefix: "00-C0-97",vendor: "ARCHIPEL SA"},
  {max_prefix: "00-C0-04",vendor: "JAPAN BUSINESS COMPUTER CO.LTD"},
  {max_prefix: "00-C0-2D",vendor: "FUJI PHOTO FILM CO., LTD."},
  {max_prefix: "00-C0-BD",vendor: "INEX TECHNOLOGIES, INC."},
  {max_prefix: "00-C0-88",vendor: "EKF ELEKTRONIK GMBH"},
  {max_prefix: "00-C0-FD",vendor: "PROSUM"},
  {max_prefix: "00-C0-76",vendor: "I-DATA INTERNATIONAL A-S"},
  {max_prefix: "00-C0-46",vendor: "Blue Chip Technology Ltd"},
  {max_prefix: "00-C0-14",vendor: "TELEMATICS CALABASAS INT'L,INC"},
  {max_prefix: "00-AA-3C",vendor: "OLIVETTI TELECOM SPA (OLTECO)"},
  {max_prefix: "00-C0-11",vendor: "INTERACTIVE COMPUTING DEVICES"},
  {max_prefix: "00-C0-3E",vendor: "FA. GEBR. HELLER GMBH"},
  {max_prefix: "00-C0-AA",vendor: "SILICON VALLEY COMPUTER"},
  {max_prefix: "00-C0-66",vendor: "DOCUPOINT, INC."},
  {max_prefix: "00-C0-60",vendor: "ID SCANDINAVIA AS"},
  {max_prefix: "00-C0-C9",vendor: "ELSAG BAILEY PROCESS"},
  {max_prefix: "00-C0-54",vendor: "NETWORK PERIPHERALS, LTD."},
  {max_prefix: "00-C0-22",vendor: "LASERMASTER TECHNOLOGIES, INC."},
  {max_prefix: "00-C0-25",vendor: "DATAPRODUCTS CORPORATION"},
  {max_prefix: "00-C0-DF",vendor: "KYE Systems Corp."},
  {max_prefix: "00-C0-F5",vendor: "METACOMP, INC."},
  {max_prefix: "00-C0-91",vendor: "JABIL CIRCUIT, INC."},
  {max_prefix: "00-C0-49",vendor: "U.S. ROBOTICS, INC."},
  {max_prefix: "00-C0-9D",vendor: "DISTRIBUTED SYSTEMS INT'L, INC"},
  {max_prefix: "00-40-7B",vendor: "SCIENTIFIC ATLANTA"},
  {max_prefix: "00-40-2C",vendor: "ISIS DISTRIBUTED SYSTEMS, INC."},
  {max_prefix: "00-40-CC",vendor: "SILCOM MANUF'G TECHNOLOGY INC."},
  {max_prefix: "00-C0-9E",vendor: "CACHE COMPUTERS, INC."},
  {max_prefix: "00-C0-AC",vendor: "GAMBIT COMPUTER COMMUNICATIONS"},
  {max_prefix: "00-C0-34",vendor: "TRANSACTION NETWORK"},
  {max_prefix: "00-C0-93",vendor: "ALTA RESEARCH CORP."},
  {max_prefix: "00-40-CF",vendor: "STRAWBERRY TREE, INC."},
  {max_prefix: "00-40-77",vendor: "MAXTON TECHNOLOGY CORPORATION"},
  {max_prefix: "00-40-E7",vendor: "ARNOS INSTRUMENTS & COMPUTER"},
  {max_prefix: "00-40-87",vendor: "UBITREX CORPORATION"},
  {max_prefix: "00-40-07",vendor: "TELMAT INFORMATIQUE"},
  {max_prefix: "00-C0-E9",vendor: "OAK SOLUTIONS, LTD."},
  {max_prefix: "00-C0-C5",vendor: "SID INFORMATICA"},
  {max_prefix: "00-C0-51",vendor: "ADVANCED INTEGRATION RESEARCH"},
  {max_prefix: "00-C0-85",vendor: "ELECTRONICS FOR IMAGING, INC."},
  {max_prefix: "00-C0-B2",vendor: "NORAND CORPORATION"},
  {max_prefix: "00-C0-2C",vendor: "CENTRUM COMMUNICATIONS, INC."},
  {max_prefix: "00-C0-2B",vendor: "GERLOFF GESELLSCHAFT FUR"},
  {max_prefix: "00-40-73",vendor: "BASS ASSOCIATES"},
  {max_prefix: "00-40-7D",vendor: "EXTENSION TECHNOLOGY CORP."},
  {max_prefix: "00-40-4D",vendor: "TELECOMMUNICATIONS TECHNIQUES"},
  {max_prefix: "00-40-0D",vendor: "LANNET DATA COMMUNICATIONS,LTD"},
  {max_prefix: "00-40-19",vendor: "AEON SYSTEMS, INC."},
  {max_prefix: "00-40-BE",vendor: "BOEING DEFENSE & SPACE"},
  {max_prefix: "00-40-6E",vendor: "COROLLARY, INC."},
  {max_prefix: "00-C0-FB",vendor: "ADVANCED TECHNOLOGY LABS"},
  {max_prefix: "00-40-5B",vendor: "FUNASSET LIMITED"},
  {max_prefix: "00-40-8B",vendor: "RAYLAN CORPORATION"},
  {max_prefix: "00-40-EF",vendor: "HYPERCOM, INC."},
  {max_prefix: "00-40-93",vendor: "PAXDATA NETWORKS LTD."},
  {max_prefix: "00-40-85",vendor: "SAAB INSTRUMENTS AB"},
  {max_prefix: "00-40-23",vendor: "LOGIC CORPORATION"},
  {max_prefix: "00-40-A4",vendor: "ROSE ELECTRONICS"},
  {max_prefix: "00-40-22",vendor: "KLEVER COMPUTERS, INC."},
  {max_prefix: "00-40-74",vendor: "CABLE AND WIRELESS"},
  {max_prefix: "00-40-B8",vendor: "IDEA ASSOCIATES"},
  {max_prefix: "00-40-E8",vendor: "CHARLES RIVER DATA SYSTEMS,INC"},
  {max_prefix: "00-40-C0",vendor: "VISTA CONTROLS CORPORATION"},
  {max_prefix: "00-40-5D",vendor: "STAR-TEK, INC."},
  {max_prefix: "00-40-08",vendor: "A PLUS INFO CORPORATION"},
  {max_prefix: "00-40-B5",vendor: "VIDEO TECHNOLOGY COMPUTERS LTD"},
  {max_prefix: "00-40-12",vendor: "WINDATA, INC."},
  {max_prefix: "00-40-D5",vendor: "Sartorius Mechatronics T&H GmbH"},
  {max_prefix: "00-80-D7",vendor: "Fantum Engineering"},
  {max_prefix: "00-80-7A",vendor: "AITECH SYSTEMS LTD."},
  {max_prefix: "00-80-DC",vendor: "PICKER INTERNATIONAL"},
  {max_prefix: "00-C0-A0",vendor: "ADVANCE MICRO RESEARCH, INC."},
  {max_prefix: "00-C0-10",vendor: "HIRAKAWA HEWTECH CORP."},
  {max_prefix: "00-40-BF",vendor: "CHANNEL SYSTEMS INTERN'L INC."},
  {max_prefix: "00-40-1E",vendor: "ICC"},
  {max_prefix: "00-40-9A",vendor: "NETWORK EXPRESS, INC."},
  {max_prefix: "00-40-94",vendor: "SHOGRAPHICS, INC."},
  {max_prefix: "00-40-55",vendor: "METRONIX GMBH"},
  {max_prefix: "00-40-E2",vendor: "MESA RIDGE TECHNOLOGIES, INC."},
  {max_prefix: "00-40-C4",vendor: "KINKEI SYSTEM CORPORATION"},
  {max_prefix: "00-C0-37",vendor: "DYNATEM"},
  {max_prefix: "00-40-83",vendor: "TDA INDUSTRIA DE PRODUTOS"},
  {max_prefix: "00-40-27",vendor: "SMC MASSACHUSETTS, INC."},
  {max_prefix: "00-40-45",vendor: "TWINHEAD CORPORATION"},
  {max_prefix: "00-40-76",vendor: "Sun Conversion Technologies"},
  {max_prefix: "00-40-F0",vendor: "MicroBrain,Inc."},
  {max_prefix: "00-40-89",vendor: "MEIDENSHA CORPORATION"},
  {max_prefix: "00-40-28",vendor: "NETCOMM LIMITED"},
  {max_prefix: "00-40-CB",vendor: "LANWAN TECHNOLOGIES"},
  {max_prefix: "00-40-B2",vendor: "SYSTEMFORSCHUNG"},
  {max_prefix: "00-40-E6",vendor: "C.A.E.N."},
  {max_prefix: "00-80-1C",vendor: "NEWPORT SYSTEMS SOLUTIONS"},
  {max_prefix: "00-80-95",vendor: "BASIC MERTON HANDELSGES.M.B.H."},
  {max_prefix: "00-80-AE",vendor: "HUGHES NETWORK SYSTEMS"},
  {max_prefix: "00-40-10",vendor: "SONIC SYSTEMS, INC."},
  {max_prefix: "00-40-CA",vendor: "FIRST INTERNAT'L COMPUTER, INC"},
  {max_prefix: "00-80-31",vendor: "BASYS, CORP."},
  {max_prefix: "00-80-3A",vendor: "VARITYPER, INC."},
  {max_prefix: "00-40-15",vendor: "ASCOM INFRASYS AG"},
  {max_prefix: "00-80-56",vendor: "SPHINX Electronics GmbH & Co KG"},
  {max_prefix: "00-80-13",vendor: "THOMAS-CONRAD CORPORATION"},
  {max_prefix: "00-80-6E",vendor: "NIPPON STEEL CORPORATION"},
  {max_prefix: "00-80-10",vendor: "COMMODORE INTERNATIONAL"},
  {max_prefix: "00-80-D5",vendor: "CADRE TECHNOLOGIES"},
  {max_prefix: "00-80-1B",vendor: "KODIAK TECHNOLOGY"},
  {max_prefix: "00-80-D3",vendor: "SHIVA CORP."},
  {max_prefix: "00-80-B3",vendor: "AVAL DATA CORPORATION"},
  {max_prefix: "00-80-A1",vendor: "MICROTEST, INC."},
  {max_prefix: "00-80-82",vendor: "PEP MODULAR COMPUTERS GMBH"},
  {max_prefix: "00-80-39",vendor: "ALCATEL STC AUSTRALIA"},
  {max_prefix: "00-80-23",vendor: "INTEGRATED BUSINESS NETWORKS"},
  {max_prefix: "00-80-6B",vendor: "SCHMID TELECOMMUNICATION"},
  {max_prefix: "00-80-59",vendor: "STANLEY ELECTRIC CO., LTD"},
  {max_prefix: "00-80-2B",vendor: "INTEGRATED MARKETING CO"},
  {max_prefix: "00-80-01",vendor: "PERIPHONICS CORPORATION"},
  {max_prefix: "00-80-97",vendor: "CENTRALP AUTOMATISMES"},
  {max_prefix: "00-80-88",vendor: "VICTOR COMPANY OF JAPAN, LTD."},
  {max_prefix: "00-80-D8",vendor: "NETWORK PERIPHERALS INC."},
  {max_prefix: "00-80-9E",vendor: "DATUS GMBH"},
  {max_prefix: "00-80-41",vendor: "VEB KOMBINAT ROBOTRON"},
  {max_prefix: "00-80-80",vendor: "DATAMEDIA CORPORATION"},
  {max_prefix: "00-80-98",vendor: "TDK CORPORATION"},
  {max_prefix: "00-80-3F",vendor: "TATUNG COMPANY"},
  {max_prefix: "00-80-E6",vendor: "PEER NETWORKS, INC."},
  {max_prefix: "00-80-E0",vendor: "XTP SYSTEMS, INC."},
  {max_prefix: "00-80-DB",vendor: "GRAPHON CORPORATION"},
  {max_prefix: "00-80-CA",vendor: "NETCOM RESEARCH INCORPORATED"},
  {max_prefix: "00-80-71",vendor: "SAI TECHNOLOGY"},
  {max_prefix: "00-80-20",vendor: "NETWORK PRODUCTS"},
  {max_prefix: "00-80-70",vendor: "COMPUTADORAS MICRON"},
  {max_prefix: "00-80-08",vendor: "DYNATECH COMPUTER SYSTEMS"},
  {max_prefix: "00-00-E4",vendor: "IN2 GROUPE INTERTECHNIQUE"},
  {max_prefix: "00-00-94",vendor: "ASANTE TECHNOLOGIES"},
  {max_prefix: "00-00-90",vendor: "MICROCOM"},
  {max_prefix: "00-00-B6",vendor: "MICRO-MATIC RESEARCH"},
  {max_prefix: "00-00-82",vendor: "LECTRA SYSTEMES SA"},
  {max_prefix: "00-00-2B",vendor: "CRISP AUTOMATION, INC"},
  {max_prefix: "00-00-14",vendor: "NETRONIX"},
  {max_prefix: "00-00-72",vendor: "MINIWARE TECHNOLOGY"},
  {max_prefix: "00-00-A1",vendor: "MARQUETTE ELECTRIC CO."},
  {max_prefix: "00-00-F5",vendor: "DIAMOND SALES LIMITED"},
  {max_prefix: "00-80-47",vendor: "IN-NET CORP."},
  {max_prefix: "00-80-67",vendor: "SQUARE D COMPANY"},
  {max_prefix: "00-80-45",vendor: "MATSUSHITA ELECTRIC IND. CO"},
  {max_prefix: "00-80-BF",vendor: "TAKAOKA ELECTRIC MFG. CO. LTD."},
  {max_prefix: "00-80-17",vendor: "PFU LIMITED"},
  {max_prefix: "00-80-F8",vendor: "MIZAR, INC."},
  {max_prefix: "00-00-51",vendor: "HOB ELECTRONIC GMBH & CO. KG"},
  {max_prefix: "00-00-A7",vendor: "NETWORK COMPUTING DEVICES INC."},
  {max_prefix: "00-00-26",vendor: "SHA-KEN CO., LTD."},
  {max_prefix: "00-80-A9",vendor: "CLEARPOINT RESEARCH"},
  {max_prefix: "00-80-F9",vendor: "HEURIKON CORPORATION"},
  {max_prefix: "00-00-47",vendor: "NICOLET INSTRUMENTS CORP."},
  {max_prefix: "00-00-70",vendor: "HCL LIMITED"},
  {max_prefix: "00-00-8F",vendor: "Raytheon"},
  {max_prefix: "00-00-45",vendor: "FORD AEROSPACE & COMM. CORP."},
  {max_prefix: "00-00-9C",vendor: "ROLM MIL-SPEC COMPUTERS"},
  {max_prefix: "00-00-7C",vendor: "AMPERE INCORPORATED"},
  {max_prefix: "00-00-68",vendor: "ROSEMOUNT CONTROLS"},
  {max_prefix: "00-00-46",vendor: "OLIVETTI NORTH AMERICA"},
  {max_prefix: "00-00-8D",vendor: "Cryptek Inc."},
  {max_prefix: "00-00-3B",vendor: "i Controls, Inc."},
  {max_prefix: "00-00-21",vendor: "SUREMAN COMP. & COMMUN. CORP."},
  {max_prefix: "00-00-74",vendor: "RICOH COMPANY LTD."},
  {max_prefix: "00-00-5C",vendor: "TELEMATICS INTERNATIONAL INC."},
  {max_prefix: "00-00-AC",vendor: "CONWARE COMPUTER CONSULTING"},
  {max_prefix: "00-00-F1",vendor: "MAGNA COMPUTER CORPORATION"},
  {max_prefix: "00-00-54",vendor: "Schneider Electric"},
  {max_prefix: "00-00-20",vendor: "DATAINDUSTRIER DIAB AB"},
  {max_prefix: "00-00-7A",vendor: "DANA COMPUTER INC."},
  {max_prefix: "00-00-98",vendor: "CROSSCOMM CORPORATION"},
  {max_prefix: "00-00-C6",vendor: "EON SYSTEMS"},
  {max_prefix: "00-00-8B",vendor: "INFOTRON"},
  {max_prefix: "00-00-30",vendor: "VG LABORATORY SYSTEMS LTD"},
  {max_prefix: "00-00-35",vendor: "SPECTRAGRAPHICS CORPORATION"},
  {max_prefix: "00-00-E9",vendor: "ISICAD, INC."},
  {max_prefix: "00-00-9F",vendor: "AMERISTAR TECHNOLOGIES INC."},
  {max_prefix: "00-00-E3",vendor: "INTEGRATED MICRO PRODUCTS LTD"},
  {max_prefix: "00-00-AD",vendor: "BRUKER INSTRUMENTS INC."},
  {max_prefix: "00-00-D3",vendor: "WANG LABORATORIES INC."},
  {max_prefix: "00-00-D0",vendor: "DEVELCON ELECTRONICS LTD."},
  {max_prefix: "00-00-93",vendor: "PROTEON INC."},
  {max_prefix: "08-00-67",vendor: "ComDesign"},
  {max_prefix: "08-00-5D",vendor: "GOULD INC."},
  {max_prefix: "08-00-5B",vendor: "VTA TECHNOLOGIES INC."},
  {max_prefix: "08-00-57",vendor: "Evans & Sutherland"},
  {max_prefix: "08-00-71",vendor: "MATRA (DSIE)"},
  {max_prefix: "08-00-6C",vendor: "SUNTEK TECHNOLOGY INT'L"},
  {max_prefix: "00-00-B3",vendor: "CIMLINC INCORPORATED"},
  {max_prefix: "08-00-2D",vendor: "LAN-TEC INC."},
  {max_prefix: "08-00-8C",vendor: "NETWORK RESEARCH CORPORATION"},
  {max_prefix: "08-00-81",vendor: "ASTECH INC."},
  {max_prefix: "00-DD-00",vendor: "UNGERMANN-BASS INC."},
  {max_prefix: "00-00-AA",vendor: "XEROX CORPORATION"},
  {max_prefix: "10-00-00",vendor: "Private"},
  {max_prefix: "08-00-11",vendor: "TEKTRONIX INC."},
  {max_prefix: "08-00-1D",vendor: "ABLE COMMUNICATIONS INC."},
  {max_prefix: "00-DD-0B",vendor: "UNGERMANN-BASS INC."},
  {max_prefix: "AA-00-04",vendor: "DIGITAL EQUIPMENT CORPORATION"},
  {max_prefix: "08-00-0C",vendor: "MIKLYN DEVELOPMENT CO."},
  {max_prefix: "00-DD-05",vendor: "UNGERMANN-BASS INC."},
  {max_prefix: "08-00-03",vendor: "ADVANCED COMPUTER COMM."},
  {max_prefix: "00-DD-0F",vendor: "UNGERMANN-BASS INC."},
  {max_prefix: "00-00-01",vendor: "XEROX CORPORATION"},
  {max_prefix: "00-DD-03",vendor: "UNGERMANN-BASS INC."},
  {max_prefix: "00-00-D7",vendor: "DARTMOUTH COLLEGE"},
  {max_prefix: "08-00-17",vendor: "NATIONAL SEMICONDUCTOR"},
  {max_prefix: "04-0A-E0",vendor: "XMIT AG COMPUTER NETWORKS"},
  {max_prefix: "08-00-26",vendor: "NORSK DATA A.S."},
  {max_prefix: "08-00-25",vendor: "CONTROL DATA"},
  {max_prefix: "48-D0-17",vendor: "Telecom Infra Project"},
  {max_prefix: "F8-09-A4",vendor: "Henan Thinker Rail Transportation Research Inc."},
  {max_prefix: "A8-42-A1",vendor: "TP-Link Corporation Limited"},
  {max_prefix: "9C-F5-5F",vendor: "Harman/Becker Automotive Systems GmbH"},
  {max_prefix: "90-2C-09",vendor: "Apple, Inc."},
  {max_prefix: "A0-B4-0F",vendor: "Apple, Inc."},
  {max_prefix: "80-7C-0A",vendor: "zte corporation"},
  {max_prefix: "EC-0D-51",vendor: "Apple, Inc."},
  {max_prefix: "AC-DF-A1",vendor: "Apple, Inc."},
  {max_prefix: "C0-5F-87",vendor: "Legrand INTELLIGENT ELECTRICAL(HUIZHOU)CO.,LTD."},
  {max_prefix: "68-52-10",vendor: "MCS Logic"},
  {max_prefix: "C8-CC-B5",vendor: "Hunter Douglas"},
  {max_prefix: "14-DA-B9",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "B0-CA-E7",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "A8-7C-45",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "2C-ED-B0",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "2C-69-3E",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "DC-62-1F",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "48-83-6F",vendor: "EM Microelectronic"},
  {max_prefix: "F4-83-2C",vendor: "OHSUNG"},
  {max_prefix: "48-ED-E6",vendor: "Zyxel Communications Corporation"},
  {max_prefix: "A4-10-B6",vendor: "Cisco Systems, Inc"},
  {max_prefix: "C8-47-09",vendor: "Cisco Systems, Inc"},
  {max_prefix: "3C-25-F8",vendor: "Dell Inc."},
  {max_prefix: "00-03-24",vendor: "LIMNO Co., Ltd."},
  {max_prefix: "70-BD-D2",vendor: "Adva Network Security GmbH"},
  {max_prefix: "D0-0B-27",vendor: "Murata Manufacturing Co., Ltd."},
  {max_prefix: "4C-57-39",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "48-E5-33",vendor: "Sunnovo International Limited"},
  {max_prefix: "F0-FD-DD",vendor: "Foxtron Vehicle Technologies Co., Ltd."},
  {max_prefix: "00-D5-98",vendor: "BOPEL MOBILE TECHNOLOGY CO.,LIMITED"},
  {max_prefix: "90-45-28",vendor: "Nintendo Co.,Ltd"},
  {max_prefix: "F4-52-46",vendor: "ASKEY COMPUTER CORP"},
  {max_prefix: "6C-16-29",vendor: "Edifier International"},
  {max_prefix: "F0-37-CF",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "2C-C8-F5",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "AC-36-1B",vendor: "Hon Hai Precision Industry Co.,LTD"},
  {max_prefix: "D8-13-2A",vendor: "Espressif Inc."},
  {max_prefix: "9C-1E-CE",vendor: "ALT Co., Ltd."},
  {max_prefix: "B8-06-0D",vendor: "Tuya Smart Inc."},
  {max_prefix: "00-1F-9C",vendor: "Havis Inc."},
  {max_prefix: "C8-E1-93",vendor: "ITEL MOBILE LIMITED"},
  {max_prefix: "D4-A2-3D",vendor: "New H3C Technologies Co., Ltd"},
  {max_prefix: "28-CB-5C",vendor: "Shenzhen CPETEK Technology Co.,Ltd."},
  {max_prefix: "D0-5B-CB",vendor: "Tianyi Telecom Terminals Company Limited"},
  {max_prefix: "BC-9A-8E",vendor: "HUMAX NETWORKS"},
  {max_prefix: "50-20-65",vendor: "Quectel Wireless Solutions Co.,Ltd."},
  {max_prefix: "10-2C-B1",vendor: "Smart Innovation LLC"},
  {max_prefix: "FC-47-8D",vendor: "SHENZHEN TOPWELL TECHNOLOGY CO., LTD."},
  {max_prefix: "80-65-99",vendor: "Espressif Inc."},
  {max_prefix: "04-AE-C7",vendor: "Marquardt"},
  {max_prefix: "E4-F8-BE",vendor: "TECNO MOBILE LIMITED"},
  {max_prefix: "00-C0-F2",vendor: "Lantronix"},
  {max_prefix: "E4-AA-E4",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "68-C4-4C",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "A0-00-4C",vendor: "HISENSE VISUAL TECHNOLOGY CO.,LTD"},
  {max_prefix: "D8-E5-93",vendor: "Apple, Inc."},
  {max_prefix: "CC-68-E0",vendor: "Apple, Inc."},
  {max_prefix: "70-DA-48",vendor: "Cisco Systems, Inc"},
  {max_prefix: "F4-B6-2D",vendor: "Dongguan Huayin Electronic Technology Co., Ltd."},
  {max_prefix: "20-10-8A",vendor: "zte corporation"},
  {max_prefix: "58-FF-A1",vendor: "zte corporation"},
  {max_prefix: "B0-8B-9E",vendor: "Telechips, Inc."},
  {max_prefix: "BC-1F-E1",vendor: "Ascendent Technology Group"},
  {max_prefix: "AC-DB-22",vendor: "Marquardt Schaltsysteme SCS"},
  {max_prefix: "B8-B1-EA",vendor: "Honor Device Co., Ltd."},
  {max_prefix: "2C-58-B9",vendor: "HP Inc."},
  {max_prefix: "30-50-F1",vendor: "Ennoconn Corporation."},
  {max_prefix: "B0-FA-91",vendor: "EM Microelectronic"},
  {max_prefix: "D4-F9-21",vendor: "Qingdao Intelligent&Precise Electronics Co.,Ltd."},
  {max_prefix: "E4-7A-2C",vendor: "SZ DJI TECHNOLOGY CO.,LTD"},
  {max_prefix: "60-60-1F",vendor: "SZ DJI TECHNOLOGY CO.,LTD"},
  {max_prefix: "48-80-02",vendor: "Cisco Systems, Inc"},
  {max_prefix: "B4-6A-D4",vendor: "Edgecore Americas Networking Corporation"},
  {max_prefix: "AC-71-2E",vendor: "Fortinet, Inc."},
  {max_prefix: "CC-D8-43",vendor: "Beijing Xiaomi Mobile Software Co., Ltd"},
  {max_prefix: "C0-35-32",vendor: "Liteon Technology Corporation"},
  {max_prefix: "74-06-35",vendor: "SERNET (SUZHOU) TECHNOLOGIES CORPORATION"},
  {max_prefix: "F8-01-B4",vendor: "LG Electronics"},
  {max_prefix: "54-21-A9",vendor: "ITEL MOBILE LIMITED"},
  {max_prefix: "7C-C0-AA",vendor: "Microsoft Corporation"},
  {max_prefix: "94-AB-18",vendor: "cellXica ltd"},
  {max_prefix: "10-73-C6",vendor: "August Internet Limited"},
  {max_prefix: "F0-3F-03",vendor: "Private"},
  {max_prefix: "C4-21-B9",vendor: "zte corporation"},
  {max_prefix: "BC-B2-CC",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "08-A5-DF",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "6C-D6-3F",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "0C-18-4E",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "D4-F7-D5",vendor: "Sony Interactive Entertainment Inc."},
  {max_prefix: "98-28-8B",vendor: "zhejiang Dusun Electron Co.,Ltd"},
  {max_prefix: "E0-E8-E6",vendor: "Shenzhen C-Data Technology Co., Ltd."},
  {max_prefix: "6C-1F-F7",vendor: "Ugreen Group Limited"},
  {max_prefix: "04-5E-A4",vendor: "Netis Technology Co., Ltd."},
  {max_prefix: "64-EE-B7",vendor: "Netis Technology Co., Ltd."},
  {max_prefix: "04-8D-38",vendor: "Netis Technology Co., Ltd."},
  {max_prefix: "E8-25-87",vendor: "Shenzhen Chilink IoT Technology Co., Ltd."},
  {max_prefix: "50-7B-91",vendor: "Sigmastar Technology Ltd."},
  {max_prefix: "8C-44-A5",vendor: "Cisco Systems, Inc"},
  {max_prefix: "08-E3-42",vendor: "Cear, Inc."},
  {max_prefix: "A0-E0-25",vendor: "Provision-ISR"},
  {max_prefix: "F4-FE-3E",vendor: "Apple, Inc."},
  {max_prefix: "14-55-B9",vendor: "Nokia Solutions and Networks GmbH & Co. KG"},
  {max_prefix: "9C-24-72",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "F4-39-A6",vendor: "Apple, Inc."},
  {max_prefix: "FC-55-57",vendor: "Apple, Inc."},
  {max_prefix: "34-2B-6E",vendor: "Apple, Inc."},
  {max_prefix: "60-65-25",vendor: "Apple, Inc."},
  {max_prefix: "8C-55-70",vendor: "IEEE Registration Authority"},
  {max_prefix: "20-5A-1D",vendor: "zte corporation"},
  {max_prefix: "8C-08-79",vendor: "Texas Instruments"},
  {max_prefix: "68-23-B0",vendor: "Texas Instruments"},
  {max_prefix: "78-CD-55",vendor: "Texas Instruments"},
  {max_prefix: "F8-F5-19",vendor: "Rulogic Inc."},
  {max_prefix: "80-85-44",vendor: "Intelbras"},
  {max_prefix: "24-69-8E",vendor: "SHENZHEN MERCURY COMMUNICATION TECHNOLOGIES CO.,LTD."},
  {max_prefix: "64-0D-CE",vendor: "SHENZHEN MERCURY COMMUNICATION TECHNOLOGIES CO.,LTD."},
  {max_prefix: "6C-5C-B1",vendor: "Silicon Laboratories"},
  {max_prefix: "5C-C7-C1",vendor: "Silicon Laboratories"},
  {max_prefix: "60-B6-47",vendor: "Silicon Laboratories"},
  {max_prefix: "A4-6D-D4",vendor: "Silicon Laboratories"},
  {max_prefix: "0C-AE-5F",vendor: "Silicon Laboratories"},
  {max_prefix: "28-DB-A7",vendor: "Silicon Laboratories"},
  {max_prefix: "58-3B-C2",vendor: "Silicon Laboratories"},
  {max_prefix: "34-25-B4",vendor: "Silicon Laboratories"},
  {max_prefix: "00-60-05",vendor: "Touchstar ATC Limited"},
  {max_prefix: "4C-B7-E0",vendor: "SHENZHEN MERCURY COMMUNICATION TECHNOLOGIES CO.,LTD."},
  {max_prefix: "BC-0F-B7",vendor: "sywinkey HongKong Co,. Limited?"},
  {max_prefix: "60-1D-56",vendor: "Extreme Networks Headquarters"},
  {max_prefix: "00-40-8C",vendor: "Axis Communications AB"},
  {max_prefix: "00-CA-E0",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "44-D4-65",vendor: "NXP Semiconductors Taiwan Ltd."},
  {max_prefix: "54-60-6D",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "A4-86-DB",vendor: "Guangdong Juan Intelligent Technology Joint Stock Co., Ltd."},
  {max_prefix: "9C-63-C0",vendor: "Mellanox Technologies, Inc."},
  {max_prefix: "5C-25-73",vendor: "Mellanox Technologies, Inc."},
  {max_prefix: "D4-F0-4A",vendor: "SKY UK LIMITED"},
  {max_prefix: "AC-BA-C0",vendor: "Intertech Services AG"},
  {max_prefix: "1C-7A-CF",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "A8-6A-86",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "3C-45-0B",vendor: "Sentry Equipment Corp."},
  {max_prefix: "70-D5-1E",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "B8-7E-39",vendor: "Motorola Mobility LLC, a Lenovo Company"},
  {max_prefix: "64-67-72",vendor: "SERCOMM PHILIPPINES INC"},
  {max_prefix: "54-EC-B0",vendor: "Sercomm France Sarl"},
  {max_prefix: "DC-DA-4D",vendor: "CELESTICA INC."},
  {max_prefix: "50-5E-24",vendor: "zte corporation"},
  {max_prefix: "84-BA-59",vendor: "Wistron InfoComm(Chongqing)Co.,Ltd."},
  {max_prefix: "10-90-FA",vendor: "New H3C Technologies Co., Ltd"},
  {max_prefix: "74-73-E2",vendor: "Hillstone Networks Corp."},
  {max_prefix: "D4-01-C3",vendor: "Routerboard.com"},
  {max_prefix: "74-07-7E",vendor: "Quectel Wireless Solutions Co.,Ltd."},
  {max_prefix: "30-EC-7C",vendor: "Shenzhen Along Electronics Co., Ltd"},
  {max_prefix: "38-26-56",vendor: "TCL King Electrical Appliances(Huizhou)Co.,Ltd"},
  {max_prefix: "54-C6-A6",vendor: "Hubei Yangtze Mason Semiconductor Technology  Co., Ltd."},
  {max_prefix: "B0-9E-1B",vendor: "Butlr Technologies, Inc."},
  {max_prefix: "14-A1-DF",vendor: "China Mobile Group Device Co.,Ltd."},
  {max_prefix: "D0-A0-11",vendor: "IEEE Registration Authority"},
  {max_prefix: "B0-B3-69",vendor: "Shenzhen SDMC Technology CO.,Ltd."},
  {max_prefix: "A8-CF-E0",vendor: "GDN Enterprises Private Limited"},
  {max_prefix: "D0-B3-C2",vendor: "Meta Platforms Technologies, LLC"},
  {max_prefix: "D8-F7-60",vendor: "EM Microelectronic"},
  {max_prefix: "40-B8-2D",vendor: "Ruckus Wireless"},
  {max_prefix: "00-50-92",vendor: "Rigaku Corporation Osaka Plant"},
  {max_prefix: "B4-77-48",vendor: "Shenzhen Neoway Technology Co.,Ltd."},
  {max_prefix: "C4-3E-AB",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "CC-44-60",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "24-50-6F",vendor: "THINKCAR TECH CO.,LTD."},
  {max_prefix: "34-EA-10",vendor: "Orbic North America"},
  {max_prefix: "6C-92-CF",vendor: "Broadcom Limited"},
  {max_prefix: "F4-31-49",vendor: "Pixel FX"},
  {max_prefix: "78-E9-96",vendor: "IEEE Registration Authority"},
  {max_prefix: "3C-35-76",vendor: "ITEL MOBILE LIMITED"},
  {max_prefix: "A0-21-AA",vendor: "China Mobile Group Device Co.,Ltd."},
  {max_prefix: "F4-20-15",vendor: "Guangzhou Shiyuan Electronic Technology Company Limited"},
  {max_prefix: "90-21-2E",vendor: "Apption Labs Ltd"},
  {max_prefix: "50-D4-48",vendor: "Private"},
  {max_prefix: "1C-7A-43",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "08-44-73",vendor: "zte corporation"},
  {max_prefix: "94-35-89",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "94-26-1D",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "A4-5C-25",vendor: "Texas Instruments"},
  {max_prefix: "98-F4-87",vendor: "Texas Instruments"},
  {max_prefix: "FC-59-9F",vendor: "Ruijie Networks Co.,LTD"},
  {max_prefix: "84-A3-29",vendor: "Arcadyan Corporation"},
  {max_prefix: "9C-09-8B",vendor: "Cisco Systems, Inc"},
  {max_prefix: "9C-58-84",vendor: "Apple, Inc."},
  {max_prefix: "24-B3-39",vendor: "Apple, Inc."},
  {max_prefix: "E0-4E-7A",vendor: "Nanjing Qinheng Microelectronics Co., Ltd."},
  {max_prefix: "D0-76-02",vendor: "Hui Zhou Gaoshengda Technology Co.,LTD"},
  {max_prefix: "94-BD-BE",vendor: "TPV Display Technology (Xiamen) Co.,Ltd."},
  {max_prefix: "94-0B-FA",vendor: "EM Microelectronic"},
  {max_prefix: "84-82-1B",vendor: "PROX SG Pte Ltd"},
  {max_prefix: "80-A5-79",vendor: "IEEE Registration Authority"},
  {max_prefix: "6C-EF-BD",vendor: "Cisco Meraki"},
  {max_prefix: "74-39-89",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "28-39-84",vendor: "Qidi Technology (shanghai) Co.,Ltd."},
  {max_prefix: "5C-FA-5A",vendor: "Sinepower Lda"},
  {max_prefix: "F4-9D-8A",vendor: "Fantasia Trading LLC"},
  {max_prefix: "7C-99-2E",vendor: "Shanghai Notion lnformatio Technology Co.,Ltd."},
  {max_prefix: "8C-EA-88",vendor: "Chengdu Yocto Communication Technology Co.Ltd."},
  {max_prefix: "10-5F-AD",vendor: "Intel Corporate"},
  {max_prefix: "4C-B0-4A",vendor: "Intel Corporate"},
  {max_prefix: "40-EE-BE",vendor: "NXP Semiconductor (Tianjin) LTD."},
  {max_prefix: "FC-98-16",vendor: "ALPSALPINE CO,.LTD"},
  {max_prefix: "E0-8F-4C",vendor: "Intel Corporate"},
  {max_prefix: "54-72-6E",vendor: "Daimler Truck AG"},
  {max_prefix: "88-65-9F",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "3C-0A-7A",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "24-24-B7",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "F0-05-1B",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "BC-A0-80",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "00-2B-70",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "C4-46-06",vendor: "Cisco Systems, Inc"},
  {max_prefix: "48-74-10",vendor: "Cisco Systems, Inc"},
  {max_prefix: "B4-36-A9",vendor: "Fibocom Wireless Inc."},
  {max_prefix: "94-27-70",vendor: "BSH Hausger\u00e4te GmbH"},
  {max_prefix: "AC-1E-A9",vendor: "Intelbras"},
  {max_prefix: "80-E9-4A",vendor: "LEAPS s.r.o."},
  {max_prefix: "20-18-5B",vendor: "Shenzhen Jingxun Technology Co., Ltd."},
  {max_prefix: "58-FE-7E",vendor: "zte corporation"},
  {max_prefix: "34-19-4D",vendor: "Arcadyan Corporation"},
  {max_prefix: "9C-5A-80",vendor: "Juniper Networks"},
  {max_prefix: "6C-80-AB",vendor: "ifanr Inc"},
  {max_prefix: "58-86-70",vendor: "Juniper Networks"},
  {max_prefix: "7C-0C-FA",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "34-83-D5",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "C4-08-26",vendor: "HISENSE VISUAL TECHNOLOGY CO.,LTD"},
  {max_prefix: "28-17-CB",vendor: "Software Freedom Conservancy"},
  {max_prefix: "7C-5E-98",vendor: "eero inc."},
  {max_prefix: "C8-58-6A",vendor: "Beijing Winner Microelectronics Co.,Ltd."},
  {max_prefix: "1C-64-F0",vendor: "Motorola Mobility LLC, a Lenovo Company"},
  {max_prefix: "74-BE-F3",vendor: "Motorola Mobility LLC, a Lenovo Company"},
  {max_prefix: "AC-88-66",vendor: "Shenzhen Skyworth Digital  Technology  CO., Ltd"},
  {max_prefix: "5C-48-42",vendor: "Hangzhou Anysoft Information Technology Co. , Ltd"},
  {max_prefix: "8C-C9-E9",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "D8-54-F2",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "E4-54-E5",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "88-6B-DB",vendor: "Apple, Inc."},
  {max_prefix: "14-35-B7",vendor: "Apple, Inc."},
  {max_prefix: "7C-C0-25",vendor: "Palo Alto Networks"},
  {max_prefix: "C4-AB-4D",vendor: "Cisco Systems, Inc"},
  {max_prefix: "10-E3-76",vendor: "Cisco Systems, Inc"},
  {max_prefix: "F4-52-93",vendor: "Apple, Inc."},
  {max_prefix: "94-21-57",vendor: "Apple, Inc."},
  {max_prefix: "9C-9E-6E",vendor: "Espressif Inc."},
  {max_prefix: "74-C1-7E",vendor: "LG Electronics"},
  {max_prefix: "B4-17-4D",vendor: "PROJECT MONITOR INC"},
  {max_prefix: "64-48-42",vendor: "Apple, Inc."},
  {max_prefix: "10-98-19",vendor: "Dell Inc."},
  {max_prefix: "FC-91-5D",vendor: "Google, Inc."},
  {max_prefix: "CC-B7-77",vendor: "zte corporation"},
  {max_prefix: "30-89-A6",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "60-F4-19",vendor: "eero inc."},
  {max_prefix: "DC-92-72",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "18-0C-7A",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "F8-4D-8B",vendor: "ecamtek"},
  {max_prefix: "1C-E8-9E",vendor: "SJIT"},
  {max_prefix: "AC-A3-1E",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "D0-67-26",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "EC-9B-8B",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "08-97-34",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "B0-B8-67",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "80-30-E0",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "00-4E-35",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "B4-5D-50",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "40-E3-D6",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "00-FD-45",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "C8-B5-AD",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "5C-BA-2C",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "5C-ED-8C",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "90-20-C2",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "94-F1-28",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "0C-C3-B8",vendor: "Shenzhen Jiahua Zhongli Technology Co., LTD"},
  {max_prefix: "EC-67-94",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "A0-25-D7",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "98-8F-00",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "A4-0E-75",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "38-BD-7A",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "E8-10-98",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "44-5B-ED",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "A8-5B-F7",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "EC-FC-C6",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "64-E8-81",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "EC-3B-AF",vendor: "EM Microelectronic"},
  {max_prefix: "5C-24-1F",vendor: "Qingdao Haier Technology Co.,Ltd"},
  {max_prefix: "48-E2-AD",vendor: "HUMAX NETWORKS"},
  {max_prefix: "50-38-AB",vendor: "PROVE"},
  {max_prefix: "AC-8E-BD",vendor: "Microsoft Corporation"},
  {max_prefix: "AC-FA-E4",vendor: "Nintendo Co.,Ltd"},
  {max_prefix: "98-B7-1E",vendor: "Infinix mobility limited"},
  {max_prefix: "78-2E-03",vendor: "GUANGDONG GENIUS TECHNOLOGY CO., LTD."},
  {max_prefix: "44-38-E8",vendor: "Intel Corporate"},
  {max_prefix: "78-29-AD",vendor: "NINGBO QIXIANG INFORMATION TECHNOLOGY CO., LTD"},
  {max_prefix: "38-1B-9E",vendor: "Qingdao Intelligent&Precise Electronics Co.,Ltd."},
  {max_prefix: "64-07-8C",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "48-02-34",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "E8-DF-24",vendor: "OHSUNG"},
  {max_prefix: "7C-BA-C6",vendor: "Solar Manager AG"},
  {max_prefix: "04-01-A1",vendor: "Fortinet, Inc."},
  {max_prefix: "D0-56-FB",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "C0-7A-D6",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "5C-15-C5",vendor: "Shenzhen SSC Technology Co. Ltd"},
  {max_prefix: "28-AF-21",vendor: "zte corporation"},
  {max_prefix: "0C-32-3A",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "00-02-95",vendor: "MAVENIR IPA UK LTD"},
  {max_prefix: "F8-7A-39",vendor: "IEEE Registration Authority"},
  {max_prefix: "78-E6-1C",vendor: "Honor Device Co., Ltd."},
  {max_prefix: "04-13-7A",vendor: "Apple, Inc."},
  {max_prefix: "70-F9-4A",vendor: "Apple, Inc."},
  {max_prefix: "90-B7-90",vendor: "Apple, Inc."},
  {max_prefix: "04-47-07",vendor: "Texas Instruments"},
  {max_prefix: "94-50-44",vendor: "Texas Instruments"},
  {max_prefix: "C8-84-4E",vendor: "Flextronics International Kft"},
  {max_prefix: "78-EE-4C",vendor: "Espressif Inc."},
  {max_prefix: "F4-9E-CE",vendor: "Sena Technologies Co., Ltd."},
  {max_prefix: "34-10-5D",vendor: "Texas Instruments"},
  {max_prefix: "F0-D6-35",vendor: "Apple, Inc."},
  {max_prefix: "9C-DB-CB",vendor: "Funshion Online Technologies Co.,Ltd"},
  {max_prefix: "38-2C-E5",vendor: "Tuya Smart Inc."},
  {max_prefix: "84-78-48",vendor: "Ubiquiti Inc"},
  {max_prefix: "C4-18-FC",vendor: "Cisco Systems, Inc"},
  {max_prefix: "EC-5B-CD",vendor: "IEEE Registration Authority"},
  {max_prefix: "CC-96-A2",vendor: "China Mobile Group Device Co.,Ltd."},
  {max_prefix: "54-3D-60",vendor: "Arcadyan Corporation"},
  {max_prefix: "6C-1D-2C",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "20-C2-B0",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "C0-52-34",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "B0-C2-C7",vendor: "Motorola Mobility LLC, a Lenovo Company"},
  {max_prefix: "64-8B-9B",vendor: "ALWAYS ON TECH PTE.LTD."},
  {max_prefix: "38-28-35",vendor: "zte corporation"},
  {max_prefix: "68-BF-6C",vendor: "Arista Networks"},
  {max_prefix: "74-A5-C2",vendor: "KYOCERA Corporation"},
  {max_prefix: "74-12-13",vendor: "Linksys USA, Inc"},
  {max_prefix: "34-3D-7F",vendor: "Klipsch Group, Inc."},
  {max_prefix: "38-DE-35",vendor: "GUANGZHOU YUANDIANHE COMMUNICATION TECHNOLOGY CO.,LTD"},
  {max_prefix: "94-B3-F7",vendor: "Hui Zhou Gaoshengda Technology Co.,LTD"},
  {max_prefix: "A0-0A-9A",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "1C-83-EC",vendor: "Ubee Interactive co, Limited."},
  {max_prefix: "28-07-08",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "58-1F-19",vendor: "EMH Metering GmbH & Co. KG"},
  {max_prefix: "08-DA-33",vendor: "IEEE Registration Authority"},
  {max_prefix: "B8-B4-C9",vendor: "Cisco Meraki"},
  {max_prefix: "C0-1C-6A",vendor: "Google, Inc."},
  {max_prefix: "8C-1E-CF",vendor: "UNIONMAN TECHNOLOGY CO.,LTD"},
  {max_prefix: "7C-3E-82",vendor: "Bouffalo Lab (Nanjing) Co., Ltd."},
  {max_prefix: "F0-24-F9",vendor: "Espressif Inc."},
  {max_prefix: "68-CC-BA",vendor: "Dense Air Networks US LLC"},
  {max_prefix: "4C-6B-B8",vendor: "Hui Zhou Gaoshengda Technology Co.,LTD"},
  {max_prefix: "00-56-F1",vendor: "zte corporation"},
  {max_prefix: "74-42-18",vendor: "Apple, Inc."},
  {max_prefix: "90-4C-C5",vendor: "Apple, Inc."},
  {max_prefix: "EC-46-54",vendor: "Apple, Inc."},
  {max_prefix: "54-05-DB",vendor: "LCFC(Hefei) Electronics Technology co., ltd"},
  {max_prefix: "50-F3-51",vendor: "Apple, Inc."},
  {max_prefix: "20-2D-F6",vendor: "Apple, Inc."},
  {max_prefix: "54-5B-86",vendor: "Panasonic Automotive Systems Co.,Ltd"},
  {max_prefix: "C4-EF-BB",vendor: "LCFC(Hefei) Electronics Technology co., ltd"},
  {max_prefix: "84-A9-38",vendor: "LCFC(Hefei) Electronics Technology co., ltd"},
  {max_prefix: "FC-5C-EE",vendor: "LCFC(Hefei) Electronics Technology co., ltd"},
  {max_prefix: "40-F4-C9",vendor: "FN-LINK TECHNOLOGY Ltd."},
  {max_prefix: "38-A5-C9",vendor: "Tuya Smart Inc."},
  {max_prefix: "F8-17-2D",vendor: "Tuya Smart Inc."},
  {max_prefix: "8C-5C-20",vendor: "Vantiva USA LLC"},
  {max_prefix: "98-75-96",vendor: "EM Microelectronic"},
  {max_prefix: "00-A1-59",vendor: "LG Electronics"},
  {max_prefix: "4C-22-C9",vendor: "zte corporation"},
  {max_prefix: "C4-13-75",vendor: "CLOUD NETWORK TECHNOLOGY SINGAPORE PTE. LTD."},
  {max_prefix: "8C-BF-EA",vendor: "Espressif Inc."},
  {max_prefix: "B4-1D-C4",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "10-5F-02",vendor: "Ruijie Networks Co.,LTD"},
  {max_prefix: "28-24-C9",vendor: "Amazon Technologies Inc."},
  {max_prefix: "CC-6C-52",vendor: "DZS Inc."},
  {max_prefix: "1C-62-7E",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "60-56-B1",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "50-00-E0",vendor: "Cisco Systems, Inc"},
  {max_prefix: "74-E2-E7",vendor: "Cisco Systems, Inc"},
  {max_prefix: "C4-60-0A",vendor: "Huaqin Technology Co.LTD"},
  {max_prefix: "14-7E-19",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "38-06-E6",vendor: "Arcadyan Corporation"},
  {max_prefix: "10-74-C5",vendor: "Calix Inc."},
  {max_prefix: "BC-CD-7F",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "74-28-69",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "44-89-6D",vendor: "TELLESCOM INDUSTRIA E COMERCIO EM TELECOMUNICACAO"},
  {max_prefix: "E4-51-A9",vendor: "Nanjing Xinlian Electronics Co., Ltd"},
  {max_prefix: "00-F8-CC",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "14-5A-41",vendor: "CLOUD NETWORK TECHNOLOGY SINGAPORE PTE. LTD."},
  {max_prefix: "AC-45-CA",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "D0-C1-B5",vendor: "Dell Inc."},
  {max_prefix: "88-76-B9",vendor: "D-Link Corporation"},
  {max_prefix: "EC-E7-8E",vendor: "AsiaTelco Technologies Co."},
  {max_prefix: "C4-8B-66",vendor: "Hui Zhou Gaoshengda Technology Co.,LTD"},
  {max_prefix: "14-E2-89",vendor: "Abietec Inc."},
  {max_prefix: "50-2F-BB",vendor: "Motorola Mobility LLC, a Lenovo Company"},
  {max_prefix: "6C-0B-5E",vendor: "HP Inc."},
  {max_prefix: "2C-19-5C",vendor: "Beijing Xiaomi Mobile Software Co., Ltd"},
  {max_prefix: "50-69-5A",vendor: "AiFamous\uff08shenzhen\uff09Technology Co.,Ltd"},
  {max_prefix: "C4-63-C4",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "54-A2-45",vendor: "Digisol Systems Limited"},
  {max_prefix: "90-A1-96",vendor: "Private"},
  {max_prefix: "14-35-B3",vendor: "Future Designs, Inc."},
  {max_prefix: "FC-D9-6B",vendor: "Realme Chongqing Mobile Telecommunications Corp.,Ltd."},
  {max_prefix: "0C-1B-CC",vendor: "IFLYTEK CO.,LTD."},
  {max_prefix: "68-E5-80",vendor: "Apple, Inc."},
  {max_prefix: "58-66-6D",vendor: "Apple, Inc."},
  {max_prefix: "DC-9E-8F",vendor: "Apple, Inc."},
  {max_prefix: "E0-C2-9E",vendor: "zte corporation"},
  {max_prefix: "B4-96-A5",vendor: "Apple, Inc."},
  {max_prefix: "38-09-FB",vendor: "Apple, Inc."},
  {max_prefix: "60-B0-2B",vendor: "Qingdao Haier Technology Co.,Ltd"},
  {max_prefix: "4C-5D-6A",vendor: "Apple, Inc."},
  {max_prefix: "34-C4-59",vendor: "Texas Instruments"},
  {max_prefix: "44-88-BE",vendor: "Texas Instruments"},
  {max_prefix: "FC-DE-C5",vendor: "Texas Instruments"},
  {max_prefix: "78-99-87",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "FC-19-28",vendor: "Actions Microelectronics"},
  {max_prefix: "F8-7B-E0",vendor: "Funtime Pickleball Inc."},
  {max_prefix: "30-68-93",vendor: "TP-Link Systems Inc."},
  {max_prefix: "9C-47-F4",vendor: "Nokia"},
  {max_prefix: "D4-C9-B2",vendor: "Quanergy Solutions Inc"},
  {max_prefix: "08-A1-36",vendor: "CLOUD NETWORK TECHNOLOGY SINGAPORE PTE. LTD."},
  {max_prefix: "74-E1-47",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "24-28-56",vendor: "Beijing Gctech Technology Co.,LTD"},
  {max_prefix: "A0-D4-2D",vendor: "G.Tech Technology Ltd."},
  {max_prefix: "58-4B-BC",vendor: "zte corporation"},
  {max_prefix: "34-2C-8E",vendor: "Ufispace Co., LTD."},
  {max_prefix: "24-3C-B0",vendor: "Dongguan Mentech Optical & Magnetic Co., Ltd."},
  {max_prefix: "A4-57-A0",vendor: "SAMJIN Co., Ltd."},
  {max_prefix: "74-B8-A8",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "E0-F3-30",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "9C-03-51",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "0C-B7-87",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "B8-8E-B0",vendor: "Shenzhen Skyworth Digital  Technology  CO., Ltd"},
  {max_prefix: "98-98-FB",vendor: "Google, Inc."},
  {max_prefix: "84-EB-3F",vendor: "Vivint Inc"},
  {max_prefix: "24-A3-F0",vendor: "IEEE Registration Authority"},
  {max_prefix: "F8-16-0C",vendor: "Shenzhen iComm Semiconductor CO.,LTD"},
  {max_prefix: "34-17-36",vendor: "Shenzhen iComm Semiconductor CO.,LTD"},
  {max_prefix: "70-7F-F2",vendor: "Telechips, Inc."},
  {max_prefix: "90-B5-7F",vendor: "Shenzhen iComm Semiconductor CO.,LTD"},
  {max_prefix: "60-DE-F4",vendor: "Shenzhen iComm Semiconductor CO.,LTD"},
  {max_prefix: "F4-65-0B",vendor: "Espressif Inc."},
  {max_prefix: "B4-16-78",vendor: "Juniper Networks"},
  {max_prefix: "3C-DC-03",vendor: "IEEE Registration Authority"},
  {max_prefix: "C0-A3-C7",vendor: "Telink Micro LLC"},
  {max_prefix: "FC-4D-6A",vendor: "Silicon Laboratories"},
  {max_prefix: "50-0A-9C",vendor: "Extreme Networks Headquarters"},
  {max_prefix: "54-08-D3",vendor: "Tianyi Telecom Terminals Company Limited"},
  {max_prefix: "4C-64-60",vendor: "ITEL MOBILE LIMITED"},
  {max_prefix: "E8-26-CF",vendor: "Shenzhen Jingxun Technology Co., Ltd."},
  {max_prefix: "04-ED-62",vendor: "Daikin Europe NV"},
  {max_prefix: "5C-14-EB",vendor: "Trident IoT"},
  {max_prefix: "AC-9F-C3",vendor: "Ring LLC"},
  {max_prefix: "10-98-5F",vendor: "Inventus Power Eletronica do Brasil LTDA"},
  {max_prefix: "FC-22-1C",vendor: "Shenzhen Xunman Technology Co., Ltd"},
  {max_prefix: "30-23-CD",vendor: "FUJIAN STAR-NET COMMUNICATION CO.,LTD"},
  {max_prefix: "38-D5-18",vendor: "GooWi Wireless Technology Co., Limited"},
  {max_prefix: "A4-6D-33",vendor: "Phyplus Technology (Shanghai) Co., Ltd"},
  {max_prefix: "BC-A0-B9",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "3C-59-C0",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "E8-1C-A5",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "00-FA-DE",vendor: "Motorola Mobility LLC, a Lenovo Company"},
  {max_prefix: "4C-92-D2",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "2C-B6-C2",vendor: "zte corporation"},
  {max_prefix: "94-2B-68",vendor: "Apple, Inc."},
  {max_prefix: "BC-80-4E",vendor: "Apple, Inc."},
  {max_prefix: "D8-74-75",vendor: "Apple, Inc."},
  {max_prefix: "8C-33-96",vendor: "Apple, Inc."},
  {max_prefix: "74-CC-40",vendor: "Apple, Inc."},
  {max_prefix: "3C-50-02",vendor: "Apple, Inc."},
  {max_prefix: "64-05-52",vendor: "China Post Communication Equipment Co.,"},
  {max_prefix: "8C-13-E2",vendor: "NETLINK ICT"},
  {max_prefix: "08-C7-B5",vendor: "Apple, Inc."},
  {max_prefix: "04-E3-C8",vendor: "FUJIAN STAR-NET COMMUNICATION CO.,LTD"},
  {max_prefix: "BC-C2-D9",vendor: "Private"},
  {max_prefix: "50-2E-66",vendor: "CLOUD NETWORK TECHNOLOGY SINGAPORE PTE. LTD."},
  {max_prefix: "9C-B1-50",vendor: "Intel Corporate"},
  {max_prefix: "24-EB-16",vendor: "Intel Corporate"},
  {max_prefix: "B8-8C-2B",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "B0-D1-D6",vendor: "Nokia Shanghai Bell Co., Ltd."},
  {max_prefix: "70-08-10",vendor: "Intel Corporate"},
  {max_prefix: "48-EA-62",vendor: "HP Inc."},
  {max_prefix: "E8-6E-AD",vendor: "Guangzhou Gizwits loT Technology Co.,Ltd"},
  {max_prefix: "00-1D-D4",vendor: "Commscope"},
  {max_prefix: "00-1D-D5",vendor: "Commscope"},
  {max_prefix: "00-1D-CF",vendor: "Commscope"},
  {max_prefix: "00-1D-D1",vendor: "Commscope"},
  {max_prefix: "9C-34-26",vendor: "Commscope"},
  {max_prefix: "00-15-CF",vendor: "Commscope"},
  {max_prefix: "E8-6D-52",vendor: "Commscope"},
  {max_prefix: "38-6B-BB",vendor: "Commscope"},
  {max_prefix: "50-03-CF",vendor: "CANON INC."},
  {max_prefix: "64-55-B1",vendor: "Commscope"},
  {max_prefix: "00-00-C5",vendor: "Commscope"},
  {max_prefix: "CC-A4-62",vendor: "Commscope"},
  {max_prefix: "44-E1-37",vendor: "Commscope"},
  {max_prefix: "14-CF-E2",vendor: "Commscope"},
  {max_prefix: "00-24-95",vendor: "Commscope"},
  {max_prefix: "74-F6-12",vendor: "Commscope"},
  {max_prefix: "6C-C1-D2",vendor: "Commscope"},
  {max_prefix: "14-5B-D1",vendor: "Commscope"},
  {max_prefix: "A8-11-FC",vendor: "Commscope"},
  {max_prefix: "94-E8-C5",vendor: "Commscope"},
  {max_prefix: "E4-83-99",vendor: "Commscope"},
  {max_prefix: "3C-75-4A",vendor: "Commscope"},
  {max_prefix: "00-24-A0",vendor: "Commscope"},
  {max_prefix: "00-20-40",vendor: "Commscope"},
  {max_prefix: "00-12-25",vendor: "Commscope"},
  {max_prefix: "00-90-9C",vendor: "Commscope"},
  {max_prefix: "00-17-84",vendor: "Commscope"},
  {max_prefix: "1C-14-48",vendor: "Commscope"},
  {max_prefix: "00-23-74",vendor: "Commscope"},
  {max_prefix: "00-21-43",vendor: "Commscope"},
  {max_prefix: "00-1E-46",vendor: "Commscope"},
  {max_prefix: "00-1C-11",vendor: "Commscope"},
  {max_prefix: "00-18-C0",vendor: "Commscope"},
  {max_prefix: "00-18-A4",vendor: "Commscope"},
  {max_prefix: "00-08-0E",vendor: "Commscope"},
  {max_prefix: "8C-61-A3",vendor: "Commscope"},
  {max_prefix: "88-71-B1",vendor: "Commscope"},
  {max_prefix: "F0-AF-85",vendor: "Commscope"},
  {max_prefix: "FC-AE-34",vendor: "Commscope"},
  {max_prefix: "B0-93-5B",vendor: "Commscope"},
  {max_prefix: "A8-9F-EC",vendor: "Commscope"},
  {max_prefix: "C0-A0-0D",vendor: "Commscope"},
  {max_prefix: "A4-98-13",vendor: "Commscope"},
  {max_prefix: "98-F7-81",vendor: "Commscope"},
  {max_prefix: "6C-63-9C",vendor: "Commscope"},
  {max_prefix: "2C-1D-B8",vendor: "Commscope"},
  {max_prefix: "E4-57-40",vendor: "Commscope"},
  {max_prefix: "90-9D-7D",vendor: "Commscope"},
  {max_prefix: "F8-F5-32",vendor: "Commscope"},
  {max_prefix: "B0-83-D6",vendor: "Commscope"},
  {max_prefix: "C8-52-61",vendor: "Commscope"},
  {max_prefix: "60-D2-48",vendor: "Commscope"},
  {max_prefix: "54-E2-E0",vendor: "Commscope"},
  {max_prefix: "70-85-C6",vendor: "Commscope"},
  {max_prefix: "00-1C-C3",vendor: "Commscope"},
  {max_prefix: "34-7A-60",vendor: "Commscope"},
  {max_prefix: "7C-26-34",vendor: "Commscope"},
  {max_prefix: "3C-F3-41",vendor: "Hosenso GmbH & Co. KG"},
  {max_prefix: "48-4E-FC",vendor: "Commscope"},
  {max_prefix: "58-60-D8",vendor: "Commscope"},
  {max_prefix: "50-A5-DC",vendor: "Commscope"},
  {max_prefix: "88-BD-09",vendor: "Netis Technology Co., Ltd."},
  {max_prefix: "FC-3F-FC",vendor: "Tozed Kangwei Tech Co.,Ltd"},
  {max_prefix: "B0-5D-D4",vendor: "Commscope"},
  {max_prefix: "A4-43-8C",vendor: "Commscope"},
  {max_prefix: "74-50-CD",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "FC-D2-02",vendor: "Realme Chongqing Mobile Telecommunications Corp.,Ltd."},
  {max_prefix: "A0-91-CA",vendor: "Nokia Solutions and Networks GmbH & Co. KG"},
  {max_prefix: "34-3E-25",vendor: "OHSUNG"},
  {max_prefix: "68-AA-C4",vendor: "Altice Labs"},
  {max_prefix: "A8-40-F8",vendor: "HUMAX NETWORKS"},
  {max_prefix: "EC-75-0C",vendor: "TP-Link Systems Inc."},
  {max_prefix: "58-D6-1F",vendor: "Ubiquiti Inc"},
  {max_prefix: "6C-63-F8",vendor: "Ubiquiti Inc"},
  {max_prefix: "0C-44-C0",vendor: "zte corporation"},
  {max_prefix: "28-DE-1C",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "18-6B-E2",vendor: "LYLINK LIMITED"},
  {max_prefix: "D8-C8-0C",vendor: "Tuya Smart Inc."},
  {max_prefix: "34-70-69",vendor: "Cisco Systems, Inc"},
  {max_prefix: "84-4D-4C",vendor: "Adtran Inc"},
  {max_prefix: "CC-12-28",vendor: "HISENSE VISUAL TECHNOLOGY CO.,LTD"},
  {max_prefix: "0C-ED-71",vendor: "Extreme Networks Headquarters"},
  {max_prefix: "3C-E8-6E",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "CC-CC-EA",vendor: "Phoenix Contact GmbH & Co. KG"},
  {max_prefix: "EC-74-CD",vendor: "IEEE Registration Authority"},
  {max_prefix: "0C-93-A5",vendor: "eero inc."},
  {max_prefix: "A8-74-1D",vendor: "Phoenix Contact GmbH & Co. KG"},
  {max_prefix: "1C-30-03",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "E0-E2-D1",vendor: "Beijing Netswift Technology Co.,Ltd."},
  {max_prefix: "98-BA-5F",vendor: "TP-Link Systems Inc."},
  {max_prefix: "F8-15-E0",vendor: "Siemens Canada"},
  {max_prefix: "F8-91-6F",vendor: "Texas Instruments"},
  {max_prefix: "08-D5-93",vendor: "Texas Instruments"},
  {max_prefix: "88-CF-CD",vendor: "Texas Instruments"},
  {max_prefix: "A0-0C-E2",vendor: "Shenzhen Shokz Co., Ltd."},
  {max_prefix: "58-FB-3E",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "C0-2C-ED",vendor: "Silicon Laboratories"},
  {max_prefix: "70-71-B3",vendor: "Brain Corporation"},
  {max_prefix: "38-18-2B",vendor: "Espressif Inc."},
  {max_prefix: "68-58-A0",vendor: "Honor Device Co., Ltd."},
  {max_prefix: "28-4D-92",vendor: "Luminator Technology Group Global LLC"},
  {max_prefix: "5C-A4-36",vendor: "Shenzhen G-world Technology Incorporated Company"},
  {max_prefix: "98-EE-94",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "20-99-52",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "A4-E8-8D",vendor: "FN-LINK TECHNOLOGY Ltd."},
  {max_prefix: "5C-61-52",vendor: "NXP Semiconductor (Tianjin) LTD."},
  {max_prefix: "74-76-1F",vendor: "Microsoft Corporation"},
  {max_prefix: "14-EA-63",vendor: "Hui Zhou Gaoshengda Technology Co.,LTD"},
  {max_prefix: "E8-E4-9D",vendor: "Nexthop Systems Inc."},
  {max_prefix: "A0-C7-D2",vendor: "Cisco Systems, Inc"},
  {max_prefix: "70-A7-03",vendor: "Honor Device Co., Ltd."},
  {max_prefix: "1C-D1-D7",vendor: "Hangzhou BroadLink Technology Co., Ltd"},
  {max_prefix: "08-8A-F1",vendor: "MERCUSYS TECHNOLOGIES CO., LTD."},
  {max_prefix: "04-BF-D5",vendor: "Apple, Inc."},
  {max_prefix: "44-A1-0E",vendor: "Apple, Inc."},
  {max_prefix: "7C-C8-DF",vendor: "Apple, Inc."},
  {max_prefix: "10-BA-1A",vendor: "SHENZHEN IK WORLD Technology Co., Ltd"},
  {max_prefix: "80-83-F6",vendor: "Apple, Inc."},
  {max_prefix: "60-3D-61",vendor: "Private"},
  {max_prefix: "54-3A-DF",vendor: "Qualfiber Technology Co.,Ltd"},
  {max_prefix: "98-03-8E",vendor: "TP-Link Systems Inc."},
  {max_prefix: "8C-93-8B",vendor: "Realme Chongqing Mobile Telecommunications Corp.,Ltd."},
  {max_prefix: "C8-AC-35",vendor: "PiLink Co., Ltd."},
  {max_prefix: "B4-10-7A",vendor: "Amazon Technologies Inc."},
  {max_prefix: "7C-DC-73",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "94-7A-F4",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "8C-A9-6D",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "5C-06-10",vendor: "Cisco Meraki"},
  {max_prefix: "70-68-71",vendor: "FN-LINK TECHNOLOGY Ltd."},
  {max_prefix: "F4-D4-54",vendor: "Shenzhen Skyworth Digital  Technology  CO., Ltd"},
  {max_prefix: "BC-3E-0B",vendor: "Panasonic Connect Co., Ltd."},
  {max_prefix: "B4-64-15",vendor: "Guangzhou V-Solution Telecommunication Technology Co.,Ltd."},
  {max_prefix: "30-BD-13",vendor: "Zyxel Communications Corporation"},
  {max_prefix: "70-1A-D5",vendor: "Avigilon Alta"},
  {max_prefix: "4C-B8-2C",vendor: "Cambridge Mobile Telematics, Inc."},
  {max_prefix: "04-3D-6E",vendor: "Nokia"},
  {max_prefix: "C8-D9-59",vendor: "Motorola Mobility LLC, a Lenovo Company"},
  {max_prefix: "9C-5A-8A",vendor: "DJI BAIWANG TECHNOLOGY CO LTD"},
  {max_prefix: "A8-44-AA",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "D8-7D-45",vendor: "Nicent Technology Co., Ltd."},
  {max_prefix: "D4-95-8E",vendor: "Universal Electronics, Inc."},
  {max_prefix: "74-FC-45",vendor: "SIEMENS AG"},
  {max_prefix: "D4-43-8A",vendor: "Beijing Xiaomi Mobile Software Co., Ltd"},
  {max_prefix: "90-10-57",vendor: "Intel Corporate"},
  {max_prefix: "C4-0F-08",vendor: "Intel Corporate"},
  {max_prefix: "58-18-62",vendor: "Sony Corporation"},
  {max_prefix: "14-70-57",vendor: "SHENNAN CIRCUITS CO.,LTD"},
  {max_prefix: "4C-CA-95",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "F0-5C-0E",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "20-0F-92",vendor: "STK Technology Co., Ltd."},
  {max_prefix: "40-C7-3C",vendor: "Intel Corporate"},
  {max_prefix: "78-5E-CC",vendor: "Apple, Inc."},
  {max_prefix: "88-02-64",vendor: "Pascal Audio"},
  {max_prefix: "A0-EE-EE",vendor: "CIG SHANGHAI CO LTD"},
  {max_prefix: "B4-59-76",vendor: "Apple, Inc."},
  {max_prefix: "DC-95-66",vendor: "Apple, Inc."},
  {max_prefix: "B8-45-EB",vendor: "Apple, Inc."},
  {max_prefix: "1C-61-BF",vendor: "Apple, Inc."},
  {max_prefix: "2C-FB-0F",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "BC-D5-ED",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "94-E7-F3",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "DC-9C-99",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "98-9D-39",vendor: "China Mobile Group Device Co.,Ltd."},
  {max_prefix: "4C-37-DE",vendor: "AltoBeam Inc."},
  {max_prefix: "34-05-57",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "4C-1F-86",vendor: "Hangzhou Hikvision Digital Technology Co.,Ltd."},
  {max_prefix: "68-D9-76",vendor: "EM Microelectronic"},
  {max_prefix: "84-E5-85",vendor: "EM Microelectronic"},
  {max_prefix: "9C-F1-D4",vendor: "Roku, Inc"},
  {max_prefix: "20-EF-BD",vendor: "Roku, Inc"},
  {max_prefix: "34-3C-30",vendor: "NXP Semiconductor (Tianjin) LTD."},
  {max_prefix: "84-6E-BC",vendor: "Nokia solutions and networks Pvt Ltd"},
  {max_prefix: "D4-6C-62",vendor: "MultiTracks.com, LLC"},
  {max_prefix: "9C-69-D3",vendor: "ASIX Electronics Corporation"},
  {max_prefix: "00-59-6C",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-D0-CE",vendor: "TASKING Labs"},
  {max_prefix: "38-16-5A",vendor: "zte corporation"},
  {max_prefix: "80-88-00",vendor: "zte corporation"},
  {max_prefix: "78-66-D7",vendor: "GENSTORAIGE TECHNOLOGY CO.LTD."},
  {max_prefix: "24-B5-F2",vendor: "Shanghai Ingeek Technology Co., Ltd"},
  {max_prefix: "F8-D0-C5",vendor: "Sector Alarm Tech S.L."},
  {max_prefix: "14-81-21",vendor: "TOP WING Corporation"},
  {max_prefix: "9C-DA-B7",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "8C-C5-73",vendor: "Xsight Labs LTD."},
  {max_prefix: "E0-28-0A",vendor: "TP-Link Systems Inc."},
  {max_prefix: "70-8B-97",vendor: "INSYS icom GmbH"},
  {max_prefix: "1C-71-26",vendor: "snom technology GmbH"},
  {max_prefix: "5C-8D-E5",vendor: "Delta Electronics, Inc."},
  {max_prefix: "EC-1B-CF",vendor: "Brain Technologies, Inc."},
  {max_prefix: "88-A2-9E",vendor: "Raspberry Pi (Trading) Ltd"},
  {max_prefix: "34-7E-5C",vendor: "Sonos, Inc."},
  {max_prefix: "00-04-7E",vendor: "TKH Security B.V."},
  {max_prefix: "00-0E-58",vendor: "Sonos, Inc."},
  {max_prefix: "28-7B-11",vendor: "Hui Zhou Gaoshengda Technology Co.,LTD"},
  {max_prefix: "68-B1-C9",vendor: "IYO, Inc"},
  {max_prefix: "00-4B-12",vendor: "Espressif Inc."},
  {max_prefix: "44-4A-37",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "40-5E-CF",vendor: "Esconet Technologies Limited"},
  {max_prefix: "6C-87-20",vendor: "New H3C Technologies Co., Ltd"},
  {max_prefix: "0C-8D-7A",vendor: "RADiflow"},
  {max_prefix: "FC-A9-F5",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "6C-03-70",vendor: "Extreme Networks Headquarters"},
  {max_prefix: "78-96-A3",vendor: "Extreme Networks Headquarters"},
  {max_prefix: "DC-E6-50",vendor: "Extreme Networks Headquarters"},
  {max_prefix: "24-1F-BD",vendor: "Extreme Networks Headquarters"},
  {max_prefix: "E4-44-E5",vendor: "Extreme Networks Headquarters"},
  {max_prefix: "40-88-2F",vendor: "Extreme Networks Headquarters"},
  {max_prefix: "40-18-B1",vendor: "Extreme Networks Headquarters"},
  {max_prefix: "20-6C-8A",vendor: "Extreme Networks Headquarters"},
  {max_prefix: "88-5B-DD",vendor: "Extreme Networks Headquarters"},
  {max_prefix: "34-85-84",vendor: "Extreme Networks Headquarters"},
  {max_prefix: "94-9B-2C",vendor: "Extreme Networks Headquarters"},
  {max_prefix: "A4-EA-8E",vendor: "Extreme Networks Headquarters"},
  {max_prefix: "B8-50-01",vendor: "Extreme Networks Headquarters"},
  {max_prefix: "14-14-4B",vendor: "Ruijie Networks Co.,LTD"},
  {max_prefix: "00-04-A5",vendor: "Barco NV"},
  {max_prefix: "AC-ED-32",vendor: "Extreme Networks Headquarters"},
  {max_prefix: "00-E6-0E",vendor: "Extreme Networks Headquarters"},
  {max_prefix: "44-D9-80",vendor: "EVERYBOT INC."},
  {max_prefix: "00-16-E6",vendor: "GIGA-BYTE TECHNOLOGY CO.,LTD."},
  {max_prefix: "D0-DC-2C",vendor: "Cisco Systems, Inc"},
  {max_prefix: "F8-3C-44",vendor: "SHENZHEN TRANSCHAN TECHNOLOGY LIMITED"},
  {max_prefix: "20-15-DE",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "18-52-3D",vendor: "Xiamen Jiwu Technology CO.,Ltd"},
  {max_prefix: "D8-43-EA",vendor: "SY Electronics Ltd"},
  {max_prefix: "D0-91-68",vendor: "SHENZHEN CHUANGWEI-RGB ELECTRONICS CO.,LTD"},
  {max_prefix: "D4-A3-8B",vendor: "ELE(GROUP)CO.,LTD"},
  {max_prefix: "54-13-CA",vendor: "ITEL MOBILE LIMITED"},
  {max_prefix: "10-E8-3A",vendor: "FIBERX DISTRIBUIDORA DE PRODUTOS DE TELECOMUNICACAO LTDA"},
  {max_prefix: "E8-C8-29",vendor: "Intel Corporate"},
  {max_prefix: "E0-4C-05",vendor: "EverCharge"},
  {max_prefix: "58-68-7A",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "80-48-2C",vendor: "Wyze Labs Inc"},
  {max_prefix: "88-5E-BD",vendor: "NCKOREA Co.,Ltd."},
  {max_prefix: "1C-60-66",vendor: "TEJAS NETWORKS LTD"},
  {max_prefix: "9C-89-1E",vendor: "FireBrick Ltd"},
  {max_prefix: "D0-F4-05",vendor: "Hon Hai Precision Industry Co., Ltd."},
  {max_prefix: "58-A2-E1",vendor: "Mellanox Technologies, Inc."},
  {max_prefix: "B0-CF-0E",vendor: "Mellanox Technologies, Inc."},
  {max_prefix: "88-09-AF",vendor: "Masimo Corporation"},
  {max_prefix: "B0-4A-B4",vendor: "Motorola Mobility LLC, a Lenovo Company"},
  {max_prefix: "C4-14-A2",vendor: "Cisco Meraki"},
  {max_prefix: "64-E7-38",vendor: "Zhejiang SUPCON Technology Co., Ltd."},
  {max_prefix: "5C-7D-F3",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "EC-04-82",vendor: "STL Systems AG"},
  {max_prefix: "E8-8D-A6",vendor: "Quectel Wireless Solutions Co.,Ltd."},
  {max_prefix: "3C-0A-F3",vendor: "CLOUD NETWORK TECHNOLOGY SINGAPORE PTE. LTD."},
  {max_prefix: "CC-E9-FA",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "64-66-D8",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "48-9A-5B",vendor: "Shenzhen iComm Semiconductor CO.,LTD"},
  {max_prefix: "9C-96-13",vendor: "Lenovo Future Communication Technology (Chongqing) Company Limited"},
  {max_prefix: "DC-F5-1B",vendor: "Arcadyan Corporation"},
  {max_prefix: "08-7B-87",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-0C-EC",vendor: "Safran Trusted 4D Inc."},
  {max_prefix: "58-D3-12",vendor: "zte corporation"},
  {max_prefix: "D8-09-7F",vendor: "zte corporation"},
  {max_prefix: "84-86-87",vendor: "weiyuantechnology"},
  {max_prefix: "00-E6-3A",vendor: "Ruckus Wireless"},
  {max_prefix: "44-E7-61",vendor: "Infinix mobility limited"},
  {max_prefix: "6C-68-A4",vendor: "Guangzhou V-Solution Telecommunication Technology Co.,Ltd."},
  {max_prefix: "44-B5-9C",vendor: "Tenet Networks Private Limited"},
  {max_prefix: "A4-36-C7",vendor: "LG Innotek"},
  {max_prefix: "38-F6-ED",vendor: "EVK DI Kerschhaggl GmbH"},
  {max_prefix: "D8-2D-40",vendor: "Janz - Contagem e Gest\u00e3o de Flu\u00eddos S.A."},
  {max_prefix: "00-39-69",vendor: "Air-Weigh Incorporated"},
  {max_prefix: "C0-7C-90",vendor: "Shenzhen YOUHUA Technology Co., Ltd"},
  {max_prefix: "94-F9-29",vendor: "Meta Platforms Technologies, LLC"},
  {max_prefix: "B8-C0-65",vendor: "Universal Electronics, Inc."},
  {max_prefix: "20-91-DF",vendor: "Apple, Inc."},
  {max_prefix: "A8-9C-78",vendor: "Apple, Inc."},
  {max_prefix: "7C-61-30",vendor: "Apple, Inc."},
  {max_prefix: "80-B9-89",vendor: "Apple, Inc."},
  {max_prefix: "C4-52-4F",vendor: "Apple, Inc."},
  {max_prefix: "60-3E-5F",vendor: "Apple, Inc."},
  {max_prefix: "D0-CE-C9",vendor: "HAN CHANG"},
  {max_prefix: "A4-BD-7E",vendor: "HMD Global Oy"},
  {max_prefix: "68-DA-73",vendor: "IEEE Registration Authority"},
  {max_prefix: "8C-D0-8B",vendor: "WuXi Rigosys Technology Co.,LTD"},
  {max_prefix: "44-32-C8",vendor: "Vantiva USA LLC"},
  {max_prefix: "08-95-2A",vendor: "Vantiva USA LLC"},
  {max_prefix: "FC-52-8D",vendor: "Vantiva USA LLC"},
  {max_prefix: "6C-55-E8",vendor: "Vantiva USA LLC"},
  {max_prefix: "88-9E-68",vendor: "Vantiva USA LLC"},
  {max_prefix: "F8-5E-42",vendor: "Vantiva USA LLC"},
  {max_prefix: "EC-93-7D",vendor: "Vantiva USA LLC"},
  {max_prefix: "7C-9A-54",vendor: "Vantiva USA LLC"},
  {max_prefix: "E0-37-17",vendor: "Vantiva USA LLC"},
  {max_prefix: "90-58-51",vendor: "Vantiva USA LLC"},
  {max_prefix: "08-7E-64",vendor: "Vantiva USA LLC"},
  {max_prefix: "10-33-BF",vendor: "Vantiva USA LLC"},
  {max_prefix: "00-65-1E",vendor: "Amcrest Technologies"},
  {max_prefix: "08-70-73",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "28-BE-9B",vendor: "Vantiva USA LLC"},
  {max_prefix: "D0-5A-00",vendor: "Vantiva USA LLC"},
  {max_prefix: "3C-9A-77",vendor: "Vantiva USA LLC"},
  {max_prefix: "D4-E2-CB",vendor: "Vantiva USA LLC"},
  {max_prefix: "5C-22-DA",vendor: "Vantiva USA LLC"},
  {max_prefix: "FC-C2-33",vendor: "ASUSTek COMPUTER INC."},
  {max_prefix: "00-EE-01",vendor: "Enablers Solucoes e Consultoria em Dispositivos"},
  {max_prefix: "80-F0-CF",vendor: "Ruckus Wireless"},
  {max_prefix: "68-DE-CE",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "FC-B4-67",vendor: "Espressif Inc."},
  {max_prefix: "D8-BC-38",vendor: "Espressif Inc."},
  {max_prefix: "38-31-5A",vendor: "Rinnai"},
  {max_prefix: "3C-19-CB",vendor: "TECNO MOBILE LIMITED"},
  {max_prefix: "FC-FA-21",vendor: "zte corporation"},
  {max_prefix: "D0-7C-B2",vendor: "Sigmastar Technology Ltd."},
  {max_prefix: "04-38-DC",vendor: "China Unicom Online Information Technology Co.,Ltd"},
  {max_prefix: "DC-BD-CC",vendor: "Quectel Wireless Solutions Co.,Ltd."},
  {max_prefix: "A8-00-E3",vendor: "Starkey Labs Inc."},
  {max_prefix: "B0-96-EA",vendor: "GD Midea Air-Conditioning Equipment Co.,Ltd."},
  {max_prefix: "64-00-9C",vendor: "Insulet Corporation"},
  {max_prefix: "60-56-99",vendor: "MAGNETI MARELLI   S.E. S.p.A."},
  {max_prefix: "A8-2C-3E",vendor: "Shenzhen Cultraview Digital Technology Co., Ltd"},
  {max_prefix: "D0-F9-28",vendor: "zte corporation"},
  {max_prefix: "38-92-2E",vendor: "ArrayComm"},
  {max_prefix: "18-6A-81",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "A0-7F-8A",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "2C-99-75",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "B0-F0-0C",vendor: "Dongguan Wecxw CO.,Ltd."},
  {max_prefix: "A8-FB-40",vendor: "Nokia Solutions and Networks GmbH & Co. KG"},
  {max_prefix: "A8-E5-39",vendor: "Nurivoice Co., Ltd"},
  {max_prefix: "A8-58-4E",vendor: "PK VEGA"},
  {max_prefix: "C4-7E-E0",vendor: "Cisco Systems, Inc"},
  {max_prefix: "10-91-D1",vendor: "Intel Corporate"},
  {max_prefix: "6C-4C-E2",vendor: "Intel Corporate"},
  {max_prefix: "1C-7C-98",vendor: "NEC Platforms, Ltd."},
  {max_prefix: "00-E0-4D",vendor: "INTERNET INITIATIVE JAPAN, INC"},
  {max_prefix: "74-3A-EF",vendor: "Kaon Group Co., Ltd."},
  {max_prefix: "F8-54-F6",vendor: "AzureWave Technology Inc."},
  {max_prefix: "08-74-58",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "C4-82-E1",vendor: "Tuya Smart Inc."},
  {max_prefix: "24-E4-CE",vendor: "Kaon Group Co., Ltd."},
  {max_prefix: "F0-C5-58",vendor: "U.D.Electronic Corp."},
  {max_prefix: "60-C9-AA",vendor: "Nokia"},
  {max_prefix: "E0-68-EE",vendor: "Phyplus Microelectronics Limited"},
  {max_prefix: "68-79-DD",vendor: "Omnipless Manufacturing (PTY) Ltd"},
  {max_prefix: "48-06-2B",vendor: "Private"},
  {max_prefix: "E8-76-40",vendor: "SKY UK LIMITED"},
  {max_prefix: "74-33-57",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "AC-00-F9",vendor: "BizLink Technology (S.E.A) Sdn. Bhd."},
  {max_prefix: "68-1A-7C",vendor: "Sichuan Tianyi Comheart Telecom Co.,LTD"},
  {max_prefix: "00-18-1C",vendor: "VITEC"},
  {max_prefix: "98-9F-1E",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "94-47-88",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "54-12-CB",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "98-FB-F5",vendor: "ATRALTECH"},
  {max_prefix: "90-39-5F",vendor: "Amazon Technologies Inc."},
  {max_prefix: "30-1D-49",vendor: "Firmus Technologies Pty Ltd"},
  {max_prefix: "40-14-82",vendor: "Cisco Systems, Inc"},
  {max_prefix: "80-07-94",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "F4-3C-3B",vendor: "HUNAN FN-LINK TECHNOLOGY LIMITED"},
  {max_prefix: "74-D7-CA",vendor: "Panasonic Automotive Systems Co.,Ltd"},
  {max_prefix: "D4-B6-80",vendor: "Shanghai Linkyum Microeletronics Co.,Ltd"},
  {max_prefix: "C8-13-37",vendor: "Juniper Networks"},
  {max_prefix: "AC-41-6A",vendor: "Amazon Technologies Inc."},
  {max_prefix: "8C-EE-FD",vendor: "zte corporation"},
  {max_prefix: "C0-49-43",vendor: "zte corporation"},
  {max_prefix: "58-CD-C9",vendor: "CLOUD NETWORK TECHNOLOGY SINGAPORE PTE. LTD."},
  {max_prefix: "BC-F4-99",vendor: "Rockwell Automation"},
  {max_prefix: "90-3C-1D",vendor: "HISENSE VISUAL TECHNOLOGY CO.,LTD"},
  {max_prefix: "24-6C-84",vendor: "Cisco Systems, Inc"},
  {max_prefix: "90-EB-50",vendor: "Cisco Systems, Inc"},
  {max_prefix: "60-95-32",vendor: "Zebra Technologies Inc."},
  {max_prefix: "D4-E9-5E",vendor: "Texas Instruments"},
  {max_prefix: "10-15-C1",vendor: "Zhanzuo (Beijing) Technology Co., Ltd."},
  {max_prefix: "8C-F3-E7",vendor: "solidotech"},
  {max_prefix: "90-A7-BF",vendor: "EM Microelectronic"},
  {max_prefix: "80-EE-25",vendor: "Shenzhen Skyworth Digital  Technology  CO., Ltd"},
  {max_prefix: "10-55-E4",vendor: "Shenzhen Skyworth Digital  Technology  CO., Ltd"},
  {max_prefix: "28-EF-01",vendor: "Amazon Technologies Inc."},
  {max_prefix: "D4-52-C7",vendor: "Beijing L&S Lancom Platform Tech. Co., Ltd."},
  {max_prefix: "84-88-E1",vendor: "Apple, Inc."},
  {max_prefix: "10-BD-3A",vendor: "Apple, Inc."},
  {max_prefix: "18-4A-53",vendor: "Apple, Inc."},
  {max_prefix: "10-9F-41",vendor: "Apple, Inc."},
  {max_prefix: "70-72-FE",vendor: "Apple, Inc."},
  {max_prefix: "2C-C2-53",vendor: "Apple, Inc."},
  {max_prefix: "B0-FB-15",vendor: "Laird Connectivity"},
  {max_prefix: "80-C4-5D",vendor: "IPG Laser GmbH"},
  {max_prefix: "68-F6-3B",vendor: "Amazon Technologies Inc."},
  {max_prefix: "68-85-A4",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "24-E8-E5",vendor: "Shenzhen Skyworth Digital  Technology  CO., Ltd"},
  {max_prefix: "04-AB-08",vendor: "Shenzhen Skyworth Digital  Technology  CO., Ltd"},
  {max_prefix: "E8-D5-2B",vendor: "Google, Inc."},
  {max_prefix: "CC-3A-DF",vendor: "Neptune Technology Group Inc."},
  {max_prefix: "68-EC-8A",vendor: "IKEA of Sweden AB"},
  {max_prefix: "11-11-11",vendor: "Private"},
  {max_prefix: "B4-56-FA",vendor: "IOPSYS Software Solutions"},
  {max_prefix: "E8-F8-D0",vendor: "Nokia Shanghai Bell Co., Ltd."},
  {max_prefix: "40-8E-2C",vendor: "Microsoft Corporation"},
  {max_prefix: "04-CE-09",vendor: "Shenzhen Skyworth Digital  Technology  CO., Ltd"},
  {max_prefix: "40-67-9B",vendor: "Shenzhen Skyworth Digital  Technology  CO., Ltd"},
  {max_prefix: "C0-68-CC",vendor: "Shenzhen Skyworth Digital  Technology  CO., Ltd"},
  {max_prefix: "28-BE-F3",vendor: "FUJIAN STAR-NET COMMUNICATION CO.,LTD"},
  {max_prefix: "E0-CB-19",vendor: "Nokia"},
  {max_prefix: "84-8F-69",vendor: "Dell Inc."},
  {max_prefix: "54-9F-35",vendor: "Dell Inc."},
  {max_prefix: "84-5A-3E",vendor: "Cisco Systems, Inc"},
  {max_prefix: "B0-21-6F",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "94-DF-34",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "74-30-AF",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "A4-4C-C8",vendor: "Dell Inc."},
  {max_prefix: "E4-F0-04",vendor: "Dell Inc."},
  {max_prefix: "20-04-0F",vendor: "Dell Inc."},
  {max_prefix: "D4-BE-D9",vendor: "Dell Inc."},
  {max_prefix: "EC-F4-BB",vendor: "Dell Inc."},
  {max_prefix: "B8-CA-3A",vendor: "Dell Inc."},
  {max_prefix: "B8-2A-72",vendor: "Dell Inc."},
  {max_prefix: "BC-30-5B",vendor: "Dell Inc."},
  {max_prefix: "00-0D-56",vendor: "Dell Inc."},
  {max_prefix: "00-1D-09",vendor: "Dell Inc."},
  {max_prefix: "00-23-AE",vendor: "Dell Inc."},
  {max_prefix: "14-C3-5E",vendor: "FibRSol Global Network Limited"},
  {max_prefix: "88-08-94",vendor: "Skullcandy"},
  {max_prefix: "90-2A-EE",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "40-FA-FE",vendor: "Motorola Mobility LLC, a Lenovo Company"},
  {max_prefix: "20-1C-3A",vendor: "Nintendo Co.,Ltd"},
  {max_prefix: "24-90-38",vendor: "Universal Biosensors Pty Ltd"},
  {max_prefix: "F8-ED-AE",vendor: "MOBIWIRE MOBILES(NINGBO) CO.,LTD"},
  {max_prefix: "D8-47-8F",vendor: "Microchip Technology Inc."},
  {max_prefix: "3C-0C-DB",vendor: "UNION MAN TECHNOLOGY CO.,LTD"},
  {max_prefix: "F4-D9-C6",vendor: "UNION MAN TECHNOLOGY CO.,LTD"},
  {max_prefix: "94-98-8F",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "F0-7B-65",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "C0-25-A5",vendor: "Dell Inc."},
  {max_prefix: "E8-B5-D0",vendor: "Dell Inc."},
  {max_prefix: "08-92-04",vendor: "Dell Inc."},
  {max_prefix: "70-B5-E8",vendor: "Dell Inc."},
  {max_prefix: "B8-CB-29",vendor: "Dell Inc."},
  {max_prefix: "34-73-5A",vendor: "Dell Inc."},
  {max_prefix: "8C-EC-4B",vendor: "Dell Inc."},
  {max_prefix: "54-48-10",vendor: "Dell Inc."},
  {max_prefix: "A8-99-69",vendor: "Dell Inc."},
  {max_prefix: "F4-02-70",vendor: "Dell Inc."},
  {max_prefix: "34-48-ED",vendor: "Dell Inc."},
  {max_prefix: "A8-C5-6F",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "AC-7A-94",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "04-DA-28",vendor: "Chongqing Zhouhai Intelligent Technology Co., Ltd"},
  {max_prefix: "0C-01-4B",vendor: "zte corporation"},
  {max_prefix: "74-D6-E5",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "5C-21-67",vendor: "Rockwell Automation"},
  {max_prefix: "20-F5-97",vendor: "Maasiv, LLC"},
  {max_prefix: "98-8C-B3",vendor: "Sichuan Tianyi Comheart Telecom Co.,LTD"},
  {max_prefix: "D8-E3-5E",vendor: "LG Innotek"},
  {max_prefix: "78-68-29",vendor: "eero inc."},
  {max_prefix: "14-A4-17",vendor: "Shenzhen Belon Technology CO.,LTD"},
  {max_prefix: "60-48-9C",vendor: "YIPPEE ELECTRONICS CO.,LIMITED"},
  {max_prefix: "34-AF-A3",vendor: "Recogni Inc"},
  {max_prefix: "DC-9E-AB",vendor: "Chongqing Yipingfang Technology Co., Ltd."},
  {max_prefix: "48-91-D5",vendor: "Cisco Systems, Inc"},
  {max_prefix: "6C-8F-4E",vendor: "Chongqing Yipingfang Technology Co., Ltd."},
  {max_prefix: "E0-1F-FC",vendor: "Motorola (Wuhan) Mobility Technologies Communication Co., Ltd."},
  {max_prefix: "CC-47-40",vendor: "AzureWave Technology Inc."},
  {max_prefix: "00-14-91",vendor: "Daniels Electronics Ltd. dba Codan Radio Communications"},
  {max_prefix: "20-0B-C5",vendor: "Cisco Systems, Inc"},
  {max_prefix: "90-E9-5E",vendor: "Cisco Systems, Inc"},
  {max_prefix: "28-57-BE",vendor: "Hangzhou Hikvision Digital Technology Co.,Ltd."},
  {max_prefix: "58-50-ED",vendor: "Hangzhou Hikvision Digital Technology Co.,Ltd."},
  {max_prefix: "10-12-FB",vendor: "Hangzhou Hikvision Digital Technology Co.,Ltd."},
  {max_prefix: "4C-F5-DC",vendor: "Hangzhou Hikvision Digital Technology Co.,Ltd."},
  {max_prefix: "98-8B-0A",vendor: "Hangzhou Hikvision Digital Technology Co.,Ltd."},
  {max_prefix: "80-7C-62",vendor: "Hangzhou Hikvision Digital Technology Co.,Ltd."},
  {max_prefix: "BC-9B-5E",vendor: "Hangzhou Hikvision Digital Technology Co.,Ltd."},
  {max_prefix: "20-29-B9",vendor: "Ikotek technology SH Co., Ltd"},
  {max_prefix: "30-4F-75",vendor: "DZS Inc."},
  {max_prefix: "D0-96-FB",vendor: "DZS Inc."},
  {max_prefix: "00-01-47",vendor: "DZS Inc."},
  {max_prefix: "00-02-71",vendor: "DZS Inc."},
  {max_prefix: "18-68-CB",vendor: "Hangzhou Hikvision Digital Technology Co.,Ltd."},
  {max_prefix: "BC-BA-C2",vendor: "Hangzhou Hikvision Digital Technology Co.,Ltd."},
  {max_prefix: "AC-CB-51",vendor: "Hangzhou Hikvision Digital Technology Co.,Ltd."},
  {max_prefix: "9C-65-FA",vendor: "AcSiP"},
  {max_prefix: "88-25-08",vendor: "Meta Platforms Technologies, LLC"},
  {max_prefix: "80-BE-AF",vendor: "Hangzhou Hikvision Digital Technology Co.,Ltd."},
  {max_prefix: "04-03-12",vendor: "Hangzhou Hikvision Digital Technology Co.,Ltd."},
  {max_prefix: "48-81-D4",vendor: "Ruijie Networks Co.,LTD"},
  {max_prefix: "34-CA-81",vendor: "New H3C Intelligence Terminal Co., Ltd."},
  {max_prefix: "28-0C-2D",vendor: "QUALVISION TECHNOLOGY CO.,LTD"},
  {max_prefix: "88-67-DC",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "C4-67-8B",vendor: "Alphabet Capital Sdn Bhd"},
  {max_prefix: "60-F0-4D",vendor: "Honor Device Co., Ltd."},
  {max_prefix: "5C-62-8B",vendor: "TP-Link Corporation Limited"},
  {max_prefix: "D4-3A-2F",vendor: "SHENZHEN MTC CO LTD"},
  {max_prefix: "60-03-0C",vendor: "Shenzhen YOUHUA Technology Co., Ltd"},
  {max_prefix: "40-41-0D",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "5C-8B-6B",vendor: "Amazon Technologies Inc."},
  {max_prefix: "C0-61-3D",vendor: "BioIntelliSense, Inc."},
  {max_prefix: "04-18-D6",vendor: "Ubiquiti Inc"},
  {max_prefix: "24-A4-3C",vendor: "Ubiquiti Inc"},
  {max_prefix: "00-19-E2",vendor: "Juniper Networks"},
  {max_prefix: "00-26-88",vendor: "Juniper Networks"},
  {max_prefix: "84-18-88",vendor: "Juniper Networks"},
  {max_prefix: "C8-2B-6B",vendor: "shenzhen worldelite electronics co., LTD"},
  {max_prefix: "18-14-AE",vendor: "Nokia"},
  {max_prefix: "44-D9-E7",vendor: "Ubiquiti Inc"},
  {max_prefix: "D0-21-F9",vendor: "Ubiquiti Inc"},
  {max_prefix: "70-A7-41",vendor: "Ubiquiti Inc"},
  {max_prefix: "B0-A8-6E",vendor: "Juniper Networks"},
  {max_prefix: "08-81-F4",vendor: "Juniper Networks"},
  {max_prefix: "E4-BC-AA",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "54-5D-D9",vendor: "EDISTEC"},
  {max_prefix: "40-4C-CA",vendor: "Espressif Inc."},
  {max_prefix: "30-7C-5E",vendor: "Juniper Networks"},
  {max_prefix: "F0-1C-2D",vendor: "Juniper Networks"},
  {max_prefix: "64-64-9B",vendor: "Juniper Networks"},
  {max_prefix: "00-90-69",vendor: "Juniper Networks"},
  {max_prefix: "80-AC-AC",vendor: "Juniper Networks"},
  {max_prefix: "0C-86-10",vendor: "Juniper Networks"},
  {max_prefix: "54-4B-8C",vendor: "Juniper Networks"},
  {max_prefix: "2C-21-31",vendor: "Juniper Networks"},
  {max_prefix: "9C-CC-83",vendor: "Juniper Networks"},
  {max_prefix: "44-AA-50",vendor: "Juniper Networks"},
  {max_prefix: "E8-B6-C2",vendor: "Juniper Networks"},
  {max_prefix: "C4-09-B7",vendor: "Juniper Networks"},
  {max_prefix: "74-E7-98",vendor: "Juniper Networks"},
  {max_prefix: "48-73-10",vendor: "Juniper Networks"},
  {max_prefix: "E4-FC-82",vendor: "Juniper Networks"},
  {max_prefix: "B8-C2-53",vendor: "Juniper Networks"},
  {max_prefix: "14-B3-A1",vendor: "Juniper Networks"},
  {max_prefix: "D4-99-6C",vendor: "Juniper Networks"},
  {max_prefix: "04-5C-6C",vendor: "Juniper Networks"},
  {max_prefix: "40-DE-AD",vendor: "Juniper Networks"},
  {max_prefix: "0C-59-9C",vendor: "Juniper Networks"},
  {max_prefix: "9C-D1-D0",vendor: "Guangzhou Ronsuo Electronic Technology Co.,Ltd"},
  {max_prefix: "3C-79-2B",vendor: "Dongguan Auklink TechnologyCo.,Ltd"},
  {max_prefix: "00-0E-51",vendor: "TECNA SpA"},
  {max_prefix: "C0-C7-0A",vendor: "Ruckus Wireless"},
  {max_prefix: "00-16-16",vendor: "BROWAN COMMUNICATIONS INCORPORATION"},
  {max_prefix: "B4-61-E9",vendor: "Sichuan AI-Link Technology Co., Ltd."},
  {max_prefix: "64-E2-04",vendor: "NTN Technical Service Corporation"},
  {max_prefix: "2C-98-11",vendor: "CLOUD NETWORK TECHNOLOGY SINGAPORE PTE. LTD."},
  {max_prefix: "90-D6-89",vendor: "Huahao Fangzhou Technology Co.,Ltd"},
  {max_prefix: "C0-8B-2A",vendor: "Cisco Systems, Inc"},
  {max_prefix: "2C-CA-75",vendor: "Robert Bosch GmbH AnP"},
  {max_prefix: "CC-D8-1F",vendor: "Maipu Communication Technology Co.,Ltd."},
  {max_prefix: "E0-D8-C4",vendor: "Qingdao Intelligent&Precise Electronics Co.,Ltd."},
  {max_prefix: "38-14-1B",vendor: "Secure Letter Inc."},
  {max_prefix: "BC-9E-2C",vendor: "China Mobile Group Device Co.,Ltd."},
  {max_prefix: "88-C6-E8",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "70-7C-E3",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "3C-6F-9B",vendor: "zte corporation"},
  {max_prefix: "5C-94-62",vendor: "Shenzhen Jiuzhou Electric Co.,LTD"},
  {max_prefix: "38-6F-6B",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "54-11-49",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "90-52-BF",vendor: "Sichuan Tianyi Comheart Telecom Co.,LTD"},
  {max_prefix: "88-1E-59",vendor: "Onion Corporation"},
  {max_prefix: "2C-00-2A",vendor: "Shenzhen TINNO Mobile Technology Corp."},
  {max_prefix: "FC-9C-A7",vendor: "Apple, Inc."},
  {max_prefix: "48-E1-5C",vendor: "Apple, Inc."},
  {max_prefix: "74-15-F5",vendor: "Apple, Inc."},
  {max_prefix: "48-A9-8A",vendor: "Routerboard.com"},
  {max_prefix: "E8-88-43",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "28-02-2E",vendor: "Apple, Inc."},
  {max_prefix: "AC-FC-82",vendor: "Shenzhen Sundray Technologies Company Limited"},
  {max_prefix: "70-5A-6F",vendor: "IEEE Registration Authority"},
  {max_prefix: "DC-A3-13",vendor: "Shenzhen Changjin Communication Technology Co.,Ltd"},
  {max_prefix: "18-26-54",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "1C-67-60",vendor: "Phonesuite"},
  {max_prefix: "04-32-01",vendor: "Broadcom Limited"},
  {max_prefix: "7C-17-79",vendor: "EM Microelectronic"},
  {max_prefix: "B0-4B-68",vendor: "NAKAYO Inc"},
  {max_prefix: "F8-6B-14",vendor: "Barrot Technology Co.,LTD"},
  {max_prefix: "70-46-98",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "78-08-4D",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "EC-AA-8F",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "68-9A-21",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "38-39-6C",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "50-66-E5",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "DC-77-94",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "D8-34-1C",vendor: "GD Midea Air-Conditioning Equipment Co.,Ltd."},
  {max_prefix: "00-18-7F",vendor: "ZODIANET"},
  {max_prefix: "CC-F8-26",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "70-B6-51",vendor: "Eight Sleep"},
  {max_prefix: "90-CE-B8",vendor: "Texas Instruments"},
  {max_prefix: "B4-BC-7C",vendor: "Texas Instruments"},
  {max_prefix: "F8-8A-5E",vendor: "Texas Instruments"},
  {max_prefix: "F4-33-1C",vendor: "Toast, Inc."},
  {max_prefix: "54-51-DE",vendor: "Cisco Systems, Inc"},
  {max_prefix: "4C-06-17",vendor: "Taicang T&W Electronics"},
  {max_prefix: "D4-9A-F6",vendor: "AzureWave Technology Inc."},
  {max_prefix: "AC-81-F3",vendor: "Nokia Corporation"},
  {max_prefix: "60-6E-41",vendor: "Barrot Technology Co.,LTD"},
  {max_prefix: "2C-18-09",vendor: "Apple, Inc."},
  {max_prefix: "FC-47-D8",vendor: "Apple, Inc."},
  {max_prefix: "E4-9D-73",vendor: "Edgecore Networks Corporation"},
  {max_prefix: "44-EB-2E",vendor: "ALPSALPINE CO,.LTD"},
  {max_prefix: "04-7F-0E",vendor: "Barrot Technology Co.,LTD"},
  {max_prefix: "D4-01-45",vendor: "ATW TECHNOLOGY, INC."},
  {max_prefix: "A8-F8-C9",vendor: "NXP Semiconductor (Tianjin) LTD."},
  {max_prefix: "94-72-0F",vendor: "Guangdong Nanguang Photo&Video Systems Co., Ltd."},
  {max_prefix: "64-68-76",vendor: "Edifier International"},
  {max_prefix: "7C-64-6C",vendor: "LG Electronics"},
  {max_prefix: "48-C1-EE",vendor: "Honor Device Co., Ltd."},
  {max_prefix: "DC-B3-47",vendor: "SHENZHEN FAST TECHNOLOGIES CO.,LTD"},
  {max_prefix: "60-29-2B",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "A4-86-AE",vendor: "Quectel Wireless Solutions Co.,Ltd."},
  {max_prefix: "58-D3-91",vendor: "Quectel Wireless Solutions Co.,Ltd."},
  {max_prefix: "00-24-AC",vendor: "Hangzhou DPtech Technologies Co., Ltd."},
  {max_prefix: "CC-08-7B",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "5C-64-F3",vendor: "sywinkey HongKong Co,. Limited?"},
  {max_prefix: "28-E4-24",vendor: "New H3C Technologies Co., Ltd"},
  {max_prefix: "50-80-4A",vendor: "Quectel Wireless Solutions Co.,Ltd."},
  {max_prefix: "60-15-21",vendor: "Redarc Electronics"},
  {max_prefix: "E4-26-D5",vendor: "Motorola Mobility LLC, a Lenovo Company"},
  {max_prefix: "FC-3F-A6",vendor: "eero inc."},
  {max_prefix: "E0-4B-41",vendor: "Hangzhou Beilian Low Carbon Technology Co., Ltd."},
  {max_prefix: "8C-8E-0D",vendor: "zte corporation"},
  {max_prefix: "9C-88-24",vendor: "PetroCloud LLC"},
  {max_prefix: "5C-2F-AF",vendor: "HomeWizard B.V."},
  {max_prefix: "70-47-77",vendor: "Ruckus Wireless"},
  {max_prefix: "00-06-8F",vendor: "Telemonitor, Inc."},
  {max_prefix: "04-00-67",vendor: "Stanley Black & Decker"},
  {max_prefix: "78-30-F5",vendor: "TBT Inc."},
  {max_prefix: "88-AF-7B",vendor: "Nanjing Powercore Tech Co.,Ltd"},
  {max_prefix: "C4-7B-80",vendor: "Protempis, LLC"},
  {max_prefix: "00-09-0F",vendor: "Fortinet, Inc."},
  {max_prefix: "00-0C-E6",vendor: "Fortinet, Inc."},
  {max_prefix: "A0-24-F9",vendor: "Chengdu InnovaTest Technology Co., Ltd"},
  {max_prefix: "E0-B9-8A",vendor: "Shenzhen Taike industrial automation company,Ltd"},
  {max_prefix: "A8-EE-6D",vendor: "Fine Point-High Export"},
  {max_prefix: "54-C6-FF",vendor: "New H3C Technologies Co., Ltd"},
  {max_prefix: "F4-E9-75",vendor: "New H3C Technologies Co., Ltd"},
  {max_prefix: "D4-D4-DA",vendor: "Espressif Inc."},
  {max_prefix: "38-64-07",vendor: "Qingdao Intelligent&Precise Electronics Co.,Ltd."},
  {max_prefix: "1C-63-A5",vendor: "securityplatform"},
  {max_prefix: "C4-E5-B1",vendor: "Suzhou PanKore Integrated Circuit Technology Co. Ltd."},
  {max_prefix: "AC-C4-A9",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "7C-94-9F",vendor: "Shenzhen iComm Semiconductor CO.,LTD"},
  {max_prefix: "3C-2C-A6",vendor: "Beijing Xiaomi Electronics Co.,Ltd"},
  {max_prefix: "34-F2-23",vendor: "Fujian Newland Communication Science Technology Co.,Ltd."},
  {max_prefix: "28-0A-EE",vendor: "Renesas Electronics (Penang) Sdn. Bhd."},
  {max_prefix: "70-AE-D5",vendor: "Apple, Inc."},
  {max_prefix: "4C-22-F3",vendor: "Arcadyan Corporation"},
  {max_prefix: "20-0C-86",vendor: "GX India Pvt Ltd"},
  {max_prefix: "88-B8-6F",vendor: "Infinix mobility limited"},
  {max_prefix: "C4-EB-43",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "C4-EB-41",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "74-3E-39",vendor: "YUSUR Technology Co., Ltd."},
  {max_prefix: "4C-74-A7",vendor: "IEEE Registration Authority"},
  {max_prefix: "B8-D4-BC",vendor: "zte corporation"},
  {max_prefix: "38-53-19",vendor: "34ED LLC DBA Centegix"},
  {max_prefix: "84-60-82",vendor: "Hyperloop Technologies, Inc dba Virgin Hyperloop"},
  {max_prefix: "F0-4F-7C",vendor: "Amazon Technologies Inc."},
  {max_prefix: "F0-A2-25",vendor: "Amazon Technologies Inc."},
  {max_prefix: "D4-3A-2C",vendor: "Google, Inc."},
  {max_prefix: "E8-AA-CB",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "C4-AA-C4",vendor: "Zhejiang Dahua Technology Co., Ltd."},
  {max_prefix: "BC-D0-EB",vendor: "New H3C Technologies Co., Ltd"},
  {max_prefix: "60-58-01",vendor: "Shandong ZTop Microelectronics Co., Ltd."},
  {max_prefix: "D8-63-0D",vendor: "Motorola (Wuhan) Mobility Technologies Communication Co., Ltd."},
  {max_prefix: "9C-63-5B",vendor: "zte corporation"},
  {max_prefix: "14-61-A4",vendor: "Honor Device Co., Ltd."},
  {max_prefix: "D0-96-86",vendor: "IEEE Registration Authority"},
  {max_prefix: "94-D8-6B",vendor: "nass magnet Hung\u00e1ria Kft."},
  {max_prefix: "38-A9-EA",vendor: "HK DAPU ELECTRONIC TECHNOLOGY CO., LIMITED"},
  {max_prefix: "E8-EF-22",vendor: "Siemens Numerical Control Ltd., Nanjing"},
  {max_prefix: "78-6C-84",vendor: "Amazon Technologies Inc."},
  {max_prefix: "94-BE-09",vendor: "China Mobile Group Device Co.,Ltd."},
  {max_prefix: "E0-75-26",vendor: "China Dragon Technology Limited"},
  {max_prefix: "E4-8E-10",vendor: "CIG SHANGHAI CO LTD"},
  {max_prefix: "64-DB-F7",vendor: "Nokia Shanghai Bell Co., Ltd."},
  {max_prefix: "3C-A2-C3",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "5C-47-5E",vendor: "Ring LLC"},
  {max_prefix: "54-10-2E",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "94-D5-4D",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "24-E5-0F",vendor: "Google, Inc."},
  {max_prefix: "C0-91-B9",vendor: "Amazon Technologies Inc."},
  {max_prefix: "70-28-04",vendor: "Realme Chongqing Mobile Telecommunications Corp.,Ltd."},
  {max_prefix: "F8-07-5D",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "98-22-6E",vendor: "Amazon Technologies Inc."},
  {max_prefix: "F8-A4-FB",vendor: "Nanjing Decowell Automation Co.,LTD"},
  {max_prefix: "24-F0-D3",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "58-20-71",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "68-49-92",vendor: "Cisco Meraki"},
  {max_prefix: "F8-9B-6E",vendor: "Nokia Solutions and Networks GmbH & Co. KG"},
  {max_prefix: "14-DC-51",vendor: "Xiamen Cheerzing IOT Technology Co.,Ltd."},
  {max_prefix: "04-33-1F",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "D4-BA-BA",vendor: "IEEE Registration Authority"},
  {max_prefix: "9C-D8-E3",vendor: "Wuhan Huazhong Numerical Control Co., Ltd"},
  {max_prefix: "E0-C2-64",vendor: "Intel Corporate"},
  {max_prefix: "24-DC-0F",vendor: "Phytium Technology Co.,Ltd."},
  {max_prefix: "7C-2F-80",vendor: "Gigaset Communications GmbH"},
  {max_prefix: "60-C7-BE",vendor: "Realme Chongqing Mobile Telecommunications Corp.,Ltd."},
  {max_prefix: "04-04-B8",vendor: "China Hualu Panasonic AVC Networks Co., LTD."},
  {max_prefix: "40-C2-BA",vendor: "COMPAL INFORMATION (KUNSHAN) CO., LTD."},
  {max_prefix: "18-D6-DD",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "FC-18-03",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "68-96-2E",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "CC-3D-D1",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "54-C4-5B",vendor: "Arcadyan Corporation"},
  {max_prefix: "80-BC-37",vendor: "Ruckus Wireless"},
  {max_prefix: "E0-01-A6",vendor: "Edgecore Networks Corporation"},
  {max_prefix: "20-36-26",vendor: "TP-Link Corporation Limited"},
  {max_prefix: "C0-51-5C",vendor: "zte corporation"},
  {max_prefix: "BC-89-A7",vendor: "Apple, Inc."},
  {max_prefix: "30-B0-EA",vendor: "Shenzhen Chuangxin Internet Communication Technology Co., Ltd"},
  {max_prefix: "4C-FC-22",vendor: "SHANGHAI HI-TECH CONTROL SYSTEM CO.,LTD."},
  {max_prefix: "E4-EC-E8",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "C8-90-8A",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "40-33-06",vendor: "Taicang T&W Electronics"},
  {max_prefix: "00-0B-F8",vendor: "Infinera, Inc."},
  {max_prefix: "78-3E-A1",vendor: "Nokia Shanghai Bell Co., Ltd."},
  {max_prefix: "7C-50-DA",vendor: "E.J Ward"},
  {max_prefix: "A4-1C-B4",vendor: "DFI Inc"},
  {max_prefix: "40-1A-58",vendor: "Wistron Neweb Corporation"},
  {max_prefix: "2C-F2-A5",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "74-08-DE",vendor: "Fujian Landi Commercial Technology Co., Ltd."},
  {max_prefix: "10-FC-33",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "94-3C-96",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "70-B7-E4",vendor: "Broadcom Limited"},
  {max_prefix: "14-23-F2",vendor: "Broadcom Limited"},
  {max_prefix: "B0-81-01",vendor: "Honor Device Co., Ltd."},
  {max_prefix: "48-5D-ED",vendor: "Sichuan Tianyi Comheart Telecom Co.,LTD"},
  {max_prefix: "5C-FC-6E",vendor: "Sichuan Tianyi Comheart Telecom Co.,LTD"},
  {max_prefix: "7C-DC-CC",vendor: "BEIJING STARBLAZE TECHNOLOGY CO.,LTD"},
  {max_prefix: "34-47-9A",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "94-AD-23",vendor: "Apple, Inc."},
  {max_prefix: "20-A5-CB",vendor: "Apple, Inc."},
  {max_prefix: "F4-21-CA",vendor: "Apple, Inc."},
  {max_prefix: "3C-02-68",vendor: "Infinera, Inc."},
  {max_prefix: "CC-5E-F8",vendor: "CLOUD NETWORK TECHNOLOGY SINGAPORE PTE. LTD."},
  {max_prefix: "FC-12-63",vendor: "ASKEY COMPUTER CORP"},
  {max_prefix: "58-30-5B",vendor: "Shanghai Junqian Sensing Technology Co.,LTD"},
  {max_prefix: "A0-31-31",vendor: "Procenne Digital Security"},
  {max_prefix: "C0-71-AA",vendor: "ShenZhen OnMicro Electronics Co.,Ltd."},
  {max_prefix: "70-9C-45",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "FC-07-36",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "94-A0-7D",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "5C-07-58",vendor: "Ufispace Co., LTD."},
  {max_prefix: "14-2D-79",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "84-F4-4C",vendor: "International Integrated Systems., Inc."},
  {max_prefix: "58-9A-3E",vendor: "Amazon Technologies Inc."},
  {max_prefix: "E8-38-A0",vendor: "Vizio, Inc"},
  {max_prefix: "EC-C0-7A",vendor: "Laird Connectivity"},
  {max_prefix: "C8-1E-C2",vendor: "ITEL MOBILE LIMITED"},
  {max_prefix: "68-83-CB",vendor: "Apple, Inc."},
  {max_prefix: "68-B9-C2",vendor: "Earda Technologies co Ltd"},
  {max_prefix: "88-B8-63",vendor: "HISENSE VISUAL TECHNOLOGY CO.,LTD"},
  {max_prefix: "1C-46-D1",vendor: "SKY UK LIMITED"},
  {max_prefix: "00-3F-10",vendor: "Shenzhen GainStrong Technology Co., Ltd."},
  {max_prefix: "88-22-B2",vendor: "Chipsea Technologies (Shenzhen) Corp."},
  {max_prefix: "48-02-AF",vendor: "Telit Communication s.p.a"},
  {max_prefix: "20-04-F3",vendor: "Honor Device Co., Ltd."},
  {max_prefix: "38-E7-C0",vendor: "Hui Zhou Gaoshengda Technology Co.,LTD"},
  {max_prefix: "F0-C1-CE",vendor: "GoodWe Technologies CO., Ltd"},
  {max_prefix: "90-6A-EB",vendor: "Microsoft Corporation"},
  {max_prefix: "C4-EB-39",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "00-52-C8",vendor: "Made Studio Design Ltd."},
  {max_prefix: "58-2B-0A",vendor: "Texas Instruments"},
  {max_prefix: "08-9D-F4",vendor: "Intel Corporate"},
  {max_prefix: "DC-46-28",vendor: "Intel Corporate"},
  {max_prefix: "0C-91-92",vendor: "Intel Corporate"},
  {max_prefix: "48-AD-9A",vendor: "Intel Corporate"},
  {max_prefix: "D8-E8-44",vendor: "zte corporation"},
  {max_prefix: "E4-6A-35",vendor: "Realme Chongqing Mobile Telecommunications Corp.,Ltd."},
  {max_prefix: "68-65-B7",vendor: "Zhishang Chuanglian Technology Co., Ltd"},
  {max_prefix: "9C-05-91",vendor: "Mellanox Technologies, Inc."},
  {max_prefix: "E4-B5-55",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "28-77-B1",vendor: "Tri plus grupa d.o.o."},
  {max_prefix: "40-5E-F6",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "94-52-44",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "9C-2E-7A",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "7C-63-05",vendor: "Amazon Technologies Inc."},
  {max_prefix: "D8-7E-6F",vendor: "CASCINATION AG"},
  {max_prefix: "04-4F-7A",vendor: "China Mobile Group Device Co.,Ltd."},
  {max_prefix: "FC-C7-37",vendor: "Shaanxi Gangsion Electronic Technology Co., Ltd"},
  {max_prefix: "94-28-6F",vendor: "zte corporation"},
  {max_prefix: "40-0E-F3",vendor: "zte corporation"},
  {max_prefix: "74-3A-F4",vendor: "Intel Corporate"},
  {max_prefix: "68-7A-64",vendor: "Intel Corporate"},
  {max_prefix: "BC-03-58",vendor: "Intel Corporate"},
  {max_prefix: "54-31-D4",vendor: "TGW Mechanics GmbH"},
  {max_prefix: "E0-16-B1",vendor: "Advanced Design Technology co.,ltd."},
  {max_prefix: "AC-60-6F",vendor: "Nokia Shanghai Bell Co., Ltd."},
  {max_prefix: "C4-3C-B0",vendor: "SHENZHEN BILIAN ELECTRONIC CO.\uff0cLTD"},
  {max_prefix: "C0-69-11",vendor: "Arista Networks"},
  {max_prefix: "94-01-AC",vendor: "Wuhan Qianyang Iotian Technology Co., Ltd"},
  {max_prefix: "2C-93-FB",vendor: "Sercomm France Sarl"},
  {max_prefix: "78-44-FD",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "B4-46-6B",vendor: "REALTIMEID AS"},
  {max_prefix: "58-C5-7E",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "24-18-C0",vendor: "E. Wehrle GmbH"},
  {max_prefix: "00-21-D0",vendor: "Global Display Solutions Spa"},
  {max_prefix: "4C-31-2D",vendor: "Sichuan AI-Link Technology Co., Ltd."},
  {max_prefix: "C0-4E-30",vendor: "Espressif Inc."},
  {max_prefix: "AC-15-A2",vendor: "TP-Link Corporation Limited"},
  {max_prefix: "B8-B4-09",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "34-CF-6C",vendor: "Hangzhou Taili wireless communication equipment Co.,Ltd"},
  {max_prefix: "48-9B-E0",vendor: "Realme Chongqing Mobile Telecommunications Corp.,Ltd."},
  {max_prefix: "AC-DF-9F",vendor: "Arcadyan Corporation"},
  {max_prefix: "B8-59-C8",vendor: "70mai Co.,Ltd."},
  {max_prefix: "C8-24-96",vendor: "Jiangsu Yinhe  Electronics Co.,Ltd."},
  {max_prefix: "E0-F6-78",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "28-F7-D6",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "A8-E2-07",vendor: "GOIP Global Services Pvt. Ltd."},
  {max_prefix: "D4-E2-2F",vendor: "Roku, Inc"},
  {max_prefix: "5C-FA-25",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "F8-CD-C8",vendor: "Sichuan Tianyi Comheart Telecom Co.,LTD"},
  {max_prefix: "40-22-30",vendor: "Shenzhen SuperElectron Technology Co.,Ltd."},
  {max_prefix: "14-5B-B9",vendor: "ConMet"},
  {max_prefix: "08-30-CE",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "14-13-0B",vendor: "Garmin International"},
  {max_prefix: "90-23-5B",vendor: "Amazon Technologies Inc."},
  {max_prefix: "1C-BC-EC",vendor: "silex technology, Inc."},
  {max_prefix: "64-D8-14",vendor: "Cisco Systems, Inc"},
  {max_prefix: "7C-11-CD",vendor: "QianTang Technology"},
  {max_prefix: "44-7F-77",vendor: "Connected Home"},
  {max_prefix: "B0-28-5B",vendor: "JUHUA Technology Inc."},
  {max_prefix: "E0-6A-05",vendor: "Shenzhen YOUHUA Technology Co., Ltd"},
  {max_prefix: "90-CD-1F",vendor: "Quectel Wireless Solutions Co.,Ltd."},
  {max_prefix: "74-97-79",vendor: "CLOUD NETWORK TECHNOLOGY SINGAPORE PTE. LTD."},
  {max_prefix: "40-3B-7B",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "F0-B6-61",vendor: "eero inc."},
  {max_prefix: "04-25-F0",vendor: "Nokia"},
  {max_prefix: "48-57-D2",vendor: "Broadcom Limited"},
  {max_prefix: "9C-21-83",vendor: "Broadcom Limited"},
  {max_prefix: "D8-34-EE",vendor: "SHURE INCORPORATED"},
  {max_prefix: "94-C5-A6",vendor: "ITEL MOBILE LIMITED"},
  {max_prefix: "5C-E9-1E",vendor: "Apple, Inc."},
  {max_prefix: "88-03-4C",vendor: "WEIFANG GOERTEK ELECTRONICS CO.,LTD"},
  {max_prefix: "04-7C-16",vendor: "Micro-Star INTL CO., LTD."},
  {max_prefix: "00-17-94",vendor: "Cisco Systems, Inc"},
  {max_prefix: "F4-CE-46",vendor: "Hewlett Packard"},
  {max_prefix: "E0-D7-38",vendor: "WireStar Networks"},
  {max_prefix: "C8-EB-EC",vendor: "Shenzhen YOUHUA Technology Co., Ltd"},
  {max_prefix: "40-42-44",vendor: "Cisco Systems, Inc"},
  {max_prefix: "04-B6-BE",vendor: "CIG SHANGHAI CO LTD"},
  {max_prefix: "7C-EC-B1",vendor: "Apple, Inc."},
  {max_prefix: "C0-ED-E5",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "10-A5-62",vendor: "Iton Technology Corp."},
  {max_prefix: "FC-B9-7E",vendor: "GE Appliances"},
  {max_prefix: "30-E8-E4",vendor: "Qorvo International Pte. Ltd."},
  {max_prefix: "28-12-93",vendor: "Honor Device Co., Ltd."},
  {max_prefix: "C0-A9-38",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "48-DC-9D",vendor: "Grandprint(Beijing) Technology Co., LTD."},
  {max_prefix: "64-FD-96",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "64-98-9E",vendor: "TRINNOV AUDIO"},
  {max_prefix: "BC-C7-46",vendor: "Hon Hai Precision IND.CO.,LTD"},
  {max_prefix: "F0-D4-15",vendor: "Intel Corporate"},
  {max_prefix: "D4-D8-53",vendor: "Intel Corporate"},
  {max_prefix: "64-31-72",vendor: "ZHEJIANG HISING TECHNOLOGY CO.,LTD"},
  {max_prefix: "D0-FC-D0",vendor: "HUMAX Co., Ltd."},
  {max_prefix: "20-FA-DB",vendor: "Huahao Kunpeng Technology (chengDu)  Co.,Ltd."},
  {max_prefix: "0C-8B-95",vendor: "Espressif Inc."},
  {max_prefix: "9C-BF-CD",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "B8-9F-CC",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "34-25-BE",vendor: "Amazon Technologies Inc."},
  {max_prefix: "9C-E0-41",vendor: "Nokia"},
  {max_prefix: "78-34-86",vendor: "Nokia"},
  {max_prefix: "68-5E-1C",vendor: "Texas Instruments"},
  {max_prefix: "38-AB-41",vendor: "Texas Instruments"},
  {max_prefix: "CC-47-92",vendor: "ASIX Electronics Corporation"},
  {max_prefix: "E0-46-EE",vendor: "NETGEAR"},
  {max_prefix: "58-76-AC",vendor: "SERNET (SUZHOU) TECHNOLOGIES CORPORATION"},
  {max_prefix: "9C-4F-5F",vendor: "Google, Inc."},
  {max_prefix: "A0-29-42",vendor: "Intel Corporate"},
  {max_prefix: "10-71-B3",vendor: "Zyxel Communications Corporation"},
  {max_prefix: "DC-BE-49",vendor: "ITEL MOBILE LIMITED"},
  {max_prefix: "9C-95-61",vendor: "Hui Zhou Gaoshengda Technology Co.,LTD"},
  {max_prefix: "E0-03-6B",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "18-69-D4",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "04-63-D0",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "14-44-8F",vendor: "Edgecore Networks Corporation"},
  {max_prefix: "50-C1-F0",vendor: "NXP Semiconductor (Tianjin) LTD."},
  {max_prefix: "F4-84-8D",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "58-11-22",vendor: "ASUSTek COMPUTER INC."},
  {max_prefix: "74-69-4A",vendor: "Sichuan Tianyi Comheart Telecom Co.,LTD"},
  {max_prefix: "00-B0-EE",vendor: "Ajile Systems, Inc."},
  {max_prefix: "50-DC-D0",vendor: "Observint Technologies, Inc."},
  {max_prefix: "78-15-2D",vendor: "UNION CHIP TECHNOLOGY LIMITED"},
  {max_prefix: "00-A5-54",vendor: "Intel Corporate"},
  {max_prefix: "D4-F0-EA",vendor: "Beijing Xiaomi Mobile Software Co., Ltd"},
  {max_prefix: "0C-86-29",vendor: "IEEE Registration Authority"},
  {max_prefix: "98-A9-2D",vendor: "New H3C Technologies Co., Ltd"},
  {max_prefix: "94-AB-FE",vendor: "Nokia"},
  {max_prefix: "5C-24-E2",vendor: "Suzhou Denbom Electronic S&T Co., Ltd"},
  {max_prefix: "90-DF-7D",vendor: "Realme Chongqing Mobile Telecommunications Corp.,Ltd."},
  {max_prefix: "BC-1D-89",vendor: "Motorola Mobility LLC, a Lenovo Company"},
  {max_prefix: "E4-73-05",vendor: "Shenzhen INVT Electric CO.,Ltd"},
  {max_prefix: "A8-54-A2",vendor: "Heimgard Technologies AS"},
  {max_prefix: "B8-7E-E5",vendor: "Intelbras"},
  {max_prefix: "60-BE-B4",vendor: "S-Bluetech co., limited"},
  {max_prefix: "B4-B7-42",vendor: "Amazon Technologies Inc."},
  {max_prefix: "78-A0-3F",vendor: "Amazon Technologies Inc."},
  {max_prefix: "50-46-AE",vendor: "MERCURY CORPORATION"},
  {max_prefix: "B0-99-D7",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "30-F7-0D",vendor: "Cisco Systems, Inc"},
  {max_prefix: "74-2A-8A",vendor: "shenzhen worldelite electronics co., LTD"},
  {max_prefix: "A0-69-D9",vendor: "New H3C Technologies Co., Ltd"},
  {max_prefix: "20-FE-00",vendor: "Amazon Technologies Inc."},
  {max_prefix: "F4-21-AE",vendor: "Shanghai Xiaodu Technology Limited"},
  {max_prefix: "74-74-46",vendor: "Google, Inc."},
  {max_prefix: "78-20-A5",vendor: "Nintendo Co.,Ltd"},
  {max_prefix: "20-78-CD",vendor: "Apple, Inc."},
  {max_prefix: "30-D5-3E",vendor: "Apple, Inc."},
  {max_prefix: "F4-12-FA",vendor: "Espressif Inc."},
  {max_prefix: "B0-F2-08",vendor: "AVM Audiovisuelles Marketing und Computersysteme GmbH"},
  {max_prefix: "F0-B2-B9",vendor: "Intel Corporate"},
  {max_prefix: "D0-88-0C",vendor: "Apple, Inc."},
  {max_prefix: "1C-57-DC",vendor: "Apple, Inc."},
  {max_prefix: "60-4F-5B",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "DC-99-FE",vendor: "Armatura LLC"},
  {max_prefix: "9C-F1-55",vendor: "Nokia"},
  {max_prefix: "50-23-A2",vendor: "Apple, Inc."},
  {max_prefix: "98-69-8A",vendor: "Apple, Inc."},
  {max_prefix: "78-FB-D8",vendor: "Apple, Inc."},
  {max_prefix: "A4-C3-37",vendor: "Apple, Inc."},
  {max_prefix: "B0-F1-D8",vendor: "Apple, Inc."},
  {max_prefix: "B0-1B-4B",vendor: "Invisible Fun Studio Limited"},
  {max_prefix: "48-74-12",vendor: "OnePlus Technology (Shenzhen) Co., Ltd"},
  {max_prefix: "A8-E6-21",vendor: "Amazon Technologies Inc."},
  {max_prefix: "3C-CF-B4",vendor: "Telink Semiconductor (Shanghai) Co., Ltd."},
  {max_prefix: "04-3C-E8",vendor: "Shenzhen SuperElectron Technology Co.,Ltd."},
  {max_prefix: "24-81-3B",vendor: "Cisco Systems, Inc"},
  {max_prefix: "0C-B8-E8",vendor: "Renesas Electronics (Penang) Sdn. Bhd."},
  {max_prefix: "F0-A6-54",vendor: "CLOUD NETWORK TECHNOLOGY SINGAPORE PTE. LTD."},
  {max_prefix: "E8-F3-75",vendor: "Nokia"},
  {max_prefix: "E8-31-CD",vendor: "Espressif Inc."},
  {max_prefix: "38-1E-C7",vendor: " Chipsea Technologies(Shenzhen) Corp."},
  {max_prefix: "C0-88-6D",vendor: "Securosys SA"},
  {max_prefix: "14-DC-E2",vendor: "THALES AVS France"},
  {max_prefix: "EC-5C-84",vendor: "Murata Manufacturing Co., Ltd."},
  {max_prefix: "C8-9B-D7",vendor: "Realme Chongqing Mobile Telecommunications Corp.,Ltd."},
  {max_prefix: "28-B1-33",vendor: "SHINEMAN(SHENZHEN) Tech. Cor., Ltd."},
  {max_prefix: "74-E6-B8",vendor: "LG Electronics"},
  {max_prefix: "28-D0-F5",vendor: "Ruijie Networks Co.,LTD"},
  {max_prefix: "94-18-65",vendor: "NETGEAR"},
  {max_prefix: "A8-58-7C",vendor: "Shoogee GmbH & Co. KG"},
  {max_prefix: "98-5F-4F",vendor: "Tongfang Computer Co.,Ltd."},
  {max_prefix: "34-F8-6E",vendor: "Parker Hannifin Corporation"},
  {max_prefix: "0C-AE-BD",vendor: "Edifier International"},
  {max_prefix: "90-F2-60",vendor: "Shenzhen Honesty Electronics Co.,Ltd."},
  {max_prefix: "18-89-CF",vendor: "TECNO MOBILE LIMITED"},
  {max_prefix: "84-16-0C",vendor: "Broadcom Limited"},
  {max_prefix: "6C-D8-69",vendor: "Guangzhou Sat Infrared Co.,LTD"},
  {max_prefix: "38-F7-F1",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "40-76-A9",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "F4-38-C1",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "28-EA-2D",vendor: "Apple, Inc."},
  {max_prefix: "D0-04-B0",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "98-B0-8B",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "9C-25-95",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "E8-EE-CC",vendor: "Fantasia Trading LLC"},
  {max_prefix: "04-BD-97",vendor: "Cisco Systems, Inc"},
  {max_prefix: "54-16-51",vendor: "Ruijie Networks Co.,LTD"},
  {max_prefix: "A0-67-20",vendor: "China Dragon Technology Limited"},
  {max_prefix: "94-C9-B7",vendor: "IEEE Registration Authority"},
  {max_prefix: "60-BC-4C",vendor: "EWM Hightec Welding GmbH"},
  {max_prefix: "0C-23-69",vendor: "Honeywell SPS"},
  {max_prefix: "AC-04-25",vendor: "ball-b GmbH Co KG"},
  {max_prefix: "A0-80-69",vendor: "Intel Corporate"},
  {max_prefix: "20-72-A9",vendor: "Beijing Xiaomi Electronics Co.,Ltd"},
  {max_prefix: "D0-62-2C",vendor: "Xi'an Yipu Telecom Technology Co.,Ltd."},
  {max_prefix: "E0-56-89",vendor: "Lootom Telcovideo Network (Wuxi) Co Ltd"},
  {max_prefix: "A8-17-10",vendor: "Bouffalo Lab (Nanjing) Co., Ltd."},
  {max_prefix: "08-6A-E5",vendor: "Amazon Technologies Inc."},
  {max_prefix: "20-9B-E6",vendor: "Guangzhou Shiyuan Electronic Technology Company Limited"},
  {max_prefix: "70-87-A7",vendor: "Murata Manufacturing Co., Ltd."},
  {max_prefix: "4C-E0-DB",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "7C-33-F9",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "B8-E6-0C",vendor: "Apple, Inc."},
  {max_prefix: "48-35-2B",vendor: "Apple, Inc."},
  {max_prefix: "4C-E6-C0",vendor: "Apple, Inc."},
  {max_prefix: "38-88-A4",vendor: "Apple, Inc."},
  {max_prefix: "44-DA-30",vendor: "Apple, Inc."},
  {max_prefix: "A0-2B-B8",vendor: "Hewlett Packard"},
  {max_prefix: "8C-B0-94",vendor: "Airtech I&C Co., Ltd"},
  {max_prefix: "70-A8-D3",vendor: "Intel Corporate"},
  {max_prefix: "D8-FB-D6",vendor: "Amazon Technologies Inc."},
  {max_prefix: "9C-47-F9",vendor: "LJU Automatisierungstechnik GmbH"},
  {max_prefix: "20-62-96",vendor: "Shenzhen Malio Technology Co.,Ltd"},
  {max_prefix: "2C-33-58",vendor: "Intel Corporate"},
  {max_prefix: "00-06-4D",vendor: "Sencore"},
  {max_prefix: "1C-86-9A",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "EC-64-88",vendor: "Honor Device Co., Ltd."},
  {max_prefix: "98-BE-DC",vendor: "Honor Device Co., Ltd."},
  {max_prefix: "E4-27-61",vendor: "Honor Device Co., Ltd."},
  {max_prefix: "A0-69-74",vendor: "Honor Device Co., Ltd."},
  {max_prefix: "0C-C6-FD",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "50-DA-D6",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "34-08-E1",vendor: "Texas Instruments"},
  {max_prefix: "18-B9-6E",vendor: "Dongguan Liesheng Electronic Co., Ltd."},
  {max_prefix: "64-BE-63",vendor: "STORDIS GmbH"},
  {max_prefix: "D4-66-24",vendor: "Cisco Systems, Inc"},
  {max_prefix: "50-58-B0",vendor: "Hunan Greatwall Computer System Co., Ltd."},
  {max_prefix: "14-CB-65",vendor: "Microsoft Corporation"},
  {max_prefix: "78-13-E0",vendor: "FUJIAN STAR-NET COMMUNICATION CO.,LTD"},
  {max_prefix: "48-61-EE",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "A8-4B-4D",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "F4-F3-09",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "D0-78-80",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "E0-6C-F6",vendor: "ESSENCORE limited"},
  {max_prefix: "78-C6-2B",vendor: "FUJIAN STAR-NET COMMUNICATION CO.,LTD"},
  {max_prefix: "3C-86-9A",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "2C-27-68",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "54-69-90",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "D8-0A-60",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "D4-F9-8D",vendor: "Espressif Inc."},
  {max_prefix: "9C-90-19",vendor: "Beyless"},
  {max_prefix: "04-34-F6",vendor: "Motorola (Wuhan) Mobility Technologies Communication Co., Ltd."},
  {max_prefix: "54-69-25",vendor: "PS INODIC CO., LTD."},
  {max_prefix: "F8-95-22",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "F8-B1-32",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "E8-47-27",vendor: "Quectel Wireless Solutions Co.,Ltd."},
  {max_prefix: "28-C5-38",vendor: "Apple, Inc."},
  {max_prefix: "78-57-B0",vendor: "GERTEC BRASIL LTDA"},
  {max_prefix: "14-01-9C",vendor: "Ubyon Inc."},
  {max_prefix: "E8-CB-ED",vendor: " Chipsea Technologies(Shenzhen) Corp."},
  {max_prefix: "C4-F1-22",vendor: "Nexar Ltd."},
  {max_prefix: "60-07-C4",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "BC-DB-09",vendor: "Cisco Meraki"},
  {max_prefix: "04-99-B9",vendor: "Apple, Inc."},
  {max_prefix: "78-02-8B",vendor: "Apple, Inc."},
  {max_prefix: "F8-4D-89",vendor: "Apple, Inc."},
  {max_prefix: "08-6F-48",vendor: "Shenzhen iComm Semiconductor CO.,LTD"},
  {max_prefix: "08-16-D5",vendor: "GOERTEK INC."},
  {max_prefix: "1C-56-8E",vendor: "Zioncom Electronics (Shenzhen) Ltd."},
  {max_prefix: "28-56-3A",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "54-0F-57",vendor: "Silicon Laboratories"},
  {max_prefix: "44-48-FF",vendor: "Qingdao Haier Technology Co.,Ltd"},
  {max_prefix: "D4-D7-CF",vendor: "Realme Chongqing Mobile Telecommunications Corp.,Ltd."},
  {max_prefix: "F4-E4-D7",vendor: "FUJIAN STAR-NET COMMUNICATION CO.,LTD"},
  {max_prefix: "F8-4E-17",vendor: "Sony Corporation"},
  {max_prefix: "04-C4-61",vendor: "Murata Manufacturing Co., Ltd."},
  {max_prefix: "A0-82-22",vendor: "Qingdao Haier Technology Co.,Ltd"},
  {max_prefix: "E0-1F-2B",vendor: "Nokia Solutions and Networks GmbH & Co. KG"},
  {max_prefix: "AC-82-26",vendor: "Qingdao Haier Technology Co.,Ltd"},
  {max_prefix: "C8-CB-9E",vendor: "Intel Corporate"},
  {max_prefix: "3C-55-76",vendor: "CLOUD NETWORK TECHNOLOGY SINGAPORE PTE. LTD."},
  {max_prefix: "90-EB-48",vendor: "Shanghai XinMiaoLink Technology Co., Ltd"},
  {max_prefix: "A8-C2-66",vendor: "HUMAX Co., Ltd."},
  {max_prefix: "18-53-45",vendor: "Nokia"},
  {max_prefix: "E4-82-10",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "F8-F7-B9",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "9C-2B-A6",vendor: "Ruijie Networks Co.,LTD"},
  {max_prefix: "94-14-57",vendor: "Shenzhen Sundray Technologies Company Limited"},
  {max_prefix: "BC-57-29",vendor: "Shenzhen KKM Co., Ltd"},
  {max_prefix: "64-B9-4E",vendor: "Dell Technologies"},
  {max_prefix: "54-47-E8",vendor: "Syrotech Networks. Ltd."},
  {max_prefix: "EC-2B-EB",vendor: "Amazon Technologies Inc."},
  {max_prefix: "B8-98-AD",vendor: "Motorola Mobility LLC, a Lenovo Company"},
  {max_prefix: "70-52-D8",vendor: "ITEL MOBILE LIMITED"},
  {max_prefix: "24-97-45",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "D8-6C-5A",vendor: "HUMAX Co., Ltd."},
  {max_prefix: "E4-C0-E2",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "4C-3F-A7",vendor: "uGrid Network Inc."},
  {max_prefix: "00-1D-5B",vendor: "Tecvan Informatica Ltda"},
  {max_prefix: "1C-AE-3E",vendor: "IEEE Registration Authority"},
  {max_prefix: "D0-C3-5A",vendor: "Jabil Circuit de Chihuahua"},
  {max_prefix: "84-B6-30",vendor: "Sichuan Tianyi Comheart Telecom Co.,LTD"},
  {max_prefix: "58-C7-AC",vendor: "New H3C Technologies Co., Ltd"},
  {max_prefix: "0C-CA-FB",vendor: "TPVision Europe B.V"},
  {max_prefix: "68-77-24",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "18-2D-F7",vendor: "JY COMPANY"},
  {max_prefix: "EC-2C-11",vendor: "CWD INNOVATION LIMITED"},
  {max_prefix: "F0-35-75",vendor: "Hui Zhou Gaoshengda Technology Co.,LTD"},
  {max_prefix: "F8-B5-4D",vendor: "Intel Corporate"},
  {max_prefix: "38-A8-CD",vendor: "IEEE Registration Authority"},
  {max_prefix: "54-67-06",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "C0-F5-35",vendor: "AMPAK Technology,Inc."},
  {max_prefix: "90-81-75",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "30-74-67",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "F0-CD-31",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "64-01-FB",vendor: "Landis+Gyr GmbH"},
  {max_prefix: "54-52-84",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "64-61-40",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "C4-17-0E",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "7C-83-34",vendor: "IEEE Registration Authority"},
  {max_prefix: "20-7B-D2",vendor: "ASIX Electronics Corporation"},
  {max_prefix: "C4-E5-32",vendor: "Arcadyan Corporation"},
  {max_prefix: "0C-AA-EE",vendor: "Ansjer Electronics Co., Ltd."},
  {max_prefix: "90-6A-94",vendor: "hangzhou huacheng network technology co., ltd"},
  {max_prefix: "10-0C-29",vendor: "Shenzhen NORCO lntelligent Technology Co.,Ltd"},
  {max_prefix: "8C-3D-B1",vendor: "Beijing H-IoT Technology Co., Ltd."},
  {max_prefix: "D8-A6-F0",vendor: "Wu Qi Technologies,Inc."},
  {max_prefix: "08-E4-DF",vendor: "Shenzhen Sande Dacom Electronics Co., Ltd"},
  {max_prefix: "10-5A-17",vendor: "Tuya Smart Inc."},
  {max_prefix: "00-8A-76",vendor: "Apple, Inc."},
  {max_prefix: "FC-A6-4C",vendor: "Alibaba cloud computing Co., Ltd"},
  {max_prefix: "E0-69-BA",vendor: "Cisco Systems, Inc"},
  {max_prefix: "24-36-DA",vendor: "Cisco Systems, Inc"},
  {max_prefix: "20-37-A5",vendor: "Apple, Inc."},
  {max_prefix: "DC-B5-4F",vendor: "Apple, Inc."},
  {max_prefix: "B8-37-4A",vendor: "Apple, Inc."},
  {max_prefix: "EC-7C-2C",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "5C-83-82",vendor: "Nokia"},
  {max_prefix: "28-36-13",vendor: "IEEE Registration Authority"},
  {max_prefix: "14-58-08",vendor: "Taicang T&W Electronics"},
  {max_prefix: "F4-23-9C",vendor: "SERNET (SUZHOU) TECHNOLOGIES CORPORATION"},
  {max_prefix: "4C-EB-D6",vendor: "Espressif Inc."},
  {max_prefix: "F4-B1-9C",vendor: "AltoBeam (China) Inc."},
  {max_prefix: "A8-D0-81",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "74-0C-EE",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "6C-B4-FD",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "74-32-C2",vendor: "KYOLIS"},
  {max_prefix: "00-CE-30",vendor: "Express LUCK Industrial Ltd."},
  {max_prefix: "1C-9D-C2",vendor: "Espressif Inc."},
  {max_prefix: "64-0E-9B",vendor: "ISHIDA MEDICAL CO., LTD."},
  {max_prefix: "24-F6-03",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "08-FA-28",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "F8-CA-85",vendor: "NEC Corporation"},
  {max_prefix: "1C-2C-E0",vendor: "Shanghai  Mountain View Silicon"},
  {max_prefix: "24-42-BC",vendor: "Alinco,incorporated"},
  {max_prefix: "18-FC-26",vendor: "Qorvo International Pte. Ltd."},
  {max_prefix: "EC-C1-AB",vendor: "Guangzhou Shiyuan Electronic Technology Company Limited"},
  {max_prefix: "00-00-C8",vendor: "ALTOS COMPUTER SYSTEMS"},
  {max_prefix: "A0-94-1A",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "30-0E-B8",vendor: "LG Electronics"},
  {max_prefix: "BC-33-29",vendor: "Sony Interactive Entertainment Inc."},
  {max_prefix: "A8-89-40",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "0C-0E-76",vendor: "D-Link International"},
  {max_prefix: "70-3E-97",vendor: "Iton Technology Corp."},
  {max_prefix: "6C-11-B3",vendor: "Wu Qi Technologies,Inc."},
  {max_prefix: "10-47-38",vendor: "Nokia Shanghai Bell Co., Ltd."},
  {max_prefix: "38-B8-00",vendor: "Wistron Neweb Corporation"},
  {max_prefix: "CC-31-2A",vendor: "HUIZHOU TCL COMMUNICATION ELECTRON CO.,LTD"},
  {max_prefix: "30-B0-37",vendor: "New H3C Technologies Co., Ltd"},
  {max_prefix: "FC-12-2C",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "6C-D1-E5",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "30-39-A9",vendor: "Hongshan Information Science and Technology (HangZhou) Co.,Ltd."},
  {max_prefix: "FC-D9-08",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "D0-16-7C",vendor: "eero inc."},
  {max_prefix: "84-14-4D",vendor: "Intel Corporate"},
  {max_prefix: "9C-6B-F0",vendor: "Shenzhen Yipingfang Network Technology Co., Ltd."},
  {max_prefix: "44-95-3B",vendor: "RLTech India Private Limited"},
  {max_prefix: "94-5A-FC",vendor: "Amazon Technologies Inc."},
  {max_prefix: "00-D7-6D",vendor: "Intel Corporate"},
  {max_prefix: "B4-1C-AB",vendor: "ICR, inc."},
  {max_prefix: "A8-5B-36",vendor: "IEEE Registration Authority"},
  {max_prefix: "2C-11-65",vendor: "Silicon Laboratories"},
  {max_prefix: "DC-ED-83",vendor: "Beijing Xiaomi Mobile Software Co., Ltd"},
  {max_prefix: "AC-0B-FB",vendor: "Espressif Inc."},
  {max_prefix: "20-B8-68",vendor: "Motorola Mobility LLC, a Lenovo Company"},
  {max_prefix: "28-9C-6E",vendor: "Shanghai High-Flying Electronics  Technology Co., Ltd"},
  {max_prefix: "1C-2A-8B",vendor: "Nokia"},
  {max_prefix: "84-F7-03",vendor: "Espressif Inc."},
  {max_prefix: "48-5A-67",vendor: "Shaanxi Ruixun Electronic Information Technology Co., Ltd"},
  {max_prefix: "94-5C-9A",vendor: "Apple, Inc."},
  {max_prefix: "8C-1F-64",vendor: "IEEE Registration Authority"},
  {max_prefix: "F4-7B-09",vendor: "Intel Corporate"},
  {max_prefix: "E8-78-29",vendor: "IEEE Registration Authority"},
  {max_prefix: "50-32-5F",vendor: "Silicon Laboratories"},
  {max_prefix: "1C-41-90",vendor: "Universal Electronics, Inc."},
  {max_prefix: "FC-8A-3D",vendor: "zte corporation"},
  {max_prefix: "08-5A-11",vendor: "D-Link International"},
  {max_prefix: "64-C4-03",vendor: "Quectel Wireless Solutions Co.,Ltd."},
  {max_prefix: "EC-94-68",vendor: "META SYSTEM SPA"},
  {max_prefix: "00-F3-9F",vendor: "Apple, Inc."},
  {max_prefix: "64-C3-94",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "4C-77-CB",vendor: "Intel Corporate"},
  {max_prefix: "44-D5-CC",vendor: "Amazon Technologies Inc."},
  {max_prefix: "00-00-5B",vendor: "ELTEC ELEKTRONIK AG"},
  {max_prefix: "5C-E4-2A",vendor: "Intel Corporate"},
  {max_prefix: "F0-C8-14",vendor: "SHENZHEN BILIAN ELECTRONIC CO.\uff0cLTD"},
  {max_prefix: "70-5F-A3",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "3C-62-F0",vendor: "Sercomm Corporation."},
  {max_prefix: "98-3B-67",vendor: "DWnet Technologies(Suzhou) Corporation"},
  {max_prefix: "6C-93-92",vendor: "BEKO Technologies GmbH"},
  {max_prefix: "58-35-5D",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "AC-7E-01",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "18-74-E2",vendor: "IEEE Registration Authority"},
  {max_prefix: "A0-B0-86",vendor: "Hirschmann Automation and Control GmbH"},
  {max_prefix: "30-B3-46",vendor: "CJSC NORSI-TRANS"},
  {max_prefix: "00-04-B8",vendor: "Kumahira Co., Ltd."},
  {max_prefix: "00-0B-3A",vendor: "PESA Inc."},
  {max_prefix: "04-B9-E3",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "94-AA-0A",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "E8-5C-0A",vendor: "Cisco Systems, Inc"},
  {max_prefix: "98-43-FA",vendor: "Intel Corporate"},
  {max_prefix: "20-D2-76",vendor: "ITEL MOBILE LIMITED"},
  {max_prefix: "3C-BD-D8",vendor: "LG ELECTRONICS INC"},
  {max_prefix: "54-C2-50",vendor: "Iskratel d.o.o."},
  {max_prefix: "00-FA-B6",vendor: "Kontakt Micro-Location Sp z o.o."},
  {max_prefix: "7C-F4-62",vendor: "BEIJING HUAWOO TECHNOLOGIES CO.LTD"},
  {max_prefix: "70-B9-50",vendor: "Texas Instruments"},
  {max_prefix: "78-1C-5A",vendor: "SHARP Corporation"},
  {max_prefix: "C4-5E-5C",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "D0-17-69",vendor: "Murata Manufacturing Co., Ltd."},
  {max_prefix: "20-C1-9B",vendor: "Intel Corporate"},
  {max_prefix: "2C-6D-C1",vendor: "Intel Corporate"},
  {max_prefix: "D0-9F-D9",vendor: "IEEE Registration Authority"},
  {max_prefix: "A0-D7-22",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "50-49-B0",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "00-0C-DF",vendor: "JAI Manufacturing"},
  {max_prefix: "C4-A1-51",vendor: "Sichuan Tianyi Comheart Telecom Co.,LTD"},
  {max_prefix: "20-1F-3B",vendor: "Google, Inc."},
  {max_prefix: "C8-05-9E",vendor: "Hefei Symboltek Co.,Ltd"},
  {max_prefix: "34-FC-A1",vendor: "Micronet union Technology(Chengdu)Co., Ltd."},
  {max_prefix: "10-7B-CE",vendor: "Nokia"},
  {max_prefix: "B8-25-B5",vendor: "Trakm8 Ltd"},
  {max_prefix: "20-41-5A",vendor: "Smarteh d.o.o."},
  {max_prefix: "B0-A7-B9",vendor: "TP-Link Corporation Limited"},
  {max_prefix: "6C-5A-B0",vendor: "TP-Link Corporation Limited"},
  {max_prefix: "60-E6-F0",vendor: "Wistron Neweb Corporation"},
  {max_prefix: "C8-F5-D6",vendor: "IEEE Registration Authority"},
  {max_prefix: "28-97-B8",vendor: "myenergi Ltd"},
  {max_prefix: "F4-4E-38",vendor: "Olibra LLC"},
  {max_prefix: "F0-92-B4",vendor: "Sichuan Tianyi Comheart Telecom Co.,LTD"},
  {max_prefix: "EC-F8-EB",vendor: "Sichuan Tianyi Comheart Telecom Co.,LTD"},
  {max_prefix: "40-58-99",vendor: "Logitech Far East"},
  {max_prefix: "08-A8-42",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "2C-63-73",vendor: "Sichuan Tianyi Comheart Telecom Co.,LTD"},
  {max_prefix: "88-E0-56",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "54-0E-58",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "5C-64-8E",vendor: "Zyxel Communications Corporation"},
  {max_prefix: "00-00-B8",vendor: "SEIKOSHA CO., LTD."},
  {max_prefix: "D0-35-E5",vendor: "EM Microelectronic"},
  {max_prefix: "90-96-F3",vendor: "BUFFALO.INC"},
  {max_prefix: "08-2C-ED",vendor: "Technity Solutions Inc."},
  {max_prefix: "14-5A-FC",vendor: "Liteon Technology Corporation"},
  {max_prefix: "EC-15-3D",vendor: "Beijing Yaxunhongda Technology Co., Ltd."},
  {max_prefix: "20-66-CF",vendor: "FREEBOX SAS"},
  {max_prefix: "2C-8D-B1",vendor: "Intel Corporate"},
  {max_prefix: "58-6C-25",vendor: "Intel Corporate"},
  {max_prefix: "2C-C8-1B",vendor: "Routerboard.com"},
  {max_prefix: "78-47-E3",vendor: "Sichuan Tianyi Comheart Telecom Co.,LTD"},
  {max_prefix: "30-E3-96",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "D0-7D-33",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "C0-E1-BE",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "04-27-28",vendor: "Microsoft Corporation"},
  {max_prefix: "18-BF-B3",vendor: "Samsung Electronics Co., Ltd., Memory Division"},
  {max_prefix: "A4-9B-CD",vendor: "Cisco Systems, Inc"},
  {max_prefix: "B4-E3-F9",vendor: "Silicon Laboratories"},
  {max_prefix: "18-42-D4",vendor: "Wuhan Hosan Telecommunication Technology Co.,Ltd"},
  {max_prefix: "A8-63-7D",vendor: "D-Link International"},
  {max_prefix: "6C-1B-3F",vendor: "MiraeSignal Co., Ltd"},
  {max_prefix: "B0-3D-C2",vendor: "Wasp artificial intelligence(Shenzhen) Co.,ltd"},
  {max_prefix: "C8-4D-34",vendor: "LIONS Taiwan Technology Inc."},
  {max_prefix: "20-25-D2",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "2C-43-BE",vendor: "Sunnovo International Limited"},
  {max_prefix: "58-10-B7",vendor: "Infinix mobility limited"},
  {max_prefix: "E0-92-5C",vendor: "Apple, Inc."},
  {max_prefix: "00-11-6C",vendor: "Nanwang Multimedia Inc.,Ltd"},
  {max_prefix: "28-F4-9B",vendor: "LEETEK"},
  {max_prefix: "D8-A3-5C",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "C0-92-96",vendor: "zte corporation"},
  {max_prefix: "50-E2-4E",vendor: "zte corporation"},
  {max_prefix: "D8-E7-2B",vendor: "NETSCOUT SYSTEMS INC"},
  {max_prefix: "78-E3-6D",vendor: "Espressif Inc."},
  {max_prefix: "00-01-39",vendor: "Point Multimedia Systems"},
  {max_prefix: "B0-24-91",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "98-75-1A",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "B4-89-01",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "44-9B-C1",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "1C-91-80",vendor: "Apple, Inc."},
  {max_prefix: "4C-B9-10",vendor: "Apple, Inc."},
  {max_prefix: "34-86-5D",vendor: "Espressif Inc."},
  {max_prefix: "1C-A0-EF",vendor: "IEEE Registration Authority"},
  {max_prefix: "44-6F-F8",vendor: "Dyson Limited"},
  {max_prefix: "28-AF-FD",vendor: "Cisco Systems, Inc"},
  {max_prefix: "D0-97-FE",vendor: "Realme Chongqing Mobile Telecommunications Corp.,Ltd."},
  {max_prefix: "84-3A-5B",vendor: "Inventec(Chongqing) Corporation"},
  {max_prefix: "98-CD-AC",vendor: "Espressif Inc."},
  {max_prefix: "F8-45-C4",vendor: "Shenzhen Netforward Micro-Electronic Co., Ltd."},
  {max_prefix: "EC-93-65",vendor: "Mapper.ai, Inc."},
  {max_prefix: "9C-76-13",vendor: "Ring LLC"},
  {max_prefix: "80-8A-F7",vendor: "Nanoleaf"},
  {max_prefix: "18-2A-57",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "F8-3E-95",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "48-12-58",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "CC-32-96",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "D8-EB-46",vendor: "Google, Inc."},
  {max_prefix: "2C-17-E0",vendor: "SYSTEMES ET TECHNOLOGIES IDENTIFICATION (STid)"},
  {max_prefix: "54-49-DF",vendor: "Peloton Interactive, Inc"},
  {max_prefix: "78-10-53",vendor: "China Mobile Group Device Co.,Ltd."},
  {max_prefix: "A8-43-97",vendor: "Innogrit Corporation"},
  {max_prefix: "08-47-4C",vendor: "Nokia"},
  {max_prefix: "90-C1-19",vendor: "Nokia"},
  {max_prefix: "8C-AA-CE",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "E8-9F-39",vendor: "Nokia"},
  {max_prefix: "B0-22-7A",vendor: "HP Inc."},
  {max_prefix: "60-A7-51",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "10-2B-41",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "34-4A-C3",vendor: "HuNan ZiKun Information Technology CO., Ltd"},
  {max_prefix: "80-34-28",vendor: "Microchip Technology Inc."},
  {max_prefix: "00-E4-21",vendor: "Sony Interactive Entertainment Inc."},
  {max_prefix: "D8-5E-D3",vendor: "GIGA-BYTE TECHNOLOGY CO.,LTD."},
  {max_prefix: "24-9A-D8",vendor: "YEALINK(XIAMEN) NETWORK TECHNOLOGY CO.,LTD."},
  {max_prefix: "D8-CD-2C",vendor: "WUXI NEIHUA NETWORK TECHNOLOGY CO., LTD"},
  {max_prefix: "7C-F8-80",vendor: "Cisco Systems, Inc"},
  {max_prefix: "98-1A-35",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "68-2D-83",vendor: "SHENZHEN DINGHE COMMUNICATION COMPANY"},
  {max_prefix: "40-3F-8C",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "CC-DB-04",vendor: "DataRemote Inc."},
  {max_prefix: "04-D6-F4",vendor: "GD Midea Air-Conditioning Equipment Co.,Ltd."},
  {max_prefix: "D8-BB-C1",vendor: "Micro-Star INTL CO., LTD."},
  {max_prefix: "B8-44-AE",vendor: "TCT mobile ltd"},
  {max_prefix: "F8-AA-3F",vendor: "DWnet Technologies(Suzhou) Corporation"},
  {max_prefix: "0C-CF-89",vendor: "SHENZHEN BILIAN ELECTRONIC CO.\uff0cLTD"},
  {max_prefix: "AC-5A-FC",vendor: "Intel Corporate"},
  {max_prefix: "D4-35-1D",vendor: "Technicolor Delivery Technologies Belgium NV"},
  {max_prefix: "F0-77-C3",vendor: "Intel Corporate"},
  {max_prefix: "04-90-81",vendor: "Pensando Systems, Inc."},
  {max_prefix: "4C-79-6E",vendor: "Intel Corporate"},
  {max_prefix: "F0-9E-4A",vendor: "Intel Corporate"},
  {max_prefix: "C8-94-02",vendor: "CHONGQING FUGUI ELECTRONICS CO.,LTD."},
  {max_prefix: "7C-B9-4C",vendor: "Bouffalo Lab (Nanjing) Co., Ltd."},
  {max_prefix: "0C-8C-69",vendor: "Shenzhen elink smart Co., ltd"},
  {max_prefix: "00-68-2B",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "98-AD-1D",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "44-6F-D8",vendor: "IEEE Registration Authority"},
  {max_prefix: "84-D3-43",vendor: "Calix Inc."},
  {max_prefix: "38-B5-D3",vendor: "SecuWorks"},
  {max_prefix: "DC-15-C8",vendor: "AVM Audiovisuelles Marketing und Computersysteme GmbH"},
  {max_prefix: "6C-D6-30",vendor: "Rootous System Co.,Ltd"},
  {max_prefix: "B0-68-E6",vendor: "CHONGQING FUGUI ELECTRONICS CO.,LTD."},
  {max_prefix: "BC-D7-CE",vendor: "China Mobile (Hangzhou) Information Technology Co., Ltd."},
  {max_prefix: "34-31-8F",vendor: "Apple, Inc."},
  {max_prefix: "C4-14-11",vendor: "Apple, Inc."},
  {max_prefix: "CC-C9-5D",vendor: "Apple, Inc."},
  {max_prefix: "70-61-BE",vendor: "Wistron Neweb Corporation"},
  {max_prefix: "C8-33-E5",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "E8-6D-E9",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "14-46-58",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "58-BE-72",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "10-8F-FE",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "D8-47-BB",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "2C-DD-0C",vendor: "Discovergy GmbH"},
  {max_prefix: "BC-9D-42",vendor: "SHENZHEN RF-LINK TECHNOLOGY CO.,LTD."},
  {max_prefix: "44-B6-BE",vendor: "Cisco Systems, Inc"},
  {max_prefix: "8C-DE-E6",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "10-EC-81",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "C4-5D-83",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "9C-9C-1F",vendor: "Espressif Inc."},
  {max_prefix: "24-0B-88",vendor: "Taicang T&W Electronics"},
  {max_prefix: "0C-93-8F",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "A0-41-2D",vendor: "Lansen Systems AB"},
  {max_prefix: "C4-39-60",vendor: "GD Midea Air-Conditioning Equipment Co.,Ltd."},
  {max_prefix: "5C-BD-9A",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "14-A3-B4",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "5C-A4-A4",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "D8-09-D6",vendor: "ZEXELON CO., LTD."},
  {max_prefix: "9C-A5-70",vendor: "eero inc."},
  {max_prefix: "74-04-F0",vendor: "Mobiwire Mobiles (NingBo) Co., LTD"},
  {max_prefix: "B4-B5-B6",vendor: "CHONGQING FUGUI ELECTRONICS CO.,LTD."},
  {max_prefix: "04-7A-0B",vendor: "Beijing Xiaomi Electronics Co., Ltd."},
  {max_prefix: "90-0F-0C",vendor: "CLOUD NETWORK TECHNOLOGY SINGAPORE PTE. LTD."},
  {max_prefix: "4C-11-54",vendor: "Mobiwire Mobiles (NingBo) Co., LTD"},
  {max_prefix: "C4-BF-60",vendor: "TECNO MOBILE LIMITED"},
  {max_prefix: "D0-40-EF",vendor: "Murata Manufacturing Co., Ltd."},
  {max_prefix: "D0-56-BF",vendor: "AMOSENSE"},
  {max_prefix: "2C-9A-A4",vendor: "Eolo SpA"},
  {max_prefix: "18-D5-B6",vendor: "SMG Holdings LLC"},
  {max_prefix: "70-F0-96",vendor: "Cisco Systems, Inc"},
  {max_prefix: "BC-9A-53",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "DC-27-27",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "F0-42-F5",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "E0-2E-3F",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "EC-75-ED",vendor: "Citrix Systems, Inc."},
  {max_prefix: "4C-75-25",vendor: "Espressif Inc."},
  {max_prefix: "00-13-40",vendor: "AD.EL s.r.l."},
  {max_prefix: "00-22-36",vendor: "VECTOR SP. Z O.O."},
  {max_prefix: "80-B9-7A",vendor: "eero inc."},
  {max_prefix: "A4-53-EE",vendor: "IEEE Registration Authority"},
  {max_prefix: "C8-A4-0D",vendor: "Cooler Master Technology Inc"},
  {max_prefix: "60-8D-26",vendor: "Arcadyan Corporation"},
  {max_prefix: "1C-39-29",vendor: "OHSUNG"},
  {max_prefix: "48-70-1E",vendor: "Texas Instruments"},
  {max_prefix: "50-8E-49",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "AC-33-28",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "8C-F3-19",vendor: "Siemens Industrial Automation Products Ltd., Chengdu"},
  {max_prefix: "B0-0C-D1",vendor: "Hewlett Packard"},
  {max_prefix: "2C-F0-5D",vendor: "Micro-Star INTL CO., LTD."},
  {max_prefix: "8C-47-6E",vendor: "IEEE Registration Authority"},
  {max_prefix: "00-02-F4",vendor: "PCTEL, Inc."},
  {max_prefix: "30-9E-1D",vendor: "OHSUNG"},
  {max_prefix: "68-79-12",vendor: "IEEE Registration Authority"},
  {max_prefix: "74-D6-CB",vendor: "New H3C Technologies Co., Ltd"},
  {max_prefix: "0C-3A-FA",vendor: "New H3C Technologies Co., Ltd"},
  {max_prefix: "A8-81-7E",vendor: "Apple, Inc."},
  {max_prefix: "18-B6-CC",vendor: "We Corporation Inc."},
  {max_prefix: "30-A0-23",vendor: "ROCK PATH S.R.L"},
  {max_prefix: "E8-48-B8",vendor: "TP-Link Corporation Limited"},
  {max_prefix: "78-CB-2C",vendor: "Join Digital, Inc."},
  {max_prefix: "F8-66-5A",vendor: "Apple, Inc."},
  {max_prefix: "60-BE-C4",vendor: "Apple, Inc."},
  {max_prefix: "F8-B1-DD",vendor: "Apple, Inc."},
  {max_prefix: "58-86-94",vendor: "EFM Networks"},
  {max_prefix: "DC-B7-2E",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "E0-C3-77",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "4C-FB-F4",vendor: "Optimal Audio Ltd"},
  {max_prefix: "E0-2B-E9",vendor: "Intel Corporate"},
  {max_prefix: "F8-6D-73",vendor: "Zengge Co., Limited"},
  {max_prefix: "AC-37-28",vendor: "Taicang T&W Electronics"},
  {max_prefix: "6C-E8-74",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "C4-69-F0",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "BC-99-30",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "E8-68-E7",vendor: "Espressif Inc."},
  {max_prefix: "64-79-24",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "D8-CC-98",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "20-CD-6E",vendor: "Realme Chongqing Mobile Telecommunications Corp.,Ltd."},
  {max_prefix: "64-03-7F",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "B4-9D-02",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "80-9F-F5",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "D0-BC-C1",vendor: "WEIFANG GOERTEK ELECTRONICS CO.,LTD"},
  {max_prefix: "DC-4A-9E",vendor: "IEEE Registration Authority"},
  {max_prefix: "FC-8D-3D",vendor: "Leapfive Tech. Ltd."},
  {max_prefix: "40-14-AD",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "78-C9-5E",vendor: "Midmark RTLS"},
  {max_prefix: "C0-33-DA",vendor: "Shenzhen JRUN Technologies CO., LTD"},
  {max_prefix: "A8-74-84",vendor: "zte corporation"},
  {max_prefix: "BC-69-CB",vendor: "Panasonic Electric Works Networks Co., Ltd."},
  {max_prefix: "08-93-56",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "DC-B7-FC",vendor: "Alps Electric (Ireland) Ltd"},
  {max_prefix: "AC-FA-A5",vendor: "digitron"},
  {max_prefix: "B4-0E-DE",vendor: "Intel Corporate"},
  {max_prefix: "6C-14-6E",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "44-E9-68",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "4C-EF-56",vendor: "Shenzhen Sundray Technologies Company Limited"},
  {max_prefix: "E8-4F-4B",vendor: "Shenzhen Delos Electronic Co., Ltd"},
  {max_prefix: "B0-4A-39",vendor: "Beijing Roborock Technology Co., Ltd."},
  {max_prefix: "A4-F9-E4",vendor: "AirVine Scientific, Inc."},
  {max_prefix: "F4-4F-D3",vendor: "shenzhen hemuwei technology co.,ltd"},
  {max_prefix: "AC-12-2F",vendor: "Fantasia Trading LLC"},
  {max_prefix: "FC-45-C3",vendor: "Texas Instruments"},
  {max_prefix: "AC-6A-A3",vendor: "Shenzhen Kertong Technology Co.,Ltd"},
  {max_prefix: "E4-C3-2A",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "90-9A-4A",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "BC-97-89",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "68-45-71",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "84-93-A0",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "9C-8E-9C",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "FC-69-8C",vendor: "ANDREAS STIHL AG & Co. KG"},
  {max_prefix: "3C-E0-38",vendor: "Omnifi Inc."},
  {max_prefix: "44-CE-3A",vendor: "Jiangsu Huacun Electronic Technology Co., Ltd."},
  {max_prefix: "9C-1E-A4",vendor: "Renesas Electronics (Penang) Sdn. Bhd."},
  {max_prefix: "00-1F-B8",vendor: "Universal Remote Control, Inc."},
  {max_prefix: "B8-AE-1C",vendor: "Smart Cube., Ltd"},
  {max_prefix: "60-70-72",vendor: "SHENZHEN HONGDE SMART LINK TECHNOLOGY CO., LTD"},
  {max_prefix: "6C-2D-24",vendor: "Zhen Shi Information Technology (Shanghai) Co., Ltd."},
  {max_prefix: "C8-B6-D3",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "E0-4B-A6",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "80-03-84",vendor: "Ruckus Wireless"},
  {max_prefix: "D8-37-3B",vendor: "Shenzhen Jingxun Software Telecommunication Technology Co.,Ltd"},
  {max_prefix: "A8-30-1C",vendor: "Qingdao Intelligent&Precise Electronics Co.,Ltd."},
  {max_prefix: "7C-78-B2",vendor: "Wyze Labs Inc"},
  {max_prefix: "B8-47-7A",vendor: "Dasan Electron Co., Ltd."},
  {max_prefix: "6C-AD-AD",vendor: "CHONGQING FUGUI ELECTRONICS CO.,LTD."},
  {max_prefix: "00-60-E9",vendor: "ATOP TECHNOLOGIES, INC."},
  {max_prefix: "D8-F3-BC",vendor: "Liteon Technology Corporation"},
  {max_prefix: "D4-48-2D",vendor: "Shenzhen Deejoy Lighting Technology Co.,Ltd."},
  {max_prefix: "78-E3-DE",vendor: "Apple, Inc."},
  {max_prefix: "F4-6F-ED",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "94-B9-7E",vendor: "Espressif Inc."},
  {max_prefix: "CC-E0-DA",vendor: "Baidu Online Network Technology (Beijing) Co., Ltd"},
  {max_prefix: "40-8C-4C",vendor: "Shenzhen MiaoMing  Intelligent Technology Co.,Ltd"},
  {max_prefix: "14-1B-30",vendor: "Shenzhen Yipingfang Network Technology Co., Ltd."},
  {max_prefix: "D0-D2-3C",vendor: "Apple, Inc."},
  {max_prefix: "64-D2-C4",vendor: "Apple, Inc."},
  {max_prefix: "DC-52-85",vendor: "Apple, Inc."},
  {max_prefix: "E8-81-52",vendor: "Apple, Inc."},
  {max_prefix: "90-81-58",vendor: "Apple, Inc."},
  {max_prefix: "D8-F8-AF",vendor: "DAONTEC"},
  {max_prefix: "E4-84-2B",vendor: "HANGZHOU SOFTEL OPTIC CO., LTD"},
  {max_prefix: "94-02-6B",vendor: "Optictimes Co.,Ltd"},
  {max_prefix: "08-42-96",vendor: "Mobile Technology Solutions LLC"},
  {max_prefix: "34-EF-B6",vendor: "Edgecore Networks Corporation"},
  {max_prefix: "E8-74-C7",vendor: "Sentinhealth"},
  {max_prefix: "B4-AD-A3",vendor: "Guangzhou\u00a0Shiyuan\u00a0Electronic\u00a0Technology\u00a0Company\u00a0Limited"},
  {max_prefix: "18-FD-CB",vendor: "IEEE Registration Authority"},
  {max_prefix: "00-90-23",vendor: "ZILOG INC."},
  {max_prefix: "B0-FE-E5",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "44-87-DB",vendor: "Tymphany Acoustic Technology (Huizhou) Co.,  Ltd."},
  {max_prefix: "64-23-15",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "A4-C7-4B",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "08-5B-D6",vendor: "Intel Corporate"},
  {max_prefix: "C8-17-39",vendor: "ITEL MOBILE LIMITED"},
  {max_prefix: "58-D0-61",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "90-CC-DF",vendor: "Intel Corporate"},
  {max_prefix: "B8-D3-09",vendor: "Cox Communications, Inc"},
  {max_prefix: "68-93-20",vendor: "New H3C Technologies Co., Ltd"},
  {max_prefix: "94-54-CE",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "38-8A-BE",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "04-7E-23",vendor: "China Mobile IOT Company Limited"},
  {max_prefix: "14-CC-B3",vendor: "AO GK NATEKS"},
  {max_prefix: "00-1C-7B",vendor: "Castlenet Technology Inc."},
  {max_prefix: "54-84-DC",vendor: "zte corporation"},
  {max_prefix: "38-54-9B",vendor: "zte corporation"},
  {max_prefix: "DC-CD-2F",vendor: "Seiko Epson Corporation"},
  {max_prefix: "34-3E-A4",vendor: "Ring LLC"},
  {max_prefix: "04-C1-D8",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "C8-BC-9C",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "8C-8D-28",vendor: "Intel Corporate"},
  {max_prefix: "6C-BA-B8",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "E0-D4-64",vendor: "Intel Corporate"},
  {max_prefix: "C0-F6-C2",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "00-7E-56",vendor: "China Dragon Technology Limited"},
  {max_prefix: "08-3A-F2",vendor: "Espressif Inc."},
  {max_prefix: "74-3A-20",vendor: "New H3C Technologies Co., Ltd"},
  {max_prefix: "3C-1A-9E",vendor: "VitalThings AS"},
  {max_prefix: "C8-84-A1",vendor: "Cisco Systems, Inc"},
  {max_prefix: "D8-77-8B",vendor: "Intelbras"},
  {max_prefix: "30-C9-AB",vendor: "CLOUD NETWORK TECHNOLOGY SINGAPORE PTE. LTD."},
  {max_prefix: "D4-AF-F7",vendor: "Arista Networks"},
  {max_prefix: "C4-D7-38",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "60-07-7C",vendor: "Jala Group"},
  {max_prefix: "60-98-66",vendor: "Texas Instruments"},
  {max_prefix: "1C-D6-BE",vendor: "Wistron Neweb Corporation"},
  {max_prefix: "28-D1-27",vendor: "Beijing Xiaomi Mobile Software Co., Ltd"},
  {max_prefix: "30-A4-52",vendor: "Arrival Elements BV"},
  {max_prefix: "F4-69-D5",vendor: "IEEE Registration Authority"},
  {max_prefix: "8C-C5-B4",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "F8-1B-04",vendor: "Zhong Shan City Richsound Electronic Industrial Ltd"},
  {max_prefix: "D8-8C-79",vendor: "Google, Inc."},
  {max_prefix: "10-34-1B",vendor: "Spacelink"},
  {max_prefix: "F8-64-65",vendor: "Anova Applied Electronics, Inc."},
  {max_prefix: "90-A9-35",vendor: "JWEntertainment"},
  {max_prefix: "60-D4-E9",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "C8-68-DE",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "D0-0D-F7",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "48-05-E2",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "00-1E-68",vendor: "Quanta Computer Inc."},
  {max_prefix: "2C-60-0C",vendor: "Quanta Computer Inc."},
  {max_prefix: "00-23-8B",vendor: "Quanta Computer Inc."},
  {max_prefix: "00-08-F6",vendor: "Sumitomo Electric Industries, Ltd"},
  {max_prefix: "00-0B-A2",vendor: "Sumitomo Electric Industries, Ltd"},
  {max_prefix: "00-00-5F",vendor: "Sumitomo Electric Industries, Ltd"},
  {max_prefix: "18-EC-E7",vendor: "BUFFALO.INC"},
  {max_prefix: "BC-03-A7",vendor: "MFP MICHELIN"},
  {max_prefix: "CC-DB-93",vendor: "Cisco Systems, Inc"},
  {max_prefix: "C8-E2-65",vendor: "Intel Corporate"},
  {max_prefix: "18-D6-1C",vendor: "Shenzhen TINNO Mobile Technology Corp."},
  {max_prefix: "54-48-E6",vendor: "Beijing Xiaomi Mobile Software Co., Ltd"},
  {max_prefix: "2C-6F-51",vendor: "Herospeed Digital Technology Limited"},
  {max_prefix: "80-EA-07",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "80-12-DF",vendor: "Shenzhen SuperElectron Technology Co.,Ltd."},
  {max_prefix: "04-A2-F3",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "98-2F-F8",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "40-DC-A5",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "30-C5-0F",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "28-68-D2",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "00-D0-B6",vendor: "CRESCENT NETWORKS, INC."},
  {max_prefix: "C0-1C-30",vendor: "Shenzhen WIFI-3L Technology Co.,Ltd"},
  {max_prefix: "1C-E6-1D",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "44-1C-7F",vendor: "Motorola Mobility LLC, a Lenovo Company"},
  {max_prefix: "00-B8-B6",vendor: "Motorola Mobility LLC, a Lenovo Company"},
  {max_prefix: "BC-17-B8",vendor: "Intel Corporate"},
  {max_prefix: "08-FA-79",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "D8-C6-78",vendor: "MitraStar Technology Corp."},
  {max_prefix: "EC-C0-1B",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "98-44-CE",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "F0-9B-B8",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "44-BB-3B",vendor: "Google, Inc."},
  {max_prefix: "14-C1-4E",vendor: "Google, Inc."},
  {max_prefix: "D8-C0-A6",vendor: "AzureWave Technology Inc."},
  {max_prefix: "10-E7-7A",vendor: "STMicrolectronics International NV"},
  {max_prefix: "E4-3D-1A",vendor: "Broadcom Limited"},
  {max_prefix: "70-18-A7",vendor: "Cisco Systems, Inc"},
  {max_prefix: "24-F1-50",vendor: "Guangzhou Qi'an Technology Co., Ltd."},
  {max_prefix: "30-42-40",vendor: "zte corporation"},
  {max_prefix: "98-A9-42",vendor: "Guangzhou Tozed Kangwei Intelligent Technology Co., LTD"},
  {max_prefix: "4C-21-13",vendor: "Nokia Shanghai Bell Co., Ltd."},
  {max_prefix: "18-3E-EF",vendor: "Apple, Inc."},
  {max_prefix: "60-7E-C9",vendor: "Apple, Inc."},
  {max_prefix: "98-2C-BC",vendor: "Intel Corporate"},
  {max_prefix: "20-50-E7",vendor: "AMPAK Technology,Inc."},
  {max_prefix: "4C-C5-3E",vendor: "Zyxel Communications Corporation"},
  {max_prefix: "10-8B-6A",vendor: "Antailiye Technology Co.,Ltd"},
  {max_prefix: "80-2D-BF",vendor: "Cisco Systems, Inc"},
  {max_prefix: "8C-5E-BD",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "2C-A0-42",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "C0-83-C9",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "FC-60-9B",vendor: "New H3C Technologies Co., Ltd"},
  {max_prefix: "E8-A1-F8",vendor: "zte corporation"},
  {max_prefix: "14-C8-8B",vendor: "Apple, Inc."},
  {max_prefix: "EC-26-51",vendor: "Apple, Inc."},
  {max_prefix: "08-AC-C4",vendor: "FMTech"},
  {max_prefix: "28-B3-71",vendor: "Ruckus Wireless"},
  {max_prefix: "D4-F8-29",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "DC-50-3A",vendor: "Nanjing Ticom Tech Co., Ltd."},
  {max_prefix: "18-47-3D",vendor: "CHONGQING FUGUI ELECTRONICS CO.,LTD."},
  {max_prefix: "D4-77-B2",vendor: "Netix Global B.V."},
  {max_prefix: "F8-6B-D9",vendor: "Cisco Systems, Inc"},
  {max_prefix: "C0-14-FE",vendor: "Cisco Systems, Inc"},
  {max_prefix: "7C-AD-4F",vendor: "Cisco Systems, Inc"},
  {max_prefix: "60-56-61",vendor: "IXECLOUD Tech"},
  {max_prefix: "70-69-79",vendor: "IEEE Registration Authority"},
  {max_prefix: "24-4B-FE",vendor: "ASUSTek COMPUTER INC."},
  {max_prefix: "7C-DD-E9",vendor: "ATOM tech Inc."},
  {max_prefix: "94-25-33",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "44-F4-E7",vendor: "Cohesity Inc"},
  {max_prefix: "F8-2F-65",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "08-31-A4",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "A8-E9-78",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "E0-62-34",vendor: "Texas Instruments"},
  {max_prefix: "50-38-2F",vendor: "ASE Group Chung-Li"},
  {max_prefix: "70-8C-BB",vendor: "MIMODISPLAYKOREA"},
  {max_prefix: "B8-48-AA",vendor: "EM Microelectronic"},
  {max_prefix: "E0-D4-62",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "90-98-38",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "F8-AC-65",vendor: "Intel Corporate"},
  {max_prefix: "00-19-F4",vendor: "Convergens Oy Ltd"},
  {max_prefix: "F0-B0-22",vendor: "TOHO Electronics INC."},
  {max_prefix: "0C-14-D2",vendor: "China Mobile Group Device Co.,Ltd."},
  {max_prefix: "98-7E-CA",vendor: "Inventus Power Eletronica do Brasil LTDA"},
  {max_prefix: "6C-2F-8A",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "2C-9F-FB",vendor: "Wistron Neweb Corporation"},
  {max_prefix: "84-CC-A8",vendor: "Espressif Inc."},
  {max_prefix: "00-20-60",vendor: "ALCATEL ITALIA S.p.A."},
  {max_prefix: "94-8A-C6",vendor: "Realme Chongqing Mobile Telecommunications Corp.,Ltd."},
  {max_prefix: "44-8D-BF",vendor: "Rhino Mobility LLC"},
  {max_prefix: "C0-A6-6D",vendor: "Inspur Group Co., Ltd."},
  {max_prefix: "AC-7A-56",vendor: "Cisco Systems, Inc"},
  {max_prefix: "D8-91-36",vendor: "Dover Fueling Solutions"},
  {max_prefix: "E0-F6-B5",vendor: "Nintendo Co.,Ltd"},
  {max_prefix: "40-B3-1E",vendor: "Universal Electronics, Inc."},
  {max_prefix: "88-40-33",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "E8-98-6D",vendor: "Palo Alto Networks"},
  {max_prefix: "FC-95-6A",vendor: "OCTAGON SYSTEMS CORP."},
  {max_prefix: "24-C8-D3",vendor: "McWane India Pvt Ltd"},
  {max_prefix: "B8-E3-EE",vendor: "Universal Electronics, Inc."},
  {max_prefix: "C8-83-14",vendor: "Tempo Communications"},
  {max_prefix: "68-B9-D3",vendor: "Shenzhen Trolink Technology CO, LTD"},
  {max_prefix: "90-56-FC",vendor: "TECNO MOBILE LIMITED"},
  {max_prefix: "98-ED-5C",vendor: "Tesla,Inc."},
  {max_prefix: "C4-42-68",vendor: "CRESTRON ELECTRONICS, INC."},
  {max_prefix: "40-F5-20",vendor: "Espressif Inc."},
  {max_prefix: "78-AA-82",vendor: "New H3C Technologies Co., Ltd"},
  {max_prefix: "7C-89-C1",vendor: "Palo Alto Networks"},
  {max_prefix: "84-D6-C5",vendor: "SolarEdge Technologies"},
  {max_prefix: "38-F6-01",vendor: "Solid State Storage Technology Corporation"},
  {max_prefix: "10-70-FD",vendor: "Mellanox Technologies, Inc."},
  {max_prefix: "B8-C6-AA",vendor: "Earda Technologies co Ltd"},
  {max_prefix: "54-AE-D0",vendor: "DASAN Networks, Inc."},
  {max_prefix: "B8-63-92",vendor: "GUANGDONG GENIUS TECHNOLOGY CO., LTD."},
  {max_prefix: "54-71-DD",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "00-06-2A",vendor: "Cisco Systems, Inc"},
  {max_prefix: "6C-F7-12",vendor: "Nokia Solutions and Networks GmbH & Co. KG"},
  {max_prefix: "20-82-6A",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "48-7A-F6",vendor: "NCS ELECTRICAL SDN BHD"},
  {max_prefix: "48-6E-70",vendor: "Zhejiang Tmall Technology Co., Ltd."},
  {max_prefix: "60-1D-9D",vendor: "Sichuan AI-Link Technology Co., Ltd."},
  {max_prefix: "64-09-AC",vendor: "TCT mobile ltd"},
  {max_prefix: "24-B6-57",vendor: "Cisco Systems, Inc"},
  {max_prefix: "5C-55-78",vendor: "iryx corp"},
  {max_prefix: "08-C0-EB",vendor: "Mellanox Technologies, Inc."},
  {max_prefix: "E4-E1-12",vendor: "Texas Instruments"},
  {max_prefix: "34-14-B5",vendor: "Texas Instruments"},
  {max_prefix: "D0-03-EB",vendor: "Texas Instruments"},
  {max_prefix: "08-BF-A0",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "70-1F-3C",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "D4-8A-39",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "E4-F3-C4",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "9C-2F-4E",vendor: "zte corporation"},
  {max_prefix: "D8-A8-C8",vendor: "zte corporation"},
  {max_prefix: "18-9E-2C",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "94-DB-56",vendor: "Sony Home Entertainment&Sound Products Inc"},
  {max_prefix: "38-22-E2",vendor: "HP Inc."},
  {max_prefix: "F0-10-90",vendor: "New H3C Technologies Co., Ltd"},
  {max_prefix: "64-62-66",vendor: "IEEE Registration Authority"},
  {max_prefix: "D8-5F-77",vendor: "Telink Semiconductor (Shanghai) Co., Ltd."},
  {max_prefix: "2C-97-ED",vendor: "Sony Imaging Products & Solutions Inc."},
  {max_prefix: "18-8A-6A",vendor: "AVPro Global Hldgs"},
  {max_prefix: "3C-7D-0A",vendor: "Apple, Inc."},
  {max_prefix: "B4-15-7E",vendor: "Celona Inc."},
  {max_prefix: "64-5E-2C",vendor: "IRay Technology Co., Ltd."},
  {max_prefix: "14-77-40",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "B8-90-47",vendor: "Apple, Inc."},
  {max_prefix: "90-9C-4A",vendor: "Apple, Inc."},
  {max_prefix: "90-8C-43",vendor: "Apple, Inc."},
  {max_prefix: "18-D9-8F",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "28-11-EC",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "00-69-2D",vendor: "Sunnovo International Limited"},
  {max_prefix: "F4-03-2A",vendor: "Amazon Technologies Inc."},
  {max_prefix: "9C-28-F7",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "10-52-1C",vendor: "Espressif Inc."},
  {max_prefix: "80-30-49",vendor: "Liteon Technology Corporation"},
  {max_prefix: "E4-26-86",vendor: "DWnet Technologies(Suzhou) Corporation"},
  {max_prefix: "0C-37-96",vendor: "BIZLINK TECHNOLOGY, INC."},
  {max_prefix: "38-EB-47",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "10-C3-AB",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "94-3B-B0",vendor: "New H3C Technologies Co., Ltd"},
  {max_prefix: "28-9A-F7",vendor: "ADVA Optical Networking Ltd."},
  {max_prefix: "E8-49-43",vendor: "YUGE Information technology Co. Ltd"},
  {max_prefix: "90-43-E2",vendor: "Cornami, Inc"},
  {max_prefix: "50-14-08",vendor: "AiNET"},
  {max_prefix: "F0-F0-A4",vendor: "Amazon Technologies Inc."},
  {max_prefix: "CC-2D-1B",vendor: "SFR"},
  {max_prefix: "B0-B1-94",vendor: "zte corporation"},
  {max_prefix: "A0-2D-13",vendor: "AirTies Wireless Networks"},
  {max_prefix: "3C-57-31",vendor: "Cisco Systems, Inc"},
  {max_prefix: "34-2F-BD",vendor: "Nintendo Co.,Ltd"},
  {max_prefix: "6C-42-AB",vendor: "Subscriber Networks, Inc."},
  {max_prefix: "64-F6-F7",vendor: "Anhui Dynamic Power Co., Ltd."},
  {max_prefix: "94-1C-56",vendor: "Actiontec Electronics, Inc"},
  {max_prefix: "C0-D6-82",vendor: "Arista Networks"},
  {max_prefix: "D8-0B-CB",vendor: "Telink Semiconductor (Shanghai) Co., Ltd."},
  {max_prefix: "9C-FC-E8",vendor: "Intel Corporate"},
  {max_prefix: "84-68-C8",vendor: "TOTOLINK TECHNOLOGY INT\u2018L LIMITED"},
  {max_prefix: "2C-91-AB",vendor: "AVM Audiovisuelles Marketing und Computersysteme GmbH"},
  {max_prefix: "80-7B-3E",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "F8-F1-E6",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "88-A9-B7",vendor: "Apple, Inc."},
  {max_prefix: "EC-CE-D7",vendor: "Apple, Inc."},
  {max_prefix: "AC-90-85",vendor: "Apple, Inc."},
  {max_prefix: "A8-24-B8",vendor: "Nokia"},
  {max_prefix: "64-90-C1",vendor: "Beijing Xiaomi Mobile Software Co., Ltd"},
  {max_prefix: "CC-5C-DE",vendor: "China Mobile Group Device Co.,Ltd."},
  {max_prefix: "4C-4F-EE",vendor: "OnePlus Technology (Shenzhen) Co., Ltd"},
  {max_prefix: "60-CE-86",vendor: "Sercomm Corporation."},
  {max_prefix: "00-09-FC",vendor: "IPFLEX Inc."},
  {max_prefix: "48-EB-62",vendor: "Murata Manufacturing Co., Ltd."},
  {max_prefix: "50-DE-19",vendor: "IEEE Registration Authority"},
  {max_prefix: "D0-05-E4",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "30-AA-E4",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "5C-E1-76",vendor: "Cisco Systems, Inc"},
  {max_prefix: "28-CD-C4",vendor: "CHONGQING FUGUI ELECTRONICS CO.,LTD."},
  {max_prefix: "E8-D8-D1",vendor: "HP Inc."},
  {max_prefix: "90-55-DE",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "E8-91-0F",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "14-AB-56",vendor: "WUXI FUNIDE DIGITAL CO.,LTD"},
  {max_prefix: "4C-E1-75",vendor: "Cisco Systems, Inc"},
  {max_prefix: "50-E5-49",vendor: "GIGA-BYTE TECHNOLOGY CO.,LTD."},
  {max_prefix: "CC-52-89",vendor: "SHENZHEN OPTFOCUS TECHNOLOGY.,LTD"},
  {max_prefix: "CC-5D-78",vendor: "JTD Consulting"},
  {max_prefix: "B0-A4-54",vendor: "Tripwire Inc."},
  {max_prefix: "AC-EB-51",vendor: "Universal Electronics, Inc."},
  {max_prefix: "C4-B2-39",vendor: "Cisco Systems, Inc"},
  {max_prefix: "A8-2B-CD",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "48-DC-2D",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "80-9F-9B",vendor: "Sichuan\u00a0AI-Link\u00a0Technology\u00a0Co.,\u00a0Ltd."},
  {max_prefix: "BC-F9-F2",vendor: "TEKO"},
  {max_prefix: "60-10-A2",vendor: "Crompton Instruments"},
  {max_prefix: "34-9F-7B",vendor: "CANON INC."},
  {max_prefix: "34-F1-50",vendor: "Hui Zhou Gaoshengda Technology Co.,LTD"},
  {max_prefix: "D0-31-10",vendor: "Ingenic Semiconductor Co.,Ltd"},
  {max_prefix: "A8-E7-7D",vendor: "Texas Instruments"},
  {max_prefix: "DC-54-3D",vendor: "ITEL MOBILE LIMITED"},
  {max_prefix: "08-CC-27",vendor: "Motorola Mobility LLC, a Lenovo Company"},
  {max_prefix: "04-D3-95",vendor: "Motorola Mobility LLC, a Lenovo Company"},
  {max_prefix: "E0-98-06",vendor: "Espressif Inc."},
  {max_prefix: "F4-CF-A2",vendor: "Espressif Inc."},
  {max_prefix: "28-77-F1",vendor: "Apple, Inc."},
  {max_prefix: "3C-51-0E",vendor: "Cisco Systems, Inc"},
  {max_prefix: "D8-AE-D0",vendor: "Shanghai Engineering Science & Technology Co.,LTD CGNPC"},
  {max_prefix: "0C-42-A1",vendor: "Mellanox Technologies, Inc."},
  {max_prefix: "E0-85-9A",vendor: "SHENZHEN RF-LINK TECHNOLOGY CO.,LTD."},
  {max_prefix: "74-8A-28",vendor: "HMD Global Oy"},
  {max_prefix: "FC-85-96",vendor: "Axonne Inc."},
  {max_prefix: "50-C6-AD",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "0C-84-47",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "9C-6B-72",vendor: "Realme Chongqing MobileTelecommunications Corp Ltd"},
  {max_prefix: "5C-B4-E2",vendor: "Inspur Software Group Ltd."},
  {max_prefix: "D4-24-93",vendor: "GW Technologies Co.,Ltd"},
  {max_prefix: "6C-41-0E",vendor: "Cisco Systems, Inc"},
  {max_prefix: "14-91-38",vendor: "Amazon Technologies Inc."},
  {max_prefix: "F8-1E-6F",vendor: "EBG compleo GmbH"},
  {max_prefix: "6C-31-0E",vendor: "Cisco Systems, Inc"},
  {max_prefix: "58-D5-0A",vendor: "Murata Manufacturing Co., Ltd."},
  {max_prefix: "14-A3-2F",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "04-D3-B5",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "00-BB-1C",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "DC-4B-FE",vendor: "Shenzhen Belon Technology CO.,LTD"},
  {max_prefix: "50-62-55",vendor: "IEEE Registration Authority"},
  {max_prefix: "80-AC-7C",vendor: "Sichuan\u00a0AI-Link\u00a0Technology\u00a0Co.,\u00a0Ltd."},
  {max_prefix: "88-A3-03",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "FC-DE-90",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "18-54-CF",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "80-79-5D",vendor: "Infinix mobility limited"},
  {max_prefix: "F0-A7-B2",vendor: "FUTABA CORPORATION"},
  {max_prefix: "60-9B-2D",vendor: "JMACS Japan Co., Ltd."},
  {max_prefix: "F0-A3-5A",vendor: "Apple, Inc."},
  {max_prefix: "60-83-73",vendor: "Apple, Inc."},
  {max_prefix: "84-AD-8D",vendor: "Apple, Inc."},
  {max_prefix: "74-42-8B",vendor: "Apple, Inc."},
  {max_prefix: "64-2C-AC",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "E8-84-C6",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "6C-71-0D",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-D2-B1",vendor: "TPV Display Technology (Xiamen) Co.,Ltd."},
  {max_prefix: "1C-44-55",vendor: "Sieb & Meyer AG"},
  {max_prefix: "E0-1F-88",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "80-32-53",vendor: "Intel Corporate"},
  {max_prefix: "C0-E4-34",vendor: "AzureWave Technology Inc."},
  {max_prefix: "5C-E7-A0",vendor: "Nokia"},
  {max_prefix: "8C-DC-02",vendor: "zte corporation"},
  {max_prefix: "D0-3D-52",vendor: "Ava Security Limited"},
  {max_prefix: "24-6F-8C",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "1C-13-86",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "BC-2E-F6",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "44-55-C4",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "10-C6-5E",vendor: "Adapt-IP"},
  {max_prefix: "20-31-1C",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "B4-E8-42",vendor: "Hong Kong Bouffalo Lab Limited"},
  {max_prefix: "7C-A7-B0",vendor: "SHENZHEN BILIAN ELECTRONIC CO.\uff0cLTD"},
  {max_prefix: "68-39-43",vendor: "ittim"},
  {max_prefix: "28-52-F9",vendor: "Zhongxin Intelligent Times (Shenzhen) Co., Ltd."},
  {max_prefix: "B8-F8-53",vendor: "Arcadyan Corporation"},
  {max_prefix: "E0-D0-83",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "D8-0B-9A",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "6C-1D-EB",vendor: "u-blox AG"},
  {max_prefix: "28-7F-CF",vendor: "Intel Corporate"},
  {max_prefix: "80-31-F0",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "74-3C-18",vendor: "Taicang T&W Electronics"},
  {max_prefix: "10-88-CE",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "8C-02-FA",vendor: "COMMANDO Networks Limited"},
  {max_prefix: "4C-80-BA",vendor: "Wuhan Tianyu Information Industry Co., Ltd."},
  {max_prefix: "34-B5-A3",vendor: "CIG SHANGHAI CO LTD"},
  {max_prefix: "AC-8D-34",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "74-31-70",vendor: "Arcadyan Technology Corporation"},
  {max_prefix: "40-11-75",vendor: "IEEE Registration Authority"},
  {max_prefix: "84-A9-EA",vendor: "Career Technologies USA"},
  {max_prefix: "E4-05-F8",vendor: "Bytedance"},
  {max_prefix: "58-35-26",vendor: "DEEPLET TECHNOLOGY CORP"},
  {max_prefix: "28-33-34",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "30-A2-C2",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "F4-74-88",vendor: "New H3C Technologies Co., Ltd"},
  {max_prefix: "DC-DF-D6",vendor: "zte corporation"},
  {max_prefix: "AC-A8-8E",vendor: "SHARP Corporation"},
  {max_prefix: "98-41-5C",vendor: "Nintendo Co.,Ltd"},
  {max_prefix: "84-80-94",vendor: "Meter, Inc."},
  {max_prefix: "10-B3-D5",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-00-84",vendor: "SUPERNET"},
  {max_prefix: "50-A1-32",vendor: "Shenzhen MiaoMing  Intelligent Technology Co.,Ltd"},
  {max_prefix: "80-78-71",vendor: "ASKEY COMPUTER CORP"},
  {max_prefix: "4C-7A-48",vendor: "Nippon Seiki (Europe) B.V."},
  {max_prefix: "5C-0B-CA",vendor: "Tunstall Nordic AB"},
  {max_prefix: "F4-B6-88",vendor: "PLANTRONICS, INC."},
  {max_prefix: "00-0C-86",vendor: "Cisco Systems, Inc"},
  {max_prefix: "08-3A-88",vendor: "Universal Global Scientific Industrial Co., Ltd."},
  {max_prefix: "08-31-8B",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "4C-B1-CD",vendor: "Ruckus Wireless"},
  {max_prefix: "F8-3C-BF",vendor: "BOTATO ELECTRONICS SDN BHD"},
  {max_prefix: "FC-58-9A",vendor: "Cisco Systems, Inc"},
  {max_prefix: "88-51-7A",vendor: "HMD Global Oy"},
  {max_prefix: "AC-B3-B5",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "F4-9C-12",vendor: "Structab AB"},
  {max_prefix: "60-AB-14",vendor: "LG Innotek"},
  {max_prefix: "BC-62-D2",vendor: "Genexis International B.V."},
  {max_prefix: "6C-9E-7C",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "84-D1-5A",vendor: "TCT mobile ltd"},
  {max_prefix: "F0-86-20",vendor: "Arcadyan Corporation"},
  {max_prefix: "DC-CC-8D",vendor: "Integrated Device Technology (Malaysia) Sdn. Bhd."},
  {max_prefix: "B8-F6-53",vendor: "Shenzhen Jingxun Software Telecommunication Technology Co.,Ltd"},
  {max_prefix: "44-67-47",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "88-4A-70",vendor: "Wacom Co.,Ltd."},
  {max_prefix: "A4-63-A1",vendor: "Inventus Power Eletronica do Brasil LTDA"},
  {max_prefix: "3C-9D-56",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "70-FD-45",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "44-D5-F2",vendor: "IEEE Registration Authority"},
  {max_prefix: "0C-DD-24",vendor: "Intel Corporate"},
  {max_prefix: "F0-5C-77",vendor: "Google, Inc."},
  {max_prefix: "00-1D-29",vendor: "Doro AB"},
  {max_prefix: "EC-A5-DE",vendor: "ONYX WIFI Inc"},
  {max_prefix: "8C-49-62",vendor: "Roku, Inc"},
  {max_prefix: "D4-4B-B6",vendor: "Zhejiang Tmall Technology Co., Ltd."},
  {max_prefix: "38-18-4C",vendor: "Sony Home Entertainment&Sound Products Inc"},
  {max_prefix: "34-75-63",vendor: "SHENZHEN RF-LINK TECHNOLOGY CO.,LTD."},
  {max_prefix: "14-2E-5E",vendor: "Sercomm Corporation."},
  {max_prefix: "00-25-CB",vendor: "Reiner SCT"},
  {max_prefix: "44-23-7C",vendor: "Beijing Xiaomi Mobile Software Co., Ltd"},
  {max_prefix: "30-94-35",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "50-EB-71",vendor: "Intel Corporate"},
  {max_prefix: "C4-AC-59",vendor: "Murata Manufacturing Co., Ltd."},
  {max_prefix: "58-16-D7",vendor: "ALPSALPINE CO,.LTD"},
  {max_prefix: "FC-A4-7A",vendor: "IEEE Registration Authority"},
  {max_prefix: "6C-D7-1F",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "F0-68-65",vendor: "Taicang T&W Electronics"},
  {max_prefix: "F4-B5-BB",vendor: "CERAGON NETWORKS"},
  {max_prefix: "50-7A-C5",vendor: "Apple, Inc."},
  {max_prefix: "4C-6B-E8",vendor: "Apple, Inc."},
  {max_prefix: "8C-86-1E",vendor: "Apple, Inc."},
  {max_prefix: "54-2B-8D",vendor: "Apple, Inc."},
  {max_prefix: "00-0A-17",vendor: "NESTAR COMMUNICATIONS, INC"},
  {max_prefix: "38-1A-52",vendor: "Seiko Epson Corporation"},
  {max_prefix: "C0-64-E4",vendor: "Cisco Systems, Inc"},
  {max_prefix: "D8-2F-E6",vendor: "Zhejiang Tmall Technology Co., Ltd."},
  {max_prefix: "E4-FD-A1",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "E4-19-C1",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "B8-66-85",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "B4-52-A9",vendor: "Texas Instruments"},
  {max_prefix: "14-0F-42",vendor: "Nokia"},
  {max_prefix: "00-6D-61",vendor: "Guangzhou V-SOLUTION Electronic Technology Co., Ltd."},
  {max_prefix: "5C-87-9C",vendor: "Intel Corporate"},
  {max_prefix: "24-EE-9A",vendor: "Intel Corporate"},
  {max_prefix: "D8-AF-81",vendor: "AO"},
  {max_prefix: "D0-C6-5B",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "90-78-B2",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "78-C3-13",vendor: "China Mobile Group Device Co.,Ltd."},
  {max_prefix: "74-34-AE",vendor: "this is engineering Inc."},
  {max_prefix: "74-AD-B7",vendor: "China Mobile Group Device Co.,Ltd."},
  {max_prefix: "78-CC-2B",vendor: "SINEWY TECHNOLOGY CO., LTD"},
  {max_prefix: "D0-C8-57",vendor: "IEEE Registration Authority"},
  {max_prefix: "94-D0-0D",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "C4-8A-5A",vendor: "JFCONTROL"},
  {max_prefix: "B4-9A-95",vendor: "Shenzhen Boomtech Industrial Corporation"},
  {max_prefix: "00-09-70",vendor: "Vibration Research Corporation"},
  {max_prefix: "14-A2-A0",vendor: "Cisco Systems, Inc"},
  {max_prefix: "54-EF-44",vendor: "Lumi United Technology Co., Ltd"},
  {max_prefix: "E4-AB-89",vendor: "MitraStar Technology Corp."},
  {max_prefix: "1C-20-DB",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "8C-FD-18",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "FC-BC-D1",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "74-60-FA",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "C4-06-83",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "50-D4-F7",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "00-1F-47",vendor: "MCS Logic Inc."},
  {max_prefix: "B4-54-59",vendor: "China Mobile (Hangzhou) Information Technology Co., Ltd."},
  {max_prefix: "FC-49-2D",vendor: "Amazon Technologies Inc."},
  {max_prefix: "74-EE-2A",vendor: "SHENZHEN BILIAN ELECTRONIC CO.\uff0cLTD"},
  {max_prefix: "08-00-39",vendor: "SPIDER SYSTEMS LIMITED"},
  {max_prefix: "88-9E-33",vendor: "TCT mobile ltd"},
  {max_prefix: "6C-8A-EC",vendor: "Nantong Coship Electronics Co., Ltd."},
  {max_prefix: "84-C2-E4",vendor: "Jiangsu Qinheng Co., Ltd."},
  {max_prefix: "8C-E5-C0",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "F0-8A-76",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "EC-AA-25",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "68-7D-6B",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "48-51-69",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "B4-CF-E0",vendor: "Sichuan tianyi kanghe communications co., LTD"},
  {max_prefix: "BC-7F-A4",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "AC-83-E9",vendor: "Beijing Zile Technology Co., Ltd"},
  {max_prefix: "D8-CA-06",vendor: "Titan DataCenters France"},
  {max_prefix: "60-95-CE",vendor: "IEEE Registration Authority"},
  {max_prefix: "D8-F1-5B",vendor: "Espressif Inc."},
  {max_prefix: "6C-F1-7E",vendor: "Zhejiang Uniview Technologies Co.,Ltd."},
  {max_prefix: "90-47-3C",vendor: "China Mobile Group Device Co.,Ltd."},
  {max_prefix: "7C-21-D8",vendor: "Shenzhen Think Will Communication Technology co., LTD."},
  {max_prefix: "FC-EA-50",vendor: "Integrated Device Technology (Malaysia) Sdn. Bhd."},
  {max_prefix: "00-E0-6B",vendor: "W&G SPECIAL PRODUCTS"},
  {max_prefix: "D4-F0-57",vendor: "Nintendo Co.,Ltd"},
  {max_prefix: "48-87-64",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "5C-1C-B9",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "C0-FD-84",vendor: "zte corporation"},
  {max_prefix: "44-4B-7E",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "E8-D0-FC",vendor: "Liteon Technology Corporation"},
  {max_prefix: "E8-E8-B7",vendor: "Murata Manufacturing Co., Ltd."},
  {max_prefix: "08-3A-2F",vendor: "Guangzhou Juan Intelligent Tech Joint Stock Co.,Ltd"},
  {max_prefix: "1C-3A-60",vendor: "Ruckus Wireless"},
  {max_prefix: "60-09-C3",vendor: "u-blox AG"},
  {max_prefix: "DC-8C-37",vendor: "Cisco Systems, Inc"},
  {max_prefix: "10-3D-3E",vendor: "China Mobile Group Device Co.,Ltd."},
  {max_prefix: "64-CC-22",vendor: "Arcadyan Corporation"},
  {max_prefix: "4C-91-57",vendor: "Fujian LANDI Commercial Equipment Co.,Ltd"},
  {max_prefix: "9C-25-BE",vendor: "Wildlife Acoustics, Inc."},
  {max_prefix: "D0-39-EA",vendor: "NetApp"},
  {max_prefix: "F8-DF-E1",vendor: "MyLight Systems"},
  {max_prefix: "78-8C-77",vendor: "LEXMARK INTERNATIONAL, INC."},
  {max_prefix: "3C-0C-7D",vendor: "Tiny Mesh AS"},
  {max_prefix: "34-76-C5",vendor: "I-O DATA DEVICE,INC."},
  {max_prefix: "60-D2-DD",vendor: "Shenzhen Baitong Putian Technology Co.,Ltd."},
  {max_prefix: "5C-32-C5",vendor: "Teracom Ltd."},
  {max_prefix: "24-DA-33",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "FC-AB-90",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "A4-97-5C",vendor: "VTech Telecommunications Ltd."},
  {max_prefix: "1C-27-04",vendor: "zte corporation"},
  {max_prefix: "50-78-B3",vendor: "zte corporation"},
  {max_prefix: "F0-D4-F7",vendor: "varram system"},
  {max_prefix: "E0-CC-7A",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "B0-2A-1F",vendor: " Wingtech Group (HongKong\uff09Limited"},
  {max_prefix: "58-93-D8",vendor: "Texas Instruments"},
  {max_prefix: "50-51-A9",vendor: "Texas Instruments"},
  {max_prefix: "F4-79-60",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "14-52-90",vendor: "KNS Group LLC (YADRO Company)"},
  {max_prefix: "6C-23-CB",vendor: "Wattty Corporation"},
  {max_prefix: "60-AB-67",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "AC-71-0C",vendor: "China Mobile Group Device Co.,Ltd."},
  {max_prefix: "A8-DB-03",vendor: "SAMSUNG ELECTRO-MECHANICS(THAILAND)"},
  {max_prefix: "30-89-44",vendor: "DEVA Broadcast Ltd."},
  {max_prefix: "00-B6-00",vendor: "VOIM Co., Ltd."},
  {max_prefix: "00-26-15",vendor: "Teracom Limited"},
  {max_prefix: "9C-8E-DC",vendor: "Teracom Limited"},
  {max_prefix: "00-01-91",vendor: "SYRED Data Systems"},
  {max_prefix: "48-89-E7",vendor: "Intel Corporate"},
  {max_prefix: "A0-BD-1D",vendor: "Zhejiang Dahua Technology Co., Ltd."},
  {max_prefix: "B4-D0-A9",vendor: "China Mobile Group Device Co.,Ltd."},
  {max_prefix: "48-46-C1",vendor: "FN-LINK TECHNOLOGY LIMITED"},
  {max_prefix: "AC-E2-D3",vendor: "Hewlett Packard"},
  {max_prefix: "00-FD-22",vendor: "Cisco Systems, Inc"},
  {max_prefix: "AC-EE-70",vendor: "Fontem Ventures BV"},
  {max_prefix: "44-18-FD",vendor: "Apple, Inc."},
  {max_prefix: "00-5B-94",vendor: "Apple, Inc."},
  {max_prefix: "E0-89-7E",vendor: "Apple, Inc."},
  {max_prefix: "2C-CC-E6",vendor: "Skyworth Digital Technology(Shenzhen) Co.,Ltd"},
  {max_prefix: "CC-2C-83",vendor: "DarkMatter L.L.C"},
  {max_prefix: "DC-ED-84",vendor: "Haverford Systems Inc"},
  {max_prefix: "64-4C-36",vendor: "Intel Corporate"},
  {max_prefix: "AC-D5-64",vendor: "CHONGQING FUGUI ELECTRONICS CO.,LTD."},
  {max_prefix: "94-D0-75",vendor: "CIS Crypto"},
  {max_prefix: "28-B4-FB",vendor: "Sprocomm Technologies CO.,LTD."},
  {max_prefix: "C0-53-36",vendor: "Beijing National Railway Research & Design Institute of Signal & Communication Group Co..Ltd."},
  {max_prefix: "60-6E-D0",vendor: "SEAL AG"},
  {max_prefix: "40-F9-D5",vendor: "Tecore Networks"},
  {max_prefix: "E4-4C-C7",vendor: "IEEE Registration Authority"},
  {max_prefix: "D4-E8-80",vendor: "Cisco Systems, Inc"},
  {max_prefix: "3C-20-F6",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "7C-38-AD",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "2C-01-B5",vendor: "Cisco Systems, Inc"},
  {max_prefix: "A8-34-6A",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "FC-29-F3",vendor: "McPay Co.,LTD."},
  {max_prefix: "F8-AF-DB",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "68-8B-0F",vendor: "China Mobile IOT Company Limited"},
  {max_prefix: "3C-F0-11",vendor: "Intel Corporate"},
  {max_prefix: "D4-9C-DD",vendor: "AMPAK Technology,Inc."},
  {max_prefix: "50-F7-22",vendor: "Cisco Systems, Inc"},
  {max_prefix: "98-09-CF",vendor: "OnePlus Technology (Shenzhen) Co., Ltd"},
  {max_prefix: "B8-DE-5E",vendor: "LONGCHEER TELECOMMUNICATION LIMITED"},
  {max_prefix: "98-49-E1",vendor: "Boeing Defence Australia"},
  {max_prefix: "04-D7-A5",vendor: "New H3C Technologies Co., Ltd"},
  {max_prefix: "88-5A-06",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "54-47-D3",vendor: "TSAT AS"},
  {max_prefix: "4C-4D-66",vendor: "Nanjing Jiahao Technology Co., Ltd."},
  {max_prefix: "A4-81-7A",vendor: "CIG SHANGHAI CO LTD"},
  {max_prefix: "CC-ED-DC",vendor: "MitraStar Technology Corp."},
  {max_prefix: "20-B7-80",vendor: "Toshiba Visual Solutions Corporation Co.,Ltd"},
  {max_prefix: "A4-E7-E4",vendor: "Connex GmbH"},
  {max_prefix: "F0-B3-1E",vendor: "Universal Electronics, Inc."},
  {max_prefix: "EC-A9-FA",vendor: "GUANGDONG GENIUS TECHNOLOGY CO., LTD."},
  {max_prefix: "00-03-A5",vendor: "Medea Corporation"},
  {max_prefix: "BC-E6-7C",vendor: "Cambium Networks Limited"},
  {max_prefix: "7C-1E-06",vendor: "New H3C Technologies Co., Ltd"},
  {max_prefix: "18-DF-B4",vendor: "BOSUNG POWERTEC CO.,LTD."},
  {max_prefix: "F8-91-73",vendor: "AEDLE SAS"},
  {max_prefix: "B8-EF-8B",vendor: "SHENZHEN CANNICE TECHNOLOGY CO.,LTD"},
  {max_prefix: "B8-18-6F",vendor: "ORIENTAL MOTOR CO., LTD."},
  {max_prefix: "00-1A-3F",vendor: "Intelbras"},
  {max_prefix: "54-47-41",vendor: "XCHENG HOLDING"},
  {max_prefix: "CC-F7-35",vendor: "Amazon Technologies Inc."},
  {max_prefix: "C4-F8-39",vendor: "Actia Automotive"},
  {max_prefix: "C8-4F-86",vendor: "Sophos Ltd"},
  {max_prefix: "64-29-ED",vendor: "AO PKK Milandr"},
  {max_prefix: "F8-2F-6A",vendor: "ITEL MOBILE LIMITED"},
  {max_prefix: "C0-D8-34",vendor: "xvtec ltd"},
  {max_prefix: "44-3C-88",vendor: "FICOSA MAROC INTERNATIONAL"},
  {max_prefix: "84-1C-70",vendor: "zte corporation"},
  {max_prefix: "78-DD-12",vendor: "Arcadyan Corporation"},
  {max_prefix: "D4-25-CC",vendor: "IEEE Registration Authority"},
  {max_prefix: "8C-6D-C4",vendor: "Megapixel VR"},
  {max_prefix: "C8-F7-42",vendor: "HangZhou Gubei Electronics Technology Co.,Ltd"},
  {max_prefix: "00-6F-F2",vendor: "MITSUMI ELECTRIC CO.,LTD."},
  {max_prefix: "30-DF-8D",vendor: "SHENZHEN GONGJIN ELECTRONICS CO.,LT"},
  {max_prefix: "D4-C9-3C",vendor: "Cisco Systems, Inc"},
  {max_prefix: "2C-5D-34",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "9C-14-63",vendor: "Zhejiang Dahua Technology Co., Ltd."},
  {max_prefix: "E0-75-0A",vendor: "ALPSALPINE CO,.LTD"},
  {max_prefix: "00-19-C1",vendor: "ALPSALPINE CO,.LTD"},
  {max_prefix: "00-16-FE",vendor: "ALPSALPINE CO,.LTD"},
  {max_prefix: "9C-8D-7C",vendor: "ALPSALPINE CO,.LTD"},
  {max_prefix: "00-24-33",vendor: "ALPSALPINE CO,.LTD"},
  {max_prefix: "00-23-06",vendor: "ALPSALPINE CO,.LTD"},
  {max_prefix: "B4-EC-02",vendor: "ALPSALPINE CO,.LTD"},
  {max_prefix: "CC-D3-9D",vendor: "IEEE Registration Authority"},
  {max_prefix: "BC-75-36",vendor: "ALPSALPINE CO,.LTD"},
  {max_prefix: "E0-AE-5E",vendor: "ALPSALPINE CO,.LTD"},
  {max_prefix: "D4-B7-61",vendor: "Sichuan\u00a0AI-Link\u00a0Technology\u00a0Co.,\u00a0Ltd."},
  {max_prefix: "7C-03-5E",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "44-FE-3B",vendor: "Arcadyan Corporation"},
  {max_prefix: "D8-3A-F5",vendor: "Wideband Labs LLC"},
  {max_prefix: "38-D9-A5",vendor: "Mikotek Information Inc."},
  {max_prefix: "64-60-38",vendor: "Hirschmann Automation and Control GmbH"},
  {max_prefix: "64-9D-99",vendor: "FS COM INC"},
  {max_prefix: "00-16-9D",vendor: "Cisco Systems, Inc"},
  {max_prefix: "4C-87-5D",vendor: "Bose Corporation"},
  {max_prefix: "B0-E7-DE",vendor: "Homa Technologies JSC"},
  {max_prefix: "00-D2-79",vendor: "VINGROUP JOINT STOCK COMPANY"},
  {max_prefix: "00-00-4C",vendor: "NEC Corporation"},
  {max_prefix: "8C-CF-8F",vendor: "ITC Systems"},
  {max_prefix: "4C-96-2D",vendor: "Fresh AB"},
  {max_prefix: "48-4A-30",vendor: "George Robotics Limited"},
  {max_prefix: "48-61-A3",vendor: "Concern Axion JSC"},
  {max_prefix: "4C-E5-AE",vendor: "Tianjin Beebox Intelligent Technology Co.,Ltd."},
  {max_prefix: "F4-C7-C8",vendor: "Kelvin Inc."},
  {max_prefix: "30-4A-26",vendor: "Shenzhen Trolink Technology CO, LTD"},
  {max_prefix: "D4-67-D3",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "A4-12-32",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "48-E3-C3",vendor: "JENOPTIK Advanced Systems GmbH"},
  {max_prefix: "CC-35-5A",vendor: "SecuGen Corporation"},
  {max_prefix: "80-54-6A",vendor: "SHENZHEN GONGJIN ELECTRONICS CO.,LT"},
  {max_prefix: "B4-47-F5",vendor: "Earda Technologies co Ltd"},
  {max_prefix: "1C-F2-9A",vendor: "Google, Inc."},
  {max_prefix: "A8-9C-A4",vendor: "Furrion Limited"},
  {max_prefix: "28-75-D8",vendor: "FUJIAN STAR-NET COMMUNICATION CO.,LTD"},
  {max_prefix: "90-E2-02",vendor: "Texas Instruments"},
  {max_prefix: "30-24-78",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "18-4B-DF",vendor: "Caavo Inc"},
  {max_prefix: "FC-77-74",vendor: "Intel Corporate"},
  {max_prefix: "70-0B-4F",vendor: "Cisco Systems, Inc"},
  {max_prefix: "E4-38-8C",vendor: "Digital Products Limited"},
  {max_prefix: "44-55-B1",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "98-F9-C7",vendor: "IEEE Registration Authority"},
  {max_prefix: "A4-0C-66",vendor: "Shenzhen Colorful Yugong Technology and Development Co., Ltd."},
  {max_prefix: "84-A9-3E",vendor: "Hewlett Packard"},
  {max_prefix: "34-93-42",vendor: "TTE Corporation"},
  {max_prefix: "58-9E-C6",vendor: "Gigaset Communications GmbH"},
  {max_prefix: "B0-C3-87",vendor: "GOEFER, Inc."},
  {max_prefix: "08-6B-D7",vendor: "Silicon Laboratories"},
  {max_prefix: "64-58-AD",vendor: "China Mobile IOT Company Limited"},
  {max_prefix: "A0-A3-B8",vendor: "WISCLOUD"},
  {max_prefix: "38-F9-D3",vendor: "Apple, Inc."},
  {max_prefix: "FC-18-3C",vendor: "Apple, Inc."},
  {max_prefix: "64-C7-53",vendor: "Apple, Inc."},
  {max_prefix: "20-1F-31",vendor: "Inteno Broadband Technology AB"},
  {max_prefix: "6C-2C-DC",vendor: "Skyworth Digital Technology(Shenzhen) Co.,Ltd"},
  {max_prefix: "78-35-A0",vendor: "Zurn Industries LLC"},
  {max_prefix: "8C-7B-F0",vendor: "Xufeng Development Limited"},
  {max_prefix: "E0-A5-09",vendor: "Bitmain Technologies Inc"},
  {max_prefix: "3C-5C-C4",vendor: "Amazon Technologies Inc."},
  {max_prefix: "D8-A7-56",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "54-83-3A",vendor: "Zyxel Communications Corporation"},
  {max_prefix: "F4-39-09",vendor: "Hewlett Packard"},
  {max_prefix: "B8-9A-9A",vendor: "Xin Shi Jia Technology (Beijing) Co.,Ltd"},
  {max_prefix: "2C-CC-44",vendor: "Sony Interactive Entertainment Inc."},
  {max_prefix: "F4-7D-EF",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "7C-8B-B5",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "D8-A9-8B",vendor: "Texas Instruments"},
  {max_prefix: "D8-D6-F3",vendor: "Integrated Device Technology (Malaysia) Sdn. Bhd."},
  {max_prefix: "78-70-52",vendor: "Welotec GmbH"},
  {max_prefix: "14-EF-CF",vendor: "SCHREDER"},
  {max_prefix: "38-30-F9",vendor: "LG Electronics (Mobile Communications)"},
  {max_prefix: "00-F4-8D",vendor: "Liteon Technology Corporation"},
  {max_prefix: "70-2E-D9",vendor: "Guangzhou Shiyuan Electronics Co., Ltd."},
  {max_prefix: "70-19-2F",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "A8-3F-A1",vendor: "IEEE Registration Authority"},
  {max_prefix: "F4-DB-E6",vendor: "Cisco Systems, Inc"},
  {max_prefix: "10-B9-F7",vendor: "Niko-Servodan"},
  {max_prefix: "18-A7-F1",vendor: "Qingdao Haier Technology Co.,Ltd"},
  {max_prefix: "90-E1-7B",vendor: "Apple, Inc."},
  {max_prefix: "D8-1C-79",vendor: "Apple, Inc."},
  {max_prefix: "58-E6-BA",vendor: "Apple, Inc."},
  {max_prefix: "24-84-98",vendor: "Beijing Jiaoda Microunion Tech.Co.,Ltd."},
  {max_prefix: "6C-9B-C0",vendor: "Chemoptics Inc."},
  {max_prefix: "04-EB-40",vendor: "Cisco Systems, Inc"},
  {max_prefix: "18-01-F1",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "C0-74-AD",vendor: "Grandstream Networks, Inc."},
  {max_prefix: "F0-95-F1",vendor: "Carl Zeiss AG"},
  {max_prefix: "C0-84-7D",vendor: "AMPAK Technology, Inc."},
  {max_prefix: "44-E4-EE",vendor: "Wistron Neweb Corporation"},
  {max_prefix: "DC-41-E5",vendor: "Shenzhen Zhixin Data Service Co., Ltd."},
  {max_prefix: "10-C2-2F",vendor: "China Entropy Co., Ltd."},
  {max_prefix: "BC-38-65",vendor: "JWCNETWORKS"},
  {max_prefix: "04-09-A5",vendor: "HFR, Inc."},
  {max_prefix: "94-91-7F",vendor: "ASKEY COMPUTER CORP"},
  {max_prefix: "9C-0C-DF",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "7C-D3-0A",vendor: "INVENTEC CORPORATION"},
  {max_prefix: "00-1E-33",vendor: "INVENTEC CORPORATION"},
  {max_prefix: "FC-1D-84",vendor: "Autobase"},
  {max_prefix: "00-A5-BF",vendor: "Cisco Systems, Inc"},
  {max_prefix: "C8-BA-E9",vendor: "QDIS"},
  {max_prefix: "C4-4F-33",vendor: "Espressif Inc."},
  {max_prefix: "54-6A-D8",vendor: "Elster Water Metering"},
  {max_prefix: "00-26-54",vendor: "3COM"},
  {max_prefix: "00-50-DA",vendor: "3COM"},
  {max_prefix: "00-04-76",vendor: "3COM"},
  {max_prefix: "00-04-75",vendor: "3COM"},
  {max_prefix: "00-D0-96",vendor: "3COM EUROPE LTD"},
  {max_prefix: "30-09-F9",vendor: "IEEE Registration Authority"},
  {max_prefix: "B4-DD-D0",vendor: "Continental Automotive Hungary Kft"},
  {max_prefix: "48-F0-27",vendor: "Chengdu newifi Co.,Ltd"},
  {max_prefix: "B4-2E-99",vendor: "GIGA-BYTE TECHNOLOGY CO.,LTD."},
  {max_prefix: "34-2C-C4",vendor: "Compal Broadband Networks, Inc."},
  {max_prefix: "DC-16-B2",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "24-FB-65",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "0C-B5-27",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "7C-03-AB",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "C8-54-4B",vendor: "Zyxel Communications Corporation"},
  {max_prefix: "14-C6-97",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "24-21-24",vendor: "Nokia"},
  {max_prefix: "18-AC-9E",vendor: "ITEL MOBILE LIMITED"},
  {max_prefix: "EC-84-B4",vendor: "CIG SHANGHAI CO LTD"},
  {max_prefix: "44-22-F1",vendor: "S.FAC, INC"},
  {max_prefix: "78-52-4A",vendor: "Optonic GmbH"},
  {max_prefix: "C4-6E-7B",vendor: "SHENZHEN RF-LINK TECHNOLOGY CO.,LTD."},
  {max_prefix: "C0-48-FB",vendor: "Shenzhen JingHanDa Electronics Co.Ltd"},
  {max_prefix: "20-E8-82",vendor: "zte corporation"},
  {max_prefix: "4C-11-59",vendor: "Vision Information & Communications"},
  {max_prefix: "00-B5-D0",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "14-96-E5",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "D0-7F-A0",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "00-90-93",vendor: "EIZO Corporation"},
  {max_prefix: "F0-9F-FC",vendor: "SHARP Corporation"},
  {max_prefix: "0C-B5-DE",vendor: "Alcatel Lucent"},
  {max_prefix: "50-DC-FC",vendor: "ECOCOM"},
  {max_prefix: "00-0B-3B",vendor: "devolo AG"},
  {max_prefix: "24-05-88",vendor: "Google, Inc."},
  {max_prefix: "00-04-9F",vendor: "Freescale Semiconductor"},
  {max_prefix: "00-D0-7B",vendor: "COMCAM INTERNATIONAL INC"},
  {max_prefix: "14-E9-B2",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "70-0B-01",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "5C-26-23",vendor: "WaveLynx Technologies Corporation"},
  {max_prefix: "30-38-55",vendor: "Nokia Corporation"},
  {max_prefix: "00-B6-70",vendor: "Cisco Systems, Inc"},
  {max_prefix: "AC-64-17",vendor: "Siemens AG"},
  {max_prefix: "EC-6F-0B",vendor: "FADU, Inc."},
  {max_prefix: "34-66-EA",vendor: "VERTU INTERNATIONAL CORPORATION LIMITED"},
  {max_prefix: "28-38-5C",vendor: "FLEXTRONICS"},
  {max_prefix: "00-BB-60",vendor: "Intel Corporate"},
  {max_prefix: "7C-6D-A6",vendor: "Superwave Group LLC"},
  {max_prefix: "B8-70-F4",vendor: "COMPAL INFORMATION (KUNSHAN) CO., LTD."},
  {max_prefix: "1C-39-47",vendor: "COMPAL INFORMATION (KUNSHAN) CO., LTD."},
  {max_prefix: "34-27-92",vendor: "FREEBOX SAS"},
  {max_prefix: "88-3F-99",vendor: "Siemens AG"},
  {max_prefix: "70-5A-B6",vendor: "COMPAL INFORMATION (KUNSHAN) CO., LTD."},
  {max_prefix: "20-1A-06",vendor: "COMPAL INFORMATION (KUNSHAN) CO., LTD."},
  {max_prefix: "F8-A9-63",vendor: "COMPAL INFORMATION (KUNSHAN) CO., LTD."},
  {max_prefix: "DC-0E-A1",vendor: "COMPAL INFORMATION (KUNSHAN) CO., LTD."},
  {max_prefix: "00-06-EC",vendor: "Harris Corporation"},
  {max_prefix: "0C-1C-57",vendor: "Texas Instruments"},
  {max_prefix: "80-6F-B0",vendor: "Texas Instruments"},
  {max_prefix: "34-79-16",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "D0-16-B4",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "F0-F0-8F",vendor: "Nextek Solutions Pte Ltd"},
  {max_prefix: "8C-B0-E9",vendor: "Samsung Electronics.,LTD"},
  {max_prefix: "A4-61-91",vendor: "NamJunSa"},
  {max_prefix: "40-A1-08",vendor: "Motorola (Wuhan) Mobility Technologies Communication Co., Ltd."},
  {max_prefix: "74-59-33",vendor: "Danal Entertainment"},
  {max_prefix: "EC-58-EA",vendor: "Ruckus Wireless"},
  {max_prefix: "84-A2-4D",vendor: "Birds Eye Systems Private Limited"},
  {max_prefix: "7C-6B-9C",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "00-9D-6B",vendor: "Murata Manufacturing Co., Ltd."},
  {max_prefix: "D4-52-51",vendor: "IBT Ingenieurbureau Broennimann Thun"},
  {max_prefix: "00-18-B5",vendor: "Magna Carta"},
  {max_prefix: "D8-7E-B1",vendor: "x.o.ware, inc."},
  {max_prefix: "20-A8-B9",vendor: "SIEMENS AG"},
  {max_prefix: "D0-EF-C1",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "48-57-02",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "00-17-B6",vendor: "Aquantia Corporation"},
  {max_prefix: "10-59-17",vendor: "Tonal"},
  {max_prefix: "38-8B-59",vendor: "Google, Inc."},
  {max_prefix: "88-01-18",vendor: "BLT Co"},
  {max_prefix: "A4-26-18",vendor: "Integrated Device Technology (Malaysia) Sdn. Bhd."},
  {max_prefix: "34-E1-2D",vendor: "Intel Corporate"},
  {max_prefix: "DC-AF-68",vendor: "WEIFANG GOERTEK ELECTRONICS CO.,LTD"},
  {max_prefix: "88-2D-53",vendor: "Baidu Online Network Technology (Beijing) Co., Ltd."},
  {max_prefix: "64-4F-42",vendor: "JETTER CO., Ltd."},
  {max_prefix: "2C-97-B1",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "24-EC-51",vendor: "ADF Technologies Sdn Bhd"},
  {max_prefix: "70-89-CC",vendor: "China Mobile Group Device Co.,Ltd."},
  {max_prefix: "2C-5B-E1",vendor: "Centripetal Networks, Inc"},
  {max_prefix: "DC-EF-CA",vendor: "Murata Manufacturing Co., Ltd."},
  {max_prefix: "00-BC-60",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-D0-B5",vendor: "IPricot formerly DotCom"},
  {max_prefix: "CC-7B-61",vendor: "NIKKISO CO., LTD."},
  {max_prefix: "9C-71-3A",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "B8-B7-F1",vendor: "Wistron Neweb Corporation"},
  {max_prefix: "80-50-F6",vendor: "ITEL MOBILE LIMITED"},
  {max_prefix: "A8-CA-B9",vendor: "SAMSUNG ELECTRO MECHANICS CO., LTD."},
  {max_prefix: "50-61-BF",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-09-DF",vendor: "Vestel Elektronik San ve Tic. A.S."},
  {max_prefix: "00-0F-A2",vendor: "2xWireless"},
  {max_prefix: "74-6B-AB",vendor: "GUANGDONG ENOK COMMUNICATION CO., LTD"},
  {max_prefix: "0C-B6-D2",vendor: "D-Link International"},
  {max_prefix: "78-29-ED",vendor: "ASKEY COMPUTER CORP"},
  {max_prefix: "00-0B-B2",vendor: "SMALLBIG TECHNOLOGY"},
  {max_prefix: "F4-03-2F",vendor: "Reduxio Systems"},
  {max_prefix: "94-4A-0C",vendor: "Sercomm Corporation."},
  {max_prefix: "70-0F-6A",vendor: "Cisco Systems, Inc"},
  {max_prefix: "10-8E-E0",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "FC-A6-21",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "8C-F2-28",vendor: "MERCURY COMMUNICATION TECHNOLOGIES CO.,LTD."},
  {max_prefix: "80-02-9C",vendor: "Gemtek Technology Co., Ltd."},
  {max_prefix: "E8-D0-99",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "00-30-5E",vendor: "Abelko Innovation"},
  {max_prefix: "FC-6B-F0",vendor: "TOPWELL INTERNATIONAL HOLDINDS LIMITED"},
  {max_prefix: "00-14-77",vendor: "Trilliant"},
  {max_prefix: "00-07-9B",vendor: "Aurora Networks"},
  {max_prefix: "54-B2-03",vendor: "PEGATRON CORPORATION"},
  {max_prefix: "38-68-DD",vendor: "INVENTEC CORPORATION"},
  {max_prefix: "3C-6A-A7",vendor: "Intel Corporate"},
  {max_prefix: "A0-28-ED",vendor: "HMD Global Oy"},
  {max_prefix: "D0-C5-D3",vendor: "AzureWave Technology Inc."},
  {max_prefix: "14-16-9E",vendor: " Wingtech Group (HongKong\uff09Limited"},
  {max_prefix: "10-7B-A4",vendor: "Olive & Dove Co.,Ltd."},
  {max_prefix: "20-39-56",vendor: "HMD Global Oy"},
  {max_prefix: "78-AF-E4",vendor: "Comau S.p.A"},
  {max_prefix: "90-A1-37",vendor: "Beijing Splendidtel Communication Technology Co,. Ltd"},
  {max_prefix: "BC-32-5F",vendor: "Zhejiang Dahua Technology Co., Ltd."},
  {max_prefix: "AC-54-74",vendor: "China Mobile IOT Company Limited"},
  {max_prefix: "8C-1C-DA",vendor: "IEEE Registration Authority"},
  {max_prefix: "7C-41-A2",vendor: "Nokia"},
  {max_prefix: "F8-C3-9E",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "50-5B-C2",vendor: "Liteon Technology Corporation"},
  {max_prefix: "6C-21-A2",vendor: "AMPAK Technology, Inc."},
  {max_prefix: "9C-2F-73",vendor: "Universal Tiancheng Technology (Beijing) Co., Ltd."},
  {max_prefix: "D8-32-E3",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "94-87-E0",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "38-AF-29",vendor: "Zhejiang Dahua Technology Co., Ltd."},
  {max_prefix: "30-0A-C5",vendor: "Ruio telecommunication technologies Co., Limited"},
  {max_prefix: "00-E0-65",vendor: "OPTICAL ACCESS INTERNATIONAL"},
  {max_prefix: "C8-86-29",vendor: "Shenzhen Duubee Intelligent Technologies Co.,LTD."},
  {max_prefix: "CC-C2-E0",vendor: "Raisecom Technology CO., LTD"},
  {max_prefix: "88-3F-4A",vendor: "Texas Instruments"},
  {max_prefix: "D8-8A-3B",vendor: "UNIT-EM"},
  {max_prefix: "EC-5A-86",vendor: "Yulong Computer Telecommunication Scientific (Shenzhen) Co.,Ltd"},
  {max_prefix: "C4-BA-A3",vendor: "Beijing Winicssec Technologies Co., Ltd."},
  {max_prefix: "9C-FE-A1",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "44-66-FC",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "50-A0-09",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "00-1D-FA",vendor: "Fujian LANDI Commercial Equipment Co.,Ltd"},
  {max_prefix: "98-14-D2",vendor: "Avonic"},
  {max_prefix: "08-F6-9C",vendor: "Apple, Inc."},
  {max_prefix: "04-FA-83",vendor: "Qingdao Haier Technology Co.,Ltd"},
  {max_prefix: "78-F9-B4",vendor: "Nokia Solutions and Networks GmbH & Co. KG"},
  {max_prefix: "20-16-B9",vendor: "Intel Corporate"},
  {max_prefix: "00-16-5C",vendor: "Trackflow Ltd."},
  {max_prefix: "50-A6-7F",vendor: "Apple, Inc."},
  {max_prefix: "60-DE-F3",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "14-09-DC",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "38-BA-F8",vendor: "Intel Corporate"},
  {max_prefix: "00-07-A8",vendor: "Haier Group Technologies Ltd"},
  {max_prefix: "14-20-5E",vendor: "Apple, Inc."},
  {max_prefix: "B8-41-A4",vendor: "Apple, Inc."},
  {max_prefix: "9C-E6-5E",vendor: "Apple, Inc."},
  {max_prefix: "C4-98-80",vendor: "Apple, Inc."},
  {max_prefix: "E0-33-8E",vendor: "Apple, Inc."},
  {max_prefix: "C0-EE-B5",vendor: "Enice Network."},
  {max_prefix: "D4-61-DA",vendor: "Apple, Inc."},
  {max_prefix: "F0-18-98",vendor: "Apple, Inc."},
  {max_prefix: "88-19-08",vendor: "Apple, Inc."},
  {max_prefix: "5C-09-47",vendor: "Apple, Inc."},
  {max_prefix: "64-1C-AE",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "F8-E4-4E",vendor: "MCOT INC."},
  {max_prefix: "40-CD-7A",vendor: "Qingdao Hisense Communications Co.,Ltd."},
  {max_prefix: "00-40-98",vendor: "DRESSLER GMBH & CO."},
  {max_prefix: "94-99-90",vendor: "VTC Telecommunications"},
  {max_prefix: "F4-BC-97",vendor: "Shenzhen Crave Communication Co., LTD"},
  {max_prefix: "28-FE-DE",vendor: "COMESTA, Inc."},
  {max_prefix: "90-79-10",vendor: "Integrated Device Technology (Malaysia) Sdn. Bhd."},
  {max_prefix: "00-3D-E8",vendor: "LG Electronics (Mobile Communications)"},
  {max_prefix: "68-FE-DA",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "D0-76-E7",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "9C-A6-15",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "E4-4E-76",vendor: "CHAMPIONTECH  ENTERPRISE (SHENZHEN) INC"},
  {max_prefix: "DC-4E-F4",vendor: "Shenzhen MTN Electronics CO., Ltd"},
  {max_prefix: "F0-81-73",vendor: "Amazon Technologies Inc."},
  {max_prefix: "00-50-8B",vendor: "Hewlett Packard"},
  {max_prefix: "14-6B-9C",vendor: "SHENZHEN BILIAN ELECTRONIC CO.\uff0cLTD"},
  {max_prefix: "94-8D-EF",vendor: "Oetiker Schweiz AG"},
  {max_prefix: "14-4E-34",vendor: "Remote Solution"},
  {max_prefix: "34-03-DE",vendor: "Texas Instruments"},
  {max_prefix: "34-D7-12",vendor: "Smartisan Digital Co., Ltd"},
  {max_prefix: "A0-66-10",vendor: "FUJITSU LIMITED"},
  {max_prefix: "44-FF-BA",vendor: "zte corporation"},
  {max_prefix: "2C-D9-74",vendor: "Hui Zhou Gaoshengda Technology Co.,LTD"},
  {max_prefix: "D4-F7-86",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "94-B8-6D",vendor: "Intel Corporate"},
  {max_prefix: "88-17-A3",vendor: "Integrated Device Technology (Malaysia) Sdn. Bhd."},
  {max_prefix: "88-A9-A7",vendor: "IEEE Registration Authority"},
  {max_prefix: "60-84-BD",vendor: "BUFFALO.INC"},
  {max_prefix: "34-7E-CA",vendor: "NEXTWILL"},
  {max_prefix: "EC-89-14",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "B8-94-36",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "E0-E6-2E",vendor: "TCT mobile ltd"},
  {max_prefix: "B4-2E-F8",vendor: "Eline Technology co.Ltd"},
  {max_prefix: "A4-D4-B2",vendor: "Shenzhen MeiG Smart Technology Co.,Ltd"},
  {max_prefix: "8C-F7-73",vendor: "Nokia"},
  {max_prefix: "F4-C2-48",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "F4-71-90",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "28-02-D8",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "DC-E5-33",vendor: "IEEE Registration Authority"},
  {max_prefix: "D8-44-5C",vendor: "DEV Tecnologia Ind Com Man Eq LTDA"},
  {max_prefix: "7C-39-53",vendor: "zte corporation"},
  {max_prefix: "38-E1-AA",vendor: "zte corporation"},
  {max_prefix: "48-C7-96",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "50-14-79",vendor: "iRobot Corporation"},
  {max_prefix: "E4-2D-7B",vendor: "China Mobile IOT Company Limited"},
  {max_prefix: "38-78-62",vendor: "Sony Corporation"},
  {max_prefix: "4C-EF-C0",vendor: "Amazon Technologies Inc."},
  {max_prefix: "C4-64-E3",vendor: "Texas Instruments"},
  {max_prefix: "CC-3B-58",vendor: "Curiouser Products Inc"},
  {max_prefix: "DC-DD-24",vendor: "Energica Motor Company SpA"},
  {max_prefix: "64-1C-B0",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "90-3A-72",vendor: "Ruckus Wireless"},
  {max_prefix: "C4-FF-BC",vendor: "IEEE Registration Authority"},
  {max_prefix: "28-3B-82",vendor: "D-Link International"},
  {max_prefix: "80-41-26",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "AC-F9-70",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "58-D7-59",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "8C-59-73",vendor: "Zyxel Communications Corporation"},
  {max_prefix: "24-18-1D",vendor: "SAMSUNG ELECTRO-MECHANICS(THAILAND)"},
  {max_prefix: "F8-90-66",vendor: "Nain Inc."},
  {max_prefix: "70-06-AC",vendor: "Eastcompeace Technology Co., Ltd"},
  {max_prefix: "6C-54-CD",vendor: "LAMPEX ELECTRONICS LIMITED"},
  {max_prefix: "94-29-0C",vendor: "Shenyang wisdom Foundation Technology Development Co., Ltd."},
  {max_prefix: "00-08-89",vendor: "Dish Technologies Corp"},
  {max_prefix: "04-C9-D9",vendor: "Dish Technologies Corp"},
  {max_prefix: "70-55-F8",vendor: "Cerebras Systems Inc"},
  {max_prefix: "58-04-54",vendor: "ICOMM HK LIMITED"},
  {max_prefix: "C4-77-AF",vendor: "Advanced Digital Broadcast SA"},
  {max_prefix: "D4-90-9C",vendor: "Apple, Inc."},
  {max_prefix: "E4-E0-A6",vendor: "Apple, Inc."},
  {max_prefix: "5C-00-38",vendor: "Viasat Group S.p.A."},
  {max_prefix: "9C-32-CE",vendor: "CANON INC."},
  {max_prefix: "80-2B-F9",vendor: "Hon Hai Precision Ind. Co.,Ltd."},
  {max_prefix: "54-B8-02",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "F0-B5-B7",vendor: "Disruptive Technologies Research AS"},
  {max_prefix: "B4-DE-DF",vendor: "zte corporation"},
  {max_prefix: "80-5E-0C",vendor: "YEALINK(XIAMEN) NETWORK TECHNOLOGY CO.,LTD."},
  {max_prefix: "6C-49-C1",vendor: "o2ones Co., Ltd."},
  {max_prefix: "34-41-5D",vendor: "Intel Corporate"},
  {max_prefix: "00-50-91",vendor: "NETACCESS, INC."},
  {max_prefix: "70-EE-A3",vendor: "Eoptolink Technology Inc. Ltd,"},
  {max_prefix: "70-47-E9",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "5C-52-1E",vendor: "Nintendo Co.,Ltd"},
  {max_prefix: "3C-2C-99",vendor: "Edgecore Networks Corporation"},
  {max_prefix: "10-CE-A9",vendor: "Texas Instruments"},
  {max_prefix: "88-D0-39",vendor: "Tonly Technology Co. Ltd"},
  {max_prefix: "20-E0-9C",vendor: "Nokia"},
  {max_prefix: "2C-FD-A1",vendor: "ASUSTek COMPUTER INC."},
  {max_prefix: "38-07-D4",vendor: "Zeppelin Systems GmbH"},
  {max_prefix: "04-19-7F",vendor: "Grasphere Japan"},
  {max_prefix: "14-44-4A",vendor: "Apollo Seiko Ltd."},
  {max_prefix: "5C-81-A7",vendor: "Network Devices Pty Ltd"},
  {max_prefix: "5C-0C-0E",vendor: "Guizhou Huaxintong Semiconductor Technology Co Ltd"},
  {max_prefix: "5C-86-C1",vendor: "DONGGUAN SOLUM ELECTRONICS CO.,LTD"},
  {max_prefix: "38-43-7D",vendor: "Compal Broadband Networks, Inc."},
  {max_prefix: "50-6F-98",vendor: "Sehaj Synergy Technologies Private Limited"},
  {max_prefix: "4C-AE-1C",vendor: "SaiNXT Technologies LLP"},
  {max_prefix: "14-28-82",vendor: "MIDICOM ELECTRONICS CO.LTD"},
  {max_prefix: "30-E4-8E",vendor: "Vodafone UK"},
  {max_prefix: "68-3E-02",vendor: "SIEMENS AG, Digital Factory, Motion Control System"},
  {max_prefix: "00-02-61",vendor: "Tilgin AB"},
  {max_prefix: "00-14-C3",vendor: "Seagate Technology"},
  {max_prefix: "00-04-CF",vendor: "Seagate Technology"},
  {max_prefix: "00-20-37",vendor: "Seagate Technology"},
  {max_prefix: "EC-81-93",vendor: "Logitech, Inc"},
  {max_prefix: "6C-DD-30",vendor: "Cisco Systems, Inc"},
  {max_prefix: "6C-4E-86",vendor: "Third Millennium Systems Ltd."},
  {max_prefix: "FC-9D-D8",vendor: "Beijing TongTongYiLian Science and Technology Ltd."},
  {max_prefix: "50-3C-EA",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "DC-28-34",vendor: "HAKKO Corporation"},
  {max_prefix: "00-17-30",vendor: "Automation Electronics"},
  {max_prefix: "00-E0-91",vendor: "LG Electronics"},
  {max_prefix: "DC-E1-AD",vendor: "Shenzhen Wintop Photoelectric Technology Co., Ltd"},
  {max_prefix: "94-88-54",vendor: "Texas Instruments"},
  {max_prefix: "00-1D-0D",vendor: "Sony Interactive Entertainment Inc."},
  {max_prefix: "EC-B0-E1",vendor: "Ciena Corporation"},
  {max_prefix: "44-91-60",vendor: "Murata Manufacturing Co., Ltd."},
  {max_prefix: "B4-F1-DA",vendor: "LG Electronics (Mobile Communications)"},
  {max_prefix: "C8-63-F1",vendor: "Sony Interactive Entertainment Inc."},
  {max_prefix: "5C-77-76",vendor: "TCT mobile ltd"},
  {max_prefix: "70-E5-6E",vendor: "Texas Instruments"},
  {max_prefix: "54-7D-CD",vendor: "Texas Instruments"},
  {max_prefix: "00-19-C2",vendor: "Equustek Solutions, Inc."},
  {max_prefix: "80-00-0B",vendor: "Intel Corporate"},
  {max_prefix: "00-AE-CD",vendor: "Pensando Systems"},
  {max_prefix: "84-50-9A",vendor: "Easy Soft TV Co., Ltd"},
  {max_prefix: "6C-05-D5",vendor: "Ethertronics Inc"},
  {max_prefix: "F8-B7-E2",vendor: "Cisco Systems, Inc"},
  {max_prefix: "F8-20-55",vendor: "Green Information System"},
  {max_prefix: "AC-A6-67",vendor: "Electronic Systems Protection, Inc."},
  {max_prefix: "00-00-97",vendor: "Dell EMC"},
  {max_prefix: "8C-CF-09",vendor: "Dell EMC"},
  {max_prefix: "8C-83-9D",vendor: "SHENZHEN XINYUPENG ELECTRONIC TECHNOLOGY CO., LTD"},
  {max_prefix: "B0-FC-36",vendor: "CyberTAN Technology Inc."},
  {max_prefix: "00-1D-F4",vendor: "Magellan Technology Pty Limited"},
  {max_prefix: "00-81-F9",vendor: "Texas Instruments"},
  {max_prefix: "EC-B5-FA",vendor: "Philips Lighting BV"},
  {max_prefix: "44-CD-0E",vendor: "FLEXTRONICS MANUFACTURING(ZHUHAI)CO.,LTD."},
  {max_prefix: "74-E1-9A",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "00-1C-AE",vendor: "WiChorus, Inc."},
  {max_prefix: "80-B0-3D",vendor: "Apple, Inc."},
  {max_prefix: "E4-9A-DC",vendor: "Apple, Inc."},
  {max_prefix: "AC-E4-B5",vendor: "Apple, Inc."},
  {max_prefix: "D0-D2-B0",vendor: "Apple, Inc."},
  {max_prefix: "B0-C1-9E",vendor: "zte corporation"},
  {max_prefix: "0C-37-47",vendor: "zte corporation"},
  {max_prefix: "78-DD-D9",vendor: "Guangzhou Shiyuan Electronics Co., Ltd."},
  {max_prefix: "E8-DF-70",vendor: "AVM Audiovisuelles Marketing und Computersysteme GmbH"},
  {max_prefix: "D0-59-95",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "18-CC-88",vendor: "Hitachi Johnson Controls Air"},
  {max_prefix: "7C-DD-76",vendor: "Suzhou Hanming Technologies Co., Ltd."},
  {max_prefix: "24-68-80",vendor: "Braveridge.co.,ltd."},
  {max_prefix: "F0-6D-78",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "70-7D-95",vendor: "Shenzhen City LinwlanTechnology Co. Ltd."},
  {max_prefix: "28-AD-3E",vendor: "Shenzhen TONG BO WEI Technology CO.,LTD"},
  {max_prefix: "70-99-1C",vendor: "Shenzhen Honesty Electronics Co.,Ltd"},
  {max_prefix: "00-1C-56",vendor: "Pado Systems, Inc."},
  {max_prefix: "80-C7-55",vendor: "Panasonic Appliances Company"},
  {max_prefix: "18-78-D4",vendor: "Verizon"},
  {max_prefix: "F0-BD-2E",vendor: "H+S Polatis Ltd"},
  {max_prefix: "74-6E-E4",vendor: "Asia Vital Components Co.,Ltd."},
  {max_prefix: "2C-43-1A",vendor: "Shenzhen YOUHUA Technology Co., Ltd"},
  {max_prefix: "70-70-8B",vendor: "Cisco Systems, Inc"},
  {max_prefix: "38-9F-5A",vendor: "C-Kur TV Inc."},
  {max_prefix: "D8-43-ED",vendor: "Suzuken"},
  {max_prefix: "38-A6-CE",vendor: "SKY UK LIMITED"},
  {max_prefix: "00-40-E4",vendor: "E-M TECHNOLOGY, INC."},
  {max_prefix: "E0-84-F3",vendor: "High Grade Controls Corporation"},
  {max_prefix: "0C-62-A6",vendor: "Hui Zhou Gaoshengda Technology Co.,LTD"},
  {max_prefix: "18-13-2D",vendor: "zte corporation"},
  {max_prefix: "BC-41-01",vendor: "Shenzhen TINNO Mobile Technology Corp."},
  {max_prefix: "04-3A-0D",vendor: "SM Optics S.r.l."},
  {max_prefix: "44-EA-D8",vendor: "Texas Instruments"},
  {max_prefix: "44-8F-17",vendor: "Samsung Electronics Co., Ltd. ARTIK"},
  {max_prefix: "00-FC-8B",vendor: "Amazon Technologies Inc."},
  {max_prefix: "00-76-B1",vendor: "Somfy-Protect By Myfox SAS"},
  {max_prefix: "C0-74-2B",vendor: "SHENZHEN XUNLONG SOFTWARE CO.,LIMITED"},
  {max_prefix: "74-D2-1D",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "38-90-A5",vendor: "Cisco Systems, Inc"},
  {max_prefix: "6C-C1-47",vendor: "Xiamen Hanin Electronic Technology Co., Ltd"},
  {max_prefix: "A0-72-E4",vendor: "NJ SYSTEM CO.,LTD"},
  {max_prefix: "4C-13-65",vendor: "Emplus Technologies"},
  {max_prefix: "CC-F9-57",vendor: "u-blox AG"},
  {max_prefix: "5C-67-76",vendor: "IDS Imaging Development Systems GmbH"},
  {max_prefix: "1C-DD-EA",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "B8-D9-4D",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "18-9B-A5",vendor: "IEEE Registration Authority"},
  {max_prefix: "A4-91-B1",vendor: "Technicolor Delivery Technologies Belgium NV"},
  {max_prefix: "1C-70-22",vendor: "Murata Manufacturing Co., Ltd."},
  {max_prefix: "CC-98-91",vendor: "Cisco Systems, Inc"},
  {max_prefix: "28-BF-89",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "8C-45-00",vendor: "Murata Manufacturing Co., Ltd."},
  {max_prefix: "A4-34-12",vendor: "Thales Alenia Space"},
  {max_prefix: "68-1F-40",vendor: "Blu Wireless Technology Ltd"},
  {max_prefix: "90-AD-F7",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "EC-FA-03",vendor: "FCA"},
  {max_prefix: "00-22-94",vendor: "KYOCERA CORPORATION"},
  {max_prefix: "38-89-DC",vendor: "Opticon Sensors Europe B.V."},
  {max_prefix: "90-3D-BD",vendor: "SECURE METERS LIMITED"},
  {max_prefix: "FC-01-7C",vendor: "Hon Hai Precision Ind. Co.,Ltd."},
  {max_prefix: "90-32-4B",vendor: "Hon Hai Precision Ind. Co.,Ltd."},
  {max_prefix: "94-00-06",vendor: "jinyoung"},
  {max_prefix: "6C-96-CF",vendor: "Apple, Inc."},
  {max_prefix: "78-88-6D",vendor: "Apple, Inc."},
  {max_prefix: "74-E5-F9",vendor: "Intel Corporate"},
  {max_prefix: "40-CE-24",vendor: "Cisco Systems, Inc"},
  {max_prefix: "34-32-E6",vendor: "Panasonic Industrial Devices Europe GmbH"},
  {max_prefix: "40-01-7A",vendor: "Cisco Systems, Inc"},
  {max_prefix: "38-CD-07",vendor: "Beijing FaceCam Technology Co., Ltd."},
  {max_prefix: "20-EE-28",vendor: "Apple, Inc."},
  {max_prefix: "B4-F6-1C",vendor: "Apple, Inc."},
  {max_prefix: "08-F4-AB",vendor: "Apple, Inc."},
  {max_prefix: "8C-85-90",vendor: "Apple, Inc."},
  {max_prefix: "BC-88-C3",vendor: "Ningbo Dooya Mechanic & Electronic Technology Co., Ltd"},
  {max_prefix: "00-12-7D",vendor: "MobileAria"},
  {max_prefix: "00-D0-60",vendor: "Panasonic Europe Ltd."},
  {max_prefix: "A0-88-69",vendor: "Intel Corporate"},
  {max_prefix: "58-A0-CB",vendor: "TrackNet, Inc"},
  {max_prefix: "0C-4B-54",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "74-81-9A",vendor: "PT. Hartono Istana Teknologi"},
  {max_prefix: "A8-2B-B5",vendor: "Edgecore Networks Corporation"},
  {max_prefix: "28-35-45",vendor: "SHENZHEN CHUANGWEI-RGB ELECTRONICS CO.,LTD"},
  {max_prefix: "60-2E-20",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "E4-72-E2",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "94-E3-6D",vendor: "Texas Instruments"},
  {max_prefix: "F0-F8-F2",vendor: "Texas Instruments"},
  {max_prefix: "34-15-13",vendor: "Texas Instruments"},
  {max_prefix: "E0-60-89",vendor: "Cloudleaf, Inc."},
  {max_prefix: "78-36-90",vendor: "Yulong Computer Telecommunication Scientific (Shenzhen) Co.,Ltd"},
  {max_prefix: "50-8F-4C",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "A4-77-58",vendor: "Ningbo Freewings Technologies Co.,Ltd"},
  {max_prefix: "60-F6-77",vendor: "Intel Corporate"},
  {max_prefix: "E8-E1-E2",vendor: "Energotest"},
  {max_prefix: "78-11-DC",vendor: "XIAOMI Electronics,CO.,LTD"},
  {max_prefix: "D4-63-C6",vendor: "Motorola Mobility LLC, a Lenovo Company"},
  {max_prefix: "C4-44-A0",vendor: "Cisco Systems, Inc"},
  {max_prefix: "18-74-2E",vendor: "Amazon Technologies Inc."},
  {max_prefix: "F8-44-E3",vendor: "Taicang T&W Electronics"},
  {max_prefix: "24-A5-34",vendor: "SynTrust Tech International Ltd."},
  {max_prefix: "00-1D-2E",vendor: "Ruckus Wireless"},
  {max_prefix: "04-4F-4C",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "1C-15-1F",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "00-8B-FC",vendor: "mixi,Inc."},
  {max_prefix: "90-A3-65",vendor: "HMD Global Oy"},
  {max_prefix: "DC-44-B6",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "10-07-B6",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "34-2D-0D",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "D8-37-BE",vendor: "SHENZHEN GONGJIN ELECTRONICS CO.,LT"},
  {max_prefix: "00-13-92",vendor: "Ruckus Wireless"},
  {max_prefix: "D4-68-4D",vendor: "Ruckus Wireless"},
  {max_prefix: "8C-0C-90",vendor: "Ruckus Wireless"},
  {max_prefix: "6C-AA-B3",vendor: "Ruckus Wireless"},
  {max_prefix: "C0-8A-DE",vendor: "Ruckus Wireless"},
  {max_prefix: "08-51-14",vendor: "QINGDAO TOPSCOMM COMMUNICATION CO., LTD"},
  {max_prefix: "70-78-8B",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "48-59-A4",vendor: "zte corporation"},
  {max_prefix: "70-F1-1C",vendor: "Shenzhen Ogemray Technology Co.,Ltd"},
  {max_prefix: "70-65-A3",vendor: "Kandao lightforge Co., Ltd."},
  {max_prefix: "74-D0-DC",vendor: "Ericsson AB"},
  {max_prefix: "54-BD-79",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "AC-9E-17",vendor: "ASUSTek COMPUTER INC."},
  {max_prefix: "64-16-66",vendor: "Nest Labs Inc."},
  {max_prefix: "EC-42-B4",vendor: "ADC Corporation"},
  {max_prefix: "60-DA-83",vendor: "Hangzhou H3C Technologies Co., Limited"},
  {max_prefix: "2C-57-31",vendor: " Wingtech Group (HongKong\uff09Limited"},
  {max_prefix: "CC-46-39",vendor: "WAAV, Inc."},
  {max_prefix: "A0-42-3F",vendor: "Tyan Computer Corp"},
  {max_prefix: "D8-DF-7A",vendor: "Quest Software, Inc."},
  {max_prefix: "E4-A7-49",vendor: "Palo Alto Networks"},
  {max_prefix: "A8-6B-7C",vendor: "SHENZHEN FENGLIAN TECHNOLOGY CO., LTD."},
  {max_prefix: "B0-39-56",vendor: "NETGEAR"},
  {max_prefix: "B4-E6-2A",vendor: "LG Innotek"},
  {max_prefix: "A0-C5-F2",vendor: "IEEE Registration Authority"},
  {max_prefix: "8C-14-7D",vendor: "IEEE Registration Authority"},
  {max_prefix: "A0-AF-BD",vendor: "Intel Corporate"},
  {max_prefix: "A0-34-1B",vendor: "Adero Inc"},
  {max_prefix: "A0-23-9F",vendor: "Cisco Systems, Inc"},
  {max_prefix: "70-F3-5A",vendor: "Cisco Systems, Inc"},
  {max_prefix: "FC-4D-D4",vendor: "Universal Global Scientific Industrial Co., Ltd."},
  {max_prefix: "14-5B-E1",vendor: "nyantec GmbH"},
  {max_prefix: "30-07-4D",vendor: "SAMSUNG ELECTRO-MECHANICS(THAILAND)"},
  {max_prefix: "00-18-0A",vendor: "Cisco Meraki"},
  {max_prefix: "88-D5-0C",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "D4-28-D5",vendor: "TCT mobile ltd"},
  {max_prefix: "7C-8B-CA",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "B0-4E-26",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "B0-89-C2",vendor: "Zyptonite"},
  {max_prefix: "F0-23-B9",vendor: "IEEE Registration Authority"},
  {max_prefix: "1C-1F-D4",vendor: "LifeBEAM Technologies LTD"},
  {max_prefix: "E8-39-35",vendor: "Hewlett Packard"},
  {max_prefix: "A4-F4-C2",vendor: "VNPT TECHNOLOGY"},
  {max_prefix: "00-9A-D2",vendor: "Cisco Systems, Inc"},
  {max_prefix: "94-7B-E7",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "50-92-B9",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "DC-74-A8",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "5C-6A-80",vendor: "Zyxel Communications Corporation"},
  {max_prefix: "D8-60-B3",vendor: "Guangdong Global Electronic Technology CO.\uff0cLTD"},
  {max_prefix: "64-35-1C",vendor: "e-CON SYSTEMS INDIA PVT LTD"},
  {max_prefix: "60-53-17",vendor: "Sandstone Technologies"},
  {max_prefix: "9C-AF-6F",vendor: "ITEL MOBILE LIMITED"},
  {max_prefix: "FC-53-9E",vendor: "Shanghai Wind Technologies Co.,Ltd"},
  {max_prefix: "A8-D5-79",vendor: "Beijing Chushang Science and Technology Co.,Ltd"},
  {max_prefix: "84-CD-62",vendor: "ShenZhen IDWELL Technology CO.,Ltd"},
  {max_prefix: "90-70-65",vendor: "Texas Instruments"},
  {max_prefix: "48-10-63",vendor: "NTT Innovation Institute, Inc."},
  {max_prefix: "A0-8E-78",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "60-BA-18",vendor: "nextLAP GmbH"},
  {max_prefix: "00-1F-A4",vendor: "SHENZHEN GONGJIN ELECTRONICS CO.,LT"},
  {max_prefix: "C4-AE-12",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "18-A9-58",vendor: "PROVISION THAI CO., LTD."},
  {max_prefix: "94-D2-99",vendor: "Techmation Co.,Ltd."},
  {max_prefix: "48-88-03",vendor: "ManTechnology Inc."},
  {max_prefix: "B4-36-E3",vendor: "KBVISION GROUP"},
  {max_prefix: "6C-4B-90",vendor: "LiteON"},
  {max_prefix: "C8-3A-6B",vendor: "Roku, Inc"},
  {max_prefix: "B4-C6-F8",vendor: "Axilspot Communication"},
  {max_prefix: "9C-E9-51",vendor: "Shenzhen Sang Fei Consumer Communications Ltd., Co."},
  {max_prefix: "74-C9-A3",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "EC-8A-4C",vendor: "zte corporation"},
  {max_prefix: "B8-D5-0B",vendor: "Sunitec Enterprise Co.,Ltd"},
  {max_prefix: "BC-66-DE",vendor: "Shadow Creator Information Technology Co.,Ltd."},
  {max_prefix: "2C-AB-EB",vendor: "Cisco Systems, Inc"},
  {max_prefix: "58-D9-D5",vendor: "Tenda Technology Co.,Ltd.Dongguan branch"},
  {max_prefix: "D4-5F-25",vendor: "Shenzhen YOUHUA Technology Co., Ltd"},
  {max_prefix: "40-C8-CB",vendor: "AM Telecom co., Ltd."},
  {max_prefix: "64-D1-54",vendor: "Routerboard.com"},
  {max_prefix: "28-FF-3E",vendor: "zte corporation"},
  {max_prefix: "B8-D7-AF",vendor: "Murata Manufacturing Co., Ltd."},
  {max_prefix: "D4-AE-05",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "F0-EE-10",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "E0-48-AF",vendor: "Premietech Limited"},
  {max_prefix: "2C-33-11",vendor: "Cisco Systems, Inc"},
  {max_prefix: "50-82-D5",vendor: "Apple, Inc."},
  {max_prefix: "34-1A-35",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "2C-02-9F",vendor: "3ALogics"},
  {max_prefix: "00-05-0F",vendor: "Tanaka S/S Ltd."},
  {max_prefix: "98-9E-63",vendor: "Apple, Inc."},
  {max_prefix: "88-6B-6E",vendor: "Apple, Inc."},
  {max_prefix: "D4-DC-CD",vendor: "Apple, Inc."},
  {max_prefix: "48-4B-AA",vendor: "Apple, Inc."},
  {max_prefix: "DC-A9-04",vendor: "Apple, Inc."},
  {max_prefix: "6C-AB-31",vendor: "Apple, Inc."},
  {max_prefix: "4C-74-BF",vendor: "Apple, Inc."},
  {max_prefix: "04-94-6B",vendor: "TECNO MOBILE LIMITED"},
  {max_prefix: "A0-4C-5B",vendor: "Shenzhen TINNO Mobile Technology Corp."},
  {max_prefix: "F8-59-71",vendor: "Intel Corporate"},
  {max_prefix: "F4-E4-AD",vendor: "zte corporation"},
  {max_prefix: "BC-02-4A",vendor: "HMD Global Oy"},
  {max_prefix: "94-99-01",vendor: "Shenzhen YITOA Digital Appliance CO.,LTD"},
  {max_prefix: "10-05-CA",vendor: "Cisco Systems, Inc"},
  {max_prefix: "78-94-B4",vendor: "Sercomm Corporation."},
  {max_prefix: "C4-70-0B",vendor: "GUANGZHOU CHIP TECHNOLOGIES CO.,LTD"},
  {max_prefix: "44-37-08",vendor: "MRV Comunications"},
  {max_prefix: "28-5F-2F",vendor: "RNware Co.,Ltd."},
  {max_prefix: "3C-A0-67",vendor: "Liteon Technology Corporation"},
  {max_prefix: "1C-1E-E3",vendor: "Hui Zhou Gaoshengda Technology Co.,LTD"},
  {max_prefix: "2C-55-D3",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "DC-C6-4B",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "04-33-89",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "B8-1D-AA",vendor: "LG Electronics (Mobile Communications)"},
  {max_prefix: "00-E4-00",vendor: "Sichuan Changhong Electric Ltd."},
  {max_prefix: "54-2F-8A",vendor: "TELLESCOM INDUSTRIA E COMERCIO EM TELECOMUNICACAO"},
  {max_prefix: "60-14-B3",vendor: "CyberTAN Technology Inc."},
  {max_prefix: "44-03-2C",vendor: "Intel Corporate"},
  {max_prefix: "50-0F-F5",vendor: "Tenda Technology Co.,Ltd.Dongguan branch"},
  {max_prefix: "BC-45-2E",vendor: "Knowledge Development for POF S.L."},
  {max_prefix: "70-6D-EC",vendor: "Wifi-soft LLC"},
  {max_prefix: "B0-C2-05",vendor: "BIONIME"},
  {max_prefix: "94-F5-51",vendor: "Cadi Scientific Pte Ltd"},
  {max_prefix: "00-A0-68",vendor: "BHP LIMITED"},
  {max_prefix: "70-3A-CB",vendor: "Google, Inc."},
  {max_prefix: "10-5A-F7",vendor: "ADB Italia"},
  {max_prefix: "00-C0-24",vendor: "EDEN SISTEMAS DE COMPUTACAO SA"},
  {max_prefix: "7C-46-85",vendor: "Motorola (Wuhan) Mobility Technologies Communication Co., Ltd."},
  {max_prefix: "1C-39-8A",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "00-17-9B",vendor: "CHANT SINCERE CO.,LTD"},
  {max_prefix: "08-23-B2",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "88-C3-B3",vendor: "SOVICO"},
  {max_prefix: "E0-51-24",vendor: "NXP Semiconductors"},
  {max_prefix: "6C-16-0E",vendor: "ShotTracker"},
  {max_prefix: "80-3A-0A",vendor: "Integrated Device Technology (Malaysia) Sdn. Bhd."},
  {max_prefix: "A4-62-DF",vendor: "DS Global. Co., LTD"},
  {max_prefix: "08-CC-A7",vendor: "Cisco Systems, Inc"},
  {max_prefix: "08-96-AD",vendor: "Cisco Systems, Inc"},
  {max_prefix: "34-78-77",vendor: "O-Net Communications (Shenzhen) Limited"},
  {max_prefix: "00-20-CC",vendor: "DIGITAL SERVICES, LTD."},
  {max_prefix: "28-52-61",vendor: "Cisco Systems, Inc"},
  {max_prefix: "28-6F-7F",vendor: "Cisco Systems, Inc"},
  {max_prefix: "F0-C8-50",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "00-1D-A3",vendor: "SabiOso"},
  {max_prefix: "68-9F-F0",vendor: "zte corporation"},
  {max_prefix: "5C-AF-06",vendor: "LG Electronics (Mobile Communications)"},
  {max_prefix: "4C-16-94",vendor: "shenzhen sibituo Technology Co., Ltd"},
  {max_prefix: "00-01-4F",vendor: "Adtran Inc"},
  {max_prefix: "78-68-F7",vendor: "YSTen Technology Co.,Ltd"},
  {max_prefix: "C8-14-51",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "44-D4-37",vendor: "Inteno Broadband Technology AB"},
  {max_prefix: "EC-E1-54",vendor: "Beijing Unisound Information Technology Co.,Ltd."},
  {max_prefix: "E8-65-D4",vendor: "Tenda Technology Co.,Ltd.Dongguan branch"},
  {max_prefix: "24-D5-1C",vendor: "Zhongtian broadband technology co., LTD"},
  {max_prefix: "4C-EC-EF",vendor: "Soraa, Inc."},
  {max_prefix: "1C-EF-CE",vendor: "bebro electronic GmbH"},
  {max_prefix: "64-DB-A0",vendor: "Select Comfort"},
  {max_prefix: "EC-43-F6",vendor: "Zyxel Communications Corporation"},
  {max_prefix: "60-C6-58",vendor: "PHYTRONIX Co.,Ltd."},
  {max_prefix: "FC-B5-8A",vendor: "Wapice Ltd."},
  {max_prefix: "20-78-0B",vendor: "Delta Faucet Company"},
  {max_prefix: "2C-D0-2D",vendor: "Cisco Systems, Inc"},
  {max_prefix: "24-A7-DC",vendor: "SKY UK LIMITED"},
  {max_prefix: "0C-73-BE",vendor: "Dongguan Haimai Electronie Technology Co.,Ltd"},
  {max_prefix: "C8-AA-55",vendor: "Hunan Comtom Electronic Incorporated Co.,Ltd"},
  {max_prefix: "98-B6-E9",vendor: "Nintendo Co.,Ltd"},
  {max_prefix: "18-65-90",vendor: "Apple, Inc."},
  {max_prefix: "F8-62-14",vendor: "Apple, Inc."},
  {max_prefix: "78-4F-43",vendor: "Apple, Inc."},
  {max_prefix: "40-4D-7F",vendor: "Apple, Inc."},
  {max_prefix: "64-B0-A6",vendor: "Apple, Inc."},
  {max_prefix: "7C-04-D0",vendor: "Apple, Inc."},
  {max_prefix: "84-FC-AC",vendor: "Apple, Inc."},
  {max_prefix: "DC-0C-5C",vendor: "Apple, Inc."},
  {max_prefix: "F8-98-3A",vendor: "Leeman International (HongKong) Limited"},
  {max_prefix: "00-1D-72",vendor: "Wistron Corporation"},
  {max_prefix: "30-E1-71",vendor: "Hewlett Packard"},
  {max_prefix: "F0-15-B9",vendor: "PlayFusion Limited"},
  {max_prefix: "64-13-6C",vendor: "zte corporation"},
  {max_prefix: "04-B6-48",vendor: "ZENNER"},
  {max_prefix: "98-F1-99",vendor: "NEC Platforms, Ltd."},
  {max_prefix: "18-40-A4",vendor: "Shenzhen Trylong Smart Science and Technology Co., Ltd."},
  {max_prefix: "4C-74-87",vendor: "Leader Phone Communication Technology Co., Ltd."},
  {max_prefix: "4C-38-D5",vendor: "MITAC COMPUTING TECHNOLOGY CORPORATION"},
  {max_prefix: "54-B5-6C",vendor: "Xi'an NovaStar Tech Co., Ltd"},
  {max_prefix: "1C-48-CE",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "34-4C-C8",vendor: "Echodyne Corp"},
  {max_prefix: "60-3E-7B",vendor: "Gafachi, Inc."},
  {max_prefix: "D8-19-7A",vendor: "Nuheara Ltd"},
  {max_prefix: "70-70-0D",vendor: "Apple, Inc."},
  {max_prefix: "6C-5C-14",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "AC-83-F3",vendor: "AMPAK Technology, Inc."},
  {max_prefix: "CC-8C-DA",vendor: "Shenzhen Wei Da Intelligent Technology Go.,Ltd"},
  {max_prefix: "D4-36-DB",vendor: "Jiangsu Toppower Automotive Electronics Co., Ltd"},
  {max_prefix: "E8-09-45",vendor: "Integrated Device Technology (Malaysia) Sdn. Bhd."},
  {max_prefix: "C8-0C-C8",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "04-25-C5",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "2C-DC-AD",vendor: "Wistron Neweb Corporation"},
  {max_prefix: "B0-A2-E7",vendor: "Shenzhen TINNO Mobile Technology Corp."},
  {max_prefix: "7C-25-87",vendor: "chaowifi.com"},
  {max_prefix: "00-21-44",vendor: "SS Telecoms"},
  {max_prefix: "00-50-1E",vendor: "Grass Valley, A Belden Brand"},
  {max_prefix: "EC-0D-9A",vendor: "Mellanox Technologies, Inc."},
  {max_prefix: "3C-FA-43",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "14-5F-94",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "90-D7-BE",vendor: "Wavelab Global Inc."},
  {max_prefix: "24-4E-7B",vendor: "IEEE Registration Authority"},
  {max_prefix: "CC-94-70",vendor: "Kinestral Technologies, Inc."},
  {max_prefix: "08-5D-DD",vendor: "MERCURY CORPORATION"},
  {max_prefix: "00-1F-82",vendor: "Cal-Comp Electronics & Communications Company Ltd."},
  {max_prefix: "88-3C-1C",vendor: "MERCURY CORPORATION"},
  {max_prefix: "28-34-A2",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-6B-F1",vendor: "Cisco Systems, Inc"},
  {max_prefix: "30-AE-A4",vendor: "Espressif Inc."},
  {max_prefix: "CC-61-E5",vendor: "Motorola Mobility LLC, a Lenovo Company"},
  {max_prefix: "20-71-9E",vendor: "SF Technology Co.,Ltd"},
  {max_prefix: "2C-C2-60",vendor: "Oracle Corporation"},
  {max_prefix: "3C-3F-51",vendor: "2CRSI"},
  {max_prefix: "50-58-4F",vendor: "waytotec,Inc."},
  {max_prefix: "B4-39-D6",vendor: "ProCurve Networking by HP"},
  {max_prefix: "34-F3-9A",vendor: "Intel Corporate"},
  {max_prefix: "8C-60-E7",vendor: "MPGIO CO.,LTD"},
  {max_prefix: "8C-8A-BB",vendor: "Beijing Orient View Technology Co., Ltd."},
  {max_prefix: "00-03-9B",vendor: "NetChip Technology, Inc."},
  {max_prefix: "60-9A-C1",vendor: "Apple, Inc."},
  {max_prefix: "74-8D-08",vendor: "Apple, Inc."},
  {max_prefix: "9C-8B-A0",vendor: "Apple, Inc."},
  {max_prefix: "CC-08-8D",vendor: "Apple, Inc."},
  {max_prefix: "38-A4-ED",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "B8-99-19",vendor: "7signal Solutions, Inc"},
  {max_prefix: "40-FE-0D",vendor: "MAXIO"},
  {max_prefix: "AC-64-DD",vendor: "IEEE Registration Authority"},
  {max_prefix: "BC-39-D9",vendor: "Z-TEC"},
  {max_prefix: "3C-2A-F4",vendor: "Brother Industries, LTD."},
  {max_prefix: "C0-85-4C",vendor: "Ragentek Technology Group"},
  {max_prefix: "D8-16-C1",vendor: "DEWAV (HK) ELECTRONICS LIMITED"},
  {max_prefix: "94-B8-19",vendor: "Nokia"},
  {max_prefix: "78-7D-48",vendor: "ITEL MOBILE LIMITED"},
  {max_prefix: "88-71-E5",vendor: "Amazon Technologies Inc."},
  {max_prefix: "60-EF-C6",vendor: "Shenzhen Chima Technologies Co Limited"},
  {max_prefix: "20-DB-AB",vendor: "Samsung Electronics Co., Ltd."},
  {max_prefix: "38-3A-21",vendor: "IEEE Registration Authority"},
  {max_prefix: "D8-38-0D",vendor: "SHENZHEN IP-COM Network Co.,Ltd"},
  {max_prefix: "FC-CA-C4",vendor: "LifeHealth, LLC"},
  {max_prefix: "88-AD-43",vendor: "PEGATRON CORPORATION"},
  {max_prefix: "B4-EF-FA",vendor: "Lemobile Information Technology (Beijing) Co., Ltd."},
  {max_prefix: "B0-C1-28",vendor: "Adler ELREHA GmbH"},
  {max_prefix: "F8-1D-78",vendor: "IEEE Registration Authority"},
  {max_prefix: "38-F7-B2",vendor: "SEOJUN ELECTRIC"},
  {max_prefix: "78-02-B7",vendor: "ShenZhen Ultra Easy Technology CO.,LTD"},
  {max_prefix: "64-61-84",vendor: "VELUX"},
  {max_prefix: "30-87-D9",vendor: "Ruckus Wireless"},
  {max_prefix: "F0-98-38",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "F0-D9-B2",vendor: "EXO S.A."},
  {max_prefix: "E4-C8-01",vendor: "BLU Products Inc"},
  {max_prefix: "00-1F-C6",vendor: "ASUSTek COMPUTER INC."},
  {max_prefix: "6C-71-BD",vendor: "EZELINK TELECOM"},
  {max_prefix: "C8-7E-75",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "00-26-5F",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "00-23-3A",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "10-12-50",vendor: "Integrated Device Technology (Malaysia) Sdn. Bhd."},
  {max_prefix: "00-07-AB",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "E8-E5-D6",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "80-69-1A",vendor: "Belkin International Inc."},
  {max_prefix: "B8-07-56",vendor: "Cisco Meraki"},
  {max_prefix: "00-14-27",vendor: "JazzMutant"},
  {max_prefix: "00-1E-84",vendor: "Pika Technologies Inc."},
  {max_prefix: "10-DD-B1",vendor: "Apple, Inc."},
  {max_prefix: "00-23-29",vendor: "DDRdrive LLC"},
  {max_prefix: "C8-0E-14",vendor: "AVM Audiovisuelles Marketing und Computersysteme GmbH"},
  {max_prefix: "AC-63-BE",vendor: "Amazon Technologies Inc."},
  {max_prefix: "08-6A-0A",vendor: "ASKEY COMPUTER CORP"},
  {max_prefix: "98-E7-F4",vendor: "Hewlett Packard"},
  {max_prefix: "EC-65-CC",vendor: "Panasonic Automotive Systems Company of America"},
  {max_prefix: "34-56-FE",vendor: "Cisco Meraki"},
  {max_prefix: "00-26-AD",vendor: "Arada Systems, Inc."},
  {max_prefix: "00-24-86",vendor: "DesignArt Networks"},
  {max_prefix: "00-24-78",vendor: "Mag Tech Electronics Co Limited"},
  {max_prefix: "38-2D-D1",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "00-1B-2C",vendor: "ATRON electronic GmbH"},
  {max_prefix: "90-34-FC",vendor: "Hon Hai Precision Ind. Co.,Ltd."},
  {max_prefix: "0C-84-DC",vendor: "Hon Hai Precision Ind. Co.,Ltd."},
  {max_prefix: "FC-1F-19",vendor: "SAMSUNG ELECTRO MECHANICS CO., LTD."},
  {max_prefix: "84-0B-2D",vendor: "SAMSUNG ELECTRO MECHANICS CO., LTD."},
  {max_prefix: "20-64-32",vendor: "SAMSUNG ELECTRO MECHANICS CO., LTD."},
  {max_prefix: "B4-07-F9",vendor: "SAMSUNG ELECTRO MECHANICS CO., LTD."},
  {max_prefix: "88-9F-FA",vendor: "Hon Hai Precision Ind. Co.,Ltd."},
  {max_prefix: "8C-7C-B5",vendor: "Hon Hai Precision Ind. Co.,Ltd."},
  {max_prefix: "C4-46-19",vendor: "Hon Hai Precision Ind. Co.,Ltd."},
  {max_prefix: "50-63-13",vendor: "Hon Hai Precision Ind. Co.,Ltd."},
  {max_prefix: "D0-66-7B",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "E8-03-9A",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "30-CD-A7",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "3C-8B-FE",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "D4-E8-B2",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "00-23-39",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "50-01-BB",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "00-16-6C",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "00-15-99",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "00-12-FB",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "00-12-47",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "00-15-B9",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "00-24-91",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "60-D8-19",vendor: "Hon Hai Precision Ind. Co.,Ltd."},
  {max_prefix: "F8-2F-A8",vendor: "Hon Hai Precision Ind. Co.,Ltd."},
  {max_prefix: "C4-01-42",vendor: "MaxMedia Technology Limited"},
  {max_prefix: "84-30-E5",vendor: "SkyHawke Technologies, LLC"},
  {max_prefix: "1C-77-F6",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "58-E3-26",vendor: "Compass Technologies Inc."},
  {max_prefix: "00-1B-2A",vendor: "Cisco Systems, Inc"},
  {max_prefix: "74-9D-DC",vendor: "2Wire Inc"},
  {max_prefix: "C8-BA-94",vendor: "SAMSUNG ELECTRO-MECHANICS(THAILAND)"},
  {max_prefix: "84-38-38",vendor: "SAMSUNG ELECTRO-MECHANICS(THAILAND)"},
  {max_prefix: "54-88-0E",vendor: "SAMSUNG ELECTRO-MECHANICS(THAILAND)"},
  {max_prefix: "F0-25-B7",vendor: "SAMSUNG ELECTRO-MECHANICS(THAILAND)"},
  {max_prefix: "F0-43-47",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "9C-B2-B2",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "A8-C8-3A",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "14-DD-E5",vendor: "MPMKVVCL"},
  {max_prefix: "00-1A-09",vendor: "Wayfarer Transit Systems Ltd"},
  {max_prefix: "74-23-44",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "E4-92-FB",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "6C-B7-F4",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "18-1E-B0",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "24-7F-20",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "68-48-98",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "34-23-BA",vendor: "SAMSUNG ELECTRO-MECHANICS(THAILAND)"},
  {max_prefix: "40-0E-85",vendor: "SAMSUNG ELECTRO-MECHANICS(THAILAND)"},
  {max_prefix: "E0-9D-FA",vendor: "Wanan Hongsheng Electronic Co.Ltd"},
  {max_prefix: "14-89-FD",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "BC-85-1F",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "B8-5E-7B",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "2C-44-01",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "B8-D9-CE",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "1C-66-AA",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "5C-3C-27",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "BC-72-B1",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "78-F7-BE",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "70-A8-4C",vendor: "MONAD., Inc."},
  {max_prefix: "84-C7-EA",vendor: "Sony Corporation"},
  {max_prefix: "24-E4-3F",vendor: "Wenzhou Kunmei Communication Technology Co.,Ltd."},
  {max_prefix: "D0-13-FD",vendor: "LG Electronics (Mobile Communications)"},
  {max_prefix: "00-25-8B",vendor: "Mellanox Technologies, Inc."},
  {max_prefix: "88-79-7E",vendor: "Motorola Mobility LLC, a Lenovo Company"},
  {max_prefix: "30-58-90",vendor: "Frontier Silicon Ltd"},
  {max_prefix: "70-8B-CD",vendor: "ASUSTek COMPUTER INC."},
  {max_prefix: "2C-AC-44",vendor: "CONEXTOP"},
  {max_prefix: "60-64-05",vendor: "Texas Instruments"},
  {max_prefix: "00-56-2B",vendor: "Cisco Systems, Inc"},
  {max_prefix: "E8-FD-90",vendor: "Turbostor"},
  {max_prefix: "18-99-F5",vendor: "Sichuan Changhong Electric Ltd."},
  {max_prefix: "18-AB-F5",vendor: "Ultra Electronics Electrics"},
  {max_prefix: "B0-3E-B0",vendor: "MICRODIA Ltd."},
  {max_prefix: "00-25-C3",vendor: "21168"},
  {max_prefix: "00-0F-57",vendor: "CABLELOGIC Co., Ltd."},
  {max_prefix: "00-03-42",vendor: "Nortel Networks"},
  {max_prefix: "00-12-83",vendor: "Nortel Networks"},
  {max_prefix: "00-11-F9",vendor: "Nortel Networks"},
  {max_prefix: "10-E6-8F",vendor: "KWANGSUNG ELECTRONICS KOREA CO.,LTD."},
  {max_prefix: "4C-FA-CA",vendor: "Cambridge Industries(Group) Co.,Ltd."},
  {max_prefix: "A4-82-69",vendor: "Datrium, Inc."},
  {max_prefix: "00-11-58",vendor: "Nortel Networks"},
  {max_prefix: "00-0F-6A",vendor: "Nortel Networks"},
  {max_prefix: "00-0E-62",vendor: "Nortel Networks"},
  {max_prefix: "00-0C-F8",vendor: "Nortel Networks"},
  {max_prefix: "00-09-97",vendor: "Nortel Networks"},
  {max_prefix: "00-1C-EB",vendor: "Nortel Networks"},
  {max_prefix: "00-1C-17",vendor: "Nortel Networks"},
  {max_prefix: "00-1A-8F",vendor: "Nortel Networks"},
  {max_prefix: "00-15-91",vendor: "RLW Inc."},
  {max_prefix: "00-18-2E",vendor: "XStreamHD"},
  {max_prefix: "00-26-F1",vendor: "ProCurve Networking by HP"},
  {max_prefix: "38-0D-D4",vendor: "Primax Electronics Ltd."},
  {max_prefix: "98-FD-B4",vendor: "Primax Electronics Ltd."},
  {max_prefix: "D8-C4-6A",vendor: "Murata Manufacturing Co., Ltd."},
  {max_prefix: "D8-FB-68",vendor: "Cloud Corner Ltd."},
  {max_prefix: "68-53-88",vendor: "P&S Technology"},
  {max_prefix: "14-C1-FF",vendor: "ShenZhen QianHai Comlan communication Co.,LTD"},
  {max_prefix: "EC-FA-AA",vendor: "The IMS Company"},
  {max_prefix: "00-14-C7",vendor: "Nortel Networks"},
  {max_prefix: "00-1D-AF",vendor: "Nortel Networks"},
  {max_prefix: "88-83-22",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "E8-93-09",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "98-2F-3C",vendor: "Sichuan Changhong Electric Ltd."},
  {max_prefix: "F0-07-86",vendor: "Shandong Bittel Electronics Co., Ltd"},
  {max_prefix: "00-D0-F6",vendor: "Nokia"},
  {max_prefix: "54-A6-19",vendor: "Alcatel-Lucent Shanghai Bell Co., Ltd"},
  {max_prefix: "38-F8-CA",vendor: "OWIN Inc."},
  {max_prefix: "00-21-05",vendor: "Alcatel-Lucent IPD"},
  {max_prefix: "00-17-D1",vendor: "Nortel Networks"},
  {max_prefix: "20-57-AF",vendor: "Shenzhen FH-NET OPTOELECTRONICS CO.,LTD"},
  {max_prefix: "54-DC-1D",vendor: "Yulong Computer Telecommunication Scientific (Shenzhen) Co.,Ltd"},
  {max_prefix: "6C-D0-32",vendor: "LG Electronics"},
  {max_prefix: "88-A6-C6",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "94-D4-69",vendor: "Cisco Systems, Inc"},
  {max_prefix: "88-2B-D7",vendor: "ADD\u00c9NERGIE  TECHNOLOGIES"},
  {max_prefix: "C0-E4-2D",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "8C-A6-DF",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "84-16-F9",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "18-D6-C7",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "44-33-4C",vendor: "Shenzhen Bilian electronic CO.,LTD"},
  {max_prefix: "AC-A2-13",vendor: "Shenzhen Bilian electronic CO.,LTD"},
  {max_prefix: "3C-33-00",vendor: "Shenzhen Bilian electronic CO.,LTD"},
  {max_prefix: "00-90-CC",vendor: "PLANEX COMMUNICATIONS INC."},
  {max_prefix: "00-22-CF",vendor: "PLANEX COMMUNICATIONS INC."},
  {max_prefix: "E4-17-D8",vendor: "8BITDO TECHNOLOGY HK LIMITED"},
  {max_prefix: "9C-D3-32",vendor: "PLC Technology Ltd"},
  {max_prefix: "64-89-9A",vendor: "LG Electronics (Mobile Communications)"},
  {max_prefix: "F8-A9-D0",vendor: "LG Electronics (Mobile Communications)"},
  {max_prefix: "CC-FA-00",vendor: "LG Electronics (Mobile Communications)"},
  {max_prefix: "74-A7-22",vendor: "LG Electronics (Mobile Communications)"},
  {max_prefix: "F0-1C-13",vendor: "LG Electronics (Mobile Communications)"},
  {max_prefix: "A8-16-B2",vendor: "LG Electronics (Mobile Communications)"},
  {max_prefix: "64-BC-0C",vendor: "LG Electronics (Mobile Communications)"},
  {max_prefix: "34-4D-F7",vendor: "LG Electronics (Mobile Communications)"},
  {max_prefix: "58-3F-54",vendor: "LG Electronics (Mobile Communications)"},
  {max_prefix: "B0-C5-CA",vendor: "IEEE Registration Authority"},
  {max_prefix: "74-19-F8",vendor: "IEEE Registration Authority"},
  {max_prefix: "00-1B-C5",vendor: "IEEE Registration Authority"},
  {max_prefix: "78-C3-E9",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "8C-1A-BF",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "30-CB-F8",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "A0-CB-FD",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "E4-5D-75",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "00-00-31",vendor: "QPSX COMMUNICATIONS, LTD."},
  {max_prefix: "00-0E-1E",vendor: "QLogic Corporation"},
  {max_prefix: "00-14-D1",vendor: "TRENDnet, Inc."},
  {max_prefix: "C0-1A-DA",vendor: "Apple, Inc."},
  {max_prefix: "00-12-1C",vendor: "PARROT SA"},
  {max_prefix: "90-03-B7",vendor: "PARROT SA"},
  {max_prefix: "90-C6-82",vendor: "IEEE Registration Authority"},
  {max_prefix: "58-FC-DB",vendor: "IEEE Registration Authority"},
  {max_prefix: "00-1C-14",vendor: "VMware, Inc."},
  {max_prefix: "00-50-56",vendor: "VMware, Inc."},
  {max_prefix: "00-10-C1",vendor: "OI ELECTRIC CO.,LTD"},
  {max_prefix: "38-A2-8C",vendor: "SHENZHEN RF-LINK TECHNOLOGY CO.,LTD."},
  {max_prefix: "A0-9E-1A",vendor: "Polar Electro Oy"},
  {max_prefix: "B4-A5-EF",vendor: "Sercomm Corporation."},
  {max_prefix: "84-9D-64",vendor: "SMC Corporation"},
  {max_prefix: "00-80-F7",vendor: "Zenith Electronics Corporation"},
  {max_prefix: "48-3C-0C",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "20-87-56",vendor: "SIEMENS AG"},
  {max_prefix: "BC-30-7D",vendor: "Wistron Neweb Corporation"},
  {max_prefix: "48-A9-D2",vendor: "Wistron Neweb Corporation"},
  {max_prefix: "80-EA-23",vendor: "Wistron Neweb Corporation"},
  {max_prefix: "74-B4-72",vendor: "CIESSE"},
  {max_prefix: "FC-F1-52",vendor: "Sony Corporation"},
  {max_prefix: "30-9B-AD",vendor: "BBK EDUCATIONAL ELECTRONICS CORP.,LTD."},
  {max_prefix: "CC-52-AF",vendor: "Universal Global Scientific Industrial Co., Ltd."},
  {max_prefix: "00-27-13",vendor: "Universal Global Scientific Industrial Co., Ltd."},
  {max_prefix: "00-1B-B1",vendor: "Wistron Neweb Corporation"},
  {max_prefix: "BC-30-7E",vendor: "Wistron Neweb Corporation"},
  {max_prefix: "68-B3-5E",vendor: "Shenzhen Neostra Technology Co.Ltd"},
  {max_prefix: "1C-D6-BD",vendor: "LEEDARSON LIGHTING CO., LTD."},
  {max_prefix: "D0-D9-4F",vendor: "IEEE Registration Authority"},
  {max_prefix: "50-65-83",vendor: "Texas Instruments"},
  {max_prefix: "B0-91-22",vendor: "Texas Instruments"},
  {max_prefix: "98-57-D3",vendor: "HON HAI-CCPBG  PRECISION IND.CO.,LTD."},
  {max_prefix: "AC-04-81",vendor: "Jiangsu Huaxing Electronics Co., Ltd."},
  {max_prefix: "FC-F5-28",vendor: "Zyxel Communications Corporation"},
  {max_prefix: "00-A0-C5",vendor: "Zyxel Communications Corporation"},
  {max_prefix: "40-88-05",vendor: "Motorola Mobility LLC, a Lenovo Company"},
  {max_prefix: "60-C0-BF",vendor: "ON Semiconductor"},
  {max_prefix: "00-1E-04",vendor: "Hanson Research Corporation"},
  {max_prefix: "98-39-8E",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "D0-FC-CC",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "24-E2-71",vendor: "Qingdao Hisense Communications Co.,Ltd."},
  {max_prefix: "BC-60-10",vendor: "Qingdao Hisense Communications Co.,Ltd."},
  {max_prefix: "F0-F6-44",vendor: "Whitesky Science & Technology Co.,Ltd."},
  {max_prefix: "7C-6A-F3",vendor: "Integrated Device Technology (Malaysia) Sdn. Bhd."},
  {max_prefix: "20-F1-7C",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "34-6A-C2",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "C4-1C-FF",vendor: "Vizio, Inc"},
  {max_prefix: "44-D1-FA",vendor: "Shenzhen Yunlink Technology Co., Ltd"},
  {max_prefix: "C0-97-27",vendor: "SAMSUNG ELECTRO-MECHANICS(THAILAND)"},
  {max_prefix: "DC-29-3A",vendor: "Shenzhen Nuoshi Technology Co., LTD."},
  {max_prefix: "40-56-2D",vendor: "Smartron India Pvt ltd"},
  {max_prefix: "E4-62-51",vendor: "HAO CHENG GROUP LIMITED"},
  {max_prefix: "A0-D3-85",vendor: "AUMA Riester GmbH & Co. KG"},
  {max_prefix: "14-14-E6",vendor: "Ningbo Sanhe Digital Co.,Ltd"},
  {max_prefix: "38-76-D1",vendor: "Euronda SpA"},
  {max_prefix: "0C-51-01",vendor: "Apple, Inc."},
  {max_prefix: "2C-F0-A2",vendor: "Apple, Inc."},
  {max_prefix: "68-FB-7E",vendor: "Apple, Inc."},
  {max_prefix: "84-A1-34",vendor: "Apple, Inc."},
  {max_prefix: "00-1A-34",vendor: "Konka Group Co., Ltd."},
  {max_prefix: "00-11-FC",vendor: "HARTING Electronics GmbH"},
  {max_prefix: "00-23-89",vendor: "Hangzhou H3C Technologies Co., Limited"},
  {max_prefix: "3C-E5-A6",vendor: "Hangzhou H3C Technologies Co., Limited"},
  {max_prefix: "D8-20-9F",vendor: "Cubro Acronet GesmbH"},
  {max_prefix: "8C-77-16",vendor: "LONGCHEER TELECOMMUNICATION LIMITED"},
  {max_prefix: "C4-69-3E",vendor: "Turbulence Design Inc."},
  {max_prefix: "00-95-69",vendor: "LSD Science and Technology Co.,Ltd."},
  {max_prefix: "B0-CF-4D",vendor: "MI-Zone Technology Ireland"},
  {max_prefix: "28-9A-FA",vendor: "TCT mobile ltd"},
  {max_prefix: "90-4D-4A",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "5C-DD-70",vendor: "Hangzhou H3C Technologies Co., Limited"},
  {max_prefix: "3C-8C-40",vendor: "Hangzhou H3C Technologies Co., Limited"},
  {max_prefix: "A0-67-BE",vendor: "Sicon srl"},
  {max_prefix: "E0-4F-43",vendor: "Universal Global Scientific Industrial Co., Ltd."},
  {max_prefix: "6C-24-83",vendor: "Microsoft Mobile Oy"},
  {max_prefix: "68-91-D0",vendor: "IEEE Registration Authority"},
  {max_prefix: "00-25-82",vendor: "Maksat Technologies (P) Ltd"},
  {max_prefix: "48-C0-49",vendor: "Broad Telecom SA"},
  {max_prefix: "AC-6F-BB",vendor: "TATUNG Technology Inc."},
  {max_prefix: "00-1C-41",vendor: "scemtec Transponder Technology GmbH"},
  {max_prefix: "14-63-08",vendor: "JABIL CIRCUIT (SHANGHAI) LTD."},
  {max_prefix: "00-1E-25",vendor: "INTEK DIGITAL"},
  {max_prefix: "00-E0-CF",vendor: "INTEGRATED DEVICE"},
  {max_prefix: "00-60-B1",vendor: "Input/Output, Inc."},
  {max_prefix: "54-7F-54",vendor: "INGENICO"},
  {max_prefix: "90-C7-D8",vendor: "zte corporation"},
  {max_prefix: "00-3A-7D",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-1A-45",vendor: "GN Netcom A/S"},
  {max_prefix: "00-20-88",vendor: "GLOBAL VILLAGE COMMUNICATION"},
  {max_prefix: "00-1C-D7",vendor: "Harman/Becker Automotive Systems GmbH"},
  {max_prefix: "00-19-21",vendor: "Elitegroup Computer Systems Co.,Ltd."},
  {max_prefix: "00-16-EC",vendor: "Elitegroup Computer Systems Co.,Ltd."},
  {max_prefix: "00-07-95",vendor: "Elitegroup Computer Systems Co.,Ltd."},
  {max_prefix: "54-13-79",vendor: "Hon Hai Precision Ind. Co.,Ltd."},
  {max_prefix: "84-40-76",vendor: "Drivenets"},
  {max_prefix: "00-06-5F",vendor: "ECI Telecom Ltd."},
  {max_prefix: "00-20-8F",vendor: "ECI Telecom Ltd."},
  {max_prefix: "FC-0F-4B",vendor: "Texas Instruments"},
  {max_prefix: "D4-88-3F",vendor: "HDPRO CO., LTD."},
  {max_prefix: "60-B6-17",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "18-A3-E8",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "DC-9C-9F",vendor: "Shenzhen YOUHUA Technology Co., Ltd"},
  {max_prefix: "74-DF-BF",vendor: "Liteon Technology Corporation"},
  {max_prefix: "F0-3E-90",vendor: "Ruckus Wireless"},
  {max_prefix: "00-18-5C",vendor: "EDSLAB Technologies"},
  {max_prefix: "00-0E-2E",vendor: "Edimax Technology Co. Ltd."},
  {max_prefix: "84-AD-58",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "58-60-5F",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "D8-D7-23",vendor: "IDS, Inc"},
  {max_prefix: "00-A0-F4",vendor: "GE"},
  {max_prefix: "AC-0D-1B",vendor: "LG Electronics (Mobile Communications)"},
  {max_prefix: "F0-D1-B8",vendor: "LEDVANCE"},
  {max_prefix: "98-6D-35",vendor: "IEEE Registration Authority"},
  {max_prefix: "88-79-5B",vendor: "Konka Group Co., Ltd."},
  {max_prefix: "B0-7E-70",vendor: "Zadara Storage Ltd."},
  {max_prefix: "00-80-B1",vendor: "SOFTCOM A/S"},
  {max_prefix: "20-2D-F8",vendor: "Digital Media Cartridge Ltd."},
  {max_prefix: "FC-2F-AA",vendor: "Nokia"},
  {max_prefix: "08-1F-71",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "5C-CA-1A",vendor: "Microsoft Mobile Oy"},
  {max_prefix: "74-1E-93",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "0C-5A-9E",vendor: "Wi-SUN Alliance"},
  {max_prefix: "D8-80-3C",vendor: "Anhui Huami Information Technology Company Limited"},
  {max_prefix: "10-D0-AB",vendor: "zte corporation"},
  {max_prefix: "00-04-C6",vendor: "YAMAHA MOTOR CO.,LTD"},
  {max_prefix: "98-1F-B1",vendor: "Shenzhen Lemon Network Technology Co.,Ltd"},
  {max_prefix: "20-2D-07",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "B4-4B-D2",vendor: "Apple, Inc."},
  {max_prefix: "DC-41-5F",vendor: "Apple, Inc."},
  {max_prefix: "64-12-25",vendor: "Cisco Systems, Inc"},
  {max_prefix: "78-64-E6",vendor: "Green Motive Technology Limited"},
  {max_prefix: "3C-BE-E1",vendor: "NIKON CORPORATION"},
  {max_prefix: "18-C5-01",vendor: "SHENZHEN GONGJIN ELECTRONICS CO.,LT"},
  {max_prefix: "00-A0-B8",vendor: "NetApp"},
  {max_prefix: "10-2A-B3",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "40-D3-57",vendor: "Ison Technology Co., Ltd."},
  {max_prefix: "A0-B9-ED",vendor: "Skytap"},
  {max_prefix: "00-34-DA",vendor: "LG Electronics (Mobile Communications)"},
  {max_prefix: "38-10-D5",vendor: "AVM Audiovisuelles Marketing und Computersysteme GmbH"},
  {max_prefix: "00-60-16",vendor: "CLARIION"},
  {max_prefix: "00-C8-8B",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-09-D2",vendor: "Mai Logic Inc."},
  {max_prefix: "24-C3-F9",vendor: "Securitas Direct AB"},
  {max_prefix: "2C-21-D7",vendor: "IMAX Corporation"},
  {max_prefix: "E8-B2-AC",vendor: "Apple, Inc."},
  {max_prefix: "E4-9A-79",vendor: "Apple, Inc."},
  {max_prefix: "30-A9-DE",vendor: "LG Innotek"},
  {max_prefix: "F0-1B-6C",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "4C-25-78",vendor: "Nokia Corporation"},
  {max_prefix: "BC-C6-DB",vendor: "Nokia Corporation"},
  {max_prefix: "60-A8-FE",vendor: "Nokia Solutions and Networks GmbH & Co. KG"},
  {max_prefix: "94-C9-60",vendor: "Zhongshan B&T technology.co.,ltd"},
  {max_prefix: "F4-5C-89",vendor: "Apple, Inc."},
  {max_prefix: "20-76-8F",vendor: "Apple, Inc."},
  {max_prefix: "9C-5C-F9",vendor: "Sony Corporation"},
  {max_prefix: "00-11-D1",vendor: "Soft Imaging System GmbH"},
  {max_prefix: "98-D6-86",vendor: "Chyi Lee industry Co., ltd."},
  {max_prefix: "8C-C6-61",vendor: "Current, powered by GE"},
  {max_prefix: "88-A0-84",vendor: "Formation Data Systems"},
  {max_prefix: "74-C3-30",vendor: "SHENZHEN FAST TECHNOLOGIES CO.,LTD"},
  {max_prefix: "00-1D-3B",vendor: "Nokia Danmark A/S"},
  {max_prefix: "00-1D-FD",vendor: "Nokia Danmark A/S"},
  {max_prefix: "00-1E-3B",vendor: "Nokia Danmark A/S"},
  {max_prefix: "00-1E-A4",vendor: "Nokia Danmark A/S"},
  {max_prefix: "00-26-CC",vendor: "Nokia Danmark A/S"},
  {max_prefix: "00-0E-ED",vendor: "Nokia Danmark A/S"},
  {max_prefix: "00-11-9F",vendor: "Nokia Danmark A/S"},
  {max_prefix: "00-1A-16",vendor: "Nokia Danmark A/S"},
  {max_prefix: "DC-B3-B4",vendor: "Honeywell Environmental & Combustion Controls (Tianjin) Co., Ltd."},
  {max_prefix: "00-24-7C",vendor: "Nokia Danmark A/S"},
  {max_prefix: "00-1A-89",vendor: "Nokia Danmark A/S"},
  {max_prefix: "00-1A-DC",vendor: "Nokia Danmark A/S"},
  {max_prefix: "00-25-CF",vendor: "Nokia Danmark A/S"},
  {max_prefix: "00-21-AB",vendor: "Nokia Danmark A/S"},
  {max_prefix: "00-1F-DE",vendor: "Nokia Danmark A/S"},
  {max_prefix: "00-1F-DF",vendor: "Nokia Danmark A/S"},
  {max_prefix: "54-79-75",vendor: "Nokia Corporation"},
  {max_prefix: "A8-7B-39",vendor: "Nokia Corporation"},
  {max_prefix: "00-22-66",vendor: "Nokia Danmark A/S"},
  {max_prefix: "00-21-FE",vendor: "Nokia Danmark A/S"},
  {max_prefix: "70-25-59",vendor: "CyberTAN Technology Inc."},
  {max_prefix: "00-1D-20",vendor: "Comtrend Corporation"},
  {max_prefix: "08-37-3D",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "C4-88-E5",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "60-7E-DD",vendor: "Microsoft Mobile Oy"},
  {max_prefix: "A8-A0-89",vendor: "Tactical Communications"},
  {max_prefix: "48-36-5F",vendor: "Wintecronics Ltd."},
  {max_prefix: "00-0B-CA",vendor: "DATAVAN TC"},
  {max_prefix: "C4-77-AB",vendor: "Beijing ASU Tech Co.,Ltd"},
  {max_prefix: "0C-75-BD",vendor: "Cisco Systems, Inc"},
  {max_prefix: "30-0D-43",vendor: "Microsoft Mobile Oy"},
  {max_prefix: "50-56-BF",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "90-F1-AA",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "10-77-B1",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "00-1F-C7",vendor: "Casio Hitachi Mobile Communications Co., Ltd."},
  {max_prefix: "FC-C7-34",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "84-25-DB",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "B0-EC-71",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "E4-58-B8",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "08-8C-2C",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "A4-9A-58",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "08-EE-8B",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "64-B8-53",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "38-94-96",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "00-00-0E",vendor: "FUJITSU LIMITED"},
  {max_prefix: "00-0B-5D",vendor: "FUJITSU LIMITED"},
  {max_prefix: "08-05-81",vendor: "Roku, Inc."},
  {max_prefix: "74-45-8A",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "C4-47-3F",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "5C-DC-96",vendor: "Arcadyan Technology Corporation"},
  {max_prefix: "00-1A-2A",vendor: "Arcadyan Technology Corporation"},
  {max_prefix: "88-25-2C",vendor: "Arcadyan Technology Corporation"},
  {max_prefix: "00-E0-63",vendor: "Cabletron Systems, Inc."},
  {max_prefix: "00-0D-F3",vendor: "Asmax Solutions"},
  {max_prefix: "00-0D-B6",vendor: "Broadcom"},
  {max_prefix: "00-0A-F7",vendor: "Broadcom"},
  {max_prefix: "D4-01-29",vendor: "Broadcom"},
  {max_prefix: "00-1D-00",vendor: "Brivo Systems, LLC"},
  {max_prefix: "00-20-D6",vendor: "Breezecom, Ltd."},
  {max_prefix: "1C-C6-3C",vendor: "Arcadyan Technology Corporation"},
  {max_prefix: "18-83-BF",vendor: "Arcadyan Technology Corporation"},
  {max_prefix: "68-ED-43",vendor: "BlackBerry RTS"},
  {max_prefix: "70-AA-B2",vendor: "BlackBerry RTS"},
  {max_prefix: "00-0F-86",vendor: "BlackBerry RTS"},
  {max_prefix: "00-14-6C",vendor: "NETGEAR"},
  {max_prefix: "00-1E-2A",vendor: "NETGEAR"},
  {max_prefix: "00-18-4D",vendor: "NETGEAR"},
  {max_prefix: "00-04-0E",vendor: "AVM GmbH"},
  {max_prefix: "9C-C7-A6",vendor: "AVM GmbH"},
  {max_prefix: "A0-63-91",vendor: "NETGEAR"},
  {max_prefix: "20-E5-2A",vendor: "NETGEAR"},
  {max_prefix: "44-94-FC",vendor: "NETGEAR"},
  {max_prefix: "20-0C-C8",vendor: "NETGEAR"},
  {max_prefix: "0C-A4-2A",vendor: "OB Telecom Electronic Technology Co., Ltd"},
  {max_prefix: "74-44-01",vendor: "NETGEAR"},
  {max_prefix: "E0-91-F5",vendor: "NETGEAR"},
  {max_prefix: "40-BA-61",vendor: "ARIMA Communications Corp."},
  {max_prefix: "00-11-F5",vendor: "ASKEY COMPUTER CORP"},
  {max_prefix: "00-16-E3",vendor: "ASKEY COMPUTER CORP"},
  {max_prefix: "E8-39-DF",vendor: "ASKEY COMPUTER CORP"},
  {max_prefix: "00-24-D2",vendor: "ASKEY COMPUTER CORP"},
  {max_prefix: "B4-EE-B4",vendor: "ASKEY COMPUTER CORP"},
  {max_prefix: "D0-DF-9A",vendor: "Liteon Technology Corporation"},
  {max_prefix: "1C-65-9D",vendor: "Liteon Technology Corporation"},
  {max_prefix: "30-10-B3",vendor: "Liteon Technology Corporation"},
  {max_prefix: "E8-C7-4F",vendor: "Liteon Technology Corporation"},
  {max_prefix: "D0-53-49",vendor: "Liteon Technology Corporation"},
  {max_prefix: "E8-74-E6",vendor: "ADB Broadband Italia"},
  {max_prefix: "00-20-E0",vendor: "Actiontec Electronics, Inc"},
  {max_prefix: "00-26-62",vendor: "Actiontec Electronics, Inc"},
  {max_prefix: "94-88-15",vendor: "Infinique Worldwide Inc"},
  {max_prefix: "5C-B5-24",vendor: "Sony Corporation"},
  {max_prefix: "90-C1-15",vendor: "Sony Corporation"},
  {max_prefix: "D0-51-62",vendor: "Sony Corporation"},
  {max_prefix: "18-00-2D",vendor: "Sony Corporation"},
  {max_prefix: "28-0D-FC",vendor: "Sony Interactive Entertainment Inc."},
  {max_prefix: "00-16-B8",vendor: "Sony Corporation"},
  {max_prefix: "00-24-EF",vendor: "Sony Corporation"},
  {max_prefix: "00-25-E7",vendor: "Sony Corporation"},
  {max_prefix: "58-17-0C",vendor: "Sony Corporation"},
  {max_prefix: "70-1A-04",vendor: "Liteon Technology Corporation"},
  {max_prefix: "48-D2-24",vendor: "Liteon Technology Corporation"},
  {max_prefix: "20-68-9D",vendor: "Liteon Technology Corporation"},
  {max_prefix: "00-25-53",vendor: "ADB Broadband Italia"},
  {max_prefix: "00-19-3E",vendor: "ADB Broadband Italia"},
  {max_prefix: "00-08-27",vendor: "ADB Broadband Italia"},
  {max_prefix: "D0-E4-4A",vendor: "Murata Manufacturing Co., Ltd."},
  {max_prefix: "08-00-46",vendor: "Sony Corporation"},
  {max_prefix: "4C-14-A3",vendor: "TCL Technoly Electronics (Huizhou) Co., Ltd."},
  {max_prefix: "4C-B0-E8",vendor: "Beijing RongZhi xinghua technology co., LTD"},
  {max_prefix: "74-DA-EA",vendor: "Texas Instruments"},
  {max_prefix: "D8-87-D5",vendor: "Leadcore Technology CO.,LTD"},
  {max_prefix: "00-F2-8B",vendor: "Cisco Systems, Inc"},
  {max_prefix: "EC-F0-0E",vendor: "AboCom"},
  {max_prefix: "00-E0-98",vendor: "AboCom"},
  {max_prefix: "74-2F-68",vendor: "AzureWave Technology Inc."},
  {max_prefix: "38-4F-F0",vendor: "AzureWave Technology Inc."},
  {max_prefix: "34-13-E8",vendor: "Intel Corporate"},
  {max_prefix: "34-E6-AD",vendor: "Intel Corporate"},
  {max_prefix: "08-11-96",vendor: "Intel Corporate"},
  {max_prefix: "18-3D-A2",vendor: "Intel Corporate"},
  {max_prefix: "80-9B-20",vendor: "Intel Corporate"},
  {max_prefix: "B4-B6-76",vendor: "Intel Corporate"},
  {max_prefix: "3C-A9-F4",vendor: "Intel Corporate"},
  {max_prefix: "9C-4E-36",vendor: "Intel Corporate"},
  {max_prefix: "B8-8A-60",vendor: "Intel Corporate"},
  {max_prefix: "78-FF-57",vendor: "Intel Corporate"},
  {max_prefix: "00-27-10",vendor: "Intel Corporate"},
  {max_prefix: "00-1B-21",vendor: "Intel Corporate"},
  {max_prefix: "18-FF-0F",vendor: "Intel Corporate"},
  {max_prefix: "A4-8E-0A",vendor: "DeLaval International AB"},
  {max_prefix: "AC-2B-6E",vendor: "Intel Corporate"},
  {max_prefix: "00-0A-8A",vendor: "Cisco Systems, Inc"},
  {max_prefix: "9C-35-83",vendor: "Nipro Diagnostics, Inc"},
  {max_prefix: "C0-61-18",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "00-23-14",vendor: "Intel Corporate"},
  {max_prefix: "34-02-86",vendor: "Intel Corporate"},
  {max_prefix: "00-1C-BF",vendor: "Intel Corporate"},
  {max_prefix: "34-DE-1A",vendor: "Intel Corporate"},
  {max_prefix: "E8-B1-FC",vendor: "Intel Corporate"},
  {max_prefix: "CC-3D-82",vendor: "Intel Corporate"},
  {max_prefix: "F8-E0-79",vendor: "Motorola Mobility LLC, a Lenovo Company"},
  {max_prefix: "CC-C3-EA",vendor: "Motorola Mobility LLC, a Lenovo Company"},
  {max_prefix: "40-78-6A",vendor: "Motorola Mobility LLC, a Lenovo Company"},
  {max_prefix: "D0-22-12",vendor: "IEEE Registration Authority"},
  {max_prefix: "10-07-23",vendor: "IEEE Registration Authority"},
  {max_prefix: "A4-4F-29",vendor: "IEEE Registration Authority"},
  {max_prefix: "74-F8-DB",vendor: "IEEE Registration Authority"},
  {max_prefix: "A4-3B-FA",vendor: "IEEE Registration Authority"},
  {max_prefix: "00-1F-3C",vendor: "Intel Corporate"},
  {max_prefix: "00-23-15",vendor: "Intel Corporate"},
  {max_prefix: "00-16-6F",vendor: "Intel Corporate"},
  {max_prefix: "00-19-D1",vendor: "Intel Corporate"},
  {max_prefix: "00-19-D2",vendor: "Intel Corporate"},
  {max_prefix: "C0-83-0A",vendor: "2Wire Inc"},
  {max_prefix: "38-3B-C8",vendor: "2Wire Inc"},
  {max_prefix: "60-FE-20",vendor: "2Wire Inc"},
  {max_prefix: "00-18-3F",vendor: "2Wire Inc"},
  {max_prefix: "00-24-56",vendor: "2Wire Inc"},
  {max_prefix: "18-62-2C",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "3C-81-D8",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "40-F2-01",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "D0-84-B0",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "18-1E-78",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "00-37-B7",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "00-54-BD",vendor: "Swelaser AB"},
  {max_prefix: "20-BB-76",vendor: "COL GIOVANNI PAOLO SpA"},
  {max_prefix: "3C-DD-89",vendor: "SOMO HOLDINGS & TECH. CO.,LTD."},
  {max_prefix: "18-01-E3",vendor: "Bittium Wireless Ltd"},
  {max_prefix: "00-1E-4C",vendor: "Hon Hai Precision Ind. Co.,Ltd."},
  {max_prefix: "D8-54-3A",vendor: "Texas Instruments"},
  {max_prefix: "64-9C-8E",vendor: "Texas Instruments"},
  {max_prefix: "10-2E-AF",vendor: "Texas Instruments"},
  {max_prefix: "7C-8E-E4",vendor: "Texas Instruments"},
  {max_prefix: "B4-EE-D4",vendor: "Texas Instruments"},
  {max_prefix: "38-86-02",vendor: "Flexoptix GmbH"},
  {max_prefix: "40-65-A3",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "00-19-4B",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "00-1E-74",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "14-91-82",vendor: "Belkin International Inc."},
  {max_prefix: "C4-ED-BA",vendor: "Texas Instruments"},
  {max_prefix: "90-59-AF",vendor: "Texas Instruments"},
  {max_prefix: "BC-6A-29",vendor: "Texas Instruments"},
  {max_prefix: "84-7E-40",vendor: "Texas Instruments"},
  {max_prefix: "C8-3E-99",vendor: "Texas Instruments"},
  {max_prefix: "40-98-4E",vendor: "Texas Instruments"},
  {max_prefix: "00-17-EB",vendor: "Texas Instruments"},
  {max_prefix: "00-17-E6",vendor: "Texas Instruments"},
  {max_prefix: "00-18-32",vendor: "Texas Instruments"},
  {max_prefix: "3C-2D-B7",vendor: "Texas Instruments"},
  {max_prefix: "D0-37-61",vendor: "Texas Instruments"},
  {max_prefix: "54-64-D9",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "9C-8E-99",vendor: "Hewlett Packard"},
  {max_prefix: "00-19-5B",vendor: "D-Link Corporation"},
  {max_prefix: "00-0F-3D",vendor: "D-Link Corporation"},
  {max_prefix: "24-DA-11",vendor: "NO NDA Inc"},
  {max_prefix: "D0-B3-3F",vendor: "Shenzhen TINNO Mobile Technology Corp."},
  {max_prefix: "BC-D1-D3",vendor: "Shenzhen TINNO Mobile Technology Corp."},
  {max_prefix: "EC-22-80",vendor: "D-Link International"},
  {max_prefix: "24-00-BA",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "24-DF-6A",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "40-D8-55",vendor: "IEEE Registration Authority"},
  {max_prefix: "F4-F5-D8",vendor: "Google, Inc."},
  {max_prefix: "D8-3C-69",vendor: "Shenzhen TINNO Mobile Technology Corp."},
  {max_prefix: "B0-C0-90",vendor: "Chicony Electronics Co., Ltd."},
  {max_prefix: "90-7F-61",vendor: "Chicony Electronics Co., Ltd."},
  {max_prefix: "AC-06-C7",vendor: "ServerNet S.r.l."},
  {max_prefix: "00-59-AC",vendor: "KPN. B.V."},
  {max_prefix: "00-17-35",vendor: "Intel Wireless Network Group"},
  {max_prefix: "74-AC-5F",vendor: "Qiku Internet Network Scientific (Shenzhen) Co., Ltd."},
  {max_prefix: "18-AF-61",vendor: "Apple, Inc."},
  {max_prefix: "5C-F9-38",vendor: "Apple, Inc."},
  {max_prefix: "0C-05-35",vendor: "Juniper Systems"},
  {max_prefix: "BC-83-A7",vendor: "SHENZHEN CHUANGWEI-RGB ELECTRONICS CO.,LTD"},
  {max_prefix: "BC-EC-23",vendor: "SHENZHEN CHUANGWEI-RGB ELECTRONICS CO.,LTD"},
  {max_prefix: "78-8B-77",vendor: "Standar Telecom"},
  {max_prefix: "8C-57-9B",vendor: "Wistron Neweb Corporation"},
  {max_prefix: "34-AB-37",vendor: "Apple, Inc."},
  {max_prefix: "38-CA-DA",vendor: "Apple, Inc."},
  {max_prefix: "10-1F-74",vendor: "Hewlett Packard"},
  {max_prefix: "00-9C-02",vendor: "Hewlett Packard"},
  {max_prefix: "00-19-BB",vendor: "Hewlett Packard"},
  {max_prefix: "00-1F-29",vendor: "Hewlett Packard"},
  {max_prefix: "78-0C-B8",vendor: "Intel Corporate"},
  {max_prefix: "18-5E-0F",vendor: "Intel Corporate"},
  {max_prefix: "00-16-35",vendor: "Hewlett Packard"},
  {max_prefix: "00-08-C7",vendor: "Hewlett Packard"},
  {max_prefix: "00-10-E3",vendor: "Hewlett Packard"},
  {max_prefix: "00-08-83",vendor: "Hewlett Packard"},
  {max_prefix: "8C-DC-D4",vendor: "Hewlett Packard"},
  {max_prefix: "DC-FB-02",vendor: "BUFFALO.INC"},
  {max_prefix: "88-57-EE",vendor: "BUFFALO.INC"},
  {max_prefix: "00-21-5A",vendor: "Hewlett Packard"},
  {max_prefix: "00-23-7D",vendor: "Hewlett Packard"},
  {max_prefix: "00-26-55",vendor: "Hewlett Packard"},
  {max_prefix: "00-0D-9D",vendor: "Hewlett Packard"},
  {max_prefix: "00-15-60",vendor: "Hewlett Packard"},
  {max_prefix: "00-20-7B",vendor: "Intel Corporation"},
  {max_prefix: "00-11-75",vendor: "Intel Corporation"},
  {max_prefix: "D4-C9-EF",vendor: "Hewlett Packard"},
  {max_prefix: "FC-15-B4",vendor: "Hewlett Packard"},
  {max_prefix: "94-09-37",vendor: "HUMAX Co., Ltd."},
  {max_prefix: "E8-4D-D0",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "0C-45-BA",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "20-90-6F",vendor: "Shenzhen Tencent Computer System Co., Ltd."},
  {max_prefix: "6C-E3-B6",vendor: "Nera Telecommunications Ltd."},
  {max_prefix: "EC-5F-23",vendor: "Qinghai Kimascend Electronics Technology Co. Ltd."},
  {max_prefix: "04-7D-50",vendor: "Shenzhen Kang Ying Technology Co.Ltd."},
  {max_prefix: "54-EF-FE",vendor: "Fullpower Technologies, Inc."},
  {max_prefix: "DC-D3-21",vendor: "HUMAX Co., Ltd."},
  {max_prefix: "44-82-E5",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "00-23-4E",vendor: "Hon Hai Precision Ind. Co.,Ltd."},
  {max_prefix: "2C-81-58",vendor: "Hon Hai Precision Ind. Co.,Ltd."},
  {max_prefix: "80-02-DF",vendor: "ORA Inc."},
  {max_prefix: "2C-23-3A",vendor: "Hewlett Packard"},
  {max_prefix: "00-0A-57",vendor: "Hewlett Packard"},
  {max_prefix: "00-01-E7",vendor: "Hewlett Packard"},
  {max_prefix: "00-01-E6",vendor: "Hewlett Packard"},
  {max_prefix: "00-30-6E",vendor: "Hewlett Packard"},
  {max_prefix: "CC-44-63",vendor: "Apple, Inc."},
  {max_prefix: "6C-72-E7",vendor: "Apple, Inc."},
  {max_prefix: "74-1B-B2",vendor: "Apple, Inc."},
  {max_prefix: "00-1D-0F",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "3C-4A-92",vendor: "Hewlett Packard"},
  {max_prefix: "00-23-76",vendor: "HTC Corporation"},
  {max_prefix: "38-E7-D8",vendor: "HTC Corporation"},
  {max_prefix: "18-87-96",vendor: "HTC Corporation"},
  {max_prefix: "B4-CE-F6",vendor: "HTC Corporation"},
  {max_prefix: "7C-7D-3D",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "3C-A8-2A",vendor: "Hewlett Packard"},
  {max_prefix: "90-48-9A",vendor: "Hon Hai Precision Ind. Co.,Ltd."},
  {max_prefix: "00-71-CC",vendor: "Hon Hai Precision Ind. Co.,Ltd."},
  {max_prefix: "B0-5B-67",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "CC-A2-23",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "78-6A-89",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "14-B9-68",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "88-25-93",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "00-1F-E1",vendor: "Hon Hai Precision Ind. Co.,Ltd."},
  {max_prefix: "10-A5-D0",vendor: "Murata Manufacturing Co., Ltd."},
  {max_prefix: "00-12-F2",vendor: "Brocade Communications Systems LLC"},
  {max_prefix: "00-05-1E",vendor: "Brocade Communications Systems LLC"},
  {max_prefix: "38-46-08",vendor: "zte corporation"},
  {max_prefix: "4C-AC-0A",vendor: "zte corporation"},
  {max_prefix: "B4-B3-62",vendor: "zte corporation"},
  {max_prefix: "B0-75-D5",vendor: "zte corporation"},
  {max_prefix: "6C-E8-73",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "C4-6E-1F",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "50-FA-84",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "44-B3-2D",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "00-23-CD",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "D8-5D-4C",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "A0-F3-C1",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "78-3E-53",vendor: "SKY UK LIMITED"},
  {max_prefix: "00-19-FB",vendor: "SKY UK LIMITED"},
  {max_prefix: "C4-F5-7C",vendor: "Brocade Communications Systems LLC"},
  {max_prefix: "D0-15-4A",vendor: "zte corporation"},
  {max_prefix: "00-26-ED",vendor: "zte corporation"},
  {max_prefix: "00-22-93",vendor: "zte corporation"},
  {max_prefix: "08-3E-8E",vendor: "Hon Hai Precision Ind. Co.,Ltd."},
  {max_prefix: "00-60-57",vendor: "Murata Manufacturing Co., Ltd."},
  {max_prefix: "70-A8-E3",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "F8-E8-11",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "20-0B-C7",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "F8-4A-BF",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "78-D7-52",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "10-47-80",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "94-77-2B",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "D4-40-F0",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "04-02-1F",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "50-A7-2B",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "0C-D6-BD",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "00-F8-1C",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "40-4D-8E",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "4C-54-99",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "F8-15-47",vendor: "Avaya Inc"},
  {max_prefix: "08-7A-4C",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "AC-E2-15",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "34-6B-D3",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "70-72-3C",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "AC-E8-7B",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "F8-3D-FF",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "28-5F-DB",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "5C-F9-6A",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "F4-9F-F3",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "24-09-95",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "84-DB-AC",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "50-61-84",vendor: "Avaya Inc"},
  {max_prefix: "10-CD-AE",vendor: "Avaya Inc"},
  {max_prefix: "04-8A-15",vendor: "Avaya Inc"},
  {max_prefix: "B4-B0-17",vendor: "Avaya Inc"},
  {max_prefix: "90-FB-5B",vendor: "Avaya Inc"},
  {max_prefix: "C8-F4-06",vendor: "Avaya Inc"},
  {max_prefix: "70-52-C5",vendor: "Avaya Inc"},
  {max_prefix: "00-04-0D",vendor: "Avaya Inc"},
  {max_prefix: "54-89-98",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "30-D1-7E",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "64-09-80",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "18-59-36",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "20-A7-83",vendor: "miControl GmbH"},
  {max_prefix: "00-90-F2",vendor: "Cisco Systems, Inc"},
  {max_prefix: "74-D6-EA",vendor: "Texas Instruments"},
  {max_prefix: "20-91-48",vendor: "Texas Instruments"},
  {max_prefix: "F8-A4-5F",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "60-73-5C",vendor: "Cisco Systems, Inc"},
  {max_prefix: "34-A8-4E",vendor: "Cisco Systems, Inc"},
  {max_prefix: "54-78-1A",vendor: "Cisco Systems, Inc"},
  {max_prefix: "E0-2F-6D",vendor: "Cisco Systems, Inc"},
  {max_prefix: "94-04-9C",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "68-8F-84",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "00-60-5C",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-06-C1",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-E0-14",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-50-F0",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-50-14",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-50-BD",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-90-6F",vendor: "Cisco Systems, Inc"},
  {max_prefix: "54-4A-16",vendor: "Texas Instruments"},
  {max_prefix: "58-97-1E",vendor: "Cisco Systems, Inc"},
  {max_prefix: "B4-E9-B0",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-08-32",vendor: "Cisco Systems, Inc"},
  {max_prefix: "70-10-5C",vendor: "Cisco Systems, Inc"},
  {max_prefix: "10-F3-11",vendor: "Cisco Systems, Inc"},
  {max_prefix: "30-85-A9",vendor: "ASUSTek COMPUTER INC."},
  {max_prefix: "B8-38-61",vendor: "Cisco Systems, Inc"},
  {max_prefix: "58-0A-20",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-17-31",vendor: "ASUSTek COMPUTER INC."},
  {max_prefix: "00-22-15",vendor: "ASUSTek COMPUTER INC."},
  {max_prefix: "E0-CB-4E",vendor: "ASUSTek COMPUTER INC."},
  {max_prefix: "2C-3E-CF",vendor: "Cisco Systems, Inc"},
  {max_prefix: "50-87-89",vendor: "Cisco Systems, Inc"},
  {max_prefix: "38-1C-1A",vendor: "Cisco Systems, Inc"},
  {max_prefix: "BC-67-1C",vendor: "Cisco Systems, Inc"},
  {max_prefix: "34-62-88",vendor: "Cisco Systems, Inc"},
  {max_prefix: "CC-D8-C1",vendor: "Cisco Systems, Inc"},
  {max_prefix: "7C-0E-CE",vendor: "Cisco Systems, Inc"},
  {max_prefix: "A0-EC-F9",vendor: "Cisco Systems, Inc"},
  {max_prefix: "5C-A4-8A",vendor: "Cisco Systems, Inc"},
  {max_prefix: "1C-1D-86",vendor: "Cisco Systems, Inc"},
  {max_prefix: "50-17-FF",vendor: "Cisco Systems, Inc"},
  {max_prefix: "18-9C-5D",vendor: "Cisco Systems, Inc"},
  {max_prefix: "DC-A5-F4",vendor: "Cisco Systems, Inc"},
  {max_prefix: "54-7C-69",vendor: "Cisco Systems, Inc"},
  {max_prefix: "9C-57-AD",vendor: "Cisco Systems, Inc"},
  {max_prefix: "B0-59-47",vendor: "Shenzhen Qihu Intelligent Technology Company Limited"},
  {max_prefix: "00-40-96",vendor: "Cisco Systems, Inc"},
  {max_prefix: "0C-E0-E4",vendor: "PLANTRONICS, INC."},
  {max_prefix: "74-A2-E6",vendor: "Cisco Systems, Inc"},
  {max_prefix: "BC-F1-F2",vendor: "Cisco Systems, Inc"},
  {max_prefix: "C8-00-84",vendor: "Cisco Systems, Inc"},
  {max_prefix: "40-A6-E8",vendor: "Cisco Systems, Inc"},
  {max_prefix: "E8-65-49",vendor: "Cisco Systems, Inc"},
  {max_prefix: "B0-7D-47",vendor: "Cisco Systems, Inc"},
  {max_prefix: "38-ED-18",vendor: "Cisco Systems, Inc"},
  {max_prefix: "38-20-56",vendor: "Cisco Systems, Inc"},
  {max_prefix: "DC-CE-C1",vendor: "Cisco Systems, Inc"},
  {max_prefix: "60-FE-C5",vendor: "Apple, Inc."},
  {max_prefix: "E4-25-E7",vendor: "Apple, Inc."},
  {max_prefix: "BC-92-6B",vendor: "Apple, Inc."},
  {max_prefix: "10-1C-0C",vendor: "Apple, Inc."},
  {max_prefix: "08-00-07",vendor: "Apple, Inc."},
  {max_prefix: "40-D3-2D",vendor: "Apple, Inc."},
  {max_prefix: "C4-2C-03",vendor: "Apple, Inc."},
  {max_prefix: "90-27-E4",vendor: "Apple, Inc."},
  {max_prefix: "10-9A-DD",vendor: "Apple, Inc."},
  {max_prefix: "00-16-CB",vendor: "Apple, Inc."},
  {max_prefix: "00-17-F2",vendor: "Apple, Inc."},
  {max_prefix: "00-1F-5B",vendor: "Apple, Inc."},
  {max_prefix: "00-24-36",vendor: "Apple, Inc."},
  {max_prefix: "00-19-47",vendor: "Cisco SPVTG"},
  {max_prefix: "00-18-39",vendor: "Cisco-Linksys, LLC"},
  {max_prefix: "00-25-4B",vendor: "Apple, Inc."},
  {max_prefix: "7C-6D-62",vendor: "Apple, Inc."},
  {max_prefix: "6C-3E-6D",vendor: "Apple, Inc."},
  {max_prefix: "BC-67-78",vendor: "Apple, Inc."},
  {max_prefix: "20-C9-D0",vendor: "Apple, Inc."},
  {max_prefix: "68-96-7B",vendor: "Apple, Inc."},
  {max_prefix: "84-FC-FE",vendor: "Apple, Inc."},
  {max_prefix: "E4-8B-7F",vendor: "Apple, Inc."},
  {max_prefix: "58-1F-AA",vendor: "Apple, Inc."},
  {max_prefix: "88-C6-63",vendor: "Apple, Inc."},
  {max_prefix: "A4-67-06",vendor: "Apple, Inc."},
  {max_prefix: "8C-58-77",vendor: "Apple, Inc."},
  {max_prefix: "28-37-37",vendor: "Apple, Inc."},
  {max_prefix: "50-EA-D6",vendor: "Apple, Inc."},
  {max_prefix: "B8-17-C2",vendor: "Apple, Inc."},
  {max_prefix: "7C-11-BE",vendor: "Apple, Inc."},
  {max_prefix: "BC-3B-AF",vendor: "Apple, Inc."},
  {max_prefix: "3C-E0-72",vendor: "Apple, Inc."},
  {max_prefix: "38-48-4C",vendor: "Apple, Inc."},
  {max_prefix: "D8-D1-CB",vendor: "Apple, Inc."},
  {max_prefix: "A8-FA-D8",vendor: "Apple, Inc."},
  {max_prefix: "00-88-65",vendor: "Apple, Inc."},
  {max_prefix: "E0-F5-C6",vendor: "Apple, Inc."},
  {max_prefix: "A0-ED-CD",vendor: "Apple, Inc."},
  {max_prefix: "18-9E-FC",vendor: "Apple, Inc."},
  {max_prefix: "80-49-71",vendor: "Apple, Inc."},
  {max_prefix: "98-D6-BB",vendor: "Apple, Inc."},
  {max_prefix: "7C-F0-5F",vendor: "Apple, Inc."},
  {max_prefix: "5C-97-F3",vendor: "Apple, Inc."},
  {max_prefix: "D4-F4-6F",vendor: "Apple, Inc."},
  {max_prefix: "48-43-7C",vendor: "Apple, Inc."},
  {max_prefix: "34-A3-95",vendor: "Apple, Inc."},
  {max_prefix: "78-7E-61",vendor: "Apple, Inc."},
  {max_prefix: "C0-F2-FB",vendor: "Apple, Inc."},
  {max_prefix: "24-E3-14",vendor: "Apple, Inc."},
  {max_prefix: "80-E6-50",vendor: "Apple, Inc."},
  {max_prefix: "90-FD-61",vendor: "Apple, Inc."},
  {max_prefix: "2C-F0-EE",vendor: "Apple, Inc."},
  {max_prefix: "3C-15-C2",vendor: "Apple, Inc."},
  {max_prefix: "6C-70-9F",vendor: "Apple, Inc."},
  {max_prefix: "64-76-BA",vendor: "Apple, Inc."},
  {max_prefix: "34-E2-FD",vendor: "Apple, Inc."},
  {max_prefix: "04-48-9A",vendor: "Apple, Inc."},
  {max_prefix: "08-70-45",vendor: "Apple, Inc."},
  {max_prefix: "A8-88-08",vendor: "Apple, Inc."},
  {max_prefix: "A4-C3-61",vendor: "Apple, Inc."},
  {max_prefix: "B0-9F-BA",vendor: "Apple, Inc."},
  {max_prefix: "8C-29-37",vendor: "Apple, Inc."},
  {max_prefix: "AC-CF-5C",vendor: "Apple, Inc."},
  {max_prefix: "80-00-6E",vendor: "Apple, Inc."},
  {max_prefix: "84-8E-0C",vendor: "Apple, Inc."},
  {max_prefix: "F0-99-BF",vendor: "Apple, Inc."},
  {max_prefix: "94-E9-6A",vendor: "Apple, Inc."},
  {max_prefix: "AC-29-3A",vendor: "Apple, Inc."},
  {max_prefix: "9C-FC-01",vendor: "Apple, Inc."},
  {max_prefix: "60-F8-1D",vendor: "Apple, Inc."},
  {max_prefix: "5C-F5-DA",vendor: "Apple, Inc."},
  {max_prefix: "18-EE-69",vendor: "Apple, Inc."},
  {max_prefix: "64-9A-BE",vendor: "Apple, Inc."},
  {max_prefix: "9C-35-EB",vendor: "Apple, Inc."},
  {max_prefix: "50-7A-55",vendor: "Apple, Inc."},
  {max_prefix: "0C-4D-E9",vendor: "Apple, Inc."},
  {max_prefix: "F0-F6-1C",vendor: "Apple, Inc."},
  {max_prefix: "F0-F2-49",vendor: "Hitron Technologies. Inc"},
  {max_prefix: "38-C9-86",vendor: "Apple, Inc."},
  {max_prefix: "D0-33-11",vendor: "Apple, Inc."},
  {max_prefix: "58-82-A8",vendor: "Microsoft"},
  {max_prefix: "C8-C2-C6",vendor: "Shanghai Airm2m Communication Technology Co., Ltd"},
  {max_prefix: "78-9C-85",vendor: "August Home, Inc."},
  {max_prefix: "DC-FE-07",vendor: "PEGATRON CORPORATION"},
  {max_prefix: "70-79-38",vendor: "Wuxi Zhanrui Electronic Technology Co.,LTD"},
  {max_prefix: "B8-13-E9",vendor: "Trace Live Network"},
  {max_prefix: "58-68-5D",vendor: "Tempo Australia Pty Ltd"},
  {max_prefix: "24-31-84",vendor: "SHARP Corporation"},
  {max_prefix: "58-2B-DB",vendor: "Pax AB"},
  {max_prefix: "24-DA-9B",vendor: "Motorola Mobility LLC, a Lenovo Company"},
  {max_prefix: "30-E0-90",vendor: "Genevisio Ltd."},
  {max_prefix: "F4-4D-30",vendor: "Elitegroup Computer Systems Co.,Ltd."},
  {max_prefix: "E0-36-76",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "EC-38-8F",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "C4-9E-41",vendor: "G24 Power Limited"},
  {max_prefix: "D0-3E-5C",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "F0-0D-5C",vendor: "JinQianMao  Technology Co.,Ltd."},
  {max_prefix: "54-BE-53",vendor: "zte corporation"},
  {max_prefix: "28-0E-8B",vendor: "Beijing Spirit Technology Development Co., Ltd."},
  {max_prefix: "80-B7-09",vendor: "Viptela, Inc"},
  {max_prefix: "A4-DC-BE",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "D4-94-E8",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "B0-78-F0",vendor: "Beijing HuaqinWorld Technology Co.,Ltd."},
  {max_prefix: "38-D4-0B",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "20-9B-CD",vendor: "Apple, Inc."},
  {max_prefix: "94-BB-AE",vendor: "Husqvarna AB"},
  {max_prefix: "EC-B8-70",vendor: "Beijing Heweinet Technology Co.,Ltd."},
  {max_prefix: "30-95-E3",vendor: "SHANGHAI SIMCOM LIMITED"},
  {max_prefix: "E8-3A-12",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "80-65-6D",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "FC-F1-36",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "B8-86-87",vendor: "Liteon Technology Corporation"},
  {max_prefix: "18-89-5B",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "58-49-25",vendor: "E3 Enterprise"},
  {max_prefix: "94-F2-78",vendor: "Elma Electronic"},
  {max_prefix: "08-94-EF",vendor: "Wistron Infocomm (Zhongshan) Corporation"},
  {max_prefix: "28-37-13",vendor: "Shenzhen 3Nod Digital Technology Co., Ltd."},
  {max_prefix: "E0-31-9E",vendor: "Valve Corporation"},
  {max_prefix: "7C-AB-25",vendor: "MESMO TECHNOLOGY INC."},
  {max_prefix: "58-48-22",vendor: "Sony Corporation"},
  {max_prefix: "74-73-36",vendor: "MICRODIGTAL Inc"},
  {max_prefix: "B0-41-1D",vendor: "ITTIM Technologies"},
  {max_prefix: "7C-A2-37",vendor: "King Slide Technology CO., LTD."},
  {max_prefix: "3C-5C-C3",vendor: "Shenzhen First Blue Chip Technology Ltd"},
  {max_prefix: "EC-EE-D8",vendor: "ZTLX Network Technology Co.,Ltd"},
  {max_prefix: "80-EB-77",vendor: "Wistron Corporation"},
  {max_prefix: "48-39-74",vendor: "Proware Technologies Co., Ltd."},
  {max_prefix: "30-FF-F6",vendor: "HangZhou KuoHeng Technology Co.,ltd"},
  {max_prefix: "48-E2-44",vendor: "Hon Hai Precision Ind. Co.,Ltd."},
  {max_prefix: "D8-EF-CD",vendor: "Nokia Solutions and Networks GmbH & Co. KG"},
  {max_prefix: "4C-C6-81",vendor: "Shenzhen Aisat Electronic Co., Ltd."},
  {max_prefix: "C4-9F-F3",vendor: "Mciao Technologies, Inc."},
  {max_prefix: "78-8E-33",vendor: "Jiangsu SEUIC Technology Co.,Ltd"},
  {max_prefix: "88-41-57",vendor: "Shenzhen Atsmart Technology Co.,Ltd."},
  {max_prefix: "D8-9A-34",vendor: "Beijing SHENQI Technology Co., Ltd."},
  {max_prefix: "C4-EA-1D",vendor: "Technicolor Delivery Technologies Belgium NV"},
  {max_prefix: "7C-F9-0E",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "38-2B-78",vendor: "ECO PLUGS ENTERPRISE CO., LTD"},
  {max_prefix: "A4-7B-2C",vendor: "Nokia"},
  {max_prefix: "DC-DB-70",vendor: "Tonfunk Systementwicklung und Service GmbH"},
  {max_prefix: "80-0B-51",vendor: "Chengdu XGimi Technology Co.,Ltd"},
  {max_prefix: "34-81-F4",vendor: "SST Taiwan Ltd."},
  {max_prefix: "F8-BF-09",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "7C-B2-5C",vendor: "Acacia Communications"},
  {max_prefix: "80-3B-2A",vendor: "ABB Xiamen Low Voltage Equipment Co.,Ltd."},
  {max_prefix: "A0-A6-5C",vendor: "Supercomputing Systems AG"},
  {max_prefix: "F8-0D-60",vendor: "CANON INC."},
  {max_prefix: "F0-18-2B",vendor: "LG Chem"},
  {max_prefix: "E8-37-7A",vendor: "Zyxel Communications Corporation"},
  {max_prefix: "00-A7-84",vendor: "ITX security"},
  {max_prefix: "5C-B4-3E",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "54-E1-40",vendor: "INGENICO"},
  {max_prefix: "E4-90-7E",vendor: "Motorola Mobility LLC, a Lenovo Company"},
  {max_prefix: "3C-CE-15",vendor: "Mercedes-Benz USA, LLC"},
  {max_prefix: "38-FA-CA",vendor: "Skyworth Digital Technology(Shenzhen) Co.,Ltd"},
  {max_prefix: "14-9A-10",vendor: "Microsoft Corporation"},
  {max_prefix: "70-77-81",vendor: "Hon Hai Precision Ind. Co.,Ltd."},
  {max_prefix: "50-F0-D3",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "24-E5-AA",vendor: "Philips Oral Healthcare, Inc."},
  {max_prefix: "78-BD-BC",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "28-76-10",vendor: "IgniteNet"},
  {max_prefix: "74-6A-3A",vendor: "Aperi Corporation"},
  {max_prefix: "94-A7-B7",vendor: "zte corporation"},
  {max_prefix: "18-44-E6",vendor: "zte corporation"},
  {max_prefix: "48-50-73",vendor: "Microsoft Corporation"},
  {max_prefix: "34-9B-5B",vendor: "Maquet GmbH"},
  {max_prefix: "84-11-9E",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "54-E2-C8",vendor: "Dongguan Aoyuan Electronics Technology Co., Ltd"},
  {max_prefix: "20-D7-5A",vendor: "Posh Mobile Limited"},
  {max_prefix: "F4-15-63",vendor: "F5 Networks, Inc."},
  {max_prefix: "8C-8B-83",vendor: "Texas Instruments"},
  {max_prefix: "40-11-DC",vendor: "Sonance"},
  {max_prefix: "1C-83-41",vendor: "Hefei Bitland Information Technology Co.Ltd"},
  {max_prefix: "70-68-79",vendor: "Saijo Denki International Co., Ltd."},
  {max_prefix: "08-1F-EB",vendor: "BinCube"},
  {max_prefix: "78-5F-4C",vendor: "Argox Information Co., Ltd."},
  {max_prefix: "6C-1E-70",vendor: "Guangzhou YBDS IT Co.,Ltd"},
  {max_prefix: "D8-AD-DD",vendor: "Sonavation, Inc."},
  {max_prefix: "88-33-BE",vendor: "Ivenix, Inc."},
  {max_prefix: "54-B8-0A",vendor: "D-Link International"},
  {max_prefix: "34-CC-28",vendor: "Nexpring Co. LTD.,"},
  {max_prefix: "24-9E-AB",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "6C-A7-5F",vendor: "zte corporation"},
  {max_prefix: "10-AF-78",vendor: "Shenzhen ATUE Technology Co., Ltd"},
  {max_prefix: "E4-8D-8C",vendor: "Routerboard.com"},
  {max_prefix: "8C-79-67",vendor: "zte corporation"},
  {max_prefix: "CC-A4-AF",vendor: "Shenzhen Sowell Technology Co., LTD"},
  {max_prefix: "CC-19-A8",vendor: "PT Inova\u00e7\u00e3o e Sistemas SA"},
  {max_prefix: "B4-B2-65",vendor: "DAEHO I&T"},
  {max_prefix: "74-E2-8C",vendor: "Microsoft Corporation"},
  {max_prefix: "00-71-C2",vendor: "PEGATRON CORPORATION"},
  {max_prefix: "7C-82-74",vendor: "Shenzhen Hikeen Technology CO.,LTD"},
  {max_prefix: "94-D4-17",vendor: "GPI KOREA INC."},
  {max_prefix: "E0-35-60",vendor: "Challenger Supply Holdings, LLC"},
  {max_prefix: "24-4B-81",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "D8-55-A3",vendor: "zte corporation"},
  {max_prefix: "38-D8-2F",vendor: "zte corporation"},
  {max_prefix: "78-58-F3",vendor: "Vachen Co.,Ltd"},
  {max_prefix: "24-4B-03",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "3C-CB-7C",vendor: "TCT mobile ltd"},
  {max_prefix: "E4-CE-70",vendor: "Health & Life co., Ltd."},
  {max_prefix: "70-4E-66",vendor: "SHENZHEN FAST TECHNOLOGIES CO.,LTD"},
  {max_prefix: "10-2C-83",vendor: "XIMEA"},
  {max_prefix: "70-9C-8F",vendor: "Nero AG"},
  {max_prefix: "84-4B-B7",vendor: "Beijing Sankuai Online Technology Co.,Ltd"},
  {max_prefix: "68-F0-BC",vendor: "Shenzhen LiWiFi Technology Co., Ltd"},
  {max_prefix: "28-84-FA",vendor: "SHARP Corporation"},
  {max_prefix: "60-D9-A0",vendor: "Lenovo Mobile Communication Technology Ltd."},
  {max_prefix: "F0-79-59",vendor: "ASUSTek COMPUTER INC."},
  {max_prefix: "E0-8E-3C",vendor: "Aztech Electronics Pte Ltd"},
  {max_prefix: "3C-1E-04",vendor: "D-Link International"},
  {max_prefix: "30-0E-E3",vendor: "Aquantia Corporation"},
  {max_prefix: "60-AF-6D",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "B8-5A-73",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "68-B9-83",vendor: "b-plus GmbH"},
  {max_prefix: "18-F1-45",vendor: "NetComm Wireless Limited"},
  {max_prefix: "AC-AB-BF",vendor: "AthenTek Inc."},
  {max_prefix: "98-1D-FA",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "18-68-82",vendor: "Beward R&D Co., Ltd."},
  {max_prefix: "78-B3-B9",vendor: "ShangHai sunup lighting CO.,LTD"},
  {max_prefix: "04-C0-9C",vendor: "Tellabs Inc."},
  {max_prefix: "74-29-AF",vendor: "Hon Hai Precision Ind. Co.,Ltd."},
  {max_prefix: "40-EA-CE",vendor: "FOUNDER BROADBAND NETWORK SERVICE CO.,LTD"},
  {max_prefix: "84-8E-DF",vendor: "Sony Corporation"},
  {max_prefix: "EC-80-09",vendor: "NovaSparks"},
  {max_prefix: "50-AD-D5",vendor: "Dynalec Corporation"},
  {max_prefix: "B0-45-19",vendor: "TCT mobile ltd"},
  {max_prefix: "D8-8D-5C",vendor: "Elentec"},
  {max_prefix: "3C-1A-0F",vendor: "ClearSky Data"},
  {max_prefix: "E8-CC-18",vendor: "D-Link International"},
  {max_prefix: "B0-91-37",vendor: "ISis ImageStream Internet Solutions, Inc"},
  {max_prefix: "8C-05-51",vendor: "Koubachi AG"},
  {max_prefix: "D8-97-BA",vendor: "PEGATRON CORPORATION"},
  {max_prefix: "A8-D8-8A",vendor: "Wyconn"},
  {max_prefix: "20-76-93",vendor: "Lenovo (Beijing) Limited."},
  {max_prefix: "60-04-17",vendor: "POSBANK CO.,LTD"},
  {max_prefix: "24-97-ED",vendor: "Techvision Intelligent Technology Limited"},
  {max_prefix: "A4-9D-49",vendor: "Ketra, Inc."},
  {max_prefix: "C0-38-96",vendor: "Hon Hai Precision Ind. Co.,Ltd."},
  {max_prefix: "2C-50-89",vendor: "Shenzhen Kaixuan Visual Technology Co.,Limited"},
  {max_prefix: "1C-7E-51",vendor: "3bumen.com"},
  {max_prefix: "94-8E-89",vendor: "INDUSTRIAS UNIDAS SA DE CV"},
  {max_prefix: "08-46-56",vendor: "VEO-LABS"},
  {max_prefix: "EC-3C-5A",vendor: "SHEN ZHEN HENG SHENG HUI DIGITAL TECHNOLOGY CO.,LTD"},
  {max_prefix: "44-88-CB",vendor: "Camco Technologies NV"},
  {max_prefix: "68-72-DC",vendor: "CETORY.TV Company Limited"},
  {max_prefix: "00-AE-FA",vendor: "Murata Manufacturing Co., Ltd."},
  {max_prefix: "84-18-26",vendor: "Osram GmbH"},
  {max_prefix: "50-29-4D",vendor: "NANJING IOT SENSOR TECHNOLOGY CO,LTD"},
  {max_prefix: "0C-CF-D1",vendor: "SPRINGWAVE Co., Ltd"},
  {max_prefix: "74-BA-DB",vendor: "Longconn Electornics(shenzhen)Co.,Ltd"},
  {max_prefix: "90-9F-33",vendor: "EFM Networks"},
  {max_prefix: "30-77-CB",vendor: "Maike Industry(Shenzhen)CO.,LTD"},
  {max_prefix: "10-2F-6B",vendor: "Microsoft Corporation"},
  {max_prefix: "94-54-93",vendor: "Rigado, LLC"},
  {max_prefix: "6C-BF-B5",vendor: "Noon Technology Co., Ltd"},
  {max_prefix: "B8-F3-17",vendor: "iSun Smasher Communications Private Limited"},
  {max_prefix: "8C-F8-13",vendor: "ORANGE POLSKA"},
  {max_prefix: "68-F0-6D",vendor: "ALONG INDUSTRIAL CO., LIMITED"},
  {max_prefix: "F8-24-41",vendor: "Yeelink"},
  {max_prefix: "10-8A-1B",vendor: "RAONIX Inc."},
  {max_prefix: "F8-E9-03",vendor: "D-Link International"},
  {max_prefix: "E8-96-06",vendor: "testo Instruments (Shenzhen) Co., Ltd."},
  {max_prefix: "F4-28-53",vendor: "Zioncom Electronics (Shenzhen) Ltd."},
  {max_prefix: "1C-9C-26",vendor: "Zoovel Technologies"},
  {max_prefix: "D4-EC-86",vendor: "LinkedHope Intelligent Technologies Co., Ltd"},
  {max_prefix: "04-67-85",vendor: "scemtec Hard- und Software fuer Mess- und Steuerungstechnik GmbH"},
  {max_prefix: "D0-FA-1D",vendor: "Qihoo  360  Technology Co.,Ltd"},
  {max_prefix: "B8-9B-E4",vendor: "ABB Power Systems Power Generation"},
  {max_prefix: "50-50-65",vendor: "TAKT Corporation"},
  {max_prefix: "40-C6-2A",vendor: "Shanghai Jing Ren Electronic Technology Co., Ltd."},
  {max_prefix: "E8-15-0E",vendor: "Nokia Corporation"},
  {max_prefix: "DC-53-7C",vendor: "Compal Broadband Networks, Inc."},
  {max_prefix: "AC-11-D3",vendor: "Suzhou HOTEK  Video Technology Co. Ltd"},
  {max_prefix: "84-32-EA",vendor: "ANHUI WANZTEN P&T CO., LTD"},
  {max_prefix: "E0-1D-38",vendor: "Beijing HuaqinWorld Technology Co.,Ltd"},
  {max_prefix: "E4-7F-B2",vendor: "FUJITSU LIMITED"},
  {max_prefix: "FC-6D-C0",vendor: "BME CORPORATION"},
  {max_prefix: "24-D1-3F",vendor: "MEXUS CO.,LTD"},
  {max_prefix: "78-24-AF",vendor: "ASUSTek COMPUTER INC."},
  {max_prefix: "0C-AC-05",vendor: "Unitend Technologies Inc."},
  {max_prefix: "FC-9F-E1",vendor: "CONWIN.Tech. Ltd"},
  {max_prefix: "A8-1B-5D",vendor: "Foxtel Management Pty Ltd"},
  {max_prefix: "44-A6-E5",vendor: "THINKING TECHNOLOGY CO.,LTD"},
  {max_prefix: "3C-AA-3F",vendor: "iKey, Ltd."},
  {max_prefix: "0C-38-3E",vendor: "Fanvil Technology Co., Ltd."},
  {max_prefix: "60-CD-A9",vendor: "Abloomy"},
  {max_prefix: "B8-AD-3E",vendor: "BLUECOM"},
  {max_prefix: "18-30-09",vendor: "Woojin Industrial Systems Co., Ltd."},
  {max_prefix: "74-DB-D1",vendor: "Ebay Inc"},
  {max_prefix: "80-AD-67",vendor: "Kasda Networks Inc"},
  {max_prefix: "30-B5-F1",vendor: "Aitexin Technology Co., Ltd"},
  {max_prefix: "B0-10-41",vendor: "Hon Hai Precision Ind. Co.,Ltd."},
  {max_prefix: "C4-42-02",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "B4-AE-6F",vendor: "Circle Reliance, Inc DBA Cranberry Networks"},
  {max_prefix: "90-DA-6A",vendor: "FOCUS H&S Co., Ltd."},
  {max_prefix: "10-30-47",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "5C-2E-59",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "4C-6E-6E",vendor: "Comnect Technology CO.,LTD"},
  {max_prefix: "8C-33-57",vendor: "HiteVision Digital Media Technology Co.,Ltd."},
  {max_prefix: "F8-84-F2",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "A4-5D-A1",vendor: "ADB Broadband Italia"},
  {max_prefix: "C4-6B-B4",vendor: "myIDkey"},
  {max_prefix: "84-94-8C",vendor: "Hitron Technologies. Inc"},
  {max_prefix: "A8-F7-E0",vendor: "PLANET Technology Corporation"},
  {max_prefix: "44-86-C1",vendor: "Siemens Low Voltage & Products"},
  {max_prefix: "E0-CB-EE",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "4C-39-09",vendor: "HPL Electric & Power Private Limited"},
  {max_prefix: "90-7E-BA",vendor: "UTEK TECHNOLOGY (SHENZHEN) CO.,LTD"},
  {max_prefix: "A0-02-DC",vendor: "Amazon Technologies Inc."},
  {max_prefix: "40-45-DA",vendor: "Spreadtrum Communications (Shanghai) Co., Ltd."},
  {max_prefix: "34-51-AA",vendor: "JID GLOBAL"},
  {max_prefix: "98-BE-94",vendor: "IBM"},
  {max_prefix: "6C-19-8F",vendor: "D-Link International"},
  {max_prefix: "C8-FF-77",vendor: "Dyson Limited"},
  {max_prefix: "54-2A-A2",vendor: "Alpha Networks Inc."},
  {max_prefix: "B4-9E-AC",vendor: "Imagik Int'l Corp"},
  {max_prefix: "54-B7-53",vendor: "Hunan Fenghui Yinjia Science And Technology Co.,Ltd"},
  {max_prefix: "B0-75-4D",vendor: "Nokia"},
  {max_prefix: "CC-07-E4",vendor: "Lenovo Mobile Communication Technology Ltd."},
  {max_prefix: "AC-B8-59",vendor: "Uniband Electronic Corp,"},
  {max_prefix: "88-B1-E1",vendor: " Mojo Networks, Inc."},
  {max_prefix: "54-EE-75",vendor: "Wistron InfoComm(Kunshan)Co.,Ltd."},
  {max_prefix: "0C-63-FC",vendor: "Nanjing Signway Technology Co., Ltd"},
  {max_prefix: "D4-E0-8E",vendor: "ValueHD Corporation"},
  {max_prefix: "C8-9F-1D",vendor: "SHENZHEN COMMUNICATION TECHNOLOGIES CO.,LTD"},
  {max_prefix: "08-74-F6",vendor: "Winterhalter Gastronom GmbH"},
  {max_prefix: "14-3D-F2",vendor: "Beijing Shidai Hongyuan Network Communication Co.,Ltd"},
  {max_prefix: "60-47-D4",vendor: "FORICS Electronic Technology Co., Ltd."},
  {max_prefix: "D8-49-2F",vendor: "CANON INC."},
  {max_prefix: "2C-39-C1",vendor: "Ciena Corporation"},
  {max_prefix: "80-0E-24",vendor: "ForgetBox"},
  {max_prefix: "3C-25-D7",vendor: "Nokia Corporation"},
  {max_prefix: "30-A8-DB",vendor: "Sony Corporation"},
  {max_prefix: "54-D1-63",vendor: "MAX-TECH,INC"},
  {max_prefix: "08-3F-3E",vendor: "WSH GmbH"},
  {max_prefix: "18-20-A6",vendor: "Sage Co., Ltd."},
  {max_prefix: "20-EA-C7",vendor: "SHENZHEN RIOPINE ELECTRONICS CO., LTD"},
  {max_prefix: "64-B3-70",vendor: "PowerComm Solutions LLC"},
  {max_prefix: "5C-F5-0D",vendor: "Institute of microelectronic applications"},
  {max_prefix: "74-9C-52",vendor: "Huizhou Desay SV Automotive Co., Ltd."},
  {max_prefix: "48-B5-A7",vendor: "Glory Horse Industries Ltd."},
  {max_prefix: "0C-4F-5A",vendor: "ASA-RT s.r.l."},
  {max_prefix: "CC-A6-14",vendor: "AIFA TECHNOLOGY CORP."},
  {max_prefix: "4C-8B-30",vendor: "Actiontec Electronics, Inc"},
  {max_prefix: "08-05-CD",vendor: "DongGuang EnMai Electronic Product Co.Ltd."},
  {max_prefix: "18-FF-2E",vendor: "Shenzhen Rui Ying Da Technology Co., Ltd"},
  {max_prefix: "84-72-07",vendor: "I&C Technology"},
  {max_prefix: "D4-22-4E",vendor: "Alcatel Lucent"},
  {max_prefix: "9C-86-DA",vendor: "Phoenix Geophysics Ltd."},
  {max_prefix: "2C-07-3C",vendor: "DEVLINE LIMITED"},
  {max_prefix: "7C-1A-03",vendor: "8Locations Co., Ltd."},
  {max_prefix: "10-DE-E4",vendor: "automationNEXT GmbH"},
  {max_prefix: "F0-3A-4B",vendor: "Bloombase, Inc."},
  {max_prefix: "90-F1-B0",vendor: "Hangzhou Anheng Info&Tech CO.,LTD"},
  {max_prefix: "90-DB-46",vendor: "E-LEAD ELECTRONIC CO., LTD"},
  {max_prefix: "34-4F-5C",vendor: "R&amp;M AG"},
  {max_prefix: "7C-E4-AA",vendor: "Private"},
  {max_prefix: "C4-29-1D",vendor: "KLEMSAN ELEKTRIK ELEKTRONIK SAN.VE TIC.AS."},
  {max_prefix: "FC-F8-B7",vendor: "TRONTEQ Electronic"},
  {max_prefix: "30-F4-2F",vendor: "ESP"},
  {max_prefix: "70-4E-01",vendor: "KWANGWON TECH CO., LTD."},
  {max_prefix: "74-6A-8F",vendor: "VS Vision Systems GmbH"},
  {max_prefix: "54-A3-1B",vendor: "Shenzhen Linkworld Technology Co,.LTD"},
  {max_prefix: "CC-39-8C",vendor: "Shiningtek"},
  {max_prefix: "6C-5F-1C",vendor: "Lenovo Mobile Communication Technology Ltd."},
  {max_prefix: "40-4A-18",vendor: "Addrek Smart Solutions"},
  {max_prefix: "C0-C5-69",vendor: "SHANGHAI LYNUC CNC TECHNOLOGY CO.,LTD"},
  {max_prefix: "C4-C0-AE",vendor: "MIDORI ELECTRONIC CO., LTD."},
  {max_prefix: "AC-C5-95",vendor: "Graphite Systems"},
  {max_prefix: "D8-EE-78",vendor: "Moog Protokraft"},
  {max_prefix: "7C-E1-FF",vendor: "Computer Performance, Inc. DBA Digital Loggers, Inc."},
  {max_prefix: "D8-15-0D",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "4C-D7-B6",vendor: "Helmer Scientific"},
  {max_prefix: "84-25-A4",vendor: "Tariox Limited"},
  {max_prefix: "48-3D-32",vendor: "Syscor Controls &amp; Automation"},
  {max_prefix: "A0-E4-53",vendor: "Sony Corporation"},
  {max_prefix: "3C-0C-48",vendor: "Servergy, Inc."},
  {max_prefix: "CC-85-6C",vendor: "SHENZHEN MDK DIGITAL TECHNOLOGY CO.,LTD"},
  {max_prefix: "2C-95-7F",vendor: "zte corporation"},
  {max_prefix: "98-FF-6A",vendor: "OTEC(Shanghai)Technology Co.,Ltd."},
  {max_prefix: "7C-CD-11",vendor: "MS-Magnet"},
  {max_prefix: "58-50-AB",vendor: "TLS Corporation"},
  {max_prefix: "24-11-48",vendor: "Entropix, LLC"},
  {max_prefix: "CC-74-98",vendor: "Filmetrics Inc."},
  {max_prefix: "64-BA-BD",vendor: "SDJ Technologies, Inc."},
  {max_prefix: "24-C8-48",vendor: "mywerk Portal GmbH"},
  {max_prefix: "50-ED-78",vendor: "Changzhou Yongse Infotech Co.,Ltd"},
  {max_prefix: "1C-C1-1A",vendor: "Wavetronix"},
  {max_prefix: "90-02-8A",vendor: "Shenzhen Shidean Legrand Electronic Products Co.,Ltd"},
  {max_prefix: "08-5A-E0",vendor: "Recovision Technology Co., Ltd."},
  {max_prefix: "E0-C8-6A",vendor: "SHENZHEN TW-SCIE Co., Ltd"},
  {max_prefix: "18-79-A2",vendor: "GMJ ELECTRIC LIMITED"},
  {max_prefix: "C4-45-EC",vendor: "Shanghai Yali Electron Co.,LTD"},
  {max_prefix: "E0-E6-31",vendor: "SNB TECHNOLOGIES LIMITED"},
  {max_prefix: "F8-57-2E",vendor: "Core Brands, LLC"},
  {max_prefix: "78-B5-D2",vendor: "Ever Treasure Industrial Limited"},
  {max_prefix: "FC-09-D8",vendor: "ACTEON Group"},
  {max_prefix: "74-3E-CB",vendor: "Gentrice tech"},
  {max_prefix: "7C-44-4C",vendor: "Entertainment Solutions, S.L."},
  {max_prefix: "04-44-A1",vendor: "TELECON GALICIA,S.A."},
  {max_prefix: "20-C6-0D",vendor: "Shanghai annijie Information technology Co.,LTD"},
  {max_prefix: "38-CA-97",vendor: "Contour Design LLC"},
  {max_prefix: "BC-2D-98",vendor: "ThinGlobal LLC"},
  {max_prefix: "BC-1A-67",vendor: "YF Technology Co., Ltd"},
  {max_prefix: "AC-6B-AC",vendor: "Jenny Science AG"},
  {max_prefix: "7C-97-63",vendor: "Openmatics s.r.o."},
  {max_prefix: "48-A2-B7",vendor: "Kodofon JSC"},
  {max_prefix: "BC-EE-7B",vendor: "ASUSTek COMPUTER INC."},
  {max_prefix: "34-13-A8",vendor: "Mediplan Limited"},
  {max_prefix: "8C-AE-89",vendor: "Y-cam Solutions Ltd"},
  {max_prefix: "FC-E1-D9",vendor: "Stable Imaging Solutions LLC"},
  {max_prefix: "B0-45-45",vendor: "YACOUB Automation GmbH"},
  {max_prefix: "C8-EE-75",vendor: "Pishion International Co. Ltd"},
  {max_prefix: "68-2D-DC",vendor: "Wuhan Changjiang Electro-Communication Equipment CO.,LTD"},
  {max_prefix: "E8-61-1F",vendor: "Dawning Information Industry Co.,Ltd"},
  {max_prefix: "70-59-57",vendor: "Medallion Instrumentation Systems"},
  {max_prefix: "9C-D6-43",vendor: "D-Link International"},
  {max_prefix: "3C-18-A0",vendor: "Luxshare Precision Industry Company Limited"},
  {max_prefix: "94-E9-8C",vendor: "Nokia"},
  {max_prefix: "94-C3-E4",vendor: "Atlas Copco IAS GmbH"},
  {max_prefix: "20-E7-91",vendor: "Siemens Healthcare Diagnostics, Inc"},
  {max_prefix: "08-97-58",vendor: "Shenzhen Strong Rising Electronics Co.,Ltd DongGuan Subsidiary"},
  {max_prefix: "FC-19-D0",vendor: "Cloud Vision Networks Technology Co.,Ltd."},
  {max_prefix: "CC-34-29",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "7C-BF-88",vendor: "Mobilicom LTD"},
  {max_prefix: "60-DB-2A",vendor: "HNS"},
  {max_prefix: "94-86-D4",vendor: "Surveillance Pro Corporation"},
  {max_prefix: "B4-24-E7",vendor: "Codetek Technology Co.,Ltd"},
  {max_prefix: "A0-A2-3C",vendor: "GPMS"},
  {max_prefix: "68-FC-B3",vendor: "Next Level Security Systems, Inc."},
  {max_prefix: "54-2F-89",vendor: "Euclid Laboratories, Inc."},
  {max_prefix: "28-47-AA",vendor: "Nokia Corporation"},
  {max_prefix: "5C-D6-1F",vendor: "Qardio, Inc"},
  {max_prefix: "CC-FB-65",vendor: "Nintendo Co., Ltd."},
  {max_prefix: "FC-D8-17",vendor: "Beijing Hesun Technologies Co.Ltd."},
  {max_prefix: "34-88-5D",vendor: "Logitech Far East"},
  {max_prefix: "88-57-6D",vendor: "XTA Electronics Ltd"},
  {max_prefix: "BC-41-00",vendor: "CODACO ELECTRONIC s.r.o."},
  {max_prefix: "50-27-C7",vendor: "TECHNART Co.,Ltd"},
  {max_prefix: "6C-5A-B5",vendor: "TCL Technoly Electronics (Huizhou) Co., Ltd."},
  {max_prefix: "B4-3E-3B",vendor: "Viableware, Inc"},
  {max_prefix: "0C-5C-D8",vendor: "DOLI Elektronik GmbH"},
  {max_prefix: "EC-3E-09",vendor: "PERFORMANCE DESIGNED PRODUCTS, LLC"},
  {max_prefix: "94-7C-3E",vendor: "Polewall Norge AS"},
  {max_prefix: "38-5A-A8",vendor: "Beijing Zhongdun Security Technology Development Co."},
  {max_prefix: "F4-A2-94",vendor: "EAGLE WORLD DEVELOPMENT CO., LIMITED"},
  {max_prefix: "9C-44-3D",vendor: "CHENGDU XUGUANG TECHNOLOGY CO, LTD"},
  {max_prefix: "3C-15-EA",vendor: "TESCOM CO., LTD."},
  {max_prefix: "E8-04-10",vendor: "Private"},
  {max_prefix: "90-99-16",vendor: "ELVEES NeoTek OJSC"},
  {max_prefix: "A0-14-3D",vendor: "PARROT SA"},
  {max_prefix: "60-24-C1",vendor: "Jiangsu Zhongxun Electronic Technology Co., Ltd"},
  {max_prefix: "60-A9-B0",vendor: "Merchandising Technologies, Inc"},
  {max_prefix: "34-A3-BF",vendor: "Terewave. Inc."},
  {max_prefix: "8C-08-8B",vendor: "Remote Solution"},
  {max_prefix: "FC-1B-FF",vendor: "V-ZUG AG"},
  {max_prefix: "00-A2-FF",vendor: "abatec group AG"},
  {max_prefix: "F4-BD-7C",vendor: "Chengdu jinshi communication Co., LTD"},
  {max_prefix: "DC-C4-22",vendor: "Systembase Limited"},
  {max_prefix: "C8-F3-6B",vendor: "Yamato Scale Co.,Ltd."},
  {max_prefix: "6C-D1-B0",vendor: "WING SING ELECTRONICS HONG KONG LIMITED"},
  {max_prefix: "98-F8-C1",vendor: "IDT Technology Limited"},
  {max_prefix: "A4-F5-22",vendor: "CHOFU SEISAKUSHO CO.,LTD"},
  {max_prefix: "84-5C-93",vendor: "Chabrier Services"},
  {max_prefix: "68-E1-66",vendor: "Private"},
  {max_prefix: "60-FE-F9",vendor: "Thomas & Betts"},
  {max_prefix: "B8-DC-87",vendor: "IAI Corporation"},
  {max_prefix: "78-CB-33",vendor: "DHC Software Co.,Ltd"},
  {max_prefix: "DC-F7-55",vendor: "SITRONIK"},
  {max_prefix: "BC-2B-D7",vendor: "Revogi Innovation Co., Ltd."},
  {max_prefix: "7C-6F-F8",vendor: "ShenZhen ACTO Digital Video Technology Co.,Ltd."},
  {max_prefix: "28-6D-97",vendor: "SAMJIN Co., Ltd."},
  {max_prefix: "24-EC-D6",vendor: "CSG Science & Technology Co.,Ltd.Hefei"},
  {max_prefix: "CC-2A-80",vendor: "Micro-Biz intelligence solutions Co.,Ltd"},
  {max_prefix: "F4-28-96",vendor: "SPECTO PAINEIS ELETRONICOS LTDA"},
  {max_prefix: "1C-48-F9",vendor: "GN Netcom A/S"},
  {max_prefix: "9C-4E-BF",vendor: "BoxCast"},
  {max_prefix: "34-A8-43",vendor: "KYOCERA Display Corporation"},
  {max_prefix: "80-BB-EB",vendor: "Satmap Systems Ltd"},
  {max_prefix: "00-B7-8D",vendor: "Nanjing Shining Electric Automation Co., Ltd"},
  {max_prefix: "5C-02-6A",vendor: "Applied Vision Corporation"},
  {max_prefix: "0C-93-01",vendor: "PT. Prasimax Inovasi Teknologi"},
  {max_prefix: "74-66-30",vendor: "T:mi Ytti"},
  {max_prefix: "6C-B3-50",vendor: "Anhui comhigher tech co.,ltd"},
  {max_prefix: "E8-92-18",vendor: "Arcontia International AB"},
  {max_prefix: "00-75-E1",vendor: "Ampt, LLC"},
  {max_prefix: "D4-6A-91",vendor: "SnapAV"},
  {max_prefix: "B0-79-3C",vendor: "Revolv Inc"},
  {max_prefix: "38-59-F8",vendor: "MindMade Sp. z o.o."},
  {max_prefix: "4C-DF-3D",vendor: "TEAM ENGINEERS ADVANCE TECHNOLOGIES INDIA PVT LTD"},
  {max_prefix: "98-CD-B4",vendor: "Virident Systems, Inc."},
  {max_prefix: "A4-23-05",vendor: "Open Networking Laboratory"},
  {max_prefix: "A0-5B-21",vendor: "ENVINET GmbH"},
  {max_prefix: "50-B8-A2",vendor: "ImTech Technologies LLC,"},
  {max_prefix: "B0-4C-05",vendor: "Fresenius Medical Care Deutschland GmbH"},
  {max_prefix: "34-CD-6D",vendor: "CommSky Technologies"},
  {max_prefix: "B0-FE-BD",vendor: "Private"},
  {max_prefix: "60-69-9B",vendor: "isepos GmbH"},
  {max_prefix: "D4-D5-0D",vendor: "Southwest Microwave, Inc"},
  {max_prefix: "E4-38-F2",vendor: "Advantage Controls"},
  {max_prefix: "C4-C7-55",vendor: "Beijing HuaqinWorld Technology Co.,Ltd"},
  {max_prefix: "0C-2D-89",vendor: "QiiQ Communications Inc."},
  {max_prefix: "A8-D2-36",vendor: "Lightware Visual Engineering"},
  {max_prefix: "78-8D-F7",vendor: "Hitron Technologies. Inc"},
  {max_prefix: "94-50-47",vendor: "Rechnerbetriebsgruppe"},
  {max_prefix: "E0-31-D0",vendor: "SZ Telstar CO., LTD"},
  {max_prefix: "54-11-2F",vendor: "Sulzer Pump Solutions Finland Oy"},
  {max_prefix: "4C-55-B8",vendor: "Turkcell Teknoloji"},
  {max_prefix: "08-80-39",vendor: "Cisco SPVTG"},
  {max_prefix: "98-10-94",vendor: "Shenzhen Vsun communication technology Co.,ltd"},
  {max_prefix: "A4-F3-C1",vendor: "Open Source Robotics Foundation, Inc."},
  {max_prefix: "14-13-30",vendor: "Anakreon UK LLP"},
  {max_prefix: "74-CA-25",vendor: "Calxeda, Inc."},
  {max_prefix: "B4-34-6C",vendor: "MATSUNICHI DIGITAL TECHNOLOGY (HONG KONG) LIMITED"},
  {max_prefix: "90-46-B7",vendor: "Vadaro Pte Ltd"},
  {max_prefix: "04-CF-25",vendor: "MANYCOLORS, INC."},
  {max_prefix: "B0-50-BC",vendor: "SHENZHEN BASICOM ELECTRONIC CO.,LTD."},
  {max_prefix: "84-1E-26",vendor: "KERNEL-I Co.,LTD"},
  {max_prefix: "DC-70-14",vendor: "Private"},
  {max_prefix: "E4-F3-E3",vendor: "Shanghai iComhome Co.,Ltd."},
  {max_prefix: "00-86-A0",vendor: "Private"},
  {max_prefix: "60-FE-1E",vendor: "China Palms Telecom.Ltd"},
  {max_prefix: "0C-F4-05",vendor: "Beijing Signalway Technologies Co.,Ltd"},
  {max_prefix: "50-61-D6",vendor: "Indu-Sol GmbH"},
  {max_prefix: "04-BF-A8",vendor: "ISB Corporation"},
  {max_prefix: "8C-C7-D0",vendor: "zhejiang ebang communication co.,ltd"},
  {max_prefix: "B8-AE-6E",vendor: "Nintendo Co., Ltd."},
  {max_prefix: "D0-EB-03",vendor: "Zhehua technology limited"},
  {max_prefix: "68-3E-EC",vendor: "ERECA"},
  {max_prefix: "0C-2A-E7",vendor: "Beijing General Research Institute of Mining and Metallurgy"},
  {max_prefix: "98-30-71",vendor: "DAIKYUNG VASCOM"},
  {max_prefix: "D4-95-24",vendor: "Clover Network, Inc."},
  {max_prefix: "0C-04-00",vendor: "Jantar d.o.o."},
  {max_prefix: "68-7C-D5",vendor: "Y Soft Corporation, a.s."},
  {max_prefix: "C0-4D-F7",vendor: "SERELEC"},
  {max_prefix: "50-A7-15",vendor: "Aboundi, Inc."},
  {max_prefix: "C4-26-28",vendor: "Airo Wireless"},
  {max_prefix: "30-AA-BD",vendor: "Shanghai Reallytek Information Technology Co.,Ltd"},
  {max_prefix: "A4-B8-18",vendor: "PENTA Gesellschaft f\u00fcr elektronische Industriedatenverarbeitung mbH"},
  {max_prefix: "90-7A-F1",vendor: "Wally"},
  {max_prefix: "2C-B6-93",vendor: "Radware"},
  {max_prefix: "AC-41-22",vendor: "Eclipse Electronic Systems Inc."},
  {max_prefix: "0C-84-84",vendor: "Zenovia Electronics Inc."},
  {max_prefix: "00-59-07",vendor: "LenovoEMC Products USA, LLC"},
  {max_prefix: "A8-61-AA",vendor: "Cloudview Limited"},
  {max_prefix: "C8-B3-73",vendor: "Cisco-Linksys, LLC"},
  {max_prefix: "2C-24-5F",vendor: "Babolat VS"},
  {max_prefix: "90-56-92",vendor: "Autotalks Ltd."},
  {max_prefix: "FC-11-86",vendor: "Logic3 plc"},
  {max_prefix: "C8-EE-A6",vendor: "Shenzhen SHX Technology Co., Ltd"},
  {max_prefix: "24-81-AA",vendor: "KSH International Co., Ltd."},
  {max_prefix: "E0-18-77",vendor: "FUJITSU LIMITED"},
  {max_prefix: "E4-57-A8",vendor: "Stuart Manufacturing, Inc."},
  {max_prefix: "78-99-66",vendor: "Musilab Electronics (DongGuan)Co.,Ltd."},
  {max_prefix: "84-17-15",vendor: "GP Electronics (HK) Ltd."},
  {max_prefix: "58-EB-14",vendor: "Proteus Digital Health"},
  {max_prefix: "C4-58-C2",vendor: "Shenzhen TATFOOK Technology Co., Ltd."},
  {max_prefix: "D0-CD-E1",vendor: "Scientech Electronics"},
  {max_prefix: "10-4D-77",vendor: "Innovative Computer Engineering"},
  {max_prefix: "5C-E0-CA",vendor: "FeiTian United (Beijing) System Technology Co., Ltd."},
  {max_prefix: "E0-81-77",vendor: "GreenBytes, Inc."},
  {max_prefix: "9C-98-11",vendor: "Guangzhou Sunrise Electronics Development Co., Ltd"},
  {max_prefix: "B8-60-91",vendor: "Onnet Technologies and Innovations LLC"},
  {max_prefix: "30-15-18",vendor: "Ubiquitous Communication Co. ltd."},
  {max_prefix: "D0-D4-71",vendor: "MVTECH co., Ltd"},
  {max_prefix: "08-68-D0",vendor: "Japan System Design"},
  {max_prefix: "D4-22-3F",vendor: "Lenovo Mobile Communication Technology Ltd."},
  {max_prefix: "E8-E8-75",vendor: "iS5 Communications Inc."},
  {max_prefix: "C8-0E-95",vendor: "OmniLync Inc."},
  {max_prefix: "68-97-E8",vendor: "Society of Motion Picture &amp; Television Engineers"},
  {max_prefix: "7C-A1-5D",vendor: "GN ReSound A/S"},
  {max_prefix: "3C-08-1E",vendor: "Beijing Yupont Electric Power Technology Co.,Ltd"},
  {max_prefix: "FC-58-FA",vendor: "Shen Zhen Shi Xin Zhong Xin Technology Co.,Ltd."},
  {max_prefix: "60-BB-0C",vendor: "Beijing HuaqinWorld Technology Co,Ltd"},
  {max_prefix: "10-B9-FE",vendor: "Lika srl"},
  {max_prefix: "A4-2C-08",vendor: "Masterwork Automodules"},
  {max_prefix: "8C-07-8C",vendor: "FLOW DATA INC"},
  {max_prefix: "F8-DF-A8",vendor: "zte corporation"},
  {max_prefix: "A8-95-B0",vendor: "Aker Subsea Ltd"},
  {max_prefix: "28-CB-EB",vendor: "One"},
  {max_prefix: "B8-C4-6F",vendor: "PRIMMCON INDUSTRIES INC"},
  {max_prefix: "D8-B0-2E",vendor: "Guangzhou Zonerich Business Machine Co., LTD."},
  {max_prefix: "4C-CC-34",vendor: "Motorola Solutions Inc."},
  {max_prefix: "30-05-5C",vendor: "Brother industries, LTD."},
  {max_prefix: "08-0E-A8",vendor: "Velex s.r.l."},
  {max_prefix: "C4-E0-32",vendor: "IEEE 1904.1 Working Group"},
  {max_prefix: "8C-76-C1",vendor: "Goden Tech Limited"},
  {max_prefix: "08-F1-B7",vendor: "Towerstream Corpration"},
  {max_prefix: "C0-44-E3",vendor: "Shenzhen Sinkna Electronics Co., LTD"},
  {max_prefix: "18-55-0F",vendor: "Cisco SPVTG"},
  {max_prefix: "18-7A-93",vendor: "AMICCOM Electronics Corporation"},
  {max_prefix: "88-87-DD",vendor: "DarbeeVision Inc."},
  {max_prefix: "30-C8-2A",vendor: "WI-BIZ srl"},
  {max_prefix: "C4-5D-D8",vendor: "HDMI Forum"},
  {max_prefix: "C4-EB-E3",vendor: "RRCN SAS"},
  {max_prefix: "94-75-6E",vendor: "QinetiQ North America"},
  {max_prefix: "4C-1A-95",vendor: "Novakon Co., Ltd."},
  {max_prefix: "A0-03-63",vendor: "Robert Bosch Healthcare GmbH"},
  {max_prefix: "64-99-A0",vendor: "AG Elektronik AB"},
  {max_prefix: "84-8E-96",vendor: "Embertec Pty Ltd"},
  {max_prefix: "44-9B-78",vendor: "The Now Factory"},
  {max_prefix: "DC-A9-89",vendor: "MACANDC"},
  {max_prefix: "7C-43-8F",vendor: "E-Band Communications Corp."},
  {max_prefix: "F0-F6-69",vendor: "Motion Analysis Corporation"},
  {max_prefix: "78-99-5C",vendor: "Nationz Technologies Inc"},
  {max_prefix: "54-11-5F",vendor: "Atamo Pty Ltd"},
  {max_prefix: "8C-AE-4C",vendor: "Plugable Technologies"},
  {max_prefix: "0C-C6-55",vendor: "Wuxi YSTen Technology Co.,Ltd."},
  {max_prefix: "5C-89-D4",vendor: "Beijing Banner Electric Co.,Ltd"},
  {max_prefix: "18-2A-7B",vendor: "Nintendo Co., Ltd."},
  {max_prefix: "68-FB-95",vendor: "Generalplus Technology Inc."},
  {max_prefix: "D0-B4-98",vendor: "Robert Bosch LLC Automotive Electronics"},
  {max_prefix: "E0-55-97",vendor: "Emergent Vision Technologies Inc."},
  {max_prefix: "24-2F-FA",vendor: "Toshiba Global Commerce Solutions"},
  {max_prefix: "84-9D-C5",vendor: "Centera Photonics Inc."},
  {max_prefix: "58-09-43",vendor: "Private"},
  {max_prefix: "EC-FC-55",vendor: "A. Eberle GmbH & Co. KG"},
  {max_prefix: "88-A3-CC",vendor: "Amatis Controls"},
  {max_prefix: "C0-A0-C7",vendor: "FAIRFIELD INDUSTRIES"},
  {max_prefix: "A0-E2-5A",vendor: "Amicus SK, s.r.o."},
  {max_prefix: "D4-0F-B2",vendor: "Applied Micro Electronics AME bv"},
  {max_prefix: "48-5A-3F",vendor: "WISOL"},
  {max_prefix: "70-F1-E5",vendor: "Xetawave LLC"},
  {max_prefix: "E4-96-AE",vendor: "ALTOGRAPHICS Inc."},
  {max_prefix: "F8-F0-82",vendor: "NAGTECH LLC"},
  {max_prefix: "4C-22-58",vendor: "cozybit, Inc."},
  {max_prefix: "F4-52-14",vendor: "Mellanox Technologies, Inc."},
  {max_prefix: "10-F4-9A",vendor: "T3 Innovation"},
  {max_prefix: "3C-57-BD",vendor: "Kessler Crane Inc."},
  {max_prefix: "04-E9-E5",vendor: "PJRC.COM, LLC"},
  {max_prefix: "50-46-5D",vendor: "ASUSTek COMPUTER INC."},
  {max_prefix: "74-BF-A1",vendor: "HYUNTECK"},
  {max_prefix: "08-B7-38",vendor: "Lite-On Technogy Corp."},
  {max_prefix: "F8-AA-8A",vendor: "Axview Technology (Shenzhen) Co.,Ltd"},
  {max_prefix: "7C-01-87",vendor: "Curtis Instruments, Inc."},
  {max_prefix: "54-F6-66",vendor: "Berthold Technologies GmbH and Co.KG"},
  {max_prefix: "1C-11-E1",vendor: "Wartsila Finland Oy"},
  {max_prefix: "70-38-11",vendor: "Siemens Mobility Limited"},
  {max_prefix: "CC-4B-FB",vendor: "Hellberg Safety AB"},
  {max_prefix: "6C-AD-EF",vendor: "KZ Broadband Technologies, Ltd."},
  {max_prefix: "74-5F-AE",vendor: "TSL PPL"},
  {max_prefix: "60-BD-91",vendor: "Move Innovation"},
  {max_prefix: "68-51-B7",vendor: "PowerCloud Systems, Inc."},
  {max_prefix: "1C-95-9F",vendor: "Veethree Electronics And Marine LLC"},
  {max_prefix: "B4-00-9C",vendor: "CableWorld Ltd."},
  {max_prefix: "2C-5A-A3",vendor: "PROMATE ELECTRONIC CO.LTD"},
  {max_prefix: "34-E0-CF",vendor: "zte corporation"},
  {max_prefix: "F0-5F-5A",vendor: "Getriebebau NORD GmbH and Co. KG"},
  {max_prefix: "CC-26-2D",vendor: "Verifi, LLC"},
  {max_prefix: "3C-8A-E5",vendor: "Tensun Information Technology(Hangzhou) Co.,LTD"},
  {max_prefix: "C0-AA-68",vendor: "OSASI Technos Inc."},
  {max_prefix: "88-D7-BC",vendor: "DEP Company"},
  {max_prefix: "F4-94-66",vendor: "CountMax,  ltd"},
  {max_prefix: "74-2D-0A",vendor: "Norfolk Elektronik AG"},
  {max_prefix: "34-C8-03",vendor: "Nokia Corporation"},
  {max_prefix: "80-AA-A4",vendor: "USAG"},
  {max_prefix: "A4-0B-ED",vendor: "Carry Technology Co.,Ltd"},
  {max_prefix: "70-23-93",vendor: "fos4X GmbH"},
  {max_prefix: "F8-5F-2A",vendor: "Nokia Corporation"},
  {max_prefix: "C4-38-D3",vendor: "TAGATEC CO.,LTD"},
  {max_prefix: "6C-E4-CE",vendor: "Villiger Security Solutions AG"},
  {max_prefix: "80-3F-D6",vendor: "bytes at work AG"},
  {max_prefix: "E8-5B-F0",vendor: "Imaging Diagnostics"},
  {max_prefix: "F8-22-85",vendor: "Cypress Technology CO., LTD."},
  {max_prefix: "24-EE-3A",vendor: "Chengdu Yingji Electronic Hi-tech Co Ltd"},
  {max_prefix: "0C-C6-6A",vendor: "Nokia Corporation"},
  {max_prefix: "74-27-3C",vendor: "ChangYang Technology (Nanjing) Co., LTD"},
  {max_prefix: "08-7C-BE",vendor: "Quintic Corp."},
  {max_prefix: "04-4A-50",vendor: "Ramaxel Technology (Shenzhen) limited company"},
  {max_prefix: "38-A5-B6",vendor: "SHENZHEN MEGMEET ELECTRICAL CO.,LTD"},
  {max_prefix: "0C-D9-C1",vendor: "Visteon Corporation"},
  {max_prefix: "68-AB-8A",vendor: "RF IDeas"},
  {max_prefix: "E8-04-F3",vendor: "Throughtek Co., Ltd."},
  {max_prefix: "28-9E-DF",vendor: "Danfoss Turbocor Compressors, Inc"},
  {max_prefix: "78-44-05",vendor: "FUJITU(HONG KONG) ELECTRONIC Co.,LTD."},
  {max_prefix: "C4-AD-21",vendor: "MEDIAEDGE Corporation"},
  {max_prefix: "08-68-EA",vendor: "EITO ELECTRONICS CO., LTD."},
  {max_prefix: "80-1D-AA",vendor: "Avaya Inc"},
  {max_prefix: "7C-09-2B",vendor: "Bekey A/S"},
  {max_prefix: "84-2B-BC",vendor: "Modelleisenbahn GmbH"},
  {max_prefix: "C4-01-B1",vendor: "SeekTech INC"},
  {max_prefix: "C0-C9-46",vendor: "MITSUYA LABORATORIES INC."},
  {max_prefix: "E8-CB-A1",vendor: "Nokia Corporation"},
  {max_prefix: "E0-AA-B0",vendor: "SUNTAILI ENTERPRISE CO. LTD,"},
  {max_prefix: "64-9F-F7",vendor: "Kone OYj"},
  {max_prefix: "20-C1-AF",vendor: "i Wit Digital Co., Limited"},
  {max_prefix: "08-5B-0E",vendor: "Fortinet, Inc."},
  {max_prefix: "EC-42-F0",vendor: "ADL Embedded Solutions, Inc."},
  {max_prefix: "50-2E-CE",vendor: "Asahi Electronics Co.,Ltd"},
  {max_prefix: "AC-14-D2",vendor: "wi-daq, inc."},
  {max_prefix: "9C-4C-AE",vendor: "Mesa Labs"},
  {max_prefix: "18-42-1D",vendor: "Private"},
  {max_prefix: "28-C9-14",vendor: "Taimag Corporation"},
  {max_prefix: "74-93-A4",vendor: "Zebra Technologies Corp."},
  {max_prefix: "E4-71-85",vendor: "Securifi Ltd"},
  {max_prefix: "80-2A-FA",vendor: "Germaneers GmbH"},
  {max_prefix: "30-AE-F6",vendor: "Radio Mobile Access"},
  {max_prefix: "F4-60-0D",vendor: "Panoptic Technology, Inc"},
  {max_prefix: "A8-2B-D6",vendor: "Shina System Co., Ltd"},
  {max_prefix: "AC-CF-23",vendor: "Hi-flying electronics technology Co.,Ltd"},
  {max_prefix: "CC-91-2B",vendor: "TE Connectivity Touch Solutions"},
  {max_prefix: "C0-5E-79",vendor: "SHENZHEN HUAXUN ARK TECHNOLOGIES CO.,LTD"},
  {max_prefix: "C8-AE-9C",vendor: "Shanghai TYD Elecronic Technology Co. Ltd"},
  {max_prefix: "08-0C-C9",vendor: "Mission Technology Group, dba Magma"},
  {max_prefix: "64-0E-94",vendor: "Pluribus Networks, Inc."},
  {max_prefix: "0C-B4-EF",vendor: "Digience Co.,Ltd."},
  {max_prefix: "AC-40-EA",vendor: "C&T Solution Inc."},
  {max_prefix: "00-2A-AF",vendor: "LARsys-Automation GmbH"},
  {max_prefix: "1C-E1-65",vendor: "Marshal Corporation"},
  {max_prefix: "40-16-FA",vendor: "EKM Metering"},
  {max_prefix: "0C-13-0B",vendor: "Uniqoteq Ltd."},
  {max_prefix: "58-BF-EA",vendor: "Cisco Systems, Inc"},
  {max_prefix: "FC-1D-59",vendor: "I Smart Cities HK Ltd"},
  {max_prefix: "78-C4-AB",vendor: "Shenzhen Runsil Technology Co.,Ltd"},
  {max_prefix: "14-6A-0B",vendor: "Cypress Electronics Limited"},
  {max_prefix: "F4-90-EA",vendor: "Deciso B.V."},
  {max_prefix: "2C-54-2D",vendor: "Cisco Systems, Inc"},
  {max_prefix: "AC-3F-A4",vendor: "TAIYO YUDEN CO.,LTD"},
  {max_prefix: "6C-AE-8B",vendor: "IBM Corporation"},
  {max_prefix: "BC-14-01",vendor: "Hitron Technologies. Inc"},
  {max_prefix: "5C-EE-79",vendor: "Global Digitech Co LTD"},
  {max_prefix: "80-CE-B1",vendor: "Theissen Training Systems GmbH"},
  {max_prefix: "FC-2A-54",vendor: "Connected Data, Inc."},
  {max_prefix: "40-AC-8D",vendor: "Data Management, Inc."},
  {max_prefix: "4C-AA-16",vendor: "AzureWave Technologies (Shanghai) Inc."},
  {max_prefix: "60-90-84",vendor: "DSSD Inc"},
  {max_prefix: "78-25-44",vendor: "Omnima Limited"},
  {max_prefix: "A4-18-75",vendor: "Cisco Systems, Inc"},
  {max_prefix: "04-5C-06",vendor: "Zmodo Technology Corporation"},
  {max_prefix: "74-7B-7A",vendor: "ETH Inc."},
  {max_prefix: "48-EA-63",vendor: "Zhejiang Uniview Technologies Co., Ltd."},
  {max_prefix: "E8-8D-F5",vendor: "ZNYX Networks, Inc."},
  {max_prefix: "64-2D-B7",vendor: "SEUNGIL ELECTRONICS"},
  {max_prefix: "28-BA-18",vendor: "NextNav, LLC"},
  {max_prefix: "AC-3D-75",vendor: "HANGZHOU ZHIWAY TECHNOLOGIES CO.,LTD."},
  {max_prefix: "A0-90-DE",vendor: "VEEDIMS,LLC"},
  {max_prefix: "F4-36-E1",vendor: "Abilis Systems SARL"},
  {max_prefix: "94-CA-0F",vendor: "Honeywell Analytics"},
  {max_prefix: "28-41-21",vendor: "OptiSense Network, LLC"},
  {max_prefix: "38-45-8C",vendor: "MyCloud Technology corporation"},
  {max_prefix: "10-E4-AF",vendor: "APR, LLC"},
  {max_prefix: "D0-57-85",vendor: "Pantech Co., Ltd."},
  {max_prefix: "40-8B-07",vendor: "Actiontec Electronics, Inc"},
  {max_prefix: "90-F7-2F",vendor: "Phillips Machine & Welding Co., Inc."},
  {max_prefix: "F4-EA-67",vendor: "Cisco Systems, Inc"},
  {max_prefix: "04-F0-21",vendor: "Compex Systems Pte Ltd"},
  {max_prefix: "34-2F-6E",vendor: "Anywire corporation"},
  {max_prefix: "BC-2C-55",vendor: "Bear Flag Design, Inc."},
  {max_prefix: "0C-75-23",vendor: "BEIJING GEHUA CATV NETWORK CO.,LTD"},
  {max_prefix: "B0-8E-1A",vendor: "URadio Systems Co., Ltd"},
  {max_prefix: "D8-E9-52",vendor: "KEOPSYS"},
  {max_prefix: "94-00-70",vendor: "Nokia Corporation"},
  {max_prefix: "E8-0C-75",vendor: "Syncbak, Inc."},
  {max_prefix: "CC-EE-D9",vendor: "VAHLE Automation GmbH"},
  {max_prefix: "60-8C-2B",vendor: "Hanson Technology"},
  {max_prefix: "64-52-99",vendor: "The Chamberlain Group, Inc"},
  {max_prefix: "80-0A-06",vendor: "COMTEC co.,ltd"},
  {max_prefix: "00-2A-6A",vendor: "Cisco Systems, Inc"},
  {max_prefix: "40-E7-93",vendor: "Shenzhen Siviton Technology Co.,Ltd"},
  {max_prefix: "00-08-31",vendor: "Cisco Systems, Inc"},
  {max_prefix: "34-D0-9B",vendor: "MobilMAX Technology Inc."},
  {max_prefix: "BC-A4-E1",vendor: "Nabto"},
  {max_prefix: "28-18-FD",vendor: "Aditya Infotech Ltd."},
  {max_prefix: "D8-B9-0E",vendor: "Triple Domain Vision Co.,Ltd."},
  {max_prefix: "34-BA-9A",vendor: "Asiatelco Technologies Co."},
  {max_prefix: "F0-00-7F",vendor: "Janz - Contadores de Energia, SA"},
  {max_prefix: "30-B3-A2",vendor: "Shenzhen Heguang Measurement & Control Technology Co.,Ltd"},
  {max_prefix: "50-60-28",vendor: "Xirrus Inc."},
  {max_prefix: "00-91-FA",vendor: "Synapse Product Development"},
  {max_prefix: "A0-5A-A4",vendor: "Grand Products Nevada, Inc."},
  {max_prefix: "F0-EE-BB",vendor: "VIPAR GmbH"},
  {max_prefix: "E4-FA-1D",vendor: "PAD Peripheral Advanced Design Inc."},
  {max_prefix: "00-5C-B1",vendor: "Gospell DIGITAL TECHNOLOGY CO., LTD"},
  {max_prefix: "1C-5C-55",vendor: "PRIMA Cinema, Inc"},
  {max_prefix: "90-8F-CF",vendor: "UNO System Co., Ltd"},
  {max_prefix: "6C-E9-07",vendor: "Nokia Corporation"},
  {max_prefix: "98-4A-47",vendor: "CHG Hospital Beds"},
  {max_prefix: "14-49-78",vendor: "Digital Control Incorporated"},
  {max_prefix: "2C-10-C1",vendor: "Nintendo Co., Ltd."},
  {max_prefix: "8C-D1-7B",vendor: "CG Mobile"},
  {max_prefix: "3C-6A-7D",vendor: "Niigata Power Systems Co., Ltd."},
  {max_prefix: "50-22-67",vendor: "PixeLINK"},
  {max_prefix: "50-64-41",vendor: "Greenlee"},
  {max_prefix: "9C-1F-DD",vendor: "Accupix Inc."},
  {max_prefix: "7C-DD-11",vendor: "Chongqing MAS SCI&TECH.Co.,Ltd"},
  {max_prefix: "B8-FD-32",vendor: "Zhejiang ROICX Microelectronics"},
  {max_prefix: "00-64-A6",vendor: "Maquet CardioVascular"},
  {max_prefix: "98-8B-AD",vendor: "Corintech Ltd."},
  {max_prefix: "D4-4B-5E",vendor: "TAIYO YUDEN CO., LTD."},
  {max_prefix: "64-0E-36",vendor: "TAZTAG"},
  {max_prefix: "C8-AF-40",vendor: "marco Systemanalyse und Entwicklung GmbH"},
  {max_prefix: "40-98-4C",vendor: "Casacom Solutions AG"},
  {max_prefix: "3C-70-59",vendor: "MakerBot Industries"},
  {max_prefix: "50-26-90",vendor: "FUJITSU LIMITED"},
  {max_prefix: "5C-18-B5",vendor: "Talon Communications"},
  {max_prefix: "64-E1-61",vendor: "DEP Corp."},
  {max_prefix: "88-23-FE",vendor: "TTTech Computertechnik AG"},
  {max_prefix: "70-EE-50",vendor: "Netatmo"},
  {max_prefix: "D4-F6-3F",vendor: "IEA S.R.L."},
  {max_prefix: "58-B0-D4",vendor: "ZuniData Systems Inc."},
  {max_prefix: "64-55-7F",vendor: "NSFOCUS Information Technology Co., Ltd."},
  {max_prefix: "00-08-2F",vendor: "Cisco Systems, Inc"},
  {max_prefix: "38-60-77",vendor: "PEGATRON CORPORATION"},
  {max_prefix: "70-81-05",vendor: "Cisco Systems, Inc"},
  {max_prefix: "94-1D-1C",vendor: "TLab West Systems AB"},
  {max_prefix: "94-AE-61",vendor: "Alcatel Lucent"},
  {max_prefix: "5C-CE-AD",vendor: "CDYNE Corporation"},
  {max_prefix: "AC-54-EC",vendor: "IEEE P1823 Standards Working Group"},
  {max_prefix: "70-97-56",vendor: "Happyelectronics Co.,Ltd"},
  {max_prefix: "E4-55-EA",vendor: "Dedicated Computing"},
  {max_prefix: "B8-9A-ED",vendor: "OceanServer Technology, Inc"},
  {max_prefix: "C8-7D-77",vendor: "Shenzhen Kingtech Communication Equipment Co.,Ltd"},
  {max_prefix: "B8-20-E7",vendor: "Guangzhou Horizontal Information & Network Integration Co. Ltd"},
  {max_prefix: "7C-6B-52",vendor: "Tigaro Wireless"},
  {max_prefix: "E0-ED-1A",vendor: "vastriver Technology Co., Ltd"},
  {max_prefix: "68-5E-6B",vendor: "PowerRay Co., Ltd."},
  {max_prefix: "B0-5C-E5",vendor: "Nokia Corporation"},
  {max_prefix: "34-82-DE",vendor: "Kiio Inc"},
  {max_prefix: "20-C8-B3",vendor: "SHENZHEN BUL-TECH CO.,LTD."},
  {max_prefix: "4C-5F-D2",vendor: "Alcatel-Lucent"},
  {max_prefix: "B8-F5-E7",vendor: "WayTools, LLC"},
  {max_prefix: "B8-19-99",vendor: "Nesys"},
  {max_prefix: "34-25-5D",vendor: "Shenzhen Loadcom Technology Co.,Ltd"},
  {max_prefix: "FC-00-12",vendor: "Toshiba Samsung Storage Technolgoy Korea Corporation"},
  {max_prefix: "C0-29-F3",vendor: "XySystem"},
  {max_prefix: "94-DE-0E",vendor: "SmartOptics AS"},
  {max_prefix: "80-7A-7F",vendor: "ABB Genway Xiamen Electrical Equipment CO., LTD"},
  {max_prefix: "24-DA-B6",vendor: "Sistemas de Gesti\u00f3n Energ\u00e9tica S.A. de C.V"},
  {max_prefix: "B0-7D-62",vendor: "Dipl.-Ing. H. Horstmann GmbH"},
  {max_prefix: "9C-C7-D1",vendor: "SHARP Corporation"},
  {max_prefix: "14-90-90",vendor: "KongTop industrial(shen zhen)CO.,LTD"},
  {max_prefix: "28-C7-18",vendor: "Altierre"},
  {max_prefix: "7C-4C-58",vendor: "Scale Computing, Inc."},
  {max_prefix: "10-13-EE",vendor: "Justec International Technology INC."},
  {max_prefix: "8C-27-1D",vendor: "QuantHouse"},
  {max_prefix: "38-DE-60",vendor: "Mohlenhoff GmbH"},
  {max_prefix: "28-39-E7",vendor: "Preceno Technology Pte.Ltd."},
  {max_prefix: "D4-CE-B8",vendor: "Enatel LTD"},
  {max_prefix: "30-68-8C",vendor: "Reach Technology Inc."},
  {max_prefix: "F8-E7-B5",vendor: "\u00b5Tech Tecnologia LTDA"},
  {max_prefix: "10-EE-D9",vendor: "Canoga Perkins Corporation"},
  {max_prefix: "18-14-20",vendor: "TEB SAS"},
  {max_prefix: "4C-A7-4B",vendor: "Alcatel Lucent"},
  {max_prefix: "1C-E1-92",vendor: "Qisda Corporation"},
  {max_prefix: "70-6F-81",vendor: "Private"},
  {max_prefix: "DC-F0-5D",vendor: "Letta Teknoloji"},
  {max_prefix: "8C-B8-2C",vendor: "IPitomy Communications"},
  {max_prefix: "80-7D-E3",vendor: "Chongqing Sichuan Instrument Microcircuit Co.LTD."},
  {max_prefix: "DC-17-5A",vendor: "Hitachi High-Technologies Corporation"},
  {max_prefix: "90-0D-66",vendor: "Digimore Electronics Co., Ltd"},
  {max_prefix: "AC-4A-FE",vendor: "Hisense Broadband Multimedia Technology Co.,Ltd."},
  {max_prefix: "54-F5-B6",vendor: "ORIENTAL PACIFIC INTERNATIONAL LIMITED"},
  {max_prefix: "90-34-2B",vendor: "Gatekeeper Systems, Inc."},
  {max_prefix: "5C-16-C7",vendor: "Arista Networks"},
  {max_prefix: "3C-09-6D",vendor: "Powerhouse Dynamics"},
  {max_prefix: "C8-A1-BA",vendor: "Neul Ltd"},
  {max_prefix: "C4-3A-9F",vendor: "Siconix Inc."},
  {max_prefix: "68-6E-23",vendor: "Wi3 Inc."},
  {max_prefix: "F4-94-61",vendor: "NexGen Storage"},
  {max_prefix: "0C-92-4E",vendor: "Rice Lake Weighing Systems"},
  {max_prefix: "B8-CD-A7",vendor: "Maxeler Technologies Ltd."},
  {max_prefix: "A4-B3-6A",vendor: "JSC SDO Chromatec"},
  {max_prefix: "F4-3D-80",vendor: "FAG Industrial Services GmbH"},
  {max_prefix: "54-35-DF",vendor: "Symeo GmbH"},
  {max_prefix: "48-DC-FB",vendor: "Nokia Corporation"},
  {max_prefix: "F0-DB-30",vendor: "Yottabyte"},
  {max_prefix: "9C-31-B6",vendor: "Kulite Semiconductor Products Inc"},
  {max_prefix: "10-C5-86",vendor: "BIO SOUND LAB CO., LTD."},
  {max_prefix: "10-76-8A",vendor: "EoCell"},
  {max_prefix: "0C-39-56",vendor: "Observator instruments"},
  {max_prefix: "DC-A6-BD",vendor: "Beijing Lanbo Technology Co., Ltd."},
  {max_prefix: "A4-5A-1C",vendor: "smart-electronic GmbH"},
  {max_prefix: "80-64-59",vendor: "Nimbus Inc."},
  {max_prefix: "8C-89-A5",vendor: "Micro-Star INT'L CO., LTD"},
  {max_prefix: "B4-A5-A9",vendor: "MODI GmbH"},
  {max_prefix: "E8-C3-20",vendor: "Austco Marketing & Service (USA) ltd."},
  {max_prefix: "C4-36-DA",vendor: "Rusteletech Ltd."},
  {max_prefix: "68-84-70",vendor: "eSSys Co.,Ltd"},
  {max_prefix: "8C-5C-A1",vendor: "d-broad,INC"},
  {max_prefix: "D4-3A-E9",vendor: "DONGGUAN ipt INDUSTRIAL CO., LTD"},
  {max_prefix: "58-98-35",vendor: "Technicolor Delivery Technologies Belgium NV"},
  {max_prefix: "E8-CC-32",vendor: "Micronet  LTD"},
  {max_prefix: "E4-DD-79",vendor: "En-Vision America, Inc."},
  {max_prefix: "18-F6-50",vendor: "Multimedia Pacific Limited"},
  {max_prefix: "20-B7-C0",vendor: "OMICRON electronics GmbH"},
  {max_prefix: "80-58-C5",vendor: "NovaTec Kommunikationstechnik GmbH"},
  {max_prefix: "B8-C7-16",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "D4-2C-3D",vendor: "Sky Light Digital Limited"},
  {max_prefix: "1C-18-4A",vendor: "ShenZhen RicherLink Technologies Co.,LTD"},
  {max_prefix: "F4-4E-FD",vendor: "Actions Semiconductor Co.,Ltd.(Cayman Islands)"},
  {max_prefix: "24-B8-D2",vendor: "Opzoon Technology Co.,Ltd."},
  {max_prefix: "A4-99-81",vendor: "FuJian Elite Power Tech CO.,LTD."},
  {max_prefix: "B8-3A-7B",vendor: "Worldplay (Canada) Inc."},
  {max_prefix: "D0-EB-9E",vendor: "Seowoo Inc."},
  {max_prefix: "78-02-8F",vendor: "Adaptive Spectrum and Signal Alignment (ASSIA), Inc."},
  {max_prefix: "24-C9-DE",vendor: "Genoray"},
  {max_prefix: "54-05-5F",vendor: "Alcatel Lucent"},
  {max_prefix: "6C-5D-63",vendor: "ShenZhen Rapoo Technology Co., Ltd."},
  {max_prefix: "04-32-F4",vendor: "Partron"},
  {max_prefix: "14-07-E0",vendor: "Abrantix AG"},
  {max_prefix: "DC-CF-94",vendor: "Beijing Rongcheng Hutong Technology Co., Ltd."},
  {max_prefix: "A4-DB-2E",vendor: "Kingspan Environmental Ltd"},
  {max_prefix: "DC-16-A2",vendor: "Medtronic Diabetes"},
  {max_prefix: "30-8C-FB",vendor: "Dropcam"},
  {max_prefix: "BC-CD-45",vendor: "VOISMART"},
  {max_prefix: "14-3E-60",vendor: "Nokia"},
  {max_prefix: "5C-56-ED",vendor: "3pleplay Electronics Private Limited"},
  {max_prefix: "94-16-73",vendor: "Point Core SARL"},
  {max_prefix: "C8-FE-30",vendor: "Bejing DAYO Mobile Communication Technology Ltd."},
  {max_prefix: "E4-D7-1D",vendor: "Oraya Therapeutics"},
  {max_prefix: "84-27-CE",vendor: "Corporation of the Presiding Bishop of The Church of Jesus Christ of Latter-day Saints"},
  {max_prefix: "48-D8-FE",vendor: "ClarIDy Solutions, Inc."},
  {max_prefix: "70-A4-1C",vendor: "Advanced Wireless Dynamics S.L."},
  {max_prefix: "70-32-D5",vendor: "Athena Wireless Communications Inc"},
  {max_prefix: "78-51-0C",vendor: "LiveU Ltd."},
  {max_prefix: "44-AA-E8",vendor: "Nanotec Electronic GmbH & Co. KG"},
  {max_prefix: "70-E8-43",vendor: "Beijing C&W Optical Communication Technology Co.,Ltd."},
  {max_prefix: "2C-7E-CF",vendor: "Onzo Ltd"},
  {max_prefix: "74-B0-0C",vendor: "Network Video Technologies, Inc"},
  {max_prefix: "BC-BB-C9",vendor: "Kellendonk Elektronik GmbH"},
  {max_prefix: "B4-2A-39",vendor: "ORBIT MERRET, spol. s r. o."},
  {max_prefix: "D4-94-5A",vendor: "COSMO CO., LTD"},
  {max_prefix: "30-4C-7E",vendor: "Panasonic Electric Works Automation Controls Techno Co.,Ltd."},
  {max_prefix: "5C-F2-07",vendor: "Speco Technologies"},
  {max_prefix: "E8-40-40",vendor: "Cisco Systems, Inc"},
  {max_prefix: "D8-9D-B9",vendor: "eMegatech International Corp."},
  {max_prefix: "40-5A-9B",vendor: "ANOVO"},
  {max_prefix: "10-37-11",vendor: "NORBIT ITS"},
  {max_prefix: "B4-B8-8D",vendor: "Thuh Company"},
  {max_prefix: "4C-73-A5",vendor: "KOVE"},
  {max_prefix: "D4-28-B2",vendor: "ioBridge, Inc."},
  {max_prefix: "E8-3E-B6",vendor: "RIM"},
  {max_prefix: "BC-35-E5",vendor: "Hydro Systems Company"},
  {max_prefix: "28-CC-FF",vendor: "Corporacion Empresarial Altra SL"},
  {max_prefix: "94-FD-1D",vendor: "WhereWhen Corp"},
  {max_prefix: "4C-07-C9",vendor: "COMPUTER OFFICE Co.,Ltd."},
  {max_prefix: "00-B3-42",vendor: "MacroSAN Technologies Co., Ltd."},
  {max_prefix: "9C-5D-95",vendor: "VTC Electronics Corp."},
  {max_prefix: "B8-A8-AF",vendor: "Logic S.p.A."},
  {max_prefix: "60-F6-73",vendor: "TERUMO CORPORATION"},
  {max_prefix: "E4-2A-D3",vendor: "Magneti Marelli S.p.A. Powertrain"},
  {max_prefix: "1C-F5-E7",vendor: "Turtle Industry Co., Ltd."},
  {max_prefix: "98-0E-E4",vendor: "Private"},
  {max_prefix: "44-7D-A5",vendor: "VTION INFORMATION TECHNOLOGY (FUJIAN) CO.,LTD"},
  {max_prefix: "0C-CD-D3",vendor: "EASTRIVER TECHNOLOGY CO., LTD."},
  {max_prefix: "84-DE-3D",vendor: "Crystal Vision Ltd"},
  {max_prefix: "E0-69-95",vendor: "PEGATRON CORPORATION"},
  {max_prefix: "F8-76-9B",vendor: "Neopis Co., Ltd."},
  {max_prefix: "C8-9C-1D",vendor: "Cisco Systems, Inc"},
  {max_prefix: "D0-75-BE",vendor: "Reno A&E"},
  {max_prefix: "BC-6E-76",vendor: "Green Energy Options Ltd"},
  {max_prefix: "E8-28-D5",vendor: "Cots Technology"},
  {max_prefix: "F8-DA-F4",vendor: "Taishan Online Technology Co., Ltd."},
  {max_prefix: "08-D5-C0",vendor: "Seers Technology Co., Ltd"},
  {max_prefix: "6C-33-A9",vendor: "Magicjack LP"},
  {max_prefix: "10-8C-CF",vendor: "Cisco Systems, Inc"},
  {max_prefix: "D8-E3-AE",vendor: "CIRTEC MEDICAL SYSTEMS"},
  {max_prefix: "CC-76-69",vendor: "SEETECH"},
  {max_prefix: "AC-20-AA",vendor: "DMATEK Co., Ltd."},
  {max_prefix: "E4-37-D7",vendor: "HENRI DEPAEPE S.A.S."},
  {max_prefix: "E0-A1-D7",vendor: "SFR"},
  {max_prefix: "94-81-A4",vendor: "Azuray Technologies"},
  {max_prefix: "BC-E0-9D",vendor: "Eoslink"},
  {max_prefix: "BC-43-77",vendor: "Hang Zhou Huite Technology Co.,ltd."},
  {max_prefix: "7C-DD-90",vendor: "Shenzhen Ogemray Technology Co., Ltd."},
  {max_prefix: "9C-22-0E",vendor: "TASCAN Systems GmbH"},
  {max_prefix: "0C-3C-65",vendor: "Dome Imaging Inc"},
  {max_prefix: "C8-DF-7C",vendor: "Nokia Corporation"},
  {max_prefix: "FC-AF-6A",vendor: "Qulsar Inc"},
  {max_prefix: "08-17-35",vendor: "Cisco Systems, Inc"},
  {max_prefix: "CC-BE-71",vendor: "OptiLogix BV"},
  {max_prefix: "0C-46-9D",vendor: "MS Sedco"},
  {max_prefix: "00-B0-33",vendor: "OAO Izhevskiy radiozavod"},
  {max_prefix: "08-B7-EC",vendor: "Wireless Seismic"},
  {max_prefix: "18-AF-9F",vendor: "DIGITRONIC Automationsanlagen GmbH"},
  {max_prefix: "E4-6C-21",vendor: "messMa GmbH"},
  {max_prefix: "E0-F3-79",vendor: "Vaddio"},
  {max_prefix: "78-B6-C1",vendor: "AOBO Telecom Co.,Ltd"},
  {max_prefix: "B0-9A-E2",vendor: "STEMMER IMAGING GmbH"},
  {max_prefix: "14-EE-9D",vendor: "AirNav Systems LLC"},
  {max_prefix: "B4-4C-C2",vendor: "NR ELECTRIC CO., LTD"},
  {max_prefix: "48-CB-6E",vendor: "Cello Electronics (UK) Ltd"},
  {max_prefix: "00-6D-FB",vendor: "Vutrix Technologies Ltd"},
  {max_prefix: "78-D0-04",vendor: "Neousys Technology Inc."},
  {max_prefix: "88-95-B9",vendor: "Unified Packet Systems Crop"},
  {max_prefix: "78-A0-51",vendor: "iiNet Labs Pty Ltd"},
  {max_prefix: "80-4F-58",vendor: "ThinkEco, Inc."},
  {max_prefix: "44-8C-52",vendor: "KTIS CO., Ltd"},
  {max_prefix: "34-6F-92",vendor: "White Rodgers Division"},
  {max_prefix: "34-BD-F9",vendor: "Shanghai WDK Industrial Co.,Ltd."},
  {max_prefix: "D8-FE-8F",vendor: "IDFone Co., Ltd."},
  {max_prefix: "88-8C-19",vendor: "Brady Corp Asia Pacific Ltd"},
  {max_prefix: "08-D2-9A",vendor: "Proformatique"},
  {max_prefix: "C8-93-83",vendor: "Embedded Automation, Inc."},
  {max_prefix: "90-67-B5",vendor: "Alcatel-Lucent"},
  {max_prefix: "04-75-F5",vendor: "CSST"},
  {max_prefix: "E8-0C-38",vendor: "DAEYOUNG INFORMATION SYSTEM CO., LTD"},
  {max_prefix: "E0-8A-7E",vendor: "Exponent"},
  {max_prefix: "8C-4D-EA",vendor: "Cerio Corporation"},
  {max_prefix: "34-DF-2A",vendor: "Fujikon Industrial Co.,Limited"},
  {max_prefix: "70-A1-91",vendor: "Trendsetter Medical, LLC"},
  {max_prefix: "24-BA-30",vendor: "Technical Consumer Products, Inc."},
  {max_prefix: "18-8E-D5",vendor: "TP Vision Belgium N.V. - innovation site Brugge"},
  {max_prefix: "40-B2-C8",vendor: "Nortel Networks"},
  {max_prefix: "70-8B-78",vendor: "citygrow technology co., ltd"},
  {max_prefix: "A8-B0-AE",vendor: "BizLink Special Cables Germany GmbH"},
  {max_prefix: "E4-27-71",vendor: "Smartlabs"},
  {max_prefix: "70-B0-8C",vendor: "Shenou Communication Equipment Co.,Ltd"},
  {max_prefix: "C0-3B-8F",vendor: "Minicom Digital Signage"},
  {max_prefix: "20-FE-DB",vendor: "M2M Solution S.A.S."},
  {max_prefix: "C8-A7-29",vendor: "SYStronics Co., Ltd."},
  {max_prefix: "44-54-C0",vendor: "Thompson Aerospace"},
  {max_prefix: "64-31-7E",vendor: "Dexin Corporation"},
  {max_prefix: "3C-99-F7",vendor: "Lansentechnology AB"},
  {max_prefix: "50-7D-02",vendor: "BIODIT"},
  {max_prefix: "B4-A4-E3",vendor: "Cisco Systems, Inc"},
  {max_prefix: "94-DD-3F",vendor: "A+V Link Technologies, Corp."},
  {max_prefix: "F4-42-27",vendor: "S & S Research Inc."},
  {max_prefix: "8C-1F-94",vendor: "RF Surgical System Inc."},
  {max_prefix: "44-91-DB",vendor: "Shanghai Huaqin Telecom Technology Co.,Ltd"},
  {max_prefix: "A8-55-6A",vendor: "3S System Technology Inc."},
  {max_prefix: "D0-57-4C",vendor: "Cisco Systems, Inc"},
  {max_prefix: "F8-DA-E2",vendor: "NDC Technologies"},
  {max_prefix: "AC-83-F0",vendor: "Cobalt Digital Inc."},
  {max_prefix: "CC-6B-98",vendor: "Minetec Wireless Technologies"},
  {max_prefix: "3C-04-BF",vendor: "PRAVIS SYSTEMS Co.Ltd.,"},
  {max_prefix: "7C-55-E7",vendor: "YSI, Inc."},
  {max_prefix: "C4-F4-64",vendor: "Spica international"},
  {max_prefix: "60-2A-54",vendor: "CardioTek B.V."},
  {max_prefix: "BC-FF-AC",vendor: "TOPCON CORPORATION"},
  {max_prefix: "14-D7-6E",vendor: "CONCH ELECTRONIC Co.,Ltd"},
  {max_prefix: "44-5E-F3",vendor: "Tonalite Holding B.V."},
  {max_prefix: "68-DB-96",vendor: "OPWILL Technologies CO .,LTD"},
  {max_prefix: "94-A7-BC",vendor: "BodyMedia, Inc."},
  {max_prefix: "C8-A1-B6",vendor: "Shenzhen Longway Technologies Co., Ltd"},
  {max_prefix: "38-58-0C",vendor: "Panaccess Systems GmbH"},
  {max_prefix: "44-51-DB",vendor: "Raytheon BBN Technologies"},
  {max_prefix: "58-50-76",vendor: "Linear Equipamentos Eletronicos SA"},
  {max_prefix: "F0-F9-F7",vendor: "IES GmbH & Co. KG"},
  {max_prefix: "64-A2-32",vendor: "OOO Samlight"},
  {max_prefix: "64-FC-8C",vendor: "Zonar Systems"},
  {max_prefix: "0C-8D-98",vendor: "TOP EIGHT IND CORP"},
  {max_prefix: "40-C7-C9",vendor: "Naviit Inc."},
  {max_prefix: "98-03-A0",vendor: "ABB n.v. Power Quality Products"},
  {max_prefix: "DC-FA-D5",vendor: "STRONG Ges.m.b.H."},
  {max_prefix: "6C-8D-65",vendor: "Wireless Glue Networks, Inc."},
  {max_prefix: "7C-BB-6F",vendor: "Cosco Electronics Co., Ltd."},
  {max_prefix: "20-B0-F7",vendor: "Enclustra GmbH"},
  {max_prefix: "B4-C8-10",vendor: "Umpi srl"},
  {max_prefix: "54-31-31",vendor: "Raster Vision Ltd"},
  {max_prefix: "D0-E3-47",vendor: "Yoga"},
  {max_prefix: "70-5E-AA",vendor: "Action Target, Inc."},
  {max_prefix: "34-F9-68",vendor: "ATEK Products, LLC"},
  {max_prefix: "F8-C0-91",vendor: "Highgates Technology"},
  {max_prefix: "AC-9B-84",vendor: "Smak Tecnologia e Automacao"},
  {max_prefix: "90-F2-78",vendor: "Radius Gateway"},
  {max_prefix: "80-66-29",vendor: "Prescope Technologies CO.,LTD."},
  {max_prefix: "4C-60-D5",vendor: "airPointe of New Hampshire"},
  {max_prefix: "84-29-14",vendor: "EMPORIA TELECOM Produktions- und VertriebsgesmbH & Co KG"},
  {max_prefix: "BC-7D-D1",vendor: "Radio Data Comms"},
  {max_prefix: "64-67-07",vendor: "Beijing Omnific Technology, Ltd."},
  {max_prefix: "58-FD-20",vendor: "Systemhouse Solutions AB"},
  {max_prefix: "F0-ED-1E",vendor: "Bilkon Bilgisayar Kontrollu Cih. Im.Ltd."},
  {max_prefix: "C4-16-FA",vendor: "Prysm Inc"},
  {max_prefix: "50-6F-9A",vendor: "Wi-Fi Alliance"},
  {max_prefix: "24-1F-2C",vendor: "Calsys, Inc."},
  {max_prefix: "F0-BD-F1",vendor: "Sipod Inc."},
  {max_prefix: "CC-43-E3",vendor: "Trump s.a."},
  {max_prefix: "AC-A0-16",vendor: "Cisco Systems, Inc"},
  {max_prefix: "58-E7-47",vendor: "Deltanet AG"},
  {max_prefix: "40-61-8E",vendor: "Stella-Green Co"},
  {max_prefix: "68-E4-1F",vendor: "Unglaube Identech GmbH"},
  {max_prefix: "38-9F-83",vendor: "OTN Systems N.V."},
  {max_prefix: "BC-6A-16",vendor: "tdvine"},
  {max_prefix: "00-3A-9D",vendor: "NEC Platforms, Ltd."},
  {max_prefix: "00-93-63",vendor: "Uni-Link Technology Co., Ltd."},
  {max_prefix: "7C-76-73",vendor: "ENMAS GmbH"},
  {max_prefix: "00-35-32",vendor: "Electro-Metrics Corporation"},
  {max_prefix: "08-1F-F3",vendor: "Cisco Systems, Inc"},
  {max_prefix: "44-37-6F",vendor: "Young Electric Sign Co"},
  {max_prefix: "6C-DC-6A",vendor: "Promethean Limited"},
  {max_prefix: "90-55-AE",vendor: "Ericsson, EAB/RWI/K"},
  {max_prefix: "84-C7-27",vendor: "Gnodal Ltd"},
  {max_prefix: "28-CD-4C",vendor: "Individual Computers GmbH"},
  {max_prefix: "8C-53-F7",vendor: "A&D ENGINEERING CO., LTD."},
  {max_prefix: "40-40-22",vendor: "ZIV"},
  {max_prefix: "A8-5B-B0",vendor: "Shenzhen Dehoo Technology Co.,Ltd"},
  {max_prefix: "88-87-17",vendor: "CANON INC."},
  {max_prefix: "10-10-B6",vendor: "McCain Inc"},
  {max_prefix: "D4-82-3E",vendor: "Argosy Technologies, Ltd."},
  {max_prefix: "44-A6-89",vendor: "PROMAX ELECTRONICA SA"},
  {max_prefix: "08-76-95",vendor: "Auto Industrial Co., Ltd."},
  {max_prefix: "AC-CE-8F",vendor: "HWA YAO TECHNOLOGIES CO., LTD"},
  {max_prefix: "8C-92-36",vendor: "Aus.Linx Technology Co., Ltd."},
  {max_prefix: "F8-91-2A",vendor: "GLP German Light Products GmbH"},
  {max_prefix: "10-C7-3F",vendor: "Midas Klark Teknik Ltd"},
  {max_prefix: "44-E4-9A",vendor: "OMNITRONICS PTY LTD"},
  {max_prefix: "08-F2-F4",vendor: "Net One Partners Co.,Ltd."},
  {max_prefix: "0C-7D-7C",vendor: "Kexiang Information Technology Co, Ltd."},
  {max_prefix: "B4-82-FE",vendor: "ASKEY COMPUTER CORP"},
  {max_prefix: "30-7C-30",vendor: "RIM"},
  {max_prefix: "BC-4E-3C",vendor: "CORE STAFF CO., LTD."},
  {max_prefix: "30-37-A6",vendor: "Cisco Systems, Inc"},
  {max_prefix: "DC-1D-9F",vendor: "U & B tech"},
  {max_prefix: "B0-90-74",vendor: "Fulan Electronics Limited"},
  {max_prefix: "94-F6-92",vendor: "Geminico co.,Ltd."},
  {max_prefix: "78-5C-72",vendor: "Hioso Technology Co., Ltd."},
  {max_prefix: "58-05-56",vendor: "Elettronica GF S.r.L."},
  {max_prefix: "10-44-5A",vendor: "Shaanxi Hitech Electronic Co., LTD"},
  {max_prefix: "F4-76-26",vendor: "Viltechmeda UAB"},
  {max_prefix: "68-EF-BD",vendor: "Cisco Systems, Inc"},
  {max_prefix: "F0-2F-D8",vendor: "Bi2-Vision"},
  {max_prefix: "50-2A-8B",vendor: "Telekom Research and Development Sdn Bhd"},
  {max_prefix: "EC-43-E6",vendor: "AWCER Ltd."},
  {max_prefix: "F0-24-08",vendor: "Talaris (Sweden) AB"},
  {max_prefix: "80-81-A5",vendor: "TONGQING COMMUNICATION EQUIPMENT (SHENZHEN) Co.,Ltd"},
  {max_prefix: "88-94-F9",vendor: "Gemicom Technology, Inc."},
  {max_prefix: "50-2A-7E",vendor: "Smart electronic GmbH"},
  {max_prefix: "F0-26-4C",vendor: "Sigrist-Photometer AG"},
  {max_prefix: "54-42-49",vendor: "Sony Corporation"},
  {max_prefix: "0C-17-F1",vendor: "TELECSYS"},
  {max_prefix: "78-12-B8",vendor: "ORANTEK LIMITED"},
  {max_prefix: "10-B7-F6",vendor: "Plastoform Industries Ltd."},
  {max_prefix: "44-8E-81",vendor: "VIG"},
  {max_prefix: "0C-84-11",vendor: "A.O. Smith Water Products"},
  {max_prefix: "98-BC-99",vendor: "Edeltech Co.,Ltd."},
  {max_prefix: "90-47-16",vendor: "RORZE CORPORATION"},
  {max_prefix: "A4-DA-3F",vendor: "Bionics Corp."},
  {max_prefix: "A8-C2-22",vendor: "TM-Research Inc."},
  {max_prefix: "00-3D-41",vendor: "Hatteland Computer AS"},
  {max_prefix: "CC-50-76",vendor: "Ocom Communications, Inc."},
  {max_prefix: "5C-87-78",vendor: "Cybertelbridge co.,ltd"},
  {max_prefix: "38-BB-23",vendor: "OzVision America LLC"},
  {max_prefix: "00-3A-9B",vendor: "Cisco Systems, Inc"},
  {max_prefix: "2C-34-27",vendor: "ERCO & GENER"},
  {max_prefix: "80-91-2A",vendor: "Lih Rong electronic Enterprise Co., Ltd."},
  {max_prefix: "80-3B-9A",vendor: "ghe-ces electronic ag"},
  {max_prefix: "74-32-56",vendor: "NT-ware Systemprg GmbH"},
  {max_prefix: "4C-C4-52",vendor: "Shang Hai Tyd. Electon Technology Ltd."},
  {max_prefix: "7C-CB-0D",vendor: "Antaira Technologies, LLC"},
  {max_prefix: "C0-1E-9B",vendor: "Pixavi AS"},
  {max_prefix: "C4-E1-7C",vendor: "U2S co."},
  {max_prefix: "20-BF-DB",vendor: "DVL"},
  {max_prefix: "44-83-12",vendor: "Star-Net"},
  {max_prefix: "E0-AB-FE",vendor: "Orb Networks, Inc."},
  {max_prefix: "A0-5D-E7",vendor: "DIRECTV, Inc."},
  {max_prefix: "08-76-18",vendor: "ViE Technologies Sdn. Bhd."},
  {max_prefix: "D0-E4-0B",vendor: "Wearable Inc."},
  {max_prefix: "74-7E-1A",vendor: "Red Embedded Design Limited"},
  {max_prefix: "14-A8-6B",vendor: "ShenZhen Telacom Science&Technology Co., Ltd"},
  {max_prefix: "0C-C3-A7",vendor: "Meritec"},
  {max_prefix: "DC-E2-AC",vendor: "Lumens Digital Optics Inc."},
  {max_prefix: "98-D8-8C",vendor: "Nortel Networks"},
  {max_prefix: "78-19-2E",vendor: "NASCENT Technology"},
  {max_prefix: "48-EB-30",vendor: "ETERNA TECHNOLOGY, INC."},
  {max_prefix: "4C-32-2D",vendor: "TELEDATA NETWORKS"},
  {max_prefix: "AC-86-7E",vendor: "Create New Technology (HK) Limited Company"},
  {max_prefix: "8C-59-8B",vendor: "C Technologies AB"},
  {max_prefix: "D4-4C-A7",vendor: "Informtekhnika & Communication, LLC"},
  {max_prefix: "A0-40-25",vendor: "Actioncable, Inc."},
  {max_prefix: "4C-4B-68",vendor: "Mobile Device, Inc."},
  {max_prefix: "20-12-57",vendor: "Most Lucky Trading Ltd"},
  {max_prefix: "E8-DA-AA",vendor: "VideoHome Technology Corp."},
  {max_prefix: "64-7D-81",vendor: "YOKOTA INDUSTRIAL CO,.LTD"},
  {max_prefix: "7C-CF-CF",vendor: "Shanghai SEARI Intelligent System Co., Ltd"},
  {max_prefix: "DC-33-50",vendor: "TechSAT GmbH"},
  {max_prefix: "E0-64-BB",vendor: "DigiView S.r.l."},
  {max_prefix: "68-AA-D2",vendor: "DATECS LTD.,"},
  {max_prefix: "A4-DE-50",vendor: "Total Walther GmbH"},
  {max_prefix: "D8-D6-7E",vendor: "GSK CNC EQUIPMENT CO.,LTD"},
  {max_prefix: "C4-AA-A1",vendor: "SUMMIT DEVELOPMENT, spol.s r.o."},
  {max_prefix: "1C-F0-61",vendor: "SCAPS GmbH"},
  {max_prefix: "A8-93-E6",vendor: "JIANGXI JINGGANGSHAN CKING COMMUNICATION TECHNOLOGY CO.,LTD"},
  {max_prefix: "30-32-D4",vendor: "Hanilstm Co., Ltd."},
  {max_prefix: "04-B4-66",vendor: "BSP Co., Ltd."},
  {max_prefix: "68-CC-9C",vendor: "Mine Site Technologies"},
  {max_prefix: "14-6E-0A",vendor: "Private"},
  {max_prefix: "0C-E7-09",vendor: "Fox Crypto B.V."},
  {max_prefix: "6C-0F-6A",vendor: "JDC Tech Co., Ltd."},
  {max_prefix: "F0-BC-C8",vendor: "MaxID (Pty) Ltd"},
  {max_prefix: "24-82-8A",vendor: "Prowave Technologies Ltd."},
  {max_prefix: "00-27-1B",vendor: "Alec Sicherheitssysteme GmbH"},
  {max_prefix: "00-27-18",vendor: "Suzhou NEW SEAUNION Video Technology Co.,Ltd"},
  {max_prefix: "00-27-0B",vendor: "Adura Technologies"},
  {max_prefix: "00-27-0D",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-27-07",vendor: "Lift Complex DS, JSC"},
  {max_prefix: "00-26-FE",vendor: "MKD Technology Inc."},
  {max_prefix: "B4-B5-AF",vendor: "Minsung Electronics"},
  {max_prefix: "04-B3-B6",vendor: "Seamap (UK) Ltd"},
  {max_prefix: "00-26-E5",vendor: "AEG Power Solutions"},
  {max_prefix: "00-26-BC",vendor: "General Jack Technology Ltd."},
  {max_prefix: "00-26-AE",vendor: "Wireless Measurement Ltd"},
  {max_prefix: "00-26-B1",vendor: "Navis Auto Motive Systems, Inc."},
  {max_prefix: "00-26-AA",vendor: "Kenmec Mechanical Engineering Co., Ltd."},
  {max_prefix: "00-26-70",vendor: "Cinch Connectors"},
  {max_prefix: "00-26-71",vendor: "AUTOVISION Co., Ltd"},
  {max_prefix: "00-26-D7",vendor: "KM Electornic Technology Co., Ltd."},
  {max_prefix: "00-26-D0",vendor: "Semihalf"},
  {max_prefix: "00-26-D2",vendor: "Pcube Systems, Inc."},
  {max_prefix: "00-26-A0",vendor: "moblic"},
  {max_prefix: "00-26-96",vendor: "NOOLIX Co., Ltd"},
  {max_prefix: "00-26-9A",vendor: "Carina System Co., Ltd."},
  {max_prefix: "00-26-95",vendor: "ZT Group Int'l Inc"},
  {max_prefix: "00-26-93",vendor: "QVidium Technologies, Inc."},
  {max_prefix: "00-26-CD",vendor: "PurpleComm, Inc."},
  {max_prefix: "00-26-8E",vendor: "Alta Solutions, Inc."},
  {max_prefix: "00-26-78",vendor: "Logic Instrument SA"},
  {max_prefix: "00-26-77",vendor: "DEIF A/S"},
  {max_prefix: "00-26-E3",vendor: "DTI"},
  {max_prefix: "00-26-65",vendor: "ProtectedLogic Corporation"},
  {max_prefix: "00-26-60",vendor: "Logiways"},
  {max_prefix: "00-26-14",vendor: "KTNF"},
  {max_prefix: "00-26-10",vendor: "Apacewave Technologies"},
  {max_prefix: "00-26-0E",vendor: "Ablaze Systems, LLC"},
  {max_prefix: "00-26-0D",vendor: "Mercury Systems, Inc."},
  {max_prefix: "00-26-0A",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-26-2C",vendor: "IKT Advanced Technologies s.r.o."},
  {max_prefix: "00-26-2E",vendor: "Chengdu Jiuzhou Electronic Technology Inc"},
  {max_prefix: "00-26-29",vendor: "Juphoon System Software Inc."},
  {max_prefix: "00-26-48",vendor: "Emitech Corp."},
  {max_prefix: "00-26-45",vendor: "Circontrol S.A."},
  {max_prefix: "00-26-3E",vendor: "Trapeze Networks"},
  {max_prefix: "00-26-3C",vendor: "Bachmann Technology GmbH & Co. KG"},
  {max_prefix: "00-26-3D",vendor: "MIA Corporation"},
  {max_prefix: "00-26-32",vendor: "Instrumentation Technologies d.d."},
  {max_prefix: "00-25-E2",vendor: "Everspring Industry Co., Ltd."},
  {max_prefix: "00-25-E1",vendor: "SHANGHAI SEEYOO ELECTRONIC & TECHNOLOGY CO., LTD"},
  {max_prefix: "00-26-25",vendor: "MediaSputnik"},
  {max_prefix: "00-26-26",vendor: "Geophysical Survey Systems, Inc."},
  {max_prefix: "00-26-1B",vendor: "LAUREL BANK MACHINES CO., LTD."},
  {max_prefix: "00-25-F9",vendor: "GMK electronic design GmbH"},
  {max_prefix: "00-25-F7",vendor: "Ansaldo STS USA"},
  {max_prefix: "00-26-03",vendor: "Shenzhen Wistar Technology Co., Ltd"},
  {max_prefix: "00-25-A6",vendor: "Central Network Solution Co., Ltd."},
  {max_prefix: "00-25-AA",vendor: "Beijing Soul Technology Co.,Ltd."},
  {max_prefix: "00-25-A3",vendor: "Trimax Wireless, Inc."},
  {max_prefix: "00-25-9C",vendor: "Cisco-Linksys, LLC"},
  {max_prefix: "00-25-A2",vendor: "Alta Definicion LINCEO S.L."},
  {max_prefix: "00-25-DA",vendor: "Secura Key"},
  {max_prefix: "00-25-DB",vendor: "ATI Electronics(Shenzhen) Co., LTD"},
  {max_prefix: "00-25-D5",vendor: "Robonica (Pty) Ltd"},
  {max_prefix: "00-25-CC",vendor: "Mobile Communications Korea Incorporated"},
  {max_prefix: "00-25-C8",vendor: "S-Access GmbH"},
  {max_prefix: "00-25-C0",vendor: "ZillionTV Corporation"},
  {max_prefix: "00-25-BD",vendor: "Italdata Ingegneria dell'Idea S.p.A."},
  {max_prefix: "00-25-B7",vendor: "Costar  electronics, inc.,"},
  {max_prefix: "00-25-7D",vendor: "PointRed Telecom Private Ltd."},
  {max_prefix: "00-25-88",vendor: "Genie Industries, Inc."},
  {max_prefix: "00-25-80",vendor: "Equipson S.A."},
  {max_prefix: "00-25-6D",vendor: "Broadband Forum"},
  {max_prefix: "00-25-6C",vendor: "Azimut Production Association JSC"},
  {max_prefix: "00-25-63",vendor: "Luxtera Inc"},
  {max_prefix: "00-25-93",vendor: "DatNet Informatikai Kft."},
  {max_prefix: "00-25-8E",vendor: "The Weather Channel"},
  {max_prefix: "00-25-0D",vendor: "GZT Telkom-Telmor sp. z o.o."},
  {max_prefix: "00-25-0E",vendor: "gt german telematics gmbh"},
  {max_prefix: "00-25-08",vendor: "Maquet Cardiopulmonary AG"},
  {max_prefix: "00-25-24",vendor: "Lightcomm Technology Co., Ltd"},
  {max_prefix: "00-25-22",vendor: "ASRock Incorporation"},
  {max_prefix: "00-25-1B",vendor: "Philips CareServant"},
  {max_prefix: "00-25-18",vendor: "Power PLUS Communications AG"},
  {max_prefix: "00-25-15",vendor: "SFR"},
  {max_prefix: "00-25-36",vendor: "Oki Electric Industry Co., Ltd."},
  {max_prefix: "00-25-41",vendor: "Maquet Critical Care AB"},
  {max_prefix: "00-25-4E",vendor: "Vertex Wireless Co., Ltd."},
  {max_prefix: "00-25-46",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-25-45",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-25-42",vendor: "Pittasoft"},
  {max_prefix: "00-25-31",vendor: "Cloud Engines, Inc."},
  {max_prefix: "00-25-2D",vendor: "Kiryung Electronics"},
  {max_prefix: "00-25-2B",vendor: "Stirling Energy Systems"},
  {max_prefix: "00-24-FC",vendor: "QuoPin Co., Ltd."},
  {max_prefix: "00-25-60",vendor: "Ibridge Networks & Communications Ltd."},
  {max_prefix: "00-24-B3",vendor: "Graf-Syteco GmbH & Co. KG"},
  {max_prefix: "00-24-AA",vendor: "Dycor Technologies Ltd."},
  {max_prefix: "00-24-D0",vendor: "Shenzhen SOGOOD Industry CO.,LTD."},
  {max_prefix: "00-24-CC",vendor: "Fascinations Toys and Gifts, Inc."},
  {max_prefix: "00-24-CB",vendor: "Autonet Mobile"},
  {max_prefix: "00-24-D1",vendor: "Thomson Inc."},
  {max_prefix: "00-24-C9",vendor: "Broadband Solutions Group"},
  {max_prefix: "00-24-CA",vendor: "Tobii Technology AB"},
  {max_prefix: "00-24-A9",vendor: "Ag Leader Technology"},
  {max_prefix: "00-24-A6",vendor: "TELESTAR DIGITAL GmbH"},
  {max_prefix: "00-24-A3",vendor: "Sonim Technologies Inc"},
  {max_prefix: "00-24-FB",vendor: "Private"},
  {max_prefix: "00-24-FA",vendor: "Hilger u. Kern GMBH"},
  {max_prefix: "00-24-F6",vendor: "MIYOSHI ELECTRONICS CORPORATION"},
  {max_prefix: "00-24-F0",vendor: "Seanodes"},
  {max_prefix: "00-24-C7",vendor: "Mobilarm Ltd"},
  {max_prefix: "00-24-B8",vendor: "free alliance sdn bhd"},
  {max_prefix: "00-24-9B",vendor: "Action Star Enterprise Co., Ltd."},
  {max_prefix: "00-24-97",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-24-DF",vendor: "Digitalbox Europe GmbH"},
  {max_prefix: "00-24-25",vendor: "Shenzhenshi chuangzhicheng Technology Co.,Ltd"},
  {max_prefix: "00-24-27",vendor: "SSI COMPUTER CORP"},
  {max_prefix: "00-24-1C",vendor: "FuGang Electronic (DG) Co.,Ltd"},
  {max_prefix: "00-24-19",vendor: "Private"},
  {max_prefix: "00-24-15",vendor: "Magnetic Autocontrol GmbH"},
  {max_prefix: "00-24-11",vendor: "PharmaSmart LLC"},
  {max_prefix: "00-24-0F",vendor: "Ishii Tool & Engineering Corporation"},
  {max_prefix: "00-24-3F",vendor: "Storwize, Inc."},
  {max_prefix: "00-24-40",vendor: "Halo Monitoring, Inc."},
  {max_prefix: "00-24-46",vendor: "MMB Research Inc."},
  {max_prefix: "00-24-45",vendor: "Adtran Inc"},
  {max_prefix: "00-24-72",vendor: "ReDriven Power Inc."},
  {max_prefix: "00-24-71",vendor: "Fusion MultiSystems dba Fusion-io"},
  {max_prefix: "00-24-74",vendor: "Autronica Fire And Securirty"},
  {max_prefix: "00-23-FA",vendor: "RG Nets, Inc."},
  {max_prefix: "00-23-F2",vendor: "TVLogic"},
  {max_prefix: "00-24-0A",vendor: "US Beverage Net"},
  {max_prefix: "00-24-07",vendor: "TELEM SAS"},
  {max_prefix: "00-24-63",vendor: "Phybridge Inc"},
  {max_prefix: "00-23-C2",vendor: "SAMSUNG Electronics. Co. LTD"},
  {max_prefix: "00-23-C4",vendor: "Lux Lumen"},
  {max_prefix: "00-23-C5",vendor: "Radiation Safety and Control Services Inc"},
  {max_prefix: "00-23-C6",vendor: "SMC Corporation"},
  {max_prefix: "00-23-B9",vendor: " Airbus Defence and Space Deutschland GmbH"},
  {max_prefix: "00-23-BD",vendor: "Digital Ally, Inc."},
  {max_prefix: "00-23-BF",vendor: "Mainpine, Inc."},
  {max_prefix: "00-23-B2",vendor: "Intelligent Mechatronic Systems Inc"},
  {max_prefix: "00-23-B5",vendor: "ORTANA LTD"},
  {max_prefix: "00-23-90",vendor: "Algolware Corporation"},
  {max_prefix: "00-23-88",vendor: "V.T. Telematica S.p.a."},
  {max_prefix: "00-23-86",vendor: "IMI Hydronic Engineering international SA"},
  {max_prefix: "00-23-83",vendor: "InMage Systems Inc"},
  {max_prefix: "00-23-81",vendor: "Lengda Technology(Xiamen) Co.,Ltd."},
  {max_prefix: "00-23-7B",vendor: "WHDI LLC"},
  {max_prefix: "00-23-24",vendor: "G-PRO COMPUTER"},
  {max_prefix: "00-23-E0",vendor: "INO Therapeutics LLC"},
  {max_prefix: "00-23-D5",vendor: " WAREMA Renkhoff SE"},
  {max_prefix: "00-23-E7",vendor: "Hinke A/S"},
  {max_prefix: "00-23-E6",vendor: "Innovation Farm, Inc."},
  {max_prefix: "00-23-E2",vendor: "SEA Signalisation"},
  {max_prefix: "00-23-C9",vendor: "Sichuan Tianyi Information Science & Technology Stock CO.,LTD"},
  {max_prefix: "00-23-CE",vendor: "KITA DENSHI CORPORATION"},
  {max_prefix: "00-23-CF",vendor: "CUMMINS-ALLISON CORP."},
  {max_prefix: "00-23-A9",vendor: "Beijing Detianquan Electromechanical Equipment Co., Ltd"},
  {max_prefix: "00-23-A7",vendor: "Redpine Signals, Inc."},
  {max_prefix: "00-23-9B",vendor: "Elster Solutions, LLC"},
  {max_prefix: "00-22-EE",vendor: "Algo Communication Products Ltd"},
  {max_prefix: "00-22-EA",vendor: "Rustelcom Inc."},
  {max_prefix: "00-22-F0",vendor: "3 Greens Aviation Limited"},
  {max_prefix: "00-22-EC",vendor: "IDEALBT TECHNOLOGY CORPORATION"},
  {max_prefix: "00-23-31",vendor: "Nintendo Co., Ltd."},
  {max_prefix: "00-23-35",vendor: "Linkflex Co.,Ltd"},
  {max_prefix: "00-23-2F",vendor: "Advanced Card Systems Ltd."},
  {max_prefix: "00-23-19",vendor: "Sielox LLC"},
  {max_prefix: "00-23-11",vendor: "Gloscom Co., Ltd."},
  {max_prefix: "00-23-5D",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-23-5C",vendor: "Aprius, Inc."},
  {max_prefix: "00-23-52",vendor: "DATASENSOR S.p.A."},
  {max_prefix: "00-23-09",vendor: "Janam Technologies LLC"},
  {max_prefix: "00-23-04",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-22-F9",vendor: "Pollin Electronic GmbH"},
  {max_prefix: "00-23-53",vendor: "F E T Elettronica snc"},
  {max_prefix: "00-23-42",vendor: "Coffee Equipment Company"},
  {max_prefix: "00-23-37",vendor: "Global Star Solutions ULC"},
  {max_prefix: "00-22-DD",vendor: "Protecta Electronics Ltd"},
  {max_prefix: "00-22-DB",vendor: "Translogic Corporation"},
  {max_prefix: "00-23-6A",vendor: "SmartRG Inc"},
  {max_prefix: "00-23-70",vendor: "Snell"},
  {max_prefix: "00-23-25",vendor: "IOLAN Holding"},
  {max_prefix: "00-23-21",vendor: "Avitech International Corp"},
  {max_prefix: "00-22-74",vendor: "FamilyPhone AB"},
  {max_prefix: "00-22-6A",vendor: "Honeywell"},
  {max_prefix: "00-22-62",vendor: "BEP Marine"},
  {max_prefix: "00-22-63",vendor: "Koos Technical Services, Inc."},
  {max_prefix: "00-22-6C",vendor: "LinkSprite Technologies, Inc."},
  {max_prefix: "00-22-5C",vendor: "Multimedia & Communication Technology"},
  {max_prefix: "00-22-B9",vendor: "Analogix Seminconductor, Inc"},
  {max_prefix: "00-22-B8",vendor: "Norcott"},
  {max_prefix: "00-22-B7",vendor: "GSS Grundig SAT-Systems GmbH"},
  {max_prefix: "00-22-B3",vendor: "Sei S.p.A."},
  {max_prefix: "00-22-AB",vendor: "Shenzhen Turbosight Technology Ltd"},
  {max_prefix: "00-22-9B",vendor: "AverLogic Technologies, Inc."},
  {max_prefix: "00-22-9C",vendor: "Verismo Networks Inc"},
  {max_prefix: "00-22-9A",vendor: "Lastar, Inc."},
  {max_prefix: "00-22-84",vendor: "DESAY A&V SCIENCE AND TECHNOLOGY CO.,LTD"},
  {max_prefix: "00-22-86",vendor: "ASTRON"},
  {max_prefix: "00-22-82",vendor: "8086 Consultancy"},
  {max_prefix: "00-22-76",vendor: "Triple EYE B.V."},
  {max_prefix: "00-22-54",vendor: "Bigelow Aerospace"},
  {max_prefix: "00-22-57",vendor: "3COM EUROPE LTD"},
  {max_prefix: "00-22-46",vendor: "Evoc Intelligent Technology Co.,Ltd."},
  {max_prefix: "00-22-48",vendor: "Microsoft Corporation"},
  {max_prefix: "00-22-C5",vendor: "INFORSON Co,Ltd."},
  {max_prefix: "00-22-BF",vendor: "SieAmp Group of Companies"},
  {max_prefix: "00-22-BE",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-22-90",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-22-8A",vendor: "Teratronik elektronische systeme gmbh"},
  {max_prefix: "00-22-8E",vendor: "TV-NUMERIC"},
  {max_prefix: "00-22-DA",vendor: "ANATEK, LLC"},
  {max_prefix: "00-22-14",vendor: "RINNAI KOREA"},
  {max_prefix: "00-22-0B",vendor: "National Source Coding Center"},
  {max_prefix: "00-22-0C",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-21-EA",vendor: "Bystronic Laser AG"},
  {max_prefix: "00-21-FD",vendor: "LACROIX TRAFFIC S.A.U"},
  {max_prefix: "00-22-28",vendor: "Breeze Innovations Ltd."},
  {max_prefix: "00-22-29",vendor: "Compumedics Ltd"},
  {max_prefix: "00-22-00",vendor: "IBM Corp"},
  {max_prefix: "00-22-30",vendor: "FutureLogic Inc."},
  {max_prefix: "00-22-2E",vendor: "maintech GmbH"},
  {max_prefix: "00-21-E1",vendor: "Nortel Networks"},
  {max_prefix: "00-21-CD",vendor: "LiveTV"},
  {max_prefix: "00-22-16",vendor: "SHIBAURA VENDING MACHINE CORPORATION"},
  {max_prefix: "00-21-6F",vendor: "SymCom, Inc."},
  {max_prefix: "00-21-66",vendor: "NovAtel Inc."},
  {max_prefix: "00-21-64",vendor: "Special Design Bureau for Seismic Instrumentation"},
  {max_prefix: "00-21-60",vendor: "Hidea Solutions Co. Ltd."},
  {max_prefix: "00-21-C0",vendor: "Mobile Appliance, Inc."},
  {max_prefix: "00-21-BB",vendor: "Riken Keiki Co., Ltd."},
  {max_prefix: "00-21-B1",vendor: "DIGITAL SOLUTIONS LTD"},
  {max_prefix: "00-21-B0",vendor: "Tyco Telecommunications"},
  {max_prefix: "00-21-52",vendor: "General Satellite Research & Development Limited"},
  {max_prefix: "00-21-58",vendor: "Style Flying Technology Co."},
  {max_prefix: "00-21-88",vendor: "EMC Corporation"},
  {max_prefix: "00-21-7F",vendor: "Intraco Technology Pte Ltd"},
  {max_prefix: "00-21-7D",vendor: "PYXIS S.R.L."},
  {max_prefix: "00-21-AD",vendor: "Nordic ID Oy"},
  {max_prefix: "00-21-A3",vendor: "Micromint"},
  {max_prefix: "00-21-A5",vendor: "ERLPhase Power Technologies Ltd."},
  {max_prefix: "00-21-C6",vendor: "CSJ Global, Inc."},
  {max_prefix: "00-21-C3",vendor: "CORNELL Communications, Inc."},
  {max_prefix: "00-21-C7",vendor: "Russound"},
  {max_prefix: "00-21-C1",vendor: "ABB Oy / Medium Voltage Products"},
  {max_prefix: "00-21-48",vendor: "Kaco Solar Korea"},
  {max_prefix: "00-21-9D",vendor: "Adesys BV"},
  {max_prefix: "00-21-95",vendor: "GWD Media Limited"},
  {max_prefix: "00-21-16",vendor: "Transcon Electronic Systems, spol. s r. o."},
  {max_prefix: "00-21-15",vendor: "PHYWE Systeme GmbH & Co. KG"},
  {max_prefix: "00-21-11",vendor: "Uniphone Inc."},
  {max_prefix: "00-21-14",vendor: "Hylab Technology Inc."},
  {max_prefix: "00-21-32",vendor: "Masterclock, Inc."},
  {max_prefix: "00-21-31",vendor: "Blynke Inc."},
  {max_prefix: "00-21-29",vendor: "Cisco-Linksys, LLC"},
  {max_prefix: "00-21-1D",vendor: "Dataline AB"},
  {max_prefix: "00-21-20",vendor: "Sequel Technologies"},
  {max_prefix: "00-21-1A",vendor: "LInTech Corporation"},
  {max_prefix: "00-21-41",vendor: "RADLIVE"},
  {max_prefix: "00-21-3D",vendor: "Cermetek Microelectronics, Inc."},
  {max_prefix: "00-21-40",vendor: "EN Technologies Inc."},
  {max_prefix: "00-21-3C",vendor: "AliphCom"},
  {max_prefix: "00-1F-E7",vendor: "Simet"},
  {max_prefix: "00-1F-DB",vendor: "Network Supply Corp.,"},
  {max_prefix: "00-1F-D4",vendor: "4IPNET, INC."},
  {max_prefix: "00-1F-CB",vendor: "NIW Solutions"},
  {max_prefix: "00-1F-F7",vendor: "Nakajima All Precision Co., Ltd."},
  {max_prefix: "00-1F-EB",vendor: "Trio Datacom Pty Ltd"},
  {max_prefix: "00-1F-B5",vendor: "I/O Interconnect Inc."},
  {max_prefix: "00-1F-AD",vendor: "Brown Innovations, Inc"},
  {max_prefix: "00-1F-BF",vendor: "Fulhua Microelectronics Corp. Taiwan Branch"},
  {max_prefix: "00-1F-BE",vendor: "Shenzhen Mopnet Industrial Co.,Ltd"},
  {max_prefix: "00-1F-C2",vendor: "Jow Tong Technology Co Ltd"},
  {max_prefix: "00-1F-9B",vendor: "POSBRO"},
  {max_prefix: "00-1F-97",vendor: "BERTANA srl"},
  {max_prefix: "00-1F-8C",vendor: "CCS Inc."},
  {max_prefix: "00-1F-81",vendor: "Accel Semiconductor Corp"},
  {max_prefix: "00-1F-83",vendor: "Teleplan Technology Services Sdn Bhd"},
  {max_prefix: "00-1F-68",vendor: "Martinsson Elektronik AB"},
  {max_prefix: "00-1F-63",vendor: "JSC Goodwin-Europa"},
  {max_prefix: "00-1F-69",vendor: "Pingood Technology Co., Ltd."},
  {max_prefix: "00-1F-D2",vendor: "COMMTECH TECHNOLOGY MACAO COMMERCIAL OFFSHORE LTD."},
  {max_prefix: "00-1F-78",vendor: "Blue Fox Porini Textile"},
  {max_prefix: "00-1F-4D",vendor: "Segnetics LLC"},
  {max_prefix: "00-1F-A6",vendor: "Stilo srl"},
  {max_prefix: "00-1F-13",vendor: "S.& A.S. Ltd."},
  {max_prefix: "00-1F-10",vendor: "TOLEDO DO BRASIL INDUSTRIA DE BALANCAS  LTDA"},
  {max_prefix: "00-1F-0F",vendor: "Select Engineered Systems"},
  {max_prefix: "00-1F-02",vendor: "Pixelmetrix Corporation Pte Ltd"},
  {max_prefix: "00-1E-FE",vendor: "LEVEL s.r.o."},
  {max_prefix: "00-1F-34",vendor: "Lung Hwa Electronics Co., Ltd."},
  {max_prefix: "00-1F-25",vendor: "MBS GmbH"},
  {max_prefix: "00-1F-27",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-1E-DB",vendor: "Giken Trastem Co., Ltd."},
  {max_prefix: "00-1E-D1",vendor: "Keyprocessor B.V."},
  {max_prefix: "00-1E-D0",vendor: "Ingespace"},
  {max_prefix: "00-1E-EF",vendor: "Cantronic International Limited"},
  {max_prefix: "00-1E-DE",vendor: "BYD COMPANY LIMITED"},
  {max_prefix: "00-1E-DD",vendor: "WASKO S.A."},
  {max_prefix: "00-1F-42",vendor: "Etherstack plc"},
  {max_prefix: "00-1F-35",vendor: "AIR802 LLC"},
  {max_prefix: "00-1F-26",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-1F-1A",vendor: "Prominvest"},
  {max_prefix: "00-1F-18",vendor: "Hakusan.Mfg.Co,.Ltd"},
  {max_prefix: "00-1E-CD",vendor: "KYLAND Technology Co. LTD"},
  {max_prefix: "00-1E-BF",vendor: "Haas Automation Inc."},
  {max_prefix: "00-1E-EB",vendor: "Talk-A-Phone Co."},
  {max_prefix: "00-1E-BC",vendor: "WINTECH AUTOMATION CO.,LTD."},
  {max_prefix: "00-1E-B1",vendor: "Cryptsoft Pty Ltd"},
  {max_prefix: "00-1E-AF",vendor: "Ophir Optronics Ltd"},
  {max_prefix: "00-1E-AD",vendor: "Wingtech Group Limited"},
  {max_prefix: "00-1E-32",vendor: "Zensys"},
  {max_prefix: "00-1E-35",vendor: "Nintendo Co., Ltd."},
  {max_prefix: "00-1E-2B",vendor: "Radio Systems Design, Inc."},
  {max_prefix: "00-1E-28",vendor: "Lumexis Corporation"},
  {max_prefix: "00-1E-43",vendor: "AISIN CORPORATION"},
  {max_prefix: "00-1E-6F",vendor: "Magna-Power Electronics, Inc."},
  {max_prefix: "00-1E-6A",vendor: "Beijing Bluexon Technology Co.,Ltd"},
  {max_prefix: "00-1E-66",vendor: "RESOL Elektronische Regelungen GmbH"},
  {max_prefix: "00-1E-63",vendor: "Vibro-Meter SA"},
  {max_prefix: "00-1E-53",vendor: "Further Tech Co., LTD"},
  {max_prefix: "00-1E-4E",vendor: "DAKO EDV-Ingenieur- und Systemhaus GmbH"},
  {max_prefix: "00-1E-49",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-1E-42",vendor: "Teltonika"},
  {max_prefix: "00-1E-87",vendor: "Realease Limited"},
  {max_prefix: "00-1E-7F",vendor: "CBM of America"},
  {max_prefix: "00-1E-82",vendor: "SanDisk Corporation"},
  {max_prefix: "00-1E-8E",vendor: "Hunkeler AG"},
  {max_prefix: "00-1E-A1",vendor: "Brunata a/s"},
  {max_prefix: "00-1D-B9",vendor: "Wellspring Wireless"},
  {max_prefix: "00-1D-BB",vendor: "Dynamic System Electronics Corp."},
  {max_prefix: "00-1D-B3",vendor: "HPN Supply Chain"},
  {max_prefix: "00-1D-B1",vendor: "Crescendo Networks"},
  {max_prefix: "00-1D-B4",vendor: "KUMHO ENG CO.,LTD"},
  {max_prefix: "00-1E-24",vendor: "Zhejiang Bell Technology Co.,ltd"},
  {max_prefix: "00-1E-20",vendor: "Intertain Inc."},
  {max_prefix: "00-1E-1C",vendor: "SWS Australia Pty Limited"},
  {max_prefix: "00-1E-12",vendor: "Ecolab"},
  {max_prefix: "00-1D-DB",vendor: "C-BEL Corporation"},
  {max_prefix: "00-1D-E6",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-1D-E7",vendor: "Marine Sonic Technology, Ltd."},
  {max_prefix: "00-1E-16",vendor: "Keytronix"},
  {max_prefix: "00-1E-08",vendor: "Centec Networks Inc"},
  {max_prefix: "00-1D-C5",vendor: "Beijing Jiaxun Feihong Electricial Co., Ltd."},
  {max_prefix: "00-1D-C6",vendor: "SNR Inc."},
  {max_prefix: "00-1D-A4",vendor: "Hangzhou System Technology CO., LTD"},
  {max_prefix: "00-1D-9F",vendor: "MATT   R.P.Traczynscy Sp.J."},
  {max_prefix: "00-1D-BF",vendor: "Radiient Technologies, Inc."},
  {max_prefix: "00-1D-FB",vendor: "NETCLEUS Systems Corporation"},
  {max_prefix: "00-1D-3E",vendor: "SAKA TECHNO SCIENCE CO.,LTD"},
  {max_prefix: "00-1D-40",vendor: " Intel \u2013 GE Care Innovations LLC"},
  {max_prefix: "00-1D-34",vendor: "SYRIS Technology Corp"},
  {max_prefix: "00-1D-32",vendor: "Longkay Communication & Technology (Shanghai) Co. Ltd"},
  {max_prefix: "00-1D-2A",vendor: "SHENZHEN BUL-TECH CO.,LTD."},
  {max_prefix: "00-1D-2D",vendor: "Pylone, Inc."},
  {max_prefix: "00-1D-90",vendor: "EMCO Flow Systems"},
  {max_prefix: "00-1D-93",vendor: "Modacom"},
  {max_prefix: "00-1D-94",vendor: "Climax Technology Co., Ltd"},
  {max_prefix: "00-1D-8E",vendor: "Alereon, Inc."},
  {max_prefix: "00-1D-5D",vendor: "Control Dynamics Pty. Ltd."},
  {max_prefix: "00-1D-59",vendor: "Mitra Energy & Infrastructure"},
  {max_prefix: "00-1D-57",vendor: "CAETEC Messtechnik"},
  {max_prefix: "00-1D-51",vendor: "Babcock & Wilcox Power Generation Group, Inc"},
  {max_prefix: "00-1D-2B",vendor: "Wuhan Pont Technology CO. , LTD"},
  {max_prefix: "00-1D-22",vendor: "Foss Analytical A/S"},
  {max_prefix: "00-1D-23",vendor: "SENSUS"},
  {max_prefix: "00-1D-7B",vendor: "Ice Energy, Inc."},
  {max_prefix: "00-1D-6C",vendor: "ClariPhy Communications, Inc."},
  {max_prefix: "00-1D-4C",vendor: "Alcatel-Lucent"},
  {max_prefix: "00-1D-84",vendor: "Gateway, Inc."},
  {max_prefix: "00-1D-85",vendor: "Call Direct Cellular Solutions"},
  {max_prefix: "00-1D-1A",vendor: "OvisLink S.A."},
  {max_prefix: "00-1D-0B",vendor: "Power Standards Lab"},
  {max_prefix: "00-1D-02",vendor: "Cybertech Telecom Development"},
  {max_prefix: "00-1C-FE",vendor: "Quartics Inc"},
  {max_prefix: "00-1C-81",vendor: "NextGen Venturi LTD"},
  {max_prefix: "00-1C-7A",vendor: "Perfectone Netware Company Ltd"},
  {max_prefix: "00-1C-75",vendor: "Segnet Ltd."},
  {max_prefix: "00-1C-74",vendor: "Syswan Technologies Inc."},
  {max_prefix: "00-1C-B7",vendor: "USC DigiArk Corporation"},
  {max_prefix: "00-1C-B1",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-1C-AF",vendor: "Plato Networks Inc."},
  {max_prefix: "00-1C-E9",vendor: "Galaxy Technology Limited"},
  {max_prefix: "00-1C-EA",vendor: "Scientific-Atlanta, Inc"},
  {max_prefix: "00-1C-CB",vendor: "Forth Corporation Public Company Limited"},
  {max_prefix: "00-1C-BC",vendor: "CastGrabber, LLC"},
  {max_prefix: "00-1C-DB",vendor: "CARPOINT CO.,LTD"},
  {max_prefix: "00-1C-D5",vendor: "ZeeVee, Inc."},
  {max_prefix: "00-1C-D3",vendor: "ZP Engineering SEL"},
  {max_prefix: "00-1C-E7",vendor: "Rocon PLC Research Centre"},
  {max_prefix: "00-1C-E4",vendor: "EleSy JSC"},
  {max_prefix: "00-1C-E2",vendor: "Attero Tech, LLC."},
  {max_prefix: "00-1C-AA",vendor: "Bellon Pty Ltd"},
  {max_prefix: "00-1C-A0",vendor: "Production Resource Group, LLC"},
  {max_prefix: "00-1C-98",vendor: "LUCKY TECHNOLOGY (HK) COMPANY LIMITED"},
  {max_prefix: "00-1C-91",vendor: "Gefen LLC"},
  {max_prefix: "00-1C-92",vendor: "Tervela"},
  {max_prefix: "00-1C-8A",vendor: "Cirrascale Corporation"},
  {max_prefix: "00-1C-02",vendor: "Pano Logic"},
  {max_prefix: "00-1C-05",vendor: "Nonin Medical Inc."},
  {max_prefix: "00-1C-06",vendor: "Siemens Numerical Control Ltd., Nanjing"},
  {max_prefix: "00-1C-04",vendor: "Airgain, Inc."},
  {max_prefix: "00-1C-01",vendor: "ABB Oy Drives"},
  {max_prefix: "00-1B-FF",vendor: "Millennia Media inc."},
  {max_prefix: "00-1B-EA",vendor: "Nintendo Co., Ltd."},
  {max_prefix: "00-1B-E5",vendor: "802automation Limited"},
  {max_prefix: "00-1B-E4",vendor: "TOWNET SRL"},
  {max_prefix: "00-1C-68",vendor: "Anhui Sun Create Electronics Co., Ltd"},
  {max_prefix: "00-1C-66",vendor: "UCAMP CO.,LTD"},
  {max_prefix: "00-1C-2A",vendor: "Envisacor Technologies Inc."},
  {max_prefix: "00-1B-F2",vendor: "KWORLD COMPUTER CO., LTD"},
  {max_prefix: "00-1B-F0",vendor: "Value Platforms Limited"},
  {max_prefix: "00-1C-4D",vendor: "Aplix IP Holdings Corporation"},
  {max_prefix: "00-1C-38",vendor: "Bio-Rad Laboratories, Inc."},
  {max_prefix: "00-1C-30",vendor: "Mode Lighting (UK ) Ltd."},
  {max_prefix: "00-1C-2E",vendor: "HPN Supply Chain"},
  {max_prefix: "00-1C-53",vendor: "Synergy Lighting Controls"},
  {max_prefix: "00-1C-1B",vendor: "Hyperstone GmbH"},
  {max_prefix: "00-1C-10",vendor: "Cisco-Linksys, LLC"},
  {max_prefix: "00-1B-C6",vendor: "Strato Rechenzentrum AG"},
  {max_prefix: "00-1B-C4",vendor: "Ultratec, Inc."},
  {max_prefix: "00-1B-C2",vendor: "Integrated Control Technology Limitied"},
  {max_prefix: "00-1B-BB",vendor: "RFTech Co.,Ltd"},
  {max_prefix: "00-1B-5D",vendor: "Vololink Pty Ltd"},
  {max_prefix: "00-1B-5A",vendor: "Apollo Imaging Technologies, Inc."},
  {max_prefix: "00-1B-56",vendor: "Tehuti Networks Ltd."},
  {max_prefix: "00-1B-86",vendor: "Bosch Access Systems GmbH"},
  {max_prefix: "00-1B-7C",vendor: "A & R Cambridge"},
  {max_prefix: "00-1B-D2",vendor: "ULTRA-X ASIA PACIFIC Inc."},
  {max_prefix: "00-1B-8D",vendor: "Electronic Computer Systems, Inc."},
  {max_prefix: "00-1B-AA",vendor: "XenICs nv"},
  {max_prefix: "00-1B-A1",vendor: "\u00c5mic AB"},
  {max_prefix: "00-1B-96",vendor: "General Sensing"},
  {max_prefix: "00-1A-D5",vendor: "KMC CHAIN INDUSTRIAL CO., LTD."},
  {max_prefix: "00-1A-D0",vendor: "Albis Technologies AG"},
  {max_prefix: "00-1A-D3",vendor: "Vamp Ltd."},
  {max_prefix: "00-1A-D8",vendor: "AlsterAero GmbH"},
  {max_prefix: "00-1A-DA",vendor: "Biz-2-Me Inc."},
  {max_prefix: "00-1A-CB",vendor: "Autocom Products Ltd"},
  {max_prefix: "00-1A-CF",vendor: "C.T. ELETTRONICA"},
  {max_prefix: "00-1B-15",vendor: "Voxtel, Inc."},
  {max_prefix: "00-1A-EA",vendor: "Radio Terminal Systems Pty Ltd"},
  {max_prefix: "00-1A-DD",vendor: "PePWave Ltd"},
  {max_prefix: "00-1A-D4",vendor: "iPOX Technology Co., Ltd."},
  {max_prefix: "00-1A-D6",vendor: "JIAGNSU AETNA ELECTRIC CO.,LTD"},
  {max_prefix: "00-1A-FB",vendor: "Joby Inc."},
  {max_prefix: "00-1A-FD",vendor: "EVOLIS"},
  {max_prefix: "00-1B-03",vendor: "Action Technology (SZ) Co., Ltd"},
  {max_prefix: "00-1B-1E",vendor: "HART Communication Foundation"},
  {max_prefix: "00-1B-14",vendor: "Carex Lighting Equipment Factory"},
  {max_prefix: "00-1B-4C",vendor: "Signtech"},
  {max_prefix: "00-1A-6F",vendor: "MI.TEL s.r.l."},
  {max_prefix: "00-1A-71",vendor: "Diostech Co., Ltd."},
  {max_prefix: "00-1A-69",vendor: "Wuhan Yangtze Optical Technology CO.,Ltd."},
  {max_prefix: "00-1A-67",vendor: "Infinite QL Sdn Bhd"},
  {max_prefix: "00-1A-B8",vendor: "Anseri Corporation"},
  {max_prefix: "00-1A-BC",vendor: "U4EA Technologies Ltd"},
  {max_prefix: "00-1A-A3",vendor: "DELORME"},
  {max_prefix: "00-1A-9B",vendor: "ADEC & Parter AG"},
  {max_prefix: "00-1A-9D",vendor: "Skipper Wireless, Inc."},
  {max_prefix: "00-1A-C3",vendor: "Scientific-Atlanta, Inc"},
  {max_prefix: "00-1A-BF",vendor: "TRUMPF Laser Marking Systems AG"},
  {max_prefix: "00-1A-8E",vendor: "3Way Networks Ltd"},
  {max_prefix: "00-1A-85",vendor: "NV Michel Van de Wiele"},
  {max_prefix: "00-1A-64",vendor: "IBM Corp"},
  {max_prefix: "00-1A-55",vendor: "ACA-Digital Corporation"},
  {max_prefix: "00-1A-1C",vendor: "GT&T Engineering Pte Ltd"},
  {max_prefix: "00-1A-1F",vendor: "Coastal Environmental Systems"},
  {max_prefix: "00-1A-23",vendor: "Ice Qube, Inc"},
  {max_prefix: "00-1A-1D",vendor: "PChome Online Inc."},
  {max_prefix: "00-1A-17",vendor: "Teak Technologies, Inc."},
  {max_prefix: "00-1A-51",vendor: "Alfred Mann Foundation"},
  {max_prefix: "00-1A-44",vendor: "JWTrading Co., Ltd"},
  {max_prefix: "00-19-E6",vendor: "TOYO MEDIC CO.,LTD."},
  {max_prefix: "00-19-E8",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-19-DF",vendor: "Thomson Inc."},
  {max_prefix: "00-19-DD",vendor: "FEI-Zyfer, Inc."},
  {max_prefix: "00-1A-05",vendor: "OPTIBASE LTD"},
  {max_prefix: "00-19-FC",vendor: "PT. Ufoakses Sukses Luarbiasa"},
  {max_prefix: "00-19-D4",vendor: "ICX Technologies"},
  {max_prefix: "00-19-CF",vendor: "SALICRU, S.A."},
  {max_prefix: "00-1A-49",vendor: "Micro Vision Co.,LTD"},
  {max_prefix: "00-1A-3D",vendor: "Ajin Vision Co.,Ltd"},
  {max_prefix: "00-1A-41",vendor: "INOCOVA Co.,Ltd"},
  {max_prefix: "00-1A-0A",vendor: "Adaptive Micro-Ware Inc."},
  {max_prefix: "00-1A-33",vendor: "ASI Communications, Inc."},
  {max_prefix: "00-19-B2",vendor: "XYnetsoft Co.,Ltd"},
  {max_prefix: "00-19-A4",vendor: "Austar Technology (hang zhou) Co.,Ltd"},
  {max_prefix: "00-19-AA",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-19-B1",vendor: "Arrow7 Corporation"},
  {max_prefix: "00-19-96",vendor: "TurboChef Technologies Inc."},
  {max_prefix: "00-19-97",vendor: "Soft Device Sdn Bhd"},
  {max_prefix: "00-19-98",vendor: "SATO CORPORATION"},
  {max_prefix: "00-19-9C",vendor: "CTRING"},
  {max_prefix: "00-19-52",vendor: "ACOGITO Co., Ltd"},
  {max_prefix: "00-19-46",vendor: "Cianet Industria e Comercio S/A"},
  {max_prefix: "00-19-49",vendor: "TENTEL  COMTECH CO., LTD."},
  {max_prefix: "00-19-67",vendor: "TELDAT Sp.J."},
  {max_prefix: "00-19-71",vendor: "Guangzhou Unicomp Technology Co.,Ltd"},
  {max_prefix: "00-19-64",vendor: "Doorking Inc."},
  {max_prefix: "00-19-76",vendor: "Xipher Technologies, LLC"},
  {max_prefix: "00-19-6C",vendor: "ETROVISION TECHNOLOGY"},
  {max_prefix: "00-19-B3",vendor: "Stanford Research Systems"},
  {max_prefix: "00-19-8B",vendor: "Novera Optics Korea, Inc."},
  {max_prefix: "00-19-61",vendor: "Blaupunkt  Embedded Systems GmbH"},
  {max_prefix: "00-19-44",vendor: "Fossil Partners, L.P."},
  {max_prefix: "00-19-42",vendor: "ON SOFTWARE INTERNATIONAL LIMITED"},
  {max_prefix: "00-19-3F",vendor: "RDI technology(Shenzhen) Co.,LTD"},
  {max_prefix: "00-19-41",vendor: "Pitney Bowes, Inc"},
  {max_prefix: "00-19-2D",vendor: "Nokia Corporation"},
  {max_prefix: "00-19-30",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-19-27",vendor: "ImCoSys Ltd"},
  {max_prefix: "00-19-08",vendor: "Duaxes Corporation"},
  {max_prefix: "00-19-0C",vendor: "Encore Electronics, Inc."},
  {max_prefix: "00-18-FD",vendor: "Optimal Technologies International Inc."},
  {max_prefix: "00-18-F0",vendor: "JOYTOTO Co., Ltd."},
  {max_prefix: "00-18-E9",vendor: "Numata Corporation"},
  {max_prefix: "00-18-EF",vendor: "Escape Communications, Inc."},
  {max_prefix: "00-18-E6",vendor: "Computer Hardware Design SIA"},
  {max_prefix: "00-18-DB",vendor: "EPL Technology Ltd"},
  {max_prefix: "00-18-D6",vendor: "Swirlnet A/S"},
  {max_prefix: "00-18-CD",vendor: "Erae Electronics Industry Co., Ltd"},
  {max_prefix: "00-18-C8",vendor: "ISONAS Inc."},
  {max_prefix: "00-18-BE",vendor: "ANSA Corporation"},
  {max_prefix: "00-18-BA",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-19-19",vendor: "ASTEL Inc."},
  {max_prefix: "00-18-F7",vendor: "Kameleon Technologies"},
  {max_prefix: "00-18-9D",vendor: "Navcast Inc."},
  {max_prefix: "00-18-85",vendor: "Motorola Solutions Inc."},
  {max_prefix: "00-18-88",vendor: "GOTIVE a.s."},
  {max_prefix: "00-18-8A",vendor: "Infinova LLC"},
  {max_prefix: "00-18-49",vendor: "nVent, Schroff GmbH"},
  {max_prefix: "00-18-46",vendor: "Crypto S.A."},
  {max_prefix: "00-18-45",vendor: "Pulsar-Telecom LLC."},
  {max_prefix: "00-18-3D",vendor: "Vertex Link Corporation"},
  {max_prefix: "00-18-A3",vendor: "ZIPPY TECHNOLOGY CORP."},
  {max_prefix: "00-18-93",vendor: "SHENZHEN PHOTON BROADBAND TECHNOLOGY CO.,LTD"},
  {max_prefix: "00-18-A0",vendor: "Cierma Ascenseurs"},
  {max_prefix: "00-18-63",vendor: "Veritech Electronics Limited"},
  {max_prefix: "00-18-5A",vendor: "uControl, Inc."},
  {max_prefix: "00-18-B4",vendor: "Dawon Media Inc."},
  {max_prefix: "00-18-B6",vendor: "S3C, Inc."},
  {max_prefix: "00-18-B1",vendor: "IBM Corp"},
  {max_prefix: "00-18-86",vendor: "EL-TECH, INC."},
  {max_prefix: "00-18-87",vendor: "Metasystem SpA"},
  {max_prefix: "00-18-7B",vendor: "4NSYS Co. Ltd."},
  {max_prefix: "00-18-7E",vendor: "RGB Spectrum"},
  {max_prefix: "00-18-52",vendor: "StorLink Semiconductors, Inc."},
  {max_prefix: "00-18-50",vendor: "Secfone Kft"},
  {max_prefix: "00-18-58",vendor: "TagMaster AB"},
  {max_prefix: "00-18-25",vendor: "Private"},
  {max_prefix: "00-18-79",vendor: "dSys"},
  {max_prefix: "00-17-CE",vendor: "Screen Service Spa"},
  {max_prefix: "00-17-CD",vendor: "CEC Wireless R&D Ltd."},
  {max_prefix: "00-17-D0",vendor: "Opticom Communications, LLC"},
  {max_prefix: "00-17-EF",vendor: "IBM Corp"},
  {max_prefix: "00-17-F5",vendor: "LIG NEOPTEK"},
  {max_prefix: "00-17-FE",vendor: "TALOS SYSTEM INC."},
  {max_prefix: "00-17-F8",vendor: "Motech Industries Inc."},
  {max_prefix: "00-17-DB",vendor: "CANKO TECHNOLOGIES INC."},
  {max_prefix: "00-17-D6",vendor: "Bluechips Microhouse Co.,Ltd."},
  {max_prefix: "00-18-1E",vendor: "GDX Technologies Ltd."},
  {max_prefix: "00-17-C6",vendor: "Cross Match Technologies Inc"},
  {max_prefix: "00-17-C3",vendor: "KTF Technologies Inc."},
  {max_prefix: "00-17-B7",vendor: "Tonze Technology Co."},
  {max_prefix: "00-18-07",vendor: "Fanstel Corp."},
  {max_prefix: "00-18-08",vendor: "SightLogix, Inc."},
  {max_prefix: "00-18-03",vendor: "ArcSoft Shanghai Co. LTD"},
  {max_prefix: "00-17-AB",vendor: "Nintendo Co., Ltd."},
  {max_prefix: "00-18-24",vendor: "Kimaldi Electronics, S.L."},
  {max_prefix: "00-17-98",vendor: "Azonic Technology Co., LTD"},
  {max_prefix: "00-17-8F",vendor: "NINGBO YIDONG ELECTRONIC CO.,LTD."},
  {max_prefix: "00-17-8A",vendor: "DARTS TECHNOLOGIES CORP."},
  {max_prefix: "00-17-21",vendor: "FITRE S.p.A."},
  {max_prefix: "00-17-20",vendor: "Image Sensing Systems, Inc."},
  {max_prefix: "00-17-1A",vendor: "Winegard Company"},
  {max_prefix: "00-17-89",vendor: "Zenitron Corporation"},
  {max_prefix: "00-17-87",vendor: "Brother, Brother & Sons ApS"},
  {max_prefix: "00-17-60",vendor: "Naito Densei Machida MFG.CO.,LTD"},
  {max_prefix: "00-17-61",vendor: "Private"},
  {max_prefix: "00-17-68",vendor: "Zinwave Ltd"},
  {max_prefix: "00-17-69",vendor: "Cymphonix Corp"},
  {max_prefix: "00-17-62",vendor: "Solar Technology, Inc."},
  {max_prefix: "00-17-3E",vendor: "LeucotronEquipamentos Ltda."},
  {max_prefix: "00-17-34",vendor: "ADC Telecommunications"},
  {max_prefix: "00-17-2E",vendor: "FXC Inc."},
  {max_prefix: "00-17-2B",vendor: "Global Technologies Inc."},
  {max_prefix: "00-17-26",vendor: "m2c Electronic Technology Ltd."},
  {max_prefix: "00-17-72",vendor: "ASTRO Strobel Kommunikationssysteme GmbH"},
  {max_prefix: "00-17-7A",vendor: "ASSA ABLOY AB"},
  {max_prefix: "00-17-1C",vendor: "NT MicroSystems, Inc."},
  {max_prefix: "00-17-16",vendor: "Qno Technology Inc."},
  {max_prefix: "00-17-47",vendor: "Trimble"},
  {max_prefix: "00-16-BD",vendor: "ATI Industrial Automation"},
  {max_prefix: "00-16-C0",vendor: "Semtech Corporation"},
  {max_prefix: "00-16-C2",vendor: "Avtec Systems Inc"},
  {max_prefix: "00-16-F1",vendor: "OmniSense, LLC"},
  {max_prefix: "00-16-F4",vendor: "Eidicom Co., Ltd."},
  {max_prefix: "00-16-E7",vendor: "Dynamix Promotions Limited"},
  {max_prefix: "00-16-BA",vendor: "WEATHERNEWS INC."},
  {max_prefix: "00-16-B2",vendor: "DriveCam Inc"},
  {max_prefix: "00-16-B3",vendor: "Photonicbridges (China) Co., Ltd."},
  {max_prefix: "00-16-AD",vendor: "BT-Links Company Limited"},
  {max_prefix: "00-16-E5",vendor: "FORDLEY DEVELOPMENT LIMITED"},
  {max_prefix: "00-16-DD",vendor: "Gigabeam Corporation"},
  {max_prefix: "00-16-C8",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-16-C4",vendor: "SiRF Technology, Inc."},
  {max_prefix: "00-16-F3",vendor: "CAST Information Co., Ltd"},
  {max_prefix: "00-16-F5",vendor: "Dalian Golden Hualu Digital Technology Co.,Ltd"},
  {max_prefix: "00-16-F9",vendor: "CETRTA POT, d.o.o., Kranj"},
  {max_prefix: "00-17-0A",vendor: "INEW DIGITAL COMPANY"},
  {max_prefix: "00-16-2A",vendor: "Antik computers & communications s.r.o."},
  {max_prefix: "00-16-29",vendor: "Nivus GmbH"},
  {max_prefix: "00-16-21",vendor: "Colorado Vnet"},
  {max_prefix: "00-16-1A",vendor: "Dametric AB"},
  {max_prefix: "00-16-31",vendor: "Xteam"},
  {max_prefix: "00-16-2E",vendor: "Space Shuttle Hi-Tech Co., Ltd."},
  {max_prefix: "00-16-6E",vendor: "Arbitron Inc."},
  {max_prefix: "00-16-6A",vendor: "TPS"},
  {max_prefix: "00-16-63",vendor: "KBT Mobile"},
  {max_prefix: "00-16-54",vendor: "Flex-P Industries Sdn. Bhd."},
  {max_prefix: "00-16-56",vendor: "Nintendo Co., Ltd."},
  {max_prefix: "00-16-51",vendor: "Exeo Systems"},
  {max_prefix: "00-16-4B",vendor: "Quorion Data Systems GmbH"},
  {max_prefix: "00-16-88",vendor: "ServerEngines LLC"},
  {max_prefix: "00-16-8B",vendor: "Paralan Corporation"},
  {max_prefix: "00-16-82",vendor: "OMS Motion"},
  {max_prefix: "00-16-9F",vendor: "Vimtron Electronics Co., Ltd."},
  {max_prefix: "00-16-9A",vendor: "Quadrics Ltd"},
  {max_prefix: "00-16-92",vendor: "Scientific-Atlanta, Inc."},
  {max_prefix: "00-16-91",vendor: "Moser-Baer AG"},
  {max_prefix: "00-16-AF",vendor: "Shenzhen Union Networks Equipment Co.,Ltd."},
  {max_prefix: "00-16-9E",vendor: "TV One Ltd"},
  {max_prefix: "00-15-ED",vendor: "Fulcrum Microsystems, Inc."},
  {max_prefix: "00-15-F0",vendor: "EGO BV"},
  {max_prefix: "00-15-EE",vendor: "Omnex Control Systems"},
  {max_prefix: "00-16-0A",vendor: "SWEEX Europe BV"},
  {max_prefix: "00-16-02",vendor: "CEYON TECHNOLOGY CO.,LTD."},
  {max_prefix: "00-16-00",vendor: "CelleBrite Mobile Synchronization"},
  {max_prefix: "00-15-F4",vendor: "Eventide"},
  {max_prefix: "00-15-C0",vendor: "DIGITAL TELEMEDIA CO.,LTD."},
  {max_prefix: "00-15-C2",vendor: "3M Germany"},
  {max_prefix: "00-15-B2",vendor: "Advanced Industrial Computer, Inc."},
  {max_prefix: "00-16-15",vendor: "Nittan Company, Limited"},
  {max_prefix: "00-16-17",vendor: "MSI"},
  {max_prefix: "00-15-94",vendor: "BIXOLON CO.,LTD"},
  {max_prefix: "00-15-90",vendor: "Hectronic GmbH"},
  {max_prefix: "00-16-10",vendor: "Carina Technology"},
  {max_prefix: "00-16-06",vendor: "Ideal Industries"},
  {max_prefix: "00-16-07",vendor: "Curves International Inc."},
  {max_prefix: "00-15-A5",vendor: "DCI Co., Ltd."},
  {max_prefix: "00-15-C8",vendor: "FlexiPanel Ltd"},
  {max_prefix: "00-15-DA",vendor: "IRITEL A.D."},
  {max_prefix: "00-15-4A",vendor: "WANSHIH ELECTRONIC CO., LTD"},
  {max_prefix: "00-15-4C",vendor: "Saunders Electronics"},
  {max_prefix: "00-15-4D",vendor: "Netronome Systems, Inc."},
  {max_prefix: "00-15-49",vendor: "Dixtal Biomedica Ind. Com. Ltda"},
  {max_prefix: "00-15-11",vendor: "Data Center Systems"},
  {max_prefix: "00-15-0E",vendor: "OPENBRAIN TECHNOLOGIES CO., LTD."},
  {max_prefix: "00-15-0D",vendor: "Hoana Medical, Inc."},
  {max_prefix: "00-15-06",vendor: "Neo Photonics"},
  {max_prefix: "00-15-04",vendor: "GAME PLUS CO., LTD."},
  {max_prefix: "00-15-05",vendor: "Actiontec Electronics, Inc"},
  {max_prefix: "00-15-8C",vendor: "Liab ApS"},
  {max_prefix: "00-15-8F",vendor: "NTT Advanced Technology Corporation"},
  {max_prefix: "00-15-83",vendor: "IVT corporation"},
  {max_prefix: "00-15-88",vendor: "Salutica Allied Solutions Sdn Bhd"},
  {max_prefix: "00-15-85",vendor: "Aonvision Technolopy Corp."},
  {max_prefix: "00-15-2B",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-15-2C",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-15-28",vendor: "Beacon Medical Products LLC d.b.a. BeaconMedaes"},
  {max_prefix: "00-15-27",vendor: "Balboa Instruments"},
  {max_prefix: "00-15-21",vendor: "Horoquartz"},
  {max_prefix: "00-15-20",vendor: "Radiocrafts AS"},
  {max_prefix: "00-15-39",vendor: "Technodrive srl"},
  {max_prefix: "00-15-31",vendor: "KOCOM"},
  {max_prefix: "00-15-35",vendor: "OTE Spa"},
  {max_prefix: "00-15-36",vendor: "Powertech co.,Ltd"},
  {max_prefix: "00-15-1C",vendor: "LENECO"},
  {max_prefix: "00-15-19",vendor: "StoreAge Networking Technologies"},
  {max_prefix: "00-15-47",vendor: "AiZen Solutions Inc."},
  {max_prefix: "00-15-3D",vendor: "ELIM PRODUCT CO."},
  {max_prefix: "00-15-44",vendor: "coM.s.a.t. AG"},
  {max_prefix: "00-15-6B",vendor: "Perfisans Networks Corp."},
  {max_prefix: "00-15-66",vendor: "A-First Technology Co., Ltd."},
  {max_prefix: "00-15-79",vendor: "Lunatone Industrielle Elektronik GmbH"},
  {max_prefix: "00-14-CF",vendor: "INVISIO Communications"},
  {max_prefix: "00-14-BE",vendor: "Wink communication technology CO.LTD"},
  {max_prefix: "00-14-B2",vendor: "mCubelogics Corporation"},
  {max_prefix: "00-14-AE",vendor: "Wizlogics Co., Ltd."},
  {max_prefix: "00-14-A6",vendor: "Teranetics, Inc."},
  {max_prefix: "00-14-AA",vendor: "Ashly Audio, Inc."},
  {max_prefix: "00-14-89",vendor: "B15402100 - JANDEI, S.L."},
  {max_prefix: "00-14-BA",vendor: "Carvers SA de CV"},
  {max_prefix: "00-14-B6",vendor: "Enswer Technology Inc."},
  {max_prefix: "00-14-D7",vendor: "Datastore Technology Corp"},
  {max_prefix: "00-14-DD",vendor: "Covergence Inc."},
  {max_prefix: "00-14-D4",vendor: "K Technology Corporation"},
  {max_prefix: "00-14-E6",vendor: "AIM Infrarotmodule GmbH"},
  {max_prefix: "00-14-DE",vendor: "Sage Instruments Inc."},
  {max_prefix: "00-14-DF",vendor: "HI-P Tech Corporation"},
  {max_prefix: "00-14-FE",vendor: "Artech Electronics"},
  {max_prefix: "00-14-F3",vendor: "ViXS Systems Inc"},
  {max_prefix: "00-14-8A",vendor: "Elin Ebg Traction Gmbh"},
  {max_prefix: "00-14-09",vendor: "MAGNETI MARELLI   S.E. S.p.A."},
  {max_prefix: "00-14-0B",vendor: "FIRST INTERNATIONAL COMPUTER, INC."},
  {max_prefix: "00-13-FD",vendor: "Nokia Danmark A/S"},
  {max_prefix: "00-14-00",vendor: "MINERVA KOREA CO., LTD"},
  {max_prefix: "00-13-FC",vendor: "SiCortex, Inc"},
  {max_prefix: "00-14-80",vendor: "Hitachi-LG Data Storage Korea, Inc"},
  {max_prefix: "00-14-7E",vendor: "InnerWireless"},
  {max_prefix: "00-14-7D",vendor: "Aeon Digital International"},
  {max_prefix: "00-14-76",vendor: "MultiCom Industries Limited"},
  {max_prefix: "00-14-73",vendor: "Bookham Inc"},
  {max_prefix: "00-14-4F",vendor: "Oracle Corporation"},
  {max_prefix: "00-14-56",vendor: "Edge Products"},
  {max_prefix: "00-14-50",vendor: "Heim Systems GmbH"},
  {max_prefix: "00-14-52",vendor: "CALCULEX,INC."},
  {max_prefix: "00-14-5D",vendor: "WJ Communications, Inc."},
  {max_prefix: "00-14-42",vendor: "ATTO CORPORATION"},
  {max_prefix: "00-14-47",vendor: "BOAZ Inc."},
  {max_prefix: "00-14-3E",vendor: "AirLink Communications, Inc."},
  {max_prefix: "00-14-3B",vendor: "Sensovation AG"},
  {max_prefix: "00-14-2D",vendor: "Toradex AG"},
  {max_prefix: "00-14-14",vendor: "Jumpnode Systems LLC."},
  {max_prefix: "00-14-29",vendor: "V Center Technologies Co., Ltd."},
  {max_prefix: "00-14-1E",vendor: "P.A. Semi, Inc."},
  {max_prefix: "00-14-6E",vendor: "H. Stoll GmbH & Co. KG"},
  {max_prefix: "00-14-69",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-13-D8",vendor: "Princeton Instruments"},
  {max_prefix: "00-13-CB",vendor: "Zenitel Norway AS"},
  {max_prefix: "00-13-CF",vendor: "4Access Communications"},
  {max_prefix: "00-13-C9",vendor: "Beyond Achieve Enterprises Ltd."},
  {max_prefix: "00-13-78",vendor: "Qsan Technology, Inc."},
  {max_prefix: "00-13-7A",vendor: "Netvox Technology Co., Ltd."},
  {max_prefix: "00-13-81",vendor: "CHIPS & Systems, Inc."},
  {max_prefix: "00-13-F6",vendor: "Cintech"},
  {max_prefix: "00-13-F3",vendor: "Giga-byte Communications Inc."},
  {max_prefix: "00-13-F4",vendor: "Psitek (Pty) Ltd"},
  {max_prefix: "00-13-E9",vendor: "VeriWave, Inc."},
  {max_prefix: "00-13-BE",vendor: "Virtual Conexions"},
  {max_prefix: "00-13-B9",vendor: "BM SPA"},
  {max_prefix: "00-13-AB",vendor: "Telemotive AG"},
  {max_prefix: "00-13-95",vendor: "congatec GmbH"},
  {max_prefix: "00-13-A8",vendor: "Tanisys Technology"},
  {max_prefix: "00-13-99",vendor: "STAC Corporation."},
  {max_prefix: "00-13-AC",vendor: "Sunmyung Electronics Co., LTD"},
  {max_prefix: "00-13-C6",vendor: "OpenGear, Inc"},
  {max_prefix: "00-13-DA",vendor: "Diskware Co., Ltd"},
  {max_prefix: "00-13-67",vendor: "Narayon. Co., Ltd."},
  {max_prefix: "00-13-5C",vendor: "OnSite Systems, Inc."},
  {max_prefix: "00-13-5F",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-13-56",vendor: "FLIR Radiation Inc"},
  {max_prefix: "00-13-5A",vendor: "Project T&E Limited"},
  {max_prefix: "00-13-61",vendor: "Biospace Co., Ltd."},
  {max_prefix: "00-13-62",vendor: "ShinHeung Precision Co., Ltd."},
  {max_prefix: "00-13-4F",vendor: "Rapidus Wireless Networks Inc."},
  {max_prefix: "00-13-38",vendor: "FRESENIUS-VIAL"},
  {max_prefix: "00-13-2D",vendor: "iWise Communications"},
  {max_prefix: "00-13-2C",vendor: "MAZ Brandenburg GmbH"},
  {max_prefix: "00-13-24",vendor: "Schneider Electric Ultra Terminal"},
  {max_prefix: "00-13-26",vendor: "ECM Systems Ltd"},
  {max_prefix: "00-13-27",vendor: "Data Acquisitions limited"},
  {max_prefix: "00-12-F6",vendor: "MDK CO.,LTD."},
  {max_prefix: "00-12-F4",vendor: "Belco International Co.,Ltd."},
  {max_prefix: "00-12-F1",vendor: "IFOTEC"},
  {max_prefix: "00-12-F5",vendor: "Imarda New Zealand Limited"},
  {max_prefix: "00-13-0F",vendor: "EGEMEN Bilgisayar Muh San ve Tic LTD STI"},
  {max_prefix: "00-13-13",vendor: "GuangZhou Post & Telecom Equipment ltd"},
  {max_prefix: "00-12-F8",vendor: "WNI Resources, LLC"},
  {max_prefix: "00-13-1D",vendor: "Scanvaegt International A/S"},
  {max_prefix: "00-13-18",vendor: "DGSTATION Co., Ltd."},
  {max_prefix: "00-13-1A",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-13-0C",vendor: "HF System Corporation"},
  {max_prefix: "00-13-79",vendor: "PONDER INFORMATION INDUSTRIES LTD."},
  {max_prefix: "00-13-74",vendor: "Atheros Communications, Inc."},
  {max_prefix: "00-13-69",vendor: "Honda Electron Co., LED."},
  {max_prefix: "00-13-42",vendor: "Vision Research, Inc."},
  {max_prefix: "00-13-3B",vendor: "Speed Dragon Multimedia Limited"},
  {max_prefix: "00-13-01",vendor: "IronGate S.L."},
  {max_prefix: "00-12-CB",vendor: "CSS Inc."},
  {max_prefix: "00-12-CE",vendor: "Advanced Cybernetics Group"},
  {max_prefix: "00-12-CA",vendor: "Mechatronic Brick Aps"},
  {max_prefix: "00-12-C7",vendor: "SECURAY Technologies Ltd.Co."},
  {max_prefix: "00-12-E8",vendor: "Fraunhofer IMS"},
  {max_prefix: "00-12-DD",vendor: "Shengqu Information Technology (Shanghai) Co., Ltd."},
  {max_prefix: "00-12-E0",vendor: "Codan Limited"},
  {max_prefix: "00-12-A1",vendor: "BluePacket Communications Co., Ltd."},
  {max_prefix: "00-12-9B",vendor: "E2S Electronic Engineering Solutions, S.L."},
  {max_prefix: "00-12-98",vendor: "MICO ELECTRIC(SHENZHEN) LIMITED"},
  {max_prefix: "00-12-AC",vendor: "ONTIMETEK INC."},
  {max_prefix: "00-12-BC",vendor: "Echolab LLC"},
  {max_prefix: "00-12-BD",vendor: "Avantec Manufacturing Limited"},
  {max_prefix: "00-12-AB",vendor: "WiLife, Inc."},
  {max_prefix: "00-12-CD",vendor: "ASEM SpA"},
  {max_prefix: "00-12-C0",vendor: "HotLava Systems, Inc."},
  {max_prefix: "00-12-B7",vendor: "PTW Freiburg"},
  {max_prefix: "00-12-DE",vendor: "Radio Components Sweden AB"},
  {max_prefix: "00-12-7C",vendor: "SWEGON AB"},
  {max_prefix: "00-12-78",vendor: "International Bar Code"},
  {max_prefix: "00-12-7A",vendor: "Sanyu Industry Co.,Ltd."},
  {max_prefix: "00-12-06",vendor: "iQuest (NZ) Ltd"},
  {max_prefix: "00-12-07",vendor: "Head Strong International Limited"},
  {max_prefix: "00-12-09",vendor: "Fastrax Ltd"},
  {max_prefix: "00-12-0B",vendor: "Chinasys Technologies Limited"},
  {max_prefix: "00-12-05",vendor: "Terrasat Communications, Inc."},
  {max_prefix: "00-12-54",vendor: "Spectra Technologies Holdings Company Ltd"},
  {max_prefix: "00-12-4F",vendor: "nVent"},
  {max_prefix: "00-12-49",vendor: "Delta Elettronica S.p.A."},
  {max_prefix: "00-12-21",vendor: "B.Braun Melsungen AG"},
  {max_prefix: "00-12-12",vendor: "PLUS  Corporation"},
  {max_prefix: "00-12-13",vendor: "Metrohm AG"},
  {max_prefix: "00-12-18",vendor: "ARUZE Corporation"},
  {max_prefix: "00-12-66",vendor: "Swisscom Hospitality Services SA"},
  {max_prefix: "00-12-5E",vendor: "CAEN"},
  {max_prefix: "00-12-5D",vendor: "CyberNet Inc."},
  {max_prefix: "00-12-72",vendor: "Redux Communications Ltd."},
  {max_prefix: "00-12-6A",vendor: "OPTOELECTRONICS Co., Ltd."},
  {max_prefix: "00-12-68",vendor: "IPS d.o.o."},
  {max_prefix: "00-12-3E",vendor: "ERUNE technology Co., Ltd."},
  {max_prefix: "00-12-3A",vendor: "Posystech Inc., Co."},
  {max_prefix: "00-12-34",vendor: "Camille Bauer"},
  {max_prefix: "00-12-4D",vendor: "Inducon BV"},
  {max_prefix: "00-12-38",vendor: "SetaBox Technology Co., Ltd."},
  {max_prefix: "00-12-3C",vendor: "Second Rule LLC"},
  {max_prefix: "00-12-23",vendor: "Pixim"},
  {max_prefix: "00-11-90",vendor: "Digital Design Corporation"},
  {max_prefix: "00-11-96",vendor: "Actuality Systems, Inc."},
  {max_prefix: "00-11-87",vendor: "Category Solutions, Inc"},
  {max_prefix: "00-11-6F",vendor: "Netforyou Co., LTD."},
  {max_prefix: "00-11-71",vendor: "DEXTER Communications, Inc."},
  {max_prefix: "00-11-67",vendor: "Integrated System Solution Corp."},
  {max_prefix: "00-11-D3",vendor: "NextGenTel Holding ASA"},
  {max_prefix: "00-11-C4",vendor: "Terminales de Telecomunicacion Terrestre, S.L."},
  {max_prefix: "00-11-CB",vendor: "Jacobsons AB"},
  {max_prefix: "00-11-7B",vendor: "B\u00fcchi  Labortechnik AG"},
  {max_prefix: "00-11-E3",vendor: "Thomson, Inc."},
  {max_prefix: "00-11-DC",vendor: "Glunz & Jensen"},
  {max_prefix: "00-11-F7",vendor: "Shenzhen Forward Industry Co., Ltd"},
  {max_prefix: "00-11-AB",vendor: "TRUSTABLE TECHNOLOGY CO.,LTD."},
  {max_prefix: "00-11-A5",vendor: "Fortuna Electronic Corp."},
  {max_prefix: "00-0F-FE",vendor: "G-PRO COMPUTER"},
  {max_prefix: "00-0F-DA",vendor: "YAZAKI CORPORATION"},
  {max_prefix: "00-0F-EF",vendor: "Thales e-Transactions GmbH"},
  {max_prefix: "00-11-05",vendor: "Sunplus Technology Co., Ltd."},
  {max_prefix: "00-11-02",vendor: "Aurora Multimedia Corp."},
  {max_prefix: "00-11-3C",vendor: "Micronas GmbH"},
  {max_prefix: "00-11-31",vendor: "UNATECH. CO.,LTD"},
  {max_prefix: "00-11-4E",vendor: "690885 Ontario Inc."},
  {max_prefix: "00-11-45",vendor: "ValuePoint Networks"},
  {max_prefix: "00-11-27",vendor: "TASI, Inc"},
  {max_prefix: "00-11-2A",vendor: "Niko NV"},
  {max_prefix: "00-11-2B",vendor: "NetModule AG"},
  {max_prefix: "00-11-2D",vendor: "iPulse Systems"},
  {max_prefix: "00-11-54",vendor: "Webpro Technologies Inc."},
  {max_prefix: "00-11-51",vendor: "Mykotronx"},
  {max_prefix: "00-11-23",vendor: "Appointech, Inc."},
  {max_prefix: "00-11-0F",vendor: "netplat,Inc."},
  {max_prefix: "00-11-6D",vendor: "American Time and Signal"},
  {max_prefix: "00-11-60",vendor: "ARTDIO Company Co., LTD"},
  {max_prefix: "00-11-15",vendor: "EPIN Technologies, Inc."},
  {max_prefix: "00-11-0B",vendor: "Franklin Technology Systems"},
  {max_prefix: "00-0F-EE",vendor: "XTec, Incorporated"},
  {max_prefix: "00-0F-E4",vendor: "Pantech Co.,Ltd"},
  {max_prefix: "00-0F-E7",vendor: "Lutron Electronics Co., Inc."},
  {max_prefix: "00-0F-E6",vendor: "MBTech Systems, Inc."},
  {max_prefix: "00-0F-E9",vendor: "GW TECHNOLOGIES CO.,LTD."},
  {max_prefix: "00-0F-E1",vendor: "ID DIGITAL CORPORATION"},
  {max_prefix: "00-0F-AB",vendor: "Kyushu Electronics Systems Inc."},
  {max_prefix: "00-0F-AD",vendor: "FMN communications GmbH"},
  {max_prefix: "00-0F-AC",vendor: "IEEE 802.11"},
  {max_prefix: "00-0F-B3",vendor: "Actiontec Electronics, Inc"},
  {max_prefix: "00-0F-9C",vendor: "Panduit Corp"},
  {max_prefix: "00-0F-7A",vendor: "BeiJing NuQX Technology CO.,LTD"},
  {max_prefix: "00-0F-64",vendor: "D&R Electronica Weesp BV"},
  {max_prefix: "00-0F-72",vendor: "Sandburst"},
  {max_prefix: "00-0F-75",vendor: "First Silicon Solutions"},
  {max_prefix: "00-0F-7C",vendor: "ACTi Corporation"},
  {max_prefix: "00-0F-C7",vendor: "Dionica R&D Ltd."},
  {max_prefix: "00-0F-C8",vendor: "Chantry Networks"},
  {max_prefix: "00-0F-BB",vendor: "Nokia Siemens Networks GmbH & Co. KG."},
  {max_prefix: "00-0F-BC",vendor: "Onkey Technologies, Inc."},
  {max_prefix: "00-0F-DF",vendor: "SOLOMON Technology Corp."},
  {max_prefix: "00-0F-D6",vendor: "Sarotech Co., Ltd"},
  {max_prefix: "00-0F-98",vendor: "Avamax Co. Ltd."},
  {max_prefix: "00-0F-97",vendor: "Avanex Corporation"},
  {max_prefix: "00-0F-96",vendor: "Telco Systems, Inc."},
  {max_prefix: "00-0F-B6",vendor: "Europlex Technologies"},
  {max_prefix: "00-0F-B9",vendor: "Adaptive Instruments"},
  {max_prefix: "00-0F-8B",vendor: "Orion MultiSystems Inc"},
  {max_prefix: "00-0F-8C",vendor: "Gigawavetech Pte Ltd"},
  {max_prefix: "00-0F-45",vendor: "Stretch, Inc."},
  {max_prefix: "00-0F-3B",vendor: "Fuji System Machines Co., Ltd."},
  {max_prefix: "00-0F-37",vendor: "Xambala Incorporated"},
  {max_prefix: "00-0F-38",vendor: "Netstar"},
  {max_prefix: "00-0F-3A",vendor: "HISHARP"},
  {max_prefix: "00-0F-34",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-0F-30",vendor: "Raza Microelectronics Inc"},
  {max_prefix: "00-0F-2E",vendor: "Megapower International Corp."},
  {max_prefix: "00-0F-26",vendor: "WorldAccxx  LLC"},
  {max_prefix: "00-0F-53",vendor: "Solarflare Communications Inc."},
  {max_prefix: "00-0F-51",vendor: "Azul Systems, Inc."},
  {max_prefix: "00-0F-07",vendor: "Mangrove Systems, Inc."},
  {max_prefix: "00-0F-00",vendor: "Legra Systems, Inc."},
  {max_prefix: "00-0F-01",vendor: "DIGITALKS INC"},
  {max_prefix: "00-0F-03",vendor: "COM&C CO., LTD"},
  {max_prefix: "00-0F-54",vendor: "Entrelogic Corporation"},
  {max_prefix: "00-0E-DF",vendor: "PLX Technology"},
  {max_prefix: "00-0E-E1",vendor: "ExtremeSpeed Inc."},
  {max_prefix: "00-0F-25",vendor: "AimValley B.V."},
  {max_prefix: "00-0F-19",vendor: "Boston Scientific"},
  {max_prefix: "00-0E-D3",vendor: "Epicenter, Inc."},
  {max_prefix: "00-0E-D7",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-0F-10",vendor: "RDM Corporation"},
  {max_prefix: "00-0E-C5",vendor: "Digital Multitools Inc"},
  {max_prefix: "00-0E-C7",vendor: "Motorola Korea"},
  {max_prefix: "00-0E-BA",vendor: "HANMI SEMICONDUCTOR CO., LTD."},
  {max_prefix: "00-0E-BC",vendor: "Paragon Fidelity GmbH"},
  {max_prefix: "00-0E-A8",vendor: "United Technologists Europe Limited"},
  {max_prefix: "00-0E-8B",vendor: "Astarte Technology Co, Ltd."},
  {max_prefix: "00-0E-80",vendor: "Thomson Technology Inc"},
  {max_prefix: "00-0E-AA",vendor: "Scalent Systems, Inc."},
  {max_prefix: "00-0E-AC",vendor: "MINTRON ENTERPRISE CO., LTD."},
  {max_prefix: "00-0E-AE",vendor: "GAWELL TECHNOLOGIES CORP."},
  {max_prefix: "00-0E-A4",vendor: "Quantum Corp."},
  {max_prefix: "00-0E-6B",vendor: "Janitza electronics GmbH"},
  {max_prefix: "00-0E-67",vendor: "Eltis Microelectronics Ltd."},
  {max_prefix: "00-0E-65",vendor: "TransCore"},
  {max_prefix: "00-0E-7A",vendor: "GemWon Communications Co., Ltd."},
  {max_prefix: "00-0E-6C",vendor: "Device Drivers Limited"},
  {max_prefix: "00-0E-9D",vendor: "Tiscali UK Ltd"},
  {max_prefix: "00-0E-5F",vendor: "activ-net GmbH & Co. KG"},
  {max_prefix: "00-0E-57",vendor: "Iworld Networking, Inc."},
  {max_prefix: "00-0E-47",vendor: "NCI System Co.,Ltd."},
  {max_prefix: "00-0E-85",vendor: "Catalyst Enterprises, Inc."},
  {max_prefix: "00-0E-74",vendor: "Solar Telecom. Tech"},
  {max_prefix: "00-0D-E7",vendor: "Snap-on OEM Group"},
  {max_prefix: "00-0D-F0",vendor: "QCOM TECHNOLOGY INC."},
  {max_prefix: "00-0D-E5",vendor: "Samsung Thales"},
  {max_prefix: "00-0D-DD",vendor: "Profilo Telra Elektronik Sanayi ve Ticaret. A.\u015e"},
  {max_prefix: "00-0D-F5",vendor: "Teletronics International Inc."},
  {max_prefix: "00-0D-F7",vendor: "Space Dynamics Lab"},
  {max_prefix: "00-0D-EB",vendor: "CompXs Limited"},
  {max_prefix: "00-0D-EE",vendor: "Andrew RF Power Amplifier Group"},
  {max_prefix: "00-0D-EF",vendor: "Soc. Coop. Bilanciai"},
  {max_prefix: "00-0E-11",vendor: "BDT B\u00fcro und Datentechnik GmbH & Co.KG"},
  {max_prefix: "00-0D-F8",vendor: "ORGA Kartensysteme GmbH"},
  {max_prefix: "00-0E-46",vendor: "Niigata Seimitsu Co.,Ltd."},
  {max_prefix: "00-0E-43",vendor: "G-Tek Electronics Sdn. Bhd."},
  {max_prefix: "00-0E-44",vendor: "Digital 5, Inc."},
  {max_prefix: "00-0E-1F",vendor: "TCL Networks Equipment Co., Ltd."},
  {max_prefix: "00-0E-26",vendor: "Gincom Technology Corp."},
  {max_prefix: "00-0E-16",vendor: "SouthWing S.L."},
  {max_prefix: "00-0E-0B",vendor: "Netac Technology Co., Ltd."},
  {max_prefix: "00-0E-32",vendor: "Kontron Medical"},
  {max_prefix: "00-0D-AF",vendor: "Plexus Corp (UK) Ltd"},
  {max_prefix: "00-0D-B1",vendor: "Japan Network Service Co., Ltd."},
  {max_prefix: "00-0D-A7",vendor: "Private"},
  {max_prefix: "00-0D-AD",vendor: "Dataprobe, Inc."},
  {max_prefix: "00-0D-A9",vendor: "INGETEAM"},
  {max_prefix: "00-0D-AB",vendor: "Parker Hannifin GmbH Electromechanical Division Europe"},
  {max_prefix: "00-0D-78",vendor: "Engineering & Security"},
  {max_prefix: "00-0D-79",vendor: "Dynamic Solutions Co,.Ltd."},
  {max_prefix: "00-0D-6D",vendor: "K-Tech Devices Corp."},
  {max_prefix: "00-0D-6E",vendor: "K-Patents Oy"},
  {max_prefix: "00-0D-A8",vendor: "Teletronics Technology Corporation"},
  {max_prefix: "00-0D-9E",vendor: "TOKUDEN OHIZUMI SEISAKUSYO Co.,Ltd."},
  {max_prefix: "00-0D-A3",vendor: "Emerging Technologies Limited"},
  {max_prefix: "00-0D-A4",vendor: "DOSCH & AMAND SYSTEMS AG"},
  {max_prefix: "00-0D-9A",vendor: "INFOTEC LTD"},
  {max_prefix: "00-0D-C0",vendor: "Spagat AS"},
  {max_prefix: "00-0D-C6",vendor: "DigiRose Technology Co., Ltd."},
  {max_prefix: "00-0D-C1",vendor: "SafeWeb Inc"},
  {max_prefix: "00-0D-85",vendor: "Tapwave, Inc."},
  {max_prefix: "00-0D-82",vendor: "PHSNET"},
  {max_prefix: "00-0D-83",vendor: "Sanmina-SCI Hungary  Ltd."},
  {max_prefix: "00-0D-7F",vendor: "MIDAS  COMMUNICATION TECHNOLOGIES PTE LTD ( Foreign Branch)"},
  {max_prefix: "00-0D-75",vendor: "Kobian Pte Ltd - Taiwan Branch"},
  {max_prefix: "00-0D-6A",vendor: "Redwood Technologies LTD"},
  {max_prefix: "00-0D-5D",vendor: "Raritan Computer, Inc"},
  {max_prefix: "00-0D-63",vendor: "DENT Instruments, Inc."},
  {max_prefix: "00-0D-66",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-0D-96",vendor: "Vtera Technology Inc."},
  {max_prefix: "00-0D-8B",vendor: "T&D Corporation"},
  {max_prefix: "00-0D-90",vendor: "Factum Electronics AB"},
  {max_prefix: "00-0D-BA",vendor: "Oc\u00e9 Document Technologies GmbH"},
  {max_prefix: "00-0D-BD",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-0D-B5",vendor: "GLOBALSAT TECHNOLOGY CORPORATION"},
  {max_prefix: "00-0D-B4",vendor: "Stormshield"},
  {max_prefix: "00-0C-CB",vendor: "Design Combus Ltd"},
  {max_prefix: "00-0C-C9",vendor: "ILWOO DATA & TECHNOLOGY CO.,LTD"},
  {max_prefix: "00-0D-02",vendor: "NEC Platforms, Ltd."},
  {max_prefix: "00-0D-07",vendor: "Calrec Audio Ltd"},
  {max_prefix: "00-0D-2F",vendor: "AIN Comm.Tech.Co., LTD"},
  {max_prefix: "00-0D-21",vendor: "WISCORE Inc."},
  {max_prefix: "00-0D-23",vendor: "Smart Solution, Inc"},
  {max_prefix: "00-0D-27",vendor: "MICROPLEX Printware AG"},
  {max_prefix: "00-0D-1B",vendor: "Kyoto Electronics Manufacturing Co., Ltd."},
  {max_prefix: "00-0D-1D",vendor: "HIGH-TEK HARNESS ENT. CO., LTD."},
  {max_prefix: "00-0D-09",vendor: "Yuehua(Zhuhai) Electronic CO. LTD"},
  {max_prefix: "00-0C-EE",vendor: "jp-embedded"},
  {max_prefix: "00-0C-E9",vendor: "BLOOMBERG L.P."},
  {max_prefix: "00-0C-EA",vendor: "aphona Kommunikationssysteme"},
  {max_prefix: "00-0D-40",vendor: "Verint Loronix Video Solutions"},
  {max_prefix: "00-0D-37",vendor: "WIPLUG"},
  {max_prefix: "00-0D-33",vendor: "Prediwave Corp."},
  {max_prefix: "00-0C-DA",vendor: "FreeHand Systems, Inc."},
  {max_prefix: "00-0C-DD",vendor: "AOS technologies AG"},
  {max_prefix: "00-0C-CD",vendor: "IEC - TC57"},
  {max_prefix: "00-0C-FB",vendor: "Korea Network Systems"},
  {max_prefix: "00-0C-F5",vendor: "InfoExpress"},
  {max_prefix: "00-0C-85",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-0C-84",vendor: "Eazix, Inc."},
  {max_prefix: "00-0C-7A",vendor: "DaTARIUS Technologies GmbH"},
  {max_prefix: "00-0C-79",vendor: "Extel Communications P/L"},
  {max_prefix: "00-0C-6D",vendor: "Edwards Ltd."},
  {max_prefix: "00-0C-70",vendor: "ACC GmbH"},
  {max_prefix: "00-0C-6A",vendor: "MBARI"},
  {max_prefix: "00-0C-6B",vendor: "Kurz Industrie-Elektronik GmbH"},
  {max_prefix: "00-0C-9D",vendor: "UbeeAirWalk, Inc."},
  {max_prefix: "00-0C-9F",vendor: "NKE Corporation"},
  {max_prefix: "00-0C-9A",vendor: "Hitech Electronics Corp."},
  {max_prefix: "00-0C-91",vendor: "Riverhead Networks Inc."},
  {max_prefix: "00-0C-5D",vendor: "CHIC TECHNOLOGY (CHINA) CORP."},
  {max_prefix: "00-0C-4F",vendor: "UDTech Japan Corporation"},
  {max_prefix: "00-0C-62",vendor: "ABB AB, Cewe-Control"},
  {max_prefix: "00-0C-4C",vendor: "Arcor AG&Co."},
  {max_prefix: "00-0C-47",vendor: "SK Teletech(R&D Planning Team)"},
  {max_prefix: "00-0C-AF",vendor: "TRI TERM CO.,LTD."},
  {max_prefix: "00-0C-B3",vendor: "ROUND Co.,Ltd."},
  {max_prefix: "00-0C-A5",vendor: "Naman NZ LTd"},
  {max_prefix: "00-0C-A9",vendor: "Ebtron Inc."},
  {max_prefix: "00-0C-75",vendor: "Oriental integrated electronics. LTD"},
  {max_prefix: "00-0C-20",vendor: "Fi WIn, Inc."},
  {max_prefix: "00-0C-15",vendor: "CyberPower Systems, Inc."},
  {max_prefix: "00-0B-D6",vendor: "Paxton Access Ltd"},
  {max_prefix: "00-0B-D0",vendor: "XiMeta Technology Americas Inc."},
  {max_prefix: "00-0B-D4",vendor: "Beijing Wise Technology & Science Development Co.Ltd"},
  {max_prefix: "00-0B-E7",vendor: "COMFLUX TECHNOLOGY INC."},
  {max_prefix: "00-0B-DA",vendor: "EyeCross Co.,Inc."},
  {max_prefix: "00-0C-16",vendor: "Concorde Microsystems Inc."},
  {max_prefix: "00-0C-09",vendor: "Hitachi IE Systems Co., Ltd"},
  {max_prefix: "00-0B-EC",vendor: "NIPPON ELECTRIC INSTRUMENT, INC."},
  {max_prefix: "00-0C-3F",vendor: "Cogent Defence & Security Networks,"},
  {max_prefix: "00-0C-30",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-0C-1F",vendor: "Glimmerglass Networks"},
  {max_prefix: "00-0C-02",vendor: "ABB Oy"},
  {max_prefix: "00-0B-FF",vendor: "Berkeley Camera Engineering"},
  {max_prefix: "00-0B-73",vendor: "Kodeos Communications"},
  {max_prefix: "00-0B-76",vendor: "ET&T Technology Co. Ltd."},
  {max_prefix: "00-0B-5E",vendor: "Audio Engineering Society Inc."},
  {max_prefix: "00-0B-5F",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-0B-60",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-0B-65",vendor: "Sy.A.C. srl"},
  {max_prefix: "00-0B-61",vendor: "Friedrich L\u00fctze GmbH & Co. KG"},
  {max_prefix: "00-0B-99",vendor: "SensAble Technologies, Inc."},
  {max_prefix: "00-0B-9C",vendor: "TriBeam Technologies, Inc."},
  {max_prefix: "00-0B-89",vendor: "Top Global Technology, Ltd."},
  {max_prefix: "00-0B-8B",vendor: "KERAJET, S.A."},
  {max_prefix: "00-0B-59",vendor: "ScriptPro, LLC"},
  {max_prefix: "00-0B-5C",vendor: "Newtech Co.,Ltd"},
  {max_prefix: "00-0B-5B",vendor: "Rincon Research Corporation"},
  {max_prefix: "00-0B-7C",vendor: "Telex Communications"},
  {max_prefix: "00-0B-83",vendor: "DATAWATT B.V."},
  {max_prefix: "00-0B-71",vendor: "Litchfield Communications Inc."},
  {max_prefix: "00-0B-74",vendor: "Kingwave Technology Co., Ltd."},
  {max_prefix: "00-0B-7E",vendor: "SAGINOMIYA Seisakusho Inc."},
  {max_prefix: "00-0B-80",vendor: "Lycium Networks"},
  {max_prefix: "00-0B-A7",vendor: "Maranti Networks"},
  {max_prefix: "00-0B-AA",vendor: "Aiphone co.,Ltd"},
  {max_prefix: "00-0B-A4",vendor: "Shiron Satellite Communications Ltd. (1996)"},
  {max_prefix: "00-0B-C1",vendor: "Bay Microsystems, Inc."},
  {max_prefix: "00-0B-C5",vendor: "SMC Networks, Inc."},
  {max_prefix: "00-0B-C6",vendor: "ISAC, Inc."},
  {max_prefix: "00-0B-41",vendor: "Ing. B\u00fcro Dr. Beutlhauser"},
  {max_prefix: "00-0B-05",vendor: "Pacific Broadband Networks"},
  {max_prefix: "00-0B-00",vendor: "FUJIAN START COMPUTER EQUIPMENT CO.,LTD"},
  {max_prefix: "00-0B-03",vendor: "Taekwang Industrial Co., Ltd"},
  {max_prefix: "00-0B-01",vendor: "DAIICHI ELECTRONICS CO., LTD."},
  {max_prefix: "00-0A-EA",vendor: "ADAM ELEKTRONIK LTD. \u015eTI"},
  {max_prefix: "00-0A-E3",vendor: "YANG MEI TECHNOLOGY CO., LTD"},
  {max_prefix: "00-0A-DC",vendor: "RuggedCom Inc."},
  {max_prefix: "00-0A-E0",vendor: "Fujitsu Softek"},
  {max_prefix: "00-0B-20",vendor: "Hirata corporation"},
  {max_prefix: "00-0B-22",vendor: "Environmental Systems and Services"},
  {max_prefix: "00-0B-1B",vendor: "Systronix, Inc."},
  {max_prefix: "00-0B-3E",vendor: "BittWare, Inc"},
  {max_prefix: "00-0B-29",vendor: "LS(LG) Industrial Systems co.,Ltd"},
  {max_prefix: "00-0A-BB",vendor: "Taiwan Secom Co,. Ltd"},
  {max_prefix: "00-0A-AD",vendor: "Stargames Corporation"},
  {max_prefix: "00-0B-39",vendor: "Keisoku Giken Co.,Ltd."},
  {max_prefix: "00-0B-33",vendor: "Vivato Technologies"},
  {max_prefix: "00-0B-11",vendor: "HIMEJI ABC TRADING CO.,LTD."},
  {max_prefix: "00-0A-6E",vendor: "Harmonic, Inc"},
  {max_prefix: "00-0A-6D",vendor: "EKS Elektronikservice GmbH"},
  {max_prefix: "00-0A-74",vendor: "Manticom Networks Inc."},
  {max_prefix: "00-0A-6F",vendor: "ZyFLEX Technologies Inc"},
  {max_prefix: "00-0A-A4",vendor: "SHANGHAI SURVEILLANCE TECHNOLOGY CO,LTD"},
  {max_prefix: "00-0A-9E",vendor: "BroadWeb Corportation"},
  {max_prefix: "00-0A-90",vendor: "Bayside Interactive, Inc."},
  {max_prefix: "00-0A-9D",vendor: "King Young Technology Co. Ltd."},
  {max_prefix: "00-0A-B1",vendor: "GENETEC Corporation"},
  {max_prefix: "00-0A-B7",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-0A-B9",vendor: "Astera Technologies Corp."},
  {max_prefix: "00-0A-A1",vendor: "V V S Limited"},
  {max_prefix: "00-0A-25",vendor: "CERAGON NETWORKS"},
  {max_prefix: "00-0A-21",vendor: "Integra Telecom Co. Ltd"},
  {max_prefix: "00-0A-1E",vendor: "Red-M Products Limited"},
  {max_prefix: "00-0A-4B",vendor: "DataPower Technology, Inc."},
  {max_prefix: "00-0A-43",vendor: "Chunghwa Telecom Co., Ltd."},
  {max_prefix: "00-0A-45",vendor: "Audio-Technica Corp."},
  {max_prefix: "00-0A-33",vendor: "Emulex Corporation"},
  {max_prefix: "00-0A-2E",vendor: "MAPLE NETWORKS CO., LTD"},
  {max_prefix: "00-0A-2D",vendor: "Cabot Communications Limited"},
  {max_prefix: "00-0A-35",vendor: "Xilinx"},
  {max_prefix: "00-0A-3B",vendor: "GCT Semiconductor, Inc"},
  {max_prefix: "00-0A-8B",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-0A-88",vendor: "InCypher S.A."},
  {max_prefix: "00-0A-7C",vendor: "Tecton Ltd"},
  {max_prefix: "00-0A-63",vendor: "DHD GmbH"},
  {max_prefix: "00-0A-67",vendor: "OngCorp"},
  {max_prefix: "00-09-DA",vendor: "Control Module Inc."},
  {max_prefix: "00-09-D7",vendor: "DC Security Products"},
  {max_prefix: "00-09-D8",vendor: "F\u00e4lt Communications AB"},
  {max_prefix: "00-09-DB",vendor: "eSpace"},
  {max_prefix: "00-09-D5",vendor: "Signal Communication, Inc."},
  {max_prefix: "00-09-FB",vendor: "Philips Patient Monitoring"},
  {max_prefix: "00-09-F9",vendor: "ART JAPAN CO., LTD."},
  {max_prefix: "00-09-FD",vendor: "Ubinetics Limited"},
  {max_prefix: "00-09-B6",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-09-B3",vendor: "MCM Systems Ltd"},
  {max_prefix: "00-09-A3",vendor: "Leadfly Techologies Corp. Ltd."},
  {max_prefix: "00-09-A5",vendor: "HANSUNG ELETRONIC INDUSTRIES DEVELOPMENT CO., LTD"},
  {max_prefix: "00-09-D3",vendor: "Western DataCom Co., Inc."},
  {max_prefix: "00-0A-14",vendor: "TECO a.s."},
  {max_prefix: "00-0A-0B",vendor: "Sealevel Systems, Inc."},
  {max_prefix: "00-0A-10",vendor: "FAST media integrations AG"},
  {max_prefix: "00-09-E6",vendor: "Cyber Switching Inc."},
  {max_prefix: "00-09-E2",vendor: "Sinbon Electronics Co., Ltd."},
  {max_prefix: "00-09-9A",vendor: "ELMO COMPANY, LIMITED"},
  {max_prefix: "00-09-9C",vendor: "Naval Research Laboratory"},
  {max_prefix: "00-09-BC",vendor: "Utility, Inc"},
  {max_prefix: "00-09-BE",vendor: "Mamiya-OP Co.,Ltd."},
  {max_prefix: "00-09-92",vendor: "InterEpoch Technology,INC."},
  {max_prefix: "00-09-95",vendor: "Castle Technology Ltd"},
  {max_prefix: "00-09-98",vendor: "Capinfo Company Limited"},
  {max_prefix: "00-09-8F",vendor: "Cetacean Networks"},
  {max_prefix: "00-09-86",vendor: "Metalink LTD."},
  {max_prefix: "00-09-87",vendor: "NISHI NIPPON ELECTRIC WIRE & CABLE CO.,LTD."},
  {max_prefix: "00-09-89",vendor: "VividLogic Inc."},
  {max_prefix: "00-09-8C",vendor: "Option Wireless Sweden"},
  {max_prefix: "00-09-85",vendor: "Auto Telecom Company"},
  {max_prefix: "00-09-8D",vendor: "Velocity Semiconductor"},
  {max_prefix: "00-09-81",vendor: "Newport Networks"},
  {max_prefix: "00-09-2C",vendor: "Hitpoint Inc."},
  {max_prefix: "00-09-2B",vendor: "iQstor Networks, Inc."},
  {max_prefix: "00-09-26",vendor: "YODA COMMUNICATIONS, INC."},
  {max_prefix: "00-09-27",vendor: "TOYOKEIKI CO.,LTD."},
  {max_prefix: "00-09-23",vendor: "Heaman System Co., Ltd"},
  {max_prefix: "00-09-1D",vendor: "Proteam Computer Corporation"},
  {max_prefix: "00-09-55",vendor: "Young Generation International Corp."},
  {max_prefix: "00-09-4A",vendor: "Homenet Communications"},
  {max_prefix: "00-09-4B",vendor: "FillFactory NV"},
  {max_prefix: "00-09-4D",vendor: "Braintree Communications Pty Ltd"},
  {max_prefix: "00-09-50",vendor: "Independent Storage Corporation"},
  {max_prefix: "00-09-54",vendor: "AMiT spol. s. r. o."},
  {max_prefix: "00-09-52",vendor: "Auerswald GmbH & Co. KG"},
  {max_prefix: "00-09-44",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-09-62",vendor: "Sonitor Technologies AS"},
  {max_prefix: "00-09-5D",vendor: "Dialogue Technology Corp."},
  {max_prefix: "00-09-5C",vendor: "Philips Medical Systems - Cardiac and Monitoring Systems (CM"},
  {max_prefix: "00-09-58",vendor: "INTELNET S.A."},
  {max_prefix: "00-09-09",vendor: "Telenor Connect A/S"},
  {max_prefix: "00-09-0A",vendor: "SnedFar Technology Co., Ltd."},
  {max_prefix: "00-09-84",vendor: "MyCasa Network Inc."},
  {max_prefix: "00-09-17",vendor: "WEM Technology Inc"},
  {max_prefix: "00-08-73",vendor: "DapTechnology B.V."},
  {max_prefix: "00-08-7A",vendor: "Wipotec GmbH"},
  {max_prefix: "00-08-71",vendor: "NORTHDATA Co., Ltd."},
  {max_prefix: "00-08-7E",vendor: "Bon Electro-Telecom Inc."},
  {max_prefix: "00-08-80",vendor: "BroadTel Canada Communications inc."},
  {max_prefix: "00-08-B4",vendor: "SYSPOL"},
  {max_prefix: "00-08-B3",vendor: "Fastwel"},
  {max_prefix: "00-08-B2",vendor: "SHENZHEN COMPASS TECHNOLOGY DEVELOPMENT CO.,LTD"},
  {max_prefix: "00-08-B1",vendor: "ProQuent Systems"},
  {max_prefix: "00-08-AF",vendor: "Novatec Corporation"},
  {max_prefix: "00-08-A6",vendor: "Multiware & Image Co., Ltd."},
  {max_prefix: "00-08-EB",vendor: "ROMWin Co.,Ltd."},
  {max_prefix: "00-08-E8",vendor: "Excel Master Ltd."},
  {max_prefix: "00-08-DC",vendor: "Wiznet"},
  {max_prefix: "00-08-DD",vendor: "Telena Communications, Inc."},
  {max_prefix: "00-08-E1",vendor: "Barix AG"},
  {max_prefix: "00-08-DA",vendor: "SofaWare Technologies Ltd."},
  {max_prefix: "00-08-D4",vendor: "IneoQuest Technologies, Inc"},
  {max_prefix: "00-08-D6",vendor: "HASSNET Inc."},
  {max_prefix: "00-08-CE",vendor: "IPMobileNet Inc."},
  {max_prefix: "00-08-8E",vendor: "Nihon Computer Co., Ltd."},
  {max_prefix: "00-08-81",vendor: "DIGITAL HANDS CO.,LTD."},
  {max_prefix: "00-08-82",vendor: "SIGMA CORPORATION"},
  {max_prefix: "00-09-03",vendor: "Panasas, Inc"},
  {max_prefix: "00-09-07",vendor: "Chrysalis Development"},
  {max_prefix: "00-09-06",vendor: "Esteem Networks"},
  {max_prefix: "00-08-FB",vendor: "SonoSite, Inc."},
  {max_prefix: "00-08-EE",vendor: "Logic Product Development"},
  {max_prefix: "00-08-C2",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-08-C0",vendor: "ASA SYSTEMS"},
  {max_prefix: "00-08-A3",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-08-9E",vendor: "Beijing Enter-Net co.LTD"},
  {max_prefix: "00-07-DD",vendor: "Cradle Technologies"},
  {max_prefix: "00-07-D5",vendor: "3e Technologies Int;., Inc."},
  {max_prefix: "00-07-DB",vendor: "Kirana Networks, Inc."},
  {max_prefix: "00-08-6D",vendor: "Missouri FreeNet"},
  {max_prefix: "00-08-6A",vendor: "Securiton Gmbh"},
  {max_prefix: "00-08-63",vendor: "Entrisphere Inc."},
  {max_prefix: "00-08-66",vendor: "DSX Access Systems, Inc."},
  {max_prefix: "00-08-5F",vendor: "Picanol N.V."},
  {max_prefix: "00-04-7B",vendor: "Schlumberger"},
  {max_prefix: "00-07-D1",vendor: "Spectrum Signal Processing Inc."},
  {max_prefix: "00-07-B9",vendor: "Ginganet Corporation"},
  {max_prefix: "00-04-7F",vendor: "Chr. Mayr GmbH & Co. KG"},
  {max_prefix: "00-08-0C",vendor: "VDA Group S.p.a."},
  {max_prefix: "00-08-04",vendor: "ICA Inc."},
  {max_prefix: "00-07-FF",vendor: "Gluon Networks"},
  {max_prefix: "00-07-F7",vendor: "Galtronics"},
  {max_prefix: "00-08-29",vendor: "TOKYO ELECTRON DEVICE NAGASAKI LIMITED"},
  {max_prefix: "00-08-1B",vendor: "Windigo Systems"},
  {max_prefix: "00-07-B4",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-07-B3",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-07-B5",vendor: "Any One Wireless Ltd."},
  {max_prefix: "00-07-EF",vendor: "Lockheed Martin Tactical Systems"},
  {max_prefix: "00-07-F4",vendor: "Eletex Co., Ltd."},
  {max_prefix: "00-08-52",vendor: "Davolink Co. Inc."},
  {max_prefix: "00-08-57",vendor: "Polaris Networks, Inc."},
  {max_prefix: "00-07-36",vendor: "Data Video Technologies Co., Ltd."},
  {max_prefix: "00-07-38",vendor: "Young Technology Co., Ltd."},
  {max_prefix: "00-07-7F",vendor: "J Communications Co., Ltd."},
  {max_prefix: "00-07-71",vendor: "Embedded System Corporation"},
  {max_prefix: "00-07-70",vendor: "Ubiquoss Inc"},
  {max_prefix: "00-07-64",vendor: "YoungWoo Telecom Co. Ltd."},
  {max_prefix: "00-07-66",vendor: "Chou Chin Industrial Co., Ltd."},
  {max_prefix: "00-07-61",vendor: "29530"},
  {max_prefix: "00-07-55",vendor: "Lafon"},
  {max_prefix: "00-07-59",vendor: "Boris Manufacturing Corp."},
  {max_prefix: "00-07-51",vendor: "m-u-t AG"},
  {max_prefix: "00-07-50",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-07-89",vendor: "Allradio Co., Ltd"},
  {max_prefix: "00-07-87",vendor: "Idea System Co., Ltd."},
  {max_prefix: "00-07-85",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-07-75",vendor: "Valence Semiconductor, Inc."},
  {max_prefix: "00-07-93",vendor: "Shin Satellite Public Company Limited"},
  {max_prefix: "00-07-96",vendor: "LSI Systems, Inc."},
  {max_prefix: "00-07-8B",vendor: "Wegener Communications, Inc."},
  {max_prefix: "00-07-83",vendor: "SynCom Network, Inc."},
  {max_prefix: "00-07-42",vendor: "Ormazabal"},
  {max_prefix: "00-07-4B",vendor: "Daihen Corporation"},
  {max_prefix: "00-07-4A",vendor: "Carl Valentin GmbH"},
  {max_prefix: "00-07-3C",vendor: "Telecom Design"},
  {max_prefix: "00-07-19",vendor: "Mobiis Co., Ltd."},
  {max_prefix: "00-07-0D",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-07-0E",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-07-0B",vendor: "Novabase SGPS, SA"},
  {max_prefix: "00-07-A2",vendor: "Opteon Corporation"},
  {max_prefix: "00-07-29",vendor: "Kistler Instrumente AG"},
  {max_prefix: "00-07-2A",vendor: "Innovance Networks"},
  {max_prefix: "00-06-EE",vendor: "Shenyang Neu-era Information & Technology Stock Co., Ltd"},
  {max_prefix: "00-06-E8",vendor: "Optical Network Testing, Inc."},
  {max_prefix: "00-06-E2",vendor: "Ceemax Technology Co., Ltd."},
  {max_prefix: "00-06-E1",vendor: "Techno Trade s.a"},
  {max_prefix: "00-07-10",vendor: "Adax, Inc."},
  {max_prefix: "00-06-F8",vendor: "The Boeing Company"},
  {max_prefix: "00-06-FB",vendor: "Hitachi Printing Solutions, Ltd."},
  {max_prefix: "00-06-FC",vendor: "Fnet Co., Ltd."},
  {max_prefix: "00-06-B5",vendor: "Source Photonics, Inc."},
  {max_prefix: "00-06-A5",vendor: "PINON Corp."},
  {max_prefix: "00-06-9D",vendor: "Petards Ltd"},
  {max_prefix: "00-06-A8",vendor: "KC Technology, Inc."},
  {max_prefix: "00-06-A0",vendor: "Mx Imaging"},
  {max_prefix: "00-06-BA",vendor: "Westwave Communications"},
  {max_prefix: "00-06-BE",vendor: "Baumer Optronic GmbH"},
  {max_prefix: "00-06-C4",vendor: "Piolink Inc."},
  {max_prefix: "00-06-F4",vendor: "Prime Electronics & Satellitics Inc."},
  {max_prefix: "00-06-90",vendor: "Euracom Communication GmbH"},
  {max_prefix: "00-06-DA",vendor: "ITRAN Communications Ltd."},
  {max_prefix: "00-06-D0",vendor: "Elgar Electronics Corp."},
  {max_prefix: "00-06-CC",vendor: "JMI Electronics Co., Ltd."},
  {max_prefix: "00-06-BF",vendor: "Accella Technologies Co., Ltd."},
  {max_prefix: "00-06-7E",vendor: "WinCom Systems, Inc."},
  {max_prefix: "00-06-77",vendor: "SICK AG"},
  {max_prefix: "00-06-2E",vendor: "Aristos Logic Corp."},
  {max_prefix: "00-06-25",vendor: "The Linksys Group, Inc."},
  {max_prefix: "00-06-27",vendor: "Uniwide Technologies, Inc."},
  {max_prefix: "00-06-24",vendor: "Gentner Communications Corp."},
  {max_prefix: "00-06-2C",vendor: "Bivio Networks"},
  {max_prefix: "00-06-21",vendor: "Hinox, Co., Ltd."},
  {max_prefix: "00-06-1C",vendor: "Hoshino Metal Industries, Ltd."},
  {max_prefix: "00-05-F5",vendor: "Geospace Technologies"},
  {max_prefix: "00-05-FD",vendor: "PacketLight Networks Ltd."},
  {max_prefix: "00-05-E4",vendor: "Red Lion Controls Inc."},
  {max_prefix: "00-05-F2",vendor: "Power R, Inc."},
  {max_prefix: "00-05-F3",vendor: "Webyn"},
  {max_prefix: "00-06-01",vendor: "Otanikeiki Co., Ltd."},
  {max_prefix: "00-06-67",vendor: "Tripp Lite"},
  {max_prefix: "00-06-60",vendor: "NADEX Co., Ltd."},
  {max_prefix: "00-06-4E",vendor: "Broad Net Technology Inc."},
  {max_prefix: "00-06-2D",vendor: "TouchStar Technologies, L.L.C."},
  {max_prefix: "00-06-3C",vendor: "Intrinsyc Software International Inc."},
  {max_prefix: "00-06-30",vendor: "Adtranz Sweden"},
  {max_prefix: "00-06-37",vendor: "Toptrend-Meta Information (ShenZhen) Inc."},
  {max_prefix: "00-05-DF",vendor: "Electronic Innovation, Inc."},
  {max_prefix: "00-05-E0",vendor: "Empirix Corp."},
  {max_prefix: "00-05-D8",vendor: "Arescom, Inc."},
  {max_prefix: "00-05-DE",vendor: "Gi Fone Korea, Inc."},
  {max_prefix: "00-05-C4",vendor: "Telect, Inc."},
  {max_prefix: "00-06-05",vendor: "Inncom International, Inc."},
  {max_prefix: "00-05-FA",vendor: "IPOptical, Inc."},
  {max_prefix: "00-05-E5",vendor: "Renishaw PLC"},
  {max_prefix: "00-06-23",vendor: "MGE UPS Systems France"},
  {max_prefix: "00-06-0B",vendor: "Artesyn Embedded Technologies"},
  {max_prefix: "00-06-15",vendor: "Kimoto Electric Co., Ltd."},
  {max_prefix: "00-06-0A",vendor: "Blue2space"},
  {max_prefix: "00-06-79",vendor: "Konami Corporation"},
  {max_prefix: "00-06-6C",vendor: "Robinson Corporation"},
  {max_prefix: "00-06-66",vendor: "Roving Networks"},
  {max_prefix: "00-06-46",vendor: "ShenZhen XunBao Network Technology Co Ltd"},
  {max_prefix: "00-06-4B",vendor: "Alexon Co., Ltd."},
  {max_prefix: "00-05-63",vendor: "J-Works, Inc."},
  {max_prefix: "00-05-57",vendor: "Agile TV Corporation"},
  {max_prefix: "00-05-5B",vendor: "Charles Industries, Ltd."},
  {max_prefix: "00-05-54",vendor: "Rangestar Wireless"},
  {max_prefix: "00-05-53",vendor: "DVC Company, Inc."},
  {max_prefix: "00-05-4B",vendor: "Eaton Automation AG"},
  {max_prefix: "00-05-67",vendor: "Etymonic Design, Inc."},
  {max_prefix: "00-05-65",vendor: "Tailyn Communication Company Ltd."},
  {max_prefix: "00-05-66",vendor: "Secui.com Corporation"},
  {max_prefix: "00-05-6C",vendor: "Hung Chang Co., Ltd."},
  {max_prefix: "00-05-5F",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-05-5D",vendor: "D-LINK SYSTEMS, INC."},
  {max_prefix: "00-05-61",vendor: "nac Image Technology, Inc."},
  {max_prefix: "00-05-D4",vendor: "FutureSmart Networks, Inc."},
  {max_prefix: "00-05-CE",vendor: "Prolink Microsystems Corporation"},
  {max_prefix: "00-05-94",vendor: "HMS Industrial Networks"},
  {max_prefix: "00-05-7C",vendor: "RCO Security AB"},
  {max_prefix: "00-05-83",vendor: "ImageCom Limited"},
  {max_prefix: "00-05-6F",vendor: "Innomedia Technologies Pvt. Ltd."},
  {max_prefix: "00-05-74",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-05-9E",vendor: "Zinwell Corporation"},
  {max_prefix: "00-05-8E",vendor: "Flextronics International GmbH & Co. Nfg. KG"},
  {max_prefix: "00-05-C2",vendor: "Soronti, Inc."},
  {max_prefix: "00-05-9D",vendor: "Daniel Computing Systems, Inc."},
  {max_prefix: "00-05-A4",vendor: "Lucid Voice Ltd."},
  {max_prefix: "00-05-B3",vendor: "Asahi-Engineering Co., Ltd."},
  {max_prefix: "00-05-A5",vendor: "KOTT"},
  {max_prefix: "00-05-A3",vendor: "QEI, Inc."},
  {max_prefix: "00-05-42",vendor: "Otari, Inc."},
  {max_prefix: "00-04-F6",vendor: "Amphus"},
  {max_prefix: "00-04-F4",vendor: "Infinite Electronics Inc."},
  {max_prefix: "00-04-F1",vendor: "WhereNet"},
  {max_prefix: "00-05-21",vendor: "Control Microsystems"},
  {max_prefix: "00-05-1F",vendor: "Taijin Media Co., Ltd."},
  {max_prefix: "00-05-23",vendor: "AVL List GmbH"},
  {max_prefix: "00-05-37",vendor: "Nets Technology Co., Ltd."},
  {max_prefix: "00-05-32",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-05-11",vendor: "Complementary Technologies Ltd"},
  {max_prefix: "00-05-06",vendor: "Reddo Networks AB"},
  {max_prefix: "00-05-0A",vendor: "ICS Spa"},
  {max_prefix: "00-04-EC",vendor: "Memobox SA"},
  {max_prefix: "00-04-E4",vendor: "Daeryung Ind., Inc."},
  {max_prefix: "00-05-0C",vendor: "Network Photonics, Inc."},
  {max_prefix: "00-04-E2",vendor: "SMC Networks, Inc."},
  {max_prefix: "00-05-36",vendor: "Danam Communications, Inc."},
  {max_prefix: "00-05-26",vendor: "IPAS GmbH"},
  {max_prefix: "00-04-2F",vendor: "International Communications Products, Inc."},
  {max_prefix: "00-04-29",vendor: "Pixord Corporation"},
  {max_prefix: "00-04-26",vendor: "Autosys"},
  {max_prefix: "00-04-21",vendor: "Ocular Networks"},
  {max_prefix: "00-04-24",vendor: "TMC s.r.l."},
  {max_prefix: "00-04-1D",vendor: "Corega of America"},
  {max_prefix: "00-04-1A",vendor: "Ines Test and Measurement GmbH & CoKG"},
  {max_prefix: "00-04-1E",vendor: "Shikoku Instrumentation Co., Ltd."},
  {max_prefix: "00-04-67",vendor: "Wuhan Research Institute of MII"},
  {max_prefix: "00-04-5A",vendor: "The Linksys Group, Inc."},
  {max_prefix: "00-04-63",vendor: "Bosch Security Systems"},
  {max_prefix: "00-04-5C",vendor: "Mobiwave Pte Ltd"},
  {max_prefix: "00-04-51",vendor: "Medrad, Inc."},
  {max_prefix: "00-04-53",vendor: "YottaYotta, Inc."},
  {max_prefix: "00-04-50",vendor: "DMD Computers SRL"},
  {max_prefix: "00-04-43",vendor: "Agilent Technologies, Inc."},
  {max_prefix: "00-04-49",vendor: "Mapletree Networks"},
  {max_prefix: "00-04-CB",vendor: "Tdsoft Communication, Ltd."},
  {max_prefix: "00-04-CC",vendor: "Peek Traffic B.V."},
  {max_prefix: "00-04-C8",vendor: "LIBA Maschinenfabrik GmbH"},
  {max_prefix: "00-04-BF",vendor: "VersaLogic Corp."},
  {max_prefix: "00-04-C3",vendor: "CASTOR Informatique"},
  {max_prefix: "00-04-B1",vendor: "Signal Technology, Inc."},
  {max_prefix: "00-04-83",vendor: "Deltron Technology, Inc."},
  {max_prefix: "00-04-82",vendor: "Medialogic Corp."},
  {max_prefix: "00-04-15",vendor: "Rasteme Systems Co., Ltd."},
  {max_prefix: "00-04-41",vendor: "Half Dome Systems, Inc."},
  {max_prefix: "00-04-B5",vendor: "Equitrac Corporation"},
  {max_prefix: "00-03-C7",vendor: "hopf Elektronik GmbH"},
  {max_prefix: "00-03-C2",vendor: "Solphone K.K."},
  {max_prefix: "00-03-C6",vendor: "ICUE Systems, Inc."},
  {max_prefix: "00-03-BB",vendor: "Signal Communications Limited"},
  {max_prefix: "00-03-BE",vendor: "Netility"},
  {max_prefix: "00-04-03",vendor: "Nexsi Corporation"},
  {max_prefix: "00-04-06",vendor: "Fa. Metabox AG"},
  {max_prefix: "00-03-F8",vendor: "SanCastle Technologies, Inc."},
  {max_prefix: "00-03-FA",vendor: "TiMetra Networks"},
  {max_prefix: "00-03-FB",vendor: "ENEGATE Co.,Ltd."},
  {max_prefix: "00-03-F6",vendor: "Allegro Networks, Inc."},
  {max_prefix: "00-03-97",vendor: "FireBrick Limited"},
  {max_prefix: "00-03-98",vendor: "WISI"},
  {max_prefix: "00-03-9E",vendor: "Tera System Co., Ltd."},
  {max_prefix: "00-03-92",vendor: "Hyundai Teletek Co., Ltd."},
  {max_prefix: "00-03-95",vendor: "California Amplifier"},
  {max_prefix: "00-03-E5",vendor: "Hermstedt SG"},
  {max_prefix: "00-03-E8",vendor: "Wavesight Limited"},
  {max_prefix: "00-03-DF",vendor: "Desana Systems"},
  {max_prefix: "00-03-DA",vendor: "Takamisawa Cybernetics Co., Ltd."},
  {max_prefix: "00-03-D9",vendor: "Secheron SA"},
  {max_prefix: "00-03-F3",vendor: "Dazzle Multimedia, Inc."},
  {max_prefix: "00-03-EC",vendor: "ICG Research, Inc."},
  {max_prefix: "00-03-E9",vendor: "Akara Canada, Inc."},
  {max_prefix: "00-03-B4",vendor: "Macrotek International Corp."},
  {max_prefix: "00-03-A6",vendor: "Traxit Technology, Inc."},
  {max_prefix: "00-03-A4",vendor: "Imation Corp."},
  {max_prefix: "00-03-AB",vendor: "Bridge Information Systems"},
  {max_prefix: "00-03-CA",vendor: "MTS Systems Corp."},
  {max_prefix: "00-03-CB",vendor: "SystemGear Co., Ltd."},
  {max_prefix: "00-03-7C",vendor: "Coax Media"},
  {max_prefix: "00-03-7F",vendor: "Atheros Communications, Inc."},
  {max_prefix: "00-02-F0",vendor: "AME Optimedia Technology Co., Ltd."},
  {max_prefix: "00-03-8E",vendor: "Atoga Systems, Inc."},
  {max_prefix: "00-03-0D",vendor: "Uniwill Computer Corp."},
  {max_prefix: "00-03-09",vendor: "Texcel Technology PLC"},
  {max_prefix: "00-03-03",vendor: "JAMA Electronics Co., Ltd."},
  {max_prefix: "00-03-05",vendor: "MSC Vertriebs GmbH"},
  {max_prefix: "00-02-FE",vendor: "Viditec, Inc."},
  {max_prefix: "00-01-9F",vendor: "ReadyNet"},
  {max_prefix: "00-02-FF",vendor: "Handan BroadInfoCom"},
  {max_prefix: "00-03-21",vendor: "Reco Research Co., Ltd."},
  {max_prefix: "00-03-16",vendor: "Nobell Communications, Inc."},
  {max_prefix: "00-03-0F",vendor: "Digital China (Shanghai) Networks Ltd."},
  {max_prefix: "00-03-11",vendor: "Micro Technology Co., Ltd."},
  {max_prefix: "00-03-27",vendor: "HMS Industrial Networks"},
  {max_prefix: "00-03-2E",vendor: "Scope Information Management, Ltd."},
  {max_prefix: "00-03-29",vendor: "F3, Inc."},
  {max_prefix: "00-03-1A",vendor: "Beijing Broad Telecom Ltd., China"},
  {max_prefix: "00-03-5B",vendor: "BridgeWave Communications"},
  {max_prefix: "00-03-57",vendor: "Intervoice-Brite, Inc."},
  {max_prefix: "00-03-45",vendor: "Routrek Networks Corporation"},
  {max_prefix: "00-02-E9",vendor: "CS Systemes De Securite - C3S"},
  {max_prefix: "00-02-E5",vendor: "Timeware Ltd."},
  {max_prefix: "00-02-E0",vendor: "ETAS GmbH"},
  {max_prefix: "00-03-3F",vendor: "BigBand Networks, Ltd."},
  {max_prefix: "00-03-68",vendor: "Embedone Co., Ltd."},
  {max_prefix: "00-02-F5",vendor: "VIVE Synergies, Inc."},
  {max_prefix: "00-02-B7",vendor: "Watanabe Electric Industry Co., Ltd."},
  {max_prefix: "00-02-AF",vendor: "TeleCruz Technology, Inc."},
  {max_prefix: "00-02-A8",vendor: "Air Link Technology"},
  {max_prefix: "00-02-AB",vendor: "CTC Union Technologies Co., Ltd."},
  {max_prefix: "00-02-A4",vendor: "AddPac Technology Co., Ltd."},
  {max_prefix: "00-02-A3",vendor: "Hitachi Energy Switzerland Ltd"},
  {max_prefix: "00-02-A0",vendor: "Flatstack Ltd."},
  {max_prefix: "00-02-C8",vendor: "Technocom Communications Technology (pte) Ltd"},
  {max_prefix: "00-02-B8",vendor: "WHI KONSULT AB"},
  {max_prefix: "00-02-BB",vendor: "Continuous Computing Corp"},
  {max_prefix: "00-02-BC",vendor: "LVL 7 Systems, Inc."},
  {max_prefix: "00-02-A9",vendor: "RACOM, s.r.o."},
  {max_prefix: "00-02-B2",vendor: "Cablevision"},
  {max_prefix: "00-02-5B",vendor: "Cambridge Silicon Radio"},
  {max_prefix: "00-02-56",vendor: "Alpha Processor, Inc."},
  {max_prefix: "00-02-59",vendor: "Tsann Kuen China (Shanghai)Enterprise Co., Ltd. IT Group"},
  {max_prefix: "00-02-51",vendor: "Soma Networks, Inc."},
  {max_prefix: "00-02-6A",vendor: "Cocess Telecom Co., Ltd."},
  {max_prefix: "00-02-6C",vendor: "Philips CFT"},
  {max_prefix: "00-02-62",vendor: "Soyo Group Soyo Com Tech Co., Ltd"},
  {max_prefix: "00-02-65",vendor: "Virditech Co. Ltd."},
  {max_prefix: "00-02-8A",vendor: "Ambit Microsystems Corporation"},
  {max_prefix: "00-01-FA",vendor: "HOROSCAS"},
  {max_prefix: "00-02-82",vendor: "ViaClix, Inc."},
  {max_prefix: "00-02-85",vendor: "Riverstone Networks"},
  {max_prefix: "00-02-94",vendor: "Tokyo Sokushin Co., Ltd."},
  {max_prefix: "00-02-96",vendor: "Lectron Co,. Ltd."},
  {max_prefix: "00-02-8E",vendor: "Rapid 5 Networks, Inc."},
  {max_prefix: "00-02-79",vendor: "Control Applications, Ltd."},
  {max_prefix: "00-02-4F",vendor: "IPM Datacom S.R.L."},
  {max_prefix: "00-02-D5",vendor: "ACR"},
  {max_prefix: "00-02-CE",vendor: "FoxJet, Inc."},
  {max_prefix: "00-02-C3",vendor: "Arelnet Ltd."},
  {max_prefix: "00-02-3C",vendor: "Creative Technology, Ltd."},
  {max_prefix: "00-02-2C",vendor: "ABB Bomem, Inc."},
  {max_prefix: "00-01-FF",vendor: "Data Direct Networks, Inc."},
  {max_prefix: "00-01-FC",vendor: "Keyence Corporation"},
  {max_prefix: "00-01-FD",vendor: "Digital Voice Systems, Inc."},
  {max_prefix: "00-01-EE",vendor: "Comtrol Europe, Ltd."},
  {max_prefix: "00-01-F0",vendor: "Tridium, Inc."},
  {max_prefix: "00-01-F1",vendor: "Innovative Concepts, Inc."},
  {max_prefix: "00-01-E2",vendor: "Ando Electric Corporation"},
  {max_prefix: "00-02-2F",vendor: "P-Cube, Ltd."},
  {max_prefix: "00-02-27",vendor: "ESD Electronic System Design GmbH"},
  {max_prefix: "00-02-1D",vendor: "Data General Communication Ltd."},
  {max_prefix: "00-02-19",vendor: "Paralon Technologies"},
  {max_prefix: "00-01-DA",vendor: "WINCOMM Corporation"},
  {max_prefix: "00-01-D2",vendor: "inXtron, Inc."},
  {max_prefix: "00-01-C6",vendor: "Quarry Technologies"},
  {max_prefix: "00-02-10",vendor: "Fenecom"},
  {max_prefix: "00-02-0B",vendor: "Native Networks, Inc."},
  {max_prefix: "00-02-18",vendor: "Advanced Scientific Corp"},
  {max_prefix: "00-02-03",vendor: "Woonsang Telecom, Inc."},
  {max_prefix: "00-01-F5",vendor: "ERIM S.A."},
  {max_prefix: "00-01-CE",vendor: "Custom Micro Products, Ltd."},
  {max_prefix: "00-01-BB",vendor: "Frequentis"},
  {max_prefix: "00-01-BC",vendor: "Brains Corporation"},
  {max_prefix: "00-01-C0",vendor: "CompuLab, Ltd."},
  {max_prefix: "00-01-A0",vendor: "Infinilink Corporation"},
  {max_prefix: "00-01-96",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-01-7C",vendor: "AG-E GmbH"},
  {max_prefix: "00-01-D3",vendor: "PAXCOMM, Inc."},
  {max_prefix: "00-01-E1",vendor: "Kinpo Electronics, Inc."},
  {max_prefix: "00-01-99",vendor: "HeiSei Electronics"},
  {max_prefix: "00-01-10",vendor: "Gotham Networks"},
  {max_prefix: "00-01-12",vendor: "Shark Multimedia Inc."},
  {max_prefix: "00-01-16",vendor: "Netspect Technologies, Inc."},
  {max_prefix: "00-01-08",vendor: "AVLAB Technology, Inc."},
  {max_prefix: "00-30-6C",vendor: "Hitex Holding GmbH"},
  {max_prefix: "00-30-8B",vendor: "Brix Networks"},
  {max_prefix: "00-01-77",vendor: "EDSL"},
  {max_prefix: "00-01-4D",vendor: "Shin Kin Enterprises Co., Ltd"},
  {max_prefix: "00-01-74",vendor: "CyberOptics Corporation"},
  {max_prefix: "00-01-5E",vendor: "BEST TECHNOLOGY CO., LTD."},
  {max_prefix: "00-01-61",vendor: "Meta Machine Technology"},
  {max_prefix: "00-01-55",vendor: "Promise Technology, Inc."},
  {max_prefix: "00-01-A1",vendor: "Mag-Tek, Inc."},
  {max_prefix: "00-01-86",vendor: "Uwe Disch"},
  {max_prefix: "00-01-A6",vendor: "Scientific-Atlanta Arcodan A/S"},
  {max_prefix: "00-01-72",vendor: "TechnoLand Co., LTD."},
  {max_prefix: "00-01-2E",vendor: "PC Partner Ltd."},
  {max_prefix: "00-01-3E",vendor: "Ascom Tateco AB"},
  {max_prefix: "00-01-32",vendor: "Dranetz - BMI"},
  {max_prefix: "00-01-3B",vendor: "BNA SYSTEMS"},
  {max_prefix: "00-01-9D",vendor: "E-Control Systems, Inc."},
  {max_prefix: "00-01-8B",vendor: "NetLinks Co., Ltd."},
  {max_prefix: "00-01-8D",vendor: "AudeSi Technologies"},
  {max_prefix: "00-01-13",vendor: "OLYMPUS CORPORATION"},
  {max_prefix: "00-01-34",vendor: "Selectron Systems AG"},
  {max_prefix: "00-01-1E",vendor: "Precidia Technologies, Inc."},
  {max_prefix: "00-01-6E",vendor: "Conklin Corporation"},
  {max_prefix: "00-30-32",vendor: "MagicRam, Inc."},
  {max_prefix: "00-30-EA",vendor: "TeraForce Technology Corporation"},
  {max_prefix: "00-30-69",vendor: "IMPACCT TECHNOLOGY CORP."},
  {max_prefix: "00-30-C3",vendor: "FLUECKIGER ELEKTRONIK AG"},
  {max_prefix: "00-30-5A",vendor: "TELGEN CORPORATION"},
  {max_prefix: "00-30-9B",vendor: "Smartware"},
  {max_prefix: "00-30-45",vendor: "Village Networks, Inc. (VNI)"},
  {max_prefix: "00-30-E5",vendor: "Amper Datos S.A."},
  {max_prefix: "00-30-94",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-30-40",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-30-8A",vendor: "NICOTRA SISTEMI S.P.A"},
  {max_prefix: "00-06-2B",vendor: "INTRASERVER TECHNOLOGY"},
  {max_prefix: "00-01-00",vendor: "EQUIP'TRANS"},
  {max_prefix: "00-B0-9D",vendor: "Point Grey Research Inc."},
  {max_prefix: "00-B0-6D",vendor: "Jones Futurex Inc."},
  {max_prefix: "00-B0-94",vendor: "Alaris, Inc."},
  {max_prefix: "00-30-72",vendor: "Intellibyte Inc."},
  {max_prefix: "00-30-06",vendor: "SUPERPOWER COMPUTER"},
  {max_prefix: "00-30-38",vendor: "XCP, INC."},
  {max_prefix: "00-30-79",vendor: "CQOS, INC."},
  {max_prefix: "00-30-F0",vendor: "Uniform Industrial Corp."},
  {max_prefix: "00-30-C9",vendor: "LuxN, N"},
  {max_prefix: "00-30-0C",vendor: "CONGRUENCY, LTD."},
  {max_prefix: "00-30-4C",vendor: "APPIAN COMMUNICATIONS, INC."},
  {max_prefix: "00-30-E8",vendor: "ENSIM CORP."},
  {max_prefix: "00-30-28",vendor: "FASE Saldatura srl"},
  {max_prefix: "00-30-D9",vendor: "DATACORE SOFTWARE CORP."},
  {max_prefix: "00-30-26",vendor: "HeiTel Digital Video GmbH"},
  {max_prefix: "00-30-47",vendor: "NISSEI ELECTRIC CO., LTD."},
  {max_prefix: "00-30-77",vendor: "ONPREM NETWORKS"},
  {max_prefix: "00-30-D4",vendor: "AAE Systems, Inc."},
  {max_prefix: "00-30-10",vendor: "VISIONETICS INTERNATIONAL"},
  {max_prefix: "00-30-17",vendor: "BlueArc UK Ltd"},
  {max_prefix: "00-30-F7",vendor: "RAMIX INC."},
  {max_prefix: "00-D0-D7",vendor: "B2C2, INC."},
  {max_prefix: "00-D0-73",vendor: "ACN ADVANCED COMMUNICATIONS"},
  {max_prefix: "00-D0-57",vendor: "ULTRAK, INC."},
  {max_prefix: "00-D0-E5",vendor: "SOLIDUM SYSTEMS CORP."},
  {max_prefix: "00-D0-D3",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-D0-17",vendor: "SYNTECH INFORMATION CO., LTD."},
  {max_prefix: "00-D0-36",vendor: "TECHNOLOGY ATLANTA CORP."},
  {max_prefix: "00-30-AB",vendor: "DELTA NETWORKS, INC."},
  {max_prefix: "00-30-49",vendor: "BRYANT TECHNOLOGY, LTD."},
  {max_prefix: "00-30-6D",vendor: "LUCENT TECHNOLOGIES"},
  {max_prefix: "00-D0-45",vendor: "KVASER AB"},
  {max_prefix: "00-D0-04",vendor: "PENTACOM LTD."},
  {max_prefix: "00-D0-05",vendor: "ZHS ZEITMANAGEMENTSYSTEME"},
  {max_prefix: "00-30-80",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-30-81",vendor: "ALTOS C&C"},
  {max_prefix: "00-30-D0",vendor: "Tellabs"},
  {max_prefix: "00-30-14",vendor: "DIVIO, INC."},
  {max_prefix: "00-30-1C",vendor: "ALTVATER AIRDATA SYSTEMS"},
  {max_prefix: "00-D0-1E",vendor: "PINGTEL CORP."},
  {max_prefix: "00-D0-65",vendor: "TOKO ELECTRIC"},
  {max_prefix: "00-D0-14",vendor: "ROOT, INC."},
  {max_prefix: "00-D0-CA",vendor: "Intrinsyc Software International Inc."},
  {max_prefix: "00-D0-23",vendor: "INFORTREND TECHNOLOGY, INC."},
  {max_prefix: "00-D0-A2",vendor: "INTEGRATED DEVICE"},
  {max_prefix: "00-D0-10",vendor: "CONVERGENT NETWORKS, INC."},
  {max_prefix: "00-D0-4B",vendor: "LA CIE GROUP S.A."},
  {max_prefix: "00-D0-0E",vendor: "PLURIS, INC."},
  {max_prefix: "00-D0-12",vendor: "GATEWORKS CORP."},
  {max_prefix: "00-D0-4D",vendor: "DIV OF RESEARCH & STATISTICS"},
  {max_prefix: "00-D0-2E",vendor: "COMMUNICATION AUTOMATION CORP."},
  {max_prefix: "00-D0-C5",vendor: "COMPUTATIONAL SYSTEMS, INC."},
  {max_prefix: "00-D0-46",vendor: "DOLBY LABORATORIES, INC."},
  {max_prefix: "00-D0-DE",vendor: "PHILIPS MULTIMEDIA NETWORK"},
  {max_prefix: "00-D0-0C",vendor: "SNIJDER MICRO SYSTEMS"},
  {max_prefix: "00-D0-DA",vendor: "TAICOM DATA SYSTEMS CO., LTD."},
  {max_prefix: "00-D0-3C",vendor: "Vieo, Inc."},
  {max_prefix: "00-D0-9A",vendor: "FILANET CORPORATION"},
  {max_prefix: "00-D0-AE",vendor: "ORESIS COMMUNICATIONS, INC."},
  {max_prefix: "00-D0-F2",vendor: "MONTEREY NETWORKS"},
  {max_prefix: "00-D0-A8",vendor: "NETWORK ENGINES, INC."},
  {max_prefix: "00-D0-B4",vendor: "KATSUJIMA CO., LTD."},
  {max_prefix: "00-D0-86",vendor: "FOVEON, INC."},
  {max_prefix: "00-D0-E8",vendor: "MAC SYSTEM CO., LTD."},
  {max_prefix: "00-D0-6B",vendor: "SR TELECOM INC."},
  {max_prefix: "00-D0-DC",vendor: "MODULAR MINING SYSTEMS, INC."},
  {max_prefix: "00-D0-26",vendor: "HIRSCHMANN AUSTRIA GMBH"},
  {max_prefix: "00-D0-F0",vendor: "CONVISION TECHNOLOGY GMBH"},
  {max_prefix: "00-D0-E3",vendor: "ELE-CHEM ENGINEERING CO., LTD."},
  {max_prefix: "00-D0-C4",vendor: "TERATECH CORPORATION"},
  {max_prefix: "00-D0-61",vendor: "TREMON ENTERPRISES CO., LTD."},
  {max_prefix: "00-D0-AB",vendor: "DELTAKABEL TELECOM CV"},
  {max_prefix: "00-D0-1D",vendor: "FURUNO ELECTRIC CO., LTD."},
  {max_prefix: "00-D0-A7",vendor: "TOKYO SOKKI KENKYUJO CO., LTD."},
  {max_prefix: "00-50-76",vendor: "IBM Corp"},
  {max_prefix: "00-50-D4",vendor: "JOOHONG INFORMATION &"},
  {max_prefix: "00-50-A6",vendor: "OPTRONICS"},
  {max_prefix: "00-50-DB",vendor: "CONTEMPORARY CONTROL"},
  {max_prefix: "00-50-7C",vendor: "VIDEOCON AG"},
  {max_prefix: "00-50-47",vendor: "Private"},
  {max_prefix: "00-50-84",vendor: "Quantum Corp."},
  {max_prefix: "00-50-A9",vendor: "MOLDAT WIRELESS TECHNOLGIES"},
  {max_prefix: "00-50-9B",vendor: "SWITCHCORE AB"},
  {max_prefix: "00-50-7E",vendor: "NEWER TECHNOLOGY"},
  {max_prefix: "00-50-CE",vendor: "LG INTERNATIONAL CORP."},
  {max_prefix: "00-50-F7",vendor: "VENTURE MANUFACTURING (SINGAPORE) LTD."},
  {max_prefix: "00-50-19",vendor: "SPRING TIDE NETWORKS, INC."},
  {max_prefix: "00-50-1B",vendor: "ABL CANADA, INC."},
  {max_prefix: "00-50-DC",vendor: "TAS TELEFONBAU A. SCHWABE GMBH & CO. KG"},
  {max_prefix: "00-50-08",vendor: "TIVA MICROCOMPUTER CORP. (TMC)"},
  {max_prefix: "00-50-1F",vendor: "MRG SYSTEMS, LTD."},
  {max_prefix: "00-50-FD",vendor: "VISIONCOMM CO., LTD."},
  {max_prefix: "00-50-BF",vendor: "Metalligence Technology Corp."},
  {max_prefix: "00-50-36",vendor: "NETCAM, LTD."},
  {max_prefix: "00-50-83",vendor: "GILBARCO, INC."},
  {max_prefix: "00-D0-6C",vendor: "SHAREWAVE, INC."},
  {max_prefix: "00-D0-EE",vendor: "DICTAPHONE CORPORATION"},
  {max_prefix: "00-50-4C",vendor: "Galil Motion Control"},
  {max_prefix: "00-D0-34",vendor: "ORMEC SYSTEMS CORP."},
  {max_prefix: "00-D0-8A",vendor: "PHOTRON USA"},
  {max_prefix: "00-50-A7",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-50-43",vendor: "MARVELL SEMICONDUCTOR, INC."},
  {max_prefix: "00-50-FA",vendor: "OXTEL, LTD."},
  {max_prefix: "00-50-55",vendor: "DOMS A/S"},
  {max_prefix: "00-50-72",vendor: "CORVIS CORPORATION"},
  {max_prefix: "00-90-EF",vendor: "INTEGRIX, INC."},
  {max_prefix: "00-90-C5",vendor: "INTERNET MAGIC, INC."},
  {max_prefix: "00-90-8C",vendor: "ETREND ELECTRONICS, INC."},
  {max_prefix: "00-90-48",vendor: "ZEAL CORPORATION"},
  {max_prefix: "00-90-07",vendor: "DOMEX TECHNOLOGY CORP."},
  {max_prefix: "00-90-2D",vendor: "DATA ELECTRONICS (AUST.) PTY, LTD."},
  {max_prefix: "00-50-16",vendor: "Molex Canada Ltd"},
  {max_prefix: "00-50-52",vendor: "TIARA NETWORKS, INC."},
  {max_prefix: "00-50-64",vendor: "CAE ELECTRONICS"},
  {max_prefix: "00-90-3D",vendor: "BIOPAC SYSTEMS, INC."},
  {max_prefix: "00-90-57",vendor: "AANetcom, Inc."},
  {max_prefix: "00-90-83",vendor: "TURBO COMMUNICATION, INC."},
  {max_prefix: "00-90-D7",vendor: "NetBoost Corp."},
  {max_prefix: "00-50-27",vendor: "GENICOM CORPORATION"},
  {max_prefix: "00-50-5A",vendor: "NETWORK ALCHEMY, INC."},
  {max_prefix: "00-50-39",vendor: "MARINER NETWORKS"},
  {max_prefix: "00-50-95",vendor: "PERACOM NETWORKS"},
  {max_prefix: "00-90-B9",vendor: "BERAN INSTRUMENTS LTD."},
  {max_prefix: "00-90-A5",vendor: "SPECTRA LOGIC"},
  {max_prefix: "00-90-A3",vendor: "Corecess Inc."},
  {max_prefix: "00-90-1F",vendor: "ADTEC PRODUCTIONS, INC."},
  {max_prefix: "00-90-38",vendor: "FOUNTAIN TECHNOLOGIES, INC."},
  {max_prefix: "00-90-B0",vendor: "VADEM"},
  {max_prefix: "00-50-5B",vendor: "KAWASAKI LSI U.S.A., INC."},
  {max_prefix: "00-50-CC",vendor: "Seagate Cloud Systems Inc"},
  {max_prefix: "00-90-D4",vendor: "BindView Development Corp."},
  {max_prefix: "00-90-C4",vendor: "JAVELIN SYSTEMS, INC."},
  {max_prefix: "00-50-B8",vendor: "INOVA COMPUTERS GMBH & CO. KG"},
  {max_prefix: "00-90-B6",vendor: "FIBEX SYSTEMS"},
  {max_prefix: "00-90-63",vendor: "COHERENT COMMUNICATIONS SYSTEMS CORPORATION"},
  {max_prefix: "00-90-62",vendor: "ICP VORTEX COMPUTERSYSTEME GmbH"},
  {max_prefix: "00-90-C3",vendor: "TOPIC SEMICONDUCTOR CORP."},
  {max_prefix: "00-90-5A",vendor: "DEARBORN GROUP, INC."},
  {max_prefix: "00-90-EC",vendor: "PYRESCOM"},
  {max_prefix: "00-10-FB",vendor: "ZIDA TECHNOLOGIES LIMITED"},
  {max_prefix: "00-10-53",vendor: "COMPUTER TECHNOLOGY CORP."},
  {max_prefix: "00-10-ED",vendor: "SUNDANCE TECHNOLOGY, INC."},
  {max_prefix: "00-10-9D",vendor: "CLARINET SYSTEMS, INC."},
  {max_prefix: "00-10-0E",vendor: "MICRO LINEAR COPORATION"},
  {max_prefix: "00-90-95",vendor: "UNIVERSAL AVIONICS"},
  {max_prefix: "00-90-41",vendor: "APPLIED DIGITAL ACCESS"},
  {max_prefix: "00-90-24",vendor: "PIPELINKS, INC."},
  {max_prefix: "00-90-3A",vendor: "NIHON MEDIA TOOL INC."},
  {max_prefix: "00-90-B2",vendor: "AVICI SYSTEMS INC."},
  {max_prefix: "00-90-82",vendor: "FORCE INSTITUTE"},
  {max_prefix: "00-90-00",vendor: "DIAMOND MULTIMEDIA"},
  {max_prefix: "00-90-54",vendor: "INNOVATIVE SEMICONDUCTORS, INC"},
  {max_prefix: "00-90-6E",vendor: "PRAXON, INC."},
  {max_prefix: "00-90-F6",vendor: "ESCALATE NETWORKS, INC."},
  {max_prefix: "00-90-A8",vendor: "NineTiles Networks, Ltd."},
  {max_prefix: "00-90-15",vendor: "CENTIGRAM COMMUNICATIONS CORP."},
  {max_prefix: "00-90-29",vendor: "CRYPTO AG"},
  {max_prefix: "00-90-0B",vendor: "LANNER ELECTRONICS, INC."},
  {max_prefix: "00-90-61",vendor: "PACIFIC RESEARCH & ENGINEERING CORPORATION"},
  {max_prefix: "00-90-11",vendor: "WAVTrace, Inc."},
  {max_prefix: "00-90-65",vendor: "FINISAR CORPORATION"},
  {max_prefix: "00-10-D3",vendor: "GRIPS ELECTRONIC GMBH"},
  {max_prefix: "00-90-C0",vendor: "K.J. LAW ENGINEERS, INC."},
  {max_prefix: "00-90-CE",vendor: " avateramedical Mechatronics GmbH"},
  {max_prefix: "00-90-DF",vendor: "MITSUBISHI CHEMICAL AMERICA, INC."},
  {max_prefix: "00-10-C8",vendor: "COMMUNICATIONS ELECTRONICS SECURITY GROUP"},
  {max_prefix: "00-10-86",vendor: "ATTO Technology, Inc."},
  {max_prefix: "00-10-F3",vendor: "Nexcom International Co., Ltd."},
  {max_prefix: "00-10-DF",vendor: "RISE COMPUTER INC."},
  {max_prefix: "00-10-72",vendor: "GVN TECHNOLOGIES, INC."},
  {max_prefix: "00-10-DA",vendor: "Kollmorgen Corp"},
  {max_prefix: "00-10-E4",vendor: "NSI CORPORATION"},
  {max_prefix: "00-10-88",vendor: "AMERICAN NETWORKS INC."},
  {max_prefix: "00-10-7E",vendor: "BACHMANN ELECTRONIC GmbH"},
  {max_prefix: "00-10-A0",vendor: "INNOVEX TECHNOLOGIES, INC."},
  {max_prefix: "00-10-16",vendor: "T.SQWARE"},
  {max_prefix: "00-10-90",vendor: "CIMETRICS, INC."},
  {max_prefix: "00-10-F5",vendor: "AMHERST SYSTEMS, INC."},
  {max_prefix: "00-10-3D",vendor: "PHASECOM, LTD."},
  {max_prefix: "00-10-EA",vendor: "ADEPT TECHNOLOGY"},
  {max_prefix: "00-10-22",vendor: "SatCom Media Corporation"},
  {max_prefix: "00-10-96",vendor: "TRACEWELL SYSTEMS, INC."},
  {max_prefix: "00-10-82",vendor: "JNA TELECOMMUNICATIONS LIMITED"},
  {max_prefix: "00-10-98",vendor: "STARNET TECHNOLOGIES, INC."},
  {max_prefix: "00-10-68",vendor: "COMOS TELECOM"},
  {max_prefix: "00-10-3F",vendor: "TOLLGRADE COMMUNICATIONS, INC."},
  {max_prefix: "00-10-CD",vendor: "INTERFACE CONCEPT"},
  {max_prefix: "00-10-56",vendor: "SODICK CO., LTD."},
  {max_prefix: "00-10-61",vendor: "HOSTLINK CORP."},
  {max_prefix: "00-10-99",vendor: "InnoMedia, Inc."},
  {max_prefix: "00-10-42",vendor: "Alacritech, Inc."},
  {max_prefix: "00-10-E1",vendor: "S.I. TECH, INC."},
  {max_prefix: "00-10-BB",vendor: "DATA & INFORMATION TECHNOLOGY"},
  {max_prefix: "00-10-20",vendor: "Hand Held Products Inc"},
  {max_prefix: "00-10-3A",vendor: "DIAMOND NETWORK TECH"},
  {max_prefix: "00-10-04",vendor: "THE BRANTLEY COILE COMPANY,INC"},
  {max_prefix: "00-10-EF",vendor: "DBTEL INCORPORATED"},
  {max_prefix: "00-10-76",vendor: "EUREM GmbH"},
  {max_prefix: "00-10-AE",vendor: "SHINKO ELECTRIC INDUSTRIES CO."},
  {max_prefix: "00-10-C4",vendor: "MEDIA GLOBAL LINKS CO., LTD."},
  {max_prefix: "00-10-FE",vendor: "DIGITAL EQUIPMENT CORPORATION"},
  {max_prefix: "00-10-6C",vendor: "EDNT GmbH"},
  {max_prefix: "00-10-E9",vendor: "RAIDTEC LTD."},
  {max_prefix: "00-10-03",vendor: "IMATRON, INC."},
  {max_prefix: "00-10-71",vendor: "ADVANET INC."},
  {max_prefix: "00-10-2A",vendor: "ZF MICROSYSTEMS, INC."},
  {max_prefix: "00-10-E5",vendor: "SOLECTRON TEXAS"},
  {max_prefix: "00-10-F7",vendor: "IRIICHI TECHNOLOGIES Inc."},
  {max_prefix: "00-10-AB",vendor: "KOITO ELECTRIC INDUSTRIES, LTD."},
  {max_prefix: "00-10-10",vendor: "INITIO CORPORATION"},
  {max_prefix: "00-10-F2",vendor: "ANTEC"},
  {max_prefix: "00-E0-07",vendor: "Avaya ECS Ltd"},
  {max_prefix: "00-10-BE",vendor: "MARCH NETWORKS CORPORATION"},
  {max_prefix: "00-10-AF",vendor: "TAC SYSTEMS, INC."},
  {max_prefix: "00-10-8C",vendor: "Fujitsu Services Ltd"},
  {max_prefix: "00-10-58",vendor: "ArrowPoint Communications"},
  {max_prefix: "00-10-0F",vendor: "INDUSTRIAL CPU SYSTEMS"},
  {max_prefix: "00-10-BC",vendor: "Aastra Telecom"},
  {max_prefix: "00-10-49",vendor: "ShoreTel, Inc"},
  {max_prefix: "00-10-5E",vendor: "Spirent plc, Service Assurance Broadband"},
  {max_prefix: "00-E0-BF",vendor: "TORRENT NETWORKING TECHNOLOGIES CORP."},
  {max_prefix: "00-E0-E3",vendor: "SK-ELEKTRONIK GMBH"},
  {max_prefix: "00-E0-C6",vendor: "LINK2IT, L.L.C."},
  {max_prefix: "00-E0-E5",vendor: "CINCO NETWORKS, INC."},
  {max_prefix: "00-E0-61",vendor: "EdgePoint Networks, Inc."},
  {max_prefix: "00-E0-B8",vendor: "GATEWAY 2000"},
  {max_prefix: "00-E0-7C",vendor: "METTLER-TOLEDO, INC."},
  {max_prefix: "00-E0-26",vendor: "Redlake MASD LLC"},
  {max_prefix: "00-E0-20",vendor: "TECNOMEN OY"},
  {max_prefix: "00-E0-0D",vendor: "RADIANT SYSTEMS"},
  {max_prefix: "00-E0-DC",vendor: "NEXWARE CORP."},
  {max_prefix: "00-E0-37",vendor: "CENTURY CORPORATION"},
  {max_prefix: "00-E0-53",vendor: "CELLPORT LABS, INC."},
  {max_prefix: "00-E0-D3",vendor: "DATENTECHNIK GmbH"},
  {max_prefix: "00-E0-43",vendor: "VitalCom"},
  {max_prefix: "00-E0-B3",vendor: "EtherWAN Systems, Inc."},
  {max_prefix: "00-E0-ED",vendor: "SILICOM, LTD."},
  {max_prefix: "00-E0-FB",vendor: "LEIGHTRONIX, INC."},
  {max_prefix: "00-E0-C2",vendor: "NECSY S.p.A."},
  {max_prefix: "00-E0-9B",vendor: "ENGAGE NETWORKS, INC."},
  {max_prefix: "00-E0-45",vendor: "TOUCHWAVE, INC."},
  {max_prefix: "00-E0-40",vendor: "DeskStation Technology, Inc."},
  {max_prefix: "00-E0-1A",vendor: "COMTEC SYSTEMS. CO., LTD."},
  {max_prefix: "00-E0-81",vendor: "TYAN COMPUTER CORP."},
  {max_prefix: "00-E0-57",vendor: "HAN MICROTELECOM. CO., LTD."},
  {max_prefix: "00-E0-BC",vendor: "SYMON COMMUNICATIONS, INC."},
  {max_prefix: "00-E0-7E",vendor: "WALT DISNEY IMAGINEERING"},
  {max_prefix: "00-E0-78",vendor: "BERKELEY NETWORKS"},
  {max_prefix: "00-E0-87",vendor: "LeCroy - Networking Productions Division"},
  {max_prefix: "00-E0-41",vendor: "CSPI"},
  {max_prefix: "00-E0-E2",vendor: "INNOVA CORP."},
  {max_prefix: "00-E0-82",vendor: "ANERMA"},
  {max_prefix: "00-E0-77",vendor: "WEBGEAR, INC."},
  {max_prefix: "00-E0-56",vendor: "HOLONTECH CORPORATION"},
  {max_prefix: "00-E0-2F",vendor: "MCNS HOLDINGS, L.P."},
  {max_prefix: "00-E0-6C",vendor: "Ultra Electronics Command & Control Systems"},
  {max_prefix: "00-E0-4A",vendor: "ZX Technologies, Inc"},
  {max_prefix: "00-E0-31",vendor: "HAGIWARA ELECTRIC CO., LTD."},
  {max_prefix: "00-E0-0B",vendor: "ROOFTOP COMMUNICATIONS CORP."},
  {max_prefix: "00-E0-B2",vendor: "TELMAX COMMUNICATIONS CORP."},
  {max_prefix: "00-E0-EC",vendor: "CELESTICA INC."},
  {max_prefix: "00-E0-7A",vendor: "MIKRODIDAKT AB"},
  {max_prefix: "00-E0-99",vendor: "SAMSON AG"},
  {max_prefix: "00-60-22",vendor: "VICOM SYSTEMS, INC."},
  {max_prefix: "00-60-EE",vendor: "APOLLO"},
  {max_prefix: "00-60-D8",vendor: "ELMIC SYSTEMS, INC."},
  {max_prefix: "00-60-EF",vendor: "FLYTECH TECHNOLOGY CO., LTD."},
  {max_prefix: "00-60-85",vendor: "Storage Concepts"},
  {max_prefix: "00-60-53",vendor: "TOYODA MACHINE WORKS, LTD."},
  {max_prefix: "00-60-56",vendor: "NETWORK TOOLS, INC."},
  {max_prefix: "00-60-0C",vendor: "Eurotech Inc."},
  {max_prefix: "00-60-32",vendor: "I-CUBE, INC."},
  {max_prefix: "00-60-33",vendor: "ACUITY IMAGING, INC."},
  {max_prefix: "00-60-13",vendor: "NETSTAL MASCHINEN AG"},
  {max_prefix: "00-02-88",vendor: "GLOBAL VILLAGE COMMUNICATION"},
  {max_prefix: "00-60-34",vendor: "ROBERT BOSCH GmbH"},
  {max_prefix: "00-60-50",vendor: "INTERNIX INC."},
  {max_prefix: "00-60-E0",vendor: "AXIOM TECHNOLOGY CO., LTD."},
  {max_prefix: "00-60-96",vendor: "T.S. MICROTECH INC."},
  {max_prefix: "00-60-1D",vendor: "LUCENT TECHNOLOGIES"},
  {max_prefix: "00-60-7B",vendor: "FORE SYSTEMS, INC."},
  {max_prefix: "00-60-11",vendor: "CRYSTAL SEMICONDUCTOR CORP."},
  {max_prefix: "00-60-FA",vendor: "EDUCATIONAL TECHNOLOGY RESOURCES, INC."},
  {max_prefix: "00-60-DA",vendor: "Red Lion Controls, LP"},
  {max_prefix: "00-60-AE",vendor: "TRIO INFORMATION SYSTEMS AB"},
  {max_prefix: "00-60-43",vendor: "iDirect, INC."},
  {max_prefix: "00-60-3A",vendor: "QUICK CONTROLS LTD."},
  {max_prefix: "00-60-28",vendor: "MACROVISION CORPORATION"},
  {max_prefix: "00-60-1C",vendor: "TELXON CORPORATION"},
  {max_prefix: "00-60-C9",vendor: "ControlNet, Inc."},
  {max_prefix: "00-60-5F",vendor: "NIPPON UNISOFT CORPORATION"},
  {max_prefix: "00-60-91",vendor: "FIRST PACIFIC NETWORKS, INC."},
  {max_prefix: "00-A0-61",vendor: "PURITAN BENNETT"},
  {max_prefix: "00-A0-3C",vendor: "EG&G NUCLEAR INSTRUMENTS"},
  {max_prefix: "00-A0-C4",vendor: "CRISTIE ELECTRONICS LTD."},
  {max_prefix: "00-A0-63",vendor: "JRL SYSTEMS, INC."},
  {max_prefix: "00-60-45",vendor: "PATHLIGHT TECHNOLOGIES"},
  {max_prefix: "00-A0-5D",vendor: "CS COMPUTER SYSTEME GmbH"},
  {max_prefix: "00-A0-33",vendor: "imc MeBsysteme GmbH"},
  {max_prefix: "00-A0-A9",vendor: "NAVTEL COMMUNICATIONS INC."},
  {max_prefix: "00-A0-71",vendor: "VIDEO LOTTERY TECHNOLOGIES,INC"},
  {max_prefix: "00-A0-EA",vendor: "ETHERCOM CORP."},
  {max_prefix: "00-A0-DC",vendor: "O.N. ELECTRONIC CO., LTD."},
  {max_prefix: "00-A0-0B",vendor: "COMPUTEX CO., LTD."},
  {max_prefix: "00-A0-E2",vendor: "Keisokugiken Corporation"},
  {max_prefix: "00-60-F0",vendor: "JOHNSON & JOHNSON MEDICAL, INC"},
  {max_prefix: "00-60-F5",vendor: "ICON WEST, INC."},
  {max_prefix: "00-60-62",vendor: "TELESYNC, INC."},
  {max_prefix: "00-60-E4",vendor: "COMPUSERVE, INC."},
  {max_prefix: "00-60-BE",vendor: "WEBTRONICS"},
  {max_prefix: "00-60-BF",vendor: "MACRAIGOR SYSTEMS, INC."},
  {max_prefix: "00-60-00",vendor: "XYCOM INC."},
  {max_prefix: "00-60-A6",vendor: "PARTICLE MEASURING SYSTEMS"},
  {max_prefix: "00-60-2A",vendor: "SYMICRON COMPUTER COMMUNICATIONS, LTD."},
  {max_prefix: "00-A0-6D",vendor: "MANNESMANN TALLY CORPORATION"},
  {max_prefix: "00-A0-F6",vendor: "AutoGas Systems Inc."},
  {max_prefix: "00-60-8F",vendor: "TEKRAM TECHNOLOGY CO., LTD."},
  {max_prefix: "00-60-C4",vendor: "SOLITON SYSTEMS K.K."},
  {max_prefix: "00-60-80",vendor: "MICROTRONIX DATACOM LTD."},
  {max_prefix: "00-A0-37",vendor: "Mindray DS USA, Inc."},
  {max_prefix: "00-A0-4C",vendor: "INNOVATIVE SYSTEMS & TECHNOLOGIES, INC."},
  {max_prefix: "00-A0-31",vendor: "HAZELTINE CORPORATION, MS 1-17"},
  {max_prefix: "00-A0-41",vendor: "INFICON"},
  {max_prefix: "00-A0-1A",vendor: "BINAR ELEKTRONIK AB"},
  {max_prefix: "00-A0-88",vendor: "ESSENTIAL COMMUNICATIONS"},
  {max_prefix: "00-A0-C2",vendor: "R.A. SYSTEMS CO., LTD."},
  {max_prefix: "00-A0-4B",vendor: "TFL LAN INC."},
  {max_prefix: "00-A0-64",vendor: "KVB/ANALECT"},
  {max_prefix: "00-A0-3E",vendor: "ATM FORUM"},
  {max_prefix: "00-A0-98",vendor: "NetApp"},
  {max_prefix: "00-A0-6C",vendor: "SHINDENGEN ELECTRIC MFG. CO., LTD."},
  {max_prefix: "00-A0-1F",vendor: "TRICORD SYSTEMS, INC."},
  {max_prefix: "00-A0-FB",vendor: "Toray Engineering D Solutions Co., Ltd."},
  {max_prefix: "00-A0-7E",vendor: "AVID TECHNOLOGY, INC."},
  {max_prefix: "00-A0-6F",vendor: "Color Sentinel Systems, LLC"},
  {max_prefix: "00-A0-C7",vendor: "TADIRAN TELECOMMUNICATIONS"},
  {max_prefix: "00-A0-2C",vendor: "interWAVE Communications"},
  {max_prefix: "00-A0-F7",vendor: "V.I COMPUTER CORP."},
  {max_prefix: "00-A0-90",vendor: "TimeStep Corporation"},
  {max_prefix: "00-A0-A7",vendor: "VORAX CORPORATION"},
  {max_prefix: "00-A0-8B",vendor: "ASTON ELECTRONIC DESIGNS LTD."},
  {max_prefix: "00-A0-B1",vendor: "FIRST VIRTUAL CORPORATION"},
  {max_prefix: "00-20-10",vendor: "JEOL SYSTEM TECHNOLOGY CO. LTD"},
  {max_prefix: "00-20-9F",vendor: "MERCURY COMPUTER SYSTEMS, INC."},
  {max_prefix: "00-20-91",vendor: "J125, NATIONAL SECURITY AGENCY"},
  {max_prefix: "00-20-BD",vendor: "NIOBRARA R & D CORPORATION"},
  {max_prefix: "00-20-54",vendor: "Sycamore Networks"},
  {max_prefix: "00-20-A7",vendor: "PAIRGAIN TECHNOLOGIES, INC."},
  {max_prefix: "00-20-72",vendor: "WORKLINK INNOVATIONS"},
  {max_prefix: "00-20-CB",vendor: "PRETEC ELECTRONICS CORP."},
  {max_prefix: "00-20-EB",vendor: "CINCINNATI MICROWAVE, INC."},
  {max_prefix: "00-20-A0",vendor: "OA LABORATORY CO., LTD."},
  {max_prefix: "00-20-0D",vendor: "CARL ZEISS"},
  {max_prefix: "00-20-2D",vendor: "TAIYO CORPORATION"},
  {max_prefix: "00-A0-DB",vendor: "FISHER & PAYKEL PRODUCTION"},
  {max_prefix: "00-A0-97",vendor: "JC INFORMATION SYSTEMS"},
  {max_prefix: "00-A0-73",vendor: "COM21, INC."},
  {max_prefix: "00-A0-3A",vendor: "KUBOTEK CORPORATION"},
  {max_prefix: "00-A0-B2",vendor: "SHIMA SEIKI"},
  {max_prefix: "00-A0-27",vendor: "FIREPOWER SYSTEMS, INC."},
  {max_prefix: "00-A0-81",vendor: "ALCATEL DATA NETWORKS"},
  {max_prefix: "00-A0-D4",vendor: "RADIOLAN,  INC."},
  {max_prefix: "00-20-B9",vendor: "METRICOM, INC."},
  {max_prefix: "00-20-39",vendor: "SCINETS"},
  {max_prefix: "00-A0-46",vendor: "SCITEX CORP. LTD."},
  {max_prefix: "00-A0-92",vendor: "H. BOLLMANN MANUFACTURERS, LTD"},
  {max_prefix: "00-20-EC",vendor: "TECHWARE SYSTEMS CORP."},
  {max_prefix: "00-20-6E",vendor: "XACT, INC."},
  {max_prefix: "00-20-F1",vendor: "ALTOS INDIA LIMITED"},
  {max_prefix: "00-20-41",vendor: "DATA NET"},
  {max_prefix: "00-20-76",vendor: "REUDO CORPORATION"},
  {max_prefix: "00-20-E8",vendor: "DATATREK CORPORATION"},
  {max_prefix: "00-20-C5",vendor: "EAGLE TECHNOLOGY"},
  {max_prefix: "00-20-E2",vendor: "INFORMATION RESOURCE ENGINEERING"},
  {max_prefix: "00-20-07",vendor: "SFA, INC."},
  {max_prefix: "00-20-5C",vendor: "InterNet Systems of Florida, Inc."},
  {max_prefix: "00-20-55",vendor: "ALTECH CO., LTD."},
  {max_prefix: "00-20-0A",vendor: "SOURCE-COMM CORP."},
  {max_prefix: "00-20-CF",vendor: "TEST & MEASUREMENT SYSTEMS INC"},
  {max_prefix: "00-20-A5",vendor: "API ENGINEERING"},
  {max_prefix: "00-20-64",vendor: "PROTEC MICROSYSTEMS, INC."},
  {max_prefix: "00-20-33",vendor: "SYNAPSE TECHNOLOGIES, INC."},
  {max_prefix: "00-20-EA",vendor: "EFFICIENT NETWORKS, INC."},
  {max_prefix: "00-20-6A",vendor: "OSAKA COMPUTER CORP."},
  {max_prefix: "00-20-B4",vendor: "TERMA ELEKTRONIK AS"},
  {max_prefix: "00-20-E4",vendor: "HSING TECH ENTERPRISE CO., LTD"},
  {max_prefix: "00-20-A2",vendor: "GALCOM NETWORKING LTD."},
  {max_prefix: "00-20-31",vendor: "Tattile SRL"},
  {max_prefix: "00-20-D0",vendor: "VERSALYNX CORPORATION"},
  {max_prefix: "00-20-6C",vendor: "EVERGREEN TECHNOLOGY CORP."},
  {max_prefix: "00-20-5E",vendor: "CASTLE ROCK, INC."},
  {max_prefix: "00-20-12",vendor: "CAMTRONICS MEDICAL SYSTEMS"},
  {max_prefix: "00-20-75",vendor: "MOTOROLA COMMUNICATION ISRAEL"},
  {max_prefix: "00-20-BB",vendor: "ZAX CORPORATION"},
  {max_prefix: "00-20-A8",vendor: "SAST TECHNOLOGY CORP."},
  {max_prefix: "00-20-45",vendor: "ION Networks, Inc."},
  {max_prefix: "00-20-8A",vendor: "SONIX COMMUNICATIONS, LTD."},
  {max_prefix: "00-20-21",vendor: "ALGORITHMS SOFTWARE PVT. LTD."},
  {max_prefix: "00-20-49",vendor: "COMTRON, INC."},
  {max_prefix: "00-20-50",vendor: "KOREA COMPUTER INC."},
  {max_prefix: "00-20-84",vendor: "OCE PRINTING SYSTEMS, GMBH"},
  {max_prefix: "00-20-09",vendor: "PACKARD BELL ELEC., INC."},
  {max_prefix: "00-20-27",vendor: "MING FORTUNE INDUSTRY CO., LTD"},
  {max_prefix: "00-20-2C",vendor: "WELLTRONIX CO., LTD."},
  {max_prefix: "00-20-DB",vendor: "XNET TECHNOLOGY, INC."},
  {max_prefix: "00-1C-7C",vendor: "PERQ SYSTEMS CORPORATION"},
  {max_prefix: "00-C0-39",vendor: "Teridian Semiconductor Corporation"},
  {max_prefix: "00-C0-A9",vendor: "BARRON MCCANN LTD."},
  {max_prefix: "00-C0-19",vendor: "LEAP TECHNOLOGY, INC."},
  {max_prefix: "00-C0-CF",vendor: "IMATRAN VOIMA OY"},
  {max_prefix: "00-C0-7D",vendor: "RISC DEVELOPMENTS LTD."},
  {max_prefix: "00-C0-B5",vendor: "CORPORATE NETWORK SYSTEMS,INC."},
  {max_prefix: "00-C0-4B",vendor: "CREATIVE MICROSYSTEMS"},
  {max_prefix: "00-C0-B9",vendor: "FUNK SOFTWARE, INC."},
  {max_prefix: "00-C0-15",vendor: "NEW MEDIA CORPORATION"},
  {max_prefix: "00-C0-83",vendor: "TRACE MOUNTAIN PRODUCTS, INC."},
  {max_prefix: "00-C0-94",vendor: "VMX INC."},
  {max_prefix: "00-C0-F9",vendor: "Artesyn Embedded Technologies"},
  {max_prefix: "00-C0-75",vendor: "XANTE CORPORATION"},
  {max_prefix: "00-C0-5B",vendor: "NETWORKS NORTHWEST, INC."},
  {max_prefix: "00-C0-08",vendor: "SECO SRL"},
  {max_prefix: "00-C0-B7",vendor: "AMERICAN POWER CONVERSION CORP"},
  {max_prefix: "00-C0-FC",vendor: "ELASTIC REALITY, INC."},
  {max_prefix: "00-C0-BB",vendor: "FORVAL CREATIVE, INC."},
  {max_prefix: "00-20-D2",vendor: "RAD DATA COMMUNICATIONS, LTD."},
  {max_prefix: "00-20-02",vendor: "SERITECH ENTERPRISE CO., LTD."},
  {max_prefix: "00-20-4B",vendor: "AUTOCOMPUTER CO., LTD."},
  {max_prefix: "00-20-8C",vendor: "GALAXY NETWORKS, INC."},
  {max_prefix: "00-20-A6",vendor: "Proxim Wireless"},
  {max_prefix: "00-C0-43",vendor: "STRATACOM"},
  {max_prefix: "00-C0-28",vendor: "JASCO CORPORATION"},
  {max_prefix: "00-C0-8D",vendor: "TRONIX PRODUCT DEVELOPMENT"},
  {max_prefix: "00-C0-2A",vendor: "OHKURA ELECTRIC CO., LTD."},
  {max_prefix: "00-C0-EF",vendor: "ABIT CORPORATION"},
  {max_prefix: "00-C0-61",vendor: "SOLECTEK CORPORATION"},
  {max_prefix: "00-C0-AD",vendor: "MARBEN COMMUNICATION SYSTEMS"},
  {max_prefix: "00-C0-7F",vendor: "NUPON COMPUTING CORP."},
  {max_prefix: "00-C0-57",vendor: "MYCO ELECTRONICS"},
  {max_prefix: "00-C0-56",vendor: "SOMELEC"},
  {max_prefix: "00-C0-27",vendor: "CIPHER SYSTEMS, INC."},
  {max_prefix: "00-C0-5C",vendor: "ELONEX PLC"},
  {max_prefix: "00-C0-ED",vendor: "US ARMY ELECTRONIC"},
  {max_prefix: "00-40-BD",vendor: "STARLIGHT NETWORKS, INC."},
  {max_prefix: "00-40-ED",vendor: "NETWORK CONTROLS INT'NATL INC."},
  {max_prefix: "00-40-E5",vendor: "SYBUS CORPORATION"},
  {max_prefix: "00-40-A5",vendor: "CLINICOMP INTL."},
  {max_prefix: "00-40-05",vendor: "ANI COMMUNICATIONS INC."},
  {max_prefix: "00-C0-30",vendor: "INTEGRATED ENGINEERING B. V."},
  {max_prefix: "00-C0-A6",vendor: "EXICOM AUSTRALIA PTY. LTD"},
  {max_prefix: "00-C0-CB",vendor: "CONTROL TECHNOLOGY CORPORATION"},
  {max_prefix: "00-C0-D1",vendor: "COMTREE TECHNOLOGY CORPORATION"},
  {max_prefix: "00-C0-38",vendor: "RASTER IMAGE PROCESSING SYSTEM"},
  {max_prefix: "00-C0-92",vendor: "MENNEN MEDICAL INC."},
  {max_prefix: "00-C0-52",vendor: "BURR-BROWN"},
  {max_prefix: "00-C0-EB",vendor: "SEH COMPUTERTECHNIK GMBH"},
  {max_prefix: "00-40-DB",vendor: "ADVANCED TECHNICAL SOLUTIONS"},
  {max_prefix: "00-40-9B",vendor: "HAL COMPUTER SYSTEMS INC."},
  {max_prefix: "00-40-EB",vendor: "MARTIN MARIETTA CORPORATION"},
  {max_prefix: "00-C0-32",vendor: "I-CUBED LIMITED"},
  {max_prefix: "00-C0-A5",vendor: "DICKENS DATA SYSTEMS"},
  {max_prefix: "00-C0-D3",vendor: "OLYMPUS IMAGE SYSTEMS, INC."},
  {max_prefix: "00-C0-E8",vendor: "PLEXCOM, INC."},
  {max_prefix: "00-40-0E",vendor: "MEMOTEC, INC."},
  {max_prefix: "00-C0-3D",vendor: "WIESEMANN & THEIS GMBH"},
  {max_prefix: "00-40-4C",vendor: "HYPERTEC PTY LTD."},
  {max_prefix: "00-C0-E0",vendor: "DSC COMMUNICATION CORP."},
  {max_prefix: "00-C0-DA",vendor: "NICE SYSTEMS LTD."},
  {max_prefix: "00-40-C8",vendor: "MILAN TECHNOLOGY CORPORATION"},
  {max_prefix: "00-40-BA",vendor: "ALLIANT COMPUTER SYSTEMS CORP."},
  {max_prefix: "00-40-CE",vendor: "NET-SOURCE, INC."},
  {max_prefix: "00-40-62",vendor: "E-SYSTEMS, INC./GARLAND DIV."},
  {max_prefix: "00-40-D9",vendor: "AMERICAN MEGATRENDS INC."},
  {max_prefix: "00-40-21",vendor: "RASTER GRAPHICS"},
  {max_prefix: "00-40-C1",vendor: "BIZERBA-WERKE WILHEIM KRAUT"},
  {max_prefix: "00-40-E1",vendor: "MARNER INTERNATIONAL, INC."},
  {max_prefix: "00-40-FE",vendor: "SYMPLEX COMMUNICATIONS"},
  {max_prefix: "00-40-D4",vendor: "GAGE TALKER CORP."},
  {max_prefix: "00-40-38",vendor: "TALENT ELECTRIC INCORPORATED"},
  {max_prefix: "00-40-D8",vendor: "OCEAN OFFICE AUTOMATION LTD."},
  {max_prefix: "00-40-C6",vendor: "FIBERNET RESEARCH, INC."},
  {max_prefix: "00-40-32",vendor: "DIGITAL COMMUNICATIONS"},
  {max_prefix: "00-40-C2",vendor: "APPLIED COMPUTING DEVICES"},
  {max_prefix: "00-40-88",vendor: "MOBIUS TECHNOLOGIES, INC."},
  {max_prefix: "00-80-AA",vendor: "MAXPEED"},
  {max_prefix: "00-C0-50",vendor: "TOYO DENKI SEIZO K.K."},
  {max_prefix: "00-40-1C",vendor: "AST RESEARCH, INC."},
  {max_prefix: "00-40-0F",vendor: "DATACOM TECHNOLOGIES"},
  {max_prefix: "00-40-06",vendor: "SAMPO TECHNOLOGY CORPORATION"},
  {max_prefix: "00-40-34",vendor: "BUSTEK CORPORATION"},
  {max_prefix: "00-80-93",vendor: "XYRON CORPORATION"},
  {max_prefix: "00-80-92",vendor: "Silex Technology, Inc."},
  {max_prefix: "00-80-5A",vendor: "TULIP COMPUTERS INTERNAT'L B.V"},
  {max_prefix: "00-80-7E",vendor: "SOUTHERN PACIFIC LTD."},
  {max_prefix: "00-80-EF",vendor: "RATIONAL"},
  {max_prefix: "00-80-F0",vendor: "Panasonic Communications Co., Ltd."},
  {max_prefix: "00-80-51",vendor: "FIBERMUX"},
  {max_prefix: "00-80-C6",vendor: "NATIONAL DATACOMM CORPORATION"},
  {max_prefix: "00-40-50",vendor: "IRONICS, INCORPORATED"},
  {max_prefix: "00-40-47",vendor: "WIND RIVER SYSTEMS"},
  {max_prefix: "00-40-41",vendor: "FUJIKURA LTD."},
  {max_prefix: "00-80-00",vendor: "MULTITECH SYSTEMS, INC."},
  {max_prefix: "00-80-69",vendor: "COMPUTONE SYSTEMS"},
  {max_prefix: "00-80-35",vendor: "TECHNOLOGY WORKS, INC."},
  {max_prefix: "00-80-4E",vendor: "APEX COMPUTER COMPANY"},
  {max_prefix: "00-80-55",vendor: "FERMILAB"},
  {max_prefix: "00-80-2A",vendor: "TEST SYSTEMS & SIMULATIONS INC"},
  {max_prefix: "00-80-1D",vendor: "INTEGRATED INFERENCE MACHINES"},
  {max_prefix: "00-80-C0",vendor: "PENRIL DATACOMM"},
  {max_prefix: "00-80-75",vendor: "PARSYTEC GMBH"},
  {max_prefix: "00-80-ED",vendor: "IQ TECHNOLOGIES, INC."},
  {max_prefix: "00-80-9A",vendor: "NOVUS NETWORKS LTD"},
  {max_prefix: "00-80-4A",vendor: "PRO-LOG"},
  {max_prefix: "00-80-04",vendor: "ANTLOW COMMUNICATIONS, LTD."},
  {max_prefix: "00-80-D0",vendor: "COMPUTER PERIPHERALS, INC."},
  {max_prefix: "00-80-24",vendor: "KALPANA, INC."},
  {max_prefix: "00-80-40",vendor: "JOHN FLUKE MANUFACTURING CO."},
  {max_prefix: "00-80-21",vendor: "Alcatel Canada Inc."},
  {max_prefix: "00-80-E8",vendor: "CUMULUS CORPORATIION"},
  {max_prefix: "00-00-66",vendor: "TALARIS SYSTEMS, INC."},
  {max_prefix: "00-00-49",vendor: "APRICOT COMPUTERS, LTD"},
  {max_prefix: "00-00-FA",vendor: "MICROSAGE COMPUTER SYSTEMS INC"},
  {max_prefix: "00-00-D4",vendor: "PURE DATA LTD."},
  {max_prefix: "00-00-19",vendor: "APPLIED DYNAMICS INTERNATIONAL"},
  {max_prefix: "00-00-AB",vendor: "LOGIC MODELING CORPORATION"},
  {max_prefix: "00-80-F2",vendor: "RAYCOM SYSTEMS INC"},
  {max_prefix: "00-80-BD",vendor: "THE FURUKAWA ELECTRIC CO., LTD"},
  {max_prefix: "00-80-25",vendor: "Telit Wireless Solutions GmbH"},
  {max_prefix: "00-80-EA",vendor: "ADVA Optical Networking Ltd."},
  {max_prefix: "00-80-0D",vendor: "VOSSWINKEL F.U."},
  {max_prefix: "00-80-D1",vendor: "KIMTRON CORPORATION"},
  {max_prefix: "00-00-1E",vendor: "TELSIST INDUSTRIA ELECTRONICA"},
  {max_prefix: "00-00-50",vendor: "RADISYS CORPORATION"},
  {max_prefix: "00-80-2E",vendor: "CASTLE ROCK COMPUTING"},
  {max_prefix: "00-00-4F",vendor: "LOGICRAFT, INC."},
  {max_prefix: "00-00-15",vendor: "DATAPOINT CORPORATION"},
  {max_prefix: "00-00-1C",vendor: "BELL TECHNOLOGIES"},
  {max_prefix: "00-00-34",vendor: "NETWORK RESOURCES CORPORATION"},
  {max_prefix: "00-00-22",vendor: "VISUAL TECHNOLOGY INC."},
  {max_prefix: "00-00-B5",vendor: "DATABILITY SOFTWARE SYS. INC."},
  {max_prefix: "00-00-E0",vendor: "QUADRAM CORP."},
  {max_prefix: "00-00-27",vendor: "JAPAN RADIO COMPANY"},
  {max_prefix: "00-00-E8",vendor: "ACCTON TECHNOLOGY CORP."},
  {max_prefix: "00-00-2F",vendor: "TIMEPLEX INC."},
  {max_prefix: "00-00-E6",vendor: "APTOR PRODUITS DE COMM INDUST"},
  {max_prefix: "00-00-9A",vendor: "RC COMPUTER A/S"},
  {max_prefix: "00-00-4B",vendor: "ICL DATA OY"},
  {max_prefix: "00-80-42",vendor: "Artesyn Embedded Technologies"},
  {max_prefix: "00-80-AC",vendor: "IMLOGIX, DIVISION OF GENESYS"},
  {max_prefix: "00-00-80",vendor: "CRAY COMMUNICATIONS A/S"},
  {max_prefix: "08-00-89",vendor: "Kinetics"},
  {max_prefix: "08-00-86",vendor: "KONICA MINOLTA HOLDINGS, INC."},
  {max_prefix: "08-00-83",vendor: "Seiko Instruments Inc."},
  {max_prefix: "00-00-73",vendor: "SIECOR CORPORATION"},
  {max_prefix: "00-00-B9",vendor: "MCDONNELL DOUGLAS COMPUTER SYS"},
  {max_prefix: "00-00-BF",vendor: "SYMMETRIC COMPUTER SYSTEMS"},
  {max_prefix: "00-00-2D",vendor: "CHROMATICS INC"},
  {max_prefix: "00-00-59",vendor: "Hellige GMBH"},
  {max_prefix: "00-00-69",vendor: "CONCORD COMMUNICATIONS INC"},
  {max_prefix: "00-00-E7",vendor: "Star Gate Technologies"},
  {max_prefix: "00-00-4D",vendor: "DCI CORPORATION"},
  {max_prefix: "00-00-6F",vendor: "Madge Ltd."},
  {max_prefix: "00-00-78",vendor: "LABTAM LIMITED"},
  {max_prefix: "00-00-5A",vendor: "SysKonnect GmbH"},
  {max_prefix: "00-00-71",vendor: "ADRA SYSTEMS INC."},
  {max_prefix: "00-00-23",vendor: "ABB INDUSTRIAL SYSTEMS AB"},
  {max_prefix: "00-00-18",vendor: "WEBSTER COMPUTER CORPORATION"},
  {max_prefix: "00-00-D5",vendor: "MICROGNOSIS INTERNATIONAL"},
  {max_prefix: "00-00-3A",vendor: "CHYRON CORPORATION"},
  {max_prefix: "00-00-BE",vendor: "THE NTI GROUP"},
  {max_prefix: "00-00-D9",vendor: "NIPPON TELEGRAPH & TELEPHONE"},
  {max_prefix: "08-00-24",vendor: "10NET COMMUNICATIONS/DCA"},
  {max_prefix: "08-00-22",vendor: "NBI INC."},
  {max_prefix: "08-00-20",vendor: "Oracle Corporation"},
  {max_prefix: "08-00-1F",vendor: "SHARP CORPORATION"},
  {max_prefix: "02-07-01",vendor: "RACAL-DATACOM"},
  {max_prefix: "08-00-06",vendor: "SIEMENS AG"},
  {max_prefix: "08-00-2A",vendor: "MOSAIC TECHNOLOGIES INC."},
  {max_prefix: "08-00-13",vendor: "Exxon"},
  {max_prefix: "02-1C-7C",vendor: "PERQ SYSTEMS CORPORATION"},
  {max_prefix: "08-00-61",vendor: "JAROGATE LTD."},
  {max_prefix: "08-00-5F",vendor: "SABER TECHNOLOGY CORP."},
  {max_prefix: "08-00-58",vendor: "SYSTEMS CONCEPTS"},
  {max_prefix: "04-E0-C4",vendor: "TRIUMPH-ADLER AG"},
  {max_prefix: "08-00-49",vendor: "UNIVATION"},
  {max_prefix: "00-00-05",vendor: "XEROX CORPORATION"},
  {max_prefix: "00-DD-08",vendor: "UNGERMANN-BASS INC."},
  {max_prefix: "AA-00-00",vendor: "DIGITAL EQUIPMENT CORPORATION"},
  {max_prefix: "AA-00-01",vendor: "DIGITAL EQUIPMENT CORPORATION"},
  {max_prefix: "AA-00-02",vendor: "DIGITAL EQUIPMENT CORPORATION"},
  {max_prefix: "08-00-14",vendor: "EXCELAN"},
  {max_prefix: "08-00-65",vendor: "GENRAD INC."},
  {max_prefix: "00-00-07",vendor: "XEROX CORPORATION"},
  {max_prefix: "00-80-1F",vendor: "KRUPP ATLAS ELECTRONIK GMBH"},
  {max_prefix: "02-AA-3C",vendor: "OLIVETTI TELECOMM SPA (OLTECO)"},
  {max_prefix: "08-00-59",vendor: "A/S MYCRON"},
  {max_prefix: "08-00-08",vendor: "BOLT BERANEK AND NEWMAN INC."},
  {max_prefix: "84-87-FF",vendor: "Shenzhen Skyworth Digital  Technology  CO., Ltd"},
  {max_prefix: "64-57-25",vendor: "Hui Zhou Gaoshengda Technology Co.,LTD"},
  {max_prefix: "00-80-E9",vendor: "Madge Ltd."},
  {max_prefix: "00-40-D6",vendor: "LOCAMATION B.V."},
  {max_prefix: "08-00-4B",vendor: "Planning Research Corp."},
  {max_prefix: "04-25-E8",vendor: "Texas Instruments"},
  {max_prefix: "28-3C-90",vendor: "Texas Instruments"},
  {max_prefix: "E4-FA-5B",vendor: "Texas Instruments"},
  {max_prefix: "00-00-09",vendor: "XEROX CORPORATION"},
  {max_prefix: "44-C3-B6",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "E8-F7-2F",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "50-A1-F3",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "DC-10-57",vendor: "Apple, Inc."},
  {max_prefix: "30-D8-75",vendor: "Apple, Inc."},
  {max_prefix: "48-5F-DF",vendor: "zte corporation"},
  {max_prefix: "6C-15-44",vendor: "Microsoft Corporation"},
  {max_prefix: "68-3E-C0",vendor: "Apple, Inc."},
  {max_prefix: "3C-55-DB",vendor: "Shenzhen Skyworth Digital  Technology  CO., Ltd"},
  {max_prefix: "F0-F8-4A",vendor: "BUFFALO.INC"},
  {max_prefix: "EC-6E-79",vendor: "InHand Networks, INC."},
  {max_prefix: "C8-0C-53",vendor: "China Mobile Group Device Co.,Ltd."},
  {max_prefix: "A4-00-4E",vendor: "Cisco Systems, Inc"},
  {max_prefix: "2C-9E-E0",vendor: "Cavli Inc."},
  {max_prefix: "64-C1-7E",vendor: "cheilelectric"},
  {max_prefix: "B0-2E-E0",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "A4-37-3E",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "C4-4F-5F",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "78-5F-28",vendor: "EM Microelectronic"},
  {max_prefix: "38-EF-E3",vendor: " INGENICO TERMINALS SAS"},
  {max_prefix: "FC-8D-13",vendor: "FUJIAN STAR-NET COMMUNICATION CO.,LTD"},
  {max_prefix: "9C-B1-DC",vendor: "Earda Technologies co Ltd"},
  {max_prefix: "24-04-62",vendor: "Siemens Energy Global GmbH & Co.KG - GT PRM"},
  {max_prefix: "38-88-71",vendor: "ASKEY COMPUTER CORP"},
  {max_prefix: "BC-F8-7E",vendor: "Arcadyan Corporation"},
  {max_prefix: "28-4E-E9",vendor: "mercury corperation"},
  {max_prefix: "58-E3-59",vendor: "Interroll Software & Electronics GmbH"},
  {max_prefix: "8C-32-23",vendor: "JWIPC Technology Co.,Ltd."},
  {max_prefix: "00-C8-96",vendor: "CIG SHANGHAI CO LTD"},
  {max_prefix: "00-1B-09",vendor: "MATRIX COMSEC PRIVATE LIMITED"},
  {max_prefix: "28-00-AF",vendor: "Dell Inc."},
  {max_prefix: "00-04-13",vendor: "snom technology GmbH"},
  {max_prefix: "74-B0-59",vendor: "Motorola Mobility LLC, a Lenovo Company"},
  {max_prefix: "E4-84-29",vendor: "New H3C Technologies Co., Ltd"},
  {max_prefix: "F4-1A-79",vendor: "IEEE Registration Authority"},
  {max_prefix: "8C-07-34",vendor: "Private"},
  {max_prefix: "98-7A-9B",vendor: "TCL MOKA International Limited"},
  {max_prefix: "78-16-99",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "E4-0A-16",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "40-9C-A7",vendor: "CHINA DRAGON TECHNOLOGY LIMITED"},
  {max_prefix: "D0-AD-08",vendor: "HP Inc."},
  {max_prefix: "60-04-5C",vendor: "NXP Semiconductor (Tianjin) LTD."},
  {max_prefix: "84-9C-02",vendor: "Druid Software"},
  {max_prefix: "CC-40-B2",vendor: "ECI Telecom Ltd."},
  {max_prefix: "A4-E2-87",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "80-95-3A",vendor: "Apple, Inc."},
  {max_prefix: "68-AE-04",vendor: "Shenzhen SuperElectron Technology Co.,Ltd."},
  {max_prefix: "CC-60-23",vendor: "Apple, Inc."},
  {max_prefix: "0C-DB-EA",vendor: "Apple, Inc."},
  {max_prefix: "68-45-CC",vendor: "Apple, Inc."},
  {max_prefix: "AC-97-38",vendor: "Apple, Inc."},
  {max_prefix: "08-C2-24",vendor: "Amazon Technologies Inc."},
  {max_prefix: "68-93-2E",vendor: "Habana Labs LTD."},
  {max_prefix: "2C-C4-4F",vendor: "IEEE Registration Authority"},
  {max_prefix: "98-12-23",vendor: "Tarmoc Network LTD"},
  {max_prefix: "1C-4C-27",vendor: "World WLAN Application Alliance"},
  {max_prefix: "D0-C9-01",vendor: "GLA ELECTRONICS PVT LTD"},
  {max_prefix: "40-99-E3",vendor: "Guangzhou Mudi Information Technology Co., Ltd"},
  {max_prefix: "7C-88-99",vendor: "FN-LINK TECHNOLOGY Ltd."},
  {max_prefix: "FC-70-2E",vendor: "Sichuan AI-Link Technology Co., Ltd."},
  {max_prefix: "B4-F9-5D",vendor: "Juniper Networks"},
  {max_prefix: "04-68-74",vendor: "CLOUD NETWORK TECHNOLOGY SINGAPORE PTE. LTD."},
  {max_prefix: "74-90-BC",vendor: "Arcadyan Corporation"},
  {max_prefix: "B0-47-E9",vendor: "Intel Corporate"},
  {max_prefix: "6C-2F-80",vendor: "Intel Corporate"},
  {max_prefix: "D0-65-78",vendor: "Intel Corporate"},
  {max_prefix: "98-BD-80",vendor: "Intel Corporate"},
  {max_prefix: "D4-76-A0",vendor: "Fortinet, Inc."},
  {max_prefix: "94-FF-3C",vendor: "Fortinet, Inc."},
  {max_prefix: "A0-F5-09",vendor: "IEI Integration Corp."},
  {max_prefix: "6C-45-C4",vendor: "Cloudflare, Inc."},
  {max_prefix: "00-0D-97",vendor: "Hitachi Energy USA Inc."},
  {max_prefix: "18-7F-88",vendor: "Ring LLC"},
  {max_prefix: "B8-7E-40",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "1C-2F-A2",vendor: "Guangzhou Shiyuan Electronic Technology Company Limited"},
  {max_prefix: "A4-3F-68",vendor: "Arista Network, Inc."},
  {max_prefix: "BC-9D-4E",vendor: "Shenzhen Skyworth Digital  Technology  CO., Ltd"},
  {max_prefix: "1C-3B-01",vendor: "Shanghai Xiaodu Technology Limited"},
  {max_prefix: "94-70-6C",vendor: "Quectel Wireless Solutions Co.,Ltd."},
  {max_prefix: "64-D8-1B",vendor: "Vestel Elektronik San ve Tic. A.S."},
  {max_prefix: "30-DC-E7",vendor: "zte corporation"},
  {max_prefix: "D0-12-55",vendor: "Hui Zhou Gaoshengda Technology Co.,LTD"},
  {max_prefix: "3C-2D-9E",vendor: "Vantiva - Connected Home"},
  {max_prefix: "08-C7-F5",vendor: "Vantiva Connected Home - Technologies Telco"},
  {max_prefix: "C0-16-93",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "FC-5B-8C",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "28-4E-44",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "80-2E-C3",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "3C-B8-D6",vendor: "Bluebank Communication Technology Co.,Ltd."},
  {max_prefix: "9C-9E-03",vendor: "awayfrom"},
  {max_prefix: "D4-D6-59",vendor: "Meta Platforms Technologies, LLC"},
  {max_prefix: "80-05-3A",vendor: "CHeKT Inc."},
  {max_prefix: "D8-B3-2F",vendor: "CLOUD NETWORK TECHNOLOGY SINGAPORE PTE. LTD."},
  {max_prefix: "54-10-4F",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "B0-54-76",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "EC-90-C1",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "30-D9-59",vendor: "Shanghai Longcheer Technology Co., Ltd."},
  {max_prefix: "00-78-39",vendor: "Nokia"},
  {max_prefix: "10-8A-7B",vendor: "Nokia"},
  {max_prefix: "A0-52-AB",vendor: "AVM ELECTRONICS PTE LTD"},
  {max_prefix: "BC-74-4B",vendor: "Nintendo Co.,Ltd"},
  {max_prefix: "6C-6F-18",vendor: "Stereotaxis, Inc."},
  {max_prefix: "F0-86-6F",vendor: "EM Microelectronic"},
  {max_prefix: "5C-B2-60",vendor: "EM Microelectronic"},
  {max_prefix: "A8-BB-56",vendor: "Apple, Inc."},
  {max_prefix: "28-2D-7F",vendor: "Apple, Inc."},
  {max_prefix: "8C-26-AA",vendor: "Apple, Inc."},
  {max_prefix: "90-62-3F",vendor: "Apple, Inc."},
  {max_prefix: "5C-07-A4",vendor: "Ciena Corporation"},
  {max_prefix: "00-4B-F3",vendor: "SHENZHEN MERCURY COMMUNICATION TECHNOLOGIES CO.,LTD."},
  {max_prefix: "BC-54-FC",vendor: "SHENZHEN MERCURY COMMUNICATION TECHNOLOGIES CO.,LTD."},
  {max_prefix: "90-76-9F",vendor: "SHENZHEN MERCURY COMMUNICATION TECHNOLOGIES CO.,LTD."},
  {max_prefix: "4C-77-66",vendor: "SHENZHEN MERCURY COMMUNICATION TECHNOLOGIES CO.,LTD."},
  {max_prefix: "00-5C-C2",vendor: "SHENZHEN MERCURY COMMUNICATION TECHNOLOGIES CO.,LTD."},
  {max_prefix: "FC-E8-C0",vendor: "Espressif Inc."},
  {max_prefix: "88-54-8E",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "58-1D-C9",vendor: "MSE CO.,LTD."},
  {max_prefix: "CC-7B-5C",vendor: "Espressif Inc."},
  {max_prefix: "50-5B-1D",vendor: "Shenzhen C-Data Technology Co., Ltd."},
  {max_prefix: "88-01-0C",vendor: "Sichuan Tianyi Comheart Telecom Co.,LTD"},
  {max_prefix: "A4-9E-69",vendor: "Silicon Laboratories"},
  {max_prefix: "F0-82-C0",vendor: "Silicon Laboratories"},
  {max_prefix: "60-EF-AB",vendor: "Silicon Laboratories"},
  {max_prefix: "D8-7A-3B",vendor: "Silicon Laboratories"},
  {max_prefix: "3C-2E-F5",vendor: "Silicon Laboratories"},
  {max_prefix: "34-10-F4",vendor: "Silicon Laboratories"},
  {max_prefix: "0C-EF-F6",vendor: "Silicon Laboratories"},
  {max_prefix: "EC-F6-4C",vendor: "Silicon Laboratories"},
  {max_prefix: "70-C9-12",vendor: "Sichuan AI-Link Technology Co., Ltd."},
  {max_prefix: "48-8E-B7",vendor: "Zebra Technologies Inc."},
  {max_prefix: "90-AB-96",vendor: "Silicon Laboratories"},
  {max_prefix: "0C-82-D5",vendor: "Maxio Technology Hangzhou Co., Ltd."},
  {max_prefix: "04-01-BB",vendor: "TECNO MOBILE LIMITED"},
  {max_prefix: "AC-72-DD",vendor: "GD Midea Air-Conditioning Equipment Co.,Ltd."},
  {max_prefix: "64-BB-1E",vendor: "Earda Technologies co Ltd"},
  {max_prefix: "64-82-14",vendor: "FN-LINK TECHNOLOGY Ltd."},
  {max_prefix: "E0-9C-E5",vendor: "Shanghai Tricheer Technology Co.,Ltd."},
  {max_prefix: "FC-D2-90",vendor: "SKY UK LIMITED"},
  {max_prefix: "78-30-5D",vendor: "zte corporation"},
  {max_prefix: "94-0B-83",vendor: "zte corporation"},
  {max_prefix: "E4-74-50",vendor: "Shenzhen Grandsun Electronic Co.,Ltd."},
  {max_prefix: "B4-A3-BD",vendor: "Extreme Networks Headquarters"},
  {max_prefix: "7C-D3-E5",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "B8-87-6E",vendor: "Intertech Services AG"},
  {max_prefix: "3C-0B-4F",vendor: "Intertech Services AG"},
  {max_prefix: "6C-62-FE",vendor: "Juniper Networks"},
  {max_prefix: "C8-A2-3B",vendor: "Shenzhen Sundray Technologies Company Limited"},
  {max_prefix: "9C-9E-D5",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "74-38-22",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "B8-3B-AB",vendor: "Arcadyan Corporation"},
  {max_prefix: "40-A6-3D",vendor: "SignalFire Telemetry"},
  {max_prefix: "60-54-64",vendor: "Eyedro Green Solutions Inc."},
  {max_prefix: "CC-E5-36",vendor: "ittim"},
  {max_prefix: "BC-00-04",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "98-A8-29",vendor: "AltoBeam Inc."},
  {max_prefix: "84-E9-C1",vendor: "Realme Chongqing Mobile Telecommunications Corp.,Ltd."},
  {max_prefix: "B4-62-2E",vendor: "Zhong Ge Smart Technology Co., Ltd."},
  {max_prefix: "C4-6E-33",vendor: "Zhong Ge Smart Technology Co., Ltd."},
  {max_prefix: "2C-67-BE",vendor: "DWnet Technologies(Suzhou) Corporation"},
  {max_prefix: "EC-19-2E",vendor: "Cisco Systems, Inc"},
  {max_prefix: "F8-96-FE",vendor: "LG Innotek"},
  {max_prefix: "A4-C4-0D",vendor: "WAC Lighting"},
  {max_prefix: "58-30-6E",vendor: "Nokia"},
  {max_prefix: "A8-CA-77",vendor: "Amazon Technologies Inc."},
  {max_prefix: "D0-C9-07",vendor: "Private"},
  {max_prefix: "14-2B-2F",vendor: "Espressif Inc."},
  {max_prefix: "C4-A4-51",vendor: "TECNO MOBILE LIMITED"},
  {max_prefix: "F8-9D-9D",vendor: "Shenzhen MinewSemi Co.,LTD."},
  {max_prefix: "F0-61-F3",vendor: "Comcast Cable Corporation"},
  {max_prefix: "D4-8D-26",vendor: "LG Innotek"},
  {max_prefix: "F4-F2-8A",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "94-E3-00",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "0C-E5-B5",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "FC-F7-38",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "8C-86-2A",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "C0-33-79",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "24-1E-2B",vendor: "Zhejiang Cainiao Supply Chain Management Co., Ltd"},
  {max_prefix: "AC-7F-8D",vendor: "Extreme Networks Headquarters"},
  {max_prefix: "24-AC-AC",vendor: "Polar Electro Oy"},
  {max_prefix: "50-20-6B",vendor: "Copeland - Transportation Solutions ApS"},
  {max_prefix: "80-D1-0A",vendor: "Sichuan AI-Link Technology Co., Ltd."},
  {max_prefix: "10-47-E7",vendor: "Shenzhen YOUHUA Technology Co., Ltd"},
  {max_prefix: "94-F6-F2",vendor: "Honor Device Co., Ltd."},
  {max_prefix: "44-90-46",vendor: "Honor Device Co., Ltd."},
  {max_prefix: "B0-C3-8E",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "14-3B-51",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "D0-73-80",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "AC-FC-E3",vendor: "EM Microelectronic"},
  {max_prefix: "04-2D-AD",vendor: "Areus GmbH"},
  {max_prefix: "78-1C-1E",vendor: "Chongqing Yipingfang Technology Co., Ltd."},
  {max_prefix: "78-A1-3E",vendor: "New H3C Technologies Co., Ltd"},
  {max_prefix: "48-CA-43",vendor: "Espressif Inc."},
  {max_prefix: "7C-BA-C0",vendor: "EVBox BV"},
  {max_prefix: "98-25-6E",vendor: "Private"},
  {max_prefix: "B0-A7-D2",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "8C-87-D0",vendor: "Shenzhen Uascent Technology Co.,Ltd"},
  {max_prefix: "5C-17-83",vendor: "Edgecore Americas Networking Corporation"},
  {max_prefix: "64-70-60",vendor: "Texas Instruments"},
  {max_prefix: "00-AA-FD",vendor: "Texas Instruments"},
  {max_prefix: "0C-4B-EE",vendor: "Texas Instruments"},
  {max_prefix: "D4-13-B3",vendor: "Wu Qi Technologies,Inc."},
  {max_prefix: "60-E5-D8",vendor: "zte corporation"},
  {max_prefix: "54-35-E9",vendor: "Feitian Technologies Co., Ltd"},
  {max_prefix: "7C-B5-9F",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "50-5C-88",vendor: "Cisco Systems, Inc"},
  {max_prefix: "30-13-8B",vendor: "HP Inc."},
  {max_prefix: "60-68-32",vendor: "Guangdong Seneasy Intelligent Technology Co., Ltd."},
  {max_prefix: "84-33-F2",vendor: "Shenzhen Stellamore Technology Co.,Ltd"},
  {max_prefix: "28-D0-43",vendor: "AzureWave Technology Inc."},
  {max_prefix: "E4-D3-AA",vendor: "FCNT LLC"},
  {max_prefix: "B0-D5-76",vendor: "Apple, Inc."},
  {max_prefix: "14-28-76",vendor: "Apple, Inc."},
  {max_prefix: "14-7F-CE",vendor: "Apple, Inc."},
  {max_prefix: "6C-C3-B2",vendor: "Cisco Meraki"},
  {max_prefix: "F8-CE-07",vendor: "ZHEJIANG DAHUA TECHNOLOGYCO.,LTD"},
  {max_prefix: "20-98-ED",vendor: "AltoBeam Inc."},
  {max_prefix: "98-39-10",vendor: "Kaon Group Co., Ltd."},
  {max_prefix: "80-4C-5D",vendor: "NXP Semiconductor (Tianjin) LTD."},
  {max_prefix: "F8-8F-C8",vendor: "Chipsea Technologies (Shenzhen) Corp."},
  {max_prefix: "4C-10-D5",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "28-91-76",vendor: "Indyme Solutions, LLC"},
  {max_prefix: "28-A0-6B",vendor: "Intel Corporate"},
  {max_prefix: "7C-8B-C1",vendor: "Infinix mobility limited"},
  {max_prefix: "3C-CA-61",vendor: "TECNO MOBILE LIMITED"},
  {max_prefix: "EC-A9-71",vendor: "Hangzhou Hikvision Digital Technology Co.,Ltd."},
  {max_prefix: "68-A2-AA",vendor: "Acres Manufacturing"},
  {max_prefix: "88-AE-35",vendor: "Realme Chongqing Mobile Telecommunications Corp.,Ltd."},
  {max_prefix: "70-49-A2",vendor: "Zyxel Communications Corporation"},
  {max_prefix: "A0-7D-9C",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "F8-C3-F1",vendor: "Raytron Photonics Co.,Ltd."},
  {max_prefix: "E0-08-55",vendor: "AVM Audiovisuelles Marketing und Computersysteme GmbH"},
  {max_prefix: "D8-95-63",vendor: "Taiwan Digital Streaming Co."},
  {max_prefix: "E8-A8-48",vendor: "Wacom Co.,Ltd."},
  {max_prefix: "5C-A3-EB",vendor: "SKODA DIGITAL s.r.o."},
  {max_prefix: "5C-BE-05",vendor: "ISPEC"},
  {max_prefix: "08-52-4E",vendor: "Shenzhen Fangcheng Baiyi Technology Co., Ltd."},
  {max_prefix: "AC-F4-2C",vendor: "Earda Technologies co Ltd"},
  {max_prefix: "C0-AB-2B",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "50-8A-7F",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "C4-CB-BE",vendor: "Great Talent Technology Limited"},
  {max_prefix: "E8-B5-27",vendor: "Phyplus Technology (Shanghai) Co., Ltd"},
  {max_prefix: "98-A8-78",vendor: "Agnigate Technologies Private Limited"},
  {max_prefix: "30-52-23",vendor: "GD Midea Air-Conditioning Equipment Co.,Ltd."},
  {max_prefix: "DC-49-65",vendor: "DASAN Newtork Solutions"},
  {max_prefix: "18-9E-2D",vendor: "Allwinner Technology Co., Ltd"},
  {max_prefix: "9C-60-76",vendor: "Apple, Inc."},
  {max_prefix: "38-E1-3D",vendor: "Apple, Inc."},
  {max_prefix: "D0-D4-9F",vendor: "Apple, Inc."},
  {max_prefix: "9C-2D-49",vendor: "Nanowell Info Tech Co., Limited"},
  {max_prefix: "88-7F-D5",vendor: "zte corporation"},
  {max_prefix: "F4-74-70",vendor: "Cisco Systems, Inc"},
  {max_prefix: "60-A4-34",vendor: "IEEE Registration Authority"},
  {max_prefix: "38-7B-01",vendor: "Shenzhen MiaoMing Intelligent Technology Co.,Ltd"},
  {max_prefix: "0C-ED-C8",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "88-BC-AC",vendor: "Zebra Technologies Inc."},
  {max_prefix: "C8-8D-D4",vendor: "Markone technology Co., Ltd."},
  {max_prefix: "2C-36-F2",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "E4-99-5F",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "A8-F0-59",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "28-3D-E8",vendor: "Guangzhou Shiyuan Electronic Technology Company Limited"},
  {max_prefix: "18-04-03",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "20-6B-D5",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "B4-7A-F1",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "48-9E-CB",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "9C-1C-12",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "04-BD-88",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "E8-F7-24",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "DC-68-0C",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "48-DF-37",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "20-67-7C",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "44-48-C1",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "68-28-CF",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "E4-DE-40",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "48-00-20",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "5C-A4-7D",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "E8-95-05",vendor: "Shenzhen MiaoMing Intelligent Technology Co.,Ltd"},
  {max_prefix: "DC-B7-AC",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "54-D7-E3",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "14-AB-EC",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "10-4F-58",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "CC-D0-83",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "BC-46-32",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "68-53-77",vendor: "Bouffalo Lab (Nanjing) Co., Ltd."},
  {max_prefix: "F0-5C-19",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "7C-57-3C",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "34-8A-12",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "10-63-A3",vendor: "IEEE Registration Authority"},
  {max_prefix: "E8-06-90",vendor: "Espressif Inc."},
  {max_prefix: "DC-A7-06",vendor: "CHENGDU KT ELECTRONIC HI-TECH CO.,LTD"},
  {max_prefix: "E0-60-4A",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "50-06-F5",vendor: "Roku, Inc"},
  {max_prefix: "98-16-CD",vendor: "leapio"},
  {max_prefix: "34-51-6F",vendor: "Skychers Creations ShenZhen Limited"},
  {max_prefix: "C8-6E-08",vendor: "Intel Corporate"},
  {max_prefix: "B8-37-4B",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "18-86-37",vendor: "INGRAM MICRO SERVICES"},
  {max_prefix: "78-07-8F",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "30-9E-62",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "10-C0-D5",vendor: "HOLOEYE Photonics AG"},
  {max_prefix: "54-FA-89",vendor: "Medtronic CRM"},
  {max_prefix: "24-16-51",vendor: "Chipsea Technologies (Shenzhen) Corp."},
  {max_prefix: "58-76-9C",vendor: "Palo Alto Networks"},
  {max_prefix: "B4-C5-56",vendor: "Shanghai Kenmyond Industrial Network Equipment Co., Ltd"},
  {max_prefix: "EC-72-5B",vendor: "zte corporation"},
  {max_prefix: "E4-92-82",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "F0-09-0D",vendor: "TP-LINK CORPORATION PTE. LTD."},
  {max_prefix: "00-09-F7",vendor: "Calian Advanced Technologies"},
  {max_prefix: "04-94-E9",vendor: "FAXedge Technologies, LLC"},
  {max_prefix: "38-7A-CC",vendor: "SHENZHEN BILIAN ELECTRONIC CO.\uff0cLTD"},
  {max_prefix: "7C-72-E7",vendor: "Texas Instruments"},
  {max_prefix: "30-30-D0",vendor: "Texas Instruments"},
  {max_prefix: "54-FE-EB",vendor: "Texas Instruments"},
  {max_prefix: "08-84-FB",vendor: "Honor Device Co., Ltd."},
  {max_prefix: "14-BE-FC",vendor: "Nanjing Jiahao Technology Co., Ltd."},
  {max_prefix: "90-5F-7A",vendor: "Apple, Inc."},
  {max_prefix: "F8-F5-8C",vendor: "Apple, Inc."},
  {max_prefix: "0C-85-E1",vendor: "Apple, Inc."},
  {max_prefix: "28-2E-89",vendor: "Wistron Neweb Corporation"},
  {max_prefix: "90-FB-5D",vendor: "Beijing Xiaomi Mobile Software Co., Ltd"},
  {max_prefix: "44-16-90",vendor: "Wuxi Ranke Technology Co., Ltd."},
  {max_prefix: "9C-A6-D8",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "0C-2A-6F",vendor: "Silicon Laboratories"},
  {max_prefix: "20-DB-EA",vendor: "Cisco Systems, Inc"},
  {max_prefix: "A0-47-9B",vendor: "PROCITEC GmbH"},
  {max_prefix: "74-C9-0F",vendor: "Microchip Technologies Inc"},
  {max_prefix: "34-CD-B0",vendor: "Espressif Inc."},
  {max_prefix: "18-E2-04",vendor: "BEIJING COOLSHARK TECHNOLOGY CO.,LTD."},
  {max_prefix: "2C-B7-A1",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "6C-82-43",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "B4-76-A4",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "50-26-D2",vendor: "AVIRE Trading Limited"},
  {max_prefix: "94-24-53",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "18-4F-43",vendor: "UNIONMAN TECHNOLOGY CO.,LTD"},
  {max_prefix: "F8-6B-FA",vendor: "Infinix mobility limited"},
  {max_prefix: "B0-32-26",vendor: "Keheng Information Industry Co., Ltd."},
  {max_prefix: "3C-58-36",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "84-CB-85",vendor: "EM Microelectronic"},
  {max_prefix: "AC-F2-3C",vendor: "CLOUD NETWORK TECHNOLOGY SINGAPORE PTE. LTD."},
  {max_prefix: "E4-57-68",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "48-E6-C6",vendor: "IEEE Registration Authority"},
  {max_prefix: "00-04-17",vendor: "Schneider Electric"},
  {max_prefix: "D0-1B-BE",vendor: "Onward Brands"},
  {max_prefix: "24-6A-0E",vendor: "HP Inc."},
  {max_prefix: "B0-7C-51",vendor: "Ruckus Wireless"},
  {max_prefix: "88-4F-59",vendor: "Cisco Systems, Inc"},
  {max_prefix: "7C-FA-80",vendor: "JiangSu Fulian Communication Technology Co., Ltd"},
  {max_prefix: "C0-87-06",vendor: "Shenzhen Qianfenyi Intelligent Technology Co.,LTD"},
  {max_prefix: "C4-B3-49",vendor: "Apple, Inc."},
  {max_prefix: "34-F6-8D",vendor: "Apple, Inc."},
  {max_prefix: "94-54-C5",vendor: "Espressif Inc."},
  {max_prefix: "AC-B7-22",vendor: "Qingdao Haier Technology Co.,Ltd"},
  {max_prefix: "CC-27-46",vendor: "Apple, Inc."},
  {max_prefix: "28-59-23",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "D0-CE-C0",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "0C-01-A5",vendor: "zte corporation"},
  {max_prefix: "98-FA-9B",vendor: "LCFC(Hefei) Electronics Technology co., ltd"},
  {max_prefix: "F8-75-A4",vendor: "LCFC(Hefei) Electronics Technology co., ltd"},
  {max_prefix: "8C-8C-AA",vendor: "LCFC(Hefei) Electronics Technology co., ltd"},
  {max_prefix: "70-19-88",vendor: "Nanjing Qinheng Microelectronics Co., Ltd."},
  {max_prefix: "FC-1A-46",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "98-3D-AE",vendor: "Espressif Inc."},
  {max_prefix: "5C-5E-0A",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "CC-B0-B3",vendor: "Microsoft Corporation"},
  {max_prefix: "80-D5-2C",vendor: "Beijing Cheering Networks Technology Co.,Ltd."},
  {max_prefix: "88-B2-AB",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "24-E3-A4",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "30-00-FC",vendor: "Nokia"},
  {max_prefix: "64-DE-6D",vendor: "Intel Corporate"},
  {max_prefix: "EC-4C-8C",vendor: "Intel Corporate"},
  {max_prefix: "EC-66-52",vendor: "Info Fiber Solutions Pvt Ltd"},
  {max_prefix: "CC-76-3A",vendor: "zte corporation"},
  {max_prefix: "30-04-75",vendor: "QBIC COMMUNICATIONS DMCC"},
  {max_prefix: "00-0A-F6",vendor: "Copeland LP"},
  {max_prefix: "5C-18-DD",vendor: "CIG SHANGHAI CO LTD"},
  {max_prefix: "14-36-0E",vendor: "Zyxel Communications Corporation"},
  {max_prefix: "5C-4E-EE",vendor: "AltoBeam Inc."},
  {max_prefix: "5C-C1-F2",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "C8-05-A4",vendor: "Motorola(Wuhan) Mobility Technologies Communication Co.,Ltd"},
  {max_prefix: "00-72-EE",vendor: "Intel Corporate"},
  {max_prefix: "C8-58-B3",vendor: "Intel Corporate"},
  {max_prefix: "E4-1F-D5",vendor: "Intel Corporate"},
  {max_prefix: "EC-1B-5F",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "1C-B4-6C",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "E8-F6-73",vendor: "Microsoft Corporation"},
  {max_prefix: "78-64-A0",vendor: "Cisco Systems, Inc"},
  {max_prefix: "5C-DB-36",vendor: "Calix Inc."},
  {max_prefix: "4C-7B-35",vendor: "UNIONMAN TECHNOLOGY CO.,LTD"},
  {max_prefix: "9C-45-F0",vendor: "SKYLARK ELECTRONICS PVT LTD"},
  {max_prefix: "40-92-49",vendor: "Shanghai Baud Data Communication Co.,Ltd."},
  {max_prefix: "0C-7E-24",vendor: "Garmin International"},
  {max_prefix: "74-75-DF",vendor: "TECLINK"},
  {max_prefix: "68-6B-6A",vendor: "Phytium Technology Co.,Ltd."},
  {max_prefix: "34-07-AC",vendor: "PRONYX TRADING LLC"},
  {max_prefix: "58-B8-58",vendor: "SZ DJI TECHNOLOGY CO.,LTD"},
  {max_prefix: "D0-CB-DD",vendor: "eero inc."},
  {max_prefix: "44-B4-23",vendor: "HANWHA VISION VIETNAM COMPANY LIMITED"},
  {max_prefix: "68-87-BD",vendor: "zte corporation"},
  {max_prefix: "30-58-EB",vendor: "zte corporation"},
  {max_prefix: "44-05-B8",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "84-1A-24",vendor: "UNIONMAN TECHNOLOGY CO.,LTD"},
  {max_prefix: "78-6C-AB",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "00-7D-3B",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "5C-40-E3",vendor: "NOVAON"},
  {max_prefix: "98-62-97",vendor: "Shenzhen Techwinsemi Technology Co., Ltd."},
  {max_prefix: "E8-BC-E4",vendor: "Cisco Systems, Inc"},
  {max_prefix: "8C-87-26",vendor: "VAST Data Inc"},
  {max_prefix: "68-62-8A",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "28-44-F4",vendor: "Honor Device Co., Ltd."},
  {max_prefix: "BC-31-E2",vendor: "New H3C Technologies Co., Ltd"},
  {max_prefix: "18-E8-EC",vendor: "STMicrolectronics International NV"},
  {max_prefix: "28-F5-2B",vendor: "FN-LINK TECHNOLOGY Ltd."},
  {max_prefix: "F8-EF-5D",vendor: "Motorola Mobility LLC, a Lenovo Company"},
  {max_prefix: "A4-09-B3",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "3C-07-D7",vendor: "Apple, Inc."},
  {max_prefix: "A4-B0-F5",vendor: "Texas Instruments"},
  {max_prefix: "2C-D3-AD",vendor: "Texas Instruments"},
  {max_prefix: "54-FB-5A",vendor: "Optomind Inc."},
  {max_prefix: "6C-D7-A0",vendor: "WIKO Terminal Technology (Dongguan) Co., Ltd."},
  {max_prefix: "F8-E3-5F",vendor: "Sichuan Tianyi Comheart Telecom Co.,LTD"},
  {max_prefix: "E8-E7-C3",vendor: "zte corporation"},
  {max_prefix: "24-B2-B9",vendor: "Liteon Technology Corporation"},
  {max_prefix: "1C-1D-D3",vendor: "Apple, Inc."},
  {max_prefix: "C8-1F-E8",vendor: "Apple, Inc."},
  {max_prefix: "08-B9-5F",vendor: "Silicon Laboratories"},
  {max_prefix: "74-78-47",vendor: "Interdisciplinary Consulting Corporation"},
  {max_prefix: "78-42-1C",vendor: "Espressif Inc."},
  {max_prefix: "F0-D5-06",vendor: "Ubee Interactive Co., Limited"},
  {max_prefix: "7C-E5-3F",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "B4-23-A2",vendor: "Google, Inc."},
  {max_prefix: "38-D0-9C",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "CC-22-DF",vendor: "EM Microelectronic"},
  {max_prefix: "8C-2A-85",vendor: "Amazon Technologies Inc."},
  {max_prefix: "E8-C6-E6",vendor: "CHANGHONG (HONGKONG) TRADING LIMITED"},
  {max_prefix: "D4-A3-EB",vendor: "Shenzhen iComm Semiconductor CO.,LTD"},
  {max_prefix: "9C-84-B6",vendor: "Shenzhen iComm Semiconductor CO.,LTD"},
  {max_prefix: "20-67-E0",vendor: "Shenzhen iComm Semiconductor CO.,LTD"},
  {max_prefix: "E4-D5-8B",vendor: "Hangzhou Hikvision Digital Technology Co.,Ltd."},
  {max_prefix: "0C-97-9B",vendor: "FUJIAN STAR-NET COMMUNICATION CO.,LTD"},
  {max_prefix: "50-04-01",vendor: "TelHi Corporation"},
  {max_prefix: "08-F4-F0",vendor: "Cisco Systems, Inc"},
  {max_prefix: "28-DB-02",vendor: "zte corporation"},
  {max_prefix: "88-15-66",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "28-45-AC",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "A4-43-80",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "10-86-F4",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "D4-50-EE",vendor: "GD Midea Air-Conditioning Equipment Co.,Ltd."},
  {max_prefix: "24-21-5E",vendor: "Quectel Wireless Solutions Co.,Ltd."},
  {max_prefix: "5C-01-3B",vendor: "Espressif Inc."},
  {max_prefix: "54-4D-D4",vendor: "China Mobile Group Device Co.,Ltd."},
  {max_prefix: "E8-05-DC",vendor: "Verifone, Inc."},
  {max_prefix: "A0-FB-68",vendor: "Miba Battery Systems GmbH"},
  {max_prefix: "28-6B-B4",vendor: "SJIT Co., Ltd."},
  {max_prefix: "98-C9-BE",vendor: "Shenzhen SDMC Technology CO., LTD"},
  {max_prefix: "6C-83-75",vendor: "Broadcom Limited"},
  {max_prefix: "D4-BE-DC",vendor: "Roku, Inc"},
  {max_prefix: "00-CD-90",vendor: "MAS Elektronik AG"},
  {max_prefix: "30-ED-96",vendor: "LS Mecapion"},
  {max_prefix: "FC-06-8C",vendor: "SHENZHEN MICIPC TECHNOLOGY CO.,LTD"},
  {max_prefix: "DC-86-8D",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "E8-F4-94",vendor: "AltoBeam Inc."},
  {max_prefix: "48-CF-A9",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "D0-87-B5",vendor: "SAFEMO PTE. LTD."},
  {max_prefix: "74-25-84",vendor: "IEEE Registration Authority"},
  {max_prefix: "40-48-6E",vendor: "Nokia Solutions and Networks GmbH & Co. KG"},
  {max_prefix: "D8-B0-61",vendor: "SHENZHEN WENXUN TECHNOLOGY CO.,LTD"},
  {max_prefix: "F4-85-AE",vendor: "Senbiosys SA"},
  {max_prefix: "08-DD-03",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "28-37-2F",vendor: "Espressif Inc."},
  {max_prefix: "E4-4E-12",vendor: "zte corporation"},
  {max_prefix: "CC-B8-5E",vendor: "Shenzhen Phaten Tech. LTD"},
  {max_prefix: "C8-A7-02",vendor: "Hangzhou Hikvision Digital Technology Co.,Ltd."},
  {max_prefix: "2C-FE-8B",vendor: "Microchip Technologies Inc"},
  {max_prefix: "68-EF-AB",vendor: "Vention"},
  {max_prefix: "B0-92-00",vendor: "Apple, Inc."},
  {max_prefix: "A0-EE-1A",vendor: "Apple, Inc."},
  {max_prefix: "78-1E-B8",vendor: "Shenzhen iComm Semiconductor CO.,LTD"},
  {max_prefix: "58-41-46",vendor: "Guangzhou Shiyuan Electronic Technology Company Limited"},
  {max_prefix: "9C-F3-AC",vendor: "Apple, Inc."},
  {max_prefix: "08-5D-53",vendor: "Apple, Inc."},
  {max_prefix: "54-29-06",vendor: "Apple, Inc."},
  {max_prefix: "70-15-FB",vendor: "Intel Corporate"},
  {max_prefix: "B0-47-5E",vendor: "IEEE Registration Authority"},
  {max_prefix: "EC-8E-12",vendor: "Nokia"},
  {max_prefix: "C0-84-FF",vendor: "GD Midea Air-Conditioning Equipment Co.,Ltd."},
  {max_prefix: "10-00-3B",vendor: "Espressif Inc."},
  {max_prefix: "F8-73-1A",vendor: "zte corporation"},
  {max_prefix: "CC-65-AD",vendor: "Commscope"},
  {max_prefix: "78-96-84",vendor: "Commscope"},
  {max_prefix: "E8-ED-05",vendor: "Commscope"},
  {max_prefix: "D4-04-CD",vendor: "Commscope"},
  {max_prefix: "20-3D-66",vendor: "Commscope"},
  {max_prefix: "D4-0A-A9",vendor: "Commscope"},
  {max_prefix: "28-A4-4A",vendor: "Intel Corporate"},
  {max_prefix: "10-96-C6",vendor: "Cisco Systems, Inc"},
  {max_prefix: "8C-09-F4",vendor: "Commscope"},
  {max_prefix: "90-0D-CB",vendor: "Commscope"},
  {max_prefix: "00-13-71",vendor: "Commscope"},
  {max_prefix: "00-1E-5A",vendor: "Commscope"},
  {max_prefix: "00-1C-C1",vendor: "Commscope"},
  {max_prefix: "00-1D-D2",vendor: "Commscope"},
  {max_prefix: "00-1D-CD",vendor: "Commscope"},
  {max_prefix: "00-1A-DE",vendor: "Commscope"},
  {max_prefix: "00-23-EE",vendor: "Commscope"},
  {max_prefix: "E8-3E-FC",vendor: "Commscope"},
  {max_prefix: "7C-BF-B1",vendor: "Commscope"},
  {max_prefix: "00-50-E3",vendor: "Commscope"},
  {max_prefix: "00-16-26",vendor: "Commscope"},
  {max_prefix: "00-13-11",vendor: "Commscope"},
  {max_prefix: "00-19-A6",vendor: "Commscope"},
  {max_prefix: "80-F5-03",vendor: "Commscope"},
  {max_prefix: "A0-55-DE",vendor: "Commscope"},
  {max_prefix: "D4-2C-0F",vendor: "Commscope"},
  {max_prefix: "FC-6F-B7",vendor: "Commscope"},
  {max_prefix: "FC-8E-7E",vendor: "Commscope"},
  {max_prefix: "A4-15-88",vendor: "Commscope"},
  {max_prefix: "04-4E-5A",vendor: "Commscope"},
  {max_prefix: "38-70-0C",vendor: "Commscope"},
  {max_prefix: "FC-51-A4",vendor: "Commscope"},
  {max_prefix: "2C-9E-5F",vendor: "Commscope"},
  {max_prefix: "40-FC-89",vendor: "Commscope"},
  {max_prefix: "E4-64-49",vendor: "Commscope"},
  {max_prefix: "74-56-12",vendor: "Commscope"},
  {max_prefix: "28-7A-EE",vendor: "Commscope"},
  {max_prefix: "BC-64-4B",vendor: "Commscope"},
  {max_prefix: "44-AA-F5",vendor: "Commscope"},
  {max_prefix: "00-36-76",vendor: "Commscope"},
  {max_prefix: "78-23-AE",vendor: "Commscope"},
  {max_prefix: "10-56-11",vendor: "Commscope"},
  {max_prefix: "18-B8-1F",vendor: "Commscope"},
  {max_prefix: "98-4B-4A",vendor: "Commscope"},
  {max_prefix: "E8-82-5B",vendor: "Commscope"},
  {max_prefix: "00-21-36",vendor: "Commscope"},
  {max_prefix: "00-26-36",vendor: "Commscope"},
  {max_prefix: "50-95-51",vendor: "Commscope"},
  {max_prefix: "24-0A-63",vendor: "Commscope"},
  {max_prefix: "F8-8B-37",vendor: "Commscope"},
  {max_prefix: "88-96-4E",vendor: "Commscope"},
  {max_prefix: "CC-75-E2",vendor: "Commscope"},
  {max_prefix: "14-D4-FE",vendor: "Commscope"},
  {max_prefix: "E4-9F-1E",vendor: "Commscope"},
  {max_prefix: "88-C4-8E",vendor: "UNEEVIU TECHNOLOGIES INDIA PRIVATE LIMITED"},
  {max_prefix: "40-2B-50",vendor: "Commscope"},
  {max_prefix: "70-54-25",vendor: "Commscope"},
  {max_prefix: "EC-A9-40",vendor: "Commscope"},
  {max_prefix: "E4-F7-5B",vendor: "Commscope"},
  {max_prefix: "F8-79-0A",vendor: "Commscope"},
  {max_prefix: "1C-93-7C",vendor: "Commscope"},
  {max_prefix: "DC-2E-97",vendor: "Quectel Wireless Solutions Co.,Ltd."},
  {max_prefix: "80-E5-40",vendor: "Commscope"},
  {max_prefix: "C0-94-35",vendor: "Commscope"},
  {max_prefix: "74-8A-0D",vendor: "Commscope"},
  {max_prefix: "8C-76-3F",vendor: "Commscope"},
  {max_prefix: "BC-45-48",vendor: "Beijing gpthink technology co.,LTD."},
  {max_prefix: "B8-52-E0",vendor: "Beijing Xiaomi Electronics Co.,Ltd"},
  {max_prefix: "28-9F-04",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "E0-85-4D",vendor: "LG Innotek"},
  {max_prefix: "AC-EF-92",vendor: "IEEE Registration Authority"},
  {max_prefix: "40-45-A0",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "0C-E6-7C",vendor: "Realme Chongqing Mobile Telecommunications Corp.,Ltd."},
  {max_prefix: "58-FC-20",vendor: "Altice Labs"},
  {max_prefix: "1C-57-3E",vendor: "Altice Labs"},
  {max_prefix: "78-12-3E",vendor: "TECNO MOBILE LIMITED"},
  {max_prefix: "14-B5-CD",vendor: "Liteon Technology Corporation"},
  {max_prefix: "E0-3E-CB",vendor: "Qingdao Intelligent&Precise Electronics Co.,Ltd."},
  {max_prefix: "3C-A9-AB",vendor: "Nintendo Co.,Ltd"},
  {max_prefix: "6C-A3-1E",vendor: "ITEL MOBILE LIMITED"},
  {max_prefix: "F8-F3-D3",vendor: "Shenzhen Gotron electronic CO.,LTD"},
  {max_prefix: "0C-47-A9",vendor: "IEEE Registration Authority"},
  {max_prefix: "C8-FB-54",vendor: "iMin Technology Pte. Ltd."},
  {max_prefix: "54-84-50",vendor: "Tiinlab Corporation"},
  {max_prefix: "68-95-75",vendor: "Zhejiang Bodyguard Electronic Co., Ltd"},
  {max_prefix: "20-53-8D",vendor: "Hon Hai Precision Industry Co., Ltd."},
  {max_prefix: "9C-E9-1E",vendor: "TEJAS NETWORKS LTD"},
  {max_prefix: "58-DF-59",vendor: "Cisco Systems, Inc"},
  {max_prefix: "04-47-CA",vendor: "GREE ELECTRIC APPLIANCES, INC. OF ZHUHAI"},
  {max_prefix: "C0-2D-2E",vendor: "China Mobile Group Device Co.,Ltd."},
  {max_prefix: "C0-F8-53",vendor: "Tuya Smart Inc."},
  {max_prefix: "44-AC-85",vendor: "eero inc."},
  {max_prefix: "00-BC-2F",vendor: "Actiontec Electronics Inc."},
  {max_prefix: "E4-FD-8C",vendor: "Extreme Networks Headquarters"},
  {max_prefix: "30-EB-15",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "68-9B-43",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "1C-4E-A2",vendor: "Shenzhen V-Link Technology CO., LTD."},
  {max_prefix: "E0-22-A1",vendor: "AltoBeam Inc."},
  {max_prefix: "04-B0-66",vendor: "Private"},
  {max_prefix: "4C-FA-9A",vendor: "Shenzhen Quanxing Technology Co., Ltd"},
  {max_prefix: "28-49-92",vendor: "Luminator Technology Group Global LLC"},
  {max_prefix: "A8-EA-E4",vendor: "Weiser"},
  {max_prefix: "94-38-AA",vendor: "Technology Innovation Institute"},
  {max_prefix: "60-A3-E3",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "64-63-06",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "6C-9A-B4",vendor: "Brodersen A/S"},
  {max_prefix: "E4-5D-39",vendor: "Texas Instruments"},
  {max_prefix: "04-90-C0",vendor: "Forvia"},
  {max_prefix: "38-8D-3D",vendor: "Wistron Neweb Corporation"},
  {max_prefix: "50-EE-32",vendor: "Hon Hai Precision Industry Co.,LTD"},
  {max_prefix: "08-3F-21",vendor: "Motorola Mobility LLC, a Lenovo Company"},
  {max_prefix: "98-E7-D5",vendor: "NXP Semiconductor (Tianjin) LTD."},
  {max_prefix: "E4-41-D4",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "64-44-7B",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "4C-85-8A",vendor: "BUFFALO.INC"},
  {max_prefix: "10-A4-50",vendor: "Kwikset"},
  {max_prefix: "C8-48-05",vendor: "Nintendo Co.,Ltd"},
  {max_prefix: "80-A1-97",vendor: "u-blox AG"},
  {max_prefix: "8C-B6-C5",vendor: "Mimosa Networks"},
  {max_prefix: "84-EA-D2",vendor: "KOGANEI CORPORATION"},
  {max_prefix: "F0-DB-2A",vendor: "LANNER ELECTRONICS, INC."},
  {max_prefix: "D8-0F-B5",vendor: "SHENZHEN ULTRAEASY TECHNOLOGY CO LTD"},
  {max_prefix: "EC-FF-3A",vendor: "Apple, Inc."},
  {max_prefix: "70-13-84",vendor: "Apple, Inc."},
  {max_prefix: "84-A8-24",vendor: "Google, Inc."},
  {max_prefix: "34-66-91",vendor: "Apple, Inc."},
  {max_prefix: "E0-1C-A7",vendor: "Arista Networks"},
  {max_prefix: "A8-D3-C8",vendor: "Wachendorff Automation GmbH & CO.KG"},
  {max_prefix: "E4-CD-A7",vendor: "zte corporation"},
  {max_prefix: "48-8C-78",vendor: "Alpha Networks Inc."},
  {max_prefix: "90-95-07",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "88-63-C5",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "50-0B-88",vendor: "Moxa.Inc"},
  {max_prefix: "24-FB-E3",vendor: "HP Inc."},
  {max_prefix: "54-0B-B6",vendor: "Variscite LTD"},
  {max_prefix: "98-9F-1A",vendor: "Private"},
  {max_prefix: "94-0E-2A",vendor: "NXP Semiconductors Taiwan Ltd."},
  {max_prefix: "F0-74-BF",vendor: "Silicon Laboratories"},
  {max_prefix: "5C-8E-10",vendor: "TimeWatch Infocom Pvt. Ltd."},
  {max_prefix: "00-52-45",vendor: "GANATECHWIN"},
  {max_prefix: "44-C2-0C",vendor: "Cisco Systems, Inc"},
  {max_prefix: "F8-97-B0",vendor: "Goki Pty Ltd"},
  {max_prefix: "88-94-8E",vendor: "Max Weishaupt SE"},
  {max_prefix: "64-7B-40",vendor: "Sichuan AI-Link Technology Co., Ltd."},
  {max_prefix: "2C-2D-48",vendor: "Commend International GmbH"},
  {max_prefix: "18-60-41",vendor: "Arcadyan Corporation"},
  {max_prefix: "A0-6B-4A",vendor: "TCT mobile ltd"},
  {max_prefix: "14-33-5C",vendor: "Espressif Inc."},
  {max_prefix: "98-88-E0",vendor: "Espressif Inc."},
  {max_prefix: "0C-79-55",vendor: "Hui Zhou Gaoshengda Technology Co.,LTD"},
  {max_prefix: "74-AD-CB",vendor: "New H3C Technologies Co., Ltd"},
  {max_prefix: "4C-99-E8",vendor: "ZHEJIANG DAHUA TECHNOLOGYCO.,LTD"},
  {max_prefix: "D8-19-09",vendor: "Wiwynn Technology Service Malaysia"},
  {max_prefix: "38-77-CD",vendor: "KOKUSAI ELECTRIC CORPORATION"},
  {max_prefix: "80-84-89",vendor: "Intel Corporate"},
  {max_prefix: "FC-B3-AA",vendor: "Intel Corporate"},
  {max_prefix: "4C-0F-3E",vendor: "Intel Corporate"},
  {max_prefix: "94-C7-A8",vendor: "Jiangsu Huitong Group Co.,Ltd."},
  {max_prefix: "88-DD-B8",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "00-9B-08",vendor: "Quectel Wireless Solutions Co.,Ltd."},
  {max_prefix: "BC-DF-E1",vendor: "IEEE Registration Authority"},
  {max_prefix: "58-96-0A",vendor: "LG Electronics"},
  {max_prefix: "8C-BD-37",vendor: "Shenzhen Phaten Tech. LTD"},
  {max_prefix: "C4-D6-D3",vendor: "Dell Inc."},
  {max_prefix: "30-0A-9D",vendor: "Axino Solutions AG"},
  {max_prefix: "2C-D8-AE",vendor: "Shenzhen SEI Robotics Co.,Ltd"},
  {max_prefix: "C4-B7-57",vendor: "ALPSALPINE CO,.LTD"},
  {max_prefix: "88-F7-15",vendor: "Arista Networks"},
  {max_prefix: "90-E6-43",vendor: "Tesla,Inc."},
  {max_prefix: "40-B3-FA",vendor: "Apple, Inc."},
  {max_prefix: "9C-DA-36",vendor: "TECNO MOBILE LIMITED"},
  {max_prefix: "24-42-E3",vendor: "Shenzhen Ai-Thinker  Technology Co.,Ltd"},
  {max_prefix: "8C-BE-6F",vendor: "Tianyi Telecom Terminals Company Limited"},
  {max_prefix: "D0-C1-BF",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "A8-2B-D5",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "C0-C9-89",vendor: "Edgecore Americas Networking Corporation"},
  {max_prefix: "04-00-6E",vendor: "Google, Inc."},
  {max_prefix: "BC-D7-D4",vendor: "Roku, Inc"},
  {max_prefix: "F8-14-DD",vendor: "Cisco Systems, Inc"},
  {max_prefix: "0C-82-47",vendor: "CIG SHANGHAI CO LTD"},
  {max_prefix: "78-ED-25",vendor: "New H3C Technologies Co., Ltd"},
  {max_prefix: "44-76-09",vendor: "New H3C Technologies Co., Ltd"},
  {max_prefix: "04-C8-45",vendor: "TP-Link Systems Inc."},
  {max_prefix: "B0-C6-1C",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "44-BE-0B",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "7C-99-46",vendor: "Sector Alarm Tech S.L."},
  {max_prefix: "5C-5E-BB",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "40-25-08",vendor: "Highway 9 Networks, Inc."},
  {max_prefix: "04-A1-6F",vendor: "IEEE Registration Authority"},
  {max_prefix: "14-39-2F",vendor: "LEAR"},
  {max_prefix: "8C-3F-44",vendor: "GD Midea Air-Conditioning Equipment Co.,Ltd."},
  {max_prefix: "34-97-D7",vendor: "YEALINK(XIAMEN) NETWORK TECHNOLOGY CO.,LTD."},
  {max_prefix: "68-F6-2B",vendor: "ITEL MOBILE LIMITED"},
  {max_prefix: "24-7B-A4",vendor: "China Mobile Group Device Co.,Ltd."},
  {max_prefix: "28-43-DC",vendor: "United Memory Technology (Jiangsu) Limited"},
  {max_prefix: "98-12-E0",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "64-DD-68",vendor: "Zyxel Communications Corporation"},
  {max_prefix: "38-77-07",vendor: "AltoBeam Inc."},
  {max_prefix: "74-CA-60",vendor: "Sonos, Inc."},
  {max_prefix: "38-42-0B",vendor: "Sonos, Inc."},
  {max_prefix: "B4-C7-99",vendor: "Extreme Networks Headquarters"},
  {max_prefix: "58-69-6C",vendor: "Ruijie Networks Co.,LTD"},
  {max_prefix: "8C-83-94",vendor: "Arcadyan Corporation"},
  {max_prefix: "F0-AB-1F",vendor: "zte corporation"},
  {max_prefix: "7C-B3-0A",vendor: "zte corporation"},
  {max_prefix: "4C-62-DF",vendor: "Hangzhou Hikvision Digital Technology Co.,Ltd."},
  {max_prefix: "B0-0C-9D",vendor: "Quectel Wireless Solutions Co.,Ltd."},
  {max_prefix: "C8-67-5E",vendor: "Extreme Networks Headquarters"},
  {max_prefix: "C4-13-E2",vendor: "Extreme Networks Headquarters"},
  {max_prefix: "90-B8-32",vendor: "Extreme Networks Headquarters"},
  {max_prefix: "40-E3-17",vendor: "Extreme Networks Headquarters"},
  {max_prefix: "AC-4D-D9",vendor: "Extreme Networks Headquarters"},
  {max_prefix: "F4-CE-48",vendor: "Extreme Networks Headquarters"},
  {max_prefix: "00-DC-B2",vendor: "Extreme Networks Headquarters"},
  {max_prefix: "9C-5D-12",vendor: "Extreme Networks Headquarters"},
  {max_prefix: "F0-9C-E9",vendor: "Extreme Networks Headquarters"},
  {max_prefix: "CC-14-BC",vendor: "Edifier International"},
  {max_prefix: "E8-D3-EB",vendor: "eero inc."},
  {max_prefix: "20-0B-74",vendor: "AzureWave Technology Inc."},
  {max_prefix: "B0-10-4B",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "44-36-5D",vendor: "Shenzhen HippStor Technology Co., Ltd"},
  {max_prefix: "F8-FE-5E",vendor: "Intel Corporate"},
  {max_prefix: "54-C0-78",vendor: "Infinix mobility limited"},
  {max_prefix: "88-F9-16",vendor: "Qingdao Dayu Dance Digital Technology Co.,Ltd"},
  {max_prefix: "E8-17-11",vendor: "Shenzhen Vipstech Co., Ltd"},
  {max_prefix: "18-C0-09",vendor: "New H3C Technologies Co., Ltd"},
  {max_prefix: "7C-73-98",vendor: "Espressif Inc."},
  {max_prefix: "6C-B4-56",vendor: "Espressif Inc."},
  {max_prefix: "E4-E2-6C",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "30-30-F9",vendor: "Espressif Inc."},
  {max_prefix: "BC-24-11",vendor: "Proxmox Server Solutions GmbH"},
  {max_prefix: "70-61-73",vendor: "Calantec GmbH"},
  {max_prefix: "9C-49-52",vendor: "Dongguan Liesheng Electronic Co., Ltd."},
  {max_prefix: "C0-22-F1",vendor: "IEEE Registration Authority"},
  {max_prefix: "04-14-71",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "C8-16-A5",vendor: "Masimo Corporation"},
  {max_prefix: "44-6D-05",vendor: "NoTraffic"},
  {max_prefix: "38-C0-EA",vendor: "Fortinet, Inc."},
  {max_prefix: "D0-94-CF",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "54-22-59",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "DC-68-80",vendor: "zte corporation"},
  {max_prefix: "10-28-74",vendor: "Shenzhen Jingxun Technology Co., Ltd."},
  {max_prefix: "04-38-55",vendor: "Scopus International Pvt. Ltd."},
  {max_prefix: "A8-B4-83",vendor: "Shenzhen SuperElectron Technology Co.,Ltd."},
  {max_prefix: "90-0E-9E",vendor: "Shenzhen SuperElectron Technology Co.,Ltd."},
  {max_prefix: "54-F2-9F",vendor: "HUNAN FN-LINK TECHNOLOGY LIMITED"},
  {max_prefix: "C8-2E-18",vendor: "Espressif Inc."},
  {max_prefix: "E8-6B-EA",vendor: "Espressif Inc."},
  {max_prefix: "28-8E-B9",vendor: "Wacom Co.,Ltd."},
  {max_prefix: "C8-9D-6D",vendor: "ITEL MOBILE LIMITED"},
  {max_prefix: "EC-B0-D2",vendor: "EM Microelectronic"},
  {max_prefix: "4C-EB-76",vendor: "Murrelektronik GmbH"},
  {max_prefix: "68-55-D4",vendor: "Seiko Epson Corporation"},
  {max_prefix: "D4-22-CD",vendor: "Movella Technologies B.V."},
  {max_prefix: "28-BB-ED",vendor: "Bouffalo Lab (Nanjing) Co., Ltd."},
  {max_prefix: "34-4E-2F",vendor: "LEAR"},
  {max_prefix: "4C-A0-D4",vendor: "Telink Semiconductor (Shanghai) Co., Ltd."},
  {max_prefix: "98-ED-CA",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "0C-65-9A",vendor: "Panasonic Automotive Systems Company of America"},
  {max_prefix: "00-40-9E",vendor: "Concurrent Technologies Ltd."},
  {max_prefix: "EC-AB-3E",vendor: "ESSYS"},
  {max_prefix: "38-4A-80",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "E4-10-88",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "BC-8D-1F",vendor: "Cisco Systems, Inc"},
  {max_prefix: "04-57-91",vendor: "Shenzhenshi Xinzhongxin Technology Co.Ltd"},
  {max_prefix: "88-A6-EF",vendor: "IEEE Registration Authority"},
  {max_prefix: "54-DF-1B",vendor: "Vestel Elektronik San ve Tic. A.S."},
  {max_prefix: "38-F9-F5",vendor: "Garmin International"},
  {max_prefix: "60-BD-2C",vendor: "Taicang T&W Electronics"},
  {max_prefix: "C4-93-BB",vendor: "Beijing Xiaomi Mobile Software Co., Ltd"},
  {max_prefix: "E8-9C-25",vendor: "ASUSTek COMPUTER INC."},
  {max_prefix: "EC-C3-8A",vendor: "Accuenergy (CANADA) Inc"},
  {max_prefix: "14-99-3E",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "2C-4C-15",vendor: "Juniper Networks"},
  {max_prefix: "78-46-7D",vendor: "SKAIChips"},
  {max_prefix: "EC-74-8C",vendor: "Sony Interactive Entertainment Inc."},
  {max_prefix: "B0-4F-A6",vendor: "DongGuan Ramaxel Memory Technology"},
  {max_prefix: "4C-99-92",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "00-0C-C8",vendor: "Xytronix Research & Design, Inc."},
  {max_prefix: "84-F5-EB",vendor: "zte corporation"},
  {max_prefix: "D8-E7-2F",vendor: "Chipsea Technologies (Shenzhen) Corp."},
  {max_prefix: "74-C7-6E",vendor: "RTK-TECHNOLOGIES, LLC"},
  {max_prefix: "14-C0-50",vendor: "GUANGDONG GENIUS TECHNOLOGY CO., LTD."},
  {max_prefix: "44-1A-4C",vendor: "xFusion Digital Technologies Co.,Ltd."},
  {max_prefix: "4C-62-CD",vendor: "Nokia"},
  {max_prefix: "3C-52-A1",vendor: "TP-Link Corporation Limited"},
  {max_prefix: "98-DA-92",vendor: "Vuzix Corporation"},
  {max_prefix: "78-46-5C",vendor: "CLOUD NETWORK TECHNOLOGY SINGAPORE PTE. LTD."},
  {max_prefix: "D0-DD-7C",vendor: "zte corporation"},
  {max_prefix: "84-3C-99",vendor: "zte corporation"},
  {max_prefix: "9C-F8-6B",vendor: "AgiTech Distribution Limited - Linki"},
  {max_prefix: "F8-E5-CE",vendor: "Apple, Inc."},
  {max_prefix: "68-E5-9E",vendor: "Cisco Systems, Inc"},
  {max_prefix: "1C-C9-92",vendor: "Honor Device Co., Ltd."},
  {max_prefix: "40-0F-C1",vendor: "Vantiva USA LLC"},
  {max_prefix: "74-97-8E",vendor: "Nova Labs"},
  {max_prefix: "08-23-C6",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "10-7B-93",vendor: "Zhen Shi Information Technology (Shanghai) Co., Ltd."},
  {max_prefix: "70-5A-9E",vendor: "Vantiva USA LLC"},
  {max_prefix: "88-F7-C7",vendor: "Vantiva USA LLC"},
  {max_prefix: "1C-9D-72",vendor: "Vantiva USA LLC"},
  {max_prefix: "3C-82-C0",vendor: "Vantiva USA LLC"},
  {max_prefix: "80-DA-C2",vendor: "Vantiva USA LLC"},
  {max_prefix: "48-1B-40",vendor: "Vantiva USA LLC"},
  {max_prefix: "B8-5E-71",vendor: "Vantiva USA LLC"},
  {max_prefix: "0C-02-27",vendor: "Vantiva USA LLC"},
  {max_prefix: "14-B7-F8",vendor: "Vantiva USA LLC"},
  {max_prefix: "10-62-D0",vendor: "Vantiva USA LLC"},
  {max_prefix: "10-C2-5A",vendor: "Vantiva USA LLC"},
  {max_prefix: "F4-C1-14",vendor: "Vantiva USA LLC"},
  {max_prefix: "F8-3B-1D",vendor: "Vantiva USA LLC"},
  {max_prefix: "E4-BF-FA",vendor: "Vantiva USA LLC"},
  {max_prefix: "08-D1-F9",vendor: "Espressif Inc."},
  {max_prefix: "34-B7-DA",vendor: "Espressif Inc."},
  {max_prefix: "10-71-FA",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "D0-8A-91",vendor: "Vantiva USA LLC"},
  {max_prefix: "C8-7F-54",vendor: "ASUSTek COMPUTER INC."},
  {max_prefix: "70-36-B2",vendor: "Focusai Corp"},
  {max_prefix: "4C-D7-17",vendor: "Dell Inc."},
  {max_prefix: "00-C7-11",vendor: "ITEL MOBILE LIMITED"},
  {max_prefix: "C8-4C-78",vendor: "zte corporation"},
  {max_prefix: "80-2D-1A",vendor: "zte corporation"},
  {max_prefix: "68-23-F4",vendor: "Shenzhen Jinlangxin Technology Co., Ltd"},
  {max_prefix: "90-83-7E",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "CC-B0-71",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "48-55-41",vendor: "Iskratel d.o.o."},
  {max_prefix: "F0-90-08",vendor: "Shenzhen Skyworth Digital  Technology  CO., Ltd"},
  {max_prefix: "34-8D-52",vendor: "Sichuan Tianyi Comheart Telecom Co.,LTD"},
  {max_prefix: "1C-86-0B",vendor: "Guangdong Taiying Technology Co.,Ltd"},
  {max_prefix: "74-D7-13",vendor: "Huaqin Technology Co. LTD"},
  {max_prefix: "2C-C3-E6",vendor: "SHENZHEN BILIAN ELECTRONIC CO.\uff0cLTD"},
  {max_prefix: "F0-20-FF",vendor: "Intel Corporate"},
  {max_prefix: "C8-8A-9A",vendor: "Intel Corporate"},
  {max_prefix: "A0-B3-39",vendor: "Intel Corporate"},
  {max_prefix: "6C-F6-DA",vendor: "Intel Corporate"},
  {max_prefix: "C8-6B-BC",vendor: "IEEE Registration Authority"},
  {max_prefix: "30-60-0A",vendor: "CIG SHANGHAI CO LTD"},
  {max_prefix: "68-13-F3",vendor: "Amazon Technologies Inc."},
  {max_prefix: "80-6D-97",vendor: "Private"},
  {max_prefix: "4C-70-CC",vendor: "Blyott NV"},
  {max_prefix: "30-2F-1E",vendor: "SIEMENS AG"},
  {max_prefix: "28-EB-0A",vendor: "Rolling Wireless S.a.r.l. Luxembourg"},
  {max_prefix: "84-8A-59",vendor: "Hisilicon Technologies Co., Ltd"},
  {max_prefix: "58-68-61",vendor: "VIASAT, INCORPORATED"},
  {max_prefix: "14-C9-CF",vendor: "Sigmastar Technology Ltd."},
  {max_prefix: "84-7A-DF",vendor: "FUJIAN STAR-NET COMMUNICATION CO.,LTD"},
  {max_prefix: "DC-CD-66",vendor: "NXP Semiconductor (Tianjin) LTD."},
  {max_prefix: "60-74-F4",vendor: "Private"},
  {max_prefix: "A0-37-68",vendor: "Shenzhen E-Life Intelligence Technology Co.,Ltd."},
  {max_prefix: "44-FA-66",vendor: "CLOUD NETWORK TECHNOLOGY SINGAPORE PTE. LTD."},
  {max_prefix: "FC-B2-D6",vendor: "CIG SHANGHAI CO LTD"},
  {max_prefix: "60-FC-F1",vendor: "Private"},
  {max_prefix: "54-76-B2",vendor: "Raisecom Technology CO., LTD"},
  {max_prefix: "14-33-75",vendor: "Zyxel Communications Corporation"},
  {max_prefix: "2C-FE-E2",vendor: "Qingdao Hisense Communications Co.,Ltd."},
  {max_prefix: "B0-FF-72",vendor: "IEEE Registration Authority"},
  {max_prefix: "64-9A-63",vendor: "Ring LLC"},
  {max_prefix: "10-28-34",vendor: "SALZ Automation GmbH"},
  {max_prefix: "60-C0-1E",vendor: "V&G Information System Co.,Ltd"},
  {max_prefix: "2C-3C-05",vendor: "Marinesync Corp"},
  {max_prefix: "14-85-54",vendor: "Earda Technologies co Ltd"},
  {max_prefix: "00-08-B9",vendor: "Kaon Group Co., Ltd."},
  {max_prefix: "C4-D6-66",vendor: "Cisco Meraki"},
  {max_prefix: "18-DE-50",vendor: "Tuya Smart Inc."},
  {max_prefix: "98-CC-F3",vendor: "Amazon Technologies Inc."},
  {max_prefix: "50-91-E3",vendor: "TP-Link Corporation Limited"},
  {max_prefix: "F0-21-78",vendor: "UNIONMAN TECHNOLOGY CO.,LTD"},
  {max_prefix: "94-79-18",vendor: "ITEL MOBILE LIMITED"},
  {max_prefix: "68-F0-B5",vendor: "Honor Device Co., Ltd."},
  {max_prefix: "00-14-57",vendor: "Nevion"},
  {max_prefix: "00-50-01",vendor: "YAMASHITA SYSTEMS CORP."},
  {max_prefix: "6C-EE-F7",vendor: "shenzhen scodeno technology co., Ltd."},
  {max_prefix: "2C-3E-BF",vendor: "HOSIN Global Electronics Co., Ltd."},
  {max_prefix: "40-D5-63",vendor: "HANA Electronics"},
  {max_prefix: "3C-33-32",vendor: "D-Link Corporation"},
  {max_prefix: "B4-40-DC",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "64-17-CD",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "DC-69-E2",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "C0-50-64",vendor: "SHENNAN CIRCUITS CO.,LTD"},
  {max_prefix: "9C-FB-77",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "64-3E-0A",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "34-88-18",vendor: "Cisco Systems, Inc"},
  {max_prefix: "40-2A-8F",vendor: "Shanghai High-Flying Electronics  Technology Co., Ltd"},
  {max_prefix: "DC-2D-DE",vendor: "Ledworks SRL"},
  {max_prefix: "10-18-49",vendor: "WEIFANG GOERTEK ELECTRONICS CO.,LTD"},
  {max_prefix: "08-F9-E0",vendor: "Espressif Inc."},
  {max_prefix: "00-36-D7",vendor: "Keltron IOT Corp."},
  {max_prefix: "34-DC-99",vendor: "New H3C Technologies Co., Ltd"},
  {max_prefix: "5C-E8-D3",vendor: "Signalinks Communication Technology Co., Ltd"},
  {max_prefix: "44-05-3F",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "80-80-2C",vendor: "Fortinet, Inc."},
  {max_prefix: "48-31-B7",vendor: "Espressif Inc."},
  {max_prefix: "54-32-04",vendor: "Espressif Inc."},
  {max_prefix: "CC-B5-4C",vendor: "Texas Instruments"},
  {max_prefix: "74-A5-8C",vendor: "Texas Instruments"},
  {max_prefix: "3C-E0-02",vendor: "Texas Instruments"},
  {max_prefix: "98-03-8A",vendor: "Texas Instruments"},
  {max_prefix: "B8-60-61",vendor: "China Mobile Group Device Co.,Ltd."},
  {max_prefix: "B0-38-93",vendor: "Onda TLC Italia S.r.l."},
  {max_prefix: "8C-DF-2C",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "F8-79-28",vendor: "zte corporation"},
  {max_prefix: "D8-8E-D4",vendor: "eero inc."},
  {max_prefix: "94-3F-D6",vendor: "Apple, Inc."},
  {max_prefix: "00-20-67",vendor: "Private"},
  {max_prefix: "2C-D7-FF",vendor: "LANCOM Systems GmbH"},
  {max_prefix: "38-AB-16",vendor: "NPO RTT LLC"},
  {max_prefix: "34-85-11",vendor: "Shenzhen Skyworth Digital  Technology  CO., Ltd"},
  {max_prefix: "F8-B8-B4",vendor: "Shenzhen Skyworth Digital  Technology  CO., Ltd"},
  {max_prefix: "68-1A-A4",vendor: "Shenzhen Skyworth Digital  Technology  CO., Ltd"},
  {max_prefix: "48-55-5E",vendor: "Shenzhen Skyworth Digital  Technology  CO., Ltd"},
  {max_prefix: "28-C1-A0",vendor: "Apple, Inc."},
  {max_prefix: "EC-2C-73",vendor: "Apple, Inc."},
  {max_prefix: "7C-C0-6F",vendor: "Apple, Inc."},
  {max_prefix: "EC-81-50",vendor: "Apple, Inc."},
  {max_prefix: "D4-2F-CA",vendor: "Apple, Inc."},
  {max_prefix: "D0-58-A5",vendor: "Apple, Inc."},
  {max_prefix: "2C-67-AB",vendor: "EZELINK TELECOM"},
  {max_prefix: "B0-97-38",vendor: "Shenzhen Skyworth Digital  Technology  CO., Ltd"},
  {max_prefix: "1C-88-0C",vendor: "Shenzhen Skyworth Digital  Technology  CO., Ltd"},
  {max_prefix: "70-06-92",vendor: "IEEE Registration Authority"},
  {max_prefix: "38-B5-C9",vendor: "INGRAM MICRO SERVICES"},
  {max_prefix: "98-4B-06",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "AC-FF-6B",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "84-4D-BE",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "80-B9-46",vendor: "Nokia"},
  {max_prefix: "F8-CA-B8",vendor: "Dell Inc."},
  {max_prefix: "44-A8-42",vendor: "Dell Inc."},
  {max_prefix: "4C-76-25",vendor: "Dell Inc."},
  {max_prefix: "00-11-43",vendor: "Dell Inc."},
  {max_prefix: "00-13-72",vendor: "Dell Inc."},
  {max_prefix: "F8-BC-12",vendor: "Dell Inc."},
  {max_prefix: "34-17-EB",vendor: "Dell Inc."},
  {max_prefix: "B0-83-FE",vendor: "Dell Inc."},
  {max_prefix: "00-08-74",vendor: "Dell Inc."},
  {max_prefix: "00-24-E8",vendor: "Dell Inc."},
  {max_prefix: "00-22-19",vendor: "Dell Inc."},
  {max_prefix: "00-18-8B",vendor: "Dell Inc."},
  {max_prefix: "F0-1F-AF",vendor: "Dell Inc."},
  {max_prefix: "18-A9-9B",vendor: "Dell Inc."},
  {max_prefix: "AC-DE-48",vendor: "Private"},
  {max_prefix: "00-50-C7",vendor: "Private"},
  {max_prefix: "54-88-FE",vendor: "Xiaoniu network technology (Shanghai) Co., Ltd."},
  {max_prefix: "F4-D5-80",vendor: "YAMAHA CORPORATION"},
  {max_prefix: "50-30-F4",vendor: "Exascend, Inc."},
  {max_prefix: "60-18-95",vendor: "Dell Inc."},
  {max_prefix: "B0-4F-13",vendor: "Dell Inc."},
  {max_prefix: "38-14-28",vendor: "Dell Inc."},
  {max_prefix: "F4-EE-08",vendor: "Dell Inc."},
  {max_prefix: "90-8D-6E",vendor: "Dell Inc."},
  {max_prefix: "EC-2A-72",vendor: "Dell Inc."},
  {max_prefix: "74-86-E2",vendor: "Dell Inc."},
  {max_prefix: "00-BE-43",vendor: "Dell Inc."},
  {max_prefix: "60-5B-30",vendor: "Dell Inc."},
  {max_prefix: "C8-4B-D6",vendor: "Dell Inc."},
  {max_prefix: "E8-65-5F",vendor: "Dell Inc."},
  {max_prefix: "E8-B2-65",vendor: "Dell Inc."},
  {max_prefix: "AC-91-A1",vendor: "Dell Inc."},
  {max_prefix: "C4-CB-E1",vendor: "Dell Inc."},
  {max_prefix: "10-7D-1A",vendor: "Dell Inc."},
  {max_prefix: "50-9A-4C",vendor: "Dell Inc."},
  {max_prefix: "40-5C-FD",vendor: "Dell Inc."},
  {max_prefix: "D0-94-66",vendor: "Dell Inc."},
  {max_prefix: "D8-9E-F3",vendor: "Dell Inc."},
  {max_prefix: "B4-E1-0F",vendor: "Dell Inc."},
  {max_prefix: "18-66-DA",vendor: "Dell Inc."},
  {max_prefix: "18-DB-F2",vendor: "Dell Inc."},
  {max_prefix: "14-B3-1F",vendor: "Dell Inc."},
  {max_prefix: "88-6F-D4",vendor: "Dell Inc."},
  {max_prefix: "5C-F9-DD",vendor: "Dell Inc."},
  {max_prefix: "00-4E-01",vendor: "Dell Inc."},
  {max_prefix: "10-65-30",vendor: "Dell Inc."},
  {max_prefix: "3C-2C-30",vendor: "Dell Inc."},
  {max_prefix: "24-71-52",vendor: "Dell Inc."},
  {max_prefix: "8C-47-BE",vendor: "Dell Inc."},
  {max_prefix: "94-CB-CD",vendor: "zte corporation"},
  {max_prefix: "80-5F-8E",vendor: "Huizhou BYD Electronic Co., Ltd."},
  {max_prefix: "A8-3A-79",vendor: "Mist Systems, Inc."},
  {max_prefix: "E4-90-69",vendor: "Rockwell Automation"},
  {max_prefix: "18-4C-08",vendor: "Rockwell Automation"},
  {max_prefix: "FC-35-E6",vendor: "Visteon Corporation"},
  {max_prefix: "8C-BA-25",vendor: "UNION MAN TECHNOLOGY CO.,LTD"},
  {max_prefix: "54-72-5E",vendor: "UNION MAN TECHNOLOGY CO.,LTD"},
  {max_prefix: "F8-14-FE",vendor: "UNION MAN TECHNOLOGY CO.,LTD"},
  {max_prefix: "CC-62-FE",vendor: "UNION MAN TECHNOLOGY CO.,LTD"},
  {max_prefix: "C0-95-73",vendor: "AIxLink"},
  {max_prefix: "40-41-01",vendor: "Rockwell Automation"},
  {max_prefix: "68-C8-EB",vendor: "Rockwell Automation"},
  {max_prefix: "40-F2-1C",vendor: "DZS Inc."},
  {max_prefix: "00-18-0C",vendor: "DZS Inc."},
  {max_prefix: "00-E0-DF",vendor: "DZS Inc."},
  {max_prefix: "00-A0-1B",vendor: "DZS Inc."},
  {max_prefix: "D0-93-95",vendor: "IEEE Registration Authority"},
  {max_prefix: "7C-BF-AE",vendor: "Renesas Electronics (Penang) Sdn. Bhd."},
  {max_prefix: "80-AF-CA",vendor: "Shenzhen Cudy Technology Co., Ltd."},
  {max_prefix: "1C-6E-74",vendor: "EnOcean Edge Inc."},
  {max_prefix: "04-47-2A",vendor: "Palo Alto Networks"},
  {max_prefix: "C0-2C-17",vendor: "Cisco Systems, Inc"},
  {max_prefix: "98-49-25",vendor: "Juniper Networks"},
  {max_prefix: "44-19-B6",vendor: "Hangzhou Hikvision Digital Technology Co.,Ltd."},
  {max_prefix: "B4-17-A8",vendor: "Meta Platforms Technologies, LLC"},
  {max_prefix: "C0-48-84",vendor: "Sigma Bilisim Sist. Tekn. Elk. Enj. ve San. D??. Tic. Ltd. ?ti."},
  {max_prefix: "7C-89-31",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "BC-5E-33",vendor: "Hangzhou Hikvision Digital Technology Co.,Ltd."},
  {max_prefix: "FC-9F-FD",vendor: "Hangzhou Hikvision Digital Technology Co.,Ltd."},
  {max_prefix: "E8-A0-ED",vendor: "Hangzhou Hikvision Digital Technology Co.,Ltd."},
  {max_prefix: "5C-34-5B",vendor: "Hangzhou Hikvision Digital Technology Co.,Ltd."},
  {max_prefix: "7C-ED-C6",vendor: "Amazon Technologies Inc."},
  {max_prefix: "48-25-F3",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "4C-BD-8F",vendor: "Hangzhou Hikvision Digital Technology Co.,Ltd."},
  {max_prefix: "F4-12-DA",vendor: "zte corporation"},
  {max_prefix: "54-02-95",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "C8-78-7D",vendor: "D-Link Corporation"},
  {max_prefix: "D8-B3-70",vendor: "Ubiquiti Inc"},
  {max_prefix: "B0-A7-32",vendor: "Espressif Inc."},
  {max_prefix: "B0-B2-1C",vendor: "Espressif Inc."},
  {max_prefix: "E0-9B-27",vendor: "Ciena Corporation"},
  {max_prefix: "8C-E0-42",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "FC-EC-DA",vendor: "Ubiquiti Inc"},
  {max_prefix: "94-2A-6F",vendor: "Ubiquiti Inc"},
  {max_prefix: "F4-E2-C6",vendor: "Ubiquiti Inc"},
  {max_prefix: "78-FE-3D",vendor: "Juniper Networks"},
  {max_prefix: "64-87-88",vendor: "Juniper Networks"},
  {max_prefix: "AC-4B-C8",vendor: "Juniper Networks"},
  {max_prefix: "3C-94-D5",vendor: "Juniper Networks"},
  {max_prefix: "B4-FB-E4",vendor: "Ubiquiti Inc"},
  {max_prefix: "68-72-51",vendor: "Ubiquiti Inc"},
  {max_prefix: "DC-38-E1",vendor: "Juniper Networks"},
  {max_prefix: "CC-E1-7F",vendor: "Juniper Networks"},
  {max_prefix: "9C-C8-93",vendor: "Juniper Networks"},
  {max_prefix: "58-E4-34",vendor: "Juniper Networks"},
  {max_prefix: "B0-EB-7F",vendor: "Juniper Networks"},
  {max_prefix: "28-B8-29",vendor: "Juniper Networks"},
  {max_prefix: "E8-97-B8",vendor: "Chiun Mai Communication System, Inc"},
  {max_prefix: "94-F5-24",vendor: "Chengdu BeiZhongWangXin Technology Co.Ltd"},
  {max_prefix: "60-FA-B1",vendor: "Kempower Oyj"},
  {max_prefix: "40-71-83",vendor: "Juniper Networks"},
  {max_prefix: "40-B4-F0",vendor: "Juniper Networks"},
  {max_prefix: "38-4F-49",vendor: "Juniper Networks"},
  {max_prefix: "58-00-BB",vendor: "Juniper Networks"},
  {max_prefix: "D0-07-CA",vendor: "Juniper Networks"},
  {max_prefix: "D8-B1-22",vendor: "Juniper Networks"},
  {max_prefix: "FC-57-03",vendor: "Hisense broadband multimedia technology Co.,Ltd"},
  {max_prefix: "64-C3-D6",vendor: "Juniper Networks"},
  {max_prefix: "C0-BF-A7",vendor: "Juniper Networks"},
  {max_prefix: "B0-33-A6",vendor: "Juniper Networks"},
  {max_prefix: "20-1B-C9",vendor: "Juniper Networks"},
  {max_prefix: "54-1E-56",vendor: "Juniper Networks"},
  {max_prefix: "88-A2-5E",vendor: "Juniper Networks"},
  {max_prefix: "00-31-46",vendor: "Juniper Networks"},
  {max_prefix: "50-C7-09",vendor: "Juniper Networks"},
  {max_prefix: "00-C5-2C",vendor: "Juniper Networks"},
  {max_prefix: "B4-8A-5F",vendor: "Juniper Networks"},
  {max_prefix: "E0-F6-2D",vendor: "Juniper Networks"},
  {max_prefix: "EC-7C-5C",vendor: "Juniper Networks"},
  {max_prefix: "80-7F-F8",vendor: "Juniper Networks"},
  {max_prefix: "C0-EA-C3",vendor: "IEEE Registration Authority"},
  {max_prefix: "FC-A0-F3",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "04-A8-1C",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "30-40-74",vendor: "zte corporation"},
  {max_prefix: "A0-10-77",vendor: "zte corporation"},
  {max_prefix: "54-B7-BD",vendor: "Arcadyan Corporation"},
  {max_prefix: "BC-73-A4",vendor: "ANDA TELECOM PVT LTD"},
  {max_prefix: "14-F5-F9",vendor: "HUNAN FN-LINK TECHNOLOGY LIMITED"},
  {max_prefix: "14-0F-A6",vendor: "Renesas Electronics (Penang) Sdn. Bhd."},
  {max_prefix: "14-84-73",vendor: "Cisco Systems, Inc"},
  {max_prefix: "AC-17-54",vendor: "tiko Energy Solutions AG"},
  {max_prefix: "AC-E0-D6",vendor: "koreabts"},
  {max_prefix: "A4-C2-3E",vendor: "Huizhou Speed Wireless Technology Co.,Ltd"},
  {max_prefix: "00-50-C4",vendor: "IMD"},
  {max_prefix: "00-1A-A6",vendor: "Elbit Systems Deutschland GmbH & Co. KG"},
  {max_prefix: "7C-45-F9",vendor: "IEEE Registration Authority"},
  {max_prefix: "10-CF-0F",vendor: "Apple, Inc."},
  {max_prefix: "90-31-4B",vendor: "AltoBeam Inc."},
  {max_prefix: "D8-63-8C",vendor: "Shenzhen Dttek Technology Co., Ltd."},
  {max_prefix: "1C-CA-41",vendor: "AO"},
  {max_prefix: "34-7D-E4",vendor: "SHENZHEN BILIAN ELECTRONIC CO.\uff0cLTD"},
  {max_prefix: "CC-CF-83",vendor: "CIG SHANGHAI CO LTD"},
  {max_prefix: "04-F7-78",vendor: "Sony Interactive Entertainment Inc."},
  {max_prefix: "B4-63-6F",vendor: "Nokia Solutions and Networks GmbH & Co. KG"},
  {max_prefix: "24-D3-37",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "7C-7A-3C",vendor: "New H3C Technologies Co., Ltd"},
  {max_prefix: "48-31-77",vendor: "Nintendo Co.,Ltd"},
  {max_prefix: "1C-76-F2",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "00-92-A5",vendor: "LG Innotek"},
  {max_prefix: "20-5F-3D",vendor: "Adtran Inc"},
  {max_prefix: "3C-3B-4D",vendor: "Toyo Seisakusho Kaisha, Limited"},
  {max_prefix: "3C-BC-D0",vendor: "zte corporation"},
  {max_prefix: "D0-A9-D3",vendor: "EM Microelectronic"},
  {max_prefix: "14-DD-02",vendor: "Liangang Optoelectronic Technology CO., Ltd."},
  {max_prefix: "60-C7-27",vendor: "Digiboard Eletronica da Amazonia Ltda"},
  {max_prefix: "40-8E-F6",vendor: "Infinix mobility limited"},
  {max_prefix: "E0-2D-F0",vendor: "ALPSALPINE CO,.LTD"},
  {max_prefix: "64-6E-60",vendor: "zte corporation"},
  {max_prefix: "4C-42-1E",vendor: "Cisco Systems, Inc"},
  {max_prefix: "64-B2-B4",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "48-31-DB",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "58-70-7F",vendor: "Ericsson AB"},
  {max_prefix: "88-74-77",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "FC-31-5D",vendor: "Apple, Inc."},
  {max_prefix: "D0-39-FA",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "B4-0B-1D",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "AC-80-FB",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "14-7F-0F",vendor: "Texas Instruments"},
  {max_prefix: "44-4A-D6",vendor: "Shenzhen Rinocloud Technology Co.,Ltd."},
  {max_prefix: "18-9E-AD",vendor: "Shenzhen Chengqian Information Technology Co., Ltd"},
  {max_prefix: "94-DD-F8",vendor: "Brother Industries, LTD."},
  {max_prefix: "84-B3-86",vendor: "IEEE Registration Authority"},
  {max_prefix: "A4-5D-5E",vendor: "Wilk Elektronik S.A."},
  {max_prefix: "98-BF-F4",vendor: "MARKIN co., Ltd."},
  {max_prefix: "78-60-5B",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "04-F9-F8",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "74-A6-CD",vendor: "Apple, Inc."},
  {max_prefix: "2C-7C-F2",vendor: "Apple, Inc."},
  {max_prefix: "30-D7-A1",vendor: "Apple, Inc."},
  {max_prefix: "E0-38-2D",vendor: "IEEE Registration Authority"},
  {max_prefix: "68-FC-B6",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "A8-72-7E",vendor: "WISDRI (wuhan) Automation Company Limited"},
  {max_prefix: "DC-D2-FD",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "58-73-D1",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "F4-2D-06",vendor: "zte corporation"},
  {max_prefix: "1C-67-4A",vendor: "zte corporation"},
  {max_prefix: "7C-57-58",vendor: "HP Inc."},
  {max_prefix: "C8-29-C8",vendor: "Palo Alto Networks"},
  {max_prefix: "9C-53-22",vendor: "TP-Link Corporation Limited"},
  {max_prefix: "48-22-54",vendor: "TP-Link Corporation Limited"},
  {max_prefix: "4C-B0-87",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "88-B4-BE",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "94-7B-AE",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "38-F8-F6",vendor: "Adtran Inc"},
  {max_prefix: "B4-E2-65",vendor: "Shenzhen SDMC Technology CO.,Ltd."},
  {max_prefix: "00-C2-8F",vendor: "Allied Telesis K.K."},
  {max_prefix: "38-47-F2",vendor: "Recogni Inc"},
  {max_prefix: "D8-AD-49",vendor: "Honor Device Co., Ltd."},
  {max_prefix: "B4-84-D5",vendor: "GooWi Wireless Technology Co., Limited"},
  {max_prefix: "54-07-7D",vendor: "NETGEAR"},
  {max_prefix: "C4-53-79",vendor: "Micronview Limited Liability Company"},
  {max_prefix: "D8-31-2C",vendor: "zte corporation"},
  {max_prefix: "04-9F-15",vendor: "Humane"},
  {max_prefix: "38-38-A6",vendor: "Arista Networks"},
  {max_prefix: "50-23-6D",vendor: "Nintendo Co.,Ltd"},
  {max_prefix: "A0-39-75",vendor: "Leo Bodnar Electronics Ltd"},
  {max_prefix: "74-93-DA",vendor: "ASKEY COMPUTER CORP"},
  {max_prefix: "C8-4A-A0",vendor: "Sony Interactive Entertainment Inc."},
  {max_prefix: "4C-96-8A",vendor: "Wacom Co.,Ltd."},
  {max_prefix: "EC-83-B7",vendor: "PUWELL CLOUD TECH LIMITED"},
  {max_prefix: "0C-64-22",vendor: "Beijing Wiseasy Technology Co.,Ltd."},
  {max_prefix: "C8-F2-B4",vendor: "Guizhou Huaxin Information Technology Co., Ltd."},
  {max_prefix: "F4-27-56",vendor: "DASAN Newtork Solutions"},
  {max_prefix: "E0-A1-CE",vendor: "zte corporation"},
  {max_prefix: "24-A6-FA",vendor: "WEIFANG GOERTEK ELECTRONICS CO.,LTD"},
  {max_prefix: "2C-75-CB",vendor: "Novitec Co., Ltd."},
  {max_prefix: "9C-55-8F",vendor: "Lockin Technology(Beijing) Co.,Ltd."},
  {max_prefix: "E8-26-8D",vendor: "Shenzhen SuperElectron Technology Co.,Ltd."},
  {max_prefix: "6C-91-06",vendor: "Katena Computing Technologies"},
  {max_prefix: "6C-70-CB",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "90-7B-C6",vendor: "Texas Instruments"},
  {max_prefix: "90-48-46",vendor: "Texas Instruments"},
  {max_prefix: "C4-79-05",vendor: "Zhejiang Uniview Technologies Co.,Ltd."},
  {max_prefix: "50-27-A9",vendor: "eero inc."},
  {max_prefix: "A4-11-BB",vendor: "Cisco Systems, Inc"},
  {max_prefix: "50-49-21",vendor: "Cisco Systems, Inc"},
  {max_prefix: "10-BB-F3",vendor: "HUNAN FN-LINK TECHNOLOGY LIMITED"},
  {max_prefix: "D0-4E-99",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "20-A7-66",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "3C-05-8E",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "F0-C7-25",vendor: "Apple, Inc."},
  {max_prefix: "20-1A-94",vendor: "Apple, Inc."},
  {max_prefix: "B0-E5-EF",vendor: "Apple, Inc."},
  {max_prefix: "28-8F-F6",vendor: "Apple, Inc."},
  {max_prefix: "44-B7-D0",vendor: "Microchip Technology Inc."},
  {max_prefix: "D4-DA-21",vendor: "Beijing Xiaomi Mobile Software Co., Ltd"},
  {max_prefix: "64-44-D5",vendor: "TD Tech"},
  {max_prefix: "E8-8F-6F",vendor: "TCT mobile ltd"},
  {max_prefix: "84-FC-E6",vendor: "Espressif Inc."},
  {max_prefix: "58-B9-65",vendor: "Apple, Inc."},
  {max_prefix: "74-31-74",vendor: "Apple, Inc."},
  {max_prefix: "CC-CC-77",vendor: "Zaram Technology. Inc."},
  {max_prefix: "BC-45-8C",vendor: "Shenzhen Topwise Communication Co.,Ltd"},
  {max_prefix: "94-78-06",vendor: "NINGBO SUNVOT TECHNOLOGY CO.,LTD"},
  {max_prefix: "78-4F-24",vendor: "Taicang T&W Electronics"},
  {max_prefix: "F8-AA-B3",vendor: "DESSMANN (China) Machinery & Electronic  Co., Ltd"},
  {max_prefix: "EC-DA-3B",vendor: "Espressif Inc."},
  {max_prefix: "68-13-E2",vendor: "Eltex Enterprise LTD"},
  {max_prefix: "7C-66-9A",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "CC-1E-97",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "B4-E4-6B",vendor: "China Mobile IOT Company Limited"},
  {max_prefix: "BC-B9-23",vendor: "Alta Networks"},
  {max_prefix: "9C-54-67",vendor: "Nokia"},
  {max_prefix: "B4-61-42",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "8C-84-42",vendor: "Cisco Systems, Inc"},
  {max_prefix: "84-F1-17",vendor: "Newseason"},
  {max_prefix: "C8-EF-BC",vendor: "Inspur Communication Technology Co.,Ltd."},
  {max_prefix: "98-CC-E4",vendor: "Shenzhen Mindray Animal Medical Technology Co.,LTD"},
  {max_prefix: "3C-06-64",vendor: "Beijing Leagrid Technology Co.,Ltd."},
  {max_prefix: "BC-52-74",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "90-09-DF",vendor: "Intel Corporate"},
  {max_prefix: "E4-0D-36",vendor: "Intel Corporate"},
  {max_prefix: "14-9F-43",vendor: "Cisco Meraki"},
  {max_prefix: "64-DB-38",vendor: "zte corporation"},
  {max_prefix: "2C-08-B4",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "B4-04-21",vendor: "zte corporation"},
  {max_prefix: "E8-43-68",vendor: "zte corporation"},
  {max_prefix: "50-07-C3",vendor: "Amazon Technologies Inc."},
  {max_prefix: "84-8D-CE",vendor: "Ciena Corporation"},
  {max_prefix: "E4-66-AB",vendor: "zte corporation"},
  {max_prefix: "A0-95-7F",vendor: "SERNET (SUZHOU) TECHNOLOGIES CORPORATION"},
  {max_prefix: "64-13-5A",vendor: "Itectra A/S"},
  {max_prefix: "54-E1-B6",vendor: "Renesas Electronics (Penang) Sdn. Bhd."},
  {max_prefix: "CC-77-C9",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "2C-57-2C",vendor: "Allwinner Technology Co., Ltd"},
  {max_prefix: "EC-DF-C9",vendor: "Hangzhou Microimage Software Co., Ltd"},
  {max_prefix: "3C-E4-41",vendor: "Amazon Technologies Inc."},
  {max_prefix: "44-42-01",vendor: "Amazon Technologies Inc."},
  {max_prefix: "E4-6D-7F",vendor: "Ciena Corporation"},
  {max_prefix: "80-27-6C",vendor: "Cisco Systems, Inc"},
  {max_prefix: "6C-4E-F6",vendor: "Cisco Systems, Inc"},
  {max_prefix: "5C-27-63",vendor: "Itibia Technologies"},
  {max_prefix: "40-C1-F6",vendor: "Shenzhen Jingxun Technology Co., Ltd."},
  {max_prefix: "FC-22-D3",vendor: "FDSYS"},
  {max_prefix: "4C-5C-DF",vendor: "ITEL MOBILE LIMITED"},
  {max_prefix: "C0-2E-26",vendor: "iRhythm Technologies, Inc."},
  {max_prefix: "00-30-AF",vendor: "Honeywell GmbH"},
  {max_prefix: "1C-B8-BA",vendor: "XIAMEN LEELEN TECHNOLOGY CO., LTD"},
  {max_prefix: "F0-CC-E0",vendor: "Shenzhen All-Smartlink Technology Co.,Ltd."},
  {max_prefix: "F8-5C-7E",vendor: "Shenzhen Honesty Electronics Co.,Ltd."},
  {max_prefix: "F8-24-E4",vendor: "Beyonics Technology Electronic (Changshu) Co., Ltd"},
  {max_prefix: "44-E2-F1",vendor: "NewRadio Technologies Co. , Ltd."},
  {max_prefix: "50-48-77",vendor: "Honor Device Co., Ltd."},
  {max_prefix: "78-C1-AE",vendor: "Hangzhou Ezviz Software Co.,Ltd."},
  {max_prefix: "30-C6-D7",vendor: "New H3C Technologies Co., Ltd"},
  {max_prefix: "F4-05-95",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "00-23-4B",vendor: "Inyuan Technology Inc."},
  {max_prefix: "00-17-6E",vendor: "DUCATI SISTEMI"},
  {max_prefix: "1C-6A-76",vendor: "Apple, Inc."},
  {max_prefix: "6C-7E-67",vendor: "Apple, Inc."},
  {max_prefix: "A4-C6-F0",vendor: "Apple, Inc."},
  {max_prefix: "A8-8F-D9",vendor: "Apple, Inc."},
  {max_prefix: "08-95-42",vendor: "Apple, Inc."},
  {max_prefix: "E4-9C-67",vendor: "Apple, Inc."},
  {max_prefix: "64-E2-20",vendor: "Qisda Corporation"},
  {max_prefix: "D4-F2-42",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "B0-78-39",vendor: "GD Midea Air-Conditioning Equipment Co.,Ltd."},
  {max_prefix: "F4-1C-71",vendor: "SHENZHEN SANMU COMMUNICATION TECHNOLOGY CO., LTD"},
  {max_prefix: "BC-10-7B",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "D4-AD-20",vendor: "Jinan USR IOT Technology Limited"},
  {max_prefix: "1C-FC-17",vendor: "Cisco Systems, Inc"},
  {max_prefix: "10-AE-60",vendor: "Amazon Technologies Inc."},
  {max_prefix: "1C-32-83",vendor: "COMTTI Intelligent Technology(Shenzhen) Co., Ltd."},
  {max_prefix: "C8-84-8C",vendor: "Ruckus Wireless"},
  {max_prefix: "D8-54-82",vendor: "Oxit, LLC"},
  {max_prefix: "C4-EF-DA",vendor: "Honeywell"},
  {max_prefix: "D8-F5-07",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "70-11-0E",vendor: "zte corporation"},
  {max_prefix: "98-81-8A",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "A8-AA-7C",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "B4-C2-F7",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "E0-5A-1B",vendor: "Espressif Inc."},
  {max_prefix: "48-8A-E8",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "CC-BA-6F",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "78-5C-5E",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "E8-86-CF",vendor: "Nokia"},
  {max_prefix: "08-B6-1F",vendor: "Espressif Inc."},
  {max_prefix: "3C-4E-56",vendor: "SHENZHEN CHUANGWEI-RGB ELECTRONICS CO.,LTD"},
  {max_prefix: "A0-8C-F2",vendor: "YINUOLINK CO.,LTD"},
  {max_prefix: "40-47-5E",vendor: "eero inc."},
  {max_prefix: "5C-3E-1B",vendor: "Apple, Inc."},
  {max_prefix: "7C-2A-CA",vendor: "Apple, Inc."},
  {max_prefix: "28-8E-EC",vendor: "Apple, Inc."},
  {max_prefix: "88-12-AC",vendor: "HUNAN FN-LINK TECHNOLOGY LIMITED"},
  {max_prefix: "30-63-71",vendor: "Shenzhenshi Xinzhongxin Technology Co.Ltd"},
  {max_prefix: "98-A2-C0",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-41-0E",vendor: "CLOUD NETWORK TECHNOLOGY SINGAPORE PTE. LTD."},
  {max_prefix: "FC-61-79",vendor: "IEEE Registration Authority"},
  {max_prefix: "EC-74-D7",vendor: "Grandstream Networks Inc"},
  {max_prefix: "28-01-1C",vendor: "zte corporation"},
  {max_prefix: "20-E6-DF",vendor: "eero inc."},
  {max_prefix: "F4-93-1C",vendor: "Universal Electronics, Inc."},
  {max_prefix: "6C-30-2A",vendor: "Texas Instruments"},
  {max_prefix: "74-46-B3",vendor: "Texas Instruments"},
  {max_prefix: "74-37-5F",vendor: "SERCOMM PHILIPPINES INC"},
  {max_prefix: "C8-2A-F1",vendor: "TCT mobile ltd"},
  {max_prefix: "3C-E9-0E",vendor: "Espressif Inc."},
  {max_prefix: "A8-42-E3",vendor: "Espressif Inc."},
  {max_prefix: "D4-9B-74",vendor: "Kinetic Technologies"},
  {max_prefix: "48-0E-EC",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "50-3E-AA",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "50-F2-61",vendor: "Photon Sail Technologies"},
  {max_prefix: "94-4E-5B",vendor: "Ubee Interactive Co., Limited"},
  {max_prefix: "B4-BA-9D",vendor: "SKY UK LIMITED"},
  {max_prefix: "E0-86-14",vendor: "Novatel Wireless Solutions, Inc."},
  {max_prefix: "40-F8-DF",vendor: "CANON INC."},
  {max_prefix: "0C-7F-ED",vendor: "IEEE Registration Authority"},
  {max_prefix: "A8-DE-68",vendor: "Beijing Wide Technology Co.,Ltd"},
  {max_prefix: "F4-3B-D8",vendor: "Intel Corporate"},
  {max_prefix: "A0-88-9D",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "3C-FE-AC",vendor: "Cisco Systems, Inc"},
  {max_prefix: "04-A7-41",vendor: "Cisco Systems, Inc"},
  {max_prefix: "7C-0C-92",vendor: "Suzhou Mobydata Smart System Co.,Ltd."},
  {max_prefix: "98-D7-42",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "F4-6A-DD",vendor: "Liteon Technology Corporation"},
  {max_prefix: "18-E9-1D",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "48-70-6F",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "18-B1-85",vendor: "Qiao Information Technology (Zhengzhou) Co., Ltd."},
  {max_prefix: "04-BA-D6",vendor: "D-Link Corporation"},
  {max_prefix: "30-3F-5D",vendor: "PT HAN SUNG ELECTORONICS INDONESIA"},
  {max_prefix: "B0-FB-DD",vendor: "Shenzhen SuperElectron Technology Co.,Ltd."},
  {max_prefix: "E0-9C-8D",vendor: "Seakeeper, Inc."},
  {max_prefix: "30-7F-10",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "A4-90-CE",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "3C-69-D1",vendor: "ADC Automotive Distance Control System GmbH"},
  {max_prefix: "54-A9-C8",vendor: "Home Control Singapore Pte Ltd"},
  {max_prefix: "A0-B7-65",vendor: "Espressif Inc."},
  {max_prefix: "CC-DB-A7",vendor: "Espressif Inc."},
  {max_prefix: "C8-6C-20",vendor: "Sichuan Tianyi Comheart Telecom Co.,LTD"},
  {max_prefix: "18-86-3A",vendor: "DIGITAL ART SYSTEM"},
  {max_prefix: "C8-D6-B7",vendor: "Solidigm Technology"},
  {max_prefix: "F0-87-7F",vendor: "Magnetar Technology Shenzhen Co., LTD."},
  {max_prefix: "34-AC-11",vendor: "China Mobile Group Device Co.,Ltd."},
  {max_prefix: "44-32-C2",vendor: "GOAL Co., Ltd."},
  {max_prefix: "70-66-2A",vendor: "Sony Interactive Entertainment Inc."},
  {max_prefix: "DC-71-DD",vendor: "AX Technologies"},
  {max_prefix: "20-64-DE",vendor: "Sunitec Enterprise Co.,Ltd"},
  {max_prefix: "A4-0F-98",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "74-B7-25",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "40-8E-DF",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "D8-80-DC",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "E8-B3-EF",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "5C-FB-3A",vendor: "CHONGQING FUGUI ELECTRONICS CO.,LTD."},
  {max_prefix: "D0-4E-50",vendor: "Mobiwire Mobiles (NingBo) Co., LTD"},
  {max_prefix: "F4-6C-68",vendor: "Wistron Neweb Corporation"},
  {max_prefix: "84-93-B2",vendor: "zte corporation"},
  {max_prefix: "E8-F7-91",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "B4-9F-4D",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "E8-3A-4B",vendor: "China Mobile Group Device Co.,Ltd."},
  {max_prefix: "60-E9-AA",vendor: "CLOUD NETWORK TECHNOLOGY SINGAPORE PTE. LTD."},
  {max_prefix: "24-0F-5E",vendor: "Shenzhen z-router Technology Co., Ltd"},
  {max_prefix: "00-0C-06",vendor: "Nixvue Systems  Pte Ltd"},
  {max_prefix: "AC-29-29",vendor: "Infinix mobility limited"},
  {max_prefix: "18-FD-74",vendor: "Routerboard.com"},
  {max_prefix: "6C-97-6D",vendor: "Motorola Mobility LLC, a Lenovo Company"},
  {max_prefix: "64-11-A4",vendor: "Motorola Mobility LLC, a Lenovo Company"},
  {max_prefix: "78-66-9D",vendor: "Hui Zhou Gaoshengda Technology Co.,LTD"},
  {max_prefix: "48-46-8D",vendor: "Zepcam B.V."},
  {max_prefix: "90-49-92",vendor: "YSTen Technology Co.,Ltd"},
  {max_prefix: "3C-CE-0D",vendor: "Shenzhen juduoping Technology Co.,Ltd"},
  {max_prefix: "00-0E-DB",vendor: "XiNCOM Corp."},
  {max_prefix: "40-D9-5A",vendor: "AMPAK Technology,Inc."},
  {max_prefix: "D0-49-7C",vendor: "OnePlus Technology (Shenzhen) Co., Ltd"},
  {max_prefix: "74-D4-DD",vendor: "Quanta Computer Inc."},
  {max_prefix: "4C-D0-DD",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "E4-90-2A",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "90-5E-44",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "10-F0-68",vendor: "Ruckus Wireless"},
  {max_prefix: "78-20-BD",vendor: "Polysense (Beijing) Technologies Co. Ltd"},
  {max_prefix: "50-E6-36",vendor: "AVM Audiovisuelles Marketing und Computersysteme GmbH"},
  {max_prefix: "7C-E1-52",vendor: "THE GOODYEAR TIRE & RUBBER COMPANY"},
  {max_prefix: "EC-21-25",vendor: "Toshiba Corp."},
  {max_prefix: "28-CD-C1",vendor: "Raspberry Pi Trading Ltd"},
  {max_prefix: "68-CE-4E",vendor: "L-3 Communications Infrared Products"},
  {max_prefix: "00-E5-F1",vendor: "BUFFALO.INC"},
  {max_prefix: "34-EE-2A",vendor: "ConMet"},
  {max_prefix: "68-4E-05",vendor: "HUNAN FN-LINK TECHNOLOGY LIMITED"},
  {max_prefix: "04-99-BB",vendor: "Apple, Inc."},
  {max_prefix: "F0-4D-D4",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "5C-1B-F4",vendor: "Apple, Inc."},
  {max_prefix: "A8-51-AB",vendor: "Apple, Inc."},
  {max_prefix: "50-39-2F",vendor: "INGRAM MICRO SERVICES"},
  {max_prefix: "FC-84-17",vendor: "Honor Device Co., Ltd."},
  {max_prefix: "2C-A7-9E",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "AC-B5-66",vendor: "Renesas Electronics (Penang) Sdn. Bhd."},
  {max_prefix: "38-1F-26",vendor: "IEEE Registration Authority"},
  {max_prefix: "30-04-5C",vendor: "Shenzhen SuperElectron Technology Co.,Ltd."},
  {max_prefix: "90-79-CF",vendor: "zte corporation"},
  {max_prefix: "8C-1E-80",vendor: "Cisco Systems, Inc"},
  {max_prefix: "C0-EE-40",vendor: "Laird Connectivity"},
  {max_prefix: "88-8F-A4",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "50-68-AC",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "A4-F9-33",vendor: "Intel Corporate"},
  {max_prefix: "10-F6-0A",vendor: "Intel Corporate"},
  {max_prefix: "70-D8-23",vendor: "Intel Corporate"},
  {max_prefix: "3C-46-45",vendor: "Shanghai Infinity Wireless Technologies Co.,Ltd."},
  {max_prefix: "C4-DE-E2",vendor: "Espressif Inc."},
  {max_prefix: "68-B6-B3",vendor: "Espressif Inc."},
  {max_prefix: "FC-A0-5A",vendor: "Oray.com co., LTD."},
  {max_prefix: "90-48-6C",vendor: "Ring LLC"},
  {max_prefix: "A4-1E-E1",vendor: "Taicang T&W Electronics"},
  {max_prefix: "E8-FB-1C",vendor: "AzureWave Technology Inc."},
  {max_prefix: "10-24-07",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "74-D9-EB",vendor: "Petabit Scale, Inc."},
  {max_prefix: "B4-A7-C6",vendor: "SERVERCOM (INDIA) PRIVATE LIMITED"},
  {max_prefix: "80-C3-BA",vendor: "Sonova Consumer Hearing GmbH"},
  {max_prefix: "48-7E-48",vendor: "Earda Technologies co Ltd"},
  {max_prefix: "28-6B-35",vendor: "Intel Corporate"},
  {max_prefix: "40-35-E6",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "D0-A4-6F",vendor: "China Dragon Technology Limited"},
  {max_prefix: "54-78-C9",vendor: "AMPAK Technology,Inc."},
  {max_prefix: "30-43-D7",vendor: "IEEE Registration Authority"},
  {max_prefix: "E8-4D-EC",vendor: "Xerox Corporation"},
  {max_prefix: "C8-B8-2F",vendor: "eero inc."},
  {max_prefix: "B8-5D-C3",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "2C-60-CD",vendor: "NR ELECTRIC CO., LTD"},
  {max_prefix: "C4-DF-39",vendor: "Realme Chongqing Mobile Telecommunications Corp.,Ltd."},
  {max_prefix: "CC-60-C8",vendor: "Microsoft Corporation"},
  {max_prefix: "00-40-5F",vendor: "AFE COMPUTERS LTD."},
  {max_prefix: "B4-3A-E2",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "F0-C8-B5",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "FC-10-1A",vendor: "Palo Alto Networks"},
  {max_prefix: "6C-AE-E3",vendor: "Nokia"},
  {max_prefix: "0C-AC-8A",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "D8-36-5F",vendor: "Intelbras"},
  {max_prefix: "00-60-E2",vendor: "QUEST ENGINEERING & DEVELOPMENT"},
  {max_prefix: "74-04-F1",vendor: "Intel Corporate"},
  {max_prefix: "00-A2-65",vendor: "M2Motive Technology Inc."},
  {max_prefix: "80-97-33",vendor: " Shenzhen Elebao Technology Co., Ltd"},
  {max_prefix: "10-54-D2",vendor: "IEEE Registration Authority"},
  {max_prefix: "F4-6D-2F",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "00-EB-D8",vendor: "MERCUSYS TECHNOLOGIES CO., LTD."},
  {max_prefix: "BC-60-6B",vendor: "Shanghai Baud Data Communication Co.,Ltd."},
  {max_prefix: "DC-FE-23",vendor: "Murata Manufacturing Co., Ltd."},
  {max_prefix: "34-73-79",vendor: "xFusion Digital Technologies Co., Limited"},
  {max_prefix: "D8-3D-CC",vendor: "shenzhen UDD Technologies,co.,Ltd"},
  {max_prefix: "6C-51-BF",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "C8-14-B4",vendor: "Sichuan Tianyi Comheart Telecom Co.,LTD"},
  {max_prefix: "6C-8D-77",vendor: "Cisco Systems, Inc"},
  {max_prefix: "74-11-B2",vendor: "Cisco Systems, Inc"},
  {max_prefix: "04-E6-9E",vendor: "ZHONGGUANCUN XINHAIZEYOU TECHNOLOGY CO.,LTD"},
  {max_prefix: "04-17-B6",vendor: "Smart Innovation LLC"},
  {max_prefix: "AC-80-AE",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "E8-5F-02",vendor: "Apple, Inc."},
  {max_prefix: "E8-C7-CF",vendor: "Wistron Neweb Corporation"},
  {max_prefix: "CC-5C-61",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "1C-F4-2B",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "E8-93-F3",vendor: "Graphiant Inc"},
  {max_prefix: "00-19-8E",vendor: "Demant A/S"},
  {max_prefix: "60-FB-00",vendor: "SHENZHEN BILIAN ELECTRONIC CO.\uff0cLTD"},
  {max_prefix: "D0-92-00",vendor: "FiRa Consortium"},
  {max_prefix: "7C-C1-80",vendor: "Apple, Inc."},
  {max_prefix: "28-02-44",vendor: "Apple, Inc."},
  {max_prefix: "BC-DF-58",vendor: "Google, Inc."},
  {max_prefix: "2C-30-1A",vendor: "Technicolor CH USA Inc for Telus"},
  {max_prefix: "E4-2B-79",vendor: "Nokia"},
  {max_prefix: "5C-84-3C",vendor: "Sony Interactive Entertainment Inc."},
  {max_prefix: "E8-AE-C5",vendor: "Arista Networks"},
  {max_prefix: "40-17-F6",vendor: "TKH SECURITY,S.L.U."},
  {max_prefix: "60-A6-C5",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "20-8C-86",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "F0-A9-51",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "C0-49-EF",vendor: "Espressif Inc."},
  {max_prefix: "94-B5-55",vendor: "Espressif Inc."},
  {max_prefix: "74-F2-FA",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "EC-E7-A7",vendor: "Intel Corporate"},
  {max_prefix: "00-40-58",vendor: "UKG"},
  {max_prefix: "18-5E-0B",vendor: "zte corporation"},
  {max_prefix: "D0-BB-61",vendor: "zte corporation"},
  {max_prefix: "58-8F-CF",vendor: "Hangzhou Ezviz Software Co.,Ltd."},
  {max_prefix: "64-D6-9A",vendor: "Intel Corporate"},
  {max_prefix: "3C-E9-F7",vendor: "Intel Corporate"},
  {max_prefix: "F4-CE-23",vendor: "Intel Corporate"},
  {max_prefix: "A8-4F-B1",vendor: "Cisco Systems, Inc"},
  {max_prefix: "AC-73-52",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "50-C2-75",vendor: "GN Audio A/S"},
  {max_prefix: "C8-72-7E",vendor: "Nokia"},
  {max_prefix: "0C-B8-15",vendor: "Espressif Inc."},
  {max_prefix: "6C-FF-CE",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "C8-99-B2",vendor: "Arcadyan Corporation"},
  {max_prefix: "F4-B8-98",vendor: "Texas Instruments"},
  {max_prefix: "B0-D2-78",vendor: "Texas Instruments"},
  {max_prefix: "64-25-EC",vendor: "guangdong kesheng zhixun technology"},
  {max_prefix: "00-E0-CD",vendor: "SAAB SENSIS CORPORATION"},
  {max_prefix: "54-AC-FC",vendor: "LIZN ApS"},
  {max_prefix: "4C-72-74",vendor: "Shenzhenshi Xinzhongxin Technology Co.Ltd"},
  {max_prefix: "00-24-B6",vendor: "Seagate Technology"},
  {max_prefix: "A4-39-B3",vendor: "Beijing Xiaomi Mobile Software Co., Ltd"},
  {max_prefix: "80-0C-F9",vendor: "Amazon Technologies Inc."},
  {max_prefix: "A0-92-08",vendor: "Tuya Smart Inc."},
  {max_prefix: "E4-84-D3",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "04-10-6B",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "04-CC-BC",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "1C-E5-04",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "2C-0B-AB",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "94-D2-BC",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "E0-D0-45",vendor: "Intel Corporate"},
  {max_prefix: "20-1F-54",vendor: "Raisecom Technology CO., LTD"},
  {max_prefix: "7C-97-E1",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "14-7E-A1",vendor: "Britania Eletr\u00f4nicos S.A."},
  {max_prefix: "E8-16-56",vendor: "Hangzhou BroadLink Technology Co.,Ltd"},
  {max_prefix: "E0-A2-5A",vendor: "Shanghai Mo xiang Network Technology CO.,ltd"},
  {max_prefix: "28-87-BA",vendor: "TP-Link Corporation Limited"},
  {max_prefix: "34-60-F9",vendor: "TP-Link Corporation Limited"},
  {max_prefix: "68-4A-E9",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "C8-3E-9E",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "60-DD-70",vendor: "Apple, Inc."},
  {max_prefix: "98-A5-F9",vendor: "Apple, Inc."},
  {max_prefix: "EC-A9-07",vendor: "Apple, Inc."},
  {max_prefix: "A8-8C-3E",vendor: "Microsoft Corporation"},
  {max_prefix: "F8-25-51",vendor: "Seiko Epson Corporation"},
  {max_prefix: "74-53-A8",vendor: "ACL Airshop BV"},
  {max_prefix: "4C-06-B7",vendor: "ProDVX Europe B.V."},
  {max_prefix: "60-57-47",vendor: "CIG SHANGHAI CO LTD"},
  {max_prefix: "14-9E-5D",vendor: "JSC IB Reform"},
  {max_prefix: "94-0D-2D",vendor: "Universal Electronics, Inc."},
  {max_prefix: "14-EB-B6",vendor: "TP-Link Corporation Limited"},
  {max_prefix: "54-AF-97",vendor: "TP-Link Corporation Limited"},
  {max_prefix: "54-CF-8D",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "D4-88-66",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "D4-84-57",vendor: "GD Midea Air-Conditioning Equipment Co.,Ltd."},
  {max_prefix: "BC-47-60",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "2C-4D-DE",vendor: "TECNO MOBILE LIMITED"},
  {max_prefix: "30-3F-7B",vendor: "Shenzhen YOUHUA Technology Co., Ltd"},
  {max_prefix: "D8-CF-BF",vendor: "Motorola Mobility LLC, a Lenovo Company"},
  {max_prefix: "18-45-B3",vendor: "IEEE Registration Authority"},
  {max_prefix: "68-BE-49",vendor: "Nebula Matrix"},
  {max_prefix: "94-F8-27",vendor: "Shanghai Imilab Technology Co.Ltd"},
  {max_prefix: "0C-B7-89",vendor: "Honor Device Co., Ltd."},
  {max_prefix: "F0-B0-40",vendor: "HUNAN FN-LINK TECHNOLOGY LIMITED"},
  {max_prefix: "70-B8-F6",vendor: "Espressif Inc."},
  {max_prefix: "34-CE-69",vendor: "Nokia Solutions and Networks GmbH & Co. KG"},
  {max_prefix: "E0-72-0A",vendor: "Shenzhen SuperElectron Technology Co.,Ltd."},
  {max_prefix: "24-D9-04",vendor: "Sichuan Changhong Network Technologies Co., Ltd."},
  {max_prefix: "3C-45-7A",vendor: "SKY UK LIMITED"},
  {max_prefix: "48-3E-5E",vendor: "SERNET (SUZHOU) TECHNOLOGIES CORPORATION"},
  {max_prefix: "50-EB-F6",vendor: "ASUSTek COMPUTER INC."},
  {max_prefix: "C4-48-FA",vendor: "Taicang T&W Electronics"},
  {max_prefix: "A8-98-92",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "28-04-C6",vendor: "Wanan Hongsheng Electronic Co.Ltd"},
  {max_prefix: "D4-79-54",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "C8-39-AC",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "14-55-94",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "08-C0-6C",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "FC-02-96",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "60-E8-5B",vendor: "Texas Instruments"},
  {max_prefix: "B4-BA-02",vendor: "Agatel Ltd"},
  {max_prefix: "60-FF-12",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "24-C6-13",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "94-E1-29",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "C4-4F-96",vendor: "Alps Alpine"},
  {max_prefix: "68-58-11",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "90-27-59",vendor: "Nanjing Jiahao Technology Co., Ltd."},
  {max_prefix: "5C-46-B0",vendor: "SIMCom Wireless Solutions Limited"},
  {max_prefix: "3C-21-9C",vendor: "Intel Corporate"},
  {max_prefix: "DC-62-94",vendor: "Guangzhou Lango Electronics Technology Co.,Ltd."},
  {max_prefix: "84-BA-20",vendor: "Silicon Laboratories"},
  {max_prefix: "00-3C-84",vendor: "Silicon Laboratories"},
  {max_prefix: "28-1D-21",vendor: "IN ONE SMART TECHNOLOGY(H,K,)LIMITED"},
  {max_prefix: "74-E3-36",vendor: "FUJIAN STAR-NET COMMUNICATION CO.,LTD"},
  {max_prefix: "C4-34-5B",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "70-32-17",vendor: "Intel Corporate"},
  {max_prefix: "34-98-B5",vendor: "NETGEAR"},
  {max_prefix: "B0-73-9C",vendor: "Amazon Technologies Inc."},
  {max_prefix: "18-59-F5",vendor: "Cisco Systems, Inc"},
  {max_prefix: "D4-57-63",vendor: "Apple, Inc."},
  {max_prefix: "C0-2C-5C",vendor: "Apple, Inc."},
  {max_prefix: "EC-42-69",vendor: "HMD Global Oy"},
  {max_prefix: "24-B7-2A",vendor: "China Dragon Technology Limited"},
  {max_prefix: "1C-73-E2",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "8C-83-E8",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "F8-29-C0",vendor: "Availink, Inc."},
  {max_prefix: "34-A5-B4",vendor: "NAVTECH PTE LTD"},
  {max_prefix: "E8-9F-6D",vendor: "Espressif Inc."},
  {max_prefix: "64-57-E5",vendor: "Beijing Royaltech Co.,Ltd"},
  {max_prefix: "FC-9B-D4",vendor: "EdgeQ"},
  {max_prefix: "14-57-90",vendor: "Qingdao Haier Technology Co.,Ltd"},
  {max_prefix: "98-77-CB",vendor: "Vorteks ED"},
  {max_prefix: "38-94-61",vendor: "Renesas Electronics (Penang) Sdn. Bhd."},
  {max_prefix: "48-55-19",vendor: "Espressif Inc."},
  {max_prefix: "F8-FC-E1",vendor: "Amazon Technologies Inc."},
  {max_prefix: "94-29-57",vendor: "Airpo Networks Technology Co.,Ltd."},
  {max_prefix: "98-6E-E8",vendor: "IEEE Registration Authority"},
  {max_prefix: "40-5F-7D",vendor: "TCT mobile ltd"},
  {max_prefix: "4C-03-4F",vendor: "Intel Corporate"},
  {max_prefix: "D4-C3-B0",vendor: "Gearlinx Pty Ltd"},
  {max_prefix: "E0-70-EA",vendor: "HP Inc."},
  {max_prefix: "B8-8A-72",vendor: "Renesas Electronics (Penang) Sdn. Bhd."},
  {max_prefix: "2C-53-D7",vendor: "Sonova AG"},
  {max_prefix: "E8-97-9A",vendor: "Quectel Wireless Solutions Co.,Ltd."},
  {max_prefix: "5C-A6-E6",vendor: "TP-Link Corporation Limited"},
  {max_prefix: "B4-B0-24",vendor: "TP-Link Corporation Limited"},
  {max_prefix: "18-F2-2C",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "DC-A7-82",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "04-51-70",vendor: "Zhongshan K-mate General Electronics Co.,Ltd"},
  {max_prefix: "38-45-54",vendor: "Harman/Becker Automotive Systems GmbH"},
  {max_prefix: "88-D8-2E",vendor: "Intel Corporate"},
  {max_prefix: "50-54-7B",vendor: "Nanjing Qinheng Microelectronics Co., Ltd."},
  {max_prefix: "90-F3-B8",vendor: "China Mobile Group Device Co.,Ltd."},
  {max_prefix: "38-56-3D",vendor: "Microsoft Corporation"},
  {max_prefix: "28-93-7D",vendor: "Sichuan Tianyi Comheart Telecom Co.,LTD"},
  {max_prefix: "D4-1A-D1",vendor: "Zyxel Communications Corporation"},
  {max_prefix: "B8-45-F4",vendor: "New H3C Technologies Co., Ltd"},
  {max_prefix: "EC-01-D5",vendor: "Cisco Systems, Inc"},
  {max_prefix: "18-0E-AC",vendor: "SHENZHEN FAST TECHNOLOGIES CO.,LTD"},
  {max_prefix: "14-49-20",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "04-D9-21",vendor: "Occuspace"},
  {max_prefix: "48-B9-C2",vendor: "Teletics Inc."},
  {max_prefix: "74-63-C2",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "D4-8F-A2",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "88-81-B9",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "3C-38-F4",vendor: "Sony Corporation"},
  {max_prefix: "0C-C4-13",vendor: "Google, Inc."},
  {max_prefix: "4C-EA-AE",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "C4-36-C0",vendor: "BUFFALO.INC"},
  {max_prefix: "08-B4-9D",vendor: "TECNO MOBILE LIMITED"},
  {max_prefix: "08-16-05",vendor: "Vodafone Italia S.p.A."},
  {max_prefix: "2C-E0-32",vendor: "TCL King Electrical Appliances(Huizhou)Co.,Ltd"},
  {max_prefix: "A0-D2-B1",vendor: "Amazon Technologies Inc."},
  {max_prefix: "80-6D-71",vendor: "Amazon Technologies Inc."},
  {max_prefix: "98-97-CC",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "3C-E4-B0",vendor: "Texas Instruments"},
  {max_prefix: "E8-01-15",vendor: "COOCAA Network Technology CO.,TD."},
  {max_prefix: "2C-B0-FD",vendor: "Shenzhen MiaoMing  Intelligent Technology Co.,Ltd"},
  {max_prefix: "88-AC-9E",vendor: "Shenzhen YOUHUA Technology Co., Ltd"},
  {max_prefix: "9C-36-F8",vendor: "Hyundai Kefico"},
  {max_prefix: "E0-B7-2E",vendor: "ShenZhen Qualmesh Technology Co.,Ltd."},
  {max_prefix: "B8-59-CE",vendor: "Earda Technologies co Ltd"},
  {max_prefix: "8C-7A-AA",vendor: "Apple, Inc."},
  {max_prefix: "7C-24-99",vendor: "Apple, Inc."},
  {max_prefix: "C4-FB-C8",vendor: "Shenzhen Candour Co., Ltd."},
  {max_prefix: "00-0D-4E",vendor: "NDR Co.,LTD."},
  {max_prefix: "10-00-20",vendor: "Apple, Inc."},
  {max_prefix: "BC-1E-85",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "8C-EA-12",vendor: "Shenzhen MiaoMing  Intelligent Technology Co.,Ltd"},
  {max_prefix: "C8-BD-4D",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "2C-15-BF",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "1C-53-F9",vendor: "Google, Inc."},
  {max_prefix: "04-42-1A",vendor: "ASUSTek COMPUTER INC."},
  {max_prefix: "20-7C-14",vendor: "Qotom"},
  {max_prefix: "CC-3F-8A",vendor: "KOMATSU LTD."},
  {max_prefix: "30-56-96",vendor: "Infinix mobility limited"},
  {max_prefix: "7C-B0-73",vendor: "Realme Chongqing Mobile Telecommunications Corp.,Ltd."},
  {max_prefix: "0C-E5-A3",vendor: "SharkNinja"},
  {max_prefix: "40-91-51",vendor: "Espressif Inc."},
  {max_prefix: "B0-E9-FE",vendor: "Woan Technology (Shenzhen) Co., Ltd."},
  {max_prefix: "C8-96-65",vendor: "Microsoft Corporation"},
  {max_prefix: "30-5F-77",vendor: "New H3C Technologies Co., Ltd"},
  {max_prefix: "8C-7A-3D",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "98-7E-E3",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "34-31-7F",vendor: "Panasonic Appliances Company"},
  {max_prefix: "00-BD-3E",vendor: "Vizio, Inc"},
  {max_prefix: "B8-56-00",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "04-B8-6A",vendor: "SKY UK LIMITED"},
  {max_prefix: "18-F8-7F",vendor: "Wha Yu Industrial Co., Ltd."},
  {max_prefix: "08-99-E8",vendor: "KEMAS GmbH"},
  {max_prefix: "E4-46-B0",vendor: "Fujitsu Client Computing Limited"},
  {max_prefix: "54-CE-82",vendor: "zte corporation"},
  {max_prefix: "58-48-49",vendor: "IEEE Registration Authority"},
  {max_prefix: "E0-75-AA",vendor: "Beijing Jingling Information System Technology Co., Ltd."},
  {max_prefix: "80-CB-BC",vendor: "Qingdao Intelligent&Precise Electronics Co.,Ltd."},
  {max_prefix: "EC-35-4D",vendor: "Wingtech Mobile Communications Co.,Ltd"},
  {max_prefix: "7C-39-85",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "44-AE-44",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "44-4F-8E",vendor: "WiZ"},
  {max_prefix: "00-05-34",vendor: "Northstar Engineering Ltd."},
  {max_prefix: "BC-09-1B",vendor: "Intel Corporate"},
  {max_prefix: "00-26-A7",vendor: "CONNECT SRL"},
  {max_prefix: "18-07-12",vendor: "Shenzhen Dazoo Technologies CO.,Ltd"},
  {max_prefix: "F0-2A-2B",vendor: "IEEE Registration Authority"},
  {max_prefix: "10-F6-05",vendor: "Realme Chongqing Mobile Telecommunications Corp.,Ltd."},
  {max_prefix: "5C-8F-40",vendor: "TECNO MOBILE LIMITED"},
  {max_prefix: "90-F1-57",vendor: "Garmin International"},
  {max_prefix: "FC-5C-45",vendor: "Ruckus Wireless"},
  {max_prefix: "20-D7-78",vendor: "Texas Instruments"},
  {max_prefix: "4C-2F-D7",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "D4-74-15",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "00-14-41",vendor: "Innovation Sound Technology Co., LTD."},
  {max_prefix: "B4-EC-FF",vendor: "Wuhan IPG Technologies Co., Ltd."},
  {max_prefix: "F0-57-A6",vendor: "Intel Corporate"},
  {max_prefix: "20-BE-CD",vendor: "eero inc."},
  {max_prefix: "04-EC-D8",vendor: "Intel Corporate"},
  {max_prefix: "7C-C2-55",vendor: "Super Micro Computer, Inc."},
  {max_prefix: "50-58-6F",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "F0-B1-3F",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "1C-E6-39",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "8C-67-94",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "64-26-77",vendor: "BKM-Micronic Richtfunkanlagen GmbH"},
  {max_prefix: "70-07-77",vendor: "OnTarget Technologies, Inc"},
  {max_prefix: "00-64-AF",vendor: "Dish Technologies Corp"},
  {max_prefix: "00-BF-AF",vendor: "Hui Zhou Gaoshengda Technology Co.,LTD"},
  {max_prefix: "40-E1-E4",vendor: "Nokia Solutions and Networks GmbH & Co. KG"},
  {max_prefix: "28-64-EF",vendor: "Shenzhen Fsan Intelligent Technology Co.,Ltd"},
  {max_prefix: "38-FF-13",vendor: "Joint Stock Company Research Instinite Masshtab"},
  {max_prefix: "D0-DB-B7",vendor: "Casa Systems"},
  {max_prefix: "E4-B5-03",vendor: "Realme Chongqing Mobile Telecommunications Corp.,Ltd."},
  {max_prefix: "EC-F4-0C",vendor: "Cisco Systems, Inc"},
  {max_prefix: "10-06-ED",vendor: "Cisco Systems, Inc"},
  {max_prefix: "D4-EB-68",vendor: "Cisco Systems, Inc"},
  {max_prefix: "C4-4D-84",vendor: "Cisco Systems, Inc"},
  {max_prefix: "10-2C-EF",vendor: "EMU Electronic AG"},
  {max_prefix: "8C-1D-96",vendor: "Intel Corporate"},
  {max_prefix: "60-DD-8E",vendor: "Intel Corporate"},
  {max_prefix: "B4-CD-F5",vendor: "CUB ELECPARTS INC."},
  {max_prefix: "D8-8C-73",vendor: "zte corporation"},
  {max_prefix: "38-65-B2",vendor: "Apple, Inc."},
  {max_prefix: "D8-DE-3A",vendor: "Apple, Inc."},
  {max_prefix: "20-53-83",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "B8-85-7B",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "50-D0-65",vendor: "ESYLUX GmbH"},
  {max_prefix: "90-59-3C",vendor: "AZ-TECHNOLOGY SDN BHD"},
  {max_prefix: "1C-C1-0C",vendor: "Intel Corporate"},
  {max_prefix: "F4-A4-75",vendor: "Intel Corporate"},
  {max_prefix: "C8-9E-43",vendor: "NETGEAR"},
  {max_prefix: "E8-78-65",vendor: "Apple, Inc."},
  {max_prefix: "A0-4E-CF",vendor: "Apple, Inc."},
  {max_prefix: "08-87-C7",vendor: "Apple, Inc."},
  {max_prefix: "C0-D0-63",vendor: "EM Microelectronic"},
  {max_prefix: "7C-10-C9",vendor: "ASUSTek COMPUTER INC."},
  {max_prefix: "DC-F5-6E",vendor: "Wellysis Corp."},
  {max_prefix: "84-47-09",vendor: "Shenzhen IP3 Century Intelligent Technology CO.,Ltd"},
  {max_prefix: "5C-D0-6E",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "00-91-EB",vendor: "Renesas Electronics (Penang) Sdn. Bhd."},
  {max_prefix: "A8-67-1E",vendor: "RATP"},
  {max_prefix: "8C-E9-B4",vendor: "Zhejiang Dahua Technology Co., Ltd."},
  {max_prefix: "08-85-5B",vendor: "Kontron Europe GmbH"},
  {max_prefix: "E8-4F-A7",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "44-9F-46",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "34-51-84",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "FC-F7-7B",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "EC-08-E5",vendor: "Motorola Mobility LLC, a Lenovo Company"},
  {max_prefix: "80-CC-9C",vendor: "NETGEAR"},
  {max_prefix: "74-E2-0C",vendor: "Amazon Technologies Inc."},
  {max_prefix: "4C-D5-77",vendor: "CHONGQING FUGUI ELECTRONICS CO.,LTD."},
  {max_prefix: "50-70-97",vendor: "China Mobile Group Device Co.,Ltd."},
  {max_prefix: "0C-60-46",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "CC-15-31",vendor: "Intel Corporate"},
  {max_prefix: "C0-F9-B0",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "28-0F-EB",vendor: "LG Innotek"},
  {max_prefix: "CC-2D-8C",vendor: "LG ELECTRONICS INC"},
  {max_prefix: "10-54-03",vendor: "INTARSO GmbH"},
  {max_prefix: "B8-13-32",vendor: "AMPAK Technology,Inc."},
  {max_prefix: "58-91-53",vendor: "China Mobile IOT Company Limited"},
  {max_prefix: "98-7D-DD",vendor: "China Mobile Group Device Co.,Ltd."},
  {max_prefix: "44-DB-60",vendor: "Nanjing Baihezhengliu Technology Co., Ltd"},
  {max_prefix: "B8-B7-7D",vendor: "Guangdong Transtek Medical Electronics CO.,Ltd"},
  {max_prefix: "C4-78-A2",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "9C-9E-71",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "C0-23-8D",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "74-5D-68",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "C0-FB-F9",vendor: "IEEE Registration Authority"},
  {max_prefix: "28-11-A8",vendor: "Intel Corporate"},
  {max_prefix: "74-CF-00",vendor: "Shenzhen SuperElectron Technology Co.,Ltd."},
  {max_prefix: "0C-9A-3C",vendor: "Intel Corporate"},
  {max_prefix: "DC-21-48",vendor: "Intel Corporate"},
  {max_prefix: "9C-5A-81",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "20-CF-AE",vendor: "Cisco Systems, Inc"},
  {max_prefix: "98-49-9F",vendor: "Domo Tactical Communications"},
  {max_prefix: "14-8C-4A",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "60-9B-B4",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "7C-27-BC",vendor: "Hui Zhou Gaoshengda Technology Co.,LTD"},
  {max_prefix: "AC-E1-4F",vendor: "Autonomic Controls, Inc."},
  {max_prefix: "AC-97-6C",vendor: "Greenliant"},
  {max_prefix: "2C-EA-DC",vendor: "ASKEY COMPUTER CORP"},
  {max_prefix: "14-69-A2",vendor: "Sichuan Tianyi Comheart Telecom Co.,LTD"},
  {max_prefix: "04-6B-25",vendor: "Sichuan Tianyi Comheart Telecom Co.,LTD"},
  {max_prefix: "10-12-B4",vendor: "Sichuan Tianyi Comheart Telecom Co.,LTD"},
  {max_prefix: "88-C9-B3",vendor: "IEEE Registration Authority"},
  {max_prefix: "58-13-D3",vendor: "Gemtek Technology Co., Ltd."},
  {max_prefix: "20-1E-88",vendor: "Intel Corporate"},
  {max_prefix: "5C-B0-0A",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "20-6A-94",vendor: "Hitron Technologies. Inc"},
  {max_prefix: "30-95-87",vendor: "HUNAN FN-LINK TECHNOLOGY LIMITED"},
  {max_prefix: "20-CE-2A",vendor: "IEEE Registration Authority"},
  {max_prefix: "9C-32-A9",vendor: "Sichuan Tianyi Comheart Telecom Co.,LTD"},
  {max_prefix: "90-86-74",vendor: "Sichuan Tianyi Comheart Telecom Co.,LTD"},
  {max_prefix: "68-26-2A",vendor: "Sichuan Tianyi Comheart Telecom Co.,LTD"},
  {max_prefix: "B8-22-4F",vendor: "Sichuan Tianyi Comheart Telecom Co.,LTD"},
  {max_prefix: "C8-BD-69",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "C0-4B-13",vendor: "WonderSound Technology Co., Ltd"},
  {max_prefix: "9C-7F-81",vendor: "SHENZHEN FAST TECHNOLOGIES CO.,LTD"},
  {max_prefix: "64-27-53",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "28-53-4E",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "A8-76-50",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "54-D1-7D",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "60-3A-AF",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "00-E5-E4",vendor: "Sichuan Tianyi Comheart Telecom Co.,LTD"},
  {max_prefix: "64-3A-B1",vendor: "Sichuan Tianyi Comheart Telecom Co.,LTD"},
  {max_prefix: "80-48-A5",vendor: "Sichuan Tianyi Comheart Telecom Co.,LTD"},
  {max_prefix: "44-BA-46",vendor: "Sichuan Tianyi Comheart Telecom Co.,LTD"},
  {max_prefix: "F4-02-28",vendor: "SAMSUNG ELECTRO-MECHANICS(THAILAND)"},
  {max_prefix: "0C-83-CC",vendor: "Alpha Networks Inc."},
  {max_prefix: "14-CB-19",vendor: "HP Inc."},
  {max_prefix: "00-1C-C5",vendor: "3Com Ltd"},
  {max_prefix: "E8-4F-25",vendor: "Murata Manufacturing Co., Ltd."},
  {max_prefix: "04-25-E0",vendor: "Taicang T&W Electronics"},
  {max_prefix: "C8-B6-FE",vendor: "Fitbit, Inc."},
  {max_prefix: "18-7C-AA",vendor: "China Mobile Group Device Co.,Ltd."},
  {max_prefix: "C4-BC-D7",vendor: "New Ryatek"},
  {max_prefix: "C0-AE-FD",vendor: "Shenzhen HC-WLAN Technology Co.,Ltd"},
  {max_prefix: "10-27-F5",vendor: "TP-Link Corporation Limited"},
  {max_prefix: "EC-B9-70",vendor: "Ruijie Networks Co.,LTD"},
  {max_prefix: "30-83-98",vendor: "Espressif Inc."},
  {max_prefix: "F8-89-D2",vendor: "CLOUD NETWORK TECHNOLOGY SINGAPORE PTE. LTD."},
  {max_prefix: "DC-87-CB",vendor: "Beijing Perfectek Technologies Co., Ltd."},
  {max_prefix: "38-52-47",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "C0-DC-D7",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "30-37-B3",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "7C-F6-66",vendor: "Tuya Smart Inc."},
  {max_prefix: "10-2D-41",vendor: "Sichuan AI-Link Technology Co., Ltd."},
  {max_prefix: "A4-4C-62",vendor: "Hangzhou Microimage Software Co., Ltd"},
  {max_prefix: "40-BE-EE",vendor: "Shenzhen Yunding Information Technology Co.,Ltd"},
  {max_prefix: "7C-55-A7",vendor: "Kastle Systems"},
  {max_prefix: "FC-E8-06",vendor: "Edifier International"},
  {max_prefix: "30-78-D3",vendor: "Virgilant Technologies Ltd."},
  {max_prefix: "30-85-EB",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "84-06-FA",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "B8-D4-3E",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "74-15-75",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "90-A8-22",vendor: "Amazon Technologies Inc."},
  {max_prefix: "0C-5C-B5",vendor: "IEEE Registration Authority"},
  {max_prefix: "74-AD-98",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-17-E8",vendor: "Texas Instruments"},
  {max_prefix: "E8-EA-4D",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "3C-FF-D8",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "50-C2-ED",vendor: "GN Audio A/S"},
  {max_prefix: "78-64-C0",vendor: "Apple, Inc."},
  {max_prefix: "E8-1C-D8",vendor: "Apple, Inc."},
  {max_prefix: "3C-06-30",vendor: "Apple, Inc."},
  {max_prefix: "84-8C-8D",vendor: "Apple, Inc."},
  {max_prefix: "0C-E4-41",vendor: "Apple, Inc."},
  {max_prefix: "B8-2A-A9",vendor: "Apple, Inc."},
  {max_prefix: "00-C0-6A",vendor: "Zahner-Elektrik Ingeborg Zahner-Schiller GmbH & Co. KG."},
  {max_prefix: "8C-43-61",vendor: "Hailo Digital Hub GmbH & Co. KG"},
  {max_prefix: "48-51-CF",vendor: "Intelbras"},
  {max_prefix: "4C-5D-3C",vendor: "Cisco Systems, Inc"},
  {max_prefix: "34-73-2D",vendor: "Cisco Systems, Inc"},
  {max_prefix: "8C-34-46",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "F4-6B-8C",vendor: "Hon Hai Precision Industry Co., Ltd."},
  {max_prefix: "FC-34-97",vendor: "ASUSTek COMPUTER INC."},
  {max_prefix: "84-7A-B6",vendor: "AltoBeam (China) Inc."},
  {max_prefix: "24-5D-FC",vendor: "IEEE Registration Authority"},
  {max_prefix: "A4-CE-DA",vendor: "Arcadyan Corporation"},
  {max_prefix: "34-E9-FE",vendor: "Metis Co., Ltd."},
  {max_prefix: "4C-61-7E",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "E0-E3-7C",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "24-18-C6",vendor: "HUNAN FN-LINK TECHNOLOGY LIMITED"},
  {max_prefix: "64-97-14",vendor: "eero inc."},
  {max_prefix: "B4-10-7B",vendor: "Texas Instruments"},
  {max_prefix: "9C-82-81",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "AC-13-9C",vendor: "Adtran Inc"},
  {max_prefix: "80-47-86",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "D4-47-5A",vendor: "ScreenBeam, Inc."},
  {max_prefix: "78-A6-A0",vendor: "Hangzhou Ezviz Software Co.,Ltd."},
  {max_prefix: "98-C3-D2",vendor: "Ningbo Sanxing Medical Electric Co.,Ltd"},
  {max_prefix: "CC-86-EC",vendor: "Silicon Laboratories"},
  {max_prefix: "04-7B-CB",vendor: "Universal Global Scientific Industrial Co., Ltd."},
  {max_prefix: "E4-41-64",vendor: "Nokia"},
  {max_prefix: "08-81-B2",vendor: "Logitech (China) Technology Co., Ltd"},
  {max_prefix: "C4-F1-74",vendor: "eero inc."},
  {max_prefix: "28-D0-EA",vendor: "Intel Corporate"},
  {max_prefix: "CC-81-2A",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "7C-1B-93",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "DC-2D-3C",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "E4-55-A8",vendor: "Cisco Meraki"},
  {max_prefix: "04-11-19",vendor: "IEEE Registration Authority"},
  {max_prefix: "E0-0C-E5",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "28-41-EC",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "7C-00-4D",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "1C-99-57",vendor: "Intel Corporate"},
  {max_prefix: "38-A0-67",vendor: "Nokia Solutions and Networks GmbH & Co. KG"},
  {max_prefix: "18-A6-F7",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "04-D3-20",vendor: "ITEL MOBILE LIMITED"},
  {max_prefix: "30-F9-4B",vendor: "Universal Electronics, Inc."},
  {max_prefix: "D8-EC-E5",vendor: "Zyxel Communications Corporation"},
  {max_prefix: "C4-70-AB",vendor: "Ruijie Networks Co.,LTD"},
  {max_prefix: "CC-6B-1E",vendor: "CLOUD NETWORK TECHNOLOGY SINGAPORE PTE. LTD."},
  {max_prefix: "F0-D0-8C",vendor: "TCT mobile ltd"},
  {max_prefix: "98-27-82",vendor: "IEEE Registration Authority"},
  {max_prefix: "FC-40-09",vendor: "zte corporation"},
  {max_prefix: "24-A6-5E",vendor: "zte corporation"},
  {max_prefix: "50-98-39",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "64-07-F6",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "48-00-7D",vendor: "DTS ELEKTRONIK SAN. TIC. LTD. STI."},
  {max_prefix: "30-B1-B5",vendor: "Arcadyan Corporation"},
  {max_prefix: "E4-C9-0B",vendor: "Radwin"},
  {max_prefix: "00-3D-E1",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "5C-C3-36",vendor: "ittim"},
  {max_prefix: "50-ED-3C",vendor: "Apple, Inc."},
  {max_prefix: "FC-04-1C",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "F4-D4-88",vendor: "Apple, Inc."},
  {max_prefix: "68-2F-67",vendor: "Apple, Inc."},
  {max_prefix: "E0-E8-BB",vendor: "Unicom Vsens Telecommunications Co., Ltd."},
  {max_prefix: "70-89-76",vendor: "Tuya Smart Inc."},
  {max_prefix: "24-5B-83",vendor: "Renesas Electronics (Penang) Sdn. Bhd."},
  {max_prefix: "58-41-20",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "3C-06-A7",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "24-E9-27",vendor: "TomTom International BV"},
  {max_prefix: "68-AB-BC",vendor: "Beijing Xiaomi Mobile Software Co., Ltd"},
  {max_prefix: "34-C1-03",vendor: "Hangzhou Huamu Technology Co.,Ltd."},
  {max_prefix: "0C-73-29",vendor: "Sercomm Corporation."},
  {max_prefix: "B0-60-88",vendor: "Intel Corporate"},
  {max_prefix: "64-13-AB",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "6C-13-D5",vendor: "Cisco Systems, Inc"},
  {max_prefix: "F0-F5-64",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "F0-6F-46",vendor: "Ubiik"},
  {max_prefix: "6C-02-E0",vendor: "HP Inc."},
  {max_prefix: "F0-AC-A4",vendor: "HBC-radiomatic"},
  {max_prefix: "80-22-78",vendor: "China Mobile IOT Company Limited"},
  {max_prefix: "14-23-0A",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "58-AE-A8",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "64-D7-C0",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "94-60-10",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "D8-14-DF",vendor: "TCL King Electrical Appliances (Huizhou) Co., Ltd"},
  {max_prefix: "90-02-7A",vendor: "Shenzhen Sworix Techonlogy Co., Ltd"},
  {max_prefix: "D0-5A-FD",vendor: "Realme Chongqing Mobile Telecommunications Corp.,Ltd."},
  {max_prefix: "6C-D7-04",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "54-3A-D6",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "84-5C-F3",vendor: "Intel Corporate"},
  {max_prefix: "70-E4-6E",vendor: "Lytx"},
  {max_prefix: "58-AE-2B",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "E4-3C-80",vendor: "University of Oklahoma"},
  {max_prefix: "0C-48-85",vendor: "LG Electronics (Mobile Communications)"},
  {max_prefix: "54-46-17",vendor: "zte corporation"},
  {max_prefix: "5C-85-F8",vendor: "SHENZHEN KAIFA TECHNOLOGY CO.,LTD."},
  {max_prefix: "2C-79-3D",vendor: "Boditech Med"},
  {max_prefix: "90-3C-B3",vendor: "Edgecore Networks Corporation"},
  {max_prefix: "9C-BC-F0",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "A4-6B-B6",vendor: "Intel Corporate"},
  {max_prefix: "FC-0C-45",vendor: "Shenzhen SuperElectron Technology Co.,Ltd."},
  {max_prefix: "54-67-E6",vendor: "SHENZHEN MTC CO LTD"},
  {max_prefix: "34-FE-C5",vendor: "Shenzhen Sunwoda intelligent hardware Co.,Ltd"},
  {max_prefix: "54-66-F9",vendor: "ConMet"},
  {max_prefix: "8C-64-D4",vendor: "Hyeco Smart Tech Co.,Ltd"},
  {max_prefix: "84-44-AF",vendor: "Zhejiang Tmall Technology Co., Ltd."},
  {max_prefix: "A8-F7-66",vendor: "ITE Tech Inc"},
  {max_prefix: "7C-21-0D",vendor: "Cisco Systems, Inc"},
  {max_prefix: "D0-3C-1F",vendor: "Intel Corporate"},
  {max_prefix: "A0-3D-6E",vendor: "Cisco Systems, Inc"},
  {max_prefix: "B0-8B-D0",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-0B-DE",vendor: "TELDIX GmbH"},
  {max_prefix: "F8-97-25",vendor: "OPPLE LIGHTING CO., LTD"},
  {max_prefix: "3C-54-47",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "00-6B-6F",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "8C-DE-F9",vendor: "Beijing Xiaomi Mobile Software Co., Ltd"},
  {max_prefix: "74-E9-BF",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "D8-10-9F",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "48-25-67",vendor: "Poly"},
  {max_prefix: "A8-9A-D7",vendor: "Nokia"},
  {max_prefix: "18-11-71",vendor: "Guangzhou Doctorpai Education & Technology Co.,Ltd"},
  {max_prefix: "60-35-73",vendor: "Earda Technologies co Ltd"},
  {max_prefix: "A4-97-33",vendor: "ASKEY COMPUTER CORP"},
  {max_prefix: "F0-2F-74",vendor: "ASUSTek COMPUTER INC."},
  {max_prefix: "00-6E-02",vendor: "Xovis AG"},
  {max_prefix: "FC-D4-36",vendor: "Motorola Mobility LLC, a Lenovo Company"},
  {max_prefix: "0C-EC-8D",vendor: "Motorola Mobility LLC, a Lenovo Company"},
  {max_prefix: "18-4F-5D",vendor: "JRC Mobility Inc."},
  {max_prefix: "10-3F-44",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "E0-77-26",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "8C-34-01",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "24-9F-89",vendor: "Texas Instruments"},
  {max_prefix: "24-76-25",vendor: "Texas Instruments"},
  {max_prefix: "2C-18-75",vendor: "Skyworth Digital Technology(Shenzhen) Co.,Ltd"},
  {max_prefix: "D0-6E-DE",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "F8-7A-41",vendor: "Cisco Systems, Inc"},
  {max_prefix: "84-F1-47",vendor: "Cisco Systems, Inc"},
  {max_prefix: "94-A6-7E",vendor: "NETGEAR"},
  {max_prefix: "D4-39-B8",vendor: "Ciena Corporation"},
  {max_prefix: "F0-23-AE",vendor: "AMPAK Technology,Inc."},
  {max_prefix: "AC-F8-5C",vendor: "Chengdu Higon Integrated Circuit Design Co,. Ltd."},
  {max_prefix: "08-CB-E5",vendor: "R3 Solutions GmbH"},
  {max_prefix: "24-94-94",vendor: "Hong Kong Bouffalo Lab Limited"},
  {max_prefix: "30-BE-3B",vendor: "Mitsubishi Electric Corporation"},
  {max_prefix: "3C-A3-7E",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "78-98-E8",vendor: "D-Link International"},
  {max_prefix: "00-34-A1",vendor: "RF-LAMBDA USA INC."},
  {max_prefix: "78-F0-9B",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "48-EF-61",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "F0-B3-EC",vendor: "Apple, Inc."},
  {max_prefix: "F4-65-A6",vendor: "Apple, Inc."},
  {max_prefix: "00-E9-3A",vendor: "AzureWave Technology Inc."},
  {max_prefix: "44-F2-1B",vendor: "Apple, Inc."},
  {max_prefix: "74-65-0C",vendor: "Apple, Inc."},
  {max_prefix: "E0-6D-17",vendor: "Apple, Inc."},
  {max_prefix: "F8-5E-A0",vendor: "Intel Corporate"},
  {max_prefix: "5C-61-99",vendor: "CLOUD NETWORK TECHNOLOGY SINGAPORE PTE. LTD."},
  {max_prefix: "E8-DB-84",vendor: "Espressif Inc."},
  {max_prefix: "D0-55-09",vendor: "Nintendo Co.,Ltd"},
  {max_prefix: "00-08-1E",vendor: "Repeatit AB"},
  {max_prefix: "A0-77-51",vendor: "ASMedia Technology Inc."},
  {max_prefix: "30-56-84",vendor: "SHENZHEN YUNJI INTELLIGENT TECHNOLOGY CO.,LTD"},
  {max_prefix: "38-FC-98",vendor: "Intel Corporate"},
  {max_prefix: "9C-B2-E8",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "60-DB-98",vendor: "Calix Inc."},
  {max_prefix: "68-72-C3",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "70-B1-3D",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "50-2F-9B",vendor: "Intel Corporate"},
  {max_prefix: "14-56-3A",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "70-90-B7",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "F8-E4-3B",vendor: "ASIX Electronics Corporation"},
  {max_prefix: "34-2B-70",vendor: "Arris"},
  {max_prefix: "4C-02-20",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "D4-1B-81",vendor: "CHONGQING FUGUI ELECTRONICS CO.,LTD."},
  {max_prefix: "F4-0B-9F",vendor: "CIG SHANGHAI CO LTD"},
  {max_prefix: "D0-21-AC",vendor: "Yohana"},
  {max_prefix: "78-45-B3",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "20-DC-FD",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "FC-65-B3",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "C0-94-AD",vendor: "zte corporation"},
  {max_prefix: "00-18-8C",vendor: "Mobile Action Technology Inc."},
  {max_prefix: "1C-90-BE",vendor: "Ericsson AB"},
  {max_prefix: "78-7D-F3",vendor: "Sterlite Technologies Limited"},
  {max_prefix: "74-73-1D",vendor: "ifm electronic gmbh"},
  {max_prefix: "00-1F-94",vendor: "Lascar Electronics Ltd"},
  {max_prefix: "10-9D-7A",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "DC-63-73",vendor: "OBARA KOREA"},
  {max_prefix: "D4-7E-E4",vendor: "China Mobile IOT Company Limited"},
  {max_prefix: "1C-A8-52",vendor: "SENSAIO PTE LTD"},
  {max_prefix: "40-1C-83",vendor: "Intel Corporate"},
  {max_prefix: "44-3B-32",vendor: "Intelbras"},
  {max_prefix: "88-89-2F",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "28-E5-B0",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "C4-40-F6",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "38-CA-73",vendor: "Shenzhen MiaoMing  Intelligent Technology Co.,Ltd"},
  {max_prefix: "6C-0D-C4",vendor: "Beijing Xiaomi Electronics Co., Ltd."},
  {max_prefix: "E0-1C-FC",vendor: "D-Link International"},
  {max_prefix: "68-3E-26",vendor: "Intel Corporate"},
  {max_prefix: "8C-55-4A",vendor: "Intel Corporate"},
  {max_prefix: "54-9F-C6",vendor: "Cisco Systems, Inc"},
  {max_prefix: "F0-1D-2D",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-55-B1",vendor: "Shanghai Baud Data Communication Co.,Ltd."},
  {max_prefix: "74-90-1F",vendor: "Ragile Networks Inc."},
  {max_prefix: "20-4E-F6",vendor: "AzureWave Technology Inc."},
  {max_prefix: "08-8F-C3",vendor: "COMPAL INFORMATION (KUNSHAN) CO., LTD."},
  {max_prefix: "0C-8B-7D",vendor: "Vizio, Inc"},
  {max_prefix: "00-23-E9",vendor: "F5 Networks, Inc."},
  {max_prefix: "3C-7C-3F",vendor: "ASUSTek COMPUTER INC."},
  {max_prefix: "24-47-0E",vendor: "PentronicAB"},
  {max_prefix: "8C-D6-7F",vendor: "EM Microelectronic"},
  {max_prefix: "34-91-6F",vendor: "UserGate Ltd."},
  {max_prefix: "EC-4D-3E",vendor: "Beijing Xiaomi Mobile Software Co., Ltd"},
  {max_prefix: "5C-91-FD",vendor: "Jaewoncnc"},
  {max_prefix: "EC-3E-B3",vendor: "Zyxel Communications Corporation"},
  {max_prefix: "FC-44-9F",vendor: "zte corporation"},
  {max_prefix: "98-60-CA",vendor: "Apple, Inc."},
  {max_prefix: "44-90-BB",vendor: "Apple, Inc."},
  {max_prefix: "34-FD-6A",vendor: "Apple, Inc."},
  {max_prefix: "44-35-83",vendor: "Apple, Inc."},
  {max_prefix: "D8-A4-91",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "68-13-24",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "A8-C0-92",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "24-B1-05",vendor: "Prama Hikvision India Private Limited"},
  {max_prefix: "70-9C-D1",vendor: "Intel Corporate"},
  {max_prefix: "14-2C-78",vendor: "GooWi Wireless Technology Co., Limited"},
  {max_prefix: "98-FC-84",vendor: "IEEE Registration Authority"},
  {max_prefix: "DC-72-23",vendor: "Hui Zhou Gaoshengda Technology Co.,LTD"},
  {max_prefix: "90-AA-C3",vendor: "Hitron Technologies. Inc"},
  {max_prefix: "44-91-7C",vendor: "HMD Global Oy"},
  {max_prefix: "24-5F-9F",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "CC-B0-A8",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "50-28-73",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "F8-2E-3F",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "90-A5-AF",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "04-76-B0",vendor: "Cisco Systems, Inc"},
  {max_prefix: "40-F0-78",vendor: "Cisco Systems, Inc"},
  {max_prefix: "78-D7-1A",vendor: "Ciena Corporation"},
  {max_prefix: "20-F4-4F",vendor: "Nokia"},
  {max_prefix: "4C-B9-9B",vendor: "WEIFANG GOERTEK ELECTRONICS CO.,LTD"},
  {max_prefix: "BC-7E-8B",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "4C-AE-EC",vendor: "Guangzhou limee technology co.,LTD"},
  {max_prefix: "9C-6B-37",vendor: "Renesas Electronics (Penang) Sdn. Bhd."},
  {max_prefix: "A8-37-59",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "50-F7-ED",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "64-33-DB",vendor: "Texas Instruments"},
  {max_prefix: "A4-06-E9",vendor: "Texas Instruments"},
  {max_prefix: "B0-B1-13",vendor: "Texas Instruments"},
  {max_prefix: "34-20-E3",vendor: "Ruckus Wireless"},
  {max_prefix: "0C-31-DC",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "40-DD-D1",vendor: "Beautiful Card Corporation"},
  {max_prefix: "C0-E7-BF",vendor: "Sichuan AI-Link Technology Co., Ltd."},
  {max_prefix: "80-60-B7",vendor: "CLOUD NETWORK TECHNOLOGY SINGAPORE PTE. LTD."},
  {max_prefix: "6C-A4-D1",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "A4-46-B4",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "DC-D4-44",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "70-82-8E",vendor: "OleumTech Corporation"},
  {max_prefix: "20-A1-71",vendor: "Amazon Technologies Inc."},
  {max_prefix: "34-6D-9C",vendor: "Carrier Corporation"},
  {max_prefix: "7C-F9-A0",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "34-55-94",vendor: "FUJIAN STAR-NET COMMUNICATION CO.,LTD"},
  {max_prefix: "4C-93-A6",vendor: "IEEE Registration Authority"},
  {max_prefix: "CC-47-BD",vendor: "Rhombus Systems"},
  {max_prefix: "40-AA-56",vendor: "China Dragon Technology Limited"},
  {max_prefix: "68-54-5A",vendor: "Intel Corporate"},
  {max_prefix: "00-1E-31",vendor: "infomark"},
  {max_prefix: "3C-E3-E7",vendor: "China Mobile Group Device Co.,Ltd."},
  {max_prefix: "24-FD-0D",vendor: "Intelbras"},
  {max_prefix: "E0-69-3A",vendor: "Innophase Inc."},
  {max_prefix: "00-02-AC",vendor: "3PAR data"},
  {max_prefix: "54-88-DE",vendor: "Cisco Systems, Inc"},
  {max_prefix: "78-2B-64",vendor: "Bose Corporation"},
  {max_prefix: "D4-F7-56",vendor: "zte corporation"},
  {max_prefix: "54-AB-3A",vendor: "Quanta Computer Inc."},
  {max_prefix: "E8-9A-8F",vendor: "Quanta Computer Inc."},
  {max_prefix: "EC-6C-9A",vendor: "Arcadyan Corporation"},
  {max_prefix: "E0-B2-60",vendor: "TENO NETWORK TECHNOLOGIES COMPANY LIMITED"},
  {max_prefix: "48-23-35",vendor: "Dialog Semiconductor Hellas SA"},
  {max_prefix: "E8-DA-20",vendor: "Nintendo Co.,Ltd"},
  {max_prefix: "E8-13-6E",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "4C-AE-13",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "4C-2E-FE",vendor: "Shenzhen Comnect Technology Co.,LTD"},
  {max_prefix: "64-3A-EA",vendor: "Cisco Systems, Inc"},
  {max_prefix: "3C-53-D7",vendor: "CEDES AG"},
  {max_prefix: "A0-D8-3D",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "58-F2-FC",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "10-BC-97",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "D0-14-11",vendor: "IEEE Registration Authority"},
  {max_prefix: "50-70-43",vendor: "SKY UK LIMITED"},
  {max_prefix: "44-01-BB",vendor: "SHENZHEN BILIAN ELECTRONIC CO.\uff0cLTD"},
  {max_prefix: "C8-02-10",vendor: "LG Innotek"},
  {max_prefix: "E0-6C-A6",vendor: "Creotech Instruments S.A."},
  {max_prefix: "44-68-0C",vendor: "Wacom Co.,Ltd."},
  {max_prefix: "98-0E-24",vendor: "Phytium Technology Co.,Ltd."},
  {max_prefix: "A8-30-BC",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "00-1E-B2",vendor: "LG Innotek"},
  {max_prefix: "C0-86-B3",vendor: "Shenzhen Voxtech Co., Ltd."},
  {max_prefix: "44-AD-B1",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "1C-98-C1",vendor: "CLOUD NETWORK TECHNOLOGY SINGAPORE PTE. LTD."},
  {max_prefix: "A0-9B-17",vendor: "Taicang T&W Electronics"},
  {max_prefix: "00-63-DE",vendor: "CLOUDWALK TECHNOLOGY CO.,LTD"},
  {max_prefix: "60-A4-23",vendor: "Silicon Laboratories"},
  {max_prefix: "78-46-D4",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "68-88-A1",vendor: "Universal Electronics, Inc."},
  {max_prefix: "E4-3E-C6",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "38-88-1E",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "2C-DB-07",vendor: "Intel Corporate"},
  {max_prefix: "98-8D-46",vendor: "Intel Corporate"},
  {max_prefix: "00-16-93",vendor: "PowerLink Technology Inc."},
  {max_prefix: "AC-1F-09",vendor: "shenzhen RAKwireless technology  Co.,Ltd"},
  {max_prefix: "10-F9-20",vendor: "Cisco Systems, Inc"},
  {max_prefix: "90-77-EE",vendor: "Cisco Systems, Inc"},
  {max_prefix: "3C-13-CC",vendor: "Cisco Systems, Inc"},
  {max_prefix: "D8-DC-40",vendor: "Apple, Inc."},
  {max_prefix: "80-5F-C5",vendor: "Apple, Inc."},
  {max_prefix: "3C-4D-BE",vendor: "Apple, Inc."},
  {max_prefix: "48-26-2C",vendor: "Apple, Inc."},
  {max_prefix: "14-7D-DA",vendor: "Apple, Inc."},
  {max_prefix: "C4-91-0C",vendor: "Apple, Inc."},
  {max_prefix: "28-48-E7",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "E4-26-8B",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "6C-99-61",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "20-69-80",vendor: "Apple, Inc."},
  {max_prefix: "BC-13-A8",vendor: "Shenzhen YOUHUA Technology Co., Ltd"},
  {max_prefix: "94-E3-EE",vendor: "zte corporation"},
  {max_prefix: "8C-E4-68",vendor: "Guangzhou Sageran Technology Co., Ltd."},
  {max_prefix: "C0-E3-A0",vendor: "Renesas Electronics (Penang) Sdn. Bhd."},
  {max_prefix: "AC-23-34",vendor: "Infinix mobility limited"},
  {max_prefix: "F8-BC-0E",vendor: "eero inc."},
  {max_prefix: "E0-19-95",vendor: "Nutanix"},
  {max_prefix: "24-79-EF",vendor: "Greenpacket Berhad, Taiwan"},
  {max_prefix: "B0-5C-DA",vendor: "HP Inc."},
  {max_prefix: "FC-BC-0E",vendor: "Zhejiang Cainiao Supply Chain Management Co., Ltd"},
  {max_prefix: "2C-D0-66",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "50-E0-39",vendor: "Zyxel Communications Corporation"},
  {max_prefix: "B8-57-76",vendor: "lignex1"},
  {max_prefix: "F8-51-28",vendor: "SimpliSafe"},
  {max_prefix: "24-00-FA",vendor: "China Mobile (Hangzhou) Information Technology Co., Ltd"},
  {max_prefix: "DC-BD-7A",vendor: "Guangzhou\u00a0Shiyuan\u00a0Electronic\u00a0Technology\u00a0Company\u00a0Limited"},
  {max_prefix: "34-2E-B7",vendor: "Intel Corporate"},
  {max_prefix: "60-AA-EF",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "D0-F3-F5",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "90-16-BA",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "D4-46-49",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "94-00-B0",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "BC-26-A1",vendor: "FACTORY FIVE Corporation"},
  {max_prefix: "18-DF-C1",vendor: "Aetheros"},
  {max_prefix: "E4-41-22",vendor: "OnePlus Technology (Shenzhen) Co., Ltd"},
  {max_prefix: "9C-19-C2",vendor: "Dongguan Liesheng Electronic Co., Ltd."},
  {max_prefix: "B0-1B-7C",vendor: "Ontrol A.S."},
  {max_prefix: "00-1C-70",vendor: "NOVACOMM LTDA"},
  {max_prefix: "00-0F-A4",vendor: "Sprecher Automation GmbH"},
  {max_prefix: "00-24-A2",vendor: "Hong Kong Middleware Technology Limited"},
  {max_prefix: "74-CB-F3",vendor: "Lava international limited"},
  {max_prefix: "84-A3-B5",vendor: "Propulsion systems"},
  {max_prefix: "9C-ED-FA",vendor: "EVUlution AG"},
  {max_prefix: "EC-63-ED",vendor: "Hyundai Autoever Corp."},
  {max_prefix: "1C-13-38",vendor: "Kimball Electronics Group, LLC"},
  {max_prefix: "24-68-B0",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "30-FC-EB",vendor: "LG Electronics (Mobile Communications)"},
  {max_prefix: "FC-71-FA",vendor: "Trane Technologies"},
  {max_prefix: "00-02-D8",vendor: "BRECIS Communications Corporation"},
  {max_prefix: "B4-EF-1C",vendor: "360 AI Technology Co.Ltd"},
  {max_prefix: "B8-F0-09",vendor: "Espressif Inc."},
  {max_prefix: "04-F5-F4",vendor: "Proxim Wireless"},
  {max_prefix: "00-80-16",vendor: "WANDEL AND GOLTERMANN"},
  {max_prefix: "E8-B4-70",vendor: "IEEE Registration Authority"},
  {max_prefix: "A4-B1-C1",vendor: "Intel Corporate"},
  {max_prefix: "00-09-2E",vendor: "B&Tech System Inc."},
  {max_prefix: "D4-1A-C8",vendor: "Nippon Printer Engineering"},
  {max_prefix: "50-61-F6",vendor: "Universal Electronics, Inc."},
  {max_prefix: "F4-EB-9F",vendor: "Ellu Company 2019 SL"},
  {max_prefix: "E8-98-C2",vendor: "ZETLAB Company"},
  {max_prefix: "64-DD-E9",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "4C-40-88",vendor: "SANSHIN ELECTRONICS CO.,LTD."},
  {max_prefix: "B0-45-02",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "1C-1F-F1",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "14-DE-39",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "F4-54-20",vendor: "TELLESCOM INDUSTRIA E COMERCIO EM TELECOMUNICACAO"},
  {max_prefix: "0C-81-7D",vendor: "EEP Elektro-Elektronik Pranjic GmbH"},
  {max_prefix: "C8-BC-E5",vendor: "Sense Things Japan INC."},
  {max_prefix: "9C-61-1D",vendor: "Panasonic Corporation of North America"},
  {max_prefix: "C0-95-DA",vendor: "NXP India Private Limited"},
  {max_prefix: "68-49-B2",vendor: "CARLO GAVAZZI LTD"},
  {max_prefix: "48-7A-FF",vendor: "ESSYS"},
  {max_prefix: "C8-D7-78",vendor: "BSH Hausgeraete GmbH"},
  {max_prefix: "CC-A7-C1",vendor: "Google, Inc."},
  {max_prefix: "A4-C5-4E",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "D4-BB-E6",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "40-B6-E7",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "D0-B4-5D",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "88-36-CF",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "00-9E-EE",vendor: "Positivo Tecnologia S.A."},
  {max_prefix: "8C-5F-AD",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "AC-C2-5D",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "8C-0C-87",vendor: "Nokia"},
  {max_prefix: "F0-D5-BF",vendor: "Intel Corporate"},
  {max_prefix: "DC-D2-FC",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "60-F2-62",vendor: "Intel Corporate"},
  {max_prefix: "00-A0-58",vendor: "GLORY, LTD."},
  {max_prefix: "7C-9E-BD",vendor: "Espressif Inc."},
  {max_prefix: "1C-02-19",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "6C-6A-77",vendor: "Intel Corporate"},
  {max_prefix: "18-69-D8",vendor: "Tuya Smart Inc."},
  {max_prefix: "C8-B2-9B",vendor: "Intel Corporate"},
  {max_prefix: "CC-41-8E",vendor: "MSA Innovation"},
  {max_prefix: "B4-22-00",vendor: "Brother Industries, LTD."},
  {max_prefix: "40-2F-86",vendor: "LG Innotek"},
  {max_prefix: "18-6F-2D",vendor: "Shenzhen Sundray Technologies Company Limited"},
  {max_prefix: "F8-4F-AD",vendor: "Hui Zhou Gaoshengda Technology Co.,LTD"},
  {max_prefix: "4C-0A-3D",vendor: "ADNACOM INC."},
  {max_prefix: "CC-7F-76",vendor: "Cisco Systems, Inc"},
  {max_prefix: "94-05-BB",vendor: "IEEE Registration Authority"},
  {max_prefix: "B8-02-A4",vendor: "Aeonsemi, Inc."},
  {max_prefix: "A8-41-22",vendor: "China Mobile (Hangzhou) Information Technology Co.,Ltd."},
  {max_prefix: "20-6D-31",vendor: "FIREWALLA INC"},
  {max_prefix: "E4-83-26",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "44-76-54",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "7C-D9-A0",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "F0-33-E5",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "3C-80-6B",vendor: "Hunan Voc Acoustics Technology Co., Ltd."},
  {max_prefix: "60-DE-35",vendor: "GITSN, Inc."},
  {max_prefix: "28-31-7E",vendor: "Hongkong Nano IC Technologies Co., Ltd"},
  {max_prefix: "B4-F1-8C",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "C4-32-D1",vendor: "Farlink Technology Limited"},
  {max_prefix: "DC-98-40",vendor: "Microsoft Corporation"},
  {max_prefix: "B8-CE-F6",vendor: "Mellanox Technologies, Inc."},
  {max_prefix: "6C-DD-BC",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "8C-3B-32",vendor: "Microfan B.V."},
  {max_prefix: "3C-58-C2",vendor: "Intel Corporate"},
  {max_prefix: "CC-F9-E4",vendor: "Intel Corporate"},
  {max_prefix: "64-5C-F3",vendor: "ParanTek Inc."},
  {max_prefix: "90-74-9D",vendor: "IRay Technology Co., Ltd."},
  {max_prefix: "28-40-1A",vendor: "C8 MediSensors, Inc."},
  {max_prefix: "D0-65-44",vendor: "Apple, Inc."},
  {max_prefix: "E4-90-FD",vendor: "Apple, Inc."},
  {max_prefix: "84-AB-1A",vendor: "Apple, Inc."},
  {max_prefix: "E4-5E-37",vendor: "Intel Corporate"},
  {max_prefix: "B0-CC-FE",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "54-0D-F9",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "00-66-19",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "FC-39-64",vendor: "ITEL MOBILE LIMITED"},
  {max_prefix: "E8-AB-FA",vendor: "Shenzhen Reecam Tech.Ltd."},
  {max_prefix: "14-47-2D",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "00-0E-9E",vendor: "Topfield Co., Ltd"},
  {max_prefix: "58-94-B2",vendor: "BrainCo"},
  {max_prefix: "B0-95-75",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "00-0C-1E",vendor: "Global Cache"},
  {max_prefix: "F0-08-D1",vendor: "Espressif Inc."},
  {max_prefix: "90-E2-FC",vendor: "IEEE Registration Authority"},
  {max_prefix: "C8-53-E1",vendor: "Beijing Bytedance Network Technology Co., Ltd"},
  {max_prefix: "14-16-9D",vendor: "Cisco Systems, Inc"},
  {max_prefix: "48-A2-E6",vendor: "Resideo"},
  {max_prefix: "CC-D4-2E",vendor: "Arcadyan Corporation"},
  {max_prefix: "14-AE-85",vendor: "IEEE Registration Authority"},
  {max_prefix: "04-8C-16",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "98-DD-5B",vendor: "TAKUMI JAPAN LTD"},
  {max_prefix: "B4-B0-55",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "3C-5C-F1",vendor: "eero inc."},
  {max_prefix: "48-D2-4F",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "E4-AA-EC",vendor: "Tianjin Hualai Technology Co., Ltd"},
  {max_prefix: "94-BE-46",vendor: "Motorola (Wuhan) Mobility Technologies Communication Co., Ltd."},
  {max_prefix: "6C-5D-3A",vendor: "Microsoft Corporation"},
  {max_prefix: "8C-C6-81",vendor: "Intel Corporate"},
  {max_prefix: "C8-C7-50",vendor: "Motorola Mobility LLC, a Lenovo Company"},
  {max_prefix: "2C-DC-D7",vendor: "AzureWave Technology Inc."},
  {max_prefix: "48-3F-DA",vendor: "Espressif Inc."},
  {max_prefix: "E0-BB-9E",vendor: "Seiko Epson Corporation"},
  {max_prefix: "48-DD-0C",vendor: "eero inc."},
  {max_prefix: "58-27-8C",vendor: "BUFFALO.INC"},
  {max_prefix: "14-0A-C5",vendor: "Amazon Technologies Inc."},
  {max_prefix: "20-83-F8",vendor: "Advanced Digital Broadcast SA"},
  {max_prefix: "94-0C-98",vendor: "Apple, Inc."},
  {max_prefix: "E8-FB-E9",vendor: "Apple, Inc."},
  {max_prefix: "38-EC-0D",vendor: "Apple, Inc."},
  {max_prefix: "9C-54-DA",vendor: "SkyBell Technologies Inc."},
  {max_prefix: "4C-49-4F",vendor: "zte corporation"},
  {max_prefix: "C4-74-1E",vendor: "zte corporation"},
  {max_prefix: "D4-60-75",vendor: "Baidu Online Network Technology (Beijing) Co., Ltd"},
  {max_prefix: "78-C5-F8",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "DC-89-83",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "5C-CB-99",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "90-B1-44",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "00-1D-7D",vendor: "GIGA-BYTE TECHNOLOGY CO.,LTD."},
  {max_prefix: "1C-6F-65",vendor: "GIGA-BYTE TECHNOLOGY CO.,LTD."},
  {max_prefix: "00-24-1D",vendor: "GIGA-BYTE TECHNOLOGY CO.,LTD."},
  {max_prefix: "E4-7C-65",vendor: "Sunstar Communication Technology  Co., Ltd"},
  {max_prefix: "5C-78-F8",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "B8-27-C5",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "D4-5D-64",vendor: "ASUSTek COMPUTER INC."},
  {max_prefix: "14-2A-14",vendor: "ShenZhen Selenview Digital Technology Co.,Ltd"},
  {max_prefix: "B8-61-42",vendor: "Beijing Tricolor Technology Co., Ltd"},
  {max_prefix: "FC-8E-5B",vendor: "China Mobile Iot Limited company"},
  {max_prefix: "A4-30-7A",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "00-A0-B3",vendor: "ZYKRONIX"},
  {max_prefix: "38-4B-5B",vendor: "ZTRON TECHNOLOGY LIMITED"},
  {max_prefix: "34-E3-DA",vendor: "Hoval Aktiengesellschaft"},
  {max_prefix: "D8-7E-76",vendor: "ITEL MOBILE LIMITED"},
  {max_prefix: "20-0A-0D",vendor: "IEEE Registration Authority"},
  {max_prefix: "2C-E3-10",vendor: "Stratacache"},
  {max_prefix: "68-19-AC",vendor: "Guangzhou Xianyou Intelligent Technogoly CO., LTD"},
  {max_prefix: "E8-2E-0C",vendor: "NETINT Technologies Inc."},
  {max_prefix: "18-92-A4",vendor: "Ciena Corporation"},
  {max_prefix: "14-11-5D",vendor: "Skyworth Digital Technology(Shenzhen) Co.,Ltd"},
  {max_prefix: "B0-73-5D",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "F0-B4-D2",vendor: "D-Link International"},
  {max_prefix: "5C-3A-45",vendor: "CHONGQING FUGUI ELECTRONICS CO.,LTD."},
  {max_prefix: "A0-3C-31",vendor: "Shenzhen Belon Technology CO.,LTD"},
  {max_prefix: "E4-F3-27",vendor: "ATOL LLC"},
  {max_prefix: "10-08-2C",vendor: "Texas Instruments"},
  {max_prefix: "68-02-B8",vendor: "Compal Broadband Networks, Inc."},
  {max_prefix: "34-63-D4",vendor: "BIONIX SUPPLYCHAIN TECHNOLOGIES SLU"},
  {max_prefix: "08-F7-E9",vendor: "HRCP Research and Development Partnership"},
  {max_prefix: "50-2D-BB",vendor: "GD Midea Air-Conditioning Equipment Co.,Ltd."},
  {max_prefix: "3C-22-FB",vendor: "Apple, Inc."},
  {max_prefix: "D4-9E-3B",vendor: "Guangzhou\u00a0Shiyuan\u00a0Electronic\u00a0Technology\u00a0Company\u00a0Limited"},
  {max_prefix: "DC-4B-DD",vendor: "Shenzhen SuperElectron Technology Co.,Ltd."},
  {max_prefix: "C0-B5-CD",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "4C-50-77",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "30-FD-65",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "58-EA-FC",vendor: "ELL-IoT Inc"},
  {max_prefix: "90-13-DA",vendor: "Athom B.V."},
  {max_prefix: "DC-DC-E2",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "A0-AC-69",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "10-89-FB",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "5C-3A-3D",vendor: "zte corporation"},
  {max_prefix: "7C-A1-AE",vendor: "Apple, Inc."},
  {max_prefix: "80-E4-55",vendor: "New H3C Technologies Co., Ltd"},
  {max_prefix: "00-90-9E",vendor: "Critical IO, LLC"},
  {max_prefix: "AC-BD-70",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "90-B8-E0",vendor: "SHENZHEN YANRAY TECHNOLOGY CO.,LTD"},
  {max_prefix: "2C-4C-C6",vendor: "Murata Manufacturing Co., Ltd."},
  {max_prefix: "38-E8-EE",vendor: "Nanjing Youkuo Electric Technology Co., Ltd"},
  {max_prefix: "44-03-77",vendor: "IEEE Registration Authority"},
  {max_prefix: "08-12-A5",vendor: "Amazon Technologies Inc."},
  {max_prefix: "9C-F5-31",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "C8-09-A8",vendor: "Intel Corporate"},
  {max_prefix: "74-8B-34",vendor: "Shanghai Smart System Technology Co., Ltd"},
  {max_prefix: "4C-BC-72",vendor: "Primex Wireless"},
  {max_prefix: "D4-77-2B",vendor: "Nanjing Ztlink Network Technology Co.,Ltd"},
  {max_prefix: "64-F9-C0",vendor: "ANALOG DEVICES"},
  {max_prefix: "BC-A5-11",vendor: "NETGEAR"},
  {max_prefix: "F8-C4-F3",vendor: "Shanghai Infinity Wireless Technologies Co.,Ltd."},
  {max_prefix: "C4-E9-0A",vendor: "D-Link International"},
  {max_prefix: "18-D0-C5",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "30-E9-8E",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "C4-44-7D",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "34-ED-1B",vendor: "Cisco Systems, Inc"},
  {max_prefix: "14-24-75",vendor: "4DReplay, Inc"},
  {max_prefix: "80-64-7A",vendor: "Ola Sense Inc"},
  {max_prefix: "C0-B8-83",vendor: "Intel Corporate"},
  {max_prefix: "70-F8-2B",vendor: "DWnet Technologies(Suzhou) Corporation"},
  {max_prefix: "44-CB-8B",vendor: "LG Innotek"},
  {max_prefix: "EC-1B-BD",vendor: "Silicon Laboratories"},
  {max_prefix: "10-DC-B6",vendor: "IEEE Registration Authority"},
  {max_prefix: "D8-A3-15",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "14-A1-BF",vendor: "ASSA ABLOY Korea Co., Ltd Unilock"},
  {max_prefix: "94-83-C4",vendor: "GL Technologies (Hong Kong) Limited"},
  {max_prefix: "1C-EA-0B",vendor: "Edgecore Networks Corporation"},
  {max_prefix: "24-41-8C",vendor: "Intel Corporate"},
  {max_prefix: "44-EF-BF",vendor: "China Dragon Technology Limited"},
  {max_prefix: "4C-B4-4A",vendor: "NANOWAVE Technologies Inc."},
  {max_prefix: "F8-D0-27",vendor: "Seiko Epson Corporation"},
  {max_prefix: "5C-66-6C",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "4C-4B-F9",vendor: "IEEE Registration Authority"},
  {max_prefix: "9C-93-B0",vendor: "Megatronix (Beijing) Technology Co., Ltd."},
  {max_prefix: "64-AE-F1",vendor: "Qingdao Hisense Electronics Co.,Ltd."},
  {max_prefix: "04-8C-9A",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "EC-3C-BB",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "28-BD-89",vendor: "Google, Inc."},
  {max_prefix: "98-48-27",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "70-44-1C",vendor: "SHENZHEN KAIFA TECHNOLOGY CO.,LTD."},
  {max_prefix: "AC-E3-42",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "90-17-C8",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "88-D5-A8",vendor: "ITEL MOBILE LIMITED"},
  {max_prefix: "B8-1F-5E",vendor: "Apption Labs Limited"},
  {max_prefix: "D8-12-65",vendor: "CHONGQING FUGUI ELECTRONICS CO.,LTD."},
  {max_prefix: "8C-3A-7E",vendor: "Universal Electronics, Inc."},
  {max_prefix: "20-85-93",vendor: "IEEE Registration Authority"},
  {max_prefix: "E4-92-2A",vendor: "DBG HOLDINGS LIMITED"},
  {max_prefix: "2C-64-1F",vendor: "Vizio, Inc"},
  {max_prefix: "54-E7-D5",vendor: "Sun Cupid Technology (HK) LTD"},
  {max_prefix: "B4-7C-59",vendor: "Jiangsu Hengxin Technology Co.,Ltd."},
  {max_prefix: "30-0D-9E",vendor: "Ruijie Networks Co.,LTD"},
  {max_prefix: "BC-B0-E7",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "54-34-EF",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "EC-FA-5C",vendor: "Beijing Xiaomi Electronics Co., Ltd."},
  {max_prefix: "F8-B4-6A",vendor: "Hewlett Packard"},
  {max_prefix: "48-01-C5",vendor: "OnePlus Technology (Shenzhen) Co., Ltd"},
  {max_prefix: "18-BF-1C",vendor: "Jiangsu Huitong Group Co.,Ltd."},
  {max_prefix: "20-77-59",vendor: "OPTICAL NETWORK VIDEO TECHNOLOGIES (SHENZHEN) CO., LTD."},
  {max_prefix: "88-9D-98",vendor: "Allied-telesisK.K."},
  {max_prefix: "4C-56-DF",vendor: "Targus US LLC"},
  {max_prefix: "B4-EE-25",vendor: "Shenzhen Belon Technology CO.,LTD"},
  {max_prefix: "C8-2B-96",vendor: "Espressif Inc."},
  {max_prefix: "DC-F8-B9",vendor: "zte corporation"},
  {max_prefix: "18-90-88",vendor: "eero inc."},
  {max_prefix: "24-15-10",vendor: "IEEE Registration Authority"},
  {max_prefix: "6C-4D-51",vendor: "Shenzhen Ceres Technology Co., Ltd."},
  {max_prefix: "98-52-3D",vendor: "Sunitec Enterprise Co.,Ltd"},
  {max_prefix: "00-01-63",vendor: "Cisco Systems, Inc"},
  {max_prefix: "E0-00-84",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "04-81-9B",vendor: "SKY UK LIMITED"},
  {max_prefix: "2C-A8-9C",vendor: "Creatz inc."},
  {max_prefix: "4C-DC-0D",vendor: "Coral Telecom Limited"},
  {max_prefix: "C4-E1-A1",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "AC-C3-58",vendor: "Continental Automotive Czech Republic s.r.o."},
  {max_prefix: "F0-99-19",vendor: "Garmin International"},
  {max_prefix: "18-55-E3",vendor: "Apple, Inc."},
  {max_prefix: "E4-50-EB",vendor: "Apple, Inc."},
  {max_prefix: "88-64-40",vendor: "Apple, Inc."},
  {max_prefix: "60-70-C0",vendor: "Apple, Inc."},
  {max_prefix: "F0-C3-71",vendor: "Apple, Inc."},
  {max_prefix: "60-63-4C",vendor: "D-Link International"},
  {max_prefix: "68-34-89",vendor: "LEA Professional"},
  {max_prefix: "94-BF-80",vendor: "zte corporation"},
  {max_prefix: "00-00-DE",vendor: "CETIA"},
  {max_prefix: "F4-3E-66",vendor: "Bee Computing (HK) Limited"},
  {max_prefix: "DC-39-6F",vendor: "AVM Audiovisuelles Marketing und Computersysteme GmbH"},
  {max_prefix: "B4-C4-76",vendor: "Wuhan Maritime Communication Research Institute"},
  {max_prefix: "9C-3A-9A",vendor: "Shenzhen Sundray Technologies Company Limited"},
  {max_prefix: "B4-6C-47",vendor: "Panasonic Appliances Company"},
  {max_prefix: "64-FF-0A",vendor: "Wistron Neweb Corporation"},
  {max_prefix: "44-42-2F",vendor: "TESTOP CO.,LTD."},
  {max_prefix: "54-9C-27",vendor: "Plasma Cloud Limited"},
  {max_prefix: "54-15-89",vendor: "MCS Logic Inc."},
  {max_prefix: "84-57-33",vendor: "Microsoft Corporation"},
  {max_prefix: "3C-EC-EF",vendor: "Super Micro Computer, Inc."},
  {max_prefix: "98-7A-14",vendor: "Microsoft Corporation"},
  {max_prefix: "C8-3D-DC",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "B0-B5-E8",vendor: "Ruroc LTD"},
  {max_prefix: "58-F3-9C",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-24-23",vendor: "AzureWave Technologies (Shanghai) Inc."},
  {max_prefix: "8C-59-3C",vendor: "IEEE Registration Authority"},
  {max_prefix: "60-29-D5",vendor: "DAVOLINK Inc."},
  {max_prefix: "50-97-44",vendor: "Integrated Device Technology (Malaysia) Sdn. Bhd."},
  {max_prefix: "00-77-E4",vendor: "Nokia Solutions and Networks GmbH & Co. KG"},
  {max_prefix: "38-01-18",vendor: "ULVAC,Inc."},
  {max_prefix: "14-AD-CA",vendor: "China Mobile Iot Limited company"},
  {max_prefix: "00-AD-63",vendor: "Dedicated Micros Malta LTD"},
  {max_prefix: "F4-1D-6B",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "7C-EC-9B",vendor: "Fuzhou Teraway Information Technology Co.,Ltd"},
  {max_prefix: "CC-90-70",vendor: "Cisco Systems, Inc"},
  {max_prefix: "28-41-C6",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "E4-15-F6",vendor: "Texas Instruments"},
  {max_prefix: "E8-28-C1",vendor: "Eltex Enterprise Ltd."},
  {max_prefix: "78-D3-47",vendor: "Ericsson AB"},
  {max_prefix: "F8-23-87",vendor: "Shenzhen Horn Audio Co.,Ltd."},
  {max_prefix: "80-91-33",vendor: "AzureWave Technology Inc."},
  {max_prefix: "BC-98-DF",vendor: "Motorola Mobility LLC, a Lenovo Company"},
  {max_prefix: "70-FC-8F",vendor: "FREEBOX SAS"},
  {max_prefix: "50-1B-32",vendor: "Taicang T&W Electronics"},
  {max_prefix: "18-19-D6",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "AC-42-28",vendor: "Parta Networks"},
  {max_prefix: "B4-F5-8E",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "C4-8F-C1",vendor: "DEEPTRACK S.L.U."},
  {max_prefix: "B0-A6-F5",vendor: "Xaptum, Inc."},
  {max_prefix: "AC-F5-E6",vendor: "Cisco Systems, Inc"},
  {max_prefix: "D4-D2-52",vendor: "Intel Corporate"},
  {max_prefix: "58-A0-23",vendor: "Intel Corporate"},
  {max_prefix: "DC-B0-82",vendor: "Nokia"},
  {max_prefix: "F8-C3-97",vendor: "NZXT Corp. Ltd."},
  {max_prefix: "70-DD-A8",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "4C-6F-9C",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "78-2C-29",vendor: "New H3C Technologies Co., Ltd"},
  {max_prefix: "A4-A1-79",vendor: "Nanjing dianyan electric power automation co. LTD"},
  {max_prefix: "68-DB-67",vendor: "Nantong Coship Electronics Co., Ltd."},
  {max_prefix: "98-0D-67",vendor: "Zyxel Communications Corporation"},
  {max_prefix: "70-2E-80",vendor: "DIEHL Connectivity Solutions"},
  {max_prefix: "AC-A4-6E",vendor: "SHENZHEN GONGJIN ELECTRONICS CO.,LT"},
  {max_prefix: "C8-B4-22",vendor: "ASKEY COMPUTER CORP"},
  {max_prefix: "94-EE-9F",vendor: "HMD Global Oy"},
  {max_prefix: "F4-32-3D",vendor: "Sichuan tianyi kanghe communications co., LTD"},
  {max_prefix: "00-1E-A3",vendor: "Nokia Danmark A/S"},
  {max_prefix: "38-F3-2E",vendor: "Skullcandy"},
  {max_prefix: "DC-2A-A1",vendor: "MedHab LLC"},
  {max_prefix: "E4-F3-E8",vendor: "Shenzhen SuperElectron Technology Co.,Ltd."},
  {max_prefix: "F8-B7-97",vendor: "NEC Platforms, Ltd."},
  {max_prefix: "B0-AA-D2",vendor: "Sichuan tianyi kanghe communications co., LTD"},
  {max_prefix: "CC-A1-2B",vendor: "TCL King Electrical Appliances (Huizhou) Co., Ltd"},
  {max_prefix: "C4-65-16",vendor: "Hewlett Packard"},
  {max_prefix: "E4-1E-0A",vendor: "IEEE Registration Authority"},
  {max_prefix: "AC-00-D0",vendor: "zte corporation"},
  {max_prefix: "E8-C4-17",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "24-31-54",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "98-1E-19",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "84-B8-66",vendor: "Beijing XiaoLu technology co. LTD"},
  {max_prefix: "14-22-DB",vendor: "eero inc."},
  {max_prefix: "E8-EC-A3",vendor: "Dongguan Liesheng Electronic Co.Ltd"},
  {max_prefix: "08-A6-BC",vendor: "Amazon Technologies Inc."},
  {max_prefix: "2C-58-E8",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "18-BC-5A",vendor: "Zhejiang Tmall Technology Co., Ltd."},
  {max_prefix: "C4-C1-38",vendor: "OWLink Technology Inc"},
  {max_prefix: "AC-37-C9",vendor: "RAID Incorporated"},
  {max_prefix: "20-58-69",vendor: "Ruckus Wireless"},
  {max_prefix: "CC-37-AB",vendor: "Edgecore Networks Corporation"},
  {max_prefix: "90-78-41",vendor: "Intel Corporate"},
  {max_prefix: "C8-63-14",vendor: "IEEE Registration Authority"},
  {max_prefix: "1C-B3-E9",vendor: " Shenzhen Zhongke United Communication Technology"},
  {max_prefix: "84-B8-B8",vendor: "Motorola (Wuhan) Mobility Technologies Communication Co., Ltd."},
  {max_prefix: "D0-41-C9",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "E8-01-8D",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "18-39-9C",vendor: "Skorpios Technologies"},
  {max_prefix: "94-C2-BD",vendor: "TECNOBIT"},
  {max_prefix: "48-83-B4",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "70-1E-68",vendor: "Hanna Instruments, Inc."},
  {max_prefix: "C0-07-4A",vendor: "Brita GmbH"},
  {max_prefix: "E8-B2-FE",vendor: "HUMAX Co., Ltd."},
  {max_prefix: "00-17-FA",vendor: "Microsoft Corporation"},
  {max_prefix: "10-A3-B8",vendor: "Iskratel d.o.o."},
  {max_prefix: "70-CD-91",vendor: "TERACOM TELEMATICA S.A"},
  {max_prefix: "94-16-25",vendor: "Apple, Inc."},
  {max_prefix: "54-3B-30",vendor: "duagon AG"},
  {max_prefix: "8C-96-5F",vendor: "Shandong Zhongan Technology Co., Ltd."},
  {max_prefix: "B0-BB-8B",vendor: "WAVETEL TECHNOLOGY LIMITED"},
  {max_prefix: "34-A8-EB",vendor: "Apple, Inc."},
  {max_prefix: "A4-83-E7",vendor: "Apple, Inc."},
  {max_prefix: "F4-AF-E7",vendor: "Apple, Inc."},
  {max_prefix: "AC-88-FD",vendor: "Apple, Inc."},
  {max_prefix: "64-89-F1",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "50-3E-7C",vendor: "LeiShen Intelligent  System Co.Ltd"},
  {max_prefix: "24-3F-30",vendor: "Oxygen Broadband s.a."},
  {max_prefix: "3C-91-80",vendor: "Liteon Technology Corporation"},
  {max_prefix: "20-32-6C",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "80-FD-7A",vendor: "BLU Products Inc"},
  {max_prefix: "B4-A3-05",vendor: "XIAMEN YAXON NETWORK CO., LTD."},
  {max_prefix: "80-3E-48",vendor: "SHENZHEN GONGJIN ELECTRONICS CO.,LT"},
  {max_prefix: "24-58-6E",vendor: "zte corporation"},
  {max_prefix: "94-BF-C4",vendor: "Ruckus Wireless"},
  {max_prefix: "20-34-FB",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "A8-9C-ED",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "D0-EC-35",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-12-4E",vendor: "XAC AUTOMATION CORP."},
  {max_prefix: "88-E0-34",vendor: "Shinwa industries(China) ltd."},
  {max_prefix: "BC-CF-4F",vendor: "Zyxel Communications Corporation"},
  {max_prefix: "0C-E0-41",vendor: "iDruide"},
  {max_prefix: "B8-8F-B4",vendor: "JABIL CIRCUIT ITALIA S.R.L"},
  {max_prefix: "C0-10-B1",vendor: "HMD Global Oy"},
  {max_prefix: "00-52-C2",vendor: "peiker acustic GmbH"},
  {max_prefix: "90-89-5F",vendor: "WEIFANG GOERTEK ELECTRONICS CO.,LTD"},
  {max_prefix: "48-D8-45",vendor: "Shenzhen Mainuoke Electronics Co., Ltd"},
  {max_prefix: "E4-58-E7",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "00-10-4A",vendor: "The Parvus Corporation"},
  {max_prefix: "38-00-25",vendor: "Intel Corporate"},
  {max_prefix: "48-C3-B0",vendor: "Pharos Co.Ltd"},
  {max_prefix: "DC-58-BC",vendor: "Thomas-Krenn.AG"},
  {max_prefix: "00-10-25",vendor: "Grayhill, Inc"},
  {max_prefix: "70-EA-1A",vendor: "Cisco Systems, Inc"},
  {max_prefix: "80-8A-8B",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "0C-F4-75",vendor: "Zliide Technologies ApS"},
  {max_prefix: "68-FF-7B",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "80-8F-1D",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "48-BD-0E",vendor: "Quanta Storage Inc."},
  {max_prefix: "00-0F-69",vendor: "SEW Eurodrive GmbH & Co. KG"},
  {max_prefix: "8C-53-D2",vendor: "China Mobile Group Device Co.,Ltd."},
  {max_prefix: "D0-58-C0",vendor: "Qingdao Haier Multimedia Limited."},
  {max_prefix: "F8-D4-78",vendor: "Flextronics Tech.(Ind) Pvt Ltd"},
  {max_prefix: "D4-53-83",vendor: "Murata Manufacturing Co., Ltd."},
  {max_prefix: "A0-42-46",vendor: "IT Telecom Co., Ltd."},
  {max_prefix: "DC-67-23",vendor: "barox Kommunikation GmbH"},
  {max_prefix: "44-B4-62",vendor: "Flextronics Tech.(Ind) Pvt Ltd"},
  {max_prefix: "A4-5F-9B",vendor: "Nexell"},
  {max_prefix: "1C-3B-8F",vendor: "Selve GmbH & Co. KG"},
  {max_prefix: "E4-E7-49",vendor: "Hewlett Packard"},
  {max_prefix: "98-44-B6",vendor: "INFRANOR SAS"},
  {max_prefix: "38-83-9A",vendor: "SHENZHEN RF-LINK TECHNOLOGY CO.,LTD."},
  {max_prefix: "C8-F6-C8",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "EC-C5-7F",vendor: "Suzhou Pairlink Network Technology"},
  {max_prefix: "A8-6D-AA",vendor: "Intel Corporate"},
  {max_prefix: "38-C2-BA",vendor: "CCTV NEOTECH"},
  {max_prefix: "A0-F9-B7",vendor: "Ademco Smart Homes Technology(Tianjin)Co.,Ltd."},
  {max_prefix: "A8-3C-CB",vendor: "ROSSMA"},
  {max_prefix: "CC-3F-EA",vendor: "BAE Systems, Inc"},
  {max_prefix: "E8-5B-B7",vendor: "Ample Systems Inc."},
  {max_prefix: "94-67-7E",vendor: "Belden India Private Limited"},
  {max_prefix: "AC-43-30",vendor: "Versa Networks"},
  {max_prefix: "D4-3A-2E",vendor: "SHENZHEN MTC CO LTD"},
  {max_prefix: "30-C3-D9",vendor: "ALPSALPINE CO,.LTD"},
  {max_prefix: "AC-57-75",vendor: "HMD Global Oy"},
  {max_prefix: "50-AD-92",vendor: "NX Technologies"},
  {max_prefix: "4C-F2-BF",vendor: "Cambridge Industries(Group) Co.,Ltd."},
  {max_prefix: "CC-9E-A2",vendor: "Amazon Technologies Inc."},
  {max_prefix: "00-32-17",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-1B-FB",vendor: "ALPSALPINE CO,.LTD"},
  {max_prefix: "8C-AE-DB",vendor: "NAGTECH LLC"},
  {max_prefix: "78-B2-13",vendor: "DWnet Technologies(Suzhou) Corporation"},
  {max_prefix: "7C-DB-98",vendor: "ASKEY COMPUTER CORP"},
  {max_prefix: "F0-0D-F5",vendor: "ACOMA Medical Industry Co,. Ltd."},
  {max_prefix: "58-C2-32",vendor: "NEC Corporation"},
  {max_prefix: "38-1D-14",vendor: "Skydio Inc."},
  {max_prefix: "74-F7-37",vendor: "KCE"},
  {max_prefix: "48-A4-93",vendor: "TAIYO YUDEN CO.,LTD"},
  {max_prefix: "88-D2-11",vendor: "Eko Devices, Inc."},
  {max_prefix: "B8-C2-27",vendor: "PSTec"},
  {max_prefix: "3C-28-6D",vendor: "Google, Inc."},
  {max_prefix: "00-09-3A",vendor: "Molex CMS"},
  {max_prefix: "6C-A9-36",vendor: "DisplayLink (UK) Ltd"},
  {max_prefix: "70-85-40",vendor: "Skyworth Digital Technology(Shenzhen) Co.,Ltd"},
  {max_prefix: "94-E0-D6",vendor: "China Dragon Technology Limited"},
  {max_prefix: "B4-A9-FC",vendor: "Quanta Computer Inc."},
  {max_prefix: "38-0B-3C",vendor: "Texas Instruments"},
  {max_prefix: "68-45-F1",vendor: "TOSHIBA CLIENT SOLUTIONS CO., LTD."},
  {max_prefix: "B4-0B-78",vendor: "Brusa Elektronik AG"},
  {max_prefix: "20-79-18",vendor: "Intel Corporate"},
  {max_prefix: "A4-8C-C0",vendor: "JLG Industries, Inc."},
  {max_prefix: "DC-48-B2",vendor: "Baraja Pty. Ltd."},
  {max_prefix: "AC-AE-19",vendor: "Roku, Inc"},
  {max_prefix: "0C-95-41",vendor: "CHIPSEA TECHNOLOGIES (SHENZHEN) CORP."},
  {max_prefix: "14-D0-0D",vendor: "Apple, Inc."},
  {max_prefix: "C0-3D-D9",vendor: "MitraStar Technology Corp."},
  {max_prefix: "2C-AA-8E",vendor: "Wyze Labs Inc"},
  {max_prefix: "70-3A-51",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "9C-69-37",vendor: "Qorvo International Pte. Ltd."},
  {max_prefix: "E8-2C-6D",vendor: "SmartRG, Inc."},
  {max_prefix: "04-F9-D9",vendor: "Speaker Electronic(Jiashan) Co.,Ltd"},
  {max_prefix: "18-1E-95",vendor: "AuVerte"},
  {max_prefix: "DC-08-0F",vendor: "Apple, Inc."},
  {max_prefix: "F8-E9-4E",vendor: "Apple, Inc."},
  {max_prefix: "EC-2C-E2",vendor: "Apple, Inc."},
  {max_prefix: "40-BC-60",vendor: "Apple, Inc."},
  {max_prefix: "E8-36-17",vendor: "Apple, Inc."},
  {max_prefix: "9C-64-8B",vendor: "Apple, Inc."},
  {max_prefix: "34-42-62",vendor: "Apple, Inc."},
  {max_prefix: "48-E6-95",vendor: "Insigma Inc"},
  {max_prefix: "B4-79-C8",vendor: "Ruckus Wireless"},
  {max_prefix: "70-6D-15",vendor: "Cisco Systems, Inc"},
  {max_prefix: "A4-A1-E4",vendor: "Innotube, Inc."},
  {max_prefix: "00-10-60",vendor: "BILLIONTON SYSTEMS, INC."},
  {max_prefix: "C4-D4-89",vendor: "JiangSu Joyque Information Industry Co.,Ltd"},
  {max_prefix: "B8-2C-A0",vendor: "Resideo"},
  {max_prefix: "B4-F9-49",vendor: "optilink networks pvt ltd"},
  {max_prefix: "48-35-2E",vendor: "Shenzhen Wolck Network Product Co.,LTD"},
  {max_prefix: "6C-C7-EC",vendor: "SAMSUNG ELECTRO-MECHANICS(THAILAND)"},
  {max_prefix: "D8-96-85",vendor: "GoPro"},
  {max_prefix: "C0-BD-C8",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "64-7B-CE",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "A8-87-B3",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "6C-00-6B",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "3C-01-EF",vendor: "Sony Corporation"},
  {max_prefix: "04-E5-98",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "3C-37-86",vendor: "NETGEAR"},
  {max_prefix: "98-D3-E7",vendor: "Netafim L"},
  {max_prefix: "F0-63-F9",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "7C-C3-85",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "90-0E-B3",vendor: "Shenzhen Amediatech Technology Co., Ltd."},
  {max_prefix: "00-AD-24",vendor: "D-Link International"},
  {max_prefix: "1C-FD-08",vendor: "IEEE Registration Authority"},
  {max_prefix: "B8-59-9F",vendor: "Mellanox Technologies, Inc."},
  {max_prefix: "30-13-89",vendor: "Siemens AG, Automations & Drives,"},
  {max_prefix: "A0-A4-C5",vendor: "Intel Corporate"},
  {max_prefix: "10-5B-AD",vendor: "Mega Well Limited"},
  {max_prefix: "94-EA-EA",vendor: "TELLESCOM INDUSTRIA E COMERCIO EM TELECOMUNICACAO"},
  {max_prefix: "F4-D1-08",vendor: "Intel Corporate"},
  {max_prefix: "60-CE-92",vendor: "The Refined Industry Company Limited"},
  {max_prefix: "74-BF-C0",vendor: "CANON INC."},
  {max_prefix: "C8-D9-D2",vendor: "Hewlett Packard"},
  {max_prefix: "24-FC-E5",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "80-96-21",vendor: "Lenovo"},
  {max_prefix: "78-05-5F",vendor: "Shenzhen WYC Technology Co., Ltd."},
  {max_prefix: "18-62-E4",vendor: "Texas Instruments"},
  {max_prefix: "20-B0-01",vendor: "Technicolor Delivery Technologies Belgium NV"},
  {max_prefix: "F0-54-94",vendor: "Honeywell Connected Building"},
  {max_prefix: "54-06-8B",vendor: "Ningbo Deli Kebei Technology Co.LTD"},
  {max_prefix: "54-9F-AE",vendor: "iBASE Gaming Inc"},
  {max_prefix: "B0-2A-43",vendor: "Google, Inc."},
  {max_prefix: "18-1D-EA",vendor: "Intel Corporate"},
  {max_prefix: "18-56-80",vendor: "Intel Corporate"},
  {max_prefix: "C4-74-F8",vendor: "Hot Pepper, Inc."},
  {max_prefix: "48-87-2D",vendor: "SHEN ZHEN DA XIA LONG QUE TECHNOLOGY CO.,LTD"},
  {max_prefix: "E8-1A-58",vendor: "TECHNOLOGIC SYSTEMS"},
  {max_prefix: "00-EA-BD",vendor: "Cisco Systems, Inc"},
  {max_prefix: "5C-C9-99",vendor: "New H3C Technologies Co., Ltd"},
  {max_prefix: "EC-79-F2",vendor: "Startel"},
  {max_prefix: "04-BC-87",vendor: "Shenzhen JustLink Technology Co., LTD"},
  {max_prefix: "54-C3-3E",vendor: "Ciena Corporation"},
  {max_prefix: "14-22-33",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "BC-B2-2B",vendor: "EM-Tech"},
  {max_prefix: "DC-37-57",vendor: "Integrated Device Technology (Malaysia) Sdn. Bhd."},
  {max_prefix: "00-50-99",vendor: "3COM EUROPE LTD"},
  {max_prefix: "00-60-EB",vendor: "FOURTHTRACK SYSTEMS"},
  {max_prefix: "74-34-00",vendor: "MTG Co., Ltd."},
  {max_prefix: "60-35-C0",vendor: "SFR"},
  {max_prefix: "00-1A-C5",vendor: "Keysight Technologies, Inc."},
  {max_prefix: "00-20-1E",vendor: "NETQUEST CORPORATION"},
  {max_prefix: "00-60-8C",vendor: "3COM"},
  {max_prefix: "00-A0-24",vendor: "3COM"},
  {max_prefix: "00-20-AF",vendor: "3COM"},
  {max_prefix: "00-10-4B",vendor: "3COM"},
  {max_prefix: "B0-8B-CF",vendor: "Cisco Systems, Inc"},
  {max_prefix: "C4-98-5C",vendor: "Hui Zhou Gaoshengda Technology Co.,LTD"},
  {max_prefix: "30-A1-FA",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "64-5D-86",vendor: "Intel Corporate"},
  {max_prefix: "64-62-8A",vendor: "evon GmbH"},
  {max_prefix: "04-15-D9",vendor: "Viwone"},
  {max_prefix: "9C-AA-1B",vendor: "Microsoft Corporation"},
  {max_prefix: "A8-9A-93",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "8C-92-46",vendor: "Oerlikon Textile Gmbh&Co.KG"},
  {max_prefix: "EC-B3-13",vendor: "SHENZHEN GONGJIN ELECTRONICS CO.,LT"},
  {max_prefix: "24-2E-90",vendor: "PALIT MICROSYSTEMS, LTD"},
  {max_prefix: "F4-06-8D",vendor: "devolo AG"},
  {max_prefix: "98-8E-D4",vendor: "ITEL MOBILE LIMITED"},
  {max_prefix: "E8-A7-88",vendor: "XIAMEN LEELEN TECHNOLOGY CO., LTD"},
  {max_prefix: "0C-9D-92",vendor: "ASUSTek COMPUTER INC."},
  {max_prefix: "0C-CB-85",vendor: "Motorola Mobility LLC, a Lenovo Company"},
  {max_prefix: "40-17-E2",vendor: "INTAI TECHNOLOGY CORP."},
  {max_prefix: "48-98-CA",vendor: "Sichuan\u00a0AI-Link\u00a0Technology\u00a0Co.,\u00a0Ltd."},
  {max_prefix: "24-7E-51",vendor: "zte corporation"},
  {max_prefix: "E8-B5-41",vendor: "zte corporation"},
  {max_prefix: "0C-96-E6",vendor: "Cloud Network Technology (Samoa) Limited"},
  {max_prefix: "3C-89-94",vendor: "SKY UK LIMITED"},
  {max_prefix: "58-2D-34",vendor: "Qingping Electronics (Suzhou) Co., Ltd"},
  {max_prefix: "20-DE-88",vendor: "IC Realtime LLC"},
  {max_prefix: "A8-5A-F3",vendor: "Shanghai Siflower Communication Technology Co., Ltd"},
  {max_prefix: "70-FD-46",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "8C-83-E1",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "88-9F-6F",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "5C-63-C9",vendor: "Intellithings Ltd."},
  {max_prefix: "00-0E-94",vendor: "Maas International BV"},
  {max_prefix: "00-1A-31",vendor: "SCAN COIN AB"},
  {max_prefix: "00-1B-84",vendor: "Scan Engineering Telecom"},
  {max_prefix: "E0-0E-E1",vendor: "We Corporation Inc."},
  {max_prefix: "48-2C-A0",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "A4-E6-15",vendor: "SHENZHEN CHUANGWEI-RGB ELECTRONICS CO.,LTD"},
  {max_prefix: "A0-93-51",vendor: "Cisco Systems, Inc"},
  {max_prefix: "88-AE-1D",vendor: "COMPAL INFORMATION (KUNSHAN) CO., LTD."},
  {max_prefix: "B8-88-E3",vendor: "COMPAL INFORMATION (KUNSHAN) CO., LTD."},
  {max_prefix: "34-12-F9",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "BC-E2-65",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "4C-D1-A1",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "88-BF-E4",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "74-1F-79",vendor: "YOUNGKOOK ELECTRONICS CO.,LTD"},
  {max_prefix: "20-89-84",vendor: "COMPAL INFORMATION (KUNSHAN) CO., LTD."},
  {max_prefix: "B4-CE-FE",vendor: "James Czekaj"},
  {max_prefix: "F8-CC-6E",vendor: "DEPO Electronics Ltd"},
  {max_prefix: "F8-36-9B",vendor: "Texas Instruments"},
  {max_prefix: "00-C0-55",vendor: "MODULAR COMPUTING TECHNOLOGIES"},
  {max_prefix: "E4-1F-E9",vendor: "Dunkermotoren GmbH"},
  {max_prefix: "D8-76-0A",vendor: "Escort, Inc."},
  {max_prefix: "5C-2E-D2",vendor: "ABC(XiSheng) Electronics Co.,Ltd"},
  {max_prefix: "20-16-3D",vendor: "Integrated Device Technology (Malaysia) Sdn. Bhd."},
  {max_prefix: "64-13-31",vendor: "Bosch Car Multimedia (Wuhu) Co. Ltd."},
  {max_prefix: "18-3A-48",vendor: "VostroNet"},
  {max_prefix: "78-2F-17",vendor: "Xlab Co.,Ltd"},
  {max_prefix: "B0-02-7E",vendor: "MULLER SERVICES"},
  {max_prefix: "24-FA-F3",vendor: "Shanghai Flexem Technology Co.,Ltd."},
  {max_prefix: "C4-51-8D",vendor: "Shenzhen YOUHUA Technology Co., Ltd"},
  {max_prefix: "48-68-34",vendor: "Silicon Motion, Inc."},
  {max_prefix: "00-11-33",vendor: "Siemens AG Austria"},
  {max_prefix: "00-0B-23",vendor: "Siemens Home & Office Comm. Devices"},
  {max_prefix: "34-0A-98",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "64-6D-6C",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "C4-B8-B4",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "28-9E-FC",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "E0-73-5F",vendor: "NUCOM"},
  {max_prefix: "00-51-ED",vendor: "LG Innotek"},
  {max_prefix: "98-03-9B",vendor: "Mellanox Technologies, Inc."},
  {max_prefix: "40-DC-9D",vendor: "HAJEN"},
  {max_prefix: "F0-BC-C9",vendor: "PFU LIMITED"},
  {max_prefix: "88-D2-BF",vendor: "German Autolabs"},
  {max_prefix: "8C-35-79",vendor: "QDIQO Sp. z o.o."},
  {max_prefix: "38-C7-0A",vendor: "WiFiSong"},
  {max_prefix: "D8-24-77",vendor: "Universal Electric Corporation"},
  {max_prefix: "00-90-7F",vendor: "WatchGuard Technologies, Inc."},
  {max_prefix: "4C-5E-0C",vendor: "Routerboard.com"},
  {max_prefix: "D4-CA-6D",vendor: "Routerboard.com"},
  {max_prefix: "FC-FB-FB",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-7E-95",vendor: "Cisco Systems, Inc"},
  {max_prefix: "08-D4-6A",vendor: "LG Electronics (Mobile Communications)"},
  {max_prefix: "40-06-A0",vendor: "Texas Instruments"},
  {max_prefix: "48-75-83",vendor: "Intellion AG"},
  {max_prefix: "9C-5A-44",vendor: "COMPAL INFORMATION (KUNSHAN) CO., LTD."},
  {max_prefix: "14-CA-A0",vendor: "Hu&Co"},
  {max_prefix: "80-B5-75",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "A4-BE-2B",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "28-11-A5",vendor: "Bose Corporation"},
  {max_prefix: "D8-F3-DB",vendor: "Post CH AG"},
  {max_prefix: "DC-B4-AC",vendor: "FLEXTRONICS MANUFACTURING(ZHUHAI)CO.,LTD."},
  {max_prefix: "F4-EE-14",vendor: "MERCURY COMMUNICATION TECHNOLOGIES CO.,LTD."},
  {max_prefix: "6C-59-40",vendor: "MERCURY COMMUNICATION TECHNOLOGIES CO.,LTD."},
  {max_prefix: "D0-25-16",vendor: "MERCURY COMMUNICATION TECHNOLOGIES CO.,LTD."},
  {max_prefix: "1C-60-DE",vendor: "MERCURY COMMUNICATION TECHNOLOGIES CO.,LTD."},
  {max_prefix: "00-14-72",vendor: "China Broadband Wireless IP Standard group(ChinaBWIPS)"},
  {max_prefix: "A4-53-85",vendor: "WEIFANG GOERTEK ELECTRONICS CO.,LTD"},
  {max_prefix: "00-40-2F",vendor: "XLNT DESIGNS INC."},
  {max_prefix: "04-EC-BB",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "00-10-17",vendor: "Bosch Access Systems GmbH"},
  {max_prefix: "D4-21-22",vendor: "Sercomm Corporation."},
  {max_prefix: "00-C0-02",vendor: "Sercomm Corporation."},
  {max_prefix: "68-E7-C2",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "58-B1-0F",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "20-3D-BD",vendor: "LG Innotek"},
  {max_prefix: "28-02-45",vendor: "Konze System Technology Co.,Ltd."},
  {max_prefix: "E4-8F-65",vendor: "Yelatma Instrument Making Enterprise, JSC"},
  {max_prefix: "84-0D-8E",vendor: "Espressif Inc."},
  {max_prefix: "A4-92-CB",vendor: "Nokia"},
  {max_prefix: "C0-D2-F3",vendor: "Hui Zhou Gaoshengda Technology Co.,LTD"},
  {max_prefix: "94-19-3A",vendor: "Elvaco AB"},
  {max_prefix: "BC-99-11",vendor: "Zyxel Communications Corporation"},
  {max_prefix: "3C-F5-CC",vendor: "New H3C Technologies Co., Ltd"},
  {max_prefix: "00-BB-3A",vendor: "Amazon Technologies Inc."},
  {max_prefix: "C0-81-35",vendor: "Ningbo Forfan technology Co., LTD"},
  {max_prefix: "3C-F4-F9",vendor: "Moda-InnoChips"},
  {max_prefix: "0C-B3-4F",vendor: "Shenzhen Xiaoqi Intelligent Technology Co., Ltd."},
  {max_prefix: "64-A2-F9",vendor: "OnePlus Technology (Shenzhen) Co., Ltd"},
  {max_prefix: "A8-7D-12",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "88-D3-7B",vendor: "FirmTek, LLC"},
  {max_prefix: "B4-C0-F5",vendor: "Shenzhen TINNO Mobile Technology Corp."},
  {max_prefix: "40-62-31",vendor: "GIFA"},
  {max_prefix: "FC-B7-F0",vendor: "Idaho National Laboratory"},
  {max_prefix: "50-50-CE",vendor: "Hangzhou Dianyixia Communication Technology Co. Ltd."},
  {max_prefix: "2C-28-B7",vendor: "Hangzhou Ruiying technology co., LTD"},
  {max_prefix: "04-6B-1B",vendor: "SYSDINE Co., Ltd."},
  {max_prefix: "E8-FA-F7",vendor: "Guangdong Uniteddata Holding Group Co., Ltd."},
  {max_prefix: "94-9D-57",vendor: "Panasonic do Brasil Limitada"},
  {max_prefix: "FC-69-47",vendor: "Texas Instruments"},
  {max_prefix: "1C-66-6D",vendor: "Hon Hai Precision Ind. Co.,Ltd."},
  {max_prefix: "DC-DE-4F",vendor: "Gionee Communication Equipment Co Ltd"},
  {max_prefix: "4C-D0-CB",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "3C-24-F0",vendor: "IEEE Registration Authority"},
  {max_prefix: "58-DB-15",vendor: "TECNO MOBILE LIMITED"},
  {max_prefix: "00-20-82",vendor: "ONEAC CORPORATION"},
  {max_prefix: "00-00-A8",vendor: "Stratus Technologies"},
  {max_prefix: "00-04-FC",vendor: "Stratus Technologies"},
  {max_prefix: "E0-7D-EA",vendor: "Texas Instruments"},
  {max_prefix: "50-5D-AC",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "B0-A3-7E",vendor: "QING DAO HAIER TELECOM CO.,LTD."},
  {max_prefix: "04-D3-B0",vendor: "Intel Corporate"},
  {max_prefix: "3C-EA-F9",vendor: "JUBIXCOLTD"},
  {max_prefix: "68-2C-7B",vendor: "Cisco Systems, Inc"},
  {max_prefix: "44-1E-98",vendor: "Ruckus Wireless"},
  {max_prefix: "00-25-0C",vendor: "Senet Inc"},
  {max_prefix: "0C-80-63",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "00-72-78",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-A0-21",vendor: "General Dynamics Mission Systems"},
  {max_prefix: "90-DD-5D",vendor: "Apple, Inc."},
  {max_prefix: "DC-29-19",vendor: "AltoBeam (Xiamen) Technology Ltd, Co."},
  {max_prefix: "64-5A-ED",vendor: "Apple, Inc."},
  {max_prefix: "84-F3-EB",vendor: "Espressif Inc."},
  {max_prefix: "00-1B-48",vendor: "Shenzhen Lantech Electronics Co., Ltd."},
  {max_prefix: "50-BC-96",vendor: "Apple, Inc."},
  {max_prefix: "FC-2A-9C",vendor: "Apple, Inc."},
  {max_prefix: "A0-56-F3",vendor: "Apple, Inc."},
  {max_prefix: "54-99-63",vendor: "Apple, Inc."},
  {max_prefix: "C0-B6-58",vendor: "Apple, Inc."},
  {max_prefix: "48-A9-1C",vendor: "Apple, Inc."},
  {max_prefix: "00-2F-D9",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "88-5F-E8",vendor: "IEEE Registration Authority"},
  {max_prefix: "F0-AF-50",vendor: "Phantom Intelligence"},
  {max_prefix: "B4-CD-27",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "C8-19-F7",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "C4-2C-4F",vendor: "Qingdao Hisense Mobile Communication Technology Co,Ltd"},
  {max_prefix: "24-CA-CB",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "54-3E-64",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "38-80-DF",vendor: "Motorola Mobility LLC, a Lenovo Company"},
  {max_prefix: "BC-6A-2F",vendor: "Henge Docks LLC"},
  {max_prefix: "48-BD-3D",vendor: "New H3C Technologies Co., Ltd"},
  {max_prefix: "0C-08-B4",vendor: "HUMAX Co., Ltd."},
  {max_prefix: "F4-E1-1E",vendor: "Texas Instruments"},
  {max_prefix: "00-27-05",vendor: "Sectronic"},
  {max_prefix: "18-0F-76",vendor: "D-Link International"},
  {max_prefix: "DC-02-65",vendor: "Meditech Kft"},
  {max_prefix: "90-94-97",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "DC-72-9B",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "14-A7-2B",vendor: "currentoptronics Pvt.Ltd"},
  {max_prefix: "3C-17-10",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "34-02-9B",vendor: "Plexonics Technologies LImited"},
  {max_prefix: "84-DB-9E",vendor: "Pink Nectarine Health AB"},
  {max_prefix: "90-03-72",vendor: "Longnan Junya Digital Technology Co. Ltd."},
  {max_prefix: "A4-DA-22",vendor: "IEEE Registration Authority"},
  {max_prefix: "8C-4C-AD",vendor: "Evoluzn Inc."},
  {max_prefix: "D4-E6-B7",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "4C-77-6D",vendor: "Cisco Systems, Inc"},
  {max_prefix: "3C-DC-BC",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "80-4E-70",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "74-E1-82",vendor: "Texas Instruments"},
  {max_prefix: "E8-DE-FB",vendor: "MESOTIC SAS"},
  {max_prefix: "94-FE-9D",vendor: "SHENZHEN GONGJIN ELECTRONICS CO.,LT"},
  {max_prefix: "6C-B6-CA",vendor: "DIVUS GmbH"},
  {max_prefix: "04-D1-3A",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "8C-F9-57",vendor: "RuiXingHengFang Network (Shenzhen) Co.,Ltd"},
  {max_prefix: "0C-C6-CC",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "6C-C4-D5",vendor: "HMD Global Oy"},
  {max_prefix: "80-C5-48",vendor: "Shenzhen Zowee Technology Co.,Ltd"},
  {max_prefix: "C4-00-AD",vendor: "Advantech Technology (CHINA) Co., Ltd."},
  {max_prefix: "34-99-D7",vendor: "Universal Flow Monitors, Inc."},
  {max_prefix: "0C-8B-D3",vendor: "ITEL MOBILE LIMITED"},
  {max_prefix: "00-24-AF",vendor: "Dish Technologies Corp"},
  {max_prefix: "C0-A8-F0",vendor: "Adamson Systems Engineering"},
  {max_prefix: "9C-43-1E",vendor: "IEEE Registration Authority"},
  {max_prefix: "4C-C2-06",vendor: "Somfy"},
  {max_prefix: "78-58-60",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "E8-AB-F3",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "44-9E-F9",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "B4-E9-A3",vendor: "port industrial automation GmbH"},
  {max_prefix: "6C-2A-CB",vendor: "Paxton Access Ltd"},
  {max_prefix: "98-00-74",vendor: "Raisecom Technology CO., LTD"},
  {max_prefix: "18-C1-9D",vendor: "Integrated Device Technology (Malaysia) Sdn. Bhd."},
  {max_prefix: "0C-98-38",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "28-2C-02",vendor: "IEEE Registration Authority"},
  {max_prefix: "58-3B-D9",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "DC-A2-66",vendor: "Hon Hai Precision Ind. Co.,Ltd."},
  {max_prefix: "C4-84-66",vendor: "Apple, Inc."},
  {max_prefix: "34-7C-25",vendor: "Apple, Inc."},
  {max_prefix: "CC-2D-B7",vendor: "Apple, Inc."},
  {max_prefix: "A0-BD-CD",vendor: "SKY UK LIMITED"},
  {max_prefix: "FC-A1-83",vendor: "Amazon Technologies Inc."},
  {max_prefix: "74-EA-CB",vendor: "New H3C Technologies Co., Ltd"},
  {max_prefix: "BC-91-B5",vendor: "Infinix mobility limited"},
  {max_prefix: "D4-1A-3F",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "30-C5-07",vendor: "ECI Telecom Ltd."},
  {max_prefix: "28-2F-C2",vendor: "Automotive Data Solutions"},
  {max_prefix: "E8-8E-60",vendor: "NSD Corporation"},
  {max_prefix: "00-0F-9B",vendor: "Ross Video Limited"},
  {max_prefix: "00-24-BA",vendor: "Texas Instruments"},
  {max_prefix: "60-51-2C",vendor: "TCT mobile ltd"},
  {max_prefix: "64-DB-81",vendor: "Syszone Co., Ltd."},
  {max_prefix: "44-AD-19",vendor: "XINGFEI \uff08H.K\uff09LIMITED"},
  {max_prefix: "38-AD-BE",vendor: "New H3C Technologies Co., Ltd"},
  {max_prefix: "04-B1-67",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "EC-83-50",vendor: "Microsoft Corporation"},
  {max_prefix: "5C-86-5C",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "5C-AD-76",vendor: "Shenzhen TCL New Technology Co., Ltd"},
  {max_prefix: "64-6E-6C",vendor: "Radio Datacom LLC"},
  {max_prefix: "04-F1-28",vendor: "HMD Global Oy"},
  {max_prefix: "0C-52-03",vendor: "AGM GROUP LIMITED"},
  {max_prefix: "2C-54-91",vendor: "Microsoft Corporation"},
  {max_prefix: "E4-A7-A0",vendor: "Intel Corporate"},
  {max_prefix: "C8-45-8F",vendor: "Wyler AG"},
  {max_prefix: "00-1B-B9",vendor: "Elitegroup Computer Systems Co.,Ltd."},
  {max_prefix: "00-24-61",vendor: "Shin Wang Tech."},
  {max_prefix: "1C-DF-52",vendor: "Texas Instruments"},
  {max_prefix: "00-1E-1D",vendor: "East Coast Datacom, Inc."},
  {max_prefix: "5C-E2-8C",vendor: "Zyxel Communications Corporation"},
  {max_prefix: "E4-BD-4B",vendor: "zte corporation"},
  {max_prefix: "3C-2E-F9",vendor: "Apple, Inc."},
  {max_prefix: "7C-01-0A",vendor: "Texas Instruments"},
  {max_prefix: "00-01-44",vendor: "Dell EMC"},
  {max_prefix: "08-00-1B",vendor: "Dell EMC"},
  {max_prefix: "38-D7-CA",vendor: "7HUGS LABS"},
  {max_prefix: "A0-4E-A7",vendor: "Apple, Inc."},
  {max_prefix: "F0-98-9D",vendor: "Apple, Inc."},
  {max_prefix: "E4-2B-34",vendor: "Apple, Inc."},
  {max_prefix: "78-46-C4",vendor: "DAEHAP HYPER-TECH"},
  {max_prefix: "FC-D6-BD",vendor: "Robert Bosch GmbH"},
  {max_prefix: "18-39-6E",vendor: "SUNSEA TELECOMMUNICATIONS CO.,LTD."},
  {max_prefix: "EC-7D-11",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "D8-08-31",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "70-1F-53",vendor: "Cisco Systems, Inc"},
  {max_prefix: "94-41-C1",vendor: "Mini-Cam Limited"},
  {max_prefix: "48-BA-4E",vendor: "Hewlett Packard"},
  {max_prefix: "50-0F-80",vendor: "Cisco Systems, Inc"},
  {max_prefix: "50-4E-DC",vendor: "Ping Communication"},
  {max_prefix: "08-67-4E",vendor: "Hisense broadband multimedia technology Co.,Ltd"},
  {max_prefix: "8C-68-C8",vendor: "zte corporation"},
  {max_prefix: "EC-82-63",vendor: "zte corporation"},
  {max_prefix: "74-BB-D3",vendor: "Shenzhen xeme Communication Co., Ltd."},
  {max_prefix: "68-3C-7D",vendor: "Magic Intelligence Technology Limited"},
  {max_prefix: "0C-1C-20",vendor: "Kakao Corp"},
  {max_prefix: "94-28-2E",vendor: "New H3C Technologies Co., Ltd"},
  {max_prefix: "10-F1-F2",vendor: "LG Electronics (Mobile Communications)"},
  {max_prefix: "8C-3C-4A",vendor: "NAKAYO Inc"},
  {max_prefix: "6C-B7-49",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "98-9C-57",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "18-52-82",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "70-86-C1",vendor: "Texas Instruments"},
  {max_prefix: "DC-6A-EA",vendor: "Infinix mobility limited"},
  {max_prefix: "98-F5-A9",vendor: "OHSUNG"},
  {max_prefix: "D8-61-62",vendor: "Wistron Neweb Corporation"},
  {max_prefix: "F4-D7-B2",vendor: "LGS Innovations, LLC"},
  {max_prefix: "00-15-2A",vendor: "Nokia Corporation"},
  {max_prefix: "A4-11-15",vendor: "Robert Bosch Engineering and Business Solutions pvt. Ltd."},
  {max_prefix: "28-84-0E",vendor: "silicon valley immigration service"},
  {max_prefix: "80-61-5F",vendor: "Beijing Sinead Technology Co., Ltd."},
  {max_prefix: "44-4A-B0",vendor: "Zhejiang Moorgen Intelligence Technology Co., Ltd"},
  {max_prefix: "B0-19-C6",vendor: "Apple, Inc."},
  {max_prefix: "9C-4A-7B",vendor: "Nokia Corporation"},
  {max_prefix: "2C-D2-E7",vendor: "Nokia Corporation"},
  {max_prefix: "38-6E-A2",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "98-2D-68",vendor: "Samsung Electronics Co., Ltd"},
  {max_prefix: "08-00-70",vendor: "Mitsubishi Precision Co.,LTd."},
  {max_prefix: "48-EC-5B",vendor: "Nokia Solutions and Networks GmbH & Co. KG"},
  {max_prefix: "C4-21-C8",vendor: "KYOCERA CORPORATION"},
  {max_prefix: "80-73-9F",vendor: "KYOCERA CORPORATION"},
  {max_prefix: "38-66-F0",vendor: "Apple, Inc."},
  {max_prefix: "70-58-12",vendor: "Panasonic Corporation AVC Networks Company"},
  {max_prefix: "04-20-9A",vendor: "Panasonic Corporation AVC Networks Company"},
  {max_prefix: "34-00-8A",vendor: "IEEE Registration Authority"},
  {max_prefix: "90-50-CA",vendor: "Hitron Technologies. Inc"},
  {max_prefix: "40-99-22",vendor: "AzureWave Technology Inc."},
  {max_prefix: "C0-6D-1A",vendor: "Tianjin Henxinhuifeng Technology Co.,Ltd."},
  {max_prefix: "10-7B-44",vendor: "ASUSTek COMPUTER INC."},
  {max_prefix: "1C-AB-34",vendor: "New H3C Technologies Co., Ltd"},
  {max_prefix: "3C-78-43",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "5C-09-79",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "E4-FB-5D",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "E8-68-19",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "AC-51-2C",vendor: "Infinix mobility limited"},
  {max_prefix: "00-30-C8",vendor: "GAD LINE, LTD."},
  {max_prefix: "00-16-E0",vendor: "3Com Ltd"},
  {max_prefix: "40-D6-3C",vendor: "Equitech Industrial(DongGuan)Co.,Ltd"},
  {max_prefix: "F4-F3-AA",vendor: "JBL GmbH & Co. KG"},
  {max_prefix: "40-A3-CC",vendor: "Intel Corporate"},
  {max_prefix: "D8-DE-CE",vendor: "ISUNG CO.,LTD"},
  {max_prefix: "80-19-34",vendor: "Intel Corporate"},
  {max_prefix: "70-3E-AC",vendor: "Apple, Inc."},
  {max_prefix: "00-11-C0",vendor: "Aday Technology Inc"},
  {max_prefix: "00-05-F1",vendor: "Vrcom, Inc."},
  {max_prefix: "84-25-3F",vendor: "silex technology, Inc."},
  {max_prefix: "00-08-C9",vendor: "TechniSat Digital GmbH Daun"},
  {max_prefix: "D8-B1-2A",vendor: "Panasonic Mobile Communications Co.,Ltd."},
  {max_prefix: "8C-25-05",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "08-A8-A1",vendor: "Cyclotronics Power Concepts, Inc"},
  {max_prefix: "F4-B5-20",vendor: "Biostar Microtech international corp."},
  {max_prefix: "00-80-09",vendor: "JUPITER SYSTEMS, INC."},
  {max_prefix: "00-C0-64",vendor: "General Datacomm LLC"},
  {max_prefix: "30-C0-1B",vendor: "Shenzhen Jingxun Software Telecommunication Technology Co.,Ltd"},
  {max_prefix: "8C-3B-AD",vendor: "NETGEAR"},
  {max_prefix: "64-CF-D9",vendor: "Texas Instruments"},
  {max_prefix: "74-7D-24",vendor: "Phicomm (Shanghai) Co., Ltd."},
  {max_prefix: "50-64-2B",vendor: "XIAOMI Electronics,CO.,LTD"},
  {max_prefix: "88-7A-31",vendor: "Velankani Electronics Pvt. Ltd."},
  {max_prefix: "8C-0F-6F",vendor: "PEGATRON CORPORATION"},
  {max_prefix: "30-99-35",vendor: "zte corporation"},
  {max_prefix: "0C-72-D9",vendor: "zte corporation"},
  {max_prefix: "64-32-A8",vendor: "Intel Corporate"},
  {max_prefix: "88-86-C2",vendor: "STABILO International GmbH"},
  {max_prefix: "CC-2F-71",vendor: "Intel Corporate"},
  {max_prefix: "B8-F8-BE",vendor: "BLUECOM"},
  {max_prefix: "2C-5D-93",vendor: "Ruckus Wireless"},
  {max_prefix: "38-FF-36",vendor: "Ruckus Wireless"},
  {max_prefix: "84-18-3A",vendor: "Ruckus Wireless"},
  {max_prefix: "A4-7B-9D",vendor: "Espressif Inc."},
  {max_prefix: "7C-2E-DD",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "3C-F7-A4",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "00-00-FE",vendor: "Annapolis Micro Systems, Inc."},
  {max_prefix: "38-E5-95",vendor: "SHENZHEN GONGJIN ELECTRONICS CO.,LT"},
  {max_prefix: "BC-96-80",vendor: "SHENZHEN GONGJIN ELECTRONICS CO.,LT"},
  {max_prefix: "24-C9-A1",vendor: "Ruckus Wireless"},
  {max_prefix: "00-24-82",vendor: "Ruckus Wireless"},
  {max_prefix: "68-92-34",vendor: "Ruckus Wireless"},
  {max_prefix: "50-A7-33",vendor: "Ruckus Wireless"},
  {max_prefix: "10-30-34",vendor: "Cara Systems"},
  {max_prefix: "00-D0-1F",vendor: "Senetas Corporation Ltd"},
  {max_prefix: "4C-65-A8",vendor: "IEEE Registration Authority"},
  {max_prefix: "D8-22-F4",vendor: "Avnet Silica"},
  {max_prefix: "34-8F-27",vendor: "Ruckus Wireless"},
  {max_prefix: "2C-9E-EC",vendor: "Jabil Circuit Penang"},
  {max_prefix: "70-6E-6D",vendor: "Cisco Systems, Inc"},
  {max_prefix: "E8-6D-65",vendor: "AUDIO MOBIL Elektronik GmbH"},
  {max_prefix: "64-47-E0",vendor: "Feitian Technologies Co., Ltd"},
  {max_prefix: "B4-4F-96",vendor: "Zhejiang Xinzailing Technology co., ltd"},
  {max_prefix: "B0-DF-C1",vendor: "Tenda Technology Co.,Ltd.Dongguan branch"},
  {max_prefix: "9C-6F-52",vendor: "zte corporation"},
  {max_prefix: "60-47-62",vendor: "Beijing Sensoro Technology Co.,Ltd."},
  {max_prefix: "98-6F-60",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "BC-1C-81",vendor: "Sichuan iLink Technology Co., Ltd."},
  {max_prefix: "90-0A-1A",vendor: "Taicang T&W Electronics"},
  {max_prefix: "50-6E-92",vendor: "Innocent Technology Co., Ltd."},
  {max_prefix: "30-FE-31",vendor: "Nokia"},
  {max_prefix: "C4-57-1F",vendor: "June Life Inc"},
  {max_prefix: "88-6A-E3",vendor: "Alpha Networks Inc."},
  {max_prefix: "1C-4D-70",vendor: "Intel Corporate"},
  {max_prefix: "00-1C-FA",vendor: "Alarm.com"},
  {max_prefix: "60-31-3B",vendor: "Sunnovo International Limited"},
  {max_prefix: "6C-B2-27",vendor: "Sony Video & Sound Products Inc."},
  {max_prefix: "00-A3-D1",vendor: "Cisco Systems, Inc"},
  {max_prefix: "48-8D-36",vendor: "Arcadyan Corporation"},
  {max_prefix: "E8-E1-E1",vendor: "Gemtek Technology Co., Ltd."},
  {max_prefix: "28-07-0D",vendor: "GUANGZHOU WINSOUND INFORMATION TECHNOLOGY CO.,LTD."},
  {max_prefix: "FC-4D-8C",vendor: "SHENZHEN PANTE ELECTRONICS TECHNOLOGY CO., LTD"},
  {max_prefix: "FC-06-ED",vendor: "M2Motive Technology Inc."},
  {max_prefix: "BC-D7-13",vendor: "Owl Labs"},
  {max_prefix: "74-54-27",vendor: "SHENZHEN FAST TECHNOLOGIES CO.,LTD"},
  {max_prefix: "60-72-0B",vendor: "BLU Products Inc"},
  {max_prefix: "30-89-76",vendor: "DALIAN LAMBA TECHNOLOGY CO.,LTD"},
  {max_prefix: "2C-26-17",vendor: "Oculus VR, LLC"},
  {max_prefix: "10-C6-FC",vendor: "Garmin International"},
  {max_prefix: "AC-22-05",vendor: "Compal Broadband Networks, Inc."},
  {max_prefix: "80-A0-36",vendor: "Shanghai MXCHIP Information Technology Co., Ltd."},
  {max_prefix: "F0-74-85",vendor: "NGD Systems, Inc."},
  {max_prefix: "34-D9-54",vendor: "WiBotic Inc."},
  {max_prefix: "48-57-DD",vendor: "Facebook Inc"},
  {max_prefix: "48-7D-2E",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "F0-D4-F6",vendor: "Lars Thrane A/S"},
  {max_prefix: "F4-A9-97",vendor: "CANON INC."},
  {max_prefix: "64-DF-E9",vendor: "ATEME"},
  {max_prefix: "A0-09-4C",vendor: "CenturyLink"},
  {max_prefix: "20-F4-52",vendor: "Shanghai IUV Software Development Co. Ltd"},
  {max_prefix: "B4-39-34",vendor: "Pen Generations, Inc."},
  {max_prefix: "74-26-AC",vendor: "Cisco Systems, Inc"},
  {max_prefix: "14-3F-27",vendor: "Noccela Oy"},
  {max_prefix: "10-58-87",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "B0-26-28",vendor: "Broadcom Limited"},
  {max_prefix: "90-0E-83",vendor: "Monico Monitoring, Inc."},
  {max_prefix: "60-14-66",vendor: "zte corporation"},
  {max_prefix: "38-AC-3D",vendor: "Nephos Inc"},
  {max_prefix: "98-74-DA",vendor: "Infinix mobility limited"},
  {max_prefix: "1C-5A-0B",vendor: "Tegile Systems"},
  {max_prefix: "04-6E-02",vendor: "OpenRTLS Group"},
  {max_prefix: "9C-06-1B",vendor: "Hangzhou H3C Technologies Co., Limited"},
  {max_prefix: "50-33-8B",vendor: "Texas Instruments"},
  {max_prefix: "68-54-ED",vendor: "Alcatel-Lucent"},
  {max_prefix: "E0-37-BF",vendor: "Wistron Neweb Corporation"},
  {max_prefix: "AC-74-09",vendor: "Hangzhou H3C Technologies Co., Limited"},
  {max_prefix: "68-02-35",vendor: "Konten Networks Inc."},
  {max_prefix: "D8-D8-66",vendor: "SHENZHEN TOZED TECHNOLOGIES CO.,LTD."},
  {max_prefix: "D8-C0-6A",vendor: "Hunantv.com Interactive Entertainment Media Co.,Ltd."},
  {max_prefix: "00-11-92",vendor: "Cisco Systems, Inc"},
  {max_prefix: "38-F1-35",vendor: "SensorTec-Canada"},
  {max_prefix: "E8-DE-8E",vendor: "Integrated Device Technology (Malaysia) Sdn. Bhd."},
  {max_prefix: "E8-13-67",vendor: "AIRSOUND Inc."},
  {max_prefix: "AC-20-2E",vendor: "Hitron Technologies. Inc"},
  {max_prefix: "70-AF-24",vendor: "TP Vision Belgium NV"},
  {max_prefix: "A4-11-63",vendor: "IEEE Registration Authority"},
  {max_prefix: "7C-E9-7C",vendor: "ITEL MOBILE LIMITED"},
  {max_prefix: "C4-D1-97",vendor: "Ventia Utility Services"},
  {max_prefix: "F4-96-34",vendor: "Intel Corporate"},
  {max_prefix: "F0-93-C5",vendor: "Garland Technology"},
  {max_prefix: "98-10-E8",vendor: "Apple, Inc."},
  {max_prefix: "2C-86-D2",vendor: "Cisco Systems, Inc"},
  {max_prefix: "DC-A4-CA",vendor: "Apple, Inc."},
  {max_prefix: "8C-8F-E9",vendor: "Apple, Inc."},
  {max_prefix: "C0-D0-12",vendor: "Apple, Inc."},
  {max_prefix: "BC-A9-20",vendor: "Apple, Inc."},
  {max_prefix: "48-A1-95",vendor: "Apple, Inc."},
  {max_prefix: "F8-03-77",vendor: "Apple, Inc."},
  {max_prefix: "C4-9D-ED",vendor: "Microsoft Corporation"},
  {max_prefix: "98-A4-0E",vendor: "Snap, Inc."},
  {max_prefix: "80-58-F8",vendor: "Motorola Mobility LLC, a Lenovo Company"},
  {max_prefix: "70-AF-25",vendor: "Nishiyama Industry Co.,LTD."},
  {max_prefix: "E8-D1-1B",vendor: "ASKEY COMPUTER CORP"},
  {max_prefix: "0C-5F-35",vendor: "Niagara Video Corporation"},
  {max_prefix: "98-00-C1",vendor: "GuangZhou CREATOR Technology Co.,Ltd.(CHINA)"},
  {max_prefix: "CC-BE-59",vendor: "Calix Inc."},
  {max_prefix: "90-3D-6B",vendor: "Zicon Technology Corp."},
  {max_prefix: "B0-C4-6C",vendor: "Senseit"},
  {max_prefix: "98-D3-D2",vendor: "MEKRA Lang GmbH & Co. KG"},
  {max_prefix: "00-19-12",vendor: "Welcat Inc"},
  {max_prefix: "B8-F8-83",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "DC-FE-18",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "70-4F-57",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "8C-78-D7",vendor: "SHENZHEN FAST TECHNOLOGIES CO.,LTD"},
  {max_prefix: "B8-EA-AA",vendor: "ICG NETWORKS CO.,ltd"},
  {max_prefix: "5C-BA-37",vendor: "Microsoft Corporation"},
  {max_prefix: "00-C0-C6",vendor: "PERSONAL MEDIA CORP."},
  {max_prefix: "3C-7F-6F",vendor: "Telechips, Inc."},
  {max_prefix: "F8-A3-4F",vendor: "zte corporation"},
  {max_prefix: "C8-73-24",vendor: " Sow Cheng Technology Co. Ltd."},
  {max_prefix: "00-21-0D",vendor: "SAMSIN INNOTEC"},
  {max_prefix: "00-02-A1",vendor: "World Wide Packets"},
  {max_prefix: "00-E0-22",vendor: "Analog Devices, Inc."},
  {max_prefix: "50-E6-66",vendor: "Shenzhen Techtion Electronics Co., Ltd."},
  {max_prefix: "00-16-D3",vendor: "Wistron Corporation"},
  {max_prefix: "00-1F-16",vendor: "Wistron Corporation"},
  {max_prefix: "00-26-2D",vendor: "Wistron Corporation"},
  {max_prefix: "B0-40-89",vendor: "Senient Systems LTD"},
  {max_prefix: "54-25-EA",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "C8-94-BB",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "10-B1-F8",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "08-9E-08",vendor: "Google, Inc."},
  {max_prefix: "28-FE-CD",vendor: "Lemobile Information Technology (Beijing) Co., Ltd."},
  {max_prefix: "4C-4E-03",vendor: "TCT mobile ltd"},
  {max_prefix: "04-95-E6",vendor: "Tenda Technology Co.,Ltd.Dongguan branch"},
  {max_prefix: "70-2D-84",vendor: "i4C Innovations"},
  {max_prefix: "C0-D9-F7",vendor: "ShanDong Domor Intelligent S&T CO.,Ltd"},
  {max_prefix: "00-60-8B",vendor: "ConferTech International"},
  {max_prefix: "CC-A2-19",vendor: "SHENZHEN ALONG INVESTMENT CO.,LTD"},
  {max_prefix: "4C-1A-3A",vendor: "PRIMA Research And Production Enterprise Ltd."},
  {max_prefix: "2C-20-0B",vendor: "Apple, Inc."},
  {max_prefix: "88-66-A5",vendor: "Apple, Inc."},
  {max_prefix: "90-17-11",vendor: "Hagenuk Marinekommunikation GmbH"},
  {max_prefix: "00-10-DE",vendor: "INTERNATIONAL DATACASTING CORPORATION"},
  {max_prefix: "38-BC-01",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "34-1E-6B",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "88-66-39",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "00-00-48",vendor: "Seiko Epson Corporation"},
  {max_prefix: "8C-A5-A1",vendor: "Oregano Systems - Design & Consulting GmbH"},
  {max_prefix: "B8-EC-A3",vendor: "Zyxel Communications Corporation"},
  {max_prefix: "B0-B9-8A",vendor: "NETGEAR"},
  {max_prefix: "80-5A-04",vendor: "LG Electronics (Mobile Communications)"},
  {max_prefix: "AC-C1-EE",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "54-19-C8",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "00-02-77",vendor: "Cash Systemes Industrie"},
  {max_prefix: "BC-83-85",vendor: "Microsoft Corporation"},
  {max_prefix: "E4-B0-05",vendor: "Beijing IQIYI Science & Technology Co., Ltd."},
  {max_prefix: "B0-52-16",vendor: "Hon Hai Precision Ind. Co.,Ltd."},
  {max_prefix: "B0-E8-92",vendor: "Seiko Epson Corporation"},
  {max_prefix: "AC-18-26",vendor: "Seiko Epson Corporation"},
  {max_prefix: "A4-EE-57",vendor: "Seiko Epson Corporation"},
  {max_prefix: "9C-AE-D3",vendor: "Seiko Epson Corporation"},
  {max_prefix: "70-7C-69",vendor: "Avaya Inc"},
  {max_prefix: "50-0B-91",vendor: "IEEE Registration Authority"},
  {max_prefix: "F8-46-1C",vendor: "Sony Interactive Entertainment Inc."},
  {max_prefix: "14-A7-8B",vendor: "Zhejiang Dahua Technology Co., Ltd."},
  {max_prefix: "2C-59-8A",vendor: "LG Electronics (Mobile Communications)"},
  {max_prefix: "68-69-75",vendor: "Angler Labs Inc"},
  {max_prefix: "18-F8-7A",vendor: "i3 International Inc."},
  {max_prefix: "A0-E4-CB",vendor: "Zyxel Communications Corporation"},
  {max_prefix: "28-4E-D7",vendor: "OutSmart Power Systems, Inc."},
  {max_prefix: "64-A6-8F",vendor: "Zhongshan Readboy Electronics Co.,Ltd"},
  {max_prefix: "00-42-5A",vendor: "Cisco Systems, Inc"},
  {max_prefix: "70-4D-7B",vendor: "ASUSTek COMPUTER INC."},
  {max_prefix: "9C-FB-D5",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "18-F7-6B",vendor: "Zhejiang Winsight Technology CO.,LTD"},
  {max_prefix: "4C-26-E7",vendor: "Welgate Co., Ltd."},
  {max_prefix: "00-60-41",vendor: "Yokogawa Digital Computer Corporation"},
  {max_prefix: "58-31-12",vendor: "DRUST"},
  {max_prefix: "9C-83-BF",vendor: "PRO-VISION, Inc."},
  {max_prefix: "78-EF-4C",vendor: "Unetconvergence Co., Ltd."},
  {max_prefix: "00-C0-5A",vendor: "SEMAPHORE COMMUNICATIONS CORP."},
  {max_prefix: "00-07-F9",vendor: "Sensaphone"},
  {max_prefix: "00-1C-B3",vendor: "Apple, Inc."},
  {max_prefix: "20-D2-5F",vendor: "SmartCap Technologies"},
  {max_prefix: "E4-7D-BD",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "A0-B8-F8",vendor: "Amgen U.S.A. Inc."},
  {max_prefix: "88-44-77",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "14-9D-09",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "4C-11-BF",vendor: "Zhejiang Dahua Technology Co., Ltd."},
  {max_prefix: "1C-C0-E1",vendor: "IEEE Registration Authority"},
  {max_prefix: "00-B0-E1",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-50-93",vendor: "BOEING"},
  {max_prefix: "C8-1B-5C",vendor: "BCTech"},
  {max_prefix: "28-EE-52",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "00-1A-39",vendor: "Merten GmbH&CoKG"},
  {max_prefix: "E0-7C-13",vendor: "zte corporation"},
  {max_prefix: "F4-1F-88",vendor: "zte corporation"},
  {max_prefix: "E4-3E-D7",vendor: "Arcadyan Corporation"},
  {max_prefix: "14-ED-BB",vendor: "2Wire Inc"},
  {max_prefix: "18-E2-9F",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "F0-79-60",vendor: "Apple, Inc."},
  {max_prefix: "A0-D7-95",vendor: "Apple, Inc."},
  {max_prefix: "00-90-E7",vendor: "HORSCH ELEKTRONIK AG"},
  {max_prefix: "90-5C-44",vendor: "Compal Broadband Networks, Inc."},
  {max_prefix: "00-F2-2C",vendor: "Shanghai B-star Technology Co.,Ltd."},
  {max_prefix: "84-25-19",vendor: "Samsung Electronics"},
  {max_prefix: "5C-24-43",vendor: "O-Sung Telecom Co., Ltd."},
  {max_prefix: "70-79-90",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "A0-4E-01",vendor: "CENTRAL ENGINEERING co.,ltd."},
  {max_prefix: "28-CA-09",vendor: "ThyssenKrupp Elevators (Shanghai) Co.,Ltd"},
  {max_prefix: "20-47-ED",vendor: "SKY UK LIMITED"},
  {max_prefix: "74-8A-69",vendor: "Korea Image Technology Co., Ltd"},
  {max_prefix: "24-92-0E",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "FC-42-03",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "A0-10-81",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "54-54-CF",vendor: "PROBEDIGITAL CO.,LTD"},
  {max_prefix: "64-74-F6",vendor: "Shooter Detection Systems"},
  {max_prefix: "3C-8B-CD",vendor: "Alcatel-Lucent Shanghai Bell Co., Ltd"},
  {max_prefix: "4C-F9-5D",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "84-21-F1",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "00-05-EE",vendor: "Vanderbilt International (SWE) AB"},
  {max_prefix: "F8-63-3F",vendor: "Intel Corporate"},
  {max_prefix: "08-86-20",vendor: "TECNO MOBILE LIMITED"},
  {max_prefix: "00-25-66",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "98-13-33",vendor: "zte corporation"},
  {max_prefix: "D0-DB-32",vendor: "Nokia Corporation"},
  {max_prefix: "E8-00-36",vendor: "Befs co,. ltd"},
  {max_prefix: "C0-9F-05",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "04-18-0F",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "20-13-E0",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "F4-3C-96",vendor: "Ericsson AB"},
  {max_prefix: "38-84-79",vendor: "Cisco Meraki"},
  {max_prefix: "C4-41-1E",vendor: "Belkin International Inc."},
  {max_prefix: "24-F5-A2",vendor: "Belkin International Inc."},
  {max_prefix: "F8-9E-28",vendor: "Cisco Meraki"},
  {max_prefix: "68-3A-1E",vendor: "Cisco Meraki"},
  {max_prefix: "C0-F9-45",vendor: "Toshiba Toko Meter Systems Co., LTD."},
  {max_prefix: "70-F8-E7",vendor: "IEEE Registration Authority"},
  {max_prefix: "D4-2C-44",vendor: "Cisco Systems, Inc"},
  {max_prefix: "84-3D-C6",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-24-85",vendor: "ConteXtream Ltd"},
  {max_prefix: "28-FC-F6",vendor: "Shenzhen Xin KingBrand enterprises Co.,Ltd"},
  {max_prefix: "00-1F-58",vendor: "EMH Energiemesstechnik GmbH"},
  {max_prefix: "5C-49-79",vendor: "AVM Audiovisuelles Marketing und Computersysteme GmbH"},
  {max_prefix: "68-94-23",vendor: "Hon Hai Precision Ind. Co.,Ltd."},
  {max_prefix: "84-4B-F5",vendor: "Hon Hai Precision Ind. Co.,Ltd."},
  {max_prefix: "08-ED-B9",vendor: "Hon Hai Precision Ind. Co.,Ltd."},
  {max_prefix: "5C-6D-20",vendor: "Hon Hai Precision Ind. Co.,Ltd."},
  {max_prefix: "5C-AC-4C",vendor: "Hon Hai Precision Ind. Co.,Ltd."},
  {max_prefix: "00-16-DF",vendor: "Lundinova AB"},
  {max_prefix: "00-1D-0C",vendor: "MobileCompia"},
  {max_prefix: "E0-CB-BC",vendor: "Cisco Meraki"},
  {max_prefix: "B8-8E-DF",vendor: "Zencheer Communication Technology Co., Ltd."},
  {max_prefix: "DC-71-44",vendor: "SAMSUNG ELECTRO MECHANICS CO., LTD."},
  {max_prefix: "98-0C-82",vendor: "SAMSUNG ELECTRO MECHANICS CO., LTD."},
  {max_prefix: "A0-0B-BA",vendor: "SAMSUNG ELECTRO MECHANICS CO., LTD."},
  {max_prefix: "3C-77-E6",vendor: "Hon Hai Precision Ind. Co.,Ltd."},
  {max_prefix: "70-18-8B",vendor: "Hon Hai Precision Ind. Co.,Ltd."},
  {max_prefix: "8C-C8-CD",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "80-18-A7",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "F4-7B-5E",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "08-C6-B3",vendor: "QTECH LLC"},
  {max_prefix: "60-6B-BD",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "00-21-4C",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "00-16-6B",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "00-00-F0",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "18-46-17",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "38-0A-94",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "D0-DF-C7",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "D0-C1-B1",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "70-F9-27",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "A8-F2-74",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "D4-87-D8",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "F0-72-8C",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "34-AA-8B",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "BC-44-86",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "20-D3-90",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "00-18-AF",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "00-1E-E1",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "34-E7-1C",vendor: "Shenzhen YOUHUA Technology Co., Ltd"},
  {max_prefix: "88-6A-B1",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "6C-1E-90",vendor: "Hansol Technics Co., Ltd."},
  {max_prefix: "C8-DE-51",vendor: " IntegraOptics"},
  {max_prefix: "24-DB-ED",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "C4-50-06",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "88-32-9B",vendor: "SAMSUNG ELECTRO-MECHANICS(THAILAND)"},
  {max_prefix: "14-49-E0",vendor: "SAMSUNG ELECTRO-MECHANICS(THAILAND)"},
  {max_prefix: "D0-25-44",vendor: "SAMSUNG ELECTRO-MECHANICS(THAILAND)"},
  {max_prefix: "A0-04-60",vendor: "NETGEAR"},
  {max_prefix: "94-01-C2",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "50-FC-9F",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "38-0B-40",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "00-5A-13",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "94-61-24",vendor: "Pason Systems"},
  {max_prefix: "B8-FF-61",vendor: "Apple, Inc."},
  {max_prefix: "30-44-87",vendor: "Hefei Radio Communication Technology Co., Ltd"},
  {max_prefix: "2C-9D-1E",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "94-3D-C9",vendor: "Asahi Net, Inc."},
  {max_prefix: "98-84-E3",vendor: "Texas Instruments"},
  {max_prefix: "38-D2-69",vendor: "Texas Instruments"},
  {max_prefix: "C8-FD-19",vendor: "Texas Instruments"},
  {max_prefix: "50-8C-B1",vendor: "Texas Instruments"},
  {max_prefix: "44-04-44",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "98-F0-58",vendor: "Lynxspring, Incl."},
  {max_prefix: "68-C4-4D",vendor: "Motorola Mobility LLC, a Lenovo Company"},
  {max_prefix: "00-81-C4",vendor: "Cisco Systems, Inc"},
  {max_prefix: "58-E8-76",vendor: "IEEE Registration Authority"},
  {max_prefix: "00-17-7E",vendor: "Meshcom Technologies Inc."},
  {max_prefix: "C4-F5-A5",vendor: "Kumalift Co., Ltd."},
  {max_prefix: "F8-E6-1A",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "84-B5-41",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "00-6F-64",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "DC-66-72",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "EC-8E-B5",vendor: "Hewlett Packard"},
  {max_prefix: "70-AF-6A",vendor: "SHENZHEN FENGLIAN TECHNOLOGY CO., LTD."},
  {max_prefix: "E0-DD-C0",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "AC-9C-E4",vendor: "Alcatel-Lucent Shanghai Bell Co., Ltd"},
  {max_prefix: "00-23-3E",vendor: "Alcatel-Lucent IPD"},
  {max_prefix: "6C-BE-E9",vendor: "Alcatel-Lucent IPD"},
  {max_prefix: "D0-37-42",vendor: "Yulong Computer Telecommunication Scientific (Shenzhen) Co.,Ltd"},
  {max_prefix: "20-F5-43",vendor: "Hui Zhou Gaoshengda Technology Co.,LTD"},
  {max_prefix: "00-17-65",vendor: "Nortel Networks"},
  {max_prefix: "00-15-E8",vendor: "Nortel Networks"},
  {max_prefix: "00-15-9B",vendor: "Nortel Networks"},
  {max_prefix: "00-15-40",vendor: "Nortel Networks"},
  {max_prefix: "00-1E-CA",vendor: "Nortel Networks"},
  {max_prefix: "00-13-0A",vendor: "Nortel Networks"},
  {max_prefix: "00-1F-0A",vendor: "Nortel Networks"},
  {max_prefix: "00-0F-06",vendor: "Nortel Networks"},
  {max_prefix: "C4-04-7B",vendor: "Shenzhen YOUHUA Technology Co., Ltd"},
  {max_prefix: "20-F4-1B",vendor: "Shenzhen Bilian electronic CO.,LTD"},
  {max_prefix: "6C-A8-58",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "A8-4E-3F",vendor: "Hitron Technologies. Inc"},
  {max_prefix: "00-16-4D",vendor: "Alcatel-Lucent IPD"},
  {max_prefix: "00-1A-F0",vendor: "Alcatel-Lucent IPD"},
  {max_prefix: "38-52-1A",vendor: "Nokia"},
  {max_prefix: "00-1E-40",vendor: "Shanghai DareGlobal Technologies Co.,Ltd"},
  {max_prefix: "94-D7-23",vendor: "Shanghai DareGlobal Technologies Co.,Ltd"},
  {max_prefix: "FC-FA-F7",vendor: "Shanghai Baud Data Communication Co.,Ltd."},
  {max_prefix: "D8-26-B9",vendor: "Guangdong Coagent Electronics S&amp;T Co.,Ltd."},
  {max_prefix: "00-22-A9",vendor: "LG Electronics (Mobile Communications)"},
  {max_prefix: "00-25-E5",vendor: "LG Electronics (Mobile Communications)"},
  {max_prefix: "00-21-FB",vendor: "LG Electronics (Mobile Communications)"},
  {max_prefix: "A0-91-C8",vendor: "zte corporation"},
  {max_prefix: "E4-95-6E",vendor: "IEEE Registration Authority"},
  {max_prefix: "B4-37-D1",vendor: "IEEE Registration Authority"},
  {max_prefix: "2C-6A-6F",vendor: "IEEE Registration Authority"},
  {max_prefix: "A4-60-32",vendor: "MRV Communications (Networks) LTD"},
  {max_prefix: "40-66-7A",vendor: "mediola - connected living AG"},
  {max_prefix: "9C-2A-83",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "68-A0-F6",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "84-5D-D7",vendor: "Shenzhen Netcom Electronics Co.,Ltd"},
  {max_prefix: "E8-92-A4",vendor: "LG Electronics (Mobile Communications)"},
  {max_prefix: "10-68-3F",vendor: "LG Electronics (Mobile Communications)"},
  {max_prefix: "40-B0-FA",vendor: "LG Electronics (Mobile Communications)"},
  {max_prefix: "A0-39-F7",vendor: "LG Electronics (Mobile Communications)"},
  {max_prefix: "00-B0-64",vendor: "Cisco Systems, Inc"},
  {max_prefix: "34-FC-EF",vendor: "LG Electronics (Mobile Communications)"},
  {max_prefix: "BC-F5-AC",vendor: "LG Electronics (Mobile Communications)"},
  {max_prefix: "98-D6-F7",vendor: "LG Electronics (Mobile Communications)"},
  {max_prefix: "70-05-14",vendor: "LG Electronics (Mobile Communications)"},
  {max_prefix: "00-55-DA",vendor: "IEEE Registration Authority"},
  {max_prefix: "78-C2-C0",vendor: "IEEE Registration Authority"},
  {max_prefix: "1C-CA-E3",vendor: "IEEE Registration Authority"},
  {max_prefix: "08-D8-33",vendor: "Shenzhen RF Technology Co., Ltd"},
  {max_prefix: "00-19-9D",vendor: "Vizio, Inc"},
  {max_prefix: "00-19-38",vendor: "UMB Communications Co., Ltd."},
  {max_prefix: "44-39-C4",vendor: "Universal Global Scientific Industrial Co., Ltd."},
  {max_prefix: "40-2C-F4",vendor: "Universal Global Scientific Industrial Co., Ltd."},
  {max_prefix: "00-1E-37",vendor: "Universal Global Scientific Industrial Co., Ltd."},
  {max_prefix: "00-1A-6B",vendor: "Universal Global Scientific Industrial Co., Ltd."},
  {max_prefix: "00-16-41",vendor: "Universal Global Scientific Industrial Co., Ltd."},
  {max_prefix: "00-10-C6",vendor: "Universal Global Scientific Industrial Co., Ltd."},
  {max_prefix: "00-24-7E",vendor: "Universal Global Scientific Industrial Co., Ltd."},
  {max_prefix: "00-0E-E8",vendor: "Zioncom Electronics (Shenzhen) Ltd."},
  {max_prefix: "00-C0-95",vendor: "ZNYX Networks, Inc."},
  {max_prefix: "00-20-25",vendor: "CONTROL TECHNOLOGY, INC."},
  {max_prefix: "68-35-63",vendor: "SHENZHEN LIOWN ELECTRONICS CO.,LTD."},
  {max_prefix: "00-40-72",vendor: "Applied Innovation Inc."},
  {max_prefix: "00-E0-8B",vendor: "QLogic Corporation"},
  {max_prefix: "1C-57-D8",vendor: "Kraftway Corporation PLC"},
  {max_prefix: "00-DD-0A",vendor: "UNGERMANN-BASS INC."},
  {max_prefix: "00-25-17",vendor: "Venntis, LLC"},
  {max_prefix: "00-60-0F",vendor: "Westell Technologies Inc."},
  {max_prefix: "00-18-3A",vendor: "Westell Technologies Inc."},
  {max_prefix: "44-6E-E5",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "C8-77-8B",vendor: "Mercury Systems \u2013 Trusted Mission Solutions, Inc."},
  {max_prefix: "00-04-4B",vendor: "NVIDIA"},
  {max_prefix: "D8-EB-97",vendor: "TRENDnet, Inc."},
  {max_prefix: "00-1C-7E",vendor: "Toshiba"},
  {max_prefix: "00-23-18",vendor: "Toshiba"},
  {max_prefix: "B8-6B-23",vendor: "Toshiba"},
  {max_prefix: "00-08-F1",vendor: "Voltaire"},
  {max_prefix: "AC-9B-0A",vendor: "Sony Corporation"},
  {max_prefix: "10-4F-A8",vendor: "Sony Corporation"},
  {max_prefix: "AC-04-0B",vendor: "Peloton Interactive, Inc"},
  {max_prefix: "48-FC-B6",vendor: "LAVA INTERNATIONAL(H.K) LIMITED"},
  {max_prefix: "B0-E2-35",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "40-C7-29",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "14-C9-13",vendor: "LG Electronics"},
  {max_prefix: "D8-E0-B8",vendor: "BULAT LLC"},
  {max_prefix: "60-31-97",vendor: "Zyxel Communications Corporation"},
  {max_prefix: "00-14-B4",vendor: "General Dynamics United Kingdom Ltd"},
  {max_prefix: "A0-B4-37",vendor: " GD Mission Systems"},
  {max_prefix: "E0-98-61",vendor: "Motorola Mobility LLC, a Lenovo Company"},
  {max_prefix: "9C-8E-CD",vendor: "Amcrest Technologies"},
  {max_prefix: "00-04-A3",vendor: "Microchip Technology Inc."},
  {max_prefix: "78-9C-E7",vendor: "Shenzhen Aikede Technology Co., Ltd"},
  {max_prefix: "50-9F-3B",vendor: "OI ELECTRIC CO.,LTD"},
  {max_prefix: "FC-23-25",vendor: "EosTek (Shenzhen) Co., Ltd."},
  {max_prefix: "FC-3D-93",vendor: "LONGCHEER TELECOMMUNICATION LIMITED"},
  {max_prefix: "00-F6-63",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-0B-6B",vendor: "Wistron Neweb Corporation"},
  {max_prefix: "60-02-B4",vendor: "Wistron Neweb Corporation"},
  {max_prefix: "94-DF-4E",vendor: "Wistron InfoComm(Kunshan)Co.,Ltd."},
  {max_prefix: "98-EE-CB",vendor: "Wistron Infocomm (Zhongshan) Corporation"},
  {max_prefix: "C0-C9-76",vendor: "Shenzhen TINNO Mobile Technology Corp."},
  {max_prefix: "58-8B-F3",vendor: "Zyxel Communications Corporation"},
  {max_prefix: "50-67-F0",vendor: "Zyxel Communications Corporation"},
  {max_prefix: "00-13-49",vendor: "Zyxel Communications Corporation"},
  {max_prefix: "8C-6D-50",vendor: "SHENZHEN MTC CO LTD"},
  {max_prefix: "A0-09-ED",vendor: "Avaya Inc"},
  {max_prefix: "68-3E-34",vendor: "MEIZU Technology Co., Ltd."},
  {max_prefix: "00-1B-FE",vendor: "Zavio Inc."},
  {max_prefix: "54-10-EC",vendor: "Microchip Technology Inc."},
  {max_prefix: "A0-60-90",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "BC-76-5E",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "E0-A8-B8",vendor: "Le Shi Zhi Xin Electronic Technology (Tianjin) Limited"},
  {max_prefix: "F4-5B-73",vendor: "Wanjiaan Interconnected Technology Co., Ltd"},
  {max_prefix: "B8-81-98",vendor: "Intel Corporate"},
  {max_prefix: "C8-3D-FC",vendor: "AlphaTheta Corporation"},
  {max_prefix: "CC-D3-1E",vendor: "IEEE Registration Authority"},
  {max_prefix: "34-B3-54",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "6C-0E-E6",vendor: "Chengdu Xiyida Electronic Technology Co,.Ltd"},
  {max_prefix: "00-5F-86",vendor: "Cisco Systems, Inc"},
  {max_prefix: "38-1D-D9",vendor: "FN-LINK TECHNOLOGY LIMITED"},
  {max_prefix: "1C-B9-C4",vendor: "Ruckus Wireless"},
  {max_prefix: "2C-DD-A3",vendor: "Point Grey Research Inc."},
  {max_prefix: "00-80-9F",vendor: "ALE International"},
  {max_prefix: "B8-24-F0",vendor: "SOYO Technology Development Co., Ltd."},
  {max_prefix: "D8-5B-2A",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "FC-A8-9A",vendor: "Sunitec Enterprise Co.,Ltd"},
  {max_prefix: "1C-7B-23",vendor: "Qingdao Hisense Communications Co.,Ltd."},
  {max_prefix: "B0-D7-CC",vendor: "Tridonic GmbH & Co KG"},
  {max_prefix: "8C-59-C3",vendor: "ADB Italia"},
  {max_prefix: "48-C6-63",vendor: "GTO Access Systems LLC"},
  {max_prefix: "1C-6E-76",vendor: "Quarion Technology Inc"},
  {max_prefix: "00-07-63",vendor: "Sunniwell Cyber Tech. Co., Ltd."},
  {max_prefix: "24-0A-11",vendor: "TCT mobile ltd"},
  {max_prefix: "D8-E5-6D",vendor: "TCT mobile ltd"},
  {max_prefix: "54-05-93",vendor: "WOORI ELEC Co.,Ltd"},
  {max_prefix: "C0-2F-F1",vendor: "Volta Networks"},
  {max_prefix: "E8-A7-F2",vendor: "sTraffic"},
  {max_prefix: "00-1F-20",vendor: "Logitech Europe SA"},
  {max_prefix: "00-62-EC",vendor: "Cisco Systems, Inc"},
  {max_prefix: "CC-16-7E",vendor: "Cisco Systems, Inc"},
  {max_prefix: "C4-6A-B7",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "00-0A-ED",vendor: "HARTING Electronics GmbH"},
  {max_prefix: "CC-50-0A",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "D0-46-DC",vendor: "Southwest Research Institute"},
  {max_prefix: "70-A2-B3",vendor: "Apple, Inc."},
  {max_prefix: "E4-1D-2D",vendor: "Mellanox Technologies, Inc."},
  {max_prefix: "F4-0F-24",vendor: "Apple, Inc."},
  {max_prefix: "4C-57-CA",vendor: "Apple, Inc."},
  {max_prefix: "90-C1-C6",vendor: "Apple, Inc."},
  {max_prefix: "0C-DA-41",vendor: "Hangzhou H3C Technologies Co., Limited"},
  {max_prefix: "74-25-8A",vendor: "Hangzhou H3C Technologies Co., Limited"},
  {max_prefix: "74-1F-4A",vendor: "Hangzhou H3C Technologies Co., Limited"},
  {max_prefix: "E4-2F-56",vendor: "OptoMET GmbH"},
  {max_prefix: "F8-DA-0C",vendor: "Hon Hai Precision Ind. Co.,Ltd."},
  {max_prefix: "1C-1B-0D",vendor: "GIGA-BYTE TECHNOLOGY CO.,LTD."},
  {max_prefix: "48-E9-F1",vendor: "Apple, Inc."},
  {max_prefix: "90-38-09",vendor: "Ericsson AB"},
  {max_prefix: "C8-3F-26",vendor: "Microsoft Corporation"},
  {max_prefix: "00-0C-49",vendor: "Dangaard Telecom Denmark A/S"},
  {max_prefix: "A0-B6-62",vendor: "Acutvista Innovation Co., Ltd."},
  {max_prefix: "00-22-38",vendor: "LOGIPLUS"},
  {max_prefix: "34-97-F6",vendor: "ASUSTek COMPUTER INC."},
  {max_prefix: "A0-8C-FD",vendor: "Hewlett Packard"},
  {max_prefix: "50-68-0A",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "00-A0-06",vendor: "IMAGE DATA PROCESSING SYSTEM GROUP"},
  {max_prefix: "80-19-FE",vendor: "JianLing Technology CO., LTD"},
  {max_prefix: "60-B4-F7",vendor: "Plume Design Inc"},
  {max_prefix: "44-65-0D",vendor: "Amazon Technologies Inc."},
  {max_prefix: "60-B3-87",vendor: "Synergics Technologies GmbH"},
  {max_prefix: "A4-D8-CA",vendor: "HONG KONG WATER WORLD TECHNOLOGY CO. LIMITED"},
  {max_prefix: "10-78-D2",vendor: "Elitegroup Computer Systems Co.,Ltd."},
  {max_prefix: "00-21-97",vendor: "Elitegroup Computer Systems Co.,Ltd."},
  {max_prefix: "00-1E-90",vendor: "Elitegroup Computer Systems Co.,Ltd."},
  {max_prefix: "00-22-B1",vendor: "Elbit Systems Ltd."},
  {max_prefix: "00-00-B4",vendor: "Edimax Technology Co. Ltd."},
  {max_prefix: "48-7A-DA",vendor: "Hangzhou H3C Technologies Co., Limited"},
  {max_prefix: "14-0C-5B",vendor: "PLNetworks"},
  {max_prefix: "50-FF-99",vendor: "IEEE Registration Authority"},
  {max_prefix: "84-E3-23",vendor: "Green Wave Telecommunication SDN BHD"},
  {max_prefix: "FC-3F-7C",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "38-4C-4F",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "00-1F-45",vendor: "Enterasys"},
  {max_prefix: "00-0E-03",vendor: "Emulex Corporation"},
  {max_prefix: "00-0D-87",vendor: "Elitegroup Computer Systems Co.,Ltd."},
  {max_prefix: "00-16-8F",vendor: "GN Netcom A/S"},
  {max_prefix: "BC-98-89",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "24-61-5A",vendor: "China Mobile Group Device Co.,Ltd."},
  {max_prefix: "00-CA-E5",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-42-68",vendor: "Cisco Systems, Inc"},
  {max_prefix: "48-83-C7",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "40-16-3B",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "04-A3-16",vendor: "Texas Instruments"},
  {max_prefix: "D4-F2-07",vendor: "DIAODIAO(Beijing)Technology CO.,Ltd"},
  {max_prefix: "D4-AD-2D",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "F0-8C-FB",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "48-55-5F",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "40-5E-E1",vendor: "Shenzhen H&T Intelligent Control Co.,Ltd."},
  {max_prefix: "00-25-78",vendor: "JSC Concern Sozvezdie"},
  {max_prefix: "30-B4-9E",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "34-E7-0B",vendor: "HAN Networks Co., Ltd"},
  {max_prefix: "00-78-88",vendor: "Cisco Systems, Inc"},
  {max_prefix: "FC-08-4A",vendor: "FUJITSU LIMITED"},
  {max_prefix: "0C-BF-3F",vendor: "Shenzhen Lencotion Technology Co.,Ltd"},
  {max_prefix: "90-03-25",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "98-E7-F5",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "00-17-06",vendor: "Techfaithwireless Communication Technology Limited."},
  {max_prefix: "A0-9D-91",vendor: "SoundBridge"},
  {max_prefix: "40-B6-88",vendor: "LEGIC Identsystems AG"},
  {max_prefix: "9C-D4-8B",vendor: "Innolux Technology Europe BV"},
  {max_prefix: "08-5B-DA",vendor: "CliniCare LTD"},
  {max_prefix: "1C-C0-35",vendor: "PLANEX COMMUNICATIONS INC."},
  {max_prefix: "34-54-3C",vendor: "TAKAOKA TOKO CO.,LTD."},
  {max_prefix: "9C-9D-5D",vendor: "Raden Inc"},
  {max_prefix: "DC-4D-23",vendor: "MRV Comunications"},
  {max_prefix: "00-23-B3",vendor: "Lyyn AB"},
  {max_prefix: "24-8A-07",vendor: "Mellanox Technologies, Inc."},
  {max_prefix: "C8-38-70",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "1C-55-3A",vendor: "QianGua Corp."},
  {max_prefix: "00-8E-73",vendor: "Cisco Systems, Inc"},
  {max_prefix: "40-2E-28",vendor: "MiXTelematics"},
  {max_prefix: "58-32-77",vendor: "Reliance Communications LLC"},
  {max_prefix: "6C-8F-B5",vendor: "Microsoft Mobile Oy"},
  {max_prefix: "00-15-C1",vendor: "Sony Interactive Entertainment Inc."},
  {max_prefix: "90-A6-2F",vendor: "NAVER"},
  {max_prefix: "1C-9E-46",vendor: "Apple, Inc."},
  {max_prefix: "9C-4F-DA",vendor: "Apple, Inc."},
  {max_prefix: "00-35-1A",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-AF-1F",vendor: "Cisco Systems, Inc"},
  {max_prefix: "C0-CC-F8",vendor: "Apple, Inc."},
  {max_prefix: "C4-E5-10",vendor: "Mechatro, Inc."},
  {max_prefix: "00-21-FC",vendor: "Nokia Danmark A/S"},
  {max_prefix: "00-1F-5D",vendor: "Nokia Danmark A/S"},
  {max_prefix: "00-1F-01",vendor: "Nokia Danmark A/S"},
  {max_prefix: "00-1B-EE",vendor: "Nokia Danmark A/S"},
  {max_prefix: "80-38-96",vendor: "SHARP Corporation"},
  {max_prefix: "84-89-AD",vendor: "Apple, Inc."},
  {max_prefix: "34-7E-39",vendor: "Nokia Danmark A/S"},
  {max_prefix: "00-19-79",vendor: "Nokia Danmark A/S"},
  {max_prefix: "00-60-EC",vendor: "HERMARY OPTO ELECTRONICS INC."},
  {max_prefix: "DC-3A-5E",vendor: "Roku, Inc."},
  {max_prefix: "00-50-7F",vendor: "DrayTek Corp."},
  {max_prefix: "00-1A-73",vendor: "Gemtek Technology Co., Ltd."},
  {max_prefix: "00-90-4B",vendor: "Gemtek Technology Co., Ltd."},
  {max_prefix: "00-1A-7F",vendor: "GCI Science & Technology Co.,LTD"},
  {max_prefix: "00-24-D4",vendor: "FREEBOX SAS"},
  {max_prefix: "AC-3A-7A",vendor: "Roku, Inc."},
  {max_prefix: "B8-3E-59",vendor: "Roku, Inc."},
  {max_prefix: "54-44-08",vendor: "Nokia Corporation"},
  {max_prefix: "3C-C2-43",vendor: "Nokia Corporation"},
  {max_prefix: "00-90-A2",vendor: "CyberTAN Technology Inc."},
  {max_prefix: "00-90-D6",vendor: "Crystal Group, Inc."},
  {max_prefix: "64-77-91",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "9C-E6-E7",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "9C-02-98",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "28-98-7B",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "0C-71-5D",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "00-18-0F",vendor: "Nokia Danmark A/S"},
  {max_prefix: "C8-97-9F",vendor: "Nokia Corporation"},
  {max_prefix: "EC-F3-5B",vendor: "Nokia Corporation"},
  {max_prefix: "00-25-D0",vendor: "Nokia Danmark A/S"},
  {max_prefix: "7C-1C-F1",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "78-F5-57",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "E0-28-61",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "D0-D0-4B",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "48-00-31",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "4C-09-D4",vendor: "Arcadyan Technology Corporation"},
  {max_prefix: "9C-80-DF",vendor: "Arcadyan Technology Corporation"},
  {max_prefix: "00-23-08",vendor: "Arcadyan Technology Corporation"},
  {max_prefix: "88-03-55",vendor: "Arcadyan Technology Corporation"},
  {max_prefix: "34-BB-1F",vendor: "BlackBerry RTS"},
  {max_prefix: "40-6F-2A",vendor: "BlackBerry RTS"},
  {max_prefix: "D4-76-EA",vendor: "zte corporation"},
  {max_prefix: "00-17-5A",vendor: "Cisco Systems, Inc"},
  {max_prefix: "54-FA-3E",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "0C-89-10",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "78-AB-BB",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "D8-C4-E9",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "BC-D1-1F",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "F4-42-8F",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "08-96-D7",vendor: "AVM GmbH"},
  {max_prefix: "50-6A-03",vendor: "NETGEAR"},
  {max_prefix: "44-6D-6C",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "00-F4-6F",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "00-10-18",vendor: "Broadcom"},
  {max_prefix: "18-C0-86",vendor: "Broadcom"},
  {max_prefix: "FC-B4-E6",vendor: "ASKEY COMPUTER CORP"},
  {max_prefix: "C8-FF-28",vendor: "Liteon Technology Corporation"},
  {max_prefix: "E0-46-9A",vendor: "NETGEAR"},
  {max_prefix: "30-46-9A",vendor: "NETGEAR"},
  {max_prefix: "10-0D-7F",vendor: "NETGEAR"},
  {max_prefix: "50-4A-6E",vendor: "NETGEAR"},
  {max_prefix: "00-22-F4",vendor: "AMPAK Technology, Inc."},
  {max_prefix: "00-1D-BA",vendor: "Sony Corporation"},
  {max_prefix: "00-24-BE",vendor: "Sony Corporation"},
  {max_prefix: "00-0F-DE",vendor: "Sony Corporation"},
  {max_prefix: "F8-D0-AC",vendor: "Sony Interactive Entertainment Inc."},
  {max_prefix: "0C-FE-45",vendor: "Sony Interactive Entertainment Inc."},
  {max_prefix: "20-16-D8",vendor: "Liteon Technology Corporation"},
  {max_prefix: "E8-61-7E",vendor: "Liteon Technology Corporation"},
  {max_prefix: "18-CF-5E",vendor: "Liteon Technology Corporation"},
  {max_prefix: "00-D0-C9",vendor: "ADVANTECH CO., LTD."},
  {max_prefix: "64-87-D7",vendor: "ADB Broadband Italia"},
  {max_prefix: "38-22-9D",vendor: "ADB Broadband Italia"},
  {max_prefix: "A4-52-6F",vendor: "ADB Broadband Italia"},
  {max_prefix: "74-88-8B",vendor: "ADB Broadband Italia"},
  {max_prefix: "00-8C-54",vendor: "ADB Broadband Italia"},
  {max_prefix: "F0-27-2D",vendor: "Amazon Technologies Inc."},
  {max_prefix: "84-D6-D0",vendor: "Amazon Technologies Inc."},
  {max_prefix: "04-E6-76",vendor: "AMPAK Technology, Inc."},
  {max_prefix: "18-FE-34",vendor: "Espressif Inc."},
  {max_prefix: "B8-F9-34",vendor: "Sony Corporation"},
  {max_prefix: "8C-64-22",vendor: "Sony Corporation"},
  {max_prefix: "E0-63-E5",vendor: "Sony Corporation"},
  {max_prefix: "00-1B-59",vendor: "Sony Corporation"},
  {max_prefix: "00-22-98",vendor: "Sony Corporation"},
  {max_prefix: "24-21-AB",vendor: "Sony Corporation"},
  {max_prefix: "00-00-6E",vendor: "Artisoft Inc."},
  {max_prefix: "00-DA-55",vendor: "Cisco Systems, Inc"},
  {max_prefix: "98-5F-D3",vendor: "Microsoft Corporation"},
  {max_prefix: "00-10-B5",vendor: "Accton Technology Corp"},
  {max_prefix: "00-19-74",vendor: "16063"},
  {max_prefix: "E0-B2-F1",vendor: "FN-LINK TECHNOLOGY LIMITED"},
  {max_prefix: "0C-4C-39",vendor: "MitraStar Technology Corp."},
  {max_prefix: "00-22-43",vendor: "AzureWave Technology Inc."},
  {max_prefix: "18-E3-BC",vendor: "TCT mobile ltd"},
  {max_prefix: "CC-1F-C4",vendor: "InVue"},
  {max_prefix: "60-5B-B4",vendor: "AzureWave Technology Inc."},
  {max_prefix: "64-D9-54",vendor: "Taicang T&W Electronics"},
  {max_prefix: "5C-36-B8",vendor: "TCL King Electrical Appliances (Huizhou) Co., Ltd"},
  {max_prefix: "00-AA-01",vendor: "Intel Corporation"},
  {max_prefix: "84-A6-C8",vendor: "Intel Corporate"},
  {max_prefix: "58-91-CF",vendor: "Intel Corporate"},
  {max_prefix: "0C-8B-FD",vendor: "Intel Corporate"},
  {max_prefix: "84-3A-4B",vendor: "Intel Corporate"},
  {max_prefix: "5C-51-4F",vendor: "Intel Corporate"},
  {max_prefix: "A4-4E-31",vendor: "Intel Corporate"},
  {max_prefix: "4C-EB-42",vendor: "Intel Corporate"},
  {max_prefix: "00-AA-00",vendor: "Intel Corporation"},
  {max_prefix: "00-C2-C6",vendor: "Intel Corporate"},
  {max_prefix: "5C-D2-E4",vendor: "Intel Corporate"},
  {max_prefix: "28-B2-BD",vendor: "Intel Corporate"},
  {max_prefix: "4C-79-BA",vendor: "Intel Corporate"},
  {max_prefix: "F8-16-54",vendor: "Intel Corporate"},
  {max_prefix: "60-6C-66",vendor: "Intel Corporate"},
  {max_prefix: "4C-80-93",vendor: "Intel Corporate"},
  {max_prefix: "AC-72-89",vendor: "Intel Corporate"},
  {max_prefix: "44-85-00",vendor: "Intel Corporate"},
  {max_prefix: "0C-D2-92",vendor: "Intel Corporate"},
  {max_prefix: "00-24-7B",vendor: "Actiontec Electronics, Inc"},
  {max_prefix: "00-04-E3",vendor: "Accton Technology Corp"},
  {max_prefix: "44-87-23",vendor: "HOYA SERVICE CORPORATION"},
  {max_prefix: "D8-6C-02",vendor: "Huaqin Telecom Technology Co.,Ltd"},
  {max_prefix: "60-BE-B5",vendor: "Motorola Mobility LLC, a Lenovo Company"},
  {max_prefix: "F8-F1-B6",vendor: "Motorola Mobility LLC, a Lenovo Company"},
  {max_prefix: "F4-F1-E1",vendor: "Motorola Mobility LLC, a Lenovo Company"},
  {max_prefix: "9C-D9-17",vendor: "Motorola Mobility LLC, a Lenovo Company"},
  {max_prefix: "90-68-C3",vendor: "Motorola Mobility LLC, a Lenovo Company"},
  {max_prefix: "68-5D-43",vendor: "Intel Corporate"},
  {max_prefix: "A0-36-9F",vendor: "Intel Corporate"},
  {max_prefix: "64-D4-DA",vendor: "Intel Corporate"},
  {max_prefix: "40-25-C2",vendor: "Intel Corporate"},
  {max_prefix: "50-2D-A2",vendor: "Intel Corporate"},
  {max_prefix: "78-92-9C",vendor: "Intel Corporate"},
  {max_prefix: "DC-A9-71",vendor: "Intel Corporate"},
  {max_prefix: "58-94-6B",vendor: "Intel Corporate"},
  {max_prefix: "3C-FD-FE",vendor: "Intel Corporate"},
  {max_prefix: "A4-C4-94",vendor: "Intel Corporate"},
  {max_prefix: "90-2E-1C",vendor: "Intel Corporate"},
  {max_prefix: "A4-34-D9",vendor: "Intel Corporate"},
  {max_prefix: "00-24-D7",vendor: "Intel Corporate"},
  {max_prefix: "00-24-D6",vendor: "Intel Corporate"},
  {max_prefix: "00-1D-E0",vendor: "Intel Corporate"},
  {max_prefix: "A0-A8-CD",vendor: "Intel Corporate"},
  {max_prefix: "5C-C5-D4",vendor: "Intel Corporate"},
  {max_prefix: "00-1E-64",vendor: "Intel Corporate"},
  {max_prefix: "00-21-5C",vendor: "Intel Corporate"},
  {max_prefix: "00-21-6B",vendor: "Intel Corporate"},
  {max_prefix: "00-22-FB",vendor: "Intel Corporate"},
  {max_prefix: "00-15-17",vendor: "Intel Corporate"},
  {max_prefix: "34-EF-44",vendor: "2Wire Inc"},
  {max_prefix: "B0-E7-54",vendor: "2Wire Inc"},
  {max_prefix: "B8-E6-25",vendor: "2Wire Inc"},
  {max_prefix: "00-1D-5A",vendor: "2Wire Inc"},
  {max_prefix: "00-25-3C",vendor: "2Wire Inc"},
  {max_prefix: "3C-19-7D",vendor: "Ericsson AB"},
  {max_prefix: "24-7C-4C",vendor: "Herman Miller"},
  {max_prefix: "E4-6F-13",vendor: "D-Link International"},
  {max_prefix: "1C-44-19",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "5C-35-3B",vendor: "Compal Broadband Networks, Inc."},
  {max_prefix: "F8-E7-1E",vendor: "Ruckus Wireless"},
  {max_prefix: "08-86-3B",vendor: "Belkin International Inc."},
  {max_prefix: "90-01-3B",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "7C-03-4C",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "6C-2E-85",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "94-FE-F4",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "28-FA-A0",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "EC-DF-3A",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "F4-29-81",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "84-F6-FA",vendor: "Miovision Technologies Incorporated"},
  {max_prefix: "2C-56-DC",vendor: "ASUSTek COMPUTER INC."},
  {max_prefix: "00-60-4C",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "00-1F-95",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "00-23-48",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "00-26-91",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "98-8B-5D",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "78-DE-E4",vendor: "Texas Instruments"},
  {max_prefix: "00-18-33",vendor: "Texas Instruments"},
  {max_prefix: "00-18-34",vendor: "Texas Instruments"},
  {max_prefix: "00-17-E3",vendor: "Texas Instruments"},
  {max_prefix: "00-18-30",vendor: "Texas Instruments"},
  {max_prefix: "00-23-D4",vendor: "Texas Instruments"},
  {max_prefix: "C0-E4-22",vendor: "Texas Instruments"},
  {max_prefix: "D0-07-90",vendor: "Texas Instruments"},
  {max_prefix: "3C-7D-B1",vendor: "Texas Instruments"},
  {max_prefix: "00-17-83",vendor: "Texas Instruments"},
  {max_prefix: "F4-FC-32",vendor: "Texas Instruments"},
  {max_prefix: "90-D7-EB",vendor: "Texas Instruments"},
  {max_prefix: "00-F8-71",vendor: "Demant A/S"},
  {max_prefix: "34-B1-F7",vendor: "Texas Instruments"},
  {max_prefix: "2C-FD-37",vendor: "Blue Calypso, Inc."},
  {max_prefix: "0C-61-27",vendor: "Actiontec Electronics, Inc"},
  {max_prefix: "2C-30-33",vendor: "NETGEAR"},
  {max_prefix: "78-54-2E",vendor: "D-Link International"},
  {max_prefix: "C4-A8-1D",vendor: "D-Link International"},
  {max_prefix: "24-35-CC",vendor: "Zhongshan Scinan Internet of Things Co.,Ltd."},
  {max_prefix: "F8-8F-CA",vendor: "Google, Inc."},
  {max_prefix: "00-21-91",vendor: "D-Link Corporation"},
  {max_prefix: "AC-F1-DF",vendor: "D-Link International"},
  {max_prefix: "BC-F6-85",vendor: "D-Link International"},
  {max_prefix: "3C-D9-2B",vendor: "Hewlett Packard"},
  {max_prefix: "BC-44-34",vendor: "Shenzhen TINNO Mobile Technology Corp."},
  {max_prefix: "04-BF-6D",vendor: "Zyxel Communications Corporation"},
  {max_prefix: "CC-46-D6",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-41-D2",vendor: "Cisco Systems, Inc"},
  {max_prefix: "2C-AB-00",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "A8-CA-7B",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "FC-FF-AA",vendor: "IEEE Registration Authority"},
  {max_prefix: "38-98-D8",vendor: "MERITECH CO.,LTD"},
  {max_prefix: "94-86-CD",vendor: "SEOUL ELECTRONICS&TELECOM"},
  {max_prefix: "88-96-B6",vendor: "Global Fire Equipment S.A."},
  {max_prefix: "88-B8-D0",vendor: "Dongguan Koppo Electronic Co.,Ltd"},
  {max_prefix: "58-AC-78",vendor: "Cisco Systems, Inc"},
  {max_prefix: "84-AC-FB",vendor: "Crouzet Automatismes"},
  {max_prefix: "7C-BB-8A",vendor: "Nintendo Co., Ltd."},
  {max_prefix: "38-97-D6",vendor: "Hangzhou H3C Technologies Co., Limited"},
  {max_prefix: "1C-A7-70",vendor: "SHENZHEN CHUANGWEI-RGB ELECTRONICS CO.,LTD"},
  {max_prefix: "0C-D7-46",vendor: "Apple, Inc."},
  {max_prefix: "60-A3-7D",vendor: "Apple, Inc."},
  {max_prefix: "68-DB-CA",vendor: "Apple, Inc."},
  {max_prefix: "08-66-98",vendor: "Apple, Inc."},
  {max_prefix: "BC-54-36",vendor: "Apple, Inc."},
  {max_prefix: "04-4B-ED",vendor: "Apple, Inc."},
  {max_prefix: "6C-8D-C1",vendor: "Apple, Inc."},
  {max_prefix: "00-0D-0B",vendor: "BUFFALO.INC"},
  {max_prefix: "00-1D-73",vendor: "BUFFALO.INC"},
  {max_prefix: "00-16-01",vendor: "BUFFALO.INC"},
  {max_prefix: "74-03-BD",vendor: "BUFFALO.INC"},
  {max_prefix: "B8-FC-9A",vendor: "Le Shi Zhi Xin Electronic Technology (Tianjin) Limited"},
  {max_prefix: "00-04-23",vendor: "Intel Corporation"},
  {max_prefix: "00-11-11",vendor: "Intel Corporation"},
  {max_prefix: "00-13-02",vendor: "Intel Corporate"},
  {max_prefix: "78-0A-C7",vendor: "Baofeng TV Co., Ltd."},
  {max_prefix: "00-24-81",vendor: "Hewlett Packard"},
  {max_prefix: "00-0F-61",vendor: "Hewlett Packard"},
  {max_prefix: "00-14-C2",vendor: "Hewlett Packard"},
  {max_prefix: "00-80-5F",vendor: "Hewlett Packard"},
  {max_prefix: "28-80-23",vendor: "Hewlett Packard"},
  {max_prefix: "00-18-FE",vendor: "Hewlett Packard"},
  {max_prefix: "00-1A-4B",vendor: "Hewlett Packard"},
  {max_prefix: "A4-51-6F",vendor: "Microsoft Mobile Oy"},
  {max_prefix: "FC-64-BA",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "8C-AB-8E",vendor: "Shanghai Feixun Communication Co.,Ltd."},
  {max_prefix: "D4-0B-1A",vendor: "HTC Corporation"},
  {max_prefix: "94-53-30",vendor: "Hon Hai Precision Ind. Co.,Ltd."},
  {max_prefix: "A0-8D-16",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "4C-D0-8A",vendor: "HUMAX Co., Ltd."},
  {max_prefix: "CC-4E-EC",vendor: "HUMAX Co., Ltd."},
  {max_prefix: "40-3D-EC",vendor: "HUMAX Co., Ltd."},
  {max_prefix: "EC-4D-47",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "C4-40-44",vendor: "RackTop Systems Inc."},
  {max_prefix: "4C-A1-61",vendor: "Rain Bird Corporation"},
  {max_prefix: "CC-3E-5F",vendor: "Hewlett Packard"},
  {max_prefix: "D8-9D-67",vendor: "Hewlett Packard"},
  {max_prefix: "A4-5D-36",vendor: "Hewlett Packard"},
  {max_prefix: "F0-92-1C",vendor: "Hewlett Packard"},
  {max_prefix: "A0-48-1C",vendor: "Hewlett Packard"},
  {max_prefix: "A0-1D-48",vendor: "Hewlett Packard"},
  {max_prefix: "40-A8-F0",vendor: "Hewlett Packard"},
  {max_prefix: "88-51-FB",vendor: "Hewlett Packard"},
  {max_prefix: "90-60-F1",vendor: "Apple, Inc."},
  {max_prefix: "54-27-58",vendor: "Motorola (Wuhan) Mobility Technologies Communication Co., Ltd."},
  {max_prefix: "08-2E-5F",vendor: "Hewlett Packard"},
  {max_prefix: "E4-11-5B",vendor: "Hewlett Packard"},
  {max_prefix: "28-92-4A",vendor: "Hewlett Packard"},
  {max_prefix: "48-0F-CF",vendor: "Hewlett Packard"},
  {max_prefix: "00-24-2B",vendor: "Hon Hai Precision Ind. Co.,Ltd."},
  {max_prefix: "1C-99-4C",vendor: "Murata Manufacturing Co., Ltd."},
  {max_prefix: "F0-27-65",vendor: "Murata Manufacturing Co., Ltd."},
  {max_prefix: "5C-F8-A1",vendor: "Murata Manufacturing Co., Ltd."},
  {max_prefix: "44-A7-CF",vendor: "Murata Manufacturing Co., Ltd."},
  {max_prefix: "00-13-E0",vendor: "Murata Manufacturing Co., Ltd."},
  {max_prefix: "90-FB-A6",vendor: "Hon Hai Precision Ind. Co.,Ltd."},
  {max_prefix: "44-37-E6",vendor: "Hon Hai Precision Ind. Co.,Ltd."},
  {max_prefix: "CC-AF-78",vendor: "Hon Hai Precision Ind. Co.,Ltd."},
  {max_prefix: "F4-B7-E2",vendor: "Hon Hai Precision Ind. Co.,Ltd."},
  {max_prefix: "78-59-68",vendor: "Hon Hai Precision Ind. Co.,Ltd."},
  {max_prefix: "08-18-1A",vendor: "zte corporation"},
  {max_prefix: "00-1E-73",vendor: "zte corporation"},
  {max_prefix: "00-15-EB",vendor: "zte corporation"},
  {max_prefix: "00-1C-25",vendor: "Hon Hai Precision Ind. Co.,Ltd."},
  {max_prefix: "00-19-7E",vendor: "Hon Hai Precision Ind. Co.,Ltd."},
  {max_prefix: "EC-26-CA",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "94-71-AC",vendor: "TCT mobile ltd"},
  {max_prefix: "2C-08-8C",vendor: "HUMAX Co., Ltd."},
  {max_prefix: "00-04-80",vendor: "Brocade Communications Systems LLC"},
  {max_prefix: "00-0C-DB",vendor: "Brocade Communications Systems LLC"},
  {max_prefix: "64-70-02",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "10-FE-ED",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "64-56-01",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "F8-D1-11",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "B0-48-7A",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "94-0C-6D",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "00-1B-ED",vendor: "Brocade Communications Systems LLC"},
  {max_prefix: "00-05-33",vendor: "Brocade Communications Systems LLC"},
  {max_prefix: "00-60-69",vendor: "Brocade Communications Systems LLC"},
  {max_prefix: "00-60-DF",vendor: "Brocade Communications Systems LLC"},
  {max_prefix: "00-00-88",vendor: "Brocade Communications Systems LLC"},
  {max_prefix: "EC-CB-30",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "F4-DC-F9",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "C8-D1-5E",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "F4-55-9C",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "80-B6-86",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "10-C6-1F",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "CC-96-A0",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "00-66-4B",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "9C-C1-72",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "24-7F-3C",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "58-1F-28",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "C0-70-09",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "30-87-30",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "C0-57-BC",vendor: "Avaya Inc"},
  {max_prefix: "80-38-BC",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "C4-07-2F",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "F4-8E-92",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "24-1F-A0",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "34-CD-BE",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "D8-49-0B",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "F8-01-13",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "A4-99-47",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "64-A7-DD",vendor: "Avaya Inc"},
  {max_prefix: "A4-25-1B",vendor: "Avaya Inc"},
  {max_prefix: "64-6A-52",vendor: "Avaya Inc"},
  {max_prefix: "44-32-2A",vendor: "Avaya Inc"},
  {max_prefix: "70-38-EE",vendor: "Avaya Inc"},
  {max_prefix: "70-30-18",vendor: "Avaya Inc"},
  {max_prefix: "9C-28-EF",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "00-90-0C",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-90-5F",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-10-0B",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-17-3B",vendor: "Cisco Systems, Inc"},
  {max_prefix: "08-00-28",vendor: "Texas Instruments"},
  {max_prefix: "40-5F-C2",vendor: "Texas Instruments"},
  {max_prefix: "20-CD-39",vendor: "Texas Instruments"},
  {max_prefix: "B4-99-4C",vendor: "Texas Instruments"},
  {max_prefix: "68-DF-DD",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "98-FA-E3",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "F0-B4-29",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "70-54-F5",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "90-17-AC",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "18-C5-8A",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "00-60-83",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-60-09",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-06-7C",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-E0-F7",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-10-7B",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-50-E2",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-90-A6",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-90-86",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-50-80",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-50-73",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-1B-D7",vendor: "Cisco SPVTG"},
  {max_prefix: "E4-D3-F1",vendor: "Cisco Systems, Inc"},
  {max_prefix: "EC-24-B8",vendor: "Texas Instruments"},
  {max_prefix: "7C-EC-79",vendor: "Texas Instruments"},
  {max_prefix: "68-9E-19",vendor: "Texas Instruments"},
  {max_prefix: "E0-E5-CF",vendor: "Texas Instruments"},
  {max_prefix: "1C-87-2C",vendor: "ASUSTek COMPUTER INC."},
  {max_prefix: "60-18-2E",vendor: "ShenZhen Protruly Electronic Ltd co."},
  {max_prefix: "C4-14-3C",vendor: "Cisco Systems, Inc"},
  {max_prefix: "3C-08-F6",vendor: "Cisco Systems, Inc"},
  {max_prefix: "50-1C-BF",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-1E-8C",vendor: "ASUSTek COMPUTER INC."},
  {max_prefix: "00-13-D4",vendor: "ASUSTek COMPUTER INC."},
  {max_prefix: "20-CF-30",vendor: "ASUSTek COMPUTER INC."},
  {max_prefix: "00-24-8C",vendor: "ASUSTek COMPUTER INC."},
  {max_prefix: "00-23-54",vendor: "ASUSTek COMPUTER INC."},
  {max_prefix: "BC-16-65",vendor: "Cisco Systems, Inc"},
  {max_prefix: "F8-72-EA",vendor: "Cisco Systems, Inc"},
  {max_prefix: "D0-C7-89",vendor: "Cisco Systems, Inc"},
  {max_prefix: "F8-4F-57",vendor: "Cisco Systems, Inc"},
  {max_prefix: "7C-69-F6",vendor: "Cisco Systems, Inc"},
  {max_prefix: "84-78-AC",vendor: "Cisco Systems, Inc"},
  {max_prefix: "04-DA-D2",vendor: "Cisco Systems, Inc"},
  {max_prefix: "F0-29-29",vendor: "Cisco Systems, Inc"},
  {max_prefix: "20-BB-C0",vendor: "Cisco Systems, Inc"},
  {max_prefix: "4C-4E-35",vendor: "Cisco Systems, Inc"},
  {max_prefix: "B0-00-B4",vendor: "Cisco Systems, Inc"},
  {max_prefix: "54-4A-00",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-E1-6D",vendor: "Cisco Systems, Inc"},
  {max_prefix: "E0-89-9D",vendor: "Cisco Systems, Inc"},
  {max_prefix: "C4-72-95",vendor: "Cisco Systems, Inc"},
  {max_prefix: "E0-D1-73",vendor: "Cisco Systems, Inc"},
  {max_prefix: "78-DA-6E",vendor: "Cisco Systems, Inc"},
  {max_prefix: "78-BA-F9",vendor: "Cisco Systems, Inc"},
  {max_prefix: "1C-6E-4C",vendor: "Logistic Service & Engineering Co.,Ltd"},
  {max_prefix: "34-BD-C8",vendor: "Cisco Systems, Inc"},
  {max_prefix: "B8-78-2E",vendor: "Apple, Inc."},
  {max_prefix: "00-05-02",vendor: "Apple, Inc."},
  {max_prefix: "00-0A-95",vendor: "Apple, Inc."},
  {max_prefix: "E8-06-88",vendor: "Apple, Inc."},
  {max_prefix: "7C-C5-37",vendor: "Apple, Inc."},
  {max_prefix: "78-CA-39",vendor: "Apple, Inc."},
  {max_prefix: "18-E7-F4",vendor: "Apple, Inc."},
  {max_prefix: "70-CD-60",vendor: "Apple, Inc."},
  {max_prefix: "E4-AA-5D",vendor: "Cisco Systems, Inc"},
  {max_prefix: "DC-EB-94",vendor: "Cisco Systems, Inc"},
  {max_prefix: "84-B5-17",vendor: "Cisco Systems, Inc"},
  {max_prefix: "18-8B-9D",vendor: "Cisco Systems, Inc"},
  {max_prefix: "24-37-4C",vendor: "Cisco SPVTG"},
  {max_prefix: "F4-5F-D4",vendor: "Cisco SPVTG"},
  {max_prefix: "2C-AB-A4",vendor: "Cisco SPVTG"},
  {max_prefix: "00-22-CE",vendor: "Cisco SPVTG"},
  {max_prefix: "00-0F-66",vendor: "Cisco-Linksys, LLC"},
  {max_prefix: "00-26-4A",vendor: "Apple, Inc."},
  {max_prefix: "04-1E-64",vendor: "Apple, Inc."},
  {max_prefix: "90-84-0D",vendor: "Apple, Inc."},
  {max_prefix: "00-11-24",vendor: "Apple, Inc."},
  {max_prefix: "00-22-41",vendor: "Apple, Inc."},
  {max_prefix: "88-CB-87",vendor: "Apple, Inc."},
  {max_prefix: "68-5B-35",vendor: "Apple, Inc."},
  {max_prefix: "2C-B4-3A",vendor: "Apple, Inc."},
  {max_prefix: "68-9C-70",vendor: "Apple, Inc."},
  {max_prefix: "38-0F-4A",vendor: "Apple, Inc."},
  {max_prefix: "30-10-E4",vendor: "Apple, Inc."},
  {max_prefix: "A8-86-DD",vendor: "Apple, Inc."},
  {max_prefix: "F0-C1-F1",vendor: "Apple, Inc."},
  {max_prefix: "B4-F0-AB",vendor: "Apple, Inc."},
  {max_prefix: "80-92-9F",vendor: "Apple, Inc."},
  {max_prefix: "9C-04-EB",vendor: "Apple, Inc."},
  {max_prefix: "5C-96-9D",vendor: "Apple, Inc."},
  {max_prefix: "A8-96-8A",vendor: "Apple, Inc."},
  {max_prefix: "D8-9E-3F",vendor: "Apple, Inc."},
  {max_prefix: "B8-C7-5D",vendor: "Apple, Inc."},
  {max_prefix: "0C-74-C2",vendor: "Apple, Inc."},
  {max_prefix: "40-30-04",vendor: "Apple, Inc."},
  {max_prefix: "74-E2-F5",vendor: "Apple, Inc."},
  {max_prefix: "E0-C9-7A",vendor: "Apple, Inc."},
  {max_prefix: "44-4C-0C",vendor: "Apple, Inc."},
  {max_prefix: "F4-1B-A1",vendor: "Apple, Inc."},
  {max_prefix: "04-15-52",vendor: "Apple, Inc."},
  {max_prefix: "CC-78-5F",vendor: "Apple, Inc."},
  {max_prefix: "70-73-CB",vendor: "Apple, Inc."},
  {max_prefix: "EC-85-2F",vendor: "Apple, Inc."},
  {max_prefix: "00-F4-B9",vendor: "Apple, Inc."},
  {max_prefix: "60-C5-47",vendor: "Apple, Inc."},
  {max_prefix: "68-A8-6D",vendor: "Apple, Inc."},
  {max_prefix: "7C-C3-A1",vendor: "Apple, Inc."},
  {max_prefix: "5C-95-AE",vendor: "Apple, Inc."},
  {max_prefix: "84-29-99",vendor: "Apple, Inc."},
  {max_prefix: "8C-7B-9D",vendor: "Apple, Inc."},
  {max_prefix: "98-03-D8",vendor: "Apple, Inc."},
  {max_prefix: "60-D9-C7",vendor: "Apple, Inc."},
  {max_prefix: "3C-AB-8E",vendor: "Apple, Inc."},
  {max_prefix: "60-92-17",vendor: "Apple, Inc."},
  {max_prefix: "84-B1-53",vendor: "Apple, Inc."},
  {max_prefix: "E0-66-78",vendor: "Apple, Inc."},
  {max_prefix: "48-D7-05",vendor: "Apple, Inc."},
  {max_prefix: "90-8D-6C",vendor: "Apple, Inc."},
  {max_prefix: "B8-09-8A",vendor: "Apple, Inc."},
  {max_prefix: "4C-7C-5F",vendor: "Apple, Inc."},
  {max_prefix: "68-64-4B",vendor: "Apple, Inc."},
  {max_prefix: "C8-1E-E7",vendor: "Apple, Inc."},
  {max_prefix: "A4-31-35",vendor: "Apple, Inc."},
  {max_prefix: "68-D9-3C",vendor: "Apple, Inc."},
  {max_prefix: "00-F7-6F",vendor: "Apple, Inc."},
  {max_prefix: "C8-85-50",vendor: "Apple, Inc."},
  {max_prefix: "70-14-A6",vendor: "Apple, Inc."},
  {max_prefix: "98-5A-EB",vendor: "Apple, Inc."},
  {max_prefix: "78-D7-5F",vendor: "Apple, Inc."},
  {max_prefix: "84-38-35",vendor: "Apple, Inc."},
  {max_prefix: "8C-00-6D",vendor: "Apple, Inc."},
  {max_prefix: "90-72-40",vendor: "Apple, Inc."},
  {max_prefix: "E0-B5-2D",vendor: "Apple, Inc."},
  {max_prefix: "6C-94-F8",vendor: "Apple, Inc."},
  {max_prefix: "F8-27-93",vendor: "Apple, Inc."},
  {max_prefix: "C0-CE-CD",vendor: "Apple, Inc."},
  {max_prefix: "F4-4B-2A",vendor: "Cisco SPVTG"},
  {max_prefix: "74-6F-19",vendor: "ICARVISIONS (SHENZHEN) TECHNOLOGY CO., LTD."},
  {max_prefix: "A0-F9-E0",vendor: "VIVATEL COMPANY LIMITED"},
  {max_prefix: "2C-AE-2B",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "C4-AD-F1",vendor: "GOPEACE Inc."},
  {max_prefix: "58-FC-73",vendor: "Arria Live Media, Inc."},
  {max_prefix: "0C-1A-10",vendor: "Acoustic Stream"},
  {max_prefix: "C4-EF-70",vendor: "Home Skinovations"},
  {max_prefix: "7C-5A-67",vendor: "JNC Systems, Inc."},
  {max_prefix: "5C-E3-B6",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "C8-69-CD",vendor: "Apple, Inc."},
  {max_prefix: "A4-B8-05",vendor: "Apple, Inc."},
  {max_prefix: "C0-11-73",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "78-53-F2",vendor: "Roxton Systems Ltd."},
  {max_prefix: "BC-E6-3F",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "7C-91-22",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "6C-EB-B2",vendor: "Dongguan Sen DongLv Electronics Co.,Ltd"},
  {max_prefix: "F4-0E-22",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "E8-13-63",vendor: "Comstock RD, Inc."},
  {max_prefix: "74-18-65",vendor: "Shanghai DareGlobal Technologies Co.,Ltd"},
  {max_prefix: "F8-C3-72",vendor: "TSUZUKI DENKI"},
  {max_prefix: "D4-72-08",vendor: "Bragi GmbH"},
  {max_prefix: "90-C9-9B",vendor: "Tesorion Nederland B.V."},
  {max_prefix: "5C-AD-CF",vendor: "Apple, Inc."},
  {max_prefix: "BC-6C-21",vendor: "Apple, Inc."},
  {max_prefix: "B4-9D-0B",vendor: "BQ"},
  {max_prefix: "3C-8C-F8",vendor: "TRENDnet, Inc."},
  {max_prefix: "A8-72-85",vendor: "IDT, INC."},
  {max_prefix: "08-0A-4E",vendor: "Planet Bingo\u00ae \u2014 3rd Rock Gaming\u00ae"},
  {max_prefix: "78-05-41",vendor: "Queclink Wireless Solutions Co., Ltd"},
  {max_prefix: "04-41-69",vendor: "GoPro"},
  {max_prefix: "C0-2D-EE",vendor: "Cuff"},
  {max_prefix: "90-23-EC",vendor: "Availink, Inc."},
  {max_prefix: "44-1C-A8",vendor: "Hon Hai Precision Ind. Co.,Ltd."},
  {max_prefix: "AC-BC-32",vendor: "Apple, Inc."},
  {max_prefix: "54-4E-90",vendor: "Apple, Inc."},
  {max_prefix: "A4-A6-A9",vendor: "Private"},
  {max_prefix: "8C-10-D4",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "F8-98-B9",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "5C-B5-59",vendor: "CNEX Labs"},
  {max_prefix: "B8-3A-9D",vendor: "Alarm.com"},
  {max_prefix: "68-58-C5",vendor: "ZF TRW Automotive"},
  {max_prefix: "88-1B-99",vendor: "SHENZHEN XIN FEI JIA ELECTRONIC CO. LTD."},
  {max_prefix: "90-6F-18",vendor: "Private"},
  {max_prefix: "98-CB-27",vendor: "Galore Networks Pvt. Ltd."},
  {max_prefix: "CC-79-4A",vendor: "BLU Products Inc."},
  {max_prefix: "94-D8-59",vendor: "TCT mobile ltd"},
  {max_prefix: "2C-FC-E4",vendor: "CTEK Sweden AB"},
  {max_prefix: "B4-29-3D",vendor: "Shenzhen Urovo Technology Co.,Ltd."},
  {max_prefix: "54-FF-82",vendor: "Davit Solution co."},
  {max_prefix: "50-DF-95",vendor: "Lytx"},
  {max_prefix: "28-27-BF",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "F4-E9-26",vendor: "Tianjin Zanpu Technology Inc."},
  {max_prefix: "E8-55-B4",vendor: "SAI Technology Inc."},
  {max_prefix: "34-0C-ED",vendor: "Moduel AB"},
  {max_prefix: "9C-A6-9D",vendor: "Whaley Technology Co.Ltd"},
  {max_prefix: "58-53-C0",vendor: "Beijing Guang Runtong Technology Development Company co.,Ltd"},
  {max_prefix: "24-72-60",vendor: "IOTTECH Corp"},
  {max_prefix: "E8-F2-E2",vendor: "LG Innotek"},
  {max_prefix: "2C-A5-39",vendor: "Parallel Wireless, Inc"},
  {max_prefix: "24-5B-F0",vendor: "Liteon, Inc."},
  {max_prefix: "C0-B7-13",vendor: "Beijing Xiaoyuer Technology Co. Ltd."},
  {max_prefix: "18-8E-F9",vendor: "G2C Co. Ltd."},
  {max_prefix: "20-63-5F",vendor: "Abeeway"},
  {max_prefix: "08-3A-5C",vendor: "Junilab, Inc."},
  {max_prefix: "B8-B3-DC",vendor: "DEREK (SHAOGUAN) LIMITED"},
  {max_prefix: "70-2A-7D",vendor: "EpSpot AB"},
  {max_prefix: "4C-AE-31",vendor: "ShengHai Electronics (Shenzhen) Ltd"},
  {max_prefix: "F4-E9-D4",vendor: "QLogic Corporation"},
  {max_prefix: "44-F4-36",vendor: "zte corporation"},
  {max_prefix: "F4-B8-A7",vendor: "zte corporation"},
  {max_prefix: "30-0C-23",vendor: "zte corporation"},
  {max_prefix: "4C-B7-6D",vendor: "Novi Security"},
  {max_prefix: "18-5D-9A",vendor: "BobjGear LLC"},
  {max_prefix: "C4-7D-46",vendor: "FUJITSU LIMITED"},
  {max_prefix: "60-9C-9F",vendor: "Brocade Communications Systems LLC"},
  {max_prefix: "A8-82-7F",vendor: "CIBN Oriental Network(Beijing) CO.,Ltd"},
  {max_prefix: "B8-C3-BF",vendor: "Henan Chengshi NetWork Technology Co.\uff0cLtd"},
  {max_prefix: "6C-E0-1E",vendor: "Modcam AB"},
  {max_prefix: "74-85-2A",vendor: "PEGATRON CORPORATION"},
  {max_prefix: "9C-B6-D0",vendor: "Rivet Networks"},
  {max_prefix: "40-B8-9A",vendor: "Hon Hai Precision Ind. Co.,Ltd."},
  {max_prefix: "1C-B7-2C",vendor: "ASUSTek COMPUTER INC."},
  {max_prefix: "40-B8-37",vendor: "Sony Corporation"},
  {max_prefix: "80-01-84",vendor: "HTC Corporation"},
  {max_prefix: "4C-EE-B0",vendor: "SHC Netzwerktechnik GmbH"},
  {max_prefix: "44-C6-9B",vendor: "Wuhan Feng Tian Information Network CO.,LTD"},
  {max_prefix: "C0-25-67",vendor: "Nexxt Solutions"},
  {max_prefix: "FC-E3-3C",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "D0-48-F3",vendor: "DATTUS Inc"},
  {max_prefix: "44-96-2B",vendor: "Aidon Oy"},
  {max_prefix: "B8-9A-CD",vendor: "ELITE OPTOELECTRONIC(ASIA)CO.,LTD"},
  {max_prefix: "D4-68-BA",vendor: "Shenzhen Sundray Technologies Company Limited"},
  {max_prefix: "08-62-66",vendor: "ASUSTek COMPUTER INC."},
  {max_prefix: "9C-30-66",vendor: "RWE Effizienz GmbH"},
  {max_prefix: "C8-C5-0E",vendor: "Shenzhen Primestone Network Technologies.Co., Ltd."},
  {max_prefix: "D0-6A-1F",vendor: "BSE CO.,LTD."},
  {max_prefix: "E8-07-BF",vendor: "SHENZHEN BOOMTECH INDUSTRY CO.,LTD"},
  {max_prefix: "84-F1-29",vendor: "Metrascale Inc."},
  {max_prefix: "60-E6-BC",vendor: "Sino-Telecom Technology Co.,Ltd."},
  {max_prefix: "70-01-36",vendor: "FATEK Automation Corporation"},
  {max_prefix: "FC-A2-2A",vendor: "PT. Callysta Multi Engineering"},
  {max_prefix: "18-BD-AD",vendor: "L-TECH CORPORATION"},
  {max_prefix: "A4-56-02",vendor: "fenglian Technology Co.,Ltd."},
  {max_prefix: "D4-52-2A",vendor: "TangoWiFi.com"},
  {max_prefix: "B0-08-BF",vendor: "Vital Connect, Inc."},
  {max_prefix: "E0-76-D0",vendor: "AMPAK Technology, Inc."},
  {max_prefix: "6C-F5-E8",vendor: "Mooredoll Inc."},
  {max_prefix: "A8-90-08",vendor: "Beijing Yuecheng Technology Co. Ltd."},
  {max_prefix: "1C-C7-2D",vendor: "Shenzhen Huapu Digital CO.,Ltd"},
  {max_prefix: "8C-BF-A6",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "C8-A8-23",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "B0-C5-59",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "18-38-64",vendor: "CAP-TECH INTERNATIONAL CO., LTD."},
  {max_prefix: "C0-33-5E",vendor: "Microsoft"},
  {max_prefix: "B0-E0-3C",vendor: "TCT mobile ltd"},
  {max_prefix: "BC-14-85",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "F0-1E-34",vendor: "ORICO Technologies Co., Ltd"},
  {max_prefix: "DC-E0-26",vendor: "Patrol Tag, Inc"},
  {max_prefix: "B4-05-66",vendor: "SP Best Corporation Co., LTD."},
  {max_prefix: "F4-2C-56",vendor: "SENOR TECH CO LTD"},
  {max_prefix: "FC-DC-4A",vendor: "G-Wearables Corp."},
  {max_prefix: "1C-14-B3",vendor: "Airwire Technologies"},
  {max_prefix: "9C-6C-15",vendor: "Microsoft Corporation"},
  {max_prefix: "94-E2-FD",vendor: "Boge Kompressoren OTTO Boge GmbH & Co. KG"},
  {max_prefix: "84-CF-BF",vendor: "Fairphone"},
  {max_prefix: "AC-D1-B8",vendor: "Hon Hai Precision Ind. Co.,Ltd."},
  {max_prefix: "A4-8C-DB",vendor: "Lenovo"},
  {max_prefix: "D8-5D-E2",vendor: "Hon Hai Precision Ind. Co.,Ltd."},
  {max_prefix: "3C-91-2B",vendor: "Vexata Inc"},
  {max_prefix: "34-6C-0F",vendor: "Pramod Telecom Pvt. Ltd"},
  {max_prefix: "E8-44-7E",vendor: "Bitdefender SRL"},
  {max_prefix: "44-5E-CD",vendor: "Razer Inc"},
  {max_prefix: "4C-A9-28",vendor: "Insensi"},
  {max_prefix: "B0-49-5F",vendor: "OMRON HEALTHCARE Co., Ltd."},
  {max_prefix: "D0-92-9E",vendor: "Microsoft Corporation"},
  {max_prefix: "DC-09-14",vendor: "Talk-A-Phone Co."},
  {max_prefix: "BC-52-B4",vendor: "Nokia"},
  {max_prefix: "94-05-B6",vendor: "Liling FullRiver Electronics & Technology Ltd"},
  {max_prefix: "C8-1B-6B",vendor: "Innova Security"},
  {max_prefix: "00-A5-09",vendor: "WigWag Inc."},
  {max_prefix: "74-91-BD",vendor: "Four systems Co.,Ltd."},
  {max_prefix: "D4-32-66",vendor: "Fike Corporation"},
  {max_prefix: "78-31-2B",vendor: "zte corporation"},
  {max_prefix: "90-0C-B4",vendor: "Alinket Electronic Technology Co., Ltd"},
  {max_prefix: "F0-FE-6B",vendor: "Shanghai High-Flying Electronics Technology Co., Ltd"},
  {max_prefix: "60-F1-89",vendor: "Murata Manufacturing Co., Ltd."},
  {max_prefix: "74-2E-FC",vendor: "DirectPacket Research, Inc,"},
  {max_prefix: "48-C0-93",vendor: "Xirrus, Inc."},
  {max_prefix: "A0-C2-DE",vendor: "Costar Video Systems"},
  {max_prefix: "88-E1-61",vendor: "Art Beijing Science and Technology Development Co., Ltd."},
  {max_prefix: "00-F3-DB",vendor: "WOO Sports"},
  {max_prefix: "3C-AE-69",vendor: "ESA Elektroschaltanlagen Grimma GmbH"},
  {max_prefix: "10-08-B1",vendor: "Hon Hai Precision Ind. Co.,Ltd."},
  {max_prefix: "E4-8C-0F",vendor: "Discovery Insure"},
  {max_prefix: "E4-23-54",vendor: "SHENZHEN FUZHI SOFTWARE TECHNOLOGY CO.,LTD"},
  {max_prefix: "94-70-D2",vendor: "WINFIRM TECHNOLOGY"},
  {max_prefix: "A4-4A-D3",vendor: "ST Electronics(Shanghai) Co.,Ltd"},
  {max_prefix: "94-BF-95",vendor: "Shenzhen Coship Electronics Co., Ltd"},
  {max_prefix: "10-FA-CE",vendor: "Reacheng Communication Technology Co.,Ltd"},
  {max_prefix: "00-A2-F5",vendor: "Guangzhou Yuanyun Network Technology Co.,Ltd"},
  {max_prefix: "44-CE-7D",vendor: "SFR"},
  {max_prefix: "34-4D-EA",vendor: "zte corporation"},
  {max_prefix: "4C-16-F1",vendor: "zte corporation"},
  {max_prefix: "34-38-AF",vendor: "Inlab Networks GmbH"},
  {max_prefix: "B4-A8-28",vendor: "Shenzhen Concox Information Technology Co., Ltd"},
  {max_prefix: "C4-BD-6A",vendor: "SKF GmbH"},
  {max_prefix: "C4-01-CE",vendor: "PRESITION (2000) CO., LTD."},
  {max_prefix: "58-7B-E9",vendor: "AirPro Technology India Pvt. Ltd"},
  {max_prefix: "7C-B1-77",vendor: "Satelco AG"},
  {max_prefix: "CC-30-80",vendor: "VAIO Corporation"},
  {max_prefix: "EC-1D-7F",vendor: "zte corporation"},
  {max_prefix: "AC-38-70",vendor: "Lenovo Mobile Communication Technology Ltd."},
  {max_prefix: "70-F1-96",vendor: "Actiontec Electronics, Inc"},
  {max_prefix: "18-82-19",vendor: "Alibaba Cloud Computing Ltd."},
  {max_prefix: "E4-C6-2B",vendor: "Airware"},
  {max_prefix: "4C-E9-33",vendor: "RailComm, LLC"},
  {max_prefix: "18-71-17",vendor: "eta plus electronic gmbh"},
  {max_prefix: "EC-0E-C4",vendor: "Hon Hai Precision Ind. Co.,Ltd."},
  {max_prefix: "8C-18-D9",vendor: "Shenzhen RF Technology Co., Ltd"},
  {max_prefix: "D8-FB-11",vendor: "AXACORE"},
  {max_prefix: "30-FA-B7",vendor: "Tunai Creative"},
  {max_prefix: "08-09-B6",vendor: "Masimo Corp"},
  {max_prefix: "4C-F5-A0",vendor: "Scalable Network Technologies Inc"},
  {max_prefix: "60-02-92",vendor: "PEGATRON CORPORATION"},
  {max_prefix: "B4-B8-59",vendor: "Texa Spa"},
  {max_prefix: "5C-F9-F0",vendor: "Atomos Engineering P/L"},
  {max_prefix: "D0-A0-D6",vendor: "ChengDu TD Tech"},
  {max_prefix: "EC-B9-07",vendor: "CloudGenix Inc"},
  {max_prefix: "0C-8C-8F",vendor: "Kamo Technology Limited"},
  {max_prefix: "A4-A4-D3",vendor: "Bluebank Communication Technology Co.Ltd"},
  {max_prefix: "A8-32-9A",vendor: "Digicom Futuristic Technologies Ltd."},
  {max_prefix: "F4-28-33",vendor: "MMPC Inc."},
  {max_prefix: "4C-83-DE",vendor: "Cisco SPVTG"},
  {max_prefix: "A8-13-74",vendor: "Panasonic Corporation AVC Networks Company"},
  {max_prefix: "F4-D0-32",vendor: "Yunnan Ideal Information&Technology.,Ltd"},
  {max_prefix: "3C-46-D8",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "14-75-90",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "50-BD-5F",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "4C-BC-42",vendor: "Shenzhen Hangsheng Electronics Co.,Ltd."},
  {max_prefix: "28-A5-EE",vendor: "Shenzhen SDGI CATV Co., Ltd"},
  {max_prefix: "08-3D-88",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "98-7E-46",vendor: "Emizon Networks Limited"},
  {max_prefix: "BC-4E-5D",vendor: "ZhongMiao Technology Co., Ltd."},
  {max_prefix: "7C-6A-C3",vendor: "GatesAir, Inc"},
  {max_prefix: "70-2D-D1",vendor: "Newings Communication CO., LTD."},
  {max_prefix: "F4-F6-46",vendor: "Dediprog Technology Co. Ltd."},
  {max_prefix: "28-E6-E9",vendor: "SIS Sat Internet Services GmbH"},
  {max_prefix: "F4-FD-2B",vendor: "ZOYI Company"},
  {max_prefix: "10-92-66",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "04-5C-8E",vendor: "gosund GROUP CO.,LTD"},
  {max_prefix: "7C-C4-EF",vendor: "Devialet"},
  {max_prefix: "D8-5D-FB",vendor: "Private"},
  {max_prefix: "5C-5B-C2",vendor: "YIK Corporation"},
  {max_prefix: "30-0D-2A",vendor: "Zhejiang Wellcom Technology Co.,Ltd."},
  {max_prefix: "3C-18-9F",vendor: "Nokia Corporation"},
  {max_prefix: "08-CD-9B",vendor: "samtec automotive electronics & software GmbH"},
  {max_prefix: "30-59-5B",vendor: "streamnow AG"},
  {max_prefix: "84-85-0A",vendor: "Hella Sonnen- und Wetterschutztechnik GmbH"},
  {max_prefix: "EC-2E-4E",vendor: "HITACHI-LG DATA STORAGE INC"},
  {max_prefix: "DC-F1-10",vendor: "Nokia Corporation"},
  {max_prefix: "90-8C-63",vendor: "GZ Weedong Networks Technology Co. , Ltd"},
  {max_prefix: "D4-67-61",vendor: "XonTel Technology Co."},
  {max_prefix: "34-81-C4",vendor: "AVM GmbH"},
  {max_prefix: "60-8F-5C",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "E8-EF-89",vendor: "OPMEX Tech."},
  {max_prefix: "F8-4A-73",vendor: "EUMTECH CO., LTD"},
  {max_prefix: "14-2B-D6",vendor: "Guangdong Appscomm Co.,Ltd"},
  {max_prefix: "CC-B6-91",vendor: "NECMagnusCommunications"},
  {max_prefix: "40-16-7E",vendor: "ASUSTek COMPUTER INC."},
  {max_prefix: "98-37-13",vendor: "PT.Navicom Indonesia"},
  {max_prefix: "A4-7E-39",vendor: "zte corporation"},
  {max_prefix: "18-CC-23",vendor: "Philio Technology Corporation"},
  {max_prefix: "98-34-9D",vendor: "Krauss Maffei Technologies GmbH"},
  {max_prefix: "88-0F-B6",vendor: "Jabil Circuits India Pvt Ltd,-EHTP unit"},
  {max_prefix: "B4-66-98",vendor: "Zealabs srl"},
  {max_prefix: "68-7C-C8",vendor: "Measurement Systems S. de R.L."},
  {max_prefix: "74-F8-5D",vendor: "Berkeley Nucleonics Corp"},
  {max_prefix: "B0-61-C7",vendor: "Ericsson-LG Enterprise"},
  {max_prefix: "40-01-07",vendor: "Arista Corp"},
  {max_prefix: "30-C7-50",vendor: "MIC Technology Group"},
  {max_prefix: "44-11-C2",vendor: "Telegartner Karl Gartner GmbH"},
  {max_prefix: "80-59-FD",vendor: "Noviga"},
  {max_prefix: "00-92-FA",vendor: "SHENZHEN WISKY TECHNOLOGY CO.,LTD"},
  {max_prefix: "10-0F-18",vendor: "Fu Gang Electronic(KunShan)CO.,LTD"},
  {max_prefix: "D0-C7-C0",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "FC-C2-DE",vendor: "Murata Manufacturing Co., Ltd."},
  {max_prefix: "5C-E7-BF",vendor: "New Singularity International Technical Development Co.,Ltd"},
  {max_prefix: "38-6C-9B",vendor: "Ivy Biomedical"},
  {max_prefix: "B4-2C-92",vendor: "Zhejiang Weirong Electronic Co., Ltd"},
  {max_prefix: "E0-D3-1A",vendor: "EQUES Technology Co., Limited"},
  {max_prefix: "44-7E-76",vendor: "Trek Technology (S) Pte Ltd"},
  {max_prefix: "B0-EC-8F",vendor: "GMX SAS"},
  {max_prefix: "4C-7F-62",vendor: "Nokia Corporation"},
  {max_prefix: "58-05-28",vendor: "LABRIS NETWORKS"},
  {max_prefix: "40-78-75",vendor: "IMBEL - Industria de Material Belico do Brasil"},
  {max_prefix: "D8-81-CE",vendor: "AHN INC."},
  {max_prefix: "28-C8-25",vendor: "DellKing Industrial Co., Ltd"},
  {max_prefix: "80-61-8F",vendor: "Shenzhen sangfei consumer communications co.,ltd"},
  {max_prefix: "D8-2A-15",vendor: "Leitner SpA"},
  {max_prefix: "28-DE-F6",vendor: "bioMerieux Inc."},
  {max_prefix: "98-77-70",vendor: "Pep Digital Technology (Guangzhou) Co., Ltd"},
  {max_prefix: "BC-14-EF",vendor: "ITON Technology Limited"},
  {max_prefix: "D8-7C-DD",vendor: "SANIX INCORPORATED"},
  {max_prefix: "34-46-6F",vendor: "HiTEM Engineering"},
  {max_prefix: "68-D2-47",vendor: "Portalis LC"},
  {max_prefix: "50-B6-95",vendor: "Micropoint Biotechnologies,Inc."},
  {max_prefix: "B4-43-0D",vendor: "Broadlink Pty Ltd"},
  {max_prefix: "50-A0-54",vendor: "Actineon"},
  {max_prefix: "B4-85-47",vendor: "Amptown System Company GmbH"},
  {max_prefix: "74-8F-1B",vendor: "MasterImage 3D"},
  {max_prefix: "08-3F-76",vendor: "Intellian Technologies, Inc."},
  {max_prefix: "10-DD-F4",vendor: "Maxway Electronics CO.,LTD"},
  {max_prefix: "38-7B-47",vendor: "AKELA, Inc."},
  {max_prefix: "C0-64-C6",vendor: "Nokia Corporation"},
  {max_prefix: "14-F2-8E",vendor: "ShenYang ZhongKe-Allwin Technology Co.LTD"},
  {max_prefix: "A0-65-18",vendor: "VNPT TECHNOLOGY"},
  {max_prefix: "7C-8D-91",vendor: "Shanghai Hongzhuo Information Technology co.,LTD"},
  {max_prefix: "08-03-71",vendor: "KRG CORPORATE"},
  {max_prefix: "20-0E-95",vendor: "IEC \u2013 TC9 WG43"},
  {max_prefix: "C8-F6-8D",vendor: "S.E.TECHNOLOGIES LIMITED"},
  {max_prefix: "6C-64-1A",vendor: "Penguin Computing"},
  {max_prefix: "CC-89-FD",vendor: "Nokia Corporation"},
  {max_prefix: "70-7C-18",vendor: "ADATA Technology Co., Ltd"},
  {max_prefix: "78-EC-74",vendor: "Kyland-USA"},
  {max_prefix: "78-3D-5B",vendor: "TELNET Redes Inteligentes S.A."},
  {max_prefix: "D0-B5-23",vendor: "Bestcare Cloucal Corp."},
  {max_prefix: "24-A4-95",vendor: "Thales Canada Inc."},
  {max_prefix: "D0-C4-2F",vendor: "Tamagawa Seiki Co.,Ltd."},
  {max_prefix: "54-93-59",vendor: "SHENZHEN TWOWING TECHNOLOGIES CO.,LTD."},
  {max_prefix: "C0-F9-91",vendor: "GME Standard Communications P/L"},
  {max_prefix: "90-35-6E",vendor: "Vodafone Omnitel N.V."},
  {max_prefix: "5C-11-93",vendor: "Seal One AG"},
  {max_prefix: "84-76-16",vendor: "Addat s.r.o."},
  {max_prefix: "DC-05-75",vendor: "SIEMENS ENERGY AUTOMATION"},
  {max_prefix: "E4-04-39",vendor: "TomTom Software Ltd"},
  {max_prefix: "E0-97-F2",vendor: "Atomax Inc."},
  {max_prefix: "70-30-5E",vendor: "Nanjing Zhongke Menglian Information Technology Co.,LTD"},
  {max_prefix: "C0-98-E5",vendor: "University of Michigan"},
  {max_prefix: "50-E1-4A",vendor: "Private"},
  {max_prefix: "0C-12-62",vendor: "zte corporation"},
  {max_prefix: "3C-D4-D6",vendor: "WirelessWERX, Inc"},
  {max_prefix: "70-59-86",vendor: "OOO TTV"},
  {max_prefix: "84-4F-03",vendor: "Ablelink Electronics Ltd"},
  {max_prefix: "E8-E7-70",vendor: "Warp9 Tech Design, Inc."},
  {max_prefix: "60-96-20",vendor: "Private"},
  {max_prefix: "44-3C-9C",vendor: "Pintsch GmbH"},
  {max_prefix: "8C-CD-A2",vendor: "ACTP, Inc."},
  {max_prefix: "84-26-2B",vendor: "Nokia"},
  {max_prefix: "98-6C-F5",vendor: "zte corporation"},
  {max_prefix: "90-67-17",vendor: "Alphion India Private Limited"},
  {max_prefix: "60-64-A1",vendor: "RADiflow Ltd."},
  {max_prefix: "9C-F8-DB",vendor: "shenzhen eyunmei technology co,.ltd"},
  {max_prefix: "0C-47-3D",vendor: "Hitron Technologies. Inc"},
  {max_prefix: "68-0A-D7",vendor: "Yancheng Kecheng Optoelectronic Technology Co., Ltd"},
  {max_prefix: "BC-88-93",vendor: "VILLBAU Ltd."},
  {max_prefix: "44-7B-C4",vendor: "DualShine Technology(SZ)Co.,Ltd"},
  {max_prefix: "40-74-96",vendor: "aFUN TECHNOLOGY INC."},
  {max_prefix: "70-1D-7F",vendor: "Comtech Technology Co., Ltd."},
  {max_prefix: "9C-03-9E",vendor: "Beijing Winchannel Software Technology Co., Ltd"},
  {max_prefix: "70-8D-09",vendor: "Nokia Corporation"},
  {max_prefix: "98-FB-12",vendor: "Grand Electronics (HK) Ltd"},
  {max_prefix: "3C-10-40",vendor: "daesung network"},
  {max_prefix: "28-FC-51",vendor: "The Electric Controller and Manufacturing Co., LLC"},
  {max_prefix: "20-D2-1F",vendor: "Wincal Technology Corp."},
  {max_prefix: "F8-95-50",vendor: "Proton Products Chengdu Ltd"},
  {max_prefix: "7C-49-B9",vendor: "Plexus Manufacturing Sdn Bhd"},
  {max_prefix: "9C-28-40",vendor: "Discovery Technology,LTD.."},
  {max_prefix: "1C-7B-21",vendor: "Sony Corporation"},
  {max_prefix: "6C-F9-7C",vendor: "Nanoptix Inc."},
  {max_prefix: "F8-FF-5F",vendor: "Shenzhen Communication Technology Co.,Ltd"},
  {max_prefix: "44-70-0B",vendor: "IFFU"},
  {max_prefix: "1C-41-58",vendor: "Gemalto M2M GmbH"},
  {max_prefix: "BC-2B-6B",vendor: "Beijing Haier IC Design Co.,Ltd"},
  {max_prefix: "98-D3-31",vendor: "Shenzhen Bolutek Technology Co.,Ltd."},
  {max_prefix: "38-EC-11",vendor: "Novatek Microelectronics Corp."},
  {max_prefix: "4C-CB-F5",vendor: "zte corporation"},
  {max_prefix: "18-7E-D5",vendor: "shenzhen kaism technology Co. Ltd"},
  {max_prefix: "84-1B-38",vendor: "Shenzhen Excelsecu Data Technology Co.,Ltd"},
  {max_prefix: "E0-AF-4B",vendor: "Pluribus Networks, Inc."},
  {max_prefix: "54-A5-4B",vendor: "NSC Communications Siberia Ltd"},
  {max_prefix: "EC-22-57",vendor: "JiangSu NanJing University Electronic Information Technology Co.,Ltd"},
  {max_prefix: "F0-37-A1",vendor: "Huike Electronics (SHENZHEN) CO., LTD."},
  {max_prefix: "58-B9-61",vendor: "SOLEM Electronique"},
  {max_prefix: "78-49-1D",vendor: "The Will-Burt Company"},
  {max_prefix: "F4-6A-BC",vendor: "Adonit Corp. Ltd."},
  {max_prefix: "84-0F-45",vendor: "Shanghai GMT Digital Technologies Co., Ltd"},
  {max_prefix: "2C-5F-F3",vendor: "Pertronic Industries"},
  {max_prefix: "58-63-9A",vendor: "TPL SYSTEMES"},
  {max_prefix: "28-C6-71",vendor: "Yota Devices OY"},
  {max_prefix: "D8-69-60",vendor: "Steinsvik"},
  {max_prefix: "08-EF-3B",vendor: "MCS Logic Inc."},
  {max_prefix: "E8-EA-DA",vendor: "Denkovi Assembly Electronics LTD"},
  {max_prefix: "F8-5B-C9",vendor: "M-Cube Spa"},
  {max_prefix: "90-7A-0A",vendor: "Gebr. Bode GmbH & Co KG"},
  {max_prefix: "F4-7A-4E",vendor: "Woojeon&Handan"},
  {max_prefix: "04-84-8A",vendor: "7INOVA TECHNOLOGY LIMITED"},
  {max_prefix: "7C-B7-7B",vendor: "Paradigm Electronics Inc"},
  {max_prefix: "B0-CE-18",vendor: "Zhejiang shenghui lighting co.,Ltd"},
  {max_prefix: "A0-C6-EC",vendor: "ShenZhen ANYK Technology Co.,LTD"},
  {max_prefix: "78-E8-B6",vendor: "zte corporation"},
  {max_prefix: "DC-AE-04",vendor: "CELOXICA Ltd"},
  {max_prefix: "80-05-DF",vendor: "Montage Technology Group Limited"},
  {max_prefix: "10-22-79",vendor: "ZeroDesktop, Inc."},
  {max_prefix: "7C-1A-FC",vendor: "Dalian Co-Edifice Video Technology Co., Ltd"},
  {max_prefix: "F0-8E-DB",vendor: "VeloCloud Networks"},
  {max_prefix: "68-1D-64",vendor: "Sunwave Communications Co., Ltd"},
  {max_prefix: "70-4C-ED",vendor: "TMRG, Inc."},
  {max_prefix: "C4-7F-51",vendor: "Inventek Systems"},
  {max_prefix: "A8-97-DC",vendor: "IBM"},
  {max_prefix: "10-9A-B9",vendor: "Tosibox Oy"},
  {max_prefix: "14-2D-8B",vendor: "Incipio Technologies, Inc"},
  {max_prefix: "CC-D2-9B",vendor: "Shenzhen Bopengfa Elec&Technology CO.,Ltd"},
  {max_prefix: "78-DA-B3",vendor: "GBO Technology"},
  {max_prefix: "70-0F-EC",vendor: "Poindus Systems Corp."},
  {max_prefix: "68-EE-96",vendor: "Cisco SPVTG"},
  {max_prefix: "78-D3-8D",vendor: "HONGKONG YUNLINK TECHNOLOGY LIMITED"},
  {max_prefix: "10-78-CE",vendor: "Hanvit SI, Inc."},
  {max_prefix: "D4-10-90",vendor: "iNFORM Systems AG"},
  {max_prefix: "34-95-DB",vendor: "Logitec Corporation"},
  {max_prefix: "9C-B7-93",vendor: "Creatcomm Technology Inc."},
  {max_prefix: "5C-33-5C",vendor: "Swissphone Telecom AG"},
  {max_prefix: "D8-DA-52",vendor: "APATOR S.A."},
  {max_prefix: "58-7A-4D",vendor: "Stonesoft Corporation"},
  {max_prefix: "F0-24-05",vendor: "OPUS High Technology Corporation"},
  {max_prefix: "84-E6-29",vendor: "Bluwan SA"},
  {max_prefix: "04-DF-69",vendor: "Car Connectivity Consortium"},
  {max_prefix: "4C-21-D0",vendor: "Sony Corporation"},
  {max_prefix: "78-D5-B5",vendor: "NAVIELEKTRO KY"},
  {max_prefix: "CC-BD-35",vendor: "Steinel GmbH"},
  {max_prefix: "6C-EC-A1",vendor: "SHENZHEN CLOU ELECTRONICS CO. LTD."},
  {max_prefix: "10-5C-3B",vendor: "Perma-Pipe, Inc."},
  {max_prefix: "34-9D-90",vendor: "Heinzmann GmbH & CO. KG"},
  {max_prefix: "B0-38-50",vendor: "Nanjing CAS-ZDC IOT SYSTEM CO.,LTD"},
  {max_prefix: "E8-48-1F",vendor: "Advanced Automotive Antennas"},
  {max_prefix: "D4-0B-B9",vendor: "Solid Semecs bv."},
  {max_prefix: "F4-15-FD",vendor: "Shanghai Pateo Electronic Equipment Manufacturing Co., Ltd."},
  {max_prefix: "74-8E-08",vendor: "Bestek Corp."},
  {max_prefix: "78-F5-E5",vendor: "BEGA Gantenbrink-Leuchten KG"},
  {max_prefix: "C4-7D-FE",vendor: "A.N. Solutions GmbH"},
  {max_prefix: "D8-62-DB",vendor: "Eno Inc."},
  {max_prefix: "8C-3C-07",vendor: "Skiva Technologies, Inc."},
  {max_prefix: "E4-F7-A1",vendor: "Datafox GmbH"},
  {max_prefix: "38-17-66",vendor: "PROMZAKAZ LTD."},
  {max_prefix: "14-41-E2",vendor: "Monaco Enterprises, Inc."},
  {max_prefix: "E4-7D-5A",vendor: "Beijing Hanbang Technology Corp."},
  {max_prefix: "70-E0-27",vendor: "HONGYU COMMUNICATION TECHNOLOGY LIMITED"},
  {max_prefix: "A0-9B-BD",vendor: "Total Aviation Solutions Pty Ltd"},
  {max_prefix: "38-A8-6B",vendor: "Orga BV"},
  {max_prefix: "F0-77-65",vendor: "Sourcefire, Inc"},
  {max_prefix: "EC-D0-40",vendor: "GEA Farm Technologies GmbH"},
  {max_prefix: "F8-0D-EA",vendor: "ZyCast Technology Inc."},
  {max_prefix: "B0-88-07",vendor: "Strata Worldwide"},
  {max_prefix: "5C-F3-70",vendor: "CC&C Technologies, Inc"},
  {max_prefix: "A4-E0-E6",vendor: "FILIZOLA S.A. PESAGEM E AUTOMACAO"},
  {max_prefix: "24-95-04",vendor: "SFR"},
  {max_prefix: "F4-58-42",vendor: "Boxx TV Ltd"},
  {max_prefix: "10-66-82",vendor: "NEC Platforms, Ltd."},
  {max_prefix: "D8-1E-DE",vendor: "B&W Group Ltd"},
  {max_prefix: "F0-84-C9",vendor: "zte corporation"},
  {max_prefix: "D4-01-6D",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "98-5C-93",vendor: "SBG Systems SAS"},
  {max_prefix: "A0-8A-87",vendor: "HuiZhou KaiYue Electronic Co.,Ltd"},
  {max_prefix: "38-67-93",vendor: "Asia Optical Co., Inc."},
  {max_prefix: "0C-82-68",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "C0-C3-B6",vendor: "Automatic Systems"},
  {max_prefix: "A0-EB-76",vendor: "AirCUVE Inc."},
  {max_prefix: "FC-44-99",vendor: "Swarco LEA d.o.o."},
  {max_prefix: "DC-64-7C",vendor: "C.R.S. iiMotion GmbH"},
  {max_prefix: "14-86-92",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "A8-15-4D",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "24-EA-40",vendor: "Helmholz GmbH & Co. KG"},
  {max_prefix: "FC-DB-96",vendor: "ENERVALLEY CO., LTD"},
  {max_prefix: "14-23-D7",vendor: "EUTRONIX CO., LTD."},
  {max_prefix: "28-CD-9C",vendor: "Shenzhen Dynamax Software Development Co.,Ltd."},
  {max_prefix: "50-4F-94",vendor: "Loxone Electronics GmbH"},
  {max_prefix: "60-B1-85",vendor: "ATH system"},
  {max_prefix: "74-5F-00",vendor: "Samsung Semiconductor Inc."},
  {max_prefix: "E0-C3-F3",vendor: "zte corporation"},
  {max_prefix: "5C-20-D0",vendor: "Asoni Communication Co., Ltd."},
  {max_prefix: "AC-A4-30",vendor: "Peerless AV"},
  {max_prefix: "44-18-4F",vendor: "Fitview"},
  {max_prefix: "18-E8-DD",vendor: "MODULETEK"},
  {max_prefix: "D0-73-D5",vendor: "LIFI LABS MANAGEMENT PTY LTD"},
  {max_prefix: "14-94-48",vendor: "BLU CASTLE S.A."},
  {max_prefix: "48-F9-25",vendor: "Maestronic"},
  {max_prefix: "68-83-1A",vendor: "Pandora Mobility Corporation"},
  {max_prefix: "D4-29-EA",vendor: "Zimory GmbH"},
  {max_prefix: "34-AD-E4",vendor: "Shanghai Chint Power Systems Co., Ltd."},
  {max_prefix: "54-1F-D5",vendor: "Advantage Electronics"},
  {max_prefix: "AC-E9-7F",vendor: "IoT Tech Limited"},
  {max_prefix: "A0-FE-91",vendor: "AVAT Automation GmbH"},
  {max_prefix: "74-EC-F1",vendor: "Acumen"},
  {max_prefix: "90-DA-4E",vendor: "AVANU"},
  {max_prefix: "28-18-78",vendor: "Microsoft Corporation"},
  {max_prefix: "70-38-B4",vendor: "Low Tech Solutions"},
  {max_prefix: "84-AC-A4",vendor: "Beijing Novel Super Digital TV Technology Co., Ltd"},
  {max_prefix: "58-09-E5",vendor: "Kivic Inc."},
  {max_prefix: "DC-6F-08",vendor: "Bay Storage Technology"},
  {max_prefix: "BC-62-9F",vendor: "Telenet Systems P. Ltd."},
  {max_prefix: "38-0F-E4",vendor: "Dedicated Network Partners Oy"},
  {max_prefix: "84-7A-88",vendor: "HTC Corporation"},
  {max_prefix: "A4-D8-56",vendor: "Gimbal, Inc"},
  {max_prefix: "78-55-17",vendor: "SankyuElectronics"},
  {max_prefix: "B4-7F-5E",vendor: "Foresight Manufacture (S) Pte Ltd"},
  {max_prefix: "B8-5A-F7",vendor: "Ouya, Inc"},
  {max_prefix: "34-F6-2D",vendor: "SHARP Corporation"},
  {max_prefix: "4C-8F-A5",vendor: "Jastec"},
  {max_prefix: "78-32-4F",vendor: "Millennium Group, Inc."},
  {max_prefix: "48-F2-30",vendor: "Ubizcore Co.,LTD"},
  {max_prefix: "38-43-69",vendor: "Patrol Products Consortium LLC"},
  {max_prefix: "E8-5A-A7",vendor: "LLC Emzior"},
  {max_prefix: "D0-D6-CC",vendor: "Wintop"},
  {max_prefix: "58-D0-71",vendor: "BW Broadcast"},
  {max_prefix: "20-85-8C",vendor: "Assa"},
  {max_prefix: "1C-52-D6",vendor: "FLAT DISPLAY TECHNOLOGY CORPORATION"},
  {max_prefix: "D0-DF-B2",vendor: "Genie Networks Limited"},
  {max_prefix: "80-FA-5B",vendor: "CLEVO CO."},
  {max_prefix: "C0-B3-39",vendor: "Comigo Ltd."},
  {max_prefix: "84-ED-33",vendor: "BBMC Co.,Ltd"},
  {max_prefix: "E8-2E-24",vendor: "Out of the Fog Research LLC"},
  {max_prefix: "38-66-45",vendor: "OOSIC Technology CO.,Ltd"},
  {max_prefix: "C0-A0-E2",vendor: "Eden Innovations"},
  {max_prefix: "6C-5A-34",vendor: "Shenzhen Haitianxiong Electronic Co., Ltd."},
  {max_prefix: "DC-B0-58",vendor: "B\u00fcrkert Werke GmbH"},
  {max_prefix: "90-38-DF",vendor: "Changzhou Tiannengbo System Co. Ltd."},
  {max_prefix: "18-52-53",vendor: "Pixord Corporation"},
  {max_prefix: "9C-9C-1D",vendor: "Starkey Labs Inc."},
  {max_prefix: "9C-E1-D6",vendor: "Junger Audio-Studiotechnik GmbH"},
  {max_prefix: "D8-18-2B",vendor: "Conti Temic Microelectronic GmbH"},
  {max_prefix: "80-CF-41",vendor: "Lenovo Mobile Communication Technology Ltd."},
  {max_prefix: "58-D6-D3",vendor: "Dairy Cheq Inc"},
  {max_prefix: "04-6E-49",vendor: "TaiYear Electronic Technology (Suzhou) Co., Ltd"},
  {max_prefix: "B4-9D-B4",vendor: "Axion Technologies Inc."},
  {max_prefix: "AC-E8-7E",vendor: "Bytemark Computer Consulting Ltd"},
  {max_prefix: "60-CD-C5",vendor: "Taiwan Carol Electronics., Ltd"},
  {max_prefix: "2C-3B-FD",vendor: "Netstor Technology Co., Ltd."},
  {max_prefix: "AC-3C-B4",vendor: "Nilan A/S"},
  {max_prefix: "80-07-A2",vendor: "Esson Technology Inc."},
  {max_prefix: "08-0F-FA",vendor: "KSP INC."},
  {max_prefix: "68-3B-1E",vendor: "Countwise LTD"},
  {max_prefix: "20-91-D9",vendor: "I'M SPA"},
  {max_prefix: "14-1B-F0",vendor: "Intellimedia Systems Ltd"},
  {max_prefix: "58-87-E2",vendor: "Shenzhen Coship Electronics Co., Ltd."},
  {max_prefix: "F4-6D-E2",vendor: "zte corporation"},
  {max_prefix: "50-39-55",vendor: "Cisco SPVTG"},
  {max_prefix: "80-82-87",vendor: "ATCOM Technology Co.Ltd."},
  {max_prefix: "28-A1-86",vendor: "enblink"},
  {max_prefix: "6C-9A-C9",vendor: "Valentine Research, Inc."},
  {max_prefix: "10-FB-F0",vendor: "KangSheng LTD."},
  {max_prefix: "AC-72-36",vendor: "Lexking Technology Co., Ltd."},
  {max_prefix: "3C-D7-DA",vendor: "SK Mtek microelectronics(shenzhen)limited"},
  {max_prefix: "04-F8-C2",vendor: "Flaircomm Microelectronics, Inc."},
  {max_prefix: "68-69-F2",vendor: "ComAp s.r.o."},
  {max_prefix: "B8-5A-FE",vendor: "Handaer Communication Technology (Beijing) Co., Ltd"},
  {max_prefix: "AC-A2-2C",vendor: "Baycity Technologies Ltd"},
  {max_prefix: "30-32-94",vendor: "W-IE-NE-R Plein & Baus GmbH"},
  {max_prefix: "7C-82-2D",vendor: "Nortec"},
  {max_prefix: "AC-8D-14",vendor: "Smartrove Inc"},
  {max_prefix: "38-8E-E7",vendor: "Fanhattan LLC"},
  {max_prefix: "CC-E7-98",vendor: "My Social Stuff"},
  {max_prefix: "A0-36-F0",vendor: "Comprehensive Power"},
  {max_prefix: "18-0C-AC",vendor: "CANON INC."},
  {max_prefix: "00-DB-1E",vendor: "Albedo Telecom SL"},
  {max_prefix: "60-74-8D",vendor: "Atmaca Elektronik"},
  {max_prefix: "B8-B7-D7",vendor: "2GIG Technologies"},
  {max_prefix: "78-D1-29",vendor: "Vicos"},
  {max_prefix: "84-DF-0C",vendor: "NET2GRID BV"},
  {max_prefix: "78-AB-60",vendor: "ABB Australia"},
  {max_prefix: "84-82-F4",vendor: "Beijing Huasun Unicreate Technology Co., Ltd"},
  {max_prefix: "5C-D4-1B",vendor: "UCZOON Technology Co., LTD"},
  {max_prefix: "2C-ED-EB",vendor: "Alpheus Digital Company Limited"},
  {max_prefix: "0C-DC-CC",vendor: "Inala Technologies"},
  {max_prefix: "0C-D9-96",vendor: "Cisco Systems, Inc"},
  {max_prefix: "30-F3-3A",vendor: "+plugg srl"},
  {max_prefix: "98-29-1D",vendor: "Jaguar de Mexico, SA de CV"},
  {max_prefix: "34-AF-2C",vendor: "Nintendo Co., Ltd."},
  {max_prefix: "7C-D9-FE",vendor: "New Cosmos Electric Co., Ltd."},
  {max_prefix: "CC-C1-04",vendor: "Applied Technical Systems"},
  {max_prefix: "A4-B1-E9",vendor: "Technicolor Delivery Technologies Belgium NV"},
  {max_prefix: "60-45-5E",vendor: "Liptel s.r.o."},
  {max_prefix: "D8-06-D1",vendor: "Honeywell Fire System (Shanghai) Co,. Ltd."},
  {max_prefix: "64-76-57",vendor: "Innovative Security Designs"},
  {max_prefix: "0C-57-EB",vendor: "Mueller Systems"},
  {max_prefix: "74-53-27",vendor: "COMMSEN CO., LIMITED"},
  {max_prefix: "D0-8C-FF",vendor: "UPWIS AB"},
  {max_prefix: "68-D1-FD",vendor: "Shenzhen Trimax Technology Co.,Ltd"},
  {max_prefix: "9C-06-6E",vendor: "Hytera Communications Corporation Limited"},
  {max_prefix: "64-22-16",vendor: "Shandong Taixin Electronic co.,Ltd"},
  {max_prefix: "F8-A0-3D",vendor: "Dinstar Technologies Co., Ltd."},
  {max_prefix: "2C-D4-44",vendor: "FUJITSU LIMITED"},
  {max_prefix: "90-70-25",vendor: "Garea Microsys Co.,Ltd."},
  {max_prefix: "10-D1-DC",vendor: "INSTAR Deutschland GmbH"},
  {max_prefix: "34-99-6F",vendor: "VPI Engineering"},
  {max_prefix: "58-69-F9",vendor: "Fusion Transactive Ltd."},
  {max_prefix: "D4-1E-35",vendor: "TOHO Electronics INC."},
  {max_prefix: "98-A7-B0",vendor: "MCST ZAO"},
  {max_prefix: "4C-06-8A",vendor: "Basler Electric Company"},
  {max_prefix: "BC-81-1F",vendor: "Ingate Systems"},
  {max_prefix: "D8-67-D9",vendor: "Cisco Systems, Inc"},
  {max_prefix: "94-4A-09",vendor: "BitWise Controls"},
  {max_prefix: "BC-28-D6",vendor: "Rowley Associates Limited"},
  {max_prefix: "10-BD-18",vendor: "Cisco Systems, Inc"},
  {max_prefix: "44-38-39",vendor: "Cumulus Networks, inc"},
  {max_prefix: "A4-E7-31",vendor: "Nokia Corporation"},
  {max_prefix: "4C-72-B9",vendor: "PEGATRON CORPORATION"},
  {max_prefix: "68-D9-25",vendor: "ProSys Development Services"},
  {max_prefix: "84-8D-84",vendor: "Rajant Corporation"},
  {max_prefix: "D8-33-7F",vendor: "Office FA.com Co.,Ltd."},
  {max_prefix: "00-36-F8",vendor: "Conti Temic microelectronic GmbH"},
  {max_prefix: "A4-F7-D0",vendor: "LAN Accessories Co., Ltd."},
  {max_prefix: "04-8B-42",vendor: "Skspruce Technologies"},
  {max_prefix: "50-76-A6",vendor: "Ecil Informatica Ind. Com. Ltda"},
  {max_prefix: "A4-4C-11",vendor: "Cisco Systems, Inc"},
  {max_prefix: "60-84-3B",vendor: "Soladigm, Inc."},
  {max_prefix: "20-9B-A5",vendor: "JIAXING GLEAD Electronics Co.,Ltd"},
  {max_prefix: "A0-F4-50",vendor: "HTC Corporation"},
  {max_prefix: "60-89-B1",vendor: "Key Digital Systems"},
  {max_prefix: "44-D1-5E",vendor: "Shanghai Kingto Information Technology Ltd"},
  {max_prefix: "00-36-FE",vendor: "SuperVision"},
  {max_prefix: "3C-EA-FB",vendor: "NSE AG"},
  {max_prefix: "8C-C7-AA",vendor: "Radinet Communications Inc."},
  {max_prefix: "40-33-6C",vendor: "Godrej & Boyce Mfg. co. ltd"},
  {max_prefix: "48-91-53",vendor: "Weinmann Ger\u00e4te f\u00fcr Medizin GmbH + Co. KG"},
  {max_prefix: "AC-94-03",vendor: "Envision Peripherals Inc"},
  {max_prefix: "1C-97-3D",vendor: "PRICOM Design"},
  {max_prefix: "BC-02-00",vendor: "Stewart Audio"},
  {max_prefix: "E8-56-D6",vendor: "NCTech Ltd"},
  {max_prefix: "C0-81-70",vendor: "Effigis GeoSolutions"},
  {max_prefix: "60-B9-33",vendor: "Deutron Electronics Corp."},
  {max_prefix: "54-46-6B",vendor: "Shenzhen CZTIC Electronic Technology Co., Ltd"},
  {max_prefix: "44-B3-82",vendor: "Kuang-chi Institute of Advanced Technology"},
  {max_prefix: "70-9E-86",vendor: "X6D Limited"},
  {max_prefix: "A0-F4-19",vendor: "Nokia Corporation"},
  {max_prefix: "00-43-FF",vendor: "KETRON S.R.L."},
  {max_prefix: "7C-AC-B2",vendor: "Bosch Software Innovations GmbH"},
  {max_prefix: "18-D6-6A",vendor: "Inmarsat"},
  {max_prefix: "28-E6-08",vendor: "Tokheim"},
  {max_prefix: "C4-7B-A3",vendor: "NAVIS Inc."},
  {max_prefix: "F4-48-48",vendor: "Amscreen Group Ltd"},
  {max_prefix: "50-D2-74",vendor: "Steffes Corporation"},
  {max_prefix: "F8-50-63",vendor: "Verathon"},
  {max_prefix: "F0-D1-4F",vendor: "LINEAR LLC"},
  {max_prefix: "5C-6F-4F",vendor: "S.A. SISTEL"},
  {max_prefix: "90-1B-0E",vendor: "Fujitsu Technology Solutions GmbH"},
  {max_prefix: "74-FF-7D",vendor: "Wren Sound Systems, LLC"},
  {max_prefix: "AC-F0-B2",vendor: "Becker Electronics Taiwan Ltd."},
  {max_prefix: "C8-56-45",vendor: "Intermas France"},
  {max_prefix: "44-34-8F",vendor: "MXT INDUSTRIAL LTDA"},
  {max_prefix: "2C-36-F8",vendor: "Cisco Systems, Inc"},
  {max_prefix: "58-08-FA",vendor: "Fiber Optic & telecommunication INC."},
  {max_prefix: "84-57-87",vendor: "DVR C&C Co., Ltd."},
  {max_prefix: "AC-3D-05",vendor: "Instorescreen Aisa"},
  {max_prefix: "50-4A-5E",vendor: "Masimo Corporation"},
  {max_prefix: "1C-7C-45",vendor: "Vitek Industrial Video Products, Inc."},
  {max_prefix: "3C-38-88",vendor: "ConnectQuest, llc"},
  {max_prefix: "48-D7-FF",vendor: "BLANKOM Antennentechnik GmbH"},
  {max_prefix: "C4-71-30",vendor: "Fon Technology S.L."},
  {max_prefix: "D4-EC-0C",vendor: "Harley-Davidson Motor Company"},
  {max_prefix: "6C-A9-6F",vendor: "TransPacket AS"},
  {max_prefix: "AC-01-42",vendor: "Uriel Technologies SIA"},
  {max_prefix: "54-2A-9C",vendor: "LSY Defense, LLC."},
  {max_prefix: "C4-3C-3C",vendor: "CYBELEC SA"},
  {max_prefix: "B8-26-D4",vendor: "Furukawa Industrial S.A. Produtos El\u00e9tricos"},
  {max_prefix: "B8-74-47",vendor: "Convergence Technologies"},
  {max_prefix: "74-63-DF",vendor: "VTS GmbH"},
  {max_prefix: "BC-12-5E",vendor: "Beijing  WisVideo  INC."},
  {max_prefix: "14-E4-EC",vendor: "mLogic LLC"},
  {max_prefix: "C8-F7-04",vendor: "Building Block Video"},
  {max_prefix: "50-8A-42",vendor: "Uptmate Technology Co., LTD"},
  {max_prefix: "BC-EA-2B",vendor: "CityCom GmbH"},
  {max_prefix: "A4-56-30",vendor: "Cisco Systems, Inc"},
  {max_prefix: "0C-9E-91",vendor: "Sankosha Corporation"},
  {max_prefix: "C8-F9-F9",vendor: "Cisco Systems, Inc"},
  {max_prefix: "80-DB-31",vendor: "Power Quotient International Co., Ltd."},
  {max_prefix: "E0-5D-A6",vendor: "Detlef Fink Elektronik & Softwareentwicklung"},
  {max_prefix: "84-6A-ED",vendor: "Wireless Tsukamoto.,co.LTD"},
  {max_prefix: "04-5A-95",vendor: "Nokia Corporation"},
  {max_prefix: "04-F4-BC",vendor: "Xena Networks"},
  {max_prefix: "6C-3A-84",vendor: "Shenzhen Aero-Startech. Co.Ltd"},
  {max_prefix: "00-D6-32",vendor: "GE Energy"},
  {max_prefix: "1C-51-B5",vendor: "Techaya LTD"},
  {max_prefix: "D0-1A-A7",vendor: "UniPrint"},
  {max_prefix: "38-3F-10",vendor: "DBL Technology Ltd."},
  {max_prefix: "28-60-94",vendor: "CAPELEC"},
  {max_prefix: "AC-D3-64",vendor: "ABB SPA, ABB SACE DIV."},
  {max_prefix: "A4-EF-52",vendor: "Telewave Co., Ltd."},
  {max_prefix: "A8-26-D9",vendor: "HTC Corporation"},
  {max_prefix: "28-94-0F",vendor: "Cisco Systems, Inc"},
  {max_prefix: "B8-DA-F7",vendor: "Advanced Photonics, Inc."},
  {max_prefix: "14-3A-EA",vendor: "Dynapower Company LLC"},
  {max_prefix: "A0-86-EC",vendor: "SAEHAN HITEC Co., Ltd"},
  {max_prefix: "94-2E-17",vendor: "Schneider Electric Canada Inc"},
  {max_prefix: "98-FE-03",vendor: "Ericsson - North America"},
  {max_prefix: "20-AA-4B",vendor: "Cisco-Linksys, LLC"},
  {max_prefix: "CC-94-4A",vendor: "Pfeiffer Vacuum GmbH"},
  {max_prefix: "0C-85-25",vendor: "Cisco Systems, Inc"},
  {max_prefix: "B4-D8-A9",vendor: "BetterBots"},
  {max_prefix: "7C-C8-D7",vendor: "Damalisk"},
  {max_prefix: "C4-60-44",vendor: "Everex Electronics Limited"},
  {max_prefix: "9C-B0-08",vendor: "Ubiquitous Computing Technology Corporation"},
  {max_prefix: "A8-77-6F",vendor: "Zonoff"},
  {max_prefix: "00-FA-3B",vendor: "CLOOS ELECTRONIC GMBH"},
  {max_prefix: "28-38-CF",vendor: "Gen2wave"},
  {max_prefix: "E0-3C-5B",vendor: "SHENZHEN JIAXINJIE ELECTRON CO.,LTD"},
  {max_prefix: "38-28-EA",vendor: "Fujian Netcom Technology Co., LTD"},
  {max_prefix: "2C-EE-26",vendor: "Petroleum Geo-Services"},
  {max_prefix: "FC-8F-C4",vendor: "Intelligent Technology Inc."},
  {max_prefix: "54-1D-FB",vendor: "Freestyle Energy Ltd"},
  {max_prefix: "60-B6-06",vendor: "Phorus"},
  {max_prefix: "90-92-B4",vendor: "Diehl BGT Defence GmbH & Co. KG"},
  {max_prefix: "FC-45-5F",vendor: "JIANGXI SHANSHUI OPTOELECTRONIC TECHNOLOGY CO.,LTD"},
  {max_prefix: "48-33-DD",vendor: "ZENNIO AVANCE Y TECNOLOGIA, S.L."},
  {max_prefix: "10-FC-54",vendor: "Shany Electronic Co., Ltd."},
  {max_prefix: "C0-29-73",vendor: "Audyssey Laboratories Inc."},
  {max_prefix: "98-AA-D7",vendor: "BLUE WAVE NETWORKING CO LTD"},
  {max_prefix: "9C-53-CD",vendor: "ENGICAM s.r.l."},
  {max_prefix: "60-86-45",vendor: "Avery Weigh-Tronix, LLC"},
  {max_prefix: "64-C5-AA",vendor: "South African Broadcasting Corporation"},
  {max_prefix: "CC-6D-EF",vendor: "TJK Tietolaite Oy"},
  {max_prefix: "A8-5B-F3",vendor: "Audivo GmbH"},
  {max_prefix: "B8-97-5A",vendor: "BIOSTAR Microtech Int'l Corp."},
  {max_prefix: "24-C0-B3",vendor: "RSF"},
  {max_prefix: "60-35-53",vendor: "Buwon Technology"},
  {max_prefix: "E0-39-D7",vendor: "Plexxi, Inc."},
  {max_prefix: "24-BC-82",vendor: "Dali Wireless, Inc."},
  {max_prefix: "08-75-72",vendor: "Obelux Oy"},
  {max_prefix: "10-C2-BA",vendor: "UTT Co., Ltd."},
  {max_prefix: "90-D7-4F",vendor: "Bookeen"},
  {max_prefix: "50-0B-32",vendor: "Foxda Technology Industrial(ShenZhen)Co.,LTD"},
  {max_prefix: "F0-4A-2B",vendor: "PYRAMID Computer GmbH"},
  {max_prefix: "4C-32-D9",vendor: "M Rutty Holdings Pty. Ltd."},
  {max_prefix: "68-CD-0F",vendor: "U Tek Company Limited"},
  {max_prefix: "A4-E3-91",vendor: "DENY FONTAINE"},
  {max_prefix: "60-3F-C5",vendor: "COX CO., LTD"},
  {max_prefix: "AC-6F-D9",vendor: "Valueplus Inc."},
  {max_prefix: "90-A7-83",vendor: "JSW PACIFIC CORPORATION"},
  {max_prefix: "28-AF-0A",vendor: "Sirius XM Radio Inc"},
  {max_prefix: "5C-D4-AB",vendor: "Zektor"},
  {max_prefix: "08-FC-52",vendor: "OpenXS BV"},
  {max_prefix: "F8-46-2D",vendor: "SYNTEC Incorporation"},
  {max_prefix: "78-A5-DD",vendor: "Shenzhen Smarteye Digital Electronics Co., Ltd"},
  {max_prefix: "EC-E7-44",vendor: "Omntec mfg. inc"},
  {max_prefix: "28-D1-AF",vendor: "Nokia Corporation"},
  {max_prefix: "64-E8-4F",vendor: "Serialway Communication Technology Co. Ltd"},
  {max_prefix: "2C-9E-FC",vendor: "CANON INC."},
  {max_prefix: "DC-1E-A3",vendor: "Accensus LLC"},
  {max_prefix: "A4-01-30",vendor: "ABIsystems Co., LTD"},
  {max_prefix: "30-2D-E8",vendor: "JDA, LLC (JDA Systems)"},
  {max_prefix: "48-A6-D2",vendor: "GJsun Optical Science and Tech Co.,Ltd."},
  {max_prefix: "7C-33-6E",vendor: "MEG Electronics Inc."},
  {max_prefix: "18-2B-05",vendor: "8D Technologies"},
  {max_prefix: "08-A1-2B",vendor: "ShenZhen EZL Technology Co., Ltd"},
  {max_prefix: "A0-0C-A1",vendor: "SKTB SKiT"},
  {max_prefix: "F8-F7-D3",vendor: "International Communications Corporation"},
  {max_prefix: "D4-E3-3F",vendor: "Nokia"},
  {max_prefix: "B4-0C-25",vendor: "Palo Alto Networks"},
  {max_prefix: "24-0B-B1",vendor: "KOSTAL Industrie Elektrik GmbH"},
  {max_prefix: "20-EE-C6",vendor: "Elefirst Science & Tech Co ., ltd"},
  {max_prefix: "E0-1E-07",vendor: "Anite Telecoms  US. Inc"},
  {max_prefix: "7C-6B-33",vendor: "Tenyu Tech Co. Ltd."},
  {max_prefix: "64-D9-89",vendor: "Cisco Systems, Inc"},
  {max_prefix: "14-7D-C5",vendor: "Murata Manufacturing Co., Ltd."},
  {max_prefix: "00-B9-F6",vendor: "Shenzhen Super Rich Electronics Co.,Ltd"},
  {max_prefix: "FC-C2-3D",vendor: "Atmel Corporation"},
  {max_prefix: "64-43-46",vendor: "GuangDong Quick Network Computer CO.,LTD"},
  {max_prefix: "CC-E7-DF",vendor: "American Magnetics, Inc."},
  {max_prefix: "A4-46-FA",vendor: "AmTRAN Video Corporation"},
  {max_prefix: "28-04-E0",vendor: "FERMAX ELECTRONICA S.A.U."},
  {max_prefix: "04-62-D7",vendor: "ALSTOM HYDRO FRANCE"},
  {max_prefix: "D4-50-7A",vendor: "CEIVA Logic, Inc"},
  {max_prefix: "88-E7-A6",vendor: "iKnowledge Integration Corp."},
  {max_prefix: "D4-02-4A",vendor: "Delphian Systems LLC"},
  {max_prefix: "00-41-B4",vendor: "Wuxi Zhongxing Optoelectronics Technology Co.,Ltd."},
  {max_prefix: "F4-44-50",vendor: "BND Co., Ltd."},
  {max_prefix: "64-5D-D7",vendor: "Shenzhen Lifesense Medical Electronics Co., Ltd."},
  {max_prefix: "EC-46-70",vendor: "Meinberg Funkuhren GmbH & Co. KG"},
  {max_prefix: "D0-5A-0F",vendor: "I-BT DIGITAL CO.,LTD"},
  {max_prefix: "EC-96-81",vendor: "2276427 Ontario Inc"},
  {max_prefix: "5C-07-6F",vendor: "Thought Creator"},
  {max_prefix: "3C-0F-C1",vendor: "KBC Networks"},
  {max_prefix: "58-E6-36",vendor: "EVRsafe Technologies"},
  {max_prefix: "10-F9-EE",vendor: "Nokia Corporation"},
  {max_prefix: "74-2B-0F",vendor: "Infinidat Ltd."},
  {max_prefix: "C8-F9-81",vendor: "Seneca s.r.l."},
  {max_prefix: "14-30-7A",vendor: "Avermetrics"},
  {max_prefix: "A0-6C-EC",vendor: "RIM"},
  {max_prefix: "24-49-7B",vendor: "Innovative Converged Devices Inc"},
  {max_prefix: "98-E7-9A",vendor: "Foxconn(NanJing) Communication Co.,Ltd."},
  {max_prefix: "A0-E9-DB",vendor: "Ningbo FreeWings Technologies Co.,Ltd"},
  {max_prefix: "78-89-73",vendor: "CMC"},
  {max_prefix: "20-37-06",vendor: "Cisco Systems, Inc"},
  {max_prefix: "90-B9-7D",vendor: "Johnson Outdoors Marine Electronics d/b/a Minnkota"},
  {max_prefix: "F4-B1-64",vendor: "Lightning Telecommunications Technology Co. Ltd"},
  {max_prefix: "70-B0-35",vendor: "Shenzhen Zowee Technology Co., Ltd"},
  {max_prefix: "88-21-E3",vendor: "Nebusens, S.L."},
  {max_prefix: "7C-F4-29",vendor: "NUUO Inc."},
  {max_prefix: "10-45-BE",vendor: "Norphonic AS"},
  {max_prefix: "8C-82-A8",vendor: "Insigma Technology Co.,Ltd"},
  {max_prefix: "CC-B5-5A",vendor: "Fraunhofer ITWM"},
  {max_prefix: "AC-8A-CD",vendor: "ROGER D.Wensker, G.Wensker sp.j."},
  {max_prefix: "98-42-46",vendor: "SOL INDUSTRY PTE., LTD"},
  {max_prefix: "34-29-EA",vendor: "MCD ELECTRONICS SP. Z O.O."},
  {max_prefix: "28-A5-74",vendor: "Miller Electric Mfg. Co."},
  {max_prefix: "90-B8-D0",vendor: "Joyent, Inc."},
  {max_prefix: "F8-03-32",vendor: "Khomp"},
  {max_prefix: "60-19-0C",vendor: "RRAMAC"},
  {max_prefix: "D0-5F-CE",vendor: "Hitachi Data Systems"},
  {max_prefix: "D4-A4-25",vendor: "SMAX Technology Co., Ltd."},
  {max_prefix: "8C-11-CB",vendor: "ABUS Security-Center GmbH & Co. KG"},
  {max_prefix: "D0-9B-05",vendor: "Emtronix"},
  {max_prefix: "AC-47-23",vendor: "Genelec"},
  {max_prefix: "E8-BA-70",vendor: "Cisco Systems, Inc"},
  {max_prefix: "FC-83-29",vendor: "Trei technics"},
  {max_prefix: "14-EB-33",vendor: "BSMediasoft Co., Ltd."},
  {max_prefix: "88-B1-68",vendor: "Delta Control GmbH"},
  {max_prefix: "AC-86-74",vendor: "Open Mesh, Inc."},
  {max_prefix: "68-87-6B",vendor: "INQ Mobile Limited"},
  {max_prefix: "1C-AA-07",vendor: "Cisco Systems, Inc"},
  {max_prefix: "68-5B-36",vendor: "POWERTECH INDUSTRIAL CO., LTD."},
  {max_prefix: "28-EE-2C",vendor: "Frontline Test Equipment"},
  {max_prefix: "78-2E-EF",vendor: "Nokia Corporation"},
  {max_prefix: "7C-F0-BA",vendor: "Linkwell Telesystems Pvt Ltd"},
  {max_prefix: "94-D9-3C",vendor: "ENELPS"},
  {max_prefix: "B8-BE-BF",vendor: "Cisco Systems, Inc"},
  {max_prefix: "64-B6-4A",vendor: "ViVOtech, Inc."},
  {max_prefix: "8C-44-35",vendor: "Shanghai BroadMobi Communication Technology Co., Ltd."},
  {max_prefix: "F8-1D-93",vendor: "Longdhua(Beijing) Controls Technology Co.,Ltd"},
  {max_prefix: "CC-F8-41",vendor: "Lumewave"},
  {max_prefix: "D8-DF-0D",vendor: "beroNet GmbH"},
  {max_prefix: "AC-F9-7E",vendor: "ELESYS INC."},
  {max_prefix: "20-40-05",vendor: "feno GmbH"},
  {max_prefix: "64-D2-41",vendor: "Keith & Koep GmbH"},
  {max_prefix: "18-B7-9E",vendor: "Invoxia"},
  {max_prefix: "28-14-71",vendor: "Lantis co., LTD."},
  {max_prefix: "38-D1-35",vendor: "EasyIO Corporation Sdn. Bhd."},
  {max_prefix: "90-19-00",vendor: "SCS SA"},
  {max_prefix: "D4-5D-42",vendor: "Nokia Corporation"},
  {max_prefix: "B0-38-29",vendor: "Siliconware Precision Industries Co., Ltd."},
  {max_prefix: "7C-6C-39",vendor: "PIXSYS SRL"},
  {max_prefix: "BC-28-46",vendor: "NextBIT Computing Pvt. Ltd."},
  {max_prefix: "BC-0F-2B",vendor: "FORTUNE TECHGROUP CO.,LTD"},
  {max_prefix: "30-EB-25",vendor: "INTEK DIGITAL"},
  {max_prefix: "44-E4-D9",vendor: "Cisco Systems, Inc"},
  {max_prefix: "AC-CA-54",vendor: "Telldus Technologies AB"},
  {max_prefix: "64-81-25",vendor: "Alphatron Marine BV"},
  {max_prefix: "8C-F9-C9",vendor: "MESADA Technology Co.,Ltd."},
  {max_prefix: "04-26-05",vendor: "Bosch Building Automation GmbH"},
  {max_prefix: "24-F0-FF",vendor: "GHT Co., Ltd."},
  {max_prefix: "C0-62-6B",vendor: "Cisco Systems, Inc"},
  {max_prefix: "94-E2-26",vendor: "D. ORtiz Consulting, LLC"},
  {max_prefix: "18-B3-BA",vendor: "Netlogic AB"},
  {max_prefix: "D4-7B-75",vendor: "HARTING Electronics GmbH"},
  {max_prefix: "8C-5F-DF",vendor: "Beijing Railway Signal Factory"},
  {max_prefix: "30-0B-9C",vendor: "Delta Mobile Systems, Inc."},
  {max_prefix: "D4-6F-42",vendor: "WAXESS USA Inc"},
  {max_prefix: "04-C5-A4",vendor: "Cisco Systems, Inc"},
  {max_prefix: "6C-AD-3F",vendor: "Hubbell Building Automation, Inc."},
  {max_prefix: "9C-C0-D2",vendor: "Conductix-Wampfler GmbH"},
  {max_prefix: "DC-D8-7F",vendor: "Shenzhen JoinCyber Telecom Equipment Ltd"},
  {max_prefix: "B4-E0-CD",vendor: "Fusion-io, Inc"},
  {max_prefix: "28-60-46",vendor: "Lantech Communications Global, Inc."},
  {max_prefix: "10-E2-D5",vendor: "Qi Hardware Inc."},
  {max_prefix: "30-39-55",vendor: "Shenzhen Jinhengjia Electronic Co., Ltd."},
  {max_prefix: "FC-5B-24",vendor: "Weibel Scientific A/S"},
  {max_prefix: "EC-98-6C",vendor: "Lufft Mess- und Regeltechnik GmbH"},
  {max_prefix: "CC-F6-7A",vendor: "Ayecka Communication Systems LTD"},
  {max_prefix: "D8-C9-9D",vendor: "EA DISPLAY LIMITED"},
  {max_prefix: "10-83-D2",vendor: "Microseven Systems, LLC"},
  {max_prefix: "D0-93-F8",vendor: "Stonestreet One LLC"},
  {max_prefix: "9C-64-5E",vendor: "Harman Consumer Group"},
  {max_prefix: "1C-33-4D",vendor: "ITS Telecom"},
  {max_prefix: "4C-B9-C8",vendor: "CONET CO., LTD."},
  {max_prefix: "34-68-4A",vendor: "Teraworks Co., Ltd."},
  {max_prefix: "CC-FC-6D",vendor: "RIZ TRANSMITTERS"},
  {max_prefix: "E0-3E-7D",vendor: "data-complex GmbH"},
  {max_prefix: "0C-C6-AC",vendor: "DAGS"},
  {max_prefix: "78-59-3E",vendor: "RAFI GmbH & Co.KG"},
  {max_prefix: "50-97-72",vendor: "Westinghouse Digital"},
  {max_prefix: "8C-B6-4F",vendor: "Cisco Systems, Inc"},
  {max_prefix: "50-3D-E5",vendor: "Cisco Systems, Inc"},
  {max_prefix: "54-04-96",vendor: "Gigawave LTD"},
  {max_prefix: "EC-46-44",vendor: "TTK SAS"},
  {max_prefix: "80-65-E9",vendor: "BenQ Corporation"},
  {max_prefix: "20-4A-AA",vendor: "Hanscan Spain S.A."},
  {max_prefix: "60-C9-80",vendor: "Trymus"},
  {max_prefix: "A0-36-FA",vendor: "Ettus Research LLC"},
  {max_prefix: "EC-83-6C",vendor: "RM Tech Co., Ltd."},
  {max_prefix: "20-FE-CD",vendor: "System In Frontier Inc."},
  {max_prefix: "F0-93-3A",vendor: "NxtConect"},
  {max_prefix: "5C-F3-FC",vendor: "IBM Corp"},
  {max_prefix: "58-2F-42",vendor: "Universal Electric Corporation"},
  {max_prefix: "04-74-A1",vendor: "Aligera Equipamentos Digitais Ltda"},
  {max_prefix: "5C-69-84",vendor: "NUVICO"},
  {max_prefix: "B8-41-5F",vendor: "ASP AG"},
  {max_prefix: "2C-B6-9D",vendor: "RED Digital Cinema"},
  {max_prefix: "A8-6A-6F",vendor: "RIM"},
  {max_prefix: "50-0E-6D",vendor: "TrafficCast International"},
  {max_prefix: "EC-3B-F0",vendor: "NovelSat"},
  {max_prefix: "70-DD-A1",vendor: "Tellabs"},
  {max_prefix: "94-D0-19",vendor: "Cydle Corp."},
  {max_prefix: "8C-27-8A",vendor: "Vocollect Inc"},
  {max_prefix: "CC-0C-DA",vendor: "Miljovakt AS"},
  {max_prefix: "E4-1C-4B",vendor: "V2 TECHNOLOGY, INC."},
  {max_prefix: "B8-FF-6F",vendor: "Shanghai Typrotech Technology Co.Ltd"},
  {max_prefix: "68-12-2D",vendor: "Special Instrument Development Co., Ltd."},
  {max_prefix: "94-F7-20",vendor: "Tianjin Deviser Electronics Instrument Co., Ltd"},
  {max_prefix: "DC-9C-52",vendor: "Sapphire Technology Limited."},
  {max_prefix: "48-91-F6",vendor: "Shenzhen Reach software technology CO.,LTD"},
  {max_prefix: "64-9B-24",vendor: "V Technology Co., Ltd."},
  {max_prefix: "84-6E-B1",vendor: "Park Assist LLC"},
  {max_prefix: "6C-50-4D",vendor: "Cisco Systems, Inc"},
  {max_prefix: "B4-14-89",vendor: "Cisco Systems, Inc"},
  {max_prefix: "A0-B5-DA",vendor: "HongKong THTF Co., Ltd"},
  {max_prefix: "88-86-A0",vendor: "Simton Technologies, Ltd."},
  {max_prefix: "A4-50-55",vendor: "BUSWARE.DE"},
  {max_prefix: "AC-AB-8D",vendor: "Lyngso Marine A/S"},
  {max_prefix: "60-83-B2",vendor: "GkWare e.K."},
  {max_prefix: "80-D0-19",vendor: "Embed, Inc"},
  {max_prefix: "68-EB-C5",vendor: "Angstrem Telecom"},
  {max_prefix: "E8-99-5A",vendor: "PiiGAB, Processinformation i Goteborg AB"},
  {max_prefix: "40-1D-59",vendor: "Biometric Associates, LP"},
  {max_prefix: "B8-D0-6F",vendor: "GUANGZHOU HKUST FOK YING TUNG RESEARCH INSTITUTE"},
  {max_prefix: "EC-14-F6",vendor: "BioControl AS"},
  {max_prefix: "1C-BD-0E",vendor: "Amplified Engineering Pty Ltd"},
  {max_prefix: "A0-F2-17",vendor: "GE Medical System(China) Co., Ltd."},
  {max_prefix: "F0-A7-64",vendor: "GST Co., Ltd."},
  {max_prefix: "1C-06-56",vendor: "IDY Corporation"},
  {max_prefix: "4C-ED-DE",vendor: "ASKEY COMPUTER CORP"},
  {max_prefix: "E8-E0-8F",vendor: "GRAVOTECH MARKING SAS"},
  {max_prefix: "A8-9B-10",vendor: "inMotion Ltd."},
  {max_prefix: "A4-A8-0F",vendor: "Shenzhen Coship Electronics Co., Ltd."},
  {max_prefix: "F8-B5-99",vendor: "Guangzhou CHNAVS Digital Technology Co.,Ltd"},
  {max_prefix: "B8-92-1D",vendor: "BG T&A"},
  {max_prefix: "80-C6-CA",vendor: "Endian s.r.l."},
  {max_prefix: "C8-8B-47",vendor: "Nolangroup S.P.A con Socio Unico"},
  {max_prefix: "C4-CD-45",vendor: "Beijing Boomsense Technology CO.,LTD."},
  {max_prefix: "54-FD-BF",vendor: "Scheidt & Bachmann GmbH"},
  {max_prefix: "D0-BB-80",vendor: "SHL Telemedicine International Ltd."},
  {max_prefix: "E0-61-B2",vendor: "HANGZHOU ZENOINTEL TECHNOLOGY CO., LTD"},
  {max_prefix: "94-11-DA",vendor: "ITF Fr\u00f6schl GmbH"},
  {max_prefix: "80-39-E5",vendor: "PATLITE CORPORATION"},
  {max_prefix: "D0-89-99",vendor: "APCON, Inc."},
  {max_prefix: "C8-84-47",vendor: "Beautiful Enterprise Co., Ltd"},
  {max_prefix: "5C-17-D3",vendor: "LGE"},
  {max_prefix: "1C-DF-0F",vendor: "Cisco Systems, Inc"},
  {max_prefix: "68-BD-AB",vendor: "Cisco Systems, Inc"},
  {max_prefix: "9C-AD-EF",vendor: "Obihai Technology, Inc."},
  {max_prefix: "90-90-3C",vendor: "TRISON TECHNOLOGY CORPORATION"},
  {max_prefix: "8C-E7-B3",vendor: "Sonardyne International Ltd"},
  {max_prefix: "08-8D-C8",vendor: "Ryowa Electronics Co.,Ltd"},
  {max_prefix: "94-E7-11",vendor: "Xirka Dama Persada PT"},
  {max_prefix: "70-76-F0",vendor: "LevelOne Communications (India) Private Limited"},
  {max_prefix: "B0-81-D8",vendor: "I-sys Corp"},
  {max_prefix: "6C-9B-02",vendor: "Nokia Corporation"},
  {max_prefix: "64-1E-81",vendor: "Dowslake Microsystems"},
  {max_prefix: "EC-54-2E",vendor: "Shanghai XiMei Electronic Technology Co. Ltd"},
  {max_prefix: "F0-E5-C3",vendor: "Dr\u00e4gerwerk AG & Co. KG aA"},
  {max_prefix: "5C-CA-32",vendor: "Theben AG"},
  {max_prefix: "C0-2B-FC",vendor: "iNES. applied informatics GmbH"},
  {max_prefix: "94-C7-AF",vendor: "Raylios Technology"},
  {max_prefix: "04-36-04",vendor: "Gyeyoung I&T"},
  {max_prefix: "A4-B2-A7",vendor: "Adaxys Solutions AG"},
  {max_prefix: "D0-D0-FD",vendor: "Cisco Systems, Inc"},
  {max_prefix: "14-FE-AF",vendor: "SAGITTAR LIMITED"},
  {max_prefix: "DC-7B-94",vendor: "Cisco Systems, Inc"},
  {max_prefix: "D8-1C-14",vendor: "Compacta International, Ltd."},
  {max_prefix: "00-8C-10",vendor: "Black Box Corp."},
  {max_prefix: "B0-B8-D5",vendor: "Nanjing Nengrui Auto Equipment CO.,Ltd"},
  {max_prefix: "D8-29-86",vendor: "Best Wish Technology LTD"},
  {max_prefix: "44-61-32",vendor: "ecobee inc"},
  {max_prefix: "E0-5B-70",vendor: "Innovid, Co., Ltd."},
  {max_prefix: "F4-1F-0B",vendor: "YAMABISHI Corporation"},
  {max_prefix: "A0-82-C7",vendor: "P.T.I Co.,LTD"},
  {max_prefix: "E8-7A-F3",vendor: "S5 Tech S.r.l."},
  {max_prefix: "74-15-E2",vendor: "Tri-Sen Systems Corporation"},
  {max_prefix: "D4-8F-AA",vendor: "Sogecam Industrial, S.A."},
  {max_prefix: "98-FC-11",vendor: "Cisco-Linksys, LLC"},
  {max_prefix: "34-E0-D7",vendor: "DONGGUAN QISHENG ELECTRONICS INDUSTRIAL CO., LTD"},
  {max_prefix: "D8-46-06",vendor: "Silicon Valley Global Marketing"},
  {max_prefix: "CC-5C-75",vendor: "Weightech Com. Imp. Exp. Equip. Pesagem Ltda"},
  {max_prefix: "90-E0-F0",vendor: "IEEE 1722a Working Group"},
  {max_prefix: "FC-D4-F6",vendor: "Messana Air.Ray Conditioning s.r.l."},
  {max_prefix: "D4-66-A8",vendor: "Riedo Networks Ltd"},
  {max_prefix: "F8-AC-6D",vendor: "Deltenna Ltd"},
  {max_prefix: "18-B2-09",vendor: "Torrey Pines Logic, Inc"},
  {max_prefix: "40-52-0D",vendor: "Pico Technology"},
  {max_prefix: "80-7D-1B",vendor: "Neosystem Co. Ltd."},
  {max_prefix: "C8-48-F5",vendor: "MEDISON Xray Co., Ltd"},
  {max_prefix: "18-80-CE",vendor: "Barberry Solutions Ltd"},
  {max_prefix: "D8-4B-2A",vendor: "Cognitas Technologies, Inc."},
  {max_prefix: "68-4B-88",vendor: "Galtronics Telemetry Inc."},
  {max_prefix: "A4-AE-9A",vendor: "Maestro Wireless Solutions ltd."},
  {max_prefix: "78-A7-14",vendor: "Amphenol"},
  {max_prefix: "F4-50-EB",vendor: "Telechips Inc"},
  {max_prefix: "98-8E-DD",vendor: "TE Connectivity Limerick"},
  {max_prefix: "14-1B-BD",vendor: "Volex Inc."},
  {max_prefix: "A4-56-1B",vendor: "MCOT Corporation"},
  {max_prefix: "80-C6-3F",vendor: "Remec Broadband Wireless , LLC"},
  {max_prefix: "40-D4-0E",vendor: "Biodata Ltd"},
  {max_prefix: "0C-82-6A",vendor: "Wuhan Huagong Genuine Optics Technology Co., Ltd"},
  {max_prefix: "7C-05-1E",vendor: "RAFAEL LTD."},
  {max_prefix: "78-66-AE",vendor: "ZTEC Instruments, Inc."},
  {max_prefix: "4C-02-2E",vendor: "CMR KOREA CO., LTD"},
  {max_prefix: "34-AA-EE",vendor: "Mikrovisatos Servisas UAB"},
  {max_prefix: "48-FC-B8",vendor: "Woodstream Corporation"},
  {max_prefix: "F8-93-F3",vendor: "VOLANS"},
  {max_prefix: "78-A2-A0",vendor: "Nintendo Co., Ltd."},
  {max_prefix: "24-B6-B8",vendor: "FRIEM SPA"},
  {max_prefix: "D4-00-0D",vendor: "Phoenix Broadband Technologies, LLC."},
  {max_prefix: "AC-51-35",vendor: "MPI TECH"},
  {max_prefix: "44-D6-3D",vendor: "Talari Networks"},
  {max_prefix: "08-F6-F8",vendor: "GET Engineering"},
  {max_prefix: "10-09-0C",vendor: "JANOME Corporation"},
  {max_prefix: "E0-1C-EE",vendor: "Bravo Tech, Inc."},
  {max_prefix: "74-B9-EB",vendor: "JinQianMao Technology Co.,Ltd."},
  {max_prefix: "D4-52-97",vendor: "nSTREAMS Technologies, Inc."},
  {max_prefix: "E0-27-1A",vendor: "TTC Next-generation Home Network System WG"},
  {max_prefix: "00-97-FF",vendor: "Heimann Sensor GmbH"},
  {max_prefix: "E4-AB-46",vendor: "UAB Selteka"},
  {max_prefix: "94-5B-7E",vendor: "TRILOBIT LTDA."},
  {max_prefix: "04-E5-48",vendor: "Cohda Wireless Pty Ltd"},
  {max_prefix: "28-93-FE",vendor: "Cisco Systems, Inc"},
  {max_prefix: "F4-C7-95",vendor: "WEY Technology AG"},
  {max_prefix: "78-11-85",vendor: "NBS Payment Solutions Inc."},
  {max_prefix: "E8-5E-53",vendor: "Infratec Datentechnik GmbH"},
  {max_prefix: "88-BA-7F",vendor: "Qfiednet Co., Ltd."},
  {max_prefix: "64-DB-18",vendor: "OpenPattern"},
  {max_prefix: "D0-58-75",vendor: "Active Control Technology Inc."},
  {max_prefix: "D8-1B-FE",vendor: "TWINLINX CORPORATION"},
  {max_prefix: "70-71-BC",vendor: "PEGATRON CORPORATION"},
  {max_prefix: "78-84-EE",vendor: "INDRA ESPACIO S.A."},
  {max_prefix: "FC-44-63",vendor: "Universal Audio, Inc"},
  {max_prefix: "90-A2-DA",vendor: "GHEO SA"},
  {max_prefix: "98-89-ED",vendor: "Anadem Information Inc."},
  {max_prefix: "04-2F-56",vendor: "ATOCS (Shenzhen) LTD"},
  {max_prefix: "C8-EF-2E",vendor: "Beijing Gefei Tech. Co., Ltd"},
  {max_prefix: "A4-C2-AB",vendor: "Hangzhou LEAD-IT Information & Technology Co.,Ltd"},
  {max_prefix: "48-AA-5D",vendor: "Store Electronic Systems"},
  {max_prefix: "0C-C9-C6",vendor: "Samwin Hong Kong Limited"},
  {max_prefix: "10-62-C9",vendor: "Adatis GmbH & Co. KG"},
  {max_prefix: "D8-AE-90",vendor: "Itibia Technologies"},
  {max_prefix: "B8-65-3B",vendor: "Bolymin, Inc."},
  {max_prefix: "38-E8-DF",vendor: "b gmbh medien + datenbanken"},
  {max_prefix: "1C-12-9D",vendor: "IEEE PES PSRC/SUB"},
  {max_prefix: "E0-CA-4D",vendor: "Shenzhen Unistar Communication Co.,LTD"},
  {max_prefix: "A0-69-86",vendor: "Wellav Technologies Ltd"},
  {max_prefix: "EC-8E-AD",vendor: "DLX"},
  {max_prefix: "F0-B6-EB",vendor: "Poslab Technology Co., Ltd."},
  {max_prefix: "1C-8F-8A",vendor: "Phase Motion Control SpA"},
  {max_prefix: "FC-CC-E4",vendor: "Ascon Ltd."},
  {max_prefix: "60-B3-C4",vendor: "Elber Srl"},
  {max_prefix: "04-C8-80",vendor: "Samtec Inc"},
  {max_prefix: "5C-35-DA",vendor: "There Corporation Oy"},
  {max_prefix: "3C-4C-69",vendor: "Infinity System S.L."},
  {max_prefix: "78-30-E1",vendor: "UltraClenz, LLC"},
  {max_prefix: "B0-91-34",vendor: "Taleo"},
  {max_prefix: "34-C6-9A",vendor: "Enecsys Ltd"},
  {max_prefix: "F8-8D-EF",vendor: "Tenebraex"},
  {max_prefix: "04-22-34",vendor: "Wireless Standard Extensions"},
  {max_prefix: "80-C8-62",vendor: "Openpeak, Inc"},
  {max_prefix: "10-2D-96",vendor: "Looxcie Inc."},
  {max_prefix: "58-50-E6",vendor: "Best Buy Corporation"},
  {max_prefix: "3C-1C-BE",vendor: "JADAK LLC"},
  {max_prefix: "7C-08-D9",vendor: "Shanghai B-Star Technology Co"},
  {max_prefix: "BC-D5-B6",vendor: "d2d technologies"},
  {max_prefix: "FC-68-3E",vendor: "Directed Perception, Inc"},
  {max_prefix: "28-E7-94",vendor: "Microtime Computer Inc."},
  {max_prefix: "A4-38-FC",vendor: "Plastic Logic"},
  {max_prefix: "18-FC-9F",vendor: "Changhe Electronics Co., Ltd."},
  {max_prefix: "94-59-2D",vendor: "EKE Building Technology Systems Ltd"},
  {max_prefix: "CC-69-B0",vendor: "Global Traffic Technologies, LLC"},
  {max_prefix: "A0-59-3A",vendor: "V.D.S. Video Display Systems srl"},
  {max_prefix: "CC-EA-1C",vendor: "DCONWORKS  Co., Ltd"},
  {max_prefix: "0C-D5-02",vendor: "Westell Technologies Inc."},
  {max_prefix: "68-7F-74",vendor: "Cisco-Linksys, LLC"},
  {max_prefix: "38-E9-8C",vendor: "Reco S.p.A."},
  {max_prefix: "34-EF-8B",vendor: "NTT Communications Corporation"},
  {max_prefix: "20-59-A0",vendor: "Paragon Technologies Inc."},
  {max_prefix: "0C-27-55",vendor: "Valuable Techologies Limited"},
  {max_prefix: "F8-47-2D",vendor: "X2gen Digital Corp. Ltd"},
  {max_prefix: "84-90-00",vendor: "Arnold&Richter Cine Technik GmbH & Co. Betriebs KG"},
  {max_prefix: "08-18-4C",vendor: "A. S. Thomas, Inc."},
  {max_prefix: "10-88-0F",vendor: "Daruma Telecomunica\u00e7\u00f5es e Inform\u00e1tica S.A."},
  {max_prefix: "FC-61-98",vendor: "NEC Personal Products, Ltd"},
  {max_prefix: "74-D8-50",vendor: "Evrisko Systems"},
  {max_prefix: "78-99-8F",vendor: "MEDILINE ITALIA SRL"},
  {max_prefix: "34-CE-94",vendor: "Parsec (Pty) Ltd"},
  {max_prefix: "24-D2-CC",vendor: "SmartDrive Systems Inc."},
  {max_prefix: "0C-EF-7C",vendor: "AnaCom Inc"},
  {max_prefix: "B0-5B-1F",vendor: "THERMO FISHER SCIENTIFIC S.P.A."},
  {max_prefix: "EC-E9-F8",vendor: "Guang Zhou TRI-SUN Electronics Technology  Co., Ltd"},
  {max_prefix: "D0-D2-86",vendor: "Beckman Coulter K.K."},
  {max_prefix: "BC-B1-81",vendor: "SHARP CORPORATION"},
  {max_prefix: "A0-BF-A5",vendor: "CORESYS"},
  {max_prefix: "C8-87-3B",vendor: "Net Optics"},
  {max_prefix: "2C-06-23",vendor: "Win Leader Inc."},
  {max_prefix: "A8-CE-90",vendor: "CVC"},
  {max_prefix: "E4-1F-13",vendor: "IBM Corp"},
  {max_prefix: "E4-FF-DD",vendor: "ELECTRON INDIA"},
  {max_prefix: "68-A1-B7",vendor: "Honghao Mingchuan Technology (Beijing) CO.,Ltd."},
  {max_prefix: "0C-D7-C2",vendor: "Axium Technologies, Inc."},
  {max_prefix: "C4-19-8B",vendor: "Dominion Voting Systems Corporation"},
  {max_prefix: "C8-3A-35",vendor: "Tenda Technology Co., Ltd."},
  {max_prefix: "6C-8C-DB",vendor: "Otus Technologies Ltd"},
  {max_prefix: "40-F5-2E",vendor: "Leica Microsystems (Schweiz) AG"},
  {max_prefix: "90-6D-C8",vendor: "DLG Automa\u00e7\u00e3o Industrial Ltda"},
  {max_prefix: "E8-4E-CE",vendor: "Nintendo Co., Ltd."},
  {max_prefix: "10-45-F8",vendor: "LNT-Automation GmbH"},
  {max_prefix: "DC-E7-1C",vendor: "AUG Elektronik GmbH"},
  {max_prefix: "A8-70-A5",vendor: "UniComm Inc."},
  {max_prefix: "44-56-B7",vendor: "Spawn Labs, Inc"},
  {max_prefix: "44-C9-A2",vendor: "Greenwald Industries"},
  {max_prefix: "40-61-86",vendor: "MICRO-STAR INT'L CO.,LTD"},
  {max_prefix: "58-4C-EE",vendor: "Digital One Technologies, Limited"},
  {max_prefix: "A0-73-32",vendor: "Cashmaster International Limited"},
  {max_prefix: "64-C6-AF",vendor: "AXERRA Networks Ltd"},
  {max_prefix: "70-1A-ED",vendor: "ADVAS CO., LTD."},
  {max_prefix: "64-65-C0",vendor: "Nuvon, Inc"},
  {max_prefix: "40-EC-F8",vendor: "Siemens AG"},
  {max_prefix: "54-B6-20",vendor: "SUHDOL E&C Co.Ltd."},
  {max_prefix: "78-C4-0E",vendor: "H&D Wireless"},
  {max_prefix: "44-56-8D",vendor: "PNC Technologies  Co., Ltd."},
  {max_prefix: "7C-1E-B3",vendor: "2N TELEKOMUNIKACE a.s."},
  {max_prefix: "00-26-DF",vendor: "TaiDoc Technology Corp."},
  {max_prefix: "00-26-D8",vendor: "Magic Point Inc."},
  {max_prefix: "00-26-D6",vendor: "Ningbo Andy Optoelectronic Co., Ltd."},
  {max_prefix: "00-26-F8",vendor: "Golden Highway Industry Development Co., Ltd."},
  {max_prefix: "00-26-F4",vendor: "Nesslab"},
  {max_prefix: "00-26-EE",vendor: "TKM GmbH"},
  {max_prefix: "00-26-EF",vendor: "Technology Advancement Group, Inc."},
  {max_prefix: "00-26-E6",vendor: "Visionhitech Co., Ltd."},
  {max_prefix: "00-27-01",vendor: "INCOstartec GmbH"},
  {max_prefix: "00-26-FB",vendor: "AirDio Wireless, Inc."},
  {max_prefix: "00-26-F5",vendor: "XRPLUS Inc."},
  {max_prefix: "00-26-B2",vendor: "Setrix GmbH"},
  {max_prefix: "00-26-AF",vendor: "Duelco A/S"},
  {max_prefix: "00-26-B3",vendor: "Thales Communications Inc"},
  {max_prefix: "00-27-21",vendor: "Shenzhen Baoan Fenda Industrial Co., Ltd"},
  {max_prefix: "00-26-D1",vendor: "S Squared Innovations Inc."},
  {max_prefix: "00-26-D3",vendor: "Zeno Information System"},
  {max_prefix: "00-26-CB",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-27-1A",vendor: "Geenovo Technology Ltd."},
  {max_prefix: "00-27-14",vendor: "Grainmustards, Co,ltd."},
  {max_prefix: "00-27-15",vendor: "Rebound Telecom. Co., Ltd"},
  {max_prefix: "00-27-0F",vendor: "Envisionnovation Inc"},
  {max_prefix: "00-27-0A",vendor: "IEE S.A."},
  {max_prefix: "00-27-09",vendor: "Nintendo Co., Ltd."},
  {max_prefix: "00-26-A2",vendor: "Instrumentation Technology Systems"},
  {max_prefix: "00-26-9F",vendor: "Private"},
  {max_prefix: "00-26-99",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-26-53",vendor: "DaySequerra Corporation"},
  {max_prefix: "00-26-47",vendor: "WFE TECHNOLOGY CORP."},
  {max_prefix: "00-26-6A",vendor: "ESSENSIUM NV"},
  {max_prefix: "00-26-6B",vendor: "SHINE UNION ENTERPRISE LIMITED"},
  {max_prefix: "00-26-67",vendor: "CARECOM CO.,LTD."},
  {max_prefix: "00-26-40",vendor: "Baustem Broadband Technologies, Ltd."},
  {max_prefix: "00-26-33",vendor: "MIR - Medical International Research"},
  {max_prefix: "00-26-30",vendor: "ACOREL S.A.S"},
  {max_prefix: "00-26-2A",vendor: "Proxense, LLC"},
  {max_prefix: "00-26-87",vendor: "corega K.K"},
  {max_prefix: "00-26-7B",vendor: "GSI Helmholtzzentrum f\u00fcr Schwerionenforschung GmbH"},
  {max_prefix: "00-26-8D",vendor: "CellTel S.p.A."},
  {max_prefix: "00-26-28",vendor: "companytec automa\u00e7\u00e3o e controle ltda."},
  {max_prefix: "00-26-1F",vendor: "SAE Magnetics (H.K.) Ltd."},
  {max_prefix: "00-26-1E",vendor: "QINGBANG ELEC(SZ) CO., LTD"},
  {max_prefix: "00-26-19",vendor: "FRC"},
  {max_prefix: "00-25-F0",vendor: "Suga Electronics Limited"},
  {max_prefix: "00-25-E8",vendor: "Idaho Technology"},
  {max_prefix: "00-25-E4",vendor: "OMNI-WiFi, LLC"},
  {max_prefix: "00-25-C9",vendor: "SHENZHEN HUAPU DIGITAL CO., LTD"},
  {max_prefix: "00-25-FA",vendor: "J&M Analytik AG"},
  {max_prefix: "00-25-EA",vendor: "Iphion BV"},
  {max_prefix: "00-25-BE",vendor: "Tektrap Systems Inc."},
  {max_prefix: "00-25-B5",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-25-FE",vendor: "Pilot Electronics Corporation"},
  {max_prefix: "00-25-D7",vendor: "CEDO"},
  {max_prefix: "00-25-D8",vendor: "KOREA MAINTENANCE"},
  {max_prefix: "00-25-D2",vendor: "InpegVision Co., Ltd"},
  {max_prefix: "00-25-5B",vendor: "CoachComm, LLC"},
  {max_prefix: "00-25-4D",vendor: "Singapore Technologies Electronics Limited"},
  {max_prefix: "00-25-4C",vendor: "Videon Central, Inc."},
  {max_prefix: "00-25-43",vendor: "MONEYTECH"},
  {max_prefix: "00-25-7B",vendor: "STJ  ELECTRONICS  PVT  LTD"},
  {max_prefix: "00-25-7C",vendor: "Huachentel Technology Development Co., Ltd"},
  {max_prefix: "00-25-75",vendor: "FiberPlex Technologies, LLC"},
  {max_prefix: "00-25-70",vendor: "Eastern Communications Company Limited"},
  {max_prefix: "00-25-96",vendor: "GIGAVISION srl"},
  {max_prefix: "00-25-95",vendor: "Northwest Signal Supply, Inc"},
  {max_prefix: "00-25-8F",vendor: "Trident Microsystems, Inc."},
  {max_prefix: "00-25-89",vendor: "Hills Industries Limited"},
  {max_prefix: "00-25-85",vendor: "KOKUYO S&T Co., Ltd."},
  {max_prefix: "00-25-51",vendor: "SE-Elektronic GmbH"},
  {max_prefix: "00-25-6A",vendor: "inIT - Institut Industrial IT"},
  {max_prefix: "00-25-62",vendor: "interbro Co. Ltd."},
  {max_prefix: "00-25-81",vendor: "x-star networks Inc."},
  {max_prefix: "00-25-A0",vendor: "Nintendo Co., Ltd."},
  {max_prefix: "00-25-9B",vendor: "Beijing PKUNITY Microsystems Technology Co., Ltd"},
  {max_prefix: "00-25-3A",vendor: "CEVA, Ltd."},
  {max_prefix: "00-24-F7",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-24-F3",vendor: "Nintendo Co., Ltd."},
  {max_prefix: "00-24-E0",vendor: "DS Tech, LLC"},
  {max_prefix: "00-24-E2",vendor: "HASEGAWA ELECTRIC CO.,LTD."},
  {max_prefix: "00-25-16",vendor: "Integrated Design Tools, Inc."},
  {max_prefix: "00-25-10",vendor: "Pico-Tesla Magnetic Therapies"},
  {max_prefix: "00-25-20",vendor: "SMA Railway Technology GmbH"},
  {max_prefix: "00-25-27",vendor: "Bitrode Corp."},
  {max_prefix: "00-25-25",vendor: "CTERA Networks Ltd."},
  {max_prefix: "00-24-C8",vendor: "Broadband Solutions Group"},
  {max_prefix: "00-24-C5",vendor: "Meridian Audio Limited"},
  {max_prefix: "00-24-E5",vendor: "Seer Technology, Inc"},
  {max_prefix: "00-25-01",vendor: "JSC Supertel"},
  {max_prefix: "00-24-58",vendor: "PA Bastion CC"},
  {max_prefix: "00-24-5D",vendor: "Terberg besturingstechniek B.V."},
  {max_prefix: "00-24-50",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-24-96",vendor: "Ginzinger electronic systems"},
  {max_prefix: "00-24-99",vendor: "Aquila Technologies"},
  {max_prefix: "00-24-8A",vendor: "Kaga Electronics Co., Ltd."},
  {max_prefix: "00-24-87",vendor: "Transact Campus, Inc."},
  {max_prefix: "00-24-B9",vendor: "Wuhan Higheasy Electronic Technology Development Co.Ltd"},
  {max_prefix: "00-24-BD",vendor: "Hainzl Industriesysteme GmbH"},
  {max_prefix: "00-24-77",vendor: "Tibbo Technology"},
  {max_prefix: "00-24-6E",vendor: "Phihong USA Corp."},
  {max_prefix: "00-24-73",vendor: "3COM EUROPE LTD"},
  {max_prefix: "00-24-51",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-24-4A",vendor: "Voyant International"},
  {max_prefix: "00-24-47",vendor: "Kaztek Systems"},
  {max_prefix: "00-24-6B",vendor: "Covia, Inc."},
  {max_prefix: "00-24-35",vendor: "WIDE CORPORATION"},
  {max_prefix: "00-24-31",vendor: "Uni-v co.,ltd"},
  {max_prefix: "00-24-32",vendor: "Neostar Technology Co.,LTD"},
  {max_prefix: "00-24-30",vendor: "Ruby Tech Corp."},
  {max_prefix: "00-24-2E",vendor: "Datastrip Inc."},
  {max_prefix: "00-23-F9",vendor: "Double-Take Software, INC."},
  {max_prefix: "00-23-F5",vendor: "WILO SE"},
  {max_prefix: "00-23-FF",vendor: "Beijing HTTC Technology Ltd."},
  {max_prefix: "00-23-F6",vendor: "Softwell Technology Co., Ltd."},
  {max_prefix: "00-23-F3",vendor: "Glocom, Inc."},
  {max_prefix: "00-23-F0",vendor: "Shanghai Jinghan Weighing Apparatus Co. Ltd."},
  {max_prefix: "00-24-1A",vendor: "Red Beetle Inc."},
  {max_prefix: "00-24-14",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-24-10",vendor: "NUETEQ Technology,Inc."},
  {max_prefix: "00-23-CC",vendor: "Nintendo Co., Ltd."},
  {max_prefix: "00-24-43",vendor: "Nortel Networks"},
  {max_prefix: "00-24-41",vendor: "Wanzl Metallwarenfabrik GmbH"},
  {max_prefix: "00-24-3B",vendor: "CSSI (S) Pte Ltd"},
  {max_prefix: "00-24-08",vendor: "Pacific Biosciences"},
  {max_prefix: "00-24-0C",vendor: "DELEC GmbH"},
  {max_prefix: "00-23-EA",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-23-E5",vendor: "IPaXiom Networks"},
  {max_prefix: "00-23-50",vendor: "RDC, Inc. dba LynTec"},
  {max_prefix: "00-23-4F",vendor: "Luminous Power Technologies Pvt. Ltd."},
  {max_prefix: "00-23-49",vendor: "Helmholtz Centre Berlin for Material and Energy"},
  {max_prefix: "00-23-46",vendor: "Vestac"},
  {max_prefix: "00-23-44",vendor: "Objective Interface Systems, Inc."},
  {max_prefix: "00-23-B6",vendor: "SECURITE COMMUNICATIONS / HONEYWELL"},
  {max_prefix: "00-23-B8",vendor: "Sichuan Jiuzhou Electronic Technology Co.,Ltd"},
  {max_prefix: "00-23-BA",vendor: "Chroma"},
  {max_prefix: "00-23-BC",vendor: "EQ-SYS GmbH"},
  {max_prefix: "00-23-B1",vendor: "Longcheer Technology (Singapore) Pte Ltd"},
  {max_prefix: "00-23-9A",vendor: "EasyData Hardware GmbH"},
  {max_prefix: "00-23-96",vendor: "ANDES TECHNOLOGY CORPORATION"},
  {max_prefix: "00-23-94",vendor: "Samjeon"},
  {max_prefix: "00-23-A1",vendor: "Trend Electronics Ltd"},
  {max_prefix: "00-23-A6",vendor: "E-Mon"},
  {max_prefix: "00-23-A8",vendor: "Marshall Electronics"},
  {max_prefix: "00-23-77",vendor: "Isotek Electronics Ltd"},
  {max_prefix: "00-23-71",vendor: "SOAM Systel"},
  {max_prefix: "00-23-64",vendor: "Power Instruments Pte Ltd"},
  {max_prefix: "00-23-8C",vendor: "Private"},
  {max_prefix: "00-23-79",vendor: "Union Business Machines Co. Ltd."},
  {max_prefix: "00-23-62",vendor: "Goldline Controls"},
  {max_prefix: "00-23-5E",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-23-58",vendor: "SYSTEL SA"},
  {max_prefix: "00-23-56",vendor: "Packet Forensics LLC"},
  {max_prefix: "00-23-C0",vendor: "Broadway Networks"},
  {max_prefix: "00-22-BB",vendor: "beyerdynamic GmbH & Co. KG"},
  {max_prefix: "00-22-B6",vendor: "Superflow Technologies Group"},
  {max_prefix: "00-22-B5",vendor: "NOVITA"},
  {max_prefix: "00-22-B2",vendor: "4RF Communications Ltd"},
  {max_prefix: "00-22-AC",vendor: "Hangzhou Siyuan Tech. Co., Ltd"},
  {max_prefix: "00-22-AD",vendor: "TELESIS TECHNOLOGIES, INC."},
  {max_prefix: "00-22-AE",vendor: "Mattel Inc."},
  {max_prefix: "00-23-07",vendor: "FUTURE INNOVATION TECH CO.,LTD"},
  {max_prefix: "00-23-05",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-22-FF",vendor: "NIVIS LLC"},
  {max_prefix: "00-23-02",vendor: "Cobalt Digital, Inc."},
  {max_prefix: "00-22-F5",vendor: "Advanced Realtime Tracking GmbH"},
  {max_prefix: "00-22-C7",vendor: "SEGGER Microcontroller GmbH & Co. KG"},
  {max_prefix: "00-22-C1",vendor: "Active Storage Inc."},
  {max_prefix: "00-22-C2",vendor: "Proview Eletr\u00f4nica do Brasil LTDA"},
  {max_prefix: "00-22-BD",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-22-BA",vendor: "HUTH Elektronik Systeme GmbH"},
  {max_prefix: "00-22-D1",vendor: "Albrecht Jung GmbH & Co. KG"},
  {max_prefix: "00-22-D2",vendor: "All Earth Com\u00e9rcio de Eletr\u00f4nicos LTDA."},
  {max_prefix: "00-22-CA",vendor: "Anviz Biometric Tech. Co., Ltd."},
  {max_prefix: "00-23-3C",vendor: "Alflex"},
  {max_prefix: "00-23-33",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-23-2E",vendor: "Kedah Electronics Engineering, LLC"},
  {max_prefix: "00-22-E4",vendor: "APASS TECHNOLOGY CO., LTD."},
  {max_prefix: "00-22-D5",vendor: "Eaton Corp. Electrical Group Data Center Solutions - Pulizzi"},
  {max_prefix: "00-22-E5",vendor: "Fisher-Rosemount Systems Inc."},
  {max_prefix: "00-23-20",vendor: "Nicira Networks"},
  {max_prefix: "00-23-22",vendor: "KISS Teknical Solutions, Inc."},
  {max_prefix: "00-23-17",vendor: "Lasercraft Inc"},
  {max_prefix: "00-23-0E",vendor: "Gorba AG"},
  {max_prefix: "00-22-5E",vendor: "Uwin Technologies Co.,LTD"},
  {max_prefix: "00-22-58",vendor: "Taiyo Yuden Co., Ltd."},
  {max_prefix: "00-22-5B",vendor: "Teradici Corporation"},
  {max_prefix: "00-22-59",vendor: "Guangzhou New Postcom Equipment Co.,Ltd."},
  {max_prefix: "00-22-53",vendor: "Entorian Technologies"},
  {max_prefix: "00-22-39",vendor: "Indiana Life Sciences Incorporated"},
  {max_prefix: "00-22-35",vendor: "Strukton Systems bv"},
  {max_prefix: "00-22-2C",vendor: "Ceton Corp"},
  {max_prefix: "00-22-2D",vendor: "SMC Networks Inc."},
  {max_prefix: "00-22-7E",vendor: "Chengdu 30Kaitian Communication Industry Co.Ltd"},
  {max_prefix: "00-22-7C",vendor: "Woori SMT Co.,ltd"},
  {max_prefix: "00-22-77",vendor: "NEC Australia Pty Ltd"},
  {max_prefix: "00-22-79",vendor: "Nippon Conlux Co., Ltd."},
  {max_prefix: "00-22-A3",vendor: "California Eastern Laboratories"},
  {max_prefix: "00-22-A0",vendor: "APTIV SERVICES US, LLC"},
  {max_prefix: "00-22-4F",vendor: "Byzoro Networks Ltd."},
  {max_prefix: "00-22-51",vendor: "Lumasense Technologies"},
  {max_prefix: "00-22-47",vendor: "DAC ENGINEERING CO., LTD."},
  {max_prefix: "00-22-71",vendor: "J\u00e4ger Computergesteuerte Me\u00dftechnik GmbH."},
  {max_prefix: "00-22-97",vendor: "XMOS Semiconductor"},
  {max_prefix: "00-22-8F",vendor: "CNRS"},
  {max_prefix: "00-22-92",vendor: "Cinetal"},
  {max_prefix: "00-22-3D",vendor: "JumpGen Systems, LLC"},
  {max_prefix: "00-22-2A",vendor: "SoundEar A/S"},
  {max_prefix: "00-21-EE",vendor: "Full Spectrum Inc."},
  {max_prefix: "00-21-EC",vendor: "Solutronic GmbH"},
  {max_prefix: "00-21-E6",vendor: "Starlight Video Limited"},
  {max_prefix: "00-21-E0",vendor: "CommAgility Ltd"},
  {max_prefix: "00-21-CC",vendor: "Flextronics International"},
  {max_prefix: "00-21-CF",vendor: "The Crypto Group"},
  {max_prefix: "00-21-C5",vendor: "3DSP Corp"},
  {max_prefix: "00-21-B9",vendor: "Universal Devices Inc."},
  {max_prefix: "00-21-B3",vendor: "Ross Controls"},
  {max_prefix: "00-21-B6",vendor: "Triacta Power Technologies Inc."},
  {max_prefix: "00-21-AE",vendor: "ALCATEL-LUCENT FRANCE - WTD"},
  {max_prefix: "00-21-AF",vendor: "Radio Frequency Systems"},
  {max_prefix: "00-21-F9",vendor: "WIRECOM Technologies"},
  {max_prefix: "00-21-FA",vendor: "A4SP Technologies Ltd."},
  {max_prefix: "00-21-F0",vendor: "EW3 Technologies LLC"},
  {max_prefix: "00-22-11",vendor: "Rohati Systems"},
  {max_prefix: "00-22-12",vendor: "CAI Networks, Inc."},
  {max_prefix: "00-22-0D",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-21-DE",vendor: "Firepro Wireless"},
  {max_prefix: "00-21-D3",vendor: "BOCOM SECURITY(ASIA PACIFIC) LIMITED"},
  {max_prefix: "00-22-08",vendor: "Certicom Corp"},
  {max_prefix: "00-22-05",vendor: "WeLink Solutions, Inc."},
  {max_prefix: "00-22-09",vendor: "Omron Healthcare Co., Ltd"},
  {max_prefix: "00-22-1F",vendor: "eSang Technologies Co., Ltd."},
  {max_prefix: "00-22-17",vendor: "Neat Electronics"},
  {max_prefix: "00-21-A4",vendor: "Dbii Networks"},
  {max_prefix: "00-21-4B",vendor: "Shenzhen HAMP Science & Technology Co.,Ltd"},
  {max_prefix: "00-21-45",vendor: "Semptian Technologies Ltd."},
  {max_prefix: "00-21-8C",vendor: "TopControl GMBH"},
  {max_prefix: "00-21-8A",vendor: "Electronic Design and Manufacturing Company"},
  {max_prefix: "00-21-8B",vendor: "Wescon Technology, Inc."},
  {max_prefix: "00-21-84",vendor: "POWERSOFT SRL"},
  {max_prefix: "00-21-21",vendor: "VRmagic GmbH"},
  {max_prefix: "00-21-23",vendor: "Aerosat Avionics"},
  {max_prefix: "00-21-1B",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-21-7A",vendor: "Sejin Electron, Inc."},
  {max_prefix: "00-21-78",vendor: "Matuschek Messtechnik GmbH"},
  {max_prefix: "00-21-73",vendor: "Ion Torrent Systems, Inc."},
  {max_prefix: "00-21-77",vendor: "W. L. Gore & Associates"},
  {max_prefix: "00-21-99",vendor: "Vacon Plc"},
  {max_prefix: "00-21-A0",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-21-98",vendor: "Thai Radio Co, LTD"},
  {max_prefix: "00-21-72",vendor: "Seoultek Valley"},
  {max_prefix: "00-21-69",vendor: "Prologix, LLC."},
  {max_prefix: "00-21-53",vendor: "SeaMicro Inc."},
  {max_prefix: "00-21-54",vendor: "D-TACQ Solutions Ltd"},
  {max_prefix: "00-21-3E",vendor: "TomTom International BV"},
  {max_prefix: "00-21-3F",vendor: "A-Team Technology Ltd."},
  {max_prefix: "00-21-2D",vendor: "SCIMOLEX CORPORATION"},
  {max_prefix: "00-1F-D8",vendor: "A-TRUST COMPUTER CORPORATION"},
  {max_prefix: "00-1F-D7",vendor: "TELERAD SA"},
  {max_prefix: "00-1F-D3",vendor: "RIVA Networks Inc."},
  {max_prefix: "00-1F-D5",vendor: "MICRORISC s.r.o."},
  {max_prefix: "00-1F-CA",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-1F-C3",vendor: "SmartSynch, Inc"},
  {max_prefix: "00-1F-C1",vendor: "Hanlong Technology Co.,LTD"},
  {max_prefix: "00-1F-EE",vendor: "ubisys technologies GmbH"},
  {max_prefix: "00-1F-EF",vendor: "SHINSEI INDUSTRIES CO.,LTD"},
  {max_prefix: "00-1F-EC",vendor: "Synapse \u00c9lectronique"},
  {max_prefix: "00-1F-FC",vendor: "Riccius+Sohn GmbH"},
  {max_prefix: "00-1F-FD",vendor: "Indigo Mobile Technologies Corp."},
  {max_prefix: "00-1F-BB",vendor: "Xenatech Co.,LTD"},
  {max_prefix: "00-1F-B1",vendor: "Cybertech Inc."},
  {max_prefix: "00-21-0A",vendor: "byd:sign Corporation"},
  {max_prefix: "00-21-07",vendor: "Seowonintech Co Ltd."},
  {max_prefix: "00-1F-FE",vendor: "HPN Supply Chain"},
  {max_prefix: "00-1F-FF",vendor: "Respironics, Inc."},
  {max_prefix: "00-1F-E8",vendor: "KURUSUGAWA Electronics Industry Inc,."},
  {max_prefix: "00-1F-E0",vendor: "EdgeVelocity Corp"},
  {max_prefix: "00-1F-AB",vendor: "I.S HIGH TECH.INC"},
  {max_prefix: "00-1F-AC",vendor: "Goodmill Systems Ltd"},
  {max_prefix: "00-1F-B2",vendor: "Sontheim Industrie Elektronik GmbH"},
  {max_prefix: "00-1F-44",vendor: "GE Transportation Systems"},
  {max_prefix: "00-1F-39",vendor: "Construcciones y Auxiliar de Ferrocarriles, S.A."},
  {max_prefix: "00-1F-3D",vendor: "Qbit GmbH"},
  {max_prefix: "00-1F-36",vendor: "Bellwin Information Co. Ltd.,"},
  {max_prefix: "00-1F-38",vendor: "POSITRON"},
  {max_prefix: "00-1F-8E",vendor: "Metris USA Inc."},
  {max_prefix: "00-1F-89",vendor: "Signalion GmbH"},
  {max_prefix: "00-1F-8A",vendor: "Ellion Digital Inc."},
  {max_prefix: "00-1F-7F",vendor: "Phabrix Limited"},
  {max_prefix: "00-1F-7C",vendor: "Witelcom AS"},
  {max_prefix: "00-1F-7A",vendor: "WiWide Inc."},
  {max_prefix: "00-1F-77",vendor: "HEOL DESIGN"},
  {max_prefix: "00-1F-76",vendor: "AirLogic Systems Inc."},
  {max_prefix: "00-1F-73",vendor: "Teraview Technology Co., Ltd."},
  {max_prefix: "00-1F-11",vendor: "OPENMOKO, INC."},
  {max_prefix: "00-1F-2D",vendor: "Electro-Optical Imaging, Inc."},
  {max_prefix: "00-1F-2F",vendor: "Berker GmbH & Co. KG"},
  {max_prefix: "00-1F-32",vendor: "Nintendo Co., Ltd."},
  {max_prefix: "00-1F-56",vendor: "DIGITAL FORECAST"},
  {max_prefix: "00-1F-52",vendor: "UVT Unternehmensberatung fur Verkehr und Technik GmbH"},
  {max_prefix: "00-1F-4F",vendor: "Thinkware Co. Ltd."},
  {max_prefix: "00-1F-62",vendor: "JSC Stilsoft"},
  {max_prefix: "00-1F-67",vendor: "Hitachi,Ltd."},
  {max_prefix: "00-1F-61",vendor: "Talent Communication Networks Inc."},
  {max_prefix: "00-1F-1C",vendor: "KOBISHI ELECTRIC Co.,Ltd."},
  {max_prefix: "00-1F-19",vendor: "BEN-RI ELECTRONICA S.A."},
  {max_prefix: "00-1E-C8",vendor: "Rapid Mobile (Pty) Ltd"},
  {max_prefix: "00-1E-CC",vendor: "CDVI"},
  {max_prefix: "00-1E-C5",vendor: "Middle Atlantic Products Inc"},
  {max_prefix: "00-1E-BE",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-1E-C3",vendor: "Kozio, Inc."},
  {max_prefix: "00-1E-BD",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-1E-B9",vendor: "Sing Fai Technology Limited"},
  {max_prefix: "00-1E-F6",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-1E-F9",vendor: "Pascom Kommunikations systeme GmbH."},
  {max_prefix: "00-1E-F3",vendor: "From2"},
  {max_prefix: "00-1E-E7",vendor: "Epic Systems Inc"},
  {max_prefix: "00-1E-E9",vendor: "Stoneridge Electronics AB"},
  {max_prefix: "00-1F-03",vendor: "NUM AG"},
  {max_prefix: "00-1E-FD",vendor: "Microbit 2.0 AB"},
  {max_prefix: "00-1E-FF",vendor: "Mueller-Elektronik GmbH & Co. KG"},
  {max_prefix: "00-1F-05",vendor: "iTAS Technology Corp."},
  {max_prefix: "00-1F-07",vendor: "AZTEQ Mobile"},
  {max_prefix: "00-1E-93",vendor: "CiriTech Systems Inc"},
  {max_prefix: "00-1E-92",vendor: "JEULIN S.A."},
  {max_prefix: "00-1E-91",vendor: "KIMIN Electronic Co., Ltd."},
  {max_prefix: "00-1E-89",vendor: "CRFS Limited"},
  {max_prefix: "00-1E-86",vendor: "MEL Co.,Ltd."},
  {max_prefix: "00-1E-88",vendor: "ANDOR SYSTEM SUPPORT CO., LTD."},
  {max_prefix: "00-1E-B7",vendor: "TBTech, Co., Ltd."},
  {max_prefix: "00-1E-A2",vendor: "Symx Systems, Inc."},
  {max_prefix: "00-1E-A9",vendor: "Nintendo Co., Ltd."},
  {max_prefix: "00-1E-9E",vendor: "ddm hopt + schuler Gmbh + Co. KG"},
  {max_prefix: "00-1E-E4",vendor: "ACS Solutions France"},
  {max_prefix: "00-1E-ED",vendor: "Adventiq Ltd."},
  {max_prefix: "00-1E-D4",vendor: "Doble Engineering"},
  {max_prefix: "00-1E-D2",vendor: "Ray Shine Video Technology Inc"},
  {max_prefix: "00-1E-CE",vendor: "BISA Technologies (Hong Kong) Limited"},
  {max_prefix: "00-1E-9D",vendor: "Recall Technologies, Inc."},
  {max_prefix: "00-1E-95",vendor: "SIGMALINK"},
  {max_prefix: "00-1E-79",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-1E-54",vendor: "TOYO ELECTRIC Corporation"},
  {max_prefix: "00-1E-4D",vendor: "Welkin Sciences, LLC"},
  {max_prefix: "00-1E-4B",vendor: "City Theatrical"},
  {max_prefix: "00-1E-4A",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-1E-3C",vendor: "Lyngbox Media AB"},
  {max_prefix: "00-1E-0D",vendor: "Micran Ltd."},
  {max_prefix: "00-1E-09",vendor: "ZEFATEK Co.,LTD"},
  {max_prefix: "00-1E-06",vendor: "WIBRAIN"},
  {max_prefix: "00-1E-0C",vendor: "Sherwood Information Partners, Inc."},
  {max_prefix: "00-1E-02",vendor: "Sougou Keikaku Kougyou Co.,Ltd."},
  {max_prefix: "00-1E-01",vendor: "Renesas Technology Sales Co., Ltd."},
  {max_prefix: "00-1D-FF",vendor: "Network Critical Solutions Ltd"},
  {max_prefix: "00-1E-5F",vendor: "KwikByte, LLC"},
  {max_prefix: "00-1E-5B",vendor: "Unitron Company, Inc."},
  {max_prefix: "00-1E-5E",vendor: "COmputime Ltd."},
  {max_prefix: "00-1E-76",vendor: "Thermo Fisher Scientific"},
  {max_prefix: "00-1E-72",vendor: "PCS"},
  {max_prefix: "00-1E-69",vendor: "Thomson Inc."},
  {max_prefix: "00-1D-EE",vendor: "NEXTVISION SISTEMAS DIGITAIS DE TELEVIS\u00c3O LTDA."},
  {max_prefix: "00-1D-EA",vendor: "Commtest Instruments Ltd"},
  {max_prefix: "00-1D-DD",vendor: "DAT H.K. LIMITED"},
  {max_prefix: "00-1D-E4",vendor: "Visioneered Image Systems"},
  {max_prefix: "00-1E-00",vendor: "Shantou Institute of Ultrasonic Instruments"},
  {max_prefix: "00-1D-F3",vendor: "SBS Science & Technology Co., Ltd"},
  {max_prefix: "00-1E-23",vendor: "Electronic Educational Devices, Inc"},
  {max_prefix: "00-1E-13",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-1E-2C",vendor: "CyVerse Corporation"},
  {max_prefix: "00-1E-26",vendor: "Digifriends Co. Ltd"},
  {max_prefix: "00-1D-89",vendor: "VaultStor Corporation"},
  {max_prefix: "00-1D-86",vendor: "Shinwa Industries(China) Ltd."},
  {max_prefix: "00-1D-88",vendor: "Clearwire"},
  {max_prefix: "00-1D-81",vendor: "GUANGZHOU GATEWAY ELECTRONICS CO., LTD"},
  {max_prefix: "00-1D-76",vendor: "Eyeheight Ltd."},
  {max_prefix: "00-1D-7A",vendor: "Wideband Semiconductor, Inc."},
  {max_prefix: "00-1D-68",vendor: "Thomson Telecom Belgium"},
  {max_prefix: "00-1D-61",vendor: "BIJ Corporation"},
  {max_prefix: "00-1D-5C",vendor: "Tom Communication Industrial Co.,Ltd."},
  {max_prefix: "00-1D-E2",vendor: "Radionor Communications"},
  {max_prefix: "00-1D-D7",vendor: "Algolith"},
  {max_prefix: "00-1D-C9",vendor: "GainSpan Corp."},
  {max_prefix: "00-1D-C7",vendor: "L-3 Communications Geneva Aerospace"},
  {max_prefix: "00-1D-BC",vendor: "Nintendo Co., Ltd."},
  {max_prefix: "00-1D-66",vendor: "Hyundai Telecom"},
  {max_prefix: "00-1D-77",vendor: "NSGate"},
  {max_prefix: "00-1D-A2",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-1D-99",vendor: "Cyan Optic, Inc."},
  {max_prefix: "00-1D-9B",vendor: "Hokuyo Automatic Co., Ltd."},
  {max_prefix: "00-1D-B6",vendor: "BestComm Networks, Inc."},
  {max_prefix: "00-1D-AC",vendor: "Gigamon Systems LLC"},
  {max_prefix: "00-1D-4E",vendor: "TCM Mobile LLC"},
  {max_prefix: "00-1D-49",vendor: "Innovation Wireless Inc."},
  {max_prefix: "00-1D-46",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-1D-48",vendor: "Sensor-Technik Wiedemann GmbH"},
  {max_prefix: "00-1D-47",vendor: "Covote GmbH & Co KG"},
  {max_prefix: "00-1D-41",vendor: "Hardy Instruments"},
  {max_prefix: "00-1C-E6",vendor: "INNES"},
  {max_prefix: "00-1C-E1",vendor: "INDRA SISTEMAS, S.A."},
  {max_prefix: "00-1C-E0",vendor: "DASAN TPS"},
  {max_prefix: "00-1D-11",vendor: "Analogue & Micro Ltd"},
  {max_prefix: "00-1D-12",vendor: "ROHM CO., LTD."},
  {max_prefix: "00-1D-03",vendor: "Design Solutions Inc."},
  {max_prefix: "00-1D-3D",vendor: "Avidyne Corporation"},
  {max_prefix: "00-1D-3C",vendor: "Muscle Corporation"},
  {max_prefix: "00-1D-3A",vendor: "mh acoustics LLC"},
  {max_prefix: "00-1D-35",vendor: "Viconics Electronics Inc."},
  {max_prefix: "00-1D-31",vendor: "HIGHPRO INTERNATIONAL R&D CO,.LTD."},
  {max_prefix: "00-1C-D9",vendor: "GlobalTop Technology Inc."},
  {max_prefix: "00-1C-DA",vendor: "Exegin Technologies Limited"},
  {max_prefix: "00-1C-D2",vendor: "King Champion (Hong Kong) Limited"},
  {max_prefix: "00-1C-F9",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-1C-F1",vendor: "SUPoX Technology Co. , LTD."},
  {max_prefix: "00-1C-F4",vendor: "Media Technology Systems Inc"},
  {max_prefix: "00-1D-26",vendor: "Rockridgesound Technology Co."},
  {max_prefix: "00-1D-21",vendor: "Alcad SL"},
  {max_prefix: "00-1D-1C",vendor: "Gennet s.a."},
  {max_prefix: "00-1D-15",vendor: "Shenzhen Dolphin Electronic Co., Ltd"},
  {max_prefix: "00-1D-16",vendor: "SFR"},
  {max_prefix: "00-1D-56",vendor: "Kramer Electronics Ltd."},
  {max_prefix: "00-1C-90",vendor: "Empacket Corporation"},
  {max_prefix: "00-1C-8E",vendor: "Alcatel-Lucent IPD"},
  {max_prefix: "00-1C-8F",vendor: "Advanced Electronic Design, Inc."},
  {max_prefix: "00-1C-88",vendor: "TRANSYSTEM INC."},
  {max_prefix: "00-1C-A9",vendor: "Audiomatica Srl"},
  {max_prefix: "00-1C-99",vendor: "Shunra Software Ltd."},
  {max_prefix: "00-1C-9B",vendor: "FEIG ELECTRONIC GmbH"},
  {max_prefix: "00-1C-95",vendor: "Opticomm Corporation"},
  {max_prefix: "00-1C-97",vendor: "Enzytek Technology Inc.,"},
  {max_prefix: "00-1C-58",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-1C-5A",vendor: "Advanced Relay Corporation"},
  {max_prefix: "00-1C-4F",vendor: "MACAB AB"},
  {max_prefix: "00-1C-4E",vendor: "TASA International Limited"},
  {max_prefix: "00-1C-6E",vendor: "Newbury Networks, Inc."},
  {max_prefix: "00-1C-6B",vendor: "COVAX  Co. Ltd"},
  {max_prefix: "00-1C-69",vendor: "Packet Vision Ltd"},
  {max_prefix: "00-1C-65",vendor: "JoeScan, Inc."},
  {max_prefix: "00-1C-5F",vendor: "Winland Electronics, Inc."},
  {max_prefix: "00-1C-3D",vendor: "WaveStorm"},
  {max_prefix: "00-1C-2D",vendor: "FlexRadio Systems"},
  {max_prefix: "00-1C-86",vendor: "Cranite Systems, Inc."},
  {max_prefix: "00-1C-7F",vendor: "Check Point Software Technologies"},
  {max_prefix: "00-1C-78",vendor: "WYPLAY SAS"},
  {max_prefix: "00-1C-CE",vendor: "By Techdesign"},
  {max_prefix: "00-1C-B9",vendor: "KWANG SUNG ELECTRONICS CO., LTD."},
  {max_prefix: "00-1C-4B",vendor: "Gener8, Inc."},
  {max_prefix: "00-1C-40",vendor: "VDG-Security bv"},
  {max_prefix: "00-1C-3A",vendor: "Element Labs, Inc."},
  {max_prefix: "00-1C-A3",vendor: "Terra"},
  {max_prefix: "00-1C-A6",vendor: "Win4NET"},
  {max_prefix: "00-1B-CA",vendor: "Beijing Run Technology LTD. Company"},
  {max_prefix: "00-1B-CC",vendor: "KINGTEK CCTV ALLIANCE CO., LTD."},
  {max_prefix: "00-1B-C8",vendor: "MIURA CO.,LTD"},
  {max_prefix: "00-1B-C1",vendor: "HOLUX Technology, Inc."},
  {max_prefix: "00-1B-BC",vendor: "Silver Peak Systems, Inc."},
  {max_prefix: "00-1C-1E",vendor: "emtrion GmbH"},
  {max_prefix: "00-1C-18",vendor: "Sicert S.r.L."},
  {max_prefix: "00-1C-1A",vendor: "Thomas Instrumentation, Inc"},
  {max_prefix: "00-1C-0E",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-1C-13",vendor: "OPTSYS TECHNOLOGY CO., LTD."},
  {max_prefix: "00-1B-F4",vendor: "KENWIN INDUSTRIAL(HK) LTD."},
  {max_prefix: "00-1B-F9",vendor: "Intellitect Water Ltd"},
  {max_prefix: "00-1B-FA",vendor: "G.i.N. mbH"},
  {max_prefix: "00-1B-F3",vendor: "TRANSRADIO SenderSysteme Berlin AG"},
  {max_prefix: "00-1B-DB",vendor: "Valeo VECS"},
  {max_prefix: "00-1B-D8",vendor: "FLIR Systems Inc"},
  {max_prefix: "00-1B-D4",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-1B-D0",vendor: "IDENTEC SOLUTIONS"},
  {max_prefix: "00-1B-CD",vendor: "DAVISCOMMS (S) PTE LTD"},
  {max_prefix: "00-1B-DE",vendor: "Renkus-Heinz, Inc."},
  {max_prefix: "00-1B-AB",vendor: "Telchemy, Incorporated"},
  {max_prefix: "00-1B-AE",vendor: "Micro Control Systems, Inc"},
  {max_prefix: "00-1B-A8",vendor: "UBI&MOBI,.Inc"},
  {max_prefix: "00-1C-2C",vendor: "Synapse"},
  {max_prefix: "00-1C-21",vendor: "Nucsafe Inc."},
  {max_prefix: "00-1C-0B",vendor: "SmartAnt Telecom"},
  {max_prefix: "00-1C-08",vendor: "Echo360, Inc."},
  {max_prefix: "00-1B-A0",vendor: "Awox"},
  {max_prefix: "00-1B-42",vendor: "Wise & Blue"},
  {max_prefix: "00-1B-35",vendor: "ChongQing JINOU Science & Technology Development CO.,Ltd"},
  {max_prefix: "00-1B-36",vendor: "Tsubata Engineering Co.,Ltd. (Head Office)"},
  {max_prefix: "00-1B-39",vendor: "Proxicast"},
  {max_prefix: "00-1B-3B",vendor: "Yi-Qing CO., LTD"},
  {max_prefix: "00-1B-51",vendor: "Vector Technology Corp."},
  {max_prefix: "00-1B-54",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-1B-4A",vendor: "W&W Communications, Inc."},
  {max_prefix: "00-1B-44",vendor: "SanDisk Corporation"},
  {max_prefix: "00-1B-46",vendor: "Blueone Technology Co.,Ltd"},
  {max_prefix: "00-1B-40",vendor: "Network Automation mxc AB"},
  {max_prefix: "00-1B-79",vendor: "FAIVELEY TRANSPORT"},
  {max_prefix: "00-1B-71",vendor: "Telular Corp."},
  {max_prefix: "00-1B-73",vendor: "DTL Broadcast Ltd"},
  {max_prefix: "00-1B-20",vendor: "TPine Technology"},
  {max_prefix: "00-1B-1C",vendor: "Coherent"},
  {max_prefix: "00-1B-22",vendor: "Palit Microsystems ( H.K.) Ltd."},
  {max_prefix: "00-1B-19",vendor: "IEEE I&M Society TC9"},
  {max_prefix: "00-1B-65",vendor: "China Gridcom Co., Ltd"},
  {max_prefix: "00-1B-64",vendor: "IsaacLandKorea Co., Ltd,"},
  {max_prefix: "00-1B-97",vendor: "Violin Technologies"},
  {max_prefix: "00-1B-88",vendor: "Divinet Access Technologies Ltd"},
  {max_prefix: "00-1B-83",vendor: "Finsoft Ltd"},
  {max_prefix: "00-1B-81",vendor: "DATAQ Instruments, Inc."},
  {max_prefix: "00-1B-7D",vendor: "CXR Anderson Jacobson"},
  {max_prefix: "00-1B-26",vendor: "RON-Telecom ZAO"},
  {max_prefix: "00-1A-A8",vendor: "Mamiya Digital Imaging Co., Ltd."},
  {max_prefix: "00-1A-99",vendor: "Smarty (HZ) Information Electronics Co., Ltd"},
  {max_prefix: "00-1A-96",vendor: "ECLER S.A."},
  {max_prefix: "00-1A-FA",vendor: "Welch Allyn, Inc."},
  {max_prefix: "00-1A-F7",vendor: "dataschalt e+a GmbH"},
  {max_prefix: "00-1A-F3",vendor: "Samyoung Electronics"},
  {max_prefix: "00-1A-EF",vendor: "Loopcomm Technology, Inc."},
  {max_prefix: "00-1A-EC",vendor: "Keumbee Electronics Co.,Ltd."},
  {max_prefix: "00-1A-A9",vendor: "FUJIAN STAR-NET COMMUNICATION CO.,LTD"},
  {max_prefix: "00-1A-8C",vendor: "Sophos Ltd"},
  {max_prefix: "00-1A-91",vendor: "FusionDynamic Ltd."},
  {max_prefix: "00-1A-B9",vendor: "PMC"},
  {max_prefix: "00-1A-BA",vendor: "Caton Overseas Limited"},
  {max_prefix: "00-1A-B3",vendor: "VISIONITE INC."},
  {max_prefix: "00-1A-CA",vendor: "Tilera Corporation"},
  {max_prefix: "00-1B-13",vendor: "Icron Technologies Corporation"},
  {max_prefix: "00-1B-0F",vendor: "Petratec"},
  {max_prefix: "00-1A-D1",vendor: "FARGO CO., LTD."},
  {max_prefix: "00-1A-15",vendor: "gemalto e-Payment"},
  {max_prefix: "00-1A-0D",vendor: "HandHeld entertainment, Inc."},
  {max_prefix: "00-1A-0E",vendor: "Cheng Uei Precision Industry Co.,Ltd"},
  {max_prefix: "00-1A-84",vendor: "V One Multimedia Pte Ltd"},
  {max_prefix: "00-1A-7B",vendor: "Teleco, Inc."},
  {max_prefix: "00-1A-6D",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-1A-6E",vendor: "Impro Technologies"},
  {max_prefix: "00-1A-6C",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-1A-3E",vendor: "Faster Technology LLC"},
  {max_prefix: "00-1A-3A",vendor: "Dongahelecomm"},
  {max_prefix: "00-1A-3B",vendor: "Doah Elecom Inc."},
  {max_prefix: "00-1A-3C",vendor: "Technowave Ltd."},
  {max_prefix: "00-1A-40",vendor: "A-FOUR TECH CO., LTD."},
  {max_prefix: "00-1A-30",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-1A-36",vendor: "Aipermon GmbH & Co. KG"},
  {max_prefix: "00-1A-26",vendor: "Deltanode Solutions AB"},
  {max_prefix: "00-1A-25",vendor: "DELTA DORE"},
  {max_prefix: "00-1A-46",vendor: "Digital Multimedia Technology Co., Ltd"},
  {max_prefix: "00-1A-4A",vendor: "Qumranet Inc."},
  {max_prefix: "00-1A-63",vendor: "Elster Solutions, LLC,"},
  {max_prefix: "00-1A-59",vendor: "Ircona"},
  {max_prefix: "00-19-FE",vendor: "SHENZHEN SEECOMM TECHNOLOGY CO.,LTD."},
  {max_prefix: "00-19-FD",vendor: "Nintendo Co., Ltd."},
  {max_prefix: "00-19-ED",vendor: "Axesstel Inc."},
  {max_prefix: "00-19-F6",vendor: "Acconet (PTE) Ltd"},
  {max_prefix: "00-19-CD",vendor: "Chengdu ethercom information technology Ltd."},
  {max_prefix: "00-19-D9",vendor: "Zeutschel GmbH"},
  {max_prefix: "00-19-CA",vendor: "Broadata Communications, Inc"},
  {max_prefix: "00-19-D3",vendor: "TRAK Microwave"},
  {max_prefix: "00-19-C3",vendor: "Qualitrol"},
  {max_prefix: "00-19-BE",vendor: "Altai Technologies Limited"},
  {max_prefix: "00-19-B4",vendor: "Intellio Ltd"},
  {max_prefix: "00-19-BA",vendor: "Paradox Security Systems Ltd"},
  {max_prefix: "00-19-80",vendor: "Gridpoint Systems"},
  {max_prefix: "00-19-83",vendor: "CCT R&D Limited"},
  {max_prefix: "00-19-7F",vendor: "PLANTRONICS, INC."},
  {max_prefix: "00-19-8A",vendor: "Northrop Grumman Systems Corp."},
  {max_prefix: "00-19-89",vendor: "Sonitrol Corporation"},
  {max_prefix: "00-19-A1",vendor: "LG INFORMATION & COMM."},
  {max_prefix: "00-19-A8",vendor: "WiQuest Communications"},
  {max_prefix: "00-19-7A",vendor: "MAZeT GmbH"},
  {max_prefix: "00-19-78",vendor: "Datum Systems, Inc."},
  {max_prefix: "00-19-1D",vendor: "Nintendo Co., Ltd."},
  {max_prefix: "00-19-24",vendor: "LBNL  Engineering"},
  {max_prefix: "00-19-1A",vendor: "IRLINK"},
  {max_prefix: "00-19-16",vendor: "PayTec AG"},
  {max_prefix: "00-19-50",vendor: "Harman Multimedia"},
  {max_prefix: "00-19-4C",vendor: "Fujian Stelcom information & Technology CO.,Ltd"},
  {max_prefix: "00-19-4A",vendor: "TESTO AG"},
  {max_prefix: "00-19-39",vendor: "Gigamips"},
  {max_prefix: "00-19-3A",vendor: "OESOLUTIONS"},
  {max_prefix: "00-19-2F",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-19-22",vendor: "CM Comandos Lineares"},
  {max_prefix: "00-19-68",vendor: "Digital Video Networks(Shanghai) CO. LTD."},
  {max_prefix: "00-19-6D",vendor: "Raybit Systems Korea, Inc"},
  {max_prefix: "00-19-70",vendor: "Z-Com, Inc."},
  {max_prefix: "00-19-0E",vendor: "Atech Technology Co., Ltd."},
  {max_prefix: "00-19-60",vendor: "DoCoMo Systems, Inc."},
  {max_prefix: "00-19-5A",vendor: "Jenaer Antriebstechnik GmbH"},
  {max_prefix: "00-19-05",vendor: "SCHRACK Seconet AG"},
  {max_prefix: "00-19-07",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-18-C6",vendor: "OPW Fuel Management Systems"},
  {max_prefix: "00-18-C3",vendor: "CS Corporation"},
  {max_prefix: "00-18-CA",vendor: "Viprinet GmbH"},
  {max_prefix: "00-18-C7",vendor: "Real Time Automation"},
  {max_prefix: "00-18-BB",vendor: "Eliwell Controls srl"},
  {max_prefix: "00-18-BF",vendor: "Essence Technology Solution, Inc."},
  {max_prefix: "00-18-FB",vendor: "Compro Technology"},
  {max_prefix: "00-18-EE",vendor: "Videology Imaging Solutions, Inc."},
  {max_prefix: "00-18-EB",vendor: "Blue Zen Enterprises Private Limited"},
  {max_prefix: "00-18-70",vendor: "E28 Shanghai Limited"},
  {max_prefix: "00-18-72",vendor: "Expertise Engineering"},
  {max_prefix: "00-18-74",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-18-69",vendor: "KINGJIM"},
  {max_prefix: "00-18-E2",vendor: "Topdata Sistemas de Automacao Ltda"},
  {max_prefix: "00-18-DC",vendor: "Prostar Co., Ltd."},
  {max_prefix: "00-18-D1",vendor: "Siemens Home & Office Comm. Devices"},
  {max_prefix: "00-18-D0",vendor: "AtRoad,  A Trimble Company"},
  {max_prefix: "00-18-D2",vendor: "High-Gain Antennas LLC"},
  {max_prefix: "00-18-D3",vendor: "TEAMCAST"},
  {max_prefix: "00-18-8F",vendor: "Montgomery Technology, Inc."},
  {max_prefix: "00-18-84",vendor: "Fon Technology S.L."},
  {max_prefix: "00-18-80",vendor: "Maxim Integrated Products"},
  {max_prefix: "00-18-7C",vendor: "INTERCROSS, LLC"},
  {max_prefix: "00-18-B9",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-18-B8",vendor: "New Voice International AG"},
  {max_prefix: "00-18-A1",vendor: "Tiqit Computers, Inc."},
  {max_prefix: "00-18-96",vendor: "Great Well Electronic LTD"},
  {max_prefix: "00-18-90",vendor: "RadioCOM, s.r.o."},
  {max_prefix: "00-18-AA",vendor: "Protec Fire Detection plc"},
  {max_prefix: "00-18-10",vendor: "IPTrade S.A."},
  {max_prefix: "00-18-04",vendor: "E-TEK DIGITAL TECHNOLOGY LIMITED"},
  {max_prefix: "00-18-09",vendor: "CRESYN"},
  {max_prefix: "00-18-61",vendor: "Ooma, Inc."},
  {max_prefix: "00-18-55",vendor: "Aeromaritime Systembau GmbH"},
  {max_prefix: "00-18-51",vendor: "SWsoft"},
  {max_prefix: "00-18-56",vendor: "EyeFi, Inc"},
  {max_prefix: "00-18-4E",vendor: "Lianhe Technologies, Inc."},
  {max_prefix: "00-18-4C",vendor: "Bogen Communications"},
  {max_prefix: "00-18-12",vendor: "Beijing Xinwei Telecom Technology Co., Ltd."},
  {max_prefix: "00-18-0B",vendor: "Brilliant Telecommunications"},
  {max_prefix: "00-18-00",vendor: "UNIGRAND LTD"},
  {max_prefix: "00-17-FC",vendor: "Suprema Inc."},
  {max_prefix: "00-17-FD",vendor: "Amulet Hotkey"},
  {max_prefix: "00-17-FB",vendor: "FA"},
  {max_prefix: "00-17-D8",vendor: "Magnum Semiconductor, Inc."},
  {max_prefix: "00-17-DA",vendor: "Spans Logic"},
  {max_prefix: "00-18-5B",vendor: "Network Chemistry, Inc"},
  {max_prefix: "00-18-41",vendor: "High Tech Computer Corp"},
  {max_prefix: "00-18-26",vendor: "Cale Access AB"},
  {max_prefix: "00-18-2D",vendor: "Artec Design"},
  {max_prefix: "00-18-2A",vendor: "Taiwan Video & Monitor"},
  {max_prefix: "00-18-3B",vendor: "CENITS Co., Ltd."},
  {max_prefix: "00-18-3C",vendor: "Encore Software Limited"},
  {max_prefix: "00-18-19",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-17-5C",vendor: "SHARP CORPORATION"},
  {max_prefix: "00-17-59",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-17-54",vendor: "Arkino HiTOP Corporation Limited"},
  {max_prefix: "00-17-52",vendor: "DAGS, Inc"},
  {max_prefix: "00-17-56",vendor: "Vinci Labs Oy"},
  {max_prefix: "00-17-90",vendor: "HYUNDAI DIGITECH Co, Ltd."},
  {max_prefix: "00-17-91",vendor: "LinTech GmbH"},
  {max_prefix: "00-17-95",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-17-99",vendor: "SmarTire Systems Inc."},
  {max_prefix: "00-17-7F",vendor: "Worldsmart Retech"},
  {max_prefix: "00-17-5F",vendor: "XENOLINK Communications Co., Ltd."},
  {max_prefix: "00-17-74",vendor: "Elesta GmbH"},
  {max_prefix: "00-17-77",vendor: "Obsidian Research Corporation"},
  {max_prefix: "00-17-6A",vendor: "Avago Technologies"},
  {max_prefix: "00-17-86",vendor: "wisembed"},
  {max_prefix: "00-17-82",vendor: "LoBenn Inc."},
  {max_prefix: "00-17-6B",vendor: "Kiyon, Inc."},
  {max_prefix: "00-17-78",vendor: "Central Music Co."},
  {max_prefix: "00-17-B3",vendor: "Aftek Infosys Limited"},
  {max_prefix: "00-17-A2",vendor: "Camrivox Ltd."},
  {max_prefix: "00-17-9D",vendor: "Kelman Limited"},
  {max_prefix: "00-17-CF",vendor: "iMCA-GmbH"},
  {max_prefix: "00-17-B9",vendor: "Gambro Lundia AB"},
  {max_prefix: "00-17-1E",vendor: "Theo Benning GmbH & Co. KG"},
  {max_prefix: "00-17-12",vendor: "ISCO International"},
  {max_prefix: "00-17-0D",vendor: "Dust Networks Inc."},
  {max_prefix: "00-17-0C",vendor: "Twig Com Ltd."},
  {max_prefix: "00-17-0B",vendor: "Contela, Inc."},
  {max_prefix: "00-17-0F",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-17-04",vendor: "Shinco Electronics Group Co.,Ltd"},
  {max_prefix: "00-17-2D",vendor: "Axcen Photonics Corporation"},
  {max_prefix: "00-17-24",vendor: "Studer Professional Audio GmbH"},
  {max_prefix: "00-17-07",vendor: "InGrid, Inc"},
  {max_prefix: "00-17-02",vendor: "Osung Midicom Co., Ltd"},
  {max_prefix: "00-16-D8",vendor: "Senea AB"},
  {max_prefix: "00-16-D6",vendor: "TDA Tech Pty Ltd"},
  {max_prefix: "00-16-D5",vendor: "Synccom Co., Ltd"},
  {max_prefix: "00-17-41",vendor: "DEFIDEV"},
  {max_prefix: "00-17-38",vendor: "International Business Machines"},
  {max_prefix: "00-16-F7",vendor: "L-3 Communications, Aviation Recorders"},
  {max_prefix: "00-16-A1",vendor: "3Leaf Networks"},
  {max_prefix: "00-16-A4",vendor: "Ezurio Ltd"},
  {max_prefix: "00-16-99",vendor: "Tonic DVB Marketing Ltd"},
  {max_prefix: "00-16-9B",vendor: "Alstom Transport"},
  {max_prefix: "00-16-90",vendor: "J-TEK INCORPORATION"},
  {max_prefix: "00-16-98",vendor: "T&A Mobile Phones"},
  {max_prefix: "00-16-96",vendor: "QDI Technology (H.K.) Limited"},
  {max_prefix: "00-16-61",vendor: "Novatium Solutions (P) Ltd"},
  {max_prefix: "00-16-64",vendor: "Prod-El SpA"},
  {max_prefix: "00-16-5E",vendor: "Precision I/O"},
  {max_prefix: "00-16-58",vendor: "Fusiontech Technologies Inc."},
  {max_prefix: "00-16-53",vendor: "LEGO System A/S IE Electronics Division"},
  {max_prefix: "00-16-52",vendor: "Hoatech Technologies, Inc."},
  {max_prefix: "00-16-50",vendor: " Kratos EPD"},
  {max_prefix: "00-16-66",vendor: "Quantier Communication Inc."},
  {max_prefix: "00-16-62",vendor: "Liyuh Technology Ltd."},
  {max_prefix: "00-16-80",vendor: "Bally Gaming + Systems"},
  {max_prefix: "00-16-81",vendor: "Vector Informatik GmbH"},
  {max_prefix: "00-BA-C0",vendor: "Biometric Access Company"},
  {max_prefix: "00-16-AB",vendor: "Dansensor A/S"},
  {max_prefix: "00-16-A5",vendor: "Tandberg Storage ASA"},
  {max_prefix: "00-16-C9",vendor: "NAT Seattle, Inc."},
  {max_prefix: "00-16-C6",vendor: "North Atlantic Industries"},
  {max_prefix: "00-16-D2",vendor: "Caspian"},
  {max_prefix: "00-16-BE",vendor: "INFRANET, Inc."},
  {max_prefix: "00-16-85",vendor: "Elisa Oyj"},
  {max_prefix: "00-15-FA",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-15-FC",vendor: "Littelfuse Startco"},
  {max_prefix: "00-15-F7",vendor: "Wintecronics Ltd."},
  {max_prefix: "00-15-DB",vendor: "Canesta Inc."},
  {max_prefix: "00-15-D7",vendor: "Reti Corporation"},
  {max_prefix: "00-15-CB",vendor: "Surf Communication Solutions Ltd."},
  {max_prefix: "00-16-30",vendor: "Vativ Technologies"},
  {max_prefix: "00-16-2F",vendor: "Geutebr\u00fcck GmbH"},
  {max_prefix: "00-16-2B",vendor: "Togami Electric Mfg.co.,Ltd."},
  {max_prefix: "00-16-42",vendor: "Pangolin"},
  {max_prefix: "00-16-3B",vendor: "Communications & Power Industries"},
  {max_prefix: "00-16-37",vendor: "CITEL SpA"},
  {max_prefix: "00-15-CC",vendor: "UQUEST, LTD."},
  {max_prefix: "00-15-C6",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-15-C3",vendor: "Ruf Telematik AG"},
  {max_prefix: "00-15-F8",vendor: "Kingtronics Industrial Co. Ltd."},
  {max_prefix: "00-15-E6",vendor: "MOBILE TECHNIKA Inc."},
  {max_prefix: "00-16-08",vendor: "Sequans Communications"},
  {max_prefix: "00-16-24",vendor: "Teneros, Inc."},
  {max_prefix: "00-16-1C",vendor: "e:cue"},
  {max_prefix: "00-15-81",vendor: "MAKUS Inc."},
  {max_prefix: "00-15-7A",vendor: "Telefin S.p.A."},
  {max_prefix: "00-15-73",vendor: "NewSoft  Technology Corporation"},
  {max_prefix: "00-15-75",vendor: "Nevis Networks Inc."},
  {max_prefix: "00-15-6C",vendor: "SANE SYSTEM CO., LTD"},
  {max_prefix: "00-15-6A",vendor: "DG2L Technologies Pvt. Ltd."},
  {max_prefix: "00-15-B8",vendor: "Tahoe"},
  {max_prefix: "00-15-B6",vendor: "ShinMaywa Industries, Ltd."},
  {max_prefix: "00-15-B0",vendor: "AUTOTELENET CO.,LTD"},
  {max_prefix: "00-15-B1",vendor: "Ambient Corporation"},
  {max_prefix: "00-15-9F",vendor: "Terascala, Inc."},
  {max_prefix: "00-15-9E",vendor: "Mad Catz Interactive Inc"},
  {max_prefix: "00-15-A1",vendor: "ECA-SINTERS"},
  {max_prefix: "00-15-93",vendor: "U4EA Technologies Inc."},
  {max_prefix: "00-15-8D",vendor: "Jennic Ltd"},
  {max_prefix: "00-15-46",vendor: "ITG Worldwide Sdn Bhd"},
  {max_prefix: "00-15-3E",vendor: "Q-Matic Sweden AB"},
  {max_prefix: "00-15-42",vendor: "MICROHARD S.R.L."},
  {max_prefix: "00-15-6F",vendor: "Xiranet Communications GmbH"},
  {max_prefix: "00-15-72",vendor: "Red-Lemon"},
  {max_prefix: "00-15-67",vendor: "RADWIN Inc."},
  {max_prefix: "00-15-51",vendor: "RadioPulse Inc."},
  {max_prefix: "00-15-52",vendor: "Wi-Gear Inc."},
  {max_prefix: "00-15-4E",vendor: "IEC"},
  {max_prefix: "00-15-50",vendor: "Nits Technology Inc"},
  {max_prefix: "00-15-5D",vendor: "Microsoft Corporation"},
  {max_prefix: "00-15-62",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-15-5B",vendor: "Sampo Corporation"},
  {max_prefix: "00-15-53",vendor: "Cytyc Corporation"},
  {max_prefix: "00-15-84",vendor: "Schenck Process GmbH"},
  {max_prefix: "00-15-A7",vendor: "Robatech AG"},
  {max_prefix: "00-15-2D",vendor: "TenX Networks, LLC"},
  {max_prefix: "00-15-23",vendor: "Meteor Communications Corporation"},
  {max_prefix: "00-15-24",vendor: "Numatics, Inc."},
  {max_prefix: "00-15-29",vendor: "N3 Corporation"},
  {max_prefix: "00-15-09",vendor: "Plus Technology Co., Ltd"},
  {max_prefix: "00-14-F9",vendor: "Vantage Controls"},
  {max_prefix: "00-14-E9",vendor: "Nortech International"},
  {max_prefix: "00-14-ED",vendor: "Airak, Inc."},
  {max_prefix: "00-14-E1",vendor: "Data Display AG"},
  {max_prefix: "00-14-E3",vendor: "mm-lab GmbH"},
  {max_prefix: "00-14-D9",vendor: "IP Fabrics, Inc."},
  {max_prefix: "00-14-D6",vendor: "Jeongmin Electronics Co.,Ltd."},
  {max_prefix: "00-14-D0",vendor: "BTI Systems Inc."},
  {max_prefix: "00-14-CE",vendor: "NF CORPORATION"},
  {max_prefix: "00-14-F0",vendor: "Business Security OL AB"},
  {max_prefix: "00-14-F2",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-14-E7",vendor: "Stolinx,. Inc"},
  {max_prefix: "00-15-10",vendor: "Techsphere Co., Ltd"},
  {max_prefix: "00-15-13",vendor: "EFS sas"},
  {max_prefix: "00-15-03",vendor: "PROFIcomms s.r.o."},
  {max_prefix: "00-15-1B",vendor: "Isilon Systems Inc."},
  {max_prefix: "00-14-59",vendor: "Moram Co., Ltd."},
  {max_prefix: "00-14-53",vendor: "ADVANTECH TECHNOLOGIES CO.,LTD"},
  {max_prefix: "00-14-54",vendor: "Symwave"},
  {max_prefix: "00-14-8B",vendor: "Globo Electronic GmbH & Co. KG"},
  {max_prefix: "00-14-90",vendor: "ASP Corporation"},
  {max_prefix: "00-14-48",vendor: "Inventec Multimedia & Telecom Corporation"},
  {max_prefix: "00-14-4B",vendor: "Hifn, Inc."},
  {max_prefix: "00-14-3C",vendor: "Rheinmetall Canada Inc."},
  {max_prefix: "00-14-88",vendor: "Akorri"},
  {max_prefix: "00-14-84",vendor: "Cermate Technologies Inc."},
  {max_prefix: "00-14-79",vendor: "NEC Magnus Communications,Ltd."},
  {max_prefix: "00-14-7B",vendor: "Iteris, Inc."},
  {max_prefix: "00-14-7A",vendor: "Eubus GmbH"},
  {max_prefix: "00-14-6D",vendor: "RF Technologies"},
  {max_prefix: "00-14-70",vendor: "Prokom Software SA"},
  {max_prefix: "00-14-67",vendor: "ArrowSpan Inc."},
  {max_prefix: "00-14-AC",vendor: "Bountiful WiFi"},
  {max_prefix: "00-14-A8",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-14-A0",vendor: "Accsense, Inc."},
  {max_prefix: "00-14-BB",vendor: "Open Interface North America"},
  {max_prefix: "00-14-5F",vendor: "ADITEC CO. LTD"},
  {max_prefix: "00-13-E6",vendor: "Technolution"},
  {max_prefix: "00-13-DF",vendor: "Ryvor Corp."},
  {max_prefix: "00-13-D5",vendor: "RuggedCom"},
  {max_prefix: "00-13-D6",vendor: "TII NETWORK TECHNOLOGIES, INC."},
  {max_prefix: "00-13-DB",vendor: "SHOEI Electric Co.,Ltd"},
  {max_prefix: "00-13-F2",vendor: "Klas Ltd"},
  {max_prefix: "00-13-F7",vendor: "SMC Networks, Inc."},
  {max_prefix: "00-13-ED",vendor: "PSIA"},
  {max_prefix: "00-14-26",vendor: "NL Technology"},
  {max_prefix: "00-14-1C",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-14-1A",vendor: "DEICY CORPORATION"},
  {max_prefix: "00-14-0C",vendor: "GKB CCTV CO., LTD."},
  {max_prefix: "00-13-FE",vendor: "GRANDTEC ELECTRONIC CORP."},
  {max_prefix: "00-13-F8",vendor: "Dex Security Solutions"},
  {max_prefix: "00-13-F9",vendor: "Cavera Systems"},
  {max_prefix: "00-13-C5",vendor: "LIGHTRON FIBER-OPTIC DEVICES INC."},
  {max_prefix: "00-13-C4",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-13-C2",vendor: "WACOM Co.,Ltd"},
  {max_prefix: "00-13-BF",vendor: "Media System Planning Corp."},
  {max_prefix: "00-13-BB",vendor: "Smartvue Corporation"},
  {max_prefix: "00-13-B5",vendor: "Wavesat"},
  {max_prefix: "00-13-CD",vendor: "MTI co. LTD"},
  {max_prefix: "00-13-D3",vendor: "MICRO-STAR INTERNATIONAL CO., LTD."},
  {max_prefix: "00-13-CA",vendor: "ATX"},
  {max_prefix: "00-14-35",vendor: "CityCom Corp."},
  {max_prefix: "00-14-16",vendor: "Scosche Industries, Inc."},
  {max_prefix: "00-13-3C",vendor: "QUINTRON SYSTEMS INC."},
  {max_prefix: "00-13-3D",vendor: "Micro Memory Curtiss Wright Co"},
  {max_prefix: "00-13-3F",vendor: "Eppendorf Instrumente GmbH"},
  {max_prefix: "00-13-41",vendor: "Shandong New Beiyang Information Technology Co.,Ltd"},
  {max_prefix: "00-13-31",vendor: "CellPoint Connect"},
  {max_prefix: "00-13-35",vendor: "VS Industry Berhad"},
  {max_prefix: "00-13-2F",vendor: "Interactek"},
  {max_prefix: "00-13-30",vendor: "EURO PROTECTION SURVEILLANCE"},
  {max_prefix: "00-13-25",vendor: "Cortina Systems Inc"},
  {max_prefix: "00-13-50",vendor: "Silver Spring Networks, Inc"},
  {max_prefix: "00-13-4C",vendor: "YDT Technology International"},
  {max_prefix: "00-13-8D",vendor: "Kinghold"},
  {max_prefix: "00-13-90",vendor: "Termtek Computer Co., Ltd"},
  {max_prefix: "00-13-9A",vendor: "K-ubique ID Corp."},
  {max_prefix: "00-13-9E",vendor: "Ciara Technologies Inc."},
  {max_prefix: "00-13-9D",vendor: "MaxLinear Hispania S.L.U."},
  {max_prefix: "00-13-AF",vendor: "NUMA Technology,Inc."},
  {max_prefix: "00-13-B0",vendor: "Jablotron"},
  {max_prefix: "00-13-B1",vendor: "Intelligent Control Systems (Asia) Pte Ltd"},
  {max_prefix: "00-13-7F",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-13-82",vendor: "Cetacea Networks Corporation"},
  {max_prefix: "00-13-58",vendor: "Realm Systems, Inc."},
  {max_prefix: "00-13-59",vendor: "ProTelevision Technologies A/S"},
  {max_prefix: "00-13-75",vendor: "American Security Products Co."},
  {max_prefix: "00-12-B4",vendor: "Work Microwave GmbH"},
  {max_prefix: "00-12-B5",vendor: "Vialta, Inc."},
  {max_prefix: "00-12-B1",vendor: "Dai Nippon Printing Co., Ltd"},
  {max_prefix: "00-12-C3",vendor: "WIT S.A."},
  {max_prefix: "00-12-C8",vendor: "Perfect tech"},
  {max_prefix: "00-12-C6",vendor: "TGC America, Inc"},
  {max_prefix: "00-12-CC",vendor: "Bitatek CO., LTD"},
  {max_prefix: "00-12-C1",vendor: "Check Point Software Technologies"},
  {max_prefix: "00-12-BB",vendor: "Telecommunications Industry Association TR-41 Committee"},
  {max_prefix: "00-12-B6",vendor: "Santa Barbara Infrared, Inc."},
  {max_prefix: "00-12-B9",vendor: "Fusion Digital Technology"},
  {max_prefix: "00-12-ED",vendor: "AVG Advanced Technologies"},
  {max_prefix: "00-12-EA",vendor: "Trane"},
  {max_prefix: "00-12-FD",vendor: "OPTIMUS IC S.A."},
  {max_prefix: "00-13-05",vendor: "Epicom, Inc."},
  {max_prefix: "00-13-06",vendor: "Always On Wireless"},
  {max_prefix: "00-12-FA",vendor: "THX LTD"},
  {max_prefix: "00-12-E7",vendor: "Projectek Networking Electronics Corp."},
  {max_prefix: "00-12-E4",vendor: "ZIEHL industrie-electronik GmbH + Co KG"},
  {max_prefix: "00-12-9F",vendor: "RAE Systems"},
  {max_prefix: "00-12-97",vendor: "O2Micro, Inc."},
  {max_prefix: "00-12-9D",vendor: "First International Computer do Brasil"},
  {max_prefix: "00-12-9C",vendor: "Yulinet"},
  {max_prefix: "00-12-90",vendor: "KYOWA Electric & Machinery Corp."},
  {max_prefix: "00-12-91",vendor: "KWS Computersysteme GmbH"},
  {max_prefix: "00-12-95",vendor: "Aiware Inc."},
  {max_prefix: "00-12-8B",vendor: "Sensory Networks Inc"},
  {max_prefix: "00-12-8F",vendor: "Montilio"},
  {max_prefix: "00-12-46",vendor: "T.O.M TECHNOLOGY INC.."},
  {max_prefix: "00-12-3D",vendor: "GES Co, Ltd"},
  {max_prefix: "00-12-A3",vendor: "Trust International B.V."},
  {max_prefix: "00-12-A7",vendor: "ISR TECHNOLOGIES Inc"},
  {max_prefix: "00-12-AA",vendor: "IEE, Inc."},
  {max_prefix: "00-12-7E",vendor: "Digital Lifestyles Group, Inc."},
  {max_prefix: "00-12-5B",vendor: "KAIMEI ELECTRONI"},
  {max_prefix: "00-12-59",vendor: "THERMO ELECTRON KARLSRUHE"},
  {max_prefix: "00-12-5A",vendor: "Microsoft Corporation"},
  {max_prefix: "00-12-89",vendor: "Advance Sterilization Products"},
  {max_prefix: "00-12-84",vendor: "Lab33 Srl"},
  {max_prefix: "00-12-81",vendor: "March Networks S.p.A."},
  {max_prefix: "00-12-56",vendor: "LG INFORMATION & COMM."},
  {max_prefix: "00-12-6B",vendor: "Ascalade Communications Limited"},
  {max_prefix: "00-11-E2",vendor: "Hua Jung Components Co., Ltd."},
  {max_prefix: "00-11-DA",vendor: "Vivaas Technology Inc."},
  {max_prefix: "00-11-DD",vendor: "FROMUS TEC. Co., Ltd."},
  {max_prefix: "00-11-E4",vendor: "Danelec Electronics A/S"},
  {max_prefix: "00-11-E1",vendor: "Arcelik A.S"},
  {max_prefix: "00-11-BE",vendor: "AGP Telecom Co. Ltd"},
  {max_prefix: "00-11-BA",vendor: "Elexol Pty Ltd"},
  {max_prefix: "00-11-BC",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-11-B3",vendor: "YOSHIMIYA CO.,LTD."},
  {max_prefix: "00-11-B6",vendor: "Open Systems International"},
  {max_prefix: "00-12-2F",vendor: "Sanei Electric Inc."},
  {max_prefix: "00-12-30",vendor: "Picaso Infocommunication CO., LTD."},
  {max_prefix: "00-11-FB",vendor: "Heidelberg Engineering GmbH"},
  {max_prefix: "00-11-EF",vendor: "Conitec Datensysteme GmbH"},
  {max_prefix: "00-11-D6",vendor: "HandEra, Inc."},
  {max_prefix: "00-11-CA",vendor: "Long Range Systems, Inc."},
  {max_prefix: "00-11-C5",vendor: "TEN Technology"},
  {max_prefix: "00-11-CD",vendor: "Axsun Technologies"},
  {max_prefix: "00-11-EB",vendor: "Innovative Integration"},
  {max_prefix: "00-11-EA",vendor: "IWICS Inc."},
  {max_prefix: "00-12-39",vendor: "S Net Systems Inc."},
  {max_prefix: "00-12-33",vendor: "JRC TOKKI Co.,Ltd."},
  {max_prefix: "00-12-1D",vendor: "Netfabric Corporation"},
  {max_prefix: "00-12-14",vendor: "Koenig & Bauer AG"},
  {max_prefix: "00-12-0F",vendor: "IEEE 802.3"},
  {max_prefix: "00-12-0C",vendor: "CE-Infosys Pte Ltd"},
  {max_prefix: "00-11-53",vendor: "Trident Tek, Inc."},
  {max_prefix: "00-11-4F",vendor: "US Digital Television, Inc"},
  {max_prefix: "00-11-B0",vendor: "Fortelink Inc."},
  {max_prefix: "00-11-AC",vendor: "Simtec Electronics"},
  {max_prefix: "00-11-AD",vendor: "Shanghai Ruijie Technology"},
  {max_prefix: "00-11-A8",vendor: "Quest Technologies"},
  {max_prefix: "00-11-8F",vendor: "EUTECH INSTRUMENTS PTE. LTD."},
  {max_prefix: "00-11-91",vendor: "CTS-Clima Temperatur Systeme GmbH"},
  {max_prefix: "00-11-8D",vendor: "Hanchang System Corp."},
  {max_prefix: "00-11-89",vendor: "Aerotech Inc"},
  {max_prefix: "00-11-63",vendor: "SYSTEM SPA DEPT. ELECTRONICS"},
  {max_prefix: "00-11-5F",vendor: "ITX Security Co., Ltd."},
  {max_prefix: "00-11-82",vendor: "IMI Norgren Ltd"},
  {max_prefix: "00-11-7A",vendor: "Singim International Corp."},
  {max_prefix: "00-11-48",vendor: "Prolon Control Systems"},
  {max_prefix: "00-11-40",vendor: "Nanometrics Inc."},
  {max_prefix: "00-11-44",vendor: "Assurance Technology Corp"},
  {max_prefix: "00-11-A1",vendor: "VISION NETWARE CO.,LTD"},
  {max_prefix: "00-11-A0",vendor: "Vtech Engineering Canada Ltd"},
  {max_prefix: "00-11-9B",vendor: "Telesynergy Research Inc."},
  {max_prefix: "00-11-72",vendor: "COTRON CORPORATION"},
  {max_prefix: "00-11-68",vendor: "HomeLogic LLC"},
  {max_prefix: "00-11-3A",vendor: "SHINBORAM"},
  {max_prefix: "00-11-3F",vendor: "Alcatel DI"},
  {max_prefix: "00-0F-FC",vendor: "Merit Li-Lin Ent."},
  {max_prefix: "00-0F-FB",vendor: "Nippon Denso Industry Co., Ltd."},
  {max_prefix: "00-0F-F2",vendor: "Loud Technologies Inc."},
  {max_prefix: "00-0F-F1",vendor: "nex-G Systems Pte.Ltd"},
  {max_prefix: "00-0F-F3",vendor: "Jung Myoung Communications&Technology"},
  {max_prefix: "00-0F-A6",vendor: "S2 Security Corporation"},
  {max_prefix: "00-0F-AA",vendor: "Nexus Technologies"},
  {max_prefix: "00-0F-A8",vendor: "Photometrics, Inc."},
  {max_prefix: "00-0F-9D",vendor: "DisplayLink (UK) Ltd"},
  {max_prefix: "00-11-01",vendor: "CET Technologies Pte Ltd"},
  {max_prefix: "00-0F-FF",vendor: "Control4"},
  {max_prefix: "00-11-13",vendor: "Fraunhofer FOKUS"},
  {max_prefix: "00-11-12",vendor: "Honeywell CMSS"},
  {max_prefix: "00-0F-BD",vendor: "MRV Communications (Networks) LTD"},
  {max_prefix: "00-0F-B4",vendor: "Timespace Technology"},
  {max_prefix: "00-11-37",vendor: "AICHI ELECTRIC CO., LTD."},
  {max_prefix: "00-11-28",vendor: "Streamit"},
  {max_prefix: "00-0F-CB",vendor: "3Com Ltd"},
  {max_prefix: "00-0F-C5",vendor: "KeyMed Ltd"},
  {max_prefix: "00-0F-BF",vendor: "DGT Sp. z o.o."},
  {max_prefix: "00-0F-E3",vendor: "Damm Cellular Systems A/S"},
  {max_prefix: "00-0F-D5",vendor: "Schwechat - RISE"},
  {max_prefix: "00-0F-E0",vendor: "NComputing Co.,Ltd."},
  {max_prefix: "00-0F-6B",vendor: "GateWare Communications GmbH"},
  {max_prefix: "00-0F-67",vendor: "West Instruments"},
  {max_prefix: "00-0F-6E",vendor: "BBox"},
  {max_prefix: "00-0F-6F",vendor: "FTA Communication Technologies"},
  {max_prefix: "00-0F-63",vendor: "Obzerv Technologies"},
  {max_prefix: "00-0F-21",vendor: "Scientific Atlanta, Inc"},
  {max_prefix: "00-0F-11",vendor: "Prodrive B.V."},
  {max_prefix: "00-0F-13",vendor: "Nisca corporation"},
  {max_prefix: "00-0F-14",vendor: "Mindray Co., Ltd."},
  {max_prefix: "00-0F-89",vendor: "Winnertec System Co., Ltd."},
  {max_prefix: "00-0F-65",vendor: "icube Corp."},
  {max_prefix: "00-0F-5D",vendor: "Genexis BV"},
  {max_prefix: "00-0F-58",vendor: "Adder Technology Limited"},
  {max_prefix: "00-0F-46",vendor: "SINAR AG"},
  {max_prefix: "00-0F-41",vendor: "Zipher Ltd"},
  {max_prefix: "00-0F-71",vendor: "Sanmei Electronics Co.,Ltd"},
  {max_prefix: "00-0F-92",vendor: "Microhard Systems Inc."},
  {max_prefix: "00-0F-4D",vendor: "TalkSwitch"},
  {max_prefix: "00-0E-B6",vendor: "Riverbed Technology, Inc."},
  {max_prefix: "00-0E-B7",vendor: "Knovative, Inc."},
  {max_prefix: "00-0E-B1",vendor: "Newcotech,Ltd"},
  {max_prefix: "00-0E-B4",vendor: "GUANGZHOU GAOKE COMMUNICATIONS TECHNOLOGY CO.LTD."},
  {max_prefix: "00-0E-A9",vendor: "Shanghai Xun Shi Communications Equipment Ltd. Co."},
  {max_prefix: "00-0E-FB",vendor: "Macey Enterprises"},
  {max_prefix: "00-0E-FA",vendor: "Optoway Technology Incorporation"},
  {max_prefix: "00-0E-FD",vendor: "FUJINON CORPORATION"},
  {max_prefix: "00-0E-F5",vendor: "iPAC Technology Co., Ltd."},
  {max_prefix: "00-0E-CD",vendor: "SKOV A/S"},
  {max_prefix: "00-0E-CA",vendor: "WTSS Inc"},
  {max_prefix: "00-0E-CC",vendor: "Tableau, LLC"},
  {max_prefix: "00-0E-D5",vendor: "COPAN Systems Inc."},
  {max_prefix: "00-0E-C8",vendor: "Zoran Corporation"},
  {max_prefix: "00-0E-CB",vendor: "VineSys Technology"},
  {max_prefix: "00-0E-D2",vendor: "Filtronic plc"},
  {max_prefix: "00-0E-D9",vendor: "Aksys, Ltd."},
  {max_prefix: "00-0E-C2",vendor: "Lowrance Electronics, Inc."},
  {max_prefix: "00-0F-09",vendor: "Private"},
  {max_prefix: "00-0E-A2",vendor: "McAfee, Inc"},
  {max_prefix: "00-0E-9B",vendor: "Ambit Microsystems Corporation"},
  {max_prefix: "00-0F-12",vendor: "Panasonic Europe Ltd."},
  {max_prefix: "00-0F-0E",vendor: "WaveSplitter Technologies, Inc."},
  {max_prefix: "00-0F-0C",vendor: "SYNCHRONIC ENGINEERING"},
  {max_prefix: "00-0F-0B",vendor: "Kentima Technologies AB"},
  {max_prefix: "00-0E-A3",vendor: "CNCR-IT CO.,LTD,HangZhou P.R.CHINA"},
  {max_prefix: "00-0E-DD",vendor: "SHURE INCORPORATED"},
  {max_prefix: "00-0E-DC",vendor: "Tellion INC."},
  {max_prefix: "00-0E-23",vendor: "Incipient, Inc."},
  {max_prefix: "00-0E-25",vendor: "Hannae Technology Co., Ltd"},
  {max_prefix: "00-0E-20",vendor: "ACCESS Systems Americas, Inc."},
  {max_prefix: "00-0E-21",vendor: "MTU Friedrichshafen GmbH"},
  {max_prefix: "00-0E-22",vendor: "Private"},
  {max_prefix: "00-0E-1C",vendor: "Hach Company"},
  {max_prefix: "00-0E-7D",vendor: "Electronics Line 3000 Ltd."},
  {max_prefix: "00-0E-78",vendor: "Amtelco"},
  {max_prefix: "00-0E-71",vendor: "Gemstar Technology Development Ltd."},
  {max_prefix: "00-0E-70",vendor: "in2 Networks"},
  {max_prefix: "00-0E-37",vendor: "Harms & Wende GmbH & Co.KG"},
  {max_prefix: "00-0E-31",vendor: "Olympus Soft Imaging Solutions GmbH"},
  {max_prefix: "00-0E-2F",vendor: "Roche Diagnostics GmbH"},
  {max_prefix: "00-0E-2C",vendor: "Netcodec co."},
  {max_prefix: "00-0E-4A",vendor: "Changchun Huayu WEBPAD Co.,LTD"},
  {max_prefix: "00-0E-41",vendor: "NIHON MECHATRONICS CO.,LTD."},
  {max_prefix: "00-0E-3C",vendor: "Transact Technologies Inc"},
  {max_prefix: "00-0E-63",vendor: "Lemke Diagnostics GmbH"},
  {max_prefix: "00-0E-5B",vendor: "ParkerVision - Direct2Data"},
  {max_prefix: "00-0E-60",vendor: "360SUN Digital Broadband Corporation"},
  {max_prefix: "00-0E-09",vendor: "Shenzhen Coship Software Co.,LTD."},
  {max_prefix: "00-0E-05",vendor: "WIRELESS MATRIX CORP."},
  {max_prefix: "00-0E-93",vendor: "Mil\u00e9nio 3 Sistemas Electr\u00f3nicos, Lda."},
  {max_prefix: "00-0E-8D",vendor: "Systems in Progress Holding GmbH"},
  {max_prefix: "00-0E-54",vendor: "AlphaCell Wireless Ltd."},
  {max_prefix: "00-0E-4E",vendor: "Waveplus Technology Co., Ltd."},
  {max_prefix: "00-0E-76",vendor: "GEMSOC INNOVISION INC."},
  {max_prefix: "00-0D-E0",vendor: "ICPDAS Co.,LTD"},
  {max_prefix: "00-0D-E3",vendor: "AT Sweden AB"},
  {max_prefix: "00-0D-D4",vendor: "Symantec Corporation"},
  {max_prefix: "00-0D-D2",vendor: "Simrad Optronics ASA"},
  {max_prefix: "00-0D-D1",vendor: "Stryker Corporation"},
  {max_prefix: "00-0D-D7",vendor: "Bright"},
  {max_prefix: "00-0D-BE",vendor: "Bel Fuse Europe Ltd.,UK"},
  {max_prefix: "00-0D-BC",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-0D-EA",vendor: "Kingtel Telecommunication Corp."},
  {max_prefix: "00-0D-ED",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-0D-E4",vendor: "DIGINICS, Inc."},
  {max_prefix: "00-0D-DC",vendor: "VAC"},
  {max_prefix: "00-0D-D9",vendor: "Anton Paar GmbH"},
  {max_prefix: "00-0D-C9",vendor: "THALES Elektronik Systeme GmbH"},
  {max_prefix: "00-0D-9F",vendor: "RF Micro Devices"},
  {max_prefix: "00-0D-A5",vendor: "Fabric7 Systems, Inc"},
  {max_prefix: "00-0D-C5",vendor: "EchoStar Global B.V."},
  {max_prefix: "00-0D-C8",vendor: "AirMagnet, Inc"},
  {max_prefix: "00-0E-02",vendor: "Advantech AMT Inc."},
  {max_prefix: "00-0D-89",vendor: "Bils Technology Inc"},
  {max_prefix: "00-0D-86",vendor: "Huber + Suhner AG"},
  {max_prefix: "00-0D-81",vendor: "Pepperl+Fuchs GmbH"},
  {max_prefix: "00-0D-7A",vendor: "DiGATTO Asia Pacific Pte Ltd"},
  {max_prefix: "00-0D-77",vendor: "FalconStor Software"},
  {max_prefix: "00-0D-76",vendor: "Hokuto Denshi Co,. Ltd."},
  {max_prefix: "00-0D-14",vendor: "Vtech Innovation LP dba Advanced American Telephones"},
  {max_prefix: "00-0D-17",vendor: "Turbo Networks Co.Ltd"},
  {max_prefix: "00-0D-18",vendor: "Mega-Trend Electronics CO., LTD."},
  {max_prefix: "00-0D-20",vendor: "ASAHIKASEI TECHNOSYSTEM CO.,LTD."},
  {max_prefix: "00-0D-6F",vendor: "Ember Corporation"},
  {max_prefix: "00-0D-5E",vendor: "NEC Personal Products"},
  {max_prefix: "00-0D-5B",vendor: "Smart Empire Investments Limited"},
  {max_prefix: "00-0D-59",vendor: "Amity Systems, Inc."},
  {max_prefix: "00-0D-50",vendor: "Galazar Networks"},
  {max_prefix: "00-0D-25",vendor: "SANDEN CORPORATION"},
  {max_prefix: "00-0D-24",vendor: "SENTEC E&E CO., LTD."},
  {max_prefix: "00-0D-22",vendor: "Unitronics LTD"},
  {max_prefix: "00-0D-99",vendor: "Orbital Sciences Corp.; Launch Systems Group"},
  {max_prefix: "00-0D-8F",vendor: "King Tsushin Kogyo Co., LTD."},
  {max_prefix: "00-0D-49",vendor: "Triton Systems of Delaware, Inc."},
  {max_prefix: "00-0D-48",vendor: "AEWIN Technologies Co., Ltd."},
  {max_prefix: "00-0D-3F",vendor: "VTI Instruments Corporation"},
  {max_prefix: "00-0D-3A",vendor: "Microsoft Corp."},
  {max_prefix: "00-0D-30",vendor: "IceFyre Semiconductor"},
  {max_prefix: "00-0D-31",vendor: "Compellent Technologies, Inc."},
  {max_prefix: "00-0D-7B",vendor: "Consensys Computers Inc."},
  {max_prefix: "00-0D-6C",vendor: "M-Audio"},
  {max_prefix: "00-0D-70",vendor: "Datamax Corporation"},
  {max_prefix: "00-0C-E3",vendor: "Option International N.V."},
  {max_prefix: "00-0C-E4",vendor: "NeuroCom International, Inc."},
  {max_prefix: "00-0C-E8",vendor: "GuangZhou AnJuBao Co., Ltd"},
  {max_prefix: "00-0C-34",vendor: "Vixen Co., Ltd."},
  {max_prefix: "00-0C-B1",vendor: "Salland Engineering (Europe) BV"},
  {max_prefix: "00-0C-BC",vendor: "Iscutum"},
  {max_prefix: "00-0C-CA",vendor: "HGST a Western Digital Company"},
  {max_prefix: "00-0C-B5",vendor: "Premier Technolgies, Inc"},
  {max_prefix: "00-0C-B6",vendor: "NANJING SEU MOBILE & INTERNET TECHNOLOGY CO.,LTD"},
  {max_prefix: "00-0C-C3",vendor: "BeWAN systems"},
  {max_prefix: "00-0C-D0",vendor: "Symetrix"},
  {max_prefix: "00-0C-D9",vendor: "Itcare Co., Ltd"},
  {max_prefix: "00-0C-D5",vendor: "Passave Inc."},
  {max_prefix: "00-0C-D2",vendor: "Schaffner EMV AG"},
  {max_prefix: "00-0D-04",vendor: "Foxboro Eckardt Development GmbH"},
  {max_prefix: "00-0D-05",vendor: "cybernet manufacturing inc."},
  {max_prefix: "00-0D-08",vendor: "AboveCable, Inc."},
  {max_prefix: "00-0C-FE",vendor: "Grand Electronic Co., Ltd"},
  {max_prefix: "00-0D-0E",vendor: "Inqnet Systems, Inc."},
  {max_prefix: "00-0D-11",vendor: "DENTSPLY - Gendex"},
  {max_prefix: "00-0C-B4",vendor: "AutoCell Laboratories, Inc."},
  {max_prefix: "00-0A-07",vendor: "WebWayOne Ltd"},
  {max_prefix: "00-0C-B0",vendor: "Star Semiconductor Corporation"},
  {max_prefix: "00-0C-96",vendor: "OQO, Inc."},
  {max_prefix: "00-0C-98",vendor: "LETEK Communications Inc."},
  {max_prefix: "00-0C-8E",vendor: "Mentor Engineering Inc"},
  {max_prefix: "00-0C-A2",vendor: "Harmonic Video Network"},
  {max_prefix: "00-0C-A4",vendor: "Prompttec Product Management GmbH"},
  {max_prefix: "00-0C-24",vendor: "ANATOR"},
  {max_prefix: "00-0C-19",vendor: "Telio Communications GmbH"},
  {max_prefix: "00-0C-1D",vendor: "Mettler & Fuchs AG"},
  {max_prefix: "00-0C-13",vendor: "MediaQ"},
  {max_prefix: "00-0C-2D",vendor: "FullWave Technology Co., Ltd."},
  {max_prefix: "00-0C-26",vendor: "Weintek Labs. Inc."},
  {max_prefix: "00-0C-2B",vendor: "ELIAS Technology, Inc."},
  {max_prefix: "00-0C-1A",vendor: "Quest Technical Solutions Inc."},
  {max_prefix: "00-0C-65",vendor: "Sunin Telecom"},
  {max_prefix: "00-0C-6F",vendor: "Amtek system co.,LTD."},
  {max_prefix: "00-0C-6C",vendor: "Eve Systems GmbH"},
  {max_prefix: "00-0C-73",vendor: "TELSON ELECTRONICS CO., LTD"},
  {max_prefix: "00-0C-7E",vendor: "Tellium Incorporated"},
  {max_prefix: "00-0C-87",vendor: "AMD"},
  {max_prefix: "00-0C-83",vendor: "Logical Solutions"},
  {max_prefix: "00-0C-5B",vendor: "HANWANG TECHNOLOGY CO.,LTD"},
  {max_prefix: "00-0C-60",vendor: "ACM Systems"},
  {max_prefix: "00-0C-32",vendor: "Avionic Design GmbH"},
  {max_prefix: "00-0C-33",vendor: "Compucase Enterprise Co. Ltd."},
  {max_prefix: "00-0C-36",vendor: "S-Takaya Electronics Industry Co.,Ltd."},
  {max_prefix: "00-0C-58",vendor: "M&S Systems"},
  {max_prefix: "00-0C-51",vendor: "Scientific Technologies Inc."},
  {max_prefix: "00-0C-39",vendor: "Sentinel Wireless Inc."},
  {max_prefix: "00-0B-F6",vendor: "Nitgen Co., Ltd"},
  {max_prefix: "00-0C-01",vendor: "Abatron AG"},
  {max_prefix: "00-0B-FD",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-0B-F4",vendor: "Private"},
  {max_prefix: "00-0B-FA",vendor: "EXEMYS SRL"},
  {max_prefix: "00-0B-FB",vendor: "D-NET International Corporation"},
  {max_prefix: "00-0B-A9",vendor: "CloudShield Technologies, Inc."},
  {max_prefix: "00-0B-A1",vendor: "Fujikura Solutions Ltd."},
  {max_prefix: "00-0B-A6",vendor: "Miyakawa Electric Works Ltd."},
  {max_prefix: "00-0B-93",vendor: "Ritter Elektronik"},
  {max_prefix: "00-0B-9B",vendor: "Sirius System Co, Ltd."},
  {max_prefix: "00-0B-F0",vendor: "MoTEX Products Co., Ltd."},
  {max_prefix: "00-0B-F1",vendor: "LAP Laser Applikations"},
  {max_prefix: "00-0B-EE",vendor: "inc.jet, Incorporated"},
  {max_prefix: "00-0B-E2",vendor: "Lumenera Corporation"},
  {max_prefix: "00-0C-08",vendor: "HUMEX Technologies Corp."},
  {max_prefix: "00-0C-0D",vendor: "Communications & Power Industries / Satcom Division"},
  {max_prefix: "00-0C-04",vendor: "Tecnova"},
  {max_prefix: "00-0B-CE",vendor: "Free2move AB"},
  {max_prefix: "00-0B-CF",vendor: "AGFA NDT INC."},
  {max_prefix: "00-0B-C3",vendor: "Multiplex, Inc."},
  {max_prefix: "00-0B-BE",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-0B-8E",vendor: "Ascent Corporation"},
  {max_prefix: "00-0B-8F",vendor: "AKITA ELECTRONICS SYSTEMS CO.,LTD."},
  {max_prefix: "00-0B-CB",vendor: "Fagor Automation , S. Coop"},
  {max_prefix: "00-0B-C8",vendor: "AirFlow Networks"},
  {max_prefix: "00-0B-E1",vendor: "Nokia NET Product Operations"},
  {max_prefix: "00-0B-E0",vendor: "SercoNet Ltd."},
  {max_prefix: "00-0B-BD",vendor: "Connexionz Limited"},
  {max_prefix: "00-0B-A8",vendor: "HANBACK ELECTRONICS CO., LTD."},
  {max_prefix: "00-0B-16",vendor: "Communication Machinery Corporation"},
  {max_prefix: "00-0B-12",vendor: "NURI Telecom Co., Ltd."},
  {max_prefix: "00-0B-0F",vendor: "Bosch Rexroth"},
  {max_prefix: "00-0B-0C",vendor: "Agile Systems Inc."},
  {max_prefix: "00-0B-0A",vendor: "dBm Optics"},
  {max_prefix: "00-0B-09",vendor: "Ifoundry Systems Singapore"},
  {max_prefix: "00-0B-8D",vendor: "Avvio Networks"},
  {max_prefix: "00-0B-7F",vendor: "Align Engineering LLC"},
  {max_prefix: "00-0B-85",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-0B-81",vendor: "Kaparel Corporation"},
  {max_prefix: "00-0B-4E",vendor: "Communications & Power Industries"},
  {max_prefix: "00-0B-4D",vendor: "Emuzed"},
  {max_prefix: "00-0B-40",vendor: "Cambridge Industries Group (CIG)"},
  {max_prefix: "00-0B-44",vendor: "Concord Idea Corp."},
  {max_prefix: "00-0B-42",vendor: "commax Co., Ltd."},
  {max_prefix: "00-0B-47",vendor: "Advanced Energy"},
  {max_prefix: "00-0B-3D",vendor: "CONTAL OK Ltd."},
  {max_prefix: "00-0B-24",vendor: "AirLogic"},
  {max_prefix: "00-0B-1D",vendor: "LayerZero Power Systems, Inc."},
  {max_prefix: "00-0B-19",vendor: "Vernier Networks, Inc."},
  {max_prefix: "00-0B-82",vendor: "Grandstream Networks, Inc."},
  {max_prefix: "00-0B-6E",vendor: "Neff Instrument Corp."},
  {max_prefix: "00-0B-72",vendor: "Lawo AG"},
  {max_prefix: "00-0B-78",vendor: "TAIFATECH INC."},
  {max_prefix: "00-0B-6C",vendor: "Sychip Inc."},
  {max_prefix: "00-91-D6",vendor: "Crystal Group, Inc."},
  {max_prefix: "00-0B-31",vendor: "Yantai ZhiYang Scientific and technology industry CO., LTD"},
  {max_prefix: "00-0B-2F",vendor: "bplan GmbH"},
  {max_prefix: "00-0B-5A",vendor: "HyperEdge"},
  {max_prefix: "00-0A-98",vendor: "M+F Gwinner GmbH & Co"},
  {max_prefix: "00-0A-9B",vendor: "TB Group Inc"},
  {max_prefix: "00-0A-C5",vendor: "Color Kinetics"},
  {max_prefix: "00-0A-BD",vendor: "Rupprecht & Patashnick Co."},
  {max_prefix: "00-0A-B0",vendor: "LOYTEC electronics GmbH"},
  {max_prefix: "00-0A-B5",vendor: "Digital Electronic Network"},
  {max_prefix: "00-0A-FD",vendor: "Kentec Electronics"},
  {max_prefix: "00-0A-EF",vendor: "OTRUM ASA"},
  {max_prefix: "00-0A-E5",vendor: "ScottCare Corporation"},
  {max_prefix: "00-0A-84",vendor: "Rainsun Enterprise Co., Ltd."},
  {max_prefix: "00-0A-7E",vendor: "The Advantage Group"},
  {max_prefix: "00-0A-78",vendor: "OLITEC"},
  {max_prefix: "00-0A-FE",vendor: "NovaPal Ltd"},
  {max_prefix: "00-0A-A5",vendor: "MAXLINK INDUSTRIES LIMITED"},
  {max_prefix: "00-0A-A9",vendor: "Brooks Automation GmbH"},
  {max_prefix: "00-0A-CB",vendor: "XPAK MSA Group"},
  {max_prefix: "00-0A-D5",vendor: "Brainchild Electronic Co., Ltd."},
  {max_prefix: "00-0A-D6",vendor: "BeamReach Networks"},
  {max_prefix: "00-0A-D2",vendor: "JEPICO Corporation"},
  {max_prefix: "00-0A-A2",vendor: "SYSTEK INC."},
  {max_prefix: "00-0A-91",vendor: "HemoCue AB"},
  {max_prefix: "00-0A-6C",vendor: "Walchem Corporation"},
  {max_prefix: "00-0A-6B",vendor: "Tadiran Telecom Business Systems LTD"},
  {max_prefix: "00-0A-5F",vendor: "almedio inc."},
  {max_prefix: "00-0A-61",vendor: "Cellinx Systems Inc."},
  {max_prefix: "00-0A-5B",vendor: "Power-One as"},
  {max_prefix: "00-0A-3A",vendor: "J-THREE INTERNATIONAL Holding Co., Ltd."},
  {max_prefix: "00-0A-47",vendor: "Allied Vision Technologies"},
  {max_prefix: "00-0A-44",vendor: "Avery Dennison Deutschland GmbH"},
  {max_prefix: "00-0A-3C",vendor: "Enerpoint Ltd."},
  {max_prefix: "00-0A-40",vendor: "Crown Audio -- Harmanm International"},
  {max_prefix: "00-0A-7B",vendor: "Cornelius Consult"},
  {max_prefix: "00-0A-73",vendor: "Scientific Atlanta"},
  {max_prefix: "00-0A-69",vendor: "SUNNY bell Technology Co., Ltd."},
  {max_prefix: "00-09-EB",vendor: "HuMANDATA LTD."},
  {max_prefix: "00-09-E8",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-09-E9",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-09-ED",vendor: "CipherOptics"},
  {max_prefix: "00-09-F2",vendor: "Cohu, Inc., Electronics Division"},
  {max_prefix: "00-0A-26",vendor: "CEIA S.p.A."},
  {max_prefix: "00-0A-29",vendor: "Pan Dacom Networking AG"},
  {max_prefix: "00-0A-1D",vendor: "Optical Communications Products Inc."},
  {max_prefix: "00-0A-16",vendor: "Lassen Research"},
  {max_prefix: "00-0A-18",vendor: "Vichel Inc."},
  {max_prefix: "00-09-F8",vendor: "UNIMO TECHNOLOGY CO., LTD."},
  {max_prefix: "00-09-FF",vendor: "X.net 2000 GmbH"},
  {max_prefix: "00-0A-03",vendor: "ENDESA SERVICIOS, S.L."},
  {max_prefix: "00-09-FE",vendor: "Daisy Technologies, Inc."},
  {max_prefix: "00-0A-28",vendor: "Motorola"},
  {max_prefix: "00-0A-55",vendor: "MARKEM Corporation"},
  {max_prefix: "00-0A-4C",vendor: "Molecular Devices Corporation"},
  {max_prefix: "00-0A-4D",vendor: "Noritz Corporation"},
  {max_prefix: "00-0A-06",vendor: "Teledex LLC"},
  {max_prefix: "00-0A-0D",vendor: "Amphenol"},
  {max_prefix: "00-09-A2",vendor: "Interface Co., Ltd."},
  {max_prefix: "00-09-A1",vendor: "Telewise Communications, Inc."},
  {max_prefix: "00-09-76",vendor: "Datasoft ISDN Systems GmbH"},
  {max_prefix: "00-09-7A",vendor: "Louis Design Labs."},
  {max_prefix: "00-09-6B",vendor: "IBM Corp"},
  {max_prefix: "00-09-6D",vendor: "Powernet Technologies Corp."},
  {max_prefix: "00-09-64",vendor: "Hi-Techniques, Inc."},
  {max_prefix: "00-09-65",vendor: "HyunJu Computer Co., Ltd."},
  {max_prefix: "00-09-82",vendor: "Loewe Opta GmbH"},
  {max_prefix: "00-09-83",vendor: "GlobalTop Technology, Inc."},
  {max_prefix: "00-09-7D",vendor: "SecWell Networks Oy"},
  {max_prefix: "00-09-AF",vendor: "e-generis"},
  {max_prefix: "00-09-AD",vendor: "HYUNDAI SYSCOMM, INC."},
  {max_prefix: "00-09-A9",vendor: "Ikanos Communications"},
  {max_prefix: "00-09-9F",vendor: "VIDEX INC."},
  {max_prefix: "00-09-C2",vendor: "Onity, Inc."},
  {max_prefix: "00-09-C3",vendor: "NETAS"},
  {max_prefix: "00-09-B5",vendor: "3J Tech. Co., Ltd."},
  {max_prefix: "00-09-B8",vendor: "Entise Systems"},
  {max_prefix: "00-09-DC",vendor: "Galaxis Technology AG"},
  {max_prefix: "00-09-DD",vendor: "Mavin Technology Inc."},
  {max_prefix: "00-09-C6",vendor: "Visionics Corporation"},
  {max_prefix: "00-09-D1",vendor: "SERANOA NETWORKS INC"},
  {max_prefix: "00-09-CE",vendor: "SpaceBridge Semiconductor Corp."},
  {max_prefix: "00-09-BD",vendor: "Epygi Technologies, Ltd."},
  {max_prefix: "00-09-6F",vendor: "Beijing Zhongqing Elegant Tech. Corp.,Limited"},
  {max_prefix: "00-09-5E",vendor: "Masstech Group Inc."},
  {max_prefix: "00-09-39",vendor: "ShibaSoku Co.,Ltd."},
  {max_prefix: "00-09-33",vendor: "Ophit Co.Ltd."},
  {max_prefix: "00-09-32",vendor: "Omnilux"},
  {max_prefix: "00-09-1C",vendor: "CacheVision, Inc"},
  {max_prefix: "00-09-1A",vendor: "Macat Optics & Electronics Co., Ltd."},
  {max_prefix: "00-09-1B",vendor: "Digital Generation Inc."},
  {max_prefix: "00-09-59",vendor: "Sitecsoft"},
  {max_prefix: "00-09-57",vendor: "Supercaller, Inc."},
  {max_prefix: "00-09-4F",vendor: "elmegt GmbH & Co. KG"},
  {max_prefix: "00-08-F2",vendor: "C&S Technology"},
  {max_prefix: "00-08-EA",vendor: "Motion Control Engineering, Inc"},
  {max_prefix: "00-09-02",vendor: "Redline Communications Inc."},
  {max_prefix: "00-08-FA",vendor: "KEB Automation KG"},
  {max_prefix: "00-09-29",vendor: "Sanyo Industries (UK) Limited"},
  {max_prefix: "00-09-28",vendor: "Telecore"},
  {max_prefix: "00-08-ED",vendor: "ST&T Instrument Corp."},
  {max_prefix: "00-09-43",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-08-72",vendor: "Sorenson Communications"},
  {max_prefix: "00-08-7C",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-08-79",vendor: "CEM Corporation"},
  {max_prefix: "00-08-7D",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-08-75",vendor: "Acorp Electronics Corp."},
  {max_prefix: "00-08-6F",vendor: "Resources Computer Network Ltd."},
  {max_prefix: "00-08-DF",vendor: "Alistel Inc."},
  {max_prefix: "00-08-DB",vendor: "Corrigent Systems"},
  {max_prefix: "00-08-D8",vendor: "Dowkey Microwave"},
  {max_prefix: "00-08-D2",vendor: "ZOOM Networks Inc."},
  {max_prefix: "00-08-B6",vendor: "RouteFree, Inc."},
  {max_prefix: "00-08-B0",vendor: "HUBER+SUHNER BKtel GmbH"},
  {max_prefix: "00-08-AC",vendor: " BST GmbH"},
  {max_prefix: "00-08-A1",vendor: "CNet Technology Inc."},
  {max_prefix: "00-08-93",vendor: "LE INFORMATION COMMUNICATION INC."},
  {max_prefix: "00-08-88",vendor: "OULLIM Information Technology Inc,."},
  {max_prefix: "00-08-85",vendor: "EMS Dr. Thomas W\u00fcnsche"},
  {max_prefix: "00-08-C5",vendor: "Liontech Co., Ltd."},
  {max_prefix: "00-08-CA",vendor: "TwinHan Technology Co.,Ltd"},
  {max_prefix: "00-08-AA",vendor: "KARAM"},
  {max_prefix: "00-08-AB",vendor: "EnerLinx.com, Inc."},
  {max_prefix: "00-08-AD",vendor: "Toyo-Linx Co., Ltd."},
  {max_prefix: "00-08-9A",vendor: "Alcatel Microelectronics"},
  {max_prefix: "00-08-26",vendor: "Colorado Med Tech"},
  {max_prefix: "00-08-1C",vendor: "@pos.com"},
  {max_prefix: "00-08-67",vendor: "Uptime Devices"},
  {max_prefix: "00-08-5E",vendor: "PCO AG"},
  {max_prefix: "00-08-56",vendor: "Gamatronic Electronic Industries Ltd."},
  {max_prefix: "00-08-53",vendor: "Schleicher GmbH & Co. Relaiswerke KG"},
  {max_prefix: "00-07-A3",vendor: "Ositis Software, Inc."},
  {max_prefix: "00-07-9A",vendor: "Verint Systems Inc"},
  {max_prefix: "00-07-74",vendor: "GuangZhou Thinker Technology Co. Ltd."},
  {max_prefix: "00-07-98",vendor: "Selea SRL"},
  {max_prefix: "00-07-91",vendor: "International Data Communications, Inc."},
  {max_prefix: "00-05-F9",vendor: "TOA Corporation"},
  {max_prefix: "00-07-CA",vendor: "Creatix Polymedia Ges Fur Kommunikaitonssysteme"},
  {max_prefix: "00-07-C5",vendor: "Gcom, Inc."},
  {max_prefix: "00-07-C8",vendor: "Brain21, Inc."},
  {max_prefix: "00-07-C1",vendor: "Overture Networks, Inc."},
  {max_prefix: "00-07-AE",vendor: "Britestream Networks, Inc."},
  {max_prefix: "00-07-B1",vendor: "Equator Technologies"},
  {max_prefix: "00-07-A7",vendor: "A-Z Inc."},
  {max_prefix: "00-07-A6",vendor: "Leviton Manufacturing Co., Inc."},
  {max_prefix: "00-07-F3",vendor: "Thinkengine Networks"},
  {max_prefix: "00-07-EE",vendor: "telco Informationssysteme GmbH"},
  {max_prefix: "00-07-E2",vendor: "Bitworks, Inc."},
  {max_prefix: "00-07-E6",vendor: "edgeflow Canada Inc."},
  {max_prefix: "00-08-16",vendor: "Bluelon ApS"},
  {max_prefix: "00-08-11",vendor: "VOIX Corporation"},
  {max_prefix: "00-08-06",vendor: "Raonet Systems, Inc."},
  {max_prefix: "00-07-E5",vendor: "Coup Corporation"},
  {max_prefix: "00-07-DE",vendor: "eCopilt AB"},
  {max_prefix: "00-07-DF",vendor: "Vbrick Systems Inc."},
  {max_prefix: "00-07-C2",vendor: "Netsys Telecom"},
  {max_prefix: "00-07-8F",vendor: "Emkay Innovative Products"},
  {max_prefix: "00-07-82",vendor: "Oracle Corporation"},
  {max_prefix: "00-07-F8",vendor: "ITDevices, Inc."},
  {max_prefix: "00-06-F2",vendor: "Platys Communications"},
  {max_prefix: "00-06-FA",vendor: "IP SQUARE Co, Ltd."},
  {max_prefix: "00-07-03",vendor: "CSEE Transport"},
  {max_prefix: "00-07-06",vendor: "Sanritz Corporation"},
  {max_prefix: "00-06-EF",vendor: "Maxxan Systems, Inc."},
  {max_prefix: "00-06-E9",vendor: "Intime Corp."},
  {max_prefix: "00-07-60",vendor: "TOMIS Information & Telecom Corp."},
  {max_prefix: "00-07-4E",vendor: "IPFRONT Inc"},
  {max_prefix: "00-07-52",vendor: "Rhythm Watch Co., Ltd."},
  {max_prefix: "00-07-4F",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-07-07",vendor: "Interalia Inc."},
  {max_prefix: "00-06-F0",vendor: "Digeo, Inc."},
  {max_prefix: "00-07-00",vendor: "Zettamedia Korea"},
  {max_prefix: "00-07-43",vendor: "Chelsio Communications"},
  {max_prefix: "00-07-47",vendor: "Mecalc"},
  {max_prefix: "00-07-44",vendor: "Unico, Inc."},
  {max_prefix: "00-07-49",vendor: "CENiX Inc."},
  {max_prefix: "00-07-30",vendor: "Hutchison OPTEL Telecom Technology Co., Ltd."},
  {max_prefix: "00-07-22",vendor: "The Nielsen Company"},
  {max_prefix: "00-07-1F",vendor: "European Systems Integration"},
  {max_prefix: "00-07-21",vendor: "Formac Elektronik GmbH"},
  {max_prefix: "00-07-39",vendor: "Scotty Group Austria Gmbh"},
  {max_prefix: "00-07-3D",vendor: "Nanjing Postel Telecommunications Co., Ltd."},
  {max_prefix: "00-07-35",vendor: "Flarion Technologies, Inc."},
  {max_prefix: "00-07-86",vendor: "Wireless Networks Inc."},
  {max_prefix: "00-07-79",vendor: "Sungil Telecom Co., Ltd."},
  {max_prefix: "00-07-7E",vendor: "Elrest GmbH"},
  {max_prefix: "00-07-78",vendor: "GERSTEL GmbH & Co. KG"},
  {max_prefix: "00-07-08",vendor: "Bitrage Inc."},
  {max_prefix: "00-07-12",vendor: "JAL Information Technology"},
  {max_prefix: "00-07-13",vendor: "IP One, Inc."},
  {max_prefix: "00-07-6D",vendor: "Flexlight Networks"},
  {max_prefix: "00-07-5E",vendor: "Ametek Power Instruments"},
  {max_prefix: "00-06-BC",vendor: "Macrolink, Inc."},
  {max_prefix: "00-06-C2",vendor: "Smartmatic Corporation"},
  {max_prefix: "00-06-54",vendor: "Winpresa Building Automation Technologies GmbH"},
  {max_prefix: "00-06-B4",vendor: "Vorne Industries, Inc."},
  {max_prefix: "00-06-AE",vendor: "Himachal Futuristic Communications Ltd"},
  {max_prefix: "00-06-B0",vendor: "Comtech EF Data Corp."},
  {max_prefix: "00-06-CA",vendor: "American Computer & Digital Components, Inc. (ACDC)"},
  {max_prefix: "00-06-CE",vendor: "DATENO"},
  {max_prefix: "00-06-C5",vendor: "INNOVI Technologies Limited"},
  {max_prefix: "00-06-C6",vendor: "lesswire AG"},
  {max_prefix: "00-06-B7",vendor: "TELEM GmbH"},
  {max_prefix: "00-06-EA",vendor: "ELZET80 Mikrocomputer GmbH&Co. KG"},
  {max_prefix: "00-06-E5",vendor: "Fujian Newland Computer Ltd. Co."},
  {max_prefix: "00-06-DE",vendor: "Flash Technology"},
  {max_prefix: "00-06-DF",vendor: "AIDONIC Corporation"},
  {max_prefix: "00-06-8E",vendor: "HID Corporation"},
  {max_prefix: "00-06-8A",vendor: "NeuronNet Co. Ltd. R&D Center"},
  {max_prefix: "00-06-85",vendor: "NetNearU Corporation"},
  {max_prefix: "00-06-7F",vendor: "Digeo, Inc."},
  {max_prefix: "00-06-83",vendor: "Bravara Communications, Inc."},
  {max_prefix: "00-06-55",vendor: "Yipee, Inc."},
  {max_prefix: "00-06-7A",vendor: "JMP Systems"},
  {max_prefix: "00-06-73",vendor: "TKH Security Solutions USA"},
  {max_prefix: "00-06-76",vendor: "Novra Technologies Inc."},
  {max_prefix: "00-06-64",vendor: "Fostex Corporation"},
  {max_prefix: "00-06-DD",vendor: "AT & T Laboratories - Cambridge Ltd"},
  {max_prefix: "00-06-D1",vendor: "Tahoe Networks, Inc."},
  {max_prefix: "00-06-D4",vendor: "Interactive Objects, Inc."},
  {max_prefix: "00-06-9B",vendor: "AVT Audio Video Technologies GmbH"},
  {max_prefix: "00-06-93",vendor: "Flexus Computer Technology, Inc."},
  {max_prefix: "00-06-96",vendor: "Advent Networks"},
  {max_prefix: "00-06-5D",vendor: "Heidelberg Web Systems"},
  {max_prefix: "00-06-50",vendor: "Tiburon Networks, Inc."},
  {max_prefix: "00-06-5E",vendor: "Photuris, Inc."},
  {max_prefix: "00-05-D7",vendor: "Vista Imaging, Inc."},
  {max_prefix: "00-05-DB",vendor: "PSI Nentec GmbH"},
  {max_prefix: "00-05-DD",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-05-D9",vendor: "Techno Valley, Inc."},
  {max_prefix: "00-05-DC",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-05-C3",vendor: "Pacific Instruments, Inc."},
  {max_prefix: "00-05-B9",vendor: "Airvana, Inc."},
  {max_prefix: "00-05-BC",vendor: "Resource Data Management Ltd"},
  {max_prefix: "00-05-BE",vendor: "Kongsberg Seatex AS"},
  {max_prefix: "00-05-BD",vendor: "ROAX BV"},
  {max_prefix: "00-05-C1",vendor: "A-Kyung Motion, Inc."},
  {max_prefix: "00-05-B6",vendor: "INSYS Microelectronics GmbH"},
  {max_prefix: "00-06-44",vendor: "NextGen Business Solutions, Inc"},
  {max_prefix: "00-06-45",vendor: "Meisei Electric Co. Ltd."},
  {max_prefix: "00-06-40",vendor: "White Rock Networks"},
  {max_prefix: "00-06-3A",vendor: "Dura Micro, Inc."},
  {max_prefix: "00-06-28",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-06-1D",vendor: "MIP Telecom, Inc."},
  {max_prefix: "00-06-19",vendor: "Connection Technology Systems"},
  {max_prefix: "00-06-32",vendor: "Mesco Engineering GmbH"},
  {max_prefix: "00-06-34",vendor: "GTE Airfone Inc."},
  {max_prefix: "00-06-08",vendor: "At-Sky SAS"},
  {max_prefix: "00-05-F6",vendor: "Young Chang Co. Ltd."},
  {max_prefix: "00-05-FC",vendor: "Schenck Pegasus Corp."},
  {max_prefix: "00-05-E8",vendor: "TurboWave, Inc."},
  {max_prefix: "A0-6A-00",vendor: "Verilink Corporation"},
  {max_prefix: "00-05-F8",vendor: "Real Time Access, Inc."},
  {max_prefix: "00-05-EB",vendor: "Blue Ridge Networks, Inc."},
  {max_prefix: "00-06-0C",vendor: "Melco Industries, Inc."},
  {max_prefix: "00-06-0E",vendor: "IGYS Systems, Inc."},
  {max_prefix: "00-06-14",vendor: "Prism Holdings"},
  {max_prefix: "00-05-D0",vendor: "Solinet Systems"},
  {max_prefix: "00-05-80",vendor: "FibroLAN Ltd."},
  {max_prefix: "00-05-82",vendor: "ClearCube Technology"},
  {max_prefix: "00-05-78",vendor: "Private"},
  {max_prefix: "00-05-72",vendor: "Deonet Co., Ltd."},
  {max_prefix: "00-05-76",vendor: "NSM Technology Ltd."},
  {max_prefix: "00-05-4A",vendor: "Ario Data Networks, Inc."},
  {max_prefix: "00-05-48",vendor: "Disco Corporation"},
  {max_prefix: "00-05-3E",vendor: "KID Systeme GmbH"},
  {max_prefix: "00-05-3F",vendor: "VisionTek, Inc."},
  {max_prefix: "00-05-B4",vendor: "Aceex Corporation"},
  {max_prefix: "00-05-98",vendor: "CRONOS S.r.l."},
  {max_prefix: "00-05-B7",vendor: "Arbor Technology Corp."},
  {max_prefix: "00-05-9B",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-05-90",vendor: "Swissvoice Ltd."},
  {max_prefix: "00-05-95",vendor: "Alesis Corporation"},
  {max_prefix: "00-05-62",vendor: "Digital View Limited"},
  {max_prefix: "00-05-55",vendor: "Japan Cash Machine Co., Ltd."},
  {max_prefix: "00-05-52",vendor: "Xycotec Computer GmbH"},
  {max_prefix: "00-05-9F",vendor: "Yotta Networks, Inc."},
  {max_prefix: "00-05-87",vendor: "Locus, Incorporated"},
  {max_prefix: "00-05-3D",vendor: "Agere Systems"},
  {max_prefix: "00-05-35",vendor: "Chip PC Ltd."},
  {max_prefix: "00-05-27",vendor: "SJ Tek Co. Ltd"},
  {max_prefix: "00-05-29",vendor: "Shanghai Broadan Communication Technology Co., Ltd"},
  {max_prefix: "00-05-6A",vendor: "Heuft Systemtechnik GmbH"},
  {max_prefix: "00-05-68",vendor: "Piltofish Networks AB"},
  {max_prefix: "00-04-DA",vendor: "Relax Technology, Inc."},
  {max_prefix: "00-04-E5",vendor: "Glonet Systems, Inc."},
  {max_prefix: "00-04-D2",vendor: "Adcon Telemetry GmbH"},
  {max_prefix: "00-04-D3",vendor: "Toyokeiki Co., Ltd."},
  {max_prefix: "00-04-D5",vendor: "Hitachi Information & Communication Engineering, Ltd."},
  {max_prefix: "00-04-CA",vendor: "FreeMs Corp."},
  {max_prefix: "00-05-0D",vendor: "Midstream Technologies, Inc."},
  {max_prefix: "00-05-00",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-04-FD",vendor: "Japan Control Engineering Co., Ltd."},
  {max_prefix: "00-04-F7",vendor: "Omega Band, Inc."},
  {max_prefix: "00-04-EE",vendor: "Lincoln Electric Company"},
  {max_prefix: "00-04-F0",vendor: "International Computers, Ltd"},
  {max_prefix: "00-04-C5",vendor: "ASE Technologies, USA"},
  {max_prefix: "00-05-2C",vendor: "Supreme Magic Corporation"},
  {max_prefix: "00-05-19",vendor: "Siemens Building Technologies AG,"},
  {max_prefix: "00-05-07",vendor: "Fine Appliance Corp."},
  {max_prefix: "00-04-B0",vendor: "ELESIGN Co., Ltd."},
  {max_prefix: "00-04-AB",vendor: "Mavenir Inc."},
  {max_prefix: "00-04-A7",vendor: "FabiaTech Corporation"},
  {max_prefix: "00-04-AA",vendor: "Jetstream Communications"},
  {max_prefix: "00-04-A0",vendor: "Verity Instruments, Inc."},
  {max_prefix: "00-04-9E",vendor: "Wirelink Co., Ltd."},
  {max_prefix: "00-04-9A",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-04-47",vendor: "Acrowave Systems Co., Ltd."},
  {max_prefix: "00-04-3E",vendor: "Telencomm"},
  {max_prefix: "00-04-36",vendor: "ELANsat Technologies, Inc."},
  {max_prefix: "00-04-35",vendor: "InfiNet LLC"},
  {max_prefix: "00-04-32",vendor: "Voyetra Turtle Beach, Inc."},
  {max_prefix: "00-04-37",vendor: "Powin Information Technology, Inc."},
  {max_prefix: "00-04-14",vendor: "Umezawa Musen Denki Co., Ltd."},
  {max_prefix: "00-04-0C",vendor: "Kanno Works, Ltd."},
  {max_prefix: "00-04-08",vendor: "Sanko Electronics Co., Ltd."},
  {max_prefix: "00-04-09",vendor: "Cratos Networks"},
  {max_prefix: "00-04-07",vendor: "Topcon Positioning Systems, Inc."},
  {max_prefix: "00-04-55",vendor: "ANTARA.net"},
  {max_prefix: "00-04-88",vendor: "Eurotherm Controls"},
  {max_prefix: "00-04-85",vendor: "PicoLight"},
  {max_prefix: "00-04-79",vendor: "Radius Co., Ltd."},
  {max_prefix: "00-04-57",vendor: "Universal Access Technology, Inc."},
  {max_prefix: "00-04-4D",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-04-54",vendor: "Quadriga UK"},
  {max_prefix: "00-04-48",vendor: "Polaroid Corporation"},
  {max_prefix: "00-04-98",vendor: "Mahi Networks"},
  {max_prefix: "00-04-97",vendor: "MacroSystem Digital Video AG"},
  {max_prefix: "00-04-6D",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-04-66",vendor: "ARMITEL Co."},
  {max_prefix: "00-04-28",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-03-D3",vendor: "Internet Energy Systems, Inc."},
  {max_prefix: "00-03-CD",vendor: "Clovertech, Inc."},
  {max_prefix: "00-03-CC",vendor: "Momentum Computer, Inc."},
  {max_prefix: "00-03-7D",vendor: "Stellcom"},
  {max_prefix: "00-03-83",vendor: "Metera Networks, Inc."},
  {max_prefix: "00-03-79",vendor: "Proscend Communications, Inc."},
  {max_prefix: "00-03-8B",vendor: "PLUS-ONE I&T, Inc."},
  {max_prefix: "00-03-8C",vendor: "Total Impact"},
  {max_prefix: "00-03-86",vendor: "Ho Net, Inc."},
  {max_prefix: "00-03-E6",vendor: "Entone, Inc."},
  {max_prefix: "00-03-D7",vendor: "NextNet Wireless, Inc."},
  {max_prefix: "00-03-EE",vendor: "MKNet Corporation"},
  {max_prefix: "00-03-EA",vendor: "Mega System Technologies, Inc."},
  {max_prefix: "00-03-B5",vendor: "Entra Technology Co."},
  {max_prefix: "00-03-9F",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-03-90",vendor: "Digital Video Communications, Inc."},
  {max_prefix: "00-03-E2",vendor: "Comspace Corporation"},
  {max_prefix: "00-03-FF",vendor: "Microsoft Corporation"},
  {max_prefix: "00-03-A9",vendor: "AXCENT Media AG"},
  {max_prefix: "00-03-66",vendor: "ASM Pacific Technology"},
  {max_prefix: "00-03-62",vendor: "Vodtel Communications, Inc."},
  {max_prefix: "00-03-64",vendor: "Scenix Semiconductor, Inc."},
  {max_prefix: "00-03-5E",vendor: "Metropolitan Area Networks, Inc."},
  {max_prefix: "00-03-5C",vendor: "Saint Song Corp."},
  {max_prefix: "00-03-5D",vendor: "Bosung Hi-Net Co., Ltd."},
  {max_prefix: "00-80-37",vendor: "Ericsson Group"},
  {max_prefix: "00-03-3D",vendor: "ILSHin Lab"},
  {max_prefix: "00-03-34",vendor: "Omega Engineering Inc."},
  {max_prefix: "00-03-3A",vendor: "Silicon Wave, Inc."},
  {max_prefix: "00-02-E8",vendor: "E.D.&A."},
  {max_prefix: "00-02-D9",vendor: "Reliable Controls"},
  {max_prefix: "00-03-0E",vendor: "Core Communications Co., Ltd."},
  {max_prefix: "00-03-12",vendor: "TRsystems GmbH"},
  {max_prefix: "00-02-F2",vendor: "eDevice, Inc."},
  {max_prefix: "00-02-EF",vendor: "CCC Network Systems Group Ltd."},
  {max_prefix: "00-03-32",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-03-26",vendor: "Iwasaki Information Systems Co., Ltd."},
  {max_prefix: "00-03-6F",vendor: "Telsey SPA"},
  {max_prefix: "00-03-72",vendor: "ULAN"},
  {max_prefix: "00-03-4D",vendor: "Chiaro Networks, Ltd."},
  {max_prefix: "00-03-22",vendor: "IDIS Co., Ltd."},
  {max_prefix: "00-03-1D",vendor: "Taiwan Commate Computer, Inc."},
  {max_prefix: "00-02-5D",vendor: "Calix Networks"},
  {max_prefix: "00-02-57",vendor: "Microcom Corp."},
  {max_prefix: "00-02-53",vendor: "Televideo, Inc."},
  {max_prefix: "00-02-4A",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-02-49",vendor: "Aviv Infocom Co, Ltd."},
  {max_prefix: "00-02-45",vendor: "Lampus Co, Ltd."},
  {max_prefix: "00-02-46",vendor: "All-Win Tech Co., Ltd."},
  {max_prefix: "00-02-90",vendor: "Woorigisool, Inc."},
  {max_prefix: "00-02-92",vendor: "Logic Innovations, Inc."},
  {max_prefix: "00-02-86",vendor: "Occam Networks"},
  {max_prefix: "00-10-95",vendor: "Thomson Inc."},
  {max_prefix: "00-02-7B",vendor: "Amplify Net, Inc."},
  {max_prefix: "00-02-73",vendor: "Coriolis Networks"},
  {max_prefix: "00-02-B4",vendor: "DAPHNE"},
  {max_prefix: "00-02-B0",vendor: "Hokubu Communication & Industrial Co., Ltd."},
  {max_prefix: "00-02-AA",vendor: "PLcom Co., Ltd."},
  {max_prefix: "00-02-9D",vendor: "Merix Corp."},
  {max_prefix: "00-02-D2",vendor: "Workstation AG"},
  {max_prefix: "00-02-CD",vendor: "TeleDream, Inc."},
  {max_prefix: "00-02-D0",vendor: "Comdial Corporation"},
  {max_prefix: "00-02-CC",vendor: "M.C.C.I"},
  {max_prefix: "00-02-6F",vendor: "Senao International Co., Ltd."},
  {max_prefix: "00-02-C5",vendor: "Evertz Microsystems Ltd."},
  {max_prefix: "00-02-B5",vendor: "Avnet, Inc."},
  {max_prefix: "00-02-7E",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-02-7F",vendor: "ask-technologies.com"},
  {max_prefix: "00-02-3A",vendor: "ZSK Stickmaschinen GmbH"},
  {max_prefix: "00-02-32",vendor: "Avision, Inc."},
  {max_prefix: "00-02-35",vendor: "Paragon Networks International"},
  {max_prefix: "00-02-37",vendor: "Cosmo Research Corp."},
  {max_prefix: "00-02-28",vendor: "Necsom, Ltd."},
  {max_prefix: "00-01-AC",vendor: "Sitara Networks, Inc."},
  {max_prefix: "00-01-AD",vendor: "Coach Master International  d.b.a. CMI Worldwide, Inc."},
  {max_prefix: "00-01-9B",vendor: "Kyoto Microcomputer Co., Ltd."},
  {max_prefix: "00-02-30",vendor: "Intersoft Electronics"},
  {max_prefix: "00-02-1C",vendor: "Network Elements, Inc."},
  {max_prefix: "00-02-0C",vendor: "Metro-Optix"},
  {max_prefix: "00-02-16",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-02-14",vendor: "DTVRO"},
  {max_prefix: "00-02-0F",vendor: "AATR"},
  {max_prefix: "00-01-C3",vendor: "Acromag, Inc."},
  {max_prefix: "00-01-C2",vendor: "ARK Research Corp."},
  {max_prefix: "00-01-ED",vendor: "SETA Corp."},
  {max_prefix: "00-01-EA",vendor: "Cirilium Corp."},
  {max_prefix: "00-01-E0",vendor: "Fast Systems, Inc."},
  {max_prefix: "00-01-D6",vendor: "manroland AG"},
  {max_prefix: "00-01-F6",vendor: "Association of Musical Electronics Industry"},
  {max_prefix: "00-01-CB",vendor: "EVR"},
  {max_prefix: "00-01-3A",vendor: "SHELCAD COMMUNICATIONS, LTD."},
  {max_prefix: "00-01-40",vendor: "Sendtek Corporation"},
  {max_prefix: "00-01-23",vendor: "Schneider Electric Japan Holdings Ltd."},
  {max_prefix: "00-01-25",vendor: "YAESU MUSEN CO., LTD."},
  {max_prefix: "00-01-26",vendor: "PAC Labs"},
  {max_prefix: "00-01-1B",vendor: "Unizone Technologies, Inc."},
  {max_prefix: "00-01-71",vendor: "Allied Data Technologies"},
  {max_prefix: "00-01-76",vendor: "Orient Silver Enterprises"},
  {max_prefix: "00-01-58",vendor: "Electro Industries/Gauge Tech"},
  {max_prefix: "00-30-AC",vendor: "Systeme Lauer GmbH & Co., Ltd."},
  {max_prefix: "00-01-5A",vendor: "Digital Video Broadcasting"},
  {max_prefix: "00-01-1D",vendor: "Centillium Communications"},
  {max_prefix: "00-01-1F",vendor: "RC Networks, Inc."},
  {max_prefix: "00-01-2A",vendor: "Telematica Sistems Inteligente"},
  {max_prefix: "00-01-2D",vendor: "Komodo Technology"},
  {max_prefix: "00-01-48",vendor: "X-traWeb Inc."},
  {max_prefix: "00-01-88",vendor: "LXCO Technologies ag"},
  {max_prefix: "00-01-7F",vendor: "Experience Music Project"},
  {max_prefix: "00-01-87",vendor: "I2SE GmbH"},
  {max_prefix: "00-01-9A",vendor: "LEUNIG GmbH"},
  {max_prefix: "00-01-97",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-01-59",vendor: "S1 Corporation"},
  {max_prefix: "00-01-66",vendor: "TC GROUP A/S"},
  {max_prefix: "00-01-80",vendor: "AOpen, Inc."},
  {max_prefix: "00-B0-9A",vendor: "Morrow Technologies Corp."},
  {max_prefix: "00-30-A9",vendor: "Netiverse, Inc."},
  {max_prefix: "00-30-FE",vendor: "DSA GmbH"},
  {max_prefix: "00-30-C4",vendor: "Canon Imaging Systems Inc."},
  {max_prefix: "00-30-4D",vendor: "ESI"},
  {max_prefix: "00-30-2E",vendor: "Hoft & Wessel AG"},
  {max_prefix: "00-30-ED",vendor: "Expert Magnetics Corp."},
  {max_prefix: "00-30-0F",vendor: "IMT - Information Management T"},
  {max_prefix: "00-30-82",vendor: "TAIHAN ELECTRIC WIRE CO., LTD."},
  {max_prefix: "00-30-FB",vendor: "AZS Technology AG"},
  {max_prefix: "00-30-03",vendor: "Phasys Ltd."},
  {max_prefix: "00-30-AE",vendor: "Times N System, Inc."},
  {max_prefix: "00-30-E2",vendor: "GARNET SYSTEMS CO., LTD."},
  {max_prefix: "00-30-D5",vendor: "DResearch GmbH"},
  {max_prefix: "00-30-18",vendor: "Jetway Information Co., Ltd."},
  {max_prefix: "00-30-89",vendor: "Spectrapoint Wireless, LLC"},
  {max_prefix: "00-B0-80",vendor: "Mannesmann Ipulsys B.V."},
  {max_prefix: "00-B0-1E",vendor: "Rantic Labs, Inc."},
  {max_prefix: "00-B0-F0",vendor: "CALY NETWORKS"},
  {max_prefix: "00-30-90",vendor: "CYRA TECHNOLOGIES, INC."},
  {max_prefix: "00-30-A7",vendor: "SCHWEITZER ENGINEERING"},
  {max_prefix: "00-30-23",vendor: "COGENT COMPUTER SYSTEMS, INC."},
  {max_prefix: "00-30-7C",vendor: "ADID SA"},
  {max_prefix: "00-30-9A",vendor: "ASTRO TERRA CORP."},
  {max_prefix: "00-30-9F",vendor: "AMBER NETWORKS"},
  {max_prefix: "00-30-A8",vendor: "OL'E COMMUNICATIONS, INC."},
  {max_prefix: "00-30-D1",vendor: "INOVA CORPORATION"},
  {max_prefix: "08-14-43",vendor: "UNIBRAIN S.A."},
  {max_prefix: "00-B0-09",vendor: "Grass Valley, A Belden Brand"},
  {max_prefix: "00-B0-AC",vendor: "SIAE-Microelettronica S.p.A."},
  {max_prefix: "00-30-55",vendor: "Renesas Technology America, Inc."},
  {max_prefix: "00-30-2F",vendor: "GE Aviation System"},
  {max_prefix: "00-30-0E",vendor: "Klotz Digital AG"},
  {max_prefix: "00-30-BB",vendor: "CacheFlow, Inc."},
  {max_prefix: "00-30-2D",vendor: "QUANTUM BRIDGE COMMUNICATIONS"},
  {max_prefix: "00-30-CB",vendor: "OMNI FLOW COMPUTERS, INC."},
  {max_prefix: "00-30-6B",vendor: "CMOS SYSTEMS, INC."},
  {max_prefix: "00-30-AD",vendor: "SHANGHAI COMMUNICATION"},
  {max_prefix: "00-D0-CF",vendor: "MORETON BAY"},
  {max_prefix: "00-D0-7F",vendor: "STRATEGY & TECHNOLOGY, LIMITED"},
  {max_prefix: "00-30-36",vendor: "RMP ELEKTRONIKSYSTEME GMBH"},
  {max_prefix: "00-30-35",vendor: "Corning Incorporated"},
  {max_prefix: "00-30-7F",vendor: "IRLAN LTD."},
  {max_prefix: "00-30-E6",vendor: "Draeger Medical Systems, Inc."},
  {max_prefix: "00-30-62",vendor: "IP Video Networks Inc"},
  {max_prefix: "00-D0-85",vendor: "OTIS ELEVATOR COMPANY"},
  {max_prefix: "00-D0-E9",vendor: "Advantage Century Telecommunication Corp."},
  {max_prefix: "00-D0-15",vendor: "UNIVEX MICROTECHNOLOGY CORP."},
  {max_prefix: "00-D0-A5",vendor: "AMERICAN ARIUM"},
  {max_prefix: "00-D0-48",vendor: "ECTON, INC."},
  {max_prefix: "00-30-BD",vendor: "BELKIN COMPONENTS"},
  {max_prefix: "00-30-CF",vendor: "TWO TECHNOLOGIES, INC."},
  {max_prefix: "00-30-B2",vendor: "L-3 Sonoma EO"},
  {max_prefix: "00-30-5D",vendor: "DIGITRA SYSTEMS, INC."},
  {max_prefix: "00-30-87",vendor: "VEGA GRIESHABER KG"},
  {max_prefix: "00-30-AA",vendor: "AXUS MICROSYSTEMS, INC."},
  {max_prefix: "00-30-07",vendor: "OPTI, INC."},
  {max_prefix: "00-D0-22",vendor: "INCREDIBLE TECHNOLOGIES, INC."},
  {max_prefix: "00-D0-71",vendor: "ECHELON CORP."},
  {max_prefix: "00-D0-4F",vendor: "BITRONICS, INC."},
  {max_prefix: "00-D0-FB",vendor: "TEK MICROSYSTEMS, INCORPORATED"},
  {max_prefix: "00-D0-66",vendor: "WINTRISS ENGINEERING CORP."},
  {max_prefix: "00-D0-82",vendor: "IOWAVE INC."},
  {max_prefix: "00-D0-9C",vendor: "KAPADIA COMMUNICATIONS"},
  {max_prefix: "00-D0-F3",vendor: "SOLARI DI UDINE SPA"},
  {max_prefix: "00-D0-39",vendor: "UTILICOM, INC."},
  {max_prefix: "00-D0-67",vendor: "CAMPIO COMMUNICATIONS"},
  {max_prefix: "00-D0-58",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-D0-3D",vendor: "GALILEO TECHNOLOGY, LTD."},
  {max_prefix: "00-D0-32",vendor: "YANO ELECTRIC CO., LTD."},
  {max_prefix: "00-D0-F1",vendor: "SEGA ENTERPRISES, LTD."},
  {max_prefix: "00-D0-90",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-D0-F5",vendor: "ORANGE MICRO, INC."},
  {max_prefix: "00-D0-78",vendor: "Eltex of Sweden AB"},
  {max_prefix: "00-D0-C2",vendor: "BALTHAZAR TECHNOLOGY AB"},
  {max_prefix: "00-D0-81",vendor: "RTD Embedded Technologies, Inc."},
  {max_prefix: "00-D0-02",vendor: "DITECH CORPORATION"},
  {max_prefix: "00-D0-9B",vendor: "SPECTEL LTD."},
  {max_prefix: "00-D0-41",vendor: "AMIGO TECHNOLOGY CO., LTD."},
  {max_prefix: "00-D0-9D",vendor: "VERIS INDUSTRIES"},
  {max_prefix: "00-D0-94",vendor: "Seeion Control LLC"},
  {max_prefix: "00-D0-69",vendor: "TECHNOLOGIC SYSTEMS"},
  {max_prefix: "00-D0-11",vendor: "PRISM VIDEO, INC."},
  {max_prefix: "00-D0-DF",vendor: "KUZUMI ELECTRONICS, INC."},
  {max_prefix: "00-D0-62",vendor: "DIGIGRAM"},
  {max_prefix: "00-D0-8D",vendor: "PHOENIX GROUP, INC."},
  {max_prefix: "00-D0-4C",vendor: "Eseye Design Ltd"},
  {max_prefix: "00-D0-D9",vendor: "DEDICATED MICROCOMPUTERS"},
  {max_prefix: "00-D0-E1",vendor: "AVIONITEK ISRAEL INC."},
  {max_prefix: "00-D0-08",vendor: "MACTELL CORPORATION"},
  {max_prefix: "00-D0-0B",vendor: "RHK TECHNOLOGY, INC."},
  {max_prefix: "00-D0-A0",vendor: "MIPS DENMARK"},
  {max_prefix: "00-D0-0A",vendor: "LANACCESS TELECOM S.A."},
  {max_prefix: "00-D0-1C",vendor: "SBS TECHNOLOGIES,"},
  {max_prefix: "00-D0-D5",vendor: "GRUNDIG AG"},
  {max_prefix: "00-50-46",vendor: "MENICX INTERNATIONAL CO., LTD."},
  {max_prefix: "00-50-B0",vendor: "TECHNOLOGY ATLANTA CORPORATION"},
  {max_prefix: "00-50-41",vendor: "Coretronic Corporation"},
  {max_prefix: "00-50-DD",vendor: "SERRA SOLDADURA, S.A."},
  {max_prefix: "00-50-67",vendor: "AEROCOMM, INC."},
  {max_prefix: "00-50-B6",vendor: "GOOD WAY IND. CO., LTD."},
  {max_prefix: "00-50-4B",vendor: "BARCONET N.V."},
  {max_prefix: "00-D0-3A",vendor: "ZONEWORX, INC."},
  {max_prefix: "00-D0-01",vendor: "VST TECHNOLOGIES, INC."},
  {max_prefix: "00-50-20",vendor: "MEDIASTAR CO., LTD."},
  {max_prefix: "00-D0-75",vendor: "ALARIS MEDICAL SYSTEMS, INC."},
  {max_prefix: "00-50-E7",vendor: "PARADISE INNOVATIONS (ASIA)"},
  {max_prefix: "00-50-FB",vendor: "VSK ELECTRONICS"},
  {max_prefix: "00-50-A5",vendor: "CAPITOL BUSINESS SYSTEMS, LTD."},
  {max_prefix: "00-50-00",vendor: "NEXO COMMUNICATIONS, INC."},
  {max_prefix: "00-50-C8",vendor: "Addonics Technologies, Inc."},
  {max_prefix: "00-50-89",vendor: "SAFETY MANAGEMENT SYSTEMS"},
  {max_prefix: "00-50-66",vendor: "AtecoM GmbH advanced telecomunication modules"},
  {max_prefix: "00-50-59",vendor: "iBAHN"},
  {max_prefix: "00-50-D9",vendor: "ENGETRON-ENGENHARIA ELETRONICA IND. e COM. LTDA"},
  {max_prefix: "00-50-F4",vendor: "SIGMATEK GMBH & CO. KG"},
  {max_prefix: "00-50-21",vendor: "EIS INTERNATIONAL, INC."},
  {max_prefix: "00-50-5E",vendor: "DIGITEK MICROLOGIC S.A."},
  {max_prefix: "00-50-90",vendor: "DCTRI"},
  {max_prefix: "00-50-3B",vendor: "MEDIAFIRE CORPORATION"},
  {max_prefix: "00-50-EA",vendor: "XEL COMMUNICATIONS, INC."},
  {max_prefix: "00-50-E8",vendor: "Nomadix, Inc"},
  {max_prefix: "00-50-AE",vendor: "FDK Co., Ltd"},
  {max_prefix: "00-D0-6D",vendor: "ACRISON, INC."},
  {max_prefix: "00-D0-2B",vendor: "JETCELL, INC."},
  {max_prefix: "00-50-3E",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-50-C6",vendor: "LOOP TELECOMMUNICATION INTERNATIONAL, INC."},
  {max_prefix: "00-50-9F",vendor: "HORIZON COMPUTER"},
  {max_prefix: "00-50-63",vendor: "OY COMSEL SYSTEM AB"},
  {max_prefix: "00-50-8D",vendor: "ABIT COMPUTER CORPORATION"},
  {max_prefix: "00-50-A0",vendor: "DELTA COMPUTER SYSTEMS, INC."},
  {max_prefix: "00-50-86",vendor: "TELKOM SA, LTD."},
  {max_prefix: "00-50-1A",vendor: "IQinVision"},
  {max_prefix: "00-50-8F",vendor: "ASITA TECHNOLOGIES INT'L LTD."},
  {max_prefix: "00-50-15",vendor: "BRIGHT STAR ENGINEERING"},
  {max_prefix: "00-50-57",vendor: "BROADBAND ACCESS SYSTEMS"},
  {max_prefix: "00-50-88",vendor: "AMANO CORPORATION"},
  {max_prefix: "00-50-31",vendor: "AEROFLEX LABORATORIES, INC."},
  {max_prefix: "00-90-7B",vendor: "E-TECH, INC."},
  {max_prefix: "00-90-81",vendor: "ALOHA NETWORKS, INC."},
  {max_prefix: "00-90-1C",vendor: "mps Software Gmbh"},
  {max_prefix: "00-90-DB",vendor: "NEXT LEVEL COMMUNICATIONS"},
  {max_prefix: "00-90-56",vendor: "TELESTREAM, INC."},
  {max_prefix: "00-90-34",vendor: "IMAGIC, INC."},
  {max_prefix: "00-90-73",vendor: "GAIO TECHNOLOGY"},
  {max_prefix: "00-90-5E",vendor: "RAULAND-BORG CORPORATION"},
  {max_prefix: "00-90-AF",vendor: "J. MORITA MFG. CORP."},
  {max_prefix: "00-90-BB",vendor: "TAINET COMMUNICATION SYSTEM Corp."},
  {max_prefix: "00-50-03",vendor: "Xrite Inc"},
  {max_prefix: "00-50-D3",vendor: "DIGITAL AUDIO PROCESSING PTY. LTD."},
  {max_prefix: "00-50-AD",vendor: "CommUnique Wireless Corp."},
  {max_prefix: "00-50-AF",vendor: "INTERGON, INC."},
  {max_prefix: "00-90-68",vendor: "DVT CORP."},
  {max_prefix: "00-90-E5",vendor: "TEKNEMA, INC."},
  {max_prefix: "00-90-F4",vendor: "LIGHTNING INSTRUMENTATION"},
  {max_prefix: "00-90-74",vendor: "ARGON NETWORKS, INC."},
  {max_prefix: "00-90-3B",vendor: "TriEMS Research Lab, Inc."},
  {max_prefix: "00-90-9F",vendor: "DIGI-DATA CORPORATION"},
  {max_prefix: "00-90-19",vendor: "HERMES ELECTRONICS CO., LTD."},
  {max_prefix: "00-90-05",vendor: "PROTECH SYSTEMS CO., LTD."},
  {max_prefix: "00-90-F8",vendor: "MEDIATRIX TELECOM"},
  {max_prefix: "00-90-10",vendor: "SIMULATION LABORATORIES, INC."},
  {max_prefix: "00-90-C6",vendor: "OPTIM SYSTEMS, INC."},
  {max_prefix: "00-90-2E",vendor: "NAMCO LIMITED"},
  {max_prefix: "00-90-8F",vendor: "AUDIO CODES LTD."},
  {max_prefix: "00-90-59",vendor: "TELECOM DEVICE K.K."},
  {max_prefix: "00-90-CA",vendor: "ACCORD VIDEO TELECOMMUNICATIONS, LTD."},
  {max_prefix: "00-90-E9",vendor: "JANZ COMPUTER AG"},
  {max_prefix: "00-90-37",vendor: "ACUCOMM, INC."},
  {max_prefix: "00-90-78",vendor: "MER TELEMANAGEMENT SOLUTIONS, LTD."},
  {max_prefix: "00-90-AA",vendor: "INDIGO ACTIVE VISION SYSTEMS LIMITED"},
  {max_prefix: "00-90-5B",vendor: "RAYMOND AND LAE ENGINEERING"},
  {max_prefix: "00-90-BC",vendor: "TELEMANN CO., LTD."},
  {max_prefix: "00-90-0A",vendor: "PROTON ELECTRONIC INDUSTRIAL CO., LTD."},
  {max_prefix: "00-90-90",vendor: "I-BUS"},
  {max_prefix: "00-90-1A",vendor: "UNISPHERE SOLUTIONS"},
  {max_prefix: "00-90-B5",vendor: "NIKON CORPORATION"},
  {max_prefix: "00-90-EB",vendor: "SENTRY TELECOM SYSTEMS"},
  {max_prefix: "00-90-FE",vendor: "ELECOM CO., LTD.  (LANEED DIV.)"},
  {max_prefix: "00-90-D5",vendor: "EUPHONIX, INC."},
  {max_prefix: "00-90-4A",vendor: "CONCUR SYSTEM TECHNOLOGIES"},
  {max_prefix: "00-10-47",vendor: "ECHO ELETRIC CO. LTD."},
  {max_prefix: "00-90-3F",vendor: "WorldCast Systems"},
  {max_prefix: "00-90-B8",vendor: "ROHDE & SCHWARZ GMBH & CO. KG"},
  {max_prefix: "00-90-D8",vendor: "WHITECROSS SYSTEMS"},
  {max_prefix: "00-10-FD",vendor: "COCOM A/S"},
  {max_prefix: "00-10-C9",vendor: "MITSUBISHI ELECTRONICS LOGISTIC SUPPORT CO."},
  {max_prefix: "00-04-00",vendor: "LEXMARK INTERNATIONAL, INC."},
  {max_prefix: "00-10-C5",vendor: "PROTOCOL TECHNOLOGIES, INC."},
  {max_prefix: "00-10-1A",vendor: "PictureTel Corp."},
  {max_prefix: "00-10-BA",vendor: "MARTINHO-DAVIS SYSTEMS, INC."},
  {max_prefix: "00-10-C2",vendor: "WILLNET, INC."},
  {max_prefix: "00-10-40",vendor: "INTERMEC CORPORATION"},
  {max_prefix: "00-10-2E",vendor: "NETWORK SYSTEMS & TECHNOLOGIES PVT. LTD."},
  {max_prefix: "00-10-46",vendor: "ALCORN MCBRIDE INC."},
  {max_prefix: "00-10-B7",vendor: "COYOTE TECHNOLOGIES, LLC"},
  {max_prefix: "00-10-28",vendor: "COMPUTER TECHNICA, INC."},
  {max_prefix: "00-10-2C",vendor: "Lasat Networks A/S"},
  {max_prefix: "00-10-A5",vendor: "OXFORD INSTRUMENTS"},
  {max_prefix: "00-10-D7",vendor: "ARGOSY RESEARCH INC."},
  {max_prefix: "00-10-92",vendor: "NETCORE INC."},
  {max_prefix: "00-10-1C",vendor: "OHM TECHNOLOGIES INTL, LLC"},
  {max_prefix: "00-10-67",vendor: "Ericsson"},
  {max_prefix: "00-10-21",vendor: "ENCANTO NETWORKS, INC."},
  {max_prefix: "00-10-64",vendor: "DNPG, LLC"},
  {max_prefix: "00-10-9E",vendor: "AWARE, INC."},
  {max_prefix: "00-10-05",vendor: "UEC COMMERCIAL"},
  {max_prefix: "00-10-B8",vendor: "ISHIGAKI COMPUTER SYSTEM CO."},
  {max_prefix: "00-10-8B",vendor: "LASERANIMATION SOLLINGER GMBH"},
  {max_prefix: "00-10-C7",vendor: "DATA TRANSMISSION NETWORK"},
  {max_prefix: "00-10-74",vendor: "ATEN INTERNATIONAL CO., LTD."},
  {max_prefix: "00-10-70",vendor: "CARADON TREND LTD."},
  {max_prefix: "00-10-43",vendor: "A2 CORPORATION"},
  {max_prefix: "00-10-4E",vendor: "CEOLOGIC"},
  {max_prefix: "00-10-B0",vendor: "MERIDIAN TECHNOLOGY CORP."},
  {max_prefix: "00-04-AC",vendor: "IBM Corp"},
  {max_prefix: "00-10-B4",vendor: "ATMOSPHERE NETWORKS"},
  {max_prefix: "00-E0-8C",vendor: "NEOPARADIGM LABS, INC."},
  {max_prefix: "00-E0-28",vendor: "APTIX CORPORATION"},
  {max_prefix: "00-E0-A1",vendor: "HIMA PAUL HILDEBRANDT GmbH Co. KG"},
  {max_prefix: "00-E0-88",vendor: "LTX-Credence CORPORATION"},
  {max_prefix: "00-E0-3D",vendor: "FOCON ELECTRONIC SYSTEMS A/S"},
  {max_prefix: "00-E0-46",vendor: "BENTLY NEVADA CORP."},
  {max_prefix: "00-E0-58",vendor: "PHASE ONE DENMARK A/S"},
  {max_prefix: "00-E0-76",vendor: "DEVELOPMENT CONCEPTS, INC."},
  {max_prefix: "00-E0-7D",vendor: "NETRONIX, INC."},
  {max_prefix: "00-E0-5D",vendor: "UNITEC CO., LTD."},
  {max_prefix: "00-E0-5E",vendor: "JAPAN AVIATION ELECTRONICS INDUSTRY, LTD."},
  {max_prefix: "00-E0-9D",vendor: "SARNOFF CORPORATION"},
  {max_prefix: "00-E0-F2",vendor: "ARLOTTO COMNET, INC."},
  {max_prefix: "00-E0-E1",vendor: "G2 NETWORKS, INC."},
  {max_prefix: "00-E0-8D",vendor: "PRESSURE SYSTEMS, INC."},
  {max_prefix: "00-E0-FF",vendor: "SECURITY DYNAMICS TECHNOLOGIES, Inc."},
  {max_prefix: "00-E0-AB",vendor: "DIMAT S.A."},
  {max_prefix: "00-E0-30",vendor: "MELITA INTERNATIONAL CORP."},
  {max_prefix: "00-E0-AA",vendor: "ELECTROSONIC LTD."},
  {max_prefix: "00-E0-10",vendor: "HESS SB-AUTOMATENBAU GmbH"},
  {max_prefix: "00-60-49",vendor: "VINA TECHNOLOGIES"},
  {max_prefix: "00-60-8D",vendor: "UNIPULSE CORP."},
  {max_prefix: "00-60-99",vendor: "SBE, Inc."},
  {max_prefix: "00-60-B3",vendor: "Z-COM, INC."},
  {max_prefix: "00-60-02",vendor: "SCREEN SUBTITLING SYSTEMS, LTD"},
  {max_prefix: "00-E0-33",vendor: "E.E.P.D. GmbH"},
  {max_prefix: "00-E0-A2",vendor: "MICROSLATE INC."},
  {max_prefix: "00-E0-79",vendor: "A.T.N.R."},
  {max_prefix: "00-E0-75",vendor: "Verilink Corporation"},
  {max_prefix: "00-E0-2E",vendor: "SPC ELECTRONICS CORPORATION"},
  {max_prefix: "00-E0-D2",vendor: "VERSANET COMMUNICATIONS, INC."},
  {max_prefix: "00-E0-47",vendor: "InFocus Corporation"},
  {max_prefix: "00-E0-C3",vendor: "SAKAI SYSTEM DEVELOPMENT CORP."},
  {max_prefix: "00-E0-92",vendor: "ADMTEK INCORPORATED"},
  {max_prefix: "00-E0-3E",vendor: "ALFATECH, INC."},
  {max_prefix: "00-E0-9A",vendor: "Positron Inc."},
  {max_prefix: "00-E0-19",vendor: "ING. GIORDANO ELETTRONICA"},
  {max_prefix: "00-E0-7B",vendor: "BAY NETWORKS"},
  {max_prefix: "00-E0-1D",vendor: "WebTV NETWORKS, INC."},
  {max_prefix: "00-60-89",vendor: "XATA"},
  {max_prefix: "00-60-21",vendor: "DSC CORPORATION"},
  {max_prefix: "00-60-B8",vendor: "CORELIS Inc."},
  {max_prefix: "00-60-39",vendor: "SanCom Technology, Inc."},
  {max_prefix: "00-E0-F8",vendor: "DICNA CONTROL AB"},
  {max_prefix: "00-60-6D",vendor: "DIGITAL EQUIPMENT CORP."},
  {max_prefix: "00-60-CE",vendor: "ACCLAIM COMMUNICATIONS"},
  {max_prefix: "00-60-B9",vendor: "NEC Platforms, Ltd"},
  {max_prefix: "00-60-36",vendor: "AIT Austrian Institute of Technology GmbH"},
  {max_prefix: "00-60-8E",vendor: "HE ELECTRONICS, TECHNOLOGIE & SYSTEMTECHNIK GmbH"},
  {max_prefix: "00-60-6A",vendor: "MITSUBISHI WIRELESS COMMUNICATIONS. INC."},
  {max_prefix: "00-60-1A",vendor: "KEITHLEY INSTRUMENTS"},
  {max_prefix: "00-60-AD",vendor: "MegaChips Corporation"},
  {max_prefix: "00-60-55",vendor: "CORNELL UNIVERSITY"},
  {max_prefix: "00-60-9C",vendor: "Perkin-Elmer Incorporated"},
  {max_prefix: "00-60-CF",vendor: "ALTEON NETWORKS, INC."},
  {max_prefix: "00-60-75",vendor: "PENTEK, INC."},
  {max_prefix: "00-60-B7",vendor: "CHANNELMATIC, INC."},
  {max_prefix: "00-60-06",vendor: "SOTEC CO., LTD"},
  {max_prefix: "00-60-BA",vendor: "SAHARA NETWORKS, INC."},
  {max_prefix: "00-60-98",vendor: "HT COMMUNICATIONS"},
  {max_prefix: "00-60-CA",vendor: "HARMONIC SYSTEMS INCORPORATED"},
  {max_prefix: "00-60-24",vendor: "GRADIENT TECHNOLOGIES, INC."},
  {max_prefix: "00-60-DE",vendor: "Kayser-Threde GmbH"},
  {max_prefix: "00-60-D0",vendor: "SNMP RESEARCH INCORPORATED"},
  {max_prefix: "00-60-AF",vendor: "PACIFIC MICRO DATA, INC."},
  {max_prefix: "00-60-38",vendor: "Nortel Networks"},
  {max_prefix: "00-60-15",vendor: "NET2NET CORPORATION"},
  {max_prefix: "00-60-4F",vendor: "Tattile SRL"},
  {max_prefix: "00-60-E8",vendor: "HITACHI COMPUTER PRODUCTS (AMERICA), INC."},
  {max_prefix: "00-60-F6",vendor: "NEXTEST COMMUNICATIONS PRODUCTS, INC."},
  {max_prefix: "00-60-72",vendor: "VXL INSTRUMENTS, LIMITED"},
  {max_prefix: "00-60-51",vendor: "QUALITY SEMICONDUCTOR"},
  {max_prefix: "00-60-92",vendor: "MICRO/SYS, INC."},
  {max_prefix: "00-60-9E",vendor: "ASC X3 - INFORMATION TECHNOLOGY STANDARDS SECRETARIATS"},
  {max_prefix: "00-60-10",vendor: "NETWORK MACHINES, INC."},
  {max_prefix: "00-60-44",vendor: "LITTON/POLY-SCIENTIFIC"},
  {max_prefix: "00-60-A2",vendor: "NIHON UNISYS LIMITED CO."},
  {max_prefix: "00-60-9D",vendor: "PMI FOOD EQUIPMENT GROUP"},
  {max_prefix: "00-60-84",vendor: "DIGITAL VIDEO"},
  {max_prefix: "00-60-2D",vendor: "ALERTON TECHNOLOGIES, INC."},
  {max_prefix: "00-60-F8",vendor: "Loran International Technologies Inc."},
  {max_prefix: "00-60-78",vendor: "POWER MEASUREMENT LTD."},
  {max_prefix: "00-60-04",vendor: "COMPUTADORES MODULARES SA"},
  {max_prefix: "00-60-B4",vendor: "GLENAYRE R&D INC."},
  {max_prefix: "00-A0-1D",vendor: "Red Lion Controls, LP"},
  {max_prefix: "00-A0-B9",vendor: "EAGLE TECHNOLOGY, INC."},
  {max_prefix: "00-A0-19",vendor: "NEBULA CONSULTANTS, INC."},
  {max_prefix: "00-A0-ED",vendor: "Brooks Automation, Inc."},
  {max_prefix: "00-A0-A0",vendor: "COMPACT DATA, LTD."},
  {max_prefix: "00-A0-13",vendor: "TELTREND LTD."},
  {max_prefix: "00-A0-A6",vendor: "M.I. SYSTEMS, K.K."},
  {max_prefix: "00-A0-51",vendor: "ANGIA COMMUNICATIONS. INC."},
  {max_prefix: "00-A0-38",vendor: "EMAIL ELECTRONICS"},
  {max_prefix: "00-A0-77",vendor: "FUJITSU NEXION, INC."},
  {max_prefix: "00-A0-42",vendor: "SPUR PRODUCTS CORP."},
  {max_prefix: "00-A0-C1",vendor: "ORTIVUS MEDICAL AB"},
  {max_prefix: "00-A0-4F",vendor: "AMERITEC CORP."},
  {max_prefix: "00-A0-CF",vendor: "SOTAS, INC."},
  {max_prefix: "00-A0-72",vendor: "OVATION SYSTEMS LTD."},
  {max_prefix: "00-A0-82",vendor: "NKT ELEKTRONIK A/S"},
  {max_prefix: "00-A0-F0",vendor: "TORONTO MICROELECTRONICS INC."},
  {max_prefix: "00-A0-D7",vendor: "KASTEN CHASE APPLIED RESEARCH"},
  {max_prefix: "00-A0-F1",vendor: "MTI"},
  {max_prefix: "00-A0-FF",vendor: "TELLABS OPERATIONS, INC."},
  {max_prefix: "00-A0-E5",vendor: "NHC COMMUNICATIONS"},
  {max_prefix: "00-A0-36",vendor: "APPLIED NETWORK TECHNOLOGY"},
  {max_prefix: "00-A0-D2",vendor: "ALLIED TELESIS INTERNATIONAL CORPORATION"},
  {max_prefix: "00-A0-D3",vendor: "INSTEM COMPUTER SYSTEMS, LTD."},
  {max_prefix: "00-A0-B4",vendor: "TEXAS MICROSYSTEMS, INC."},
  {max_prefix: "00-A0-65",vendor: "Symantec Corporation"},
  {max_prefix: "00-A0-A3",vendor: "RELIABLE POWER METERS"},
  {max_prefix: "00-A0-55",vendor: "Data Device Corporation"},
  {max_prefix: "00-A0-5B",vendor: "MARQUIP, INC."},
  {max_prefix: "00-A0-8C",vendor: "MultiMedia LANs, Inc."},
  {max_prefix: "00-A0-29",vendor: "COULTER CORPORATION"},
  {max_prefix: "00-A0-AA",vendor: "SPACELABS MEDICAL"},
  {max_prefix: "00-A0-3B",vendor: "TOSHIN ELECTRIC CO., LTD."},
  {max_prefix: "00-A0-F3",vendor: "STAUBLI"},
  {max_prefix: "00-A0-60",vendor: "ACER PERIPHERALS, INC."},
  {max_prefix: "00-A0-83",vendor: "ASIMMPHONY TURKEY"},
  {max_prefix: "00-A0-04",vendor: "NETPOWER, INC."},
  {max_prefix: "00-A0-87",vendor: "Microsemi Corporation"},
  {max_prefix: "00-A0-43",vendor: "AMERICAN TECHNOLOGY LABS, INC."},
  {max_prefix: "00-A0-D0",vendor: "TEN X TECHNOLOGY, INC."},
  {max_prefix: "00-A0-BC",vendor: "VIASAT, INCORPORATED"},
  {max_prefix: "00-A0-74",vendor: "PERCEPTION TECHNOLOGY"},
  {max_prefix: "00-A0-7F",vendor: "GSM-SYNTEL, LTD."},
  {max_prefix: "00-A0-9B",vendor: "QPSX COMMUNICATIONS, LTD."},
  {max_prefix: "00-A0-00",vendor: "CENTILLION NETWORKS, INC."},
  {max_prefix: "00-A0-8A",vendor: "BROOKTROUT TECHNOLOGY, INC."},
  {max_prefix: "00-A0-7B",vendor: "DAWN COMPUTER INCORPORATION"},
  {max_prefix: "00-A0-5C",vendor: "INVENTORY CONVERSION, INC./"},
  {max_prefix: "00-20-0F",vendor: "EBRAINS Inc"},
  {max_prefix: "00-20-DF",vendor: "KYOSAN ELECTRIC MFG. CO., LTD."},
  {max_prefix: "00-20-92",vendor: "CHESS ENGINEERING B.V."},
  {max_prefix: "00-20-2B",vendor: "ADVANCED TELECOMMUNICATIONS MODULES, LTD."},
  {max_prefix: "00-20-6B",vendor: "KONICA MINOLTA HOLDINGS, INC."},
  {max_prefix: "00-20-04",vendor: "YAMATAKE-HONEYWELL CO., LTD."},
  {max_prefix: "00-20-E5",vendor: "APEX DATA, INC."},
  {max_prefix: "00-20-43",vendor: "NEURON COMPANY LIMITED"},
  {max_prefix: "00-20-71",vendor: "IBR GMBH"},
  {max_prefix: "00-20-87",vendor: "MEMOTEC, INC."},
  {max_prefix: "00-20-F9",vendor: "PARALINK NETWORKS, INC."},
  {max_prefix: "00-20-15",vendor: "ACTIS COMPUTER SA"},
  {max_prefix: "00-20-99",vendor: "BON ELECTRIC CO., LTD."},
  {max_prefix: "00-20-7C",vendor: "AUTEC GMBH"},
  {max_prefix: "00-20-57",vendor: "TITZE DATENTECHNIK GmbH"},
  {max_prefix: "00-20-BC",vendor: "Long Reach Networks Pty Ltd"},
  {max_prefix: "00-20-C7",vendor: "AKAI Professional M.I. Corp."},
  {max_prefix: "00-20-EE",vendor: "GTECH CORPORATION"},
  {max_prefix: "00-20-4C",vendor: "MITRON COMPUTER PTE LTD."},
  {max_prefix: "00-20-17",vendor: "ORBOTECH"},
  {max_prefix: "00-20-93",vendor: "LANDINGS TECHNOLOGY CORP."},
  {max_prefix: "00-20-63",vendor: "WIPRO INFOTECH LTD."},
  {max_prefix: "00-20-56",vendor: "NEOPRODUCTS"},
  {max_prefix: "00-20-42",vendor: "DATAMETRICS CORP."},
  {max_prefix: "00-20-78",vendor: "RUNTOP, INC."},
  {max_prefix: "00-C0-F3",vendor: "NETWORK COMMUNICATIONS CORP."},
  {max_prefix: "00-C0-C0",vendor: "SHORE MICROSYSTEMS, INC."},
  {max_prefix: "00-C0-0C",vendor: "RELIA TECHNOLGIES"},
  {max_prefix: "00-C0-73",vendor: "XEDIA CORPORATION"},
  {max_prefix: "00-C0-D4",vendor: "AXON NETWORKS, INC."},
  {max_prefix: "00-20-24",vendor: "PACIFIC COMMUNICATION SCIENCES"},
  {max_prefix: "00-20-D1",vendor: "MICROCOMPUTER SYSTEMS (M) SDN."},
  {max_prefix: "00-20-CE",vendor: "LOGICAL DESIGN GROUP, INC."},
  {max_prefix: "00-20-14",vendor: "GLOBAL VIEW CO., LTD."},
  {max_prefix: "00-20-C2",vendor: "TEXAS MEMORY SYSTEMS, INC."},
  {max_prefix: "00-20-ED",vendor: "GIGA-BYTE TECHNOLOGY CO., LTD."},
  {max_prefix: "00-20-85",vendor: "Eaton Corporation"},
  {max_prefix: "00-20-CD",vendor: "HYBRID NETWORKS, INC."},
  {max_prefix: "00-20-2E",vendor: "DAYSTAR DIGITAL"},
  {max_prefix: "00-20-B3",vendor: "Tattile SRL"},
  {max_prefix: "00-20-16",vendor: "SHOWA ELECTRIC WIRE & CABLE CO"},
  {max_prefix: "00-20-4D",vendor: "INOVIS GMBH"},
  {max_prefix: "00-20-5F",vendor: "GAMMADATA COMPUTER GMBH"},
  {max_prefix: "00-20-1F",vendor: "BEST POWER TECHNOLOGY, INC."},
  {max_prefix: "00-20-B6",vendor: "AGILE NETWORKS, INC."},
  {max_prefix: "00-20-29",vendor: "TELEPROCESSING PRODUCTS, INC."},
  {max_prefix: "00-20-69",vendor: "ISDN SYSTEMS CORPORATION"},
  {max_prefix: "00-20-8B",vendor: "LAPIS TECHNOLOGIES, INC."},
  {max_prefix: "00-20-06",vendor: "GARRETT COMMUNICATIONS, INC."},
  {max_prefix: "00-C0-CD",vendor: "COMELTA, S.A."},
  {max_prefix: "00-20-5D",vendor: "NANOMATIC OY"},
  {max_prefix: "00-C0-4C",vendor: "DEPARTMENT OF FOREIGN AFFAIRS"},
  {max_prefix: "00-C0-7C",vendor: "HIGHTECH INFORMATION"},
  {max_prefix: "00-C0-B8",vendor: "FRASER'S HILL LTD."},
  {max_prefix: "00-C0-62",vendor: "IMPULSE TECHNOLOGY"},
  {max_prefix: "00-C0-EC",vendor: "DAUPHIN TECHNOLOGY"},
  {max_prefix: "00-C0-16",vendor: "ELECTRONIC THEATRE CONTROLS"},
  {max_prefix: "00-C0-86",vendor: "THE LYNK CORPORATION"},
  {max_prefix: "00-C0-13",vendor: "NETRIX"},
  {max_prefix: "00-C0-58",vendor: "DATAEXPERT CORP."},
  {max_prefix: "00-C0-D0",vendor: "RATOC SYSTEM INC."},
  {max_prefix: "00-C0-BF",vendor: "TECHNOLOGY CONCEPTS, LTD."},
  {max_prefix: "00-C0-BA",vendor: "NETVANTAGE"},
  {max_prefix: "00-C0-5E",vendor: "VARI-LITE, INC."},
  {max_prefix: "00-C0-D5",vendor: "Werbeagentur J\u00fcrgen Siebert"},
  {max_prefix: "00-C0-63",vendor: "MORNING STAR TECHNOLOGIES, INC"},
  {max_prefix: "00-C0-21",vendor: "NETEXPRESS"},
  {max_prefix: "00-C0-C1",vendor: "QUAD/GRAPHICS, INC."},
  {max_prefix: "00-C0-89",vendor: "TELINDUS DISTRIBUTION"},
  {max_prefix: "00-C0-67",vendor: "UNITED BARCODE INDUSTRIES"},
  {max_prefix: "00-C0-A3",vendor: "DUAL ENTERPRISES CORPORATION"},
  {max_prefix: "00-C0-18",vendor: "LANART CORPORATION"},
  {max_prefix: "00-C0-77",vendor: "DAEWOO TELECOM LTD."},
  {max_prefix: "00-C0-C8",vendor: "MICRO BYTE PTY. LTD."},
  {max_prefix: "00-C0-69",vendor: "Axxcelera Broadband Wireless"},
  {max_prefix: "00-C0-90",vendor: "PRAIM S.R.L."},
  {max_prefix: "00-C0-DE",vendor: "ZCOMM, INC."},
  {max_prefix: "00-C0-DB",vendor: "IPC CORPORATION (PTE) LTD."},
  {max_prefix: "00-C0-9B",vendor: "Tellabs Enterprise, Inc."},
  {max_prefix: "00-C0-6B",vendor: "OSI PLUS CORPORATION"},
  {max_prefix: "00-9D-8E",vendor: "CARDIAC RECORDERS, INC."},
  {max_prefix: "00-BB-01",vendor: "OCTOTHORPE CORP."},
  {max_prefix: "00-C0-05",vendor: "LIVINGSTON ENTERPRISES, INC."},
  {max_prefix: "00-C0-33",vendor: "TELEBIT COMMUNICATIONS APS"},
  {max_prefix: "00-C0-BC",vendor: "TELECOM AUSTRALIA/CSSC"},
  {max_prefix: "00-C0-0A",vendor: "MICRO CRAFT"},
  {max_prefix: "00-C0-74",vendor: "TOYODA AUTOMATIC LOOM"},
  {max_prefix: "00-C0-6C",vendor: "SVEC COMPUTER CORP."},
  {max_prefix: "00-40-FF",vendor: "TELEBIT CORPORATION"},
  {max_prefix: "00-40-1F",vendor: "COLORGRAPH LTD"},
  {max_prefix: "00-40-AF",vendor: "DIGITAL PRODUCTS, INC."},
  {max_prefix: "00-40-F7",vendor: "Polaroid Corporation"},
  {max_prefix: "00-40-37",vendor: "SEA-ILAN, INC."},
  {max_prefix: "00-40-4E",vendor: "FLUENT, INC."},
  {max_prefix: "00-40-8D",vendor: "THE GOODYEAR TIRE & RUBBER CO."},
  {max_prefix: "00-C0-26",vendor: "LANS TECHNOLOGY CO., LTD."},
  {max_prefix: "00-40-46",vendor: "UDC RESEARCH LIMITED"},
  {max_prefix: "00-40-4A",vendor: "WEST AUSTRALIAN DEPARTMENT"},
  {max_prefix: "00-40-3C",vendor: "FORKS, INC."},
  {max_prefix: "00-40-42",vendor: "N.A.T. GMBH"},
  {max_prefix: "00-40-F2",vendor: "JANICH & KLASS COMPUTERTECHNIK"},
  {max_prefix: "00-40-A2",vendor: "KINGSTAR TECHNOLOGY INC."},
  {max_prefix: "00-C0-6F",vendor: "KOMATSU LTD."},
  {max_prefix: "00-C0-A7",vendor: "SEEL LTD."},
  {max_prefix: "00-40-1B",vendor: "PRINTER SYSTEMS CORP."},
  {max_prefix: "00-40-A3",vendor: "MICROUNITY SYSTEMS ENGINEERING"},
  {max_prefix: "00-40-B3",vendor: "ParTech Inc."},
  {max_prefix: "00-C0-E3",vendor: "OSITECH COMMUNICATIONS, INC."},
  {max_prefix: "00-C0-FE",vendor: "APTEC COMPUTER SYSTEMS, INC."},
  {max_prefix: "00-40-7E",vendor: "EVERGREEN SYSTEMS, INC."},
  {max_prefix: "00-40-3E",vendor: "RASTER OPS CORPORATION"},
  {max_prefix: "00-40-1D",vendor: "INVISIBLE SOFTWARE, INC."},
  {max_prefix: "00-40-F9",vendor: "COMBINET"},
  {max_prefix: "00-C0-B0",vendor: "GCC TECHNOLOGIES,INC."},
  {max_prefix: "00-C0-CA",vendor: "ALFA, INC."},
  {max_prefix: "00-C0-4A",vendor: "GROUP 2000 AG"},
  {max_prefix: "00-40-DC",vendor: "TRITEC ELECTRONIC GMBH"},
  {max_prefix: "00-40-54",vendor: "CONNECTION MACHINES SERVICES"},
  {max_prefix: "00-40-04",vendor: "ICM CO. LTD."},
  {max_prefix: "00-40-18",vendor: "ADOBE SYSTEMS, INC."},
  {max_prefix: "00-40-1A",vendor: "FUJI ELECTRIC CO., LTD."},
  {max_prefix: "00-40-80",vendor: "ATHENIX CORPORATION"},
  {max_prefix: "00-40-30",vendor: "GK COMPUTER"},
  {max_prefix: "00-40-40",vendor: "RING ACCESS, INC."},
  {max_prefix: "00-80-57",vendor: "ADSOFT, LTD."},
  {max_prefix: "00-80-BB",vendor: "HUGHES LAN SYSTEMS"},
  {max_prefix: "00-C0-D7",vendor: "TAIWAN TRADING CENTER DBA"},
  {max_prefix: "00-40-60",vendor: "COMENDEC LTD"},
  {max_prefix: "00-40-56",vendor: "MCM JAPAN LTD."},
  {max_prefix: "00-40-3D",vendor: "Teradata Corporation"},
  {max_prefix: "00-40-DA",vendor: "TELSPEC LTD"},
  {max_prefix: "00-40-A6",vendor: "Cray, Inc."},
  {max_prefix: "00-40-D0",vendor: "MITAC INTERNATIONAL CORP."},
  {max_prefix: "00-40-AB",vendor: "ROLAND DG CORPORATION"},
  {max_prefix: "00-40-B6",vendor: "COMPUTERM  CORPORATION"},
  {max_prefix: "00-40-67",vendor: "OMNIBYTE CORPORATION"},
  {max_prefix: "00-40-C3",vendor: "FISCHER AND PORTER CO."},
  {max_prefix: "00-40-EC",vendor: "MIKASA SYSTEM ENGINEERING"},
  {max_prefix: "00-80-72",vendor: "MICROPLEX SYSTEMS LTD."},
  {max_prefix: "00-80-2F",vendor: "NATIONAL INSTRUMENTS CORP."},
  {max_prefix: "00-80-54",vendor: "FRONTIER TECHNOLOGIES CORP."},
  {max_prefix: "00-80-53",vendor: "INTELLICOM, INC."},
  {max_prefix: "00-80-26",vendor: "NETWORK PRODUCTS CORPORATION"},
  {max_prefix: "00-80-B0",vendor: "ADVANCED INFORMATION"},
  {max_prefix: "00-80-FA",vendor: "RWT GMBH"},
  {max_prefix: "00-80-FD",vendor: "EXSCEED CORPRATION"},
  {max_prefix: "00-40-EE",vendor: "OPTIMEM"},
  {max_prefix: "00-40-51",vendor: "Garbee and Garbee"},
  {max_prefix: "00-40-7A",vendor: "SOCIETE D'EXPLOITATION DU CNIT"},
  {max_prefix: "00-80-FE",vendor: "AZURE TECHNOLOGIES, INC."},
  {max_prefix: "00-80-3C",vendor: "TVS ELECTRONICS LTD"},
  {max_prefix: "00-80-46",vendor: "Tattile SRL"},
  {max_prefix: "00-40-25",vendor: "MOLECULAR DYNAMICS"},
  {max_prefix: "00-40-C7",vendor: "RUBY TECH CORPORATION"},
  {max_prefix: "00-40-52",vendor: "STAR TECHNOLOGIES, INC."},
  {max_prefix: "00-40-2E",vendor: "PRECISION SOFTWARE, INC."},
  {max_prefix: "00-40-2B",vendor: "TRIGEM COMPUTER, INC."},
  {max_prefix: "00-40-02",vendor: "PERLE SYSTEMS LIMITED"},
  {max_prefix: "00-40-49",vendor: "Roche Diagnostics International Ltd."},
  {max_prefix: "00-40-29",vendor: "Compex"},
  {max_prefix: "00-40-D3",vendor: "KIMPSION INTERNATIONAL CORP."},
  {max_prefix: "00-80-11",vendor: "DIGITAL SYSTEMS INT'L. INC."},
  {max_prefix: "00-80-F1",vendor: "OPUS SYSTEMS"},
  {max_prefix: "00-80-29",vendor: "EAGLE TECHNOLOGY, INC."},
  {max_prefix: "00-40-01",vendor: "Zero One Technology Co. Ltd."},
  {max_prefix: "00-40-71",vendor: "ATM COMPUTER GMBH"},
  {max_prefix: "00-80-34",vendor: "SMT GOUPIL"},
  {max_prefix: "00-80-2C",vendor: "THE SAGE GROUP PLC"},
  {max_prefix: "00-80-D6",vendor: "NUVOTECH, INC."},
  {max_prefix: "00-80-0A",vendor: "JAPAN COMPUTER CORP."},
  {max_prefix: "00-80-27",vendor: "ADAPTIVE SYSTEMS, INC."},
  {max_prefix: "00-80-FC",vendor: "AVATAR CORPORATION"},
  {max_prefix: "00-80-E4",vendor: "NORTHWEST DIGITAL SYSTEMS, INC"},
  {max_prefix: "00-80-EC",vendor: "SUPERCOMPUTING SOLUTIONS, INC."},
  {max_prefix: "00-80-A2",vendor: "CREATIVE ELECTRONIC SYSTEMS"},
  {max_prefix: "00-80-A5",vendor: "SPEED INTERNATIONAL"},
  {max_prefix: "00-80-79",vendor: "MICROBUS DESIGNS LTD."},
  {max_prefix: "00-80-AD",vendor: "CNET TECHNOLOGY, INC."},
  {max_prefix: "00-80-0E",vendor: "ATLANTIX CORPORATION"},
  {max_prefix: "00-80-AB",vendor: "DUKANE NETWORK INTEGRATION"},
  {max_prefix: "00-80-4B",vendor: "EAGLE TECHNOLOGIES PTY.LTD."},
  {max_prefix: "00-80-C8",vendor: "D-LINK SYSTEMS, INC."},
  {max_prefix: "00-80-12",vendor: "INTEGRATED MEASUREMENT SYSTEMS"},
  {max_prefix: "00-80-CC",vendor: "MICROWAVE BYPASS SYSTEMS"},
  {max_prefix: "00-00-75",vendor: "Nortel Networks"},
  {max_prefix: "00-00-ED",vendor: "APRIL"},
  {max_prefix: "00-00-A3",vendor: "NETWORK APPLICATION TECHNOLOGY"},
  {max_prefix: "00-00-39",vendor: "TOSHIBA CORPORATION"},
  {max_prefix: "00-00-3C",vendor: "AUSPEX SYSTEMS INC."},
  {max_prefix: "00-00-7E",vendor: "CLUSTRIX CORPORATION"},
  {max_prefix: "00-00-CB",vendor: "COMPU-SHACK ELECTRONIC GMBH"},
  {max_prefix: "00-00-A5",vendor: "Tattile SRL"},
  {max_prefix: "00-00-36",vendor: "ATARI CORPORATION"},
  {max_prefix: "00-00-F8",vendor: "DIGITAL EQUIPMENT CORPORATION"},
  {max_prefix: "00-80-7B",vendor: "ARTEL COMMUNICATIONS CORP."},
  {max_prefix: "00-00-44",vendor: "CASTELLE CORPORATION"},
  {max_prefix: "00-80-5C",vendor: "AGILIS CORPORATION"},
  {max_prefix: "00-80-C5",vendor: "NOVELLCO DE MEXICO"},
  {max_prefix: "00-80-14",vendor: "ESPRIT SYSTEMS"},
  {max_prefix: "00-00-7F",vendor: "LINOTYPE-HELL AG"},
  {max_prefix: "00-00-CE",vendor: "MEGADATA CORP."},
  {max_prefix: "00-00-7B",vendor: "RESEARCH MACHINES"},
  {max_prefix: "00-80-F6",vendor: "SYNERGY MICROSYSTEMS"},
  {max_prefix: "00-80-78",vendor: "PRACTICAL PERIPHERALS, INC."},
  {max_prefix: "00-00-79",vendor: "NETWORTH INCORPORATED"},
  {max_prefix: "00-00-91",vendor: "ANRITSU CORPORATION"},
  {max_prefix: "00-00-1A",vendor: "ADVANCED MICRO DEVICES"},
  {max_prefix: "00-80-B7",vendor: "STELLAR COMPUTER"},
  {max_prefix: "00-00-96",vendor: "MARCONI ELECTRONICS LTD."},
  {max_prefix: "00-00-5E",vendor: "ICANN, IANA Department"},
  {max_prefix: "00-00-38",vendor: "CSS LABS"},
  {max_prefix: "00-00-9E",vendor: "MARLI S.A."},
  {max_prefix: "00-00-42",vendor: "METIER MANAGEMENT SYSTEMS LTD."},
  {max_prefix: "00-00-13",vendor: "CAMEX"},
  {max_prefix: "00-00-95",vendor: "SONY TEKTRONIX CORP."},
  {max_prefix: "00-00-57",vendor: "SCITEX CORPORATION LTD."},
  {max_prefix: "00-00-D6",vendor: "PUNCH LINE HOLDING"},
  {max_prefix: "00-00-40",vendor: "APPLICON, INC."},
  {max_prefix: "00-00-85",vendor: "CANON INC."},
  {max_prefix: "00-00-4A",vendor: "ADC CODENOLL TECHNOLOGY CORP."},
  {max_prefix: "00-00-12",vendor: "INFORMATION TECHNOLOGY LIMITED"},
  {max_prefix: "00-00-60",vendor: "Kontron Europe GmbH"},
  {max_prefix: "08-00-6F",vendor: "PHILIPS APELDOORN B.V."},
  {max_prefix: "00-00-6A",vendor: "COMPUTER CONSOLES INC."},
  {max_prefix: "00-00-0F",vendor: "NEXT, INC."},
  {max_prefix: "00-00-BB",vendor: "TRI-DATA"},
  {max_prefix: "00-00-7D",vendor: "Oracle Corporation"},
  {max_prefix: "00-00-8A",vendor: "DATAHOUSE INFORMATION SYSTEMS"},
  {max_prefix: "00-00-32",vendor: "Marconi plc"},
  {max_prefix: "00-00-5D",vendor: "CS TELECOM"},
  {max_prefix: "08-00-8F",vendor: "CHIPCOM CORPORATION"},
  {max_prefix: "08-00-7A",vendor: "INDATA"},
  {max_prefix: "08-00-79",vendor: "THE DROID WORKS"},
  {max_prefix: "08-00-73",vendor: "TECMAR INC."},
  {max_prefix: "08-00-72",vendor: "XEROX CORP UNIV GRANT PROGRAM"},
  {max_prefix: "08-00-6A",vendor: "AT&T"},
  {max_prefix: "08-00-19",vendor: "GENERAL ELECTRIC CORPORATION"},
  {max_prefix: "02-70-01",vendor: "RACAL-DATACOM"},
  {max_prefix: "08-00-3E",vendor: "CODEX CORPORATION"},
  {max_prefix: "08-00-40",vendor: "FERRANTI COMPUTER SYS. LIMITED"},
  {max_prefix: "08-00-3A",vendor: "ORCATECH INC."},
  {max_prefix: "08-00-3D",vendor: "CADNETIX CORPORATIONS"},
  {max_prefix: "08-00-38",vendor: "BULL S.A.S."},
  {max_prefix: "08-00-2F",vendor: "PRIME COMPUTER INC."},
  {max_prefix: "08-00-62",vendor: "General Dynamics"},
  {max_prefix: "08-00-5C",vendor: "FOUR PHASE SYSTEMS"},
  {max_prefix: "08-00-5A",vendor: "IBM Corp"},
  {max_prefix: "08-00-2C",vendor: "BRITTON LEE INC."},
  {max_prefix: "08-00-4D",vendor: "CORVUS SYSTEMS INC."},
  {max_prefix: "08-00-1E",vendor: "APOLLO COMPUTER INC."},
  {max_prefix: "08-00-52",vendor: "INSYSTEC"},
  {max_prefix: "08-00-01",vendor: "COMPUTERVISION CORPORATION"},
  {max_prefix: "08-00-05",vendor: "SYMBOLICS INC."},
  {max_prefix: "00-00-3D",vendor: "UNISYS"},
  {max_prefix: "00-00-08",vendor: "XEROX CORPORATION"},
  {max_prefix: "00-DD-07",vendor: "UNGERMANN-BASS INC."},
  {max_prefix: "00-DD-0D",vendor: "UNGERMANN-BASS INC."},
  {max_prefix: "08-00-16",vendor: "BARRISTER INFO SYS CORP"},
  {max_prefix: "00-00-06",vendor: "XEROX CORPORATION"},
  {max_prefix: "08-00-64",vendor: "Sitasys AG"},
  {max_prefix: "08-00-02",vendor: "BRIDGE COMMUNICATIONS INC."},
  {max_prefix: "08-00-1A",vendor: "TIARA/ 10NET"},
  {max_prefix: "08-00-8B",vendor: "PYRAMID TECHNOLOGY CORP."},
  {max_prefix: "08-00-12",vendor: "BELL ATLANTIC INTEGRATED SYST."},
  {max_prefix: "08-00-30",vendor: "ROYAL MELBOURNE INST OF TECH"},
  {max_prefix: "00-00-0B",vendor: "MATRIX CORPORATION"},
  {max_prefix: "00-00-9B",vendor: "INFORMATION INTERNATIONAL, INC"},
  {max_prefix: "08-00-0E",vendor: "NCR CORPORATION"},
  {max_prefix: "EC-9A-34",vendor: "Texas Instruments"},
  {max_prefix: "94-3F-0C",vendor: "Genexis B.V."},
  {max_prefix: "00-00-02",vendor: "XEROX CORPORATION"},
  {max_prefix: "00-00-03",vendor: "XEROX CORPORATION"},
  {max_prefix: "00-DD-09",vendor: "UNGERMANN-BASS INC."},
  {max_prefix: "84-BB-26",vendor: "Texas Instruments"},
  {max_prefix: "58-BA-D3",vendor: "NANJING CASELA TECHNOLOGIES CORPORATION LIMITED"},
  {max_prefix: "34-B1-EB",vendor: "Apple, Inc."},
  {max_prefix: "A4-F8-41",vendor: "Apple, Inc."},
  {max_prefix: "FC-A8-E0",vendor: "THE FURUKAWA ELECTRIC CO., LTD"},
  {max_prefix: "FC-51-B5",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "A0-52-72",vendor: "Apple, Inc."},
  {max_prefix: "20-04-84",vendor: "Apple, Inc."},
  {max_prefix: "F8-7D-3F",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "90-A5-7D",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "90-9E-24",vendor: "ekey biometric systems gmbh"},
  {max_prefix: "8C-9B-2D",vendor: "PLANTRONICS, INC."},
  {max_prefix: "28-44-30",vendor: "Arcade Communications Ltd."},
  {max_prefix: "60-49-66",vendor: "Shenzhen Dingsheng Technology Co., Ltd."},
  {max_prefix: "98-03-CF",vendor: "SHENZHEN BILIAN ELECTRONIC CO.\uff0cLTD"},
  {max_prefix: "94-32-51",vendor: "ASKEY COMPUTER CORP"},
  {max_prefix: "60-6B-5B",vendor: "Arista Network, Inc."},
  {max_prefix: "DC-E5-D8",vendor: "zte corporation"},
  {max_prefix: "68-94-4A",vendor: "zte corporation"},
  {max_prefix: "74-33-E9",vendor: "zte corporation"},
  {max_prefix: "78-ED-BC",vendor: "OnePlus Technology (Shenzhen) Co., Ltd"},
  {max_prefix: "40-C0-EE",vendor: "365mesh Pty Ltd"},
  {max_prefix: "FC-C0-CC",vendor: "Yunke China Information Technology Limited"},
  {max_prefix: "48-EB-65",vendor: "Henan KunLun Technologies CO.,Ltd."},
  {max_prefix: "C8-A3-E8",vendor: "CLOUD NETWORK TECHNOLOGY SINGAPORE PTE. LTD."},
  {max_prefix: "1C-21-56",vendor: "Smappee NV"},
  {max_prefix: "40-B7-0E",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "D8-D6-68",vendor: "Tuya Smart Inc."},
  {max_prefix: "00-4C-E5",vendor: "Sichuan Tianyi Comheart Telecom Co.,LTD"},
  {max_prefix: "C0-91-20",vendor: "Sichuan Tianyi Comheart Telecom Co.,LTD"},
  {max_prefix: "4C-50-DD",vendor: "Hui Zhou Gaoshengda Technology Co.,LTD"},
  {max_prefix: "40-72-18",vendor: "Tonly Technology Co. Ltd"},
  {max_prefix: "A8-41-F4",vendor: "AzureWave Technology Inc."},
  {max_prefix: "C4-CB-76",vendor: "Microsoft Corporation"},
  {max_prefix: "40-9E-A4",vendor: "Juniper Networks"},
  {max_prefix: "BC-4C-78",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "D4-5F-7A",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "A8-05-56",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "9C-B4-00",vendor: "zte corporation"},
  {max_prefix: "B0-52-46",vendor: "NXP Semiconductor (Tianjin) LTD."},
  {max_prefix: "F0-07-27",vendor: "INTEREL BUILDING AUTOMATION"},
  {max_prefix: "88-60-76",vendor: "Sparnex n.v."},
  {max_prefix: "F8-71-A6",vendor: "Apple, Inc."},
  {max_prefix: "78-A7-C7",vendor: "Apple, Inc."},
  {max_prefix: "98-FE-E1",vendor: "Apple, Inc."},
  {max_prefix: "34-09-62",vendor: "Hangzhou Hikvision Digital Technology Co.,Ltd."},
  {max_prefix: "28-34-FF",vendor: "Apple, Inc."},
  {max_prefix: "D0-C0-50",vendor: "Apple, Inc."},
  {max_prefix: "0C-D5-D3",vendor: "Cisco Systems, Inc"},
  {max_prefix: "2C-81-BF",vendor: "Apple, Inc."},
  {max_prefix: "10-06-48",vendor: "IEEE Registration Authority"},
  {max_prefix: "00-0D-2C",vendor: "Lantronix"},
  {max_prefix: "14-AE-68",vendor: "KLG Smartec"},
  {max_prefix: "C8-75-F4",vendor: "China Mobile Group Device Co.,Ltd."},
  {max_prefix: "2C-53-2B",vendor: "TCT mobile ltd"},
  {max_prefix: "84-94-37",vendor: "Apple, Inc."},
  {max_prefix: "64-FD-29",vendor: "Zhejiang Dahua Technology Co., Ltd."},
  {max_prefix: "44-E4-E6",vendor: "Extreme Networks Headquarters"},
  {max_prefix: "74-2E-4F",vendor: "Stienen Group"},
  {max_prefix: "5C-83-6C",vendor: "Ruckus Wireless"},
  {max_prefix: "30-16-9D",vendor: "MERCUSYS TECHNOLOGIES CO., LTD."},
  {max_prefix: "EC-46-2C",vendor: "Infinix mobility limited"},
  {max_prefix: "8C-88-81",vendor: "Cisco Meraki"},
  {max_prefix: "D8-B0-20",vendor: "Taicang T&W Electronics"},
  {max_prefix: "D4-F3-2D",vendor: "Intel Corporate"},
  {max_prefix: "2C-7B-A0",vendor: "Intel Corporate"},
  {max_prefix: "70-4C-A5",vendor: "Fortinet, Inc."},
  {max_prefix: "04-D5-90",vendor: "Fortinet, Inc."},
  {max_prefix: "94-F3-92",vendor: "Fortinet, Inc."},
  {max_prefix: "24-D2-08",vendor: "Sensata Technologies Inc."},
  {max_prefix: "10-04-C1",vendor: "JD Cloud Computing Co., Ltd."},
  {max_prefix: "5C-53-10",vendor: "Nanjing Qinheng Microelectronics Co., Ltd."},
  {max_prefix: "34-D2-62",vendor: "SZ DJI TECHNOLOGY CO.,LTD"},
  {max_prefix: "4C-4C-D8",vendor: "zte corporation"},
  {max_prefix: "54-22-E0",vendor: "Adtran Inc"},
  {max_prefix: "C4-07-78",vendor: "New H3C Technologies Co., Ltd"},
  {max_prefix: "FC-8A-F7",vendor: "zte corporation"},
  {max_prefix: "C4-50-9C",vendor: "Vantiva - Connected Home"},
  {max_prefix: "78-91-E9",vendor: "Raisecom Technology CO., LTD"},
  {max_prefix: "E8-55-40",vendor: "WUXI FUNIDE DIGITAL CO.,LTD"},
  {max_prefix: "54-D6-0D",vendor: "Hangzhou Ezviz Software Co.,Ltd."},
  {max_prefix: "58-96-71",vendor: "Wistron Neweb Corporation"},
  {max_prefix: "8C-1A-50",vendor: "China Mobile Group Device Co.,Ltd."},
  {max_prefix: "D4-00-68",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "38-63-7F",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "C8-FE-0F",vendor: "SHENZHEN BILIAN ELECTRONIC CO.\uff0cLTD"},
  {max_prefix: "30-8E-CF",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "D8-29-F8",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "38-76-05",vendor: "Inogeni"},
  {max_prefix: "78-39-2D",vendor: "IEEE Registration Authority"},
  {max_prefix: "BC-E0-01",vendor: "Netis Technology Co., Ltd."},
  {max_prefix: "D0-3E-07",vendor: "Apple, Inc."},
  {max_prefix: "50-B1-27",vendor: "Apple, Inc."},
  {max_prefix: "70-F6-CF",vendor: "Relay, Inc."},
  {max_prefix: "C8-10-72",vendor: "BBPOS Limited"},
  {max_prefix: "24-9D-2A",vendor: "LinkData Technology (Tianjin) Co., LTD"},
  {max_prefix: "E4-E6-6C",vendor: "Tiandy Technologies Co.,LTD"},
  {max_prefix: "84-3E-03",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "54-9A-8F",vendor: "Realme Chongqing Mobile Telecommunications Corp.,Ltd."},
  {max_prefix: "A8-EF-5F",vendor: "Realme Chongqing Mobile Telecommunications Corp.,Ltd."},
  {max_prefix: "F0-10-A5",vendor: "Texas Instruments"},
  {max_prefix: "C0-63-80",vendor: "Texas Instruments"},
  {max_prefix: "F8-FB-90",vendor: "Texas Instruments"},
  {max_prefix: "44-6B-1F",vendor: "Texas Instruments"},
  {max_prefix: "1C-CE-51",vendor: "AzureWave Technology Inc."},
  {max_prefix: "0C-A1-38",vendor: "BLiNQ Networks Inc."},
  {max_prefix: "C0-25-2F",vendor: "SHENZHEN MERCURY COMMUNICATION TECHNOLOGIES CO.,LTD."},
  {max_prefix: "10-63-4B",vendor: "SHENZHEN MERCURY COMMUNICATION TECHNOLOGIES CO.,LTD."},
  {max_prefix: "04-4B-A5",vendor: "SHENZHEN MERCURY COMMUNICATION TECHNOLOGIES CO.,LTD."},
  {max_prefix: "D4-84-09",vendor: "SHENZHEN MERCURY COMMUNICATION TECHNOLOGIES CO.,LTD."},
  {max_prefix: "E4-F3-F5",vendor: "SHENZHEN MERCURY COMMUNICATION TECHNOLOGIES CO.,LTD."},
  {max_prefix: "C0-A5-DD",vendor: "SHENZHEN MERCURY COMMUNICATION TECHNOLOGIES CO.,LTD."},
  {max_prefix: "D8-EE-42",vendor: "Sichuan Tianyi Comheart Telecom Co.,LTD"},
  {max_prefix: "28-AF-42",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "F4-ED-37",vendor: "Qingdao Yuze lntelligent Technology Co.,Ltd"},
  {max_prefix: "BC-02-6E",vendor: "Silicon Laboratories"},
  {max_prefix: "B4-35-22",vendor: "Silicon Laboratories"},
  {max_prefix: "90-39-5E",vendor: "Silicon Laboratories"},
  {max_prefix: "E8-E0-7E",vendor: "Silicon Laboratories"},
  {max_prefix: "B0-C7-DE",vendor: "Silicon Laboratories"},
  {max_prefix: "88-1A-14",vendor: "Silicon Laboratories"},
  {max_prefix: "44-E2-F8",vendor: "Silicon Laboratories"},
  {max_prefix: "E4-12-26",vendor: "Continental Automotive Romania SLR"},
  {max_prefix: "30-FB-68",vendor: "Wuhan Zmvision Technology Co. Ltd."},
  {max_prefix: "30-A3-0F",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "24-68-30",vendor: "Shenzhen Shokzhear Co., Ltd"},
  {max_prefix: "30-8D-D4",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "3C-90-E0",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "70-73-62",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "B8-DB-38",vendor: "Google, Inc."},
  {max_prefix: "74-C6-4A",vendor: "AGOS Co.,Ltd"},
  {max_prefix: "E4-61-F4",vendor: "shenzhen worldelite electronics co., LTD"},
  {max_prefix: "10-9F-47",vendor: "Shenzhen Skyworth Digital  Technology  CO., Ltd"},
  {max_prefix: "E4-37-9F",vendor: "Cisco Systems, Inc"},
  {max_prefix: "54-64-BC",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "00-26-A4",vendor: "Novus Produtos Eletronicos Ltda"},
  {max_prefix: "24-58-7C",vendor: "Espressif Inc."},
  {max_prefix: "CC-8D-A2",vendor: "Espressif Inc."},
  {max_prefix: "EC-31-4A",vendor: "Chengdu Quanjing Intelligent Technology Co.,Ltd"},
  {max_prefix: "04-F4-D8",vendor: "Hui Zhou Gaoshengda Technology Co.,LTD"},
  {max_prefix: "D4-AD-FC",vendor: "Shenzhen Intellirocks Tech. Co. Ltd."},
  {max_prefix: "E8-27-25",vendor: "Axis Communications AB"},
  {max_prefix: "9C-C8-E9",vendor: "Amazon Technologies Inc."},
  {max_prefix: "3C-B4-3D",vendor: "SZ Tenveo video technology co., Ltd"},
  {max_prefix: "54-78-85",vendor: "SHENZHEN GIEC DIGITAL CO.,LTD"},
  {max_prefix: "60-6B-B3",vendor: "zte corporation"},
  {max_prefix: "A0-DD-6C",vendor: "Espressif Inc."},
  {max_prefix: "00-0E-2A",vendor: "dormakaba USA Inc."},
  {max_prefix: "74-30-9D",vendor: "Infinix mobility limited"},
  {max_prefix: "54-A7-A0",vendor: "HUNAN AIMAG INTELLIGENT TECHNOLOGY CO.,LTD"},
  {max_prefix: "14-C6-7D",vendor: "Vizio, Inc"},
  {max_prefix: "E8-F0-85",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "3C-36-6A",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "64-F0-AD",vendor: "Zhejiang Tmall Technology Co., Ltd."},
  {max_prefix: "E4-FA-C4",vendor: "TP-LINK CORPORATION PTE. LTD."},
  {max_prefix: "40-AE-30",vendor: "TP-LINK CORPORATION PTE. LTD."},
  {max_prefix: "2C-66-AD",vendor: "NimbleTech Digital Inc."},
  {max_prefix: "44-E6-4A",vendor: "Shenzhen iComm Semiconductor CO.,LTD"},
  {max_prefix: "70-13-01",vendor: "Vantiva - Connected Home"},
  {max_prefix: "EC-5A-A3",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "98-87-6C",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "0C-83-06",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "04-6E-CB",vendor: "zte corporation"},
  {max_prefix: "F0-ED-19",vendor: "zte corporation"},
  {max_prefix: "D0-EF-76",vendor: "Espressif Inc."},
  {max_prefix: "C4-D8-D5",vendor: "Espressif Inc."},
  {max_prefix: "EC-CD-4C",vendor: "New H3C Technologies Co., Ltd"},
  {max_prefix: "D0-4F-AB",vendor: "Yoqu Technology (Shenzhen) Co., Ltd."},
  {max_prefix: "00-25-FC",vendor: "ENDA"},
  {max_prefix: "E8-14-99",vendor: "Yoqu Technology(Shenzhen)Co.,Ltd."},
  {max_prefix: "68-4D-B6",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "20-5D-0D",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "60-34-57",vendor: "HP Tuners LLC"},
  {max_prefix: "08-DD-EB",vendor: "Silicon Laboratories"},
  {max_prefix: "C0-6F-98",vendor: "eero inc."},
  {max_prefix: "D8-A0-E6",vendor: "Chongqing Yipingfang Technology Co., Ltd."},
  {max_prefix: "78-66-F3",vendor: "shenzhen worldelite electronics co., LTD"},
  {max_prefix: "0C-0A-DF",vendor: "Texas Instruments"},
  {max_prefix: "DC-BE-04",vendor: "Texas Instruments"},
  {max_prefix: "E0-B7-63",vendor: "Bosch Automotive Products (Suzhou) Co., Ltd. Changzhou Branch"},
  {max_prefix: "C8-CD-55",vendor: "Ruijie Networks Co.,LTD"},
  {max_prefix: "C4-B2-5B",vendor: "Ruijie Networks Co.,LTD"},
  {max_prefix: "98-4A-6B",vendor: "Ruijie Networks Co.,LTD"},
  {max_prefix: "10-2F-F8",vendor: "Vicoretek (Nanjing) Co.,Ltd."},
  {max_prefix: "C4-57-CD",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "28-22-1E",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "F0-E7-52",vendor: "Shenzhen Huajuxin Semiconductor Co.,ltd"},
  {max_prefix: "64-BA-A4",vendor: "zte corporation"},
  {max_prefix: "38-24-F1",vendor: "Private"},
  {max_prefix: "24-EF-B4",vendor: "Shanghai Neardi Technologies Co. Ltd."},
  {max_prefix: "40-D1-60",vendor: "Apple, Inc."},
  {max_prefix: "BC-BB-58",vendor: "Apple, Inc."},
  {max_prefix: "30-3B-7C",vendor: "Apple, Inc."},
  {max_prefix: "00-81-2A",vendor: "Apple, Inc."},
  {max_prefix: "CC-6A-33",vendor: "Cisco Systems, Inc"},
  {max_prefix: "1C-E2-09",vendor: "Apple, Inc."},
  {max_prefix: "90-03-71",vendor: "Quectel Wireless Solutions Co.,Ltd."},
  {max_prefix: "94-9F-8B",vendor: "zte corporation"},
  {max_prefix: "6C-C4-1E",vendor: "NEXSEC Incorporated"},
  {max_prefix: "F4-6E-D6",vendor: "EM Microelectronic"},
  {max_prefix: "80-28-3C",vendor: "Sonova AG"},
  {max_prefix: "80-AE-54",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "18-EF-C0",vendor: "Sercomm Japan Corporation"},
  {max_prefix: "3C-08-68",vendor: "Power Plus Communications AG"},
  {max_prefix: "90-15-64",vendor: "IEEE Registration Authority"},
  {max_prefix: "20-50-0F",vendor: "Fiber Groep B.V."},
  {max_prefix: "94-8C-D7",vendor: "Hui Zhou Gaoshengda Technology Co.,LTD"},
  {max_prefix: "48-E9-CA",vendor: "creoline GmbH"},
  {max_prefix: "D4-7F-35",vendor: "Cisco Systems, Inc"},
  {max_prefix: "40-8F-9A",vendor: "KanEL Sweden AB"},
  {max_prefix: "00-1C-A1",vendor: "Akamai Technologies Inc."},
  {max_prefix: "50-79-73",vendor: "Inagile Electronic Technology Co.,LTD."},
  {max_prefix: "5C-35-48",vendor: "Aditya Infotech Ltd."},
  {max_prefix: "88-DA-18",vendor: "China Mobile Group Device Co.,Ltd."},
  {max_prefix: "98-CB-38",vendor: "Boxin Communications Limited Liability Company"},
  {max_prefix: "D0-09-F5",vendor: "Hosiden Corporation"},
  {max_prefix: "28-68-47",vendor: "Silicon Laboratories"},
  {max_prefix: "44-C5-32",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "30-D4-E2",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "30-C6-AB",vendor: "zte corporation"},
  {max_prefix: "48-E6-63",vendor: "IEEE Registration Authority"},
  {max_prefix: "DC-EC-E3",vendor: "HORYS TECHNOLOGIES LLC"},
  {max_prefix: "A4-40-3D",vendor: "Shenzhen Baseus Technology Co., Ltd."},
  {max_prefix: "4C-81-25",vendor: "ZOWEE TECHNOLOGY(HEYUAN)Co.,Ltd"},
  {max_prefix: "48-49-82",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "28-36-F0",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "1C-13-FA",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "A8-B0-D1",vendor: "EFUN Display Technology (Shenzhen) Co., Ltd."},
  {max_prefix: "48-35-84",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "88-F6-DC",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "70-EB-74",vendor: "Ningbo Goneo Electric Appliance Co., Ltd."},
  {max_prefix: "60-D5-1B",vendor: "Fujitsu Limited"},
  {max_prefix: "00-05-7E",vendor: "Eckelmann AG"},
  {max_prefix: "D4-95-5D",vendor: "zte corporation"},
  {max_prefix: "1C-3C-78",vendor: "Apple, Inc."},
  {max_prefix: "94-0B-CD",vendor: "Apple, Inc."},
  {max_prefix: "9C-66-97",vendor: "Cisco Systems, Inc"},
  {max_prefix: "D0-E5-81",vendor: "Apple, Inc."},
  {max_prefix: "C4-B1-D9",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "70-70-13",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "A4-A4-59",vendor: "Hangzhou Hikvision Digital Technology Co.,Ltd."},
  {max_prefix: "E4-5B-B3",vendor: "zte corporation"},
  {max_prefix: "24-EC-4A",vendor: "Espressif Inc."},
  {max_prefix: "98-1D-AC",vendor: "Cyviz AS"},
  {max_prefix: "F4-03-43",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "98-F2-B3",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "70-10-6F",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "C8-FF-BF",vendor: "IEEE Registration Authority"},
  {max_prefix: "00-14-38",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "00-1A-1E",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "94-B4-0F",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "BC-9F-E4",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "D0-15-A6",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "48-2F-6B",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "0C-97-5F",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "34-3A-20",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "74-9E-75",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "EC-EB-B8",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "38-17-C3",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "04-09-73",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "B8-83-03",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "90-4C-81",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "54-80-28",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "9C-8C-D8",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "08-F1-EA",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "D0-D3-E0",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "8C-85-C1",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "FC-7F-F1",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "94-64-24",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "D4-F5-EF",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "54-77-8A",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "38-21-C7",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "00-24-6C",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "94-A6-D8",vendor: "New H3C Technologies Co., Ltd"},
  {max_prefix: "A8-27-C8",vendor: "Edgecore Americas Networking Corporation"},
  {max_prefix: "24-DD-1B",vendor: "Qingdao Hi-image Technologies Co., Ltd"},
  {max_prefix: "A8-52-D4",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "50-E4-E0",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "F8-44-77",vendor: "Silicon Laboratories"},
  {max_prefix: "6C-FD-22",vendor: "Silicon Laboratories"},
  {max_prefix: "38-0B-26",vendor: "Mindray Co., Ltd."},
  {max_prefix: "CC-7E-0F",vendor: "Theben AG"},
  {max_prefix: "C4-47-4E",vendor: "Intel Corporate"},
  {max_prefix: "08-B4-D2",vendor: "Intel Corporate"},
  {max_prefix: "5C-B4-7E",vendor: "Intel Corporate"},
  {max_prefix: "28-C5-C8",vendor: "HP Inc."},
  {max_prefix: "38-AA-20",vendor: "zte corporation"},
  {max_prefix: "70-6A-C9",vendor: "zte corporation"},
  {max_prefix: "24-3F-75",vendor: "Hui Zhou Gaoshengda Technology Co.,LTD"},
  {max_prefix: "90-D3-CF",vendor: "ASKEY COMPUTER CORP"},
  {max_prefix: "48-3A-02",vendor: "Fortinet, Inc."},
  {max_prefix: "90-CB-A3",vendor: "TECNO MOBILE LIMITED"},
  {max_prefix: "BC-41-A0",vendor: "zte corporation"},
  {max_prefix: "C8-71-1F",vendor: "SUZHOU TESIEN TECHNOLOGY CO., LTD."},
  {max_prefix: "D8-D2-61",vendor: "GD Midea Air-Conditioning Equipment Co.,Ltd."},
  {max_prefix: "24-62-78",vendor: "sysmocom - s.f.m.c. GmbH"},
  {max_prefix: "70-37-8E",vendor: "Tianyi Telecom Terminals Company Limited"},
  {max_prefix: "D4-3B-8A",vendor: "Shenzhen Zhide technology Co., LTD"},
  {max_prefix: "2C-ED-89",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "80-05-18",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "DC-EB-4D",vendor: "Motorola (Wuhan) Mobility Technologies Communication Co., Ltd."},
  {max_prefix: "24-7C-46",vendor: "FLEXTRONICS TECHNOLOGIES MEXICO S DE RL DE CV"},
  {max_prefix: "14-6C-27",vendor: "Dongguan Liesheng Electronic Co., Ltd."},
  {max_prefix: "C4-70-BD",vendor: "Mellanox Technologies, Inc."},
  {max_prefix: "B8-E9-24",vendor: "Mellanox Technologies, Inc."},
  {max_prefix: "94-0D-4B",vendor: "Cisco Systems, Inc"},
  {max_prefix: "04-E3-87",vendor: "Cisco Systems, Inc"},
  {max_prefix: "44-27-45",vendor: "LG Innotek"},
  {max_prefix: "8C-12-C2",vendor: "GLBB Japan"},
  {max_prefix: "44-87-63",vendor: "FN-LINK TECHNOLOGY Ltd."},
  {max_prefix: "8C-08-AA",vendor: "Apple, Inc."},
  {max_prefix: "F4-A3-10",vendor: "Apple, Inc."},
  {max_prefix: "3C-3B-77",vendor: "Apple, Inc."},
  {max_prefix: "E8-4A-78",vendor: "Apple, Inc."},
  {max_prefix: "10-DA-63",vendor: "Apple, Inc."},
  {max_prefix: "A8-BA-25",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "3C-A8-0A",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "B0-65-3A",vendor: "Murata Manufacturing Co., Ltd."},
  {max_prefix: "00-A4-1C",vendor: "Tonly Technology Co. Ltd"},
  {max_prefix: "90-15-06",vendor: "Espressif Inc."},
  {max_prefix: "60-F5-49",vendor: "Apple, Inc."},
  {max_prefix: "D8-3B-DA",vendor: "Espressif Inc."},
  {max_prefix: "40-BC-68",vendor: "Funshion Online Technologies Co.,Ltd"},
  {max_prefix: "D0-AE-05",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "58-FC-E3",vendor: "Funshion Online Technologies Co.,Ltd"},
  {max_prefix: "48-A1-70",vendor: "Cisco Systems, Inc"},
  {max_prefix: "74-02-E1",vendor: "Texas Instruments"},
  {max_prefix: "24-F3-06",vendor: "ITEL MOBILE LIMITED"},
  {max_prefix: "64-9C-F3",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "78-99-12",vendor: "Flyingvoice (HongKong) Technologies Limited"},
  {max_prefix: "94-10-42",vendor: "Fanox Electronic S.L."},
  {max_prefix: "10-90-FC",vendor: "Shenzhen DOOGEE Hengtong Technology CO.,LTD"},
  {max_prefix: "4C-97-A1",vendor: "Silicon Laboratories"},
  {max_prefix: "B0-3B-1B",vendor: "Kontrolnext Technology (Beijing) Ltd."},
  {max_prefix: "BC-34-CA",vendor: "INOVANCE"},
  {max_prefix: "A0-0E-98",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "B4-1E-52",vendor: "Flock Safety"},
  {max_prefix: "58-B4-BB",vendor: "Ruijie Networks Co.,LTD"},
  {max_prefix: "60-D5-61",vendor: "Shenzhen Glazero Technology Co., Ltd."},
  {max_prefix: "7C-D4-4D",vendor: "Shanghai Moorewatt Energy Technology Co.,Ltd"},
  {max_prefix: "04-4B-B1",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "F0-D7-EE",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "F4-5B-29",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "34-96-71",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "B8-A2-5D",vendor: "Motorola Mobility LLC, a Lenovo Company"},
  {max_prefix: "B0-19-21",vendor: "TP-LINK CORPORATION PTE. LTD."},
  {max_prefix: "34-46-63",vendor: "IEEE Registration Authority"},
  {max_prefix: "0C-CD-B4",vendor: "Sichuan AI-Link Technology Co., Ltd."},
  {max_prefix: "7C-A5-8F",vendor: "shenzhen Qikai Electronic Co., Ltd."},
  {max_prefix: "64-7B-1E",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "8C-5E-4D",vendor: "DragonWave Technologies DMCC"},
  {max_prefix: "78-00-A8",vendor: "PT HAN SUNG ELECTORONICS INDONESIA"},
  {max_prefix: "98-FA-2E",vendor: "Sony Interactive Entertainment Inc."},
  {max_prefix: "90-03-2E",vendor: "Taicang T&W Electronics"},
  {max_prefix: "F4-0A-2E",vendor: "Shenzhen Skyworth Digital  Technology  CO., Ltd"},
  {max_prefix: "40-69-18",vendor: "EM Microelectronic"},
  {max_prefix: "A8-5C-03",vendor: "JiangSu Fulian Communication Technology Co., Ltd"},
  {max_prefix: "20-BE-B8",vendor: "Amazon Technologies Inc."},
  {max_prefix: "C0-95-CF",vendor: "Amazon Technologies Inc."},
  {max_prefix: "08-8B-C8",vendor: "Google, Inc."},
  {max_prefix: "6C-3A-FF",vendor: "Apple, Inc."},
  {max_prefix: "EC-97-A2",vendor: "Apple, Inc."},
  {max_prefix: "44-9E-8B",vendor: "Apple, Inc."},
  {max_prefix: "54-0A-77",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "2C-E3-8E",vendor: "Cisco Systems, Inc"},
  {max_prefix: "E0-C3-EA",vendor: "Apple, Inc."},
  {max_prefix: "6C-1F-8A",vendor: "Apple, Inc."},
  {max_prefix: "38-54-39",vendor: "Guangzhou Shiyuan Electronic Technology Company Limited"},
  {max_prefix: "60-B5-8D",vendor: "AVM Audiovisuelles Marketing und Computersysteme GmbH"},
  {max_prefix: "C4-0F-A6",vendor: "SERNET (SUZHOU) TECHNOLOGIES CORPORATION"},
  {max_prefix: "E8-12-2D",vendor: "UNIONMAN TECHNOLOGY CO.,LTD"},
  {max_prefix: "4C-39-46",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "78-C4-FA",vendor: "Meta Platforms, Inc."},
  {max_prefix: "F4-20-55",vendor: "IEEE Registration Authority"},
  {max_prefix: "44-3B-14",vendor: "MitraStar Technology Corp."},
  {max_prefix: "44-63-70",vendor: "LCFC(Hefei) Electronics Technology co., ltd"},
  {max_prefix: "C4-C6-E6",vendor: "LCFC(Hefei) Electronics Technology co., ltd"},
  {max_prefix: "18-2C-A9",vendor: "DASAN Networks, Inc."},
  {max_prefix: "54-A5-52",vendor: "Shenzhen WeSing Interactive Entertainment Technology Co., Ltd"},
  {max_prefix: "24-0A-3F",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "20-3B-67",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "24-60-B3",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "80-22-FA",vendor: "ITEL MOBILE LIMITED"},
  {max_prefix: "54-E1-AD",vendor: "LCFC(Hefei) Electronics Technology co., ltd"},
  {max_prefix: "00-2B-67",vendor: "LCFC(Hefei) Electronics Technology co., ltd"},
  {max_prefix: "B0-81-84",vendor: "Espressif Inc."},
  {max_prefix: "B4-05-5D",vendor: "IEIT SYSTEMS Co., Ltd."},
  {max_prefix: "30-63-EA",vendor: "Juniper Networks"},
  {max_prefix: "A0-98-57",vendor: "Shenzhen ELINK Technology Co., Ltd."},
  {max_prefix: "A8-4F-A4",vendor: "CHINA DRAGON TECHNOLOGY LIMITED"},
  {max_prefix: "5C-5D-EC",vendor: "JiangSu Newcom Optical&Electrical Communication CO Ltd"},
  {max_prefix: "24-86-25",vendor: "IEEE Registration Authority"},
  {max_prefix: "58-EA-1F",vendor: "Beijing Xiaomi Mobile Software Co., Ltd"},
  {max_prefix: "94-8B-93",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "F8-20-D2",vendor: "Vantiva USA LLC"},
  {max_prefix: "C0-A3-9E",vendor: "EarthCam, Inc."},
  {max_prefix: "8C-1D-55",vendor: "Hanwha NxMD (Thailand) Co., Ltd."},
  {max_prefix: "7C-8D-9C",vendor: "Edgecore Americas Networking Corporation"},
  {max_prefix: "D8-26-FA",vendor: "Jiangxi Zhentian Technology CO.,LTD"},
  {max_prefix: "F4-FB-F5",vendor: "EM Microelectronic"},
  {max_prefix: "E8-62-BE",vendor: "Intel Corporate"},
  {max_prefix: "10-35-97",vendor: "Qorvo Utrecht B.V."},
  {max_prefix: "AC-5C-80",vendor: "Telink Micro LLC"},
  {max_prefix: "C8-B4-AB",vendor: "Inspur Computer Technology Co.,Ltd."},
  {max_prefix: "C4-D8-C8",vendor: "Silicon Laboratories"},
  {max_prefix: "74-3C-24",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "B8-D4-C3",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "00-A9-1D",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "D0-F7-6E",vendor: "Shenzhen YOUHUA Technology Co., Ltd"},
  {max_prefix: "30-F9-47",vendor: "Shenzhen Skyworth Digital  Technology  CO., Ltd"},
  {max_prefix: "A0-FA-9C",vendor: "WEIFANG GOERTEK ELECTRONICS CO.,LTD"},
  {max_prefix: "48-A9-64",vendor: "APEXSHA SMARTTECH PRIVATE LIMITED"},
  {max_prefix: "E4-F5-8E",vendor: "Schneider Electric USA"},
  {max_prefix: "48-5F-2D",vendor: "Amazon Technologies Inc."},
  {max_prefix: "E8-1D-EE",vendor: "i-TEK RFID"},
  {max_prefix: "C4-4F-D5",vendor: "Vantiva - Connected Home"},
  {max_prefix: "D0-F8-E7",vendor: "Shenzhen Shutong Space Technology Co., Ltd"},
  {max_prefix: "8C-4F-00",vendor: "Espressif Inc."},
  {max_prefix: "E8-08-AF",vendor: "zte corporation"},
  {max_prefix: "0C-C1-19",vendor: "Shenzhen Phaten Tech. LTD"},
  {max_prefix: "80-48-9F",vendor: "Hangzhou Hikvision Digital Technology Co.,Ltd."},
  {max_prefix: "34-A3-4E",vendor: "NevadaNano"},
  {max_prefix: "B4-A1-0A",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "30-6D-F9",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "DC-31-30",vendor: "3onedata Technology Co. Ltd."},
  {max_prefix: "F0-BD-EE",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "94-34-2F",vendor: "ITEL MOBILE LIMITED"},
  {max_prefix: "2C-D8-DE",vendor: "AltoBeam Inc."},
  {max_prefix: "54-C8-CC",vendor: "Shenzhen SDG Telecom Equipment Co.,Ltd."},
  {max_prefix: "00-BB-43",vendor: "Tiinlab Corporation"},
  {max_prefix: "04-CB-01",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "70-6E-10",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "C4-D8-D4",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "80-92-A5",vendor: "Valeo Interior Controls (Shenzhen) Co.,Ltd"},
  {max_prefix: "2C-34-7B",vendor: "SHENZHEN JUNGE TECHNOLOGY CO.,LTD"},
  {max_prefix: "E4-7F-3C",vendor: "zte corporation"},
  {max_prefix: "2C-5E-AB",vendor: "Mellanox Technologies, Inc."},
  {max_prefix: "40-B2-15",vendor: "Extreme Networks Headquarters"},
  {max_prefix: "EC-1D-53",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "FC-62-6F",vendor: "Fortx"},
  {max_prefix: "C8-75-DD",vendor: "LG Electronics NV"},
  {max_prefix: "30-34-F6",vendor: "Vantiva Connected Home - Subcomponents"},
  {max_prefix: "A8-50-08",vendor: "Felion Technologies Company Limited"},
  {max_prefix: "B8-22-0C",vendor: "Apple, Inc."},
  {max_prefix: "9C-A9-C5",vendor: "Apple, Inc."},
  {max_prefix: "F0-27-A0",vendor: "Apple, Inc."},
  {max_prefix: "50-BA-02",vendor: "Qingdao Intelligent&Precise Electronics Co.,Ltd."},
  {max_prefix: "38-39-CD",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "40-73-4D",vendor: "New H3C Technologies Co., Ltd"},
  {max_prefix: "E4-58-BC",vendor: "Bose Corporation"},
  {max_prefix: "84-0F-4C",vendor: "Apple, Inc."},
  {max_prefix: "3C-64-CF",vendor: "TP-LINK CORPORATION PTE. LTD."},
  {max_prefix: "A0-D9-1A",vendor: "Texas Instruments"},
  {max_prefix: "C0-4A-0E",vendor: "Texas Instruments"},
  {max_prefix: "D4-06-0F",vendor: "Texas Instruments"},
  {max_prefix: "5C-2D-08",vendor: "Subeca"},
  {max_prefix: "84-39-FC",vendor: "Nokia"},
  {max_prefix: "60-56-EE",vendor: "AltoBeam Inc."},
  {max_prefix: "D0-20-DD",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "28-17-2E",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "CC-BA-97",vendor: "Espressif Inc."},
  {max_prefix: "50-CF-14",vendor: "Quectel Wireless Solutions Co.,Ltd."},
  {max_prefix: "10-5F-81",vendor: "INTENTSECURE Inc.,"},
  {max_prefix: "CC-A1-50",vendor: "SystemX Co.,Ltd."},
  {max_prefix: "68-79-C4",vendor: "Shanghai MXCHIP Information Technology Co., Ltd."},
  {max_prefix: "E8-E6-09",vendor: "Chongqing Zhouhai  intelligent  technology CO., Ltd"},
  {max_prefix: "CC-BF-0C",vendor: "SHENZHEN FENDA TECHNOLOGY CO., LTD"},
  {max_prefix: "70-D9-83",vendor: "Shanghai JINXVM Microelectronics Co.,Ltd."},
  {max_prefix: "98-4E-8A",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "9C-61-D7",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "9C-BF-0D",vendor: "Framework Computer Inc."},
  {max_prefix: "18-9C-2C",vendor: "Dongguan Huayin Electronic Technology Co., Ltd."},
  {max_prefix: "00-1C-F3",vendor: "EVS Broadcast Equipment"},
  {max_prefix: "00-03-41",vendor: "EVS Broadcast Equipment"},
  {max_prefix: "D4-DC-85",vendor: "Edgecore Americas Networking Corporation"},
  {max_prefix: "20-37-F0",vendor: "Arcadyan Corporation"},
  {max_prefix: "4C-01-F7",vendor: "Cisco Systems, Inc"},
  {max_prefix: "A0-89-66",vendor: "CIG SHANGHAI CO LTD"},
  {max_prefix: "74-6A-B3",vendor: "MICIUS Laboratory"},
  {max_prefix: "D4-0E-60",vendor: "Nanjing phx-gctech Information Technology Co., Ltd"},
  {max_prefix: "98-17-3C",vendor: "Private"},
  {max_prefix: "A4-7D-9F",vendor: "Shenzhen iComm Semiconductor CO.,LTD"},
  {max_prefix: "84-EA-97",vendor: "Shenzhen iComm Semiconductor CO.,LTD"},
  {max_prefix: "14-B2-E5",vendor: "Shenzhen iComm Semiconductor CO.,LTD"},
  {max_prefix: "94-A7-48",vendor: "New H3C Technologies Co., Ltd"},
  {max_prefix: "34-D7-2F",vendor: "Shenzhen SuperElectron Technology Co.,Ltd."},
  {max_prefix: "E0-CB-56",vendor: "Shenzhen iComm Semiconductor CO.,LTD"},
  {max_prefix: "D0-A0-BB",vendor: "Shenzhen iComm Semiconductor CO.,LTD"},
  {max_prefix: "A0-AC-78",vendor: "Shenzhen iComm Semiconductor CO.,LTD"},
  {max_prefix: "74-05-1D",vendor: "Jiangxi Risound Electronics Co.,LTD"},
  {max_prefix: "A8-E2-91",vendor: "AzureWave Technology Inc."},
  {max_prefix: "90-E4-B0",vendor: "SHARP Corporation"},
  {max_prefix: "3C-C5-DD",vendor: "Hui Zhou Gaoshengda Technology Co.,LTD"},
  {max_prefix: "44-85-DA",vendor: "Shenzhen Skyworth Digital  Technology  CO., Ltd"},
  {max_prefix: "3C-76-25",vendor: "zte corporation"},
  {max_prefix: "40-D5-21",vendor: "LG Innotek"},
  {max_prefix: "48-62-64",vendor: "Arlo Technology"},
  {max_prefix: "14-8F-34",vendor: "TECNO MOBILE LIMITED"},
  {max_prefix: "B8-3C-20",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "60-B0-E8",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "04-E3-E5",vendor: "Silicon Laboratories"},
  {max_prefix: "58-02-05",vendor: "AzureWave Technology Inc."},
  {max_prefix: "B8-1E-9E",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "9C-97-93",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "74-22-0D",vendor: "CHENGDU XUGUANG TECHNOLOGY CO,LTD"},
  {max_prefix: "48-D6-82",vendor: "zte corporation"},
  {max_prefix: "24-DE-EB",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "E0-F3-25",vendor: "Elkor Technologies Inc."},
  {max_prefix: "E8-AC-7E",vendor: "TERAHOP PTE.LTD."},
  {max_prefix: "A4-C3-BE",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "FC-43-45",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "D4-5E-89",vendor: "Motorola Mobility LLC, a Lenovo Company"},
  {max_prefix: "D0-6C-37",vendor: "ikeja wireless (pty) ltd"},
  {max_prefix: "A4-F9-21",vendor: "Apple, Inc."},
  {max_prefix: "FC-A5-C8",vendor: "Apple, Inc."},
  {max_prefix: "7C-D2-DA",vendor: "Apple, Inc."},
  {max_prefix: "80-AF-19",vendor: "Apple, Inc."},
  {max_prefix: "AC-10-07",vendor: "Arcadyan Corporation"},
  {max_prefix: "60-1A-4F",vendor: "Beijing China Electronics Intelligent Acoustics Technology Co.,Ltd"},
  {max_prefix: "90-EF-4A",vendor: "Dongguan Liesheng Electronic Co., Ltd."},
  {max_prefix: "54-0A-8A",vendor: " Jlztlink Industry(ShenZhen)Co.,Ltd."},
  {max_prefix: "B0-AC-82",vendor: "CHINA DRAGON TECHNOLOGY LIMITED"},
  {max_prefix: "AC-16-DE",vendor: "Intel Corporate"},
  {max_prefix: "D0-57-7E",vendor: "Intel Corporate"},
  {max_prefix: "10-36-AA",vendor: "Vantiva - Connected Home"},
  {max_prefix: "00-BE-44",vendor: "Silicon Laboratories"},
  {max_prefix: "0C-2C-7C",vendor: "Shenzhen Skyworth Digital  Technology  CO., Ltd"},
  {max_prefix: "40-31-1B",vendor: "Genbyte Technology Inc."},
  {max_prefix: "94-A9-90",vendor: "Espressif Inc."},
  {max_prefix: "D4-5F-2C",vendor: "zte corporation"},
  {max_prefix: "78-93-C3",vendor: "Hui Zhou Gaoshengda Technology Co.,LTD"},
  {max_prefix: "10-05-B1",vendor: "Commscope"},
  {max_prefix: "E8-33-81",vendor: "Commscope"},
  {max_prefix: "D4-05-98",vendor: "Commscope"},
  {max_prefix: "6C-CA-08",vendor: "Commscope"},
  {max_prefix: "54-65-DE",vendor: "Commscope"},
  {max_prefix: "08-3E-0C",vendor: "Commscope"},
  {max_prefix: "40-70-09",vendor: "Commscope"},
  {max_prefix: "94-87-7C",vendor: "Commscope"},
  {max_prefix: "E8-89-2C",vendor: "Commscope"},
  {max_prefix: "F8-ED-A5",vendor: "Commscope"},
  {max_prefix: "20-73-55",vendor: "Commscope"},
  {max_prefix: "1C-1B-68",vendor: "Commscope"},
  {max_prefix: "3C-36-E4",vendor: "Commscope"},
  {max_prefix: "3C-7A-8A",vendor: "Commscope"},
  {max_prefix: "8C-7F-3B",vendor: "Commscope"},
  {max_prefix: "00-1B-DD",vendor: "Commscope"},
  {max_prefix: "00-1E-8D",vendor: "Commscope"},
  {max_prefix: "00-1B-52",vendor: "Commscope"},
  {max_prefix: "00-23-ED",vendor: "Commscope"},
  {max_prefix: "00-23-A2",vendor: "Commscope"},
  {max_prefix: "00-1A-77",vendor: "Commscope"},
  {max_prefix: "00-1A-66",vendor: "Commscope"},
  {max_prefix: "00-1A-1B",vendor: "Commscope"},
  {max_prefix: "00-19-5E",vendor: "Commscope"},
  {max_prefix: "00-19-2C",vendor: "Commscope"},
  {max_prefix: "60-19-71",vendor: "Commscope"},
  {max_prefix: "00-15-96",vendor: "Commscope"},
  {max_prefix: "00-15-D0",vendor: "Commscope"},
  {max_prefix: "40-B7-F3",vendor: "Commscope"},
  {max_prefix: "90-B1-34",vendor: "Commscope"},
  {max_prefix: "20-E5-64",vendor: "Commscope"},
  {max_prefix: "00-12-C9",vendor: "Commscope"},
  {max_prefix: "00-1D-BE",vendor: "Commscope"},
  {max_prefix: "00-1D-6B",vendor: "Commscope"},
  {max_prefix: "00-16-75",vendor: "Commscope"},
  {max_prefix: "00-17-E2",vendor: "Commscope"},
  {max_prefix: "5C-57-1A",vendor: "Commscope"},
  {max_prefix: "00-0C-E5",vendor: "Commscope"},
  {max_prefix: "00-03-E0",vendor: "Commscope"},
  {max_prefix: "B0-DA-F9",vendor: "Commscope"},
  {max_prefix: "18-35-D1",vendor: "Commscope"},
  {max_prefix: "BC-2E-48",vendor: "Commscope"},
  {max_prefix: "60-8C-E6",vendor: "Commscope"},
  {max_prefix: "F8-A0-97",vendor: "Commscope"},
  {max_prefix: "00-0E-5C",vendor: "Commscope"},
  {max_prefix: "40-0D-10",vendor: "Commscope"},
  {max_prefix: "64-02-CB",vendor: "Commscope"},
  {max_prefix: "F8-2D-C0",vendor: "Commscope"},
  {max_prefix: "18-9C-27",vendor: "Commscope"},
  {max_prefix: "B8-16-19",vendor: "Commscope"},
  {max_prefix: "C0-C5-22",vendor: "Commscope"},
  {max_prefix: "5C-E3-0E",vendor: "Commscope"},
  {max_prefix: "48-D3-43",vendor: "Commscope"},
  {max_prefix: "A4-ED-4E",vendor: "Commscope"},
  {max_prefix: "00-26-41",vendor: "Commscope"},
  {max_prefix: "00-24-93",vendor: "Commscope"},
  {max_prefix: "00-21-80",vendor: "Commscope"},
  {max_prefix: "00-21-1E",vendor: "Commscope"},
  {max_prefix: "B0-77-AC",vendor: "Commscope"},
  {max_prefix: "70-B1-4E",vendor: "Commscope"},
  {max_prefix: "78-58-44",vendor: "Hangzhou Sciener Smart Technology Co., Ltd."},
  {max_prefix: "70-DF-F7",vendor: "Commscope"},
  {max_prefix: "64-ED-57",vendor: "Commscope"},
  {max_prefix: "F8-63-D9",vendor: "Commscope"},
  {max_prefix: "A4-05-D6",vendor: "Commscope"},
  {max_prefix: "DC-A6-33",vendor: "Commscope"},
  {max_prefix: "28-15-A4",vendor: "SHENZHEN PINSU ZHILIAN INFORMATION TECHNOLOGY CO.,LTD."},
  {max_prefix: "AC-F8-CC",vendor: "Commscope"},
  {max_prefix: "8C-5A-25",vendor: "Commscope"},
  {max_prefix: "78-71-9C",vendor: "Commscope"},
  {max_prefix: "6C-A6-04",vendor: "Commscope"},
  {max_prefix: "10-93-97",vendor: "Commscope"},
  {max_prefix: "50-75-F1",vendor: "Commscope"},
  {max_prefix: "04-34-C3",vendor: "Qingdao Goertek  Horizons Tecnology  Co.,LTD"},
  {max_prefix: "D4-AB-82",vendor: "Commscope"},
  {max_prefix: "70-4F-B8",vendor: "Commscope"},
  {max_prefix: "40-4C-77",vendor: "Commscope"},
  {max_prefix: "A8-97-CD",vendor: "Commscope"},
  {max_prefix: "0C-7F-B2",vendor: "Commscope"},
  {max_prefix: "68-33-EE",vendor: "Commscope"},
  {max_prefix: "FC-4E-6D",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "F4-24-8B",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "44-E5-9B",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "0C-8B-A2",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "7C-36-26",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "50-B0-3B",vendor: "Sony Interactive Entertainment Inc."},
  {max_prefix: "54-DD-4F",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "74-F6-7A",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "A0-56-2C",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "A0-B0-BD",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "C8-53-09",vendor: "LCFC(Hefei) Electronics Technology co., ltd"},
  {max_prefix: "78-A4-BA",vendor: "Marquardt India Pvt Ltd"},
  {max_prefix: "D4-E3-C5",vendor: "zte corporation"},
  {max_prefix: "04-83-08",vendor: "Espressif Inc."},
  {max_prefix: "1C-0B-8B",vendor: "Ubiquiti Inc"},
  {max_prefix: "58-0D-0D",vendor: "GREE ELECTRIC APPLIANCES, INC. OF ZHUHAI"},
  {max_prefix: "9C-BA-C9",vendor: "Telit Communication s.p.a"},
  {max_prefix: "2C-DF-E6",vendor: "Raisecom Technology CO., LTD"},
  {max_prefix: "D8-49-BF",vendor: "CELESTICA INC."},
  {max_prefix: "88-45-F0",vendor: "GUANGDONG GENIUS TECHNOLOGY CO., LTD."},
  {max_prefix: "1C-78-4B",vendor: "Bouffalo Lab (Nanjing) Co., Ltd."},
  {max_prefix: "34-F5-D7",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "DC-42-C8",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "80-3E-4F",vendor: "GD Midea Air-Conditioning Equipment Co.,Ltd."},
  {max_prefix: "94-A0-81",vendor: "Silicon Laboratories"},
  {max_prefix: "A0-77-71",vendor: "Vialis BV"},
  {max_prefix: "7C-B8-E6",vendor: "GREE ELECTRIC APPLIANCES, INC. OF ZHUHAI"},
  {max_prefix: "A4-C3-4E",vendor: "Texas Instruments"},
  {max_prefix: "D4-29-A7",vendor: "Infinix mobility limited"},
  {max_prefix: "D8-F1-D8",vendor: "OI ELECTRIC CO.,LTD"},
  {max_prefix: "74-29-81",vendor: "Texas Instruments"},
  {max_prefix: "18-DF-26",vendor: "INGRAM MICRO SERVICES"},
  {max_prefix: "80-F4-16",vendor: "Chipsea Technologies (Shenzhen) Corp."},
  {max_prefix: "84-96-90",vendor: "Murata Manufacturing Co., Ltd."},
  {max_prefix: "0C-7D-B0",vendor: "Motorola Mobility LLC, a Lenovo Company"},
  {max_prefix: "80-79-33",vendor: "Aigentec Technology(Zhejiang) Co., Ltd."},
  {max_prefix: "D0-5F-AF",vendor: "Shenzhen C-Data Technology Co., Ltd."},
  {max_prefix: "D0-85-43",vendor: "Cisco Systems, Inc"},
  {max_prefix: "50-56-A8",vendor: "Jollyboys Ltd"},
  {max_prefix: "B0-18-31",vendor: "Apple, Inc."},
  {max_prefix: "D0-4D-86",vendor: "Apple, Inc."},
  {max_prefix: "34-D5-09",vendor: "Genexis B.V."},
  {max_prefix: "20-35-43",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "68-67-C7",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "F4-B5-49",vendor: "Xiamen Yeastar Digital Technology Co., Ltd"},
  {max_prefix: "F4-CB-E7",vendor: "Apple, Inc."},
  {max_prefix: "5C-9B-A6",vendor: "Apple, Inc."},
  {max_prefix: "38-C4-3A",vendor: "Apple, Inc."},
  {max_prefix: "A8-5B-D1",vendor: "INVENTEC CORPORATION"},
  {max_prefix: "BC-07-1D",vendor: "TP-Link Systems Inc."},
  {max_prefix: "BC-5E-91",vendor: "Honor Device Co., Ltd."},
  {max_prefix: "00-1A-01",vendor: "ICU Medical, Inc."},
  {max_prefix: "2C-E0-99",vendor: "FUJIAN STAR-NET COMMUNICATION CO.,LTD"},
  {max_prefix: "58-D5-33",vendor: "Huaqin Technology Co.,Ltd"},
  {max_prefix: "D8-74-DF",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "78-DA-AF",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "BC-A2-31",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "5C-7D-AE",vendor: "zte corporation"},
  {max_prefix: "7C-B6-8D",vendor: "Mist Systems, Inc."},
  {max_prefix: "A4-59-D3",vendor: "IEEE Registration Authority"},
  {max_prefix: "00-9C-17",vendor: "Quectel Wireless Solutions Co.,Ltd."},
  {max_prefix: "0C-C8-44",vendor: "Cambridge Mobile Telematics, Inc."},
  {max_prefix: "50-13-65",vendor: "Vola Networks Inc."},
  {max_prefix: "3C-6A-D2",vendor: "TP-Link Systems Inc."},
  {max_prefix: "EC-E3-34",vendor: "Espressif Inc."},
  {max_prefix: "B8-17-43",vendor: "shenzhen worldelite electronics co., LTD"},
  {max_prefix: "A0-C0-16",vendor: "Sichuan Changhong Network Technologies Co., Ltd."},
  {max_prefix: "18-0B-1B",vendor: "Amazon Technologies Inc."},
  {max_prefix: "A4-7F-1B",vendor: "Juniper Networks"},
  {max_prefix: "84-F7-58",vendor: "Huizhou BYD Electronic Co., Ltd."},
  {max_prefix: "78-B3-9F",vendor: "SERNET (SUZHOU) TECHNOLOGIES CORPORATION"},
  {max_prefix: "0C-E0-FC",vendor: "Edgecore Americas Networking Corporation"},
  {max_prefix: "D4-E5-C9",vendor: "Senao Networks Inc."},
  {max_prefix: "9C-4B-6B",vendor: "iFlight Technology Company Limited"},
  {max_prefix: "D8-74-EF",vendor: "Guangzhou Shiyuan Electronic Technology Company Limited"},
  {max_prefix: "10-91-66",vendor: "Shenzhen Yinwang Intelligent Technologies Co.,Ltd."},
  {max_prefix: "80-CA-52",vendor: "Wistron InfoComm(Chongqing)Co.,Ltd."},
  {max_prefix: "4C-49-68",vendor: "Ruijie Networks Co.,LTD"},
  {max_prefix: "F0-03-BC",vendor: "Cisco Systems, Inc"},
  {max_prefix: "40-70-A5",vendor: "Sichuan Tianyi Comheart Telecom Co.,LTD"},
  {max_prefix: "0C-B7-8E",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "68-4C-25",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "10-0D-8C",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "74-1B-39",vendor: "CONVEY INDIA PRIVATE LIMITED"},
  {max_prefix: "D4-5B-51",vendor: "Motorola Mobility LLC, a Lenovo Company"},
  {max_prefix: "28-92-00",vendor: "Intel Corporate"},
  {max_prefix: "88-60-78",vendor: "Sparnex n.v."},
  {max_prefix: "78-F7-A3",vendor: "Opentext"},
  {max_prefix: "80-05-1F",vendor: "Vizio, Inc"},
  {max_prefix: "A8-29-48",vendor: "TP-Link Systems Inc."},
  {max_prefix: "30-BB-43",vendor: "Sixi Networks Co., Ltd"},
  {max_prefix: "E4-82-FF",vendor: "Ecliptic Enterprises Corp"},
  {max_prefix: "00-0C-AB",vendor: "Commend International GmbH"},
  {max_prefix: "D4-AB-61",vendor: "Intel Corporate"},
  {max_prefix: "B8-F7-75",vendor: "Intel Corporate"},
  {max_prefix: "E0-D3-62",vendor: "TP-Link Systems Inc."},
  {max_prefix: "24-65-E1",vendor: "Ciena Corporation"},
  {max_prefix: "7C-BF-77",vendor: "SPEEDTECH CORP."},
  {max_prefix: "18-41-C3",vendor: "GD Midea Air-Conditioning Equipment Co.,Ltd."},
  {max_prefix: "30-63-CF",vendor: "Zhejiang Cainiao Supply Chain Management Co., Ltd"},
  {max_prefix: "A4-09-87",vendor: "Apple, Inc."},
  {max_prefix: "4C-9F-F1",vendor: "Apple, Inc."},
  {max_prefix: "40-79-11",vendor: "Apple, Inc."},
  {max_prefix: "C0-C7-DB",vendor: "Apple, Inc."},
  {max_prefix: "F8-3D-C6",vendor: "AzureWave Technology Inc."},
  {max_prefix: "98-3C-8C",vendor: "Apple, Inc."},
  {max_prefix: "00-0C-43",vendor: "MediaTek Inc"},
  {max_prefix: "24-CB-E1",vendor: "Ericsson AB"},
  {max_prefix: "D4-19-72",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "B8-C0-51",vendor: "VusionGroup"},
  {max_prefix: "A4-EA-4F",vendor: "VusionGroup"},
  {max_prefix: "FC-93-1D",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "1C-99-DB",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "B4-7B-1A",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "6C-41-DE",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "38-25-F3",vendor: "Mellanox Technologies, Inc."},
  {max_prefix: "8C-A5-CF",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "2C-BE-EE",vendor: "Nothing Technology Limited"},
  {max_prefix: "00-0C-E7",vendor: "MediaTek Inc"},
  {max_prefix: "00-40-31",vendor: "KOKUSAI DENKI Electric Inc."},
  {max_prefix: "B4-13-24",vendor: "Google, Inc."},
  {max_prefix: "74-E9-D8",vendor: "Shanghai High-Flying Electronics Technology Co.,Ltd"},
  {max_prefix: "E4-E3-3D",vendor: "Qingdao Intelligent&Precise Electronics Co.,Ltd."},
  {max_prefix: "BC-98-29",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "EC-35-32",vendor: "Tactrix Inc."},
  {max_prefix: "5C-89-E6",vendor: "Richard Wolf GmbH"},
  {max_prefix: "10-51-DB",vendor: "Espressif Inc."},
  {max_prefix: "B0-4F-3C",vendor: "Genuine Optics"},
  {max_prefix: "78-86-2E",vendor: "Microsoft Corporation"},
  {max_prefix: "14-85-01",vendor: "Rivos Inc."},
  {max_prefix: "9C-06-CF",vendor: "PLAUD Inc."},
  {max_prefix: "7C-67-AB",vendor: "Roku, Inc"},
  {max_prefix: "08-0F-E5",vendor: "Cisco Systems, Inc"},
  {max_prefix: "6C-CF-39",vendor: "Shanghai StarFive Semiconductor Co., Ltd."},
  {max_prefix: "08-4B-44",vendor: "Robert Bosch Elektronika Kft."},
  {max_prefix: "00-25-DF",vendor: "Axon Enterprise, Inc."},
  {max_prefix: "90-F9-64",vendor: "Rawasi Co"},
  {max_prefix: "60-4A-77",vendor: "Finder SpA"},
  {max_prefix: "FC-E1-A6",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "0C-07-F3",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "98-24-7B",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "58-83-36",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "BC-A6-8D",vendor: "Continetal Automotive Systems Sibiu"},
  {max_prefix: "2C-DA-3F",vendor: "DongGuan Ramaxel Memory Technology Limited"},
  {max_prefix: "80-4A-F2",vendor: "Sonos, Inc."},
  {max_prefix: "00-01-CC",vendor: "Brand Maker Enabler Inc."},
  {max_prefix: "30-F0-28",vendor: "Bosch Sicherheitssysteme GmbH"},
  {max_prefix: "50-78-7D",vendor: "Espressif Inc."},
  {max_prefix: "34-38-39",vendor: "NEC Platforms, Ltd."},
  {max_prefix: "A0-9A-52",vendor: "Shenzhen MoreSense Technology Co., Ltd."},
  {max_prefix: "C8-5C-E2",vendor: "IEEE Registration Authority"},
  {max_prefix: "DC-EE-14",vendor: "ADT Technology"},
  {max_prefix: "10-FF-E0",vendor: "GIGA-BYTE TECHNOLOGY CO.,LTD."},
  {max_prefix: "18-49-F8",vendor: "Extreme Networks Headquarters"},
  {max_prefix: "E8-4A-54",vendor: "Beijing Xiaomi Mobile Software Co., Ltd"},
  {max_prefix: "2C-AB-46",vendor: "Ruckus Wireless"},
  {max_prefix: "D8-84-66",vendor: "Extreme Networks Headquarters"},
  {max_prefix: "00-04-96",vendor: "Extreme Networks Headquarters"},
  {max_prefix: "00-E0-2B",vendor: "Extreme Networks Headquarters"},
  {max_prefix: "5C-0E-8B",vendor: "Extreme Networks Headquarters"},
  {max_prefix: "74-67-F7",vendor: "Extreme Networks Headquarters"},
  {max_prefix: "4C-23-1A",vendor: "Extreme Networks Headquarters"},
  {max_prefix: "58-58-CD",vendor: "Extreme Networks Headquarters"},
  {max_prefix: "DC-DC-C3",vendor: "Extreme Networks Headquarters"},
  {max_prefix: "C8-BE-35",vendor: "Extreme Networks Headquarters"},
  {max_prefix: "F0-64-26",vendor: "Extreme Networks Headquarters"},
  {max_prefix: "F4-54-24",vendor: "Extreme Networks Headquarters"},
  {max_prefix: "F0-2B-7C",vendor: "Extreme Networks Headquarters"},
  {max_prefix: "7C-95-B1",vendor: "Extreme Networks Headquarters"},
  {max_prefix: "58-59-C2",vendor: "Extreme Networks Headquarters"},
  {max_prefix: "78-7D-53",vendor: "Extreme Networks Headquarters"},
  {max_prefix: "20-9E-F7",vendor: "Extreme Networks Headquarters"},
  {max_prefix: "80-95-62",vendor: "Extreme Networks Headquarters"},
  {max_prefix: "90-A1-BA",vendor: "PNetworks Electronics Information"},
  {max_prefix: "00-0A-D7",vendor: "Origin Co., Ltd."},
  {max_prefix: "A0-D8-6F",vendor: "ARGO AI, LLC"},
  {max_prefix: "B4-D7-DB",vendor: "New H3C Technologies Co., Ltd"},
  {max_prefix: "30-50-CE",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "B4-05-A1",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "28-85-BB",vendor: "Zen Exim Pvt. Ltd."},
  {max_prefix: "F8-C6-50",vendor: "Cisco Systems, Inc"},
  {max_prefix: "3C-10-60",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "B4-56-5D",vendor: "Chipsea Technologies (Shenzhen) Corp."},
  {max_prefix: "4C-E1-36",vendor: "Private"},
  {max_prefix: "00-84-ED",vendor: "LEXMARK INTERNATIONAL, INC."},
  {max_prefix: "C0-5D-39",vendor: "Jiangsu Huitong Group Co.,Ltd."},
  {max_prefix: "74-24-9F",vendor: "TIBRO Corp."},
  {max_prefix: "64-85-05",vendor: "zte corporation"},
  {max_prefix: "E4-60-4D",vendor: "zte corporation"},
  {max_prefix: "F4-E8-4F",vendor: "zte corporation"},
  {max_prefix: "C8-78-67",vendor: "Mist Systems, Inc."},
  {max_prefix: "E8-22-B8",vendor: "Shenzhen Skyworth Digital  Technology  CO., Ltd"},
  {max_prefix: "74-4D-BD",vendor: "Espressif Inc."},
  {max_prefix: "00-F9-52",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "2C-15-D9",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "D4-A9-23",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "B8-10-D4",vendor: "Masimo Corporation"},
  {max_prefix: "C8-9F-0C",vendor: "Motorola Mobility LLC, a Lenovo Company"},
  {max_prefix: "28-E6-A9",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "DC-2D-04",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "A4-3C-D7",vendor: "NTX Electronics YangZhou co.,LTD"},
  {max_prefix: "00-02-63",vendor: "RPS S.p.A."},
  {max_prefix: "48-35-43",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "4C-5F-70",vendor: "Intel Corporate"},
  {max_prefix: "60-B9-C0",vendor: "Cisco Systems, Inc"},
  {max_prefix: "8C-94-61",vendor: "Cisco Systems, Inc"},
  {max_prefix: "58-07-F8",vendor: "Nokia Solutions and Networks GmbH & Co. KG"},
  {max_prefix: "DC-65-55",vendor: "New H3C Intelligence Terminal Co., Ltd."},
  {max_prefix: "34-6F-71",vendor: "TenaFe Inc."},
  {max_prefix: "54-91-AF",vendor: "IEEE Registration Authority"},
  {max_prefix: "9C-39-28",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "BC-0E-AB",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "98-FB-27",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "A8-0B-FB",vendor: "Ruckus Wireless"},
  {max_prefix: "3C-FA-30",vendor: "Palo Alto Networks"},
  {max_prefix: "CC-EC-B7",vendor: "ShenZhen Linked-Z Intelligent Display Co., Ltd"},
  {max_prefix: "DC-DA-0C",vendor: "Espressif Inc."},
  {max_prefix: "A8-B8-E0",vendor: "Changwang Technology inc."},
  {max_prefix: "14-CA-56",vendor: "zte corporation"},
  {max_prefix: "48-D3-5D",vendor: "Private"},
  {max_prefix: "54-33-C6",vendor: "Mist Systems, Inc."},
  {max_prefix: "CC-93-4A",vendor: "Sierra Wireless, ULC"},
  {max_prefix: "00-A0-D5",vendor: "Sierra Wireless, ULC"},
  {max_prefix: "28-A3-31",vendor: "Sierra Wireless, ULC"},
  {max_prefix: "6C-22-1A",vendor: "AltoBeam Inc."},
  {max_prefix: "D8-43-AE",vendor: "Micro-Star INTL CO., LTD."},
  {max_prefix: "A8-43-A4",vendor: "China Dragon Technology Limited"},
  {max_prefix: "D8-09-4E",vendor: "Active Brains"},
  {max_prefix: "00-D0-7C",vendor: "JTEKT ELECTRONICS CORPORATION"},
  {max_prefix: "88-6C-60",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "E0-DF-13",vendor: "Hangzhou Hikvision Digital Technology Co.,Ltd."},
  {max_prefix: "B0-18-86",vendor: "SmarDTV Corporation"},
  {max_prefix: "A4-22-49",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "5C-E9-31",vendor: "TP-Link Corporation Limited"},
  {max_prefix: "C8-A6-EF",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "68-79-09",vendor: "Cisco Systems, Inc"},
  {max_prefix: "E4-A4-1C",vendor: "Cisco Systems, Inc"},
  {max_prefix: "B4-0A-D8",vendor: "Sony Interactive Entertainment Inc."},
  {max_prefix: "04-80-A7",vendor: "ShenZhen TianGang Micro Technology CO.LTD"},
  {max_prefix: "38-9C-B2",vendor: "Apple, Inc."},
  {max_prefix: "58-36-53",vendor: "Apple, Inc."},
  {max_prefix: "84-D3-28",vendor: "Apple, Inc."},
  {max_prefix: "50-40-74",vendor: "Alarm.com"},
  {max_prefix: "5C-7B-5C",vendor: "Shenzhen SDMC Technology CO.,Ltd."},
  {max_prefix: "40-79-12",vendor: "Texas Instruments"},
  {max_prefix: "58-A1-5F",vendor: "Texas Instruments"},
  {max_prefix: "10-CA-BF",vendor: "Texas Instruments"},
  {max_prefix: "98-52-4A",vendor: "Vantiva USA LLC"},
  {max_prefix: "A0-FF-70",vendor: "Vantiva USA LLC"},
  {max_prefix: "5C-7D-7D",vendor: "Vantiva USA LLC"},
  {max_prefix: "08-A7-C0",vendor: "Vantiva USA LLC"},
  {max_prefix: "8C-6A-8D",vendor: "Vantiva USA LLC"},
  {max_prefix: "1C-9E-CC",vendor: "Vantiva USA LLC"},
  {max_prefix: "CC-1A-A3",vendor: "Arista Networks"},
  {max_prefix: "3C-C0-3E",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "FC-94-E3",vendor: "Vantiva USA LLC"},
  {max_prefix: "8C-04-FF",vendor: "Vantiva USA LLC"},
  {max_prefix: "CC-35-40",vendor: "Vantiva USA LLC"},
  {max_prefix: "B0-C2-87",vendor: "Vantiva USA LLC"},
  {max_prefix: "BC-9B-68",vendor: "Vantiva USA LLC"},
  {max_prefix: "80-D0-4A",vendor: "Vantiva USA LLC"},
  {max_prefix: "3C-B7-4B",vendor: "Vantiva USA LLC"},
  {max_prefix: "FC-91-14",vendor: "Vantiva USA LLC"},
  {max_prefix: "50-09-59",vendor: "Vantiva USA LLC"},
  {max_prefix: "38-17-E1",vendor: "Vantiva USA LLC"},
  {max_prefix: "40-75-C3",vendor: "Vantiva USA LLC"},
  {max_prefix: "10-A7-93",vendor: "Vantiva USA LLC"},
  {max_prefix: "B8-A5-35",vendor: "Vantiva USA LLC"},
  {max_prefix: "98-39-C0",vendor: "FLEXTRONICS"},
  {max_prefix: "8C-CB-14",vendor: "TBS GmbH"},
  {max_prefix: "BC-5C-17",vendor: "Qingdao Intelligent&Precise Electronics Co.,Ltd."},
  {max_prefix: "74-B8-0F",vendor: "Zipline International Inc."},
  {max_prefix: "54-5F-A7",vendor: "Jibaiyou Technology Co.,Ltd."},
  {max_prefix: "08-BF-B8",vendor: "ASUSTek COMPUTER INC."},
  {max_prefix: "00-30-44",vendor: "CradlePoint, Inc"},
  {max_prefix: "00-E0-1C",vendor: "CradlePoint, Inc"},
  {max_prefix: "58-D2-37",vendor: "Sichuan Tianyi Comheart Telecom Co.,LTD"},
  {max_prefix: "BC-0F-F3",vendor: "HP Inc."},
  {max_prefix: "20-BB-BC",vendor: "Hangzhou Ezviz Software Co.,Ltd."},
  {max_prefix: "64-B3-79",vendor: "Jiangsu Viscore Technologies Co.,Ltd"},
  {max_prefix: "24-FD-FA",vendor: "Private"},
  {max_prefix: "D4-31-27",vendor: "Ruijie Networks Co.,LTD"},
  {max_prefix: "EC-EF-17",vendor: "Sunplus Technology Co., Ltd."},
  {max_prefix: "4C-C6-4C",vendor: "Beijing Xiaomi Mobile Software Co., Ltd"},
  {max_prefix: "90-E4-68",vendor: "Guangzhou Shiyuan Electronic Technology Company Limited"},
  {max_prefix: "EC-0C-96",vendor: "Nokia"},
  {max_prefix: "A0-77-9E",vendor: "Chipsea Technologies (Shenzhen) Corp."},
  {max_prefix: "90-D0-92",vendor: "HUMAX Co., Ltd."},
  {max_prefix: "84-30-CE",vendor: "Shenzhen Jaguar Microsystems Co., Ltd"},
  {max_prefix: "70-D8-C2",vendor: "Intel Corporate"},
  {max_prefix: "44-59-25",vendor: "Square Inc."},
  {max_prefix: "E0-21-FE",vendor: "Richer Link Technologies CO.,LTD"},
  {max_prefix: "C8-A3-62",vendor: "ASIX Electronics Corporation"},
  {max_prefix: "34-D8-56",vendor: "Shenzhen Skyworth Digital  Technology  CO., Ltd"},
  {max_prefix: "18-44-CF",vendor: "B+L Industrial Measurements GmbH"},
  {max_prefix: "CC-57-63",vendor: "Panasonic Automotive Systems Co.,Ltd"},
  {max_prefix: "88-2F-64",vendor: "BCOM Networks Limited"},
  {max_prefix: "BC-87-FA",vendor: "Bose Corporation"},
  {max_prefix: "B8-1E-A4",vendor: "Liteon Technology Corporation"},
  {max_prefix: "68-47-24",vendor: "EM Microelectronic"},
  {max_prefix: "AC-3B-96",vendor: "NXP Semiconductor (Tianjin) LTD."},
  {max_prefix: "0C-67-14",vendor: "SERNET (SUZHOU) TECHNOLOGIES CORPORATION"},
  {max_prefix: "48-5F-08",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "F8-6F-B0",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "90-F8-91",vendor: "Kaon Group Co., Ltd."},
  {max_prefix: "C8-A6-08",vendor: "Ruckus Wireless"},
  {max_prefix: "98-77-E7",vendor: "Kaon Group Co., Ltd."},
  {max_prefix: "84-01-12",vendor: "Kaon Group Co., Ltd."},
  {max_prefix: "18-34-AF",vendor: "Kaon Group Co., Ltd."},
  {max_prefix: "34-47-D4",vendor: "Chengdu Quanjing Intelligent Technology Co.,Ltd"},
  {max_prefix: "64-FB-01",vendor: "Zhongshan Camry Electronic Company Limited"},
  {max_prefix: "38-F6-CF",vendor: "zte corporation"},
  {max_prefix: "78-2A-F8",vendor: "IETHCOM INFORMATION TECHNOLOGY CO., LTD."},
  {max_prefix: "74-49-D2",vendor: "New H3C Technologies Co., Ltd"},
  {max_prefix: "DC-D2-6A",vendor: "Hangzhou Hikvision Digital Technology Co.,Ltd."},
  {max_prefix: "AC-D8-A7",vendor: "BELLDESIGN Inc."},
  {max_prefix: "0C-87-72",vendor: "FUJIAN STAR-NET COMMUNICATION CO.,LTD"},
  {max_prefix: "DC-8D-91",vendor: "Infinix mobility limited"},
  {max_prefix: "40-9A-30",vendor: "TECNO MOBILE LIMITED"},
  {max_prefix: "90-F4-21",vendor: "IEEE Registration Authority"},
  {max_prefix: "24-23-61",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "D4-27-87",vendor: "Shanghai High-Flying Electronics  Technology Co., Ltd"},
  {max_prefix: "34-D4-E3",vendor: "Atom Power, Inc."},
  {max_prefix: "90-6D-62",vendor: "Cambium Networks Limited"},
  {max_prefix: "30-B2-9F",vendor: "EVIDENT CORPORATION"},
  {max_prefix: "24-DC-C3",vendor: "Espressif Inc."},
  {max_prefix: "F8-24-DB",vendor: "EntryPoint Networks, Inc"},
  {max_prefix: "B4-4D-43",vendor: "IEEE Registration Authority"},
  {max_prefix: "F8-C4-AE",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "C4-A8-16",vendor: "eero inc."},
  {max_prefix: "34-F0-43",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "4C-66-A6",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "48-45-CF",vendor: "LLC Proizvodstvennaya Kompania TransService"},
  {max_prefix: "8C-02-CD",vendor: "FUJIAN STAR-NET COMMUNICATION CO.,LTD"},
  {max_prefix: "54-F8-F0",vendor: "Tesla Inc"},
  {max_prefix: "74-54-6B",vendor: "hangzhou zhiyi communication co., ltd"},
  {max_prefix: "D8-D4-5D",vendor: "Orbic North America"},
  {max_prefix: "0C-29-8F",vendor: "Tesla,Inc."},
  {max_prefix: "20-B8-2B",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "14-18-44",vendor: "Xenon Smart Teknoloji Ltd."},
  {max_prefix: "A0-66-36",vendor: "Intracom SA Telecom Solutions"},
  {max_prefix: "58-43-AB",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "58-56-9F",vendor: "Cisco Systems, Inc"},
  {max_prefix: "A8-2A-D6",vendor: "Arthrex Inc."},
  {max_prefix: "E4-8E-BB",vendor: "Rockwell Automation"},
  {max_prefix: "50-E5-38",vendor: "Hangzhou Hikvision Digital Technology Co.,Ltd."},
  {max_prefix: "F4-AA-D0",vendor: "OHSUNG"},
  {max_prefix: "FC-48-C9",vendor: "Yobiiq Intelligence B.V."},
  {max_prefix: "64-1C-10",vendor: "Texas Instruments"},
  {max_prefix: "24-BF-74",vendor: "Hamamatsu Photonics K.K."},
  {max_prefix: "08-33-ED",vendor: "ASKEY COMPUTER CORP"},
  {max_prefix: "C0-17-54",vendor: "Apple, Inc."},
  {max_prefix: "BC-33-40",vendor: "Cisco Meraki"},
  {max_prefix: "00-12-27",vendor: "Franklin Electric Co., Inc."},
  {max_prefix: "AC-1A-3D",vendor: "Dell Inc."},
  {max_prefix: "F4-9D-A7",vendor: "Private"},
  {max_prefix: "E8-4C-4A",vendor: "Amazon Technologies Inc."},
  {max_prefix: "74-8F-4D",vendor: "duagon Germany GmbH"},
  {max_prefix: "00-C0-3A",vendor: "duagon Germany GmbH"},
  {max_prefix: "2C-64-F6",vendor: "Wu Qi Technologies,Inc."},
  {max_prefix: "88-68-4B",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "C8-F2-25",vendor: "EM Microelectronic"},
  {max_prefix: "78-53-0D",vendor: "Shenzhen Skyworth Digital  Technology  CO., Ltd"},
  {max_prefix: "78-5F-36",vendor: "Shenzhen Skyworth Digital  Technology  CO., Ltd"},
  {max_prefix: "94-7F-D8",vendor: "Shenzhen Skyworth Digital  Technology  CO., Ltd"},
  {max_prefix: "20-89-8A",vendor: "Shenzhen Skyworth Digital  Technology  CO., Ltd"},
  {max_prefix: "18-AA-1E",vendor: "Shenzhen Skyworth Digital  Technology  CO., Ltd"},
  {max_prefix: "FC-7A-58",vendor: "Shenzhen Skyworth Digital  Technology  CO., Ltd"},
  {max_prefix: "E8-8F-C4",vendor: "MOBIWIRE MOBILES(NINGBO) CO.,LTD"},
  {max_prefix: "00-A0-85",vendor: "Private"},
  {max_prefix: "FC-2C-FD",vendor: "dormakaba Canada Inc. - Keyscan"},
  {max_prefix: "4C-9B-63",vendor: "LG Innotek"},
  {max_prefix: "64-8F-3E",vendor: "Cisco Systems, Inc"},
  {max_prefix: "CC-B6-C8",vendor: "Cisco Systems, Inc"},
  {max_prefix: "18-FB-7B",vendor: "Dell Inc."},
  {max_prefix: "1C-40-24",vendor: "Dell Inc."},
  {max_prefix: "14-18-77",vendor: "Dell Inc."},
  {max_prefix: "E0-DB-55",vendor: "Dell Inc."},
  {max_prefix: "F0-4D-A2",vendor: "Dell Inc."},
  {max_prefix: "84-2B-2B",vendor: "Dell Inc."},
  {max_prefix: "EC-2C-49",vendor: "NakaoLab, The University of Tokyo"},
  {max_prefix: "24-6E-96",vendor: "Dell Inc."},
  {max_prefix: "00-06-5B",vendor: "Dell Inc."},
  {max_prefix: "B8-AC-6F",vendor: "Dell Inc."},
  {max_prefix: "00-21-9B",vendor: "Dell Inc."},
  {max_prefix: "00-21-70",vendor: "Dell Inc."},
  {max_prefix: "00-1E-C9",vendor: "Dell Inc."},
  {max_prefix: "34-E6-D7",vendor: "Dell Inc."},
  {max_prefix: "74-E6-E2",vendor: "Dell Inc."},
  {max_prefix: "24-B6-FD",vendor: "Dell Inc."},
  {max_prefix: "00-0F-1F",vendor: "Dell Inc."},
  {max_prefix: "14-9E-CF",vendor: "Dell Inc."},
  {max_prefix: "48-4D-7E",vendor: "Dell Inc."},
  {max_prefix: "6C-D6-E3",vendor: "Cisco Systems, Inc"},
  {max_prefix: "D4-AE-52",vendor: "Dell Inc."},
  {max_prefix: "F8-B1-56",vendor: "Dell Inc."},
  {max_prefix: "AC-3D-94",vendor: "Arista Networks"},
  {max_prefix: "CC-96-E5",vendor: "Dell Inc."},
  {max_prefix: "3C-46-A1",vendor: "Ruckus Wireless"},
  {max_prefix: "AC-12-8E",vendor: "Shanghai Baud Data Communication Co.,Ltd."},
  {max_prefix: "EC-ED-73",vendor: "Motorola Mobility LLC, a Lenovo Company"},
  {max_prefix: "78-AC-44",vendor: "Dell Inc."},
  {max_prefix: "C0-3E-BA",vendor: "Dell Inc."},
  {max_prefix: "74-78-27",vendor: "Dell Inc."},
  {max_prefix: "B0-7B-25",vendor: "Dell Inc."},
  {max_prefix: "B8-85-84",vendor: "Dell Inc."},
  {max_prefix: "E4-B9-7A",vendor: "Dell Inc."},
  {max_prefix: "68-4F-64",vendor: "Dell Inc."},
  {max_prefix: "D8-D0-90",vendor: "Dell Inc."},
  {max_prefix: "1C-72-1D",vendor: "Dell Inc."},
  {max_prefix: "0C-29-EF",vendor: "Dell Inc."},
  {max_prefix: "A0-29-19",vendor: "Dell Inc."},
  {max_prefix: "58-8A-5A",vendor: "Dell Inc."},
  {max_prefix: "EC-C0-18",vendor: "Cisco Systems, Inc"},
  {max_prefix: "74-8F-C2",vendor: "Cisco Systems, Inc"},
  {max_prefix: "64-E0-AB",vendor: "UNION MAN TECHNOLOGY CO.,LTD"},
  {max_prefix: "00-19-F0",vendor: "UNION MAN TECHNOLOGY CO.,LTD"},
  {max_prefix: "A0-1C-87",vendor: "UNION MAN TECHNOLOGY CO.,LTD"},
  {max_prefix: "40-F4-FD",vendor: "UNION MAN TECHNOLOGY CO.,LTD"},
  {max_prefix: "18-84-C1",vendor: "Guangzhou Shiyuan Electronic Technology Company Limited"},
  {max_prefix: "D0-16-F0",vendor: "IEEE Registration Authority"},
  {max_prefix: "B0-44-9C",vendor: "Assa Abloy AB - Yale"},
  {max_prefix: "60-2A-1B",vendor: "JANCUS"},
  {max_prefix: "68-85-6A",vendor: "OuterLink Corporation"},
  {max_prefix: "E4-12-89",vendor: "topsystem GmbH"},
  {max_prefix: "30-E1-F1",vendor: "Intelbras"},
  {max_prefix: "D8-24-EC",vendor: "Plenom A/S"},
  {max_prefix: "10-12-D0",vendor: "zte corporation"},
  {max_prefix: "44-A3-C7",vendor: "zte corporation"},
  {max_prefix: "00-1D-9C",vendor: "Rockwell Automation"},
  {max_prefix: "D0-19-D3",vendor: "ITEL MOBILE LIMITED"},
  {max_prefix: "CC-AC-FE",vendor: "Telink Semiconductor (Shanghai) Co., Ltd."},
  {max_prefix: "88-6E-DD",vendor: "Micronet union Technology(Chengdu)Co., Ltd."},
  {max_prefix: "80-F3-EF",vendor: "Meta Platforms Technologies, LLC"},
  {max_prefix: "D8-B2-49",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "C4-9D-08",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "4C-5E-D3",vendor: "Unisyue Technologies Co; LTD."},
  {max_prefix: "A0-FF-0C",vendor: "Hangzhou Hikvision Digital Technology Co.,Ltd."},
  {max_prefix: "08-54-11",vendor: "Hangzhou Hikvision Digital Technology Co.,Ltd."},
  {max_prefix: "74-3F-C2",vendor: "Hangzhou Hikvision Digital Technology Co.,Ltd."},
  {max_prefix: "A4-D5-C2",vendor: "Hangzhou Hikvision Digital Technology Co.,Ltd."},
  {max_prefix: "F4-00-46",vendor: "ON Semiconductor"},
  {max_prefix: "80-43-3F",vendor: "Juniper Networks"},
  {max_prefix: "C4-2F-90",vendor: "Hangzhou Hikvision Digital Technology Co.,Ltd."},
  {max_prefix: "54-C4-15",vendor: "Hangzhou Hikvision Digital Technology Co.,Ltd."},
  {max_prefix: "B4-A3-82",vendor: "Hangzhou Hikvision Digital Technology Co.,Ltd."},
  {max_prefix: "68-6D-BC",vendor: "Hangzhou Hikvision Digital Technology Co.,Ltd."},
  {max_prefix: "08-A1-89",vendor: "Hangzhou Hikvision Digital Technology Co.,Ltd."},
  {max_prefix: "44-A6-42",vendor: "Hangzhou Hikvision Digital Technology Co.,Ltd."},
  {max_prefix: "5C-4D-BF",vendor: "zte corporation"},
  {max_prefix: "98-47-44",vendor: "Shenzhen Boomtech Industrial Corporation"},
  {max_prefix: "48-38-B6",vendor: "Auhui Taoyun Technology Co., Ltd"},
  {max_prefix: "AC-89-D2",vendor: "Ciena Corporation"},
  {max_prefix: "E0-56-94",vendor: "Yunhight Microelectronics"},
  {max_prefix: "24-27-30",vendor: "GD Midea Air-Conditioning Equipment Co.,Ltd."},
  {max_prefix: "54-11-59",vendor: "Nettrix Information Industry co.LTD"},
  {max_prefix: "BC-C4-27",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "00-03-56",vendor: "Diebold Nixdorf"},
  {max_prefix: "00-15-6D",vendor: "Ubiquiti Inc"},
  {max_prefix: "00-27-22",vendor: "Ubiquiti Inc"},
  {max_prefix: "DC-9F-DB",vendor: "Ubiquiti Inc"},
  {max_prefix: "B0-CF-CB",vendor: "Amazon Technologies Inc."},
  {max_prefix: "18-E8-29",vendor: "Ubiquiti Inc"},
  {max_prefix: "74-AC-B9",vendor: "Ubiquiti Inc"},
  {max_prefix: "F4-92-BF",vendor: "Ubiquiti Inc"},
  {max_prefix: "68-D7-9A",vendor: "Ubiquiti Inc"},
  {max_prefix: "00-30-52",vendor: "DZS Inc."},
  {max_prefix: "50-45-94",vendor: "Radisys"},
  {max_prefix: "80-71-1F",vendor: "Juniper Networks"},
  {max_prefix: "64-B7-08",vendor: "Espressif Inc."},
  {max_prefix: "E8-A2-45",vendor: "Juniper Networks"},
  {max_prefix: "84-03-28",vendor: "Juniper Networks"},
  {max_prefix: "F4-BF-A8",vendor: "Juniper Networks"},
  {max_prefix: "C8-FE-6A",vendor: "Juniper Networks"},
  {max_prefix: "FC-96-43",vendor: "Juniper Networks"},
  {max_prefix: "08-05-E2",vendor: "Juniper Networks"},
  {max_prefix: "68-F3-8E",vendor: "Juniper Networks"},
  {max_prefix: "88-28-FB",vendor: "Juniper Networks"},
  {max_prefix: "E8-24-A6",vendor: "Juniper Networks"},
  {max_prefix: "B4-98-82",vendor: "Brusa HyPower AG"},
  {max_prefix: "88-E0-F3",vendor: "Juniper Networks"},
  {max_prefix: "F8-C0-01",vendor: "Juniper Networks"},
  {max_prefix: "A8-D0-E5",vendor: "Juniper Networks"},
  {max_prefix: "54-E0-32",vendor: "Juniper Networks"},
  {max_prefix: "3C-8A-B0",vendor: "Juniper Networks"},
  {max_prefix: "3C-61-04",vendor: "Juniper Networks"},
  {max_prefix: "88-E6-4B",vendor: "Juniper Networks"},
  {max_prefix: "D0-DD-49",vendor: "Juniper Networks"},
  {max_prefix: "1C-9C-8C",vendor: "Juniper Networks"},
  {max_prefix: "9C-8A-CB",vendor: "Juniper Networks"},
  {max_prefix: "10-39-E9",vendor: "Juniper Networks"},
  {max_prefix: "2C-6B-F5",vendor: "Juniper Networks"},
  {max_prefix: "B0-C6-9A",vendor: "Juniper Networks"},
  {max_prefix: "EC-13-DB",vendor: "Juniper Networks"},
  {max_prefix: "F4-CC-55",vendor: "Juniper Networks"},
  {max_prefix: "EC-94-D5",vendor: "Juniper Networks"},
  {max_prefix: "A4-E1-1A",vendor: "Juniper Networks"},
  {max_prefix: "24-FC-4E",vendor: "Juniper Networks"},
  {max_prefix: "A4-51-5E",vendor: "Juniper Networks"},
  {max_prefix: "F4-B5-2F",vendor: "Juniper Networks"},
  {max_prefix: "00-14-F6",vendor: "Juniper Networks"},
  {max_prefix: "28-A2-4B",vendor: "Juniper Networks"},
  {max_prefix: "00-1D-B5",vendor: "Juniper Networks"},
  {max_prefix: "7C-27-3C",vendor: "Shenzhen Yunlink Technology Co., Ltd"},
  {max_prefix: "1C-2A-B0",vendor: "Beijing Xiaomi Electronics Co.,Ltd"},
  {max_prefix: "98-17-F1",vendor: "zte corporation"},
  {max_prefix: "4C-C8-44",vendor: "Maipu Communication Technology Co.,Ltd."},
  {max_prefix: "0C-95-05",vendor: "The Chamberlain Group, Inc"},
  {max_prefix: "08-E6-C9",vendor: "Business-intelligence of Oriental Nations Corporation Ltd."},
  {max_prefix: "98-C8-54",vendor: "Chiun Mai Communication System, Inc"},
  {max_prefix: "70-17-D7",vendor: "Shanghai Enflame Technology Co., Ltd."},
  {max_prefix: "A0-8E-24",vendor: "eero inc."},
  {max_prefix: "24-78-23",vendor: "Panasonic Entertainment & Communication Co., Ltd."},
  {max_prefix: "84-46-93",vendor: "Beijing Xiaomi Mobile Software Co., Ltd"},
  {max_prefix: "B8-6A-F1",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "CC-58-30",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "44-DF-65",vendor: "Beijing Xiaomi Mobile Software Co., Ltd"},
  {max_prefix: "D8-03-1A",vendor: "Laird Connectivity"},
  {max_prefix: "00-2B-F5",vendor: "BUFFALO.INC"},
  {max_prefix: "5C-16-48",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "20-21-41",vendor: "Universal Electronics BV"},
  {max_prefix: "28-D0-CB",vendor: "Adtran Inc"},
  {max_prefix: "AC-51-EE",vendor: "Adtran Inc"},
  {max_prefix: "C4-FC-22",vendor: "YEALINK(XIAMEN) NETWORK TECHNOLOGY CO.,LTD."},
  {max_prefix: "08-28-02",vendor: "SHENZHEN CHUANGWEI-RGB ELECTRONICS CO.,LTD"},
  {max_prefix: "CC-42-10",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "A0-60-32",vendor: "Amcrest Technologies"},
  {max_prefix: "F0-D3-1F",vendor: "Apple, Inc."},
  {max_prefix: "B4-AE-C1",vendor: "Apple, Inc."},
  {max_prefix: "54-32-C7",vendor: "Apple, Inc."},
  {max_prefix: "58-E4-88",vendor: "Amazon Technologies Inc."},
  {max_prefix: "D8-53-BC",vendor: "Lenovo Information Products (Shenzhen)Co.,Ltd"},
  {max_prefix: "98-F9-CC",vendor: "Zhejiang Dahua Technology Co., Ltd."},
  {max_prefix: "38-68-A4",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "AC-1E-92",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "80-AC-C8",vendor: "Phyplus Microelectronics Limited"},
  {max_prefix: "A4-16-C0",vendor: "Apple, Inc."},
  {max_prefix: "DC-45-B8",vendor: "Apple, Inc."},
  {max_prefix: "90-EC-EA",vendor: "Apple, Inc."},
  {max_prefix: "10-B5-88",vendor: "Apple, Inc."},
  {max_prefix: "BC-AD-90",vendor: "Kymeta Purchasing"},
  {max_prefix: "3C-99-8C",vendor: "Houwa System Design Corp."},
  {max_prefix: "5C-75-45",vendor: "Wayties, Inc."},
  {max_prefix: "38-0F-AD",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "B4-E5-4C",vendor: "LLC Elektra"},
  {max_prefix: "DC-C2-C9",vendor: "CANON INC."},
  {max_prefix: "48-29-D6",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "FC-6A-1C",vendor: "Mellanox Technologies, Inc."},
  {max_prefix: "A0-88-C2",vendor: "Mellanox Technologies, Inc."},
  {max_prefix: "E4-62-C4",vendor: "Cisco Systems, Inc"},
  {max_prefix: "20-DE-1E",vendor: "Nokia"},
  {max_prefix: "60-77-71",vendor: "Texas Instruments"},
  {max_prefix: "AC-B1-81",vendor: "Belden Mooresville"},
  {max_prefix: "AC-E4-03",vendor: "Shenzhen Visteng Technology CO.,LTD"},
  {max_prefix: "A8-80-55",vendor: "Tuya Smart Inc."},
  {max_prefix: "50-8B-B9",vendor: "Tuya Smart Inc."},
  {max_prefix: "6C-AC-C2",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "BC-F7-30",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "C4-DB-04",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "94-7D-77",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "94-09-C9",vendor: "ALPSALPINE CO,.LTD"},
  {max_prefix: "60-D0-39",vendor: "Apple, Inc."},
  {max_prefix: "C4-C1-7D",vendor: "Apple, Inc."},
  {max_prefix: "44-EE-14",vendor: "Texas Instruments"},
  {max_prefix: "B4-AC-9D",vendor: "Texas Instruments"},
  {max_prefix: "D4-3F-32",vendor: "eero inc."},
  {max_prefix: "F8-34-51",vendor: "Comcast-SRL"},
  {max_prefix: "68-63-72",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "A0-C2-0D",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "4C-88-9E",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "3C-31-74",vendor: "Google, Inc."},
  {max_prefix: "BC-93-07",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "50-3C-CA",vendor: "TECNO MOBILE LIMITED"},
  {max_prefix: "9C-0C-35",vendor: "Shenzhenshi Xinzhongxin Technology Co.Ltd"},
  {max_prefix: "78-D6-D6",vendor: "eero inc."},
  {max_prefix: "A4-3F-51",vendor: "Shenzhen Benew Technology  Co.,Ltd."},
  {max_prefix: "A4-18-94",vendor: "Bosch Security Systems B.V."},
  {max_prefix: "8C-CD-FE",vendor: "AMPAK Technology,Inc."},
  {max_prefix: "DC-FB-B8",vendor: "Meizhou Guo Wei Electronics Co., Ltd"},
  {max_prefix: "00-1B-8F",vendor: "Cisco Systems, Inc"},
  {max_prefix: "D0-4F-58",vendor: "Ruckus Wireless"},
  {max_prefix: "6C-23-16",vendor: "TATUNG Technology Inc.,"},
  {max_prefix: "C8-ED-FC",vendor: "Shenzhen Ideaform Industrial Product Design Co., Ltd"},
  {max_prefix: "F8-22-29",vendor: "Nokia Shanghai Bell Co., Ltd."},
  {max_prefix: "48-5D-35",vendor: "AVM Audiovisuelles Marketing und Computersysteme GmbH"},
  {max_prefix: "98-AC-EF",vendor: "Realme Chongqing Mobile Telecommunications Corp.,Ltd."},
  {max_prefix: "E0-BD-A0",vendor: "Apple, Inc."},
  {max_prefix: "58-73-D8",vendor: "Apple, Inc."},
  {max_prefix: "F4-E8-C7",vendor: "Apple, Inc."},
  {max_prefix: "14-85-09",vendor: "Apple, Inc."},
  {max_prefix: "44-3D-54",vendor: "Amazon Technologies Inc."},
  {max_prefix: "54-47-CC",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "A0-A7-63",vendor: "Polytron Vertrieb GmbH"},
  {max_prefix: "24-D7-9C",vendor: "Cisco Systems, Inc"},
  {max_prefix: "24-72-4A",vendor: "Nile Global Inc"},
  {max_prefix: "00-84-1E",vendor: "Cisco Meraki"},
  {max_prefix: "54-65-03",vendor: "Quectel Wireless Solutions Co.,Ltd."},
  {max_prefix: "F8-5B-9B",vendor: "iMercury"},
  {max_prefix: "00-09-41",vendor: "Allied Telesis K.K."},
  {max_prefix: "D4-92-5E",vendor: "Technicolor Delivery Technologies Belgium NV"},
  {max_prefix: "E8-47-3A",vendor: "Hon Hai Precision Industry Co.,LTD"},
  {max_prefix: "D8-0E-29",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "10-BF-67",vendor: "Amazon Technologies Inc."},
  {max_prefix: "FC-D5-D9",vendor: "Shenzhen SDMC Technology CO.,Ltd."},
  {max_prefix: "80-FB-F0",vendor: "Quectel Wireless Solutions Co.,Ltd."},
  {max_prefix: "90-BD-E6",vendor: "Quectel Wireless Solutions Co.,Ltd."},
  {max_prefix: "50-0B-26",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "50-41-72",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "90-A6-BF",vendor: "Quectel Wireless Solutions Co.,Ltd."},
  {max_prefix: "3C-58-5D",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "38-95-92",vendor: "Tendyron Corporation"},
  {max_prefix: "F0-C7-45",vendor: "TECNO MOBILE LIMITED"},
  {max_prefix: "B4-6D-C2",vendor: "SHENZHEN BILIAN ELECTRONIC CO.\uff0cLTD"},
  {max_prefix: "3C-FA-06",vendor: "Microsoft Corporation"},
  {max_prefix: "44-32-62",vendor: "zte corporation"},
  {max_prefix: "F0-F6-9C",vendor: "NIO Co., Ltd."},
  {max_prefix: "58-E4-03",vendor: "Wistron Neweb Corporation"},
  {max_prefix: "98-CC-D9",vendor: "Shenzhen SuperElectron Technology Co.,Ltd."},
  {max_prefix: "64-D3-15",vendor: "HMD Global Oy"},
  {max_prefix: "60-6D-9D",vendor: "Otto Bock Healthcare Products GmbH"},
  {max_prefix: "B0-6B-B3",vendor: "GRT"},
  {max_prefix: "4C-82-A9",vendor: "CLOUD NETWORK TECHNOLOGY SINGAPORE PTE. LTD."},
  {max_prefix: "0C-AF-31",vendor: "Cisco Systems, Inc"},
  {max_prefix: "64-7C-34",vendor: "Ubee Interactive Co., Limited"},
  {max_prefix: "6C-38-A1",vendor: "Ubee Interactive Co., Limited"},
  {max_prefix: "AC-CF-7B",vendor: "INGRAM MICRO SERVICES"},
  {max_prefix: "C8-E3-06",vendor: "eero inc."},
  {max_prefix: "AC-3E-B1",vendor: "Google, Inc."},
  {max_prefix: "CC-B7-C4",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "50-14-C1",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "60-A2-C6",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "A4-E8-A3",vendor: "Fujian Newland Auto-ID Tech Co.,Ltd."},
  {max_prefix: "B8-16-5F",vendor: "LG Innotek"},
  {max_prefix: "1C-63-49",vendor: "Texas Instruments"},
  {max_prefix: "E4-52-1E",vendor: "Texas Instruments"},
  {max_prefix: "18-2C-65",vendor: "Texas Instruments"},
  {max_prefix: "18-FA-B7",vendor: "Apple, Inc."},
  {max_prefix: "70-22-FE",vendor: "Apple, Inc."},
  {max_prefix: "88-1E-5A",vendor: "Apple, Inc."},
  {max_prefix: "00-C5-85",vendor: "Apple, Inc."},
  {max_prefix: "A8-7C-F8",vendor: "Apple, Inc."},
  {max_prefix: "60-56-7D",vendor: "AM Telecom co., Ltd."},
  {max_prefix: "D4-F0-C9",vendor: "KYOCERA Document Solutions Inc."},
  {max_prefix: "64-E8-33",vendor: "Espressif Inc."},
  {max_prefix: "24-B7-DA",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "08-46-C7",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "78-D8-40",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "C8-96-5A",vendor: "SKY UK LIMITED"},
  {max_prefix: "C8-DE-41",vendor: "SKY UK LIMITED"},
  {max_prefix: "38-CA-84",vendor: "HP Inc."},
  {max_prefix: "B8-AB-62",vendor: "Hui Zhou Gaoshengda Technology Co.,LTD"},
  {max_prefix: "84-E6-57",vendor: "Sony Interactive Entertainment Inc."},
  {max_prefix: "20-5E-97",vendor: "Nokia"},
  {max_prefix: "04-86-80",vendor: "Quectel Wireless Solutions Co.,Ltd."},
  {max_prefix: "FC-22-F4",vendor: "Zyxel Communications Corporation"},
  {max_prefix: "F0-ED-B8",vendor: "SERVERCOM (INDIA) PRIVATE LIMITED"},
  {max_prefix: "F8-79-99",vendor: "Guangdong Jiuzhi Technology Co.,Ltd"},
  {max_prefix: "CC-2A-AC",vendor: "Yunjing lntelligent Technology(Dongguan).,Ltd"},
  {max_prefix: "20-40-6A",vendor: "AMPAK Technology,Inc."},
  {max_prefix: "84-DB-A4",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "24-5C-C5",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "F4-63-FC",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "B8-3D-FB",vendor: "Bouffalo Lab (Nanjing) Co., Ltd."},
  {max_prefix: "54-64-DE",vendor: "u-blox AG"},
  {max_prefix: "38-BC-61",vendor: "Starkoff Co., Ltd."},
  {max_prefix: "EC-74-27",vendor: "eero inc."},
  {max_prefix: "68-E1-DC",vendor: "BUFFALO.INC"},
  {max_prefix: "5C-AC-3D",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "CC-E6-86",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "74-19-0A",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "C0-C4-F9",vendor: "Qisda Corporation"},
  {max_prefix: "10-A8-29",vendor: "Cisco Systems, Inc"},
  {max_prefix: "E4-60-17",vendor: "Intel Corporate"},
  {max_prefix: "30-F6-EF",vendor: "Intel Corporate"},
  {max_prefix: "58-6D-67",vendor: "Intel Corporate"},
  {max_prefix: "68-6C-E6",vendor: "Microsoft Corporation"},
  {max_prefix: "00-31-26",vendor: "Nokia"},
  {max_prefix: "10-98-26",vendor: "Nokia"},
  {max_prefix: "C8-70-D4",vendor: "IBO Technology Co,Ltd"},
  {max_prefix: "58-47-CA",vendor: "IEEE Registration Authority"},
  {max_prefix: "84-FB-43",vendor: "Central Denshi Seigyo"},
  {max_prefix: "68-D4-0C",vendor: "TELLESCOM INDUSTRIA E COMERCIO EM TELECOMUNICACAO"},
  {max_prefix: "24-E3-DE",vendor: "China Telecom Fufu Information Technology Co., Ltd."},
  {max_prefix: "18-16-E8",vendor: "Siliconware Precision Industries Co., Ltd."},
  {max_prefix: "CC-79-D7",vendor: "Cisco Systems, Inc"},
  {max_prefix: "E4-38-7E",vendor: "Cisco Systems, Inc"},
  {max_prefix: "90-AC-3F",vendor: "BrightSign LLC"},
  {max_prefix: "68-F5-43",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "80-82-F5",vendor: "STMicrolectronics International NV"},
  {max_prefix: "F4-F1-9E",vendor: "Wistron InforComm (Zhongshan) Corporation"},
  {max_prefix: "10-4C-43",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "64-7C-E8",vendor: "Palo Alto Networks"},
  {max_prefix: "28-2B-B9",vendor: "Shenzhen Xiongxin Technology Co.,Ltd"},
  {max_prefix: "30-1A-BA",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "44-8E-EC",vendor: "China Mobile Group Device Co.,Ltd."},
  {max_prefix: "F0-26-F8",vendor: "Worldcns Co.,Ltd."},
  {max_prefix: "14-07-08",vendor: "CP PLUS GMBH & CO. KG"},
  {max_prefix: "F8-5B-6E",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "14-D4-24",vendor: "AzureWave Technology Inc."},
  {max_prefix: "68-27-5F",vendor: "zte corporation"},
  {max_prefix: "44-41-F0",vendor: "zte corporation"},
  {max_prefix: "A4-79-52",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "B8-7C-D0",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "D0-7E-01",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "F8-79-07",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "7C-E8-7F",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "68-87-1C",vendor: "Motorola Mobility LLC, a Lenovo Company"},
  {max_prefix: "F8-A4-75",vendor: "PT Indoreka Jaya Wutama"},
  {max_prefix: "14-49-D4",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "F8-7D-76",vendor: "Apple, Inc."},
  {max_prefix: "00-A0-93",vendor: "B/E AEROSPACE, Inc."},
  {max_prefix: "2C-76-00",vendor: "Apple, Inc."},
  {max_prefix: "70-42-D3",vendor: "Ruijie Networks Co.,LTD"},
  {max_prefix: "18-C3-F4",vendor: "IEEE Registration Authority"},
  {max_prefix: "34-DF-20",vendor: "Shenzhen Comstar .Technology Co.,Ltd"},
  {max_prefix: "38-7C-76",vendor: "Universal Global Scientific Industrial Co., Ltd."},
  {max_prefix: "E4-A6-34",vendor: "Universal Electronics, Inc."},
  {max_prefix: "2C-8D-37",vendor: "Virtium"},
  {max_prefix: "40-FD-F3",vendor: "AMPAK Technology,Inc."},
  {max_prefix: "8C-6A-3B",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "24-11-53",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "88-9C-AD",vendor: "Cisco Systems, Inc"},
  {max_prefix: "D8-83-32",vendor: "TaiXin Semiconductor Co., Ltd"},
  {max_prefix: "EC-E6-1D",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "4C-63-AD",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "DC-DB-27",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "AC-B6-87",vendor: "Arcadyan Corporation"},
  {max_prefix: "50-63-91",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "E8-A3-4E",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "04-18-92",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "14-65-6A",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "6C-B7-E2",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "C4-75-EA",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "90-25-F2",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "F4-1A-B0",vendor: "Shenzhen Xingguodu Technology Co., Ltd."},
  {max_prefix: "B0-1F-47",vendor: "Heights Telecom T ltd"},
  {max_prefix: "28-29-47",vendor: "Chipsea Technologies (Shenzhen) Corp."},
  {max_prefix: "AC-BC-B5",vendor: "Apple, Inc."},
  {max_prefix: "08-25-73",vendor: "Apple, Inc."},
  {max_prefix: "AC-00-7A",vendor: "Apple, Inc."},
  {max_prefix: "F0-1F-C7",vendor: "Apple, Inc."},
  {max_prefix: "88-20-0D",vendor: "Apple, Inc."},
  {max_prefix: "BC-15-41",vendor: "Nokia"},
  {max_prefix: "40-E1-71",vendor: "Jiangsu Huitong Group Co.,Ltd."},
  {max_prefix: "18-C3-00",vendor: "Nokia"},
  {max_prefix: "D4-4D-77",vendor: "Nokia"},
  {max_prefix: "94-6D-AE",vendor: "Mellanox Technologies, Inc."},
  {max_prefix: "E0-F3-18",vendor: "Sichuan Tianyi Comheart Telecom Co.,LTD"},
  {max_prefix: "C4-64-F2",vendor: "Infinix mobility limited"},
  {max_prefix: "6C-72-4A",vendor: "Onkyo Technology K.K."},
  {max_prefix: "D8-FF-C3",vendor: "Shenzhen 3SNIC information technology company Limited"},
  {max_prefix: "B8-8F-27",vendor: "Realme Chongqing Mobile Telecommunications Corp.,Ltd."},
  {max_prefix: "8C-25-5E",vendor: "VoltServer"},
  {max_prefix: "58-B3-8F",vendor: "New H3C Technologies Co., Ltd"},
  {max_prefix: "F4-6D-3F",vendor: "Intel Corporate"},
  {max_prefix: "DC-05-39",vendor: "Cisco Systems, Inc"},
  {max_prefix: "48-27-E2",vendor: "Espressif Inc."},
  {max_prefix: "38-86-F7",vendor: "Google, Inc."},
  {max_prefix: "F4-22-7A",vendor: "Guangdong Seneasy Intelligent Technology Co., Ltd."},
  {max_prefix: "DC-8D-B7",vendor: "ATW TECHNOLOGY, INC."},
  {max_prefix: "54-3D-92",vendor: "WIRELESS-TEK TECHNOLOGY LIMITED"},
  {max_prefix: "98-26-AD",vendor: "Quectel Wireless Solutions Co.,Ltd."},
  {max_prefix: "64-0E-6A",vendor: "SECO-LARM USA Inc"},
  {max_prefix: "8C-51-09",vendor: "IEEE Registration Authority"},
  {max_prefix: "68-E1-54",vendor: "SiMa.ai"},
  {max_prefix: "6C-60-D0",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "E8-EF-05",vendor: "MIND TECH INTERNATIONAL LIMITED"},
  {max_prefix: "A4-75-B9",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "80-54-9C",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "1C-F8-D0",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "F0-87-56",vendor: "Zyxel Communications Corporation"},
  {max_prefix: "94-D3-31",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "D4-43-0E",vendor: "Zhejiang Dahua Technology Co., Ltd."},
  {max_prefix: "F8-5E-0B",vendor: "Realme Chongqing Mobile Telecommunications Corp.,Ltd."},
  {max_prefix: "90-F9-70",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "04-CA-ED",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "68-EE-88",vendor: "Shenzhen TINNO Mobile Technology Corp."},
  {max_prefix: "2C-3B-70",vendor: "AzureWave Technology Inc."},
  {max_prefix: "5C-8C-30",vendor: "Taicang T&W Electronics"},
  {max_prefix: "34-DD-04",vendor: "Minut AB"},
  {max_prefix: "60-1E-98",vendor: "Axevast Technology"},
  {max_prefix: "A8-F7-D9",vendor: "Mist Systems, Inc."},
  {max_prefix: "44-88-16",vendor: "Cisco Systems, Inc"},
  {max_prefix: "E8-AC-23",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "68-D9-27",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "2C-9D-65",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "4C-9D-22",vendor: "ACES Co.,Ltd"},
  {max_prefix: "88-C9-E8",vendor: "Sony Corporation"},
  {max_prefix: "B8-F0-B9",vendor: "zte corporation"},
  {max_prefix: "80-5B-65",vendor: "LG Innotek"},
  {max_prefix: "A0-C9-8B",vendor: "Nokia Solutions and Networks GmbH & Co. KG"},
  {max_prefix: "18-BB-1C",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "70-A9-83",vendor: "Cisco Systems, Inc"},
  {max_prefix: "BC-FA-EB",vendor: "Cisco Systems, Inc"},
  {max_prefix: "84-85-53",vendor: "Biznes Systema Telecom, LLC"},
  {max_prefix: "B4-7D-76",vendor: "KNS Group LLC"},
  {max_prefix: "C0-AD-97",vendor: "TECNO MOBILE LIMITED"},
  {max_prefix: "58-00-32",vendor: "Genexis B.V."},
  {max_prefix: "BC-C7-DA",vendor: "Earda Technologies co Ltd"},
  {max_prefix: "18-66-F0",vendor: "Jupiter Systems"},
  {max_prefix: "74-60-4C",vendor: "RODE"},
  {max_prefix: "B0-38-E2",vendor: "Wanan Hongsheng Electronic Co.Ltd"},
  {max_prefix: "4C-53-69",vendor: "YanFeng Visteon(ChongQing) Automotive Electronic Co.,Ltd"},
  {max_prefix: "E0-48-D8",vendor: "Guangzhi Wulian Technology(Guangzhou) Co., Ltd"},
  {max_prefix: "F8-E4-A4",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "28-6F-40",vendor: "Tonly Technology Co. Ltd"},
  {max_prefix: "0C-86-C7",vendor: "Jabil Circuit (Guangzhou) Limited"},
  {max_prefix: "D8-88-63",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "C0-3E-50",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "80-60-36",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "74-76-7D",vendor: "shenzhen kexint technology co.,ltd"},
  {max_prefix: "24-CF-24",vendor: "Beijing Xiaomi Mobile Software Co., Ltd"},
  {max_prefix: "F0-6C-5D",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "40-B0-2F",vendor: "Miele & Cie. KG"},
  {max_prefix: "0C-7B-C8",vendor: "Cisco Meraki"},
  {max_prefix: "C8-F0-9E",vendor: "Espressif Inc."},
  {max_prefix: "DC-54-75",vendor: "Espressif Inc."},
  {max_prefix: "00-14-01",vendor: "Rivertree Networks Corp."},
  {max_prefix: "00-60-68",vendor: "Dialogic Corporation"},
  {max_prefix: "1C-59-74",vendor: "IEEE Registration Authority"},
  {max_prefix: "1C-0D-7D",vendor: "Apple, Inc."},
  {max_prefix: "14-F2-87",vendor: "Apple, Inc."},
  {max_prefix: "58-55-95",vendor: "Apple, Inc."},
  {max_prefix: "14-94-6C",vendor: "Apple, Inc."},
  {max_prefix: "90-D4-73",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "60-7D-09",vendor: "Luxshare Precision Industry Co., Ltd"},
  {max_prefix: "EC-62-60",vendor: "Espressif Inc."},
  {max_prefix: "B0-6E-72",vendor: "Realme Chongqing Mobile Telecommunications Corp.,Ltd."},
  {max_prefix: "5C-A4-F4",vendor: "zte corporation"},
  {max_prefix: "24-6C-60",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "64-C5-82",vendor: "China Mobile Group Device Co.,Ltd."},
  {max_prefix: "88-FC-5D",vendor: "Cisco Systems, Inc"},
  {max_prefix: "F4-C8-8A",vendor: "Intel Corporate"},
  {max_prefix: "28-82-7C",vendor: "Bosch Automative products(Suzhou)Co.,Ltd Changzhou Branch"},
  {max_prefix: "30-3E-A7",vendor: "Intel Corporate"},
  {max_prefix: "50-28-4A",vendor: "Intel Corporate"},
  {max_prefix: "08-EB-F6",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "B0-23-47",vendor: "Shenzhen Giant Microelectronics Company Limited"},
  {max_prefix: "18-3C-98",vendor: "Shenzhen Hengyi Technology Co., LTD"},
  {max_prefix: "D8-10-68",vendor: "Murata Manufacturing Co., Ltd."},
  {max_prefix: "5C-04-5A",vendor: "Company NA Stage & Light"},
  {max_prefix: "58-C3-56",vendor: "EM Microelectronic"},
  {max_prefix: "F4-E2-04",vendor: "COYOTE SYSTEM"},
  {max_prefix: "F8-4E-58",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "B4-70-64",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "4C-2E-5E",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "64-5D-F4",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "DC-A9-56",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "CC-82-7F",vendor: "Advantech Technology (CHINA) Co., Ltd."},
  {max_prefix: "78-AF-08",vendor: "Intel Corporate"},
  {max_prefix: "CC-DD-58",vendor: "Robert Bosch GmbH"},
  {max_prefix: "78-71-04",vendor: "Sichuan Tianyi Comheart Telecom Co.,LTD"},
  {max_prefix: "00-16-A3",vendor: "INGETEAM"},
  {max_prefix: "88-3F-0C",vendor: "system a.v. co., ltd."},
  {max_prefix: "A0-ED-FB",vendor: "Quectel Wireless Solutions Co.,Ltd."},
  {max_prefix: "10-4D-15",vendor: "Viaanix Inc"},
  {max_prefix: "50-A0-15",vendor: "Shenzhen Yipingfang Network Technology Co., Ltd."},
  {max_prefix: "70-85-C4",vendor: "Ruijie Networks Co.,LTD"},
  {max_prefix: "A0-09-2E",vendor: "zte corporation"},
  {max_prefix: "AC-33-0B",vendor: "Japan Computer Vision Corp."},
  {max_prefix: "30-53-C1",vendor: "CRESYN"},
  {max_prefix: "5C-C5-63",vendor: "HUNAN FN-LINK TECHNOLOGY LIMITED"},
  {max_prefix: "EC-60-73",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "74-DD-CB",vendor: "China Leadshine Technology Co.,Ltd"},
  {max_prefix: "A8-B1-3B",vendor: "HP Inc."},
  {max_prefix: "68-B6-91",vendor: "Amazon Technologies Inc."},
  {max_prefix: "B0-AF-F7",vendor: "Shenzhen Yipingfang Network Technology Co., Ltd."},
  {max_prefix: "48-27-C5",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "BC-D2-06",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "14-75-5B",vendor: "Intel Corporate"},
  {max_prefix: "C8-54-A4",vendor: "Infinix mobility limited"},
  {max_prefix: "2C-F2-95",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "7C-DA-C3",vendor: "Sichuan Tianyi Comheart Telecom Co.,LTD"},
  {max_prefix: "BC-61-93",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "F8-E5-7E",vendor: "Cisco Systems, Inc"},
  {max_prefix: "3C-5D-29",vendor: "Zhejiang Tmall Technology Co., Ltd."},
  {max_prefix: "68-FC-CA",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "6C-D7-19",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "38-5B-44",vendor: "Silicon Laboratories"},
  {max_prefix: "94-34-69",vendor: "Silicon Laboratories"},
  {max_prefix: "E0-BB-0C",vendor: "Synertau LLC"},
  {max_prefix: "80-01-5C",vendor: "Synaptics, Inc"},
  {max_prefix: "CC-EB-18",vendor: "OOO TSS"},
  {max_prefix: "AC-77-B9",vendor: "Nanjing Yufei Intelligent Control Technology Co.,LTD"},
  {max_prefix: "A8-5B-B7",vendor: "Apple, Inc."},
  {max_prefix: "34-62-B4",vendor: "Renesas Electronics (Penang) Sdn. Bhd."},
  {max_prefix: "8C-17-B6",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "B8-2B-68",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "00-10-E6",vendor: "APPLIED INTELLIGENT SYSTEMS, INC."},
  {max_prefix: "00-08-13",vendor: "Diskbank, Inc."},
  {max_prefix: "74-46-87",vendor: "Kingsignal Technology Co., Ltd."},
  {max_prefix: "8C-47-7F",vendor: "NambooSolution"},
  {max_prefix: "48-2E-72",vendor: "Cisco Systems, Inc"},
  {max_prefix: "30-CB-C7",vendor: "Cambium Networks Limited"},
  {max_prefix: "88-50-46",vendor: "LEAR"},
  {max_prefix: "08-26-AE",vendor: "IEEE Registration Authority"},
  {max_prefix: "24-4C-AB",vendor: "Espressif Inc."},
  {max_prefix: "E0-41-02",vendor: "zte corporation"},
  {max_prefix: "D8-40-08",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "6C-04-7A",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "6C-55-8D",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "A4-2A-95",vendor: "D-Link International"},
  {max_prefix: "78-48-2C",vendor: "START USA, INC."},
  {max_prefix: "7C-45-D0",vendor: "Shenzhen Wewins Wireless Co., ltd"},
  {max_prefix: "70-04-1D",vendor: "Espressif Inc."},
  {max_prefix: "88-6F-29",vendor: "Pocketbook International SA"},
  {max_prefix: "BC-22-47",vendor: "New H3C Technologies Co., Ltd"},
  {max_prefix: "20-AF-1B",vendor: "SteelSeries ApS"},
  {max_prefix: "18-C2-93",vendor: "Laird Connectivity"},
  {max_prefix: "00-0A-C2",vendor: "Wuhan FiberHome Digital Technology Co.,Ltd."},
  {max_prefix: "70-4C-B6",vendor: "Shenzhen SuperElectron Technology Co.,Ltd."},
  {max_prefix: "54-9B-49",vendor: "NEC Platforms, Ltd."},
  {max_prefix: "7C-77-16",vendor: "Zyxel Communications Corporation"},
  {max_prefix: "94-3F-BB",vendor: "JSC RPC Istok named after Shokin"},
  {max_prefix: "50-E7-B7",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "F4-F7-0C",vendor: "Avang - neterbit"},
  {max_prefix: "74-EF-4B",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "44-8C-00",vendor: "Realme Chongqing Mobile Telecommunications Corp.,Ltd."},
  {max_prefix: "F0-BE-25",vendor: "Dongguan Cannice Precision Manufacturing Co., Ltd."},
  {max_prefix: "14-9B-D7",vendor: "MULI MUWAI FURNITURE QIDONG CO., LTD"},
  {max_prefix: "D0-E8-28",vendor: "Radiant Industries Incorporated"},
  {max_prefix: "10-76-36",vendor: "Earda Technologies co Ltd"},
  {max_prefix: "F4-44-2C",vendor: "Shenzhen SuperElectron Technology Co.,Ltd."},
  {max_prefix: "E0-FF-F1",vendor: "Texas Instruments"},
  {max_prefix: "9C-0B-05",vendor: "eero inc."},
  {max_prefix: "40-F6-BC",vendor: "Amazon Technologies Inc."},
  {max_prefix: "EC-DA-59",vendor: "New H3C Technologies Co., Ltd"},
  {max_prefix: "98-B7-85",vendor: "Shenzhen 10Gtek Transceivers Co., Limited"},
  {max_prefix: "48-5A-EA",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "84-81-02",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "54-E0-05",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "A0-83-B4",vendor: "HeNet B.V."},
  {max_prefix: "3C-8B-7F",vendor: "Cisco Systems, Inc"},
  {max_prefix: "C0-F8-7F",vendor: "Cisco Systems, Inc"},
  {max_prefix: "5C-8E-8B",vendor: "Shenzhen Linghai Electronics Co.,Ltd"},
  {max_prefix: "10-B1-DF",vendor: "CLOUD NETWORK TECHNOLOGY SINGAPORE PTE. LTD."},
  {max_prefix: "F4-F6-47",vendor: "zte corporation"},
  {max_prefix: "E8-81-75",vendor: "zte corporation"},
  {max_prefix: "1C-71-25",vendor: "Apple, Inc."},
  {max_prefix: "34-FE-77",vendor: "Apple, Inc."},
  {max_prefix: "6C-E5-C9",vendor: "Apple, Inc."},
  {max_prefix: "74-B7-E6",vendor: "Zegna-Daidong Limited"},
  {max_prefix: "04-FF-08",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "00-A4-5F",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "FC-E2-6C",vendor: "Apple, Inc."},
  {max_prefix: "4C-79-75",vendor: "Apple, Inc."},
  {max_prefix: "DC-97-58",vendor: "Sichuan AI-Link Technology Co., Ltd."},
  {max_prefix: "78-F2-38",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "64-D0-D6",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "78-EB-46",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "C4-16-C8",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "E4-DC-CC",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "3C-A1-61",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "00-09-31",vendor: "Future Internet, Inc."},
  {max_prefix: "B8-F2-55",vendor: "Universal Electronics, Inc."},
  {max_prefix: "EC-65-6E",vendor: "The Things Industries B.V."},
  {max_prefix: "10-AE-A5",vendor: "Duskrise inc."},
  {max_prefix: "94-94-4A",vendor: "Particle Industries Inc."},
  {max_prefix: "5C-49-FA",vendor: "Shenzhen Guowei Shidai Communication Equipement Co., Ltd"},
  {max_prefix: "2C-A3-27",vendor: "Oraimo Technology Limited"},
  {max_prefix: "90-75-BC",vendor: "Nokia Shanghai Bell Co., Ltd."},
  {max_prefix: "4C-53-FD",vendor: "Amazon Technologies Inc."},
  {max_prefix: "58-7D-B6",vendor: "Northern Data AG"},
  {max_prefix: "78-21-84",vendor: "Espressif Inc."},
  {max_prefix: "34-13-43",vendor: "GE Lighting"},
  {max_prefix: "18-58-80",vendor: "Arcadyan Corporation"},
  {max_prefix: "30-83-D2",vendor: "Motorola Mobility LLC, a Lenovo Company"},
  {max_prefix: "AC-50-DE",vendor: "CLOUD NETWORK TECHNOLOGY SINGAPORE PTE. LTD."},
  {max_prefix: "00-DD-B6",vendor: "New H3C Technologies Co., Ltd"},
  {max_prefix: "B8-9E-A6",vendor: "SPBEC-MINING CO.LTD"},
  {max_prefix: "E0-7E-5F",vendor: "Renesas Electronics (Penang) Sdn. Bhd."},
  {max_prefix: "38-BE-AB",vendor: "AltoBeam (China) Inc."},
  {max_prefix: "04-79-75",vendor: "Honor Device Co., Ltd."},
  {max_prefix: "9C-05-67",vendor: "Honor Device Co., Ltd."},
  {max_prefix: "14-66-B7",vendor: "Advanced Design Technology Pty Ltd"},
  {max_prefix: "B0-8B-92",vendor: "zte corporation"},
  {max_prefix: "08-C8-C2",vendor: "GN Audio A/S"},
  {max_prefix: "AC-8B-6A",vendor: "China Mobile IOT Company Limited"},
  {max_prefix: "00-00-56",vendor: "DR. B. STRUCK"},
  {max_prefix: "70-FD-88",vendor: "Nanjing Jiahao Technology Co., Ltd."},
  {max_prefix: "98-19-2C",vendor: "Edgecore Networks Corporation"},
  {max_prefix: "08-00-04",vendor: "CROMEMCO INCORPORATED"},
  {max_prefix: "FC-77-7B",vendor: "Hitron Technologies. Inc"},
  {max_prefix: "14-17-2A",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "00-5F-BF",vendor: "Toshiba Corp."},
  {max_prefix: "D4-05-DE",vendor: "eero inc."},
  {max_prefix: "FC-0F-E7",vendor: "Microchip Technology Inc."},
  {max_prefix: "24-12-81",vendor: "China Mobile Group Device Co.,Ltd."},
  {max_prefix: "50-C0-F0",vendor: "Artek Microelectronics Co.,Ltd."},
  {max_prefix: "B4-AE-2B",vendor: "Microsoft"},
  {max_prefix: "38-A9-1C",vendor: "New H3C Technologies Co., Ltd"},
  {max_prefix: "F4-70-0C",vendor: "IEEE Registration Authority"},
  {max_prefix: "54-9F-06",vendor: "Nokia Shanghai Bell Co., Ltd."},
  {max_prefix: "68-A8-78",vendor: "GeoWAN Pty Ltd"},
  {max_prefix: "40-9B-21",vendor: "Nokia"},
  {max_prefix: "48-73-CB",vendor: "Tiinlab Corporation"},
  {max_prefix: "78-9F-AA",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "E8-A7-2F",vendor: "Microsoft Corporation"},
  {max_prefix: "94-B3-4F",vendor: "Ruckus Wireless"},
  {max_prefix: "C8-5C-CC",vendor: "Beijing Xiaomi Mobile Software Co., Ltd"},
  {max_prefix: "38-38-4B",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "14-0A-29",vendor: "Tiinlab Corporation"},
  {max_prefix: "30-1A-30",vendor: "Mako Networks Ltd"},
  {max_prefix: "C4-8B-A3",vendor: "Cisco Meraki"},
  {max_prefix: "24-CE-33",vendor: "Amazon Technologies Inc."},
  {max_prefix: "0C-DD-EF",vendor: "Nokia Corporation"},
  {max_prefix: "5C-96-66",vendor: "Sony Interactive Entertainment Inc."},
  {max_prefix: "88-23-1F",vendor: "Fibocom Wireless Inc."},
  {max_prefix: "A4-DE-26",vendor: "Sumitomo Electric Industries, Ltd"},
  {max_prefix: "30-7B-C9",vendor: "SHENZHEN BILIAN ELECTRONIC CO.\uff0cLTD"},
  {max_prefix: "68-E4-78",vendor: "Qingdao Haier Technology Co.,Ltd"},
  {max_prefix: "E0-9D-13",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "A0-0F-37",vendor: "Cisco Systems, Inc"},
  {max_prefix: "4C-44-5B",vendor: "Intel Corporate"},
  {max_prefix: "C8-CA-79",vendor: "Ciena Corporation"},
  {max_prefix: "BC-6E-E2",vendor: "Intel Corporate"},
  {max_prefix: "7C-72-6E",vendor: "Ericsson AB"},
  {max_prefix: "D4-EE-DE",vendor: "Sichuan Tianyi Comheart Telecom Co.,LTD"},
  {max_prefix: "C0-8B-05",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "04-68-65",vendor: "Apple, Inc."},
  {max_prefix: "DC-53-92",vendor: "Apple, Inc."},
  {max_prefix: "1C-B3-C9",vendor: "Apple, Inc."},
  {max_prefix: "FC-AA-81",vendor: "Apple, Inc."},
  {max_prefix: "60-93-16",vendor: "Apple, Inc."},
  {max_prefix: "64-6D-2F",vendor: "Apple, Inc."},
  {max_prefix: "C0-F9-D2",vendor: "arkona technologies GmbH"},
  {max_prefix: "38-7A-0E",vendor: "Intel Corporate"},
  {max_prefix: "04-20-84",vendor: "zte corporation"},
  {max_prefix: "B4-5F-84",vendor: "zte corporation"},
  {max_prefix: "00-DF-1D",vendor: "Cisco Systems, Inc"},
  {max_prefix: "78-72-64",vendor: "IEEE Registration Authority"},
  {max_prefix: "10-97-BD",vendor: "Espressif Inc."},
  {max_prefix: "B0-1C-0C",vendor: "SHENZHEN CHUANGWEI-RGB ELECTRONICS CO.,LTD"},
  {max_prefix: "68-40-3C",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "90-3E-7F",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "14-C0-A1",vendor: "UCloud Technology Co., Ltd."},
  {max_prefix: "DC-0E-96",vendor: "Palo Alto Networks"},
  {max_prefix: "30-C6-F7",vendor: "Espressif Inc."},
  {max_prefix: "C0-36-53",vendor: "eero inc."},
  {max_prefix: "94-02-30",vendor: "Logitech"},
  {max_prefix: "70-11-35",vendor: "Livesecu co., Ltd"},
  {max_prefix: "64-32-16",vendor: "Weidu Technology (Beijing) Co., Ltd."},
  {max_prefix: "C0-6B-55",vendor: "Motorola Mobility LLC, a Lenovo Company"},
  {max_prefix: "DC-84-E9",vendor: "Shenzhen Qihoo Intelligent Technology Co.,Ltd"},
  {max_prefix: "78-67-0E",vendor: "Wistron Neweb Corporation"},
  {max_prefix: "08-02-05",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "90-35-EA",vendor: "Silicon Laboratories"},
  {max_prefix: "FC-A8-4A",vendor: "Sentinum GmbH"},
  {max_prefix: "6C-3C-7C",vendor: "CANON INC."},
  {max_prefix: "30-4F-00",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "1C-5E-E6",vendor: "SHENZHEN TWOWING TECHNOLOGIES CO.,LTD."},
  {max_prefix: "34-6F-24",vendor: "AzureWave Technology Inc."},
  {max_prefix: "A4-1A-3A",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "34-84-E4",vendor: "Texas Instruments"},
  {max_prefix: "AC-4D-16",vendor: "Texas Instruments"},
  {max_prefix: "B0-10-A0",vendor: "Texas Instruments"},
  {max_prefix: "DC-91-66",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "AC-80-D6",vendor: "Hexatronic AB"},
  {max_prefix: "DC-E5-5B",vendor: "Google, Inc."},
  {max_prefix: "4C-B9-EA",vendor: "iRobot Corporation"},
  {max_prefix: "B8-94-E7",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "34-B9-8D",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "C4-D7-FD",vendor: "Bouffalo Lab (Nanjing) Co., Ltd."},
  {max_prefix: "D8-76-AE",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "0C-84-08",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "A0-9F-7A",vendor: "D-Link Middle East FZCO"},
  {max_prefix: "B4-20-46",vendor: "eero inc."},
  {max_prefix: "E8-6E-44",vendor: "zte corporation"},
  {max_prefix: "00-E7-E3",vendor: "zte corporation"},
  {max_prefix: "B0-6A-41",vendor: "Google, Inc."},
  {max_prefix: "1C-ED-6F",vendor: "AVM Audiovisuelles Marketing und Computersysteme GmbH"},
  {max_prefix: "D8-1F-12",vendor: "Tuya Smart Inc."},
  {max_prefix: "20-4B-22",vendor: "Sunnovo International Limited"},
  {max_prefix: "C8-1C-FE",vendor: "Zebra Technologies Inc."},
  {max_prefix: "F0-70-4F",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "AC-6C-90",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "90-B6-22",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "0C-02-BD",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "2C-D1-DA",vendor: "Keysight Technologies, Inc."},
  {max_prefix: "C4-FC-EF",vendor: "SambaNova Systems, Inc."},
  {max_prefix: "5C-52-30",vendor: "Apple, Inc."},
  {max_prefix: "64-5A-36",vendor: "Apple, Inc."},
  {max_prefix: "6C-71-D2",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "F8-00-A1",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "B4-AC-8C",vendor: "Bern University of Applied Sciences"},
  {max_prefix: "BC-CE-25",vendor: "Nintendo Co.,Ltd"},
  {max_prefix: "20-32-C6",vendor: "Apple, Inc."},
  {max_prefix: "FC-1D-2A",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "9C-54-C2",vendor: "New H3C Technologies Co., Ltd"},
  {max_prefix: "78-E9-CF",vendor: "TELLESCOM INDUSTRIA E COMERCIO EM TELECOMUNICACAO"},
  {max_prefix: "00-0D-67",vendor: "Ericsson"},
  {max_prefix: "24-D7-EB",vendor: "Espressif Inc."},
  {max_prefix: "58-BF-25",vendor: "Espressif Inc."},
  {max_prefix: "80-D2-E5",vendor: "Nintendo Co.,Ltd"},
  {max_prefix: "04-D4-42",vendor: "GUANGDONG GENIUS TECHNOLOGY CO., LTD."},
  {max_prefix: "1C-05-B7",vendor: "Chongqing Trantor Technology Co., Ltd."},
  {max_prefix: "14-19-73",vendor: "Beijing Yunyi Times Technology Co.,Ltd"},
  {max_prefix: "54-6F-71",vendor: "uAvionix Corporation"},
  {max_prefix: "54-EF-33",vendor: "SHENZHEN BILIAN ELECTRONIC CO.\uff0cLTD"},
  {max_prefix: "44-48-B9",vendor: "MitraStar Technology Corp."},
  {max_prefix: "5C-75-C6",vendor: "China Mobile Group Device Co.,Ltd."},
  {max_prefix: "90-09-D0",vendor: "Synology Incorporated"},
  {max_prefix: "A0-D0-5B",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "F8-CE-72",vendor: "Wistron Corporation"},
  {max_prefix: "CC-9D-A2",vendor: "Eltex Enterprise Ltd."},
  {max_prefix: "B4-37-D8",vendor: "D-Link (Shanghai) Limited Corp."},
  {max_prefix: "00-0A-02",vendor: "ANNSO CO., LTD."},
  {max_prefix: "9C-D5-7D",vendor: "Cisco Systems, Inc"},
  {max_prefix: "CC-E2-36",vendor: "Hangzhou Yaguan Technology Co. LTD"},
  {max_prefix: "00-0F-E5",vendor: "MERCURY SECURITY CORPORATION"},
  {max_prefix: "74-76-5B",vendor: "Quectel Wireless Solutions Co.,Ltd."},
  {max_prefix: "20-41-81",vendor: "ESYSE GmbH Embedded Systems Engineering"},
  {max_prefix: "60-53-75",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "78-DD-33",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "A0-31-DB",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "DC-BB-96",vendor: "Full Solution Telecom"},
  {max_prefix: "94-1F-3A",vendor: "Ambiq"},
  {max_prefix: "70-66-E1",vendor: "dnt Innovation GmbH"},
  {max_prefix: "48-18-8D",vendor: "WEIFANG GOERTEK ELECTRONICS CO.,LTD"},
  {max_prefix: "80-FB-F1",vendor: "Freescale Semiconductor (China) Ltd."},
  {max_prefix: "38-D5-7A",vendor: "CLOUD NETWORK TECHNOLOGY SINGAPORE PTE. LTD."},
  {max_prefix: "08-E7-E5",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "48-73-97",vendor: "New H3C Technologies Co., Ltd"},
  {max_prefix: "A0-44-5C",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "64-6D-4E",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "08-5C-1B",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "50-9A-88",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "B8-3B-CC",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "D0-9C-AE",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "88-D1-99",vendor: "Vencer Co., Ltd."},
  {max_prefix: "70-1F-0B",vendor: "WILOGY SRL"},
  {max_prefix: "A0-18-42",vendor: "Comtrend Corporation"},
  {max_prefix: "50-6F-0C",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "D4-86-60",vendor: "Arcadyan Corporation"},
  {max_prefix: "40-40-6C",vendor: "Icomera"},
  {max_prefix: "04-39-26",vendor: "China Dragon Technology Limited"},
  {max_prefix: "70-CD-0D",vendor: "Intel Corporate"},
  {max_prefix: "5C-0C-E6",vendor: "Nintendo Co.,Ltd"},
  {max_prefix: "18-56-C3",vendor: "Apple, Inc."},
  {max_prefix: "68-81-E0",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "4C-D6-29",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "F0-C4-78",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "D8-6D-17",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "7C-1A-C0",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "7C-87-CE",vendor: "Espressif Inc."},
  {max_prefix: "8C-81-72",vendor: "Sichuan Tianyi Comheart Telecom Co.,LTD"},
  {max_prefix: "1C-93-C4",vendor: "Amazon Technologies Inc."},
  {max_prefix: "74-5D-43",vendor: "BSH Hausgeraete GmbH"},
  {max_prefix: "00-0B-A5",vendor: "Quasar Cipta Mandiri, PT"},
  {max_prefix: "44-E5-17",vendor: "Intel Corporate"},
  {max_prefix: "AC-49-DB",vendor: "Apple, Inc."},
  {max_prefix: "44-F0-9E",vendor: "Apple, Inc."},
  {max_prefix: "08-FF-44",vendor: "Apple, Inc."},
  {max_prefix: "54-77-87",vendor: "Earda Technologies co Ltd"},
  {max_prefix: "00-27-04",vendor: "Accelerated Concepts, Inc"},
  {max_prefix: "D0-C2-4E",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "34-5B-98",vendor: "EM Microelectronic"},
  {max_prefix: "B8-8D-12",vendor: "Apple, Inc."},
  {max_prefix: "08-00-37",vendor: "FUJIFILM Business Innovation Corp."},
  {max_prefix: "88-8E-7F",vendor: "ATOP CORPORATION"},
  {max_prefix: "00-30-59",vendor: "Kontron Europe GmbH"},
  {max_prefix: "88-A0-BE",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "94-90-10",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "98-F0-83",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "8C-64-A2",vendor: "OnePlus Technology (Shenzhen) Co., Ltd"},
  {max_prefix: "D0-54-75",vendor: "SAVI Controls"},
  {max_prefix: "48-9E-BD",vendor: "HP Inc."},
  {max_prefix: "00-26-9C",vendor: "ITUS JAPAN CO. LTD"},
  {max_prefix: "28-FA-19",vendor: "Shenzhen Jingxun Software Telecommunication Technology Co.,Ltd"},
  {max_prefix: "BC-A3-7F",vendor: "Rail-Mil Sp. z o.o. Sp. K."},
  {max_prefix: "28-DF-EB",vendor: "Intel Corporate"},
  {max_prefix: "88-FC-A6",vendor: "devolo AG"},
  {max_prefix: "50-09-E5",vendor: "Drimsys,Inc"},
  {max_prefix: "20-3C-C0",vendor: "Beijing Tosee Technology Co., Ltd."},
  {max_prefix: "28-FB-AE",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "D8-A0-11",vendor: "WiZ"},
  {max_prefix: "A4-78-06",vendor: "Cisco Systems, Inc"},
  {max_prefix: "B8-D5-6B",vendor: "Mirka Ltd."},
  {max_prefix: "CC-B5-D1",vendor: "Beijing Xiaomi Mobile Software Co., Ltd"},
  {max_prefix: "78-D6-DC",vendor: "Motorola (Wuhan) Mobility Technologies Communication Co., Ltd."},
  {max_prefix: "0C-43-14",vendor: "Silicon Laboratories"},
  {max_prefix: "F4-C0-2F",vendor: "BlueBite"},
  {max_prefix: "EC-8A-C4",vendor: "Amazon Technologies Inc."},
  {max_prefix: "78-B8-4B",vendor: "Sichuan Tianyi Comheart Telecom Co.,LTD"},
  {max_prefix: "50-8A-06",vendor: "Tuya Smart Inc."},
  {max_prefix: "D4-08-68",vendor: "Beijing Lanxum Computer Technology CO.,LTD."},
  {max_prefix: "E8-DA-00",vendor: "Kivo Technology, Inc."},
  {max_prefix: "78-86-B6",vendor: "Shenzhen YOUHUA Technology Co., Ltd"},
  {max_prefix: "C4-80-25",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "B8-14-5C",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "C8-9D-18",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "DC-03-98",vendor: "LG Innotek"},
  {max_prefix: "10-38-1F",vendor: "Sichuan AI-Link Technology Co., Ltd."},
  {max_prefix: "54-14-F3",vendor: "Intel Corporate"},
  {max_prefix: "EC-B4-E8",vendor: "Wistron Mexico SA de CV"},
  {max_prefix: "30-A6-12",vendor: "ShenZhen Hugsun Technology Co.,Ltd."},
  {max_prefix: "18-BB-41",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "78-18-A8",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "80-72-64",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "E8-F6-54",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "20-87-EC",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "D4-41-65",vendor: "Sichuan Tianyi Comheart Telecom Co.,LTD"},
  {max_prefix: "7C-CC-1F",vendor: "Sichuan Tianyi Comheart Telecom Co.,LTD"},
  {max_prefix: "FC-37-2B",vendor: "Sichuan Tianyi Comheart Telecom Co.,LTD"},
  {max_prefix: "24-8B-E0",vendor: "Sichuan Tianyi Comheart Telecom Co.,LTD"},
  {max_prefix: "5C-4A-1F",vendor: "Sichuan Tianyi Comheart Telecom Co.,LTD"},
  {max_prefix: "54-E0-61",vendor: "Sichuan Tianyi Comheart Telecom Co.,LTD"},
  {max_prefix: "5C-C8-E3",vendor: "Shintec Hozumi co.ltd."},
  {max_prefix: "08-6A-C5",vendor: "Intel Corporate"},
  {max_prefix: "8C-36-7A",vendor: "Palo Alto Networks"},
  {max_prefix: "6C-FE-54",vendor: "Intel Corporate"},
  {max_prefix: "3C-19-5E",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "1C-FF-59",vendor: "Sichuan Tianyi Comheart Telecom Co.,LTD"},
  {max_prefix: "C0-1B-23",vendor: "Sichuan Tianyi Comheart Telecom Co.,LTD"},
  {max_prefix: "18-75-32",vendor: "Sichuan Tianyi Comheart Telecom Co.,LTD"},
  {max_prefix: "9C-9C-40",vendor: "Sichuan Tianyi Comheart Telecom Co.,LTD"},
  {max_prefix: "4C-3B-6C",vendor: "GARO AB"},
  {max_prefix: "68-DD-D9",vendor: "HMD Global Oy"},
  {max_prefix: "6C-94-66",vendor: "Intel Corporate"},
  {max_prefix: "54-F6-E2",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "A8-50-81",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "3C-7A-C4",vendor: "Chemtronics"},
  {max_prefix: "00-15-E5",vendor: "Cheertek Inc."},
  {max_prefix: "8C-83-FC",vendor: "Axioma Metering UAB"},
  {max_prefix: "00-1F-C8",vendor: "Up-Today Industrial Co., Ltd."},
  {max_prefix: "38-AF-D0",vendor: "Nevro"},
  {max_prefix: "FC-97-A8",vendor: "Cricut Inc."},
  {max_prefix: "A0-D7-A0",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "E0-DA-90",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "A4-A4-6B",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "C0-47-54",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "8C-D9-D6",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "60-A4-B7",vendor: "TP-Link Corporation Limited"},
  {max_prefix: "00-A0-0E",vendor: "NETSCOUT SYSTEMS INC"},
  {max_prefix: "A4-58-02",vendor: "SHIN-IL TECH"},
  {max_prefix: "38-F3-FB",vendor: "Asperiq"},
  {max_prefix: "50-AE-86",vendor: "Linkintec Co., Ltd"},
  {max_prefix: "60-15-92",vendor: "IEEE Registration Authority"},
  {max_prefix: "14-3B-42",vendor: "Realfit(Shenzhen) Intelligent Technology Co., Ltd"},
  {max_prefix: "94-3C-C6",vendor: "Espressif Inc."},
  {max_prefix: "A4-DA-D4",vendor: "Yamato Denki Co.,Ltd."},
  {max_prefix: "F4-B1-C2",vendor: "Zhejiang Dahua Technology Co., Ltd."},
  {max_prefix: "BC-FA-B8",vendor: "Guangzhou\u00a0Shiyuan\u00a0Electronic\u00a0Technology\u00a0Company\u00a0Limited"},
  {max_prefix: "A0-4A-5E",vendor: "Microsoft Corporation"},
  {max_prefix: "60-C5-E6",vendor: "Skullcandy"},
  {max_prefix: "10-94-97",vendor: "Logitech Hong Kong"},
  {max_prefix: "F0-2F-4B",vendor: "Apple, Inc."},
  {max_prefix: "40-E6-4B",vendor: "Apple, Inc."},
  {max_prefix: "EC-2E-98",vendor: "AzureWave Technology Inc."},
  {max_prefix: "D4-77-98",vendor: "Cisco Systems, Inc"},
  {max_prefix: "10-C9-CA",vendor: "Ace Technology Corp."},
  {max_prefix: "B4-FA-48",vendor: "Apple, Inc."},
  {max_prefix: "38-B3-F7",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "84-E9-86",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "AC-1F-0F",vendor: "Texas Instruments"},
  {max_prefix: "74-D2-85",vendor: "Texas Instruments"},
  {max_prefix: "80-44-FD",vendor: "China Mobile (Hangzhou) Information Technology Co., Ltd."},
  {max_prefix: "F4-6A-D7",vendor: "Microsoft Corporation"},
  {max_prefix: "48-1F-2D",vendor: "Shenzhen Jie Shi Lian Industrial Co.,LTD"},
  {max_prefix: "A8-E8-1E",vendor: "ATW TECHNOLOGY, INC."},
  {max_prefix: "14-09-B4",vendor: "zte corporation"},
  {max_prefix: "10-10-81",vendor: "zte corporation"},
  {max_prefix: "F8-1A-2B",vendor: "Google, Inc."},
  {max_prefix: "8C-73-A0",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "F4-A8-0D",vendor: "Wistron InfoComm(Kunshan)Co.,Ltd."},
  {max_prefix: "3C-A8-ED",vendor: "smart light technology"},
  {max_prefix: "F8-85-F9",vendor: "Calix Inc."},
  {max_prefix: "78-CF-F9",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "04-C2-9B",vendor: "Aura Home, Inc."},
  {max_prefix: "1C-87-E3",vendor: "TECNO MOBILE LIMITED"},
  {max_prefix: "20-B7-30",vendor: "TeconGroup, Inc"},
  {max_prefix: "50-97-07",vendor: "Xiamen Paperang Technology Co.,Ltd."},
  {max_prefix: "3C-E3-6B",vendor: "Zhejiang Dahua Technology Co., Ltd."},
  {max_prefix: "48-88-99",vendor: "Shenzhen SuperElectron Technology Co.,Ltd."},
  {max_prefix: "DC-B1-31",vendor: "SHENZHEN HUARUIAN TECHNOLOGY CO.,LTD"},
  {max_prefix: "F0-62-5A",vendor: "Realme Chongqing Mobile Telecommunications Corp.,Ltd."},
  {max_prefix: "84-3B-10",vendor: " LVSWITCHES INC."},
  {max_prefix: "94-A4-F9",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "6C-34-91",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "E8-4D-74",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "CC-89-5E",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "EC-63-D7",vendor: "Intel Corporate"},
  {max_prefix: "7C-9F-07",vendor: "CIG SHANGHAI CO LTD"},
  {max_prefix: "20-3B-69",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "5C-17-20",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "60-5E-4F",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "1C-E6-AD",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "14-85-7F",vendor: "Intel Corporate"},
  {max_prefix: "94-E2-3C",vendor: "Intel Corporate"},
  {max_prefix: "58-56-C2",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "A0-36-79",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "B8-D6-F6",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "2C-52-AF",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "48-68-4A",vendor: "Intel Corporate"},
  {max_prefix: "58-FB-96",vendor: "Ruckus Wireless"},
  {max_prefix: "28-5B-0C",vendor: "Sichuan Jiuzhou Electronic Technology Co., Ltd."},
  {max_prefix: "14-89-CB",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "6C-26-36",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "40-DE-17",vendor: "Shenzhen Lanfeng Times Industrial Co.,Ltd."},
  {max_prefix: "AC-23-16",vendor: "Mist Systems, Inc."},
  {max_prefix: "00-24-70",vendor: "AUROTECH ultrasound AS."},
  {max_prefix: "BC-F1-71",vendor: "Intel Corporate"},
  {max_prefix: "84-71-6A",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "48-8C-63",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "70-DD-EF",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "54-A6-DB",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "14-98-77",vendor: "Apple, Inc."},
  {max_prefix: "88-66-5A",vendor: "Apple, Inc."},
  {max_prefix: "B0-E5-F9",vendor: "Apple, Inc."},
  {max_prefix: "B8-8D-F1",vendor: "Nanjing BigFish Semiconductor Co., Ltd."},
  {max_prefix: "6C-4A-74",vendor: "AERODISK LLC"},
  {max_prefix: "14-EB-08",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "B0-16-56",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "78-D4-F1",vendor: "IEEE Registration Authority"},
  {max_prefix: "DC-90-20",vendor: "RURU TEK PRIVATE LIMITED"},
  {max_prefix: "A8-42-A7",vendor: "Jiangsu Huitong Group Co.,Ltd."},
  {max_prefix: "2C-D2-6B",vendor: "FN-LINK TECHNOLOGY LIMITED"},
  {max_prefix: "A0-62-FB",vendor: "HISENSE VISUAL TECHNOLOGY CO.,LTD"},
  {max_prefix: "9C-68-65",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "38-3D-5B",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "24-ED-FD",vendor: "Siemens Canada Limited"},
  {max_prefix: "6C-CD-D6",vendor: "NETGEAR"},
  {max_prefix: "54-07-64",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "DC-D7-A0",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "F0-16-28",vendor: "Technicolor (China) Technology Co., Ltd."},
  {max_prefix: "F8-8F-07",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "8C-EA-48",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "00-5F-67",vendor: "TP-Link Corporation Limited"},
  {max_prefix: "50-3D-C6",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "B0-BD-1B",vendor: "Dongguan Liesheng Electronic Co., Ltd."},
  {max_prefix: "D4-EC-AB",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "BC-3E-CB",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "08-79-8C",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "5C-9A-A1",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "BC-F4-5F",vendor: "zte corporation"},
  {max_prefix: "60-55-F9",vendor: "Espressif Inc."},
  {max_prefix: "54-8A-BA",vendor: "Cisco Systems, Inc"},
  {max_prefix: "64-A1-98",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "14-18-C3",vendor: "Intel Corporate"},
  {max_prefix: "54-DB-A2",vendor: "Fibrain"},
  {max_prefix: "44-AE-25",vendor: "Cisco Systems, Inc"},
  {max_prefix: "BC-E7-12",vendor: "Cisco Systems, Inc"},
  {max_prefix: "CC-CC-81",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "40-89-A8",vendor: "WiredIQ, LLC"},
  {max_prefix: "3C-20-93",vendor: "GD Midea Air-Conditioning Equipment Co.,Ltd."},
  {max_prefix: "E0-29-67",vendor: "HMD Global Oy"},
  {max_prefix: "98-8B-69",vendor: "Shenzhen hylitech Co.,LTD"},
  {max_prefix: "18-14-6C",vendor: "Zhejiang Tmall Technology Co., Ltd."},
  {max_prefix: "68-89-75",vendor: "nuoxc"},
  {max_prefix: "A8-99-DC",vendor: "i-TOP DESING TECHNOLOGY CO.,LTD"},
  {max_prefix: "00-1D-1E",vendor: "KYUSHU TEN CO.,LTD"},
  {max_prefix: "50-C3-A2",vendor: "nFore Technology Co.,Ltd."},
  {max_prefix: "1C-D1-BA",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "80-4B-50",vendor: "Silicon Laboratories"},
  {max_prefix: "C0-E3-FB",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "30-E2-83",vendor: "Texas Instruments"},
  {max_prefix: "34-AF-B3",vendor: "Amazon Technologies Inc."},
  {max_prefix: "68-3F-7D",vendor: "INGRAM MICRO SERVICES"},
  {max_prefix: "94-5F-34",vendor: "Renesas Electronics (Penang) Sdn. Bhd."},
  {max_prefix: "60-3C-EE",vendor: "LG Electronics (Mobile Communications)"},
  {max_prefix: "2C-4A-11",vendor: "Ciena Corporation"},
  {max_prefix: "20-AC-9C",vendor: "China Telecom Corporation Limited"},
  {max_prefix: "28-AD-18",vendor: "Hui Zhou Gaoshengda Technology Co.,LTD"},
  {max_prefix: "0C-35-4F",vendor: "Nokia"},
  {max_prefix: "C4-CB-54",vendor: "Fibocom Auto Inc."},
  {max_prefix: "30-24-A9",vendor: "HP Inc."},
  {max_prefix: "94-09-D3",vendor: "shenzhen maxtopic technology co.,ltd"},
  {max_prefix: "F8-A7-3A",vendor: "Cisco Systems, Inc"},
  {max_prefix: "B8-11-4B",vendor: "Cisco Systems, Inc"},
  {max_prefix: "1C-4C-48",vendor: "ITEL MOBILE LIMITED"},
  {max_prefix: "2C-07-86",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "A8-6E-4E",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "10-2D-31",vendor: "Shenzhen Americas Trading Company LLC"},
  {max_prefix: "78-2E-56",vendor: "China Mobile Group Device Co.,Ltd."},
  {max_prefix: "C4-0B-31",vendor: "Apple, Inc."},
  {max_prefix: "8C-7A-15",vendor: "Ruckus Wireless"},
  {max_prefix: "64-20-E0",vendor: "T3 Technology Co., Ltd."},
  {max_prefix: "84-11-C2",vendor: "IEEE Registration Authority"},
  {max_prefix: "90-DE-80",vendor: "Shenzhen Century Xinyang Technology Co., Ltd"},
  {max_prefix: "10-96-93",vendor: "Amazon Technologies Inc."},
  {max_prefix: "E4-F1-D4",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "5C-C0-A0",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "04-F3-52",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "48-8B-0A",vendor: "Cisco Systems, Inc"},
  {max_prefix: "18-5B-B3",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "9C-5F-B0",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "E8-7F-6B",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "EC-A1-D1",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "A4-6D-A4",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "24-A4-87",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "C4-5A-86",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "FC-B6-9D",vendor: "Zhejiang Dahua Technology Co., Ltd."},
  {max_prefix: "94-F2-BB",vendor: "Valeo Vision Systems"},
  {max_prefix: "B0-EC-DD",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "6C-14-14",vendor: "BUJEON ELECTRONICS Co,.Ltd"},
  {max_prefix: "AC-67-84",vendor: "Google, Inc."},
  {max_prefix: "A8-B0-88",vendor: "eero inc."},
  {max_prefix: "EC-7E-91",vendor: "ITEL MOBILE LIMITED"},
  {max_prefix: "E0-E1-A9",vendor: "Shenzhen Four Seas Global Link Network Technology Co., Ltd."},
  {max_prefix: "7C-4E-09",vendor: "Shenzhen Skyworth Wireless Technology Co.,Ltd"},
  {max_prefix: "28-0F-C5",vendor: "Beijing Leadsec Technology Co., Ltd."},
  {max_prefix: "1C-EC-72",vendor: "Allradio Co., Ltd"},
  {max_prefix: "E4-DC-43",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "24-30-F8",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "C4-3C-EA",vendor: "BUFFALO.INC"},
  {max_prefix: "D4-91-0F",vendor: "Amazon Technologies Inc."},
  {max_prefix: "80-F5-B5",vendor: "Texas Instruments"},
  {max_prefix: "1C-30-08",vendor: "Hui Zhou Gaoshengda Technology Co.,LTD"},
  {max_prefix: "98-06-3A",vendor: "Home Control Singapore Pte Ltd"},
  {max_prefix: "64-A2-00",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "70-3A-2D",vendor: "Shenzhen V-Link Technology CO., LTD."},
  {max_prefix: "1C-45-C2",vendor: "Huizhou City Sunsin lntelligent Technology Co.,Ltd"},
  {max_prefix: "84-CC-63",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "C0-78-31",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "C4-FB-AA",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "B0-A6-51",vendor: "Cisco Systems, Inc"},
  {max_prefix: "18-36-72",vendor: "Shaoxing ShunChuang Technology CO.,LTD"},
  {max_prefix: "00-17-C9",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "5C-F9-FD",vendor: "Taicang T&W Electronics"},
  {max_prefix: "38-98-E9",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "48-A5-16",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "AC-DC-CA",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "B8-5F-B0",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "B4-BA-12",vendor: "China Mobile (Hangzhou) Information Technology Co.,Ltd."},
  {max_prefix: "E0-E0-C2",vendor: "China Mobile Group Device Co.,Ltd."},
  {max_prefix: "6C-1E-D7",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "F0-AA-0B",vendor: "Arra Networks/ Spectramesh"},
  {max_prefix: "94-56-41",vendor: "Palo Alto Networks"},
  {max_prefix: "EC-C3-02",vendor: "HUMAX Co., Ltd."},
  {max_prefix: "C4-DD-57",vendor: "Espressif Inc."},
  {max_prefix: "00-C3-43",vendor: "E-T-A Circuit Breakers Ltd"},
  {max_prefix: "A0-D0-DC",vendor: "Amazon Technologies Inc."},
  {max_prefix: "14-3F-C3",vendor: "SnapAV"},
  {max_prefix: "20-9A-7D",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "50-C6-8E",vendor: "Biwin Semiconductor (HK) Company Limted"},
  {max_prefix: "E4-75-DC",vendor: "Arcadyan Corporation"},
  {max_prefix: "58-20-8A",vendor: "IEEE Registration Authority"},
  {max_prefix: "BC-A5-A9",vendor: "Apple, Inc."},
  {max_prefix: "10-27-79",vendor: "Sadel S.p.A."},
  {max_prefix: "80-CA-4B",vendor: "SHENZHEN GONGJIN ELECTRONICS CO.,LTD"},
  {max_prefix: "C4-16-88",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "64-B0-E8",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "30-A9-98",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "64-F5-4E",vendor: "EM Microelectronic"},
  {max_prefix: "04-E7-95",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "20-E2-A8",vendor: "Apple, Inc."},
  {max_prefix: "A0-FB-C5",vendor: "Apple, Inc."},
  {max_prefix: "00-7D-60",vendor: "Apple, Inc."},
  {max_prefix: "74-42-7F",vendor: "AVM Audiovisuelles Marketing und Computersysteme GmbH"},
  {max_prefix: "00-1C-45",vendor: "Chenbro Micom Co., Ltd."},
  {max_prefix: "C0-61-9A",vendor: "IEEE Registration Authority"},
  {max_prefix: "28-C2-1F",vendor: "SAMSUNG ELECTRO-MECHANICS(THAILAND)"},
  {max_prefix: "8C-70-86",vendor: "Gesellschaft f\u00fcr Sonder-EDV-Anlagen mbH"},
  {max_prefix: "40-06-34",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "C4-2B-44",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "F8-A2-6D",vendor: "CANON INC."},
  {max_prefix: "68-9E-0B",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-42-38",vendor: "Intel Corporate"},
  {max_prefix: "74-F9-CA",vendor: "Nintendo Co.,Ltd"},
  {max_prefix: "70-61-EE",vendor: "Sunwoda Electronic Co.,Ltd"},
  {max_prefix: "00-19-DC",vendor: "ENENSYS Technologies"},
  {max_prefix: "B0-7D-64",vendor: "Intel Corporate"},
  {max_prefix: "48-90-2F",vendor: "LG Electronics (Mobile Communications)"},
  {max_prefix: "4C-22-19",vendor: "YUANFUDAO HK LIMTED"},
  {max_prefix: "E4-24-6C",vendor: "Zhejiang Dahua Technology Co., Ltd."},
  {max_prefix: "E8-EB-1B",vendor: "Microchip Technology Inc."},
  {max_prefix: "38-BA-B0",vendor: "Broadcom"},
  {max_prefix: "B0-A4-60",vendor: "Intel Corporate"},
  {max_prefix: "00-00-83",vendor: "TADPOLE TECHNOLOGY PLC"},
  {max_prefix: "28-B7-7C",vendor: "IEEE Registration Authority"},
  {max_prefix: "00-14-6A",vendor: "Cisco Systems, Inc"},
  {max_prefix: "68-D6-ED",vendor: "GooWi Wireless Technology Co., Limited"},
  {max_prefix: "84-02-83",vendor: "HUMAX Co., Ltd."},
  {max_prefix: "94-17-00",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "D8-EF-42",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "80-CC-12",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "18-AA-0F",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "54-D9-C6",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "30-8A-F7",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "64-E7-D8",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "00-E4-06",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "44-22-7C",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "CC-B1-82",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "08-9B-B9",vendor: "Nokia Solutions and Networks GmbH & Co. KG"},
  {max_prefix: "D8-9E-D4",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "40-D2-5F",vendor: "ITEL MOBILE LIMITED"},
  {max_prefix: "54-F1-5F",vendor: "Sichuan AI-Link Technology Co., Ltd."},
  {max_prefix: "E0-79-C4",vendor: "iRay Technology Company Limited"},
  {max_prefix: "AC-95-72",vendor: "Jovision Technology Co., Ltd."},
  {max_prefix: "00-17-53",vendor: "nFore Technology Inc."},
  {max_prefix: "00-16-36",vendor: "Quanta Computer Inc."},
  {max_prefix: "88-40-67",vendor: "infomark"},
  {max_prefix: "A8-03-2A",vendor: "Espressif Inc."},
  {max_prefix: "08-38-69",vendor: "Hong Kong AMobile Intelligent Corp. Limited Taiwan Branch"},
  {max_prefix: "24-81-C7",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "FC-86-2A",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "AC-9A-96",vendor: "Maxlinear, Inc"},
  {max_prefix: "48-43-DD",vendor: "Amazon Technologies Inc."},
  {max_prefix: "58-94-A2",vendor: "KETEK GmbH"},
  {max_prefix: "00-1A-57",vendor: "Matrix Design Group, LLC"},
  {max_prefix: "00-1C-FC",vendor: "Sumitomo Electric Industries, Ltd"},
  {max_prefix: "EC-75-3E",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "24-A1-60",vendor: "Espressif Inc."},
  {max_prefix: "DC-AE-EB",vendor: "Ruckus Wireless"},
  {max_prefix: "88-29-49",vendor: "Renesas Electronics (Penang) Sdn. Bhd."},
  {max_prefix: "EC-DB-86",vendor: "API-K"},
  {max_prefix: "3C-84-6A",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "84-D8-1B",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "7C-2A-DB",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "F8-64-B8",vendor: "zte corporation"},
  {max_prefix: "14-51-20",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "C0-D1-93",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "78-04-E3",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "A4-3B-0E",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "90-91-64",vendor: "ChongQing Lavid Technology Co., Ltd."},
  {max_prefix: "1C-3D-2F",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "00-1D-67",vendor: "AMEC"},
  {max_prefix: "00-1A-62",vendor: "Data Robotics, Incorporated"},
  {max_prefix: "C0-63-69",vendor: "BINXIN TECHNOLOGY(ZHEJIANG) LTD."},
  {max_prefix: "18-41-FE",vendor: "Digital 14"},
  {max_prefix: "08-B0-A7",vendor: "Truebeyond Co., Ltd"},
  {max_prefix: "18-AB-1D",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "BC-E9-2F",vendor: "HP Inc."},
  {max_prefix: "E0-92-A7",vendor: "Feitian Technologies Co., Ltd"},
  {max_prefix: "B0-76-1B",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "8C-0E-60",vendor: "Nanjing Juplink Intelligent Technologies Co., Ltd."},
  {max_prefix: "DC-91-BF",vendor: "Amazon Technologies Inc."},
  {max_prefix: "6C-CE-44",vendor: "1MORE"},
  {max_prefix: "94-44-44",vendor: "LG Innotek"},
  {max_prefix: "34-0A-33",vendor: "D-Link International"},
  {max_prefix: "84-37-D5",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "34-82-C5",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "F0-D7-AF",vendor: "IEEE Registration Authority"},
  {max_prefix: "7C-A9-6B",vendor: "Syrotech Networks. Ltd."},
  {max_prefix: "98-06-3C",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "74-A7-EA",vendor: "Amazon Technologies Inc."},
  {max_prefix: "7C-6D-F8",vendor: "Apple, Inc."},
  {max_prefix: "50-AD-71",vendor: "Tessolve Semiconductor Private Limited"},
  {max_prefix: "5C-A5-BC",vendor: "eero inc."},
  {max_prefix: "D8-4F-37",vendor: "Proxis, spol. s r.o."},
  {max_prefix: "74-AB-93",vendor: "Blink by Amazon"},
  {max_prefix: "E8-7F-95",vendor: "Apple, Inc."},
  {max_prefix: "78-11-00",vendor: "Quantumsolution"},
  {max_prefix: "88-C0-8B",vendor: "Apple, Inc."},
  {max_prefix: "4C-7C-D9",vendor: "Apple, Inc."},
  {max_prefix: "94-08-53",vendor: "Liteon Technology Corporation"},
  {max_prefix: "B4-9E-80",vendor: "Sichuan Changhong Electric Ltd."},
  {max_prefix: "A4-93-40",vendor: "Beijing Supvan Information Technology Co.,Ltd."},
  {max_prefix: "F8-E8-77",vendor: "Harman/Becker Automotive Systems GmbH"},
  {max_prefix: "A0-9B-12",vendor: "China Mobile IOT Company Limited"},
  {max_prefix: "98-F6-21",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "14-13-FB",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "D0-76-8F",vendor: "Calix Inc."},
  {max_prefix: "00-E2-2C",vendor: "China Mobile Group Device Co.,Ltd."},
  {max_prefix: "C0-39-5A",vendor: "Zhejiang Dahua Technology Co., Ltd."},
  {max_prefix: "20-64-CB",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "F0-55-01",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "EC-6C-B5",vendor: "zte corporation"},
  {max_prefix: "C0-B1-01",vendor: "zte corporation"},
  {max_prefix: "A4-50-06",vendor: "SHENZHEN HUACHUANG SHIDAI TECHNOLOGYCO.,LTD"},
  {max_prefix: "AC-3C-8E",vendor: "Flextronics Computing(Suzhou)Co.,Ltd."},
  {max_prefix: "40-62-34",vendor: "Telink Semiconductor (Shanghai) Co., Ltd."},
  {max_prefix: "94-FB-A7",vendor: "IEEE Registration Authority"},
  {max_prefix: "FC-3D-A5",vendor: "Arcadyan Corporation"},
  {max_prefix: "C8-71-25",vendor: "Johnson Outdoors Marine Electronics d/b/a Minnkota"},
  {max_prefix: "80-CF-A2",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "00-94-EC",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "74-45-2D",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "14-01-52",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "DC-1B-A1",vendor: "Intel Corporate"},
  {max_prefix: "BC-33-AC",vendor: "Silicon Laboratories"},
  {max_prefix: "00-13-29",vendor: "VSST Co., LTD"},
  {max_prefix: "7C-F2-DD",vendor: "Vence Corp"},
  {max_prefix: "98-8E-79",vendor: "Qudelix, Inc."},
  {max_prefix: "C8-07-39",vendor: "NAKAYO Inc"},
  {max_prefix: "6C-6D-09",vendor: "Kyowa Electronics Co.,Ltd."},
  {max_prefix: "08-03-42",vendor: "Palo Alto Networks"},
  {max_prefix: "A8-6A-BB",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "24-43-E2",vendor: "DASAN Network Solutions"},
  {max_prefix: "EC-23-7B",vendor: "zte corporation"},
  {max_prefix: "D4-9C-F4",vendor: "Palo Alto Networks"},
  {max_prefix: "CC-AB-2C",vendor: "HUMAX Co., Ltd."},
  {max_prefix: "54-0E-2D",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "70-8F-47",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "90-17-3F",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "60-7E-CD",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "00-18-76",vendor: "WowWee Ltd."},
  {max_prefix: "C4-24-56",vendor: "Palo Alto Networks"},
  {max_prefix: "84-D4-12",vendor: "Palo Alto Networks"},
  {max_prefix: "B8-2F-CB",vendor: "CMS Electracom"},
  {max_prefix: "4C-FC-AA",vendor: "Tesla,Inc."},
  {max_prefix: "10-CE-45",vendor: "Miromico AG"},
  {max_prefix: "8C-59-DC",vendor: "ASR Microelectronics (Shanghai) Co., Ltd."},
  {max_prefix: "18-82-8C",vendor: "Arcadyan Corporation"},
  {max_prefix: "D4-52-EE",vendor: "SKY UK LIMITED"},
  {max_prefix: "6C-61-F4",vendor: "SFR"},
  {max_prefix: "9C-F0-29",vendor: "Integrated Device Technology (Malaysia) Sdn. Bhd."},
  {max_prefix: "28-56-C1",vendor: "Harman/Becker Automotive Systems GmbH"},
  {max_prefix: "BC-4A-56",vendor: "Cisco Systems, Inc"},
  {max_prefix: "F8-AF-05",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "94-E9-EE",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "64-BC-58",vendor: "Intel Corporate"},
  {max_prefix: "AC-12-03",vendor: "Intel Corporate"},
  {max_prefix: "28-E3-4E",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "78-B8-D6",vendor: "Zebra Technologies Inc."},
  {max_prefix: "F4-90-CB",vendor: "IEEE Registration Authority"},
  {max_prefix: "18-58-69",vendor: "Sailer Electronic Co., Ltd"},
  {max_prefix: "BC-2D-EF",vendor: "Realme Chongqing Mobile Telecommunications Corp.,Ltd."},
  {max_prefix: "78-81-CE",vendor: "China Mobile Iot Limited company"},
  {max_prefix: "44-5C-E9",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "C0-16-92",vendor: "China Mobile Group Device Co.,Ltd."},
  {max_prefix: "BC-FF-21",vendor: "Smart Code(shenzhen)Technology Co.,Ltd"},
  {max_prefix: "38-17-30",vendor: "Ulrich Lippert GmbH & Co KG"},
  {max_prefix: "98-C8-B8",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "40-2E-71",vendor: "Texas Instruments"},
  {max_prefix: "F0-81-75",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "1C-63-BF",vendor: "SHENZHEN BROADTEL  TELECOM CO.,LTD"},
  {max_prefix: "AC-36-51",vendor: "Jiangsu Hengtong Terahertz Technology Co., Ltd."},
  {max_prefix: "68-4A-76",vendor: "eero inc."},
  {max_prefix: "8C-68-3A",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "B4-6E-08",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "28-64-B0",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "04-F1-69",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "50-21-EC",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "0C-48-C6",vendor: "CELESTICA INC."},
  {max_prefix: "A4-29-85",vendor: "Sichuan AI-Link Technology Co., Ltd."},
  {max_prefix: "B4-81-07",vendor: "SHENZHEN CHUANGWEI-RGB ELECTRONICS CO.,LTD"},
  {max_prefix: "70-66-55",vendor: "AzureWave Technology Inc."},
  {max_prefix: "C8-58-C0",vendor: "Intel Corporate"},
  {max_prefix: "4C-B1-6C",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "BC-09-63",vendor: "Apple, Inc."},
  {max_prefix: "D8-4C-90",vendor: "Apple, Inc."},
  {max_prefix: "24-D0-DF",vendor: "Apple, Inc."},
  {max_prefix: "6C-4A-85",vendor: "Apple, Inc."},
  {max_prefix: "28-F0-33",vendor: "Apple, Inc."},
  {max_prefix: "00-5E-0C",vendor: "HMD Global Oy"},
  {max_prefix: "68-8F-C9",vendor: "Zhuolian (Shenzhen) Communication Co., Ltd"},
  {max_prefix: "D8-47-32",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "A0-43-B0",vendor: "Hangzhou BroadLink Technology Co.,Ltd"},
  {max_prefix: "D8-C5-61",vendor: "CommFront Communications Pte Ltd"},
  {max_prefix: "60-D8-9C",vendor: "HMD Global Oy"},
  {max_prefix: "F4-1C-95",vendor: "BEIJING YUNYI TIMES TECHNOLOGY CO,.LTD"},
  {max_prefix: "9C-97-89",vendor: "1MORE"},
  {max_prefix: "F8-2E-8E",vendor: "Nanjing Kechen Electric Co., Ltd."},
  {max_prefix: "B4-C9-B9",vendor: "Sichuan AI-Link Technology Co., Ltd."},
  {max_prefix: "F0-46-3B",vendor: "Comcast Cable Corporation"},
  {max_prefix: "C4-02-E1",vendor: "Khwahish Technologies Private Limited"},
  {max_prefix: "4C-6C-13",vendor: "IoT Company Solucoes Tecnologicas Ltda"},
  {max_prefix: "00-09-F3",vendor: "WELL Communication Corp."},
  {max_prefix: "C8-FA-84",vendor: "Trusonus corp."},
  {max_prefix: "28-63-BD",vendor: "APTIV SERVICES US, LLC"},
  {max_prefix: "F8-5B-3B",vendor: "ASKEY COMPUTER CORP"},
  {max_prefix: "A8-85-D7",vendor: "Sangfor Technologies Inc."},
  {max_prefix: "78-6D-EB",vendor: "GE Lighting"},
  {max_prefix: "9C-A5-13",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "30-90-48",vendor: "Apple, Inc."},
  {max_prefix: "44-4A-DB",vendor: "Apple, Inc."},
  {max_prefix: "8C-5A-C1",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "A8-5A-E0",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "A4-B6-1E",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "C4-FE-5B",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "84-F8-83",vendor: "Luminar Technologies"},
  {max_prefix: "B4-4C-3B",vendor: "Zhejiang Dahua Technology Co., Ltd."},
  {max_prefix: "40-A2-DB",vendor: "Amazon Technologies Inc."},
  {max_prefix: "C0-39-37",vendor: "GREE ELECTRIC APPLIANCES, INC. OF ZHUHAI"},
  {max_prefix: "C4-67-D1",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "94-24-B8",vendor: "GREE ELECTRIC APPLIANCES, INC. OF ZHUHAI"},
  {max_prefix: "CC-F4-11",vendor: "Google, Inc."},
  {max_prefix: "9C-2D-CF",vendor: "Shishi Tongyun Technology(Chengdu)Co.,Ltd."},
  {max_prefix: "04-33-C2",vendor: "Intel Corporate"},
  {max_prefix: "D4-9A-A0",vendor: "VNPT TECHNOLOGY"},
  {max_prefix: "C8-C4-65",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "1C-43-63",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "94-29-2F",vendor: "New H3C Technologies Co., Ltd"},
  {max_prefix: "6C-F0-49",vendor: "GIGA-BYTE TECHNOLOGY CO.,LTD."},
  {max_prefix: "FC-AA-14",vendor: "GIGA-BYTE TECHNOLOGY CO.,LTD."},
  {max_prefix: "F8-0F-F9",vendor: "Google, Inc."},
  {max_prefix: "44-46-87",vendor: "Realme Chongqing MobileTelecommunications Corp Ltd"},
  {max_prefix: "E0-F4-42",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "F0-C4-2F",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "C0-B4-7D",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "F4-A5-9D",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "30-7E-CB",vendor: "SFR"},
  {max_prefix: "C8-03-F5",vendor: "Ruckus Wireless"},
  {max_prefix: "E8-1B-4B",vendor: "amnimo Inc."},
  {max_prefix: "A4-B4-39",vendor: "Cisco Systems, Inc"},
  {max_prefix: "A0-B4-39",vendor: "Cisco Systems, Inc"},
  {max_prefix: "10-5F-D4",vendor: "Tendyron Corporation"},
  {max_prefix: "B4-26-5D",vendor: "Taicang T&W Electronics"},
  {max_prefix: "48-A5-E7",vendor: "Nintendo Co.,Ltd"},
  {max_prefix: "B8-19-04",vendor: "Nokia Shanghai Bell Co., Ltd."},
  {max_prefix: "B4-A5-AC",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "1C-78-4E",vendor: "China Mobile Iot Limited company"},
  {max_prefix: "D4-B7-09",vendor: "zte corporation"},
  {max_prefix: "38-14-4E",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "E0-CC-F8",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "AC-67-5D",vendor: "Intel Corporate"},
  {max_prefix: "84-C5-A6",vendor: "Intel Corporate"},
  {max_prefix: "5C-80-B6",vendor: "Intel Corporate"},
  {max_prefix: "F0-78-07",vendor: "Apple, Inc."},
  {max_prefix: "08-2C-B6",vendor: "Apple, Inc."},
  {max_prefix: "F8-4E-73",vendor: "Apple, Inc."},
  {max_prefix: "3C-CD-36",vendor: "Apple, Inc."},
  {max_prefix: "84-3E-79",vendor: "Shenzhen Belon Technology CO.,LTD"},
  {max_prefix: "F8-E4-E3",vendor: "Intel Corporate"},
  {max_prefix: "D8-CF-89",vendor: "Beijing DoSee Science and Technology Co., Ltd."},
  {max_prefix: "04-AA-E1",vendor: "BEIJING MICROVISION TECHNOLOGY CO.,LTD"},
  {max_prefix: "44-DC-4E",vendor: "ITEL MOBILE LIMITED"},
  {max_prefix: "B4-E8-C9",vendor: "XADA Technologies"},
  {max_prefix: "6C-24-A6",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "9C-5F-5A",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "A0-1C-8D",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "F4-DE-AF",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "60-12-3C",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "C0-D2-DD",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "94-2D-DC",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "7C-B3-7B",vendor: "Qingdao Intelligent&Precise Electronics Co.,Ltd."},
  {max_prefix: "88-12-3D",vendor: "Suzhou Aquila Solutions Inc."},
  {max_prefix: "48-21-0B",vendor: "PEGATRON CORPORATION"},
  {max_prefix: "00-68-EB",vendor: "HP Inc."},
  {max_prefix: "7C-31-0E",vendor: "Cisco Systems, Inc"},
  {max_prefix: "48-4C-86",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "54-F2-94",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "24-5A-B5",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "38-2A-19",vendor: "Technica Engineering GmbH"},
  {max_prefix: "74-D6-54",vendor: "GINT"},
  {max_prefix: "7C-21-0E",vendor: "Cisco Systems, Inc"},
  {max_prefix: "F4-E5-F2",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "54-13-10",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "8C-E5-EF",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "F0-EF-86",vendor: "Google, Inc."},
  {max_prefix: "E4-C0-CC",vendor: "China Mobile Group Device Co.,Ltd."},
  {max_prefix: "A0-94-6A",vendor: "Shenzhen XGTEC Technology Co,.Ltd."},
  {max_prefix: "1C-2A-A3",vendor: "Shenzhen HongRui Optical Technology Co., Ltd."},
  {max_prefix: "38-8E-7A",vendor: "AUTOIT"},
  {max_prefix: "9C-31-C3",vendor: "SKY UK LIMITED"},
  {max_prefix: "5C-B1-3E",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "E4-AA-EA",vendor: "Liteon Technology Corporation"},
  {max_prefix: "4C-71-0D",vendor: "Cisco Systems, Inc"},
  {max_prefix: "4C-71-0C",vendor: "Cisco Systems, Inc"},
  {max_prefix: "A4-CD-23",vendor: "Shenzhenshi Xinzhongxin  Co., Ltd"},
  {max_prefix: "E0-1F-0A",vendor: "Xslent Energy Technologies. LLC"},
  {max_prefix: "E4-47-B3",vendor: "zte corporation"},
  {max_prefix: "FC-DB-21",vendor: "SAMSARA NETWORKS INC"},
  {max_prefix: "E4-67-1E",vendor: "SHEN ZHEN NUO XIN CHENG TECHNOLOGY co., Ltd."},
  {max_prefix: "68-27-19",vendor: "Microchip Technology Inc."},
  {max_prefix: "24-C1-7A",vendor: "BEIJING IACTIVE NETWORK CO.,LTD"},
  {max_prefix: "A4-C9-39",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "34-D7-72",vendor: "Xiamen Yudian Automation Technology Co., Ltd"},
  {max_prefix: "3C-86-D1",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "30-1B-97",vendor: "Lierda Science & Technology Group Co.,Ltd"},
  {max_prefix: "48-79-4D",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "18-F9-C4",vendor: "BAE Systems"},
  {max_prefix: "60-AB-D2",vendor: "Bose Corporation"},
  {max_prefix: "C0-DC-DA",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "04-B4-29",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "7C-8A-E1",vendor: "COMPAL INFORMATION (KUNSHAN) CO., LTD."},
  {max_prefix: "54-E4-A9",vendor: "BHR Tech GmbH"},
  {max_prefix: "20-80-58",vendor: "Ciena Corporation"},
  {max_prefix: "74-DA-88",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "CC-32-E5",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "1C-3B-F3",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "2C-00-F7",vendor: "XOS"},
  {max_prefix: "2C-D2-E3",vendor: "Guangzhou Aoshi Electronic Co.,Ltd"},
  {max_prefix: "A8-C2-52",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "A0-41-47",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "14-59-C3",vendor: "Creative Chips GmbH"},
  {max_prefix: "1C-CC-D6",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "A4-45-CD",vendor: "IoT Diagnostics"},
  {max_prefix: "7C-C9-26",vendor: "Wuhan GreeNet Information Service Co.,Ltd."},
  {max_prefix: "68-07-0A",vendor: "TPVision Europe B.V"},
  {max_prefix: "4C-EB-BD",vendor: "CHONGQING FUGUI ELECTRONICS CO.,LTD."},
  {max_prefix: "70-87-9E",vendor: "Beken Corporation"},
  {max_prefix: "68-4A-AE",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "60-D7-55",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "1C-AE-CB",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "4C-F1-9E",vendor: "Groupe Atlantic"},
  {max_prefix: "04-ED-33",vendor: "Intel Corporate"},
  {max_prefix: "20-36-D7",vendor: "Shanghai Reacheng  Communication Technology Co.,Ltd"},
  {max_prefix: "04-4A-6C",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "38-FB-14",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "F0-E4-A2",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "B4-50-62",vendor: "EmBestor Technology Inc."},
  {max_prefix: "5C-75-AF",vendor: "Fitbit, Inc."},
  {max_prefix: "94-D5-05",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "7C-B2-7D",vendor: "Intel Corporate"},
  {max_prefix: "58-20-59",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "90-27-2B",vendor: "Algorab S.r.l."},
  {max_prefix: "4C-BC-B4",vendor: "ABB SpA - DIN Rail"},
  {max_prefix: "10-63-C8",vendor: "Liteon Technology Corporation"},
  {max_prefix: "7C-51-89",vendor: "SG Wireless Limited"},
  {max_prefix: "08-26-97",vendor: "Zyxel Communications Corporation"},
  {max_prefix: "78-69-D4",vendor: "Shenyang Vibrotech Instruments Inc."},
  {max_prefix: "5C-D1-35",vendor: "Xtreme Power Systems"},
  {max_prefix: "74-E1-B6",vendor: "Apple, Inc."},
  {max_prefix: "24-A5-2C",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "48-27-59",vendor: "Levven Electronics Ltd."},
  {max_prefix: "64-CB-9F",vendor: "TECNO MOBILE LIMITED"},
  {max_prefix: "4C-FB-FE",vendor: "Sercomm Japan Corporation"},
  {max_prefix: "C0-CB-F1",vendor: "Mobiwire Mobiles (NingBo) Co., LTD"},
  {max_prefix: "FC-7D-6C",vendor: "HYESUNG TECHWIN Co., Ltd"},
  {max_prefix: "1C-D5-E2",vendor: "Shenzhen YOUHUA Technology Co., Ltd"},
  {max_prefix: "00-24-E9",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "68-3B-78",vendor: "Cisco Systems, Inc"},
  {max_prefix: "F4-CE-36",vendor: "Nordic Semiconductor ASA"},
  {max_prefix: "AC-77-13",vendor: "Honeywell Safety Products (Shanghai) Co.,Ltd"},
  {max_prefix: "08-84-9D",vendor: "Amazon Technologies Inc."},
  {max_prefix: "24-40-AE",vendor: "NIIC Technology Co., Ltd."},
  {max_prefix: "F4-0E-01",vendor: "Apple, Inc."},
  {max_prefix: "14-95-CE",vendor: "Apple, Inc."},
  {max_prefix: "50-DE-06",vendor: "Apple, Inc."},
  {max_prefix: "CC-66-0A",vendor: "Apple, Inc."},
  {max_prefix: "FC-1D-43",vendor: "Apple, Inc."},
  {max_prefix: "18-A4-A9",vendor: "Vanu Inc."},
  {max_prefix: "80-E8-2C",vendor: "Hewlett Packard"},
  {max_prefix: "D4-AD-BD",vendor: "Cisco Systems, Inc"},
  {max_prefix: "CC-CC-CC",vendor: "Silicon Laboratories"},
  {max_prefix: "5C-A1-E0",vendor: "EmbedWay Technologies"},
  {max_prefix: "1C-B7-96",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "38-47-BC",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "54-92-09",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "74-59-09",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "7C-94-2A",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "E4-7E-9A",vendor: "zte corporation"},
  {max_prefix: "2C-16-BD",vendor: "IEEE Registration Authority"},
  {max_prefix: "30-A8-89",vendor: "DECIMATOR DESIGN"},
  {max_prefix: "00-F6-20",vendor: "Google, Inc."},
  {max_prefix: "F4-33-28",vendor: "CIMCON Lighting Inc."},
  {max_prefix: "5C-5A-C7",vendor: "Cisco Systems, Inc"},
  {max_prefix: "B4-60-77",vendor: "Sichuan Changhong Electric Ltd."},
  {max_prefix: "B4-A2-EB",vendor: "IEEE Registration Authority"},
  {max_prefix: "68-AB-09",vendor: "Nokia"},
  {max_prefix: "00-ED-B8",vendor: "KYOCERA Corporation"},
  {max_prefix: "C8-D6-9D",vendor: "Arab International Optronics"},
  {max_prefix: "40-5B-D8",vendor: "CHONGQING FUGUI ELECTRONICS CO.,LTD."},
  {max_prefix: "B8-91-C9",vendor: "Handreamnet"},
  {max_prefix: "C8-A7-76",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "A4-00-E2",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "C4-C6-03",vendor: "Cisco Systems, Inc"},
  {max_prefix: "7C-B5-9B",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "2C-4F-52",vendor: "Cisco Systems, Inc"},
  {max_prefix: "28-23-F5",vendor: "China Mobile (Hangzhou) Information Technology Co., Ltd."},
  {max_prefix: "F0-10-AB",vendor: "China Mobile (Hangzhou) Information Technology Co., Ltd."},
  {max_prefix: "B4-DC-09",vendor: "Guangzhou Dawei Communication Co.,Ltd"},
  {max_prefix: "98-86-5D",vendor: "Nokia Shanghai Bell Co., Ltd."},
  {max_prefix: "9C-99-CD",vendor: "Voippartners"},
  {max_prefix: "68-A0-3E",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "B8-C3-85",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "4C-E9-E4",vendor: "New H3C Technologies Co., Ltd"},
  {max_prefix: "C8-0D-32",vendor: "Holoplot GmbH"},
  {max_prefix: "C4-F7-D5",vendor: "Cisco Systems, Inc"},
  {max_prefix: "1C-64-99",vendor: "Comtrend Corporation"},
  {max_prefix: "D0-57-94",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "04-D9-F5",vendor: "ASUSTek COMPUTER INC."},
  {max_prefix: "54-EC-2F",vendor: "Ruckus Wireless"},
  {max_prefix: "00-25-7E",vendor: "NEW POS TECHNOLOGY LIMITED"},
  {max_prefix: "28-99-C7",vendor: "LINDSAY BROADBAND INC"},
  {max_prefix: "B4-C4-FC",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "FC-BD-67",vendor: "Arista Networks"},
  {max_prefix: "10-DC-4A",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "48-77-46",vendor: "Calix Inc."},
  {max_prefix: "F8-AE-27",vendor: "John Deere Electronic Solutions"},
  {max_prefix: "8C-A9-6F",vendor: "D&M Holdings Inc."},
  {max_prefix: "98-B8-BA",vendor: "LG Electronics (Mobile Communications)"},
  {max_prefix: "7C-D6-61",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "74-45-CE",vendor: "CRESYN"},
  {max_prefix: "B0-FD-0B",vendor: "IEEE Registration Authority"},
  {max_prefix: "B4-CC-04",vendor: "Piranti"},
  {max_prefix: "4C-BC-48",vendor: "Cisco Systems, Inc"},
  {max_prefix: "48-D8-75",vendor: "China TransInfo Technology Co., Ltd"},
  {max_prefix: "30-50-FD",vendor: "Skyworth Digital Technology(Shenzhen) Co.,Ltd"},
  {max_prefix: "0C-E9-9A",vendor: "ATLS ALTEC"},
  {max_prefix: "4C-11-AE",vendor: "Espressif Inc."},
  {max_prefix: "8C-89-FA",vendor: "Zhejiang Hechuan Technology Co., Ltd."},
  {max_prefix: "D4-78-9B",vendor: "Cisco Systems, Inc"},
  {max_prefix: "48-3F-E9",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "14-3C-C3",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "A8-E5-44",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "50-C4-DD",vendor: "BUFFALO.INC"},
  {max_prefix: "00-40-BC",vendor: "ALGORITHMICS LTD."},
  {max_prefix: "00-40-65",vendor: "GTE SPACENET"},
  {max_prefix: "E0-02-A5",vendor: "ABB Robotics"},
  {max_prefix: "B8-D5-26",vendor: "Zyxel Communications Corporation"},
  {max_prefix: "EC-AD-E0",vendor: "D-Link International"},
  {max_prefix: "F0-B9-68",vendor: "ITEL MOBILE LIMITED"},
  {max_prefix: "04-E5-6E",vendor: "THUB Co., ltd."},
  {max_prefix: "1C-7F-2C",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "88-BC-C1",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "8C-42-6D",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "38-D2-CA",vendor: "Zhejiang Tmall Technology Co., Ltd."},
  {max_prefix: "10-9E-3A",vendor: "Zhejiang Tmall Technology Co., Ltd."},
  {max_prefix: "78-DA-07",vendor: "Zhejiang Tmall Technology Co., Ltd."},
  {max_prefix: "44-A6-1E",vendor: "INGRAM MICRO SERVICES"},
  {max_prefix: "90-4D-C3",vendor: "Flonidan A/S"},
  {max_prefix: "00-0D-F1",vendor: "IONIX INC."},
  {max_prefix: "00-07-7C",vendor: "Westermo Network Technologies AB"},
  {max_prefix: "A8-BF-3C",vendor: "HDV Phoelectron Technology Limited"},
  {max_prefix: "D4-F5-27",vendor: "SIEMENS AG"},
  {max_prefix: "1C-BF-CE",vendor: "Shenzhen Century Xinyang Technology Co., Ltd"},
  {max_prefix: "F8-30-02",vendor: "Texas Instruments"},
  {max_prefix: "A8-A1-59",vendor: "ASRock Incorporation"},
  {max_prefix: "20-2A-C5",vendor: "Petite-En"},
  {max_prefix: "08-36-C9",vendor: "NETGEAR"},
  {max_prefix: "CC-DC-55",vendor: "Dragonchip Limited"},
  {max_prefix: "A4-C3-F0",vendor: "Intel Corporate"},
  {max_prefix: "90-6D-05",vendor: "BXB ELECTRONICS CO., LTD"},
  {max_prefix: "D4-BB-C8",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "48-95-07",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "74-5B-C5",vendor: "IEEE Registration Authority"},
  {max_prefix: "44-5D-5E",vendor: "SHENZHEN Coolkit Technology CO.,LTD"},
  {max_prefix: "A0-41-A7",vendor: "NL Ministry of Defense"},
  {max_prefix: "54-62-E2",vendor: "Apple, Inc."},
  {max_prefix: "14-9D-99",vendor: "Apple, Inc."},
  {max_prefix: "B8-B2-F8",vendor: "Apple, Inc."},
  {max_prefix: "98-46-0A",vendor: "Apple, Inc."},
  {max_prefix: "B8-5D-0A",vendor: "Apple, Inc."},
  {max_prefix: "7C-9A-1D",vendor: "Apple, Inc."},
  {max_prefix: "10-30-25",vendor: "Apple, Inc."},
  {max_prefix: "70-AC-D7",vendor: "Shenzhen YOUHUA Technology Co., Ltd"},
  {max_prefix: "14-7B-AC",vendor: "Nokia"},
  {max_prefix: "E4-46-DA",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "1C-12-B0",vendor: "Amazon Technologies Inc."},
  {max_prefix: "4C-BC-98",vendor: "IEEE Registration Authority"},
  {max_prefix: "58-D9-C3",vendor: "Motorola Mobility LLC, a Lenovo Company"},
  {max_prefix: "2C-F4-32",vendor: "Espressif Inc."},
  {max_prefix: "28-FF-B2",vendor: "Toshiba Corp."},
  {max_prefix: "1C-60-D2",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "F4-B5-AA",vendor: "zte corporation"},
  {max_prefix: "E8-AC-AD",vendor: "zte corporation"},
  {max_prefix: "64-73-66",vendor: "Shenzhen Siera Technology Ltd"},
  {max_prefix: "74-4D-28",vendor: "Routerboard.com"},
  {max_prefix: "04-1E-FA",vendor: "BISSELL Homecare, Inc."},
  {max_prefix: "2C-73-A0",vendor: "Cisco Systems, Inc"},
  {max_prefix: "44-3E-07",vendor: "Electrolux"},
  {max_prefix: "04-BA-8D",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "D8-55-75",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "D4-11-A3",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "90-84-2B",vendor: "LEGO System A/S"},
  {max_prefix: "00-26-7E",vendor: "PARROT SA"},
  {max_prefix: "E0-5A-9F",vendor: "IEEE Registration Authority"},
  {max_prefix: "2C-55-7C",vendor: "Shenzhen YOUHUA Technology Co., Ltd"},
  {max_prefix: "F4-BC-DA",vendor: "Shenzhen Jingxun Software Telecommunication Technology Co.,Ltd"},
  {max_prefix: "00-09-15",vendor: "CAS Corp."},
  {max_prefix: "8C-5A-F8",vendor: "Beijing Xiaomi Electronics Co., Ltd."},
  {max_prefix: "D4-58-00",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "00-17-A0",vendor: "RoboTech srl"},
  {max_prefix: "00-0A-A8",vendor: "ePipe Pty. Ltd."},
  {max_prefix: "00-29-C2",vendor: "Cisco Systems, Inc"},
  {max_prefix: "18-7C-0B",vendor: "Ruckus Wireless"},
  {max_prefix: "48-5D-36",vendor: "Verizon"},
  {max_prefix: "20-C0-47",vendor: "Verizon"},
  {max_prefix: "34-6B-46",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "84-85-E6",vendor: "Guangdong Asano Technology CO.,Ltd."},
  {max_prefix: "3C-83-75",vendor: "Microsoft Corporation"},
  {max_prefix: "78-A7-EB",vendor: "1MORE"},
  {max_prefix: "D4-7B-35",vendor: "NEO Monitors AS"},
  {max_prefix: "00-08-78",vendor: "Benchmark Storage Innovations"},
  {max_prefix: "34-DA-B7",vendor: "zte corporation"},
  {max_prefix: "10-9C-70",vendor: "Prusa Research s.r.o."},
  {max_prefix: "10-3D-0A",vendor: "Hui Zhou Gaoshengda Technology Co.,LTD"},
  {max_prefix: "94-27-90",vendor: "TCT mobile ltd"},
  {max_prefix: "A4-17-91",vendor: "Shenzhen Decnta Technology Co.,LTD."},
  {max_prefix: "A4-19-08",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "7C-60-4A",vendor: "Avelon"},
  {max_prefix: "00-14-A5",vendor: "Gemtek Technology Co., Ltd."},
  {max_prefix: "C0-B5-D7",vendor: "CHONGQING FUGUI ELECTRONICS CO.,LTD."},
  {max_prefix: "10-8E-BA",vendor: "Molekule"},
  {max_prefix: "80-D3-36",vendor: "CERN"},
  {max_prefix: "64-25-5E",vendor: "Observint Technologies, Inc."},
  {max_prefix: "90-94-0A",vendor: "Analog Devices, Inc"},
  {max_prefix: "40-B0-76",vendor: "ASUSTek COMPUTER INC."},
  {max_prefix: "E8-4C-56",vendor: "INTERCEPT SERVICES LIMITED"},
  {max_prefix: "D4-AD-71",vendor: "Cisco Systems, Inc"},
  {max_prefix: "70-2B-1D",vendor: "E-Domus International Limited"},
  {max_prefix: "F0-85-C1",vendor: "SHENZHEN RF-LINK TECHNOLOGY CO.,LTD."},
  {max_prefix: "FC-62-B9",vendor: "ALPSALPINE CO,.LTD"},
  {max_prefix: "00-02-C7",vendor: "ALPSALPINE CO,.LTD"},
  {max_prefix: "00-1E-3D",vendor: "ALPSALPINE CO,.LTD"},
  {max_prefix: "28-A1-83",vendor: "ALPSALPINE CO,.LTD"},
  {max_prefix: "48-F0-7B",vendor: "ALPSALPINE CO,.LTD"},
  {max_prefix: "4C-DD-7D",vendor: "LHP Telematics LLC"},
  {max_prefix: "B8-BC-5B",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "60-38-0E",vendor: "ALPSALPINE CO,.LTD"},
  {max_prefix: "D4-3D-39",vendor: "Dialog Semiconductor"},
  {max_prefix: "4C-21-8C",vendor: "Panasonic India Private limited"},
  {max_prefix: "94-A4-0C",vendor: "Diehl Metering GmbH"},
  {max_prefix: "4C-91-7A",vendor: "IEEE Registration Authority"},
  {max_prefix: "F4-8C-EB",vendor: "D-Link International"},
  {max_prefix: "74-3A-65",vendor: "NEC Corporation"},
  {max_prefix: "00-25-5C",vendor: "NEC Corporation"},
  {max_prefix: "2C-4E-7D",vendor: "Chunghua Intelligent Network Equipment Inc."},
  {max_prefix: "A4-F4-65",vendor: "ITEL MOBILE LIMITED"},
  {max_prefix: "CC-70-ED",vendor: "Cisco Systems, Inc"},
  {max_prefix: "90-7E-30",vendor: "LARS"},
  {max_prefix: "84-EB-3E",vendor: "Vivint Smart Home"},
  {max_prefix: "9C-82-75",vendor: "Yichip\u00a0Microelectronics (Hangzhou) Co.,Ltd"},
  {max_prefix: "5C-CB-CA",vendor: "FUJIAN STAR-NET COMMUNICATION CO.,LTD"},
  {max_prefix: "18-BB-26",vendor: "FN-LINK TECHNOLOGY LIMITED"},
  {max_prefix: "34-F8-E7",vendor: "Cisco Systems, Inc"},
  {max_prefix: "28-E9-8E",vendor: "Mitsubishi Electric Corporation"},
  {max_prefix: "18-B9-05",vendor: "Hong Kong Bouffalo Lab Limited"},
  {max_prefix: "EC-F0-FE",vendor: "zte corporation"},
  {max_prefix: "70-B3-17",vendor: "Cisco Systems, Inc"},
  {max_prefix: "B0-02-47",vendor: "AMPAK Technology, Inc."},
  {max_prefix: "BC-E7-96",vendor: "Wireless CCTV Ltd"},
  {max_prefix: "44-D3-AD",vendor: "Shenzhen TINNO Mobile Technology Corp."},
  {max_prefix: "74-85-C4",vendor: "New H3C Technologies Co., Ltd"},
  {max_prefix: "D8-CE-3A",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "B0-90-7E",vendor: "Cisco Systems, Inc"},
  {max_prefix: "2C-73-60",vendor: "Earda Technologies co Ltd"},
  {max_prefix: "D0-BA-E4",vendor: "Shanghai MXCHIP Information Technology Co., Ltd."},
  {max_prefix: "C8-2E-47",vendor: "Suzhou SmartChip Semiconductor Co., LTD"},
  {max_prefix: "C0-22-50",vendor: "Koss Corporation"},
  {max_prefix: "04-33-85",vendor: "Nanchang BlackShark Co.,Ltd."},
  {max_prefix: "E4-CA-12",vendor: "zte corporation"},
  {max_prefix: "D4-9E-05",vendor: "zte corporation"},
  {max_prefix: "58-5F-F6",vendor: "zte corporation"},
  {max_prefix: "40-B3-0E",vendor: "Integrated Device Technology (Malaysia) Sdn. Bhd."},
  {max_prefix: "04-CE-7E",vendor: "NXP France Semiconductors France"},
  {max_prefix: "50-8C-F5",vendor: "China Mobile Group Device Co.,Ltd."},
  {max_prefix: "1C-54-9E",vendor: "Universal Electronics, Inc."},
  {max_prefix: "94-B0-1F",vendor: "Apple, Inc."},
  {max_prefix: "98-CC-4D",vendor: "Shenzhen mantunsci co., LTD"},
  {max_prefix: "B8-C7-4A",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "10-2C-6B",vendor: "AMPAK Technology, Inc."},
  {max_prefix: "24-53-BF",vendor: "Enernet"},
  {max_prefix: "94-F6-D6",vendor: "Apple, Inc."},
  {max_prefix: "F8-2D-7C",vendor: "Apple, Inc."},
  {max_prefix: "C0-9A-D0",vendor: "Apple, Inc."},
  {max_prefix: "D0-92-FA",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "E8-5A-D1",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "50-76-AF",vendor: "Intel Corporate"},
  {max_prefix: "6C-5C-3D",vendor: "IEEE Registration Authority"},
  {max_prefix: "84-E5-D8",vendor: "Guangdong UNIPOE IoT Technology Co.,Ltd."},
  {max_prefix: "A8-BC-9C",vendor: "Cloud Light Technology Limited"},
  {max_prefix: "A8-90-42",vendor: "Beijing Wanwei Intelligent Technology Co., Ltd."},
  {max_prefix: "18-BE-92",vendor: "Delta Networks, Inc."},
  {max_prefix: "C0-13-2B",vendor: "Sichuan Changhong Electric Ltd."},
  {max_prefix: "0C-B4-A4",vendor: "Xintai Automobile Intelligent Network Technology"},
  {max_prefix: "90-63-3B",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "FC-AA-B6",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "78-23-27",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "DC-F7-56",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "2C-A0-2F",vendor: "Veroguard Systems Pty Ltd"},
  {max_prefix: "90-C5-4A",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "BC-75-96",vendor: "Beijing Broadwit Technology Co., Ltd."},
  {max_prefix: "A0-B5-49",vendor: "Arcadyan Corporation"},
  {max_prefix: "00-1F-5A",vendor: "Beckwith Electric Co."},
  {max_prefix: "98-5D-82",vendor: "Arista Networks"},
  {max_prefix: "1C-34-DA",vendor: "Mellanox Technologies, Inc."},
  {max_prefix: "68-A4-7D",vendor: "Sun Cupid Technology (HK) LTD"},
  {max_prefix: "18-4B-0D",vendor: "Ruckus Wireless"},
  {max_prefix: "D4-12-43",vendor: "AMPAK Technology, Inc."},
  {max_prefix: "B8-78-26",vendor: "Nintendo Co.,Ltd"},
  {max_prefix: "DC-CB-A8",vendor: "Explora Technologies Inc"},
  {max_prefix: "C0-78-78",vendor: "FLEXTRONICS MANUFACTURING(ZHUHAI)CO.,LTD."},
  {max_prefix: "CC-D4-A1",vendor: "MitraStar Technology Corp."},
  {max_prefix: "08-BA-5F",vendor: "Qingdao Hisense Electronics Co.,Ltd."},
  {max_prefix: "10-DF-FC",vendor: "Siemens AG"},
  {max_prefix: "84-7F-3D",vendor: "Integrated Device Technology (Malaysia) Sdn. Bhd."},
  {max_prefix: "94-4F-4C",vendor: "Sound United LLC"},
  {max_prefix: "34-DA-C1",vendor: "SAE Technologies Development(Dongguan) Co., Ltd."},
  {max_prefix: "70-5D-CC",vendor: "EFM Networks"},
  {max_prefix: "A8-23-FE",vendor: "LG Electronics"},
  {max_prefix: "1C-59-9B",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "80-69-33",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "BC-26-C7",vendor: "Cisco Systems, Inc"},
  {max_prefix: "E0-5D-5C",vendor: "Oy Everon Ab"},
  {max_prefix: "E0-46-E5",vendor: "Gosuncn Technology Group Co., Ltd."},
  {max_prefix: "68-8F-2E",vendor: "Hitron Technologies. Inc"},
  {max_prefix: "D4-BD-1E",vendor: "5VT Technologies,Taiwan LTd."},
  {max_prefix: "BC-5E-A1",vendor: "PsiKick, Inc."},
  {max_prefix: "28-39-26",vendor: "CyberTAN Technology Inc."},
  {max_prefix: "C4-FD-E6",vendor: "DRTECH"},
  {max_prefix: "CC-98-8B",vendor: "SONY Visual Products Inc."},
  {max_prefix: "74-B5-87",vendor: "Apple, Inc."},
  {max_prefix: "FC-B6-D8",vendor: "Apple, Inc."},
  {max_prefix: "3C-6A-2C",vendor: "IEEE Registration Authority"},
  {max_prefix: "30-E3-D6",vendor: "Spotify USA Inc."},
  {max_prefix: "D8-0D-17",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "74-05-A5",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "28-6D-CD",vendor: "Beijing Winner Microelectronics Co.,Ltd."},
  {max_prefix: "54-10-31",vendor: "SMARTO"},
  {max_prefix: "44-A4-66",vendor: "GROUPE LDLC"},
  {max_prefix: "8C-FC-A0",vendor: "Shenzhen Smart Device Technology Co., LTD."},
  {max_prefix: "1C-42-7D",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "9C-A5-25",vendor: "Shandong USR IOT Technology Limited"},
  {max_prefix: "E0-45-6D",vendor: "China Mobile Group Device Co.,Ltd."},
  {max_prefix: "18-81-0E",vendor: "Apple, Inc."},
  {max_prefix: "60-8C-4A",vendor: "Apple, Inc."},
  {max_prefix: "24-1B-7A",vendor: "Apple, Inc."},
  {max_prefix: "8C-FE-57",vendor: "Apple, Inc."},
  {max_prefix: "C0-A6-00",vendor: "Apple, Inc."},
  {max_prefix: "E0-C2-86",vendor: "Aisai Communication Technology Co., Ltd."},
  {max_prefix: "F0-B0-14",vendor: "AVM Audiovisuelles Marketing und Computersysteme GmbH"},
  {max_prefix: "18-C2-BF",vendor: "BUFFALO.INC"},
  {max_prefix: "0C-2A-86",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "FC-61-E9",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "00-A0-D1",vendor: "INVENTEC CORPORATION"},
  {max_prefix: "00-18-CC",vendor: "AXIOHM SAS"},
  {max_prefix: "00-18-27",vendor: "NEC UNIFIED SOLUTIONS NEDERLAND B.V."},
  {max_prefix: "00-90-04",vendor: "3COM EUROPE LTD"},
  {max_prefix: "00-06-8C",vendor: "3COM"},
  {max_prefix: "02-60-8C",vendor: "3COM"},
  {max_prefix: "00-D0-D8",vendor: "3COM"},
  {max_prefix: "18-93-7F",vendor: "AMPAK Technology, Inc."},
  {max_prefix: "A4-35-23",vendor: "Guangdong Donyan Network Technologies Co.,Ltd."},
  {max_prefix: "B4-A9-4F",vendor: "MERCURY CORPORATION"},
  {max_prefix: "80-3A-F4",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "48-A0-F8",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "40-56-62",vendor: "GuoTengShengHua Electronics LTD."},
  {max_prefix: "E4-DB-6D",vendor: "Beijing Xiaomi Electronics Co., Ltd."},
  {max_prefix: "0C-53-31",vendor: "ETH Zurich"},
  {max_prefix: "0C-9A-42",vendor: "FN-LINK TECHNOLOGY LIMITED"},
  {max_prefix: "F8-5E-3C",vendor: "SHENZHEN ZHIBOTONG ELECTRONICS CO.,LTD"},
  {max_prefix: "28-3E-76",vendor: "Common Networks"},
  {max_prefix: "DC-39-79",vendor: "Cisco Systems, Inc"},
  {max_prefix: "58-D5-6E",vendor: "D-Link International"},
  {max_prefix: "DC-90-88",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "54-81-2D",vendor: "PAX Computer Technology(Shenzhen) Ltd."},
  {max_prefix: "40-62-EA",vendor: "China Mobile Group Device Co.,Ltd."},
  {max_prefix: "44-65-7F",vendor: "Calix Inc."},
  {max_prefix: "30-4B-07",vendor: "Motorola Mobility LLC, a Lenovo Company"},
  {max_prefix: "34-5A-BA",vendor: "tcloud intelligence"},
  {max_prefix: "50-2F-A8",vendor: "Cisco Systems, Inc"},
  {max_prefix: "C0-83-59",vendor: "IEEE Registration Authority"},
  {max_prefix: "88-35-C1",vendor: "OI ELECTRIC CO.,LTD"},
  {max_prefix: "30-42-A1",vendor: "ilumisys Inc. DBA Toggled"},
  {max_prefix: "00-26-B7",vendor: "Kingston Technology Company, Inc."},
  {max_prefix: "00-08-09",vendor: "Systemonic AG"},
  {max_prefix: "8C-41-F4",vendor: "IPmotion GmbH"},
  {max_prefix: "70-4F-08",vendor: "Shenzhen Huisheng Information Technology Co., Ltd."},
  {max_prefix: "4C-0F-C7",vendor: "Earda Technologies co Ltd"},
  {max_prefix: "9C-F6-DD",vendor: "IEEE Registration Authority"},
  {max_prefix: "48-88-1E",vendor: "EthoSwitch LLC"},
  {max_prefix: "CC-21-19",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "80-A7-96",vendor: "Neuralink Corp."},
  {max_prefix: "00-1E-EC",vendor: "COMPAL INFORMATION (KUNSHAN) CO., LTD."},
  {max_prefix: "F0-76-1C",vendor: "COMPAL INFORMATION (KUNSHAN) CO., LTD."},
  {max_prefix: "00-04-AE",vendor: "Sullair Corporation"},
  {max_prefix: "FC-B1-0D",vendor: "Shenzhen Tian Kun Technology Co.,LTD."},
  {max_prefix: "20-F7-7C",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "00-45-1D",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-03-93",vendor: "Apple, Inc."},
  {max_prefix: "00-00-C3",vendor: "Harris Corporation"},
  {max_prefix: "A0-D6-35",vendor: "WBS Technology"},
  {max_prefix: "3C-71-BF",vendor: "Espressif Inc."},
  {max_prefix: "90-2B-D2",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "08-D5-9D",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "B4-69-21",vendor: "Intel Corporate"},
  {max_prefix: "14-94-2F",vendor: "USYS CO.,LTD."},
  {max_prefix: "EC-83-D5",vendor: "GIRD Systems Inc"},
  {max_prefix: "3C-42-7E",vendor: "IEEE Registration Authority"},
  {max_prefix: "30-D6-59",vendor: "Merging Technologies SA"},
  {max_prefix: "70-2A-D5",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "88-97-65",vendor: "exands"},
  {max_prefix: "00-0B-A3",vendor: "Siemens AG"},
  {max_prefix: "34-80-0D",vendor: "Cavium Inc"},
  {max_prefix: "B4-4B-D6",vendor: "IEEE Registration Authority"},
  {max_prefix: "D8-91-2A",vendor: "Zyxel Communications Corporation"},
  {max_prefix: "00-0C-8A",vendor: "Bose Corporation"},
  {max_prefix: "24-3A-82",vendor: "IRTS"},
  {max_prefix: "88-09-07",vendor: "MKT Systemtechnik GmbH & Co. KG"},
  {max_prefix: "40-EE-DD",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "AC-75-1D",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "28-9E-97",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "00-1E-B0",vendor: "ImesD Electronica S.L."},
  {max_prefix: "00-15-25",vendor: "Chamberlain Access Solutions"},
  {max_prefix: "58-A4-8E",vendor: "PixArt Imaging Inc."},
  {max_prefix: "60-05-8A",vendor: "Hitachi Metals, Ltd."},
  {max_prefix: "BC-22-FB",vendor: "RF Industries"},
  {max_prefix: "74-B9-1E",vendor: "Nanjing Bestway Automation System Co., Ltd"},
  {max_prefix: "A0-19-B2",vendor: "IEEE Registration Authority"},
  {max_prefix: "4C-3F-D3",vendor: "Texas Instruments"},
  {max_prefix: "8C-15-C7",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "60-FA-9D",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "38-6E-88",vendor: "zte corporation"},
  {max_prefix: "64-1C-67",vendor: "DIGIBRAS INDUSTRIA DO BRASILS/A"},
  {max_prefix: "DC-99-14",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "B0-53-65",vendor: "China Mobile IOT Company Limited"},
  {max_prefix: "30-88-41",vendor: "Sichuan\u00a0AI-Link\u00a0Technology\u00a0Co.,\u00a0Ltd."},
  {max_prefix: "44-EF-CF",vendor: "UGENE SOLUTION inc."},
  {max_prefix: "00-80-B6",vendor: "Mercury Systems \u2013 Trusted Mission Solutions, Inc."},
  {max_prefix: "08-51-2E",vendor: "Orion Diagnostica Oy"},
  {max_prefix: "98-A4-04",vendor: "Ericsson AB"},
  {max_prefix: "00-CC-3F",vendor: "Universal Electronics, Inc."},
  {max_prefix: "74-EB-80",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "0C-E0-DC",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "D8-68-C3",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "C4-93-D9",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "A8-2B-B9",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "AC-FD-93",vendor: "WEIFANG GOERTEK ELECTRONICS CO.,LTD"},
  {max_prefix: "00-0E-8F",vendor: "Sercomm Corporation."},
  {max_prefix: "00-B8-C2",vendor: "Heights Telecom T ltd"},
  {max_prefix: "B4-B6-86",vendor: "Hewlett Packard"},
  {max_prefix: "F4-BF-80",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "30-45-96",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "C0-F4-E6",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "68-57-2D",vendor: "Tuya Smart Inc."},
  {max_prefix: "A0-E6-17",vendor: "MATIS"},
  {max_prefix: "70-01-B5",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-1F-49",vendor: "Manhattan TV Ltd"},
  {max_prefix: "7C-2E-BD",vendor: "Google, Inc."},
  {max_prefix: "4C-ED-FB",vendor: "ASUSTek COMPUTER INC."},
  {max_prefix: "D0-B2-14",vendor: "PoeWit Inc"},
  {max_prefix: "88-D6-52",vendor: "AMERGINT Technologies"},
  {max_prefix: "FC-90-FA",vendor: "Independent Technologies"},
  {max_prefix: "E4-E1-30",vendor: "TCT mobile ltd"},
  {max_prefix: "6C-AF-15",vendor: "Webasto SE"},
  {max_prefix: "40-F0-4E",vendor: "Integrated Device Technology (Malaysia) Sdn. Bhd."},
  {max_prefix: "0C-21-38",vendor: "Hengstler GmbH"},
  {max_prefix: "E0-19-1D",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "68-D1-BA",vendor: "Shenzhen YOUHUA Technology Co., Ltd"},
  {max_prefix: "E4-60-59",vendor: "Pingtek Co., Ltd."},
  {max_prefix: "40-50-B5",vendor: "Shenzhen New Species Technology Co., Ltd."},
  {max_prefix: "1C-1A-C0",vendor: "Apple, Inc."},
  {max_prefix: "30-78-C2",vendor: "Innowireless / QUCELL Networks"},
  {max_prefix: "80-05-88",vendor: "Ruijie Networks Co.,LTD"},
  {max_prefix: "3C-15-FB",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "DC-33-0D",vendor: "QING DAO HAIER TELECOM CO.,LTD."},
  {max_prefix: "E0-38-3F",vendor: "zte corporation"},
  {max_prefix: "D4-72-26",vendor: "zte corporation"},
  {max_prefix: "00-21-F2",vendor: "EASY3CALL Technology Limited"},
  {max_prefix: "00-15-C4",vendor: "FLOVEL CO., LTD."},
  {max_prefix: "00-CF-C0",vendor: "China Mobile Group Device Co.,Ltd."},
  {max_prefix: "AC-35-EE",vendor: "FN-LINK TECHNOLOGY LIMITED"},
  {max_prefix: "0C-2C-54",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "88-11-96",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "E4-0E-EE",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "28-D9-97",vendor: "Yuduan Mobile Co., Ltd."},
  {max_prefix: "00-11-E6",vendor: "Scientific Atlanta"},
  {max_prefix: "88-AE-07",vendor: "Apple, Inc."},
  {max_prefix: "40-83-1D",vendor: "Apple, Inc."},
  {max_prefix: "DC-D3-A2",vendor: "Apple, Inc."},
  {max_prefix: "5C-1D-D9",vendor: "Apple, Inc."},
  {max_prefix: "68-FE-F7",vendor: "Apple, Inc."},
  {max_prefix: "F0-0E-1D",vendor: "Megafone Limited"},
  {max_prefix: "24-F1-28",vendor: "Telstra"},
  {max_prefix: "70-69-5A",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-BF-77",vendor: "Cisco Systems, Inc"},
  {max_prefix: "D0-77-14",vendor: "Motorola Mobility LLC, a Lenovo Company"},
  {max_prefix: "30-B7-D4",vendor: "Hitron Technologies. Inc"},
  {max_prefix: "B4-81-BF",vendor: "Meta-Networks, LLC"},
  {max_prefix: "94-6A-B0",vendor: "Arcadyan Corporation"},
  {max_prefix: "48-18-FA",vendor: "Nocsys"},
  {max_prefix: "58-7A-6A",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "E8-1A-AC",vendor: "ORFEO SOUNDWORKS Inc."},
  {max_prefix: "A0-38-F8",vendor: "OURA Health Oy"},
  {max_prefix: "68-79-24",vendor: "ELS-GmbH & Co. KG"},
  {max_prefix: "D4-C1-9E",vendor: "Ruckus Wireless"},
  {max_prefix: "0C-AE-7D",vendor: "Texas Instruments"},
  {max_prefix: "30-45-11",vendor: "Texas Instruments"},
  {max_prefix: "30-1F-9A",vendor: "IEEE Registration Authority"},
  {max_prefix: "28-FD-80",vendor: "IEEE Registration Authority"},
  {max_prefix: "00-07-58",vendor: "DragonWave Inc."},
  {max_prefix: "D4-93-98",vendor: "Nokia Corporation"},
  {max_prefix: "00-19-37",vendor: "CommerceGuard AB"},
  {max_prefix: "FC-7C-02",vendor: "Phicomm (Shanghai) Co., Ltd."},
  {max_prefix: "A8-61-0A",vendor: "ARDUINO AG"},
  {max_prefix: "60-97-DD",vendor: "MicroSys Electronics GmbH"},
  {max_prefix: "04-79-70",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "A0-57-E3",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "F8-DF-15",vendor: "Sunitec Enterprise Co.,Ltd"},
  {max_prefix: "B0-26-80",vendor: "Cisco Systems, Inc"},
  {max_prefix: "D4-6D-6D",vendor: "Intel Corporate"},
  {max_prefix: "B4-1C-30",vendor: "zte corporation"},
  {max_prefix: "70-5A-AC",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "60-98-13",vendor: "Shanghai Visking Digital Technology Co. LTD"},
  {max_prefix: "1C-B0-44",vendor: "ASKEY COMPUTER CORP"},
  {max_prefix: "38-DE-AD",vendor: "Intel Corporate"},
  {max_prefix: "F4-84-4C",vendor: "Texas Instruments"},
  {max_prefix: "4C-82-CF",vendor: "Dish Technologies Corp"},
  {max_prefix: "28-57-67",vendor: "Dish Technologies Corp"},
  {max_prefix: "70-16-9F",vendor: "EtherCAT Technology Group"},
  {max_prefix: "A0-39-EE",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "68-98-61",vendor: "Beacon Inc"},
  {max_prefix: "3C-57-4F",vendor: "China Mobile Group Device Co.,Ltd."},
  {max_prefix: "84-74-60",vendor: "zte corporation"},
  {max_prefix: "50-6B-4B",vendor: "Mellanox Technologies, Inc."},
  {max_prefix: "1C-A0-B8",vendor: "Hon Hai Precision Industry Co., Ltd."},
  {max_prefix: "2C-61-F6",vendor: "Apple, Inc."},
  {max_prefix: "D4-A3-3D",vendor: "Apple, Inc."},
  {max_prefix: "04-4F-17",vendor: "HUMAX Co., Ltd."},
  {max_prefix: "0C-A8-A7",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "B0-67-2F",vendor: "Bowers & Wilkins"},
  {max_prefix: "10-CD-6E",vendor: "FISYS"},
  {max_prefix: "E4-CB-59",vendor: "Beijing Loveair Science and Technology Co. Ltd."},
  {max_prefix: "B4-E6-2D",vendor: "Espressif Inc."},
  {max_prefix: "F0-76-6F",vendor: "Apple, Inc."},
  {max_prefix: "40-98-AD",vendor: "Apple, Inc."},
  {max_prefix: "6C-4D-73",vendor: "Apple, Inc."},
  {max_prefix: "D8-9C-67",vendor: "Hon Hai Precision Ind. Co.,Ltd."},
  {max_prefix: "68-5A-CF",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "64-20-9F",vendor: "Tilgin AB"},
  {max_prefix: "A4-3E-51",vendor: "ANOV FRANCE"},
  {max_prefix: "D8-63-75",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "68-EF-43",vendor: "Apple, Inc."},
  {max_prefix: "D0-2B-20",vendor: "Apple, Inc."},
  {max_prefix: "70-26-05",vendor: "SONY Visual Products Inc."},
  {max_prefix: "00-50-13",vendor: "Seagate Cloud Systems Inc"},
  {max_prefix: "00-90-F1",vendor: "Seagate Cloud Systems Inc"},
  {max_prefix: "84-5A-81",vendor: "ffly4u"},
  {max_prefix: "CC-81-DA",vendor: "Phicomm (Shanghai) Co., Ltd."},
  {max_prefix: "00-80-6C",vendor: "Secure Systems & Services"},
  {max_prefix: "1C-27-DD",vendor: "Datang Gohighsec(zhejiang)Information Technology Co.,Ltd."},
  {max_prefix: "B8-C8-EB",vendor: "ITEL MOBILE LIMITED"},
  {max_prefix: "80-C5-F2",vendor: "AzureWave Technology Inc."},
  {max_prefix: "64-F8-8A",vendor: "China Mobile IOT Company Limited"},
  {max_prefix: "68-DB-54",vendor: "Phicomm (Shanghai) Co., Ltd."},
  {max_prefix: "C8-DF-84",vendor: "Texas Instruments"},
  {max_prefix: "24-0D-6C",vendor: "SMND"},
  {max_prefix: "B4-52-53",vendor: "Seagate Technology"},
  {max_prefix: "00-11-C6",vendor: "Seagate Technology"},
  {max_prefix: "00-1D-38",vendor: "Seagate Technology"},
  {max_prefix: "E4-8F-34",vendor: "Vodafone Italia S.p.A."},
  {max_prefix: "D8-E0-04",vendor: "Vodia Networks Inc"},
  {max_prefix: "2C-FD-AB",vendor: "Motorola (Wuhan) Mobility Technologies Communication Co., Ltd."},
  {max_prefix: "30-B4-B8",vendor: "LG Electronics"},
  {max_prefix: "38-73-EA",vendor: "IEEE Registration Authority"},
  {max_prefix: "4C-52-62",vendor: "Fujitsu Technology Solutions GmbH"},
  {max_prefix: "80-3B-F6",vendor: "LOOK EASY INTERNATIONAL LIMITED"},
  {max_prefix: "30-EB-1F",vendor: "Skylab M&C Technology Co.,Ltd"},
  {max_prefix: "54-9A-4C",vendor: "GUANGDONG HOMECARE TECHNOLOGY CO.,LTD."},
  {max_prefix: "0C-CE-F6",vendor: "Guizhou Fortuneship Technology Co., Ltd"},
  {max_prefix: "18-06-FF",vendor: "Acer Computer(Shanghai) Limited."},
  {max_prefix: "EC-1D-8B",vendor: "Cisco Systems, Inc"},
  {max_prefix: "D0-7F-C4",vendor: "Ou Wei Technology Co.\uff0cLtd. of Shenzhen City"},
  {max_prefix: "14-79-F3",vendor: "China Mobile Group Device Co.,Ltd."},
  {max_prefix: "48-55-5C",vendor: "Wu Qi Technologies,Inc."},
  {max_prefix: "18-F0-E4",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "38-0E-4D",vendor: "Cisco Systems, Inc"},
  {max_prefix: "C4-CD-82",vendor: "Hangzhou Lowan Information Technology Co., Ltd."},
  {max_prefix: "30-FB-94",vendor: "Shanghai Fangzhiwei Information Technology CO.,Ltd."},
  {max_prefix: "A4-B5-2E",vendor: "Integrated Device Technology (Malaysia) Sdn. Bhd."},
  {max_prefix: "9C-8C-6E",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "DC-4F-22",vendor: "Espressif Inc."},
  {max_prefix: "F8-6C-E1",vendor: "Taicang T&W Electronics"},
  {max_prefix: "1C-73-28",vendor: "Connected Home"},
  {max_prefix: "08-BA-22",vendor: "Swaive Corporation"},
  {max_prefix: "28-C1-3C",vendor: "Hon Hai Precision Industry Co., Ltd."},
  {max_prefix: "00-23-A0",vendor: "Hana CNS Co., LTD."},
  {max_prefix: "F4-06-A5",vendor: "Hangzhou Bianfeng Networking Technology Co., Ltd."},
  {max_prefix: "B0-EC-E1",vendor: "Private"},
  {max_prefix: "60-E7-8A",vendor: "UNISEM"},
  {max_prefix: "64-CB-A3",vendor: "Pointmobile"},
  {max_prefix: "3C-A5-81",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "34-E9-11",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "10-46-B4",vendor: "FormericaOE"},
  {max_prefix: "9C-E3-3F",vendor: "Apple, Inc."},
  {max_prefix: "78-67-D7",vendor: "Apple, Inc."},
  {max_prefix: "B8-C1-11",vendor: "Apple, Inc."},
  {max_prefix: "00-24-24",vendor: "Ace Axis Limited"},
  {max_prefix: "50-C9-A0",vendor: "SKIPPER AS"},
  {max_prefix: "74-83-EF",vendor: "Arista Networks"},
  {max_prefix: "00-E0-F6",vendor: "DECISION EUROPE"},
  {max_prefix: "00-12-48",vendor: "Dell EMC"},
  {max_prefix: "00-60-48",vendor: "Dell EMC"},
  {max_prefix: "7C-C9-5A",vendor: "Dell EMC"},
  {max_prefix: "00-BF-61",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "EC-FA-BC",vendor: "Espressif Inc."},
  {max_prefix: "CC-2D-E0",vendor: "Routerboard.com"},
  {max_prefix: "38-9D-92",vendor: "Seiko Epson Corporation"},
  {max_prefix: "A0-70-99",vendor: "Beijing Huacan Electronics Co., Ltd"},
  {max_prefix: "DC-55-83",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "F8-F2-1E",vendor: "Intel Corporate"},
  {max_prefix: "28-29-86",vendor: "APC by Schneider Electric"},
  {max_prefix: "70-7D-B9",vendor: "Cisco Systems, Inc"},
  {max_prefix: "08-BE-AC",vendor: "Edimax Technology Co. Ltd."},
  {max_prefix: "8C-0F-83",vendor: "Angie Hospitality LLC"},
  {max_prefix: "D0-04-01",vendor: "Motorola Mobility LLC, a Lenovo Company"},
  {max_prefix: "74-28-57",vendor: "Mayfield Robotics"},
  {max_prefix: "58-90-43",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "24-E1-24",vendor: "Xiamen Milesight IoT Co., Ltd."},
  {max_prefix: "DC-68-EB",vendor: "Nintendo Co.,Ltd"},
  {max_prefix: "E8-36-1D",vendor: "Sense Labs, Inc."},
  {max_prefix: "A4-07-B6",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "40-49-8A",vendor: "Synapticon GmbH"},
  {max_prefix: "08-78-08",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "88-75-98",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "C0-17-4D",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "70-91-F3",vendor: "Universal Electronics, Inc."},
  {max_prefix: "08-00-69",vendor: "Silicon Graphics"},
  {max_prefix: "00-22-91",vendor: "Cisco Systems, Inc"},
  {max_prefix: "10-FC-B6",vendor: "mirusystems CO.,LTD"},
  {max_prefix: "04-D6-AA",vendor: "SAMSUNG ELECTRO-MECHANICS(THAILAND)"},
  {max_prefix: "C8-9F-42",vendor: "VDII Innovation AB"},
  {max_prefix: "50-A8-3A",vendor: "S Mobile Devices Limited"},
  {max_prefix: "64-05-E9",vendor: "Shenzhen WayOS Technology Crop., Ltd."},
  {max_prefix: "F4-F5-DB",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "D0-66-6D",vendor: "Shenzhen Bus-Lan Technology Co., Ltd."},
  {max_prefix: "08-15-2F",vendor: "Samsung Electronics Co., Ltd. ARTIK"},
  {max_prefix: "0C-58-42",vendor: "DME Micro"},
  {max_prefix: "BC-82-5D",vendor: "MITSUMI ELECTRIC CO.,LTD."},
  {max_prefix: "CC-22-37",vendor: "IEEE Registration Authority"},
  {max_prefix: "48-D6-D5",vendor: "Google, Inc."},
  {max_prefix: "38-D6-20",vendor: "Limidea Concept Pte. Ltd."},
  {max_prefix: "10-49-63",vendor: "HARTING K.K."},
  {max_prefix: "8C-E3-8E",vendor: "Kioxia Corporation"},
  {max_prefix: "18-60-24",vendor: "Hewlett Packard"},
  {max_prefix: "8C-D4-8E",vendor: "ITEL MOBILE LIMITED"},
  {max_prefix: "64-2B-8A",vendor: "ALL BEST Industrial Co., Ltd."},
  {max_prefix: "00-14-9D",vendor: "Sound ID Inc."},
  {max_prefix: "A8-E8-24",vendor: "INIM ELECTRONICS S.R.L."},
  {max_prefix: "BC-3D-85",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "20-54-FA",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "38-37-8B",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "74-5C-4B",vendor: "GN Audio A/S"},
  {max_prefix: "74-F9-1A",vendor: "Onface"},
  {max_prefix: "A4-34-F1",vendor: "Texas Instruments"},
  {max_prefix: "EC-04-41",vendor: "ShenZhen TIGO Semiconductor Co., Ltd."},
  {max_prefix: "B8-EE-0E",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "C0-A5-3E",vendor: "Apple, Inc."},
  {max_prefix: "B0-35-0B",vendor: "MOBIWIRE MOBILES (NINGBO) CO.,LTD"},
  {max_prefix: "28-A6-AC",vendor: "seca gmbh & co. kg"},
  {max_prefix: "EC-D0-9F",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "78-E1-03",vendor: "Amazon Technologies Inc."},
  {max_prefix: "78-A6-E1",vendor: "Brocade Communications Systems LLC"},
  {max_prefix: "E4-EC-10",vendor: "Nokia Corporation"},
  {max_prefix: "00-06-59",vendor: "EAL (Apeldoorn) B.V."},
  {max_prefix: "A8-BE-27",vendor: "Apple, Inc."},
  {max_prefix: "00-26-92",vendor: "Mitsubishi Electric Corporation"},
  {max_prefix: "00-C0-8F",vendor: "Panasonic Electric Works Co., Ltd."},
  {max_prefix: "6C-09-0A",vendor: "GEMATICA SRL"},
  {max_prefix: "70-E1-FD",vendor: "FLEXTRONICS"},
  {max_prefix: "74-E6-0F",vendor: "TECNO MOBILE LIMITED"},
  {max_prefix: "00-1A-A7",vendor: "Torian Wireless"},
  {max_prefix: "44-4E-6D",vendor: "AVM Audiovisuelles Marketing und Computersysteme GmbH"},
  {max_prefix: "90-B1-E0",vendor: "Beijing Nebula Link Technology Co., Ltd"},
  {max_prefix: "80-14-A8",vendor: "Guangzhou V-SOLUTION Electronic Technology Co., Ltd."},
  {max_prefix: "58-61-63",vendor: "Quantum Networks (SG) Pte. Ltd."},
  {max_prefix: "00-2E-C7",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "48-8E-EF",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "8C-C1-21",vendor: "Panasonic Corporation AVC Networks Company"},
  {max_prefix: "AC-BE-75",vendor: "Ufine Technologies Co.,Ltd."},
  {max_prefix: "40-9B-CD",vendor: "D-Link International"},
  {max_prefix: "0C-B4-59",vendor: "Marketech International Corp."},
  {max_prefix: "94-D0-29",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "30-84-54",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "5C-03-39",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "EC-3D-FD",vendor: "SHENZHEN BILIAN ELECTRONIC CO.\uff0cLTD"},
  {max_prefix: "54-75-95",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "C4-71-54",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "F8-28-19",vendor: "Liteon Technology Corporation"},
  {max_prefix: "50-E9-71",vendor: "Jibo, Inc."},
  {max_prefix: "58-C5-83",vendor: "ITEL MOBILE LIMITED"},
  {max_prefix: "18-20-4C",vendor: "Kummler+Matter AG"},
  {max_prefix: "18-D2-25",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "08-40-F3",vendor: "Tenda Technology Co.,Ltd.Dongguan branch"},
  {max_prefix: "94-FB-B2",vendor: "SHENZHEN GONGJIN ELECTRONICS CO.,LT"},
  {max_prefix: "00-C0-5D",vendor: "L&N TECHNOLOGIES"},
  {max_prefix: "30-F7-7F",vendor: "S Mobile Devices Limited"},
  {max_prefix: "D8-6C-63",vendor: "Google, Inc."},
  {max_prefix: "00-1E-99",vendor: "Vantanol Industrial Corporation"},
  {max_prefix: "58-B6-33",vendor: "Ruckus Wireless"},
  {max_prefix: "54-3D-37",vendor: "Ruckus Wireless"},
  {max_prefix: "2C-E6-CC",vendor: "Ruckus Wireless"},
  {max_prefix: "5C-51-81",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "60-8E-08",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "00-22-7F",vendor: "Ruckus Wireless"},
  {max_prefix: "74-91-1A",vendor: "Ruckus Wireless"},
  {max_prefix: "C8-DB-26",vendor: "Logitech"},
  {max_prefix: "A4-0E-2B",vendor: "Facebook Inc"},
  {max_prefix: "F8-8A-3C",vendor: "IEEE Registration Authority"},
  {max_prefix: "A4-04-50",vendor: "nFore Technology Inc."},
  {max_prefix: "FC-5A-1D",vendor: "Hitron Technologies. Inc"},
  {max_prefix: "94-14-7A",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "18-B4-30",vendor: "Nest Labs Inc."},
  {max_prefix: "30-B1-64",vendor: "Power Electronics International Inc."},
  {max_prefix: "98-28-A6",vendor: "COMPAL INFORMATION (KUNSHAN) CO., LTD."},
  {max_prefix: "00-1B-17",vendor: "Palo Alto Networks"},
  {max_prefix: "58-49-3B",vendor: "Palo Alto Networks"},
  {max_prefix: "78-6D-94",vendor: "Palo Alto Networks"},
  {max_prefix: "F4-30-B9",vendor: "Hewlett Packard"},
  {max_prefix: "14-61-2F",vendor: "Avaya Inc"},
  {max_prefix: "00-30-9D",vendor: "Nimble Microsystems, Inc."},
  {max_prefix: "8C-21-0A",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "4C-18-9A",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "CC-4B-73",vendor: "AMPAK Technology, Inc."},
  {max_prefix: "68-1D-EF",vendor: "Shenzhen CYX Technology Co., Ltd."},
  {max_prefix: "AC-20-3E",vendor: "Wuhan Tianyu Information Industry Co., Ltd."},
  {max_prefix: "B0-1F-29",vendor: "Helvetia INC."},
  {max_prefix: "54-C9-DF",vendor: "FN-LINK TECHNOLOGY LIMITED"},
  {max_prefix: "A4-78-86",vendor: "Avaya Inc"},
  {max_prefix: "04-03-D6",vendor: "Nintendo Co.,Ltd"},
  {max_prefix: "AC-4E-2E",vendor: "Shenzhen JingHanDa Electronics Co.Ltd"},
  {max_prefix: "4C-91-0C",vendor: " Lanix Internacional, S.A. de C.V."},
  {max_prefix: "00-15-DC",vendor: "KT&C Co., Ltd."},
  {max_prefix: "00-18-7D",vendor: "Armorlink Co .Ltd"},
  {max_prefix: "88-0F-10",vendor: "Huami Information Technology Co.,Ltd."},
  {max_prefix: "5C-1A-6F",vendor: "Cambridge Industries(Group) Co.,Ltd."},
  {max_prefix: "3C-4C-D0",vendor: "CERAGON NETWORKS"},
  {max_prefix: "20-6B-E7",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "18-2C-B4",vendor: "Nectarsoft Co., Ltd."},
  {max_prefix: "2C-3A-E8",vendor: "Espressif Inc."},
  {max_prefix: "88-BD-78",vendor: "Flaircomm Microelectronics,Inc."},
  {max_prefix: "58-C5-CB",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "B4-BF-F6",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "EC-36-3F",vendor: "Markov Corporation"},
  {max_prefix: "58-04-CB",vendor: "Tianjin Huisun Technology Co.,Ltd."},
  {max_prefix: "B8-FF-B3",vendor: "MitraStar Technology Corp."},
  {max_prefix: "98-2D-BA",vendor: "Fibergate Inc."},
  {max_prefix: "E0-D5-5E",vendor: "GIGA-BYTE TECHNOLOGY CO.,LTD."},
  {max_prefix: "A0-40-A0",vendor: "NETGEAR"},
  {max_prefix: "00-A3-8E",vendor: "Cisco Systems, Inc"},
  {max_prefix: "A0-C9-A0",vendor: "Murata Manufacturing Co., Ltd."},
  {max_prefix: "74-F6-1C",vendor: "HTC Corporation"},
  {max_prefix: "84-C0-EF",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "00-0D-2B",vendor: "Racal Instruments"},
  {max_prefix: "00-40-66",vendor: "APRESIA Systems Ltd"},
  {max_prefix: "48-A7-4E",vendor: "zte corporation"},
  {max_prefix: "BC-8A-E8",vendor: "QING DAO HAIER TELECOM CO.,LTD."},
  {max_prefix: "F4-DE-0C",vendor: "ESPOD Ltd."},
  {max_prefix: "28-C6-3F",vendor: "Intel Corporate"},
  {max_prefix: "88-CC-45",vendor: "Skyworth Digital Technology(Shenzhen) Co.,Ltd"},
  {max_prefix: "CC-90-E8",vendor: "Shenzhen YOUHUA Technology Co., Ltd"},
  {max_prefix: "3C-52-82",vendor: "Hewlett Packard"},
  {max_prefix: "08-ED-02",vendor: "IEEE Registration Authority"},
  {max_prefix: "84-A9-C4",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "A0-08-6F",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "D0-6F-82",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "A0-F4-79",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "84-47-65",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "C4-FF-1F",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "7C-4F-7D",vendor: "Sawwave"},
  {max_prefix: "9C-AC-6D",vendor: "Universal Electronics, Inc."},
  {max_prefix: "60-08-37",vendor: "ivvi Scientific(Nanchang)Co.Ltd"},
  {max_prefix: "E8-FD-E8",vendor: "CeLa Link Corporation"},
  {max_prefix: "C4-0B-CB",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "60-D7-E3",vendor: "IEEE Registration Authority"},
  {max_prefix: "18-93-D7",vendor: "Texas Instruments"},
  {max_prefix: "A8-B8-6E",vendor: "LG Electronics (Mobile Communications)"},
  {max_prefix: "84-AF-EC",vendor: "BUFFALO.INC"},
  {max_prefix: "7C-7B-8B",vendor: "Control Concepts, Inc."},
  {max_prefix: "B8-37-65",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "34-5B-BB",vendor: "GD Midea Air-Conditioning Equipment Co.,Ltd."},
  {max_prefix: "34-CE-00",vendor: "XIAOMI Electronics,CO.,LTD"},
  {max_prefix: "D0-49-8B",vendor: "ZOOM SERVER"},
  {max_prefix: "08-27-CE",vendor: "NAGANO KEIKI CO., LTD."},
  {max_prefix: "00-21-9E",vendor: "Sony Corporation"},
  {max_prefix: "AC-B5-7D",vendor: "Liteon Technology Corporation"},
  {max_prefix: "D4-61-9D",vendor: "Apple, Inc."},
  {max_prefix: "98-DD-EA",vendor: "Infinix mobility limited"},
  {max_prefix: "00-1D-44",vendor: "Krohne"},
  {max_prefix: "A8-A1-98",vendor: "TCT mobile ltd"},
  {max_prefix: "08-EA-40",vendor: "SHENZHEN BILIAN ELECTRONIC CO.\uff0cLTD"},
  {max_prefix: "00-D0-95",vendor: "Alcatel-Lucent Enterprise"},
  {max_prefix: "00-20-DA",vendor: "Alcatel-Lucent Enterprise"},
  {max_prefix: "A0-C4-A5",vendor: "SYGN HOUSE INC."},
  {max_prefix: "6C-59-76",vendor: "Shanghai Tricheer Technology Co.,Ltd."},
  {max_prefix: "14-BD-61",vendor: "Apple, Inc."},
  {max_prefix: "5C-C6-E9",vendor: "Edifier International"},
  {max_prefix: "EC-4F-82",vendor: "Calix Inc."},
  {max_prefix: "00-2C-C8",vendor: "Cisco Systems, Inc"},
  {max_prefix: "C0-02-8D",vendor: "WINSTAR Display CO.,Ltd"},
  {max_prefix: "E8-9F-EC",vendor: "CHENGDU KT ELECTRONIC HI-TECH CO.,LTD"},
  {max_prefix: "80-26-89",vendor: "D-Link International"},
  {max_prefix: "F8-AB-05",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "50-3A-7D",vendor: "AlphaTech PLC Int\u2019l Co., Ltd."},
  {max_prefix: "F4-C4-D6",vendor: "Shenzhen Xinfa Electronic Co.,ltd"},
  {max_prefix: "7C-50-49",vendor: "Apple, Inc."},
  {max_prefix: "E4-7D-EB",vendor: "Shanghai Notion Information Technology CO.,LTD."},
  {max_prefix: "D8-32-5A",vendor: "Shenzhen YOUHUA Technology Co., Ltd"},
  {max_prefix: "9C-DA-3E",vendor: "Intel Corporate"},
  {max_prefix: "28-3F-69",vendor: "Sony Corporation"},
  {max_prefix: "D8-32-14",vendor: "Tenda Technology Co.,Ltd.Dongguan branch"},
  {max_prefix: "10-95-4B",vendor: "Megabyte Ltd."},
  {max_prefix: "C4-B9-CD",vendor: "Cisco Systems, Inc"},
  {max_prefix: "E0-C0-D1",vendor: "CK Telecom (Shenzhen) Limited"},
  {max_prefix: "C0-D3-C0",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "94-8B-C1",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "14-56-8E",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "68-37-E9",vendor: "Amazon Technologies Inc."},
  {max_prefix: "00-10-81",vendor: "DPS, INC."},
  {max_prefix: "40-F3-85",vendor: "IEEE Registration Authority"},
  {max_prefix: "88-78-73",vendor: "Intel Corporate"},
  {max_prefix: "F8-75-88",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "F4-4C-7F",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "64-B4-73",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "74-51-BA",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "6C-B4-A7",vendor: "Landauer, Inc."},
  {max_prefix: "F8-A5-C5",vendor: "Cisco Systems, Inc"},
  {max_prefix: "7C-5A-1C",vendor: "Sophos Ltd"},
  {max_prefix: "B0-F1-EC",vendor: "AMPAK Technology, Inc."},
  {max_prefix: "54-2B-57",vendor: "Night Owl SP"},
  {max_prefix: "78-02-F8",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "00-23-8A",vendor: "Ciena Corporation"},
  {max_prefix: "08-00-27",vendor: "PCS Systemtechnik GmbH"},
  {max_prefix: "2C-4D-54",vendor: "ASUSTek COMPUTER INC."},
  {max_prefix: "34-96-72",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "70-20-84",vendor: "Hon Hai Precision Industry Co., Ltd."},
  {max_prefix: "9C-66-50",vendor: "Glodio Technolies Co.,Ltd Tianjin Branch"},
  {max_prefix: "D4-61-FE",vendor: "Hangzhou H3C Technologies Co., Limited"},
  {max_prefix: "50-1E-2D",vendor: "StreamUnlimited Engineering GmbH"},
  {max_prefix: "A0-A3-3B",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "EC-01-EE",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "60-49-C1",vendor: "Avaya Inc"},
  {max_prefix: "E4-5D-51",vendor: "SFR"},
  {max_prefix: "7C-67-A2",vendor: "Intel Corporate"},
  {max_prefix: "00-E0-5A",vendor: "GALEA NETWORK SECURITY"},
  {max_prefix: "F0-6E-32",vendor: "MICROTEL INNOVATION S.R.L."},
  {max_prefix: "C4-30-18",vendor: "MCS Logic Inc."},
  {max_prefix: "68-31-FE",vendor: "Teladin Co.,Ltd."},
  {max_prefix: "28-16-AD",vendor: "Intel Corporate"},
  {max_prefix: "D0-FF-98",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "94-65-2D",vendor: "OnePlus Technology (Shenzhen) Co., Ltd"},
  {max_prefix: "00-60-D3",vendor: "AT&T"},
  {max_prefix: "84-8D-C7",vendor: "Cisco SPVTG"},
  {max_prefix: "04-5D-4B",vendor: "Sony Corporation"},
  {max_prefix: "78-AF-58",vendor: "GIMASI SA"},
  {max_prefix: "1C-B8-57",vendor: "Becon Technologies Co,.Ltd."},
  {max_prefix: "48-A3-80",vendor: "Gionee Communication Equipment Co.,Ltd."},
  {max_prefix: "68-27-37",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "00-19-92",vendor: "Adtran Inc"},
  {max_prefix: "3C-F8-62",vendor: "Intel Corporate"},
  {max_prefix: "B0-E5-ED",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "C4-86-E9",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "34-4B-3D",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "8C-93-51",vendor: "Jigowatts Inc."},
  {max_prefix: "D8-38-FC",vendor: "Ruckus Wireless"},
  {max_prefix: "2C-0B-E9",vendor: "Cisco Systems, Inc"},
  {max_prefix: "90-50-5A",vendor: "unGlue, Inc"},
  {max_prefix: "34-78-D7",vendor: "Gionee Communication Equipment Co.,Ltd."},
  {max_prefix: "5C-CC-A0",vendor: "Gridwiz Inc."},
  {max_prefix: "20-AB-37",vendor: "Apple, Inc."},
  {max_prefix: "60-F4-45",vendor: "Apple, Inc."},
  {max_prefix: "E0-A7-00",vendor: "Verkada Inc"},
  {max_prefix: "58-40-4E",vendor: "Apple, Inc."},
  {max_prefix: "D0-C5-F3",vendor: "Apple, Inc."},
  {max_prefix: "BC-9F-EF",vendor: "Apple, Inc."},
  {max_prefix: "58-00-E3",vendor: "Liteon Technology Corporation"},
  {max_prefix: "00-0A-E4",vendor: "Wistron Corporation"},
  {max_prefix: "A4-08-F5",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "00-B0-91",vendor: "Transmeta Corp."},
  {max_prefix: "AC-C6-62",vendor: "MitraStar Technology Corp."},
  {max_prefix: "88-6B-44",vendor: "Sunnovo International Limited"},
  {max_prefix: "FC-3C-E9",vendor: "Tsingtong Technologies Co, Ltd."},
  {max_prefix: "A4-58-0F",vendor: "IEEE Registration Authority"},
  {max_prefix: "48-F9-7C",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "C0-BF-C0",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "A0-8C-F8",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "10-13-31",vendor: "Technicolor Delivery Technologies Belgium NV"},
  {max_prefix: "4C-B8-1C",vendor: "SAM Electronics GmbH"},
  {max_prefix: "44-D2-44",vendor: "Seiko Epson Corporation"},
  {max_prefix: "7C-F9-5C",vendor: "U.I. Lapp GmbH"},
  {max_prefix: "C8-F7-33",vendor: "Intel Corporate"},
  {max_prefix: "A0-3D-6F",vendor: "Cisco Systems, Inc"},
  {max_prefix: "A0-E0-AF",vendor: "Cisco Systems, Inc"},
  {max_prefix: "A4-E6-B1",vendor: "Shanghai Joindata Technology Co.,Ltd."},
  {max_prefix: "C0-9C-04",vendor: "Shaanxi GuoLian Digital TV Technology Co.,Ltd."},
  {max_prefix: "AC-D6-57",vendor: "Shaanxi GuoLian Digital TV Technology Co.,Ltd."},
  {max_prefix: "3C-EF-8C",vendor: "Zhejiang Dahua Technology Co., Ltd."},
  {max_prefix: "8C-2F-A6",vendor: "Solid Optics B.V."},
  {max_prefix: "8C-19-2D",vendor: "IEEE Registration Authority"},
  {max_prefix: "64-73-E2",vendor: "Arbiter Systems, Inc."},
  {max_prefix: "E4-5D-52",vendor: "Avaya Inc"},
  {max_prefix: "94-E9-79",vendor: "Liteon Technology Corporation"},
  {max_prefix: "B4-96-91",vendor: "Intel Corporate"},
  {max_prefix: "00-76-86",vendor: "Cisco Systems, Inc"},
  {max_prefix: "D0-60-8C",vendor: "zte corporation"},
  {max_prefix: "7C-03-C9",vendor: "Shenzhen YOUHUA Technology Co., Ltd"},
  {max_prefix: "00-30-48",vendor: "Super Micro Computer, Inc."},
  {max_prefix: "9C-D9-CB",vendor: "Lesira Manufacturing Pty Ltd"},
  {max_prefix: "AC-23-3F",vendor: "Shenzhen Minew Technologies Co., Ltd."},
  {max_prefix: "3C-80-AA",vendor: "Ransnet Singapore Pte Ltd"},
  {max_prefix: "88-C6-26",vendor: "Logitech, Inc"},
  {max_prefix: "00-23-F7",vendor: "Private"},
  {max_prefix: "D4-C8-B0",vendor: "Prime Electronics & Satellitics Inc."},
  {max_prefix: "04-81-AE",vendor: "Clack Corporation"},
  {max_prefix: "9C-13-AB",vendor: "Chanson Water Co., Ltd."},
  {max_prefix: "70-3D-15",vendor: "Hangzhou H3C Technologies Co., Limited"},
  {max_prefix: "E4-9E-12",vendor: "FREEBOX SAS"},
  {max_prefix: "18-F2-92",vendor: "Shannon Systems"},
  {max_prefix: "8C-EA-1B",vendor: "Edgecore Networks Corporation"},
  {max_prefix: "24-20-C7",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "70-1C-E7",vendor: "Intel Corporate"},
  {max_prefix: "9C-2A-70",vendor: "Hon Hai Precision Ind. Co.,Ltd."},
  {max_prefix: "58-E1-6C",vendor: "Ying Hua Information Technology (Shanghai)Co., LTD"},
  {max_prefix: "C8-21-58",vendor: "Intel Corporate"},
  {max_prefix: "98-E4-76",vendor: "Zentan"},
  {max_prefix: "14-A5-1A",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "04-75-03",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "50-B3-63",vendor: "Digitron da Amazonia S/A"},
  {max_prefix: "D4-E9-0B",vendor: "CVT CO.,LTD"},
  {max_prefix: "CC-B0-DA",vendor: "Liteon Technology Corporation"},
  {max_prefix: "A4-D9-A4",vendor: "neXus ID Solutions AB"},
  {max_prefix: "E0-2C-F3",vendor: "MRS Electronic GmbH"},
  {max_prefix: "2C-0E-3D",vendor: "SAMSUNG ELECTRO-MECHANICS(THAILAND)"},
  {max_prefix: "48-7A-55",vendor: "ALE International"},
  {max_prefix: "00-1E-AE",vendor: "Continental Automotive Systems Inc."},
  {max_prefix: "2C-BA-BA",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "40-D3-AE",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "2C-DD-95",vendor: "Taicang T&W Electronics"},
  {max_prefix: "88-E8-7F",vendor: "Apple, Inc."},
  {max_prefix: "9C-F4-8E",vendor: "Apple, Inc."},
  {max_prefix: "5C-F7-E6",vendor: "Apple, Inc."},
  {max_prefix: "B8-53-AC",vendor: "Apple, Inc."},
  {max_prefix: "20-3C-AE",vendor: "Apple, Inc."},
  {max_prefix: "A0-3B-E3",vendor: "Apple, Inc."},
  {max_prefix: "4C-32-75",vendor: "Apple, Inc."},
  {max_prefix: "9C-7D-A3",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "A4-C6-4F",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "00-D7-8F",vendor: "Cisco Systems, Inc"},
  {max_prefix: "10-72-23",vendor: "TELLESCOM INDUSTRIA E COMERCIO EM TELECOMUNICACAO"},
  {max_prefix: "D0-3D-C3",vendor: "AQ Corporation"},
  {max_prefix: "EC-01-E2",vendor: "FOXCONN INTERCONNECT TECHNOLOGY"},
  {max_prefix: "E0-68-6D",vendor: "Raybased AB"},
  {max_prefix: "CC-FD-17",vendor: "TCT mobile ltd"},
  {max_prefix: "50-2B-73",vendor: "Tenda Technology Co.,Ltd.Dongguan branch"},
  {max_prefix: "B4-E7-82",vendor: "Vivalnk"},
  {max_prefix: "44-09-B8",vendor: "Salcomp (Shenzhen) CO., LTD."},
  {max_prefix: "04-BA-36",vendor: "Li Seng Technology Ltd"},
  {max_prefix: "B4-F8-1E",vendor: "Kinova"},
  {max_prefix: "00-16-28",vendor: "Magicard Ltd"},
  {max_prefix: "70-2E-22",vendor: "zte corporation"},
  {max_prefix: "38-16-D1",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "D0-17-6A",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "D4-88-90",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "54-92-BE",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "18-61-C7",vendor: "lemonbeat GmbH"},
  {max_prefix: "20-5D-47",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "10-C6-0C",vendor: "Domino UK Ltd"},
  {max_prefix: "04-31-10",vendor: "Inspur Group Co., Ltd."},
  {max_prefix: "94-9A-A9",vendor: "Microsoft Corporation"},
  {max_prefix: "AC-AB-2E",vendor: "Beijing LasNubes Technology Co., Ltd."},
  {max_prefix: "00-06-78",vendor: "D&M Holdings Inc."},
  {max_prefix: "88-4C-CF",vendor: "Pulzze Systems, Inc"},
  {max_prefix: "E0-28-6D",vendor: "AVM Audiovisuelles Marketing und Computersysteme GmbH"},
  {max_prefix: "98-18-88",vendor: "Cisco Meraki"},
  {max_prefix: "4C-C8-A1",vendor: "Cisco Meraki"},
  {max_prefix: "C8-B2-1E",vendor: "CHIPSEA TECHNOLOGIES (SHENZHEN) CORP."},
  {max_prefix: "60-0B-03",vendor: "Hangzhou H3C Technologies Co., Limited"},
  {max_prefix: "A0-AB-1B",vendor: "D-Link International"},
  {max_prefix: "D8-42-E2",vendor: "Canary Connect, Inc."},
  {max_prefix: "C8-E7-76",vendor: "PTCOM Technology"},
  {max_prefix: "30-23-03",vendor: "Belkin International Inc."},
  {max_prefix: "AC-17-C8",vendor: "Cisco Meraki"},
  {max_prefix: "E4-12-18",vendor: "ShenZhen Rapoo Technology Co., Ltd."},
  {max_prefix: "00-19-84",vendor: "ESTIC Corporation"},
  {max_prefix: "00-21-2F",vendor: "Phoebe Micro Inc."},
  {max_prefix: "38-59-F9",vendor: "Hon Hai Precision Ind. Co.,Ltd."},
  {max_prefix: "EC-55-F9",vendor: "Hon Hai Precision Ind. Co.,Ltd."},
  {max_prefix: "00-1A-8A",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "3C-5A-37",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "F4-9F-54",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "34-C3-AC",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "44-F4-59",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "00-26-5D",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "00-25-67",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "BC-B1-F3",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "5C-0A-5B",vendor: "SAMSUNG ELECTRO MECHANICS CO., LTD."},
  {max_prefix: "80-56-F2",vendor: "Hon Hai Precision Ind. Co.,Ltd."},
  {max_prefix: "00-02-78",vendor: "SAMSUNG ELECTRO MECHANICS CO., LTD."},
  {max_prefix: "00-23-99",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "7C-F8-54",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "7C-E9-D3",vendor: "Hon Hai Precision Ind. Co.,Ltd."},
  {max_prefix: "1C-3E-84",vendor: "Hon Hai Precision Ind. Co.,Ltd."},
  {max_prefix: "B8-76-3F",vendor: "Hon Hai Precision Ind. Co.,Ltd."},
  {max_prefix: "60-F4-94",vendor: "Hon Hai Precision Ind. Co.,Ltd."},
  {max_prefix: "90-6E-BB",vendor: "Hon Hai Precision Ind. Co.,Ltd."},
  {max_prefix: "18-F4-6A",vendor: "Hon Hai Precision Ind. Co.,Ltd."},
  {max_prefix: "4C-0F-6E",vendor: "Hon Hai Precision Ind. Co.,Ltd."},
  {max_prefix: "78-E4-00",vendor: "Hon Hai Precision Ind. Co.,Ltd."},
  {max_prefix: "C4-73-1E",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "00-1C-43",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "00-23-D6",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "00-1B-98",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "44-D6-E1",vendor: "Snuza International Pty. Ltd."},
  {max_prefix: "48-6D-BB",vendor: "Vestel Elektronik San ve Tic. A.S."},
  {max_prefix: "00-2A-10",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-A2-89",vendor: "Cisco Systems, Inc"},
  {max_prefix: "44-E9-DD",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "00-0F-5E",vendor: "Veo"},
  {max_prefix: "00-13-28",vendor: "Westech Korea Inc.,"},
  {max_prefix: "B8-BF-83",vendor: "Intel Corporate"},
  {max_prefix: "84-E0-F4",vendor: "IEEE Registration Authority"},
  {max_prefix: "D8-30-62",vendor: "Apple, Inc."},
  {max_prefix: "7C-79-E8",vendor: "PayRange Inc."},
  {max_prefix: "A4-31-11",vendor: "ZIV"},
  {max_prefix: "00-80-73",vendor: "DWB ASSOCIATES"},
  {max_prefix: "80-A1-D7",vendor: "Shanghai DareGlobal Technologies Co.,Ltd"},
  {max_prefix: "EC-1F-72",vendor: "SAMSUNG ELECTRO-MECHANICS(THAILAND)"},
  {max_prefix: "8C-0D-76",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "84-BE-52",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "84-9F-B5",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "A4-CA-A0",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "14-F4-2A",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "08-08-C2",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "CC-FE-3C",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "28-BA-B5",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "E4-40-E2",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "10-3B-59",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "D8-90-E8",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "E4-12-1D",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "E8-50-8B",vendor: "SAMSUNG ELECTRO-MECHANICS(THAILAND)"},
  {max_prefix: "F8-04-2E",vendor: "SAMSUNG ELECTRO-MECHANICS(THAILAND)"},
  {max_prefix: "1C-62-B8",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "CC-F9-E8",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "D8-57-EF",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "18-E2-C2",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "98-52-B1",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "C4-62-EA",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "18-26-66",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "30-D6-C9",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "CC-07-AB",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "B4-3A-28",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "78-A8-73",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "BC-8A-A3",vendor: "NHN Entertainment"},
  {max_prefix: "04-BB-F9",vendor: "Pavilion Data Systems Inc"},
  {max_prefix: "58-FB-84",vendor: "Intel Corporate"},
  {max_prefix: "8C-61-02",vendor: "Beijing Baofengmojing Technologies Co., Ltd"},
  {max_prefix: "54-8C-A0",vendor: "Liteon Technology Corporation"},
  {max_prefix: "34-57-60",vendor: "MitraStar Technology Corp."},
  {max_prefix: "C0-D3-91",vendor: "IEEE Registration Authority"},
  {max_prefix: "D4-9B-5C",vendor: "Chongqing Miedu Technology Co., Ltd."},
  {max_prefix: "00-C0-17",vendor: "NetAlly"},
  {max_prefix: "00-22-61",vendor: "Frontier Silicon Ltd"},
  {max_prefix: "E8-EB-11",vendor: "Texas Instruments"},
  {max_prefix: "3C-6F-EA",vendor: "Panasonic India Pvt. Ltd."},
  {max_prefix: "00-19-88",vendor: "Wi2Wi, Inc"},
  {max_prefix: "18-DC-56",vendor: "Yulong Computer Telecommunication Scientific (Shenzhen) Co.,Ltd"},
  {max_prefix: "00-16-F2",vendor: "Dmobile System Co., Ltd."},
  {max_prefix: "44-BF-E3",vendor: "Shenzhen Longtech Electronics Co.,Ltd"},
  {max_prefix: "34-07-4F",vendor: "AccelStor, Inc."},
  {max_prefix: "B4-A9-84",vendor: "Symantec Corporation"},
  {max_prefix: "00-E0-11",vendor: "UNIDEN CORPORATION"},
  {max_prefix: "00-25-55",vendor: "Visonic Technologies 1993 Ltd."},
  {max_prefix: "58-98-6F",vendor: "Revolution Display"},
  {max_prefix: "C8-1F-BE",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "20-3D-B2",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "48-D5-39",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "C8-8D-83",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "B0-B2-8F",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "44-14-41",vendor: "AudioControl Inc."},
  {max_prefix: "00-1F-9A",vendor: "Nortel Networks"},
  {max_prefix: "00-0A-0E",vendor: "Invivo Research Inc."},
  {max_prefix: "00-04-38",vendor: "Nortel Networks"},
  {max_prefix: "00-0E-C0",vendor: "Nortel Networks"},
  {max_prefix: "3C-40-4F",vendor: "GUANGDONG PISEN ELECTRONICS CO.,LTD"},
  {max_prefix: "D8-4F-B8",vendor: "LG ELECTRONICS"},
  {max_prefix: "00-0A-EB",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "60-EE-5C",vendor: "SHENZHEN FAST TECHNOLOGIES CO.,LTD"},
  {max_prefix: "64-88-FF",vendor: "Sichuan Changhong Electric Ltd."},
  {max_prefix: "C4-08-4A",vendor: "Nokia"},
  {max_prefix: "00-08-01",vendor: "HighSpeed Surfing Inc."},
  {max_prefix: "00-07-72",vendor: "Alcatel-Lucent Shanghai Bell Co., Ltd"},
  {max_prefix: "E0-30-05",vendor: "Alcatel-Lucent Shanghai Bell Co., Ltd"},
  {max_prefix: "00-1E-7E",vendor: "Nortel Networks"},
  {max_prefix: "00-13-65",vendor: "Nortel Networks"},
  {max_prefix: "00-21-62",vendor: "Nortel Networks"},
  {max_prefix: "02-E6-D3",vendor: "NIXDORF COMPUTER CORP."},
  {max_prefix: "0C-A4-02",vendor: "Alcatel-Lucent IPD"},
  {max_prefix: "A0-F3-E4",vendor: "Alcatel-Lucent IPD"},
  {max_prefix: "84-DB-FC",vendor: "Nokia"},
  {max_prefix: "00-16-B9",vendor: "ProCurve Networking by HP"},
  {max_prefix: "00-16-60",vendor: "Nortel Networks"},
  {max_prefix: "00-AA-70",vendor: "LG Electronics (Mobile Communications)"},
  {max_prefix: "F8-95-C7",vendor: "LG Electronics (Mobile Communications)"},
  {max_prefix: "84-D9-31",vendor: "Hangzhou H3C Technologies Co., Limited"},
  {max_prefix: "7C-FC-3C",vendor: "Visteon Corporation"},
  {max_prefix: "48-88-CA",vendor: "Motorola (Wuhan) Mobility Technologies Communication Co., Ltd."},
  {max_prefix: "38-56-10",vendor: "CANDY HOUSE, Inc."},
  {max_prefix: "00-A7-42",vendor: "Cisco Systems, Inc"},
  {max_prefix: "2C-37-31",vendor: "SHENZHEN YIFANG DIGITAL TECHNOLOGY CO.,LTD."},
  {max_prefix: "00-11-6E",vendor: "Peplink International Ltd."},
  {max_prefix: "54-09-55",vendor: "zte corporation"},
  {max_prefix: "C0-41-F6",vendor: "LG ELECTRONICS INC"},
  {max_prefix: "00-1E-75",vendor: "LG Electronics (Mobile Communications)"},
  {max_prefix: "00-1C-62",vendor: "LG Electronics (Mobile Communications)"},
  {max_prefix: "50-55-27",vendor: "LG Electronics (Mobile Communications)"},
  {max_prefix: "88-C9-D0",vendor: "LG Electronics (Mobile Communications)"},
  {max_prefix: "8C-3A-E3",vendor: "LG Electronics (Mobile Communications)"},
  {max_prefix: "D0-05-2A",vendor: "Arcadyan Corporation"},
  {max_prefix: "EC-68-81",vendor: "Palo Alto Networks"},
  {max_prefix: "E4-50-9A",vendor: "HW Communications Ltd"},
  {max_prefix: "70-29-00",vendor: "Shenzhen ChipTrip Technology Co,Ltd"},
  {max_prefix: "EC-AA-A0",vendor: "PEGATRON CORPORATION"},
  {max_prefix: "60-E3-AC",vendor: "LG Electronics (Mobile Communications)"},
  {max_prefix: "90-F0-52",vendor: "MEIZU Technology Co., Ltd."},
  {max_prefix: "90-A4-6A",vendor: "SISNET CO., LTD"},
  {max_prefix: "14-E7-C8",vendor: "Integrated Device Technology (Malaysia) Sdn. Bhd."},
  {max_prefix: "EC-CD-6D",vendor: "Allied Telesis, Inc."},
  {max_prefix: "98-1E-0F",vendor: "Jeelan (Shanghai Jeelan Technology Information Inc"},
  {max_prefix: "9C-A3-A9",vendor: "Guangzhou Juan Optical and Electronical Tech Joint Stock Co., Ltd"},
  {max_prefix: "7C-C7-09",vendor: "SHENZHEN RF-LINK TECHNOLOGY CO.,LTD."},
  {max_prefix: "18-33-9D",vendor: "Cisco Systems, Inc"},
  {max_prefix: "14-61-02",vendor: "Alps Alpine"},
  {max_prefix: "54-27-6C",vendor: "Jiangsu Houge Technology Corp."},
  {max_prefix: "08-21-EF",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "34-14-5F",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "A0-3E-6B",vendor: "IEEE Registration Authority"},
  {max_prefix: "98-02-D8",vendor: "IEEE Registration Authority"},
  {max_prefix: "64-FB-81",vendor: "IEEE Registration Authority"},
  {max_prefix: "2C-26-5F",vendor: "IEEE Registration Authority"},
  {max_prefix: "8C-FD-F0",vendor: "Qualcomm Inc."},
  {max_prefix: "C4-BB-4C",vendor: "Zebra Information Tech Co. Ltd"},
  {max_prefix: "98-CF-53",vendor: "BBK EDUCATIONAL ELECTRONICS CORP.,LTD."},
  {max_prefix: "D4-A1-48",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "4C-0B-BE",vendor: "Microsoft"},
  {max_prefix: "0C-25-76",vendor: "LONGCHEER TELECOMMUNICATION LIMITED"},
  {max_prefix: "D8-D4-3C",vendor: "Sony Corporation"},
  {max_prefix: "48-6B-2C",vendor: "BBK EDUCATIONAL ELECTRONICS CORP.,LTD."},
  {max_prefix: "6C-25-B9",vendor: "BBK EDUCATIONAL ELECTRONICS CORP.,LTD."},
  {max_prefix: "2C-28-2D",vendor: "BBK EDUCATIONAL ELECTRONICS CORP.,LTD."},
  {max_prefix: "48-13-F3",vendor: "BBK EDUCATIONAL ELECTRONICS CORP.,LTD."},
  {max_prefix: "00-40-9F",vendor: "Telco Systems, Inc."},
  {max_prefix: "00-00-1F",vendor: "Telco Systems, Inc."},
  {max_prefix: "00-A0-12",vendor: "Telco Systems, Inc."},
  {max_prefix: "F0-DE-F1",vendor: "Wistron Infocomm (Zhongshan) Corporation"},
  {max_prefix: "F8-0F-41",vendor: "Wistron Infocomm (Zhongshan) Corporation"},
  {max_prefix: "3C-97-0E",vendor: "Wistron InfoComm(Kunshan)Co.,Ltd."},
  {max_prefix: "30-14-4A",vendor: "Wistron Neweb Corporation"},
  {max_prefix: "D0-65-CA",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "8C-EB-C6",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "B0-89-00",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "00-E0-DD",vendor: "Zenith Electronics Corporation"},
  {max_prefix: "50-CE-75",vendor: "Measy Electronics Co., Ltd."},
  {max_prefix: "00-16-39",vendor: "Ubiquam Co., Ltd."},
  {max_prefix: "00-15-97",vendor: "AETA AUDIO SYSTEMS"},
  {max_prefix: "00-23-97",vendor: "Westell Technologies Inc."},
  {max_prefix: "00-04-5B",vendor: "Techsan Electronics Co., Ltd."},
  {max_prefix: "00-07-BA",vendor: "UTStarcom Inc"},
  {max_prefix: "90-A2-10",vendor: "United Telecoms Ltd"},
  {max_prefix: "6C-0B-84",vendor: "Universal Global Scientific Industrial Co., Ltd."},
  {max_prefix: "78-CB-68",vendor: "DAEHAP HYPER-TECH"},
  {max_prefix: "00-0B-0E",vendor: "Trapeze Networks"},
  {max_prefix: "00-0C-29",vendor: "VMware, Inc."},
  {max_prefix: "00-05-69",vendor: "VMware, Inc."},
  {max_prefix: "E4-02-9B",vendor: "Intel Corporate"},
  {max_prefix: "DC-1A-C5",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "B4-56-B9",vendor: "Teraspek Technologies Co.,Ltd"},
  {max_prefix: "9C-DD-1F",vendor: "Intelligent Steward Co.,Ltd"},
  {max_prefix: "3C-68-16",vendor: "VXi Corporation"},
  {max_prefix: "E8-11-CA",vendor: "SHANDONG KAER ELECTRIC.CO.,LTD"},
  {max_prefix: "00-C0-00",vendor: "LANOPTICS, LTD."},
  {max_prefix: "84-51-81",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "A8-FC-B7",vendor: "Consolidated Resource Imaging"},
  {max_prefix: "C8-16-BD",vendor: "Qingdao Hisense Communications Co.,Ltd."},
  {max_prefix: "00-EB-D5",vendor: "Cisco Systems, Inc"},
  {max_prefix: "34-ED-0B",vendor: " Shanghai XZ-COM.CO.,Ltd."},
  {max_prefix: "90-EF-68",vendor: "Zyxel Communications Corporation"},
  {max_prefix: "F4-5E-AB",vendor: "Texas Instruments"},
  {max_prefix: "C4-A3-66",vendor: "zte corporation"},
  {max_prefix: "60-73-BC",vendor: "zte corporation"},
  {max_prefix: "18-B1-69",vendor: "Sonicwall"},
  {max_prefix: "44-44-50",vendor: "OttoQ"},
  {max_prefix: "50-F5-DA",vendor: "Amazon Technologies Inc."},
  {max_prefix: "10-12-12",vendor: "Vivo International Corporation Pty Ltd"},
  {max_prefix: "F0-3E-BF",vendor: "GOGORO TAIWAN LIMITED"},
  {max_prefix: "C4-8F-07",vendor: "Shenzhen Yihao Hulian Science and Technology Co., Ltd."},
  {max_prefix: "DC-78-34",vendor: "LOGICOM SA"},
  {max_prefix: "D0-57-7B",vendor: "Intel Corporate"},
  {max_prefix: "6C-95-22",vendor: "Scalys"},
  {max_prefix: "A8-BB-50",vendor: "WiZ IoT Company Limited"},
  {max_prefix: "3C-92-DC",vendor: "Octopod Technology Co. Ltd."},
  {max_prefix: "08-C0-21",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "60-08-10",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "48-43-5A",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "78-FF-CA",vendor: "TECNO MOBILE LIMITED"},
  {max_prefix: "04-65-65",vendor: "Testop"},
  {max_prefix: "7C-35-48",vendor: "Transcend Information"},
  {max_prefix: "78-00-9E",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "AC-C3-3A",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "54-F2-01",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "70-28-8B",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "34-8A-7B",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "10-00-FD",vendor: "LaonPeople"},
  {max_prefix: "C4-7C-8D",vendor: "IEEE Registration Authority"},
  {max_prefix: "84-83-19",vendor: "Hangzhou Zero Zero Technology Co., Ltd."},
  {max_prefix: "7C-B0-C2",vendor: "Intel Corporate"},
  {max_prefix: "A8-15-59",vendor: "Breathometer, Inc."},
  {max_prefix: "C4-CA-D9",vendor: "Hangzhou H3C Technologies Co., Limited"},
  {max_prefix: "58-66-BA",vendor: "Hangzhou H3C Technologies Co., Limited"},
  {max_prefix: "70-BA-EF",vendor: "Hangzhou H3C Technologies Co., Limited"},
  {max_prefix: "58-6A-B1",vendor: "Hangzhou H3C Technologies Co., Limited"},
  {max_prefix: "00-90-06",vendor: "Hamamatsu Photonics K.K."},
  {max_prefix: "00-1A-F4",vendor: "Handreamnet"},
  {max_prefix: "E0-0E-DA",vendor: "Cisco Systems, Inc"},
  {max_prefix: "4C-0B-3A",vendor: "TCT mobile ltd"},
  {max_prefix: "E4-2D-02",vendor: "TCT mobile ltd"},
  {max_prefix: "0C-BD-51",vendor: "TCT mobile ltd"},
  {max_prefix: "74-5C-9F",vendor: "TCT mobile ltd"},
  {max_prefix: "8C-99-E6",vendor: "TCT mobile ltd"},
  {max_prefix: "44-9F-7F",vendor: "DataCore Software Corporation"},
  {max_prefix: "00-17-93",vendor: "Tigi Corporation"},
  {max_prefix: "00-03-58",vendor: "Hanyang Digitech Co.Ltd"},
  {max_prefix: "8C-8E-F2",vendor: "Apple, Inc."},
  {max_prefix: "90-B0-ED",vendor: "Apple, Inc."},
  {max_prefix: "04-D3-CF",vendor: "Apple, Inc."},
  {max_prefix: "48-82-F2",vendor: "Appel Elektronik GmbH"},
  {max_prefix: "E0-C7-9D",vendor: "Texas Instruments"},
  {max_prefix: "00-17-7D",vendor: "IDT Technology Limited"},
  {max_prefix: "40-00-E0",vendor: "Derek(Shaoguan)Limited"},
  {max_prefix: "FC-BC-9C",vendor: "Vimar Spa"},
  {max_prefix: "E8-09-59",vendor: "Guoguang Electric Co.,Ltd"},
  {max_prefix: "F0-40-7B",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "94-88-5E",vendor: "Surfilter Network Technology Co., Ltd."},
  {max_prefix: "94-50-89",vendor: "SimonsVoss Technologies GmbH"},
  {max_prefix: "00-24-F4",vendor: "Kaminario, Ltd."},
  {max_prefix: "00-1A-29",vendor: "Johnson Outdoors Marine Electronics d/b/a Minnkota"},
  {max_prefix: "00-90-AE",vendor: "ITALTEL S.p.A/RF-UP-I"},
  {max_prefix: "04-2A-E2",vendor: "Cisco Systems, Inc"},
  {max_prefix: "E0-B6-F5",vendor: "IEEE Registration Authority"},
  {max_prefix: "D0-A4-B1",vendor: "Sonifex Ltd."},
  {max_prefix: "50-DD-4F",vendor: "Automation Components, Inc"},
  {max_prefix: "F4-9E-EF",vendor: "Taicang T&W Electronics"},
  {max_prefix: "00-23-78",vendor: "GN Netcom A/S"},
  {max_prefix: "50-C9-71",vendor: "GN Netcom A/S"},
  {max_prefix: "C4-F0-81",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "80-13-82",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "94-FE-22",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "00-40-9C",vendor: "TRANSWARE"},
  {max_prefix: "60-83-34",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "E4-7E-66",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "94-DB-DA",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "B0-1B-D2",vendor: "Le Shi Zhi Xin Electronic Technology (Tianjin) Limited"},
  {max_prefix: "1C-3A-DE",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "00-23-60",vendor: "Lookit Technology Co., Ltd"},
  {max_prefix: "F4-62-D0",vendor: "Not for Radio, LLC"},
  {max_prefix: "84-00-2D",vendor: "PEGATRON CORPORATION"},
  {max_prefix: "40-82-56",vendor: "Continental Automotive GmbH"},
  {max_prefix: "E4-A1-E6",vendor: "Alcatel-Lucent Shanghai Bell Co., Ltd"},
  {max_prefix: "1C-AB-C0",vendor: "Hitron Technologies. Inc"},
  {max_prefix: "C8-25-E1",vendor: "Lemobile Information Technology (Beijing) Co., Ltd"},
  {max_prefix: "54-D9-E4",vendor: "BRILLIANTTS CO., LTD"},
  {max_prefix: "00-90-FA",vendor: "Emulex Corporation"},
  {max_prefix: "00-E0-D5",vendor: "Emulex Corporation"},
  {max_prefix: "84-FE-DC",vendor: "Borqs Beijing Ltd."},
  {max_prefix: "00-10-35",vendor: "Elitegroup Computer Systems Co.,Ltd."},
  {max_prefix: "00-0A-E6",vendor: "Elitegroup Computer Systems Co.,Ltd."},
  {max_prefix: "74-27-EA",vendor: "Elitegroup Computer Systems Co.,Ltd."},
  {max_prefix: "64-99-68",vendor: "Elentec"},
  {max_prefix: "98-DE-D0",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "00-5B-A1",vendor: "shanghai huayuan chuangxin software CO., LTD."},
  {max_prefix: "58-D6-7A",vendor: "TCPlink"},
  {max_prefix: "5C-C7-D7",vendor: "AZROAD TECHNOLOGY COMPANY LIMITED"},
  {max_prefix: "50-9E-A7",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "A8-81-95",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "88-AD-D2",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "A4-BF-01",vendor: "Intel Corporate"},
  {max_prefix: "10-DA-43",vendor: "NETGEAR"},
  {max_prefix: "B8-05-AB",vendor: "zte corporation"},
  {max_prefix: "78-96-82",vendor: "zte corporation"},
  {max_prefix: "C4-BE-D4",vendor: "Avaya Inc"},
  {max_prefix: "D0-17-C2",vendor: "ASUSTek COMPUTER INC."},
  {max_prefix: "98-07-2D",vendor: "Texas Instruments"},
  {max_prefix: "F0-C7-7F",vendor: "Texas Instruments"},
  {max_prefix: "D4-67-E7",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "E4-2F-26",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "04-C1-B9",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "34-99-71",vendor: "Quanta Storage Inc."},
  {max_prefix: "9C-52-F8",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "5C-F2-86",vendor: "IEEE Registration Authority"},
  {max_prefix: "E8-FD-72",vendor: "SHANGHAI LINGUO TECHNOLOGY CO., LTD."},
  {max_prefix: "98-BB-1E",vendor: "BYD Precision Manufacture Company Ltd."},
  {max_prefix: "04-C1-03",vendor: "Clover Network, Inc."},
  {max_prefix: "28-0C-28",vendor: "Unigen DataStorage Corporation"},
  {max_prefix: "00-CC-FC",vendor: "Cisco Systems, Inc"},
  {max_prefix: "08-BE-77",vendor: "Green Electronics"},
  {max_prefix: "AC-5F-3E",vendor: "SAMSUNG ELECTRO-MECHANICS(THAILAND)"},
  {max_prefix: "54-6D-52",vendor: "TOPVIEW OPTRONICS CORP."},
  {max_prefix: "54-5A-A6",vendor: "Espressif Inc."},
  {max_prefix: "FC-1A-11",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "38-FD-FE",vendor: "IEEE Registration Authority"},
  {max_prefix: "2C-09-CB",vendor: "COBS AB"},
  {max_prefix: "20-8B-37",vendor: "Skyworth Digital Technology(Shenzhen) Co.,Ltd"},
  {max_prefix: "00-23-40",vendor: "MiXTelematics"},
  {max_prefix: "B4-8B-19",vendor: "Apple, Inc."},
  {max_prefix: "BC-EC-5D",vendor: "Apple, Inc."},
  {max_prefix: "28-A0-2B",vendor: "Apple, Inc."},
  {max_prefix: "00-19-C5",vendor: "Sony Interactive Entertainment Inc."},
  {max_prefix: "00-13-15",vendor: "Sony Interactive Entertainment Inc."},
  {max_prefix: "00-1E-1E",vendor: "Honeywell Life Safety"},
  {max_prefix: "A0-C5-89",vendor: "Intel Corporate"},
  {max_prefix: "1C-23-4F",vendor: "EDMI  Europe Ltd"},
  {max_prefix: "A4-44-D1",vendor: " Wingtech Group (HongKong\uff09Limited"},
  {max_prefix: "00-6C-FD",vendor: "Sichuan Changhong Electric Ltd."},
  {max_prefix: "90-72-82",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "38-B8-EB",vendor: "IEEE Registration Authority"},
  {max_prefix: "98-97-D1",vendor: "MitraStar Technology Corp."},
  {max_prefix: "B8-32-41",vendor: "Wuhan Tianyu Information Industry Co., Ltd."},
  {max_prefix: "00-60-DC",vendor: "NEC Magnus Communications,Ltd."},
  {max_prefix: "00-23-B4",vendor: "Nokia Danmark A/S"},
  {max_prefix: "00-25-48",vendor: "Nokia Danmark A/S"},
  {max_prefix: "00-22-FC",vendor: "Nokia Danmark A/S"},
  {max_prefix: "00-22-FD",vendor: "Nokia Danmark A/S"},
  {max_prefix: "00-21-AA",vendor: "Nokia Danmark A/S"},
  {max_prefix: "00-1D-6E",vendor: "Nokia Danmark A/S"},
  {max_prefix: "00-13-70",vendor: "Nokia Danmark A/S"},
  {max_prefix: "9C-18-74",vendor: "Nokia Danmark A/S"},
  {max_prefix: "00-1B-AF",vendor: "Nokia Danmark A/S"},
  {max_prefix: "00-1C-35",vendor: "Nokia Danmark A/S"},
  {max_prefix: "00-1C-9A",vendor: "Nokia Danmark A/S"},
  {max_prefix: "00-1C-D6",vendor: "Nokia Danmark A/S"},
  {max_prefix: "00-1C-D4",vendor: "Nokia Danmark A/S"},
  {max_prefix: "00-1D-98",vendor: "Nokia Danmark A/S"},
  {max_prefix: "00-1D-E9",vendor: "Nokia Danmark A/S"},
  {max_prefix: "00-1E-3A",vendor: "Nokia Danmark A/S"},
  {max_prefix: "C8-D1-0B",vendor: "Nokia Corporation"},
  {max_prefix: "A8-44-81",vendor: "Nokia Corporation"},
  {max_prefix: "88-44-F6",vendor: "Nokia Corporation"},
  {max_prefix: "F4-4D-17",vendor: "GOLDCARD HIGH-TECH CO.,LTD."},
  {max_prefix: "00-30-FF",vendor: "DataFab Systems Inc."},
  {max_prefix: "00-0F-F6",vendor: "DARFON LIGHTING CORP"},
  {max_prefix: "CC-6D-A0",vendor: "Roku, Inc."},
  {max_prefix: "00-16-E4",vendor: "VANGUARD SECURITY ENGINEERING CORP."},
  {max_prefix: "3C-89-70",vendor: "Neosfar"},
  {max_prefix: "78-CA-83",vendor: "IEEE Registration Authority"},
  {max_prefix: "0C-11-67",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-17-42",vendor: "FUJITSU LIMITED"},
  {max_prefix: "00-19-99",vendor: "Fujitsu Technology Solutions GmbH"},
  {max_prefix: "00-5A-39",vendor: "SHENZHEN FAST TECHNOLOGIES CO.,LTD"},
  {max_prefix: "A0-89-E4",vendor: "Skyworth Digital Technology(Shenzhen) Co.,Ltd"},
  {max_prefix: "5C-C6-D0",vendor: "Skyworth Digital Technology(Shenzhen) Co.,Ltd"},
  {max_prefix: "00-1A-9A",vendor: "Skyworth Digital Technology(Shenzhen) Co.,Ltd"},
  {max_prefix: "50-F5-20",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "64-B3-10",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "A4-EB-D3",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "B8-C6-8E",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "04-FE-31",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "4C-BC-A5",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "F8-8E-85",vendor: "Comtrend Corporation"},
  {max_prefix: "02-CF-1C",vendor: "Communication Machinery Corporation"},
  {max_prefix: "00-90-F5",vendor: "CLEVO CO."},
  {max_prefix: "00-21-00",vendor: "Gemtek Technology Co., Ltd."},
  {max_prefix: "D8-31-CF",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "B0-D0-9C",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "50-C8-E5",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "18-83-31",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "9C-65-B0",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "84-55-A5",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "A8-7C-01",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "E0-1D-3B",vendor: "Cambridge Industries(Group) Co.,Ltd."},
  {max_prefix: "C8-10-73",vendor: "CENTURY OPTICOMM CO.,LTD"},
  {max_prefix: "34-37-59",vendor: "zte corporation"},
  {max_prefix: "FC-2F-40",vendor: "Calxeda, Inc."},
  {max_prefix: "BC-62-0E",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "74-A5-28",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "00-1D-19",vendor: "Arcadyan Technology Corporation"},
  {max_prefix: "00-12-BF",vendor: "Arcadyan Technology Corporation"},
  {max_prefix: "50-7E-5D",vendor: "Arcadyan Technology Corporation"},
  {max_prefix: "7C-4F-B5",vendor: "Arcadyan Technology Corporation"},
  {max_prefix: "00-20-D4",vendor: "Cabletron Systems, Inc."},
  {max_prefix: "00-E0-3A",vendor: "Cabletron Systems, Inc."},
  {max_prefix: "70-C7-6F",vendor: "INNO S"},
  {max_prefix: "38-19-2F",vendor: "Nokia Corporation"},
  {max_prefix: "00-10-E7",vendor: "Breezecom, Ltd."},
  {max_prefix: "5C-F6-DC",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "00-26-E4",vendor: "Canal +"},
  {max_prefix: "00-01-17",vendor: "Canal +"},
  {max_prefix: "F4-0B-93",vendor: "BlackBerry RTS"},
  {max_prefix: "1C-69-A5",vendor: "BlackBerry RTS"},
  {max_prefix: "94-EB-CD",vendor: "BlackBerry RTS"},
  {max_prefix: "00-26-FF",vendor: "BlackBerry RTS"},
  {max_prefix: "A4-E4-B8",vendor: "BlackBerry RTS"},
  {max_prefix: "80-37-73",vendor: "NETGEAR"},
  {max_prefix: "A4-2B-8C",vendor: "NETGEAR"},
  {max_prefix: "28-C6-8E",vendor: "NETGEAR"},
  {max_prefix: "04-A1-51",vendor: "NETGEAR"},
  {max_prefix: "F8-73-94",vendor: "NETGEAR"},
  {max_prefix: "20-4E-7F",vendor: "NETGEAR"},
  {max_prefix: "C0-3F-0E",vendor: "NETGEAR"},
  {max_prefix: "00-26-F2",vendor: "NETGEAR"},
  {max_prefix: "00-30-67",vendor: "BIOSTAR Microtech Int'l Corp."},
  {max_prefix: "94-92-BC",vendor: "SYNTECH(HK) TECHNOLOGY LIMITED"},
  {max_prefix: "00-22-3F",vendor: "NETGEAR"},
  {max_prefix: "00-0F-B5",vendor: "NETGEAR"},
  {max_prefix: "00-09-5B",vendor: "NETGEAR"},
  {max_prefix: "00-1A-4F",vendor: "AVM GmbH"},
  {max_prefix: "00-1C-4A",vendor: "AVM GmbH"},
  {max_prefix: "00-15-0C",vendor: "AVM GmbH"},
  {max_prefix: "00-13-8F",vendor: "Asiarock Technology Limited"},
  {max_prefix: "34-C3-D2",vendor: "FN-LINK TECHNOLOGY LIMITED"},
  {max_prefix: "54-F6-C5",vendor: "FUJIAN STAR-NET COMMUNICATION CO.,LTD"},
  {max_prefix: "40-95-58",vendor: "Aisino Corporation"},
  {max_prefix: "18-28-61",vendor: "AirTies Wireless Networks"},
  {max_prefix: "44-6D-57",vendor: "Liteon Technology Corporation"},
  {max_prefix: "9C-B7-0D",vendor: "Liteon Technology Corporation"},
  {max_prefix: "68-A3-C4",vendor: "Liteon Technology Corporation"},
  {max_prefix: "70-F1-A1",vendor: "Liteon Technology Corporation"},
  {max_prefix: "EC-08-6B",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "00-D9-D1",vendor: "Sony Interactive Entertainment Inc."},
  {max_prefix: "B0-05-94",vendor: "Liteon Technology Corporation"},
  {max_prefix: "28-E3-47",vendor: "Liteon Technology Corporation"},
  {max_prefix: "94-A1-A2",vendor: "AMPAK Technology, Inc."},
  {max_prefix: "00-01-4A",vendor: "Sony Corporation"},
  {max_prefix: "00-1E-DC",vendor: "Sony Corporation"},
  {max_prefix: "00-1D-28",vendor: "Sony Corporation"},
  {max_prefix: "84-00-D2",vendor: "Sony Corporation"},
  {max_prefix: "30-39-26",vendor: "Sony Corporation"},
  {max_prefix: "00-EB-2D",vendor: "Sony Corporation"},
  {max_prefix: "B4-52-7D",vendor: "Sony Corporation"},
  {max_prefix: "5C-33-8E",vendor: "Alpha Networks Inc."},
  {max_prefix: "98-3B-16",vendor: "AMPAK Technology, Inc."},
  {max_prefix: "00-1C-A2",vendor: "ADB Broadband Italia"},
  {max_prefix: "00-22-33",vendor: "ADB Broadband Italia"},
  {max_prefix: "30-39-F2",vendor: "ADB Broadband Italia"},
  {max_prefix: "00-17-C2",vendor: "ADB Broadband Italia"},
  {max_prefix: "00-18-13",vendor: "Sony Corporation"},
  {max_prefix: "40-2B-A1",vendor: "Sony Corporation"},
  {max_prefix: "9C-0E-4A",vendor: "Shenzhen Vastking Electronic Co.,Ltd."},
  {max_prefix: "A8-58-40",vendor: "Cambridge Industries(Group) Co.,Ltd."},
  {max_prefix: "A0-D3-7A",vendor: "Intel Corporate"},
  {max_prefix: "88-96-F2",vendor: "Valeo Schalter und Sensoren GmbH"},
  {max_prefix: "44-D8-32",vendor: "AzureWave Technology Inc."},
  {max_prefix: "E0-B9-A5",vendor: "AzureWave Technology Inc."},
  {max_prefix: "78-18-81",vendor: "AzureWave Technology Inc."},
  {max_prefix: "6C-71-D9",vendor: "AzureWave Technology Inc."},
  {max_prefix: "D0-E7-82",vendor: "AzureWave Technology Inc."},
  {max_prefix: "6C-AD-F8",vendor: "AzureWave Technology Inc."},
  {max_prefix: "A8-1D-16",vendor: "AzureWave Technology Inc."},
  {max_prefix: "B0-46-FC",vendor: "MitraStar Technology Corp."},
  {max_prefix: "E0-41-36",vendor: "MitraStar Technology Corp."},
  {max_prefix: "00-15-AF",vendor: "AzureWave Technology Inc."},
  {max_prefix: "74-F0-6D",vendor: "AzureWave Technology Inc."},
  {max_prefix: "D0-D4-12",vendor: "ADB Broadband Italia"},
  {max_prefix: "00-26-B8",vendor: "Actiontec Electronics, Inc"},
  {max_prefix: "00-26-FC",vendor: "AcSiP Technology Corp."},
  {max_prefix: "68-9C-5E",vendor: "AcSiP Technology Corp."},
  {max_prefix: "00-10-73",vendor: "TECHNOBOX, INC."},
  {max_prefix: "20-93-4D",vendor: "FUJIAN STAR-NET COMMUNICATION CO.,LTD"},
  {max_prefix: "00-90-27",vendor: "Intel Corporation"},
  {max_prefix: "00-A0-C9",vendor: "Intel Corporation"},
  {max_prefix: "B8-03-05",vendor: "Intel Corporate"},
  {max_prefix: "34-07-FB",vendor: "Ericsson AB"},
  {max_prefix: "A4-A1-C2",vendor: "Ericsson AB"},
  {max_prefix: "E0-97-96",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "00-0D-72",vendor: "2Wire Inc"},
  {max_prefix: "00-12-88",vendor: "2Wire Inc"},
  {max_prefix: "00-21-7C",vendor: "2Wire Inc"},
  {max_prefix: "00-1F-B3",vendor: "2Wire Inc"},
  {max_prefix: "28-16-2E",vendor: "2Wire Inc"},
  {max_prefix: "F8-18-97",vendor: "2Wire Inc"},
  {max_prefix: "94-C1-50",vendor: "2Wire Inc"},
  {max_prefix: "5C-F8-21",vendor: "Texas Instruments"},
  {max_prefix: "14-1F-BA",vendor: "IEEE Registration Authority"},
  {max_prefix: "80-7B-85",vendor: "IEEE Registration Authority"},
  {max_prefix: "CC-1B-E0",vendor: "IEEE Registration Authority"},
  {max_prefix: "F4-0E-11",vendor: "IEEE Registration Authority"},
  {max_prefix: "C4-85-08",vendor: "Intel Corporate"},
  {max_prefix: "68-05-CA",vendor: "Intel Corporate"},
  {max_prefix: "8C-A9-82",vendor: "Intel Corporate"},
  {max_prefix: "BC-77-37",vendor: "Intel Corporate"},
  {max_prefix: "14-30-C6",vendor: "Motorola Mobility LLC, a Lenovo Company"},
  {max_prefix: "14-1A-A3",vendor: "Motorola Mobility LLC, a Lenovo Company"},
  {max_prefix: "24-77-03",vendor: "Intel Corporate"},
  {max_prefix: "74-E5-0B",vendor: "Intel Corporate"},
  {max_prefix: "D8-FC-93",vendor: "Intel Corporate"},
  {max_prefix: "00-57-D2",vendor: "Cisco Systems, Inc"},
  {max_prefix: "3C-67-16",vendor: "Lily Robotics"},
  {max_prefix: "2C-22-8B",vendor: "CTR SRL"},
  {max_prefix: "0C-6F-9C",vendor: "Shaw Communications Inc."},
  {max_prefix: "00-D0-BD",vendor: "Lattice Semiconductor Corp. (LPA)"},
  {max_prefix: "00-1F-3A",vendor: "Hon Hai Precision Ind. Co.,Ltd."},
  {max_prefix: "64-7B-D4",vendor: "Texas Instruments"},
  {max_prefix: "D8-95-2F",vendor: "Texas Instruments"},
  {max_prefix: "B8-FF-FE",vendor: "Texas Instruments"},
  {max_prefix: "88-07-4B",vendor: "LG Electronics (Mobile Communications)"},
  {max_prefix: "F4-EB-38",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "00-1B-BF",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "00-25-69",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "C8-A0-30",vendor: "Texas Instruments"},
  {max_prefix: "78-C5-E5",vendor: "Texas Instruments"},
  {max_prefix: "0C-FD-37",vendor: "SUSE Linux GmbH"},
  {max_prefix: "00-78-9E",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "E8-BE-81",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "68-15-90",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "10-F6-81",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "2C-E4-12",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "04-E4-51",vendor: "Texas Instruments"},
  {max_prefix: "50-56-63",vendor: "Texas Instruments"},
  {max_prefix: "88-33-14",vendor: "Texas Instruments"},
  {max_prefix: "44-C1-5C",vendor: "Texas Instruments"},
  {max_prefix: "00-22-A5",vendor: "Texas Instruments"},
  {max_prefix: "00-17-E4",vendor: "Texas Instruments"},
  {max_prefix: "00-22-75",vendor: "Belkin International Inc."},
  {max_prefix: "78-E3-B5",vendor: "Hewlett Packard"},
  {max_prefix: "78-AC-C0",vendor: "Hewlett Packard"},
  {max_prefix: "68-B5-99",vendor: "Hewlett Packard"},
  {max_prefix: "1C-C1-DE",vendor: "Hewlett Packard"},
  {max_prefix: "3C-35-56",vendor: "Cognitec Systems GmbH"},
  {max_prefix: "3C-90-66",vendor: "SmartRG, Inc."},
  {max_prefix: "00-0D-88",vendor: "D-Link Corporation"},
  {max_prefix: "00-11-95",vendor: "D-Link Corporation"},
  {max_prefix: "B8-A3-86",vendor: "D-Link International"},
  {max_prefix: "1C-7E-E5",vendor: "D-Link International"},
  {max_prefix: "3C-CF-5B",vendor: "ICOMM HK LIMITED"},
  {max_prefix: "24-05-F5",vendor: "Integrated Device Technology (Malaysia) Sdn. Bhd."},
  {max_prefix: "00-73-8D",vendor: "Shenzhen TINNO Mobile Technology Corp."},
  {max_prefix: "E0-43-DB",vendor: "Shenzhen ViewAt Technology Co.,Ltd."},
  {max_prefix: "F4-03-04",vendor: "Google, Inc."},
  {max_prefix: "54-60-09",vendor: "Google, Inc."},
  {max_prefix: "A4-77-33",vendor: "Google, Inc."},
  {max_prefix: "00-E0-FC",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "00-13-46",vendor: "D-Link Corporation"},
  {max_prefix: "1C-BD-B9",vendor: "D-Link International"},
  {max_prefix: "9C-DF-B1",vendor: "Shenzhen Crave Communication Co., LTD"},
  {max_prefix: "60-69-44",vendor: "Apple, Inc."},
  {max_prefix: "78-F8-82",vendor: "LG Electronics (Mobile Communications)"},
  {max_prefix: "C0-2C-7A",vendor: "Shenzhen Horn Audio Co.,Ltd."},
  {max_prefix: "1C-CB-99",vendor: "TCT mobile ltd"},
  {max_prefix: "A4-2B-B0",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "80-7A-BF",vendor: "HTC Corporation"},
  {max_prefix: "34-BA-75",vendor: "Everest Networks, Inc"},
  {max_prefix: "7C-18-CD",vendor: "E-TRON Co.,Ltd."},
  {max_prefix: "94-AB-DE",vendor: "OMX Technology - FZE"},
  {max_prefix: "64-16-F0",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "AC-CF-85",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "18-8B-45",vendor: "Cisco Systems, Inc"},
  {max_prefix: "F4-CA-24",vendor: "FreeBit Co., Ltd."},
  {max_prefix: "00-14-2F",vendor: "Savvius"},
  {max_prefix: "28-BC-18",vendor: "SourcingOverseas Co. Ltd"},
  {max_prefix: "00-D0-B7",vendor: "Intel Corporation"},
  {max_prefix: "38-71-DE",vendor: "Apple, Inc."},
  {max_prefix: "70-81-EB",vendor: "Apple, Inc."},
  {max_prefix: "00-0E-35",vendor: "Intel Corporation"},
  {max_prefix: "00-07-E9",vendor: "Intel Corporation"},
  {max_prefix: "00-13-E8",vendor: "Intel Corporate"},
  {max_prefix: "00-13-CE",vendor: "Intel Corporate"},
  {max_prefix: "B8-B8-1E",vendor: "Intel Corporate"},
  {max_prefix: "B4-6D-83",vendor: "Intel Corporate"},
  {max_prefix: "2C-76-8A",vendor: "Hewlett Packard"},
  {max_prefix: "C8-34-8E",vendor: "Intel Corporate"},
  {max_prefix: "4C-34-88",vendor: "Intel Corporate"},
  {max_prefix: "10-02-B5",vendor: "Intel Corporate"},
  {max_prefix: "00-17-08",vendor: "Hewlett Packard"},
  {max_prefix: "00-17-A4",vendor: "Hewlett Packard"},
  {max_prefix: "BC-EA-FA",vendor: "Hewlett Packard"},
  {max_prefix: "00-40-26",vendor: "BUFFALO.INC"},
  {max_prefix: "4C-E6-76",vendor: "BUFFALO.INC"},
  {max_prefix: "00-0B-CD",vendor: "Hewlett Packard"},
  {max_prefix: "00-0F-20",vendor: "Hewlett Packard"},
  {max_prefix: "00-11-0A",vendor: "Hewlett Packard"},
  {max_prefix: "1C-78-39",vendor: "Shenzhen Tencent Computer System Co., Ltd."},
  {max_prefix: "04-53-D5",vendor: "Sysorex Global Holdings"},
  {max_prefix: "EC-52-DC",vendor: "WORLD MEDIA AND TECHNOLOGY Corp."},
  {max_prefix: "94-B2-CC",vendor: "PIONEER CORPORATION"},
  {max_prefix: "08-EB-74",vendor: "HUMAX Co., Ltd."},
  {max_prefix: "28-32-C5",vendor: "HUMAX Co., Ltd."},
  {max_prefix: "88-C2-55",vendor: "Texas Instruments"},
  {max_prefix: "CC-78-AB",vendor: "Texas Instruments"},
  {max_prefix: "58-20-B1",vendor: "Hewlett Packard"},
  {max_prefix: "94-57-A5",vendor: "Hewlett Packard"},
  {max_prefix: "00-0E-B3",vendor: "Hewlett Packard"},
  {max_prefix: "08-00-09",vendor: "Hewlett Packard"},
  {max_prefix: "00-30-C1",vendor: "Hewlett Packard"},
  {max_prefix: "00-80-A0",vendor: "Hewlett Packard"},
  {max_prefix: "D4-85-64",vendor: "Hewlett Packard"},
  {max_prefix: "24-BE-05",vendor: "Hewlett Packard"},
  {max_prefix: "FC-3F-DB",vendor: "Hewlett Packard"},
  {max_prefix: "00-09-2D",vendor: "HTC Corporation"},
  {max_prefix: "00-26-5C",vendor: "Hon Hai Precision Ind. Co.,Ltd."},
  {max_prefix: "00-22-69",vendor: "Hon Hai Precision Ind. Co.,Ltd."},
  {max_prefix: "D8-79-88",vendor: "Hon Hai Precision Ind. Co.,Ltd."},
  {max_prefix: "74-A7-8E",vendor: "zte corporation"},
  {max_prefix: "7C-61-93",vendor: "HTC Corporation"},
  {max_prefix: "90-E7-C4",vendor: "HTC Corporation"},
  {max_prefix: "90-CD-B6",vendor: "Hon Hai Precision Ind. Co.,Ltd."},
  {max_prefix: "40-49-0F",vendor: "Hon Hai Precision Ind. Co.,Ltd."},
  {max_prefix: "AC-16-2D",vendor: "Hewlett Packard"},
  {max_prefix: "80-C1-6E",vendor: "Hewlett Packard"},
  {max_prefix: "B4-B5-2F",vendor: "Hewlett Packard"},
  {max_prefix: "D0-7E-28",vendor: "Hewlett Packard"},
  {max_prefix: "D0-BF-9C",vendor: "Hewlett Packard"},
  {max_prefix: "30-8D-99",vendor: "Hewlett Packard"},
  {max_prefix: "74-46-A0",vendor: "Hewlett Packard"},
  {max_prefix: "2C-44-FD",vendor: "Hewlett Packard"},
  {max_prefix: "44-31-92",vendor: "Hewlett Packard"},
  {max_prefix: "A0-D3-C1",vendor: "Hewlett Packard"},
  {max_prefix: "38-EA-A7",vendor: "Hewlett Packard"},
  {max_prefix: "04-52-F3",vendor: "Apple, Inc."},
  {max_prefix: "00-21-27",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "EC-88-8F",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "64-66-B3",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "F0-F3-36",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "BC-46-99",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "F4-83-CD",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "FC-D7-33",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "5C-89-9A",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "A8-1B-5A",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "2C-5B-B8",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "90-21-06",vendor: "SKY UK LIMITED"},
  {max_prefix: "88-94-71",vendor: "Brocade Communications Systems LLC"},
  {max_prefix: "8C-7C-FF",vendor: "Brocade Communications Systems LLC"},
  {max_prefix: "5C-63-BF",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "E0-05-C5",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "38-83-45",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "98-F5-37",vendor: "zte corporation"},
  {max_prefix: "00-1F-E2",vendor: "Hon Hai Precision Ind. Co.,Ltd."},
  {max_prefix: "00-16-CF",vendor: "Hon Hai Precision Ind. Co.,Ltd."},
  {max_prefix: "20-02-AF",vendor: "Murata Manufacturing Co., Ltd."},
  {max_prefix: "00-21-E8",vendor: "Murata Manufacturing Co., Ltd."},
  {max_prefix: "00-0E-6D",vendor: "Murata Manufacturing Co., Ltd."},
  {max_prefix: "D0-27-88",vendor: "Hon Hai Precision Ind. Co.,Ltd."},
  {max_prefix: "90-4C-E5",vendor: "Hon Hai Precision Ind. Co.,Ltd."},
  {max_prefix: "14-2D-27",vendor: "Hon Hai Precision Ind. Co.,Ltd."},
  {max_prefix: "5C-4C-A9",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "F4-C7-14",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "28-6E-D4",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "00-1E-10",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "D4-78-56",vendor: "Avaya Inc"},
  {max_prefix: "A0-12-90",vendor: "Avaya Inc"},
  {max_prefix: "D8-42-AC",vendor: "Shanghai Feixun Communication Co.,Ltd."},
  {max_prefix: "54-39-DF",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "74-88-2A",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "88-E3-AB",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "C4-05-28",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "3C-DF-BD",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "50-9F-27",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "8C-34-FD",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "58-7F-66",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "64-A6-51",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "CC-F9-54",vendor: "Avaya Inc"},
  {max_prefix: "2C-F4-C5",vendor: "Avaya Inc"},
  {max_prefix: "3C-3A-73",vendor: "Avaya Inc"},
  {max_prefix: "FC-83-99",vendor: "Avaya Inc"},
  {max_prefix: "28-3C-E4",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "08-63-61",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "38-BB-3C",vendor: "Avaya Inc"},
  {max_prefix: "F8-73-A2",vendor: "Avaya Inc"},
  {max_prefix: "80-71-7A",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "D4-97-0B",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "8C-BE-BE",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "14-F6-5A",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "00-9E-C8",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "0C-1D-AF",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "34-80-B3",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "F4-8B-32",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "F4-B8-5E",vendor: "Texas Instruments"},
  {max_prefix: "68-C9-0B",vendor: "Texas Instruments"},
  {max_prefix: "D4-F5-13",vendor: "Texas Instruments"},
  {max_prefix: "50-72-24",vendor: "Texas Instruments"},
  {max_prefix: "AC-F7-F3",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "08-19-A6",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "3C-F8-08",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "48-62-76",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "B4-15-13",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "AC-4E-91",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "28-31-52",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "00-90-21",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-90-B1",vendor: "Cisco Systems, Inc"},
  {max_prefix: "04-BD-70",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "00-1A-B6",vendor: "Texas Instruments"},
  {max_prefix: "00-12-D1",vendor: "Texas Instruments"},
  {max_prefix: "00-12-37",vendor: "Texas Instruments"},
  {max_prefix: "00-10-2F",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-10-0D",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-10-07",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-10-14",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-40-0B",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-90-BF",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-50-D1",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-90-D9",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-90-92",vendor: "Cisco Systems, Inc"},
  {max_prefix: "A0-E6-F8",vendor: "Texas Instruments"},
  {max_prefix: "70-FF-76",vendor: "Texas Instruments"},
  {max_prefix: "D0-39-72",vendor: "Texas Instruments"},
  {max_prefix: "5C-31-3E",vendor: "Texas Instruments"},
  {max_prefix: "00-60-70",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-E0-1E",vendor: "Cisco Systems, Inc"},
  {max_prefix: "1C-E6-C7",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-11-2F",vendor: "ASUSTek COMPUTER INC."},
  {max_prefix: "18-E7-28",vendor: "Cisco Systems, Inc"},
  {max_prefix: "D0-72-DC",vendor: "Cisco Systems, Inc"},
  {max_prefix: "28-C7-CE",vendor: "Cisco Systems, Inc"},
  {max_prefix: "F4-0F-1B",vendor: "Cisco Systems, Inc"},
  {max_prefix: "F8-C2-88",vendor: "Cisco Systems, Inc"},
  {max_prefix: "BC-AE-C5",vendor: "ASUSTek COMPUTER INC."},
  {max_prefix: "10-BF-48",vendor: "ASUSTek COMPUTER INC."},
  {max_prefix: "6C-99-89",vendor: "Cisco Systems, Inc"},
  {max_prefix: "1C-6A-7A",vendor: "Cisco Systems, Inc"},
  {max_prefix: "50-67-AE",vendor: "Cisco Systems, Inc"},
  {max_prefix: "F0-9E-63",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-1B-FC",vendor: "ASUSTek COMPUTER INC."},
  {max_prefix: "48-5B-39",vendor: "ASUSTek COMPUTER INC."},
  {max_prefix: "CC-D5-39",vendor: "Cisco Systems, Inc"},
  {max_prefix: "50-06-04",vendor: "Cisco Systems, Inc"},
  {max_prefix: "4C-00-82",vendor: "Cisco Systems, Inc"},
  {max_prefix: "7C-95-F3",vendor: "Cisco Systems, Inc"},
  {max_prefix: "34-DB-FD",vendor: "Cisco Systems, Inc"},
  {max_prefix: "88-5A-92",vendor: "Cisco Systems, Inc"},
  {max_prefix: "04-6C-9D",vendor: "Cisco Systems, Inc"},
  {max_prefix: "84-B2-61",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-10-1F",vendor: "Cisco Systems, Inc"},
  {max_prefix: "54-A2-74",vendor: "Cisco Systems, Inc"},
  {max_prefix: "A0-55-4F",vendor: "Cisco Systems, Inc"},
  {max_prefix: "20-4C-9E",vendor: "Cisco Systems, Inc"},
  {max_prefix: "84-B8-02",vendor: "Cisco Systems, Inc"},
  {max_prefix: "B0-AA-77",vendor: "Cisco Systems, Inc"},
  {max_prefix: "BC-C4-93",vendor: "Cisco Systems, Inc"},
  {max_prefix: "A4-6C-2A",vendor: "Cisco Systems, Inc"},
  {max_prefix: "D0-A5-A6",vendor: "Cisco Systems, Inc"},
  {max_prefix: "3C-5E-C3",vendor: "Cisco Systems, Inc"},
  {max_prefix: "64-F6-9D",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-03-89",vendor: "PLANTRONICS, INC."},
  {max_prefix: "48-44-87",vendor: "Cisco SPVTG"},
  {max_prefix: "38-C8-5C",vendor: "Cisco SPVTG"},
  {max_prefix: "E4-48-C7",vendor: "Cisco SPVTG"},
  {max_prefix: "00-12-17",vendor: "Cisco-Linksys, LLC"},
  {max_prefix: "00-13-10",vendor: "Cisco-Linksys, LLC"},
  {max_prefix: "00-1E-E5",vendor: "Cisco-Linksys, LLC"},
  {max_prefix: "00-1D-4F",vendor: "Apple, Inc."},
  {max_prefix: "00-23-12",vendor: "Apple, Inc."},
  {max_prefix: "80-E0-1D",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-0A-27",vendor: "Apple, Inc."},
  {max_prefix: "34-51-C9",vendor: "Apple, Inc."},
  {max_prefix: "40-6C-8F",vendor: "Apple, Inc."},
  {max_prefix: "D0-23-DB",vendor: "Apple, Inc."},
  {max_prefix: "70-DE-E2",vendor: "Apple, Inc."},
  {max_prefix: "F0-CB-A1",vendor: "Apple, Inc."},
  {max_prefix: "18-20-32",vendor: "Apple, Inc."},
  {max_prefix: "60-FA-CD",vendor: "Apple, Inc."},
  {max_prefix: "00-3E-E1",vendor: "Apple, Inc."},
  {max_prefix: "FC-25-3F",vendor: "Apple, Inc."},
  {max_prefix: "18-34-51",vendor: "Apple, Inc."},
  {max_prefix: "0C-77-1A",vendor: "Apple, Inc."},
  {max_prefix: "D8-A2-5E",vendor: "Apple, Inc."},
  {max_prefix: "58-55-CA",vendor: "Apple, Inc."},
  {max_prefix: "DC-2B-61",vendor: "Apple, Inc."},
  {max_prefix: "40-A6-D9",vendor: "Apple, Inc."},
  {max_prefix: "28-6A-BA",vendor: "Apple, Inc."},
  {max_prefix: "4C-B1-99",vendor: "Apple, Inc."},
  {max_prefix: "C0-9F-42",vendor: "Apple, Inc."},
  {max_prefix: "70-56-81",vendor: "Apple, Inc."},
  {max_prefix: "04-F7-E4",vendor: "Apple, Inc."},
  {max_prefix: "34-C0-59",vendor: "Apple, Inc."},
  {max_prefix: "F0-D1-A9",vendor: "Apple, Inc."},
  {max_prefix: "04-0C-CE",vendor: "Apple, Inc."},
  {max_prefix: "40-3C-FC",vendor: "Apple, Inc."},
  {max_prefix: "48-60-BC",vendor: "Apple, Inc."},
  {max_prefix: "AC-3C-0B",vendor: "Apple, Inc."},
  {max_prefix: "70-11-24",vendor: "Apple, Inc."},
  {max_prefix: "60-FB-42",vendor: "Apple, Inc."},
  {max_prefix: "64-B9-E8",vendor: "Apple, Inc."},
  {max_prefix: "14-10-9F",vendor: "Apple, Inc."},
  {max_prefix: "04-26-65",vendor: "Apple, Inc."},
  {max_prefix: "EC-35-86",vendor: "Apple, Inc."},
  {max_prefix: "54-EA-A8",vendor: "Apple, Inc."},
  {max_prefix: "28-E1-4C",vendor: "Apple, Inc."},
  {max_prefix: "E4-C6-3D",vendor: "Apple, Inc."},
  {max_prefix: "54-E4-3A",vendor: "Apple, Inc."},
  {max_prefix: "04-DB-56",vendor: "Apple, Inc."},
  {max_prefix: "DC-9B-9C",vendor: "Apple, Inc."},
  {max_prefix: "54-72-4F",vendor: "Apple, Inc."},
  {max_prefix: "8C-7C-92",vendor: "Apple, Inc."},
  {max_prefix: "B0-34-95",vendor: "Apple, Inc."},
  {max_prefix: "F4-37-B7",vendor: "Apple, Inc."},
  {max_prefix: "78-FD-94",vendor: "Apple, Inc."},
  {max_prefix: "2C-BE-08",vendor: "Apple, Inc."},
  {max_prefix: "E8-80-2E",vendor: "Apple, Inc."},
  {max_prefix: "00-61-71",vendor: "Apple, Inc."},
  {max_prefix: "04-E5-36",vendor: "Apple, Inc."},
  {max_prefix: "A8-BB-CF",vendor: "Apple, Inc."},
  {max_prefix: "AC-7F-3E",vendor: "Apple, Inc."},
  {max_prefix: "20-A2-E4",vendor: "Apple, Inc."},
  {max_prefix: "BC-4C-C4",vendor: "Apple, Inc."},
  {max_prefix: "28-0B-5C",vendor: "Apple, Inc."},
  {max_prefix: "AC-FD-EC",vendor: "Apple, Inc."},
  {max_prefix: "D8-CF-9C",vendor: "Apple, Inc."},
  {max_prefix: "DC-37-14",vendor: "Apple, Inc."},
  {max_prefix: "6C-40-08",vendor: "Apple, Inc."},
  {max_prefix: "28-F0-76",vendor: "Apple, Inc."},
  {max_prefix: "FC-A3-86",vendor: "SHENZHEN CHUANGWEI-RGB ELECTRONICS CO.,LTD"},
  {max_prefix: "40-33-1A",vendor: "Apple, Inc."},
  {max_prefix: "CC-C7-60",vendor: "Apple, Inc."},
  {max_prefix: "14-13-57",vendor: "ATP Electronics, Inc."},
  {max_prefix: "FC-CF-43",vendor: "HUIZHOU CITY HUIYANG DISTRICT MEISIQI INDUSTRY DEVELOPMENT CO,.LTD"},
  {max_prefix: "B4-EF-04",vendor: "DAIHAN Scientific Co., Ltd."},
  {max_prefix: "A4-DE-C9",vendor: "QLove Mobile Intelligence Information Technology (W.H.) Co. Ltd."},
  {max_prefix: "CC-E0-C3",vendor: "EXTEN Technologies, Inc."},
  {max_prefix: "D8-48-EE",vendor: "Hangzhou Xueji Technology Co., Ltd."},
  {max_prefix: "4C-8E-CC",vendor: "SILKAN SA"},
  {max_prefix: "B8-B2-EB",vendor: "Googol Technology (HK) Limited"},
  {max_prefix: "64-6A-74",vendor: "AUTH-SERVERS, LLC"},
  {max_prefix: "7C-71-76",vendor: "Wuxi iData Technology Company Ltd."},
  {max_prefix: "7C-01-91",vendor: "Apple, Inc."},
  {max_prefix: "0C-54-B9",vendor: "Nokia"},
  {max_prefix: "98-E8-48",vendor: "Axiim"},
  {max_prefix: "2C-1B-C8",vendor: "Hunan Topview Network System CO.,LTD"},
  {max_prefix: "A8-47-4A",vendor: "Hon Hai Precision Ind. Co.,Ltd."},
  {max_prefix: "C4-00-49",vendor: "Kamama"},
  {max_prefix: "AC-64-62",vendor: "zte corporation"},
  {max_prefix: "A0-1E-0B",vendor: "MINIX Technology Limited"},
  {max_prefix: "68-E8-EB",vendor: "Linktel Technologies Co.,Ltd"},
  {max_prefix: "40-40-A7",vendor: "Sony Corporation"},
  {max_prefix: "C0-4A-09",vendor: "Zhejiang Everbright Communication Equip. Co,. Ltd"},
  {max_prefix: "84-10-0D",vendor: "Motorola Mobility LLC, a Lenovo Company"},
  {max_prefix: "D0-0F-6D",vendor: "T&W Electronics Company"},
  {max_prefix: "48-13-7E",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "C0-25-A2",vendor: "NEC Platforms, Ltd."},
  {max_prefix: "90-8D-78",vendor: "D-Link International"},
  {max_prefix: "E4-35-C8",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "80-D6-05",vendor: "Apple, Inc."},
  {max_prefix: "08-9B-4B",vendor: "iKuai Networks"},
  {max_prefix: "A8-45-CD",vendor: "Siselectron Technology LTD."},
  {max_prefix: "D0-C1-93",vendor: "SKYBELL, INC"},
  {max_prefix: "D4-83-04",vendor: "SHENZHEN FAST TECHNOLOGIES CO.,LTD"},
  {max_prefix: "E8-07-34",vendor: "Champion Optical Network Engineering, LLC"},
  {max_prefix: "30-F7-72",vendor: "Hon Hai Precision Ind. Co.,Ltd."},
  {max_prefix: "DC-3C-F6",vendor: "Atomic Rules LLC"},
  {max_prefix: "44-73-D6",vendor: "Logitech"},
  {max_prefix: "10-CC-1B",vendor: "Liverock technologies,INC"},
  {max_prefix: "A4-38-31",vendor: "RF elements s.r.o."},
  {max_prefix: "38-05-46",vendor: "Foctek Photonics, Inc."},
  {max_prefix: "DC-2B-2A",vendor: "Apple, Inc."},
  {max_prefix: "9C-8D-D3",vendor: "Leonton Technologies"},
  {max_prefix: "E4-1A-2C",vendor: "ZPE Systems, Inc."},
  {max_prefix: "E8-BD-D1",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "F4-15-35",vendor: "SPON Communication Technology Co.,Ltd"},
  {max_prefix: "38-0A-AB",vendor: "Formlabs"},
  {max_prefix: "38-2D-E8",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "C0-89-97",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "A8-15-D6",vendor: "Shenzhen Meione Technology CO., LTD"},
  {max_prefix: "C0-7C-D1",vendor: "PEGATRON CORPORATION"},
  {max_prefix: "90-D8-F3",vendor: "zte corporation"},
  {max_prefix: "D4-45-E8",vendor: "Jiangxi Hongpai Technology Co., Ltd."},
  {max_prefix: "30-A2-43",vendor: "Shenzhen Prifox Innovation Technology Co., Ltd."},
  {max_prefix: "34-26-06",vendor: "CarePredict, Inc."},
  {max_prefix: "38-B7-25",vendor: "Wistron Infocomm (Zhongshan) Corporation"},
  {max_prefix: "6C-72-20",vendor: "D-Link International"},
  {max_prefix: "28-B9-D9",vendor: "Radisys Corporation"},
  {max_prefix: "AC-67-6F",vendor: "Electrocompaniet A.S."},
  {max_prefix: "E0-55-3D",vendor: "Cisco Meraki"},
  {max_prefix: "64-0D-E6",vendor: "Petra Systems"},
  {max_prefix: "F4-C6-13",vendor: "Alcatel-Lucent Shanghai Bell Co., Ltd"},
  {max_prefix: "44-5F-8C",vendor: "Intercel Group Limited"},
  {max_prefix: "FC-33-5F",vendor: "Polyera"},
  {max_prefix: "84-D4-C8",vendor: "Widex A/S"},
  {max_prefix: "EC-21-E5",vendor: "Toshiba"},
  {max_prefix: "38-01-95",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "44-97-5A",vendor: "SHENZHEN FAST TECHNOLOGIES CO.,LTD"},
  {max_prefix: "50-45-F7",vendor: "Liuhe Intelligence Technology Ltd."},
  {max_prefix: "B8-89-81",vendor: "Chengdu InnoThings Technology Co., Ltd."},
  {max_prefix: "94-9F-3E",vendor: "Sonos, Inc."},
  {max_prefix: "04-C2-3E",vendor: "HTC Corporation"},
  {max_prefix: "E0-1A-EA",vendor: "Allied Telesis, Inc."},
  {max_prefix: "30-89-D3",vendor: "HONGKONG UCLOUDLINK NETWORK TECHNOLOGY LIMITED"},
  {max_prefix: "5C-B3-95",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "90-6C-AC",vendor: "Fortinet, Inc."},
  {max_prefix: "DC-A3-AC",vendor: "RBcloudtech"},
  {max_prefix: "F0-26-24",vendor: "WAFA TECHNOLOGIES CO., LTD."},
  {max_prefix: "F8-F4-64",vendor: "Rawe Electonic GmbH"},
  {max_prefix: "D0-04-92",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "5C-51-88",vendor: "Motorola Mobility LLC, a Lenovo Company"},
  {max_prefix: "EC-01-33",vendor: "TRINUS SYSTEMS INC."},
  {max_prefix: "00-FC-8D",vendor: "Hitron Technologies. Inc"},
  {max_prefix: "A0-13-CB",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "58-F1-02",vendor: "BLU Products Inc."},
  {max_prefix: "2C-C5-48",vendor: "IAdea Corporation"},
  {max_prefix: "0C-E7-25",vendor: "Microsoft Corporation"},
  {max_prefix: "14-DD-A9",vendor: "ASUSTek COMPUTER INC."},
  {max_prefix: "18-4F-32",vendor: "Hon Hai Precision Ind. Co.,Ltd."},
  {max_prefix: "DC-15-DB",vendor: "Ge Ruili Intelligent Technology ( Beijing ) Co., Ltd."},
  {max_prefix: "84-DF-19",vendor: "Chuango Security Technology Corporation"},
  {max_prefix: "30-F3-35",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "B4-6D-35",vendor: "Dalian Seasky Automation Co;Ltd"},
  {max_prefix: "18-16-C9",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "84-2E-27",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "1C-AD-D1",vendor: "Bosung Electronics Co., Ltd."},
  {max_prefix: "08-2C-B0",vendor: "Network Instruments"},
  {max_prefix: "E0-DB-10",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "54-61-72",vendor: "ZODIAC AEROSPACE SAS"},
  {max_prefix: "EC-60-E0",vendor: "AVI-ON LABS"},
  {max_prefix: "3C-DA-2A",vendor: "zte corporation"},
  {max_prefix: "14-32-D1",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "3C-A3-1A",vendor: "Oilfind International LLC"},
  {max_prefix: "E8-F2-E3",vendor: "Starcor Beijing Co.,Limited"},
  {max_prefix: "34-3D-98",vendor: "JinQianMao Technology Co.,Ltd."},
  {max_prefix: "F4-47-13",vendor: "Leading Public Performance Co., Ltd."},
  {max_prefix: "60-19-70",vendor: "HUIZHOU QIAOXING ELECTRONICS TECHNOLOGY CO., LTD."},
  {max_prefix: "A4-08-EA",vendor: "Murata Manufacturing Co., Ltd."},
  {max_prefix: "A8-D4-09",vendor: "USA 111 Inc"},
  {max_prefix: "6C-45-98",vendor: "Antex Electronic Corp."},
  {max_prefix: "68-A3-78",vendor: "FREEBOX SAS"},
  {max_prefix: "34-0A-22",vendor: "TOP-ACCESS ELECTRONICS CO LTD"},
  {max_prefix: "E8-66-C4",vendor: "Diamanti"},
  {max_prefix: "D4-D7-A9",vendor: "Shanghai Kaixiang Info Tech LTD"},
  {max_prefix: "6C-44-18",vendor: "Zappware"},
  {max_prefix: "64-59-F8",vendor: "Vodafone Omnitel B.V."},
  {max_prefix: "D0-83-D4",vendor: "Xtel Wireless ApS"},
  {max_prefix: "F0-2A-23",vendor: "Creative Next Design"},
  {max_prefix: "58-47-04",vendor: " Shenzhen Webridge Technology Co.,Ltd"},
  {max_prefix: "5C-A1-78",vendor: "TableTop Media (dba Ziosk)"},
  {max_prefix: "9C-BE-E0",vendor: "Biosoundlab Co., Ltd."},
  {max_prefix: "0C-41-3E",vendor: "Microsoft Corporation"},
  {max_prefix: "80-74-59",vendor: "K's Co.,Ltd."},
  {max_prefix: "E4-69-5A",vendor: "Dictum Health, Inc."},
  {max_prefix: "7C-7A-53",vendor: "Phytrex Technology Corp."},
  {max_prefix: "10-78-73",vendor: "Shenzhen Jinkeyi Communication Co., Ltd."},
  {max_prefix: "48-EE-0C",vendor: "D-Link International"},
  {max_prefix: "70-AD-54",vendor: "Malvern Instruments Ltd"},
  {max_prefix: "90-00-DB",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "B4-EF-39",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "B8-78-79",vendor: "Roche Diagnostics GmbH"},
  {max_prefix: "D0-6F-4A",vendor: "TOPWELL INTERNATIONAL HOLDINGS LIMITED"},
  {max_prefix: "04-92-EE",vendor: "iway AG"},
  {max_prefix: "7C-53-4A",vendor: "Metamako"},
  {max_prefix: "BC-B3-08",vendor: "HONGKONG RAGENTEK COMMUNICATION TECHNOLOGY CO.,LIMITED"},
  {max_prefix: "BC-6E-64",vendor: "Sony Corporation"},
  {max_prefix: "6C-2E-72",vendor: "B&B EXPORTING LIMITED"},
  {max_prefix: "5C-CC-FF",vendor: "Techroutes Network Pvt Ltd"},
  {max_prefix: "FC-32-88",vendor: "CELOT Wireless Co., Ltd"},
  {max_prefix: "D8-74-95",vendor: "zte corporation"},
  {max_prefix: "EC-3C-88",vendor: "MCNEX Co.,Ltd."},
  {max_prefix: "08-D3-4B",vendor: "Techman Electronics (Changshu) Co., Ltd."},
  {max_prefix: "78-A3-51",vendor: "SHENZHEN ZHIBOTONG ELECTRONICS CO.,LTD"},
  {max_prefix: "90-C3-5F",vendor: "Nanjing Jiahao Technology Co., Ltd."},
  {max_prefix: "C8-08-E9",vendor: "LG Electronics"},
  {max_prefix: "E4-FE-D9",vendor: "EDMI Europe Ltd"},
  {max_prefix: "5C-F7-C3",vendor: "SYNTECH (HK) TECHNOLOGY LIMITED"},
  {max_prefix: "EC-E2-FD",vendor: "SKG Electric Group(Thailand) Co., Ltd."},
  {max_prefix: "18-3A-2D",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "EC-74-BA",vendor: "Hirschmann Automation and Control GmbH"},
  {max_prefix: "74-A0-63",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "5C-3B-35",vendor: "Gehirn Inc."},
  {max_prefix: "54-51-46",vendor: "AMG Systems Ltd."},
  {max_prefix: "84-63-D6",vendor: "Microsoft Corporation"},
  {max_prefix: "BC-4D-FB",vendor: "Hitron Technologies. Inc"},
  {max_prefix: "2C-33-7A",vendor: "Hon Hai Precision Ind. Co.,Ltd."},
  {max_prefix: "70-76-FF",vendor: "KERLINK"},
  {max_prefix: "14-36-C6",vendor: "Lenovo Mobile Communication Technology Ltd."},
  {max_prefix: "BC-E7-67",vendor: "Quanzhou  TDX Electronics Co., Ltd"},
  {max_prefix: "34-4C-A4",vendor: "amazipoint technology Ltd."},
  {max_prefix: "14-8F-21",vendor: "Garmin International"},
  {max_prefix: "9C-68-5B",vendor: "Octonion SA"},
  {max_prefix: "9C-E2-30",vendor: "JULONG CO,.LTD."},
  {max_prefix: "5C-41-E7",vendor: "Wiatec International Ltd."},
  {max_prefix: "A8-F0-38",vendor: "SHEN ZHEN SHI JIN HUA TAI ELECTRONICS CO.,LTD"},
  {max_prefix: "AC-C7-3F",vendor: "VITSMO CO., LTD."},
  {max_prefix: "44-35-6F",vendor: "Neterix Ltd"},
  {max_prefix: "BC-54-F9",vendor: "Drogoo Technology Co., Ltd."},
  {max_prefix: "78-FC-14",vendor: "Family Zone Cyber Safety Ltd"},
  {max_prefix: "38-09-A4",vendor: "Firefly Integrations"},
  {max_prefix: "74-E2-77",vendor: "Vizmonet Pte Ltd"},
  {max_prefix: "14-89-3E",vendor: "VIXTEL TECHNOLOGIES LIMTED"},
  {max_prefix: "FC-AF-AC",vendor: "Socionext Inc."},
  {max_prefix: "38-2C-4A",vendor: "ASUSTek COMPUTER INC."},
  {max_prefix: "08-EF-AB",vendor: "SAYME WIRELESS SENSOR NETWORK"},
  {max_prefix: "3C-1E-13",vendor: "HANGZHOU SUNRISE TECHNOLOGY CO., LTD"},
  {max_prefix: "04-9B-9C",vendor: "Eadingcore  Intelligent Technology Co., Ltd."},
  {max_prefix: "84-26-90",vendor: "BEIJING THOUGHT SCIENCE CO.,LTD."},
  {max_prefix: "84-DD-B7",vendor: "Cilag GmbH International"},
  {max_prefix: "CC-BD-D3",vendor: "Ultimaker B.V."},
  {max_prefix: "8C-E7-8C",vendor: "DK Networks"},
  {max_prefix: "C0-98-79",vendor: "Acer Inc."},
  {max_prefix: "30-73-50",vendor: "Inpeco SA"},
  {max_prefix: "DC-EC-06",vendor: "Heimi Network Technology Co., Ltd."},
  {max_prefix: "EC-13-B2",vendor: "Netonix"},
  {max_prefix: "10-4E-07",vendor: "Shanghai Genvision Industries Co.,Ltd"},
  {max_prefix: "F0-3D-29",vendor: "Actility"},
  {max_prefix: "88-70-8C",vendor: "Lenovo Mobile Communication Technology Ltd."},
  {max_prefix: "08-11-5E",vendor: "Bitel Co., Ltd."},
  {max_prefix: "08-81-BC",vendor: "HongKong Ipro Technology Co., Limited"},
  {max_prefix: "50-14-B5",vendor: "Richfit Information Technology Co., Ltd"},
  {max_prefix: "34-28-F0",vendor: "ATN International Limited"},
  {max_prefix: "CC-10-A3",vendor: "Beijing Nan Bao Technology Co., Ltd."},
  {max_prefix: "14-ED-E4",vendor: "Kaiam Corporation"},
  {max_prefix: "CC-3F-1D",vendor: "HMS Industrial Networks SLU"},
  {max_prefix: "DC-DA-4F",vendor: "GETCK TECHNOLOGY,  INC"},
  {max_prefix: "80-19-67",vendor: "Shanghai Reallytek Information Technology  Co.,Ltd"},
  {max_prefix: "2C-F7-F1",vendor: "Seeed Technology Inc."},
  {max_prefix: "10-12-18",vendor: "Korins Inc."},
  {max_prefix: "B8-4F-D5",vendor: "Microsoft Corporation"},
  {max_prefix: "D8-4A-87",vendor: "OI ELECTRIC CO.,LTD"},
  {max_prefix: "D0-12-42",vendor: "BIOS Corporation"},
  {max_prefix: "60-36-96",vendor: "The Sapling Company"},
  {max_prefix: "F4-F2-6D",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "AC-B7-4F",vendor: "METEL s.r.o."},
  {max_prefix: "CC-F5-38",vendor: "3isysnetworks"},
  {max_prefix: "C0-EE-FB",vendor: "OnePlus Tech (Shenzhen) Ltd"},
  {max_prefix: "54-FF-CF",vendor: "Mopria Alliance"},
  {max_prefix: "B8-BD-79",vendor: "TrendPoint Systems"},
  {max_prefix: "30-42-25",vendor: "BURG-W\u00c4CHTER KG"},
  {max_prefix: "FC-DB-B3",vendor: "Murata Manufacturing Co., Ltd."},
  {max_prefix: "40-4E-EB",vendor: "Higher Way Electronic Co., Ltd."},
  {max_prefix: "C4-56-FE",vendor: "Lava International Ltd."},
  {max_prefix: "74-F4-13",vendor: "Maxwell Forest"},
  {max_prefix: "A0-06-27",vendor: "NEXPA System"},
  {max_prefix: "30-33-35",vendor: "Boosty"},
  {max_prefix: "F4-D2-61",vendor: "SEMOCON Co., Ltd"},
  {max_prefix: "B0-09-D3",vendor: "Avizia"},
  {max_prefix: "9C-AD-97",vendor: "Hon Hai Precision Ind. Co.,Ltd."},
  {max_prefix: "BC-9C-C5",vendor: "Beijing Huafei Technology Co., Ltd."},
  {max_prefix: "94-D6-0E",vendor: "shenzhen yunmao information technologies co., ltd"},
  {max_prefix: "50-67-87",vendor: "Planet Networks"},
  {max_prefix: "5C-B8-CB",vendor: "Allis Communications"},
  {max_prefix: "34-F0-CA",vendor: "Shenzhen Linghangyuan Digital Technology Co.,Ltd."},
  {max_prefix: "44-74-6C",vendor: "Sony Corporation"},
  {max_prefix: "4C-9E-FF",vendor: "Zyxel Communications Corporation"},
  {max_prefix: "6C-2F-2C",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "8C-BF-9D",vendor: "Shanghai Xinyou Information Technology Ltd. Co."},
  {max_prefix: "70-72-0D",vendor: "Lenovo Mobile Communication Technology Ltd."},
  {max_prefix: "3C-CD-5A",vendor: "Technische Alternative GmbH"},
  {max_prefix: "48-D8-55",vendor: "Telvent"},
  {max_prefix: "08-F7-28",vendor: "GLOBO Multimedia Sp. z o.o. Sp.k."},
  {max_prefix: "20-6E-9C",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "BC-25-F0",vendor: "3D Display Technologies Co., Ltd."},
  {max_prefix: "C0-3D-46",vendor: "Shanghai Sango Network Technology Co.,Ltd"},
  {max_prefix: "64-EA-C5",vendor: "SiboTech Automation Co., Ltd."},
  {max_prefix: "60-C1-CB",vendor: "Fujian Great Power PLC Equipment Co.,Ltd"},
  {max_prefix: "88-29-50",vendor: "Netmoon Technology Co., Ltd"},
  {max_prefix: "7C-E5-24",vendor: "Quirky, Inc."},
  {max_prefix: "74-DA-38",vendor: "Edimax Technology Co. Ltd."},
  {max_prefix: "30-F7-D7",vendor: "Thread Technology Co., Ltd"},
  {max_prefix: "18-22-7E",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "30-C7-AE",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "E4-F4-C6",vendor: "NETGEAR"},
  {max_prefix: "90-AE-1B",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "60-E3-27",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "44-D4-E0",vendor: "Sony Corporation"},
  {max_prefix: "B0-25-AA",vendor: "Private"},
  {max_prefix: "D4-B4-3E",vendor: "Messcomp Datentechnik GmbH"},
  {max_prefix: "CC-A0-E5",vendor: "DZG Metering GmbH"},
  {max_prefix: "60-81-2B",vendor: "Astronics Custom Control Concepts"},
  {max_prefix: "F8-66-01",vendor: "Suzhou Chi-tek information technology Co., Ltd"},
  {max_prefix: "14-56-45",vendor: "Savitech Corp."},
  {max_prefix: "1C-1C-FD",vendor: "Dalian Hi-Think Computer Technology, Corp"},
  {max_prefix: "48-D1-8E",vendor: "Metis Communication Co.,Ltd"},
  {max_prefix: "6C-2C-06",vendor: "OOO NPP Systemotechnika-NN"},
  {max_prefix: "C4-91-3A",vendor: "Shenzhen Sanland Electronic Co., ltd."},
  {max_prefix: "70-62-B8",vendor: "D-Link International"},
  {max_prefix: "AC-A9-19",vendor: "TrekStor GmbH"},
  {max_prefix: "C4-4E-1F",vendor: "BlueN"},
  {max_prefix: "B0-86-9E",vendor: "Chloride S.r.L"},
  {max_prefix: "D0-57-A1",vendor: "Werma Signaltechnik GmbH & Co. KG"},
  {max_prefix: "B4-B5-42",vendor: "Hubbell Power Systems, Inc."},
  {max_prefix: "64-E8-92",vendor: "Morio Denki Co., Ltd."},
  {max_prefix: "88-E8-F8",vendor: "YONG TAI ELECTRONIC (DONGGUAN) LTD."},
  {max_prefix: "90-98-64",vendor: "Impex-Sat GmbH&amp;Co KG"},
  {max_prefix: "DC-E5-78",vendor: "Experimental Factory of Scientific Engineering and Special Design Department"},
  {max_prefix: "38-F0-98",vendor: "Vapor Stone Rail Systems"},
  {max_prefix: "54-CD-EE",vendor: "ShenZhen Apexis Electronic Co.,Ltd"},
  {max_prefix: "E8-FC-60",vendor: "ELCOM Innovations Private Limited"},
  {max_prefix: "94-51-BF",vendor: "Hyundai ESG"},
  {max_prefix: "F0-15-A0",vendor: "KyungDong One Co., Ltd."},
  {max_prefix: "1C-AB-01",vendor: "Innovolt"},
  {max_prefix: "B0-DA-00",vendor: "CERA ELECTRONIQUE"},
  {max_prefix: "D8-B6-D6",vendor: "Blu Tether Limited"},
  {max_prefix: "94-C0-14",vendor: "Sorter Sp. j. Konrad Grzeszczyk MichaA, Ziomek"},
  {max_prefix: "9C-FB-F1",vendor: "MESOMATIC GmbH & Co.KG"},
  {max_prefix: "10-27-BE",vendor: "TVIP"},
  {max_prefix: "20-87-AC",vendor: "AES motomation"},
  {max_prefix: "70-93-83",vendor: "Intelligent Optical Network High Tech CO.,LTD."},
  {max_prefix: "80-D4-33",vendor: "LzLabs GmbH"},
  {max_prefix: "8C-41-F2",vendor: "RDA Technologies Ltd."},
  {max_prefix: "E0-36-E3",vendor: "Stage One International Co., Ltd."},
  {max_prefix: "24-26-42",vendor: "SHARP Corporation."},
  {max_prefix: "34-DE-34",vendor: "zte corporation"},
  {max_prefix: "FC-16-07",vendor: "Taian Technology(Wuxi) Co.,Ltd."},
  {max_prefix: "AC-02-CA",vendor: "HI Solutions, Inc."},
  {max_prefix: "04-DB-8A",vendor: "Suntech International Ltd."},
  {max_prefix: "90-DF-B7",vendor: "s.m.s smart microwave sensors GmbH"},
  {max_prefix: "08-57-00",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "F8-5C-45",vendor: "IC Nexus Co. Ltd."},
  {max_prefix: "AC-E0-69",vendor: "ISAAC Instruments"},
  {max_prefix: "30-B5-C2",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "FC-27-A2",vendor: "TRANS ELECTRIC CO., LTD."},
  {max_prefix: "FC-BB-A1",vendor: "Shenzhen Minicreate Technology Co.,Ltd"},
  {max_prefix: "F0-8A-28",vendor: "JIANGSU HENGSION ELECTRONIC S and T CO.,LTD"},
  {max_prefix: "28-BB-59",vendor: "RNET Technologies, Inc."},
  {max_prefix: "E8-EA-6A",vendor: "StarTech.com"},
  {max_prefix: "D8-65-95",vendor: "Toy's Myth Inc."},
  {max_prefix: "C0-F7-9D",vendor: "Powercode"},
  {max_prefix: "C4-C9-19",vendor: "Energy Imports Ltd"},
  {max_prefix: "D8-DD-5F",vendor: "BALMUDA Inc."},
  {max_prefix: "E0-7F-53",vendor: "TECHBOARD SRL"},
  {max_prefix: "48-FE-EA",vendor: "HOMA B.V."},
  {max_prefix: "74-6F-3D",vendor: "Contec GmbH"},
  {max_prefix: "4C-0D-EE",vendor: "JABIL CIRCUIT (SHANGHAI) LTD."},
  {max_prefix: "D0-63-4D",vendor: "Meiko Maschinenbau GmbH &amp; Co. KG"},
  {max_prefix: "D8-61-94",vendor: "Objetivos y Sevicios de Valor An\u0303adido"},
  {max_prefix: "58-9C-FC",vendor: "FreeBSD Foundation"},
  {max_prefix: "70-2C-1F",vendor: "Wisol"},
  {max_prefix: "DC-05-2F",vendor: "National Products Inc."},
  {max_prefix: "00-8B-43",vendor: "RFTECH"},
  {max_prefix: "C8-D4-29",vendor: "Muehlbauer AG"},
  {max_prefix: "8C-56-9D",vendor: "Imaging Solutions Group"},
  {max_prefix: "40-B6-B1",vendor: "SUNGSAM CO,.Ltd"},
  {max_prefix: "E8-61-83",vendor: "Black Diamond Advanced Technology, LLC"},
  {max_prefix: "38-C9-A9",vendor: "SMART High Reliability Solutions, Inc."},
  {max_prefix: "8C-DE-99",vendor: "Comlab Inc."},
  {max_prefix: "4C-E1-BB",vendor: "Zhuhai HiFocus Technology Co., Ltd."},
  {max_prefix: "24-A8-7D",vendor: "Panasonic Automotive Systems Asia Pacific(Thailand)Co.,Ltd."},
  {max_prefix: "84-FE-9E",vendor: "RTC Industries, Inc."},
  {max_prefix: "FC-4B-1C",vendor: "INTERSENSOR S.R.L."},
  {max_prefix: "40-30-67",vendor: "Conlog (Pty) Ltd"},
  {max_prefix: "60-03-47",vendor: "Billion Electric Co. Ltd."},
  {max_prefix: "F8-1C-E5",vendor: "Telefonbau Behnke GmbH"},
  {max_prefix: "EC-71-DB",vendor: "Reolink Innovation Limited"},
  {max_prefix: "A4-09-CB",vendor: "Alfred Kaercher GmbH &amp; Co KG"},
  {max_prefix: "50-1A-C5",vendor: "Microsoft"},
  {max_prefix: "B0-98-9F",vendor: "LG CNS"},
  {max_prefix: "C0-F1-C4",vendor: "Pacidal Corporation Ltd."},
  {max_prefix: "14-ED-A5",vendor: "Wa\u0308chter GmbH Sicherheitssysteme"},
  {max_prefix: "D0-BD-01",vendor: "DS International"},
  {max_prefix: "08-52-40",vendor: "EbV Elektronikbau- und Vertriebs GmbH"},
  {max_prefix: "B8-C1-A2",vendor: "Dragon Path Technologies Co., Limited"},
  {max_prefix: "80-F2-5E",vendor: "Kyynel"},
  {max_prefix: "88-91-66",vendor: "Viewcooper Corp."},
  {max_prefix: "38-A5-3C",vendor: "COMECER Netherlands"},
  {max_prefix: "5C-FF-FF",vendor: "Shenzhen Kezhonglong Optoelectronic Technology Co., Ltd"},
  {max_prefix: "68-69-2E",vendor: "Zycoo Co.,Ltd"},
  {max_prefix: "D4-68-67",vendor: "Neoventus Design Group"},
  {max_prefix: "9C-21-6A",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "F8-62-AA",vendor: "xn systems"},
  {max_prefix: "A4-05-9E",vendor: "STA Infinity LLP"},
  {max_prefix: "44-EE-30",vendor: "Budelmann Elektronik GmbH"},
  {max_prefix: "FC-1E-16",vendor: "IPEVO corp"},
  {max_prefix: "30-51-F8",vendor: "BYK-Gardner GmbH"},
  {max_prefix: "E8-F2-26",vendor: "MILLSON CUSTOM SOLUTIONS INC."},
  {max_prefix: "3C-6E-63",vendor: "Mitron OY"},
  {max_prefix: "10-33-78",vendor: "FLECTRON Co., LTD"},
  {max_prefix: "F0-D3-A7",vendor: "CobaltRay Co., Ltd"},
  {max_prefix: "2C-18-AE",vendor: "Trend Electronics Co., Ltd."},
  {max_prefix: "50-C0-06",vendor: "Carmanah Signs"},
  {max_prefix: "A4-E9-A3",vendor: "Honest Technology Co., Ltd"},
  {max_prefix: "A0-E5-E9",vendor: "enimai Inc"},
  {max_prefix: "04-CB-1D",vendor: "Traka plc"},
  {max_prefix: "C0-C6-87",vendor: "Cisco SPVTG"},
  {max_prefix: "C4-93-80",vendor: "Speedytel technology"},
  {max_prefix: "00-7D-FA",vendor: "Volkswagen Group of America"},
  {max_prefix: "E0-AE-B2",vendor: "Bender GmbH &amp; Co.KG"},
  {max_prefix: "F8-4A-7F",vendor: "Innometriks Inc"},
  {max_prefix: "0C-9B-13",vendor: "Shanghai Magic Mobile Telecommunication Co.Ltd."},
  {max_prefix: "10-7B-EF",vendor: "Zyxel Communications Corporation"},
  {max_prefix: "B4-62-AD",vendor: "Elysia Germany GmbH"},
  {max_prefix: "2C-71-55",vendor: "HiveMotion"},
  {max_prefix: "20-18-0E",vendor: "Shenzhen Sunchip Technology Co., Ltd"},
  {max_prefix: "80-B2-19",vendor: "ELEKTRON TECHNOLOGY UK LIMITED"},
  {max_prefix: "28-94-AF",vendor: "Samhwa Telecom"},
  {max_prefix: "B4-75-0E",vendor: "Belkin International Inc."},
  {max_prefix: "94-10-3E",vendor: "Belkin International Inc."},
  {max_prefix: "7C-B7-33",vendor: "ASKEY COMPUTER CORP"},
  {max_prefix: "34-5C-40",vendor: "Cargt Holdings LLC"},
  {max_prefix: "74-A4-B5",vendor: "Powerleader Science and Technology Co. Ltd."},
  {max_prefix: "90-9F-43",vendor: "Accutron Instruments Inc."},
  {max_prefix: "AC-50-36",vendor: "Pi-Coral Inc"},
  {max_prefix: "C4-D6-55",vendor: "Tercel technology co.,ltd"},
  {max_prefix: "58-BD-F9",vendor: "Sigrand"},
  {max_prefix: "24-64-EF",vendor: "CYG SUNRI CO.,LTD."},
  {max_prefix: "D8-27-0C",vendor: "MaxTronic International Co., Ltd."},
  {max_prefix: "68-19-3F",vendor: "Digital Airways"},
  {max_prefix: "B4-CC-E9",vendor: "PROSYST"},
  {max_prefix: "A0-BF-50",vendor: "S.C. ADD-PRODUCTION S.R.L."},
  {max_prefix: "E8-D4-E0",vendor: "Beijing BenyWave Technology Co., Ltd."},
  {max_prefix: "FC-01-9E",vendor: "VIEVU"},
  {max_prefix: "64-21-84",vendor: "Nippon Denki Kagaku Co.,LTD"},
  {max_prefix: "94-BF-1E",vendor: "eflow Inc. / Smart Device Planning and Development Division"},
  {max_prefix: "E8-51-6E",vendor: "TSMART Inc."},
  {max_prefix: "AC-22-0B",vendor: "ASUSTek COMPUTER INC."},
  {max_prefix: "B8-87-A8",vendor: "Step Ahead Innovations Inc."},
  {max_prefix: "8C-4B-59",vendor: "3D Imaging & Simulations Corp"},
  {max_prefix: "5C-33-27",vendor: "Spazio Italia srl"},
  {max_prefix: "E0-A1-98",vendor: "NOJA Power Switchgear Pty Ltd"},
  {max_prefix: "88-35-4C",vendor: "Transics"},
  {max_prefix: "50-76-91",vendor: "Tekpea, Inc."},
  {max_prefix: "CC-4A-E1",vendor: "fourtec -Fourier Technologies"},
  {max_prefix: "28-F5-32",vendor: "ADD-Engineering BV"},
  {max_prefix: "94-40-A2",vendor: "Anywave Communication Technologies, Inc."},
  {max_prefix: "38-42-33",vendor: "Wildeboer Bauteile GmbH"},
  {max_prefix: "C0-34-B4",vendor: "Gigastone Corporation"},
  {max_prefix: "30-3E-AD",vendor: "Sonavox Canada Inc"},
  {max_prefix: "F8-35-DD",vendor: "Gemtek Technology Co., Ltd."},
  {max_prefix: "D8-B0-4C",vendor: "Jinan USR IOT Technology Co., Ltd."},
  {max_prefix: "E8-51-9D",vendor: "Yeonhab Precision Co.,LTD"},
  {max_prefix: "1C-86-AD",vendor: "MCT CO., LTD."},
  {max_prefix: "28-D9-3E",vendor: "Telecor Inc."},
  {max_prefix: "64-0B-4A",vendor: "Digital Telecom Technology Limited"},
  {max_prefix: "70-F1-76",vendor: "Data Modul AG"},
  {max_prefix: "CC-E8-AC",vendor: "SOYEA Technology Co.,Ltd."},
  {max_prefix: "CC-04-B4",vendor: "Select Comfort"},
  {max_prefix: "5C-15-E1",vendor: "AIDC TECHNOLOGY (S) PTE LTD"},
  {max_prefix: "B8-47-C6",vendor: "SanJet Technology Corp."},
  {max_prefix: "B8-CD-93",vendor: "Penetek, Inc"},
  {max_prefix: "38-06-B4",vendor: "A.D.C. GmbH"},
  {max_prefix: "B8-24-1A",vendor: "SWEDA INFORMATICA LTDA"},
  {max_prefix: "A0-B1-00",vendor: "ShenZhen Cando Electronics Co.,Ltd"},
  {max_prefix: "20-1D-03",vendor: "Elatec GmbH"},
  {max_prefix: "C0-6C-6D",vendor: "MagneMotion, Inc."},
  {max_prefix: "B8-C8-55",vendor: "Shanghai GBCOM Communication Technology Co.,Ltd."},
  {max_prefix: "78-30-3B",vendor: "Stephen Technologies Co.,Limited"},
  {max_prefix: "40-BC-73",vendor: "Cronoplast  S.L."},
  {max_prefix: "40-07-C0",vendor: "Railtec Systems GmbH"},
  {max_prefix: "84-E4-D9",vendor: "Shenzhen NEED technology Ltd."},
  {max_prefix: "78-4B-08",vendor: "f.robotics acquisitions ltd"},
  {max_prefix: "B0-80-8C",vendor: "Laser Light Engines"},
  {max_prefix: "E8-80-D8",vendor: "GNTEK Electronics Co.,Ltd."},
  {max_prefix: "D8-66-C6",vendor: "Shenzhen Daystar Technology Co.,ltd"},
  {max_prefix: "D0-0E-A4",vendor: "Porsche Cars North America"},
  {max_prefix: "18-88-57",vendor: "Beijing Jinhong Xi-Dian Information Technology Corp."},
  {max_prefix: "E4-77-6B",vendor: "AARTESYS AG"},
  {max_prefix: "30-F3-1D",vendor: "zte corporation"},
  {max_prefix: "A0-EC-80",vendor: "zte corporation"},
  {max_prefix: "28-DB-81",vendor: "Shanghai Guao Electronic Technology Co., Ltd"},
  {max_prefix: "0C-C8-1F",vendor: "Summer Infant, Inc."},
  {max_prefix: "A8-FB-70",vendor: "WiseSec L.t.d"},
  {max_prefix: "E4-81-B3",vendor: "Shenzhen ACT Industrial Co.,Ltd."},
  {max_prefix: "1C-4A-F7",vendor: "AMON INC"},
  {max_prefix: "D8-DC-E9",vendor: "Kunshan Erlab ductless filtration system Co.,Ltd"},
  {max_prefix: "DC-D5-2A",vendor: "Sunny Heart Limited"},
  {max_prefix: "A4-D3-B5",vendor: "GLITEL Stropkov, s.r.o."},
  {max_prefix: "44-61-9C",vendor: "FONsystem co. ltd."},
  {max_prefix: "88-68-5C",vendor: "Shenzhen ChuangDao & Perpetual Eternal Technology Co.,Ltd"},
  {max_prefix: "10-28-31",vendor: "Morion Inc."},
  {max_prefix: "DC-57-26",vendor: "Power-One"},
  {max_prefix: "F8-DA-DF",vendor: "EcoTech, Inc."},
  {max_prefix: "30-AE-7B",vendor: "Deqing Dusun Electron CO., LTD"},
  {max_prefix: "68-EC-62",vendor: "YODO Technology Corp. Ltd."},
  {max_prefix: "1C-76-CA",vendor: "Terasic Technologies Inc."},
  {max_prefix: "F4-99-AC",vendor: "WEBER Schraubautomaten GmbH"},
  {max_prefix: "D4-3A-65",vendor: "IGRS Engineering Lab Ltd."},
  {max_prefix: "7C-D8-44",vendor: "Enmotus Inc"},
  {max_prefix: "70-82-0E",vendor: "as electronics GmbH"},
  {max_prefix: "88-89-64",vendor: "GSI Electronics Inc."},
  {max_prefix: "0C-11-05",vendor: "AKUVOX (XIAMEN) NETWORKS CO., LTD"},
  {max_prefix: "5C-22-C4",vendor: "DAE EUN ELETRONICS CO., LTD"},
  {max_prefix: "F8-FE-A8",vendor: "Technico Japan Corporation"},
  {max_prefix: "18-00-DB",vendor: "Fitbit Inc."},
  {max_prefix: "78-A1-06",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "D0-50-99",vendor: "ASRock Incorporation"},
  {max_prefix: "A4-9E-DB",vendor: "AutoCrib, Inc."},
  {max_prefix: "6C-8B-2F",vendor: "zte corporation"},
  {max_prefix: "CC-1A-FA",vendor: "zte corporation"},
  {max_prefix: "8C-5A-F0",vendor: "Exeltech Solar Products"},
  {max_prefix: "6C-61-26",vendor: "Rinicom Holdings"},
  {max_prefix: "CC-04-7C",vendor: "G-WAY Microwave"},
  {max_prefix: "50-AB-BF",vendor: "Hoseo Telecom"},
  {max_prefix: "B4-FE-8C",vendor: "Centro Sicurezza Italia SpA"},
  {max_prefix: "2C-F2-03",vendor: "EMKO ELEKTRONIK SAN VE TIC AS"},
  {max_prefix: "18-5A-E8",vendor: "Zenotech.Co.,Ltd"},
  {max_prefix: "C4-7D-CC",vendor: "Zebra Technologies Inc"},
  {max_prefix: "E0-AE-ED",vendor: "LOENK"},
  {max_prefix: "E4-92-E7",vendor: "Gridlink Tech. Co.,Ltd."},
  {max_prefix: "1C-37-BF",vendor: "Cloudium Systems Ltd."},
  {max_prefix: "D8-29-16",vendor: "Ascent Communication Technology"},
  {max_prefix: "A0-73-FC",vendor: "Rancore Technologies Private Limited"},
  {max_prefix: "64-53-5D",vendor: "Frauscher Sensortechnik"},
  {max_prefix: "40-E7-30",vendor: "DEY Storage Systems, Inc."},
  {max_prefix: "68-B0-94",vendor: "INESA ELECTRON CO.,LTD"},
  {max_prefix: "44-F8-49",vendor: "Union Pacific Railroad"},
  {max_prefix: "CC-0D-EC",vendor: "Cisco SPVTG"},
  {max_prefix: "2C-7B-5A",vendor: "Milper Ltd"},
  {max_prefix: "0C-72-2C",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "FC-9F-AE",vendor: "Fidus Systems Inc"},
  {max_prefix: "68-1E-8B",vendor: "InfoSight Corporation"},
  {max_prefix: "F8-D7-BF",vendor: "REV Ritter GmbH"},
  {max_prefix: "48-BE-2D",vendor: "Symanitron"},
  {max_prefix: "F0-23-29",vendor: "SHOWA DENKI CO.,LTD."},
  {max_prefix: "54-61-EA",vendor: "Zaplox AB"},
  {max_prefix: "D0-8B-7E",vendor: "Passif Semiconductor"},
  {max_prefix: "04-58-6F",vendor: "Sichuan Whayer information industry Co.,LTD"},
  {max_prefix: "D4-BF-2D",vendor: "SE Controls Asia Pacific Ltd"},
  {max_prefix: "9C-E6-35",vendor: "Nintendo Co., Ltd."},
  {max_prefix: "60-A4-4C",vendor: "ASUSTek COMPUTER INC."},
  {max_prefix: "E0-D9-A2",vendor: "Hippih aps"},
  {max_prefix: "FC-06-47",vendor: "Cortland Research, LLC"},
  {max_prefix: "D0-52-A8",vendor: "Physical Graph Corporation"},
  {max_prefix: "CC-3A-61",vendor: "SAMSUNG ELECTRO MECHANICS CO., LTD."},
  {max_prefix: "3C-6F-F7",vendor: "EnTek Systems, Inc."},
  {max_prefix: "6C-D1-46",vendor: "FRAMOS GmbH"},
  {max_prefix: "3C-57-D5",vendor: "FiveCo"},
  {max_prefix: "F0-73-AE",vendor: "PEAK-System Technik"},
  {max_prefix: "48-B8-DE",vendor: "HOMEWINS TECHNOLOGY CO.,LTD."},
  {max_prefix: "10-EA-59",vendor: "Cisco SPVTG"},
  {max_prefix: "0C-19-1F",vendor: "Inform Electronik"},
  {max_prefix: "98-20-8E",vendor: "Definium Technologies"},
  {max_prefix: "70-4A-E4",vendor: "Rinstrum Pty Ltd"},
  {max_prefix: "08-3A-B8",vendor: "Shinoda Plasma Co., Ltd."},
  {max_prefix: "A0-DD-97",vendor: "PolarLink Technologies, Ltd"},
  {max_prefix: "E4-A7-FD",vendor: "Cellco Partnership"},
  {max_prefix: "2C-E2-A8",vendor: "DeviceDesign"},
  {max_prefix: "60-C5-A8",vendor: "Beijing LT Honway Technology Co.,Ltd"},
  {max_prefix: "B4-DF-3B",vendor: "Chromlech"},
  {max_prefix: "A8-45-E9",vendor: "Firich Enterprises CO., LTD."},
  {max_prefix: "7C-9A-9B",vendor: "VSE valencia smart energy"},
  {max_prefix: "64-5A-04",vendor: "Chicony Electronics Co., Ltd."},
  {max_prefix: "AC-17-02",vendor: "Fibar Group sp. z o.o."},
  {max_prefix: "98-4C-D3",vendor: "Mantis Deposition"},
  {max_prefix: "08-60-6E",vendor: "ASUSTek COMPUTER INC."},
  {max_prefix: "EC-89-F5",vendor: "Lenovo Mobile Communication Technology Ltd."},
  {max_prefix: "B4-98-42",vendor: "zte corporation"},
  {max_prefix: "70-54-D2",vendor: "PEGATRON CORPORATION"},
  {max_prefix: "70-76-DD",vendor: "OxyGuard Internation A/S"},
  {max_prefix: "E8-9A-FF",vendor: "Fujian LANDI Commercial Equipment Co.,Ltd"},
  {max_prefix: "10-65-CF",vendor: "IQSIM"},
  {max_prefix: "68-4C-A8",vendor: "Shenzhen Herotel Tech. Co., Ltd."},
  {max_prefix: "0C-8C-DC",vendor: "Suunto Oy"},
  {max_prefix: "F8-48-97",vendor: "Hitachi, Ltd."},
  {max_prefix: "F8-0B-D0",vendor: "Datang Telecom communication terminal (Tianjin) Co., Ltd."},
  {max_prefix: "64-62-23",vendor: "Cellient Co., Ltd."},
  {max_prefix: "98-47-3C",vendor: "SHANGHAI SUNMON COMMUNICATION TECHNOGY CO.,LTD"},
  {max_prefix: "54-81-AD",vendor: "Eagle Research Corporation"},
  {max_prefix: "EC-47-3C",vendor: "Redwire, LLC"},
  {max_prefix: "3C-C1-2C",vendor: "AES Corporation"},
  {max_prefix: "94-9B-FD",vendor: "Trans New Technology, Inc."},
  {max_prefix: "84-E7-14",vendor: "Liang Herng Enterprise,Co.Ltd."},
  {max_prefix: "B8-29-F7",vendor: "Blaster Tech"},
  {max_prefix: "C8-E1-A7",vendor: "Vertu Corporation Limited"},
  {max_prefix: "A0-0A-BF",vendor: "Wieson Technologies Co., Ltd."},
  {max_prefix: "8C-CD-E8",vendor: "Nintendo Co., Ltd."},
  {max_prefix: "00-E6-66",vendor: "ARIMA Communications Corp."},
  {max_prefix: "34-BD-FA",vendor: "Cisco SPVTG"},
  {max_prefix: "D4-13-6F",vendor: "Asia Pacific Brands"},
  {max_prefix: "00-B5-6D",vendor: "David Electronics Co., LTD."},
  {max_prefix: "2C-35-57",vendor: "ELIIY Power CO., Ltd."},
  {max_prefix: "B8-04-15",vendor: "Bayan Audio"},
  {max_prefix: "4C-AB-33",vendor: "KST technology"},
  {max_prefix: "48-52-61",vendor: "SOREEL"},
  {max_prefix: "F4-47-2A",vendor: "Nanjing Rousing Sci. and Tech. Industrial Co., Ltd"},
  {max_prefix: "DC-02-8E",vendor: "zte corporation"},
  {max_prefix: "18-D9-49",vendor: "Qvis Labs, LLC"},
  {max_prefix: "D8-08-F5",vendor: "Arcadia Networks Co. Ltd."},
  {max_prefix: "04-9F-06",vendor: "Smobile Co., Ltd."},
  {max_prefix: "28-9A-4B",vendor: "SteelSeries ApS"},
  {max_prefix: "7C-B2-32",vendor: "Hui Zhou Gaoshengda Technology Co.,LTD"},
  {max_prefix: "54-A0-4F",vendor: "t-mac Technologies Ltd"},
  {max_prefix: "14-DB-85",vendor: "S NET MEDIA"},
  {max_prefix: "B8-DA-F1",vendor: "Strahlenschutz- Entwicklungs- und Ausruestungsgesellschaft mbH"},
  {max_prefix: "D4-5C-70",vendor: "Wi-Fi Alliance"},
  {max_prefix: "0C-C4-7E",vendor: "EUCAST Co., Ltd."},
  {max_prefix: "50-72-4D",vendor: "BEG Brueck Electronic GmbH"},
  {max_prefix: "78-3C-E3",vendor: "Kai-EE"},
  {max_prefix: "B8-98-B0",vendor: "Atlona Inc."},
  {max_prefix: "08-0C-0B",vendor: "SysMik GmbH Dresden"},
  {max_prefix: "DC-BF-90",vendor: "HUIZHOU QIAOXING TELECOMMUNICATION INDUSTRY CO.,LTD."},
  {max_prefix: "C8-FB-26",vendor: "Cisco SPVTG"},
  {max_prefix: "AC-BD-0B",vendor: "Leimac Ltd."},
  {max_prefix: "B8-58-10",vendor: "NUMERA, INC."},
  {max_prefix: "A0-8C-15",vendor: "Gerhard D. Wempe KG"},
  {max_prefix: "90-CF-6F",vendor: "Dlogixs Co Ltd"},
  {max_prefix: "24-69-4A",vendor: "Jasmine Systems Inc."},
  {max_prefix: "F4-1E-26",vendor: "Simon-Kaloi Engineering"},
  {max_prefix: "70-25-26",vendor: "Nokia"},
  {max_prefix: "EC-D9-50",vendor: "IRT SA"},
  {max_prefix: "64-5F-FF",vendor: "Nicolet Neuro"},
  {max_prefix: "A4-D1-8F",vendor: "Shenzhen Skyee Optical Fiber Communication Technology Ltd."},
  {max_prefix: "58-34-3B",vendor: "Glovast Technology Ltd."},
  {max_prefix: "10-9F-A9",vendor: "Actiontec Electronics, Inc"},
  {max_prefix: "C0-A3-64",vendor: "3D Systems Massachusetts"},
  {max_prefix: "AC-7A-42",vendor: "iConnectivity"},
  {max_prefix: "F8-05-1C",vendor: "DRS Imaging and Targeting Solutions"},
  {max_prefix: "78-D3-4F",vendor: "Pace-O-Matic, Inc."},
  {max_prefix: "A4-46-6B",vendor: "EOC Technology"},
  {max_prefix: "7C-38-6C",vendor: "Real Time Logic"},
  {max_prefix: "20-67-B1",vendor: "Pluto inc."},
  {max_prefix: "7C-02-BC",vendor: "Hansung Electronics Co. LTD"},
  {max_prefix: "B8-24-10",vendor: "Magneti Marelli Slovakia s.r.o."},
  {max_prefix: "10-5F-49",vendor: "Cisco SPVTG"},
  {max_prefix: "1C-5C-60",vendor: "Shenzhen Belzon Technology Co.,LTD."},
  {max_prefix: "70-0B-C0",vendor: "Dewav Technology Company"},
  {max_prefix: "CC-14-A6",vendor: "Yichun MyEnergy Domain, Inc"},
  {max_prefix: "88-96-76",vendor: "TTC MARCONI s.r.o."},
  {max_prefix: "B8-B9-4E",vendor: "Shenzhen iBaby Labs, Inc."},
  {max_prefix: "AC-C6-98",vendor: "Kohzu Precision Co., Ltd."},
  {max_prefix: "AC-0A-61",vendor: "Labor S.r.L."},
  {max_prefix: "24-10-64",vendor: "Shenzhen Ecsino Tecnical Co. Ltd"},
  {max_prefix: "7C-EB-EA",vendor: "ASCT"},
  {max_prefix: "18-9A-67",vendor: "CSE-Servelec Limited"},
  {max_prefix: "08-7D-21",vendor: "Altasec technology corporation"},
  {max_prefix: "34-D7-B4",vendor: "Tributary Systems, Inc."},
  {max_prefix: "F4-0F-9B",vendor: "WAVELINK"},
  {max_prefix: "90-1E-DD",vendor: "GREAT COMPUTER CORPORATION"},
  {max_prefix: "9C-0D-AC",vendor: "Tymphany HK Limited"},
  {max_prefix: "1C-43-EC",vendor: "JAPAN CIRCUIT CO.,LTD"},
  {max_prefix: "78-52-62",vendor: "Shenzhen Hojy Software Co., Ltd."},
  {max_prefix: "20-01-4F",vendor: "Linea Research Ltd"},
  {max_prefix: "EC-0E-D6",vendor: "ITECH INSTRUMENTS SAS"},
  {max_prefix: "74-6A-89",vendor: "Rezolt Corporation"},
  {max_prefix: "70-2F-4B",vendor: "Steelcase Inc."},
  {max_prefix: "74-14-89",vendor: "SRT Wireless"},
  {max_prefix: "24-1B-13",vendor: "Shanghai Nutshell Electronic Co., Ltd."},
  {max_prefix: "B4-35-64",vendor: "Fujian Tian Cheng Electron Science & Technical Development Co.,Ltd."},
  {max_prefix: "1C-5F-FF",vendor: "Beijing Ereneben Information Technology Co.,Ltd Shenzhen Branch"},
  {max_prefix: "60-45-BD",vendor: "Microsoft"},
  {max_prefix: "9C-54-CA",vendor: "Zhengzhou VCOM Science and Technology Co.,Ltd"},
  {max_prefix: "00-BF-15",vendor: "Genetec Inc."},
  {max_prefix: "24-11-25",vendor: "Hutek Co., Ltd."},
  {max_prefix: "B4-31-B8",vendor: "Aviwest"},
  {max_prefix: "50-8C-77",vendor: "DIRMEIER Schanktechnik GmbH &Co KG"},
  {max_prefix: "5C-17-37",vendor: "I-View Now, LLC."},
  {max_prefix: "70-B5-99",vendor: "Embedded Technologies s.r.o."},
  {max_prefix: "EC-4C-4D",vendor: "ZAO NPK RoTeK"},
  {max_prefix: "38-EE-9D",vendor: "Anedo Ltd."},
  {max_prefix: "54-D1-B0",vendor: "Universal Laser Systems, Inc"},
  {max_prefix: "24-09-17",vendor: "Devlin Electronics Limited"},
  {max_prefix: "78-BE-BD",vendor: "STULZ GmbH"},
  {max_prefix: "B0-58-C4",vendor: "Broadcast Microwave Services, Inc"},
  {max_prefix: "A0-07-B6",vendor: "Advanced Technical Support, Inc."},
  {max_prefix: "0C-D2-B5",vendor: "Binatone Telecommunication Pvt. Ltd"},
  {max_prefix: "48-46-F1",vendor: "Uros Oy"},
  {max_prefix: "B8-27-EB",vendor: "Raspberry Pi Foundation"},
  {max_prefix: "08-BE-09",vendor: "Astrol Electronic AG"},
  {max_prefix: "B4-1D-EF",vendor: "Internet Laboratories, Inc."},
  {max_prefix: "80-93-93",vendor: "Xapt GmbH"},
  {max_prefix: "60-44-F5",vendor: "Easy Digital Ltd."},
  {max_prefix: "F4-7F-35",vendor: "Cisco Systems, Inc"},
  {max_prefix: "BC-C1-68",vendor: "DinBox Sverige AB"},
  {max_prefix: "DC-30-9C",vendor: "Heyrex Limited"},
  {max_prefix: "D4-DF-57",vendor: "Alpinion Medical Systems"},
  {max_prefix: "50-48-EB",vendor: "BEIJING HAIHEJINSHENG NETWORK TECHNOLOGY CO. LTD."},
  {max_prefix: "B4-0E-96",vendor: "HERAN"},
  {max_prefix: "F8-99-55",vendor: "Fortress Technology Inc"},
  {max_prefix: "4C-C9-4F",vendor: "Nokia"},
  {max_prefix: "0C-E5-D3",vendor: "DH electronics GmbH"},
  {max_prefix: "40-70-4A",vendor: "Power Idea Technology Limited"},
  {max_prefix: "54-5E-BD",vendor: "NL Technologies"},
  {max_prefix: "CC-18-7B",vendor: "Manzanita Systems, Inc."},
  {max_prefix: "28-F6-06",vendor: "Syes srl"},
  {max_prefix: "0C-AF-5A",vendor: "GENUS POWER INFRASTRUCTURES LIMITED"},
  {max_prefix: "7C-EF-8A",vendor: "Inhon International Ltd."},
  {max_prefix: "94-FA-E8",vendor: "Shenzhen Eycom Technology Co., Ltd"},
  {max_prefix: "E8-40-F2",vendor: "PEGATRON CORPORATION"},
  {max_prefix: "50-05-3D",vendor: "CyWee Group Ltd"},
  {max_prefix: "F8-8C-1C",vendor: "KAISHUN ELECTRONIC TECHNOLOGY CO., LTD. BEIJING"},
  {max_prefix: "1C-0B-52",vendor: "EPICOM S.A"},
  {max_prefix: "74-7E-2D",vendor: "Beijing Thomson CITIC Digital Technology Co. LTD."},
  {max_prefix: "4C-64-D9",vendor: "Guangdong Leawin Group Co., Ltd"},
  {max_prefix: "9C-01-11",vendor: "Shenzhen Newabel Electronic Co., Ltd."},
  {max_prefix: "40-0E-67",vendor: "Tremol Ltd."},
  {max_prefix: "00-8D-DA",vendor: "Link One Co., Ltd."},
  {max_prefix: "18-19-3F",vendor: "Tamtron Oy"},
  {max_prefix: "88-5C-47",vendor: "Alcatel Lucent"},
  {max_prefix: "3C-C1-F6",vendor: "Melange Systems Pvt. Ltd."},
  {max_prefix: "C4-98-05",vendor: "Minieum Networks, Inc"},
  {max_prefix: "90-F4-C1",vendor: "Rand McNally"},
  {max_prefix: "3C-CE-73",vendor: "Cisco Systems, Inc"},
  {max_prefix: "B0-BD-6D",vendor: "Echostreams Innovative Solutions"},
  {max_prefix: "74-57-98",vendor: "TRUMPF Laser GmbH + Co. KG"},
  {max_prefix: "28-17-CE",vendor: "Omnisense Ltd"},
  {max_prefix: "E4-25-E9",vendor: "Color-Chip"},
  {max_prefix: "78-A1-83",vendor: "Advidia"},
  {max_prefix: "94-01-49",vendor: "AutoHotBox"},
  {max_prefix: "1C-B0-94",vendor: "HTC Corporation"},
  {max_prefix: "14-B1-C8",vendor: "InfiniWing, Inc."},
  {max_prefix: "B4-82-55",vendor: "Research Products Corporation"},
  {max_prefix: "80-16-B7",vendor: "Brunel University"},
  {max_prefix: "48-ED-80",vendor: "daesung eltec"},
  {max_prefix: "C8-07-18",vendor: "TDSi"},
  {max_prefix: "B4-67-E9",vendor: "Qingdao GoerTek Technology Co., Ltd."},
  {max_prefix: "18-67-51",vendor: "KOMEG Industrielle Messtechnik GmbH"},
  {max_prefix: "F0-F7-55",vendor: "Cisco Systems, Inc"},
  {max_prefix: "1C-B2-43",vendor: "TDC A/S"},
  {max_prefix: "38-BF-33",vendor: "NEC CASIO Mobile Communications"},
  {max_prefix: "64-5E-BE",vendor: "Yahoo! JAPAN"},
  {max_prefix: "CC-C5-0A",vendor: "SHENZHEN DAJIAHAO TECHNOLOGY CO.,LTD"},
  {max_prefix: "D8-BF-4C",vendor: "Victory Concept Electronics Limited"},
  {max_prefix: "3C-B9-A6",vendor: "Belden Deutschland GmbH"},
  {max_prefix: "C0-DF-77",vendor: "Conrad Electronic SE"},
  {max_prefix: "58-1D-91",vendor: "Advanced Mobile Telecom co.,ltd."},
  {max_prefix: "1C-B1-7F",vendor: "NEC Platforms, Ltd."},
  {max_prefix: "E4-2C-56",vendor: "Lilee Systems, Ltd."},
  {max_prefix: "B8-96-74",vendor: "AllDSP GmbH & Co. KG"},
  {max_prefix: "30-55-ED",vendor: "Trex Network LLC"},
  {max_prefix: "94-DF-58",vendor: "IJ Electron CO.,Ltd."},
  {max_prefix: "48-D5-4C",vendor: "Jeda Networks"},
  {max_prefix: "1C-BB-A8",vendor: "OJSC Ufimskiy Zavod Promsvyaz"},
  {max_prefix: "70-D6-B6",vendor: "Metrum Technologies"},
  {max_prefix: "C0-A0-DE",vendor: "Multi Touch Oy"},
  {max_prefix: "8C-0C-A3",vendor: "Amper"},
  {max_prefix: "48-E1-AF",vendor: "Vity"},
  {max_prefix: "BC-B8-52",vendor: "Cybera, Inc."},
  {max_prefix: "C4-93-00",vendor: "8Devices"},
  {max_prefix: "6C-A6-82",vendor: "EDAM information & communications"},
  {max_prefix: "28-D5-76",vendor: "Premier Wireless, Inc."},
  {max_prefix: "F0-DA-7C",vendor: "RLH INDUSTRIES,INC."},
  {max_prefix: "AC-31-9D",vendor: "Shenzhen TG-NET Botone Technology Co.,Ltd."},
  {max_prefix: "24-86-F4",vendor: "Ctek, Inc."},
  {max_prefix: "C4-23-7A",vendor: "WhizNets Inc."},
  {max_prefix: "90-3C-AE",vendor: "Yunnan KSEC Digital Technology Co.,Ltd."},
  {max_prefix: "70-70-4C",vendor: "Purple Communications, Inc"},
  {max_prefix: "D8-97-60",vendor: "C2 Development, Inc."},
  {max_prefix: "8C-DE-52",vendor: "ISSC Technologies Corp."},
  {max_prefix: "08-25-22",vendor: "ADVANSEE"},
  {max_prefix: "4C-2F-9D",vendor: "ICM Controls"},
  {max_prefix: "50-FC-30",vendor: "Treehouse Labs"},
  {max_prefix: "E4-67-BA",vendor: "Danish Interpretation Systems A/S"},
  {max_prefix: "9C-A1-34",vendor: "Nike, Inc."},
  {max_prefix: "40-BC-8B",vendor: "itelio GmbH"},
  {max_prefix: "F4-7A-CC",vendor: "SolidFire, Inc."},
  {max_prefix: "90-56-82",vendor: "Lenbrook Industries Limited"},
  {max_prefix: "C0-91-32",vendor: "Patriot Memory"},
  {max_prefix: "90-18-5E",vendor: "Apex Tool Group GmbH & Co OHG"},
  {max_prefix: "F8-FE-5C",vendor: "Reciprocal Labs Corp"},
  {max_prefix: "6C-9C-ED",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-6B-A0",vendor: "SHENZHEN UNIVERSAL INTELLISYS PTE LTD"},
  {max_prefix: "A8-98-C6",vendor: "Shinbo Co., Ltd."},
  {max_prefix: "B4-21-1D",vendor: "Beijing GuangXin Technology Co., Ltd"},
  {max_prefix: "E8-43-B6",vendor: "QNAP Systems, Inc."},
  {max_prefix: "E0-DA-DC",vendor: "JVC KENWOOD Corporation"},
  {max_prefix: "B8-9B-C9",vendor: "SMC Networks Inc"},
  {max_prefix: "D4-D2-49",vendor: "Power Ethernet"},
  {max_prefix: "80-42-7C",vendor: "Adolf Tedsen GmbH & Co. KG"},
  {max_prefix: "40-9F-C7",vendor: "BAEKCHUN I&C Co., Ltd."},
  {max_prefix: "00-FC-58",vendor: "WebSilicon Ltd."},
  {max_prefix: "98-35-71",vendor: "Sub10 Systems Ltd"},
  {max_prefix: "F4-E6-D7",vendor: "Solar Power Technologies, Inc."},
  {max_prefix: "20-10-7A",vendor: "Gemtek Technology Co., Ltd."},
  {max_prefix: "78-DD-D6",vendor: "c-scape"},
  {max_prefix: "F8-2F-5B",vendor: "eGauge Systems LLC"},
  {max_prefix: "E4-AF-A1",vendor: "HES-SO"},
  {max_prefix: "A8-87-ED",vendor: "ARC Wireless LLC"},
  {max_prefix: "18-6D-99",vendor: "Adanis Inc."},
  {max_prefix: "A0-E2-01",vendor: "AVTrace Ltd.(China)"},
  {max_prefix: "9C-F6-7D",vendor: "Ricardo Prague, s.r.o."},
  {max_prefix: "98-90-80",vendor: "Linkpower Network System Inc Ltd."},
  {max_prefix: "B8-74-24",vendor: "Viessmann Elektronik GmbH"},
  {max_prefix: "B4-51-F9",vendor: "NB Software"},
  {max_prefix: "30-16-8D",vendor: "ProLon"},
  {max_prefix: "4C-02-89",vendor: "LEX COMPUTECH CO., LTD"},
  {max_prefix: "C0-E5-4E",vendor: "ARIES Embedded GmbH"},
  {max_prefix: "50-F6-1A",vendor: "Kunshan JADE Technologies co., Ltd."},
  {max_prefix: "54-20-18",vendor: "Tely Labs"},
  {max_prefix: "58-1F-EF",vendor: "Tuttnaer LTD"},
  {max_prefix: "58-BD-A3",vendor: "Nintendo Co., Ltd."},
  {max_prefix: "18-7C-81",vendor: "Valeo Vision Systems"},
  {max_prefix: "AC-CC-8E",vendor: "Axis Communications AB"},
  {max_prefix: "BC-76-4E",vendor: "Rackspace US, Inc."},
  {max_prefix: "54-04-A6",vendor: "ASUSTek COMPUTER INC."},
  {max_prefix: "F8-33-76",vendor: "Good Mind Innovation Co., Ltd."},
  {max_prefix: "98-C8-45",vendor: "PacketAccess"},
  {max_prefix: "CC-C8-D7",vendor: "CIAS Elettronica srl"},
  {max_prefix: "84-D3-2A",vendor: "IEEE 1905.1"},
  {max_prefix: "8C-94-CF",vendor: "Encell Technology, Inc."},
  {max_prefix: "F8-F2-5A",vendor: "G-Lab GmbH"},
  {max_prefix: "F0-DE-B9",vendor: "ShangHai Y&Y Electronics Co., Ltd"},
  {max_prefix: "7C-A6-1D",vendor: "MHL, LLC"},
  {max_prefix: "30-57-AC",vendor: "IRLAB LTD."},
  {max_prefix: "84-2B-50",vendor: "Huria Co.,Ltd."},
  {max_prefix: "B8-BB-6D",vendor: "ENERES Co.,Ltd."},
  {max_prefix: "14-37-3B",vendor: "PROCOM Systems"},
  {max_prefix: "18-97-FF",vendor: "TechFaith Wireless Technology Limited"},
  {max_prefix: "4C-55-85",vendor: "Hamilton Systems"},
  {max_prefix: "8C-8E-76",vendor: "taskit GmbH"},
  {max_prefix: "A0-13-3B",vendor: "HiTi Digital, Inc."},
  {max_prefix: "48-F7-F1",vendor: "Nokia"},
  {max_prefix: "9C-57-11",vendor: "Feitian Xunda(Beijing) Aeronautical Information Technology Co., Ltd."},
  {max_prefix: "88-F4-88",vendor: "cellon communications technology(shenzhen)Co.,Ltd."},
  {max_prefix: "44-8E-12",vendor: "DT Research, Inc."},
  {max_prefix: "70-31-87",vendor: "ACX GmbH"},
  {max_prefix: "80-97-1B",vendor: "Altenergy Power System,Inc."},
  {max_prefix: "30-E4-DB",vendor: "Cisco Systems, Inc"},
  {max_prefix: "EC-EA-03",vendor: "DARFON LIGHTING CORP"},
  {max_prefix: "6C-A7-80",vendor: "Nokia Corporation"},
  {max_prefix: "58-2E-FE",vendor: "Lighting Science Group"},
  {max_prefix: "30-F9-ED",vendor: "Sony Corporation"},
  {max_prefix: "EC-F2-36",vendor: "NEOMONTANA ELECTRONICS"},
  {max_prefix: "04-18-B6",vendor: "Private"},
  {max_prefix: "E4-A5-EF",vendor: "TRON LINK ELECTRONICS CO., LTD."},
  {max_prefix: "70-5C-AD",vendor: "Konami Gaming Inc"},
  {max_prefix: "80-47-31",vendor: "Packet Design, Inc."},
  {max_prefix: "F0-8B-FE",vendor: "COSTEL.,CO.LTD"},
  {max_prefix: "30-71-B2",vendor: "Hangzhou Prevail Optoelectronic Equipment Co.,LTD."},
  {max_prefix: "3C-6F-45",vendor: "Fiberpro Inc."},
  {max_prefix: "DC-CE-41",vendor: "FE GLOBAL HONG KONG LIMITED"},
  {max_prefix: "FC-6C-31",vendor: "LXinstruments GmbH"},
  {max_prefix: "B0-9B-D4",vendor: "GNH Software India Private Limited"},
  {max_prefix: "00-7F-28",vendor: "Actiontec Electronics, Inc"},
  {max_prefix: "CC-60-BB",vendor: "Empower RF Systems"},
  {max_prefix: "7C-DD-20",vendor: "IOXOS Technologies S.A."},
  {max_prefix: "E8-4E-06",vendor: "EDUP INTERNATIONAL (HK) CO., LTD"},
  {max_prefix: "00-07-7D",vendor: "Cisco Systems, Inc"},
  {max_prefix: "E0-C9-22",vendor: "Jireh Energy Tech., Ltd."},
  {max_prefix: "90-5F-8D",vendor: "modas GmbH"},
  {max_prefix: "98-29-3F",vendor: "Fujian Start Computer Equipment Co.,Ltd"},
  {max_prefix: "0C-51-F7",vendor: "CHAUVIN ARNOUX"},
  {max_prefix: "0C-FC-83",vendor: "Airoha Technology Corp.,"},
  {max_prefix: "58-76-75",vendor: "Beijing ECHO Technologies Co.,Ltd"},
  {max_prefix: "CC-D9-E9",vendor: "SCR Engineers Ltd."},
  {max_prefix: "34-A7-09",vendor: "Trevil srl"},
  {max_prefix: "3C-26-D5",vendor: "Sotera Wireless"},
  {max_prefix: "AC-C9-35",vendor: "Ness Corporation"},
  {max_prefix: "00-8D-4E",vendor: "CJSC NII STT"},
  {max_prefix: "44-4F-5E",vendor: "Pan Studios Co.,Ltd."},
  {max_prefix: "D0-AF-B6",vendor: "Linktop Technology Co., LTD"},
  {max_prefix: "98-EC-65",vendor: "Cosesy ApS"},
  {max_prefix: "4C-98-EF",vendor: "Zeo"},
  {max_prefix: "00-A1-DE",vendor: "ShenZhen ShiHua Technology CO.,LTD"},
  {max_prefix: "90-8D-1D",vendor: "GH Technologies"},
  {max_prefix: "80-6C-BC",vendor: "NET New Electronic Technology GmbH"},
  {max_prefix: "58-E8-08",vendor: "AUTONICS CORPORATION"},
  {max_prefix: "DC-05-ED",vendor: "Nabtesco  Corporation"},
  {max_prefix: "98-F8-DB",vendor: "Marini Impianti Industriali s.r.l."},
  {max_prefix: "90-90-60",vendor: "RSI VIDEO TECHNOLOGIES"},
  {max_prefix: "BC-81-99",vendor: "BASIC Co.,Ltd."},
  {max_prefix: "E0-F2-11",vendor: "Digitalwatt"},
  {max_prefix: "B4-5C-A4",vendor: "Thing-talk Wireless Communication Technologies Corporation Limited"},
  {max_prefix: "DC-A7-D9",vendor: "Compressor Controls Corp"},
  {max_prefix: "38-FE-C5",vendor: "Ellips B.V."},
  {max_prefix: "C4-55-A6",vendor: "Cadac Holdings Ltd"},
  {max_prefix: "5C-77-57",vendor: "Haivision Network Video"},
  {max_prefix: "D4-D8-98",vendor: "Korea CNO Tech Co., Ltd"},
  {max_prefix: "B4-AA-4D",vendor: "Ensequence, Inc."},
  {max_prefix: "04-0A-83",vendor: "Alcatel-Lucent"},
  {max_prefix: "B8-3D-4E",vendor: "Shenzhen Cultraview Digital Technology Co.,Ltd Shanghai Branch"},
  {max_prefix: "50-87-B8",vendor: "Nuvyyo Inc"},
  {max_prefix: "C0-EA-E4",vendor: "Sonicwall"},
  {max_prefix: "08-38-A5",vendor: "Funkwerk plettac electronic GmbH"},
  {max_prefix: "B0-A7-2A",vendor: "Ensemble Designs, Inc."},
  {max_prefix: "64-00-F1",vendor: "Cisco Systems, Inc"},
  {max_prefix: "F8-69-71",vendor: "Seibu Electric Co.,"},
  {max_prefix: "44-AA-27",vendor: "udworks Co., Ltd."},
  {max_prefix: "CC-1E-FF",vendor: "Metrological Group BV"},
  {max_prefix: "18-4E-94",vendor: "MESSOA TECHNOLOGIES INC."},
  {max_prefix: "60-F5-9C",vendor: "CRU-Dataport"},
  {max_prefix: "6C-39-1D",vendor: "Beijing ZhongHuaHun Network Information center"},
  {max_prefix: "80-B3-2A",vendor: "UK Grid Solutions Ltd"},
  {max_prefix: "40-55-39",vendor: "Cisco Systems, Inc"},
  {max_prefix: "C4-56-00",vendor: "Galleon Embedded Computing"},
  {max_prefix: "58-EE-CE",vendor: "Icon Time Systems"},
  {max_prefix: "64-7F-DA",vendor: "TEKTELIC Communications Inc."},
  {max_prefix: "AC-06-13",vendor: "Senselogix Ltd"},
  {max_prefix: "74-78-18",vendor: "Jurumani Solutions"},
  {max_prefix: "B8-F4-D0",vendor: "Herrmann Ultraschalltechnik GmbH & Co. Kg"},
  {max_prefix: "08-AC-A5",vendor: "Benu Video, Inc."},
  {max_prefix: "58-6D-8F",vendor: "Cisco-Linksys, LLC"},
  {max_prefix: "10-E3-C7",vendor: "Seohwa Telecom"},
  {max_prefix: "FC-F1-CD",vendor: "OPTEX-FA CO.,LTD."},
  {max_prefix: "44-25-BB",vendor: "Bamboo Entertainment Corporation"},
  {max_prefix: "E0-0C-7F",vendor: "Nintendo Co., Ltd."},
  {max_prefix: "1C-95-5D",vendor: "I-LAX ELECTRONICS INC."},
  {max_prefix: "74-65-D1",vendor: "Atlinks"},
  {max_prefix: "E4-8A-D5",vendor: "RF WINDOW CO., LTD."},
  {max_prefix: "44-37-19",vendor: "2 Save Energy Ltd"},
  {max_prefix: "84-EA-99",vendor: "Vieworks"},
  {max_prefix: "BC-3E-13",vendor: "Accordance Systems Inc."},
  {max_prefix: "04-1D-10",vendor: "Dream Ware Inc."},
  {max_prefix: "80-14-40",vendor: "Sunlit System Technology Corp"},
  {max_prefix: "88-DD-79",vendor: "Voltaire"},
  {max_prefix: "64-F9-87",vendor: "Avvasi Inc."},
  {max_prefix: "90-2E-87",vendor: "LabJack"},
  {max_prefix: "DC-07-C1",vendor: "HangZhou QiYang Technology Co.,Ltd."},
  {max_prefix: "A8-1B-18",vendor: "XTS CORP"},
  {max_prefix: "D0-A3-11",vendor: "Neuberger Geb\u00e4udeautomation GmbH"},
  {max_prefix: "A4-24-B3",vendor: "FlatFrog Laboratories AB"},
  {max_prefix: "94-CD-AC",vendor: "Creowave Oy"},
  {max_prefix: "7C-DA-84",vendor: "Dongnian Networks Inc."},
  {max_prefix: "C0-A2-6D",vendor: "Abbott Point of Care"},
  {max_prefix: "00-BB-8E",vendor: "HME Co., Ltd."},
  {max_prefix: "D8-2A-7E",vendor: "Nokia Corporation"},
  {max_prefix: "80-1F-02",vendor: "Edimax Technology Co. Ltd."},
  {max_prefix: "18-0B-52",vendor: "Nanotron Technologies GmbH"},
  {max_prefix: "14-4C-1A",vendor: "Max Communication GmbH"},
  {max_prefix: "D8-5D-84",vendor: "CAx soft GmbH"},
  {max_prefix: "D4-E3-2C",vendor: "S. Siedle & Sohne"},
  {max_prefix: "7C-6A-DB",vendor: "SafeTone Technology Co.,Ltd"},
  {max_prefix: "F0-5D-89",vendor: "Dycon Limited"},
  {max_prefix: "9C-F9-38",vendor: "AREVA NP GmbH"},
  {max_prefix: "8C-DB-25",vendor: "ESG Solutions"},
  {max_prefix: "BC-C6-1A",vendor: "SPECTRA EMBEDDED SYSTEMS"},
  {max_prefix: "04-70-BC",vendor: "Globalstar Inc."},
  {max_prefix: "98-8E-34",vendor: "ZHEJIANG BOXSAM ELECTRONIC CO.,LTD"},
  {max_prefix: "C4-71-FE",vendor: "Cisco Systems, Inc"},
  {max_prefix: "34-08-04",vendor: "D-Link Corporation"},
  {max_prefix: "FC-35-98",vendor: "Favite Inc."},
  {max_prefix: "90-D9-2C",vendor: "HUG-WITSCHI AG"},
  {max_prefix: "40-22-ED",vendor: "Digital Projection Ltd"},
  {max_prefix: "6C-2E-33",vendor: "Accelink Technologies Co.,Ltd."},
  {max_prefix: "98-94-49",vendor: "Skyworth Wireless Technology Ltd."},
  {max_prefix: "2C-A1-57",vendor: "acromate, Inc."},
  {max_prefix: "94-20-53",vendor: "Nokia Corporation"},
  {max_prefix: "28-85-2D",vendor: "Touch Networks"},
  {max_prefix: "48-6B-91",vendor: "Fleetwood Group Inc."},
  {max_prefix: "64-34-09",vendor: "BITwave Pte Ltd"},
  {max_prefix: "74-CD-0C",vendor: "Smith Myers Communications Ltd."},
  {max_prefix: "CC-CE-40",vendor: "Janteq Corp"},
  {max_prefix: "B8-EE-79",vendor: "YWire Technologies, Inc."},
  {max_prefix: "28-ED-58",vendor: "JAG Jakob AG"},
  {max_prefix: "B8-79-7E",vendor: "Secure Meters (UK) Limited"},
  {max_prefix: "20-05-E8",vendor: "OOO InProMedia"},
  {max_prefix: "00-06-F6",vendor: "Cisco Systems, Inc"},
  {max_prefix: "74-7D-B6",vendor: "Aliwei Communications, Inc"},
  {max_prefix: "B0-65-63",vendor: "Shanghai Railway Communication Factory"},
  {max_prefix: "AC-6F-4F",vendor: "Enspert Inc"},
  {max_prefix: "E8-28-77",vendor: "TMY Co., Ltd."},
  {max_prefix: "F0-C8-8C",vendor: "LeddarTech Inc."},
  {max_prefix: "B0-B3-2B",vendor: "Slican Sp. z o.o."},
  {max_prefix: "58-42-E4",vendor: "Baxter International Inc"},
  {max_prefix: "CC-9E-00",vendor: "Nintendo Co., Ltd."},
  {max_prefix: "58-A7-6F",vendor: "iD corporation"},
  {max_prefix: "40-98-7B",vendor: "Aisino Corporation"},
  {max_prefix: "BC-38-D2",vendor: "Pandachip Limited"},
  {max_prefix: "B8-BA-68",vendor: "Xi'an Jizhong Digital Communication Co.,Ltd"},
  {max_prefix: "40-18-D7",vendor: "Smartronix, Inc."},
  {max_prefix: "4C-2C-80",vendor: "Beijing Skyway Technologies Co.,Ltd"},
  {max_prefix: "D4-9E-6D",vendor: "Wuhan Zhongyuan Huadian Science & Technology Co.,"},
  {max_prefix: "40-37-AD",vendor: "Macro Image Technology, Inc."},
  {max_prefix: "F0-02-48",vendor: "SmarteBuilding"},
  {max_prefix: "40-C2-45",vendor: "Shenzhen Hexicom Technology Co., Ltd."},
  {max_prefix: "CC-55-AD",vendor: "RIM"},
  {max_prefix: "E8-75-7F",vendor: "FIRS Technologies(Shenzhen) Co., Ltd"},
  {max_prefix: "F0-F7-B3",vendor: "Phorm"},
  {max_prefix: "60-63-FD",vendor: "Transcend Communication Beijing Co.,Ltd."},
  {max_prefix: "74-BE-08",vendor: "ATEK Products, LLC"},
  {max_prefix: "74-D6-75",vendor: "WYMA Tecnologia"},
  {max_prefix: "B4-0E-DC",vendor: "LG-Ericsson Co.,Ltd."},
  {max_prefix: "E0-EE-1B",vendor: "Panasonic Automotive Systems Company of America"},
  {max_prefix: "60-89-3C",vendor: "Thermo Fisher Scientific P.O.A."},
  {max_prefix: "D8-6B-F7",vendor: "Nintendo Co., Ltd."},
  {max_prefix: "00-D3-8D",vendor: "Hotel Technology Next Generation"},
  {max_prefix: "C8-3E-A7",vendor: "KUNBUS GmbH"},
  {max_prefix: "D8-B6-C1",vendor: "NetworkAccountant, Inc."},
  {max_prefix: "74-A4-A7",vendor: "QRS Music Technologies, Inc."},
  {max_prefix: "40-CD-3A",vendor: "Z3 Technology"},
  {max_prefix: "48-2C-EA",vendor: "Motorola Inc Business Light Radios"},
  {max_prefix: "F4-55-E0",vendor: "Niceway CNC Technology Co.,Ltd.Hunan Province"},
  {max_prefix: "20-FD-F1",vendor: "3COM EUROPE LTD"},
  {max_prefix: "A4-21-8A",vendor: "Nortel Networks"},
  {max_prefix: "EC-23-68",vendor: "IntelliVoice Co.,Ltd."},
  {max_prefix: "74-90-50",vendor: "Renesas Electronics Corporation"},
  {max_prefix: "7C-EF-18",vendor: "Creative Product Design Pty. Ltd."},
  {max_prefix: "90-88-A2",vendor: "IONICS TECHNOLOGY ME LTDA"},
  {max_prefix: "00-33-6C",vendor: "SynapSense Corporation"},
  {max_prefix: "10-0C-24",vendor: "pomdevices, LLC"},
  {max_prefix: "58-F6-BF",vendor: "Kyoto University"},
  {max_prefix: "AC-61-23",vendor: "Drivven, Inc."},
  {max_prefix: "F8-66-F2",vendor: "Cisco Systems, Inc"},
  {max_prefix: "AC-34-CB",vendor: "Shanhai GBCOM Communication Technology Co. Ltd"},
  {max_prefix: "70-02-58",vendor: "01DB-METRAVIB"},
  {max_prefix: "84-97-B8",vendor: "Memjet Inc."},
  {max_prefix: "A0-DD-E5",vendor: "SHARP Corporation"},
  {max_prefix: "20-6A-FF",vendor: "Atlas Elektronik UK Limited"},
  {max_prefix: "FC-75-E6",vendor: "Handreamnet"},
  {max_prefix: "0C-15-C5",vendor: "SDTEC Co., Ltd."},
  {max_prefix: "F8-FB-2F",vendor: "Santur Corporation"},
  {max_prefix: "2C-CD-43",vendor: "Summit Technology Group"},
  {max_prefix: "14-54-12",vendor: "Entis Co., Ltd."},
  {max_prefix: "78-EC-22",vendor: "Shanghai Qihui Telecom Technology Co., LTD"},
  {max_prefix: "64-99-5D",vendor: "LGE"},
  {max_prefix: "FC-7C-E7",vendor: "FCI USA LLC"},
  {max_prefix: "3C-10-6F",vendor: "ALBAHITH TECHNOLOGIES"},
  {max_prefix: "D8-71-57",vendor: "Lenovo Mobile Communication Technology Ltd."},
  {max_prefix: "C0-0D-7E",vendor: "Additech, Inc."},
  {max_prefix: "84-C7-A9",vendor: "C3PO S.A."},
  {max_prefix: "60-9A-A4",vendor: "GVI SECURITY INC."},
  {max_prefix: "64-10-84",vendor: "HEXIUM Technical Development Co., Ltd."},
  {max_prefix: "6C-62-6D",vendor: "Micro-Star INT'L CO., LTD"},
  {max_prefix: "28-06-8D",vendor: "ITL, LLC"},
  {max_prefix: "34-21-09",vendor: "Jensen Scandinavia AS"},
  {max_prefix: "7C-3E-9D",vendor: "PATECH"},
  {max_prefix: "24-45-97",vendor: "GEMUE Gebr. Mueller Apparatebau"},
  {max_prefix: "78-81-8F",vendor: "Server Racks Australia Pty Ltd"},
  {max_prefix: "28-48-46",vendor: "GridCentric Inc."},
  {max_prefix: "30-69-4B",vendor: "RIM"},
  {max_prefix: "10-CC-DB",vendor: "AXIMUM PRODUITS ELECTRONIQUES"},
  {max_prefix: "38-C7-BA",vendor: "CS Services Co.,Ltd."},
  {max_prefix: "EC-5C-69",vendor: "MITSUBISHI HEAVY INDUSTRIES MECHATRONICS SYSTEMS,LTD."},
  {max_prefix: "E4-AD-7D",vendor: "SCL Elements"},
  {max_prefix: "F0-9C-BB",vendor: "RaonThink Inc."},
  {max_prefix: "80-EE-73",vendor: "Shuttle Inc."},
  {max_prefix: "FC-E2-3F",vendor: "CLAY PAKY SPA"},
  {max_prefix: "58-57-0D",vendor: "Danfoss Solar Inverters"},
  {max_prefix: "44-A8-C2",vendor: "SEWOO TECH CO., LTD"},
  {max_prefix: "BC-A9-D6",vendor: "Cyber-Rain, Inc."},
  {max_prefix: "EC-FE-7E",vendor: "BlueRadios, Inc."},
  {max_prefix: "C4-82-3F",vendor: "Fujian Newland Auto-ID Tech. Co,.Ltd."},
  {max_prefix: "E8-5B-5B",vendor: "LG ELECTRONICS INC"},
  {max_prefix: "7C-2C-F3",vendor: "Secure Electrans Ltd"},
  {max_prefix: "08-16-51",vendor: "SHENZHEN SEA STAR TECHNOLOGY CO.,LTD"},
  {max_prefix: "30-41-74",vendor: "ALTEC LANSING LLC"},
  {max_prefix: "3C-39-C3",vendor: "JW Electronics Co., Ltd."},
  {max_prefix: "3C-05-AB",vendor: "Product Creation Studio"},
  {max_prefix: "30-EF-D1",vendor: "Alstom Strongwish (Shenzhen) Co., Ltd."},
  {max_prefix: "D4-1F-0C",vendor: "JAI Manufacturing"},
  {max_prefix: "8C-D6-28",vendor: "Ikor Metering"},
  {max_prefix: "24-3C-20",vendor: "Dynamode Group"},
  {max_prefix: "48-1B-D2",vendor: "Intron Scientific co., ltd."},
  {max_prefix: "F0-43-35",vendor: "DVN(Shanghai)Ltd."},
  {max_prefix: "A4-79-E4",vendor: "KLINFO Corp"},
  {max_prefix: "8C-54-1D",vendor: "LGE"},
  {max_prefix: "00-A2-DA",vendor: "INAT GmbH"},
  {max_prefix: "6C-3E-9C",vendor: "KE Knestel Elektronik GmbH"},
  {max_prefix: "A8-63-DF",vendor: "DISPLAIRE CORPORATION"},
  {max_prefix: "18-3B-D2",vendor: "BYD Precision Manufacture Company Ltd."},
  {max_prefix: "4C-C6-02",vendor: "Radios, Inc."},
  {max_prefix: "D0-F0-DB",vendor: "Ericsson"},
  {max_prefix: "7C-14-76",vendor: "Damall Technologies SAS"},
  {max_prefix: "00-3C-C5",vendor: "WONWOO Engineering Co., Ltd"},
  {max_prefix: "F0-77-D0",vendor: "Xcellen"},
  {max_prefix: "88-4B-39",vendor: "Siemens AG, Healthcare Sector"},
  {max_prefix: "D8-28-C9",vendor: "General Electric Consumer and Industrial"},
  {max_prefix: "44-C2-33",vendor: "Guangzhou Comet Technology Development Co.Ltd"},
  {max_prefix: "E4-35-93",vendor: "Hangzhou GoTo technology Co.Ltd"},
  {max_prefix: "2C-3A-28",vendor: "Fagor Electr\u00f3nica"},
  {max_prefix: "80-B2-89",vendor: "Forworld Electronics Ltd."},
  {max_prefix: "E8-3A-97",vendor: "Toshiba Corporation"},
  {max_prefix: "10-56-CA",vendor: "Peplink International Ltd."},
  {max_prefix: "D4-9C-28",vendor: "JayBird LLC"},
  {max_prefix: "80-F5-93",vendor: "IRCO Sistemas de Telecomunicaci\u00f3n S.A."},
  {max_prefix: "EC-DE-3D",vendor: "Lamprey Networks, Inc."},
  {max_prefix: "6C-FF-BE",vendor: "MPB Communications Inc."},
  {max_prefix: "E4-97-F0",vendor: "Shanghai VLC Technologies Ltd. Co."},
  {max_prefix: "B4-08-32",vendor: "TC Communications"},
  {max_prefix: "88-FD-15",vendor: "LINEEYE CO., LTD"},
  {max_prefix: "24-DB-AD",vendor: "ShopperTrak RCT Corporation"},
  {max_prefix: "28-72-C5",vendor: "Smartmatic Corp"},
  {max_prefix: "48-6F-D2",vendor: "StorSimple Inc"},
  {max_prefix: "A0-3A-75",vendor: "PSS Belgium N.V."},
  {max_prefix: "B4-58-61",vendor: "CRemote, LLC"},
  {max_prefix: "B0-97-3A",vendor: "E-Fuel Corporation"},
  {max_prefix: "20-4E-6B",vendor: "Axxana(israel) ltd"},
  {max_prefix: "9C-EB-E8",vendor: "BizLink (Kunshan) Co.,Ltd"},
  {max_prefix: "F0-62-81",vendor: "ProCurve Networking by HP"},
  {max_prefix: "C0-9C-92",vendor: "COBY"},
  {max_prefix: "88-ED-1C",vendor: "Cudo Communication Co., Ltd."},
  {max_prefix: "9C-CD-82",vendor: "CHENG UEI PRECISION INDUSTRY CO.,LTD"},
  {max_prefix: "04-0E-C2",vendor: "ViewSonic Mobile China Limited"},
  {max_prefix: "C8-D1-D1",vendor: "AGAiT Technology Corporation"},
  {max_prefix: "00-DB-45",vendor: "THAMWAY CO.,LTD."},
  {max_prefix: "74-F7-26",vendor: "Neuron Robotics"},
  {max_prefix: "C0-38-F9",vendor: "Nokia Danmark A/S"},
  {max_prefix: "F4-63-49",vendor: "Diffon Corporation"},
  {max_prefix: "00-3A-9C",vendor: "Cisco Systems, Inc"},
  {max_prefix: "88-98-21",vendor: "TERAON"},
  {max_prefix: "E0-E7-51",vendor: "Nintendo Co., Ltd."},
  {max_prefix: "74-F0-7D",vendor: "BnCOM Co.,Ltd"},
  {max_prefix: "7C-6C-8F",vendor: "AMS NEVE LTD"},
  {max_prefix: "9C-B2-06",vendor: "HMS Industrial Networks"},
  {max_prefix: "AC-E9-AA",vendor: "Hay Systems Ltd"},
  {max_prefix: "08-2A-D0",vendor: "SRD Innovations Inc."},
  {max_prefix: "E0-8F-EC",vendor: "REPOTEC CO., LTD."},
  {max_prefix: "F8-52-DF",vendor: "VNL Europe AB"},
  {max_prefix: "00-3A-AF",vendor: "BlueBit Ltd."},
  {max_prefix: "64-16-8D",vendor: "Cisco Systems, Inc"},
  {max_prefix: "D8-C3-FB",vendor: "DETRACOM"},
  {max_prefix: "A8-CB-95",vendor: "EAST BEST CO., LTD."},
  {max_prefix: "F4-5F-F7",vendor: "DQ Technology Inc."},
  {max_prefix: "7C-3B-D5",vendor: "Imago Group"},
  {max_prefix: "5C-E2-23",vendor: "Delphin Technology AG"},
  {max_prefix: "F8-71-FE",vendor: "The Goldman Sachs Group, Inc."},
  {max_prefix: "2C-19-84",vendor: "IDN Telecom, Inc."},
  {max_prefix: "40-EF-4C",vendor: "Fihonest communication co.,Ltd"},
  {max_prefix: "00-27-1E",vendor: "Xagyl Communications"},
  {max_prefix: "00-27-1D",vendor: "Comba Telecom Systems (China) Ltd."},
  {max_prefix: "00-27-20",vendor: "NEW-SOL COM"},
  {max_prefix: "64-4F-74",vendor: "LENUS Co., Ltd."},
  {max_prefix: "78-7F-62",vendor: "GiK mbH"},
  {max_prefix: "58-F6-7B",vendor: "Xia Men UnionCore Technology LTD."},
  {max_prefix: "40-15-97",vendor: "Protect America, Inc."},
  {max_prefix: "C4-FC-E4",vendor: "DishTV NZ Ltd"},
  {max_prefix: "EC-6C-9F",vendor: "Chengdu Volans Technology CO.,LTD"},
  {max_prefix: "E8-0B-13",vendor: "Akib Systems Taiwan, INC"},
  {max_prefix: "00-26-F7",vendor: "Nivetti Systems Pvt. Ltd."},
  {max_prefix: "00-26-F6",vendor: "Military Communication Institute"},
  {max_prefix: "00-26-F0",vendor: "cTrixs International GmbH."},
  {max_prefix: "00-26-EA",vendor: "Cheerchip Electronic Technology (ShangHai) Co., Ltd."},
  {max_prefix: "00-26-DD",vendor: "Fival Science & Technology Co.,Ltd."},
  {max_prefix: "00-26-DE",vendor: "FDI MATELEC"},
  {max_prefix: "00-26-DA",vendor: "Universal Media Corporation /Slovakia/ s.r.o."},
  {max_prefix: "00-26-DB",vendor: "Ionics EMS Inc."},
  {max_prefix: "00-26-C9",vendor: "Proventix Systems, Inc."},
  {max_prefix: "00-26-BF",vendor: "ShenZhen Temobi Science&Tech Development Co.,Ltd"},
  {max_prefix: "00-26-D5",vendor: "Ory Solucoes em Comercio de Informatica Ltda."},
  {max_prefix: "00-26-CE",vendor: "Kozumi USA Corp."},
  {max_prefix: "00-26-CA",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-27-08",vendor: "Nordiag ASA"},
  {max_prefix: "00-27-02",vendor: "SolarEdge Technologies"},
  {max_prefix: "00-26-FA",vendor: "BandRich Inc."},
  {max_prefix: "00-26-F9",vendor: "S.E.M. srl"},
  {max_prefix: "00-26-FD",vendor: "Interactive Intelligence"},
  {max_prefix: "00-26-B4",vendor: "Ford Motor Company"},
  {max_prefix: "00-26-AC",vendor: "Shanghai LUSTER Teraband photonic Co., Ltd."},
  {max_prefix: "00-26-A6",vendor: "TRIXELL"},
  {max_prefix: "00-27-11",vendor: "LanPro Inc"},
  {max_prefix: "00-26-8F",vendor: "MTA SpA"},
  {max_prefix: "00-26-86",vendor: "Quantenna Communcations, Inc."},
  {max_prefix: "00-26-84",vendor: "KISAN SYSTEM"},
  {max_prefix: "00-26-80",vendor: "SIL3 Pty.Ltd"},
  {max_prefix: "00-26-38",vendor: "Xia Men Joyatech Co., Ltd."},
  {max_prefix: "00-26-3A",vendor: "Digitec Systems"},
  {max_prefix: "00-26-35",vendor: "Bluetechnix GmbH"},
  {max_prefix: "00-26-17",vendor: "OEM Worldwide"},
  {max_prefix: "00-26-13",vendor: "Engel Axil S.L."},
  {max_prefix: "00-26-0F",vendor: "Linn Products Ltd"},
  {max_prefix: "00-26-0C",vendor: "Dataram"},
  {max_prefix: "00-26-20",vendor: "ISGUS GmbH"},
  {max_prefix: "00-26-1D",vendor: "COP SECURITY SYSTEM CORP."},
  {max_prefix: "00-26-2B",vendor: "Wongs Electronics Co. Ltd."},
  {max_prefix: "00-26-94",vendor: "Senscient Ltd"},
  {max_prefix: "00-26-90",vendor: "I DO IT"},
  {max_prefix: "00-26-79",vendor: "Euphonic Technologies, Inc."},
  {max_prefix: "00-26-76",vendor: "COMMidt AS"},
  {max_prefix: "00-26-66",vendor: "EFM Networks"},
  {max_prefix: "00-26-57",vendor: "OOO NPP EKRA"},
  {max_prefix: "00-26-52",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-25-F6",vendor: "netTALK.com, Inc."},
  {max_prefix: "00-25-F5",vendor: "DVS Korea, Co., Ltd"},
  {max_prefix: "00-25-EB",vendor: "Reutech Radar Systems (PTY) Ltd"},
  {max_prefix: "00-25-EE",vendor: "Avtex Ltd"},
  {max_prefix: "00-25-D6",vendor: "The Kroger Co."},
  {max_prefix: "00-25-D1",vendor: "Eastern Asia Technology Limited"},
  {max_prefix: "00-25-CD",vendor: "Skylane Optics"},
  {max_prefix: "00-25-8C",vendor: "ESUS ELEKTRONIK SAN. VE DIS. TIC. LTD. STI."},
  {max_prefix: "00-25-A5",vendor: "Walnut Media Network"},
  {max_prefix: "00-25-A4",vendor: "EuroDesign embedded technologies GmbH"},
  {max_prefix: "00-25-AE",vendor: "Microsoft Corporation"},
  {max_prefix: "00-25-AF",vendor: "COMFILE Technology"},
  {max_prefix: "00-25-A8",vendor: "Kontron (BeiJing) Technology Co.,Ltd"},
  {max_prefix: "00-26-01",vendor: "Cutera Inc"},
  {max_prefix: "00-25-BF",vendor: "Wireless Cables Inc."},
  {max_prefix: "00-25-B9",vendor: "Cypress Solutions Inc"},
  {max_prefix: "00-25-B6",vendor: "Telecom FM"},
  {max_prefix: "00-25-D9",vendor: "DataFab Systems Inc."},
  {max_prefix: "00-25-C1",vendor: "Nawoo Korea Corp."},
  {max_prefix: "00-25-7A",vendor: "CAMCO Produktions- und Vertriebs-GmbH f\u00fcr  Beschallungs- und Beleuchtungsanlagen"},
  {max_prefix: "00-25-76",vendor: "NELI TECHNOLOGIES"},
  {max_prefix: "00-25-74",vendor: "KUNIMI MEDIA DEVICE Co., Ltd."},
  {max_prefix: "00-25-73",vendor: "ST Electronics (Info-Security) Pte Ltd"},
  {max_prefix: "00-25-6F",vendor: "Dantherm Power"},
  {max_prefix: "00-25-54",vendor: "Pixel8 Networks"},
  {max_prefix: "00-25-5A",vendor: "Tantalus Systems Corp."},
  {max_prefix: "00-25-59",vendor: "Syphan Technologies Ltd"},
  {max_prefix: "00-25-30",vendor: "Aetas Systems Inc."},
  {max_prefix: "00-25-2C",vendor: "Entourage Systems, Inc."},
  {max_prefix: "00-25-2F",vendor: "Energy, Inc."},
  {max_prefix: "00-25-0F",vendor: "On-Ramp Wireless, Inc."},
  {max_prefix: "00-25-05",vendor: "eks Engel GmbH & Co. KG"},
  {max_prefix: "00-25-40",vendor: "Quasar Technologies, Inc."},
  {max_prefix: "00-25-33",vendor: "WITTENSTEIN AG"},
  {max_prefix: "00-25-87",vendor: "Vitality, Inc."},
  {max_prefix: "00-25-23",vendor: "OCP Inc."},
  {max_prefix: "00-25-1D",vendor: "DSA Encore, LLC"},
  {max_prefix: "00-25-0A",vendor: "Security Expert Co. Ltd"},
  {max_prefix: "00-25-09",vendor: "SHARETRONIC Group LTD"},
  {max_prefix: "00-24-D8",vendor: "IlSung Precision"},
  {max_prefix: "00-24-CD",vendor: "Willow Garage, Inc."},
  {max_prefix: "00-24-D3",vendor: "QUALICA Inc."},
  {max_prefix: "00-24-CE",vendor: "Exeltech Inc"},
  {max_prefix: "00-24-CF",vendor: "Inscape Data Corporation"},
  {max_prefix: "00-24-C6",vendor: "Hager Electro SAS"},
  {max_prefix: "00-24-B4",vendor: "ESCATRONIC GmbH"},
  {max_prefix: "00-24-B1",vendor: "Coulomb Technologies"},
  {max_prefix: "00-24-9C",vendor: "Bimeng Comunication System Co. Ltd"},
  {max_prefix: "00-24-98",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-24-FD",vendor: "Accedian Networks Inc"},
  {max_prefix: "00-24-F5",vendor: "NDS Surgical Imaging"},
  {max_prefix: "00-24-DA",vendor: "Innovar Systems Limited"},
  {max_prefix: "00-24-6A",vendor: "Solid Year Co., Ltd."},
  {max_prefix: "00-24-67",vendor: "AOC International (Europe) GmbH"},
  {max_prefix: "00-24-8B",vendor: "HYBUS CO., LTD."},
  {max_prefix: "00-24-92",vendor: "Motorola, Broadband Solutions Group"},
  {max_prefix: "00-24-84",vendor: "Bang and Olufsen Medicom a/s"},
  {max_prefix: "00-24-52",vendor: "Silicon Software GmbH"},
  {max_prefix: "00-24-53",vendor: "Initra d.o.o."},
  {max_prefix: "00-24-4C",vendor: "Solartron Metrology Ltd"},
  {max_prefix: "00-24-48",vendor: "SpiderCloud Wireless, Inc"},
  {max_prefix: "00-24-4B",vendor: "PERCEPTRON INC"},
  {max_prefix: "00-24-2A",vendor: "Hittite Microwave Corporation"},
  {max_prefix: "00-24-22",vendor: "Knapp Logistik Automation GmbH"},
  {max_prefix: "00-24-1B",vendor: "iWOW Communications Pte Ltd"},
  {max_prefix: "00-24-28",vendor: "EnergyICT"},
  {max_prefix: "00-24-17",vendor: "Thomson Telecom Belgium"},
  {max_prefix: "00-24-7A",vendor: "FU YI CHENG Technology Co., Ltd."},
  {max_prefix: "00-24-76",vendor: "TAP.tv"},
  {max_prefix: "00-24-6D",vendor: "Weinzierl Engineering GmbH"},
  {max_prefix: "00-24-42",vendor: "Axona Limited"},
  {max_prefix: "00-24-3C",vendor: "S.A.A.A."},
  {max_prefix: "00-24-5E",vendor: "Hivision Co.,ltd"},
  {max_prefix: "00-24-4D",vendor: "Hokkaido Electronics Corporation"},
  {max_prefix: "00-24-16",vendor: "Any Use"},
  {max_prefix: "00-24-09",vendor: "The Toro Company"},
  {max_prefix: "00-24-06",vendor: "Pointmobile"},
  {max_prefix: "00-24-00",vendor: "Nortel Networks"},
  {max_prefix: "00-23-E1",vendor: "Cavena Image Products AB"},
  {max_prefix: "00-23-DC",vendor: "Benein, Inc"},
  {max_prefix: "00-23-D1",vendor: "TRG"},
  {max_prefix: "00-23-D3",vendor: "AirLink WiFi Networking Corp."},
  {max_prefix: "00-23-FB",vendor: "IP Datatel, LLC."},
  {max_prefix: "00-23-FE",vendor: "Biodevices, SA"},
  {max_prefix: "00-23-F4",vendor: "Masternaut"},
  {max_prefix: "00-23-CA",vendor: "Behind The Set, LLC"},
  {max_prefix: "00-23-CB",vendor: "Shenzhen Full-join Technology Co.,Ltd"},
  {max_prefix: "00-23-D0",vendor: "Uniloc USA Inc."},
  {max_prefix: "00-23-AD",vendor: "Xmark Corporation"},
  {max_prefix: "00-23-AB",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-23-A4",vendor: "New Concepts Development Corp."},
  {max_prefix: "00-23-85",vendor: "ANTIPODE"},
  {max_prefix: "00-23-C7",vendor: "AVSystem sp. z o. o."},
  {max_prefix: "00-23-C3",vendor: "LogMeIn, Inc."},
  {max_prefix: "00-23-B0",vendor: "COMXION Technology Inc."},
  {max_prefix: "00-23-03",vendor: "LITE-ON IT Corporation"},
  {max_prefix: "00-22-FE",vendor: "Advanced Illumination"},
  {max_prefix: "00-23-00",vendor: "Cayee Computer Ltd."},
  {max_prefix: "00-22-F3",vendor: "SHARP Corporation"},
  {max_prefix: "00-22-F6",vendor: "Syracuse Research Corporation"},
  {max_prefix: "00-23-2C",vendor: "Senticare"},
  {max_prefix: "00-23-2B",vendor: "IRD A/S"},
  {max_prefix: "00-23-2A",vendor: "eonas IT-Beratung und -Entwicklung GmbH"},
  {max_prefix: "00-23-27",vendor: "Shouyo Electronics CO., LTD"},
  {max_prefix: "00-23-28",vendor: "ALCON TELECOMMUNICATIONS CO., LTD."},
  {max_prefix: "00-23-72",vendor: "MORE STAR INDUSTRIAL GROUP LIMITED"},
  {max_prefix: "00-23-6D",vendor: "ResMed Ltd"},
  {max_prefix: "00-23-6B",vendor: "Xembedded, Inc."},
  {max_prefix: "00-23-7E",vendor: "ELSTER GMBH"},
  {max_prefix: "00-23-7C",vendor: "NEOTION"},
  {max_prefix: "00-23-7A",vendor: "RIM"},
  {max_prefix: "00-23-4C",vendor: "KTC AB"},
  {max_prefix: "00-23-43",vendor: "TEM AG"},
  {max_prefix: "00-23-36",vendor: "METEL s.r.o."},
  {max_prefix: "00-23-38",vendor: "OJ-Electronics A/S"},
  {max_prefix: "00-23-61",vendor: "Unigen Corporation"},
  {max_prefix: "00-23-5F",vendor: "Silicon Micro Sensors GmbH"},
  {max_prefix: "00-23-3B",vendor: "C-Matic Systems Ltd"},
  {max_prefix: "00-23-1A",vendor: "ITF Co., Ltd."},
  {max_prefix: "00-23-0D",vendor: "Nortel Networks"},
  {max_prefix: "00-22-ED",vendor: "TSI Power Corporation"},
  {max_prefix: "00-22-E1",vendor: "ZORT Labs, LLC."},
  {max_prefix: "00-22-E0",vendor: "Atlantic Software Technologies S.r.L."},
  {max_prefix: "00-22-DF",vendor: "TAMUZ Monitors"},
  {max_prefix: "00-22-D8",vendor: "Shenzhen GST Security and Safety Technology Limited"},
  {max_prefix: "00-22-DC",vendor: "Vigil Health Solutions Inc."},
  {max_prefix: "00-22-8C",vendor: "Photon Europe GmbH"},
  {max_prefix: "00-22-8B",vendor: "Kensington Computer Products Group"},
  {max_prefix: "00-22-8D",vendor: "GBS Laboratories LLC"},
  {max_prefix: "00-22-89",vendor: " Vanderlande APC inc."},
  {max_prefix: "00-22-6D",vendor: "Shenzhen GIEC Electronics Co., Ltd."},
  {max_prefix: "00-22-6E",vendor: "Gowell Electronic Limited"},
  {max_prefix: "00-22-5D",vendor: "Digicable Network India Pvt. Ltd."},
  {max_prefix: "00-22-56",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-22-D9",vendor: "Fortex Industrial Ltd."},
  {max_prefix: "00-22-D3",vendor: "Hub-Tech"},
  {max_prefix: "00-22-D4",vendor: "ComWorth Co., Ltd."},
  {max_prefix: "00-22-9F",vendor: "Sensys Traffic AB"},
  {max_prefix: "00-22-99",vendor: "SeaMicro Inc."},
  {max_prefix: "00-22-BC",vendor: "JDSU France SAS"},
  {max_prefix: "00-22-AA",vendor: "Nintendo Co., Ltd."},
  {max_prefix: "00-22-A8",vendor: "Ouman Oy"},
  {max_prefix: "00-22-C9",vendor: "Lenord, Bauer & Co GmbH"},
  {max_prefix: "00-22-7D",vendor: "YE DATA INC."},
  {max_prefix: "00-22-7B",vendor: "Apogee Labs, Inc."},
  {max_prefix: "00-22-23",vendor: "TimeKeeping Systems, Inc."},
  {max_prefix: "00-22-1A",vendor: "Audio Precision"},
  {max_prefix: "00-22-4B",vendor: "AIRTECH TECHNOLOGIES, INC."},
  {max_prefix: "00-22-3C",vendor: "RATIO Entwicklungen GmbH"},
  {max_prefix: "00-22-52",vendor: "ZOLL Lifecor Corporation"},
  {max_prefix: "00-22-4D",vendor: "MITAC INTERNATIONAL CORP."},
  {max_prefix: "00-22-4C",vendor: "Nintendo Co., Ltd."},
  {max_prefix: "00-22-4A",vendor: "RAYLASE AG"},
  {max_prefix: "00-21-E3",vendor: "SerialTek LLC"},
  {max_prefix: "00-21-E7",vendor: "Informatics Services Corporation"},
  {max_prefix: "00-21-DC",vendor: "TECNOALARM S.r.l."},
  {max_prefix: "00-21-F8",vendor: "Enseo, Inc."},
  {max_prefix: "00-21-F3",vendor: "Si14 SpA"},
  {max_prefix: "00-21-F1",vendor: "Tutus Data AB"},
  {max_prefix: "00-22-13",vendor: "PCI CORPORATION"},
  {max_prefix: "00-22-1C",vendor: "Private"},
  {max_prefix: "00-22-22",vendor: "Schaffner Deutschland GmbH"},
  {max_prefix: "00-22-31",vendor: "SMT&C Co., Ltd."},
  {max_prefix: "00-22-01",vendor: "Aksys Networks Inc"},
  {max_prefix: "00-21-9A",vendor: "Cambridge Visual Networks Ltd"},
  {max_prefix: "00-21-93",vendor: "Videofon MV"},
  {max_prefix: "00-21-92",vendor: "Baoding Galaxy Electronic Technology  Co.,Ltd"},
  {max_prefix: "00-21-7B",vendor: "Bastec AB"},
  {max_prefix: "00-21-76",vendor: "YMax Telecom Ltd."},
  {max_prefix: "00-21-71",vendor: "Wesung TNC Co., Ltd."},
  {max_prefix: "00-21-5F",vendor: "IHSE GmbH"},
  {max_prefix: "00-21-56",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-21-51",vendor: "Millinet Co., Ltd."},
  {max_prefix: "00-21-A7",vendor: "Hantle System Co., Ltd."},
  {max_prefix: "00-21-9C",vendor: "Honeywld Technology Corp."},
  {max_prefix: "00-21-D8",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-21-D7",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-21-D9",vendor: "SEKONIC CORPORATION"},
  {max_prefix: "00-21-DA",vendor: "Automation Products Group Inc."},
  {max_prefix: "00-21-6C",vendor: "ODVA"},
  {max_prefix: "00-21-61",vendor: "Yournet Inc."},
  {max_prefix: "00-21-89",vendor: "AppTech, Inc."},
  {max_prefix: "00-21-BD",vendor: "Nintendo Co., Ltd."},
  {max_prefix: "00-21-B5",vendor: "Galvanic Ltd"},
  {max_prefix: "00-21-C4",vendor: "Consilium AB"},
  {max_prefix: "00-21-22",vendor: "Chip-pro Ltd."},
  {max_prefix: "00-21-25",vendor: "KUK JE TONG SHIN Co.,LTD"},
  {max_prefix: "00-21-26",vendor: "Shenzhen Torch Equipment Co., Ltd."},
  {max_prefix: "00-21-1C",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-1F-F9",vendor: "Advanced Knowledge Associates"},
  {max_prefix: "00-1F-F4",vendor: "Power Monitors, Inc."},
  {max_prefix: "00-21-35",vendor: "ALCATEL-LUCENT"},
  {max_prefix: "00-21-18",vendor: "Athena Tech, Inc."},
  {max_prefix: "00-21-13",vendor: "Padtec S/A"},
  {max_prefix: "00-21-12",vendor: "WISCOM SYSTEM CO.,LTD"},
  {max_prefix: "00-21-47",vendor: "Nintendo Co., Ltd."},
  {max_prefix: "00-21-49",vendor: "China Daheng Group ,Inc."},
  {max_prefix: "00-1F-ED",vendor: "Tecan Systems Inc."},
  {max_prefix: "00-1F-E5",vendor: "In-Circuit GmbH"},
  {max_prefix: "00-21-38",vendor: "Cepheid"},
  {max_prefix: "00-1F-7B",vendor: "TechNexion Ltd."},
  {max_prefix: "00-1F-7D",vendor: "Embedded Wireless GmbH"},
  {max_prefix: "00-1F-74",vendor: "Eigen Development"},
  {max_prefix: "00-1F-75",vendor: "GiBahn Media"},
  {max_prefix: "00-1F-B6",vendor: "Chi Lin Technology Co., Ltd."},
  {max_prefix: "00-1F-AF",vendor: "NextIO, Inc."},
  {max_prefix: "00-1F-AA",vendor: "Taseon, Inc."},
  {max_prefix: "00-1F-BC",vendor: "EVGA Corporation"},
  {max_prefix: "00-1F-BD",vendor: "Kyocera Wireless Corp."},
  {max_prefix: "00-1F-B9",vendor: "Paltronics"},
  {max_prefix: "00-1F-B7",vendor: "WiMate Technologies Corp."},
  {max_prefix: "00-1F-B4",vendor: "SmartShare Systems"},
  {max_prefix: "00-1F-9E",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-1F-A0",vendor: "A10 Networks"},
  {max_prefix: "00-1F-6E",vendor: "Vtech Engineering Corporation"},
  {max_prefix: "00-1F-66",vendor: "PLANAR LLC"},
  {max_prefix: "00-1F-5F",vendor: "Blatand GmbH"},
  {max_prefix: "00-1F-8D",vendor: "Ingenieurbuero Stark GmbH und Ko. KG"},
  {max_prefix: "00-1F-C5",vendor: "Nintendo Co., Ltd."},
  {max_prefix: "00-1F-C0",vendor: "Control Express Finland Oy"},
  {max_prefix: "00-1F-8F",vendor: "Shanghai Bellmann Digital Source Co.,Ltd."},
  {max_prefix: "00-1F-84",vendor: "Gigle Semiconductor"},
  {max_prefix: "00-1E-E6",vendor: "Shenzhen Advanced Video Info-Tech Co., Ltd."},
  {max_prefix: "00-1E-F5",vendor: "Hitek Automated Inc."},
  {max_prefix: "00-1E-F7",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-1E-D8",vendor: "Digital United Inc."},
  {max_prefix: "00-1E-D6",vendor: "Alentec & Orion AB"},
  {max_prefix: "00-1F-1E",vendor: "Astec Technology Co., Ltd"},
  {max_prefix: "00-1F-14",vendor: "NexG"},
  {max_prefix: "00-1F-0C",vendor: "Intelligent Digital Services GmbH"},
  {max_prefix: "00-1F-08",vendor: "RISCO LTD"},
  {max_prefix: "00-1E-F1",vendor: "Servimat"},
  {max_prefix: "00-1E-F4",vendor: "L-3 Communications Display Systems"},
  {max_prefix: "00-1F-0E",vendor: "Japan Kyastem Co., Ltd"},
  {max_prefix: "00-1F-0B",vendor: "Federal State Unitary Enterprise Industrial UnionElectropribor"},
  {max_prefix: "00-1F-57",vendor: "Phonik Innovation Co.,LTD"},
  {max_prefix: "00-1F-59",vendor: "Kronback Tracers"},
  {max_prefix: "00-1F-4E",vendor: "ConMed Linvatec"},
  {max_prefix: "00-1F-4A",vendor: "Albentia Systems S.A."},
  {max_prefix: "00-1F-43",vendor: "ENTES ELEKTRONIK"},
  {max_prefix: "00-1F-28",vendor: "HPN Supply Chain"},
  {max_prefix: "00-1E-77",vendor: "Air2App"},
  {max_prefix: "00-1E-7B",vendor: "R.I.CO. S.r.l."},
  {max_prefix: "00-1E-8B",vendor: "Infra Access Korea Co., Ltd."},
  {max_prefix: "00-1E-85",vendor: "Lagotek Corporation"},
  {max_prefix: "00-1E-B5",vendor: "Ever Sparkle Technologies Ltd"},
  {max_prefix: "00-1E-B3",vendor: "Primex Wireless"},
  {max_prefix: "00-1E-B6",vendor: "TAG Heuer SA"},
  {max_prefix: "00-1E-AC",vendor: "Armadeus Systems"},
  {max_prefix: "00-1E-AA",vendor: "E-Senza Technologies GmbH"},
  {max_prefix: "00-1E-3F",vendor: "TrellisWare Technologies, Inc."},
  {max_prefix: "00-1E-55",vendor: "COWON SYSTEMS,Inc."},
  {max_prefix: "00-1E-56",vendor: "Bally Wulff Entertainment GmbH"},
  {max_prefix: "00-1E-57",vendor: "ALCOMA, spol. s r.o."},
  {max_prefix: "00-1E-C4",vendor: "Celio Corp"},
  {max_prefix: "00-1E-C1",vendor: "3COM EUROPE LTD"},
  {max_prefix: "00-1E-BB",vendor: "BLUELIGHT TECHNOLOGY INC."},
  {max_prefix: "00-1E-9C",vendor: "Fidustron INC"},
  {max_prefix: "00-1E-97",vendor: "Medium Link System Technology CO., LTD,"},
  {max_prefix: "00-1E-6E",vendor: "Shenzhen First Mile Communications Ltd"},
  {max_prefix: "00-1E-6D",vendor: "IT R&D Center"},
  {max_prefix: "00-1E-50",vendor: "BATTISTONI RESEARCH"},
  {max_prefix: "00-1E-41",vendor: "Microwave Communication & Component, Inc."},
  {max_prefix: "00-1D-FC",vendor: "KSIC"},
  {max_prefix: "00-1D-F2",vendor: "Netflix, Inc."},
  {max_prefix: "00-1D-EF",vendor: "TRIMM, INC."},
  {max_prefix: "00-1D-F1",vendor: "Intego Systems, Inc."},
  {max_prefix: "00-1D-C3",vendor: "RIKOR TV, Ltd"},
  {max_prefix: "00-1D-C1",vendor: "Audinate Pty L"},
  {max_prefix: "00-1D-ED",vendor: "Grid Net, Inc."},
  {max_prefix: "00-1E-34",vendor: "CryptoMetrics"},
  {max_prefix: "00-1E-2D",vendor: "STIM"},
  {max_prefix: "00-1D-B2",vendor: "HOKKAIDO ELECTRIC ENGINEERING CO.,LTD."},
  {max_prefix: "00-1D-AD",vendor: "Sinotech Engineering Consultants, Inc.  Geotechnical Enginee"},
  {max_prefix: "00-1D-D8",vendor: "Microsoft Corporation"},
  {max_prefix: "00-1D-C8",vendor: "Navionics Research Inc., dba SCADAmetrics"},
  {max_prefix: "00-1D-CC",vendor: "Ayon Cyber Security, Inc"},
  {max_prefix: "00-1D-AB",vendor: "SwissQual License AG"},
  {max_prefix: "00-1D-A0",vendor: "Heng Yu Electronic Manufacturing Company Limited"},
  {max_prefix: "00-1E-11",vendor: "ELELUX INTERNATIONAL LTD"},
  {max_prefix: "00-1D-83",vendor: "Emitech Corporation"},
  {max_prefix: "00-1D-74",vendor: "Tianjin China-Silicon Microelectronics Co., Ltd."},
  {max_prefix: "00-1D-6D",vendor: "Confidant International LLC"},
  {max_prefix: "00-1D-7C",vendor: "ABE Elettronica S.p.A."},
  {max_prefix: "00-1D-4B",vendor: "Grid Connect Inc."},
  {max_prefix: "00-1D-4D",vendor: "Adaptive Recognition Hungary, Inc"},
  {max_prefix: "00-1D-8A",vendor: "TechTrex Inc"},
  {max_prefix: "00-1D-87",vendor: "VigTech Labs Sdn Bhd"},
  {max_prefix: "00-1D-80",vendor: "Beijing Huahuan Eletronics Co.,Ltd"},
  {max_prefix: "00-1D-58",vendor: "CQ Inc"},
  {max_prefix: "00-1D-53",vendor: "S&O Electronics (Malaysia) Sdn. Bhd."},
  {max_prefix: "00-1D-54",vendor: "Sunnic Technology & Merchandise INC."},
  {max_prefix: "00-1D-1D",vendor: "Inter-M Corporation"},
  {max_prefix: "00-1D-0E",vendor: "Agapha Technology co., Ltd."},
  {max_prefix: "00-1D-75",vendor: "Radioscape PLC"},
  {max_prefix: "00-1D-5F",vendor: "OverSpeed SARL"},
  {max_prefix: "00-1D-92",vendor: "MICRO-STAR INT'L CO.,LTD."},
  {max_prefix: "00-1D-2F",vendor: "QuantumVision Corporation"},
  {max_prefix: "00-1C-ED",vendor: "ENVIRONNEMENT SA"},
  {max_prefix: "00-1C-E5",vendor: "MBS Electronic Systems GmbH"},
  {max_prefix: "00-1C-DD",vendor: "COWBELL ENGINEERING CO., LTD."},
  {max_prefix: "00-1C-C2",vendor: "Part II Research, Inc."},
  {max_prefix: "00-1C-BD",vendor: "Ezze Mobile Tech., Inc."},
  {max_prefix: "00-1C-B8",vendor: "CBC Co., Ltd"},
  {max_prefix: "00-1D-0A",vendor: "Davis Instruments, Inc."},
  {max_prefix: "00-1D-05",vendor: "Cooper Lighting Solutions"},
  {max_prefix: "00-1C-FF",vendor: "Napera Networks Inc"},
  {max_prefix: "00-1C-82",vendor: "Genew Technologies"},
  {max_prefix: "00-1C-84",vendor: "STL Solution Co.,Ltd."},
  {max_prefix: "00-1C-79",vendor: "Cohesive Financial Technologies LLC"},
  {max_prefix: "00-1C-A5",vendor: "Zygo Corporation"},
  {max_prefix: "00-1C-9D",vendor: "Liecthi AG"},
  {max_prefix: "00-1C-96",vendor: "Linkwise Technology Pte Ltd"},
  {max_prefix: "00-1C-BA",vendor: "VerScient, Inc."},
  {max_prefix: "00-1C-B0",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-1C-AC",vendor: "Qniq Technology Corp."},
  {max_prefix: "00-1C-DC",vendor: "Custom Computer Services, Inc."},
  {max_prefix: "00-1C-D1",vendor: "Waves Audio LTD"},
  {max_prefix: "00-1C-8B",vendor: "MJ Innovations Ltd."},
  {max_prefix: "00-1C-57",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-1C-5D",vendor: "Leica Microsystems"},
  {max_prefix: "00-1C-5E",vendor: "ASTON France"},
  {max_prefix: "00-1C-5B",vendor: "Chubb Electronic Security Systems Ltd"},
  {max_prefix: "00-1C-55",vendor: "Shenzhen Kaifa Technology Co."},
  {max_prefix: "00-1C-44",vendor: "Bosch Security Systems BV"},
  {max_prefix: "00-1C-4C",vendor: "Petrotest Instruments"},
  {max_prefix: "00-1C-3C",vendor: "Seon Design Inc."},
  {max_prefix: "00-1C-29",vendor: "CORE DIGITAL ELECTRONICS CO., LTD"},
  {max_prefix: "00-1C-24",vendor: "Formosa Wireless Systems Corp."},
  {max_prefix: "00-1C-20",vendor: "CLB Benelux"},
  {max_prefix: "00-1C-1C",vendor: "Center Communication Systems GmbH"},
  {max_prefix: "00-1C-31",vendor: "Mobile XP Technology Co., LTD"},
  {max_prefix: "00-1C-34",vendor: "HUEY CHIAO INTERNATIONAL CO., LTD."},
  {max_prefix: "00-1C-36",vendor: "iNEWiT NV"},
  {max_prefix: "00-1C-07",vendor: "Cwlinux Limited"},
  {max_prefix: "00-1C-00",vendor: "Entry Point, LLC"},
  {max_prefix: "00-1B-FD",vendor: "Dignsys Inc."},
  {max_prefix: "00-1C-6D",vendor: "KYOHRITSU ELECTRONIC INDUSTRY CO., LTD."},
  {max_prefix: "00-1C-64",vendor: "Landis+Gyr"},
  {max_prefix: "00-1B-E6",vendor: "VR AG"},
  {max_prefix: "00-1B-E2",vendor: "AhnLab,Inc."},
  {max_prefix: "00-1B-E0",vendor: "TELENOT ELECTRONIC GmbH"},
  {max_prefix: "00-1B-DA",vendor: "UTStarcom Inc"},
  {max_prefix: "00-1B-F5",vendor: "Tellink Sistemas de Telecomunicaci\u00f3n S.L."},
  {max_prefix: "00-1C-15",vendor: "iPhotonix LLC"},
  {max_prefix: "00-1B-A3",vendor: "Flexit Group GmbH"},
  {max_prefix: "00-1B-9F",vendor: "Calyptech Pty Ltd"},
  {max_prefix: "00-1B-99",vendor: "KS System GmbH"},
  {max_prefix: "00-1B-9A",vendor: "Apollo Fire Detectors Ltd"},
  {max_prefix: "00-1B-BD",vendor: "FMC Kongsberg Subsea AS"},
  {max_prefix: "00-1B-BE",vendor: "ICOP Digital"},
  {max_prefix: "00-1B-B3",vendor: "Condalo GmbH"},
  {max_prefix: "00-1B-B7",vendor: "Alta Heights Technology Corp."},
  {max_prefix: "00-1B-AC",vendor: "Curtiss Wright Controls Embedded Computing"},
  {max_prefix: "00-1B-60",vendor: "NAVIGON AG"},
  {max_prefix: "00-1B-57",vendor: "SEMINDIA SYSTEMS PRIVATE LIMITED"},
  {max_prefix: "00-1B-55",vendor: "Hurco Automation Ltd."},
  {max_prefix: "00-1B-53",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-1B-D1",vendor: "SOGESTMATIC"},
  {max_prefix: "00-1B-D6",vendor: "Kelvin Hughes Ltd"},
  {max_prefix: "00-1B-CF",vendor: "Dataupia Corporation"},
  {max_prefix: "00-1B-8B",vendor: "NEC Platforms, Ltd."},
  {max_prefix: "00-1B-82",vendor: "Taiwan Semiconductor Co., Ltd."},
  {max_prefix: "00-1B-85",vendor: "MAN Energy Solutions"},
  {max_prefix: "00-1B-8C",vendor: "JMicron Technology Corp."},
  {max_prefix: "00-1B-91",vendor: "EFKON AG"},
  {max_prefix: "00-1B-89",vendor: "EMZA Visual Sense Ltd."},
  {max_prefix: "00-1B-6A",vendor: "Powerwave Technologies Sweden AB"},
  {max_prefix: "00-1B-67",vendor: "Cisco Systems Inc"},
  {max_prefix: "00-1B-66",vendor: "Sennheiser electronic GmbH & Co. KG"},
  {max_prefix: "00-1B-CB",vendor: "PEMPEK SYSTEMS PTY LTD"},
  {max_prefix: "00-1B-7B",vendor: "The Tintometer Ltd"},
  {max_prefix: "00-1B-75",vendor: "Hypermedia Systems"},
  {max_prefix: "00-1A-D9",vendor: "International Broadband Electric Communications, Inc."},
  {max_prefix: "00-1B-31",vendor: "Neural Image. Co. Ltd."},
  {max_prefix: "00-1B-29",vendor: "Avantis.Co.,Ltd"},
  {max_prefix: "00-1B-28",vendor: "POLYGON, JSC"},
  {max_prefix: "00-1B-2B",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-1B-27",vendor: "Merlin CSI"},
  {max_prefix: "00-1B-0E",vendor: "InoTec GmbH Organisationssysteme"},
  {max_prefix: "00-1B-04",vendor: "Affinity International S.p.a"},
  {max_prefix: "00-1B-06",vendor: "Ateliers R. LAUMONIER"},
  {max_prefix: "00-1B-05",vendor: "YMC AG"},
  {max_prefix: "00-1A-FF",vendor: "Wizyoung Tech."},
  {max_prefix: "00-1B-00",vendor: "Neopost Technologies"},
  {max_prefix: "00-1B-43",vendor: "Beijing DG Telecommunications equipment Co.,Ltd"},
  {max_prefix: "00-1B-3C",vendor: "Software Technologies Group,Inc."},
  {max_prefix: "00-1B-3D",vendor: "EuroTel Spa"},
  {max_prefix: "00-1B-1B",vendor: "Siemens AG,"},
  {max_prefix: "00-1B-1F",vendor: "FORCE Technology"},
  {max_prefix: "00-1B-49",vendor: "Roberts Radio limited"},
  {max_prefix: "00-1B-4E",vendor: "Navman New Zealand"},
  {max_prefix: "00-1B-16",vendor: "Celtro Ltd."},
  {max_prefix: "00-1A-E6",vendor: "Atlanta Advanced Communications Holdings Limited"},
  {max_prefix: "00-1A-F2",vendor: "Dynavisions Schweiz AG"},
  {max_prefix: "00-1A-7A",vendor: "Lismore Instruments Limited"},
  {max_prefix: "00-1A-78",vendor: "ubtos"},
  {max_prefix: "00-1A-76",vendor: "SDT information Technology Co.,LTD."},
  {max_prefix: "00-1A-70",vendor: "Cisco-Linksys, LLC"},
  {max_prefix: "00-1A-60",vendor: "Wave Electronics Co.,Ltd."},
  {max_prefix: "00-1A-56",vendor: "ViewTel Co,. Ltd."},
  {max_prefix: "00-1A-50",vendor: "PheeNet Technology Corp."},
  {max_prefix: "00-1A-53",vendor: "Zylaya"},
  {max_prefix: "00-1A-4C",vendor: "Crossbow Technology, Inc"},
  {max_prefix: "00-1A-7D",vendor: "cyber-blue(HK)Ltd"},
  {max_prefix: "00-1A-82",vendor: "PROBA Building Automation Co.,LTD"},
  {max_prefix: "00-1A-7C",vendor: "Hirschmann Multimedia B.V."},
  {max_prefix: "00-1A-AB",vendor: "eWings s.r.l."},
  {max_prefix: "00-1A-AC",vendor: "Corelatus AB"},
  {max_prefix: "00-1A-AF",vendor: "BLUSENS TECHNOLOGY"},
  {max_prefix: "00-1A-B0",vendor: "Signal Networks Pvt. Ltd.,"},
  {max_prefix: "00-1A-C8",vendor: "ISL (Instrumentation Scientifique de Laboratoire)"},
  {max_prefix: "00-1A-C6",vendor: "Micro Control Designs"},
  {max_prefix: "00-1A-61",vendor: "PacStar Corp."},
  {max_prefix: "00-1A-65",vendor: "Seluxit"},
  {max_prefix: "00-1A-54",vendor: "Hip Shing Electronics Ltd."},
  {max_prefix: "00-1A-A1",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-19-F8",vendor: "Embedded Systems Design, Inc."},
  {max_prefix: "00-19-EF",vendor: "SHENZHEN LINNKING ELECTRONICS CO.,LTD"},
  {max_prefix: "00-19-F3",vendor: "Cetis, Inc"},
  {max_prefix: "00-19-F5",vendor: "Imagination Technologies Ltd"},
  {max_prefix: "00-19-F7",vendor: "Onset Computer Corporation"},
  {max_prefix: "00-19-EE",vendor: "CARLO GAVAZZI CONTROLS SPA-Controls Division"},
  {max_prefix: "00-19-EB",vendor: "Pyronix Ltd"},
  {max_prefix: "00-19-E7",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-19-E9",vendor: "S-Information Technolgy, Co., Ltd."},
  {max_prefix: "00-19-DA",vendor: "Welltrans O&E Technology Co. , Ltd."},
  {max_prefix: "00-19-D0",vendor: "Cathexis"},
  {max_prefix: "00-1A-14",vendor: "Xin Hua Control Engineering Co.,Ltd."},
  {max_prefix: "00-1A-10",vendor: "LUCENT TRANS ELECTRONICS CO.,LTD"},
  {max_prefix: "00-1A-0C",vendor: "Swe-Dish Satellite Systems AB"},
  {max_prefix: "00-1A-07",vendor: "Arecont Vision"},
  {max_prefix: "00-1A-08",vendor: "Simoco Ltd."},
  {max_prefix: "00-1A-04",vendor: "Interay Solutions BV"},
  {max_prefix: "00-1A-02",vendor: "SECURE CARE PRODUCTS, INC"},
  {max_prefix: "00-1A-1A",vendor: "Gentex Corporation/Electro-Acoustic Products"},
  {max_prefix: "00-1A-12",vendor: "Essilor"},
  {max_prefix: "00-19-D6",vendor: "LS Cable and System Ltd."},
  {max_prefix: "00-19-D7",vendor: "FORTUNETEK CO., LTD"},
  {max_prefix: "00-19-C9",vendor: "S&C ELECTRIC COMPANY"},
  {max_prefix: "00-1A-42",vendor: "Techcity Technology co., Ltd."},
  {max_prefix: "00-19-AC",vendor: "GSP SYSTEMS Inc."},
  {max_prefix: "00-19-B0",vendor: "HanYang System"},
  {max_prefix: "00-19-95",vendor: "Jurong Hi-Tech (Suzhou)Co.ltd"},
  {max_prefix: "00-19-9A",vendor: "EDO-EVI"},
  {max_prefix: "00-19-94",vendor: "Jorjin Technologies Inc."},
  {max_prefix: "00-19-BF",vendor: "Citiway technology Co.,ltd"},
  {max_prefix: "00-19-B6",vendor: "Euro Emme s.r.l."},
  {max_prefix: "00-19-B5",vendor: "Famar Fueguina S.A."},
  {max_prefix: "00-19-5D",vendor: "ShenZhen XinHuaTong Opto Electronics Co.,Ltd"},
  {max_prefix: "00-19-53",vendor: "Chainleader Communications Corp."},
  {max_prefix: "00-19-55",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-19-59",vendor: "Staccato Communications Inc."},
  {max_prefix: "00-19-4D",vendor: "Avago Technologies Sdn Bhd"},
  {max_prefix: "00-19-4E",vendor: "Ultra Electronics - TCS (Tactical Communication Systems)"},
  {max_prefix: "00-19-7C",vendor: "Riedel Communications GmbH"},
  {max_prefix: "00-19-6F",vendor: "SensoPart GmbH"},
  {max_prefix: "00-19-A0",vendor: "NIHON DATA SYSTENS, INC."},
  {max_prefix: "00-19-91",vendor: "avinfo"},
  {max_prefix: "00-19-8C",vendor: "iXSea"},
  {max_prefix: "00-19-62",vendor: "Commerciant, LP"},
  {max_prefix: "00-19-B8",vendor: "Boundary Devices"},
  {max_prefix: "00-18-E4",vendor: "YIGUANG"},
  {max_prefix: "00-18-E5",vendor: "Adhoco AG"},
  {max_prefix: "00-18-DD",vendor: "Silicondust Engineering Ltd"},
  {max_prefix: "00-18-DF",vendor: "The Morey Corporation"},
  {max_prefix: "00-18-E1",vendor: "Verkerk Service Systemen"},
  {max_prefix: "00-18-DA",vendor: "W\u00fcrth Elektronik eiSos GmbH & Co. KG"},
  {max_prefix: "00-18-D5",vendor: "REIGNCOM"},
  {max_prefix: "00-18-D4",vendor: "Unified Display Interface SIG"},
  {max_prefix: "00-19-15",vendor: "TECOM Co., Ltd."},
  {max_prefix: "00-19-1B",vendor: "Sputnik Engineering AG"},
  {max_prefix: "00-19-09",vendor: "DEVI - Danfoss A/S"},
  {max_prefix: "00-19-0A",vendor: "HASWARE INC."},
  {max_prefix: "00-19-04",vendor: "WB Electronics Sp. z o.o."},
  {max_prefix: "00-19-34",vendor: "TRENDON TOUCH TECHNOLOGY CORP."},
  {max_prefix: "00-19-23",vendor: "Phonex Korea Co., LTD."},
  {max_prefix: "00-19-1C",vendor: "Sensicast Systems"},
  {max_prefix: "00-19-33",vendor: "Strix Systems, Inc."},
  {max_prefix: "00-19-28",vendor: "Siemens AG, Transportation Systems"},
  {max_prefix: "00-19-0D",vendor: "IEEE 1394c"},
  {max_prefix: "00-18-C2",vendor: "Firetide, Inc"},
  {max_prefix: "00-18-E7",vendor: "Cameo Communications, INC."},
  {max_prefix: "00-18-38",vendor: "PanAccess Communications,Inc."},
  {max_prefix: "00-18-21",vendor: "SINDORICOH"},
  {max_prefix: "00-18-23",vendor: "Delta Electronics, Inc."},
  {max_prefix: "00-18-15",vendor: "GZ Technologies, Inc."},
  {max_prefix: "00-18-18",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-18-A9",vendor: "Ethernet Direct Corporation"},
  {max_prefix: "00-18-A7",vendor: "Yoggie Security Systems LTD."},
  {max_prefix: "00-18-A8",vendor: "AnNeal Technology Inc."},
  {max_prefix: "00-18-A2",vendor: "XIP Technology AB"},
  {max_prefix: "00-18-9C",vendor: "Weldex Corporation"},
  {max_prefix: "00-18-9A",vendor: "HANA Micron Inc."},
  {max_prefix: "00-18-5F",vendor: "TAC Inc."},
  {max_prefix: "00-18-64",vendor: "Eaton Corporation"},
  {max_prefix: "00-18-66",vendor: "Leutron Vision"},
  {max_prefix: "00-18-60",vendor: "SIM Technology Group Shanghai Simcom Ltd.,"},
  {max_prefix: "00-18-3E",vendor: "Digilent, Inc"},
  {max_prefix: "00-18-42",vendor: "Nokia Danmark A/S"},
  {max_prefix: "00-18-40",vendor: "3 Phoenix, Inc."},
  {max_prefix: "00-18-57",vendor: "Unilever R&D"},
  {max_prefix: "00-18-53",vendor: "Atera Networks LTD."},
  {max_prefix: "00-18-59",vendor: "Strawberry Linux Co.,Ltd."},
  {max_prefix: "00-18-4F",vendor: "8 Ways Technology Corp."},
  {max_prefix: "00-18-73",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-18-7A",vendor: "Wiremold"},
  {max_prefix: "00-18-6C",vendor: "Neonode AB"},
  {max_prefix: "00-18-44",vendor: "Heads Up Technologies, Inc."},
  {max_prefix: "00-18-2B",vendor: "Softier"},
  {max_prefix: "00-18-29",vendor: "Gatsometer"},
  {max_prefix: "00-18-81",vendor: "Buyang Electronics Industrial Co., Ltd"},
  {max_prefix: "00-18-97",vendor: "JESS-LINK PRODUCTS Co., LTD"},
  {max_prefix: "00-18-9E",vendor: "OMNIKEY GmbH."},
  {max_prefix: "00-18-1A",vendor: "AVerMedia Information Inc."},
  {max_prefix: "00-18-16",vendor: "Ubixon Co., Ltd."},
  {max_prefix: "00-17-B5",vendor: "Peerless Systems Corporation"},
  {max_prefix: "00-17-AF",vendor: "Enermet"},
  {max_prefix: "00-17-AA",vendor: "elab-experience inc."},
  {max_prefix: "00-17-AE",vendor: "GAI-Tronics"},
  {max_prefix: "00-17-A8",vendor: "EDM Corporation"},
  {max_prefix: "00-17-A9",vendor: "Sentivision"},
  {max_prefix: "00-17-C5",vendor: "SonicWALL"},
  {max_prefix: "00-17-BE",vendor: "Tratec Telecom B.V."},
  {max_prefix: "00-17-C0",vendor: "PureTech Systems, Inc."},
  {max_prefix: "00-17-BA",vendor: "SEDO CO., LTD."},
  {max_prefix: "00-17-D4",vendor: "Monsoon Multimedia, Inc"},
  {max_prefix: "00-17-F1",vendor: "Renu Electronics Pvt Ltd"},
  {max_prefix: "00-17-FF",vendor: "PLAYLINE Co.,Ltd."},
  {max_prefix: "00-17-F6",vendor: "Pyramid Meriden Inc."},
  {max_prefix: "00-18-06",vendor: "Hokkei Industries Co., Ltd."},
  {max_prefix: "00-17-F0",vendor: "SZCOM Broadband Network Technology Co.,Ltd"},
  {max_prefix: "00-17-8B",vendor: "Teledyne Technologies Incorporated"},
  {max_prefix: "00-17-80",vendor: "Applied Biosystems B.V."},
  {max_prefix: "00-17-DF",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-17-2F",vendor: "NeuLion Incorporated"},
  {max_prefix: "00-17-28",vendor: "Selex Communications"},
  {max_prefix: "00-17-46",vendor: "Freedom9 Inc."},
  {max_prefix: "00-17-4D",vendor: "DYNAMIC NETWORK FACTORY, INC."},
  {max_prefix: "00-17-44",vendor: "Araneo Ltd."},
  {max_prefix: "00-17-49",vendor: "HYUNDAE YONG-O-SA CO.,LTD"},
  {max_prefix: "00-17-43",vendor: "Deck Srl"},
  {max_prefix: "00-17-3D",vendor: "Neology"},
  {max_prefix: "00-17-40",vendor: "Bluberi Gaming Technologies Inc"},
  {max_prefix: "00-17-32",vendor: "Science-Technical Center RISSA"},
  {max_prefix: "00-17-3A",vendor: "Cloudastructure Inc"},
  {max_prefix: "00-17-70",vendor: "Arti Industrial Electronics Ltd."},
  {max_prefix: "00-17-6D",vendor: "CORE CORPORATION"},
  {max_prefix: "00-17-73",vendor: "Laketune Technologies Co. Ltd"},
  {max_prefix: "00-17-22",vendor: "Hanazeder Electronic GmbH"},
  {max_prefix: "00-17-23",vendor: "Summit Data Communications"},
  {max_prefix: "00-17-6C",vendor: "Pivot3, Inc."},
  {max_prefix: "00-17-1F",vendor: "IMV Corporation"},
  {max_prefix: "00-17-57",vendor: "RIX TECHNOLOGY LIMITED"},
  {max_prefix: "00-16-BB",vendor: "Law-Chain Computer Technology Co Ltd"},
  {max_prefix: "00-16-B4",vendor: "Private"},
  {max_prefix: "00-16-A7",vendor: "AWETA G&P"},
  {max_prefix: "00-16-BF",vendor: "PaloDEx Group Oy"},
  {max_prefix: "00-16-B7",vendor: "Seoul Commtech"},
  {max_prefix: "00-16-A0",vendor: "Auto-Maskin"},
  {max_prefix: "00-16-E1",vendor: "SiliconStor, Inc."},
  {max_prefix: "00-16-E2",vendor: "American Fibertek, Inc."},
  {max_prefix: "00-17-13",vendor: "Tiger NetCom"},
  {max_prefix: "00-16-CD",vendor: "HIJI HIGH-TECH CO., LTD."},
  {max_prefix: "00-16-EF",vendor: "Koko Fitness, Inc."},
  {max_prefix: "00-16-FD",vendor: "Jaty Electronics"},
  {max_prefix: "00-16-8D",vendor: "KORWIN CO., Ltd."},
  {max_prefix: "00-16-8E",vendor: "Vimicro corporation"},
  {max_prefix: "00-16-89",vendor: "Pilkor Electronics Co., Ltd"},
  {max_prefix: "00-16-8A",vendor: "id-Confirm Inc"},
  {max_prefix: "00-16-86",vendor: "Karl Storz Imaging"},
  {max_prefix: "00-16-7E",vendor: "DIBOSS.CO.,LTD"},
  {max_prefix: "00-16-7B",vendor: "Haver&Boecker"},
  {max_prefix: "00-16-79",vendor: "eOn Communications"},
  {max_prefix: "00-16-78",vendor: "SHENZHEN BAOAN GAOKE ELECTRONICS CO., LTD"},
  {max_prefix: "00-16-74",vendor: "EuroCB (Phils.), Inc."},
  {max_prefix: "00-16-4A",vendor: "Vibration Technology Limited"},
  {max_prefix: "00-16-45",vendor: "Power Distribution, Inc."},
  {max_prefix: "00-16-3F",vendor: "CReTE SYSTEMS Inc."},
  {max_prefix: "00-16-3D",vendor: "Tsinghua Tongfang Legend Silicon Tech. Co., Ltd."},
  {max_prefix: "00-16-3A",vendor: "YVES TECHNOLOGY CO., LTD."},
  {max_prefix: "00-16-38",vendor: "TECOM Co., Ltd."},
  {max_prefix: "00-16-27",vendor: "embedded-logic DESIGN AND MORE GmbH"},
  {max_prefix: "00-16-22",vendor: "BBH SYSTEMS GMBH"},
  {max_prefix: "00-16-13",vendor: "LibreStream Technologies Inc."},
  {max_prefix: "00-16-33",vendor: "Oxford Diagnostics Ltd."},
  {max_prefix: "00-16-2D",vendor: "STNet Co., Ltd."},
  {max_prefix: "00-16-4F",vendor: "World Ethnic Broadcastin Inc."},
  {max_prefix: "00-16-70",vendor: "SKNET Corporation"},
  {max_prefix: "00-16-67",vendor: "A-TEC Subsystem INC."},
  {max_prefix: "00-16-5D",vendor: "AirDefense, Inc."},
  {max_prefix: "00-16-5B",vendor: "Grip Audio"},
  {max_prefix: "00-15-CD",vendor: "Exartech International Corp."},
  {max_prefix: "00-15-C9",vendor: "Gumstix, Inc"},
  {max_prefix: "00-15-BA",vendor: "iba AG"},
  {max_prefix: "00-15-BB",vendor: "SMA Solar Technology AG"},
  {max_prefix: "00-15-EC",vendor: "Boca Devices LLC"},
  {max_prefix: "00-15-EF",vendor: "NEC TOKIN Corporation"},
  {max_prefix: "00-15-E4",vendor: "Zimmer Elektromedizin"},
  {max_prefix: "00-15-BF",vendor: "technicob"},
  {max_prefix: "00-15-BC",vendor: "Develco"},
  {max_prefix: "00-15-BD",vendor: "Group 4 Technology Ltd"},
  {max_prefix: "00-15-B5",vendor: "CI Network Corp."},
  {max_prefix: "00-15-FB",vendor: "setex schermuly textile computer gmbh"},
  {max_prefix: "00-15-FE",vendor: "SCHILLING ROBOTICS LLC"},
  {max_prefix: "00-15-AE",vendor: "kyung il"},
  {max_prefix: "00-16-0F",vendor: "BADGER METER INC"},
  {max_prefix: "00-16-04",vendor: "Sigpro"},
  {max_prefix: "00-15-9C",vendor: "B-KYUNG SYSTEM Co.,Ltd."},
  {max_prefix: "00-15-95",vendor: "Quester Tangent Corporation"},
  {max_prefix: "00-15-D9",vendor: "PKC Electronics Oy"},
  {max_prefix: "00-15-DD",vendor: "IP Control Systems Ltd."},
  {max_prefix: "00-16-0D",vendor: "Be Here Corporation"},
  {max_prefix: "00-15-1A",vendor: "Hunter Engineering Company"},
  {max_prefix: "00-15-14",vendor: "Hu Zhou NAVA Networks&Electronics Ltd."},
  {max_prefix: "00-15-16",vendor: "URIEL SYSTEMS INC."},
  {max_prefix: "00-15-65",vendor: "XIAMEN YEALINK NETWORK TECHNOLOGY CO.,LTD"},
  {max_prefix: "00-15-69",vendor: "PECO II, Inc."},
  {max_prefix: "00-15-63",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-15-64",vendor: "BEHRINGER Spezielle Studiotechnik GmbH"},
  {max_prefix: "00-15-7B",vendor: "Leuze electronic GmbH + Co. KG"},
  {max_prefix: "00-15-78",vendor: "Audio / Video Innovations"},
  {max_prefix: "00-15-71",vendor: "Nolan Systems"},
  {max_prefix: "00-15-43",vendor: "Aberdeen Test Center"},
  {max_prefix: "00-15-41",vendor: "StrataLight Communications, Inc."},
  {max_prefix: "00-15-61",vendor: "JJPlus Corporation"},
  {max_prefix: "00-15-58",vendor: "FOXCONN"},
  {max_prefix: "00-15-38",vendor: "RFID, Inc."},
  {max_prefix: "00-15-2E",vendor: "PacketHop, Inc."},
  {max_prefix: "00-15-4F",vendor: "one RF Technology"},
  {max_prefix: "00-15-45",vendor: "SEECODE Co., Ltd."},
  {max_prefix: "00-15-1F",vendor: "Multivision Intelligent Surveillance (Hong Kong) Ltd"},
  {max_prefix: "00-15-22",vendor: "Dea Security"},
  {max_prefix: "00-15-87",vendor: "Takenaka Seisakusho Co.,Ltd"},
  {max_prefix: "00-14-EF",vendor: "TZero Technologies, Inc."},
  {max_prefix: "00-14-EE",vendor: "Western Digital Technologies, Inc."},
  {max_prefix: "00-14-A3",vendor: "Vitelec BV"},
  {max_prefix: "00-14-97",vendor: "ZHIYUAN Eletronics co.,ltd."},
  {max_prefix: "00-14-99",vendor: "Helicomm Inc"},
  {max_prefix: "00-14-92",vendor: "Liteon, Mobile Media Solution SBU"},
  {max_prefix: "00-14-94",vendor: "ESU AG"},
  {max_prefix: "00-14-BC",vendor: "SYNECTIC TELECOM EXPORTS PVT. LTD."},
  {max_prefix: "00-14-B9",vendor: "MSTAR SEMICONDUCTOR"},
  {max_prefix: "00-14-BD",vendor: "incNETWORKS, Inc"},
  {max_prefix: "00-14-B7",vendor: "AR Infotek Inc."},
  {max_prefix: "00-14-B5",vendor: "PHYSIOMETRIX,INC"},
  {max_prefix: "00-14-CA",vendor: "Key Radio Systems Limited"},
  {max_prefix: "00-14-C8",vendor: "Contemporary Research Corp"},
  {max_prefix: "00-14-C5",vendor: "Alive Technologies Pty Ltd"},
  {max_prefix: "00-14-D3",vendor: "SEPSA"},
  {max_prefix: "00-14-D8",vendor: "bio-logic SA"},
  {max_prefix: "00-14-DA",vendor: "Huntleigh Healthcare"},
  {max_prefix: "00-14-D5",vendor: "Datang Telecom Technology CO. , LCD,Optical Communication Br"},
  {max_prefix: "00-14-85",vendor: "Giga-Byte"},
  {max_prefix: "00-14-83",vendor: "eXS Inc."},
  {max_prefix: "00-14-9C",vendor: "HF Company"},
  {max_prefix: "00-15-01",vendor: "LexBox"},
  {max_prefix: "00-14-12",vendor: "S-TEC electronics AG"},
  {max_prefix: "00-14-11",vendor: "Deutschmann Automation GmbH & Co. KG"},
  {max_prefix: "00-14-05",vendor: "OpenIB, Inc."},
  {max_prefix: "00-45-01",vendor: "Midmark RTLS"},
  {max_prefix: "00-14-60",vendor: "Kyocera Wireless Corp."},
  {max_prefix: "00-14-5C",vendor: "Intronics B.V."},
  {max_prefix: "00-14-5A",vendor: "Westermo Neratec AG"},
  {max_prefix: "00-14-5B",vendor: "SeekerNet Inc."},
  {max_prefix: "00-14-43",vendor: "Consultronics Europe Ltd"},
  {max_prefix: "00-14-49",vendor: "Sichuan Changhong Electric Ltd."},
  {max_prefix: "00-14-46",vendor: "SuperVision Solutions LLC"},
  {max_prefix: "00-14-40",vendor: "ATOMIC Corporation"},
  {max_prefix: "00-14-2E",vendor: "77 Elektronika Kft."},
  {max_prefix: "00-14-30",vendor: "ViPowER, Inc"},
  {max_prefix: "00-14-32",vendor: "Tarallax Wireless, Inc."},
  {max_prefix: "00-13-F5",vendor: "Akimbi Systems"},
  {max_prefix: "00-13-F1",vendor: "AMOD Technology Co., Ltd."},
  {max_prefix: "00-14-1F",vendor: "SunKwang Electronics Co., Ltd"},
  {max_prefix: "00-14-1D",vendor: "KEBA Industrial Automation Germany GmbH"},
  {max_prefix: "00-14-4C",vendor: "General Meters Corp."},
  {max_prefix: "00-14-71",vendor: "Eastern Asia Technology Limited"},
  {max_prefix: "00-14-03",vendor: "Renasis, LLC"},
  {max_prefix: "00-13-E7",vendor: "Halcro"},
  {max_prefix: "00-13-EA",vendor: "Kamstrup A/S"},
  {max_prefix: "00-13-E1",vendor: "Iprobe AB"},
  {max_prefix: "00-13-E3",vendor: "CoVi Technologies, Inc."},
  {max_prefix: "00-13-E4",vendor: "YANGJAE SYSTEMS CORP."},
  {max_prefix: "00-13-D9",vendor: "Matrix Product Development, Inc."},
  {max_prefix: "00-13-94",vendor: "Infohand Co.,Ltd"},
  {max_prefix: "00-13-89",vendor: "Redes de Telefon\u00eda M\u00f3vil S.A."},
  {max_prefix: "00-13-8C",vendor: "Kumyoung.Co.Ltd"},
  {max_prefix: "00-13-8E",vendor: "FOAB Elektronik AB"},
  {max_prefix: "00-13-76",vendor: "Tabor Electronics Ltd."},
  {max_prefix: "00-13-80",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-13-85",vendor: "Add-On Technology Co., LTD."},
  {max_prefix: "00-13-C1",vendor: "Asoka USA Corporation"},
  {max_prefix: "00-13-C0",vendor: "Trix Tecnologia Ltda."},
  {max_prefix: "00-13-A1",vendor: "Crow Electronic Engeneering"},
  {max_prefix: "00-13-9C",vendor: "Exavera Technologies, Inc."},
  {max_prefix: "00-13-6C",vendor: "TomTom"},
  {max_prefix: "00-13-64",vendor: "Paradigm Technology Inc.."},
  {max_prefix: "00-13-6B",vendor: "E-TEC"},
  {max_prefix: "00-13-B3",vendor: "Ecom Communications Technology Co., Ltd."},
  {max_prefix: "00-13-CC",vendor: "Tall Maple Systems"},
  {max_prefix: "00-13-B7",vendor: "Scantech ID"},
  {max_prefix: "00-13-0B",vendor: "Mextal B.V."},
  {max_prefix: "00-13-0D",vendor: "GALILEO AVIONICA"},
  {max_prefix: "00-13-08",vendor: "Nuvera Fuel Cells"},
  {max_prefix: "00-13-07",vendor: "Paravirtual Corporation"},
  {max_prefix: "00-13-55",vendor: "TOMEN Cyber-business Solutions, Inc."},
  {max_prefix: "00-13-57",vendor: "Soyal Technology Co., Ltd."},
  {max_prefix: "00-13-60",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-13-5D",vendor: "NTTPC Communications, Inc."},
  {max_prefix: "00-13-52",vendor: "Naztec, Inc."},
  {max_prefix: "00-13-45",vendor: "Eaton Corporation"},
  {max_prefix: "00-13-47",vendor: "Red Lion Controls, LP"},
  {max_prefix: "00-13-43",vendor: "Matsushita Electronic Components (Europe) GmbH"},
  {max_prefix: "00-12-EC",vendor: "Movacolor b.v."},
  {max_prefix: "00-12-EB",vendor: "PDH Solutions, LLC"},
  {max_prefix: "00-12-FC",vendor: "PLANET System Co.,LTD"},
  {max_prefix: "00-12-FE",vendor: "Lenovo Mobile Communication Technology Ltd."},
  {max_prefix: "00-12-F7",vendor: "Xiamen Xinglian Electronics Co., Ltd."},
  {max_prefix: "00-13-3E",vendor: "MetaSwitch"},
  {max_prefix: "00-13-2E",vendor: "ITian Coporation"},
  {max_prefix: "00-12-E1",vendor: "Alliant Networks, Inc"},
  {max_prefix: "00-13-39",vendor: "CCV Deutschland GmbH"},
  {max_prefix: "00-13-2B",vendor: "Phoenix Digital"},
  {max_prefix: "00-13-1B",vendor: "BeCell Innovations Corp."},
  {max_prefix: "00-12-86",vendor: "ENDEVCO CORP"},
  {max_prefix: "00-12-C2",vendor: "Apex Electronics Factory"},
  {max_prefix: "00-12-BE",vendor: "Astek Corporation"},
  {max_prefix: "00-12-DF",vendor: "Novomatic AG"},
  {max_prefix: "00-12-E2",vendor: "ALAXALA Networks Corporation"},
  {max_prefix: "00-12-D4",vendor: "Princeton Technology, Ltd"},
  {max_prefix: "00-12-D9",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-12-74",vendor: "NIT lab"},
  {max_prefix: "00-12-70",vendor: "NGES Denro Systems"},
  {max_prefix: "00-12-6E",vendor: "Seidel Elektronik GmbH Nfg.KG"},
  {max_prefix: "00-12-A0",vendor: "NeoMeridian Sdn Bhd"},
  {max_prefix: "00-12-AD",vendor: "VIVAVIS AG"},
  {max_prefix: "00-12-C5",vendor: "V-Show  Technology (China) Co.,Ltd"},
  {max_prefix: "00-12-2D",vendor: "SiNett Corporation"},
  {max_prefix: "00-12-2C",vendor: "Soenen Controls N.V."},
  {max_prefix: "00-12-2B",vendor: "Virbiage Pty Ltd"},
  {max_prefix: "00-12-32",vendor: "LeWiz Communications Inc."},
  {max_prefix: "00-11-E5",vendor: "KCodes Corporation"},
  {max_prefix: "00-12-04",vendor: "u10 Networks, Inc."},
  {max_prefix: "00-12-0A",vendor: "Emerson Climate Technologies GmbH"},
  {max_prefix: "00-12-08",vendor: "Gantner Instruments GmbH"},
  {max_prefix: "00-12-01",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-12-00",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-12-63",vendor: "Data Voice Technologies GmbH"},
  {max_prefix: "00-12-5C",vendor: "Green Hills Software, Inc."},
  {max_prefix: "00-12-5F",vendor: "AWIND Inc."},
  {max_prefix: "00-12-1A",vendor: "Techno Soft Systemnics Inc."},
  {max_prefix: "00-12-1B",vendor: "Sound Devices, LLC"},
  {max_prefix: "00-11-EE",vendor: "Estari, Inc."},
  {max_prefix: "00-12-6F",vendor: "Rayson Technology Co., Ltd."},
  {max_prefix: "00-12-4C",vendor: "BBWM Corporation"},
  {max_prefix: "00-12-44",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-12-55",vendor: "NetEffect Incorporated"},
  {max_prefix: "00-12-53",vendor: "AudioDev AB"},
  {max_prefix: "00-11-B4",vendor: "Westermo Network Technologies AB"},
  {max_prefix: "00-11-B8",vendor: "Liebherr - Elektronik GmbH"},
  {max_prefix: "00-11-C1",vendor: "4P MOBILE DATA PROCESSING"},
  {max_prefix: "00-11-8C",vendor: "Missouri Department of Transportation"},
  {max_prefix: "00-11-93",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-11-8E",vendor: "Halytech Mace"},
  {max_prefix: "00-11-86",vendor: "Prime Systems, Inc."},
  {max_prefix: "00-11-78",vendor: "Chiron Technology Ltd"},
  {max_prefix: "00-11-6A",vendor: "Domo Ltd"},
  {max_prefix: "00-11-9D",vendor: "Diginfo Technology Corporation"},
  {max_prefix: "00-11-9A",vendor: "Alkeria srl"},
  {max_prefix: "00-11-9C",vendor: "EP&T Energy"},
  {max_prefix: "00-11-83",vendor: "Datalogic ADC, Inc."},
  {max_prefix: "00-11-7F",vendor: "Neotune Information Technology Corporation,.LTD"},
  {max_prefix: "00-11-7D",vendor: "ZMD America, Inc."},
  {max_prefix: "00-11-7C",vendor: "e-zy.net"},
  {max_prefix: "00-11-D7",vendor: "eWerks Inc"},
  {max_prefix: "00-11-C8",vendor: "Powercom Co., Ltd."},
  {max_prefix: "00-11-B2",vendor: "2001 Technology Inc."},
  {max_prefix: "00-11-AF",vendor: "Medialink-i,Inc"},
  {max_prefix: "00-11-DE",vendor: "EURILOGIC"},
  {max_prefix: "00-11-DF",vendor: "Current Energy"},
  {max_prefix: "00-11-5E",vendor: "ProMinent Dosiertechnik GmbH"},
  {max_prefix: "00-11-57",vendor: "Oki Electric Industry Co., Ltd."},
  {max_prefix: "00-11-39",vendor: "STOEBER ANTRIEBSTECHNIK GmbH + Co. KG."},
  {max_prefix: "00-11-3E",vendor: "JL Corporation"},
  {max_prefix: "00-11-38",vendor: "TAISHIN CO., LTD."},
  {max_prefix: "00-11-36",vendor: "Goodrich Sensor Systems"},
  {max_prefix: "00-11-32",vendor: "Synology Incorporated"},
  {max_prefix: "00-11-29",vendor: "Paradise Datacom Ltd."},
  {max_prefix: "00-11-30",vendor: "Allied Telesis (Hong Kong) Ltd."},
  {max_prefix: "00-11-18",vendor: "BLX IC Design Corp., Ltd."},
  {max_prefix: "00-11-07",vendor: "RGB Networks Inc."},
  {max_prefix: "00-11-08",vendor: "Orbital Data Corporation"},
  {max_prefix: "00-11-0C",vendor: "Atmark Techno, Inc."},
  {max_prefix: "00-11-4B",vendor: "Francotyp-Postalia GmbH"},
  {max_prefix: "00-11-47",vendor: "Secom-Industry co.LTD."},
  {max_prefix: "00-11-4A",vendor: "KAYABA INDUSTRY Co,.Ltd."},
  {max_prefix: "00-11-42",vendor: "e-SMARTCOM  INC."},
  {max_prefix: "00-11-20",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-0F-EC",vendor: "ARKUS Inc."},
  {max_prefix: "00-0F-ED",vendor: "Anam Electronics Co., Ltd"},
  {max_prefix: "00-0F-DD",vendor: "SORDIN AB"},
  {max_prefix: "00-11-4D",vendor: "Atsumi Electric Co.,LTD."},
  {max_prefix: "00-0F-F0",vendor: "Sunray Co. Ltd."},
  {max_prefix: "00-11-03",vendor: "kawamura electric inc."},
  {max_prefix: "00-0F-88",vendor: "AMETEK, Inc."},
  {max_prefix: "00-0F-7E",vendor: "Ablerex Electronics Co., LTD"},
  {max_prefix: "00-0F-83",vendor: "Brainium Technologies Inc."},
  {max_prefix: "00-0F-84",vendor: "Astute Networks, Inc."},
  {max_prefix: "00-0F-D9",vendor: "FlexDSL Telecommunications AG"},
  {max_prefix: "00-0F-D0",vendor: "ASTRI"},
  {max_prefix: "00-0F-AE",vendor: "E2O Communications"},
  {max_prefix: "00-0F-B1",vendor: "Cognio Inc."},
  {max_prefix: "00-0F-9E",vendor: "Murrelektronik GmbH"},
  {max_prefix: "00-0F-93",vendor: "Landis+Gyr Ltd."},
  {max_prefix: "00-0F-94",vendor: "Genexis BV"},
  {max_prefix: "00-0F-CA",vendor: "A-JIN TECHLINE CO, LTD"},
  {max_prefix: "00-0F-C3",vendor: "PalmPalm Technology, Inc."},
  {max_prefix: "00-0F-79",vendor: "Bluetooth Interest Group Inc."},
  {max_prefix: "00-0F-6C",vendor: "ADDI-DATA GmbH"},
  {max_prefix: "00-0F-8F",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-0F-7D",vendor: "Xirrus"},
  {max_prefix: "00-0F-76",vendor: "Digital Keystone, Inc."},
  {max_prefix: "00-0F-A7",vendor: "Raptor Networks Technology"},
  {max_prefix: "00-0F-23",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-0F-22",vendor: "Helius, Inc."},
  {max_prefix: "00-0F-24",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-0F-17",vendor: "Insta Elektro GmbH"},
  {max_prefix: "00-0F-1E",vendor: "Chengdu KT Electric Co.of High & New Technology"},
  {max_prefix: "00-0F-35",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-0F-36",vendor: "Accurate Techhnologies, Inc."},
  {max_prefix: "00-0F-2B",vendor: "GREENBELL SYSTEMS"},
  {max_prefix: "00-0F-28",vendor: "Itronix Corporation"},
  {max_prefix: "00-0E-FC",vendor: "JTAG Technologies B.V."},
  {max_prefix: "00-0E-FE",vendor: "EndRun Technologies LLC"},
  {max_prefix: "00-0E-F3",vendor: "Smartlabs, Inc."},
  {max_prefix: "00-0E-F2",vendor: "Infinico Corporation"},
  {max_prefix: "00-0F-5C",vendor: "Day One Digital Media Limited"},
  {max_prefix: "00-0E-E4",vendor: "BOE TECHNOLOGY GROUP CO.,LTD"},
  {max_prefix: "00-0E-DE",vendor: "REMEC, Inc."},
  {max_prefix: "00-0F-52",vendor: "YORK Refrigeration, Marine & Controls"},
  {max_prefix: "00-0F-4C",vendor: "Elextech INC"},
  {max_prefix: "00-0F-42",vendor: "Xalyo Systems"},
  {max_prefix: "00-0F-39",vendor: "IRIS SENSORS"},
  {max_prefix: "00-0F-3E",vendor: "CardioNet, Inc"},
  {max_prefix: "00-0F-3F",vendor: "Big Bear Networks"},
  {max_prefix: "00-0F-08",vendor: "Indagon Oy"},
  {max_prefix: "00-0F-04",vendor: "cim-usa inc"},
  {max_prefix: "00-0F-0D",vendor: "Hunt Electronic Co., Ltd."},
  {max_prefix: "00-0E-83",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-0E-81",vendor: "Devicescape Software, Inc."},
  {max_prefix: "00-0E-88",vendor: "VIDEOTRON CORP."},
  {max_prefix: "00-0E-86",vendor: "Alcatel North America"},
  {max_prefix: "00-0E-69",vendor: "China Electric Power Research Institute"},
  {max_prefix: "00-0E-61",vendor: "MICROTROL LIMITED"},
  {max_prefix: "00-0E-64",vendor: "Elphel, Inc"},
  {max_prefix: "00-0E-5A",vendor: "TELEFIELD inc."},
  {max_prefix: "00-0E-CE",vendor: "S.I.T.T.I. S.p.A."},
  {max_prefix: "00-0E-D4",vendor: "CRESITT INDUSTRIE"},
  {max_prefix: "00-0E-D6",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-0E-D8",vendor: "Positron Access Solutions Corp"},
  {max_prefix: "00-0E-D1",vendor: "Osaka Micro Computer."},
  {max_prefix: "00-0E-A5",vendor: "BLIP Systems"},
  {max_prefix: "00-0E-A0",vendor: "NetKlass Technology Inc."},
  {max_prefix: "00-0E-9C",vendor: "Benchmark Electronics"},
  {max_prefix: "00-0E-9A",vendor: "BOE TECHNOLOGY GROUP CO.,LTD"},
  {max_prefix: "00-0E-7E",vendor: "ionSign Oy"},
  {max_prefix: "00-0E-77",vendor: "Decru, Inc."},
  {max_prefix: "00-0E-6A",vendor: "3Com Ltd"},
  {max_prefix: "00-0E-90",vendor: "PONICO CORP."},
  {max_prefix: "00-0E-8A",vendor: "Avara Technologies Pty. Ltd."},
  {max_prefix: "00-0E-B8",vendor: "Iiga co.,Ltd"},
  {max_prefix: "00-0E-BB",vendor: "Everbee Networks"},
  {max_prefix: "00-0E-BE",vendor: "B&B Electronics Manufacturing Co."},
  {max_prefix: "00-0E-B0",vendor: "Solutions Radio BV"},
  {max_prefix: "00-0E-4C",vendor: "Bermai Inc."},
  {max_prefix: "00-0E-49",vendor: "Forsway Scandinavia AB"},
  {max_prefix: "00-0E-42",vendor: "Motic Incoporation Ltd."},
  {max_prefix: "00-0E-3D",vendor: "Televic N.V."},
  {max_prefix: "00-0E-39",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-0D-E1",vendor: "Control Products, Inc."},
  {max_prefix: "00-0D-D0",vendor: "TetraTec Instruments GmbH"},
  {max_prefix: "00-0D-D3",vendor: "SAMWOO Telecommunication Co.,Ltd."},
  {max_prefix: "00-0D-D8",vendor: "BBN"},
  {max_prefix: "00-0E-34",vendor: "NexGen City, LP"},
  {max_prefix: "00-0E-2D",vendor: "Hyundai Digital Technology Co.,Ltd."},
  {max_prefix: "00-0E-30",vendor: "AERAS Networks, Inc."},
  {max_prefix: "00-0E-0D",vendor: "Hesch Schr\u00f6der GmbH"},
  {max_prefix: "00-0D-FF",vendor: "CHENMING MOLD INDUSTRY CORP."},
  {max_prefix: "00-0D-F6",vendor: "Technology Thesaurus Corp."},
  {max_prefix: "00-0E-29",vendor: "Shester Communications Inc"},
  {max_prefix: "00-0E-19",vendor: "LogicaCMG Pty Ltd"},
  {max_prefix: "00-0E-5D",vendor: "Triple Play Technologies A/S"},
  {max_prefix: "00-0E-52",vendor: "Optium Corporation"},
  {max_prefix: "00-0E-4D",vendor: "Numesa Inc."},
  {max_prefix: "00-0E-1A",vendor: "JPS Communications"},
  {max_prefix: "00-0E-06",vendor: "Team Simoco Ltd"},
  {max_prefix: "00-0D-AE",vendor: "SAMSUNG HEAVY INDUSTRIES CO., LTD."},
  {max_prefix: "00-0D-B2",vendor: "Ammasso, Inc."},
  {max_prefix: "00-0D-AA",vendor: "S.A.Tehnology co.,Ltd."},
  {max_prefix: "00-0D-A6",vendor: "Universal Switching Corporation"},
  {max_prefix: "00-0D-9C",vendor: "K.A. Schmersal GmbH & Co. KG"},
  {max_prefix: "00-0D-98",vendor: "S.W.A.C. Schmitt-Walter Automation Consult GmbH"},
  {max_prefix: "00-0D-8C",vendor: "Shanghai Wedone Digital Ltd. CO."},
  {max_prefix: "00-0D-94",vendor: "AFAR Communications,Inc"},
  {max_prefix: "00-0D-8D",vendor: "Prosoft Technology, Inc"},
  {max_prefix: "00-0D-7D",vendor: "Afco Systems"},
  {max_prefix: "00-0D-73",vendor: "Technical Support, Inc."},
  {max_prefix: "00-0D-69",vendor: "TMT&D Corporation"},
  {max_prefix: "00-0D-68",vendor: "Vinci Systems, Inc."},
  {max_prefix: "00-0D-64",vendor: "COMAG Handels AG"},
  {max_prefix: "00-0D-5C",vendor: "Robert Bosch GmbH, VT-ATMO"},
  {max_prefix: "00-0D-60",vendor: "IBM Corp"},
  {max_prefix: "00-0D-84",vendor: "Makus Inc."},
  {max_prefix: "00-0D-74",vendor: "Sand Network Systems, Inc."},
  {max_prefix: "00-0D-A2",vendor: "Infrant Technologies, Inc."},
  {max_prefix: "00-0D-C7",vendor: "COSMIC ENGINEERING INC."},
  {max_prefix: "00-0D-C2",vendor: "Private"},
  {max_prefix: "00-0D-BF",vendor: "TekTone Sound & Signal Mfg., Inc."},
  {max_prefix: "00-0D-B3",vendor: "SDO Communication Corperation"},
  {max_prefix: "00-0D-38",vendor: "NISSIN INC."},
  {max_prefix: "00-0D-34",vendor: "Shell International Exploration and Production, Inc."},
  {max_prefix: "00-0D-32",vendor: "DispenseSource, Inc."},
  {max_prefix: "00-0C-FC",vendor: "S2io Technologies Corp"},
  {max_prefix: "00-0C-F6",vendor: "Sitecom Europe BV"},
  {max_prefix: "00-0C-F2",vendor: "GAMESA E\u00f3lica"},
  {max_prefix: "00-0D-5F",vendor: "Minds Inc"},
  {max_prefix: "00-0D-54",vendor: "3Com Ltd"},
  {max_prefix: "00-0D-4C",vendor: "Outline Electronics Ltd."},
  {max_prefix: "00-0D-4D",vendor: "Ninelanes"},
  {max_prefix: "00-0D-12",vendor: "AXELL Corporation"},
  {max_prefix: "00-0D-0A",vendor: "Barco Projection Systems NV"},
  {max_prefix: "00-0C-DC",vendor: "BECS Technology, Inc"},
  {max_prefix: "00-0C-DE",vendor: "ABB STOTZ-KONTAKT GmbH"},
  {max_prefix: "00-0D-15",vendor: "Voipac s.r.o."},
  {max_prefix: "00-0D-0F",vendor: "Finlux Ltd"},
  {max_prefix: "00-0D-2E",vendor: "Matsushita Avionics Systems Corporation"},
  {max_prefix: "00-0D-28",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-0D-53",vendor: "Beijing 5w Communication Corp."},
  {max_prefix: "00-0C-E1",vendor: "The Open Group"},
  {max_prefix: "00-0C-71",vendor: "Wybron, Inc"},
  {max_prefix: "00-0C-72",vendor: "Tempearl Industrial Co., Ltd."},
  {max_prefix: "00-0C-C2",vendor: "ControlNet (India) Private Limited"},
  {max_prefix: "00-0C-C1",vendor: "Eaton Corporation"},
  {max_prefix: "00-0C-A1",vendor: "SIGMACOM Co., LTD."},
  {max_prefix: "00-0C-A6",vendor: "Mintera Corporation"},
  {max_prefix: "00-0C-94",vendor: "United Electronic Industries, Inc. (EUI)"},
  {max_prefix: "00-0C-9B",vendor: "EE Solutions, Inc"},
  {max_prefix: "00-0C-8B",vendor: "Connect Tech Inc"},
  {max_prefix: "00-0C-90",vendor: "Octasic Inc."},
  {max_prefix: "00-0C-8C",vendor: "KODICOM CO.,LTD."},
  {max_prefix: "00-0C-55",vendor: "Microlink Communications Inc."},
  {max_prefix: "00-0C-59",vendor: "Indyme Electronics, Inc."},
  {max_prefix: "00-0C-5C",vendor: "GTN Systems B.V."},
  {max_prefix: "00-0C-52",vendor: "Roll Systems Inc."},
  {max_prefix: "00-0C-78",vendor: "In-Tech Electronics Limited"},
  {max_prefix: "00-0C-74",vendor: "RIVERTEC CORPORATION"},
  {max_prefix: "00-0C-C5",vendor: "Nextlink Co., Ltd."},
  {max_prefix: "00-0C-67",vendor: "OYO ELECTRIC CO.,LTD"},
  {max_prefix: "00-0C-63",vendor: "Zenith Electronics Corporation"},
  {max_prefix: "00-0C-BF",vendor: "Holy Stone Ent. Co., Ltd."},
  {max_prefix: "00-0C-2E",vendor: "Openet information technology(shenzhen) Co., Ltd."},
  {max_prefix: "00-0C-2C",vendor: "Enwiser Inc."},
  {max_prefix: "00-0C-28",vendor: "RIFATRON"},
  {max_prefix: "00-0C-21",vendor: "Faculty of Science and Technology, Keio University"},
  {max_prefix: "00-0C-1B",vendor: "ORACOM Co, Ltd."},
  {max_prefix: "00-0C-44",vendor: "Automated Interfaces, Inc."},
  {max_prefix: "00-0C-3B",vendor: "Orion Electric Co., Ltd."},
  {max_prefix: "00-0C-3D",vendor: "Glsystech Co., Ltd."},
  {max_prefix: "00-0C-14",vendor: "Diagnostic Instruments, Inc."},
  {max_prefix: "00-0C-53",vendor: "Private"},
  {max_prefix: "00-0C-48",vendor: "QoStek Corporation"},
  {max_prefix: "00-0C-4D",vendor: "Curtiss-Wright Controls Avionics & Electronics"},
  {max_prefix: "00-0C-2F",vendor: "SeorimTechnology Co.,Ltd."},
  {max_prefix: "00-0C-31",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-0C-17",vendor: "AJA Video Systems Inc"},
  {max_prefix: "00-0C-11",vendor: "NIPPON DEMPA CO.,LTD."},
  {max_prefix: "00-0B-F7",vendor: "NIDEK CO.,LTD"},
  {max_prefix: "00-0C-00",vendor: "BEB Industrie-Elektronik AG"},
  {max_prefix: "00-0B-F3",vendor: "BAE SYSTEMS"},
  {max_prefix: "00-0B-ED",vendor: "ELM Inc."},
  {max_prefix: "00-0B-B5",vendor: "nStor Technologies, Inc."},
  {max_prefix: "00-0B-B9",vendor: "Imsys AB"},
  {max_prefix: "00-0B-BB",vendor: "Etin Systems Co., Ltd"},
  {max_prefix: "00-0B-BC",vendor: "En Garde Systems, Inc."},
  {max_prefix: "00-0B-B1",vendor: "Super Star Technology Co., Ltd."},
  {max_prefix: "00-0B-BF",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-0B-AD",vendor: "PC-PoS Inc."},
  {max_prefix: "00-0B-A0",vendor: "T&L Information Inc."},
  {max_prefix: "00-0B-91",vendor: "Aglaia Gesellschaft f\u00fcr Bildverarbeitung und Kommunikation mbH"},
  {max_prefix: "00-0B-97",vendor: "Matsushita Electric Industrial Co.,Ltd."},
  {max_prefix: "00-0B-92",vendor: "Ascom Danmark A/S"},
  {max_prefix: "00-0B-52",vendor: "JOYMAX ELECTRONICS CO. LTD."},
  {max_prefix: "00-0B-49",vendor: "RF-Link System Inc."},
  {max_prefix: "00-0B-46",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-0B-C9",vendor: "Electroline Equipment"},
  {max_prefix: "00-0B-C2",vendor: "Corinex Communication Corp."},
  {max_prefix: "00-0B-9A",vendor: "Shanghai Ulink Telecom Equipment Co. Ltd."},
  {max_prefix: "00-0B-96",vendor: "Innotrac Diagnostics Oy"},
  {max_prefix: "00-0B-9D",vendor: "TwinMOS Technologies Inc."},
  {max_prefix: "00-0B-69",vendor: "Franke Finland Oy"},
  {max_prefix: "00-0B-66",vendor: "Teralink Communications"},
  {max_prefix: "00-0B-56",vendor: "Cybernetics"},
  {max_prefix: "00-0B-50",vendor: "Oxygnet"},
  {max_prefix: "00-0B-7A",vendor: "L-3 Linkabit"},
  {max_prefix: "00-0B-84",vendor: "BODET"},
  {max_prefix: "00-0B-77",vendor: "Cogent Systems, Inc."},
  {max_prefix: "00-0A-F2",vendor: "NeoAxiom Corp."},
  {max_prefix: "00-0A-F5",vendor: "Airgo Networks, Inc."},
  {max_prefix: "00-0A-F4",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-0A-F0",vendor: "SHIN-OH ELECTRONICS CO., LTD. R&D"},
  {max_prefix: "00-0A-EE",vendor: "GCD Hard- & Software GmbH"},
  {max_prefix: "00-0A-E9",vendor: "AirVast Technology Inc."},
  {max_prefix: "00-0A-CD",vendor: "Sunrich Technology Limited"},
  {max_prefix: "00-0A-CC",vendor: "Winnow Networks, Inc."},
  {max_prefix: "00-0A-CF",vendor: "PROVIDEO Multimedia Co. Ltd."},
  {max_prefix: "00-0A-D1",vendor: "MWS"},
  {max_prefix: "00-0A-D3",vendor: "INITECH Co., Ltd"},
  {max_prefix: "00-0A-C8",vendor: "ZPSYS CO.,LTD. (Planning&Management)"},
  {max_prefix: "00-0B-2C",vendor: "Eiki Industrial Co. Ltd."},
  {max_prefix: "00-0B-26",vendor: "Wetek Corporation"},
  {max_prefix: "00-0B-28",vendor: "Quatech Inc."},
  {max_prefix: "00-0B-30",vendor: "Beijing Gongye Science & Technology Co.,Ltd"},
  {max_prefix: "00-0B-2A",vendor: "HOWTEL Co., Ltd."},
  {max_prefix: "00-0B-13",vendor: "ZETRON INC"},
  {max_prefix: "00-0B-10",vendor: "11wave Technonlogy Co.,Ltd"},
  {max_prefix: "00-0B-07",vendor: "Voxpath Networks"},
  {max_prefix: "00-0B-04",vendor: "Volktek Corporation"},
  {max_prefix: "00-0A-C6",vendor: "Overture Networks."},
  {max_prefix: "00-0A-AB",vendor: "Toyota Technical Development Corporation"},
  {max_prefix: "00-0B-38",vendor: "Kn\u00fcrr GmbH"},
  {max_prefix: "00-0B-32",vendor: "VORMETRIC, INC."},
  {max_prefix: "00-0A-D8",vendor: "IPCserv Technology Corp."},
  {max_prefix: "00-0B-2B",vendor: "HOSTNET CORPORATION"},
  {max_prefix: "00-0B-1F",vendor: "I CON Computer Co."},
  {max_prefix: "00-0A-F9",vendor: "HiConnect, Inc."},
  {max_prefix: "00-0B-02",vendor: "Dallmeier electronic"},
  {max_prefix: "00-0A-7D",vendor: "Valo, Inc."},
  {max_prefix: "00-0A-7F",vendor: "Teradon Industries, Inc"},
  {max_prefix: "00-0A-81",vendor: "TEIMA Audiotex S.L."},
  {max_prefix: "00-0A-87",vendor: "Integrated Micromachines Inc."},
  {max_prefix: "00-0A-77",vendor: "Bluewire Technologies LLC"},
  {max_prefix: "00-0A-7A",vendor: "Kyoritsu Electric Co., Ltd."},
  {max_prefix: "00-0A-38",vendor: "Apani Networks"},
  {max_prefix: "00-0A-41",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-0A-3E",vendor: "EADS Telecom"},
  {max_prefix: "00-0A-48",vendor: "Albatron Technology"},
  {max_prefix: "00-0A-36",vendor: "Synelec Telecom Multimedia"},
  {max_prefix: "00-0A-32",vendor: "Xsido Corporation"},
  {max_prefix: "00-0A-2B",vendor: "Etherstuff"},
  {max_prefix: "00-0A-9C",vendor: "Server Technology, Inc."},
  {max_prefix: "00-0A-8C",vendor: "Guardware Systems Ltd."},
  {max_prefix: "00-0A-96",vendor: "MEWTEL TECHNOLOGY INC."},
  {max_prefix: "00-0A-82",vendor: "TATSUTA SYSTEM ELECTRONICS CO.,LTD."},
  {max_prefix: "00-0A-54",vendor: "Laguna Hills, Inc."},
  {max_prefix: "00-0A-52",vendor: "AsiaRF Ltd."},
  {max_prefix: "00-0A-4F",vendor: "Brain Boxes Limited"},
  {max_prefix: "00-0A-42",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-0A-65",vendor: "GentechMedia.co.,ltd."},
  {max_prefix: "00-0A-59",vendor: "HW server"},
  {max_prefix: "00-0A-22",vendor: "Amperion Inc"},
  {max_prefix: "00-0A-1C",vendor: "Bridge Information Co., Ltd."},
  {max_prefix: "00-0A-B4",vendor: "ETIC Telecommunications"},
  {max_prefix: "00-0A-75",vendor: "Caterpillar, Inc"},
  {max_prefix: "00-09-D0",vendor: "Solacom Technologies Inc."},
  {max_prefix: "00-09-CC",vendor: "Moog GmbH"},
  {max_prefix: "00-09-C8",vendor: "SINAGAWA TSUSHIN KEISOU SERVICE"},
  {max_prefix: "00-09-B2",vendor: "L&F Inc."},
  {max_prefix: "00-09-A8",vendor: "Eastmode Pte Ltd"},
  {max_prefix: "00-09-AA",vendor: "Data Comm for Business, Inc."},
  {max_prefix: "00-09-A4",vendor: "HARTEC Corporation"},
  {max_prefix: "00-09-A6",vendor: "Ignis Optics, Inc."},
  {max_prefix: "00-09-A7",vendor: "Bang & Olufsen A/S"},
  {max_prefix: "00-09-A0",vendor: "Microtechno Corporation"},
  {max_prefix: "00-09-9B",vendor: "Western Telematic Inc."},
  {max_prefix: "00-09-90",vendor: "ACKSYS Communications & systems"},
  {max_prefix: "00-0A-12",vendor: "Azylex Technology, Inc"},
  {max_prefix: "00-0A-13",vendor: "Honeywell Video Systems"},
  {max_prefix: "00-0A-09",vendor: "TaraCom Integrated Products, Inc."},
  {max_prefix: "00-09-C4",vendor: "Medicore Co., Ltd"},
  {max_prefix: "00-09-B7",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-09-EF",vendor: "Vocera Communications"},
  {max_prefix: "00-09-E4",vendor: "K Tech Infosystem Inc."},
  {max_prefix: "00-09-F0",vendor: "Shimizu Technology Inc."},
  {max_prefix: "00-09-D9",vendor: "Neoscale Systems, Inc"},
  {max_prefix: "00-09-42",vendor: "Wireless Technologies, Inc"},
  {max_prefix: "00-09-45",vendor: "Palmmicro Communications Inc"},
  {max_prefix: "00-09-3E",vendor: "C&I Technologies"},
  {max_prefix: "00-09-40",vendor: "AGFEO GmbH & Co. KG"},
  {max_prefix: "00-09-3B",vendor: "HYUNDAI NETWORKS INC."},
  {max_prefix: "00-09-7F",vendor: "Vsecure 2000 LTD."},
  {max_prefix: "00-09-80",vendor: "Power Zenith Inc."},
  {max_prefix: "00-09-72",vendor: "Securebase,Inc"},
  {max_prefix: "00-09-78",vendor: "AIJI System Co., Ltd."},
  {max_prefix: "00-09-73",vendor: "Lenten Technology Co., Ltd."},
  {max_prefix: "00-09-75",vendor: "fSONA Communications Corporation"},
  {max_prefix: "00-09-77",vendor: "Brunner Elektronik AG"},
  {max_prefix: "00-09-69",vendor: "Meret Optical Communications"},
  {max_prefix: "00-09-6E",vendor: "GIANT ELECTRONICS LTD."},
  {max_prefix: "00-09-20",vendor: "EpoX COMPUTER CO.,LTD."},
  {max_prefix: "00-09-22",vendor: "TST Biometrics GmbH"},
  {max_prefix: "00-09-16",vendor: "Listman Home Technologies, Inc."},
  {max_prefix: "00-09-6C",vendor: "Imedia Semiconductor Corp."},
  {max_prefix: "00-09-5F",vendor: "Telebyte, Inc."},
  {max_prefix: "00-09-53",vendor: "Linkage System Integration Co.Ltd."},
  {max_prefix: "00-09-4C",vendor: "Communication Weaver Co.,Ltd."},
  {max_prefix: "00-09-30",vendor: "AeroConcierge Inc."},
  {max_prefix: "00-09-1E",vendor: "Firstech Technology Corp."},
  {max_prefix: "00-09-34",vendor: "Dream-Multimedia-Tv GmbH"},
  {max_prefix: "00-09-8A",vendor: "EqualLogic Inc"},
  {max_prefix: "00-08-A5",vendor: "Peninsula Systems Inc."},
  {max_prefix: "00-08-A2",vendor: "ADI Engineering, Inc."},
  {max_prefix: "00-08-98",vendor: "Gigabit Optics Corporation"},
  {max_prefix: "00-08-9B",vendor: "ICP Electronics Inc."},
  {max_prefix: "00-08-9C",vendor: "Elecs Industry Co., Ltd."},
  {max_prefix: "00-08-9D",vendor: "UHD-Elektronik"},
  {max_prefix: "00-08-CF",vendor: "Nippon Koei Power Systems Co., Ltd."},
  {max_prefix: "00-08-CB",vendor: "Zeta Broadband Inc."},
  {max_prefix: "00-08-D3",vendor: "Hercules Technologies S.A.S."},
  {max_prefix: "00-08-D0",vendor: "Musashi Engineering Co., LTD."},
  {max_prefix: "00-08-F4",vendor: "Bluetake Technology Co., Ltd."},
  {max_prefix: "00-08-F7",vendor: "Hitachi Ltd, Semiconductor & Integrated Circuits Gr"},
  {max_prefix: "00-08-F5",vendor: "YESTECHNOLOGY Co.,Ltd."},
  {max_prefix: "00-09-11",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-09-12",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-09-08",vendor: "VTech Technology Corp."},
  {max_prefix: "00-09-0B",vendor: "MTL  Instruments PLC"},
  {max_prefix: "00-08-B5",vendor: "TAI GUEN ENTERPRISE CO., LTD"},
  {max_prefix: "00-08-B7",vendor: "HIT Incorporated"},
  {max_prefix: "00-08-A9",vendor: "SangSang Technology, Inc."},
  {max_prefix: "00-08-C8",vendor: "Soneticom, Inc."},
  {max_prefix: "00-08-C4",vendor: "Hikari Co.,Ltd."},
  {max_prefix: "00-08-BA",vendor: "Erskine Systems Ltd"},
  {max_prefix: "00-08-8F",vendor: "ADVANCED DIGITAL TECHNOLOGY"},
  {max_prefix: "00-08-8B",vendor: "Tropic Networks Inc."},
  {max_prefix: "00-08-86",vendor: "Hansung Teliann, Inc."},
  {max_prefix: "00-08-EC",vendor: "Optical Zonu Corporation"},
  {max_prefix: "00-08-E6",vendor: "Littlefeet"},
  {max_prefix: "00-08-E2",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-09-05",vendor: "iTEC Technologies Ltd."},
  {max_prefix: "00-08-F9",vendor: "Artesyn Embedded Technologies"},
  {max_prefix: "00-07-E1",vendor: "WIS Communications Co. Ltd."},
  {max_prefix: "00-07-E0",vendor: "Palm Inc."},
  {max_prefix: "00-07-D6",vendor: "Commil Ltd."},
  {max_prefix: "00-07-D7",vendor: "Caporis Networks AG"},
  {max_prefix: "00-07-D4",vendor: "Zhejiang Yutong Network Communication Co Ltd."},
  {max_prefix: "00-07-CE",vendor: "Cabletime Limited"},
  {max_prefix: "00-07-D3",vendor: "SPGPrints B.V."},
  {max_prefix: "00-07-D0",vendor: "Automat Engenharia de Automa\u00e7\u00e3o Ltda."},
  {max_prefix: "00-08-5D",vendor: "Mitel Corporation"},
  {max_prefix: "00-08-55",vendor: "NASA-Goddard Space Flight Center"},
  {max_prefix: "00-08-5A",vendor: "IntiGate Inc."},
  {max_prefix: "00-08-58",vendor: "Novatechnology Inc."},
  {max_prefix: "00-08-50",vendor: "Arizona Instrument Corp."},
  {max_prefix: "00-08-5B",vendor: "Hanbit Electronics Co., Ltd."},
  {max_prefix: "00-07-ED",vendor: "Altera Corporation"},
  {max_prefix: "00-07-EA",vendor: "Massana, Inc."},
  {max_prefix: "00-07-F1",vendor: "TeraBurst Networks Inc."},
  {max_prefix: "00-07-F2",vendor: "IOA Corporation"},
  {max_prefix: "00-07-F0",vendor: "LogiSync LLC"},
  {max_prefix: "00-07-E7",vendor: "FreeWave Technologies"},
  {max_prefix: "00-07-E3",vendor: "Navcom Technology, Inc."},
  {max_prefix: "00-08-17",vendor: "EmergeCore Networks LLC"},
  {max_prefix: "00-08-15",vendor: "CATS Co., Ltd."},
  {max_prefix: "00-08-18",vendor: "Pixelworks, Inc."},
  {max_prefix: "00-08-0F",vendor: "Proximion Fiber Optics AB"},
  {max_prefix: "00-08-12",vendor: "GM-2 Corporation"},
  {max_prefix: "00-08-0B",vendor: "Birka BPA Informationssystem AB"},
  {max_prefix: "00-08-0A",vendor: "Espera-Werke GmbH"},
  {max_prefix: "00-07-FA",vendor: "ITT Co., Ltd."},
  {max_prefix: "00-07-F6",vendor: "Qqest Software Systems"},
  {max_prefix: "00-07-FB",vendor: "Giga Stream UMTS Technologies GmbH"},
  {max_prefix: "00-04-7C",vendor: "Skidata AG"},
  {max_prefix: "00-07-BE",vendor: "DataLogic SpA"},
  {max_prefix: "00-08-2B",vendor: "Wooksung Electronics, Inc."},
  {max_prefix: "00-08-2E",vendor: "Multitone Electronics PLC"},
  {max_prefix: "00-08-2A",vendor: "Powerwallz Network Security"},
  {max_prefix: "00-08-6B",vendor: "MIPSYS"},
  {max_prefix: "00-08-7F",vendor: "SPAUN electronic GmbH & Co. KG"},
  {max_prefix: "00-07-AF",vendor: "Red Lion Controls, LP"},
  {max_prefix: "00-07-B2",vendor: "Transaccess S.A."},
  {max_prefix: "00-07-AD",vendor: "Pentacon GmbH Foto-und Feinwerktechnik"},
  {max_prefix: "00-07-AC",vendor: "Eolring"},
  {max_prefix: "00-07-AA",vendor: "Quantum Data Inc."},
  {max_prefix: "00-07-A4",vendor: "GN Netcom Ltd."},
  {max_prefix: "00-07-9D",vendor: "Musashi Co., Ltd."},
  {max_prefix: "00-07-9F",vendor: "Action Digital Inc."},
  {max_prefix: "00-07-92",vendor: "S\u00fctron Electronic GmbH"},
  {max_prefix: "00-07-90",vendor: "Tri-M Technologies (s) Limited"},
  {max_prefix: "00-07-8D",vendor: "NetEngines Ltd."},
  {max_prefix: "00-07-8A",vendor: "Mentor Data System Inc."},
  {max_prefix: "00-07-6A",vendor: "NEXTEYE Co., Ltd."},
  {max_prefix: "00-07-67",vendor: "Yuxing Electronics Company Limited"},
  {max_prefix: "00-07-5B",vendor: "Gibson Guitars"},
  {max_prefix: "00-07-62",vendor: "Group Sense Limited"},
  {max_prefix: "00-07-84",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-07-76",vendor: "Federal APD"},
  {max_prefix: "00-07-7A",vendor: "Infoware System Co., Ltd."},
  {max_prefix: "00-07-0C",vendor: "SVA-Intrusion.com Co. Ltd."},
  {max_prefix: "00-07-0F",vendor: "Fujant, Inc."},
  {max_prefix: "00-07-27",vendor: "Zi Corporation (HK) Ltd."},
  {max_prefix: "00-07-2E",vendor: "North Node AB"},
  {max_prefix: "00-06-E6",vendor: "DongYang Telecom Co., Ltd."},
  {max_prefix: "00-06-D2",vendor: "Tundra Semiconductor Corp."},
  {max_prefix: "00-06-D8",vendor: "Maple Optical Systems"},
  {max_prefix: "00-06-CF",vendor: "Thales Avionics In-Flight Systems, LLC"},
  {max_prefix: "00-06-B6",vendor: "Nir-Or Israel Ltd."},
  {max_prefix: "00-06-A3",vendor: "Bitran Corporation"},
  {max_prefix: "00-06-9F",vendor: "Kuokoa Networks"},
  {max_prefix: "00-06-A2",vendor: "Microtune, Inc."},
  {max_prefix: "00-06-A6",vendor: "Artistic Licence Engineering Ltd"},
  {max_prefix: "00-06-AA",vendor: "VT Miltope"},
  {max_prefix: "00-06-57",vendor: "Market Central, Inc."},
  {max_prefix: "00-06-DC",vendor: "Syabas Technology (Amquest)"},
  {max_prefix: "00-06-97",vendor: "R & D Center"},
  {max_prefix: "00-06-9A",vendor: "e & Tel"},
  {max_prefix: "00-06-99",vendor: "Vida Design Co."},
  {max_prefix: "00-06-8D",vendor: "SEPATON, Inc."},
  {max_prefix: "00-06-84",vendor: "Biacore AB"},
  {max_prefix: "00-06-82",vendor: "Convedia"},
  {max_prefix: "00-06-BB",vendor: "ATI Technologies Inc."},
  {max_prefix: "00-06-BD",vendor: "BNTECHNOLOGY Co., Ltd."},
  {max_prefix: "00-06-C3",vendor: "Schindler Elevator Ltd."},
  {max_prefix: "00-06-B2",vendor: "Linxtek Co."},
  {max_prefix: "00-06-FE",vendor: "Ambrado, Inc"},
  {max_prefix: "00-06-E7",vendor: "Bit Blitz Communications Inc."},
  {max_prefix: "00-06-ED",vendor: "Inara Networks"},
  {max_prefix: "00-07-14",vendor: "Brightcom"},
  {max_prefix: "00-06-F3",vendor: "AcceLight Networks"},
  {max_prefix: "00-06-DB",vendor: "ICHIPS Co., Ltd."},
  {max_prefix: "00-06-B8",vendor: "Bandspeed Pty Ltd"},
  {max_prefix: "00-06-6B",vendor: "Sysmex Corporation"},
  {max_prefix: "00-05-5A",vendor: "Power Dsine Ltd."},
  {max_prefix: "00-06-53",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-06-4F",vendor: "PRO-NETS Technology Corporation"},
  {max_prefix: "00-06-51",vendor: "Aspen Networks Inc."},
  {max_prefix: "00-06-5C",vendor: "Malachite Technologies, Inc."},
  {max_prefix: "00-06-22",vendor: "Chung Fu Chen Yeh Enterprise Corp."},
  {max_prefix: "00-06-12",vendor: "Accusys, Inc."},
  {max_prefix: "00-06-09",vendor: "Crossport Systems"},
  {max_prefix: "00-06-16",vendor: "Tel Net Co., Ltd."},
  {max_prefix: "00-06-0D",vendor: "Wave7 Optics"},
  {max_prefix: "00-06-42",vendor: "Genetel Systems Inc."},
  {max_prefix: "00-06-4A",vendor: "Honeywell Co., Ltd. (KOREA)"},
  {max_prefix: "00-06-3F",vendor: "Everex Communications Inc."},
  {max_prefix: "00-06-3D",vendor: "Microwave Data Systems Inc."},
  {max_prefix: "00-06-39",vendor: "Newtec"},
  {max_prefix: "00-05-EF",vendor: "ADOIR Digital Technology"},
  {max_prefix: "00-05-E9",vendor: "Unicess Network, Inc."},
  {max_prefix: "00-06-00",vendor: "Toshiba Teli Corporation"},
  {max_prefix: "00-05-E6",vendor: "Egenera, Inc."},
  {max_prefix: "00-06-7D",vendor: "Takasago Ltd."},
  {max_prefix: "00-06-71",vendor: "Softing AG"},
  {max_prefix: "00-06-72",vendor: "Netezza"},
  {max_prefix: "00-06-2F",vendor: "Pivotech Systems Inc."},
  {max_prefix: "00-06-36",vendor: "Jedai Broadband Networks"},
  {max_prefix: "00-CB-BD",vendor: "Cambridge Broadband Networks Group"},
  {max_prefix: "00-05-E3",vendor: "LightSand Communications, Inc."},
  {max_prefix: "00-05-ED",vendor: "Technikum Joanneum GmbH"},
  {max_prefix: "00-06-6A",vendor: "InfiniCon Systems, Inc."},
  {max_prefix: "00-06-61",vendor: "NIA Home Technologies Corp."},
  {max_prefix: "00-06-1E",vendor: "Maxan Systems"},
  {max_prefix: "00-05-C6",vendor: "Triz Communications"},
  {max_prefix: "00-05-CC",vendor: "Sumtel Communications, Inc."},
  {max_prefix: "00-05-CF",vendor: "Thunder River Technologies, Inc."},
  {max_prefix: "00-05-A0",vendor: "MOBILINE Kft."},
  {max_prefix: "00-05-A6",vendor: "Extron Electronics"},
  {max_prefix: "00-05-88",vendor: "Sensoria Corp."},
  {max_prefix: "00-05-8D",vendor: "Lynx Photonic Networks, Inc."},
  {max_prefix: "00-05-8A",vendor: "Netcom Co., Ltd."},
  {max_prefix: "00-05-91",vendor: "Active Silicon Ltd"},
  {max_prefix: "00-05-93",vendor: "Grammar Engine Inc."},
  {max_prefix: "00-05-8F",vendor: "CLCsoft co."},
  {max_prefix: "00-05-84",vendor: "AbsoluteValue Systems, Inc."},
  {max_prefix: "00-05-D6",vendor: "L-3 Linkabit"},
  {max_prefix: "00-05-DA",vendor: "Apex Automationstechnik"},
  {max_prefix: "00-05-C7",vendor: "I/F-COM A/S"},
  {max_prefix: "00-05-64",vendor: "Tsinghua Bitway Co., Ltd."},
  {max_prefix: "00-05-58",vendor: "Synchronous, Inc."},
  {max_prefix: "00-05-50",vendor: "Vcomms Connect Limited"},
  {max_prefix: "00-05-A9",vendor: "Princeton Networks, Inc."},
  {max_prefix: "00-05-AF",vendor: "InnoScan Computing A/S"},
  {max_prefix: "00-05-A1",vendor: "Zenocom"},
  {max_prefix: "00-05-41",vendor: "Advanced Systems Co., Ltd."},
  {max_prefix: "00-05-45",vendor: "Internet Photonics"},
  {max_prefix: "00-05-7A",vendor: "Overture Networks"},
  {max_prefix: "00-05-BB",vendor: "Myspace AB"},
  {max_prefix: "00-04-F8",vendor: "QUALICABLE TV Industria E Com., Ltda"},
  {max_prefix: "00-04-F5",vendor: "SnowShore Networks, Inc."},
  {max_prefix: "00-04-F2",vendor: "Polycom"},
  {max_prefix: "00-04-F3",vendor: "FS FORTH-SYSTEME GmbH"},
  {max_prefix: "00-04-ED",vendor: "Billion Electric Co., Ltd."},
  {max_prefix: "00-04-E7",vendor: "Lightpointe Communications, Inc"},
  {max_prefix: "00-04-E6",vendor: "Banyan Network Private Limited"},
  {max_prefix: "00-04-DE",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-05-31",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-05-39",vendor: "A Brand New World in Sweden AB"},
  {max_prefix: "00-05-2A",vendor: "Ikegami Tsushinki Co., Ltd."},
  {max_prefix: "00-05-2D",vendor: "Zoltrix International Limited"},
  {max_prefix: "00-05-17",vendor: "Shellcomm, Inc."},
  {max_prefix: "00-05-1C",vendor: "Xnet Technology Corp."},
  {max_prefix: "00-05-12",vendor: "Zebra Technologies Inc"},
  {max_prefix: "00-05-03",vendor: "ICONAG"},
  {max_prefix: "00-04-F9",vendor: "Xtera Communications, Inc."},
  {max_prefix: "00-04-FA",vendor: "NBS Technologies Inc."},
  {max_prefix: "00-05-3A",vendor: "Willowglen Services Pte Ltd"},
  {max_prefix: "00-04-E1",vendor: "Infinior Microsystems"},
  {max_prefix: "00-05-25",vendor: "Puretek Industrial Co., Ltd."},
  {max_prefix: "00-04-A6",vendor: "SAF Tehnika Ltd."},
  {max_prefix: "00-04-A8",vendor: "Broadmax Technologies, Inc."},
  {max_prefix: "00-04-A1",vendor: "Pathway Connectivity"},
  {max_prefix: "00-04-A2",vendor: "L.S.I. Japan Co., Ltd."},
  {max_prefix: "00-04-9B",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-04-7A",vendor: "AXXESSIT ASA"},
  {max_prefix: "00-04-6A",vendor: "Navini Networks"},
  {max_prefix: "00-04-6B",vendor: "Palm Wireless, Inc."},
  {max_prefix: "00-04-72",vendor: "Telelynx, Inc."},
  {max_prefix: "00-04-64",vendor: "Pulse-Link Inc"},
  {max_prefix: "00-04-90",vendor: "Optical Access"},
  {max_prefix: "00-04-86",vendor: "ITTC, University of Kansas"},
  {max_prefix: "00-04-8B",vendor: "Poscon Corporation"},
  {max_prefix: "00-04-84",vendor: "Amann GmbH"},
  {max_prefix: "00-04-78",vendor: "G. Star Technology Corporation"},
  {max_prefix: "00-04-4E",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-04-52",vendor: "RocketLogix, Inc."},
  {max_prefix: "00-04-42",vendor: "NACT"},
  {max_prefix: "00-04-45",vendor: "LMS Skalar Instruments GmbH"},
  {max_prefix: "00-04-3D",vendor: "INDEL AG"},
  {max_prefix: "00-04-3B",vendor: "Lava Computer Mfg., Inc."},
  {max_prefix: "00-04-31",vendor: "GlobalStreams, Inc."},
  {max_prefix: "00-04-99",vendor: "Chino Corporation"},
  {max_prefix: "00-04-8E",vendor: "Ohm Tech Labs, Inc."},
  {max_prefix: "00-04-8D",vendor: " Teo Technologies, Inc"},
  {max_prefix: "00-04-CE",vendor: "Patria Ailon"},
  {max_prefix: "00-04-C7",vendor: "NetMount"},
  {max_prefix: "00-04-65",vendor: "i.s.t isdn-support technik GmbH"},
  {max_prefix: "00-04-5E",vendor: "PolyTrax Information Technology AG"},
  {max_prefix: "00-04-C2",vendor: "Magnipix, Inc."},
  {max_prefix: "00-03-C9",vendor: "TECOM Co., Ltd."},
  {max_prefix: "00-03-C1",vendor: "Packet Dynamics Ltd"},
  {max_prefix: "00-03-C4",vendor: "Tomra Systems ASA"},
  {max_prefix: "00-03-BF",vendor: "Centerpoint Broadband Technologies, Inc."},
  {max_prefix: "00-03-FD",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-03-F9",vendor: "Pleiades Communications, Inc."},
  {max_prefix: "00-03-FE",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-03-B9",vendor: "Hualong Telecom Co., Ltd."},
  {max_prefix: "00-03-BA",vendor: "Oracle Corporation"},
  {max_prefix: "00-03-AF",vendor: "Paragea Communications"},
  {max_prefix: "00-04-11",vendor: "Inkra Networks, Inc."},
  {max_prefix: "00-04-10",vendor: "Spinnaker Networks, Inc."},
  {max_prefix: "00-04-12",vendor: "WaveSmith Networks, Inc."},
  {max_prefix: "00-03-AC",vendor: "Fronius Schweissmaschinen"},
  {max_prefix: "00-03-99",vendor: "Dongju Informations & Communications Co., Ltd."},
  {max_prefix: "00-03-0B",vendor: "Hunter Technology, Inc."},
  {max_prefix: "00-04-27",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-04-20",vendor: "Slim Devices, Inc."},
  {max_prefix: "00-03-DE",vendor: "OTC Wireless"},
  {max_prefix: "00-03-F5",vendor: "Chip2Chip"},
  {max_prefix: "00-03-30",vendor: "Imagenics, Co., Ltd."},
  {max_prefix: "00-03-28",vendor: "Mace Group, Inc."},
  {max_prefix: "00-03-2B",vendor: "GAI Datenfunksysteme GmbH"},
  {max_prefix: "00-03-2C",vendor: "ABB Switzerland Ltd"},
  {max_prefix: "00-03-23",vendor: "Cornet Technology, Inc."},
  {max_prefix: "00-03-13",vendor: "Access Media SPA"},
  {max_prefix: "00-03-10",vendor: "E-Globaledge Corporation"},
  {max_prefix: "00-03-0A",vendor: "Argus Technologies"},
  {max_prefix: "00-03-04",vendor: "Pacific Broadband Communications"},
  {max_prefix: "00-03-01",vendor: "EXFO"},
  {max_prefix: "00-02-FD",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-03-40",vendor: "Floware Wireless Systems, Ltd."},
  {max_prefix: "00-01-EC",vendor: "Ericsson Group"},
  {max_prefix: "00-03-33",vendor: "Digitel Co., Ltd."},
  {max_prefix: "00-03-38",vendor: "Oak Technology"},
  {max_prefix: "00-03-39",vendor: "Eurologic Systems, Ltd."},
  {max_prefix: "00-03-31",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-03-81",vendor: "Ingenico International"},
  {max_prefix: "00-03-80",vendor: "SSH Communications Security Corp."},
  {max_prefix: "00-03-82",vendor: "A-One Co., Ltd."},
  {max_prefix: "00-03-7A",vendor: "Taiyo Yuden Co., Ltd."},
  {max_prefix: "00-03-75",vendor: "NetMedia, Inc."},
  {max_prefix: "00-03-91",vendor: "Advanced Digital Broadcast, Ltd."},
  {max_prefix: "00-03-8F",vendor: "Weinschel Corporation"},
  {max_prefix: "00-03-84",vendor: "AETA"},
  {max_prefix: "00-03-87",vendor: "Blaze Network Products"},
  {max_prefix: "00-03-5A",vendor: "Photron Limited"},
  {max_prefix: "00-03-53",vendor: "Mitac, Inc."},
  {max_prefix: "00-03-1E",vendor: "Optranet, Inc."},
  {max_prefix: "00-03-15",vendor: "Cidco Incorporated"},
  {max_prefix: "00-03-19",vendor: "Infineon AG"},
  {max_prefix: "00-03-7B",vendor: "IDEC IZUMI Corporation"},
  {max_prefix: "00-03-67",vendor: "Jasmine Networks, Inc."},
  {max_prefix: "00-03-6A",vendor: "Mainnet, Ltd."},
  {max_prefix: "00-03-6B",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-03-6C",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-03-4F",vendor: "Sur-Gard Security"},
  {max_prefix: "00-03-4A",vendor: "RIAS Corporation"},
  {max_prefix: "00-02-B6",vendor: "Acrosser Technology Co., Ltd."},
  {max_prefix: "00-02-B1",vendor: "Anritsu, Ltd."},
  {max_prefix: "00-02-AD",vendor: "HOYA Corporation"},
  {max_prefix: "00-02-AE",vendor: "Scannex Electronics Ltd."},
  {max_prefix: "00-02-A2",vendor: "Hilscher GmbH"},
  {max_prefix: "00-02-C2",vendor: "Net Vision Telecom"},
  {max_prefix: "00-02-BD",vendor: "Bionet Co., Ltd."},
  {max_prefix: "00-02-BE",vendor: "Totsu Engineering, Inc."},
  {max_prefix: "00-02-B9",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-02-BA",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-03-00",vendor: "Barracuda Networks, Inc."},
  {max_prefix: "00-02-F9",vendor: "MIMOS Berhad"},
  {max_prefix: "00-02-F3",vendor: "Media Serve Co., Ltd."},
  {max_prefix: "00-02-EA",vendor: "Focus Enhancements"},
  {max_prefix: "00-02-5A",vendor: "Catena Networks"},
  {max_prefix: "00-02-6E",vendor: "NeGeN Access, Inc."},
  {max_prefix: "00-02-E7",vendor: "CAB GmbH & Co KG"},
  {max_prefix: "00-02-DF",vendor: "Net Com Systems, Inc."},
  {max_prefix: "00-02-DB",vendor: "NETSEC"},
  {max_prefix: "00-02-70",vendor: "Crewave Co., Ltd."},
  {max_prefix: "00-02-9A",vendor: "Storage Apps"},
  {max_prefix: "00-02-8F",vendor: "Globetek, Inc."},
  {max_prefix: "00-02-87",vendor: "Adapcom"},
  {max_prefix: "00-02-81",vendor: "Madge Ltd."},
  {max_prefix: "00-02-CF",vendor: "ZyGate Communications, Inc."},
  {max_prefix: "00-02-D1",vendor: "Vivotek, Inc."},
  {max_prefix: "00-02-54",vendor: "WorldGate"},
  {max_prefix: "00-02-4B",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-02-4D",vendor: "Mannesman Dematic Colby Pty. Ltd."},
  {max_prefix: "00-02-50",vendor: "Geyser Networks, Inc."},
  {max_prefix: "00-01-D9",vendor: "Sigma, Inc."},
  {max_prefix: "00-01-C5",vendor: "Simpler Networks"},
  {max_prefix: "00-01-C9",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-01-C4",vendor: "NeoWave, Inc."},
  {max_prefix: "00-02-00",vendor: "Net & Sys Co., Ltd."},
  {max_prefix: "00-01-E4",vendor: "Sitera, Inc."},
  {max_prefix: "00-01-F3",vendor: "QPS, Inc."},
  {max_prefix: "00-01-E3",vendor: "Siemens AG"},
  {max_prefix: "00-02-48",vendor: "Pilz GmbH & Co."},
  {max_prefix: "00-02-4C",vendor: "SiByte, Inc."},
  {max_prefix: "00-02-40",vendor: "Seedek Co., Ltd."},
  {max_prefix: "00-02-3B",vendor: "Ericsson"},
  {max_prefix: "00-01-EB",vendor: "C-COM Corporation"},
  {max_prefix: "00-01-F2",vendor: "Mark of the Unicorn, Inc."},
  {max_prefix: "00-01-D8",vendor: "Teltronics, Inc."},
  {max_prefix: "00-02-39",vendor: "Visicom"},
  {max_prefix: "00-02-33",vendor: "Mantra Communications, Inc."},
  {max_prefix: "00-02-2A",vendor: "Asound Electronic"},
  {max_prefix: "00-02-05",vendor: "Hitachi Denshi, Ltd."},
  {max_prefix: "00-01-F7",vendor: "Image Display Systems, Inc."},
  {max_prefix: "00-02-21",vendor: "DSP Application, Ltd."},
  {max_prefix: "00-02-15",vendor: "Cotas Computer Technology A/B"},
  {max_prefix: "00-02-1B",vendor: "Kollmorgen-Servotronix"},
  {max_prefix: "00-02-1E",vendor: "SIMTEL S.R.L."},
  {max_prefix: "00-01-8A",vendor: "ROI COMPUTER AG"},
  {max_prefix: "00-01-92",vendor: "Texas Digital Systems"},
  {max_prefix: "00-01-82",vendor: "DICA TECHNOLOGIES AG"},
  {max_prefix: "00-01-89",vendor: "Refraction Technology, Inc."},
  {max_prefix: "00-01-93",vendor: "Hanbyul Telecom Co., Ltd."},
  {max_prefix: "00-30-F5",vendor: "Wild Lab. Ltd."},
  {max_prefix: "00-01-45",vendor: "WINSYSTEMS, INC."},
  {max_prefix: "00-01-37",vendor: "IT Farm Corporation"},
  {max_prefix: "00-01-33",vendor: "KYOWA Electronic Instruments C"},
  {max_prefix: "00-01-3C",vendor: "TIW SYSTEMS"},
  {max_prefix: "00-01-4C",vendor: "Berkeley Process Control"},
  {max_prefix: "00-01-A3",vendor: "GENESYS LOGIC, INC."},
  {max_prefix: "00-01-8C",vendor: "Mega Vision"},
  {max_prefix: "00-01-8F",vendor: "Kenetec, Inc."},
  {max_prefix: "00-01-7B",vendor: "Heidelberger Druckmaschinen AG"},
  {max_prefix: "00-01-73",vendor: "AMCC"},
  {max_prefix: "00-01-6C",vendor: "FOXCONN"},
  {max_prefix: "00-01-75",vendor: "Radiant Communications Corp."},
  {max_prefix: "00-01-5C",vendor: "CADANT INC."},
  {max_prefix: "00-01-AB",vendor: "Main Street Networks"},
  {max_prefix: "00-01-AF",vendor: "Artesyn Embedded Technologies"},
  {max_prefix: "00-01-69",vendor: "Celestix Networks Pte Ltd."},
  {max_prefix: "00-01-6B",vendor: "LightChip, Inc."},
  {max_prefix: "00-01-A5",vendor: "Nextcomm, Inc."},
  {max_prefix: "00-01-90",vendor: "SMK-M"},
  {max_prefix: "00-01-C1",vendor: "Vitesse Semiconductor Corporation"},
  {max_prefix: "00-01-B6",vendor: "SAEJIN T&M Co., Ltd."},
  {max_prefix: "00-01-5D",vendor: "Oracle Corporation"},
  {max_prefix: "00-B0-2A",vendor: "ORSYS GmbH"},
  {max_prefix: "00-B0-69",vendor: "Honewell Oy"},
  {max_prefix: "00-B0-C2",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-B0-3B",vendor: "HiQ Networks"},
  {max_prefix: "00-B0-86",vendor: "LocSoft Limited"},
  {max_prefix: "00-30-46",vendor: "Controlled Electronic Manageme"},
  {max_prefix: "00-30-75",vendor: "ADTECH"},
  {max_prefix: "00-30-98",vendor: "Global Converging Technologies"},
  {max_prefix: "00-30-0D",vendor: "MMC Technology, Inc."},
  {max_prefix: "00-30-A2",vendor: "Lightner Engineering"},
  {max_prefix: "00-30-42",vendor: "DeTeWe-Deutsche Telephonwerke"},
  {max_prefix: "00-30-37",vendor: "Packard Bell Nec Services"},
  {max_prefix: "00-30-57",vendor: "QTelNet, Inc."},
  {max_prefix: "00-30-FC",vendor: "Terawave Communications, Inc."},
  {max_prefix: "00-01-27",vendor: "OPEN Networks Pty Ltd"},
  {max_prefix: "00-01-04",vendor: "DVICO Co., Ltd."},
  {max_prefix: "00-01-0E",vendor: "Bri-Link Technologies Co., Ltd"},
  {max_prefix: "00-01-3D",vendor: "RiscStation Ltd."},
  {max_prefix: "00-01-43",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-01-4B",vendor: "Ennovate Networks, Inc."},
  {max_prefix: "00-01-20",vendor: "OSCILLOQUARTZ S.A."},
  {max_prefix: "00-30-C2",vendor: "COMONE"},
  {max_prefix: "00-30-F3",vendor: "At Work Computers"},
  {max_prefix: "00-30-CC",vendor: "Tenor Networks, Inc."},
  {max_prefix: "00-30-B0",vendor: "Convergenet Technologies"},
  {max_prefix: "00-02-9C",vendor: "3COM"},
  {max_prefix: "00-B0-19",vendor: "UTC CCS"},
  {max_prefix: "00-B0-C7",vendor: "Tellabs Operations, Inc."},
  {max_prefix: "00-30-86",vendor: "Transistor Devices, Inc."},
  {max_prefix: "00-01-06",vendor: "Tews Datentechnik GmbH"},
  {max_prefix: "00-01-09",vendor: "Nagano Japan Radio Co., Ltd."},
  {max_prefix: "00-30-5F",vendor: "Hasselblad"},
  {max_prefix: "00-30-DC",vendor: "RIGHTECH CORPORATION"},
  {max_prefix: "00-30-25",vendor: "CHECKOUT COMPUTER SYSTEMS, LTD"},
  {max_prefix: "00-30-C6",vendor: "CONTROL SOLUTIONS, INC."},
  {max_prefix: "00-30-E3",vendor: "SEDONA NETWORKS CORP."},
  {max_prefix: "00-30-BF",vendor: "MULTIDATA GMBH"},
  {max_prefix: "00-30-34",vendor: "SET ENGINEERING"},
  {max_prefix: "00-30-4A",vendor: "Fraunhofer IPMS"},
  {max_prefix: "00-30-8D",vendor: "Pinnacle Systems, Inc."},
  {max_prefix: "00-30-F6",vendor: "SECURELOGIX CORPORATION"},
  {max_prefix: "00-30-D6",vendor: "MSC VERTRIEBS GMBH"},
  {max_prefix: "00-30-41",vendor: "SAEJIN T & M CO., LTD."},
  {max_prefix: "00-30-8C",vendor: "Quantum Corporation"},
  {max_prefix: "00-30-19",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-30-76",vendor: "Akamba Corporation"},
  {max_prefix: "00-30-EC",vendor: "BORGARDT"},
  {max_prefix: "00-30-3C",vendor: "ONNTO CORP."},
  {max_prefix: "00-30-24",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-30-D8",vendor: "SITEK"},
  {max_prefix: "00-30-16",vendor: "ISHIDA CO., LTD."},
  {max_prefix: "00-30-6F",vendor: "SEYEON TECH. CO., LTD."},
  {max_prefix: "00-30-3D",vendor: "IVA CORPORATION"},
  {max_prefix: "00-30-F4",vendor: "STARDOT TECHNOLOGIES"},
  {max_prefix: "00-30-EB",vendor: "TURBONET COMMUNICATIONS, INC."},
  {max_prefix: "00-30-A1",vendor: "WEBGATE Inc."},
  {max_prefix: "00-30-6A",vendor: "PENTA MEDIA CO., LTD."},
  {max_prefix: "00-30-53",vendor: "Basler AG"},
  {max_prefix: "00-30-D2",vendor: "WIN TECHNOLOGIES, CO., LTD."},
  {max_prefix: "00-30-97",vendor: "AB Regin"},
  {max_prefix: "00-D0-43",vendor: "ZONAL RETAIL DATA SYSTEMS"},
  {max_prefix: "00-D0-C1",vendor: "HARMONIC DATA SYSTEMS, LTD."},
  {max_prefix: "00-D0-35",vendor: "BEHAVIOR TECH. COMPUTER CORP."},
  {max_prefix: "00-D0-DB",vendor: "MCQUAY INTERNATIONAL"},
  {max_prefix: "00-D0-70",vendor: "LONG WELL ELECTRONICS CORP."},
  {max_prefix: "00-D0-29",vendor: "WAKEFERN FOOD CORPORATION"},
  {max_prefix: "00-D0-B1",vendor: "OMEGA ELECTRONICS SA"},
  {max_prefix: "00-D0-16",vendor: "SCM MICROSYSTEMS, INC."},
  {max_prefix: "00-30-12",vendor: "DIGITAL ENGINEERING LTD."},
  {max_prefix: "00-D0-0F",vendor: "SPEECH DESIGN GMBH"},
  {max_prefix: "00-30-58",vendor: "API MOTION"},
  {max_prefix: "00-30-A6",vendor: "VIANET TECHNOLOGIES, LTD."},
  {max_prefix: "00-D0-C3",vendor: "VIVID TECHNOLOGY PTE, LTD."},
  {max_prefix: "00-D0-C8",vendor: "Prevas A/S"},
  {max_prefix: "00-D0-7E",vendor: "KEYCORP LTD."},
  {max_prefix: "00-D0-EA",vendor: "NEXTONE COMMUNICATIONS, INC."},
  {max_prefix: "00-D0-20",vendor: "AIM SYSTEM, INC."},
  {max_prefix: "00-D0-CB",vendor: "DASAN CO., LTD."},
  {max_prefix: "00-D0-19",vendor: "DAINIPPON SCREEN CORPORATE"},
  {max_prefix: "00-D0-13",vendor: "PRIMEX AEROSPACE COMPANY"},
  {max_prefix: "00-D0-A3",vendor: "VOCAL DATA, INC."},
  {max_prefix: "00-D0-BF",vendor: "PIVOTAL TECHNOLOGIES"},
  {max_prefix: "00-D0-2F",vendor: "VLSI TECHNOLOGY INC."},
  {max_prefix: "00-D0-AC",vendor: "Commscope, Inc"},
  {max_prefix: "00-D0-BC",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-50-A1",vendor: "CARLO GAVAZZI, INC."},
  {max_prefix: "00-50-17",vendor: "RSR S.R.L."},
  {max_prefix: "00-50-65",vendor: "TDK-Lambda Corporation"},
  {max_prefix: "00-50-B9",vendor: "XITRON TECHNOLOGIES, INC."},
  {max_prefix: "00-50-6B",vendor: "SPX-ATEG"},
  {max_prefix: "00-50-4A",vendor: "ELTECO A.S."},
  {max_prefix: "00-D0-64",vendor: "MULTITEL"},
  {max_prefix: "00-D0-5E",vendor: "STRATABEAM TECHNOLOGY, INC."},
  {max_prefix: "00-D0-AA",vendor: "CHASE COMMUNICATIONS"},
  {max_prefix: "00-D0-FA",vendor: "Thales e-Security Ltd."},
  {max_prefix: "00-D0-EB",vendor: "LIGHTERA NETWORKS, INC."},
  {max_prefix: "00-D0-06",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-D0-2A",vendor: "Voxent Systems Ltd."},
  {max_prefix: "00-D0-8F",vendor: "ARDENT TECHNOLOGIES, INC."},
  {max_prefix: "00-D0-51",vendor: "O2 MICRO, INC."},
  {max_prefix: "00-D0-BB",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-D0-6E",vendor: "TRENDVIEW RECORDERS LTD."},
  {max_prefix: "00-D0-5C",vendor: "KATHREIN TechnoTrend GmbH"},
  {max_prefix: "00-D0-5D",vendor: "INTELLIWORXX, INC."},
  {max_prefix: "00-D0-A1",vendor: "OSKAR VIERLING GMBH + CO. KG"},
  {max_prefix: "00-D0-C0",vendor: "Cisco Systems, Inc"},
  {max_prefix: "00-D0-76",vendor: "Bank of America"},
  {max_prefix: "00-50-C1",vendor: "GEMFLEX NETWORKS, LTD."},
  {max_prefix: "00-50-75",vendor: "KESTREL SOLUTIONS"},
  {max_prefix: "00-50-ED",vendor: "ANDA NETWORKS"},
  {max_prefix: "00-50-96",vendor: "SALIX TECHNOLOGIES, INC."},
  {max_prefix: "00-50-24",vendor: "NAVIC SYSTEMS, INC."},
  {max_prefix: "00-D0-68",vendor: "IWILL CORPORATION"},
  {max_prefix: "00-50-12",vendor: "CBL - GMBH"},
  {max_prefix: "00-50-F2",vendor: "MICROSOFT CORP."},
  {max_prefix: "00-50-29",vendor: "1394 PRINTER WORKING GROUP"},
  {max_prefix: "00-50-81",vendor: "MURATA MACHINERY, LTD."},
  {max_prefix: "00-50-AC",vendor: "MAPLE COMPUTER CORPORATION"},
  {max_prefix: "00-50-49",vendor: "Arbor Networks Inc"},
  {max_prefix: "00-90-2C",vendor: "DATA & CONTROL EQUIPMENT LTD."},
  {max_prefix: "00-90-BD",vendor: "OMNIA COMMUNICATIONS, INC."},
  {max_prefix: "00-90-B4",vendor: "WILLOWBROOK TECHNOLOGIES"},
  {max_prefix: "00-90-03",vendor: "APLIO"},
  {max_prefix: "00-50-CF",vendor: "VANLINK COMMUNICATION TECHNOLOGY RESEARCH INSTITUTE"},
  {max_prefix: "00-50-A4",vendor: "IO TECH, INC."},
  {max_prefix: "00-50-A3",vendor: "TransMedia Communications, Inc."},
  {max_prefix: "00-50-5C",vendor: "TUNDO CORPORATION"},
  {max_prefix: "00-50-B3",vendor: "VOICEBOARD CORPORATION"},
  {max_prefix: "00-50-CD",vendor: "DIGIANSWER A/S"},
  {max_prefix: "00-50-2D",vendor: "ACCEL, INC."},
  {max_prefix: "00-50-3A",vendor: "DATONG ELECTRONICS LTD."},
  {max_prefix: "00-50-87",vendor: "TERASAKI ELECTRIC CO., LTD."},
  {max_prefix: "00-50-8C",vendor: "RSI SYSTEMS"},
  {max_prefix: "00-50-E1",vendor: "NS TECH ELECTRONICS SDN BHD"},
  {max_prefix: "00-50-DE",vendor: "SIGNUM SYSTEMS CORP."},
  {max_prefix: "00-50-7B",vendor: "MERLOT COMMUNICATIONS"},
  {max_prefix: "00-50-0D",vendor: "SATORI ELECTORIC CO., LTD."},
  {max_prefix: "00-50-26",vendor: "COSYSTEMS, INC."},
  {max_prefix: "00-90-CF",vendor: "NORTEL"},
  {max_prefix: "00-90-2F",vendor: "NETCORE SYSTEMS, INC."},
  {max_prefix: "00-90-98",vendor: "SBC DESIGNS, INC."},
  {max_prefix: "00-90-45",vendor: "Marconi Communications"},
  {max_prefix: "00-90-36",vendor: "ens, inc."},
  {max_prefix: "00-90-8B",vendor: "Tattile SRL"},
  {max_prefix: "00-90-44",vendor: "ASSURED DIGITAL, INC."},
  {max_prefix: "00-90-7C",vendor: "DIGITALCAST, INC."},
  {max_prefix: "00-90-42",vendor: "ECCS, Inc."},
  {max_prefix: "00-90-51",vendor: "ULTIMATE TECHNOLOGY CORP."},
  {max_prefix: "00-90-26",vendor: "ADVANCED SWITCHING COMMUNICATIONS, INC."},
  {max_prefix: "00-90-D3",vendor: "GIESECKE & DEVRIENT GmbH"},
  {max_prefix: "00-90-67",vendor: "WalkAbout Computers, Inc."},
  {max_prefix: "00-90-25",vendor: "BAE Systems Australia (Electronic Systems) Pty Ltd"},
  {max_prefix: "00-90-4C",vendor: "Epigram, Inc."},
  {max_prefix: "00-90-84",vendor: "ATECH SYSTEM"},
  {max_prefix: "00-90-6A",vendor: "TURNSTONE SYSTEMS, INC."},
  {max_prefix: "00-90-87",vendor: "ITIS"},
  {max_prefix: "00-90-31",vendor: "MYSTICOM, LTD."},
  {max_prefix: "00-90-DD",vendor: "MIHARU COMMUNICATIONS Inc"},
  {max_prefix: "00-90-28",vendor: "NIPPON SIGNAL CO., LTD."},
  {max_prefix: "00-90-7D",vendor: "Lake Communications"},
  {max_prefix: "00-90-C9",vendor: "DPAC Technologies"},
  {max_prefix: "00-90-1D",vendor: "PEC (NZ) LTD."},
  {max_prefix: "00-90-7E",vendor: "VETRONIX CORP."},
  {max_prefix: "00-90-50",vendor: "Teleste Corporation"},
  {max_prefix: "00-90-2A",vendor: "COMMUNICATION DEVICES, INC."},
  {max_prefix: "00-90-72",vendor: "SIMRAD AS"},
  {max_prefix: "00-90-0D",vendor: "Overland Storage Inc."},
  {max_prefix: "00-90-33",vendor: "INNOVAPHONE AG"},
  {max_prefix: "00-90-02",vendor: "ALLGON AB"},
  {max_prefix: "00-90-FC",vendor: "NETWORK COMPUTING DEVICES"},
  {max_prefix: "00-90-14",vendor: "ROTORK INSTRUMENTS, LTD."},
  {max_prefix: "00-90-4D",vendor: "SPEC S.A."},
  {max_prefix: "00-90-FD",vendor: "CopperCom, Inc."},
  {max_prefix: "00-90-39",vendor: "SHASTA NETWORKS"},
  {max_prefix: "00-90-91",vendor: "DigitalScape, Inc."},
  {max_prefix: "00-90-8D",vendor: "VICKERS ELECTRONICS SYSTEMS"},
  {max_prefix: "00-90-97",vendor: "Sycamore Networks"},
  {max_prefix: "00-06-29",vendor: "IBM Corp"},
  {max_prefix: "00-10-A9",vendor: "ADHOC TECHNOLOGIES"},
  {max_prefix: "00-10-8A",vendor: "TeraLogic, Inc."},
  {max_prefix: "00-10-24",vendor: "NAGOYA ELECTRIC WORKS CO., LTD"},
  {max_prefix: "00-10-D6",vendor: "Exelis"},
  {max_prefix: "00-10-48",vendor: "HTRC AUTOMATION, INC."},
  {max_prefix: "00-10-97",vendor: "WinNet Metropolitan Communications Systems, Inc."},
  {max_prefix: "00-10-85",vendor: "POLARIS COMMUNICATIONS, INC."},
  {max_prefix: "00-10-0C",vendor: "ITO CO., LTD."},
  {max_prefix: "00-10-06",vendor: "Thales Contact Solutions Ltd."},
  {max_prefix: "00-10-6F",vendor: "TRENTON TECHNOLOGY INC."},
  {max_prefix: "00-10-34",vendor: "GNP Computers"},
  {max_prefix: "00-10-44",vendor: "InnoLabs Corporation"},
  {max_prefix: "00-90-8E",vendor: "Nortel Networks Broadband Access"},
  {max_prefix: "00-90-09",vendor: "I Controls, Inc."},
  {max_prefix: "00-90-D2",vendor: "Artel Video Systems"},
  {max_prefix: "00-01-FE",vendor: "DIGITAL EQUIPMENT CORPORATION"},
  {max_prefix: "00-10-D9",vendor: "IBM JAPAN, FUJISAWA MT+D"},
  {max_prefix: "00-10-3C",vendor: "IC ENSEMBLE, INC."},
  {max_prefix: "00-10-19",vendor: "SIRONA DENTAL SYSTEMS GmbH & Co. KG"},
  {max_prefix: "00-10-7F",vendor: "CRESTRON ELECTRONICS, INC."},
  {max_prefix: "00-10-D4",vendor: "STORAGE COMPUTER CORPORATION"},
  {max_prefix: "00-10-E2",vendor: "ArrayComm, Inc."},
  {max_prefix: "00-90-DE",vendor: "CARDKEY SYSTEMS, INC."},
  {max_prefix: "00-90-6B",vendor: "APPLIED RESOURCES, INC."},
  {max_prefix: "00-90-BE",vendor: "IBC/INTEGRATED BUSINESS COMPUTERS"},
  {max_prefix: "00-90-66",vendor: "Troika Networks, Inc."},
  {max_prefix: "00-10-D2",vendor: "NITTO TSUSHINKI CO., LTD"},
  {max_prefix: "00-10-94",vendor: "Performance Analysis Broadband, Spirent plc"},
  {max_prefix: "00-10-50",vendor: "RION CO., LTD."},
  {max_prefix: "00-10-CE",vendor: "VOLAMP, LTD."},
  {max_prefix: "00-10-B2",vendor: "COACTIVE AESTHETICS"},
  {max_prefix: "00-10-A1",vendor: "KENDIN SEMICONDUCTOR, INC."},
  {max_prefix: "00-10-5F",vendor: "ZODIAC DATA SYSTEMS"},
  {max_prefix: "00-10-3E",vendor: "NETSCHOOLS CORPORATION"},
  {max_prefix: "00-10-CB",vendor: "FACIT K.K."},
  {max_prefix: "00-10-E0",vendor: "Oracle Corporation"},
  {max_prefix: "00-10-7C",vendor: "P-COM, INC."},
  {max_prefix: "00-10-BD",vendor: "THE TELECOMMUNICATION TECHNOLOGY COMMITTEE (TTC)"},
  {max_prefix: "00-10-38",vendor: "Micro Research Ltd."},
  {max_prefix: "00-10-A8",vendor: "RELIANCE COMPUTER CORP."},
  {max_prefix: "00-10-D1",vendor: "Top Layer Networks, Inc."},
  {max_prefix: "00-10-6A",vendor: "DIGITAL MICROWAVE CORPORATION"},
  {max_prefix: "00-10-3B",vendor: "HIPPI NETWORKING FORUM"},
  {max_prefix: "00-10-89",vendor: "WebSonic"},
  {max_prefix: "00-10-08",vendor: "VIENNA SYSTEMS CORPORATION"},
  {max_prefix: "00-10-6E",vendor: "TADIRAN COM. LTD."},
  {max_prefix: "00-10-9A",vendor: "NETLINE"},
  {max_prefix: "00-E0-D1",vendor: "TELSIS LIMITED"},
  {max_prefix: "00-E0-F0",vendor: "ABLER TECHNOLOGY, INC."},
  {max_prefix: "00-E0-02",vendor: "CROSSROADS SYSTEMS, INC."},
  {max_prefix: "00-E0-D6",vendor: "COMPUTER & COMMUNICATION RESEARCH LAB."},
  {max_prefix: "00-E0-B7",vendor: "Cosworth Electronics Ltd"},
  {max_prefix: "00-E0-83",vendor: "JATO TECHNOLOGIES, INC."},
  {max_prefix: "00-E0-72",vendor: "LYNK"},
  {max_prefix: "00-E0-AD",vendor: "EES TECHNOLOGY, LTD."},
  {max_prefix: "00-E0-94",vendor: "OSAI SRL"},
  {max_prefix: "00-E0-32",vendor: "MISYS FINANCIAL SYSTEMS, LTD."},
  {max_prefix: "00-E0-C0",vendor: "SEIWA ELECTRIC MFG. CO., LTD."},
  {max_prefix: "00-E0-27",vendor: "DUX, INC."},
  {max_prefix: "00-E0-44",vendor: "LSICS CORPORATION"},
  {max_prefix: "00-E0-CA",vendor: "BEST DATA PRODUCTS"},
  {max_prefix: "00-E0-A7",vendor: "IPC INFORMATION SYSTEMS, INC."},
  {max_prefix: "00-E0-97",vendor: "CARRIER ACCESS CORPORATION"},
  {max_prefix: "00-E0-89",vendor: "ION Networks, Inc."},
  {max_prefix: "00-E0-70",vendor: "DH TECHNOLOGY"},
  {max_prefix: "00-E0-55",vendor: "INGENIERIA ELECTRONICA COMERCIAL INELCOM S.A."},
  {max_prefix: "00-E0-42",vendor: "Pacom Systems Ltd."},
  {max_prefix: "00-E0-EB",vendor: "DIGICOM SYSTEMS, INCORPORATED"},
  {max_prefix: "00-E0-4B",vendor: "JUMP INDUSTRIELLE COMPUTERTECHNIK GmbH"},
  {max_prefix: "00-E0-74",vendor: "TIERNAN COMMUNICATIONS, INC."},
  {max_prefix: "00-E0-D9",vendor: "TAZMO CO., LTD."},
  {max_prefix: "00-E0-93",vendor: "ACKFIN NETWORKS"},
  {max_prefix: "00-E0-71",vendor: "EPIS MICROCOMPUTER"},
  {max_prefix: "00-E0-66",vendor: "ProMax Systems, Inc."},
  {max_prefix: "00-E0-B4",vendor: "TECHNO SCOPE CO., LTD."},
  {max_prefix: "00-60-5B",vendor: "IntraServer Technology, Inc."},
  {max_prefix: "00-60-D7",vendor: "ECOLE POLYTECHNIQUE FEDERALE DE LAUSANNE (EPFL)"},
  {max_prefix: "00-60-F3",vendor: "Performance Analysis Broadband, Spirent plc"},
  {max_prefix: "00-60-7C",vendor: "WaveAccess, Ltd."},
  {max_prefix: "00-60-C1",vendor: "WaveSpan Corporation"},
  {max_prefix: "00-60-3C",vendor: "HAGIWARA SYS-COM CO., LTD."},
  {max_prefix: "00-60-7D",vendor: "SENTIENT NETWORKS INC."},
  {max_prefix: "00-60-6E",vendor: "DAVICOM SEMICONDUCTOR, INC."},
  {max_prefix: "00-60-C7",vendor: "AMATI COMMUNICATIONS CORP."},
  {max_prefix: "00-E0-60",vendor: "SHERWOOD"},
  {max_prefix: "00-E0-BA",vendor: "BERGHOF AUTOMATIONSTECHNIK GmbH"},
  {max_prefix: "00-E0-21",vendor: "FREEGATE CORP."},
  {max_prefix: "00-E0-5B",vendor: "WEST END SYSTEMS CORP."},
  {max_prefix: "00-E0-62",vendor: "HOST ENGINEERING"},
  {max_prefix: "00-E0-01",vendor: "STRAND LIGHTING LIMITED"},
  {max_prefix: "00-E0-D8",vendor: "LANBit Computer, Inc."},
  {max_prefix: "00-E0-1F",vendor: "AVIDIA Systems, Inc."},
  {max_prefix: "00-E0-D0",vendor: "NETSPEED, INC."},
  {max_prefix: "00-60-73",vendor: "REDCREEK COMMUNICATIONS, INC."},
  {max_prefix: "00-60-FD",vendor: "NetICs, Inc."},
  {max_prefix: "00-60-FB",vendor: "PACKETEER, INC."},
  {max_prefix: "00-E0-CE",vendor: "ARN"},
  {max_prefix: "00-E0-5F",vendor: "e-Net, Inc."},
  {max_prefix: "00-E0-E7",vendor: "RAYTHEON E-SYSTEMS, INC."},
  {max_prefix: "00-E0-73",vendor: "NATIONAL AMUSEMENT NETWORK, INC."},
  {max_prefix: "00-E0-6A",vendor: "KAPSCH AG"},
  {max_prefix: "00-E0-3C",vendor: "AdvanSys"},
  {max_prefix: "00-E0-24",vendor: "GADZOOX NETWORKS"},
  {max_prefix: "10-00-E8",vendor: "NATIONAL SEMICONDUCTOR"},
  {max_prefix: "00-60-31",vendor: "HRK SYSTEMS"},
  {max_prefix: "00-60-ED",vendor: "RICARDO TEST AUTOMATION LTD."},
  {max_prefix: "00-60-12",vendor: "POWER COMPUTING CORPORATION"},
  {max_prefix: "00-60-4D",vendor: "MMC NETWORKS, INC."},
  {max_prefix: "00-60-DD",vendor: "MYRICOM, INC."},
  {max_prefix: "00-60-F4",vendor: "ADVANCED COMPUTER SOLUTIONS, Inc."},
  {max_prefix: "00-60-60",vendor: "Data Innovations North America"},
  {max_prefix: "00-60-F7",vendor: "DATAFUSION SYSTEMS"},
  {max_prefix: "00-60-20",vendor: "PIVOTAL NETWORKING, INC."},
  {max_prefix: "00-60-C0",vendor: "Nera Networks AS"},
  {max_prefix: "00-60-0E",vendor: "WAVENET INTERNATIONAL, INC."},
  {max_prefix: "00-60-A0",vendor: "SWITCHED NETWORK TECHNOLOGIES, INC."},
  {max_prefix: "00-60-17",vendor: "TOKIMEC INC."},
  {max_prefix: "00-60-26",vendor: "VIKING Modular Solutions"},
  {max_prefix: "00-60-19",vendor: "Roche Diagnostics"},
  {max_prefix: "00-60-59",vendor: "TECHNICAL COMMUNICATIONS CORP."},
  {max_prefix: "00-60-03",vendor: "TERAOKA WEIGH SYSTEM PTE, LTD."},
  {max_prefix: "00-60-95",vendor: "ACCU-TIME SYSTEMS, INC."},
  {max_prefix: "00-60-CB",vendor: "HITACHI ZOSEN CORPORATION"},
  {max_prefix: "00-60-C8",vendor: "KUKA WELDING SYSTEMS & ROBOTS"},
  {max_prefix: "00-60-23",vendor: "PERICOM SEMICONDUCTOR CORP."},
  {max_prefix: "00-60-63",vendor: "PSION DACOM PLC."},
  {max_prefix: "00-60-35",vendor: "DALLAS SEMICONDUCTOR, INC."},
  {max_prefix: "00-60-07",vendor: "ACRES GAMING, INC."},
  {max_prefix: "00-60-58",vendor: "COPPER MOUNTAIN COMMUNICATIONS, INC."},
  {max_prefix: "00-60-7A",vendor: "DVS GMBH"},
  {max_prefix: "00-60-66",vendor: "LACROIX Trafic"},
  {max_prefix: "00-60-77",vendor: "PRISA NETWORKS"},
  {max_prefix: "00-60-94",vendor: "IBM Corp"},
  {max_prefix: "00-60-AB",vendor: "LARSCOM INCORPORATED"},
  {max_prefix: "00-60-5A",vendor: "CELCORE, INC."},
  {max_prefix: "00-60-A3",vendor: "CONTINUUM TECHNOLOGY CORP."},
  {max_prefix: "00-60-3D",vendor: "3CX"},
  {max_prefix: "00-60-46",vendor: "VMETRO, INC."},
  {max_prefix: "00-A0-DA",vendor: "INTEGRATED SYSTEMS Technology, Inc."},
  {max_prefix: "00-A0-2A",vendor: "TRANCELL SYSTEMS"},
  {max_prefix: "00-A0-1C",vendor: "NASCENT NETWORKS CORPORATION"},
  {max_prefix: "00-A0-9A",vendor: "NIHON KOHDEN AMERICA"},
  {max_prefix: "00-A0-78",vendor: "Marconi Communications"},
  {max_prefix: "00-A0-BF",vendor: "WIRELESS DATA GROUP MOTOROLA"},
  {max_prefix: "00-A0-5F",vendor: "BTG Electronics Design BV"},
  {max_prefix: "00-A0-CD",vendor: "DR. JOHANNES HEIDENHAIN GmbH"},
  {max_prefix: "00-A0-2D",vendor: "1394 Trade Association"},
  {max_prefix: "00-A0-7C",vendor: "TONYANG NYLON CO., LTD."},
  {max_prefix: "00-A0-E6",vendor: "DIALOGIC CORPORATION"},
  {max_prefix: "00-A0-4A",vendor: "NISSHIN ELECTRIC CO., LTD."},
  {max_prefix: "00-A0-35",vendor: "CYLINK CORPORATION"},
  {max_prefix: "00-A0-3F",vendor: "COMPUTER SOCIETY MICROPROCESSOR & MICROPROCESSOR STANDARDS C"},
  {max_prefix: "00-A0-3D",vendor: "OPTO-22"},
  {max_prefix: "00-A0-26",vendor: "TELDAT, S.A."},
  {max_prefix: "00-A0-23",vendor: "APPLIED CREATIVE TECHNOLOGY, INC."},
  {max_prefix: "00-A0-8F",vendor: "DESKNET SYSTEMS, INC."},
  {max_prefix: "00-A0-CC",vendor: "LITE-ON COMMUNICATIONS, INC."},
  {max_prefix: "00-A0-B7",vendor: "CORDANT, INC."},
  {max_prefix: "00-A0-56",vendor: "MICROPROSS"},
  {max_prefix: "00-A0-E1",vendor: "WESTPORT RESEARCH ASSOCIATES, INC."},
  {max_prefix: "00-60-8A",vendor: "CITADEL COMPUTER"},
  {max_prefix: "00-60-93",vendor: "VARIAN"},
  {max_prefix: "00-A0-89",vendor: "XPOINT TECHNOLOGIES, INC."},
  {max_prefix: "00-A0-07",vendor: "APEXX TECHNOLOGY, INC."},
  {max_prefix: "00-A0-47",vendor: "INTEGRATED FITNESS CORP."},
  {max_prefix: "00-A0-32",vendor: "GES SINGAPORE PTE. LTD."},
  {max_prefix: "00-A0-E3",vendor: "XKL SYSTEMS CORP."},
  {max_prefix: "00-A0-14",vendor: "CSIR"},
  {max_prefix: "00-A0-15",vendor: "WYLE"},
  {max_prefix: "00-A0-6A",vendor: "Verilink Corporation"},
  {max_prefix: "00-A0-70",vendor: "COASTCOM"},
  {max_prefix: "00-20-20",vendor: "MEGATRON COMPUTER INDUSTRIES PTY, LTD."},
  {max_prefix: "00-20-1B",vendor: "NORTHERN TELECOM/NETWORK"},
  {max_prefix: "00-20-C0",vendor: "PULSE ELECTRONICS, INC."},
  {max_prefix: "00-20-6F",vendor: "FLOWPOINT CORPORATION"},
  {max_prefix: "00-A0-18",vendor: "CREATIVE CONTROLLERS, INC."},
  {max_prefix: "00-A0-FE",vendor: "BOSTON TECHNOLOGY, INC."},
  {max_prefix: "00-A0-EB",vendor: "Encore Networks, Inc."},
  {max_prefix: "00-A0-7D",vendor: "SEEQ TECHNOLOGY, INC."},
  {max_prefix: "00-A0-D9",vendor: "CONVEX COMPUTER CORPORATION"},
  {max_prefix: "00-20-DE",vendor: "JAPAN DIGITAL LABORAT'Y CO.LTD"},
  {max_prefix: "00-20-0B",vendor: "OCTAGON SYSTEMS CORP."},
  {max_prefix: "00-20-94",vendor: "CUBIX CORPORATION"},
  {max_prefix: "00-20-F7",vendor: "CYBERDATA CORPORATION"},
  {max_prefix: "00-20-D7",vendor: "JAPAN MINICOMPUTER SYSTEMS CO., Ltd."},
  {max_prefix: "00-20-C3",vendor: "COUNTER SOLUTIONS LTD."},
  {max_prefix: "00-20-FB",vendor: "OCTEL COMMUNICATIONS CORP."},
  {max_prefix: "00-20-47",vendor: "STEINBRECHER CORP."},
  {max_prefix: "00-20-D5",vendor: "VIPA GMBH"},
  {max_prefix: "00-20-1A",vendor: "MRV Communications, Inc."},
  {max_prefix: "00-20-F2",vendor: "Oracle Corporation"},
  {max_prefix: "00-20-B8",vendor: "PRIME OPTION, INC."},
  {max_prefix: "00-20-62",vendor: "SCORPION LOGIC, LTD."},
  {max_prefix: "00-20-81",vendor: "TITAN ELECTRONICS"},
  {max_prefix: "00-20-D9",vendor: "PANASONIC TECHNOLOGIES, INC./MIECO-US"},
  {max_prefix: "00-20-7E",vendor: "FINECOM CO., LTD."},
  {max_prefix: "00-20-AD",vendor: "LINQ SYSTEMS"},
  {max_prefix: "00-20-7D",vendor: "ADVANCED COMPUTER APPLICATIONS"},
  {max_prefix: "00-20-2F",vendor: "ZETA COMMUNICATIONS, LTD."},
  {max_prefix: "00-20-9A",vendor: "THE 3DO COMPANY"},
  {max_prefix: "00-20-01",vendor: "DSP SOLUTIONS, INC."},
  {max_prefix: "00-20-BF",vendor: "AEHR TEST SYSTEMS"},
  {max_prefix: "00-20-4E",vendor: "NETWORK SECURITY SYSTEMS, INC."},
  {max_prefix: "00-20-FF",vendor: "SYMMETRICAL TECHNOLOGIES"},
  {max_prefix: "00-20-44",vendor: "GENITECH PTY LTD"},
  {max_prefix: "00-20-A1",vendor: "DOVATRON"},
  {max_prefix: "00-20-70",vendor: "HYNET, LTD."},
  {max_prefix: "00-20-BE",vendor: "LAN ACCESS CORP."},
  {max_prefix: "00-20-4A",vendor: "PRONET GMBH"},
  {max_prefix: "00-20-F3",vendor: "RAYNET CORPORATION"},
  {max_prefix: "00-20-90",vendor: "ADVANCED COMPRESSION TECHNOLOGY, INC."},
  {max_prefix: "00-20-53",vendor: "HUNTSVILLE MICROSYSTEMS, INC."},
  {max_prefix: "00-20-CA",vendor: "DIGITAL OCEAN"},
  {max_prefix: "00-20-95",vendor: "RIVA ELECTRONICS"},
  {max_prefix: "00-20-3F",vendor: "JUKI CORPORATION"},
  {max_prefix: "00-20-A9",vendor: "WHITE HORSE INDUSTRIAL"},
  {max_prefix: "00-20-96",vendor: "Invensys"},
  {max_prefix: "00-20-EF",vendor: "USC CORPORATION"},
  {max_prefix: "00-20-30",vendor: "ANALOG & DIGITAL SYSTEMS"},
  {max_prefix: "00-20-AC",vendor: "INTERFLEX DATENSYSTEME GMBH"},
  {max_prefix: "00-20-D8",vendor: "Nortel Networks"},
  {max_prefix: "00-20-66",vendor: "GENERAL MAGIC, INC."},
  {max_prefix: "00-20-36",vendor: "BMC SOFTWARE"},
  {max_prefix: "00-20-F8",vendor: "CARRERA COMPUTERS, INC."},
  {max_prefix: "00-C0-2F",vendor: "OKUMA CORPORATION"},
  {max_prefix: "00-C0-1E",vendor: "LA FRANCAISE DES JEUX"},
  {max_prefix: "00-C0-E1",vendor: "SONIC SOLUTIONS"},
  {max_prefix: "00-C0-65",vendor: "SCOPE COMMUNICATIONS, INC."},
  {max_prefix: "00-C0-79",vendor: "FONSYS CO.,LTD."},
  {max_prefix: "00-40-6F",vendor: "SYNC RESEARCH INC."},
  {max_prefix: "00-40-4F",vendor: "SPACE & NAVAL WARFARE SYSTEMS"},
  {max_prefix: "00-40-8F",vendor: "WM-DATA MINFO AB"},
  {max_prefix: "00-40-D7",vendor: "STUDIO GEN INC."},
  {max_prefix: "00-40-57",vendor: "LOCKHEED - SANDERS"},
  {max_prefix: "00-40-17",vendor: "Silex Technology America"},
  {max_prefix: "00-C0-71",vendor: "AREANEX COMMUNICATIONS, INC."},
  {max_prefix: "00-C0-C4",vendor: "COMPUTER OPERATIONAL"},
  {max_prefix: "00-C0-12",vendor: "NETSPAN CORPORATION"},
  {max_prefix: "00-C0-D9",vendor: "QUINTE NETWORK CONFIDENTIALITY"},
  {max_prefix: "00-C0-B1",vendor: "GENIUS NET CO."},
  {max_prefix: "00-C0-0F",vendor: "QUANTUM SOFTWARE SYSTEMS LTD."},
  {max_prefix: "00-C0-87",vendor: "UUNET TECHNOLOGIES, INC."},
  {max_prefix: "00-C0-06",vendor: "NIPPON AVIONICS CO., LTD."},
  {max_prefix: "00-C0-D2",vendor: "SYNTELLECT, INC."},
  {max_prefix: "00-C0-7E",vendor: "KUBOTA CORPORATION ELECTRONIC"},
  {max_prefix: "00-C0-DD",vendor: "QLogic Corporation"},
  {max_prefix: "00-C0-1B",vendor: "SOCKET COMMUNICATIONS, INC."},
  {max_prefix: "00-C0-3F",vendor: "STORES AUTOMATED SYSTEMS, INC."},
  {max_prefix: "00-C0-36",vendor: "RAYTECH ELECTRONIC CORP."},
  {max_prefix: "00-C0-40",vendor: "ECCI"},
  {max_prefix: "00-C0-A4",vendor: "UNIGRAF OY"},
  {max_prefix: "00-C0-FA",vendor: "CANARY COMMUNICATIONS, INC."},
  {max_prefix: "00-C0-1C",vendor: "INTERLINK COMMUNICATIONS LTD."},
  {max_prefix: "00-C0-96",vendor: "TAMURA CORPORATION"},
  {max_prefix: "00-C0-4E",vendor: "COMTROL CORPORATION"},
  {max_prefix: "00-C0-44",vendor: "EMCOM CORPORATION"},
  {max_prefix: "00-C0-E6",vendor: "Verilink Corporation"},
  {max_prefix: "00-C0-42",vendor: "DATALUX CORP."},
  {max_prefix: "00-C0-A2",vendor: "INTERMEDIUM A/S"},
  {max_prefix: "00-C0-53",vendor: "Aspect Software Inc."},
  {max_prefix: "00-C0-CC",vendor: "TELESCIENCES CO SYSTEMS, INC."},
  {max_prefix: "00-C0-CE",vendor: "CEI SYSTEMS & ENGINEERING PTE"},
  {max_prefix: "00-C0-8E",vendor: "NETWORK INFORMATION TECHNOLOGY"},
  {max_prefix: "00-C0-C7",vendor: "SPARKTRUM MICROSYSTEMS, INC."},
  {max_prefix: "00-40-F3",vendor: "NETCOR"},
  {max_prefix: "00-40-4B",vendor: "MAPLE COMPUTER SYSTEMS"},
  {max_prefix: "00-40-33",vendor: "ADDTRON TECHNOLOGY CO., LTD."},
  {max_prefix: "00-40-AD",vendor: "SMA REGELSYSTEME GMBH"},
  {max_prefix: "00-40-6D",vendor: "LANCO, INC."},
  {max_prefix: "00-40-CD",vendor: "TERA MICROSYSTEMS, INC."},
  {max_prefix: "00-40-F5",vendor: "OEM ENGINES"},
  {max_prefix: "00-40-39",vendor: "OPTEC DAIICHI DENKO CO., LTD."},
  {max_prefix: "00-40-79",vendor: "JUKO MANUFACTURE COMPANY, LTD."},
  {max_prefix: "00-40-59",vendor: "YOSHIDA KOGYO K. K."},
  {max_prefix: "00-40-AE",vendor: "DELTA CONTROLS, INC."},
  {max_prefix: "00-40-F6",vendor: "KATRON COMPUTERS INC."},
  {max_prefix: "00-C0-5F",vendor: "FINE-PAL COMPANY LIMITED"},
  {max_prefix: "00-80-D9",vendor: "EMK Elektronik GmbH & Co. KG"},
  {max_prefix: "00-C0-20",vendor: "ARCO ELECTRONIC, CONTROL LTD."},
  {max_prefix: "00-C0-E7",vendor: "FIBERDATA AB"},
  {max_prefix: "00-40-F4",vendor: "CAMEO COMMUNICATIONS, INC."},
  {max_prefix: "00-40-B4",vendor: "NEXTCOM K.K."},
  {max_prefix: "00-40-B0",vendor: "BYTEX CORPORATION, ENGINEERING"},
  {max_prefix: "00-40-86",vendor: "MICHELS & KLEBERHOFF COMPUTER"},
  {max_prefix: "00-40-92",vendor: "ASP COMPUTER PRODUCTS, INC."},
  {max_prefix: "00-40-B9",vendor: "MACQ ELECTRONIQUE SA"},
  {max_prefix: "00-40-BB",vendor: "GOLDSTAR CABLE CO., LTD."},
  {max_prefix: "00-40-B1",vendor: "CODONICS INC."},
  {max_prefix: "00-40-7F",vendor: "FLIR Systems"},
  {max_prefix: "00-40-95",vendor: "R.P.T. INTERGROUPS INT'L LTD."},
  {max_prefix: "00-40-35",vendor: "OPCOM"},
  {max_prefix: "00-40-5C",vendor: "FUTURE SYSTEMS, INC."},
  {max_prefix: "00-40-61",vendor: "DATATECH ENTERPRISES CO., LTD."},
  {max_prefix: "00-00-8C",vendor: "Alloy Computer Products (Australia) Pty Ltd"},
  {max_prefix: "00-40-C5",vendor: "MICOM COMMUNICATIONS INC."},
  {max_prefix: "00-40-20",vendor: "CommScope Inc"},
  {max_prefix: "00-40-48",vendor: "SMD INFORMATICA S.A."},
  {max_prefix: "00-40-68",vendor: "EXTENDED SYSTEMS"},
  {max_prefix: "00-40-78",vendor: "WEARNES AUTOMATION PTE LTD"},
  {max_prefix: "00-40-F8",vendor: "SYSTEMHAUS DISCOM"},
  {max_prefix: "00-40-24",vendor: "COMPAC INC."},
  {max_prefix: "00-40-D2",vendor: "PAGINE CORPORATION"},
  {max_prefix: "00-40-E9",vendor: "ACCORD SYSTEMS, INC."},
  {max_prefix: "00-40-03",vendor: "Emerson Process Management Power & Water Solutions, Inc."},
  {max_prefix: "00-40-90",vendor: "ANSEL COMMUNICATIONS"},
  {max_prefix: "00-40-7C",vendor: "QUME CORPORATION"},
  {max_prefix: "00-40-44",vendor: "QNIX COMPUTER CO., LTD."},
  {max_prefix: "00-40-2D",vendor: "HARRIS ADACOM CORPORATION"},
  {max_prefix: "00-80-81",vendor: "KENDALL SQUARE RESEARCH CORP."},
  {max_prefix: "00-80-9C",vendor: "LUXCOM, INC."},
  {max_prefix: "00-80-65",vendor: "CYBERGRAPHIC SYSTEMS PTY LTD."},
  {max_prefix: "00-80-19",vendor: "DAYNA COMMUNICATIONS, INC."},
  {max_prefix: "00-80-50",vendor: "ZIATECH CORPORATION"},
  {max_prefix: "00-80-D2",vendor: "SHINNIHONDENKO CO., LTD."},
  {max_prefix: "00-80-89",vendor: "TECNETICS (PTY) LTD."},
  {max_prefix: "00-80-6F",vendor: "ONELAN LTD."},
  {max_prefix: "00-80-84",vendor: "THE CLOUD INC."},
  {max_prefix: "00-80-A6",vendor: "REPUBLIC TECHNOLOGY, INC."},
  {max_prefix: "00-40-FC",vendor: "IBR COMPUTER TECHNIK GMBH"},
  {max_prefix: "00-40-DE",vendor: "Elsag Datamat spa"},
  {max_prefix: "00-40-C9",vendor: "NCUBE"},
  {max_prefix: "00-80-CF",vendor: "EMBEDDED PERFORMANCE INC."},
  {max_prefix: "00-80-90",vendor: "MICROTEK INTERNATIONAL, INC."},
  {max_prefix: "00-80-C4",vendor: "DOCUMENT TECHNOLOGIES, INC."},
  {max_prefix: "00-40-A0",vendor: "GOLDSTAR CO., LTD."},
  {max_prefix: "00-40-DF",vendor: "DIGALOG SYSTEMS, INC."},
  {max_prefix: "00-40-09",vendor: "TACHIBANA TECTRON CO., LTD."},
  {max_prefix: "00-80-32",vendor: "ACCESS CO., LTD."},
  {max_prefix: "00-80-5B",vendor: "CONDOR SYSTEMS, INC."},
  {max_prefix: "00-80-43",vendor: "NETWORLD, INC."},
  {max_prefix: "00-80-AF",vendor: "ALLUMER CO., LTD."},
  {max_prefix: "00-00-55",vendor: "COMMISSARIAT A L`ENERGIE ATOM."},
  {max_prefix: "00-00-41",vendor: "ICE CORPORATION"},
  {max_prefix: "00-00-86",vendor: "MEGAHERTZ CORPORATION"},
  {max_prefix: "00-00-92",vendor: "COGENT DATA TECHNOLOGIES"},
  {max_prefix: "00-00-58",vendor: "RACORE COMPUTER PRODUCTS INC."},
  {max_prefix: "00-80-CE",vendor: "BROADCAST TELEVISION SYSTEMS"},
  {max_prefix: "00-80-0C",vendor: "VIDECOM LIMITED"},
  {max_prefix: "00-80-7D",vendor: "EQUINOX SYSTEMS INC."},
  {max_prefix: "00-80-63",vendor: "Hirschmann Automation and Control GmbH"},
  {max_prefix: "00-80-74",vendor: "FISHER CONTROLS"},
  {max_prefix: "00-80-30",vendor: "NEXUS ELECTRONICS"},
  {max_prefix: "00-80-C9",vendor: "ALBERTA MICROELECTRONIC CENTRE"},
  {max_prefix: "00-80-EE",vendor: "THOMSON CSF"},
  {max_prefix: "00-80-8E",vendor: "RADSTONE TECHNOLOGY"},
  {max_prefix: "00-80-1A",vendor: "BELL ATLANTIC"},
  {max_prefix: "00-80-52",vendor: "TECHNICALLY ELITE CONCEPTS"},
  {max_prefix: "00-80-5D",vendor: "CANSTAR"},
  {max_prefix: "00-80-4F",vendor: "DAIKIN INDUSTRIES, LTD."},
  {max_prefix: "00-80-05",vendor: "CACTUS COMPUTER INC."},
  {max_prefix: "00-80-CD",vendor: "MICRONICS COMPUTER, INC."},
  {max_prefix: "00-80-03",vendor: "HYTEC ELECTRONICS LTD."},
  {max_prefix: "00-80-99",vendor: "Eaton Industries GmbH"},
  {max_prefix: "00-80-8D",vendor: "WESTCOAST TECHNOLOGY B.V."},
  {max_prefix: "00-80-BE",vendor: "ARIES RESEARCH"},
  {max_prefix: "00-80-15",vendor: "SEIKO SYSTEMS, INC."},
  {max_prefix: "00-80-A4",vendor: "LIBERTY ELECTRONICS"},
  {max_prefix: "00-80-F3",vendor: "SUN ELECTRONICS CORP."},
  {max_prefix: "00-80-96",vendor: "HUMAN DESIGNED SYSTEMS, INC."},
  {max_prefix: "00-80-DA",vendor: "Hottinger Br\u00fcel & Kj\u00e6r A/S"},
  {max_prefix: "00-80-3E",vendor: "SYNERNETICS"},
  {max_prefix: "00-80-DE",vendor: "GIPSI S.A."},
  {max_prefix: "00-80-6D",vendor: "CENTURY SYSTEMS CORP."},
  {max_prefix: "00-80-94",vendor: "ALFA LAVAL AUTOMATION AB"},
  {max_prefix: "00-80-02",vendor: "SATELCOM (UK) LTD"},
  {max_prefix: "00-80-64",vendor: "WYSE TECHNOLOGY LLC"},
  {max_prefix: "00-80-48",vendor: "COMPEX INCORPORATED"},
  {max_prefix: "00-80-85",vendor: "H-THREE SYSTEMS CORPORATION"},
  {max_prefix: "00-80-4C",vendor: "CONTEC CO., LTD."},
  {max_prefix: "00-80-8F",vendor: "C. ITOH ELECTRONICS, INC."},
  {max_prefix: "00-00-52",vendor: "Intrusion.com, Inc."},
  {max_prefix: "00-80-FF",vendor: "SOC. DE TELEINFORMATIQUE RTC"},
  {max_prefix: "00-00-37",vendor: "OXFORD METRICS LIMITED"},
  {max_prefix: "00-00-F7",vendor: "YOUTH KEEP ENTERPRISE CO LTD"},
  {max_prefix: "00-00-C7",vendor: "ARIX CORPORATION"},
  {max_prefix: "00-00-D1",vendor: "ADAPTEC INCORPORATED"},
  {max_prefix: "00-00-16",vendor: "DU PONT PIXEL SYSTEMS     ."},
  {max_prefix: "00-00-E2",vendor: "ACER TECHNOLOGIES CORP."},
  {max_prefix: "00-00-E1",vendor: "GRID SYSTEMS"},
  {max_prefix: "00-00-81",vendor: "Bay Networks"},
  {max_prefix: "00-00-29",vendor: "IMC NETWORKS CORP."},
  {max_prefix: "00-00-FB",vendor: "RECHNER ZUR KOMMUNIKATION"},
  {max_prefix: "00-00-0D",vendor: "FIBRONICS LTD."},
  {max_prefix: "00-00-0A",vendor: "OMRON TATEISI ELECTRONICS CO."},
  {max_prefix: "00-00-24",vendor: "CONNECT AS"},
  {max_prefix: "00-00-CF",vendor: "HAYES MICROCOMPUTER PRODUCTS"},
  {max_prefix: "00-00-EF",vendor: "KTI"},
  {max_prefix: "00-00-25",vendor: "RAMTEK CORP."},
  {max_prefix: "00-00-67",vendor: "SOFT * RITE, INC."},
  {max_prefix: "00-00-D2",vendor: "SBE, INC."},
  {max_prefix: "00-00-BA",vendor: "SIIG, INC."},
  {max_prefix: "08-00-7B",vendor: "SANYO ELECTRIC CO. LTD."},
  {max_prefix: "08-00-7C",vendor: "VITALINK COMMUNICATIONS CORP."},
  {max_prefix: "08-00-8E",vendor: "Tandem Computers"},
  {max_prefix: "08-00-84",vendor: "TOMEN ELECTRONICS CORP."},
  {max_prefix: "08-00-85",vendor: "ELXSI"},
  {max_prefix: "08-00-82",vendor: "VERITAS SOFTWARE"},
  {max_prefix: "08-00-80",vendor: "AES DATA INC."},
  {max_prefix: "00-00-2A",vendor: "TRW - SEDD/INP"},
  {max_prefix: "00-00-2C",vendor: "AUTOTOTE LIMITED"},
  {max_prefix: "00-00-F3",vendor: "GANDALF DATA LIMITED"},
  {max_prefix: "00-00-B0",vendor: "RND-RAD NETWORK DEVICES"},
  {max_prefix: "00-00-A9",vendor: "NETWORK SYSTEMS CORP."},
  {max_prefix: "00-00-DF",vendor: "BELL & HOWELL PUB SYS DIV"},
  {max_prefix: "00-00-F9",vendor: "QUOTRON SYSTEMS INC."},
  {max_prefix: "00-00-AF",vendor: "Canberra Industries, Inc."},
  {max_prefix: "00-00-76",vendor: "ABEKAS VIDEO SYSTEM"},
  {max_prefix: "08-00-41",vendor: "RACAL-MILGO INFORMATION SYS.."},
  {max_prefix: "08-00-35",vendor: "MICROFIVE CORPORATION"},
  {max_prefix: "08-00-32",vendor: "TIGAN INCORPORATED"},
  {max_prefix: "08-00-77",vendor: "TSL COMMUNICATIONS LTD."},
  {max_prefix: "08-00-74",vendor: "CASIO COMPUTER CO. LTD."},
  {max_prefix: "08-00-6E",vendor: "MASSCOMP"},
  {max_prefix: "08-00-53",vendor: "MIDDLE EAST TECH. UNIVERSITY"},
  {max_prefix: "08-00-4C",vendor: "HYDRA COMPUTER SYSTEMS INC."},
  {max_prefix: "08-00-47",vendor: "SEQUENT COMPUTER SYSTEMS INC."},
  {max_prefix: "08-00-4A",vendor: "BANYAN SYSTEMS INC."},
  {max_prefix: "08-00-44",vendor: "DAVID SYSTEMS INC."},
  {max_prefix: "08-00-68",vendor: "RIDGE COMPUTERS"},
  {max_prefix: "08-00-63",vendor: "PLESSEY"},
  {max_prefix: "08-00-60",vendor: "INDUSTRIAL NETWORKING INC."},
  {max_prefix: "08-00-55",vendor: "STANFORD TELECOMM. INC."},
  {max_prefix: "08-00-0B",vendor: "UNISYS CORPORATION"},
  {max_prefix: "00-DD-01",vendor: "UNGERMANN-BASS INC."},
  {max_prefix: "08-00-8A",vendor: "PerfTech, Inc."},
  {max_prefix: "00-DD-06",vendor: "UNGERMANN-BASS INC."},
  {max_prefix: "02-BB-01",vendor: "OCTOTHORPE CORP."},
  {max_prefix: "00-00-3E",vendor: "SIMPACT"},
  {max_prefix: "08-00-15",vendor: "STC BUSINESS SYSTEMS"},
  {max_prefix: "08-00-42",vendor: "MACNICA, Inc."},
  {max_prefix: "AA-00-03",vendor: "DIGITAL EQUIPMENT CORPORATION"},
  {max_prefix: "08-00-66",vendor: "AGFA CORPORATION"},
  {max_prefix: "00-00-00",vendor: "XEROX CORPORATION"},
  {max_prefix: "F8-21-11",vendor: "HMD Global Oy"},
  {max_prefix: "EC-91-61",vendor: "CLOUD NETWORK TECHNOLOGY SINGAPORE PTE. LTD."},
  {max_prefix: "08-00-30",vendor: "CERN"},
  {max_prefix: "08-00-8D",vendor: "XYVISION INC."},
  {max_prefix: "00-DD-02",vendor: "UNGERMANN-BASS INC."},
  {max_prefix: "00-DD-04",vendor: "UNGERMANN-BASS INC."},
  {max_prefix: "00-01-C8",vendor: "CONRAD CORP."},
  {max_prefix: "08-00-3F",vendor: "FRED KOSCHARA ENTERPRISES"},
  {max_prefix: "C0-D6-0A",vendor: "Texas Instruments"},
  {max_prefix: "7C-49-CF",vendor: "eero inc."},
  {max_prefix: "28-83-C9",vendor: "Apple, Inc."},
  {max_prefix: "08-00-21",vendor: "3M COMPANY"},
  {max_prefix: "02-60-86",vendor: "LOGIC REPLACEMENT TECH. LTD."},
  {max_prefix: "DC-A0-D0",vendor: "Amazon Technologies Inc."},
  {max_prefix: "F0-2F-9E",vendor: "Amazon Technologies Inc."},
  {max_prefix: "40-36-68",vendor: "E&B TELECOM"},
  {max_prefix: "18-3F-70",vendor: "Apple, Inc."},
  {max_prefix: "A4-F6-E8",vendor: "Apple, Inc."},
  {max_prefix: "64-41-E6",vendor: "Apple, Inc."},
  {max_prefix: "70-BB-5B",vendor: "Apple, Inc."},
  {max_prefix: "E8-51-9E",vendor: "Guangzhou Shiyuan Electronic Technology Company Limited"},
  {max_prefix: "74-0E-A4",vendor: "Apple, Inc."},
  {max_prefix: "60-57-C8",vendor: "Apple, Inc."},
  {max_prefix: "D4-41-3F",vendor: "Gen IV Technology LLC"},
  {max_prefix: "44-A0-38",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "F8-6D-CC",vendor: "Wistron Neweb Corporation"},
  {max_prefix: "D0-D7-BE",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "20-6B-F4",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "E8-2B-C5",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "08-27-6B",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "3C-CD-73",vendor: "Nebula Electronic Technology Corporation"},
  {max_prefix: "3C-13-BB",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "CC-36-CF",vendor: "Cisco Systems, Inc"},
  {max_prefix: "58-D8-A7",vendor: "Bird Home Automation GmbH"},
  {max_prefix: "EC-B1-E0",vendor: "Eltex Enterprise LTD"},
  {max_prefix: "B4-00-16",vendor: " INGENICO TERMINALS SAS"},
  {max_prefix: "C8-9C-BB",vendor: "Taicang T&W Electronics"},
  {max_prefix: "34-5E-E7",vendor: "Hangzhou ChengFengErLai Digial Technology Co.,Ltd."},
  {max_prefix: "54-B8-74",vendor: "GD Midea Air-Conditioning Equipment Co.,Ltd."},
  {max_prefix: "C4-57-81",vendor: "Wingtech Group (HongKong) Limited"},
  {max_prefix: "F4-DD-06",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "10-1E-DA",vendor: " INGENICO TERMINALS SAS"},
  {max_prefix: "44-D4-7F",vendor: " INGENICO TERMINALS SAS"},
  {max_prefix: "18-5D-6F",vendor: "N3com"},
  {max_prefix: "64-51-F4",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "4C-60-BA",vendor: "AltoBeam Inc."},
  {max_prefix: "48-63-45",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "70-90-41",vendor: "Mist Systems, Inc."},
  {max_prefix: "78-59-94",vendor: "Alif Semiconductor, Inc."},
  {max_prefix: "14-CB-49",vendor: "Habolink Technology Co.,LTD"},
  {max_prefix: "94-A4-B5",vendor: "Mitsubishi Electric Corporation"},
  {max_prefix: "30-C9-22",vendor: "Espressif Inc."},
  {max_prefix: "C4-95-5F",vendor: "Anhui Saida Technology Limited Liability Company"},
  {max_prefix: "CC-1B-5A",vendor: "Ruckus Wireless"},
  {max_prefix: "24-2D-6C",vendor: "eero inc."},
  {max_prefix: "38-90-AF",vendor: "zte corporation"},
  {max_prefix: "18-79-FD",vendor: "zte corporation"},
  {max_prefix: "64-A4-44",vendor: "Loongson Technology Corporation Limited"},
  {max_prefix: "70-48-B5",vendor: "CTS System Co., LTD."},
  {max_prefix: "18-31-4F",vendor: "AIDIN ROBOTICS"},
  {max_prefix: "68-1D-4C",vendor: "eSystems MTG GmbH"},
  {max_prefix: "0C-67-43",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "F4-79-46",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "30-B8-51",vendor: "Siemens AG"},
  {max_prefix: "78-0B-8C",vendor: "Private"},
  {max_prefix: "94-33-D8",vendor: "Cisco Systems, Inc"},
  {max_prefix: "84-0A-9E",vendor: "Nexapp Technologies Pvt Ltd"},
  {max_prefix: "D0-6B-78",vendor: "Apple, Inc."},
  {max_prefix: "0C-53-B7",vendor: "Apple, Inc."},
  {max_prefix: "7C-F3-4D",vendor: "Apple, Inc."},
  {max_prefix: "00-0F-2C",vendor: "Lantronix"},
  {max_prefix: "FC-D5-86",vendor: "Shenzhen SuperElectron Technology Co.,Ltd."},
  {max_prefix: "D8-02-C0",vendor: "Extreme Networks Headquarters"},
  {max_prefix: "0C-DC-91",vendor: "Amazon Technologies Inc."},
  {max_prefix: "E8-CB-F5",vendor: "Laird Connectivity"},
  {max_prefix: "98-1C-42",vendor: "LAIIER"},
  {max_prefix: "58-35-0F",vendor: "EM Microelectronic"},
  {max_prefix: "30-67-A1",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "D0-1B-F4",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "AC-A0-9D",vendor: "Juniper Networks"},
  {max_prefix: "78-BB-C1",vendor: "SERVERCOM (INDIA) PRIVATE LIMITED"},
  {max_prefix: "E4-BC-96",vendor: "Versuni"},
  {max_prefix: "E0-23-FF",vendor: "Fortinet, Inc."},
  {max_prefix: "E8-ED-D6",vendor: "Fortinet, Inc."},
  {max_prefix: "70-BC-48",vendor: "Cisco Systems, Inc"},
  {max_prefix: "E8-1C-BA",vendor: "Fortinet, Inc."},
  {max_prefix: "D0-C7-30",vendor: "zte corporation"},
  {max_prefix: "24-59-E5",vendor: "GD Midea Air-Conditioning Equipment Co.,Ltd."},
  {max_prefix: "20-C7-92",vendor: "Wuhan Maiwe communication Co.,Ltd"},
  {max_prefix: "A0-BC-6F",vendor: "Cisco Systems, Inc"},
  {max_prefix: "B0-8D-57",vendor: "Cisco Systems, Inc"},
  {max_prefix: "18-93-41",vendor: "Intel Corporate"},
  {max_prefix: "DC-45-46",vendor: "Intel Corporate"},
  {max_prefix: "94-CE-0F",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "88-3F-27",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "24-E2-9D",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "2C-20-80",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "CC-BC-2B",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "C0-2B-56",vendor: "CANDID OPTRONIX PRIVATE LIMITED"},
  {max_prefix: "DC-97-BA",vendor: "Intel Corporate"},
  {max_prefix: "A0-D3-65",vendor: "Intel Corporate"},
  {max_prefix: "B4-02-F2",vendor: "Synaptics, Inc"},
  {max_prefix: "A0-2D-DB",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "74-4D-DC",vendor: "Sonim Technologies, Inc"},
  {max_prefix: "B8-A1-4A",vendor: "Raisecom Technology CO., LTD"},
  {max_prefix: "3C-6D-66",vendor: "NVIDIA Corporation"},
  {max_prefix: "74-D5-C6",vendor: "Microchip Technologies Inc"},
  {max_prefix: "BC-09-EB",vendor: "TECNO MOBILE LIMITED"},
  {max_prefix: "5C-64-0F",vendor: "Sage Technologies Inc."},
  {max_prefix: "38-A7-46",vendor: "COMPAL INFORMATION (KUNSHAN) CO., LTD."},
  {max_prefix: "6C-E2-D3",vendor: "New H3C Technologies Co., Ltd"},
  {max_prefix: "5C-16-7D",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "50-E4-52",vendor: "Chipsea Technologies (Shenzhen) Corp."},
  {max_prefix: "A4-A4-90",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "B8-A8-25",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "CC-F9-F0",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "F0-A8-82",vendor: "shenzhen trolink  Technology Co.,Ltd"},
  {max_prefix: "10-32-2C",vendor: "Murata Manufacturing Co., Ltd."},
  {max_prefix: "DC-C4-9C",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "E4-C7-70",vendor: "Raisecom Technology CO., LTD"},
  {max_prefix: "E0-67-B3",vendor: "Shenzhen C-Data Technology Co., Ltd."},
  {max_prefix: "70-A5-6A",vendor: "Shenzhen C-Data Technology Co., Ltd."},
  {max_prefix: "DC-8E-8D",vendor: "Netis Technology Co., Ltd."},
  {max_prefix: "E4-BE-ED",vendor: "Netis Technology Co., Ltd."},
  {max_prefix: "00-72-63",vendor: "Netis Technology Co., Ltd."},
  {max_prefix: "EC-64-C9",vendor: "Espressif Inc."},
  {max_prefix: "F0-F5-BD",vendor: "Espressif Inc."},
  {max_prefix: "F8-42-88",vendor: "Apple, Inc."},
  {max_prefix: "88-B7-EB",vendor: "Apple, Inc."},
  {max_prefix: "10-A2-D3",vendor: "Apple, Inc."},
  {max_prefix: "F4-42-50",vendor: "Bouffalo Lab (Nanjing) Co., Ltd."},
  {max_prefix: "BC-62-CE",vendor: "Netis Technology Co., Ltd."},
  {max_prefix: "98-D7-E1",vendor: "Cisco Systems, Inc"},
  {max_prefix: "F8-39-18",vendor: "Cisco Systems, Inc"},
  {max_prefix: "84-B1-E2",vendor: "Microsoft Corporation"},
  {max_prefix: "FC-34-E2",vendor: "Shenzhen YOUHUA Technology Co., Ltd"},
  {max_prefix: "4C-97-CC",vendor: "Apple, Inc."},
  {max_prefix: "34-EE-16",vendor: "Apple, Inc."},
  {max_prefix: "48-84-9D",vendor: "Texas Instruments"},
  {max_prefix: "60-0F-6B",vendor: "Apple, Inc."},
  {max_prefix: "44-F9-71",vendor: "SHENZHEN MERCURY COMMUNICATION TECHNOLOGIES CO.,LTD."},
  {max_prefix: "38-6B-1C",vendor: "SHENZHEN MERCURY COMMUNICATION TECHNOLOGIES CO.,LTD."},
  {max_prefix: "34-96-77",vendor: "zte corporation"},
  {max_prefix: "24-75-FC",vendor: "zte corporation"},
  {max_prefix: "50-89-65",vendor: "SHENZHEN MERCURY COMMUNICATION TECHNOLOGIES CO.,LTD."},
  {max_prefix: "C0-3F-BB",vendor: "Zhongshan Zhiniu Electronics Co.,Ltd."},
  {max_prefix: "5C-57-9E",vendor: "Chiun Mai Communication System, Inc"},
  {max_prefix: "44-1A-84",vendor: "Quectel Wireless Solutions Co.,Ltd."},
  {max_prefix: "70-54-64",vendor: "Silicon Laboratories"},
  {max_prefix: "8C-6F-B9",vendor: "Silicon Laboratories"},
  {max_prefix: "14-2D-41",vendor: "Silicon Laboratories"},
  {max_prefix: "04-87-27",vendor: "Silicon Laboratories"},
  {max_prefix: "4C-5B-B3",vendor: "Silicon Laboratories"},
  {max_prefix: "28-76-81",vendor: "Silicon Laboratories"},
  {max_prefix: "30-FB-10",vendor: "Silicon Laboratories"},
  {max_prefix: "94-B2-16",vendor: "Silicon Laboratories"},
  {max_prefix: "8C-65-A3",vendor: "Silicon Laboratories"},
  {max_prefix: "B4-3A-31",vendor: "Silicon Laboratories"},
  {max_prefix: "28-F8-C6",vendor: "IEEE Registration Authority"},
  {max_prefix: "C8-8A-D8",vendor: "CHINA DRAGON TECHNOLOGY LIMITED"},
  {max_prefix: "D0-58-AB",vendor: "Mara Tech LLC"},
  {max_prefix: "94-40-F3",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "40-6F-27",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "18-CA-A7",vendor: "zte corporation"},
  {max_prefix: "D4-87-CC",vendor: "Sivantos GmbH"},
  {max_prefix: "00-33-58",vendor: "Ruckus Wireless"},
  {max_prefix: "64-4E-EB",vendor: "Daikin Holdings Singapore Pte Ltd"},
  {max_prefix: "E0-4C-12",vendor: "Realme Chongqing Mobile Telecommunications Corp.,Ltd."},
  {max_prefix: "24-E8-CE",vendor: "Qingdao Haier Technology Co.,Ltd"},
  {max_prefix: "9C-38-18",vendor: "Cisco Systems, Inc"},
  {max_prefix: "B4-4C-90",vendor: "Cisco Systems, Inc"},
  {max_prefix: "DC-EA-E7",vendor: "D-Link Corporation"},
  {max_prefix: "8C-A6-82",vendor: "IEEE Registration Authority"},
  {max_prefix: "4C-A9-19",vendor: "Tuya Smart Inc."},
  {max_prefix: "A8-EA-71",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "BC-62-9C",vendor: "zte corporation"},
  {max_prefix: "D4-17-61",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "98-25-4A",vendor: "TP-LINK CORPORATION PTE. LTD."},
  {max_prefix: "24-2F-D0",vendor: "TP-LINK CORPORATION PTE. LTD."},
  {max_prefix: "D8-25-DF",vendor: "CAME UK"},
  {max_prefix: "D4-B5-CD",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "68-49-83",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "2C-EC-F7",vendor: "Shenzhen SDMC Technology CO.,Ltd."},
  {max_prefix: "90-F7-21",vendor: "IndiNatus (IndiNatus India Private Limited)"},
  {max_prefix: "E8-DA-3E",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "EC-B8-78",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "38-F4-5E",vendor: "H1-Radio co.,ltd"},
  {max_prefix: "74-FE-CE",vendor: "TP-LINK CORPORATION PTE. LTD."},
  {max_prefix: "78-F5-05",vendor: "Murata Manufacturing Co., Ltd."},
  {max_prefix: "58-8D-39",vendor: "MITSUBISHI ELECTRIC AUTOMATION (CHINA) LTD."},
  {max_prefix: "B8-E2-8C",vendor: "MOTOROLA SOLUTIONS MALAYSIA SDN. BHD."},
  {max_prefix: "E0-DA-D7",vendor: "zte corporation"},
  {max_prefix: "94-BB-43",vendor: "AzureWave Technology Inc."},
  {max_prefix: "18-8B-0E",vendor: "Espressif Inc."},
  {max_prefix: "30-A3-B5",vendor: "Jiangsu Best Tone Information Service Co., Ltd"},
  {max_prefix: "B8-F7-74",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "2C-CF-67",vendor: "Raspberry Pi (Trading) Ltd"},
  {max_prefix: "44-49-63",vendor: "Woven By Toyota U.S., Inc."},
  {max_prefix: "60-74-B1",vendor: "Home Control Singapore Pte Ltd"},
  {max_prefix: "34-C8-D6",vendor: "IEEE Registration Authority"},
  {max_prefix: "28-1D-FB",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "0C-23-8D",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "DC-61-80",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "A8-6F-36",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "C0-A4-76",vendor: "Ruijie Networks Co.,LTD"},
  {max_prefix: "20-01-9C",vendor: "Bigleaf Networks Inc."},
  {max_prefix: "48-A3-BD",vendor: "Texas Instruments"},
  {max_prefix: "04-23-22",vendor: "Texas Instruments"},
  {max_prefix: "28-DE-59",vendor: "Domus NTW CORP."},
  {max_prefix: "B8-D0-F0",vendor: "FCNT LLC"},
  {max_prefix: "10-2F-6E",vendor: "Shenzhen Sundray Technologies Company Limited"},
  {max_prefix: "70-2A-D7",vendor: "New H3C Intelligence Terminal Co., Ltd."},
  {max_prefix: "EC-C3-42",vendor: "zte corporation"},
  {max_prefix: "94-BE-50",vendor: "Remote Solution"},
  {max_prefix: "50-CF-56",vendor: "China Mobile Group Device Co.,Ltd."},
  {max_prefix: "AC-07-75",vendor: "Apple, Inc."},
  {max_prefix: "84-2F-57",vendor: "Apple, Inc."},
  {max_prefix: "18-81-ED",vendor: "TERACOM TELEMATICA S.A"},
  {max_prefix: "24-5A-5F",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "00-10-9C",vendor: "MG Co., Ltd."},
  {max_prefix: "D4-4D-9F",vendor: "Shenzhen Skyworth Digital  Technology  CO., Ltd"},
  {max_prefix: "9C-D4-A6",vendor: "SERVERCOM (INDIA) PRIVATE LIMITED"},
  {max_prefix: "A8-63-08",vendor: "AVUTEC"},
  {max_prefix: "68-42-16",vendor: "Steplock Access AB"},
  {max_prefix: "6C-F2-D8",vendor: "CANON INC."},
  {max_prefix: "F0-FC-65",vendor: "SynaXG Technologies Pte. Ltd."},
  {max_prefix: "A4-27-A5",vendor: "Palo Alto Networks"},
  {max_prefix: "00-24-80",vendor: "Meteocontrol GmbH"},
  {max_prefix: "C4-BD-8D",vendor: "SHENZHEN CHUANGWEI-RGB ELECTRONICS CO.,LTD"},
  {max_prefix: "94-4B-F8",vendor: "TOZO INC"},
  {max_prefix: "98-9D-B2",vendor: "GOIP Global Services Pvt. Ltd."},
  {max_prefix: "68-34-21",vendor: "Intel Corporate"},
  {max_prefix: "00-22-18",vendor: "Akamai Technologies Inc."},
  {max_prefix: "00-01-9C",vendor: "Lumentum"},
  {max_prefix: "68-E9-05",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "34-E3-FB",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "98-34-8C",vendor: "Great Talent Technology Limited"},
  {max_prefix: "30-F6-00",vendor: "Sagemcom Broadband SAS"},
  {max_prefix: "98-5F-41",vendor: "Intel Corporate"},
  {max_prefix: "30-3A-4A",vendor: "eero inc."},
  {max_prefix: "AC-39-71",vendor: "Realme Chongqing Mobile Telecommunications Corp.,Ltd."},
  {max_prefix: "A0-44-B7",vendor: "PAX Computer Technology(Shenzhen) Ltd."},
  {max_prefix: "60-1D-16",vendor: "Med-Eng Holdings ULC"},
  {max_prefix: "98-5A-98",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "E0-AD-9B",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "B0-5A-44",vendor: "Fibocom Wireless Inc."},
  {max_prefix: "38-F1-8F",vendor: "Technicolor (China) Technology Co., Ltd."},
  {max_prefix: "40-99-F6",vendor: "Telink Semiconductor(Shanghai) Co.,Ltd"},
  {max_prefix: "34-C6-DD",vendor: "Hangzhou Ezviz Software Co.,Ltd."},
  {max_prefix: "CC-8C-17",vendor: "ITEL MOBILE LIMITED"},
  {max_prefix: "08-A6-F7",vendor: "Espressif Inc."},
  {max_prefix: "34-CF-B5",vendor: "Robotic d.o.o."},
  {max_prefix: "4C-82-37",vendor: "Telink Micro LLC"},
  {max_prefix: "84-9C-A4",vendor: "Mimosa Networks"},
  {max_prefix: "00-D6-CB",vendor: "Quectel Wireless Solutions Co.,Ltd."},
  {max_prefix: "EC-C9-FF",vendor: "Espressif Inc."},
  {max_prefix: "2C-BC-BB",vendor: "Espressif Inc."},
  {max_prefix: "88-3E-0D",vendor: "HD Hyundai Electric"},
  {max_prefix: "B8-3B-8F",vendor: "Hangzhou Hylin IoT Techonology Co.,Ltd."},
  {max_prefix: "00-03-C5",vendor: "Mobotix AG"},
  {max_prefix: "58-A8-E8",vendor: "Amazon Technologies Inc."},
  {max_prefix: "00-E0-5C",vendor: "PHC Corporation"},
  {max_prefix: "64-0C-91",vendor: "Apple, Inc."},
  {max_prefix: "58-93-E8",vendor: "Apple, Inc."},
  {max_prefix: "F0-04-E1",vendor: "Apple, Inc."},
  {max_prefix: "70-8C-F2",vendor: "Apple, Inc."},
  {max_prefix: "F8-73-DF",vendor: "Apple, Inc."},
  {max_prefix: "14-1B-A0",vendor: "Apple, Inc."},
  {max_prefix: "9C-4F-AC",vendor: "zte corporation"},
  {max_prefix: "E4-B0-63",vendor: "Espressif Inc."},
  {max_prefix: "A8-C2-46",vendor: "Gemtek Technology Co., Ltd."},
  {max_prefix: "84-57-F7",vendor: "Meta Platforms Technologies, LLC"},
  {max_prefix: "CC-81-7D",vendor: "Apple, Inc."},
  {max_prefix: "1C-69-20",vendor: "Espressif Inc."},
  {max_prefix: "20-F0-94",vendor: "Google, Inc."},
  {max_prefix: "60-C2-2A",vendor: "Allwinner Technology Co., Ltd"},
  {max_prefix: "70-08-94",vendor: "Liteon Technology Corporation"},
  {max_prefix: "B0-38-6C",vendor: "EFM Networks"},
  {max_prefix: "BC-F2-12",vendor: "Telink Micro LLC"},
  {max_prefix: "88-13-BF",vendor: "Espressif Inc."},
  {max_prefix: "34-5F-45",vendor: "Espressif Inc."},
  {max_prefix: "2C-0B-97",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "74-23-8D",vendor: "zte corporation"},
  {max_prefix: "D0-69-C1",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "54-2F-2B",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "A4-11-62",vendor: "Arlo Technology"},
  {max_prefix: "08-BA-B7",vendor: "Ceragon Networks Ltd."},
  {max_prefix: "A0-67-D6",vendor: "Nokia"},
  {max_prefix: "E8-26-89",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "B8-D4-E7",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "F8-60-F0",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "1C-28-AF",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "28-DE-65",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "88-E9-A4",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "9C-DC-71",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "30-3F-BB",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "F4-2E-7F",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "B8-3A-5A",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "A8-BD-27",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "40-B9-3C",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "E0-07-1B",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "D8-94-03",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "18-64-72",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "6C-F3-7F",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "24-DE-C6",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "84-D4-7E",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "94-3F-C2",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "94-40-C9",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "38-10-F0",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "CC-88-C7",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "EC-02-73",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "D0-4D-C6",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "94-60-D5",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "88-25-10",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "D4-E0-53",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "44-12-44",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "54-F0-B1",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "B8-37-B2",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "0C-D3-A1",vendor: "Monthly Kitchen"},
  {max_prefix: "00-1E-C6",vendor: "Leviton Manufacturing Co., Inc"},
  {max_prefix: "44-A3-BB",vendor: "Intel Corporate"},
  {max_prefix: "94-B6-09",vendor: "Intel Corporate"},
  {max_prefix: "7C-1E-4A",vendor: "FORTUNE MARKETING PRIVATE LIMITED"},
  {max_prefix: "C0-BF-BE",vendor: "AzureWave Technology Inc."},
  {max_prefix: "3C-BD-69",vendor: "Nokia Shanghai Bell Co., Ltd."},
  {max_prefix: "1C-EA-AC",vendor: "Beijing Xiaomi Mobile Software Co., Ltd"},
  {max_prefix: "84-65-2B",vendor: "Donaldson Company"},
  {max_prefix: "58-DB-09",vendor: "Quectel Wireless Solutions Co.,Ltd."},
  {max_prefix: "78-34-09",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "14-9A-A3",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "A0-AD-62",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "5C-87-D8",vendor: "IEEE Registration Authority"},
  {max_prefix: "50-B1-40",vendor: "ELPROMA ELEKTRONIKA SP Z O O"},
  {max_prefix: "8C-C5-D0",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "28-B5-91",vendor: "Cisco Systems, Inc"},
  {max_prefix: "08-02-3C",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "78-B6-FE",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "9C-CE-88",vendor: "Ruijie Networks Co.,LTD"},
  {max_prefix: "90-54-B7",vendor: "Eltex Enterprise LTD"},
  {max_prefix: "BC-94-24",vendor: "TCT mobile ltd"},
  {max_prefix: "84-9E-56",vendor: "CLOUD NETWORK TECHNOLOGY SINGAPORE PTE. LTD."},
  {max_prefix: "24-9E-7D",vendor: "Beijing Roborock Technology Co., Ltd."},
  {max_prefix: "5C-8A-AE",vendor: "CHINA DRAGON TECHNOLOGY LIMITED"},
  {max_prefix: "90-9F-22",vendor: "Zyxel Communications Corporation"},
  {max_prefix: "2C-CA-16",vendor: "Apple, Inc."},
  {max_prefix: "14-14-7D",vendor: "Apple, Inc."},
  {max_prefix: "DC-69-B5",vendor: "eero inc."},
  {max_prefix: "10-23-81",vendor: "Barrot Technology Co.,LTD"},
  {max_prefix: "E8-B7-23",vendor: "Shenzhen Vatilon Electronics Co.,Ltd"},
  {max_prefix: "18-89-A0",vendor: "Funshion Online Technologies Co.,Ltd"},
  {max_prefix: "98-3F-66",vendor: "Funshion Online Technologies Co.,Ltd"},
  {max_prefix: "84-31-A8",vendor: "Funshion Online Technologies Co.,Ltd"},
  {max_prefix: "7C-2C-67",vendor: "Espressif Inc."},
  {max_prefix: "98-87-4C",vendor: "SERVERCOM (INDIA) PRIVATE LIMITED"},
  {max_prefix: "C0-8A-60",vendor: "AltoBeam Inc."},
  {max_prefix: "C8-60-8F",vendor: "Cisco Systems, Inc"},
  {max_prefix: "B8-C0-07",vendor: "tickIoT Inc."},
  {max_prefix: "10-90-7D",vendor: "Funshion Online Technologies Co.,Ltd"},
  {max_prefix: "C0-5D-89",vendor: "Espressif Inc."},
  {max_prefix: "40-03-26",vendor: "NXP Semiconductor (Tianjin) LTD."},
  {max_prefix: "1C-6A-1B",vendor: "Ubiquiti Inc"},
  {max_prefix: "20-05-B6",vendor: "OpenWrt"},
  {max_prefix: "68-F1-25",vendor: "Data Controls Inc."},
  {max_prefix: "00-83-20",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "7C-68-B9",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "90-28-F6",vendor: "Wuhan Yangtze Optical Technology CO.,Ltd."},
  {max_prefix: "20-A2-00",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "7C-A8-EC",vendor: "Hewlett Packard Enterprise"},
  {max_prefix: "F4-D0-A7",vendor: "Zhejiang Weilai Jingling Artificial Intelligence Technology Co., Ltd."},
  {max_prefix: "20-F3-07",vendor: "zte corporation"},
  {max_prefix: "B4-E3-D0",vendor: "LG Electronics"},
  {max_prefix: "3C-D1-C9",vendor: "Groupe SEB"},
  {max_prefix: "EC-55-4D",vendor: "Private"},
  {max_prefix: "08-4F-66",vendor: "Shenzhen Skyworth Digital  Technology  CO., Ltd"},
  {max_prefix: "CC-DA-20",vendor: "Beijing Xiaomi Mobile Software Co., Ltd"},
  {max_prefix: "7C-F1-7E",vendor: "TP-LINK CORPORATION PTE. LTD."},
  {max_prefix: "B0-F2-F6",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "24-19-72",vendor: "Quectel Wireless Solutions Co.,Ltd."},
  {max_prefix: "50-3D-7F",vendor: "Nokia Shanghai Bell Co., Ltd."},
  {max_prefix: "00-17-10",vendor: "AxyomCore Inc."},
  {max_prefix: "14-3A-9A",vendor: "Hon Hai Precision Industry Co.,LTD"},
  {max_prefix: "C8-E0-7A",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "D0-69-FF",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "84-FF-C2",vendor: "Cisco Systems, Inc"},
  {max_prefix: "78-85-17",vendor: "Cisco Systems, Inc"},
  {max_prefix: "48-A4-FD",vendor: "AltoBeam Inc."},
  {max_prefix: "48-F1-EB",vendor: "Nintendo Co.,Ltd"},
  {max_prefix: "50-1B-6A",vendor: "SUNITEC TECHNOLOGY CO.,LIMITED"},
  {max_prefix: "8C-0D-D9",vendor: "Skullcandy"},
  {max_prefix: "44-65-E0",vendor: "Merlion Consulting Services (Shenzhen) Co., Ltd"},
  {max_prefix: "74-1E-B1",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "A4-9F-E7",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "A4-1A-6E",vendor: "zte corporation"},
  {max_prefix: "CC-3F-36",vendor: "Apple, Inc."},
  {max_prefix: "10-2F-CA",vendor: "Apple, Inc."},
  {max_prefix: "54-20-97",vendor: "TTTech Auto  AG"},
  {max_prefix: "28-D2-44",vendor: "LCFC(Hefei) Electronics Technology co., ltd"},
  {max_prefix: "50-7B-9D",vendor: "LCFC(Hefei) Electronics Technology co., ltd"},
  {max_prefix: "88-A4-C2",vendor: "LCFC(Hefei) Electronics Technology co., ltd"},
  {max_prefix: "6C-24-08",vendor: "LCFC(Hefei) Electronics Technology co., ltd"},
  {max_prefix: "9C-2D-CD",vendor: "LCFC(Hefei) Electronics Technology co., ltd"},
  {max_prefix: "D0-48-A1",vendor: "Juniper Networks"},
  {max_prefix: "68-F7-28",vendor: "LCFC(Hefei) Electronics Technology co., ltd"},
  {max_prefix: "C8-5B-76",vendor: "LCFC(Hefei) Electronics Technology co., ltd"},
  {max_prefix: "8C-16-45",vendor: "LCFC(Hefei) Electronics Technology co., ltd"},
  {max_prefix: "38-F3-AB",vendor: "LCFC(Hefei) Electronics Technology co., ltd"},
  {max_prefix: "98-F1-AE",vendor: "Senaisc"},
  {max_prefix: "F0-4B-8A",vendor: "Vantiva USA LLC"},
  {max_prefix: "2C-EA-DA",vendor: "ICC Intelligent Platforms GmbH"},
  {max_prefix: "C0-47-0E",vendor: "Dell Inc."},
  {max_prefix: "AC-A6-13",vendor: "Aivres SYSTEMS INC"},
  {max_prefix: "24-96-D5",vendor: "NEXCON Technology Co.,ltd."},
  {max_prefix: "DC-62-79",vendor: "TP-LINK CORPORATION PTE. LTD."},
  {max_prefix: "CC-38-D0",vendor: "Palo Alto Networks"},
  {max_prefix: "F4-9E-A4",vendor: "Epiq Solutions"},
  {max_prefix: "80-06-D9",vendor: "zte corporation"},
  {max_prefix: "94-C3-6B",vendor: "DRD Automation GmbH"},
  {max_prefix: "D4-8C-49",vendor: "Espressif Inc."},
  {max_prefix: "BC-04-35",vendor: "GD Midea Air-Conditioning Equipment Co.,Ltd."},
  {max_prefix: "E4-30-22",vendor: "Hanwha Vision VietNam"},
  {max_prefix: "C0-96-A4",vendor: "Fiberhome Telecommunication Technologies Co.,LTD"},
  {max_prefix: "EC-73-59",vendor: "Shenzhen Cloudsky Technologies Co., Ltd."},
  {max_prefix: "80-C0-1E",vendor: "Intel Corporate"},
  {max_prefix: "4C-49-29",vendor: "TCL King Electrical Appliances(Huizhou)Co.,Ltd"},
  {max_prefix: "94-EC-32",vendor: "Silicon Laboratories"},
  {max_prefix: "34-5A-60",vendor: "Micro-Star INTL CO., LTD."},
  {max_prefix: "BC-A1-3A",vendor: "VusionGroup"},
  {max_prefix: "C4-B6-91",vendor: "Angel Robotics"},
  {max_prefix: "C8-82-34",vendor: "Cisco Systems, Inc"},
  {max_prefix: "AC-86-D1",vendor: "IEEE Registration Authority"},
  {max_prefix: "78-22-88",vendor: "SHENZHEN BILIAN ELECTRONIC CO.\uff0cLTD"},
  {max_prefix: "84-BE-8B",vendor: "Chengdu Geeker Technology Co., Ltd."},
  {max_prefix: "98-A1-4A",vendor: "Quectel Wireless Solutions Co.,Ltd."},
  {max_prefix: "F8-B3-B7",vendor: "Espressif Inc."},
  {max_prefix: "C4-EB-68",vendor: "VusionGroup"},
  {max_prefix: "AC-33-51",vendor: "Ericsson AB"},
  {max_prefix: "E4-B3-23",vendor: "Espressif Inc."},
  {max_prefix: "30-DD-AA",vendor: "ZHEJIANG DAHUA TECHNOLOGYCO.,LTD"},
  {max_prefix: "B8-91-65",vendor: "Zhejiang Tmall Technology Co., Ltd."},
  {max_prefix: "70-9A-C4",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "C8-DD-6A",vendor: "OHSUNG"},
  {max_prefix: "C4-FF-84",vendor: "IEEE Registration Authority"},
  {max_prefix: "1C-4D-89",vendor: "Hangzhou Huacheng Network Technology Co.,Ltd"},
  {max_prefix: "34-4A-1B",vendor: "zte corporation"},
  {max_prefix: "B4-BA-6A",vendor: "TECNO MOBILE LIMITED"},
  {max_prefix: "04-56-65",vendor: "Nokia Shanghai Bell Co., Ltd."},
  {max_prefix: "B4-41-30",vendor: "Jabil Circuit (Guangzhou) Ltd."},
  {max_prefix: "A0-33-4F",vendor: "Cisco Systems, Inc"},
  {max_prefix: "A0-A4-7F",vendor: "Cisco Systems, Inc"},
  {max_prefix: "90-70-BF",vendor: "Mimosa Networks"},
  {max_prefix: "AC-B4-80",vendor: "Dell Inc."},
  {max_prefix: "28-A9-15",vendor: "SERVERCOM (INDIA) PRIVATE LIMITED"},
  {max_prefix: "44-37-0B",vendor: "Guangzhou Shiyuan Electronic Technology Company Limited"},
  {max_prefix: "24-AE-CC",vendor: "Honor Device Co., Ltd."},
  {max_prefix: "E0-9D-73",vendor: "Mellanox Technologies, Inc."},
  {max_prefix: "60-2D-74",vendor: "Extreme Networks Headquarters"},
  {max_prefix: "10-A3-0F",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "C4-F7-C1",vendor: "Apple, Inc."},
  {max_prefix: "38-62-33",vendor: "Apple, Inc."},
  {max_prefix: "3C-8A-1F",vendor: "Espressif Inc."},
  {max_prefix: "20-43-A8",vendor: "Espressif Inc."},
  {max_prefix: "D4-DD-0B",vendor: "WAVETEL TECHNOLOGY LIMITED"},
  {max_prefix: "74-86-6F",vendor: "zte corporation"},
  {max_prefix: "54-6C-AC",vendor: "Intelbras"},
  {max_prefix: "54-0F-2C",vendor: "Arista Networks"},
  {max_prefix: "90-82-C3",vendor: "Quanta Computer Inc."},
  {max_prefix: "1C-C0-89",vendor: "Silicon Laboratories"},
  {max_prefix: "BC-2B-02",vendor: "CHINA DRAGON TECHNOLOGY LIMITED"},
  {max_prefix: "D4-A3-65",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "7C-60-DB",vendor: "zte corporation"},
  {max_prefix: "C0-48-2F",vendor: "IEEE Registration Authority"},
  {max_prefix: "00-75-32",vendor: "Integrated Engineering BV"},
  {max_prefix: "58-59-24",vendor: "Nanjing Simon Info Tech Co.,Ltd."},
  {max_prefix: "90-9D-AC",vendor: "Infinix mobility limited"},
  {max_prefix: "24-DE-8A",vendor: "Nokia Solutions and Networks GmbH & Co. KG"},
  {max_prefix: "C8-E5-E0",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "BC-B4-FD",vendor: "NXP Semiconductor (Tianjin) LTD."},
  {max_prefix: "58-09-87",vendor: "Amazon Technologies Inc."},
  {max_prefix: "94-F7-17",vendor: "CIG SHANGHAI CO LTD"},
  {max_prefix: "7C-51-84",vendor: "Unis Flash Memory Technology(Chengdu)Co.,Ltd."},
  {max_prefix: "E8-06-EB",vendor: "ShieldSOS LLC"},
  {max_prefix: "78-20-2E",vendor: "Skychers Creations ShenZhen Limited"},
  {max_prefix: "DC-1E-D5",vendor: "Espressif Inc."},
  {max_prefix: "94-6C-04",vendor: "EM Microelectronic"},
  {max_prefix: "E0-C2-50",vendor: "NETGEAR"},
  {max_prefix: "98-C9-7C",vendor: "Shenzhen iComm Semiconductor CO.,LTD"},
  {max_prefix: "84-B4-D2",vendor: "Shenzhen iComm Semiconductor CO.,LTD"},
  {max_prefix: "BC-09-5C",vendor: "FiSens GmbH"},
  {max_prefix: "C0-19-44",vendor: "Juniper Networks"},
  {max_prefix: "AC-04-AA",vendor: "GoPro"},
  {max_prefix: "E8-5C-5F",vendor: "FN-LINK TECHNOLOGY Ltd."},
  {max_prefix: "00-15-82",vendor: "Pulse Eight Limited"},
  {max_prefix: "50-4F-3B",vendor: "Beijing Xiaomi Mobile Software Co., Ltd"},
  {max_prefix: "84-0F-2A",vendor: "Jiangxi Risound Electronics Co.,LTD"},
  {max_prefix: "5C-F7-96",vendor: "New H3C Technologies Co., Ltd"},
  {max_prefix: "4C-C0-96",vendor: "Shenzhen SuperElectron Technology Co.,Ltd."},
  {max_prefix: "BC-FF-54",vendor: "zte corporation"},
  {max_prefix: "4C-2B-3B",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "94-93-86",vendor: "Shenzhen SiACRRIER Industry Machines Co.,LTD"},
  {max_prefix: "E4-B1-07",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "58-B1-8F",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "64-86-24",vendor: "Beijing Global Safety Technology Co., LTD."},
  {max_prefix: "88-67-46",vendor: "eero inc."},
  {max_prefix: "68-7D-00",vendor: "Shenzhen YOUHUA Technology Co., Ltd"},
  {max_prefix: "28-2E-30",vendor: "MECHATRONICS INNOVATION TECHNOLOGIES, S.L.U."},
  {max_prefix: "50-9B-94",vendor: "Shenzhen iComm Semiconductor CO.,LTD"},
  {max_prefix: "48-26-4C",vendor: "BSH Electrical Appliances (Jiangsu) Co., Ltd."},
  {max_prefix: "D4-98-B9",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "E4-E6-08",vendor: "Kiwibit Inc."},
  {max_prefix: "8C-90-2D",vendor: "TP-Link Systems Inc."},
  {max_prefix: "B4-E0-25",vendor: "ITLook"},
  {max_prefix: "10-E1-8E",vendor: "Universal Global Scientific Industrial., Ltd"},
  {max_prefix: "B0-7A-DF",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "34-FF-F3",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "CC-54-FE",vendor: "Mimosa Networks"},
  {max_prefix: "74-88-A8",vendor: "Bouffalo Lab (Nanjing) Co., Ltd."},
  {max_prefix: "30-ED-A0",vendor: "Espressif Inc."},
  {max_prefix: "04-CD-C0",vendor: "Mist Systems, Inc."},
  {max_prefix: "54-A1-04",vendor: "OPTOWL Co.,Ltd"},
  {max_prefix: "0C-FA-22",vendor: "FLIPPER DEVICES INC"},
  {max_prefix: "5C-40-71",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "3C-C6-83",vendor: "Sichuan AI-Link Technology Co., Ltd."},
  {max_prefix: "9C-67-D6",vendor: "Intel Corporate"},
  {max_prefix: "6C-12-70",vendor: "Apple, Inc."},
  {max_prefix: "E8-F9-28",vendor: "ENGINKO SRL"},
  {max_prefix: "88-22-22",vendor: "VusionGroup"},
  {max_prefix: "3C-AB-72",vendor: "Nanjing Qinheng Microelectronics Co., Ltd."},
  {max_prefix: "60-3C-0E",vendor: "Guizhou Huaxin Information Technology Co.,Ltd"},
  {max_prefix: "B4-0E-06",vendor: "Third Reality, Inc"},
  {max_prefix: "8C-8B-48",vendor: "Silicon Laboratories"},
  {max_prefix: "10-57-25",vendor: "Cisco Systems, Inc"},
  {max_prefix: "9C-A9-B8",vendor: "Cisco Systems, Inc"},
  {max_prefix: "40-F4-9F",vendor: "Cisco Systems, Inc"},
  {max_prefix: "B4-89-70",vendor: "IGEN Tech Co., Ltd."},
  {max_prefix: "54-2B-1C",vendor: "Amazon Technologies Inc."},
  {max_prefix: "A4-02-B7",vendor: "Amazon Technologies Inc."},
  {max_prefix: "AC-EC-80",vendor: "Commscope"},
  {max_prefix: "C0-05-C2",vendor: "Commscope"},
  {max_prefix: "00-16-B5",vendor: "Commscope"},
  {max_prefix: "00-17-00",vendor: "Commscope"},
  {max_prefix: "A4-7A-A4",vendor: "Commscope"},
  {max_prefix: "CC-7D-37",vendor: "Commscope"},
  {max_prefix: "00-0B-06",vendor: "Commscope"},
  {max_prefix: "00-11-80",vendor: "Commscope"},
  {max_prefix: "00-15-9A",vendor: "Commscope"},
  {max_prefix: "00-15-A8",vendor: "Commscope"},
  {max_prefix: "30-60-23",vendor: "Commscope"},
  {max_prefix: "90-3E-AB",vendor: "Commscope"},
  {max_prefix: "00-1D-D6",vendor: "Commscope"},
  {max_prefix: "00-11-AE",vendor: "Commscope"},
  {max_prefix: "00-12-8A",vendor: "Commscope"},
  {max_prefix: "00-D0-88",vendor: "Commscope"},
  {max_prefix: "E0-B7-0A",vendor: "Commscope"},
  {max_prefix: "C8-3F-B4",vendor: "Commscope"},
  {max_prefix: "90-1A-CA",vendor: "Commscope"},
  {max_prefix: "14-AB-F0",vendor: "Commscope"},
  {max_prefix: "3C-43-8E",vendor: "Commscope"},
  {max_prefix: "94-CC-B9",vendor: "Commscope"},
  {max_prefix: "00-1D-CE",vendor: "Commscope"},
  {max_prefix: "00-1A-AD",vendor: "Commscope"},
  {max_prefix: "84-E0-58",vendor: "Commscope"},
  {max_prefix: "00-D0-37",vendor: "Commscope"},
  {max_prefix: "44-6A-B7",vendor: "Commscope"},
  {max_prefix: "00-AC-E0",vendor: "Commscope"},
  {max_prefix: "74-EA-E8",vendor: "Commscope"},
  {max_prefix: "98-6B-3D",vendor: "Commscope"},
  {max_prefix: "34-1F-E4",vendor: "Commscope"},
  {max_prefix: "84-96-D8",vendor: "Commscope"},
  {max_prefix: "A0-C5-62",vendor: "Commscope"},
  {max_prefix: "D0-E5-4D",vendor: "Commscope"},
  {max_prefix: "70-76-30",vendor: "Commscope"},
  {max_prefix: "00-26-BA",vendor: "Commscope"},
  {max_prefix: "00-25-F1",vendor: "Commscope"},
  {max_prefix: "00-25-F2",vendor: "Commscope"},
  {max_prefix: "00-24-C1",vendor: "Commscope"},
  {max_prefix: "5C-B0-66",vendor: "Commscope"},
  {max_prefix: "00-26-D9",vendor: "Commscope"},
  {max_prefix: "00-1F-7E",vendor: "Commscope"},
  {max_prefix: "00-1A-DB",vendor: "Commscope"},
  {max_prefix: "00-23-A3",vendor: "Commscope"},
  {max_prefix: "00-14-04",vendor: "Commscope"},
  {max_prefix: "00-1C-12",vendor: "Commscope"},
  {max_prefix: "00-23-75",vendor: "Commscope"},
  {max_prefix: "00-23-0B",vendor: "Commscope"},
  {max_prefix: "74-E7-C6",vendor: "Commscope"},
  {max_prefix: "CC-3E-79",vendor: "Commscope"},
  {max_prefix: "28-F5-D1",vendor: "Commscope"},
  {max_prefix: "10-E1-77",vendor: "Commscope"},
  {max_prefix: "A4-6E-A7",vendor: "DX ANTENNA CO.,LTD."},
  {max_prefix: "AC-DB-48",vendor: "Commscope"},
  {max_prefix: "C8-63-FC",vendor: "Commscope"},
  {max_prefix: "94-62-69",vendor: "Commscope"},
  {max_prefix: "20-F3-75",vendor: "Commscope"},
  {max_prefix: "84-BB-69",vendor: "Commscope"},
  {max_prefix: "20-F1-9E",vendor: "Commscope"},
  {max_prefix: "EC-70-97",vendor: "Commscope"},
  {max_prefix: "58-19-F8",vendor: "Commscope"},
  {max_prefix: "B4-F2-E8",vendor: "Commscope"},
  {max_prefix: "2C-95-69",vendor: "Commscope"},
  {max_prefix: "A0-68-7E",vendor: "Commscope"},
  {max_prefix: "A8-70-5D",vendor: "Commscope"},
  {max_prefix: "78-6A-1F",vendor: "Commscope"},
  {max_prefix: "24-94-CB",vendor: "Commscope"},
  {max_prefix: "2C-A1-7D",vendor: "Commscope"},
  {max_prefix: "F4-0E-83",vendor: "Commscope"},
  {max_prefix: "98-F7-D7",vendor: "Commscope"},
  {max_prefix: "A8-F5-DD",vendor: "Commscope"},
  {max_prefix: "18-20-D5",vendor: "Commscope"},
  {max_prefix: "88-EF-16",vendor: "Commscope"},
  {max_prefix: "F0-FC-C8",vendor: "Commscope"},
  {max_prefix: "94-8F-CF",vendor: "Commscope"},
  {max_prefix: "2C-00-AB",vendor: "Commscope"},
  {max_prefix: "1C-C8-C1",vendor: "HongKong YiTong Technology Ltd."},
  {max_prefix: "B4-FC-7D",vendor: "AVM Audiovisuelles Marketing und Computersysteme GmbH"},
  {max_prefix: "30-29-4B",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "E0-4E-5D",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "9C-49-29",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "D0-2A-AA",vendor: "Infinix mobility limited"},
  {max_prefix: "9C-83-06",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "68-DF-E4",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "50-67-E0",vendor: "Altice Labs"},
  {max_prefix: "00-90-9D",vendor: "NovaTech, LLC"},
  {max_prefix: "94-F7-BE",vendor: "SERCOMM PHILIPPINES INC"},
  {max_prefix: "88-0E-85",vendor: "Shenzhen Boomtech Industrial Corporation"},
  {max_prefix: "A0-8A-06",vendor: "ASKEY COMPUTER CORP"},
  {max_prefix: "78-26-A6",vendor: "zte corporation"},
  {max_prefix: "C0-51-F3",vendor: "CIG SHANGHAI CO LTD"},
  {max_prefix: "B4-3A-45",vendor: "Espressif Inc."},
  {max_prefix: "14-EA-A1",vendor: "Micronet union Technology (chengdu) co., Ltd"},
  {max_prefix: "6C-40-E8",vendor: "vivo Mobile Communication Co., Ltd."},
  {max_prefix: "B4-E8-5C",vendor: "f\u00fcnfeinhalb Funksysteme GmbH"},
  {max_prefix: "F8-05-84",vendor: "EM Microelectronic"},
  {max_prefix: "14-80-CC",vendor: "Quectel Wireless Solutions Co.,Ltd."},
  {max_prefix: "CC-20-AC",vendor: "Samsung Electronics Co.,Ltd"},
  {max_prefix: "44-F5-3E",vendor: "Earda Technologies co Ltd"},
  {max_prefix: "30-01-AF",vendor: "Cisco Systems, Inc"},
  {max_prefix: "B4-CA-DD",vendor: "Cisco Systems, Inc"},
  {max_prefix: "1C-94-68",vendor: "New H3C Technologies Co., Ltd"},
  {max_prefix: "FC-B5-77",vendor: "Cortex Security Inc"},
  {max_prefix: "00-A0-45",vendor: "Phoenix Contact GmbH & Co. KG"},
  {max_prefix: "08-46-8B",vendor: "Guangdong NanGuang Photo & Video Systems Co., Ltd"},
  {max_prefix: "FC-C2-E5",vendor: "HOLOWITS TECHNOLOGIES CO.,LTD"},
  {max_prefix: "98-1E-89",vendor: "Tianyi Telecom Terminals Company Limited"},
  {max_prefix: "20-5A-8F",vendor: "Shenzhen Hikeen Technology Co.,LTD"},
  {max_prefix: "98-A9-65",vendor: "AVM Audiovisuelles Marketing und Computersysteme GmbH"},
  {max_prefix: "4C-DA-38",vendor: "Texas Instruments"},
  {max_prefix: "CC-DA-B5",vendor: "Texas Instruments"},
  {max_prefix: "30-E4-D8",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "90-BF-D9",vendor: "Smart Innovation LLC"},
  {max_prefix: "B8-88-80",vendor: "Shanghai Imilab Technology Co.Ltd"},
  {max_prefix: "DC-88-A1",vendor: "ITEL MOBILE LIMITED"},
  {max_prefix: "9C-47-82",vendor: "TP-LINK TECHNOLOGIES CO.,LTD."},
  {max_prefix: "0C-A6-4C",vendor: "Hangzhou Ezviz Software Co.,Ltd."},
  {max_prefix: "68-28-6C",vendor: "Sony Interactive Entertainment Inc."},
  {max_prefix: "50-00-E6",vendor: "Mellanox Technologies, Inc."},
  {max_prefix: "38-AA-09",vendor: "Cisco Systems, Inc"},
  {max_prefix: "D0-AA-5F",vendor: "IEEE Registration Authority"},
  {max_prefix: "DC-32-62",vendor: "Nanjing Qinheng Microelectronics Co., Ltd."},
  {max_prefix: "E4-FE-43",vendor: "Beijing Xiaomi Mobile Software Co., Ltd"},
  {max_prefix: "38-D6-E0",vendor: "TOPDON TECHNOLOGY Co.,Ltd."},
  {max_prefix: "D8-06-F3",vendor: "Arista Networks"},
  {max_prefix: "78-1C-9D",vendor: "Silicon Laboratories"},
  {max_prefix: "3C-BD-14",vendor: "Diebold Nixdorf"},
  {max_prefix: "A0-9A-8E",vendor: "Apple, Inc."},
  {max_prefix: "BC-9F-58",vendor: "Apple, Inc."},
  {max_prefix: "90-CD-E8",vendor: "Apple, Inc."},
  {max_prefix: "70-ED-FA",vendor: "imperix Ltd"},
  {max_prefix: "00-21-0E",vendor: "Gilbarco Inc."},
  {max_prefix: "E8-6D-16",vendor: "Elmec Elettronica SRL"},
  {max_prefix: "84-FE-40",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "EC-E9-F5",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "D0-AB-4A",vendor: "Telink Micro LLC"},
  {max_prefix: "6C-7D-B7",vendor: "Cisco Meraki"},
  {max_prefix: "14-BA-AF",vendor: "BKS GmbH"},
  {max_prefix: "5C-CD-A8",vendor: "ITEL MOBILE LIMITED"},
  {max_prefix: "9C-C2-C4",vendor: "IEIT SYSTEMS Co., Ltd."},
  {max_prefix: "6C-92-BF",vendor: "IEIT SYSTEMS Co., Ltd."},
  {max_prefix: "D4-E4-C3",vendor: "Ciena Corporation"},
  {max_prefix: "3C-1A-65",vendor: "Nokia"},
  {max_prefix: "94-22-4C",vendor: "Qingdao Haier Technology Co.,Ltd"},
  {max_prefix: "54-26-3D",vendor: "Sony Corporation"},
  {max_prefix: "54-80-8A",vendor: "PT. BIZLINK TECHNOLOGY INDONESIA"},
  {max_prefix: "F0-F0-69",vendor: "Private"},
  {max_prefix: "6C-78-C1",vendor: "Juniper Networks"},
  {max_prefix: "8C-F0-DF",vendor: "Beijing Zhongyuan Yishang Technology Co.,LTD"},
  {max_prefix: "F8-38-8D",vendor: "New H3C Technologies Co., Ltd"},
  {max_prefix: "34-D8-68",vendor: "Broadcom Limited"},
  {max_prefix: "24-F3-E3",vendor: "eero inc."},
  {max_prefix: "40-2F-51",vendor: "Maxtek Optoelectronics Ltd"},
  {max_prefix: "E0-A4-98",vendor: "SHENZHEN ORFA TECH CO.,LTD"},
  {max_prefix: "B0-5A-7B",vendor: "Huawei Device Co., Ltd."},
  {max_prefix: "A8-08-CF",vendor: "Vestel Elektronik San ve Tic. A.S."},
  {max_prefix: "54-48-9C",vendor: "CDOUBLES ELECTRONICS CO. LTD."},
  {max_prefix: "E0-3D-A6",vendor: "Taicang T&W Electronics"},
  {max_prefix: "BC-CD-99",vendor: "Intel Corporate"},
  {max_prefix: "00-C0-29",vendor: "Aginode Germany GmbH"},
  {max_prefix: "FC-23-CD",vendor: "SHENZHEN BILIAN ELECTRONIC CO.\uff0cLTD"},
  {max_prefix: "AC-45-EF",vendor: "Intel Corporate"},
  {max_prefix: "28-0C-50",vendor: "Intel Corporate"},
  {max_prefix: "E4-66-E5",vendor: "Nanjing Qinheng Microelectronics Co., Ltd."},
  {max_prefix: "04-A8-5A",vendor: "SZ DJI TECHNOLOGY CO.,LTD"},
  {max_prefix: "38-7F-8B",vendor: "Apple, Inc."},
  {max_prefix: "5C-13-CC",vendor: "Apple, Inc."},
  {max_prefix: "4C-CD-B6",vendor: "Apple, Inc."},
  {max_prefix: "00-0F-15",vendor: "Icotera A/S"},
  {max_prefix: "00-1E-80",vendor: "Icotera A/S"},
  {max_prefix: "0C-F0-7B",vendor: "Shenzhen Skyworth Digital  Technology  CO., Ltd"},
  {max_prefix: "A0-48-39",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "F4-89-18",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "08-B6-57",vendor: "AVM Audiovisuelles Marketing und Computersysteme GmbH"},
  {max_prefix: "F4-68-02",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "00-17-A5",vendor: "MediaTek Inc"},
  {max_prefix: "44-A7-F4",vendor: "Apple, Inc."},
  {max_prefix: "18-66-96",vendor: "IEEE Registration Authority"},
  {max_prefix: "00-0A-00",vendor: "MediaTek Inc"},
  {max_prefix: "44-FE-EF",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "24-75-B3",vendor: "GUANGDONG OPPO MOBILE TELECOMMUNICATIONS CORP.,LTD"},
  {max_prefix: "64-33-AA",vendor: "Mellanox Technologies, Inc."},
  {max_prefix: "A0-AD-9F",vendor: "ASUSTek COMPUTER INC."},
  {max_prefix: "68-25-DD",vendor: "Espressif Inc."},
  {max_prefix: "78-5F-6C",vendor: "Arista Networks"},
  {max_prefix: "FC-5A-DC",vendor: "Keenetic Limited"},
  {max_prefix: "84-EA-ED",vendor: "Roku, Inc"},
  {max_prefix: "60-92-C8",vendor: "Roku, Inc"},
  {max_prefix: "20-34-62",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "60-45-E8",vendor: "Arcadyan Corporation"},
  {max_prefix: "C0-DC-AB",vendor: "LG Innotek"},
  {max_prefix: "10-B6-76",vendor: "HP Inc."},
  {max_prefix: "54-DC-E9",vendor: "Silicon Laboratories"},
  {max_prefix: "9C-E5-49",vendor: "IEEE Registration Authority"},
  {max_prefix: "C4-22-4E",vendor: "Telink Micro LLC"},
  {max_prefix: "84-AF-1F",vendor: "GopherTec Inc."},
  {max_prefix: "9C-53-85",vendor: "PT. Hartono Istana Teknologi"},
  {max_prefix: "24-86-02",vendor: "Bouffalo Lab (Nanjing) Co., Ltd."},
  {max_prefix: "B4-2F-03",vendor: "Quectel Wireless Solutions Co.,Ltd."},
  {max_prefix: "90-B9-42",vendor: "zte corporation"},
  {max_prefix: "20-1E-1D",vendor: "HUAWEI TECHNOLOGIES CO.,LTD"},
  {max_prefix: "00-00-6C",vendor: "Schneider Electric"},
  {max_prefix: "60-D8-A4",vendor: "Vantiva Connected Home - Technologies Telco"},
  {max_prefix: "48-A6-B8",vendor: "Sonos, Inc."},
  {max_prefix: "C4-38-75",vendor: "Sonos, Inc."},
  {max_prefix: "20-19-F3",vendor: "WavTek Technologies, Inc"},
  {max_prefix: "5C-AA-FD",vendor: "Sonos, Inc."},
  {max_prefix: "B8-E9-37",vendor: "Sonos, Inc."},
  {max_prefix: "A8-0B-6B",vendor: "Chipsea Technologies (Shenzhen) Corp."},
  {max_prefix: "54-EF-5B",vendor: "Science Corporation"},
  {max_prefix: "C4-71-0F",vendor: "Xiaomi Communications Co Ltd"},
  {max_prefix: "1C-F4-3F",vendor: "Arcadyan Corporation"
  }
];

function setCellCodeFormat0(lac: string, cellId: string, tec: string): any {
  if (tec.indexOf("U") >= 0 || tec.indexOf("G") >= 0) {
    if (lac.length < 5) {
      while (lac.length != 5) {
        lac = "0" + lac;
      }
    }
    if (cellId.length < 5) {
      while (cellId.length != 5) {
        cellId = "0" + cellId;
      }
    }
  } else if (tec.indexOf("L") >= 0) {
    if (lac.length < 7) {
      while (lac.length != 7) {
        lac = "0" + lac;
      }
    }
    if (cellId.length < 3) {
      while (cellId.length != 3) {
        cellId = "0" + cellId;
      }
    }
  } else if (tec.indexOf("5GNSA")) {
    lac = "0000000";
    cellId = "000";
  }
  const lac_cellid = lac + "-" + cellId;
  return lac_cellid;
}

export interface IScanResult {
  ScanNumber: number;
}

export class OperatoriTel {
  name = "";
  img = "";
  constructor(name: string, img: string) {
    this.name = name;
    this.img = img;
  }
}

export class frequenze {
  download = 0;
  constructor(download: number) {
    this.download = download;
  }
}

interface AvailableCell {
  cellCode: string;
  band: string;
  arfcn: number;
  power: number;
  operatore: string;
  img: string;
  down_frequency: number;
  find: boolean; // trovata cella di interesse
}

export class ScanResultH implements IScanResult {
  location: number[] = [];
  ScanNumber = 0;
  Technology = "";
  LastPart = 0;
  time: Date | undefined;
  Cells: AvailableCell[] = [];

  constructor(ScanNumber: number, Technology: string, Part: number, location: number[]) {
    this.ScanNumber = ScanNumber;
    if (Technology.indexOf("L") >= 0) {
      this.Technology = "LTE";
    } else if (Technology.indexOf("U") >= 0) {
      this.Technology = "UMTS";
    } else if (Technology.indexOf("G") >= 0) {
      this.Technology = "GSM";
    }
    this.LastPart = Part;
    this.location = location;
  }

  addCells(cell: string, band: string, freq: number, power: number, operator: OperatoriTel, down: number) {
    this.Cells.push(
      {
        cellCode: cell,
        band: band,
        arfcn: freq,
        power: power,
        operatore: operator.name,
        img: operator.img,
        down_frequency: (Number)(down.toFixed(1)),
        find: false,
      }
    );
  }

  getString() {
    let s = "";
    let i = 0;
    for (i = 0; i < this.Cells.length; i++) {
      s = s + this.Cells[i] + ";";
    }
    return s;
  }
}

export class ScanResultS implements IScanResult {
  ScanNumber = 0;
  Technology = "";
  operatore: OperatoriTel;
  constructor(ScanNumber: number, Technology: string, operatore: OperatoriTel) {
    this.ScanNumber = ScanNumber;
    this.Technology = Technology;
    this.operatore = operatore;
  }
}

export class ScanResultS_5GNSA extends ScanResultS {
  ScellID = "";
  frequencyBand = "";
  rsrp = 0;
  lte: ScanResultS_lte;
  constructor(ScanNumber: number, Technology: string,
    ScellID: string,
    frequencyBand: string,
    rsrq: string,
    lte: ScanResultS_lte
  ) {
    super(ScanNumber, Technology, lte.operatore);
    this.ScellID = ScellID;
    this.frequencyBand = frequencyBand;
    this.rsrp = Number(rsrq) / 10;
    this.lte = lte;
  }
}

export class ScanResultS_5GSA extends ScanResultS {
  OperationMode = "";
  mcc_mnc = "";
  ScellID = "";
  frequencyBand = "";
  rsrp = 0;
  cellcode = "";
  frequency: frequenze;
  find = false;
  constructor(ScanNumber: number, Technology: string,
    OperationMode: string,
    mcc_mnc: string,
    ScellID: string,
    frequencyBand: string,
    rsrq: string,
    operatore: OperatoriTel,
    frequency: frequenze
  ) {
    super(ScanNumber, Technology, operatore);
    this.OperationMode = OperationMode;
    this.mcc_mnc = mcc_mnc;
    this.ScellID = ScellID;
    this.frequencyBand = frequencyBand;
    this.rsrp = Number(rsrq) / 10;
    this.frequency = frequency;
  }
  setCellCode() {
    const value = parseInt(this.ScellID, 16);
    const value1 = Math.floor(value / 256);
    const value2 = value % 256;
    const lac_cellid = setCellCodeFormat0(String(value1), String(value2), "5GSA");
    this.cellcode = this.mcc_mnc + "-" + lac_cellid;
  }
}

export class ScanResultS_wcdma extends ScanResultS {
  OperationMode = "";
  mcc_mnc = "";
  ScellID = "";
  tac = "";
  frequencyBand = "";
  cellcode = "";
  value = 0; value1 = 0; value2 = 0;
  cellcodeS = "";
  frequency = "";
  find = false;
  constructor(ScanNumber: number, Technology: string,
    OperationMode: string,
    mcc_mnc: string,
    tac: string,
    ScellID: string,
    frequencyBand: string,
    operatore: OperatoriTel,
    frequency: string
  ) {
    super(ScanNumber, Technology, operatore);
    this.OperationMode = OperationMode;
    this.mcc_mnc = mcc_mnc;
    this.tac = tac;
    this.ScellID = ScellID;
    this.frequencyBand = frequencyBand;
    this.frequency = frequency;
  }
  setCellCode() {
    const lac = parseInt(this.tac);
    const scellid = parseInt(this.ScellID);
    const rncid = scellid / 65536;
    const cid = scellid % 65536;
    const lac_cellid = setCellCodeFormat0(String(lac), String(cid), "U");
    this.cellcode = this.mcc_mnc + "-" + lac_cellid;
  }
}
export class ScanResultS_lte extends ScanResultS {
  OperationMode = "";
  mcc_mnc = "";
  ScellID = "";
  tac = "";
  frequencyBand = "";
  rsrp = 0;
  cellcode = "";
  value = 0; value1 = 0; value2 = 0;
  cellcodeS = "";
  frequency: frequenze;
  find: boolean;
  constructor(ScanNumber: number, Technology: string,
    OperationMode: string,
    mcc_mnc: string,
    tac: string,
    ScellID: string,
    frequencyBand: string,
    rsrp: string,
    operatore: OperatoriTel,
    frequency: frequenze
  ) {
    super(ScanNumber, Technology, operatore);
    this.OperationMode = OperationMode;
    this.mcc_mnc = mcc_mnc;
    this.tac = tac;
    this.ScellID = ScellID;
    this.frequencyBand = frequencyBand;
    this.rsrp = Number(rsrp) / 10;
    this.frequency = frequency;
    this.find = false;
  }
  setCellCode() {
    const value = Number(this.ScellID);
    const value1 = Math.floor(value / 256);
    const value2 = value % 256;
    const lac_cellid = setCellCodeFormat0(String(value1), String(value2), "L");
    this.cellcode = this.mcc_mnc + "-" + lac_cellid;
  }
  setCellCodeSecond() {
    if (this.mcc_mnc.indexOf("222-88") >= 0 || this.mcc_mnc.indexOf("222-99") >= 0 || this.mcc_mnc.indexOf("222-50") >= 0) {
      const num = parseInt(this.tac, 16);
      const lac_cellid = setCellCodeFormat0(String(num), String(this.ScellID), "L");
      this.cellcodeS = " | " + this.mcc_mnc + "-" + lac_cellid;
    }
  }
}

export class ScanResultS_noService extends ScanResultS {
  OperationMode = "";
  constructor(ScanNumber: number, Technology: string, OperationMode: string,
    operatore: OperatoriTel
  ) {
    super(ScanNumber, Technology, operatore);
    this.OperationMode = OperationMode;
  }
}

export class ActiveCell extends ScanResultS {
  OperationMode = "";
  mcc_mnc = "";
  lac = "";
  cellId = "";
  arfcn = "";
  signal = "";
  cellcode = "";
  type = "";
  band = "";
  find = false;

  constructor(ScanNumber: number, Technology: string,
    operationMode: string, mcc_mnc: string, lac: string, cellId: string,
    arfcn: string, signal: string, operatore: OperatoriTel, band: string
  ) {
    super(ScanNumber, Technology, operatore);
    this.OperationMode = operationMode;
    this.mcc_mnc = mcc_mnc;
    this.lac = lac.length == 0 ? "0" : lac;
    this.cellId = cellId.length == 0 ? "0" : cellId;
    this.arfcn = arfcn;
    this.signal = signal;
    this.setBand(band);
  }
  setBand(band: string) {
    switch (band) {
      case "0": {
        this.band = "GSM 850";
        return;
      }
      case "1": {
        this.band = "GSM 900";
        return;
      }
      case "2": {
        this.band = "GSM 1800";
        return;
      }
      case "3": {
        this.band = "GSM 1900";
        return;
      }
      default:
        this.band = band;
        return;
    }
  }
  setCellCode() {
    const lac = parseInt(this.lac, 16);
    const cellid = parseInt(this.cellId, 16);
    const lac_cellid = setCellCodeFormat0(String(lac), String(cellid), "G");
    this.cellcode = this.mcc_mnc + "-" + lac_cellid;
  }
  setType(type: string) {
    this.type = type;
  }
}

export class ActiveScan implements IScanResult {
  ScanNumber = 0;
  Cell: ActiveCell[] = [];

  constructor(ScanNumber: number) {
    this.ScanNumber = ScanNumber;
  }
  addCell(cell: ActiveCell) {
    this.Cell.push(cell);
  }
}

export class ScanGPS implements IScanResult {
  ScanNumber = 0;
  latitude = 0
  longitude = 0;
  altitude = "";
  constructor(ScanNumber: number, Altitude: string) {
    this.ScanNumber = ScanNumber;
    this.altitude = Altitude;
  }
  setConversion(value: string, ind: string, type: number) {
    let deg = "", min = "";
    let m1 = -1, m2 = -1;
    let i = 0;
    for (i = 0; i < value.length; i++) {
      if (value.charAt(i) == '.') {
        m1 = i - 2;
        m2 = i - 1;
        min = min + value.charAt(m1) + value.charAt(m2) + value.charAt(i);
      }
      else if (m1 != -1) {
        min = min + value.charAt(i);
      }
    }
    for (i = 0; i < m1; i++) {
      deg = deg + value.charAt(i);
    }
    const degrees = Number(deg);
    const minutes = Number(min);
    let dec = degrees + (minutes / 60);
    if (ind.indexOf("S") >= 0 || ind.indexOf("W") >= 0) {
      dec = -1 * dec;
    }
    if (type == 1) {
      this.latitude = dec;
    } else {
      this.longitude = dec;
    }
  }
}

export class ScanResult {
  static parseFromAtCommand(atComm: string, surveyNum: number, location: number[]): IScanResult | null | undefined { // per moduli B,C,D
    if (atComm == null || atComm.length < 5) {
      return null;
    }
    if (atComm.indexOf("^NETSCAN") >= 0) {
      return ScanResult.parseHuaAtCommand(atComm, surveyNum, location);
    }
    else if (atComm.indexOf("+CPSI") >= 0) {
      return ScanResult.parseSimAtCommand(atComm, surveyNum, location);
    }
    else if (atComm.indexOf("+CGPSINFO") >= 0) {
      return ScanResult.parseGPSCommand(atComm, surveyNum, "");
    }
    return null;
  }

  static parseAtCommandHauwei(atComm: string, surveyNum: number, part: number, type: string, location: number[]) { // per moduli 1,2,3
    if (atComm.indexOf("ERROR") >= 0) {
      return null;
    }
    const activeScan = new ActiveScan(surveyNum);
    const commands = atComm.split("AT^MONNC");
    let atMonsc = atComm, atMonnc = atComm;
    if (commands.length == 2) {
      atMonsc = commands[0];
      atMonnc = commands[1];
    } else if (commands.length == 1) {
      if (commands[0].indexOf("^MONSC") >= 0) {
        atMonsc = commands[0];
      } else {
        atMonnc = commands[0];
      }
    }
    if (atMonsc.indexOf("^MONSC") >= 0) {
      atMonsc = atMonsc.replace("AT^MONSC^MONSC: ", "");
      if (atMonsc.length == 0) return;
      const monsc = atMonsc.split(",");
      const servingCell = new ActiveCell(surveyNum, monsc[0], "", monsc[1] + "-" + monsc[2], monsc[7], monsc[6], monsc[4], monsc[8], this.getOperatore(monsc[1] + "-" + monsc[2]), (monsc[3]));
      servingCell.setCellCode();
      servingCell.setType("Serving");
      activeScan.addCell(servingCell);
      $BTSTracker.value.mcc_mncServingCell = monsc[1] + "-" + monsc[2];
    }
    if (atMonnc.indexOf("^MONNC") >= 0) {
      atMonnc = atMonnc.replaceAll("^MONNC: ", ";");
      if (atMonnc.length == 0) return;
      const cell = atMonnc.split(";");
      for (let i = 0; i < cell.length; i++) {
        if (cell[i].length > 0) {
          const gsm = cell[i].split(",");
          if (gsm[0].indexOf("GSM") >= 0) {
            const op = new OperatoriTel("", "");
            if (gsm[0].indexOf("GSM") >= 0) {
              const cellGSM = new ActiveCell(surveyNum, gsm[0], "", $BTSTracker.value.mcc_mncServingCell, gsm[5], gsm[4], gsm[2], gsm[6], op, (gsm[1]));
              cellGSM.setCellCode();
              cellGSM.setType("Neighbor");
              activeScan.addCell(cellGSM);
            } else {
              // NONE
              break;
            }
          }
        }
      }
      return activeScan;
    }
    const cell = new ScanResultH(surveyNum, type, part, location);
    atComm = atComm.replaceAll("^NETSCAN: ", ";");
    const net = atComm.split(";");
    let i = 0;
    for (i = 1; i < net.length; i++) {
      const info = net[i].split(",");
      if (info[4].length > 0 && info[5].length > 0 && info[3].length > 0 && info[8].length > 0) {
        let v1, v2;
        if (type.indexOf("L") >= 0) {
          v1 = Math.floor(parseInt(info[8], 16) / 256);
          v2 = (Number)(parseInt(info[8], 16) % 256);
        } else if (type.indexOf("G") >= 0) {
          v1 = parseInt(info[3], 16);
          v2 = parseInt(info[8], 16);
        } else if (type.indexOf("U") >= 0) {
          //console.log(info);
          const lac = parseInt(info[3], 16); //11001
          const len = (info[8] + "").length; // "1D0B3BC"
          let cid = 0;
          if (len > 2) {
            cid = parseInt(info[8].substring(len - 4), 16); //46012
          }
          v1 = lac;
          v2 = cid;
        }
        const c1 = ScanResult.cellH(info[4], info[5], (String)(v1), (String)(v2), type);
        let band;
        if (type.indexOf("L") >= 0) {
          band = this.getBand(1, type, (String)(info[0]));
        } else {
          band = this.getBand(1, type, info[9]);
        }
        const mcc_mnc = info[4] + "-" + info[5];
        const operator = this.getOperatore(mcc_mnc);
        const frequency = this.getFrequency(type, (Number)(info[0]));
        cell.addCells(c1, band, (Number)(info[0]), (Number)(info[7]), operator, frequency.download);
      }
    }
    return cell;
  }

  static parse4GModule(atComm: string, surveyNum: number, part: number, type: string, location: number[]): IScanResult | null | undefined {
    if (atComm.indexOf("+CGPSINFO") >= 0) {
      return ScanResult.parseGPSCommand(atComm, surveyNum, type);
    } else if (type.indexOf("L") >= 0 || type.indexOf("U") >= 0 || type.indexOf("G") >= 0) {
      return ScanResult.parseAtCommandH2(atComm, surveyNum, part, type, location);
    } else {
      return ScanResult.parseSimAtCommand(atComm, surveyNum, location);
    }
  }

  static parseAtCommandH2(atComm: string, surveyNum: number, part: number, type: string, location: number[]) { // per moduli 4,5
    if (atComm.indexOf("ERROR") >= 0) {
      return null;
    }
    if (atComm.indexOf("END") >= 0) {
      return null;
    }
    if (atComm.indexOf("no results") >= 0) {
      return null;
    }
    const cell = new ScanResultH(surveyNum, type, part, location);
    let resp = atComm.replaceAll('"', "");
    resp = resp.replaceAll("\r", "");
    if (resp.length > 0) {
      let command: any;
      if (resp.indexOf("+AOPS") >= 0) {
        resp = resp.replace("+", ";+");
      }
      if (resp.indexOf(";") >= 0) {
        command = resp.split(";");
      }
      for (let x = 0; x < command.length; x++) {
        if (command[x].length > 0) {
          if (command[x].indexOf("+AOPS:") >= 0) {
            command[x] = command[x].replace("+AOPS:", "");
            $BTSTracker.value.aops = command[x].split(",");
          } else {
            const cellAvailable = command[x].split(",");
            for (let i = 0; i < cellAvailable.length; i++) {
              if (cellAvailable[i].indexOf(":") >= 0) {
                const value = cellAvailable[i].split(":");
                cellAvailable[i] = value[1];
              }
            }
            const mcc = $BTSTracker.value.aops[2].charAt(0) + $BTSTracker.value.aops[2].charAt(1) + $BTSTracker.value.aops[2].charAt(2);
            let mnc = "";
            for (let y = 0; y < $BTSTracker.value.aops[2].length; y++) {
              if (y > 2) {
                mnc = mnc + $BTSTracker.value.aops[2].charAt(y);
              }
            }
            if (mnc.endsWith(' ')) {
              mnc = mnc.slice(0, -1);
            }
            let v1, v2;
            if (cellAvailable.length == 9) {
              if (type.indexOf("U") >= 0) {
                v1 = cellAvailable[6];
                v2 = (Number)(parseInt(cellAvailable[7]) % 65536);
                //console.log(v1);
                //console.log(v2);
              } else if (type.indexOf("L") >= 0) {
                v1 = Math.floor(parseInt(cellAvailable[7]) / 256);
                v2 = (Number)(parseInt(cellAvailable[7]) % 256);
              }
            } else if (cellAvailable.length == 10) {
              if (type.indexOf("G") >= 0) { // gsm
                v1 = cellAvailable[5];
                v2 = cellAvailable[8];
              }
            } else {
              return null;
            }
            const c = ScanResult.cellH(mcc, mnc, (String)(v1), (String)(v2), type);
            for (let x = 0; x < c.length; x++) {
              if (c.charAt(x) < '0' || c.charAt(x) > '9') {
                if (c.charAt(x) != '-') {
                  return null;
                }
              }
            }
            const band = this.getBand(3, type, (String)(cellAvailable[2]));
            const mcc_mnc = mcc + "-" + mnc;
            const operator = this.getOperatore(mcc_mnc);
            const frequency = this.getFrequency(type, (Number)(cellAvailable[2]));
            cell.addCells(c, band, (Number)(cellAvailable[2]), (Number)(cellAvailable[3]), operator, frequency.download);
          }
        }

      }
      // se è l'intestazione per cambio operatore
    }
    return cell;
  }

  static getFrequency(type: string, arfcn: number): frequenze {
    let download = 0;
    if (type.indexOf("G") >= 0) {
      if (arfcn >= 259 && arfcn <= 293) {
        download = 450.6 + 0.2 * (arfcn - 259);
      } else if (arfcn >= 306 && arfcn <= 340) {
        download = 479 + 0.2 * (arfcn - 306);
      } else if (arfcn >= 438 && arfcn <= 511) {
        download = 747.2 + 0.2 * (arfcn - 438);
      } else if (arfcn >= 128 && arfcn <= 251) {
        download = 824.2 + 0.2 * (arfcn - 128);
      } else if (arfcn >= 1 && arfcn <= 124) {
        download = 890 + 0.2 * arfcn;
      } else if (arfcn >= 975 && arfcn <= 1023) {
        download = 890 + 0.2 * (arfcn - 1024);
      } else if (arfcn >= 512 && arfcn <= 885) {
        download = 1718 + 0.2 * (arfcn - 512);
      }
    }
    if (type.indexOf("L") >= 0) {
      const myBand = lteBands.find(o => o.dl_earfcn && arfcn >= o.dl_earfcn[0] && arfcn <= o.dl_earfcn[1]);
      if (myBand) {
        download = myBand.dl_fr[0] + 0.1 * (arfcn - myBand.dl_earfcn[0]);
      }
      else {
        download = 0;
      }
    }
    let FDL_offset, FUL_offset, diff, Nul;
    if (type.indexOf("U") >= 0) {
      if (((arfcn >= 10562) && (arfcn <= 10838))) {
        FDL_offset = 0;
        FUL_offset = 0;
        diff = arfcn - 10562;
        Nul = 9612 + diff;
      }
      else if (((arfcn >= 9662) && (arfcn <= 9938))) {
        FDL_offset = 0;
        FUL_offset = 0;
        diff = arfcn - 9662;
        Nul = 9262 + diff;
      }
      else if (((arfcn >= 1162) && (arfcn <= 1513))) {
        FDL_offset = 1575;
        FUL_offset = 1525;
        diff = arfcn - 1162;
        Nul = 937 + diff;
      }
      else if (((arfcn >= 1537) && (arfcn <= 1738))) {
        FDL_offset = 1805;
        FUL_offset = 1450;
        diff = arfcn - 1537;
        Nul = 1312 + diff;
      }
      else if (((arfcn >= 4357) && (arfcn <= 4458))) {
        FDL_offset = 0;
        FUL_offset = 0;
        diff = arfcn - 4357;
        Nul = 4132 + diff;
      }
      else if (((arfcn >= 4387) && (arfcn <= 4413))) {
        FDL_offset = 0;
        FUL_offset = 0;
        diff = arfcn - 4387;
        Nul = 4162 + diff;
      }
      else if (((arfcn >= 2237) && (arfcn <= 2563))) {
        FDL_offset = 2175;
        FUL_offset = 2100;
        diff = arfcn - 2237;
        Nul = 2012 + diff;
      }
      else if (((arfcn >= 2937) && (arfcn <= 3088))) {
        FDL_offset = 340;
        FUL_offset = 340;
        diff = arfcn - 2937;
        Nul = 2712 + diff;
      }
      else if (((arfcn >= 9237) && (arfcn <= 9387))) {
        FDL_offset = 0;
        FUL_offset = 0;
        diff = arfcn - 9237;
        Nul = 8762 + diff;
      }
      else if (((arfcn >= 3112) && (arfcn <= 3388))) {
        FDL_offset = 1490;
        FUL_offset = 1135;
        diff = arfcn - 3112;
        Nul = 2887 + diff;
      }
      else if (((arfcn >= 3712) && (arfcn <= 3787))) {
        FDL_offset = 736;
        FUL_offset = 733;
        diff = arfcn - 3712;
        Nul = 3487 + diff;
      }
      else if (((arfcn >= 3842) && (arfcn <= 3903))) {
        FDL_offset = -37;
        FUL_offset = -22;
        diff = arfcn - 3842;
        Nul = 3617 + diff;
      }
      else if (((arfcn >= 4017) && (arfcn <= 4043))) {
        FDL_offset = -55;
        FUL_offset = 21;
        diff = arfcn - 4017;
        Nul = 3792 + diff;
      }
      else if (((arfcn >= 4117) && (arfcn <= 4143))) {
        FDL_offset = -63;
        FUL_offset = 12;
        diff = arfcn - 4117;
        Nul = 3892 + diff;
      }
      else if (((arfcn >= 712) && (arfcn <= 763))) {
        FDL_offset = 735;
        FUL_offset = 770;
        diff = arfcn - 712;
        Nul = 312 + diff;
      }
      else if (((arfcn >= 4512) && (arfcn <= 4638))) {
        FDL_offset = -109;
        FUL_offset = -23;
        diff = arfcn - 4512;
        Nul = 4287 + diff;
      }
      else if (((arfcn >= 862) && (arfcn <= 912))) {
        FDL_offset = 1326;
        FUL_offset = 1358;
        diff = arfcn - 862;
        Nul = 462 + diff;
      }
      else if (((arfcn >= 4662) && (arfcn <= 5038))) {
        FDL_offset = 2580;
        FUL_offset = 2525;
        diff = arfcn - 4662;
        Nul = 4437 + diff;
      }
      else if (((arfcn >= 5112) && (arfcn <= 5413))) {
        FDL_offset = 910;
        FUL_offset = 875;
        diff = arfcn - 5112;
        Nul = 4887 + diff;
      }
      else if (((arfcn >= 5762) && (arfcn <= 5913))) {
        FDL_offset = -291;
        FUL_offset = -291;
        diff = arfcn - 5762;
        Nul = 5537 + diff;
      }
      else {
        FDL_offset = 0;
        FUL_offset = 0;
        diff = 0;
        Nul = 0;
      }
      download = (FDL_offset + (0.2 * arfcn));
    }
    if (type.indexOf("5G") >= 0) {
      if (arfcn >= 0 && arfcn <= 599999) {
        download = 0 + 5 * (arfcn - 0);
      } else if (arfcn >= 600000 && arfcn <= 2016666) {
        download = 3000 + 15 * (arfcn - 600000);
      } else if (arfcn >= 2016667 && arfcn <= 3279165) {
        download = 24250.08 + 60 * (arfcn - 20166670);
      }
    }
    return new frequenze(download);
  }

  static getBand(modem: number, type: string, band: string): string {
    let s = "";
    if (modem == 3) {
      const arfcn = (Number)(band);
      if (type.indexOf("G") >= 0) {
        const myBand = gsmBands.find(o => arfcn >= o.earfcn[0] && arfcn <= o.earfcn[1]);
        if (myBand) {
          s = myBand.band;
        } else {
          s = "";
        }
        return s;
      } else if (type.indexOf("U") >= 0) {
        const myBand = umtsBands.find(o => arfcn >= o.earfcn[0] && arfcn <= o.earfcn[1]);
        if (myBand) {
          s = myBand.band;
        } else {
          s = "";
        }
        return s;
      }

    }

    if (band.indexOf("7FFFFFFFFFFFFFFF") >= 0) {
      return "All bands";
    }
    const band_ = (Number)(band);
    if (type.indexOf("L") >= 0) {
      const arfcn = (Number)(band);
      const myBand = lteBands.find(o => o.dl_earfcn && arfcn >= o.dl_earfcn[0] && arfcn <= o.dl_earfcn[1]);
      if (myBand) {
        s = "B" + myBand.band + " " + myBand.duplex;
      }
      else {
        s = "";
      }
      return s;
    } else {
      switch (band_) {
        case 80000:
          s = "GSM 850";
          break;
        case 80:
          s = "GSM DCS systems";
          break;
        case 100:
          s = "Extended GSM 900";
          break;
        case 200:
          s = "Primary GSM 900";
          break;
        case 100000:
        case 100300:
          s = "Railway GSM 900";
          break;
        case 200000:
          s = "GSM PCS";
          break;
        case 400000:
          s = "WCDMA IMT 2100";
          break;
        case 800000:
          s = "WCDMA_II_PCS_1900";
          break;
        case 4000000:
          s = "WCDMA_V_850";
          break;
        case 8000000:
          s = "WCDMA_VI_800";
          break;
        case 4000000000000:
          s = "WCDMA_IX_1700";
          break;
        case 2000000000000:
          s = "WCDMA_VIII_900";
          break;
        case 1000000000000000:
          s = "WCDMA_XIX_850";
          break;
        case 2000000:
          s = "AWS";
          break;
        case 680380:
          s = "Automatic";
          break;
        default:
          s = "" + band;
          break;
      }
      return s;
    }
  }

  static getOperatore(mcc_mnc: string): OperatoriTel {
    const operatorInfo = apn.find(single_apn => single_apn.mcc_mnc.indexOf(mcc_mnc) >= 0);
    if (operatorInfo != null) {
      return new OperatoriTel(operatorInfo.operatore, operatorInfo.img);
    } else {
      return new OperatoriTel("", "");
    }
  }

  private static cellH(mcc: string, mnc: string, val1: string, val2: string, type: string): string {
    const lac_cellid = setCellCodeFormat0(String(val1), String(val2), type);
    return (String)(mcc + "-" + mnc + "-" + lac_cellid);
  }

  private static parseHuaAtCommand(atComm: string, scannum: number, location: number[]): IScanResult | null | undefined {
    if (atComm.indexOf("ERROR") >= 0) {
      return null;
    }
    const cell = new ScanResultH(scannum, "", 0, location);
    return cell;
  }

  private static parseSimAtCommand(atComm: string, scannum: number, location: number[]): IScanResult | null | undefined {
    let gsmInfo = "";
    const activeScan = new ActiveScan(scannum);
    if (atComm.indexOf("+GSMCIINFO:") >= 0 && atComm.indexOf("+CPSI") >= 0) {
      atComm = atComm.replace("+GSMCIINFO:", ";+GSMCIINFO:");
      const activeInfo = atComm.split(";");
      atComm = activeInfo[0];
      gsmInfo = activeInfo[2];
    } else if (atComm.indexOf("+GSMCIINFO") >= 0) {
      gsmInfo = atComm;
      atComm = "";
    }
    if (atComm.indexOf("+CPSI") >= 0) {
      const a = atComm.replaceAll("+CPSI: ", ";");
      const arr = a.split(";");
      if (arr.length > 0) {
        if (arr.length == 3) {
          const lte = arr[1].split(",");
          const operator = this.getOperatore(lte[2]);
          const frequency = this.getFrequency(lte[0], (Number)(lte[7]));
          const cellLTE = new ScanResultS_lte(scannum, lte[0], lte[1], lte[2], lte[3], lte[4], lte[6], lte[11], operator, frequency);
          cellLTE.setCellCode();
          cellLTE.setCellCodeSecond();
          const nsa = arr[2].split(",");
          const cell5G = new ScanResultS_5GNSA(scannum, nsa[0], nsa[1], nsa[2], nsa[4], cellLTE);
          return cell5G;
        }
        else if (arr.length == 2) {
          if (arr[1].indexOf("LTE") >= 0) {
            const lte = arr[1].split(",");
            const operator = this.getOperatore(lte[2]);
            const frequency = this.getFrequency(lte[0], (Number)(lte[7]));
            const cellLTE = new ScanResultS_lte(scannum, lte[0], lte[1], lte[2], lte[3], lte[4], lte[6], lte[11], operator, frequency);
            cellLTE.setCellCode();
            cellLTE.setCellCodeSecond();
            return cellLTE;
          } else if (arr[1].indexOf("WCDMA") >= 0) {
            const wcdma = arr[1].split(",");
            const operator = this.getOperatore(wcdma[2]);
            const cellwcdma = new ScanResultS_wcdma(scannum, wcdma[0], wcdma[1], wcdma[2], wcdma[3], wcdma[4], wcdma[5], operator, wcdma[7]);
            cellwcdma.setCellCode();
            return cellwcdma;
          } else if (arr[1].indexOf("NR5G_SA") >= 0) {
            const sa = arr[1].split(",");
            const operator = this.getOperatore(sa[2]);
            const frequency = this.getFrequency(sa[0], (Number)(sa[7]));
            const cellSA = new ScanResultS_5GSA(scannum, sa[0], sa[1], sa[2], sa[4], sa[6], sa[10], operator, frequency);
            cellSA.setCellCode();
            return cellSA;
          } else if (arr[1].indexOf("NO") >= 0) {
            const no = arr[1].split(",");
            const operator = this.getOperatore("");
            const cellNo = new ScanResultS_noService(scannum, no[0], no[1], operator);
            return cellNo;
          } else if (arr[1].indexOf("GSM") >= 0) {
            const gsm = arr[1].split(",");
            const operator = this.getOperatore(gsm[2]);
            const arfcn = gsm[5].split(" ");
            gsm[6] = gsm[6].replaceAll(" ", "");
            const cellGSM = new ActiveCell(scannum, gsm[0], gsm[1], gsm[2], gsm[3], gsm[4], arfcn[0], "-" + gsm[6], operator, (arfcn[1] + " " + arfcn[2]));
            cellGSM.setCellCode();
            cellGSM.setType("Serving");
            $BTSTracker.value.mcc_mncServingCell = gsm[2];
            activeScan.addCell(cellGSM);
          }
        }
      }
    }
    if (gsmInfo.indexOf("+GSMCIINFO:") >= 0) {
      const neighbor = gsmInfo.split("+GSMCIINFO: ");
      for (let i = 0; i < neighbor.length; i++) {
        if (neighbor[i].length > 0) {
          neighbor[i] = neighbor[i].replace(";", "");
          const infos = neighbor[i].split(",");
          for (let x = 0; x < infos.length; x++) {
            if (infos[x].indexOf(":") >= 0) {
              const support = infos[x].split(":");
              infos[x] = support[1];
            }
          }
          if (infos[1].indexOf("000-00") < 0) {
            const signal = -parseInt(infos[4]);
            const neighborCell = new ActiveCell(scannum, "GSM", "", $BTSTracker.value.mcc_mncServingCell, infos[2], infos[3], infos[5], (String)(signal), this.getOperatore($BTSTracker.value.mcc_mncServingCell), "");
            neighborCell.setCellCode();
            neighborCell.setType("Neighbor");
            activeScan.addCell(neighborCell);
          }
        }
      }
    }
    return activeScan;
  }

  private static parseGPSCommand(atComm: string, scannum: number, type: string): IScanResult | null | undefined {
    atComm = atComm.replace("+CGPSINFO: ", "");
    if (atComm.indexOf(",,,,,") >= 0 || atComm.indexOf("error") >= 0) {
      if (type.startsWith("G"))
        $BTSTracker.value.gpsBTS4G = 0;
      else
        $BTSTracker.value.gpsBTS5G = 0;

      return null;
    } else {
      $BTSTracker.value.tipo = 1;
      if (type.startsWith("G"))
        $BTSTracker.value.gpsBTS4G = 1;
      else
        $BTSTracker.value.gpsBTS5G = 1;

      if ($BTSTracker.value.gpsBTS4G == 1 && $BTSTracker.value.gpsBTS5G == 1) {
        if (type.startsWith("G"))
          return null;
      }
      const arr = atComm.split(",");
      const gps = new ScanGPS(scannum, arr[6]);
      gps.setConversion(arr[0], arr[1], 1);
      gps.setConversion(arr[2], arr[3], 2);
      return gps;
    }

  }

}

export class CellRecord {
  id? = undefined;

  date = "";

  // gelocation
  latitude = 0;
  longitude = 0;
  altitude = 0;

  accuracy = 0;
  speed = 0;
  course = 0;
  satellites_in_view = 0;

  // tower identification
  mcc = ""; // cgi
  mnc = "";
  lac = 0;
  cid = 0; //
  c1 = 0;
  c2 = 0;
  arfcn = 0;
  band = "";
  ta = 0;
  eci = 0;
  cell_survey_id = 0;
  is_genuine = false;
  bsic = 0;
  psc = 0;
  pci = 0;
  tac = 0;
  e_node_b = 0;
  power = 0;
  cell_mode = 0;
  tech = 0;
  fileName = "";

  toJson(): string {
    return JSON.stringify(this);
  }

  GetCGI(): string {
    return `${this.mcc}-${this.mnc}-${this.lac}-${this.cid}`;
  }

  GetTech(): string {
    switch (this.tech) {
      case 0:
        return "GSM";
      case 1:
        return "UMTS";
      case 2:
        return "UNKNOWN_2";
      case 3:
        return "LTE";
      case 4:
        return "AutoConnection5G";
      default:
        return "UNKNOWN";
    }
  }

  static fromJson(json: string): CellRecord {
    try {
      const j = JSON.parse(json);
      return Object.assign(new CellRecord(), j);
    } catch (error) { // just for logging the gson
      console.warn("Error parsing json: " + json);
      throw error;
    }
  }
  static fromCsv(csvString: string): CellRecord {
    const csv = csvString.split(";");
    const cell = new CellRecord();
    cell.mcc = csv[0];
    cell.mnc = csv[1];
    cell.lac = parseIntOrZero(csv[2]);
    cell.cid = parseIntOrZero(csv[3]);
    cell.c1 = parseIntOrZero(csv[4]);
    cell.c2 = parseIntOrZero(csv[5]);
    cell.arfcn = parseIntOrZero(csv[6]);
    cell.band = csv[7];
    cell.ta = parseIntOrZero(csv[8]);
    cell.eci = parseIntOrZero(csv[9]);
    cell.cell_survey_id = parseIntOrZero(csv[10]);
    cell.is_genuine = csv[11] === "true";
    cell.bsic = parseIntOrZero(csv[12]);
    cell.psc = parseIntOrZero(csv[13]);
    cell.pci = parseIntOrZero(csv[14]);
    cell.tac = parseIntOrZero(csv[15]);
    cell.e_node_b = parseIntOrZero(csv[16]);
    cell.power = parseIntOrZero(csv[17]);
    cell.cell_mode = parseIntOrZero(csv[18]);
    cell.tech = parseIntOrZero(csv[19]);
    cell.latitude = parseFloatOrZero(csv[20]) / 10000000;
    cell.longitude = parseFloatOrZero(csv[21]) / 10000000;
    cell.altitude = parseFloatOrZero(csv[22]) / 10000000;
    cell.speed = parseFloatOrZero(csv[23]);
    cell.course = parseFloatOrZero(csv[24]);
    cell.satellites_in_view = parseIntOrZero(csv[25]);
    cell.date = csv[26];
    return cell;
  }
  static toCsv(cr: CellRecord): string {
    return `${cr.mcc};${cr.mnc};${cr.lac};${cr.cid};${cr.c1};${cr.c2};${cr.arfcn};${cr.band};${cr.ta};${cr.eci};${cr.cell_survey_id};${cr.is_genuine};${cr.bsic};${cr.psc};${cr.pci};${cr.tac};${cr.e_node_b};${cr.power};${cr.cell_mode};${cr.tech};${cr.latitude};${cr.longitude};${cr.altitude};${cr.speed};${cr.course};${cr.satellites_in_view};${cr.date}`;
  }

  static toJson(cr: CellRecord): string {
    return JSON.stringify(cr);
  }
  _toJson(): string {
    return JSON.stringify(this);
  }
}

interface scanWifi {
  name: string;
  signal: number;
  encryp: string;
  mac: string;
  frequency: number;
  find: boolean;
  vendor: string;
}

export class ScanResultWiFi implements IScanResult {
  network: scanWifi[] = [];
  ScanNumber: number;

  constructor() {
    this.ScanNumber = 0;
  }

  addScan(name_: string, signal_: number, encryp_: string, mac_: string, freq: number, scanNumber: number, find: boolean) {
    if (name_.indexOf("hidden") >= 0) {
      name_ = "-";
    }
    const vendor_ = getMacVendor(mac_);
    this.network.push({
      name: name_,
      signal: signal_,
      encryp: encryp_,
      mac: mac_,
      frequency: freq,
      find: find,
      vendor: vendor_
    });
    this.ScanNumber = scanNumber;
  }

}

export class wifiResult {
  static parseWifi(atCommand: string, scannum: number) {
    if (atCommand.indexOf("Couldn't get a wifi connection") >= 0 || atCommand.indexOf("no result") >= 0) {
      return undefined;
    }
    atCommand = atCommand.replaceAll(";;;", ";;");
    atCommand = atCommand.replaceAll(";;", ";");
    const command = atCommand.split(";");
    const wifi = new ScanResultWiFi();
    for (let i = 0; i < command.length; i++) {
      if (command[i].indexOf("wifi,more") >= 0) {
        $BTSTracker.value.moreWifi = true;
        return wifi;
      }
      const value = command[i].split(", ");
      if (value[1] != undefined) {
        if (atCommand.indexOf("wifi") < 0) {
          // name, signal, encryption, mac, freq
          wifi.addScan(value[0], parseInt(value[1]), value[3], value[2].toUpperCase(), parseFloat("2.4"), scannum, false);
        } else if (atCommand.startsWith("wifi")) {
          wifi.addScan(value[2], parseInt(value[3]), value[4], value[1].toUpperCase(), parseFloat(value[5]), scannum, false);
        }
      }
    }
    return wifi;
  }
}

interface scanBlue {
  name: string;
  signal: number;
  mac: string;
  adv_type_: string,
  remote_add: string;
  find: boolean;
  vendor: string;
}

export class ScanResultBluetooth implements IScanResult {
  network: scanBlue[] = [];
  ScanNumber: number;

  constructor() {
    this.ScanNumber = 0;
  }

  addScan(name_: string, signal_: number, mac_: string, adv_type_: string, remote_: string, scanNumber: number, find: boolean) {
    const vendor_ = getMacVendor(mac_);
    this.network.push({
      name: name_,
      signal: signal_,
      mac: mac_,
      adv_type_: adv_type_,
      remote_add: remote_,
      find: find,
      vendor: vendor_,
    });
    this.ScanNumber = scanNumber;
  }
}

export class blueResult {
  static parseBlue(atCommand: string, scannum: number) {
    if (atCommand.indexOf("no result") >= 0) {
      return undefined;
    } else {
      atCommand = atCommand.replaceAll(";;;", ";;");
      atCommand = atCommand.replaceAll(";;", ";");
      const command = atCommand.split(";");
      const blue = new ScanResultBluetooth();
      for (let i = 0; i < command.length; i++) {
        if (command[i].indexOf("BT,more") >= 0) {
          $BTSTracker.value.moreBlue = true;
          return blue;
        }
        if (command[i].length > 0 && command[i].startsWith("BT,")) {
          const value = command[i].split(",");
          if (value.length == 6) {
            if (value[4].length == 0) {
              value[4] = "-";
            }
            blue.addScan(value[4], Number(value[5]), value[3].toUpperCase(), value[1], value[2], scannum, false);
          }
        }
      }
      return blue;
    }
  }
}

export class ScanResultActive {

}

/**
 * Function to find vendor by MAC address
 */
function getMacVendor(mac: string): string {
  // Normalize MAC address format (uppercase & remove extra chars)
  const cleanMac = mac.toUpperCase().replace(/[^A-F0-9]/g, "");
  // Extract first 6 characters (OUI)
  const macPrefix = cleanMac.substring(0, 6).match(/.{1,2}/g)?.join("-");
  // Find vendor
  const vendorEntry = vendors.find(v => v.max_prefix === macPrefix);
  return vendorEntry ? vendorEntry.vendor : "Unknown";
}

function parseFloatOrZero(input: any) {
  const parsed = parseFloat(input);
  return isNaN(parsed) || parsed === undefined ? 0 : parsed;
}

function parseIntOrZero(input: any) {
  const parsed = parseInt(input);
  return isNaN(parsed) || parsed === undefined ? 0 : parsed;
}

